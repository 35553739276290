{/* Customizable Area Start */}
import { combineReducers } from 'redux';
import { ADD_NOTIFICATION } from '../types';


const initInformation = {
    _notifications_info:[]
}

function notificationsInfo(state = initInformation,action: { type: any; payload: string | number; }){
    switch(action.type){
        case  ADD_NOTIFICATION: 
            return{
                ...state,
                _notifications_info:action.payload
            }    
        default:
            return state;
    }
}

export default notificationsInfo;

{/* Customizable Area End */}