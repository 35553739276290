import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { imgBell } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onClose:any;
  notificationmodalState:any;
  type?:any
  isCome?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  filteredData:any[];
  seeRequests:any;
  eventType:any;
  data: any[];
  selectedData: any;
  token: any;
  activeClass:any;
  linkText:any;
  events:any;
  communication:any;
  registeredVolunteer:any;
  deleteId: string | number;
  msgType: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  acceptConnectionId: string ="";
  cancelConnectionId: string = "";
  deleteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      filteredData:[],
      seeRequests:false,
      eventType:"",
      data: [],
      selectedData: null,
      token: "",
      activeClass: [],
      events: [],
      communication: [],
      registeredVolunteer: [],
      linkText: "Click more...",
      deleteId: "",
      msgType: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({ loading: true });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiResponse?.data){
        if(this.props.type === "non_profit_entity"||this.props.type === "business_entity"){
          this.handleResponse(apiResponse.data);
        } else {
          this.setState({
            loading: false,
            data: apiResponse?.data?.sort((a:any,b:any)=> new Date(b.attributes.updated_at).getTime()-new Date(a.attributes.updated_at).getTime()),
          });
        }
      }
      if(apiResponse?.errors){
        this.setState({ data: [] });
      }
      this.setState({ loading: false });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleDeleteMsg();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any){
    if(prevProps.isCome !== this.props.isCome){
     this.getNotifications();
    }
  }

  handleResponse = (responseJson: any) => {
    const events: any = [];
    const communication: any = [];
    const registeredVolunteer: any = [];
    responseJson = responseJson.sort((a: any, b: any) => new Date(b.attributes.updated_at).getTime() - new Date(a.attributes.updated_at).getTime());
    responseJson.forEach((item: any) => {
      const type = item.attributes.notify_type;
      const remark = item.attributes.remarks;
      const obj = {
        id: item.id,
        message: remark,
        sid: item.attributes.sid,
        isMore: remark.length > 45,
        isSurvey: item.attributes.is_survey,
        userId: item.attributes.push_notificable?.id.toString(),
        date: moment(item.attributes.created_at).format('DD MMMM YYYY')
      };
      if (["EventChatRoom", "IndividualChatRoom"].includes(type)) {
        communication.push(obj);
      } else if (item.attributes.push_notificable?.type === "registered_volunteer") {
        registeredVolunteer.push(obj);
      } else {
        events.push(obj);
      }
    });
    this.setState({ events, communication, registeredVolunteer, loading: false });
  }

  surveyClick = (isSurvey: boolean) => {
    if(this.props.type !== "non_profit_entity" && isSurvey){
      this.navigateToPath("BusSurveysDashboard");
    }
  }

  handleDeleteMsg = () => {
    let name = "data";
    let data = this.state.data;
    if (this.props.type !== "user_entity") {
      name = this.state.msgType;
      data = (this.state as any)[name];
    }
    const newData = data.filter((item: any) => {
      return this.state.deleteId !== item.id;
    });
    this.setState((prevState) => ({
      ...prevState,
      deleteId: "",
      msgType: "",
      [name]: newData,
    }));
  }

  navigateToPath = (path: string, id?: number | string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), id);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  onViewClick = (id: any) => {
    this.props.notificationmodalState();
    this.navigateToPath("RegisteredVolunteers", id);
  }

  iconBellProps = {
    source: imgBell,
  };
  
  connectionRequest=()=>{
    const filtered = this.state.data.filter(val=>
      val.attributes.push_notificable?.type==="connection" && val.attributes.push_notificable?.status==="pending"
    )
    this.setState({seeRequests:!this.state.seeRequests, filteredData:filtered})
  }

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_push_notifications/push_notifications"
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.props.type==="non_profit_entity"?localStorage.getItem("nonprofitToken"):localStorage.getItem("token"),
        type:this.props.type
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number, type?: string) {
    this.setState({ deleteId: id, msgType: type ?? "" });

    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_push_notifications/push_notifications/seen_notification`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.props.type === "non_profit_entity" ? localStorage.getItem("nonprofitToken") : localStorage.getItem("token"),
        type: this.props.type
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data:{attributes:{notification_ids:[id]}}
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  acceptConnection(id: any) {
    const token = localStorage.getItem('token')

    const httpBody = {
      account_id: id,
      token: token
    }

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptConnectionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_add_friends/accept_connection'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  cancelConnection(id: any) {
    const token = localStorage.getItem('token');

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelConnectionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_add_friends/decline_connection?account_id=${id}&token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }


  //For showing the message
  showNotificationMsg = (id: any, sid: any, path: string) => {
    this.markAsRead(id, path.toLowerCase());
    this.navigateToPath(path, sid);
  }

  showNotificationConnection = (id:any) => {
    this.props.navigation.navigate("UserProfileBasicBlock");
  }

  showNotificationEvent = (type:any,id:any,noti_id:any) => {
    if(type==="need"){
      this.props.navigation.navigate("EventsDetails", { id: "r1-" + id }); 
    }
  }

  formatDate = (dateString:any) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  //For close the notification modal
  closeNotificationModal = () => {
    this.props.onClose();
  }
  // Customizable Area End
}


// Customizable Area Start

// Customizable Area End