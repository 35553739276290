 {/* Customizable Area Start */}
import { ADD_ADVANCE_SEARCH_FILTER_VALUE ,GET_ALL_EVENTS_DATA,ALL_DAY,DBS_INCLUDE,FROM_DATE,END_TIME,
    TO_DATE,PROXIMITY_VALUE,SAVED_SEARCH,START_TIME,CATEGORIES} from './advanceSearchActions';


    
const initSearchInformation = {
    searchFilterValue: new Array(),
    alleventsdetails:new Array(),
    dbs_included: false,
    categories: new Array(),
    from_date: new Date(),
    to_date: new Date(),
    start_time: new Date(),
    end_time: new Date(),
    proximityValue: 6,
    saveSearchValue: false,
    all_day:false
}

function advanceSearchReducer(state = initSearchInformation, action: { type: any; payload: any; }) {
    switch (action.type) {
        case ADD_ADVANCE_SEARCH_FILTER_VALUE:
            return {
                ...state,
                searchFilterValue: action.payload
            }
            case GET_ALL_EVENTS_DATA:
                return {
                    ...state,
                    alleventsdetails: action.payload
                }
            case ALL_DAY:
                return {
                    ...state,
                    all_day: action.payload
                }
            case CATEGORIES:
                return {
                    ...state,
                    categories: action.payload
                }
            case SAVED_SEARCH:
                return {
                    ...state,
                    saveSearchValue: action.payload
                }
            case FROM_DATE:
                return {
                    ...state,
                    from_date: action.payload
                }
            case TO_DATE:
                return {
                    ...state,
                    to_date: action.payload
                }
            case START_TIME:
                return {
                    ...state,
                    start_time: action.payload
                }
            case END_TIME:
                return {
                    ...state,
                    end_time: action.payload
                }
            case DBS_INCLUDE:
                return {
                    ...state,
                    dbs_included: action.payload
                }
            case PROXIMITY_VALUE:
                return {
                    ...state,
                    proximityValue: action.payload
                }
        default:
            return state;
    }
}

export default advanceSearchReducer;

 {/* Customizable Area End */}