// Customizable Area Start
import React, { useEffect, useState } from 'react';
import { icons } from "../../../components/src/constants";
import InputAdornment from '@material-ui/core/InputAdornment';
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { informationIcon } from "./assets";
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { InitiateButton } from '../../../components/src/design-system/button';
import { useMyNonProfitOrgsAffiliations, useNPOrganziation, useRegisterUserAsVolunteerToOrg, } from '../../../components/src/api/hooks/user.hooks';
import { InitiateTextField } from '../../../components/src/design-system/text-field';
import { useNavigate } from '../../../components/src/utils/router-context';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { RegisterationMobileHeader } from './RegisterationMobileHeader';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../../../components/src/api/keys/user.keys';
import { OrganizationCard } from './OrganizationCard';
import { InitiateSpinner } from '../../../components/src/design-system/spinner';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'min(630px, 80vh)',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
      height: '85vh',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    maxHeight: '350px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  titleText: {
    fontSize: '1.8rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
    textAlign: 'center',
  },
  submitButton: {
    fontSize: '1.3rem',
  },
  skipButton: {
    fontSize: '1.3rem',
  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  orgsContainer: {
    margin: '0 -50px',
    maxHeight: '55vh',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxWidth: '20px',
      marginRight: '10px',
    },
  },
  limitText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#a4a3a4',
    fontWeight: 700,
    fontSize: '0.9rem',
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  searchIcon: {
    maxWidth: '20px',
  },
  helperText: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '110%',
    right: '-10px',
    fontSize: '0.9rem',
  },
  noAvailableDateText: {
    textAlign: 'center',
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#a4a3a4',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));


const MAX_ALLOWED_ORGS = 5;



export function MyNonProfitForm() {
  const classes = useStyles();

  const navigate = useNavigate();
  const { mutate: registerAsVolunteer, isLoading } = useRegisterUserAsVolunteerToOrg();

  const queryClient = useQueryClient();

  const prevSelectedQuery = useMyNonProfitOrgsAffiliations({ token: localStorage.getItem('token') || '' });

  const isMobile = useIsMobileScreen();

  const orgsQuery = useNPOrganziation();
  const orgs = orgsQuery.data ?? [];
  const orgsLoading = orgsQuery.isLoading;

  const [selectedOrgs, setSelectedOrgs] = useState<string[]>([]);
  const [search, setSearch] = useState('');


  function toggleOrg(orgId: string) {
    if (selectedOrgs.includes(orgId))
      setSelectedOrgs(state => state.filter(id => id != orgId))
    else {
      if (selectedOrgs.length < MAX_ALLOWED_ORGS)
        setSelectedOrgs(state => state.concat(orgId));
    }
  }

  useEffect(() => {
    if (!selectedOrgs.length) {

      const prevSelected = prevSelectedQuery.data ?? [];

      setSelectedOrgs(
        prevSelected
          .filter(org => org.registered)
          .map(org => String(org.id))
      );
    }
  }, [prevSelectedQuery.data]);



  async function submitHandler() {

    if (selectedOrgs.length) {
      registerAsVolunteer({
        token: localStorage.getItem('token') || '',
        organizationsIds: selectedOrgs.map(org => parseInt(org)),
      }, {
        onSuccess() {
          queryClient.invalidateQueries(userKeys.fetchMyNonProfitOrgsAffilliation());
          navigate('/AddWorkEmailWeb');
        },
      });
    }
  }

  function skipHandler() {
    navigate('/AddWorkEmailWeb');
  }


  const filteredOrgs = orgs
    ?.filter(org => !search ?
      true
      :
      org.organisation_name?.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <>

      <div className={classes.formContentRoot}>


        <HideContainer hideWhen={isMobile}>
          <Typography
            variant="h1"
            color="primary"
            className={classes.titleText}
          >
            {'My Non-Profits'}
          </Typography>
          <VerticalSpacer value={20} />
        </HideContainer>



        <div className={classes.textContainer}>
          <img src={informationIcon} alt="information" />
          <Typography variant="caption" className={classes.limitText}>
            {`Select up to ${MAX_ALLOWED_ORGS} organizations where you are`}
          &nbsp;
          <span>
              {'currently a registered member/volunteer'}
            </span>
          </Typography>
        </div>

        <VerticalSpacer value={20} />

        <InitiateTextField
          value={search}
          onChange={event => setSearch(event.target.value)}
          placeholder="Search Organisation"
          fullWidth
          variant="outlined"
          helperText={`${selectedOrgs.length} of ${MAX_ALLOWED_ORGS}`}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img className={classes.searchIcon} src={icons.search} />
              </InputAdornment>
            ),
          }}
        />

        <VerticalSpacer value={10} />

        <div className={clsx(classes.formContent, {
          [classes.flexCenter]: orgsLoading
        })}>


          <div className={classes.orgsContainer}>
            <HideContainer hideWhen={!orgsLoading}>
              <InitiateSpinner />
            </HideContainer>

            <HideContainer hideWhen={orgsLoading || filteredOrgs.length}>
              <Typography className={classes.noAvailableDateText}>
                {'There is no organizations with the given name'}
              </Typography>
            </HideContainer>

            <HideContainer hideWhen={orgsLoading || orgs.length}>
              <Typography className={classes.noAvailableDateText}>
                {'There is no organizations available'}
              </Typography>
            </HideContainer>

            {
              filteredOrgs?.map((org) => (
                <OrganizationCard
                  key={org.id}
                  disabled={!selectedOrgs.includes(org.id) && selectedOrgs.length == MAX_ALLOWED_ORGS}
                  onCheck={() => toggleOrg(org.id)}
                  checked={selectedOrgs.includes(org.id)}
                  org={org}
                />
              ))
            }
          </div>

          <VerticalSpacer value={40} />



        </div>


        <div className={classes.submitButtonsContainer}>
          <InitiateButton
            loading={isLoading}
            disabled={isLoading || !selectedOrgs.length}
            className={classes.submitButton}
            onClick={submitHandler}
            fullWidth
            color="primary"
            variant="contained"
            size="large">
            {'Continue'}
          </InitiateButton>

          <HideContainer hideWhen={isMobile}>
            <Button
              className={classes.skipButton}
              fullWidth
              color="primary"
              variant="text"
              onClick={skipHandler}
              size="large">
              {'Skip'}
            </Button>
          </HideContainer>
        </div>


      </div>

      <RegisterationMobileHeader
        title="My Non-Profits"
        onSkip={skipHandler}
      />
    </>
  );

}


// Customizable Area End
