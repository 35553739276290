Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.contentTypeApiAddDetail = 'application/json'
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.getUserProfileApiEndPoint = "account_block/accounts/get_user_profile";
exports.needsEnterData = "bx_block_events/needs";
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.contentTypeApi = "application/json";
exports.deleteNeedEndPoint = "needs";
exports.deleteApiType = "DELETE";
exports.pastEventEndPoint = "non_profit_events/past_events";
exports.upComingEventEndPoint= "non_profit_events/upcoming_events";
exports.needsTabDataEndPoint="/bx_block_events/needs"
exports.pledgesTabDataEndPoint="my_pledges/active_pledges"
exports.activeNeedsTabEndPoint="bx_block_events/needs/active_needs"
exports.pastNeedsTabEndPoint="needs/past_needs"
exports.deleteActiveNeedsTabEndpoint="bx_block_events/needs"
exports.allConnectionModulesEndpoint="bx_block_add_friends/connection"
exports.connectedModulesEndpoint="/bx_block_add_friends/connected_friends"
exports.pendingRequestModulesEndpoint="bx_block_add_friends/pending_connections"
exports.registeredModulesEndpoint="bx_block_profile/selected_organisation"
exports.awaitResponseModulesEndpoint="bx_block_add_friends/pending_connections"
exports.followingResponseModulesEndpoint="bx_block_followers/get_followings"
exports.addConnectionModulesEndPoint="bx_block_add_friends/connection"
exports.nonProfitAffiliationsEndPoint="get_available_organisations"
exports.saveNonProfitData="affiliate_organisation"
exports.updateProfileEndPoint="account_block/accounts/edit_profile"
exports.dbsTypesEndPoint="dbs_types"
exports.getSelectedDbsEndPoint="account_block/accounts/my_selected_dbs"
exports.updateDbsEndPoint="account_block/accounts/assign_dbs_types"
exports.publishNeedApiEndPoint = "/bx_block_events/needs";
exports.getconnectionApi="bx_block_add_friends/connected_friends";
exports.getNonProfitAffiliation="get_available_organisations"
exports.publishconnectionsApiEndpoint="bx_block_events/needs/select_connections"
exports.apiKey = "AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY";
exports.getAwaitREsponseEndPoint = 'bx_block_add_friends/connection_requests'
exports.acceptConnReqEndPoint = 'bx_block_add_friends/accept_connection';
exports.getCurrentPoints = "bx_block_gamification/donate_points/r1_rewarded_points";
exports.activityLog = "/bx_block_profile/user_activity_logs";
exports.currentPoints = "/bx_block_gamification/donate_points/r1_rewarded_points"
exports.getActivityLog = "/bx_block_profile/user_activity_logs"
// Customizable Area End





