import React from "react";
// Customizable Area Start
import ContentManagementController, {
  Props,
} from "./ContentManagementController";
import ContentManagement from "./ContentManagement";
import {
  Container,
  Box,
  Table,
  TableRow,
  TableCell as MuiTableCell, TableHead as MuiTableHead,
  TableBody,
  Divider,
  Typography,
  Input,
  InputAdornment,
  IconButton,
  CircularProgress
} from "@material-ui/core";
// @ts-ignore
import classesOuter from './Followers.module.css'
import { constants, icons } from "../../../components/src/constants";
import {
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import moment from "moment";

export const styles = (theme: Theme) =>
  createStyles({
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    search: {
      position: "relative",
      borderRadius: "8px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px",
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },

    sectionTabDesktop: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    image: {
      width: 128,
      height: 128,
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
   
    inputInput: {
      marginTop: "15",
      padding: "16 45 16 0",
      marginBottom: "10px",
      boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      fontSize: "revert",
      fontFamily: "Rene_Bieder_Milliard_Light",
      fontWeight: 700,
      "@media (max-width: 600px)": {
        marginTop: "15",
        padding: "16 65 16 0",
        marginBottom: "10px",
        boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
        paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
        transition: theme.transitions.create("width"),
        fontSize: "revert",
        fontFamily: "Rene_Bieder_Milliard_Light",
        fontWeight: 700,
      },
      "&::placeholder": {
        color: "#000501",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Rene_Bieder_Milliard_Light",
      },
    },
    aftersearchIconbutton: {
      borderRadius: "8px",
      color: "text.primary",
      backgroundColor: "white",
      margin: "8px",
      padding: "8px",
      height: 46,
      width: 46,
    },
  });

const TableHead: any = withStyles({
  root: {
    borderBottom: "3px solid #eaeaea",
  },
})(MuiTableHead);

const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);


// Customizable Area End

class FollowersNP extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  tableData() {
    return (
      this.state.loader ? <div
        style={{
          height: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress style={{ color: "#009be2" }} />
      </div> : this.tableBodyData()
    )
  }

  tableBodyData() {
    return (
      this.state.filteredFollowersData?.length > 0 ?
        <Table style={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className={classesOuter.followersNPTableHead}>
            <TableRow>
              <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>FIRST NAME</TableCell>
              <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>LAST NAME</TableCell>
              <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>LOCATION</TableCell>
              <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>LAST LOGIN</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classesOuter.followersNPTableCell}>
            {this.state.filteredFollowersData.map((item: any, index: any) =>
              <TableRow data-testId={`tableData-${item.id}`} style={{ border: 0, borderBottom: "none" }} key={index}>
                <TableCell >{item.attributes.full_name?.split(" ")[0]}</TableCell>
                <TableCell >{item.attributes.full_name?.split(" ")[1]?.length > 0 ? item.attributes.full_name?.split(" ")[1] : '-'}</TableCell>
                <TableCell>{item.attributes?.location === "" ? '-' : item.attributes.location}</TableCell>
                <TableCell>{item.attributes?.last_login === null ? '-' : moment(item.attributes.last_login).format('DD/MM/YYYY hh:mm')}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        : <Typography
          style={{
            textAlign: "center",
            height: "50%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontFamily: "Rene_Bieder_Milliard_Medium",
          }}
        >
          Sorry, No data available
        </Typography>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, theme }: any = this.props;

    // Customizable Area End
    return (
      // Customizable Area Start
      // @ts-ignore
      <ContentManagement navigation={this.props.navigation} id={""} children={undefined} showBackButton={undefined} title={"Followers"} showBottomBar={false} showDrawerHeader={false} mobileOpen={false} open={false} classes={undefined} history={undefined} titleColor={undefined} showLine={undefined}>
        <Container maxWidth="xl">
          <Box className={classesOuter.followersNPmainDiv}>
            <Box className={classesOuter.followersNPheadingDiv}>
              <Box className={classesOuter.followersNPheading}>
                <Typography style={{ fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }}>Followers</Typography>
              </Box>
              <Box>
                <div className={classes.search}>
                  {/* search field */}
                  <Input
                    data-testId="searchVal"
                    startAdornment={
                      <InputAdornment
                        position="start"
                        style={{
                          width: 40,
                          marginLeft: -9,
                        }}
                      >
                        <IconButton>
                          <img
                            src={icons.searchSVG}
                            style={{
                              height: 25,
                              width: "auto",
                              opacity: 0.5555555,
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Search..."
                    className={classesOuter.searchField}
                    onChange={(e) => this.requestSearchFollowers(e.target.value)}
                    disableUnderline
                  />
                </div>
              </Box>
            </Box>
            <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
            <Box>
              {this.tableData()}
            </Box>
          </Box>
        </Container>
      </ContentManagement>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles as any, { withTheme: true })(
  FollowersNP as any
);
export { FollowersNP };
// Customizable Area End
