 {/* Customizable Area Start */}
import * as tabActionTypes from './adminTabActions';

const initialstate = {
  adminSelectedTab:
    JSON.parse(localStorage.getItem('adminSelectedTab') as any) 
};

const adminTabReducer = (
  state = initialstate,
  action: { type: any; payload: { adminSelectedTab: any } }
) => {
  switch (action.type) {
    case tabActionTypes.SET_SELECTED_TAB:
      return {
        ...state,
        adminSelectedTab: action.payload.adminSelectedTab
      };
    default:
      return state;
  }
};

export default adminTabReducer;


 {/* Customizable Area End */}
