import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
  followStatus: any;
}

interface FollowAlertsOptions {
  selectAll: boolean,
  publishedNeed: boolean,
  publishedActivity: boolean,
  publishedProjectFund: boolean,
  broadcastedMsgs: boolean
}

export interface S {
  // Customizable Area Start
  id: string;
  loading: boolean;
  eventImage: string;
  isFavorite: boolean;
  title: string;
  startDate: any;
  startTime: any;
  nonProfitProfileUrl: any;
  organisation_id: any;
  organizationName: string;
  organizationLogo: string;
  location: string;
  isEventOpen: boolean;
  participantList: any[];
  description: string;
  userLat: number;
  userLang: number;
  eventLat: number;
  eventLang: number;
  eventStatus: string | null;
  isOpen: boolean;
  isCancelButton: boolean;
  favoriteId: null | number;
  pledgeId: null | number;
  toDate: any;
  toTime: any;
  totalCount: any;
  isPastEvent: boolean;
  joinChatStatus: boolean;
  information: string;
  NpDetais: any;
  openSuccessModal: boolean;
  followingStatus: boolean;
  followStatus: boolean;
  followAlertModal: boolean;
  contactModal: boolean;
  followOptionsModal: FollowAlertsOptions;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NonProfitProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getNonProfitProfileApiCallId: string = "";
  getUnfollowNpApiCallId: string = "";
  getFollowNpApiCallId: string = "";
  getFollowingNotifyApicallId: string = "";
  getFollowStatusApiCallId: string = "";
  addToFavApiId: string = "";
  removeFromFavApiId: string = "";
  getNpMessageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: "",
      loading: false,
      eventImage: "",
      isFavorite: false,
      title: "",
      startDate: "",
      startTime: "",
      nonProfitProfileUrl: '',
      organisation_id: "",
      organizationName: "",
      organizationLogo: "",
      location: "",
      isEventOpen: false,
      participantList: [],
      description: "",
      userLat: 0,
      userLang: 0,
      eventLat: 0,
      eventLang: 0,
      eventStatus: null,
      isOpen: false,
      isCancelButton: false,
      favoriteId: null,
      pledgeId: null,
      toDate: "",
      toTime: "",
      totalCount: 0,
      isPastEvent: false,
      joinChatStatus: false,
      information: "",
      NpDetais: {},
      openSuccessModal: false,
      followingStatus: false,
      followStatus: false,
      followAlertModal: false,
      contactModal: false,
      followOptionsModal: {
        selectAll: false,
        publishedActivity: false,
        publishedNeed: false,
        publishedProjectFund: false,
        broadcastedMsgs: false
      },
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        if (responseJson.error) {
          toast.error(`${responseJson.error}`);
        } else if (apiRequestCallId === this.getNonProfitProfileApiCallId) {
          this.handleDetailApiResponse(responseJson);
        } else if (apiRequestCallId === this.getUnfollowNpApiCallId) {
          this.getNonProfitProfile(this.props.navigation.getParam("id"))
          this.setState({ followStatus: false })
        } else if (apiRequestCallId === this.getFollowNpApiCallId) {
          this.getNonProfitProfile(this.props.navigation.getParam("id"))
          this.setState({ followStatus: !this.state.followStatus })
        } else if (responseJson !== undefined) {
          this.handleDashboardAPIs(apiRequestCallId, responseJson)
        }
      }
    }
  }

  handleDashboardAPIs(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getFollowingNotifyApicallId) {
      this.setState({ followAlertModal: false })
      this.handleSuccessModalOpen()
    } 
    if (apiRequestCallId === this.getFollowStatusApiCallId) {
      responseJson?.data ? this.setState({ followStatus: true }) : this.setState({ followStatus: false })
    }
    if (apiRequestCallId === this.addToFavApiId) {
      toast.success("Added to favourites!");
      this.getNonProfitProfile(this.props.navigation.getParam("id"))
    }
    if (apiRequestCallId === this.removeFromFavApiId) {
      toast.success("Removed from favourites!");
      this.getNonProfitProfile(this.props.navigation.getParam("id"))
    }
    if (apiRequestCallId === this.getNpMessageApiCallId) {
      this.onViewClick(responseJson.data)
    }
  }

  async componentDidMount() {
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.setState({ id }, () => {
        this.getNonProfitProfile(id);
        this.checkFollowStatus(id)
      });
    }
  }

  handleDetailApiResponse = (responseJson: any) => {
    this.setState({
      loading: false,
      NpDetais: responseJson.data
    })
  };

  openMap = () => {
    window.open(`${configJSON.GOOGLE_MAP_BASE_URL}?api=1&origin=${this.state.userLat},${this.state.userLang}&destination=${this.state.eventLat},${this.state.eventLang}`);
  }

  handleSuccessModalOpen = () => {
    this.setState({ openSuccessModal: true });
  };

  handleSuccessModalClose = () => {
    this.setState({ openSuccessModal: false });
  };

  handleAllOptions = (e: any) => {
    if (e.target.name === 'selectAll' && e.target.checked === true) {
      this.setState({
        followOptionsModal: {
          selectAll: true,
          publishedActivity: true,
          publishedNeed: true,
          publishedProjectFund: true,
          broadcastedMsgs: true
        }
      })
    } else if (e.target.name === 'selectAll' && e.target.checked === false) {
      this.setState({
        followOptionsModal: {
          selectAll: false,
          publishedActivity: false,
          publishedNeed: false,
          publishedProjectFund: false,
          broadcastedMsgs: false
        }
      })
    } else {
      this.setState({ followOptionsModal: { ...this.state.followOptionsModal, [e.target.name]: e.target.checked } })
    }
  }

  getNonProfitProfile = async (id: string) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    };

    this.getNonProfitProfileApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `bx_block_organisations/organisations/${id}`,
    });
  }

  followNotifications = async (id: any) => {

    this.followNpProfile(id);

    const body = {
      "data": {
        "attributes": {
          "published_need": this.state.followOptionsModal.publishedNeed,
          "broadcasted_message": this.state.followOptionsModal.broadcastedMsgs,
          "published_project_funding": this.state.followOptionsModal.publishedProjectFund,
          "published_upcoming_activity": this.state.followOptionsModal.publishedActivity
        }
      }
    };

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    };

    this.getFollowingNotifyApicallId = await apiCall({
      body: JSON.stringify(body),
      endPoint: `bx_block_settings/notification_settings`,
      method: "POST",
      contentType: "application/json",
      header
    });
  }

  followNpProfile = async (id: any) => {
    const token = localStorage.getItem("token");
    const type = 'user_account'

    const body = {
      "organisation_id": id,
      "type": type,
      "token": token
    }

    this.getFollowNpApiCallId = await apiCall({
      body: JSON.stringify(body),
      endPoint: `bx_block_followers/organisation_follows`,
      method: "POST",
      contentType: "application/json"
    });
  };

  unfollowNpProfile = async (id: any) => {
    const body = {
      "organisation_id": id,
      "type": 'user_account',
      "token": localStorage.getItem("token") 
    }
    this.getUnfollowNpApiCallId = await apiCall({
      body: JSON.stringify(body),
      endPoint: `bx_block_followers/unfollow`,
      method: "POST",
      contentType: "application/json"
    });
  }

  checkFollowStatus = async (id: any) => {
    const token = localStorage.getItem("token");
    this.getFollowStatusApiCallId = await apiCall({
      endPoint: `bx_block_followers/check_follow_status?organisation_id=${id}&type=user_account&token=${token}`,
      method: "GET",
      contentType: "application/json"
    });
  }

  MessageNpProfile = (id: string) => {
    const token = localStorage.getItem("token");
    const type = 'user_entity'

    const header = {
      "Content-Type": "application/json",
      "type": type,
      "token": token
    };

    const httpBody = {
      "organisation_id": id,
      
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNpMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMessageNpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  onViewClick = (item: [data:{attributes:{sid: string}}]) => 
  {
    if (item && item.length > 0 && item[0].attributes)
     {const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'GroupChat');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), 
      item[0].attributes.sid);
  msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);this.send(msg);
    } 
    else {console.error('Invalid item or missing attributes:', item);}
  }

  //Favourites
  addToFav = async (id: any) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const attrs = {
      data: {
        favouriteable_id: id,
        favouriteable_type: 'NonProfitEvent'
      }, 
    }

    this.addToFavApiId = await apiCall({
      body: JSON.stringify(attrs),
      endPoint: `bx_block_favourites/favourites`,
      method: "POST",
      contentType: "application/json",
      header,
    });
  }

  removeFromFav = async (item: any) => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token"),
    };

    this.removeFromFavApiId = await apiCall({
      endPoint: `bx_block_favourites/favourites/${item?.favourite_id}`,
      method: "DELETE",
      contentType: "application/json",
      header,
    });
  }

}

// Customizable Area End