// Customizable Area Start
import { produce } from 'immer';
import { SaveSignupFormAction, SAVE_SIGNUP_FORM } from './signup-form.actions';

export interface SignupFormState {
  email: string,
  accountId: string,
  full_phone_number: string,
  full_name: string,
}


const INTITIAL_STATE: SignupFormState = {
  email: localStorage.getItem('email') || '',
  accountId: localStorage.getItem('acc_id') || '',
  full_phone_number: localStorage.getItem('full_phone_number') || '',
  full_name: localStorage.getItem('full_name') || '',
}

type SignupFormActions = SaveSignupFormAction;

export const signupFormReducer = produce((state: SignupFormState, action: SignupFormActions) => {

  switch (action.type) {
    case SAVE_SIGNUP_FORM: {
      const { type, ...payload } = action;

      for (let [key, value] of Object.entries(payload))
        (state as any)[key] = value;
    }
  }

}, INTITIAL_STATE);
// Customizable Area End

