import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// @ts-ignore
import { constants } from "../../../components/src/constants";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  setSelectedAdminTab : any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  email: string;
  password: string;
  enablePasswordField: boolean;
  passwordError: string;
  emailPasswordValidation: boolean;
  emailError: string;
  emailValidation: boolean;
  otp: string;
  otpError: string;
  isOtpResend: string;
  expireOtpError: string;
  new_otp: string;
  forgotPwdEmail: string;
  emailOtp: string;
  newPwd: string;
  confirmPwd: string;
  resetPasswordValidation: boolean;
  enableNewPwd: boolean;
  resetPwdError: string;
  matchPwdError: string;
  enableConfirmPwd: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoginFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signInApiCallId: string = "";
  listDeviceApiId: string = "";
  forgotPasswordApiCallId: string = "";
  ForgotOtpConfirmationCallId: string = "";
  ResetPasswordCallId: string = "";
  getFcApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      email: '',
      password: '',
      enablePasswordField: true,
      passwordError: '',
      emailPasswordValidation: false,
      emailError: '',
      emailValidation: false,
      otp: '',
      otpError: '',
      isOtpResend: '',
      expireOtpError: '',
      new_otp: '',
      forgotPwdEmail: '',
      emailOtp: '',
      newPwd: '',
      confirmPwd: '',
      resetPasswordValidation: false,
      enableNewPwd: true,
      resetPwdError: '',
      matchPwdError: '',
      enableConfirmPwd: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId === this.signInApiCallId ) {
        this.signInApiCallIdFunction(responseJson,errorReponse)
      }

      if(apiRequestCallId === this.getFcApiCallId){
        // console.log("fcccccc", this.getFcApiCallId)
        this.handleFcmResponse(responseJson)
      }

      if(apiRequestCallId === this.listDeviceApiId){
        this.handleFcmList(responseJson)
      }
      if (apiRequestCallId === this.forgotPasswordApiCallId ) {
        this.handleforgotPasswordApiCallIdFunction(responseJson)
      }

      if (apiRequestCallId === this.ForgotOtpConfirmationCallId ) {
        this.handleForgotOtpConfirmationCallIdFunction(responseJson)
      }

      if (apiRequestCallId === this.ResetPasswordCallId ) {
        this.handleResetPasswordCallIdFunction(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // console.log(this.props, 'props22222')
    if(localStorage.getItem('token') !== null && localStorage.getItem('user_type') === 'business_user'){
      this.props.navigation.navigate('BusHomeDashboard')
    }
  }
  // refactor
  signInApiCallIdFunction = (responseJson: any,errorReponse: any) => {
    if(responseJson){
      if(responseJson.meta){
        localStorage.setItem("user_type", "business_user")
        localStorage.setItem("showSubscribe","true")
        localStorage.setItem('busId', responseJson?.meta?.id)
        localStorage.setItem("EmailToken",responseJson?.meta.token)
        localStorage.setItem("token",responseJson?.meta.token)
        localStorage.setItem('user_email', this.state.email.toLowerCase());
        localStorage.setItem("isLogin", "false");

        this.props.navigation.navigate('BusHomeDashboard')
        this.props.setSelectedAdminTab('Dashboard')
      }
      if(responseJson.errors[0].failed_login){
        this.setState({emailError: responseJson.errors[0].failed_login})
      }
      if(responseJson.errors[0].failed_login === 'Entered Password is not valid'){
        this.setState({passwordError: responseJson.errors[0].failed_login})
      }
      if (responseJson.errors[0].failed_login === 'Registered email not confirmed') {
        this.setState({ emailError: responseJson.errors[0].failed_login })
      }
    }
  }

  handleforgotPasswordApiCallIdFunction = (responseJson: any) => {
    if(responseJson){
      if (responseJson.data){
        localStorage.setItem('sendOtpToken', responseJson.meta.token)
        localStorage.setItem('forgotPasswordOTP', responseJson.meta.otp)
        this.props.navigation.navigate('EmailOtpVerifyBusiness')
      }
      if (responseJson.errors[0].otp === 'Account not found') {
        this.setState({ emailError: responseJson.errors[0].otp })
      }
    }
  }

  handleForgotOtpConfirmationCallIdFunction = (responseJson: any) => {
    if(responseJson){
      if(responseJson.messages){
        this.props.navigation.navigate('BusAccountVerificationMessage')
      }
      if(responseJson.errors[0].pin === 'OTP has expired, please request a new one.'){
        this.setState({expireOtpError: responseJson.errors[0].pin})
      }
      if(responseJson.errors[0].otp === 'Please enter a valid OTP'){
        this.setState({expireOtpError: responseJson.errors[0].otp})
      }
    }
  }

  goToResetPwd= () =>{
    this.props.navigation.navigate('ResetPasswordBusiness')
  }

  handleResetPasswordCallIdFunction = (responseJson: any) => {
    if(responseJson){
      if(responseJson.data){
        this.props.navigation.navigate('CongratesPasswordUpdated')
      }
      if (responseJson.errors) {
        this.setState({
          resetPwdError:
            responseJson.errors[0].token ||
            responseJson.errors[0].otp ||
            responseJson.errors[0].profile
        });
        toast.error(`${this.state.resetPwdError}` )
        return;
      }
    }
  }

  handleFcmResponse =(responseJson:any)=>{
    if(responseJson.message==="device id save successfully"){
      this.listDeviceId()
    }
  }

  handleFcmList = (responseJson:any)=>{
    const token = localStorage.getItem("fcm_token")
    const foundDevice = responseJson.data.find((device:any) => device.device_token === token);
    if(foundDevice){
      localStorage.setItem("device_id",foundDevice.id)
    }
  }
  // Login Content Start
  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  }
  //@ts-ignore
  validatePassword = (value: string) => {
    if (value === "") {
      this.setState({ passwordError: constants.BasicPasswordValidation, emailPasswordValidation: true });
    }
    if(value !== "") {
      (!value.match(/^(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[\s\S]{8,}$/)) ?
        this.setState({passwordError: constants.PasswordValidation, emailPasswordValidation: true})
        :
        this.setState({passwordError:"",emailPasswordValidation: false}
        )
    }
  }

  validateEmail = (value: any) => {
    if (value == '') {
      this.setState({ emailError: "", emailValidation: false })
    }
    if(value !== "") {
      (!value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) ?
        this.setState({emailError: "Please enter a valid email address", emailValidation: true})
        :
        this.setState({emailError: "",emailValidation: false}
        )
    }
  }

  // Sign In Api Call Start
  handleSignIn = () => {
    const header = {
      'Content-Type': 'application/json',
    };

    const httpBody = {
      "data" : {
        "attributes": {
          "email": this.state.email,
          "password": this.state.password,
          "type": "business_account"
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signInApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signInApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Sign In Api Call End
  // Login Content End

  getFcmTkn(){
    const httpBody = {
      "device_token":localStorage.getItem("fcm_token")
    };

    const header = {
      "Content-Type": 'application/json',
      "token":localStorage.getItem("token"),
      "type":"business_entity"

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFcApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/device_ids`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
 
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  listDeviceId () {

    const header = {
      'Content-Type': 'application/json',
      "token":localStorage.getItem("token"),
      "type":"business_entity"

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listDeviceApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/device_ids`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  setOtp = (e: any) => {
    this.setState({otp: e.target.value})
  }

  gotoVerify = () => {
    this.props.navigation.navigate('CongrateLogin')
  }

  setForgotPwdEmail = (e: any) => {
    this.setState({forgotPwdEmail: e.target.value})
    localStorage.setItem('ForgotPasswordEmail',e.target.value);
  }

  setEmailOtp = (e: any) => {
    this.setState({emailOtp: e})
  }

  goToEmailVerification = () => {
    this.props.navigation.navigate('LoginBusiness')
  }

  setNewPassword = (text: any) => {
    this.setState({
      newPwd: text,
      matchPwdError: "",
    });
  };
  isValidPassword(value: any) {
    const minMaxLength = /^[\s\S]{30}$/,
      upper = /[A-Z]/,
      number = /\d/,
      special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    if (
      minMaxLength.test(value) &&
      upper.test(value) &&
      number.test(value) &&
      special.test(value)
    ) {
      return true;
    }
    return false;
  }
  // For Reset password validation
  validResetPassword(value: string) {
    if (value == "") {
      this.setState({ resetPwdError: "Input can not be empty", resetPasswordValidation: true });
    } else if (!value.match(/^(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[\s\S]{8,}$/)) {
      this.setState({
        resetPwdError:
          "Password must be of 8 characters with a capital letter, a special character & a number.",
        resetPasswordValidation: true
      });
    } else {
      this.setState({ resetPwdError: "", resetPasswordValidation: false });
    }
  }

  handleClickShowNewPassword = () => {
    this.setState({
      enableNewPwd: !this.state.enableNewPwd,
    });
  }


  // Hide and show the Confirm Password
  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPwd: !this.state.enableConfirmPwd,
    });
  }


  // For Confirm Password Input
  setConfirmPassword = (text: any) => {
    this.setState({
      confirmPwd: text,
      matchPwdError: "",
      resetPasswordValidation: false
    });
  };

  handleForgotPasswordApiSendOtp = () => {
    const header = {
      'Content-Type': 'application/json',
    };

    const email = this.state.forgotPwdEmail;

    const httpBody =
    {
      "data" :
      {
        "attributes":
        {
          "email": email,
          "type":"business_account"
        }
      }
    }

    this.handleResendOtpCode(header,httpBody);
  }

  handleOtpConfirmationApi = () => {
    let token = "";

    if(typeof window !== "undefined"){
      token = localStorage.getItem("sendOtpToken") || ""
    }

    const otp = this.state.emailOtp;

    const header = {
      'Content-Type': 'application/json',
    };

    const httpBody = {
      "data" :
      {
        "token": token,
        "otp_code": otp
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ForgotOtpConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotOtpConfirmationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleResetPasswordApi = () => {
    if (this.state.newPwd !== this.state.confirmPwd) {
      this.setState({ matchPwdError: "Passwords did not match.", resetPasswordValidation: true });
      return;
    }

    let token = "";
    if(typeof window !== "undefined"){
      token = localStorage.getItem("sendOtpToken") || ""
    }
    const newPassword = this.state.newPwd;
    const confirmPassword = this.state.confirmPwd;

    const header = {
      'Content-Type': 'application/json',
    };

    const httpBody = { "data" :
      {
        "token":token,
        "new_password": newPassword,
        "password_confirmation": confirmPassword,
        "type":"business_account"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ResetPasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetNewPasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleResendOtp = () => {
    const header = {
      'Content-Type': 'application/json',
    };

    const email = localStorage.getItem('ForgotPasswordEmail');

    const httpBody =
    {
      "data" :
      {
        "attributes":
        {
          "email": email,
          "type":"business_account"
        }
      }
    }

    this.handleResendOtpCode(header,httpBody)

  }

  handleResendOtpCode = (header: any,httpBody: any ) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordSendOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
