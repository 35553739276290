// Customizable Area Start
// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
  createTheme
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import {
  Typography,
  Box,
  Container,
  Grid,
  Paper,
  Button,
  Divider,
  Input,
  Accordion,
  Dialog,
  CircularProgress,
  Checkbox
} from "@material-ui/core";
import { images } from "../../../components/src/constants";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TrendingUpSharpIcon from '@material-ui/icons/TrendingUpSharp';
import TrendingDownSharpIcon from '@material-ui/icons/TrendingDownSharp';
import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter } from "react-router-dom";
// @ts-ignore
import classOuter from "./ContentManagement.module.css";
import './ContentManagementDashboard.web.css'
import { constants } from "../../../components/src/constants";
import ContentManagementController, {
  Props,
} from "./ContentManagementController";
import ContentManagement from "./ContentManagement.web";
import CustomSuccessModel from "../../../components/src/custom/CustomSuccessModel";
import moment from "moment";
import { styled } from "@material-ui/styles";
export { AppContext } from '../../../web/src/App.js';
// Customizable Area End

// Customizable Area Start
function pxToRem(value: number) {
  return `${value / 16}rem`;
}
export type CounterProps = { label?: string };

export const styles = (theme: Theme) =>
  createStyles({
    FiCardMedia: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
    },
    FiCardContent: {
      position: "relative",
      backgroundColor: "transparent",
    },
    FiCardActions: {
      position: "relative",
    },
    FiCardActionArea: {
      position: "relative",
    },
    FiCard: {
      position: "relative",
      borderRadius: 15,
      boxShadow: "none",
      height: 145,
    },
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: "8px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      height: 48,
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(2, 2, 2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      fontSize: "revert",
    },
    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px",
      width: "100%",
      // marginLeft: " 3%",
    },
    sectionDesktop: {
      display: "none",
      // width:"27%",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      // width:"27%",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      borderRadius: "8px",
      color: "text.primary",
      backgroundColor: "white",
      margin: "8px",
      padding: "8px",
      height: 46,
      width: 46,
    },
    tabIcons: {
      margin: 6,
      padding: 8,
      border: "1px solid #cdc9c9",
      borderRadius: 8,
      backgroundColor: "white",
    },
    locationsreading: {
      borderRadius: "8px",
      color: "text.primary",
      margin: "8px",
      padding: "8px",
      display: "flex",
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      // maxWidth: 500,
      borderRadius: 16,
    },
    image: {
      width: 90,
      height: 50,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    locationIcon: {
      height: "24px",
      width: "24px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
      marginRight: "13px",
    },
    readinglocation: {
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      paddingLeft: 6,
      paddingRight: "inherit",

      "& > p": {
        fontWeight: 600,
      },

      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    changeLocationdash: {
      // marginRight: "40px",
      [theme.breakpoints.up("xs")]: {
        marginLeft: "auto",
        marginight: 0,
      },
    },

    gutter: {
      // marginLeft: 80, // or something
      "&:first-child": {
        marginLeft: 0,
      },
    },
    tabsRoot: {
      marginBottom: "50px",
      backgroundColor: COLORS.white,
    },
    tabsIndicator: {
      backgroundColor: "#1890ff",
    },
    tabRoot: {
      textTransform: "initial",
      // minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$tabSelected": {
        // color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    tabSelected: {},
    typography: {
      padding: theme.spacing(3),
    },
    eventloop: {
      marginBottom: "30px",
    },
    carouselWrapper: {
      width: " 100%",
    },
    image_div: {
      backgroundColor: "pink",
    },
    cardCarousel: {
      width: "100%",
      // margin: " 0 0 20 -17",
      borderStyle: "solid",
      borderColor: "white",
      backgroundColor: "white",
      padding: "24px 24px 24px 14px",
      borderRadius: 12,
      "& after": {
        content: "",
        display: "table",
        clear: "both",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        margin: 0,
      },
    },
    card_carousel_div: {
      float: "left",
      width: "50%",
      padding: "0 10px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        display: "block",
        marginBottom: "20px",
      },
    },
    carousel_card: {
      // padding: "16px",
      textAlign: "left",
      // backgroundColor: "#f1f1f1",
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 2.334,
      [theme.breakpoints.up("md")]: {
        fontSize: pxToRem(16),
      },
    },
    avatar_Groups: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    // web

    showAvatarGroup: { display: "none" },
    showCarouselAvatar: {
      display: "block",
      width: 200,
    },

    icon_holder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 35,
      width: 35,
      background: "white",
      borderRadius: "50%",
      position: "absolute",
      top: 10,
      right: 10,
    },
    eventContent: {
      width: "60%",
      padding: "0 20px",
      "@media (min-width:600px)": {
        width: "40%",
        padding: "0 10px",
      },
    },

    w_60: {
      width: "60%",
      paddingRight: "15px",
      "@media (min-width:600px)": {
        width: "100%",
        paddingRight: 0,
      },
    },
    py_3: {
      padding: ".5rem 0",
      "@media (min-width:600px)": {
        padding: ".5rem 0",
      },
    },

    heading2: {
      fontSize: 15,
      fontWeight: 400,
    },

    heading3: {
      fontSize: 12,
      fontWeight: 300,
    },

    flex: {
      display: "flex",
    },

    event_img_container: {
      width: "40%",
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
    },

    eventcontent: {
      width: "100%",
      padding: " 0 22px",
      marginTop: -8,
    },
    cardDescription: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    cardDescriptionAvatar_location: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    avatarImgcontainer: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "0.5rem",
    },
    avatarImg: {
      width: 23,
      height: 23,
      justifyContent: "space-between",
      borderRadius: "50%",
      position: "relative",
      left: "-5px",
      // marginLeft: "-25px",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: 16,
        height: 16,
      },
    },

    event_main_img: {
      width: "100%",
      height: "100%",
      borderRadius: 15,
      objectFit: "cover",
    },

    eventCard: {
      padding: 24,
      background: "white",
      borderRadius: 24,
      marginBottom: 30,
      height: 230,
      width: "100%",
      // marginLeft: "2rem",
    },

    icon: {
      height: 25,
      width: 25,
    },

    past_card_status: {
      zIndex: 9,
      position: "absolute",
      padding: "6px",
      height: 25,
      width: 52,
      right: " 12px",
      top: "64px",
      borderRadius: "16px",
      color: COLORS.white,
      fontSize: "12px",
      lineHeight: "27px",
      textAlign: "center",
      letterSpacing: "-0.3px",
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 13,
    },
    btn_dbs: {
      color: COLORS.white,
      height: 38,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: "0 !important",
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 15,
      left: 0,
      [theme.breakpoints.up("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },

    mobileLayoutContent: {
      display: "none",
    },
    multiLineEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      width: 180,
    },
    layOutbuttons: {
      width: 76,
      height: 24,
      borderRadius: "8px",
      lineHeight: 2,
      marginTop: 0,
      color: COLORS.white,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },

    navigationIcon: {
      color: COLORS.skyBlue,
      backgroundColor: "white",
      margin: "8px",
      padding: "13px",
      height: 50,
      borderRadius: 30,
      zIndex: 999,
      top: -12,
      right: 11,
    },

    [theme.breakpoints.down("xs")]: {
      showCarouselAvatar: {
        display: "none",
      },
      eventdate_loc: {
        width: 121,
        height: 14,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: -0.3,
        color: " #858585",
      },

      event_img_container: {
        width: "36%",
        position: "relative",
      },
      eventCard: {
        padding: 0,
        background: "white",
        borderRadius: 15,
        marginBottom: 30,
        height: 120,
      },

      icon: {
        height: 15,
        width: 15,
      },

      heading2: {
        fontSize: 12,
        fontWeight: 400,
      },

      heading3: {
        fontSize: 10,
        fontWeight: 300,
      },

      py_3: {
        padding: ".5rem 0",
      },

      w_60: {
        width: "100%",
        paddingRight: 0,
      },

      h2: {
        fontSize: 15,
      },
      desktopLayoutContent: {
        display: "none",
      },

      layOutbuttons: {
        width: 76,
        height: 24,
        borderRadius: "8px",
        lineHeight: 2,
        marginTop: 0,
        color: COLORS.white,
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
      },

      navigationIcon: {
        color: COLORS.skyBlue,
        backgroundColor: "white",
        margin: "8px",
        padding: "13px",
        height: 50,
        borderRadius: 30,
        zIndex: 999,
        top: -25,
        right: -29,
      },
      mobileLayoutContent: {
        width: "50%",
        display: "inline-grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "self-start",
        "@media (min-width:600px)": {
          width: "50%",
          position: "relative",
        },
      },
    },
  });

const theme = createTheme({
  typography: {
    subtitle1: {
      fontFamily: "Rene_Bieder_Milliard_Medium",
    },
    subtitle2: {
      fontFamily: "Rene_Bieder_Milliard_Book",
    },
  },
});

// Customizable Area End

class ContentManagementDashBoard extends ContentManagementController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  navigateToSubscriptionPlans = () => {
    let description = "Change your plan according to your needs";
    let text = "Upgrade";
    if (this.state.activePlanName === "") {
      text = "Select";
      description = "Start your 30 days FREE trial to get access";
    } else if (this.state.activePlanName === "Trial-NP") {
      text = "Select";
      description = "To keep using this account after the trial ends, set your subscription now.";
    }
    this.props.history.push({
      pathname: "/SubscriptionPlan",
      state: { description, text }
    })
  }

  renderDaysText = () => {
    const { daysRemaning, activePlanName } = this.state;
    if (activePlanName === "") {
      return (
        <>
          <Typography className="subText">Start your 30 days free trial today</Typography>
          <Typography className="noteText">Start 30 days FREE trial and get access to all services.</Typography>
        </>
      );
    } else if (activePlanName === "PLAN 3 - Non-Profit Full Subscription"){
      return (
        <>
          <Typography className="subText">You are on Full Subscription Plan</Typography>
          <Typography className="noteText">Renew your plan to maintain access to all services.</Typography>
        </>
      );
    } else if (activePlanName !== "Trial-NP" && daysRemaning > 0) {
      return (
        <>
          <Typography className="subText">Upgrade your subscription</Typography>
          <Typography className="noteText">Upgrade your plan to maintain access to all services.</Typography>
        </>
      );
    } else if (activePlanName === "Trial-NP") {
      return (
        <>
          <Typography className="subText">FREE trial</Typography>
          <Typography className="noteText">You have {this.state.daysRemaning} days left on your free trial - Subscribe now.</Typography>
        </>
      );
    }
  }

  getButtonText = () => {
    let text = "Upgrade Plan";
    if (this.state.activePlanName === "") {
      text = "Start Free Trial";
    } else if (this.state.activePlanName === "Trial-NP") {
      text = "Subscribe Now";
    }
    return text;
  }

  renderSubscriptionBanner = () => {
    return (
      <Box position='relative' margin="30px 0px 0px 0px">
        <img src={images.subscriptionBannerImg} className="bannerImg" alt="img" />
        <Box className="mainSubscribeDiv" padding={this.state.activePlanName !== "" ? "20px 30px" : "33px 30px"}>
          <Box display="flex" alignItems="center">
            {this.state.activePlanName !== "" && 
              <div className="progressDiv">
                <CircularProgressWithLabel value={(this.state.daysRemaning / this.state.totalDays) * 100} totalDays={this.state.totalDays} noOfDays={this.state.daysRemaning} />
              </div>
            }
            <div>
              {this.renderDaysText()}
            </div>
          </Box>
          {this.state.activePlanName !== "PLAN 3 - Non-Profit Full Subscription" && 
          <div>
            <Button
              className="upgradeBtn"
              onClick={(e) => {
                e.stopPropagation();
                this.navigateToSubscriptionPlans();
              }}
            >
              {this.getButtonText()}
            </Button>
          </div>
          }
        </Box>
      </Box>
    )
  }

  renderPlanExpired = () => {
    return (
      <div className="mainFreeTrialDiv">
        <div>
          {this.state.activePlanName === "Trial-NP"
            ?
            <>
              <Typography className="freeTrialText">Your FREE trial is expired!</Typography>
              <Typography className="description">Subscribe to another plan to maintain access to all services.</Typography>
            </>
            :
            <>
              <Typography className="freeTrialText">Your subscription plan has expired!</Typography>
              <Typography className="description">Subscribe now to access all features.</Typography>
            </>
          }
        </div>
        <div>
          <Button
            data-test-id="subNowBtn"
            className="subNowBtn"
            onClick={(e) => {
              e.stopPropagation();
              this.navigateToSubscriptionPlans();
            }}
          >
            Subscribe Now
          </Button>
        </div>
      </div>
    )
  }

  onBannerClick = () => {
    if (this.state.daysRemaning === 0) {
      this.setState({ isBannerVisible: "false" });
      localStorage.setItem("showSubscribe", "false");
    }
  }

  renderSubscribeSection = () => {
    if (this.state.isBannerVisible === "true"|| !this.state.isBannerVisible) {
      return (
        <Banner onClick={this.onBannerClick}>
          {(this.state.daysRemaning === 0 || localStorage.getItem("plan") === "Presence-Only") ? this.renderPlanExpired() : this.renderSubscriptionBanner()}
        </Banner>
      )
    }
  }

  renderSkeleton() {
    return (
      <Banner>
        <div className="skeleton">
          <Skeleton variant="rect" animation="wave" width={'100%'} height={130} />
        </div>
      </Banner>
    )
  }

  broadcastDialog() {
    return (
      <Dialog
        hideBackdrop
        PaperProps={{
          style: {
            background: "#FEFEFE",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
            borderRadius: 18,
            border: '1px solid #D9F6FA',
            width: 350,
            height: 'auto',
            padding: 10,
            position: "fixed",
            top: '28%',
            left: '48%'
          },
          elevation: 0,
        }}
        open={this.state.filterBroadcastModal}
        data-testId='closeModel'
        onClose={() => {
          this.setState({ filterBroadcastModal: false });
        }}
      >
        <div>
          <div style={{ display: 'flex' }}>
            <Checkbox
              style={{
                color: "lightgrey",
              }}
              checkedIcon={
                <CheckBoxIcon style={{ color: "#359ce2" }} />
              }
              data-testId='selectAll'
              checked={this.state.everyone}
              onChange={(e) => {
                if (this.state.registered_user && this.state.followers) {
                  this.setState({ everyone: true });
                }
                this.setState({
                  everyone: e.target.checked,
                  registered_user: e.target.checked,
                  followers: e.target.checked,
                });
              }}
            />
            <Typography style={{ marginTop: 10, marginLeft: 20, fontFamily: 'Rene_Bieder_Milliard_Book' }}>Everyone else</Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Checkbox
              style={{
                color: "lightgrey",
              }}
              checkedIcon={
                <CheckBoxIcon style={{ color: "#359ce2" }} />
              }
              data-testId='follower'
              checked={this.state.followers}
              onChange={(e) => {
                this.setState({
                  followers: e.target.checked,
                });
                if (this.state.registered_user || e.target.checked) {
                  this.setState({ everyone: false })
                }
              }}
            />
            <Typography style={{ marginTop: 10, marginLeft: 20, fontFamily: 'Rene_Bieder_Milliard_Book' }}>Followers</Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Checkbox
              style={{
                color: "lightgrey",
              }}
              checkedIcon={
                <CheckBoxIcon style={{ color: "#359ce2" }} />
              }
              data-testId='regiUsers'
              checked={this.state.registered_user}
              onChange={(e) => {
                if (e.target.checked || this.state.followers) {
                  this.setState({ everyone: false })
                }
                this.setState({
                  registered_user: e.target.checked,
                });
              }}
            />
            <Typography style={{ marginTop: 10, marginLeft: 20, fontFamily: 'Rene_Bieder_Milliard_Book' }}>Registered Users</Typography>
          </div>
          <div style={{ textAlign: 'end' }}>
            <Button
              data-testId="save-btn"
              color="primary"
              variant="contained"
              disabled={!(this.state.everyone || this.state.registered_user || this.state.followers)}
              className={classOuter.broadcastModelBtn}
              onClick={() => this.saveBroadcast()}
            >
              Broadcast
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

  percValue(count: number, value: number) {
    if (value >= 0) {
      return (

        <Box className={classOuter.digitContainer}>
          <Typography variant="subtitle2" className={classOuter.numberText}>
            {count}
          </Typography>
          <Typography variant="subtitle2" className={classOuter.percentageText}>
            +{value}%
            <TrendingUpSharpIcon style={{ verticalAlign: 'bottom' }} />
          </Typography>
        </Box>
      )
    }

    else if (value < 0) {
      return (
        <Box className={classOuter.digitContainer}>
          <Typography variant="subtitle2" className={classOuter.numberText}>
            {count}
          </Typography>
          <Typography variant="subtitle2" className={classOuter.percentageWarnText}>
            {value}%
            <TrendingDownSharpIcon style={{ verticalAlign: 'bottom' }} />
          </Typography>
        </Box>
      )
    }
    else {
      return (
        <Box className={classOuter.digitContainer}>
          <Typography variant="subtitle2" className={classOuter.numberText}>
            0
          </Typography>
          <Typography variant="subtitle2" className={classOuter.percentageText}>
            0%
            <TrendingUpSharpIcon style={{ verticalAlign: 'bottom' }} />
          </Typography>
        </Box>
      )
    }
  }


  renderAccordianData(accId: string, no: number) {
    return (
      <Accordion expanded={this.state.expanded === accId} data-testId={`acc-${no}`} onChange={this.handleAccordianClick(accId)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#009be2' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classOuter.listBroadcasts}>
            <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Book' }}>{no}.</Typography>
            {this.state.expanded === accId ?
              <Typography className={classOuter.listDesc} style={{ fontFamily: 'Rene_Bieder_Milliard_Book' }}>Our community outreach initiative helps us to make a difference where help is needed. If you need  <br /> help or know someone who would benefit from our assistance, then get in touch. We're here to help</Typography>
              : <Typography className={classOuter.listWrap} style={{ fontFamily: 'Rene_Bieder_Milliard_Book' }}>Our community outreach initiative helps us to make a difference where help is needed. If you need</Typography>}

            <Typography variant="subtitle2" className={classOuter.listTime}>22/6/2023 9:00 AM</Typography>
          </div>
        </AccordionSummary>
      </Accordion>
    )
  }

  renderBroadcastList() {
    return (
      this.state.broadcastMsgData?.length > 0 ?
        this.state.broadcastMsgData.map((item: any, index: any) => {
          return (
            <Accordion expanded={this.state.expanded === item.id} data-testId={`acc-${item.id}`} onChange={this.handleAccordianClick(item.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#009be2' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classOuter.listBroadcasts}>
                  <Typography style={{fontWeight: 200, color: "black", fontFamily: 'Rene_Bieder_Milliard_Book' }}>{this.state.broadcastMsgData.length - index}.</Typography>
                  {this.state.expanded === item.id ?
                    <Typography className={classOuter.listDesc} style={{ fontWeight: 200, color: "black", fontFamily: 'Rene_Bieder_Milliard_Book' }}>{item?.attributes?.message}</Typography>
                    : <Typography className={classOuter.listWrap} style={{ fontWeight: 200, color: "black", fontFamily: 'Rene_Bieder_Milliard_Book' }}>{item?.attributes?.message}</Typography>}

                  <Typography variant="subtitle2" style={{ fontWeight: 200, color: "black"}} className={classOuter.listTime}>{moment(item.attributes.created_at).format('DD/MM/YYYY hh:mm A')}</Typography>
                </div>
              </AccordionSummary>
            </Accordion>
          )
        }) : <Typography
          style={{
            textAlign: "center",
            height: "30%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontFamily: "Rene_Bieder_Milliard_Medium",
          }}
        >
          Sorry, No Broadcasts available
        </Typography>
    )
  }

  countWords = (count) => {
    if (count.length === 0) {
      return 0;
    } else {
      count = count.replace(constants.regExp.reg1, "");
      count = count.replace(constants.regExp.reg2, " ");
      count = count.replace(constants.regExp.reg3, "\n");
      return count.split(' ').length;
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // const { theme }: any = this.props;

    const openfilter = Boolean(this.state.setAnchorEl);

    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <ContentManagement
          showBackButton={constants._icons.hide}
          title="Dashboard"
          showBottomBar={constants._icons.show}
          showDrawerHeader={true}
          titleColor={COLORS.black}
          open={false}
          mobileOpen={false}
          navigation={this.props.navigation}
        >
          <Container maxWidth={'xl'} className='ContentManagementUIDiv'>
            {this.state.loading ? this.renderSkeleton() : this.renderSubscribeSection()}
            <Grid>
              <Box className={classOuter.headerContainer}>
                <Typography variant="subtitle1" className={classOuter.dashboardText}>
                  Dashboard
                </Typography>
                <Typography variant="subtitle2" className={classOuter.subHeaderText}>
                  Data visualization of last {" "}
                  <span className={classOuter.daysCount}>30 days</span>{" "}
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={6}>
                    <Paper className={classOuter.subContent}>
                      <Box className={classOuter.titleContainer}>
                        <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Book', color: 'black' }}>Registered Users</Typography>
                      </Box>
                      {this.percValue(this.state.analyticsData?.registered_volunteers?.count, this.state.analyticsData?.registered_volunteers?.percentage)}
                    </Paper>
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <Paper className={classOuter.subContent}>
                      <Box className={classOuter.titleContainer}>
                        <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Book', color: 'black' }}>Total Followers</Typography>
                      </Box>
                      {this.percValue(this.state.analyticsData?.total_followers?.count, this.state.analyticsData?.total_followers?.percentage)}

                    </Paper>
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <Paper className={classOuter.subContent}>
                      <Box className={classOuter.titleContainer}>
                        <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Book', color: 'black' }}>Pending Registrations</Typography>
                      </Box>
                      {this.percValue(this.state.analyticsData?.pending_registrations?.count, this.state.analyticsData?.pending_registrations?.percentage)}
                    </Paper>
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <Paper className={classOuter.subContent}>
                      <Box className={classOuter.titleContainer}>
                        <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Book', color: 'black' }}>Total Pledges</Typography>
                      </Box>
                      {this.percValue(this.state.analyticsData?.total_pledges?.count, this.state.analyticsData?.total_pledges?.percentage)}
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Divider className={classOuter.dividerLine} />

            <div className={classOuter.divWrapper}>
              <Input
                name="descrition"
                multiline
                disabled={!localStorage.getItem("plan")}
                rows={5}
                style={{ fontFamily: 'Rene_Bieder_Milliard_Book' }}
                placeholder="Type something you want to broadcast..."
                className={classOuter.multiLineInput}
                value={this.state.broadcastMsgCounter}
                onChange={(e) => this.setState({ broadcastMsgCounter: e.target.value })}
                disableUnderline
                data-testId='broadcastMsg'
               
              />

            </div>
            <Typography className={this.countWords(this.state.broadcastMsgCounter) > 70 ? classOuter.largeCounter : classOuter.counter} style={{ color: '#A2A2A2', fontFamily: 'Rene_Bieder_Milliard_Book' }}>{`${this.countWords(this.state.broadcastMsgCounter)}/70`}</Typography>
            <Button
              color="primary"
              data-testId='broadcastBtn'
              variant="contained"
              style={{ fontFamily: 'Rene_Bieder_Milliard_Book' }}
              className={this.countWords(this.state.broadcastMsgCounter) > 70 || this.state.broadcastMsgCounter?.trim().length === 0 ? classOuter.disableBroadcastbtn : classOuter.brodcastBtn}
              disabled={this.countWords(this.state.broadcastMsgCounter) > 70 || this.state.broadcastMsgCounter?.trim().length === 0 || !localStorage.getItem("plan")? true : false}
              onClick={() => this.setState({ filterBroadcastModal: true })}>
              Broadcast
            </Button>
            {localStorage.getItem("plan") &&
            <Box className={classOuter.broadcastList}>
              <Typography variant="subtitle2" className={classOuter.broadcastText}>Previous broadcasts</Typography>
              <Divider />

              {this.state.loader ? <div
                style={{
                  height: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{ color: "#009be2" }} />
              </div> : this.renderBroadcastList()}



            </Box>
            }
            <br />

            {this.broadcastDialog()}
            <CustomSuccessModel openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose} msgTitle='Thank you' msg="Broadcast published successfully" />
          </Container>
        </ContentManagement>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Banner = styled(Box)({
  "& .skeleton":{
    "margin": "30px 0px 25px",
    "& span":{
      "borderRadius": "16px",
    }
  },
  "& .mainFreeTrialDiv": {
    "padding": "32px 50px 32px 55px",
    "borderRadius": "16px",
    "border": "1px solid #ff6464",
    "background": "#ffffff",
    "backgroundBlendMode": "normal",
    "margin": "30px 0px 25px",
    "display": "flex",
    "justifyContent": "space-between",
    "alignItems": "center"
  },
  "& .freeTrialText": {
    "fontFamily": "Rene_Bieder_Milliard_Bold",
    "fontSize": "26px !important",
    "fontWeight": "600",
    "textTransform": "uppercase"
  },
  "& .description": {
    "fontFamily": "Rene_Bieder_Milliard_SemiBold",
  },
  "& .subNowBtn": {
    "fontFamily": "Rene_Bieder_Milliard_SemiBold",
    "width": "200px",
    "height": "40px",
    "borderRadius": "6px",
    "color": "white !important",
    "background": "#009be2 !important",
    "backgroundBlendMode": "normal",
    "letterSpacing": "-0.45px",
    "fontSize": "16px"
  },
  "& .subText": {
    "color": "#ffffff",
    "fontFamily": "Rene_Bieder_Milliard_Bold",
    "fontSize": "26px !important",
    "fontWeight": "600",
    "textTransform": "uppercase"
  },
  "& .noteText": {
    "color": "#ffffff",
    "fontFamily": "Rene_Bieder_Milliard_SemiBold",
    "fontSize": "16px !important",
    "fontWeight": "600"
  },
  "& .mainSubscribeDiv": {
    "position": "relative",
    "borderRadius": "16px",
    "background": "linear-gradient(-27deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
    "backgroundBlendMode": "normal",
    "display": "flex",
    "justifyContent": "space-between",
    "alignItems": "center",
  },
  "& .progressDiv": {
    "marginRight": "30px"
  },
  "& .upgradeBtn": {
    "fontFamily": "Rene_Bieder_Milliard_SemiBold",
    "width": "200px",
    "height": "40px",
    "borderRadius": "6px",
    "color": "white !important",
    "letterSpacing": "-0.45px",
    "background": "#009be2 !important",
    "backgroundBlendMode": "normal",
    "fontSize": "16px"
  },
  "& .bannerImg": {
    "position": "absolute",
    "height": "130",
    "width": "100%",
    "borderRadius": "16px"
  }
});

ContentManagementDashBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export { ContentManagementDashBoard };

function CircularProgressWithLabel(props: any) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} style={{ height: 90, width: 90, zIndex: 1, color: props.noOfDays > 7 ? '#8eff59' : 'red' }} />
      <CircularProgress variant="determinate" value={100} style={{ position: "absolute", top: 0, left: 0, height: 90, width: 90, color: "#dbdbdb" }} />
      <Box
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        position="absolute"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="caption" component="div" style={{ color: 'white', fontWeight: 'bold', fontSize: "23px", lineHeight: "1" }}>{props.noOfDays}</Typography>
        <Typography variant="caption" component="small" style={{ color: 'white' }}> Days Left</Typography>
      </Box>
    </Box>
  );
}
// Customizable Area End

export default withRouter(withStyles(styles as any, { withTheme: true })(
  ContentManagementDashBoard as any
));
