 {/* Customizable Area Start */}
 import * as eventActionTypes from "./eventsActions"

 const initialstate = {
     selectedEvents: JSON.parse(localStorage.getItem("selectedEvents") as any) || new Array(),
     allEvents: new Array(),
     selectedLocationWiseEvents:new Array(),
     selectedLocationWiseEventsMessage: ''
 }
 
 
 
 const eventsReducer = (state = initialstate, action: { type: any; payload: { selectedEvents: any, message: any } }) => {
     switch(action.type){
         case  eventActionTypes.SET_SELECTED_EVENTS: 
             return{
                 ...state,
                 selectedEvents:action.payload.selectedEvents
             }
         case  eventActionTypes.SET_ALL_EVENTS: 
             return{
                 ...state,
                 allEvents:action.payload.selectedEvents
         }      
         case  eventActionTypes.SET_SELECTED_LOCATION_WISE_EVENTS: 
             return{
                 ...state,
                 selectedLocationWiseEvents:action.payload.selectedEvents,
                 selectedLocationWiseEventsMessage: '',
             }
         case  eventActionTypes.SET_SELECTED_LOCATION_WISE_EVENTS_MESSAGE: 
             return{
                ...state,
                selectedLocationWiseEventsMessage:action.payload.message,
                selectedLocationWiseEvents: []
             }
                 
         default:
             return state;
     }
    
 
 }
export default eventsReducer;
 {/* Customizable Area End */}
