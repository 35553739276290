import React from "react";
// Customizable Area Start
import PropTypes from "prop-types";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { icons } from "../../../components/src/constants";
import ContentManagementController, {
  Props,
} from "./ContentManagementController";

// @ts-ignore
import module from "./ContentManagementEvent.module.css";
import {
  Table,
  TableBody,
  CircularProgress,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow,
  Divider,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  Container,
  Paper,
  Button,
  Box
} from "@material-ui/core";
import { COLORS } from "../../../framework/src/Globals";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";


export type CounterProps = { label?: string };

const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);

const TableHead: any = withStyles({
  root: {
    borderBottom: "3px solid #eaeaea",
  },
})(MuiTableHead);

const styles = (theme: Theme) => createStyles({});

// Customizable Area End

export class ManageNeedsWeb extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  createEventBtn() {
    return (
      <div>
        <Button
          onClick={() => {
            this?.props?.createNeedEvent();
          }}
          variant="contained"
          style={{
            color: "#009BE2",
            height: 49,
            backgroundColor: "rgb(162 231 225 / 10%)",
            border: "1px solid #009BE2",
            textTransform: "none",
            borderRadius: 10,
            boxShadow: "none",
            fontSize: 16,
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
          }}
          data-test-id="btnTest"
        >
          Create new event
        </Button>
      </div>
    )
  }

  eventsInTable(classes: any) {
    const isNpUser = localStorage.getItem("np_user_role") === "User";
    return (
      <Box width="100%" overflow="auto">
      <Table className={classes?.table}>
        <TableHead>
          <TableRow>
            {/* @ts-ignore */}
            <TableCell className={module.thead} width="27%">
              TITLE
            </TableCell>
            <TableCell
              className={module.thead}
              align="center"
              width="20%"
            >
              STATUS
            </TableCell>
            <TableCell className={module.thead} align="center">
              PARTICIPANTS
            </TableCell>
            <TableCell className={module.thead} align="center">
              PLEDGES
            </TableCell>
            <TableCell
              className={module.thead}
              align="center"
              width="20%"
            >
              PENDING APPROVAL
            </TableCell>
            <TableCell
              className={module.thead}
              align="center"
              width="20%"
            >
              START AT
            </TableCell>
            <TableCell className={module.thead} align="center">
              {""}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.allNeedsEvents.data &&
            this.state.allNeedsEvents.data
              .slice(0)
              .reverse()
              .map(
                (item: any, index: any) =>
                  item.attributes.event_type === "need" && (
                    <TableRow key={index}>
                      <TableCell
                        className={`${module.tbody} ${module.ellipsis}`}
                        component="th"
                        scope="row"
                      >
                        {item.attributes.event_name}
                      </TableCell>
                      <TableCell
                        className={module.tbody}
                        align="center"
                      >
                        {item.attributes.publish
                          ? "Published"
                          : "Not Published"}
                      </TableCell>
                      <TableCell
                        className={module.tbody}
                        align="center"
                      >
                        {" "}
                        {item.attributes.max_attendence
                          ? item.attributes.max_attendence
                          : "-"}{" "}
                      </TableCell>
                      <TableCell
                        className={module.tbody}
                        align="center"
                      >
                        {item.attributes.pledges
                          ? item.attributes.pledges
                          : "-"}
                      </TableCell>
                      <TableCell
                        className={module.tbody}
                        align="center"
                      >
                        {item.attributes.pending_approval
                          ? item.attributes.pending_approval
                          : "-"}
                      </TableCell>
                      <TableCell
                        className={module.tbody}
                        align="center"
                      >
                        {item.attributes.from_date
                          ? `${moment(
                            item.attributes.from_date
                          ).format("DD/MM/YYYY")}`
                          : "-"}{" "}
                        {item.attributes.start_time
                          ? `${moment(item.attributes.start_time).format("hh:mm A")}`
                          : ""}
                      </TableCell>
                      {/* @ts-ignore */}
                      <TableCell width="10%">
                        {!isNpUser &&
                        <Button
                          onClick={() => {
                            this.props.onSecondTabsCreation(
                              item.id
                            );
                            // this.props.eventUpdates(item.id);
                            localStorage.setItem(
                              "secondTabs",
                              "secondTabs"
                            );
                          }}
                          variant="outlined"
                          style={{
                            width: 100,
                            boxShadow: "none",
                            textTransform: "capitalize",
                            backgroundColor: " #009be2",
                            color: "white ",
                            fontFamily: 'Rene_Bieder_Milliard_Medium',
                            borderRadius: 10,
                            marginRight: 13,
                            marginLeft: 13,
                            border: "none",
                          }}
                          color="primary"
                        >
                          View
                        </Button>
                      }
                      </TableCell>
                    </TableRow>
                  )
              )}
        </TableBody>
      </Table>
      </Box>
    )
  }

  searchField() {
    return (
      <div>
        <Input
          value={this.state.searchNeedsEvents}
          data-testid="searchBar_test"
          startAdornment={
            <InputAdornment
              position="start"
              style={{
                width: 40,
                marginLeft: -9,
              }}
            >
              <IconButton>
                <img
                  src={icons.searchSVG}
                  style={{
                    height: 25,
                    width: "auto",
                    opacity: 0.5
                  }}
                />
              </IconButton>
            </InputAdornment>
          }
          placeholder="Search..."
          className={module.searchField}
          name="searchID"
          disableUnderline
          onChange={(e) => {
            this.setState({ searchNeedsEvents: e.target.value });
            this.searchNeedsEvents(e.target.value);
          }}
          endAdornment={
            <IconButton
              style={{
                visibility: this.state.searchNeedsEvents
                  ? "visible"
                  : "hidden",
              }}
              onClick={() => {
                this.searchNeedsEvents("");
                this.setState({ searchNeedsEvents: "" });
              }}
            >
              {" "}
              <CancelRoundedIcon />{" "}
            </IconButton>
          }
        />
      </div>
    )
  }



  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    window.localStorage.setItem("eventTabs", "need");
    const role = localStorage.getItem("np_user_role");
    const isNpUser = role === "User" ? true : false;

    return (
      <div>
        <Container maxWidth={"xl"}>
          <div>
            <Paper
              className={classes?.tableroot}
              style={{
                marginTop: 30,
                borderRadius: 10,
              }}
              elevation={0}
            >
              <Box
                p={{ sm: "22px", md: "30px" }}
                display={{ sm: "block", md: "flex" }}
                alignItems="center"
                justifyContent="space-between"
                >
                  <Box 
                    justifyContent="space-between" 
                    alignItems="center"
                    display="flex" 
                    mb={{ sm: "15px", md: "0px" }} 
                  >
                  <Box ml={{ sm: "0px", md: "-15px" }}>
                  <Typography className={module.heading}>
                    Managing needs
                  </Typography>
                  </Box>
                  <Box ml={{ sm: "0px", md: "50px" }} mr={{ sm: "0px", md: "25px" }}>
                {!isNpUser &&this.createEventBtn()}
                </Box>
                </Box>
                {this.searchField()}
              </Box>

              <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />

              {(() => {
                if (this.state.allNeedsEvents?.data?.length > 0) {
                  return this.eventsInTable(classes)




                } else if (this.state.allNeedsEvents?.data?.length === 0) {
                  return (
                    <Typography
                      style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                      }}
                    >
                      Sorry, We are unable to find this event
                    </Typography>
                  );
                }
                else if (this.state.allNeedsEvents?.error) {
                  return (
                    <Typography
                      style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                      }}
                    >
                      {this.state.allNeedsEvents?.error}
                    </Typography>
                  );
                } else {
                  return (
                    <div
                      style={{
                        height: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "#009be2" }} />
                    </div>
                  );
                }
              })()}
            </Paper>
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(
  ManageNeedsWeb as any
);

