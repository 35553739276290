import React from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { icons } from "../constants";

const useCustomKeyboardDatePickerStyles = makeStyles((theme) => ({
  textField: {
    padding: 0,
    borderRadius: 12,
    width: 145,
    height: 48,
    boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.5)',
    border: "none",
    [theme.breakpoints.down('xs')]: {
      width: "154px",
    },
  },
  pickerMargin: {
    marginTop: 0,
    marginBottom: 0
  },
  fontResize: {
    fontWeight: 400,
    lineHeight: ' 20px',
    letterSpacing: '-0.4px',
    color: "#767676",
    fontFamily: "Rene_Bieder_Milliard_Book",
  },
  inputAdornedEnd: {
    margin: 0,
    fontSize: '0.85rem',
  },
}));

export function InitiateKeyboardDatePicker(props: {
  id?: string,
  date: Date,
  setDate: (date: Date) => void,
  helperText?: string,
  error?: boolean,
  minDate?: Date,
}) {
const {
  id,
  date,
  setDate,
  helperText,
  error,
  minDate,
} = props;

  const classes = useCustomKeyboardDatePickerStyles();

  return (
    <KeyboardDatePicker
      id={id}
      disableToolbar
      color="primary"
      variant="inline"
      inputVariant="outlined"
      format="DD/MM/YYYY"
      margin="normal"
      className={classes.pickerMargin}
      autoOk
      InputProps={{
        classes: {
          root: classes.textField,
          input: classes.fontResize,
          inputAdornedEnd: classes.inputAdornedEnd
        },
      }}
      minDate={minDate}
      error={error}
      helperText={helperText}
      value={date}
      onChange={(date) => {
        setDate(date);
      }}
      KeyboardButtonProps={{
        'aria-label': `change date ${id}`,
      }}
      keyboardIcon={<img
        src={icons.calendarblue}
        alt="calender"
      />
      }
      style={{ fontSize: 16 }}
    />
  );
}


