export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const emailIcon =  require("../assets/emailIcon.png");
export const successIcon = require("../assets/successIcon.png");
export const searchIcon = require("../assets/searchIcon.png");
export const informationIcon = require("../assets/informationIcon.png");
export const phoneIcon = require("../assets/phoneIcon.png");
export const avatar = require("../assets/avatar.png");
export const cameraIcon = require("../assets/cameraIcon.png");
export const eyeOpenIcon = require("../assets/eyeOpenIcon.png");
export const eyeCloseIcon = require("../assets/eyeCloseIcon.png");
export const validOtp = require("../assets/image_caution.png");
export const greenRightIcon = require("../assets/greenRightIcon.png");
export const crossIcon = require("../assets/cross.svg");
export const stopwatchIcon = require("../assets/stopwatch.png");
