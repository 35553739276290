import React from 'react';
// Customizable Area Start
import { icons } from "../../../components/src/constants";
import clsx from 'clsx';
import { Typography, Avatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { InitiateCheckbox } from '../../../components/src/design-system/checkbox';

const useOrganizationCardStyles = makeStyles({
  formLabel: {
    margin: '0',
  },
  container: {
    margin: '10px 0',
    borderBottom: '1px solid #ececec',
    padding: '10px 50px',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '50px',
    height: '50px',
    minWidth: '50px',
    minHeight: '50px',
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '0 10px',
  },
  textContainer: {
    '& p:first-child': {
      fontFamily: 'Rene_Bieder_Milliard_Book',
      fontWeight: 700,
      fontSize: '1rem',
    },
    '& p:last-child': {
      fontFamily: 'Rene_Bieder_Milliard_Book',
      fontWeight: 700,
      color: '#a4a3a4',
      fontSize: '0.8rem',
    },
  },
  disabledIcon: {
    opacity: 0.4,
  },
});

interface NPOrganization {
  organisation_name: string;
  location: string;
  post_code? : number;
  logo?: {
    url: string | null;
  }
}

export function OrganizationCard({ org, checked, onCheck, disabled }: { org: NPOrganization, checked: boolean, onCheck: (value: boolean) => void, disabled: boolean }) {

  const classes = useOrganizationCardStyles();

  return (
    <div className={classes.container}>
      <InitiateCheckbox
        formControlLabelProps={{
          disabled: disabled,
          className: classes.formLabel,
        }}
        checked={checked}
        onChange={(event) => onCheck(event.target.checked)}
        name={org.organisation_name}
        label={
          <div className={classes.root}>
            <Avatar className={clsx(classes.icon, { [classes.disabledIcon]: disabled })} alt={org.organisation_name} src={org.logo?.url ?? org.organisation_name} />
            <div className={classes.textContainer}>
              <Typography>
                {org.organisation_name}
              </Typography>

              <Typography>
                {org.location}, {org.post_code}
              </Typography>
            </div>

          </div>
        }
      />
    </div>
  );
}
// Customizable Area End
