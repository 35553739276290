import React from "react";
// Customizable Area Start
import PropTypes from "prop-types";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { icons } from "../../../components/src/constants";
import ContentManagementController, {
  Props,
} from "./ContentManagementController";

// @ts-ignore
import module from "./ContentManagementEvent.module.css";
import {
  Table,
  Input,
  CircularProgress,
  InputAdornment,
  TableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow,
  Divider,
  IconButton,
  Typography,
  Container,
  Paper,
  Button,
  Box
} from "@material-ui/core";
import { COLORS } from "../../../framework/src/Globals";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export type CounterProps = { label?: string };

const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);

const TableHead: any = withStyles({
  root: {
    borderBottom: "3px solid #eaeaea",
  },
})(MuiTableHead);

const styles = (theme: Theme) =>
  createStyles({
    FiCardMedia: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
    },
    FiCardContent: {
      position: "relative",
      backgroundColor: "transparent",
    },
    FiCardActions: {
      position: "relative",
    },
    FiCardActionArea: {
      position: "relative",
    },
    FiCard: {
      position: "relative",
      borderRadius: 15,
      boxShadow: "none",
      height: 145,
    },
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      // borderRadius: "8px",
      marginLeft: 0,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      height: 48,
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
      },
      backgroundColor: "#F0F2F5",
      // boxShadow: "0px 0px 8px rgba(212, 212, 212, 0.8)",
      border: "1px solid lightgrey",
      borderRadius: "12px",
      zIndex: 2,
      borderWidth: 1,
      boxSizing: "border-box",
      maxWidth: 350,
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(2, 2, 2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      fontSize: "revert",
    },
    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px",
      width: "100%",
      // marginLeft: " 3%",
    },
    sectionDesktop: {
      display: "none",
      // width:"27%",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      // width:"27%",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      borderRadius: "8px",
      color: "text.primary",
      backgroundColor: "white",
      margin: "8px",
      padding: "8px",
      height: 46,
      width: 46,
    },
    tabIcons: {
      margin: 6,
      padding: 8,
      border: "1px solid #cdc9c9",
      borderRadius: 8,
      backgroundColor: "white",
    },
    locationsreading: {
      borderRadius: "8px",
      color: "text.primary",
      margin: "8px",
      padding: "8px",
      display: "flex",
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      // maxWidth: 500,
      borderRadius: 16,
    },
    image: {
      width: 90,
      height: 50,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    locationIcon: {
      height: "24px",
      width: "24px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
      marginRight: "13px",
    },
    readinglocation: {
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      paddingLeft: 6,
      paddingRight: "inherit",

      "& > p": {
        fontWeight: 600,
      },

      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    changeLocationdash: {
      // marginRight: "40px",
      [theme.breakpoints.up("xs")]: {
        marginLeft: "auto",
        marginight: 0,
      },
    },

    gutter: {
      // marginLeft: 80, // or something
      "&:first-child": {
        marginLeft: 0,
      },
    },
    tabsRoot: {
      marginBottom: "50px",
      backgroundColor: COLORS.white,
    },
    tabsIndicator: {
      backgroundColor: "#1890ff",
    },
    tabRoot: {
      textTransform: "initial",
      // minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$tabSelected": {
        // color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    tabSelected: {},
    typography: {
      padding: theme.spacing(3),
    },
    eventloop: {
      marginBottom: "30px",
    },
    carouselWrapper: {
      width: " 100%",
    },
    image_div: {
      backgroundColor: "pink",
    },
    cardCarousel: {
      width: "100%",
      // margin: " 0 0 20 -17",
      borderStyle: "solid",
      borderColor: "white",
      backgroundColor: "white",
      padding: "24px 24px 24px 14px",
      borderRadius: 12,
      "& after": {
        content: "",
        display: "table",
        clear: "both",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        margin: 0,
      },
    },
    card_carousel_div: {
      float: "left",
      width: "50%",
      padding: "0 10px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        display: "block",
        marginBottom: "20px",
      },
    },
    carousel_card: {
      // padding: "16px",
      textAlign: "left",
      // backgroundColor: "#f1f1f1",
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 2.334,
      [theme.breakpoints.up("md")]: {
        fontSize: pxToRem(16),
      },
    },
    avatar_Groups: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    // web

    showAvatarGroup: { display: "none" },
    showCarouselAvatar: {
      display: "block",
      width: 200,
    },

    icon_holder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 35,
      width: 35,
      background: "white",
      borderRadius: "50%",
      position: "absolute",
      top: 10,
      right: 10,
    },
    eventContent: {
      width: "60%",
      padding: "0 20px",
      "@media (min-width:600px)": {
        width: "40%",
        padding: "0 10px",
      },
    },

    w_60: {
      width: "60%",
      paddingRight: "15px",
      "@media (min-width:600px)": {
        width: "100%",
        paddingRight: 0,
      },
    },
    py_3: {
      padding: ".5rem 0",
      "@media (min-width:600px)": {
        padding: ".5rem 0",
      },
    },

    heading2: {
      fontSize: 15,
      fontWeight: 400,
    },

    heading3: {
      fontSize: 12,
      fontWeight: 300,
    },

    flex: {
      display: "flex",
    },

    event_img_container: {
      width: "40%",
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
    },

    eventcontent: {
      width: "100%",
      padding: " 0 22px",
      marginTop: -8,
    },
    cardDescription: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    cardDescriptionAvatar_location: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    avatarImgcontainer: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "0.5rem",
    },
    avatarImg: {
      width: 23,
      height: 23,
      justifyContent: "space-between",
      borderRadius: "50%",
      position: "relative",
      left: "-5px",
      // marginLeft: "-25px",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: 16,
        height: 16,
      },
    },

    event_main_img: {
      width: "100%",
      height: "100%",
      borderRadius: 15,
      objectFit: "cover",
    },

    eventCard: {
      padding: 24,
      background: "white",
      borderRadius: 24,
      marginBottom: 30,
      height: 230,
      width: "100%",
      // marginLeft: "2rem",
    },

    icon: {
      height: 25,
      width: 25,
    },

    past_card_status: {
      zIndex: 9,
      position: "absolute",
      padding: "6px",
      height: 25,
      width: 52,
      right: " 12px",
      top: "64px",
      borderRadius: "16px",
      color: COLORS.white,
      fontSize: "12px",
      lineHeight: "27px",
      textAlign: "center",
      letterSpacing: "-0.3px",
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 13,
    },
    btn_dbs: {
      color: COLORS.white,
      height: 38,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: "0 !important",
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 15,
      left: 0,
      [theme.breakpoints.up("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },

    mobileLayoutContent: {
      display: "none",
    },
    multiLineEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      width: 180,
    },
    layOutbuttons: {
      width: 76,
      height: 24,
      borderRadius: "8px",
      lineHeight: 2,
      marginTop: 0,
      color: COLORS.white,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },

    navigationIcon: {
      color: COLORS.skyBlue,
      backgroundColor: "white",
      margin: "8px",
      padding: "13px",
      height: 50,
      borderRadius: 30,
      zIndex: 999,
      top: -12,
      right: 11,
    },

    [theme.breakpoints.down("xs")]: {
      showCarouselAvatar: {
        display: "none",
      },
      eventdate_loc: {
        width: 121,
        height: 14,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: -0.3,
        color: " #858585",
      },

      event_img_container: {
        width: "36%",
        position: "relative",
      },
      eventCard: {
        padding: 0,
        background: "white",
        borderRadius: 15,
        marginBottom: 30,
        height: 120,
      },

      icon: {
        height: 15,
        width: 15,
      },

      heading2: {
        fontSize: 12,
        fontWeight: 400,
      },

      heading3: {
        fontSize: 10,
        fontWeight: 300,
      },

      py_3: {
        padding: ".5rem 0",
      },

      w_60: {
        width: "100%",
        paddingRight: 0,
      },

      h2: {
        fontSize: 15,
      },
      desktopLayoutContent: {
        display: "none",
      },

      layOutbuttons: {
        width: 76,
        height: 24,
        borderRadius: "8px",
        lineHeight: 2,
        marginTop: 0,
        color: COLORS.white,
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
      },

      navigationIcon: {
        color: COLORS.skyBlue,
        backgroundColor: "white",
        margin: "8px",
        padding: "13px",
        height: 50,
        borderRadius: 30,
        zIndex: 999,
        top: -25,
        right: -29,
      },
      mobileLayoutContent: {
        width: "50%",
        display: "inline-grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "self-start",
        "@media (min-width:600px)": {
          width: "50%",
          position: "relative",
        },
      },
    },
    table: {
      minWidth: 650,
    },
    tableroot: {
      width: "100%",
      marginTop: theme.spacing(3),
      borderRadius: 16,
    },
    searchreletiveenv: {
      position: "relative",
    },
    searchfilterEnv: {
      position: "absolute",
      height: "100vh",
      maxHeight: "555px",
      top: "55px",
      padding: "10px",
      // "backgroundColor": "#F0F2F5",
      background: "#FFFFFF",
      border: "1px solid #D5D5D5",
      borderRadius: "13px",
    },
  });
// Customizable Area End
export class ManageActivities extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }


  manageActivityPart() {
    const role = localStorage.getItem("np_user_role");
    const isNpUser = role === "User" ? true : false;
    return (
      <Box
        display={{ sm: "block", md: "flex" }}
        p={{ sm: "22px", md: "30px" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box 
          display="flex" 
          justifyContent="space-between" 
          alignItems="center"
          mb={{ sm: "15px", md: "0px" }} 
        >
        <Box ml={{ sm: "0px", md: "-15px" }}>
          <Typography
            className={module.heading}
            style={{
              fontSize: 18,
            }}
          >
            Managing activities
          </Typography>
        </Box>
          <Box ml={{ sm: "0px", md: "50px" }} mr={{ sm: "0px", md: "25px" }}>
          {!isNpUser &&
          <Button
            data-test-id="ManageActivitiesCreateNewEventBtn"
            onClick={() => {
              this.props.createActivitiesEvent();
            }}
            variant="contained"
            style={{
              color: "#009BE2",
              height: 49,
              backgroundColor: "rgb(162 231 225 / 10%)",
              border: "1px solid #009BE2",
              textTransform: "none",
              borderRadius: 10,
              boxShadow: "none",
              fontSize: 16,
              fontFamily: "Rene_Bieder_Milliard_SemiBold",
              // marginLeft: 40,
              // marginRight: 25
            }}
          >
            Create new event
          </Button>
        }
        </Box>
        </Box>
        <div >
          <Input
            value={this.state.searchActivitiesEvents}
            data-test-id="ManageActivitiesSearchInput"
            startAdornment={
              <InputAdornment
                position="start"
                style={{
                  width: 40,
                  marginLeft: -9,
                }}
              >
                <IconButton>
                  <img
                    src={icons.searchSVG}
                    style={{
                      height: 25,
                      width: "auto",
                      opacity: 0.5
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
            placeholder="Search..."
            className={module.searchField}
            disableUnderline
            onChange={(e) => {
              this.setState({
                searchActivitiesEvents: e.target.value,
              });
              this.searchActivitiesEvents(e.target.value);
            }}
            endAdornment={
              <IconButton
                style={{
                  visibility: this.state.searchActivitiesEvents
                    ? "visible"
                    : "hidden",
                }}
                onClick={() => {
                  this.searchActivitiesEvents("");
                  this.setState({ searchActivitiesEvents: "" });
                }}
              >
                {" "}
                <CancelRoundedIcon />{" "}
              </IconButton>
            }
          />
        </div>
      </Box>

    )
  }


  renderTableshellevent_name(item: any) {
    return (
      <TableCell
        className={`${module.tbody} ${module.ellipsis}`}
        component="th"
        scope="row"
      >
        {item.attributes.event_name}
      </TableCell>
    )
  }

  renderTableshellPublishStatus(item: any) {
    return (
      <TableCell
        className={module.tbody}
        align="center"
      >
        {item.attributes.publish
          ? "Published"
          : "Not Published"}
      </TableCell>
    )
  }

  renderTableshellAttendnanceStatus(item: any) {
    return (

      <TableCell
        className={module.tbody}
        align="center"
      >
        {item.attributes.max_attendence
          ? item.attributes.max_attendence
          : "-"}
      </TableCell>
    )
  }

  tableBodyPart(index: any, item: any) {
    const role = localStorage.getItem("np_user_role");
    const isNpUser = role === "User" ? true : false;
    return (
      <TableRow key={index}>
        {this.renderTableshellevent_name(item)}
        {this.renderTableshellPublishStatus(item)}
        {this.renderTableshellAttendnanceStatus(item)}

        <TableCell
          className={module.tbody}
          align="center"
        >
          {item.attributes.pledges
            ? item.attributes.pledges
            : "-"}
        </TableCell>
        <TableCell
          className={module.tbody}
          align="center"
        >
          {item.attributes.pending_approval
            ? item.attributes.pending_approval
            : "-"}
        </TableCell>
        <TableCell
          className={module.tbody}
          align="center"
        >
          {item.attributes.from_date
            ? `${moment(
              item.attributes.from_date
            ).format("DD/MM/YYYY")}`
            : "-"}{" "}
          {item.attributes.start_time
            ? `${moment(item.attributes.start_time).format("hh:mm A")}`
            : ""}
        </TableCell>
        {/* @ts-ignore */}
        <TableCell width="10%">
          {!isNpUser &&
          <Button
            onClick={() => {
              this.props.onSecondTabsCreation(
                item.id
              );
              this.setState({
                secondTabForm: true,
              });
            }}
            variant="outlined"
            style={{
              width: 100,
              boxShadow: "none",
              textTransform: "capitalize",
              // height: 35,
              backgroundColor: " #009be2",
              color: "white ",
              fontFamily: 'Rene_Bieder_Milliard_Medium',
              borderRadius: 10,
              marginRight: 13,
              marginLeft: 13,
              border: "none",
            }}
            color="primary"
          >
            View
          </Button>
        }
        </TableCell>
      </TableRow>
    )
  }

  activityTablePart(classes: any) {
    return (
      <Box width="100%" overflow="auto">
      <Table className={classes?.table}>
        <TableHead>
          <TableRow>
            <TableCell className={module.thead} width="27%">
              TITLE
            </TableCell>
            <TableCell
              className={module.thead}
              align="center"
              width="20%"
            >
              STATUS
            </TableCell>
            <TableCell className={module.thead} align="center">
              PARTICIPANTS
            </TableCell>
            <TableCell className={module.thead} align="center">
              PLEDGES
            </TableCell>
            <TableCell
              className={module.thead}
              align="center"
              width="20%"
            >
              PENDING APPROVAL
            </TableCell>
            <TableCell
              className={module.thead}
              align="center"
              width="20%"
            >
              START AT
            </TableCell>
            <TableCell className={module.thead} align="center">
              {""}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {this.state.allActivitiesEvents.data &&
            this.state.allActivitiesEvents.data
              .slice(0)
              .reverse()
              .map(
                (item: any, index: any) =>
                  item.attributes.event_type === "activity" && this.tableBodyPart(index, item)
              )}
        </TableBody>
      </Table>
      </Box>
    )
  }
  render() {
    // Customizable Area STart
    const { classes }: any = this.props;
    window.localStorage.setItem("eventTabs", "activity");

    return (
      <div>
        <Container maxWidth={"xl"}>
          <div>
            <Paper
              className={classes?.tableroot}
              style={{
                marginTop: 30,
                borderRadius: 10,
              }}
              elevation={0}
            >

              {this.manageActivityPart()}

              <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />

              {(() => {
                if (this.state.allActivitiesEvents?.data?.length > 0) {
                  return this.activityTablePart(classes)
                } else if (this.state.allActivitiesEvents?.data?.length === 0) {
                  return (
                    <Typography
                      style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                      }}
                    >
                      Sorry, We are unable to find this event
                    </Typography>
                  );
                } else if (this.state.allActivitiesEvents?.error) {
                  return <Typography
                    style={{
                      textAlign: "center",
                      height: "50%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                    }}
                  >
                    {this.state.allActivitiesEvents?.error}                    </Typography>
                }


                else {
                  //circularprogress
                  return (
                    <div
                      style={{
                        height: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "#009be2" }} />
                    </div>
                  );
                }
              })()}
            </Paper>
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(
  ManageActivities as any
);
