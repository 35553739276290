import React, { forwardRef } from "react";
// Customizable Area Start
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import {
  Snackbar,
  SnackbarContent,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
  Button,
  Input,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Dialog,
  IconButton,
  Checkbox,
  Grid,
  withStyles,
  Theme,
  Switch,
  createStyles,
  makeStyles,
  Container,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Select from "react-select";
// @ts-ignore
import styles from "./ContentManagementEvent.module.css";
export const useStyles = (theme: Theme) =>
  createStyles({
    textField: {
      padding: 0,
      borderRadius: 12,
      height: 54,
      width: "50%",
      fontFamily: "Rene_Bieder_Milliard_Book",
      border: "none",
    },
    fontResize: {
      fontWeight: 400,
      lineHeight: " 20px",
      letterSpacing: "-0.4px",
      color: "black",
    },
  });
import { FAMILYFONTS, icons } from "../../../components/src/constants";
import { COLORS } from "../../../framework/src/Globals";
import ContentManagementController, { configJSON } from "./ContentManagementController";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";

const eventTypes = [
  { label: "Need", value: "need" },
  { label: "Project funding", 
  value: "project_funding" },
  { label: "Upcoming activity",
   value: "activity" },
];

const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 18,
      padding: 0,
      display: "flex",
    },

    switchBase: {
      padding: 2,
      // color: theme.palette.grey[500],
      color: "white",
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#359ce2",
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 18 / 2,
      opacity: 1,
      // backgroundColor: theme.palette.common.white,
      backgroundColor: "grey",
    },
    checked: {},
  })
)(Switch);

// @ts-ignore
const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <TextField
    variant="outlined"
    placeholder="dd/mm/yyyy"
    value={value}
    className={styles.textInput}
    onClick={onClick}
    onChange={onChange}
    InputProps={{
      classes: {
        root: styles.locationField,
      },
      endAdornment: (
        <div>
          <InputAdornment position="end">
            <img
              src={icons.npEformCalendar}
              // style={{ height: 28, width: "auto" }}
            />
          </InputAdornment>
        </div>
      ),
    }}
  />
));

export const styles1 = makeStyles((theme) => ({
  root: {
    // your existing styles for the TextField component
  },
  focused: {
    // styles for the focused state
  },
  notchedOutline: {
    // styles for the notched outline
    borderColor: theme.palette.grey[400],
    "&:hover": {
      borderColor: theme.palette.grey[500],
    },
  },
}));

// Customizable Area End

export class ProjectsEventWeb extends ContentManagementController {
  nameOfEvent() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Name of event *</label>
        <br />
        <Input
          value={this.state.eventName}
          className={styles.textInput}
          disableUnderline
          data-testId="eventName"
          placeholder="Name of event"
          onChange={(e) => {
            this.setState({ eventType: "project_funding" });
            this.setState({
              eventName: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
            if (e.target.value.length < 1) {
              this.setState({ eventNameValidation: true });
            } else {
              this.setState({
                eventNameValidation: false,
              });
            }
          }}
        />
        <br />
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventNameValidation ? "flex" : "none"}`,
          }}
        >
          Event name can't be blank
        </span>
      </div>
    );
  }

  eventDescription() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Description of project *</label>
        <br />
        <Input
          disableUnderline
          data-testId="desc"
          value={this.state.description}
          onChange={(e) => {
            this.setState({
              description: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          multiline
          rows={6}
          className={styles.multiLineInput}
          placeholder="Provide a general description visible
           to all users alerted to your project."
        />
      </div>
    );
  }

  projectOutcomeField() {
    return (
      <div className={styles.divWrapper} >
        <label className={styles.labelHeading}>What is the project outcome</label>
        <br />
        <Input
          data-testId="projectOutcome"
          value={this.state.projectOutcome}
          onChange={(e) => {
            this.setState({
              projectOutcome: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          disableUnderline
          className={styles.multiLineInput}
          placeholder="Describe what is the project outcome"
          multiline
          rows={6}
        />
      </div>
    );
  }

  beneficiariesField() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Who are the beneficiaries ?</label>
        <br />
        <Input
          data-testId="benefit"
          value={this.state.beneficiaries}
          onChange={(e) => {
            this.setState({ beneficiaries: e.target.value });
          }}
          placeholder="Beneficiaries"
          disableUnderline
          className={styles.textInput}
        />
      </div>
    );
  }

  amountRequired() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>How much is required</label>
        <br />
        <Input
          startAdornment={<IconButton>
              <img src={icons.currencySymbol} style={{ marginTop: -2 }} />
            </IconButton>}
          onChange={(e) => {this.setState({ amount_required: e.target.value });}}
          value={this.state.amount_required}
          type="number"
          data-testId="amount"
          placeholder="Required amount"
          className={styles.textInput}
          disableUnderline
        />
      </div>
    );
  }

  projectDeliveryField() {
    return (
      <div className={styles.divWrapper} style={{ width: '50%', position: 'relative' }}>
        <label className={styles.labelHeading}>Where will the project be delivered</label>
        <br />
        <div className="published-need projectOutcome new">
          {/* @ts-ignore */}
          <GooglePlacesAutoComplete
          className={styles.textInput}
          debounce={500}
          data-testId = 'location'
          apiKey={configJSON.mapsAPIKey}
          selectProps={{
            placeholder:"Enter here",
            isClearable: true,
            classNamePrefix: "react-select",
            value: this.state.location ? [{ label: this.state.location, value: this.state.location }] : undefined,
            noOptionsMessage: () => null,
            onChange: (event: any) => {
              const location = event?.value?.description ?? "";
              this.setState({ location });
            },
          }}
        >
        </GooglePlacesAutoComplete>
        </div>
      </div>
    );
  }

  videoUploadsCheck() {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "lightgrey" }}
              onChange={(e) => {
                this.setState({
                  video_uploads: e.target.checked,
                });
              }}
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
            />
          }
          label={<span className={styles.label}>Video uploads</span>}
          data-testId="check-1"
        />
      </FormGroup>
    );
  }

  videoUploadExt() {
    return (
      <div
        style={{
          display: `${this.state.video_uploads ? "block" : "none"}`,
        }}
      >
        <IconButton
          color="primary"
          // className={classes.imageuploadicon}
          aria-label="upload picture"
          component="label"
        >
          <img src={icons.npUpload} style={{ height: 20 }} alt="cameraIcon" />

          <input
            accept="video/mp4"
            type="file"
            hidden
            multiple
            onChange={this.handleMultiVideo}
          />
        </IconButton>
        <Button
          data-testId="modalOpen"
          onClick={() => {
            this.multiVideosDisplay.length >= 1 &&
              this.setState({ multiVideoModal: true });
          }}
          style={{
            width: "20%",
            height: 30,
            textTransform: "capitalize",
            boxShadow: "none",
            backgroundColor: "#009be2",
            color: "white ",
            borderRadius: 10,
          }}
        >
          View
        </Button>
      </div>
    );
  }

  imgUploadCheck() {
    return (
      <FormGroup>
        <FormControlLabel
          data-testId="check-2"
          control={
            <Checkbox
              style={{ color: "lightgrey" }}
              onChange={(e) => {
                this.setState({
                  upload_images: e.target.checked,
                });
              }}
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
            />
          }
          label={<span className={styles.label}>Upload images</span>}
        />
      </FormGroup>
    );
  }

  postUpdatesCheck() {
    return (
      <FormGroup>
        <FormControlLabel
          data-testId="check-3"
          control={
            <Checkbox
              style={{ color: "lightgrey" }}
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
              onChange={(e) => {
                this.setState({
                  post_updates: e.target.checked,
                });
              }}
            />
          }
          label={<span className={styles.label}>Post updates</span>}
        />
      </FormGroup>
    );
  }

  postUpdateExt() {
    return (
      <div
        style={{
          display: `${this.state.post_updates ? "block" : "none"}`,
        }}
      >
        <IconButton
          onClick={() => {
            this.setState({ editNoteModal: true });
          }}
        >
          <img src={icons.npEdit} style={{ height: 25, width: 25 }} />
        </IconButton>

        <Button
          onClick={() => {
            this.setState({ viewUpdatesModal: true });
          }}
          style={{
            width: "20%",
            height: 30,
            textTransform: "capitalize",
            boxShadow: "none",
            backgroundColor: "#009be2",
            color: "white ",
            borderRadius: 10,
          }}
        >
          View
        </Button>
      </div>
    );
  }

  proofOfDelivery() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>
          What proof-of-delivery artefacts will be implemented
        </label>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {this.videoUploadsCheck()}
          {this.videoUploadExt()}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {this.imgUploadCheck()}
          <div
            style={{
              display: `${this.state.upload_images ? "block" : "none"}`,
            }}
          >
            <IconButton
              color="primary"
              // className={classes.imageuploadicon}
              aria-label="upload picture"
              component="label"
            >
              <img
                src={icons.npUpload}
                style={{ height: 20 }}
                alt="cameraIcon"
              />

              <input
                accept="image/png, image/jpeg"
                type="file"
                hidden
                multiple
                onChange={this.handleMultiImage}
              />
            </IconButton>
            <Button
              data-testId="modalImgOpen"
              onClick={() => {
                this.multiImagesdisplay.length >= 1 &&
                  this.setState({ multiImageModal: true });
              }}
              style={{
                width: "20%",
                height: 30,
                textTransform: "capitalize",
                boxShadow: "none",
                backgroundColor: "#009be2",
                color: "white ",
                borderRadius: 10,
              }}
            >
              View
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {this.postUpdatesCheck()}
          {this.postUpdateExt()}
        </div>
      </div>
    );
  }

  publishScope() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Publishing scope</label>
        <br />
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-6"
              control={
                <Checkbox
                  style={{ color: "lightgrey" }}
                  checked={
                    this.state.everyone
                  }
                  checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                  onChange={(e) => {
                    if (this.state.registered_user && this.state.followers ) {
                      this.setState({ 
                        everyone: true 
                      });
                    } 
                    this.setState({
                      everyone: e.target.checked,
                      followers: e.target.checked,
                      registered_user: e.target.checked,
                    });
                  }}
                />
              }
              label={<span className={styles.label}>Everyone</span>}
            />
          </span>
        </FormGroup>
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-7"
              control={
                <Checkbox
                  style={{ color: "lightgrey" }}
                  checked={this.state.followers}
                  checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                  onChange={(e) => {
                    if( this.state.registered_user 
                      || e.target.checked) {
                      this.setState({ everyone: false})
                    }
                    this.setState({
                      followers: e.target.checked,
                    });
                  }}
                />
              }
              label={<span className={styles.label}>Followers</span>}
            />
          </span>
        </FormGroup>
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-8"
              control={
                <Checkbox
                  style={{ color: "lightgrey" }}
                  checked={this.state.registered_user}
                  checkedIcon={
                    <CheckBoxIcon
                      style={{
                        color: "#359ce2",
                      }}
                    />
                  }
                  onChange={(e) => {
                    if( this.state.followers 
                      || e.target.checked) {
                      this.setState({ everyone: false})
                    }
                    this.setState({
                      registered_user: e.target.checked,
                    });
                  }}
                />
              }
              label={<span className={styles.label}>Registered user</span>}
            />
          </span>
        </FormGroup>
      </div>
    );
  }

  projectDate(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Date *</label>
        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiOutlinedInput-root.Mui-Focused .MuiOutlinedInput-notchedOutline   {
              border : 1px solid black
            }
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          data-testId='updateFromDate' 
          autoOk
          disablePast
          placeholder="dd/mm/yyyy"
          fullWidth
          disableToolbar
          label=""
          minDate={new Date()}
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          margin="normal"
          id="date-picker-inline"
          InputProps={{
            classes: {
              root: classes.textField,
              input: classes.fontResize,
            },
          }}
          value={this.state.from_date}
          onChange={(date) => {
            this.setState({ from_date: date });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
          style={{ fontSize: 16, marginTop: 0 }}
        />
      </div>
    );
  }

  projectTime(classes: any) {
    return (
      <>
      <div className={styles.divWrapper} style={{ position: "relative" }}>
        <label className={styles.labelHeading}>Time</label>
        <br />
        <InitiateThemeProvider>
          <KeyboardTimePicker
            data-testId= 'updateStartTime'
            autoOk
            fullWidth
            disableToolbar
            ampm={false}
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            id="mui-pickers-time"
            className={classes.pickerMargin}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            placeholder="12:00 AM"
            mask="__:__ _M"
            value={this.state.start_time}
            onChange={(time) => {
              const isSameDate = this.state.from_date?.isSame(moment(),"day");
              if(isSameDate){
                this.setState({ start_time: new Date(Date.now()) });
              } else {
                this.setState({ start_time: time });
              }
            }}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                src={icons.clockIcon}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
      <div className={styles.divWrapper} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "50%"}}>
        <div className={styles.labelHeading}>
          Donors will be updated on progress during project delivery.
        </div>
        <AntSwitch 
         data-testId="projectUpdate"
         value={this.state.projectUpdate}
         onChange={(e: any) => {
           this.setState({ projectUpdate: e.target.checked ? "true" : "false" });
         }} />
      </div>
      </>
    );
  }

  socialMedia1() {
    return (
      <Input
        data-testId="media-1"
        value={this.state.social_media1}
        onChange={(e) => {
          this.setState({ social_media1: e.target.value });
        }}
        style={{ marginBottom: 10 }}
        disableUnderline
        className={styles.textInput}
        placeholder="Enter link here"
      />
    );
  }

  socialMedia2() {
    return (
      <Input
        data-testId="media-2"
        disableUnderline
        value={this.state.social_media2}
        onChange={(e) => {
          this.setState({ social_media2: e.target.value });
        }}
        style={{ marginBottom: 10 }}
        className={styles.textInput}
        placeholder="Enter link here"
      />
    );
  }

  socialMedia3() {
    return (
      <Input
        data-testId="media-3"
        value={this.state.social_media3}
        onChange={(e) => {
          this.setState({ social_media3: e.target.value });
        }}
        // style={{ marginBottom: 10 }}
        disableUnderline
        className={styles.textInput}
        placeholder="Enter link here"
      />
    );
  }

  uploadImg() {
    return (
      <div style={{ width: "50%" }} className={styles.divWrapper}>
        <label className={styles.labelHeading}>Upload project banner *</label>

        <CardContent className={styles.cardUploadImage}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              background: COLORS.white,
              width: "106%",
              marginLeft: -12,
              height: "22%",
              // border: "1px solid #a7b6b9 ",
              border: "1px solid #dfdfdf",
              borderRadius: 10,
              position : 'relative',
            }}
          >
            <div
              style={{
                display: `${this.state.needsEventImg ? "flex" : "none"}`,
                position: "absolute",
                right: 0,
                top : 0,
              }}
              data-testId="imgPreview"
              onClick={(e) => {
                this.setState({
                  needsEventImg: "",
                  needsEventImgPreview: "",
                });
              }}
            >
              <HighlightOffRoundedIcon />
            </div>
            {this.state.needsEventImg ? (
              <img
                src={this.state.needsEventImgPreview}
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 10,
                }}
              />
            ) : (
              <IconButton
                color="primary"
                // className={classes.imageuploadicon}
                aria-label="upload picture"
                component="label"
              >
                <img
                  src={icons.plusIcon}
                  style={{ height: 20 }}
                  alt="cameraIcon"
                />

                <input
                  // name="profile_picture"
                  accept="image/*"
                  // id="contained-button-file"

                  type="file"
                  hidden
                  data-testId="userImageUpload"
                  onChange={(event) => {
                    event.preventDefault();
                    if (event.target.files && event.target.files[0]) {
                      let file = event.target.files[0];
                      this.setState({
                        needsEventImgPreview: URL.createObjectURL(file),
                      });
                      this.setState({
                        needsEventImg: file,
                      });
                    }
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </CardContent>
        <div />
      </div>
    );
  }

  publishSwitch() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Publish</label>
        <br />
        {/* @ts-ignore */}
        <AntSwitch
          data-testId="publishSwitch"
          value={this.state.publish}
          onChange={(e: any) => {
            this.setState({ publish: e.target.checked });
          }}
        />
      </div>
    );
  }

  isSaveBtnDisabled = () => {
    return !this.state.description ||
    !this.state.location ||
    !this.state.eventName ||
    !this.state.from_date ||
    !this.state.start_time ||
    !this.state.needsEventImg ||
    this.state.createEventBtn;
  }

  createEventBtn() {
    return (
      <Button
        data-testId="createBtn"
        disabled={this.isSaveBtnDisabled()}
        onClick={() => {
          this.needsEvent();
        }}
        // variant="outlined"
        style={{
          fontFamily: "Rene_Bieder_Milliard_SemiBold",
          width: "12%",
          boxShadow: "none",
          textTransform: "capitalize",
          height: 45,
          backgroundColor: `${
            !this.isSaveBtnDisabled()
              ? "#009be2"
              : "#009be233"
          }`,
          color: "white ",
          borderRadius: 10,
          marginRight: 13,
          marginLeft: 13,
        }}
        color="primary"
      >
        Save
      </Button>
    );
  }

  cancelEventBtn() {
    return (
      <Button
        onClick={() => {
          this.props.navigationToProjectsList();
        }}
        style={{
          width: "12%",
          fontFamily: "Rene_Bieder_Milliard_SemiBold",
          backgroundColor: COLORS.white,
          color: "#009be2",
          boxShadow: "none",
          borderRadius: 8,
          height: 45,
          border: ".1 solid lightgray",
          textTransform: "capitalize",
        }}
        variant="outlined"
        data-testId="cancelBtn"
      >
        Cancel
      </Button>
    );
  }

  footerBtns() {
    return (
      <div
        className={styles.divWrapper}
        style={{ display: "flex", marginTop: 15, marginLeft: -10 }}
      >
        {this.createEventBtn()}
        {this.cancelEventBtn()}
      </div>
    );
  }

  videoDialog() {
    return (
      <Dialog
        hideBackdrop
        PaperProps={{
          style: {
            background: "#FEFEFE",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
            borderRadius: 18,
            maxHeight: 370,
            width: 500,
          },
          elevation: 0,
        }}
        open={this.state.multiVideoModal}
        data-testId="modal"
        onClose={() => {
          this.setState({ multiVideoModal: false });
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 20,
            }}
          >
            <label className={styles.label} style={{ fontWeight: 600 }}>
              Video proofs on project!
            </label>
            <div
              data-testId="modalOpenVideo"
              onClick={() => {
                this.setState({ multiVideoModal: false });
              }}
            >
              <HighlightOffRoundedIcon />
            </div>
          </div>
          <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />
          <div style={{ padding: 20 }}>
            {this.multiVideosDisplay.map((item, index: any) => (
              <div
              key={index}
                style={{
                  display: "flex",
                  border: "1px solid #e5e7f0",
                  borderRadius: 10,
                  marginBottom: 10,
                  padding: 10,
                  background: "#FFFFFF",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    marginLeft: "77%",
                    marginTop: "-10px",
                    border: "1px solid rgb(0, 155, 226)",
                    fontFamily: "Rene_Bieder_Milliard_Medium",
                    background: "rgb(0, 155, 226)",
                    color: "rgb(255, 255, 255)",
                    padding: "0px 15px 0px 17px",
                    borderRadius: "0px 9px",
                  }}
                >
                  New
                </span>
                <div style={{ marginRight: 10, borderRadius: 8 }}>
                  <video src={item} style={{ height: 64, width: 64 }} />
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    className={styles.label}
                    style={{ fontWeight: 600, fontSize: 16 }}
                  >
                    Video proof of project
                  </Typography>
                  <Typography
                    className={styles.updatedText}
                    style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}
                  >
                    Updated on : {moment(new Date()).format("DD/MM/YYYY")}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    );
  }

  viewNoteDialog() {
    return (
      <Dialog
        hideBackdrop
        PaperProps={{
          style: {
            background: "#FEFEFE",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
            borderRadius: 18,
            maxHeight: 370,
            width: 500,
          },
          elevation: 0,
        }}
        open={this.state.viewUpdatesModal}
        // data-testId="modalEdit"
        onClose={() => {
          this.setState({ viewUpdatesModal: false });
        }}
      >
        {this.state.projectNote.length >= 1 && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <label className={styles.label} style={{ fontWeight: 600 }}>
                Updates on project!
              </label>
              <div
                data-testId="modalOpenVideo"
                onClick={() => {
                  this.setState({ viewUpdatesModal: false });
                }}
              >
                <HighlightOffRoundedIcon />
              </div>
            </div>
            <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />
            <div style={{ padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  border: "1px solid #e5e7f0",
                  borderRadius: 10,
                  marginBottom: 10,
                  padding: 10,
                  background: "#FFFFFF",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      border: "1px solid rgb(0, 155, 226)",
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                      background: "rgb(0, 155, 226)",
                      color: "rgb(255, 255, 255)",
                      padding: "0px 15px 0px 17px",
                      borderRadius: "0px 9px",
                    }}
                  >
                    New
                  </span>
                  <Typography
                    className={styles.label}
                    style={{ marginTop: 10 }}
                  >
                    {this.state.projectNote}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    );
  }

  noteDialog() {
    return (
      <Dialog
        hideBackdrop
        PaperProps={{
          style: {
            background: "#FEFEFE",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
            borderRadius: 18,
            maxHeight: 370,
            width: 500,
          },
          elevation: 0,
        }}
        open={this.state.editNoteModal}
        data-testId="modalEdit"
        onClose={() => {
          this.setState({ editNoteModal: false });
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 20,
            }}
          >
            <label className={styles.label} style={{ fontWeight: 600 }}>
              Post update on project!
            </label>
            <div
              data-testId="editModalOpen"
              onClick={() => {
                this.setState({ editNoteModal: false });
              }}
            >
              <HighlightOffRoundedIcon />
            </div>
          </div>
          <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />

          <div style={{ padding: 20 }}>
            <Input
              disableUnderline
              className={styles.multiLineInput}
              style={{ borderRadius: 12, width: "100%" }}
              value={this.state.projectNote}
              data-testId="notes"
              onChange={(e: any) => {
                this.setState({ projectNote: e.target.value });
              }}
              multiline
              rows={6}
              placeholder="Enter what is updated"
            />
          </div>
          <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />
          <div style={{ padding: 20 }}>
            <Button
              onClick={() => {
                this.setState({ editNoteModal: false });
              }}
              data-testId="editModalOpen-Post"
              style={{
                backgroundColor: "#009be2",
                boxShadow: "none",
                textTransform: "capitalize",
                height: 45,
                width: 100,
                ...FAMILYFONTS.family2,
                borderRadius: 10,
                color: "white ",
              }}
            >
              Post
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  imgDialog() {
    return (
      <Dialog
        hideBackdrop
        PaperProps={{
          style: {
            background: "#FEFEFE",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
            borderRadius: 18,
            maxHeight: 370,
            width: 500,
          },
          elevation: 0,
        }}
        open={this.state.multiImageModal}
        data-testId="multiImgModal"
        onClose={() => {
          this.setState({ multiImageModal: false });
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 20,
            }}
          >
            <label className={styles.label} style={{ fontWeight: 600 }}>
              Image proofs on project!
            </label>
            <div
              data-testId="openMultiImgModal"
              onClick={() => {
                this.setState({ multiImageModal: false });
              }}
            >
              <HighlightOffRoundedIcon />
            </div>
          </div>
          <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />
          <div style={{ padding: 20 }}>
            {this.multiImagesdisplay.map((item) => (
              <div
                style={{
                  display: "flex",
                  border: "1px solid #e5e7f0",
                  borderRadius: 10,
                  marginBottom: 10,
                  padding: 10,
                  background: "#FFFFFF",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                }}
              >
                <div style={{ marginRight: 10, borderRadius: 8 }}>
                  <img src={item} style={{ height: 64, width: 64 }} />
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      marginLeft: "62%",
                      marginTop: -62,
                      border: "1px solid rgb(0, 155, 226)",
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                      background: "rgb(0, 155, 226)",
                      color: "rgb(255, 255, 255)",
                      padding: "0px 15px 0px 17px",
                      borderRadius: "0px 9px",
                    }}
                  >
                    New
                  </span>
                  <Typography
                    className={styles.label}
                    style={{ fontWeight: 600, fontSize: 16 }}
                  >
                    Image proof of project
                  </Typography>
                  <Typography
                    className={styles.updatedText}
                    style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}
                  >
                    Updated on : {moment(new Date()).format("DD/MM/YYYY")}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    );
  }

  render() {
    const { classes }: any = this.props;
    return (
      // Customizable Area Start
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <Container maxWidth="xl">
            <Card
              style={{
                //  width: 90%;
                borderRadius: 12,
                margin: "auto",
                marginTop: 30,
              }}
            >
              <div style={{ marginTop: 10 }} />
              <div className={styles.newEventTitle}>
                <label className={styles.heading} style={{ padding: 15 }}> New event</label>
              </div>
              <CardContent
                style={{
                  borderTop: "2px solid #eaeaea",
                  padding: 15,
                }}
              >
                <div className={styles.divWrapper}>
                  <label className={styles.labelHeading}>Selected type</label>
                  <br />
                  {/* @ts-ignore */}
                  <div
                    style={{
                      border: `${
                        this.state.createEventState
                          ? "1px solid  #009BE2"
                          : "1px solid lightgrey"
                      }`,
                      width: "50%",
                      borderRadius: 10,
                      borderBottom: `${
                        !this.state.createEventState
                          ? "1px solid lightgrey"
                          : "1px solid  #009BE2"
                      }`,
                      height: `${
                        !this.state.createEventState ? "54px" : "180px"
                      }`,
                    }}
                  >
                    <Select
                      isDisabled={true}
                      onMenuOpen={() => {
                        this.setState({ createEventState: true });
                      }}
                      onMenuClose={() => {
                        this.setState({ createEventState: false });
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "skyblue",
                          primary: "dodgerblue",
                        },
                      })}
                      styles={{
                        menu: (base) => {
                          return {
                            ...base,
                            paddingLeft: 20,
                            paddingRight: 20,
                            border: "none",
                            marginTop: 0,
                            borderTop: "none",
                            boxShadow: "none",
                            color: "black",
                            paddingTop: 5,
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px",
                          };
                        },
                        singleValue: (base) => {
                          return {
                            ...base,
                            color: "black",
                          };
                        },
                        dropdownIndicator: (base) => {
                          return {
                            ...base,
                            color: "lightgrey",
                          };
                        },
                        control: (base, state) => {
                          return {
                            ...base,
                            height: "54px",
                            cursor: "not-allowed",
                            backgroundColor: "white",
                            boxShadow: "none",
                            borderRadius: state.isFocused ? "10px " : "10px",
                            border: `${
                              this.state.createEventState
                                ? "1px solid  #009BE2"
                                : "1px solid lightgrey"
                            }`,
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            "&:hover": {
                              borderBottom: `${
                                this.state.createEventState
                                  ? "1px solid  #009BE2"
                                  : "1px solid lightgrey"
                              }`,
                            },
                          };
                        },
                        indicatorSeparator: (styles) => ({
                          ...styles,
                          display: "none",
                        }),
                        menuList: (base) => {
                          return {
                            ...base,
                            marginTop: 15,
                            position: "relative",
                            top: "-5px",
                            zIndex: -1,
                          };
                        },
                      }}
                      name="eventTypes"
                      data-testId="eventTypes"
                      isSearchable={false}
                      isClearable={false}
                      isRtl={false}
                      options={eventTypes}
                      defaultValue={eventTypes[1]}
                      className={styles.selectInput}
                      onChange={(e: any) => {
                        this.setState({ eventType: e.value });
                      }}
                    />
                  </div>
                </div>

                {this.nameOfEvent()}
                {this.eventDescription()}
                {this.projectOutcomeField()}
                {this.beneficiariesField()}
                {this.amountRequired()}
                {this.projectDeliveryField()}
                {this.proofOfDelivery()}
                {this.publishScope()}

                <div className={styles.divWrapper}>
                  <label className={styles.labelHeading}>Campaign launch</label>
                  <br />
                </div>
                {this.projectDate(classes)}
                {this.projectTime(classes)}
                <div className={styles.divWrapper}>
                  <label className={styles.labelHeading}>
                    Social media presence
                  </label>
                  <br />
                  {this.socialMedia1()}
                  <br />
                  {this.socialMedia2()}
                  <br />
                  {this.socialMedia3()}
                  <br />
                </div>
                {this.uploadImg()}
                {this.publishSwitch()}
                {this.footerBtns()}
                {this.state.eventUpdateError.length > 0 && (
                  <Snackbar
                    open={this.state.eventUpdateError.length > 0}
                    onClose={() => {
                      this.setState({ eventUpdateError: "" });
                    }}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <SnackbarContent
                      className={styles.snackbar}
                      message={"Server Error. Please try again later."}
                    />
                  </Snackbar>
                )}
              </CardContent>
            </Card>
            {this.videoDialog()}
            {this.noteDialog()}
            {this.imgDialog()}
            {this.viewNoteDialog()}
          </Container>
        </div>
      </MuiPickersUtilsProvider>

      // Customizable Area End
    );
  }
}

//Customizable Area Start

export default withStyles(useStyles as any)(ProjectsEventWeb as any);

//Customizable Area End
