import React from "react";
// Customizable Area Start
import AdminRegistrationController, { Props } from "./AdminRegistrationController";
//@ts-ignore
import classOuter from './NpForgotPasswordOTP.module.css'
import { FAMILYFONTS, images } from "../../../components/src/constants";
import OtpInput from 'react-otp-input';
import {
  Typography,
  Button,
  Container,
  Box,
  Link
} from "@material-ui/core";

// Customizable Area End

class NpForgotPasswordOTP extends AdminRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    // Verify button validation
    const otpLength = this.state.otp.length;
    const disabled = (otpLength === 6 && !this.state.otpError) ? false : true;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        {/* OTP logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.otpLogoMainContainer}>
            <div className={classOuter.otpLogoContainer}>
              <img src={images.emailIcon} className={classOuter.otpLogo} />
            </div>
          </div>

          {/* Forgot Password OTP Form */}
          <div>
            {/* Form Title */}
            <Box>
              <Typography
                className={classOuter.titleText}
                style={{
                  ...FAMILYFONTS.family1,
                  fontSize: 24,
                  fontWeight: 400
                }}>OTP Verification!</Typography>
            </Box>

            {/* Form Content */}
            <Box>
              <Box className={classOuter.otpContainer}>
                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 16,
                    fontWeight: 400
                  }}>
                  Please enter the verification code sent to your registered email address
                </Typography>

                {/* OTP Input Field */}
                <Box className="otpInput"
                  style={{ color: this.state.otpError ? "#E41616" : "inherit", }}
                >
                  <OtpInput
                    data-test-id="forgotPwdOtp"
                    // @ts-ignore
                    name="otpInput"
                    value={this.state.otp}
                    onChange={(e: any) => { this.setOtp(e) }}
                    numInputs={6}
                    className={this.state.otpError ? classOuter.innerOtpErrorText : classOuter.innerOtpText}
                    separator={<span />}
                    isInputNum
                  />
                  {/* OTP validation Error */}
                  {this.state.otpError && (
                    <Box className={classOuter.errorMsg} display="flex" sx={{ mt: '1rem' }}>
                      <Box className={classOuter.errorIconDiv}><img src={images.invalidOtp} alt="inValidOtp" /></Box>
                      <div className={classOuter.errors}>Please enter valid OTP</div>
                    </Box>
                  )}

                  {/* OTP expire Error */}
                  {this.state.expireOtpError && (
                    <Box className={classOuter.errorMsg} display="flex" sx={{ mt: '1rem' }}>
                      <Box className={classOuter.errorIconDiv}><img src={images.invalidOtp} alt="inValidOtp" /></Box>
                      <div className={classOuter.errors}>{this.state.expireOtpError}</div>
                    </Box>
                  )}

                </Box>
              </Box>

              {/* Resend link and OTP Verify Button */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
                className={classOuter.footerLinkContainer}
              >
                <Box onClick={this.resendOTP}>
                  <Typography className={classOuter.footerLinkText}>
                    <span
                      style={{
                        ...FAMILYFONTS.family3,
                        fontSize: 16,
                        fontWeight: 400
                      }}>
                      Didn't receive a verification link?
                    </span>
                    <Link
                      style={{
                        color: "#009be1",
                        textTransform: "initial",
                        marginLeft: "3px",
                        cursor: "pointer",
                        textDecoration: "none"
                      }}
                      data-test-id = "resendLink">
                      <span
                        style={{
                          ...FAMILYFONTS.family1,
                          fontSize: 16,
                          fontWeight: 400
                        }}>
                        Resend link
                      </span>
                    </Link>
                  </Typography>
                </Box>
                <Button
                  data-test-id={"btnVerifyOtp"}
                  variant="contained"
                  color="primary"
                  className={classOuter.footerBtn}
                  fullWidth
                  onClick={this.gotoVerify}
                  style={{
                    opacity: disabled ? "0.5" : "1",
                    cursor: disabled ? "not-allowed" : "pointer",
                    ...FAMILYFONTS.family2,
                    fontSize: 20,
                    fontWeight: 400,
                  }}
                  disabled={disabled}>
                  Verify
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }
}

export default NpForgotPasswordOTP;