import React from "react";
// Customizable Area Start
import CommunicationController, {
  Props,
} from "./CommunicationController.web";
import {ChatObject,
  MemberDataEntity} from "./NpChatController.web"
import ContentManagement from "./ContentManagement";
// @ts-ignore                                                       
import active from "../../Gamification/src/Gamification.module.css";
import { FONTS,icons } from "../../../components/src/constants";
import {
  Container,
  Box,
  Button,
  ButtonGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  InputBase,
  CircularProgress,
  Divider
} from "@material-ui/core";
// @ts-ignore
import classesOuter from './Communication.module.css'
// @ts-ignore
import module from "./ContentManagementEvent.module.css";
import CloseIcon from '@material-ui/icons/Close';
import {
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import NpChatWeb from "./NpChat.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: "8px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
    inputInput: {
      marginTop: "15",
      padding: "16 45 16 0",
      marginBottom: "10px",
      boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      fontSize: "revert",
      fontFamily: "Rene_Bieder_Milliard_Light",
      fontWeight: 700,
      width: 500,
      height: 20,
      borderRadius: 10,
      "@media (max-width: 600px)": {
        marginTop: "15",
        padding: "16 65 16 0",
        marginBottom: "10px",
        boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
        paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
        transition: theme.transitions.create("width"),
        fontSize: "revert",
        fontFamily: "Rene_Bieder_Milliard_Light",
        fontWeight: 700,
      },
      "&::placeholder": {
        color: "#000501",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Rene_Bieder_Milliard_Light",
      },
    },
    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      borderRadius: "8px",
      color: "text.primary",
      backgroundColor: "white",
      margin: "8px",
      padding: "8px",
      height: 46,
      width: 46,
    },
    image: {
      width: 128,
      height: 128,
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    alignLeftCss: {
      textAlign: "left",
      background: " #F7F7F7",
    },
    cancelIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    icon: {
      background: "rgb(59, 149, 204)",
      color: "#fff",
      borderRadius: "0px 10px 0px 10px",
      width: "50px",
      height: "35px",
      padding: "7px"
    }
  });
 // Customizable Area End

class CommunicationContent extends CommunicationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
renderChatSection(){
    return <div>
      {this.state.selectedTab===3?
      this.renderEventChats():this.renderEntityChats()}
    </div>
}
renderNoData(){
  return <div style={{
    justifyContent: "center",
    alignItems: "center",  display: "flex",
    height: "70vh",
    
  }}>
  No data</div>
}
loader(){
  return <div
  style={{
    height: "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <CircularProgress style={{ color: "#009be2" }} />
</div>
}
renderEventChats(){
   
      if( this.state.selectedTab===3 && this.state.chatsList.length == 0 && !this.state.loading){
        return this.renderNoData();
       }
      else if( this.state.loading){
        return this.loader() 
      }
      else{
        const filterData = this.state.chatsList.filter((item: { attributes: { event_name: string | null }}) => !!(item.attributes.event_name?.toLowerCase().includes(this.state.searchTerm?.toLowerCase())));
       return <TableContainer component={Paper} 
         style={{overflowY: 'scroll',height:'60vh'}}>
         <Table style={{ minWidth: 650 }}
           aria-label="simple table" 
           id="chatList">
      <TableHead className={classesOuter.tableHead}>
        <TableRow><TableCell 
          className={`${module.tbody} ${module.ellipsis}`}
          style={{ fontWeight: 200 }}
          scope="row"
          component="th"> EVENT NAME </TableCell>
          <TableCell className={module.tbody} align="center">TYPE</TableCell>
          <TableCell className={module.tbody} align="center">PARTICIPANTS</TableCell>
          <TableCell className={module.tbody} align="center"></TableCell>
          <TableCell className={module.tbody} align="center"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody  className={classesOuter.tableCell}>
          {filterData.length > 0 ? filterData.map((item: { attributes: { event_name: string | null | undefined; event_type: string | null | undefined; member_data: MemberDataEntity[] }}, index: number) => (
            <>
              <TableRow style={{ border: 0, borderBottom: "none" }} data-testid={`chat-row-${index}`} key={index}>
                <TableCell className={`${module.tbody} ${module.ellipsis}`}>{item.attributes.event_name}</TableCell>
                <TableCell className={module.tbody} align="center" style={{textTransform: 'capitalize'}}>{item.attributes.event_type}</TableCell>
                <TableCell  className={module.tbody} align="center">{item.attributes.member_data.length ?? 0}</TableCell>
                <TableCell className={module.tbody} align="center" >
                  <Button data-testid="messageBtnEvents"
                          variant="outlined"
                          onClick={() => this.handleOpen(item)}
                          color="primary"
                          style={{width: 100,
                              border: "none",
                              textTransform: "capitalize",
                              boxShadow: "none",
                              color: "white ",
                              backgroundColor: " #009be2",
                              borderRadius: 10,
                              fontFamily: "Rene_Bieder_Milliard_Medium",
                              marginLeft: 13,
                              marginRight: 13,}}>Message</Button></TableCell>
              </TableRow></>))
              : 
              <TableRow>
                <TableCell colSpan={4} align="center" style={{ cursor: "default", paddingTop: "100px" }}>No Data</TableCell>
              </TableRow>
              }
      </TableBody></Table>
  </TableContainer>}
}
renderButtonGroup(){
  return(
    <ButtonGroup
        size="large"
        aria-label="large button group"
        fullWidth
        className="activityLogBtns">
        <Button
          data-testid="eventTab"
          onClick={() => {
            this.setState({ selectedTab: 1 },()=>{
              this.getEntityChatList();
            });
          }}
          className={`${this.state.selectedTab === 1 ? "activeEventTabs" : "inactiveTab"} ${
            active.activityLogBtn
          }`}>
        <Typography style={{ ...FONTS.semiBoldFont, fontSize: "20px" }}>Chats</Typography>
        </Button>
          <Button
           data-testid="usersTab"
          onClick={() => {
            this.setState({ selectedTab: 3},()=>{
              this.getChatList();
            });
          }}
          style={{borderTopRightRadius:this.state.selectedTab === 3 ? 16:0,
            borderBottomLeftRadius:this.state.selectedTab === 3 ? 16:0}}
          className={`${this.state.selectedTab === 3 ? "activeEventTabs" : "inactiveTab"} ${
            active.activityLogBtn
          }`}>
          <Typography style={{ ...FONTS.semiBoldFont, fontSize: "20px" }}>Events</Typography>
        </Button>
      </ButtonGroup>
  );
}
itemType(obj:ChatObject){
  let type = "";
  const acc_id=localStorage.getItem("non_profit_acc_id")
  obj?.attributes?.member_data?.forEach((member:MemberDataEntity) => {
  const memberid= member?.id.toString()
  if (memberid !== acc_id && (obj.attributes.convo_type === "personal" || (member?.type === 'business_entity' || member?.type === 'user_entity'))) {
    type = member.type; 
  }
});
return type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
} 
itemOrg(obj:ChatObject){
  let orgName = "-";
  const acc_id=localStorage.getItem("non_profit_acc_id")
  obj?.attributes?.member_data?.forEach((member:MemberDataEntity) => {
  const memberid= member?.id.toString()
  if (memberid!==acc_id && member?.type==='non_profit_entity' && obj.attributes.convo_type === "personal") {
   orgName=member?.full_name;
  } else if(memberid!==acc_id && member?.type==='business_entity' && obj.attributes.convo_type){
    orgName=member?.business_name;
  }
});
return orgName
} 
itemName(obj:any){
  let fullName = "";
  const acc_id=localStorage.getItem("non_profit_acc_id")?.toString()
   if(obj?.attributes?.first_user_image?.id.toString()!==acc_id){
    fullName=obj?.attributes?.first_user_image?.name
   }
   else{
    fullName=obj?.attributes?.second_user_image?.name
   }
return fullName;
}
renderEntityChats(){
    if(this.state.selectedTab===1 && this.state.memberList.length == 0 && !this.state.loading){
    return this.renderNoData();
       }
    else if(this.state.loading ) {
     return this.loader() ;
    }
    else {
      const filterData = this.state.memberList.filter((item: {}) => !!(this.itemName(item)?.toLowerCase().includes(this.state.searchTerm?.toLowerCase())));
    return <TableContainer component={Paper} style={{overflowY: 'scroll',height: "60vh"}}>
  <Table style={{ minWidth: 650 }} aria-label="simple table" id="chatList">
    <TableHead className={classesOuter.tableHead} 
    >
      <TableRow>
        <TableCell 
        style={{ fontWeight: 200 }}
        className={`${module.tbody} ${module.ellipsis}`}
        component="th"
        scope="row"
        >
          NAME
        </TableCell>
        <TableCell className={module.tbody} align="left">ORGANISATION</TableCell>
        <TableCell className={module.tbody} align="left">TYPE</TableCell>
        <TableCell className={module.tbody} align="left"></TableCell>
        <TableCell className={module.tbody} align="left"></TableCell>
      </TableRow>
    </TableHead>
    <TableBody className={classesOuter.tableCell}>        
               {filterData.length > 0 ? filterData.map((item: ChatObject) => (
                (item.attributes.first_user_image?.id && item.attributes.second_user_image?.id && item.attributes.first_user_image.id !== item.attributes.second_user_image.id) && <TableRow style={{ border: 0, borderBottom: "none" }} key={item.id}>
                <TableCell className={`${module.tbody} ${module.ellipsis}`}>{this.itemName(item)}</TableCell>
                <TableCell className={module.tbody} align="left" style={{textTransform: 'capitalize',marginLeft:10}}>{this.itemOrg(item)}</TableCell>
                <TableCell  className={module.tbody} align="left">{this.itemType(item)}</TableCell>
                <TableCell className={module.tbody} align="left" >
                  <Button
                            variant="outlined"
                            data-testid="messageBtn"
                            color="primary"
                            onClick={() => this.handleOpen(item)}
                            style={{width: 100,
                              boxShadow: "none",
                              textTransform: "capitalize",
                              backgroundColor: " #009be2",
                              color: "white ",
                              fontFamily: "Rene_Bieder_Milliard_Medium",
                              borderRadius: 10,
                              marginRight: 13,
                              marginLeft: 13,
                              border: "none"}}>
                            Message
                        </Button></TableCell>

              </TableRow> 
          ))
          :
          <TableRow>
            <TableCell colSpan={4} align="center" style={{ cursor: "default", paddingTop: "100px" }}>No Data</TableCell>
          </TableRow>
        }
      </TableBody>
  </Table>
</TableContainer>}
}
  render() {
    // Customizable Area Start
    const { classes }: any = this.props;

    // Customizable Area End
    return (
      // Customizable Area Start
      // @ts-ignore
      <ContentManagement navigation={this.props.navigation} id={""} showBackButton={undefined} title={undefined} showBottomBar={false} showDrawerHeader={false} mobileOpen={false} open={false} classes={undefined} history={undefined} titleColor={undefined} showLine={undefined}>
        <Container maxWidth="lg">
          <Box className={classesOuter.mainDiv}>
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 40,
                width:'100%'
              }}
            >
              
                {this.renderButtonGroup()}
                </div>
                <Box className={classesOuter.headingDiv}>
                <Box className={classesOuter.heading}>
                <Typography variant="h3">{this.state.selectedTab=== 1?'Chats':'Events'}</Typography>
              </Box>
            <Box className={classesOuter.headingDiv}
            
            >
              <Box  >
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <img className={classes._tabs_logo} src={icons.searchSVG} />
                  </div>
                  <InputBase
                    data-testid="searchBar_test"
                    name="search_Bar"
                    placeholder="Search..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={this.searchGroup}
                  />
                </div>
              </Box>
              </Box>
            </Box>
            <Divider style={{ backgroundColor: "#eaeaea" }} />
            <Box>
            
                {this.renderChatSection()}
            </Box>
          </Box>
          {this.state.openModal ? 
          <div className={classesOuter.modalBox}>
            <div className={classes.cancelIcon}>
              <CloseIcon className={classes.icon} 
              data-testid="closeBtnTest"
              onClick={this.handleClose}
              />
            </div>
            <NpChatWeb
          
            client={this.chatClient}
            chatUserInformation={this.state.currentChat}
            clearChat={this.clearChat}/>
          </div>
          : null }
          
        </Container>
      </ContentManagement>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles as any, { withTheme: true })(
  CommunicationContent as any
);
export { CommunicationContent };
// Customizable Area End
