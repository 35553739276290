 {/* Customizable Area Start */}
// reducer.js

import {
    FETCH_CURRENT_LOCATION_REQUEST,
    FETCH_CURRENT_LOCATION_SUCCESS,
    FETCH_CURRENT_LOCATION_FAILURE,
    SET_CURRENT_LOCATION__LATITUDE_LONGITUDE
  } from './action';
  
  const initialState = {
    latitude: '',
    longitude: '',
    postcode: '',
    location: '',
    address: {
      doorName: '', // Set default value to an empty string
      area: '',
      city: '',
      country: '',
      postcode: ''
    },
    loading: false,
    error: null
  };
  
  const reducer = (state = initialState, action: { type: any; payload: { lat: any, lng: any, latitude: any; longitude: any; address: any; post_code: any; location: any; }; }) => {
    switch (action.type) {
      case FETCH_CURRENT_LOCATION_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case FETCH_CURRENT_LOCATION_SUCCESS:
        return {
          ...state,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
          address: {
            ...state.address, // Preserve existing address values
            ...action.payload.address // Update address with new values
          },
          loading: false,
          error: null
        };
      case FETCH_CURRENT_LOCATION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case SET_CURRENT_LOCATION__LATITUDE_LONGITUDE:
        return {
          ...state,
          loading: false,
          error: null,
          latitude: action.payload.lat,
          longitude: action.payload.lng,
          postcode: action.payload.post_code,
          location: localStorage.getItem("current_location")
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  

   {/* Customizable Area End */}
  