import React from "react";

// Customizable Area Start


import PropTypes from 'prop-types';


// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import AdminRegistrationController, {
  Props,
} from "./AdminRegistrationController";


import { NPSignupForm, } from "./NPSignupForm";
import { NPSignupFormLayout } from './NPSignupLayout';

// Customizable Area Start
// Customizable Area End

class AccountCreation extends AdminRegistrationController {
  static propTypes: { classes: PropTypes.Validator<object>; theme: PropTypes.Validator<object>; };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start



  // Customizable Area End

  render() {
    // Customizable Area Start

    // Merge Engine - render - Start
    return (
      <NPSignupFormLayout>
        <NPSignupForm />
      </NPSignupFormLayout>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

AccountCreation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


export default AccountCreation;

