import React from "react";
import {
  FormControlLabel,
  FormGroup,
  Button,
  Checkbox,
  Typography,
  Box,
} from "@material-ui/core";
import "../assets/css/Settings5.css";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import Settings5Controller from "./Settings5Controller";
import CustomSuccessModel from "../../../components/src/custom/CustomSuccessModel";
export default class TermsCondns extends Settings5Controller {
  render() {
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    return (
      <div>
        <div>
          <Box className="mob-header" marginTop='-24%' marginLeft='-5%'>
            <MobileHeader title="Terms & Conditions" navigation={this.props.navigation} />
          </Box>
          <Typography data-testId='termandcond' style={{ whiteSpace: 'pre-wrap',
          fontFamily:"Rene_Bieder_Milliard_Book",
          fontSize:'0.8rem',
          textAlign: 'justify',
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop:'10px'}}>{this.state.termsandconditiondata?.attributes?.description}</Typography>
        </div>
        <CustomSuccessModel openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose} msgTitle='Thank you' msg="Accepted terms and conditions successfully" />
      </div>
    );
  }
}
