import React from "react";
// Customizable Area Start
import {
  // Customizable Area Start
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  InputBase,
  InputAdornment,
  TextField,
  Typography,
  Box,
  Checkbox,
  Button,
  CircularProgress,
  Avatar
  // Customizable Area End
} from "@material-ui/core";
import {
  images,
  icons,
} from "../../../components/src/constants";
import { TwillioMessage,MemberDataEntity } from "../../ContentManagement/src/NpChatController.web";
import PropTypes from "prop-types";
// @ts-ignore
import classesOuter from "./BusUserChat.module.css";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import BusUserChatController, {
  Props,
} from "./BusUserChatController.web";
import EmojiPicker from "emoji-picker-react";
import ImageLoader from '../../../components/src/custom/ImageLoader';
// Customizable Area End
const styles = (theme: Theme) =>
createStyles({

  tabsIndicator: {
    backgroundColor: "#1890ff",
  },
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  root: { flexGrow: 1, },
  headBG: { backgroundColor: "#e0e0e0", },

  messageArea: { maxHeight: "290px",
    overflowY: "auto",
  },
  alignLeftCss: {
    width:"fit-content",
    teatAlign: "left",
    backgroundColor:"red",
   
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  tabRoot: {
    textTransform: "initial",
    // minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$tabSelected": {
      // color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  alignRightCss: {textAlign: "right",},

  messageReceived: { width: '55%',},
  chatFont: {
fontFamily: 'Rene_Bieder_Milliard_Book, serif',
color: '#000000c2',fontSize: '16px',

  },sendMessageLink: { color: '#fff' },
  receivedMsgLink: { color: '#000000c2' },
  cancelIcon: {display: 'flex',
    justifyContent: 'flex-end',cursor: 'pointer'
  },

  icon: {
    width: "50px",
    background: "rgb(59, 149, 204)",
    color: "#fff",
     height: "35px",
    borderRadius: "0px 10px 0px 10px",
    padding: "7px"
  },
  messageSend: {
    width: '55%',
    marginLeft: '45%'
  },

  clearChatDiv: {
    fontSize: '16px',
    borderRadius: '8px',
    border: '1px solid #efefef',
    padding: '10px 15px',
    backgroundColor: '#fff',
    position: 'absolute',
    right: '20px', },
  clearChatModal: {
    position: "absolute",
    border: '1px solid #bbbbbb94',
    padding: '25px',
    borderRadius: '15px',
    // @ts-ignore
    zIndex: '3',
    backgroundColor: '#fff',
    left: '20%',
    top: '27%'
  },  clearChatHead: {  fontSize: '17px',
  color: '#000', fontFamily: 'Rene_Bieder_Milliard_Book, sans-serif',
  },
  clearChatSubHead: {
    color: 'grey',
    fontSize: '15px',
    marginBottom: '10px',
    lineHeight: '25px',},
  checkBox: {
    gap: '20px',
    display: 'flex',
    marginBottom: '10px' },
  darkButton: {
    display: 'flex',
    justifyContent: 'space-between' },
  imageMediaShare: {
    height: '100%',
    objectFit: 'cover',
    width: '100%', },
  mediaLoader: {
    display: 'flex',
    height: '17vh',
    justifyContent: 'center',
    width: 'auto',
    alignItems: 'center' },

    pinModal: {
      width: '22%',
      padding: '13px',
      marginLeft: '77%',
      border: '1px solid #efefef',
      borderRadius: '12px'},
  pinnedMessage: {
    width: '60%',
    marginLeft: '40%',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '14px',
    gap: '10px',
    color: '#009BE2',},

  senderName: {
    fontWeight: 600,
    fontFamily: 'Rene_Bieder_Milliard_Medium, sans-serif',
    textTransform: 'capitalize',
    color: '#019BE1', },
  messageContainer: {
    textAlign: 'start',
    borderBottomRightRadius: '78px',
    border: '1px solid transparent',
    position: 'relative', },          
  tickMarkContainer: {
    right: '-5px',
    position: 'absolute',
    bottom: '5px',
  },
  receiverMedia: {
    
    paddingTop: '10px',
    position: 'relative',
    paddingBottom: '27px',
  },
  mediaImageSharingReceived: {
    height: '200px'
  },
  mediaImageSharingSend: {
    height: '200px'
  },

});

// Customizable Area Start

// Customizable Area End

class BusUserChat extends BusUserChatController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>; 
    theme: PropTypes.Validator<object>;
  };
  constructor(props: Props) { super(props);
    // Customizable Area Start
    // Customizable Area End  
   }

  getSentMessage = (showDropdown: boolean, pinned: boolean, message: any, classes: any, index: number, tickmark: any) => (
    <>
      {showDropdown ?
        <div className={classes.pinModal}>
          <Typography 
          onClick={()=> pinned ? this.unpinMessage(message) : this.pinMessage(message)}
          style={{fontFamily: 'Rene_Bieder_Milliard_Medium',fontSize: '12px',paddingBottom: '10px'}}
          data-testid='pinMessage'
          >{pinned ? 'Unpin message' : 'Pin message'}
          </Typography>
            <Typography data-testid="deleteMsg" onClick={() => this.deleteMessage(message)} style={{fontFamily: 'Rene_Bieder_Milliard_Medium',fontSize: '12px'}}>Delete message</Typography>
        </div>:null}
      <ListItem key={index} className={classes.messageSend} 
      data-testid='pinDropdown' 
      onClick={() => this.setState({dropDownPin: !this.state.dropDownPin, currentMessageSelected: message.sid})}>
        <Grid container className={classesOuter.alignRightCss}>
          <Grid item xs={12}>
          {message.type == 'media' ? (
            <div className={classes.mediaImageSharingSend}>
            <ImageLoader
              classes={classes.imageMediaShare}
              loaderStyle={{
                  marginTop:'15%',
                  textAlign:"center",
                  marginLeft:'30%',
              }}
              imageUrl={message.media}
              alt="media"
            />
            </div>
          ) : (
            <ListItemText 
              primary={
                <div className={classes.messageContainer}>
                  <small style={{color: '#fff'}} className={classes.chatFont}>
                    {showDropdown ? (
                        <img
                          style={{position: 'absolute',top: '-13px',right: '-4px',height: '16px', width: '16px'}} src={icons.view_down}  alt="arrowDown"/>
                    ) : null
                    }
                    <span className="messagesContent">{this.handleMessageSendLink(message, true, classes)}</span>
                  </small>
                  <div className={classes.tickMarkContainer}>
                    <img src={tickmark} alt='doubleTick' 
                    style={{height: '19px', width: '25px'}}/>
                  </div>
                </div>
              }
            ></ListItemText>
          )}
          </Grid>
          <Grid item xs={12}>
            <ListItemText secondary={
              <small style={{color: '#fff', 
              fontSize: '14px'}}>
                {new Date(message.timestamp.toString()).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}
              </small>
            } style={{textAlign: 'end'}}></ListItemText>
          </Grid>
        </Grid>
      </ListItem>
      {pinned ? (
        <Box className={classes.pinnedMessage}>
          <Typography style={{fontSize: '12px',
          fontFamily: 'Rene_Bieder_Milliard_Medium',}}>
            Message pinned by you 
            </Typography>
          <img 
          src={icons.pinIcon} 
          alt="pinIcon" 
          style={{width: '16px', height: '16px'}} /> 
        </Box>
      ) : null}
    </>
  );


  isBroadcastDisabled = () => {
    const activePlanName = localStorage.getItem("activePlanName")
    const isExpired = localStorage.getItem("expired");
    if(activePlanName === 'none'||activePlanName==="Plan 3 - Non paying - Presence only"||isExpired ==='true') {
      return true;
    } else {
      return false;
    }
 }
  handleChatUserName = (message:TwillioMessage) => {
   
   return this.state.chatInformation.attributes.friendly_name === "eventChatRoom"
  ? this.state.chatInformation?.attributes.member_data
      .filter(
        (item: MemberDataEntity) =>
         item.id == message.customAttributes.senderId &&
          message.customAttributes.senderType.toLowerCase() ===
            item.type.toLowerCase()
      )[0]?.full_name|| this.getNonProfitEntityFullName()
    :this.props.chatUserInformation?.attributes?.member_data[1]?.full_name;                          
  }

  getNonProfitEntityFullName = () => {
    const nonProfitEntityMember = this.state.chatInformation?.attributes.member_data.find((item: MemberDataEntity) =>
        item.type.toLowerCase() === "non_profit_entity"
    );
    
    return nonProfitEntityMember ? nonProfitEntityMember.full_name : "No Non-profit Entity Found";
}
  
  renderMsgs(msgsData: any, classes: any, classesOuter: any) {
    return <>{
      msgsData.map((message: any, index: number) => {
        let myMsgs = false;
        if (message.customAttributes && message.customAttributes.senderId == this.state.userId &&
        message.customAttributes.senderType == 'business_entity' ) {
            myMsgs = true;}
        let pinned = 
        message.customAttributes.pinned;
        let showDropdown = false;
        if (this.state.dropDownPin && this.state.currentMessageSelected == message.sid) {
          showDropdown = true; }
        let tickmark = icons.singleTick;
        if (this.state.lastReadMessageIndex && this.state.lastReadMessageIndex >= message.index) {
          tickmark = icons.GreenTick } else if (this.state.lastDeliveredMessageIndex && this.state.lastDeliveredMessageIndex >= message.index) {
          tickmark = icons.doubleTick; }
        return (
          <>
            {myMsgs ? (
              this.getSentMessage(showDropdown, pinned, message, classes, index, tickmark)
            ) : (
              <>
              <ListItem key={index} className={classes.messageReceived}>
                <Grid container className={classesOuter.alignLeftCss}>
                  {message.type == 'media' ? (
                    <>
                      <Grid item xs={12} 
                      style={{paddingTop: '10px'}}>
                      <span style={{fontSize: '1rem'}}>
                        <small className={classes.senderName} 
                        style={{fontFamily: 'Rene_Bieder_Milliard_Medium',textTransform: 'capitalize'}}

                        data-testid="userNameTest">
                         {this.handleChatUserName(message)}
                        </small>
                      </span>
                      <div className={classes.mediaImageSharingReceived}>
                      <ImageLoader
                        loaderStyle={{
                            marginTop:'15%',
                            marginLeft:'30%',
                            textAlign:"center",
                        }}
                        classes={`${classes.imageMediaShare} ${classes.receiverMedia}`}
                        alt="media"
                        imageUrl={message.media}
                      />
                      </div>
                      <span style={{fontSize: '0.875rem',color: 'rgba(0, 0, 0, 0.54)',position: 'absolute',bottom: '15px',right: '27px'}}>
                        {new Date(message.timestamp.toString()).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}
                      </span></Grid>
                      </>
                    ) : (
                      <>
                      <Grid item xs={12}>
                        <ListItemText 
                        primary={
                            <small className={classes.senderName} 
                            style={{fontFamily: 'Rene_Bieder_Milliard_Medium',textTransform: 'capitalize'}}
                            >
                            {this.handleChatUserName(message)}
                            </small>
                        } 
                        secondary={
                            <small className={classes.chatFont}>
                              <span className="messagesContent">{this.handleMessageSendLink(message, false, classes)}</span>
                            </small>
                          }
                        ></ListItemText>
                        <ListItemText secondary={new Date(message.timestamp.toString()).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}
                         style={{textAlign: 'end'}}></ListItemText>
                      </Grid>
                      </>
                    )}
                  </Grid>
                </ListItem>
                 {pinned ? (
                  <Box style={{marginLeft:20,justifyContent: 'flex-start',}} className={classes.pinnedMessage}>
                   <Typography style={{ fontSize: '12px', fontFamily: 'Rene_Bieder_Milliard_Medium', }}>Message pinned by {message.customAttributes.senderType == 'user_entity'?'User':'Non-Profit'} </Typography>
                   <img src={icons.pinIcon} alt="pinIcon" style={{ width: '16px', height: '16px' }} />
                  </Box>
                 ) : null}
                </>
              )}
            </>
          )
        })
      }
      </>
  }

  makeLinksClickable = (messageText: any, sendMessageLink: boolean, classes: any) => {
    const urlRegex = /(https?:\/\/[^\s]+)/;    

    const messageParts = messageText.split(urlRegex);  

    const formattedText = messageParts.map((part: any, index: any) => {  

      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            target="_blank"
            href={part}
            className={sendMessageLink ? classes.sendMessageLink : classes.receivedMsgLink}
            style={{textDecoration: 'underline'}}
          > {part} </a>
        );
      } 
      else { return part; }
    });

    return formattedText;
  };

  handleMessageSendLink(message: any, sendMessageLink: boolean, classes: any) {
    return (
        <div>{this.makeLinksClickable(message.body, sendMessageLink, classes)} </div>
    )
  }
  handleImage(friendlyName:string){
    return friendlyName==='eventChatRoom'? this.state.chatInformation.attributes?.image?.url:
    this.state.chatInformation.attributes.second_user_image?.url?.url ?? "" ;
  }
  handleUserName(friendlyName:string){
    return friendlyName==='eventChatRoom'?this.state.chatInformation.attributes?.event_name:
    this.props.chatUserInformation?.attributes?.member_data[1]?.full_name ;
  }
  renderEmojiPicker(classesOuter: any){
    return (
    <>
        {this.state.emojiPicker ?
        <div className={classesOuter.emojiPicker} data-testid="emojiPickerTest">
            <EmojiPicker 
                width="100%"  
                searchDisabled  
                skinTonesDisabled
                // @ts-ignore
                emojiStyle="google"
                onEmojiClick={this.handleEmojiSend}
            /> 
        </div>:null}
    </>
    ) }
  // Customizable Area End
  render() {
    const { classes, theme }: any = this.props;
    const friendlyName = this.state.chatInformation.attributes.friendly_name;
    // @ts-ignore
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div>
          {!this.state.searchBar ? 
          <div className={classesOuter.modalHeaderContainer}>
            <div className={classesOuter.modalPicTitle}>
              <div> 
                <Avatar 
                  src={this.handleImage(friendlyName)} 
                  alt="Event Pic" className={classesOuter.modalDp} 
                  >
                {this.state.chatInformation.attributes.second_user_image?.name.charAt(0).toUpperCase()}
                </Avatar>
              </div>
              <div className={classesOuter.modalEventTitle}>{this.handleUserName(friendlyName)}</div>
            </div>
            <div className={classesOuter.modalSearchSettingIcon}>
              <div className={classesOuter.modalSearchContainer} data-testid="SearchTest" onClick={() => this.setState({searchBar: true})}>
                <IconButton aria-label="search" color="inherit">
                  <img className={classesOuter._search_logo} src={icons.search} /> </IconButton>
              </div>
              <div>
                <IconButton aria-label="display more actions" edge="end" color="inherit">
                  <img data-testid="clearChatTest" className={classesOuter._search_logo} src={icons.chatMoreIcon} onClick={() => this.setState({clearChat: !this.state.clearChat})}/>
                </IconButton>
                {this.state.clearChat ?
                  <Box className={classes.clearChatDiv} data-testid="clearChatDivTest" onClick={() => this.setState({deleteModal: true, clearChat: !this.state.clearChat})}>
                    <Typography>
                      Clear chat
                      </Typography>
                  </Box> : 
                  null
                }
                {this.state.deleteModal === true ? 
                  <Box className={classes.clearChatModal}>
                    <Typography className={classes.clearChatHead}>Clear chat</Typography>
                    <Typography className={classes.clearChatSubHead}>( Messages will be delete for permanently ).</Typography>
                    <Box className={classes.checkBox}>
                      <Checkbox
                        data-testid='clearchat24hour'
                        checked={this.state.clearChatOption == 1}
                        style={{margin: '0px',padding: '0px'}}
                        value={1}
                        onClick={this.onChangeClearChat}

                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4"  height="20" stroke='#019BE1' width="20"fill='#019BE1' stroke-width="1" />
                            <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                          </svg>
                        }
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24" height="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" stroke="#aaa"  height="20"  fill="transparent" stroke-width="0.8" />
                          </svg>}
                          disableRipple
                        color="primary" />
                      <Typography style={{fontFamily: 'Rene_Bieder_Milliard_Book'}}>Over 24 hours
                      </Typography>
                    </Box>
                    <Box className={classes.checkBox}>
                      <Checkbox
                        value={2}
                        checked={this.state.clearChatOption == 2}
                        style={{margin: '0px',padding: '0px'}}
                        onClick={this.onChangeClearChat}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                          </svg>
                        }
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke='#019BE1' fill='#019BE1' stroke-width="1" />
                            <path  d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }}fill="white" />
                          </svg>
                        }
                        color="primary"
                        disableRipple />
                      <Typography style={{fontFamily: 'Rene_Bieder_Milliard_Book'}}>Over 1 Week
                      </Typography>
                    </Box>
                    <Box className={classes.checkBox}>
                      <Checkbox
                        value={3}
                        style={{margin: '0px',padding: '0px'}}
                        onClick={this.onChangeClearChat}
                        checked={this.state.clearChatOption == 3}
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4"  height="20" stroke='#019BE1' width="20" fill='#019BE1' stroke-width="1" />
                            <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                          </svg>
                        }
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                          </svg>
                        }
                        color="primary"
                        disableRipple
                      />
                      <Typography style={{fontFamily: 'Rene_Bieder_Milliard_Book'}}>Over 1 month</Typography>
                    </Box>
                    <Box className={classes.darkButton}>
                      <Button
                      variant="outlined"
                      style={{
                        marginTop: '10px',
                          width: '48%',
                          boxShadow: "none",
                          border: '1px solid #009be2',
                          textTransform: "capitalize",
                          color: "#009be2",
                          fontFamily: "Rene_Bieder_Milliard_Medium",
                          borderRadius: 10,
                      }}
                      color="primary"
                      data-testid="deletedTest"
                      onClick={() => this.setState({deleteModal: !this.state.deleteModal})}
                      > Cancel </Button>
                      <Button
                      variant="outlined"
                      style={{
                          width: '48%',
                          boxShadow: "none",
                          textTransform: "none",
                          backgroundColor: " #009be2",
                          color: "white ",
                          fontFamily: "Rene_Bieder_Milliard_Medium",
                          borderRadius: 10,
                          border: "none",
                          marginTop: '10px'
                      }}
                      color="primary"
                      data-testid="modalTest"
                      onClick={this.clearChat}
                      disabled={this.state.clearChatOption < 1}
                      >Clear chat </Button>
                    </Box>
                  </Box>: 
                null
                }
              </div>
            </div>
          </div> :
          <div className={classesOuter.SearchBarDiv}>
            <IconButton aria-label="search" color="inherit">
              <img className={classesOuter._search_logo} src={icons.search} /> </IconButton>
            <TextField placeholder="Search..." 
            data-testid='messageSearch' 
            onKeyDown={() => this.onSearchNext()} 
            value={this.state.searchTerm} 
            onChange={this.onSearchChange} 
            className={classesOuter.textSearch}/>
            <Typography className={classesOuter.cancelButton} 
            onClick={() => this.setState({searchBar: false, searchTerm: ''})}
            data-testid='cancelTest' 
            >Cancel</Typography>
          </div>
          }
          {/* Modal body and footer */}
          <div className={classesOuter.modalContent}>
            {/* Modal Chat Body */}
            <div className={classesOuter.modalBodyContainer} >
  {this.state.loading ? (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '50%',
        display: 'flex',
      }}
    >
      <CircularProgress style={{ color: '#009be2' }} />
    </div>
  ) : (
    <List className={classes.messageArea} id='messagesListing'>
      {Object.keys(this.state.messages).length > 0 ? (
        <>
          {Object.keys(this.state.messages).map((dateKey) => (
            <>
              <p className={classesOuter.dateIssue}>{dateKey}</p>
              {this.renderMsgs(this.state.messages[dateKey].data, classes, classesOuter)}
            </>
          ))}
        </>
      ) : (
        <p className={classesOuter.noMessagesText} style={{color:'grey', textAlign:'center'}}>Get started by typing your message at the bottom.</p>
      )}

      {this.state.showMediaLoading ? (
        <ListItem className={classes.messageSend}>
          <Grid container className={classesOuter.alignRightCss}>
            <Grid item xs={12}>
              <div className={classes.mediaLoader}>
                <CircularProgress />
              </div>
            </Grid>
          </Grid>
        </ListItem>
      ) : null}
    </List>
  )}
</div>

            {/* Modal Footer */}
            <div className={classesOuter.modalFooterContainer} >
              <div className={classesOuter.modalFooterInput}>
                <InputBase
                  data-test-id="chatInput"
                  fullWidth={true}
                  value={this.state.messageText}
                  onKeyDown={this.keyUp}
                  disabled={this.isBroadcastDisabled()}
                  placeholder={"Type something.... (Max 150 characters)"}
                  onChange={this.typingMessage}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        onClick={this.handleEmojiPicker}
                        data-test-id="btnEmoji"
                        aria-label="emoji button"
                        edge="end"
                      >
                        <img
                          style={{width: '25px'}}
                          src={icons.chatEmojiIcon}
                          alt="emoji icon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        data-test-id="btnGallery"
                        aria-label="gallery button"
                        onClick={this.importData}
                      >
                        <img
                          src={icons.chatGalleryIcon}
                          style={{width: '25px'}}
                          alt="gallery Icon"
                          
                        />
                      </IconButton>
                    </InputAdornment>
                  } />
              </div>
              <div className={classesOuter.modalSendContainer}>
                <IconButton
                  onClick={this.sendMessage}
                  data-test-id="btnSend"
                  style={{padding: '10px'}}
                  disabled={this.isBroadcastDisabled()}
                  aria-label="send button"
                >
                  <img
                    style={{width: '25px'}}                    
                    src={icons.sendIcon}
                    alt="sendIcon"
                  />
                </IconButton>
              </div>
            </div>
            {this.renderEmojiPicker(classesOuter)}
          </div>
        </div>
      </ThemeProvider>
      // Customizable Area End
    ); } }

// Customizable Area Start

BusUserChat.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Customizable Area End

export default withStyles(styles as any, { withTheme: true })(BusUserChat as any);
export {BusUserChat}
// Customizable Area End