import React from "react";
import {
  // Customizable Area Start
  TableCell,
  TableHead,
  Box,
  Typography,
  Container,
  IconButton,
  Toolbar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Input,
  Radio,
  FormGroup,
  Checkbox,
  Avatar,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { FONTS, icons } from "../../../components/src/constants";
import ContentManagement from "../../ContentManagement/src/ContentManagement.web";
import { styled } from "@material-ui/styles";
import moment from "moment";
import SurveyUserAnswersController, { Questions, OptionsInt, ParticipantDetails, ColumnInt, RangeAttributes } from "./SurveyUserAnswersController.web";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import BusDashbaordWeb from "../../ServiceSpecificSettingsAdmin/src/BusDashboard.web";
// Customizable Area End

// Customizable Area Start
const columns = [
  { id: "name", label: "Name", minWidth: "35%" },
  { id: "date", label: "Date of Completion", minWidth: "35%" },
  { id: "action", label: "", minWidth: "30%" },
];
// Customizable Area End

class SurveyUserAnswers extends SurveyUserAnswersController {
  // Customizable Area Start
  renderHeader = () => {
    const {
      survey_details,
      userName,
      completionDate,
      openResultPage,
      userImage,
    } = this.state;
    return (
      <Toolbar className="toolBar">
        <div className={`backIconWeb`}>
          <IconButton
            aria-label="Open drawer"
            color={"inherit"}
            data-test-id="backIcon"
            onClick={() =>
                 this.handleBackRedirection()
            }
          >
            <img src={icons.back} alt={"backIcon"} />
          </IconButton>
          {openResultPage ? (
            <div className="nameCell">
              {this.getUserAvatar()}
              <div className="name">
                <p className="subHeader">Name</p>
                {userName}
              </div>
              <div className="name">
                <p className="subHeader">Date</p>
                {completionDate}
              </div>
            </div>
          ) : (
            <Typography variant="h6" className={"activityDetails"} noWrap>
              {survey_details?.attributes?.title}
            </Typography>
          )}
        </div>
      </Toolbar>
    );
  };

  getUserImage = (row: ParticipantDetails) => {
    if(row.image?.url) {
      return <img src={row.image?.url} alt={row.name} />
    } else {
      return <Avatar>
        <Box fontSize="14px">{row.name.charAt(0).toUpperCase()}</Box>
      </Avatar>
    }
  }

  getUserAvatar = () => {
    if(this.state.userImage) {
      return <img src={this.state.userImage} alt={this.state.userName} />
    } else {
      return <Avatar>
        <Box fontSize="14px">{this.state.userName.charAt(0).toUpperCase()}</Box>
      </Avatar>
    }
  }

  renderCell = (row: ParticipantDetails, column: ColumnInt) => {
    if (column.id === "name") {
      return (
        <div className="nameCell">
          {this.getUserImage(row)}
          <p>{row.name}</p>
        </div>
      );
    } else if (column.id === "date") {
      return moment(row.date_of_completion).format("DD/MM/YYYY");
    } else {
      return (
        <button
          data-test-id="viewBtn"
          onClick={() => this.handleResultBtn(row)}
        >
          View results
        </button>
      );
    }
  };

  renderAnswerTable = () => {
    const { participant_details } = this.state;
    if (participant_details.length > 0) {
      return (
        <Table stickyHeader aria-label="sticky table" className="table">
          <TableHead>
            <TableRow>
              {columns.map((column: ColumnInt) => (
                <TableCell
                  key={column.id}
                  align={"left"}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {participant_details.map((row: ParticipantDetails) => {
              return (
                <TableRow hover tabIndex={-1} key={row.name}>
                  {columns.map((column: ColumnInt) => {
                    return (
                      <TableCell
                        key={column.id}
                        style={{ minWidth: column.minWidth }}
                      >
                        {this.renderCell(row, column)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    } else {
      return <div className="errorMessage">No data available</div>;
    }
  };

  handleRangeNumbers = (start: number | undefined, end: number | undefined) => {
    const array = [];
    if (start !== undefined && end !== undefined) {
      for (let index = start; index <= end; index++) {
        array.push(index);
      }
    }
    return array;
  };

  textQuestionBlock = (item: Questions) => {
    return (
      <div>
        <FormControl component="fieldset" className="radioDescSection">
          <RadioGroup aria-label="gender" name="gender">
            <FormControlLabel
              data-testId="textFeild"
              className="descRadioBox"
              control={
                <Input value={item.attributes.answer} disableUnderline />
              }
              label={undefined}
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  checkRangeAttributes(value: RangeAttributes | OptionsInt[], key:any) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      if(key === "start_range_text"){
        return value.start_range_text;
      } else if (key === "start_range"){
        return value.start_range;
      } else if (key === "end_range"){
        return value.end_range;
      } else if (key === "end_range_text"){
        return value.end_range_text;
      }
    }
  }

  rangeQuestionBlock = (item: Questions) => {
    return (
      <div className="radioWrapper">
        <div style={{ display: "flex" }}>
          <span style={{ padding: 10 }}>
            {this.checkRangeAttributes(item?.attributes?.question?.options, "start_range_text")}
          </span>
          <RadioGroup
            style={{ display: "flex", flexDirection: "row" }}
            name="range"
            value={item.attributes.answer}
          >
            {this.handleRangeNumbers(
              this.checkRangeAttributes(item?.attributes?.question?.options, "start_range") as number,
              this.checkRangeAttributes(item?.attributes?.question?.options, "end_range") as number
            )?.map((item: number) => {
              return (
                <FormControlLabel
                  value={item.toString()}
                  control={<Radio className="descRangeBox" />}
                  label={undefined}
                  key={item}
                />
              );
            })}
          </RadioGroup>
          <span style={{ padding: 10 }}>
            {this.checkRangeAttributes(item?.attributes?.question?.options, "end_range_text")}
          </span>
        </div>
      </div>
    );
  };

  checkboxQuestionBlock = (item: Questions) => {
    return (
      <div>
        <FormControl component="fieldset" className="fieldset">
          <FormGroup className="radioDescSection">
            {(Array.isArray(item?.attributes?.question?.options)) && 
              item?.attributes?.question?.options?.map((option: OptionsInt, num: number) => {
              return (
                <FormControlLabel
                  data-testId={`check-${num}`}
                  className="descCheckBox"
                  key={option?.id}
                  control={
                    <Checkbox
                      name="checked"
                      value={option?.id}
                      checked={item?.attributes?.answer.includes(
                        option?.id.toString()
                      )}
                    />
                  }
                  label={option?.name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </div>
    );
  };

  radioQuestionBlock = (item: Questions) => {
    return (
      <div>
        <FormControl component="fieldset" className="fieldset">
          <FormGroup className="radioDescSection">
            {(Array.isArray(item?.attributes?.question?.options)) && 
              item?.attributes?.question?.options?.map((option: OptionsInt, num: number) => {
              return (
                <FormControlLabel
                  data-testId={`check-${num}`}
                  className="descCheckBox"
                  key={option?.id}
                  control={
                    <Radio
                      name="checked"
                      value={option?.id}
                      checked={item?.attributes?.answer.includes(
                        option?.id.toString()
                      )}
                    />
                  }
                  label={option?.name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </div>
    );
  };

  surveyQuestionsFrame = () => {
    const { survey_details } = this.state;
    return (
      <Box className="toolBarInner">
        <Box style={{ marginBottom: "auto" }}>
          <Box className="toolBarTopHead">
            <div className="toolBarInnerHead">
              <Typography variant="h4">
                {survey_details?.attributes?.title} 
              </Typography>
            </div>
          </Box>
          {this.state.questions.map((item: Questions, id: number) => (
            <Box className="descriptionOuter" key={item.id}>
              <Typography variant="h5">
                {id + 1}
                {". "}
                {item.attributes?.question?.title}
              </Typography>
              {this.surveyQuestionBlocks(item)}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  surveyQuestionBlocks = (item: Questions) => {
    const blockType: any = {
      text: this.textQuestionBlock(item),
      range: this.rangeQuestionBlock(item),
    };
    if (
      item.attributes.question?.choose_limit > 1 &&
      item.attributes.question?.q_type === "multiple_choice"
    ) {
      return this.checkboxQuestionBlock(item);
    } else if (
      item.attributes.question?.choose_limit == 1 &&
      item.attributes.question?.q_type === "multiple_choice"
    ) {
      return this.radioQuestionBlock(item);
    } else {
      return blockType[item.attributes.question?.q_type];
    }
  };

  getUserSurveyResults = () => {
    const { questions } = this.state;
    if (questions.length > 0) {
      return this.surveyQuestionsFrame();
    } else {
      return <div className="errorMessage">Work in progress</div>;
    }
  };

  renderTable = () => {
    const { survey_details, openResultPage, surveyData } = this.state;

    return (
      <>
        {!openResultPage && (
          <div className="topDetails">
            <div>Total Participants: {surveyData.total_participants}</div>
            <div>
              Date range:{" "}
              <span>
                {moment(survey_details?.attributes?.start_date).format(
                  "DD/MM/YYYY "
                )}
              </span>{" "}
              to{" "}
              <span>
                {moment(survey_details?.attributes?.end_date).format(
                  "DD/MM/YYYY"
                )}
              </span>
            </div>
          </div>
        )}
        {!openResultPage
          ? this.renderAnswerTable()
          : this.getUserSurveyResults()}
      </>
    );
  };

  userLaylout = () => {
    const {
      userName,
      completionDate,
      openResultPage,
    } = this.state;
    return (
      <Wrapper className={this.state.userType === "business_entity" ? `business` : ""}>
        {openResultPage ?
          <>
            {this.renderHeader()}
            <Container maxWidth={"xl"} className="ContentManagementUIDiv">
              {this.getUserSurveyResults()}
            </Container> </>
          :
          <>
            <Toolbar className="toolBar">
              <div className={`backIconWeb`}>
                <IconButton
                  aria-label="Open drawer"
                  color={"inherit"}
                  data-test-id="backIcon"
                >
                  <img src={icons.back} alt={"backIcon"} />
                </IconButton>

                <div className="nameCell">
                  {this.getUserAvatar()}
                  <div className="name">
                    <p className="subHeader">Name</p>
                    {userName}
                  </div>
                  <div className="name">
                    <p className="subHeader">Date</p>
                    {completionDate}
                  </div>
                </div>
              </div>
            </Toolbar>
            <Box className="loading">
              <CircularProgress className="loader" />
            </Box>
          </>
        }
      </Wrapper>
    )
  }

  children = () => {
    return (
      <Wrapper className={this.state.userType === "business_entity" ? `business` : ""}>
        {this.renderHeader()}
        <Container maxWidth={"xl"} className="ContentManagementUIDiv">
          {this.renderTable()}
        </Container>
      </Wrapper>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    let user = localStorage.getItem("user_type")
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
      {user === "user entity" &&
      //@ts-ignore
      <DashboardWeb navigation={this.props.navigation}>
        {this.userLaylout()}
      </DashboardWeb>
      }
      {user === "Non profit entity" &&
        //@ts-ignore
        <ContentManagement navigation={this.props.navigation} id={""} showBackButton={undefined} title={"Followers"}>
        {this.children()}
      </ContentManagement>
      }
      {user === "business_user" &&
        <BusDashbaordWeb classes={{}} theme={{}} navigation={this.props.navigation} id={""}>
        {this.userLaylout()}
        </BusDashbaordWeb>
      }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  "& .loading": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "300px"
  },
  "&.business": {
    marginLeft: "300px"
  },
  "&.toolBar": {
    padding: "0 !important",
    margin: "0px 0 20px 20px",
    background: "white",
    borderBottomLeftRadius: "10px",
  },
 
  "&.nameCell": {
    display: "flex",
    alignItems: "center",
    "& img, .MuiAvatar-root": {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      marginRight: "15px",
      objectFit: "cover",
      marginLeft: "10px",
    },
    "& .name": {
      position: "relative",
      marginRight: "50px",
      top: "5px",
      ...FONTS.mediumFont,

      "& .subHeader": {
        position: "absolute",
        fontSize: "12px",
        color: "#cdcdcd",
        margin: "0px",
        top: "-14px",
      },
    },
  },
  "& .toolBar": {
    padding: "0 !important",
    margin: "0px 0 20px 20px",
    background: "white",
    borderBottomLeftRadius: "10px",
  },
  "& .errorMessage": {
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
  },
  "& .nameCell": {
    display: "flex",
    alignItems: "center",
    "& img, .MuiAvatar-root": {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      marginRight: "15px",
      objectFit: "cover",
      marginLeft: "10px",
    },
    "& .name": {
      position: "relative",
      marginRight: "50px",
      top: "5px",
      ...FONTS.mediumFont,

      "& .subHeader": {
        position: "absolute",
        fontSize: "12px",
        color: "#cdcdcd",
        margin: "0px",
        top: "-14px",
      },
    },
  },
  "& .backIconWeb": {
    display: "flex",
    alignItems: "center",
  },
  "& .topDetails": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ...FONTS.mediumFont,
    fontSize: "16px",
    marginBottom: "20px",

    "& .surveyDesc": {
      fontSize: "18px",
    },

    "& span": {
      ...FONTS.regularFont,
      fontSize: "16px",
    },
  },

  "& .table": {
    "& thead": {
      "& tr": {
        width: "100%",
        display: "flex",
        "& th": {
          backgroundColor: "transparent",
          borderBottom: "none",
          fontWeight: 500,
          padding: "10px",
          ...FONTS.mediumFont,
          fontSize: "16px",
        },
      },
    },

    "& tbody": {
      "& tr": {
        background: "white",
        borderRadius: "10px",
        display: "flex",
        width: "100%",
        marginBottom: "10px",
        alignItems: "center",
        "&:hover": {
          background: "#fff",
        },
        "& td": {
          background: "transparent",
          borderBottom: "none",
          padding: "10px",
          ...FONTS.mediumFont,
          fontSize: "16px",

          "& .nameCell": {
            display: "flex",
            alignItems: "center",
            "& img, .MuiAvatar-root": {
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              marginRight: "15px",
              marginLeft: "5px",
            },
          },
          "& button": {
            background: "#1A9BDE",
            color: "#fff",
            border: "none",
            borderRadius: "10px",
            padding: "10px",
            cursor: "pointer",
            ...FONTS.mediumFont,
            fontSize: "16px",
          },
          "&:last-child": {
            textAlign: "right",
          },
        },
      },
    },
  },
  "& .toolBarInner": {
    width: "85%",
    margin: "40px auto",
    display: "flex",
    flexDirection: "column",
    "& .toolBarInnerHead": {
      "& h4": {
        fontSize: "28px",
        fontFamily: "'Rene_Bieder_Milliard_Book', sans-serif",
      },
      "& h6": {
        fontFamily: "'Rene_Bieder_Milliard_Book', sans-serif",
      },
    },
    "& .toolBarTopHead": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  "& .descriptionOuter": {
    margin: "30px auto",
    backgroundColor: "#f8f9fb",
    borderRadius: "25px",
    padding: "30px 50px ",
    fontFamily: "'Rene_Bieder_Milliard_SemiBold', sans-serif",
    "& .radioDescSection": {
      display: "block",
      "& .descRadioBox": {
        backgroundColor: "#fff",
        padding: "1px 10px",
        borderRadius: "10px",
        width: "70%",
        margin: "10px 0 10px !important",
        border: "1px solid #009BE2",
        minHeight: "58px",
        "& span": {
          color: "#B2B2B2",
        },

        "&:hover span": {
          color: "#009BE2",
        },
      },
    },
    "& h5": {
      marginBottom: "15px",
      fontSize: "20px",
    },
    "& .fieldset": {
      width: "100%",
    },
    "& .radioWrapper": {
      display: "flex",
      alignItems: "center",
      "& .descRangeBox": {
        margin: " 0 2px",
      },
      "& .descRangeBox span": {
        color: "#B2B2B2",
      },
      "& .descRangeBox input:checked + div svg": {
        color: " #009BE2",
      },
      "& .descRangeBox:hover span": {
        color: "#009BE2",
      },
    },

    "& .descCheckBox": {
      backgroundColor: "#fff",
      padding: "8px 10px",
      borderRadius: "10px",
      margin: "10px 0",
      width: "70%",
      border: "1px solid #e5e5e5",
      "& span": {
        color: " #B2B2B2",
        "&.Mui-checked": {
          color: "red",
          "& svg": {
            fill: "#009be2",
          },
        },
      },
      "&:hover": {
        color: "#009be2",
      },
      "& input": {
        fontSize: "16px",
        minHeight: "60px",
      },
    },
  },
});

export default SurveyUserAnswers;
// Customizable Area End