import React from 'react';

import {
  Container,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper,
  Tabs,
  Tab,
  Card,
  Dialog,
  Button,
  Box,
  CardMedia,
  Avatar,
  CircularProgress,
  Popover
  // Customizable Area End
} from "@material-ui/core";



// Customizable Area Start
import { Redirect } from "react-router-dom";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import moment from 'moment';
import PropTypes from "prop-types";
import {
  withStyles,
  createStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import { COLORS } from '../../../framework/src/Globals';
import { isMobile } from "react-device-detect";
// @ts-ignore
import classesOuter from "./HomeDashBoard.module.css";



import Carousel from "react-elastic-carousel";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";


import {
  icons,
  FONTS,
  images, FAMILYFONTS
} from "../../../components/src/constants";

import DashboardController, { Props } from "./DashboardController";
import "./HomeDashBoard.css";

//@ts-ignore
import dateFormat from "dateformat";
import { setLocationWiseFilterEventMessage, setAllEvents, setLocationWiseFilterEvent, setSelectedEvents } from "../../../framework/src/redux/events/eventsActions";
import { getAllEventsDetails } from "../../../framework/src/redux/advanceSearch/advanceSearchActions";
import { connect } from "react-redux";
import Skeleton from '@material-ui/lab/Skeleton';
import { setSelectedFilterEvents } from "../../../framework/src/redux/filterEvents/filterEventsAction"
import { storeSearchDetails } from '../../../framework/src/redux/savedSearch/savedSearchAction';


function pxToRem(value: number) {
  return `${value / 16}rem`;
}
export type CounterProps = { label?: string };



export const styles = (theme: any) =>
  createStyles({
    FiCardMedia: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
    },
    FiCardContent: {
      position: "relative",
      backgroundColor: "transparent",
    },
    FiCardActions: {
      position: "relative",
    },
    FiCardActionArea: {
      position: "relative",
    },
    FiCard: {
      position: "relative",
      borderRadius: 15,
      boxShadow: "none",
      height: isMobile ? 190 : 145
    },
    root: {
      flexGrow: 1,
    },
    card_carousel_div1: {
      float: "left",
      width: "40%",
      padding: "0 10px"
    },
    card_carousel_divw100: {
      float: "left",
      width: '98vw',
      padding: "0 10px"
    },
    card_carousel_divw102: {
      float: "left",
      width: '102vw',
      padding: "0 10px"
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: "12px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      height: 54,
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: COLORS.black10,
    },
    inputInput: {
      padding: theme.spacing(2, 2, 2, 0),
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      color: COLORS.black10, ...FAMILYFONTS.family3, fontSize: 16, fontWeight: 400
    },

    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      color: "text.primary",
      margin: "8px",
      padding: "8px",
      height: 54,
      width: 54,
      background: COLORS.white,
      border: '1px solid #D5D5D5',
      borderRadius: 13
    },
    searchFilterIconbutton: {
      color: "text.primary",
      margin: "8px",
      background: COLORS.white,
      padding: "8px",
      height: 46,
      width: 46,
    },
    tabIcons: {
      margin: 6,
      padding: 8,
      border: "1px solid #cdc9c9",
      borderRadius: 8,
      backgroundColor: "white",
    },
    mobileTabIcons: {
      margin: 6,
      padding: 8,
      background: '#FFFFFF',
      boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.474186)',
      borderRadius: 6,
    },

    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      borderRadius: 16,
    },
    image: {
      width: 72,
      height: 42,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    locationIcon: {
      height: "24px",
      width: "24px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
      marginRight: "10px",
    },
    gutter: {
      "&:first-child": {
        marginLeft: 0,
      },
    },
    tabsRoot: {
      marginBottom: "50px",
      backgroundColor: COLORS.white,
    },
    "tabsRoot___div___div": {
      "marginBottom": "50px",
      "backgroundColor": "white",
      "justifyContent": "none",
      "padding": "0rem"
    },

    upcommingTabsIndicator: {
      backgroundColor: "#009BE2",
      borderRadius: "4px 4px 0px 0px",
      height: 4,
      maxWidth: 160
    },
    pastTabtabsIndicator: {
      backgroundColor: "#009BE2",
      borderRadius: "4px 4px 0px 0px",
      height: 4,
      maxWidth: 160
    },
    tabRoot: {
      textTransform: "initial",
      // @ts-ignore 
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "#858585",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#000000",
        opacity: 1,
      },
      "&$tabSelected": {
        fontWeight: theme.typography.fontWeightMedium,
        color: "#000000"
      },
      "&:focus": {
        color: "000000",
      },
    },
    tabSelected: {},
    typography: {
      // padding: theme.spacing(3),
    },
    eventloop: {
      marginBottom: "30px",
    },
    carouselWrapper: {
      width: " 100%",
    },
    image_div: {
      backgroundColor: "pink",
    },
    cardCarousel: {
      width: "100%",
      borderStyle: "solid",
      borderColor: "white",
      backgroundColor: "white",
      padding: "24px 24px 24px 14px",
      borderRadius: 18,
      "& after": {
        content: "",
        display: "table",
        clear: "both",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        margin: 0,
      },
    },

    carousel_card: {
      textAlign: "left",
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 2.334,
      [theme.breakpoints.up("md")]: {
        fontSize: pxToRem(16),
      },
    },
    avatar_Groups: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    // web

    showAvatarGroup: { display: "none" },
    showCarouselAvatar: {
      display: "block",
      width: 200,
    },

    icon_holder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 35,
      width: 35,
      background: "white",
      borderRadius: "50%",
      position: "absolute",
      top: 10,
      right: 10,
    },
    eventContent: {
      width: "60%",
      padding: "0 20px",
      "@media (min-width:600px)": {
        width: "40%",
        padding: "0 10px",
      },
    },

    w_60: {
      width: "60%",
      paddingRight: "15px",
      "@media (min-width:600px)": {
        width: "100%",
        paddingRight: 0,
      },
    },
    py_3: {
      padding: ".5rem 0",
      "@media (min-width:600px)": {
        padding: ".5rem 0",
      },
    },

    heading2: {
      fontSize: 15,
      fontWeight: 400,
    },

    heading3: {
      fontSize: 12,
      fontWeight: 300,
    },

    flex: {
      display: "flex",
    },

    event_img_container: {
      maxWidth: '216rem',
      maxHeight: 0,

      borderRadius: 16,
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
    },

    eventcontent: {
      width: "100%",
      padding: " 0 22px",
      marginTop: -8,
    },
    cardDescription: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    cardDescriptionAvatar_location: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    avatarImgcontainer: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "0.5rem",
    },
    avatarImg: {
      width: 23,
      height: 23,
      justifyContent: "space-between",
      borderRadius: "50%",
      position: "relative",
      left: "-5px",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: 16,
        height: 16,
      },
    },



    eventCard: {
      padding: 24,
      background: "white",
      borderRadius: 24,
      marginBottom: 30,
      height: 230,
      width: "100%",
    },

    icon: {
      height: 25,
      width: 25,
    },

    past_card_status: {
      zIndex: 1,
      position: "absolute",
      padding: "6px",
      height: 25,
      width: 52,
      right: " 12px",
      top: "64px",
      borderRadius: "16px",
      color: COLORS.white,
      fontSize: "12px",
      lineHeight: "27px",
      textAlign: "center",
      letterSpacing: "-0.3px",
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 13,
    },
    btn_dbs: {
      color: COLORS.white,
      height: 30,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: '0 !important',
      borderBottomLeftRadius: 13,
      borderTopRightRadius: 13,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        top: 100,
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },
    btn_dbs_carousel: {
      color: COLORS.white,
      height: 30,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: '0 !important',
      borderBottomLeftRadius: 13,
      borderTopRightRadius: 13,
      left: 0,
      top: 115,
      [theme.breakpoints.down("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        top: 166,
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },
    btn_dbs_slider: {
      color: COLORS.white,
      height: 38,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: '0 !important',
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 15,
      left: 0,
      top: 120,
      [theme.breakpoints.up("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },
    newtag:{
      color: COLORS.white,
      height: 27,
      backgroundColor: "red",
      padding: "3px 18px",
      position: "absolute",
      bottom: '0 !important',
      left: 9,
      top: 9,
      borderRadius:6
    },

    mobileLayoutContent: {
      display: "none",
    },
    multiLineEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      width: 180,
    },
    layOutbuttons: {
      width: 76,
      height: 24,
      borderRadius: "8px",
      lineHeight: 2,
      marginTop: 0,
      color: COLORS.white,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },

    navigationIcon: {
      color: COLORS.skyBlue,
      backgroundColor: "white",
      margin: "8px",
      padding: "13px",
      height: 50,
      borderRadius: 30,
      zIndex: 999,
      top: -12,
      right: 11,
    },

    [theme.breakpoints.down("xs")]: {
      showCarouselAvatar: {
        display: "none",
      },
      upcommingTabsIndicator: {
        backgroundColor: "#009BE2",
        marginLeft: 10,
        borderRadius: "4px 4px 0px 0px",
        height: 4,
        maxWidth: 128
      },
      pastTabtabsIndicator: {
        backgroundColor: "#009BE2",
        marginLeft: -5,
        borderRadius: "4px 4px 0px 0px",
        height: 4,
        maxWidth: 128
      },
      eventdate_loc: {
        width: 121,
        height: 14,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: -0.3,
        color: " #858585",
      },

      event_img_container: {
        maxWidth: 120,
        maxHeight: 120,
        borderRadius: 12,
      },
      event_Paper: {
        margin: "auto",
        padding: 24,
        marginBottom: 30,
        height: 120,
        width: "100%",
        background: '#FFFFFF',
        border: '1px solid #C5C5C5',
        borderRadius: 18,
      },
      eventCard: {
        padding: 0,
        background: "white",
        borderRadius: 15,
        marginBottom: 30,
        height: 120,
      },

      icon: {
        height: 15,
        width: 15,
      },

      heading2: {
        fontSize: 12,
        fontWeight: 400,
      },

      heading3: {
        fontSize: 10,
        fontWeight: 300,
      },

      py_3: {
        padding: ".5rem 0",
      },

      w_60: {
        width: "100%",
        paddingRight: 0,
      },

      h2: {
        fontSize: 15,
      },
      desktopLayoutContent: {
        display: "none",
      },

      layOutbuttons: {
        width: 76,
        height: 21,
        borderRadius: "8px",
        lineHeight: 2,
        marginTop: 0,
        color: COLORS.white,
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
      },

      navigationIcon: {
        color: COLORS.skyBlue,
        backgroundColor: "white",
        margin: "8px",
        padding: "13px",
        height: 50,
        borderRadius: 30,
        zIndex: 999,
        top: -25,
        right: -29,
      },
      mobileLayoutContent: {
        width: "50%",
        display: "inline-grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "self-start",
        "@media (min-width:600px)": {
          width: "50%",
          position: "relative",
        },
      },
    },

    // outer 

    "searchreletiveenv": {
      "position": "relative"
    },
    "searchfilterEnv": {
      "position": "absolute",
      "height": "100vh",
      "maxHeight": "555px",
      "top": "63px",
      "padding": "10px",
      "background": "#FFFFFF",
      "border": "1px solid #D5D5D5",
      "borderRadius": "13px"
    },


    "leftmenuDetails": {
      "height": "100%"
    },
    "backIconWeb": {
      "display": "flex",
      "alignItems": "center"
    },
    "settingContent": {
      "marginLeft": "50px"
    },

    "filterPositions": {
      "position": "absolute",
      "top": "0px",
      "left": "0px",
      "transform": "translate3d(575px, 119px, 0px)",
      "willChange": "transform"
    },
    "pastImg": {
      "marginTop": "-36px",
      "borderRadius": "15px"
    },
    event_Paper: {
      margin: "auto",
      padding: 24,
      marginBottom: 20,
      height: "fit-content",
      width: "100%",
      background: '#FFFFFF',
      border: '1px solid #C5C5C5',
      borderRadius: 18,
      cursor: "pointer"
    },

    modalpaper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      height: 54
    },

    // location modal css 
    textInputmodal: {
      marginTop: 10
    },
    drawerPaper: {
      height: 580,
      padding: 16,
      background: '#FFFFFF',
      borderRadius: '16px 16px 0px 0px'
    },
    broadcastDialog: {
      "& div div": {
        borderRadius: "15px",
      }
    },
    modalBox: {
      width: "350px",
      height: "auto",
      backgroundColor: "white",
      padding: "30px"
    },
    okButton: {
      width: "100%",
      backgroundColor: "#009be2",
      color: "white",
      borderRadius: "10px",
      marginTop: '35px',
      height: '50px',
      textTransform: 'capitalize',
      "&:hover": {
        backgroundColor: "#009be2",
        color: "white",
      }
    },
    readMore: {
      color: "#68B7D7",
      cursor: "pointer",
      marginLeft: "5px"
    },
    messageText: {
      paddingLeft: "8px",
      marginTop: "4px",
      wordBreak: "break-all",
     },
    scroll: {
      overflow: "auto", 
      height: "calc(100vh - 200px)",
      "&::-webkit-scrollbar": {
        display: "block !important"
      }
    },
    leftScroll: {
      height: "calc(100vh - 140px)",
      overflow: "auto",
      scrollbarWidth: "none"
    },
    "&::-webkit-scrollbar": {
      display: "none"
    },
    width100: {
      width: "100%"
    },
    hoverTextWrapper: {
      height: "auto",
      padding: "1rem",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
      maxHeight: "300px",
      overflowY: "auto",  
    },
  });

import ImageLoader from '../../../components/src/custom/ImageLoader';
// Customizable Area End

class HomeDashBoard extends DashboardController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Tab events past and upcomming start Refactor 
  renderEventsTagInDesktop = (item: any) => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "66px",
          height: "31px",
          borderBottomLeftRadius: "1.2rem",
          borderTopRightRadius: "1rem"
        }}
      >
        <Typography
          style={{
            top: "12px",
            color: COLORS.white,
            fontSize: "12px",
            lineHeight: "28px",
            textAlign: "center",
            letterSpacing: "-0.3px",
            backgroundColor: this.check_event_type(item.attributes.event_type) as any,
            borderRadius: " 0px 14px"
          }}
        >
          {this.check_event_type_name(item.attributes.event_type) as any}
        </Typography>
      </div>
    );
  };

  renderEventsTagInMobile = (item: any) => {
    return (
      <div
        style={{
          backgroundColor: this.check_event_type(item?.event_type) as any,
          height: 24,
          borderRadius: "8px",
          lineHeight: 2,
          marginTop: 0,
          width: 76,
          fontSize: 12,
          display: "flex",
          justifyContent: "center",
          color: COLORS.white,
          marginLeft: 6
        }}
      >
        {this.check_event_type_name(item?.event_type) as any}
      </div>
    );
  };

  truncateString = (str: string) => {
    const maxLength = 56;
    if (str?.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
  }

  renderEventTitle = (title: string) => (
    <div data-testId="eventTitle">
      <Typography
        variant="body1"
        style={{
          marginLeft: 9,
          ...FAMILYFONTS.family1,
          fontSize: 16,
          marginTop: !isMobile ? 0 : 4,
          width: isMobile ? '180px' : '230px',
          overflow: "hidden",
          maxWidth: 250,
        }}
      >
        {this.truncateString(title)}
      </Typography>
    </div>
  );

  handleRedirection = (item: any) => {
    if (item.type === "non_profit_entity") {
      this.setState({ eventType: `/nonProfitProfile/${item.organisation_id}` });
    }
  }

  renderOrganizationInfo = (item:any, name: any, image: any) => (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: -20,
      }}
    >
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          data-test-id="redirect-btn"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 8,
            marginTop: 29,
          }}
          className={classesOuter.cardRightSubTitleText}
        >
          {!isMobile && <Avatar
            style={{
              height: 20,
              width: 20,
              borderRadius: 50,
            }}
            src={image?.url != "" ? image?.url : name}
            alt={name}
          />}

          <Typography
            title={name}
            variant="body2"
            style={{
              marginLeft: !isMobile?9:"",
              marginTop: 2,
              fontFamily: "Rene_Bieder_Milliard_Medium",
              fontSize: "16px",
            }}
            className={classesOuter.cardRightText}
          >
            {name}
          </Typography>
        </div>
      </div>
    </div>
  );

  renderEventDate = (date: any) => (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginLeft: 8,
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 11,
        }}
      >
        <img
          style={{
            height: 20,
            width: 20,
          }}
          src={icons.calender}
          alt="Calendar Icon"
        />
        <Typography
          variant="body2"
          gutterBottom
          style={{
            marginLeft: 9,
            ...FONTS.paragraph,
            fontSize: 14,
            letterSpacing: "0.0571429px",
            width: 200,
            marginTop: 6,
          }}
          className={classesOuter.cardRightDatenLocation}
        >
          {date}
        </Typography>
      </div>
    </div>
  );
  renderLocation=(location:string, post_code:string)=> {
    if(post_code && !location.includes(post_code)){
     location += `, ${post_code}`; 
   }
  return location;
 }
 renderCountry = (location:string,post_code:string) => {
   return post_code?post_code.substring(0, post_code.indexOf(" "))||post_code:location?.slice(location.lastIndexOf(",") + 1).trim();
 }

 renderFeedCountry = (post_code:string) => {
  return post_code?.substring(0, post_code.indexOf(" "))
}

  renderEventLocation = (location:string, post_code: string) => (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginLeft: 6,
        marginTop: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: 2,
        }}
      >
        <img
          style={{
            height: 20,
            width: 20,
          }}
          src={icons.locationred}
          alt="Location Icon"
        />
        <Typography
          title={post_code}
          variant="body2"
          gutterBottom
          className={classesOuter.cardRightDatenLocation}
          style={{
            marginLeft: 9,
            ...FONTS.paragraph,
            fontSize: 14,
            letterSpacing: "0.0571429px",
            marginTop: 6,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: isMobile ? '180px' : '230px',
            overflow: "hidden",
            maxWidth: 250
          }}
        >{this.renderLocation(location,post_code)}
        </Typography>
      </div>
    </div>
  );

  renderEventPostLocation = ( location:string, post_code: string) => (
    <div
      style={{
        marginTop: 2,
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 6,
        display: "flex" }} >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          marginLeft: 2,}} >
        <img
          style={{
            width: 20,
            height: 20, }}
            alt="Location Icon"
          src={icons.locationred}/>
        <Typography
          variant="body2"
          className={classesOuter.cardRightDatenLocation}
          gutterBottom
          title={post_code}
          style={{
            ...FONTS.paragraph,
            fontSize: 14,
            whiteSpace: "nowrap",
            letterSpacing: "0.0571429px",
            marginTop: 6,
            textOverflow: "ellipsis",
            marginLeft: 9,
            maxWidth: 250,
            width: isMobile ? '180px' : '230px',
            overflow: "hidden",
          }} > 
          {this.renderCountry(location,post_code)}
        </Typography></div>
    </div>
  );

  renderUpcomingPastCardContainer = (classes: any, item: any) => {
    const { event_name,event_type, my_pledge_id,need_my_pledge_id, organisation_name, user_name, user_image, title_of_need, from_date, start_date, start_time, post_code, organisation_image_url,location } = item;
    const pledgeId = my_pledge_id ?? need_my_pledge_id; // Choose the appropriate pledge ID property

    return (
      <div className={`${classesOuter.card_carousel_div4} ${classes.width100}`}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              {isMobile && <div>{this.renderEventsTagInMobile(item)}</div>}
              {this.renderEventTitle(event_name?? title_of_need)}

              {this.renderOrganizationInfo(item, organisation_name?? user_name, organisation_image_url??user_image)}

              {this.renderEventDate(
                 `${moment(from_date ?? start_date).format("DD MMM YYYY")}, ${moment(start_time).format("HH:mm A")}`
              )}

{((pledgeId && pledgeId.length > 0 && pledgeId[0]?.status === "Approved") || event_type === "project_funding" || event_type === "activity") ? (
  this.renderEventLocation(location, post_code)
) : (
  <div>
    {this.renderEventPostLocation(location, post_code)}
  </div>
)}

            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };



  renderuppcommingPastImage = (item: any) => {
    const imageUrl = item.attributes?.event_image?.url || item.attributes?.need_photo?.url || this.state.placeholderImg;
    return   <ImageLoader loaderStyle={{textAlign:"center",marginTop:44,marginLeft:54}}  classes={classesOuter.event_main_img} imageUrl={imageUrl ? imageUrl : this.state.placeholderImg} alt="complex" />

  }


  renderupcommingpastCard = (classes: any, item: any, index: any) => {
    const handleCardClick = () => {
      if (item.attributes.type === "non_profit_entity") {
        if (item.attributes.event_type === "activity") {
          this.props.navigation.navigate("EventInfo", { id: item.id });
        } else if(item.attributes.event_type === "project_funding"){
          this.props.navigation.navigate("FundCampaign", { id: item.id });
        } else if(item.attributes.event_type === "need"){
          this.props.navigation.navigate("EventsDetails", { id: item.id });
        }
      } else {
        this.props.navigation.navigate("EventsDetails", { id: "r1-" + item.id });
      }
      this.props.setSelectedEvents(item);
    };
    const renderFavouritesIcon =
      item?.attributes?.is_favourite
        ? <FavoriteIcon
          data-testId="unfavorite"
          onClick={(e) => {
            e.stopPropagation()
            this.removeFromFav(item)
          }}
          className={classesOuter.favorite}
          style={{color:"red"}}
        />
        :
        <FavoriteBorderIcon
          data-testId="favorite"
          className={classesOuter.favorite}
          onClick={(e) => {
            e.stopPropagation();
            this.addToFav(item.id,item.attributes.type);
          }}
        />
    const isPasttabValue = this.state.eventTabValue == "Past"

    return (
      <div
        className={classes.root}
        key={index}
        style={{ filter: isPasttabValue ? 'grayscale(100%)' : "", marginBottom: 10, position: 'relative', }}
        data-testId={`upcomingPastEvents`}
        onClick={handleCardClick}
      >
        <Paper className={`${classes.event_Paper} ${classesOuter.eventPaperCard}`} elevation={0}>
          {!isMobile && this.renderEventsTagInDesktop(item)}

          <Grid container>
            <Grid item xs={4} md={5} style={{ position: "relative" }}>
              {item.attributes.dbs_registration && <span className={classes.btn_dbs}>DBS</span>}
              <div className={classes.event_img_container}>
                {this.renderuppcommingPastImage(item)}
                {!isMobile && renderFavouritesIcon}
              </div>
            </Grid>
            <Grid item xs={8} md={7}>
              {this.renderUpcomingPastCardContainer(classes, item?.attributes)}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }


  renderInitialupcommingPastCard = (isFeed: boolean) => {
    const card = <Paper
      style={{
        margin: "auto",
        padding: 24,
        marginBottom: 30,
        height: isFeed ? 100 : 150,
        width: "100%",
        background: '#FFFFFF',
        borderRadius: 18,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
      }}
      elevation={0}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
          <Skeleton variant="rect" className={classesOuter.event_main_img} height={isFeed ? 55 : 118} />
        </Grid>
        <Grid item xs={6} md={8}>
          {[...Array(isFeed ? 4 : 6)].map((_, index) => (
            <Skeleton key={index} />
          ))}
        </Grid>
      </Grid>
    </Paper>

    if (isFeed) {
      return [...Array(4)].map((_, index) => (card));
    }
    return card;
  }


  renderTabLabel = (text: {} | null | undefined, fontSize: number, paddingBottom: number, marginLeft: number) => (
    <Typography
      style={{
        ...FAMILYFONTS.family2,
        fontWeight: 400,
        fontSize,
        paddingBottom,
        marginLeft,
      }}
    >
      {text}
    </Typography>
  );

  handleTabChange = (_: any, newValue: any) => {
    if (this.props?.tabHandler) {
      this.props?.tabHandler(newValue)
    }
    this.setState({ eventTabValue: newValue, upComingPastEventError: "" });
    if (newValue === "Past") {
      this.pastEvents();
    } else {
      this.upComingEvents();
    }
  };

  renderUpcommingPastTab = (classes: any) => {
    const { eventTabValue } = this.state;
    const isUpcomingTabSelected = eventTabValue === "Upcoming";

    return (
      <Tabs
        data-testId="tabs"
        value={eventTabValue}
        classes={{
          root: `${classesOuter.tabsRoot}`,
          indicator: isUpcomingTabSelected ? classes.upcommingTabsIndicator : classes.pastTabtabsIndicator,
        }}
        style={{ borderRadius: "12px" }}
        onChange={this.handleTabChange}
      >
        <Tab
          className={classes.tabsAlign}
          disableRipple
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
          label={this.renderTabLabel(
            "Upcoming Events",
            isMobile ? 14 : 18,
            isMobile ? 0 : 12,
            isMobile ? 5 : 0
          )}
          value="Upcoming"
        />
        <Tab
          disableRipple
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
          label={this.renderTabLabel(
            "Past Events",
            isMobile ? 14 : 18,
            isMobile ? 0 : 14,
            isMobile ? -12 : 0
          )}
          value="Past"
        />

        {/* tab icons for mobile  */}
        {this.mobileViewTabIcons(classes)}
      </Tabs>
    );
  };



  mobileViewTabIcons = (classes: any) => {
    return (
      <div
        className={`${classes.sectionTabDesktop} ${classesOuter.mobileViewFilterNsearchPosition}`}
      >
        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
          className={`${classes.mobileTabIcons} ${classesOuter.mobileViewFilterNsearch} ${classesOuter.tabsAlignPastEvents}`}
          onClick={this.handleFilterClick}
        >
          <img
            className={classes._tabs_logo}
            src={icons.filterOption}
          />
        </IconButton>
        <IconButton
          aria-label="show 17 new notifications"
          color="inherit"
          data-test-id="mobile-search-container"
          className={`${classes.mobileTabIcons} ${classesOuter.mobileViewFilterNsearch}`}
          onClick={() => {
            this.props.navigation.navigate("MobileSearchContainer")
          }}
        >
          <img className={classes._tabs_logo} src={icons.search} />
        </IconButton>
      </div>
    )
  }


  renderupcommingpastEventsDataContainer = (upComingPastEvent: any, classes: any) => {
    if (this.state.loading) {
      return this.renderInitialupcommingPastCard(false);
    }
    if (upComingPastEvent && upComingPastEvent.length > 0) {
      return upComingPastEvent.map((item: any, index: any) => {
        return this.renderupcommingpastCard(classes, item, index);
      });
    } else {
      if (upComingPastEvent.length > 0) {
        return <Container maxWidth="xl">
          <Paper className={`${classes.event_Paper} ${classesOuter.eventPaperCard}`} style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }} elevation={0}>
            No Events Available
          </Paper>
        </Container>
      }
    }
    
    return this.renderInitialupcommingPastCard(false);
  };

  renderUpCommingPastEvents = (classes: any, upComingPastEvent: any, selectedLocationWiseEventsMessage: any) => {
    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classesOuter.tabContainer}>
          <div className={classesOuter.tabblock} style={{ marginBottom: 30,padding:"0 10px" }}>
            {this.renderUpcommingPastTab(classes)}
          </div>
        </Container>
        <Container maxWidth="xl">
          {selectedLocationWiseEventsMessage !== "" ? (
            <Paper className={`${classes.event_Paper} ${classesOuter.eventPaperCard}`} style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }} elevation={0}>
              {selectedLocationWiseEventsMessage}
            </Paper>
          ) : (
            <>
              <div style={{ paddingBottom: 65 }}>
                {this.renderupcommingpastEventsDataContainer(upComingPastEvent, classes)}
              </div>
            </>

          )}
        </Container>
      </div>
    );
  };
  // Tab events past and upcomming end Refactor 



  // right side card  event Refactor

  renderEventTypeBadge = (event_type: any) => {
    const backgroundColor = this.check_event_type(event_type);
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor,
          width: "56px",
          height: "19.98px",
          borderRadius: "0px 14px",
          border: "0.5px solid #D5D5D5"
        }}
      >
        <Typography
          style={{
            top: "12px",
            color: event_type == "update"? "#179EDB": COLORS.white,
            fontSize: "10px",
            lineHeight: "18px",
            textAlign: "center",
            letterSpacing: "-0.25px",
          }}
        >
          {this.check_event_type_name(event_type)}
        </Typography>
      </div>
    );
  };

  readMore = (classes: any, broadcastMessage: string) => {
    const toggleReadMore = () => {
      this.setState({ broadcastMessage, isDialogOpen: true });
    };

    let len = 138;
    const width = window.innerWidth;
    if (width <= 1072) {
      len = 95;
    } else if (width <= 1220) {
      len = 115;
    }
    const shouldShowReadMoreButton = broadcastMessage.length > len;
    const truncatedContent = shouldShowReadMoreButton ? broadcastMessage.slice(0, len) : broadcastMessage;
    return (
      <Box data-test-id="read-more-button"  className={classes.messageText}>
        <span>{truncatedContent}</span>
        {shouldShowReadMoreButton && (
          <span className={classes.readMore} onClick={toggleReadMore}>Read more...</span>
        )}
      </Box>
    );
  }

  broadcastMessageDialog = (classes: any) => {
    return (
      <Dialog
        open={this.state.isDialogOpen}
        className={classes.broadcastDialog}
        transitionDuration={1}
        aria-labelledby="detail"
        aria-describedby="description"
        data-testId="modalClose"
        onClose={() => this.setState({ isDialogOpen: false })}
      >
        <Box className={classes.modalBox}>
          <Typography>{this.state.broadcastMessage}</Typography>
          <Button
            className={classes.okButton}
            data-testId='ok-btn'
            onClick={() => this.setState({ broadcastMessage: "", isDialogOpen: false })}
          >
            OK
          </Button>
        </Box>
      </Dialog>
    );
  }

  renderBroadcastCard = (classes: any, item: any, index: any) => {
    return (
      <div data-testid="event-card" className={classes.root} key={index} style={{ marginBottom: 25, position: 'relative' }}>
        <Paper
          className={` ${classesOuter.meetingeventCard}`}
          elevation={0}
          style={{
            width: "100%",
            border: '1px solid #C5C5C5',
            background: '#FFFFFF',
             borderRadius: 18,
             height: "fit-content",
             minHeight: "100px"
          }}
        >
          {this.renderEventTypeBadge("update")}
          <Grid container>
            <Grid container direction="column" spacing={1}>
              <Grid item xs style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", padding: "6px 13px" }}>
                <div style={{ marginBottom: "auto", display: "flex", alignItems: "center", paddingLeft: 8, paddingTop: 6 }} >
                  <Avatar
                    style={{
                      height: 25,
                      width: 25,
                      borderRadius: 50,
                    }}
                    src={item.organization_profile_picture}
                    alt={item.organization_name}
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginLeft: 9,
                      maxWidth: 305,
                      fontFamily: FAMILYFONTS.family2.fontFamily,
                      overflow: "hidden",
                      fontSize: 14,
                      paddingRight:"40px"
                    }}
                  >
                    {item.organization_name}
                  </Typography>
                </div>
                <div>
                  {this.readMore(classes, item.message)}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }

  renderEventCard = (classes: any, item: any, index: any) => {
    if(item.type === "broadcast"){
      return this.renderBroadcastCard(classes, item, index);
    }
    const accountId = localStorage.getItem("acc_id");
    const myPledge = item.my_pledge?.filter((obj: { account_id: number }) => obj.account_id.toString() === accountId)[0];
    return (
      <div data-testid="event-card" className={classes.root} key={index}
      style={{ marginBottom: 25, position: 'relative' }}>
        <Paper className={` ${classesOuter.meetingeventCard}`} elevation={0} style={{
          width: "100%",
          background: '#FFFFFF',
          borderRadius: 18,
          height: "unset",
          cursor: item.type === "broadcast" ? "default" : "pointer"
        }}
        data-testId="upcomingEvents"
        onClick={()=> this.handleCardClick(item.id, item.type, item.event_type, item)}
        >
          {this.renderEventTypeBadge(item.event_type)}

          <Grid container>
            <Grid item xs={3}>
              <ImageLoader loaderStyle={{textAlign:"center",marginTop:34,marginLeft:34}} imgstyles={{ marginTop: 0, borderRadius: 8, marginLeft: 0, width: "100%", maxHeight: 85, padding: 16 }}
                classes={classes.img} imageUrl={item.event_image ? item.event_image : this.state.placeholderImg} alt="complex" />
            </Grid>

            <Grid item xs={9} style={{ borderLeft: "1px solid lightgray", height: "90%", flex: 1, overflow: "auto", padding: 5 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                  <div style={{ marginBottom: "auto" }}>
                    <Typography
                      variant="body1"
                      style={{
                        marginLeft: 9,
                        maxWidth: 305,
                        fontFamily: FAMILYFONTS.family2.fontFamily,
                        fontSize: 14,
                        overflow: "hidden",
                      }}
                    >
                      {this.truncateString(item.title)}
                    </Typography>
                  </div>
                 <div>
                    <div style={{ display: "flex", alignItems: "center", paddingLeft: 8 }}>
                    <Avatar
                      style={{
                        height: 15,
                        width: 15,
                        borderRadius: 50,
                      }}
                      src={item.organization_profile_picture}
                      alt={item.organization_name}
                    />
                    <Typography title={item.organization_name} variant="body2" gutterBottom style={{ marginLeft: 9, fontFamily: FAMILYFONTS.family1.fontFamily, fontSize: 12, marginBottom: 0, color: "rgb(88 85 85)" ,
                    }}>
                        {item.organization_name}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: 8 }}>
                      <img style={{ height: 15, width: 15 }} src={icons.calender} />
                      <Typography variant="body2" gutterBottom style={{ marginLeft: 9, fontFamily: FAMILYFONTS.family1.fontFamily, fontSize: 12, fontWeight: 400, marginTop: 6, color: "#aeaeae", marginBottom: 0 }}>
                        {`${moment(item.start_date).format("DD MMM YYYY")}, ${moment(item.start_time).format("HH:mm A")}`}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: 6 }}>
                      <img style={{ height: "20px", width: "20px" }} src={icons.locationred} />
                      <Typography title={item.location} variant="body2" gutterBottom style={{ marginLeft: 9, fontFamily: FAMILYFONTS.family2.fontFamily, fontSize: 12, fontWeight: 400, marginTop: 6, color: "#aeaeae", marginBottom: 0,  textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    maxWidth: 250 }}>
                        {((myPledge && myPledge.status === "Approved") || item.event_type === "project_funding" || item.event_type === "activity")
                        ? this.renderLocation(item.location,item.post_code):
        this.renderCountry(item.location,item.post_code)}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  };


  renderSkeletonCards() {

    const skeletonCards = [];

    for (let i = 0; i < 3; i++) {
      skeletonCards.push(

        <Skeleton
          key={i}
          variant="rect"

          style={{
            width: "100%",
            border: '1px solid #C5C5C5',
            borderRadius: 18,
            height: 120, marginTop: 12
          }}
        />

      );
    }

    return skeletonCards;
  }


  renderHistoryRightEventsDetails = (classes: any, upComingPastEvent: any) => {
    if (this.state.isLoading) {
      return <Container maxWidth="xl">
        {this.renderInitialupcommingPastCard(true)}
      </Container>
    }
    const filteredEvents = upComingPastEvent?.length && upComingPastEvent?.reduce((acc: any[], event: any) => {
      acc.push(event);
      return acc;
    }, []);

    return (
      <Container className={classes.scroll}>
        {filteredEvents && filteredEvents.map((item: any, index: any) =>
          this.renderEventCard(classes, item, index)
        )}
      </Container>
    );
  }
  // right side card  event Refactor End





  // carousel functionality  start Refactor

  myArrow({ type, onClick, isEdge }: any) {

    const pointer = type === 'PREV' ? <ArrowBackIcon /> : <ArrowForwardIcon />;
    const marginLeft = type === 'PREV' ? -25 : 27;
    const marginRight = type === 'PREV' ? 29 : -20;

    let arrowStyle: any = {
      color: '#009BE2',
      backgroundColor: 'white',
      padding: '13px',
      height: '50px',
      borderRadius: 30,
      zIndex: 1,
      boxShadow: '0 3px 3px 0 rgb(0 0 0 / 16%)',
    };

    return (
      <div className="PrivateHiddenCss-xsDown-67" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft, marginRight }}>
        <IconButton onClick={onClick} disabled={isEdge} style={arrowStyle}>
          {pointer}
        </IconButton>
      </div>
    );
  }

  renderDotS({ pages, activePage, onClick }: any) {

    let activeIndexStyle: any = {
      boxSizing: 'border-box',
      padding: 0,
      WebkitTransition: 'all 250ms ease',
      transition: 'all 250ms ease',
      border: 'none',
      margin: 5,
      marginTop: 25,
      marginBottom: 40,
      backgroundColor: '#009BE2',
      fontSize: '1.3em',
      content: '',
      height: 10,
      width: 44.44,
      borderRadius: 18,
      outline: 'none',
    };

    let inActiveIndexStyle: any = {
      marginTop: 25,
      marginBottom: 40,
      opacity: 0.3,
      padding: 0,
      WebkitTransition: 'all 250ms ease',
      transition: 'all 250ms ease',
      border: 'none',
      margin: 5,
      backgroundColor: '#009BE2',
      fontSize: '1.3em',
      content: '',
      height: 10,
      width: 10,
      borderRadius: 18,
      outline: 'none',
    };

    return (
      <div style={{ flexDirection: 'row' }}>
        {pages.map((page: any) => {
          const isActivePage = activePage === page;
          const style = isActivePage ? activeIndexStyle : inActiveIndexStyle;
          return (
            <button key={page} data-testId={`active-dot`} onClick={() => onClick(page)} style={style} type="button" />
          );
        })}
      </div>
    );
  }

  renderCarouselImg = (classes: any, item: any) => {
    const imageUrl = item.attributes?.event_image?.url || item.attributes?.need_photo?.url || this.state.placeholderImg;
    const renderFavouriteIcon =
      item?.attributes?.is_favourite
        ? <FavoriteIcon
          data-testId="unfavorite"
          onClick={(e) => {
            e.stopPropagation()
            this.removeFromFav(item)
          }}
          className={classesOuter.favorite}
          style={{color:"red"}}
        />
        :
        <FavoriteBorderIcon
          data-testId="favorite"
          className={classesOuter.favorite}
          onClick={(e) => {
            e.stopPropagation();
            this.addToFav(item.id,item.attributes.type);
          }}
        />
    return (
      <Card className={classes.FiCard}>
        <CardMedia
        />
        {renderFavouriteIcon}
        <ImageLoader loaderStyle={{textAlign:"center",marginTop:44,marginLeft:isMobile?150:54}}  classes={classes.FiCardMedia} imageUrl={imageUrl ? imageUrl : this.state.placeholderImg}/>
        {item.attributes.dbs_registration && <span className={classes.btn_dbs_carousel}>DBS</span>}
        <span className={classes.newtag}>New</span>
      </Card>
    );
  };

  isFullLocationDisplayed = (status: string | undefined , event_type: null | string | undefined) => {
    return status === "Approved" || event_type === "project_funding" || event_type === "activity";
  }

  renderCarouselDetails = (classes: any, item: any) => {
    const { event_name, event_type, title_of_need,my_pledge_id,need_my_pledge_id, organisation_name, user_name,start_time, start_date, user_image, from_date, location, organisation_image_url, post_code } = item.attributes;
    const formattedDate = `${moment(from_date ?? start_date).format("DD MMM YYYY")}, ${moment(start_time).format("HH:mm A")}`;
    const orgLogo = organisation_image_url ?? user_image;
    const orgName = organisation_name ?? user_name;
    const pledgeId = my_pledge_id ?? need_my_pledge_id; // Choose the appropriate pledge ID property
    const status = pledgeId && pledgeId.length > 0 && pledgeId[0]?.status;
    const getHeadingText = event_name || title_of_need || ""
    return (
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Typography
                  title={event_name ?? title_of_need}
                  variant="body1"
                  style={{
                    marginLeft: 9,
                    ...FAMILYFONTS.family1,
                    fontSize: 18,
                    fontWeight: 'bold',
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    maxWidth: 200,
                    cursor:'pointer'
                  }}
                  data-test-id="mouse-enter-event" 
                  onMouseEnter={(event: React.MouseEvent<HTMLElement>)=>{
                    this.setState({carosalDetailHeadingText : getHeadingText});
                    if(getHeadingText && getHeadingText.length > 20){
                      this.handleOnMouseEnter(event)
                    }else{
                      this.handleOnMouseLeave();
                    }
                  }}
                >
                  {getHeadingText && getHeadingText.length > 20 ? `${getHeadingText}...` : getHeadingText}
                </Typography>
              </div>
              {/* subtitle  */}
              <div>
                <IconButton>
                  {isMobile && (
                    <img
                      src={icons.navgation}
                      style={{
                        height: 35
                      }}
                    />
                  )}
                </IconButton>
              </div>
            </div>

            {/* title end  */}

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: -18,
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 8,
                    marginTop: isMobile? 0:29,
                  }}
                >
                  <Avatar
                    style={{
                      height: 20,
                      width: 20,
                      borderRadius: 50,
                    }}
                    src={orgLogo?.url != "" ? orgLogo?.url : orgName}
                    alt={orgName}
                  />
                  <Typography
                    title={organisation_name ?? user_name}
                    variant="body2"
                    style={{
                      marginLeft: 9,
                      marginTop: 2,
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                      fontSize: "16px",
                      width: isMobile ? '180px' : '230px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                  >
                    {organisation_name ?? user_name}
                  </Typography>
                </div>
              </div>
            </div>

            {/* dates  */}
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                marginLeft: 8,
                marginTop: 8
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: 20,
                    width: 20,
                  }}
                  src={icons.calender}
                />
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{
                    marginLeft: 9,
                    ...FONTS.paragraph,
                    fontSize: 14,
                    letterSpacing: "0.0571429px",
                    width: 200,
                    marginTop: 6
                  }}
                  className={classesOuter.cardRightDatenLocation}
                >
                  {formattedDate}
                </Typography>
              </div>
            </div>

            {/* location  */}
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                marginLeft: 6,
                marginTop: 6
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 2,
                }}
              >
                <img
                  style={{
                    height: 20,
                    width: 20,
                  }}
                  src={icons.locationred}
                />
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classesOuter.cardRightDatenLocation}
                  style={{
                    marginLeft: 9,
                    ...FONTS.paragraph,
                    fontSize: 14,
                    letterSpacing: "0.0571429px",
                    marginTop: isMobile?0:6,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    maxWidth: 250
                  }}
                >
                  {this.isFullLocationDisplayed(status, event_type)
                  ? this.renderLocation(location,post_code):
        this.renderCountry(location,post_code)}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  handleCardClick = (id: any, type: string, event_type: string, item: any) => {
    if (type === "non_profit_entity") {
      if (event_type === "project_funding") {
        this.props.navigation.navigate("FundCampaign", { id: item.id });
      } else if (event_type === "need") {
        this.props.navigation.navigate("EventsDetails", { id: item.id });
      } else if (event_type === "activity") {
        this.props.navigation.navigate("EventInfo", { id: item.id });
      }
    } else {
      this.props.navigation.navigate("EventsDetails", { id: "r1-" + id });
    }
    this.props.setSelectedEvents(item);
  };

  renderCarouselCard = (item: any, index: any, classes: any) => {
    return (
      <div
        key={index}
        className={`${classes.cardCarousel}`}
        data-testId="upcomingEvents"
        onClick={()=> this.handleCardClick(item.id, item.attributes.type, item.attributes.event_type, item)}
        style={{ marginTop:'1.7%', cursor: "pointer" }}
      >
        <div className={isMobile?classes.card_carousel_divw100:classes.card_carousel_div1}>
          {this.renderCarouselImg(classes, item)}
        </div>

        <div className={isMobile?classes.card_carousel_divw102:classes.card_carousel_div1}>
          {this.renderCarouselDetails(classes, item)}
        </div>
      </div>
    );
  };

  renderWelcomeCard = (classes: any) => {
    return (
      <div
      style={{height:210}}
        className={`${classes.cardCarousel} ${classesOuter.main_carousel}`}
      >
        <Grid container>
          <Paper
            elevation={0}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              textAlign: "center",
              color: COLORS.skyBlue,
              width:'100%'
            }}
          >
            <ImageLoader imgstyles={{
                height: 169,
                width: 480,
              }} loaderStyle={{marginTop:10}}  imageUrl={this.state.initiateBanner ? this.state.initiateBanner : this.state.placeholderImg}/>
          </Paper>
        </Grid>
      </div>
    );
  };

  renderLoadingCarouselCard = () => {
    return (
      <Paper className={classesOuter.loadingCardPaper} style={{ width: "100%", padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="rect" className={classesOuter.event_main_img} style={{ height: "100%" }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Skeleton key={index} />
            ))}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  renderCarouselCardContainer = (storeUpcomingEvents: any, classes: any) => {
    if (storeUpcomingEvents) {
      storeUpcomingEvents.sort((a: { attributes: { created_at: string }; },b: { attributes: { created_at: string }; }) => {
        return new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime();
      });
      return storeUpcomingEvents.slice(0, 5).map((item: any, index: any) => {
        return this.renderCarouselCard(item, index, classes);
      });
    } else {
      return this.renderLoadingCarouselCard();
    }
  };

  renderCarousel = (classes: any, storeUpcomingEvents: any, breakPoints: any, arrow: any) => {
    return (
      <Container className={classesOuter.tabContainer}>

        {isMobile?
          <>
          {this.props.showLoader?<CircularProgress style={{marginBottom:50, marginTop:50, marginLeft:"45vw", height:24,width:24,color: "#009be2"}}/>:<Carousel
          isRTL={false}
          breakPoints={breakPoints}
          renderArrow={arrow}
          showArrows={!isMobile}
          renderPagination={this.renderDotS}
        >

          {storeUpcomingEvents?.length > 0 && this.renderCarouselCardContainer(storeUpcomingEvents, classes)}
        </Carousel>}</>
        
        :""}
        {!isMobile && <Carousel
          isRTL={false}
          breakPoints={breakPoints}
          renderArrow={arrow}
          showArrows={!isMobile}
          renderPagination={this.renderDotS}
        >
          {this.renderWelcomeCard(classes)}

          {storeUpcomingEvents?.length > 0 && this.renderCarouselCardContainer(storeUpcomingEvents, classes)}
        </Carousel>}
        
        
       
      </Container>
    );
  };

  // carousel functionality 



  renderDashBoardMainContent(
    classes: any,
    filteroptionid: any,
    storeUpcommingEvents: any,
    upComingPastEvent: any,
    breakPoints: any,
    myArrow: any,
    allEvents: any,
    selectedLocationEvents: any,
    selectedLocationWiseEventsMessage: any,
  ) {
    
    return (
      <>


        <Grid container>
          <Grid item xs={12} sm={7} className={classes.leftScroll}>
            {/* Card carousel section */}
            {this.renderCarousel(classes, this.props.storeUpcommingEvents?.data, breakPoints, myArrow)}
            {/* Card carousel section end */}

            {/* Tab block */}
            {this.renderUpCommingPastEvents(classes, selectedLocationEvents, selectedLocationWiseEventsMessage)}
            {/* Tab block end */}
          </Grid>

          <Grid item xs={12} sm={5} className={classesOuter.rightmenuDetails}>
            {this.renderHistoryRightEventsDetails(classes, allEvents)}
          </Grid>
        </Grid>
      </>
    );
  }

  // Customizable Area End

  render() {
    const { classes, theme}: any = this.props;
    const { upComingPastEvent, storeUpcommingEvents } = this.state;

    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 1, itemsToScroll: 1 },
      { width: 768, itemsToShow: 1 },
      { width: 1400, itemsToShow: 1 },
    ];

    const openfilter = Boolean(this.state.setAnchorEl);
    const filteroptionid = openfilter ? 'simple-popper' : undefined;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.eventType && <Redirect to={`${this.state.eventType}`} />}
        {/* DashboardWeb component */}
        {/* @ts-ignore */}

        {/* Main Content */}
        {!isMobile ? (
          // Container for non-mobile view
          <Container maxWidth="xl" >
            {this.renderDashBoardMainContent(
              classes,
              filteroptionid,
              storeUpcommingEvents,
              upComingPastEvent,
              breakPoints,
              this.myArrow,
              this.props.allEvents,
              this.props.selectedLocationEvents,
              this.props.selectedLocationWiseEventsMessageError,
            )}
          </Container>
        ) : (
          // Main content for mobile view
          this.renderDashBoardMainContent(
            classes,
            filteroptionid,
            storeUpcommingEvents,
            upComingPastEvent,
            breakPoints,
            this.myArrow,
            this.props.allEvents,
            this.props.selectedLocationEvents,
            this.props.selectedLocationWiseEventsMessageError,
          )
        )}
        {this.broadcastMessageDialog(classes)}

        {this.state.isDashaboardTextHover && <Popover
          data-test-id="notification-modal"
          open={this.state.isDashaboardTextHover}
          onClose={this.handleOnMouseLeave}
          style={{ height: "80%" }}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              borderRadius: "10px",
            }
          }}
        >
        <Box className={classes.hoverTextWrapper}>{this.state.carosalDetailHeadingText}</Box>
        </Popover>}

      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
HomeDashBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state: any) {
  return {
    selectedevents: state.eventsReducer?.selectedEvents,
    savedSearchInfo: state.savedSearchInfo?.saved_search,
    allEvents: state.eventsReducer?.allEvents,
    selectedLocationEvents: state.eventsReducer?.selectedLocationWiseEvents,
    selectedLocationWiseEventsMessageError: state.eventsReducer?.selectedLocationWiseEventsMessage,
    latitude: state.reducer?.latitude,
    longitude: state.reducer?.longitude,
    postcode: state.reducer?.postcode,
    location: state.reducer?.location,
  }
}



function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedEvents: (selectedTab: any) => {
      return dispatch(setSelectedEvents(selectedTab))
    },
    getAllEventsDetails: (selectedTab: any) => {
      return dispatch(getAllEventsDetails(selectedTab))
    },
    setSelectedFilterEvents: (selectedEvents: any) => {
      return dispatch(setSelectedFilterEvents(selectedEvents))
    },
    storeSearchDetails: (savedSearchinfo: any) => {
      return dispatch(storeSearchDetails(savedSearchinfo))
    },
    setAllEvents: (selectedEvents: any) => {
      return dispatch(setAllEvents(selectedEvents))
    },
    setLocationWiseFilterEvent: (selectedEvents: any) => {
      return dispatch(setLocationWiseFilterEvent(selectedEvents))
    },
    setLocationWiseFilterEventMessage: (selectedEvents: any) => {
      return dispatch(setLocationWiseFilterEventMessage(selectedEvents));
    },
  }
}

export { HomeDashBoard }
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any, { withTheme: true })(
  HomeDashBoard as any
));

// Customizable Area End
