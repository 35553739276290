// Customizable Area Start
import * as React from "react";
// Customizable Area End
import {
  // Customizable Area Start
  Typography, TextField, Container, Tabs, Tab, Avatar, Grid, Paper, ListItem, ListItemText, ListItemAvatar, IconButton
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import {
  constants,
  icons,
  FONTS,
  images
} from "../../../components/src/constants";
import { isMobile } from "react-device-detect";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import PropTypes from 'prop-types';
// @ts-ignore
import classesouter from "./GroupChat.module.css";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";

import GroupChatController, {
  Props,
  configJSON,
} from "./GroupChatController.web";
import { COLORS } from "../../../framework/src/Globals";
import IndivisualChat from "./IndivisualChat.web";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import './GroupChat.web.css'
import Skeleton from '@material-ui/lab/Skeleton';
import { userProfile } from "../../navigationmenu/src/assets";
import Spinner from "../../../components/src/Spinner.web";
// Customizable Area End

// Customizable Area Start
export type CounterProps = { label?: string };

export const styles = (theme: Theme) =>
  createStyles({
    indicator: {
      backgroundColor: 'white',
    },
    root: {
      flexGrow: 1,
    },
    tabsIndicator: {
      backgroundColor: "#359ce2",
      borderBottom: "5px solid #359ce2",
    },
    tabRoot: {
      textTransform: "initial",
      color: '#868282',
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      fontSize: '18px',
      fontFamily: 'Rene_Bieder_Milliard_Medium',
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$tabSelected": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    selectedTab: {
      color: '#40a9ff'
    },
    customAvatar: {
      width: '50px',
      height: '50px'
    },
    customListItem: {
      marginLeft: '15px',
    },
    customPrimaryText: {
      fontFamily: 'Rene_Bieder_Milliard_Medium, sans-serif',
      fontWeight: 600,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    customSubText: {
      fontSize: '18px',
      fontFamily: 'Rene_Bieder_Milliard_Light, sans-serif',
      fontWeight: 800,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    customSecondayText: {
      display: 'flex',
      gap: '10px',
      fontFamily: 'Rene_Bieder_Milliard_Medium, sans-serif',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      alignItem: 'center'
    },
    customTime: {
      display: "flex",
      justifyContent: "flex-end",
      fontFamily: 'Rene_Bieder_Milliard_Light, sans-serif',
      color: '#a7b6b9',
      fontWeight: 700,
      fontSize: '16px'
    },
    customTimeForGroups: {
      display: "flex",
      justifyContent: "flex-end",
      fontFamily: 'Rene_Bieder_Milliard_Light, sans-serif',
      paddingTop: '15px !important',
      color: '#a7b6b9'
    },
    listItemClicked: {
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        '& > li': {
          padding: '0px'
        },
        '& > li > div:nth-child(2)>span>div>div>p:nth-child(2)': {
          width: '35vw !important'
        }
      }
    },
    textSearchField: {
      '& input::placeholder': {
        fontSize: '18px'
      },
    },
    selectedChat: {
      border: '1px solid #4db0dd',
      borderRadius: '10px',
      [theme.breakpoints.down('sm')]: {
        border: "none",
        borderRadius: "none",
      },
    },
    searchSpace: {
      margin: '20px 20px 0px 20px',
      [theme.breakpoints.down('sm')]: {
        margin: '20px 5px 0px 0px',
      },
    },
    eventTime: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    hideAppBar: {
      '&>div>div>header': {
        display: 'none'
      },
      '&>div>div>div:nth-child(3)': {
        display: 'none'
      }
    }
});

// Customizable Area End



export class GroupChat extends GroupChatController {
  static propTypes: {
    // Customizable Area Start
    classes: PropTypes
    .Validator<object>; theme: PropTypes.Validator<object>;
    // Customizable Area End
  };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getReceiptMark(last_message_status: string) {
    let receiptMark = icons.singleTickGrey;
    if (last_message_status == 'read') {
      receiptMark = icons.GreenTick;
    } else if (last_message_status == 'delivered') {
      receiptMark = icons.doubleTickGrey;
    }
    return receiptMark;
  }
// All Tab Content
  renderChatsInformation(index: any, chatInfo: any, classes: any) {
    const isChatSelected = chatInfo.id === this.state.isSelectedChat;
    const receiptImg = this.getReceiptMark(chatInfo.attributes.last_message_status);
    return (
      <Grid data-testid="allTabChatInfo" key={index} 
      onClick={() => this.handleUserClick(chatInfo)}
      // className={`${classes.listItemClicked} ${isChatSelected ? classes.selectedChat : ''}`}
      >
        <Grid>
          <Grid item 
          className={`${classes.listItemClicked} ${isChatSelected ? classes.selectedChat : ''}`}     
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar alt="Profile Pic" 
                src={chatInfo.attributes.image?.url ?? images.avatar}
                classes={{ root: classes.customAvatar }} />
              </ListItemAvatar>
              <ListItemText 
                classes={{ root: classes.customListItem }} 
                primary={
                  <div>
                    <>
                      <div className={classes.eventTime}>
                        <Typography variant="body1" 
                        className={classes.customPrimaryText}>
                          {chatInfo.attributes.event_name}
                        </Typography>
                        <Typography 
                        classes={{ root: classes.customTime }}
                        style={this.state.toggleFunctionality ? {width: '7vw'} : {width: '6vw'}}
                        >
                          {chatInfo.attributes.clearedChatTime && chatInfo.attributes.clearedChatTime >= chatInfo.attributes.lastMessageActualTime ? null : chatInfo.attributes.last_message_times }
                        </Typography>
                      </div>
                      <Typography variant="body1" className={classes.customSubText}>
                        <small>{chatInfo.attributes.organisation_name}</small>
                      </Typography>
                    </>
                  </div>
                } 
                secondary={
                (chatInfo.attributes.clearedChatTime &&
                chatInfo.attributes.clearedChatTime >= chatInfo.attributes.lastMessageActualTime) ||
                !chatInfo.attributes?.last_message ? null :
                (
                  <Typography
                    style={{ color: COLORS.green }}
                    className={classes.customSecondayText}
                  >
                    {chatInfo.attributes.typing ? <span>Typing...</span> : <>
                      <img
                        src={receiptImg}
                        alt={chatInfo.attributes.last_message_status}
                        style={{height: '22px', width: '22px'}}
                      />
                      {chatInfo.attributes.last_message}
                    </>}
                  </Typography>
                )
                }
              />
            </ListItem>
          </Grid>
          <div
            style={{marginTop: '10px',marginBottom: '10px'}}
            className={classesouter.devideLine}
          />
        </Grid>
      </Grid>
    );
  }
  itemOrg(obj:{attributes:{member_data:[{}]}}){
    let name = "";
    const acc_id=localStorage.getItem("acc_id")
    obj?.attributes?.member_data.forEach((member:any) => {
    const memberid= member?.id.toString()
    if (memberid!==acc_id) {
      name=member?.full_name;
    }
  });
  return name
  }
  renderIndividualChatsInformation(index: any, chatInfo: any, classes: any) {
    const secondPerson = chatInfo.attributes.first_user_image.id != localStorage.getItem('acc_id') ? chatInfo.attributes.first_user_image : chatInfo.attributes.second_user_image;
    const isChatSelected = chatInfo.id === this.state.isSelectedChat;
    const receiptImg = this.getReceiptMark(chatInfo.attributes.last_message_status);
    return (
      <Grid data-testid="allTabChatInfoIndividual" key={index} 
        onClick={() => this.handleUserClick(chatInfo)}
      >
        <Grid>
          <Grid item
          className={`${classes.listItemClicked} ${isChatSelected ? classes.selectedChat : ''}`}   
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar alt="Profile Pic" src={secondPerson?.url?.url ?? userProfile} classes={{ root: classes.customAvatar }} />
              </ListItemAvatar>
              <ListItemText 
                classes={{ root: classes.customListItem }} 
                primary={
                  <div>
                    <>
                      <div className={classes.eventTime}>
                        <Typography variant="body1" 
                        className={classes.customPrimaryText}>
                          {chatInfo.attributes.friendly_name==='individualChatRoom'?this.itemOrg(chatInfo):secondPerson.name}
                        </Typography>
                        <Typography classes={{ root: classes.customTime }}>
                        {chatInfo.attributes.clearedChatTime && chatInfo.attributes.clearedChatTime >= chatInfo.attributes.lastMessageActualTime ? null : chatInfo.attributes.last_message_times }
                        </Typography>
                      </div>
                    </>
                  </div>
                } 
                secondary={
                  (chatInfo.attributes.clearedChatTime && chatInfo.attributes.clearedChatTime >= chatInfo.attributes.lastMessageActualTime) || !chatInfo.attributes?.last_message ? null : (
                    <Typography
                      className={classes.customSecondayText}
                      style={{ color: COLORS.green }}>
                    {chatInfo.attributes.typing ? <span>Typing...</span> : <>
                    <img src={receiptImg} alt={chatInfo.attributes.last_message_status} style={{height: '20px', width: '20px'}}/>
                      {chatInfo.attributes?.last_message}
                    </>}
                    </Typography>
                  )
                }
              />
            </ListItem>
          </Grid>
          <div className={classesouter.devideLine} style={{marginTop: '10px',marginBottom: '10px'}}/>
        </Grid>
      </Grid>
    );
  }

  renderchatsTabPart(classes: any) {
    return (
      <>
      {this.state.screen === 'individual-screen' && isMobile ? null : 
        <Paper elevation={0} style={{
          flexGrow: 1,
          position: 'sticky',
          top: 0,
          zIndex: 1
        }}>
          <Tabs
            value={this.state.chatcategoryvalue}
            indicatorColor="primary"
            data-testid="changeCategoryTest"
            onChange={this.handleChangeChatCategory}
            classes={{
              root: classes.tabRoot,
              indicator: classes.tabsIndicator,
            }}
            style={{ borderRadius: "12px", paddingTop: isMobile ? 0 : 20 }}
          >
            <Tab label="All" 
            href="#All"
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
            />
            <Tab href="#Group" label="Groups" classes={{ root: classes.tabRoot }}/>
            <Tab href="#Connection" label="Connections" classes={{ root: classes.tabRoot }}/>
          </Tabs>
          <div className={classesouter.devideLine}
            style={{
              position: 'sticky',
              top: 70,
              zIndex: 1
            }} 
          />
          <div>
          <div className={classes.searchSpace}>
          <TextField 
            data-testid='filterTest'
            variant="outlined"
            className={classes.textSearchField} 
            value={this.state.searchTerm}
            InputProps={{
              className: classes.input,
            }}
            onChange={this.searchChat}
            placeholder={this.renderSearchFilterPlaceHolder()} />
          <img src={icons.search} alt="icon" className="searchIcon" />
        </div>
      </div>
        </Paper>
      }
      </>
    )
  }

  renderChatContainerPart(classes: any){
    return(
      <div className={classesouter.chatContent} id="chatlisting" data-testid="tabTesting">
        {this.state.loading ? [...Array(10)].map((_, index) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="circle" className={classesouter.avatar} />
            <Skeleton variant="text" height={80} className={classesouter.textContent} />
          </div>
        )) : null}
        {!this.state.loading && this.state.userChats.length > 0 ? (
          this.state.userChats.map((chatInfo: any, index: any) => {
            return chatInfo.attributes.friendly_name == 'eventChatRoom' ? 
              this.renderChatsInformation(index, chatInfo, classes) :
              this.renderIndividualChatsInformation(index, chatInfo, classes)
          }
        )) : null}
        {!this.state.loading && this.state.userChats.length == 0 ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            marginTop: "110px",
            marginLeft: "20px",
          }}><p>No chats available</p></div>
        ) : null}
        {this.state.paginationLoading ? [...Array(5)].map((_, index) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="circle" className={classesouter.avatar} />
            <Skeleton variant="text" height={80} className={classesouter.textContent} />
          </div>
        )) : null}
    </div>
    )
  }

  renderSearchIconVisiblePart(classes: any){
    return(
      <div className={this.state.mobileView ? 'marginRight' : 'hideChat'}>
        <div className={classes.searchSpace}>
          <TextField 
            data-testid='filterTest'
            variant="outlined"
            className={classes.textSearchField} 
            value={this.state.searchTerm}
            InputProps={{
              className: classes.input,
            }}
            onChange={this.searchChat}
            placeholder={this.renderSearchFilterPlaceHolder()} />
          <img src={icons.search} alt="icon" className="searchIcon" />
        </div>
    </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, theme }: any = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Spinner  spinnerModal={this.state.loader} />
        {/* @ts-ignore */}
          <div className={this.state.toggleFunctionality && 
            isMobile && 
            this.state.screen === 'individual-screen' ? 
            classes.hideAppBar :
            null
          }>
          {/* @ts-ignore */}
          <DashboardWeb
            // title={this.state.mobileView ? "Chats" : ''}
            title="Chats"
            // showBottomBar={this.state.mobileView ? constants._icons.show : false}
            showBottomBar={constants._icons.show}
            navigation={this.props.navigation}
            showDrawerHeader={isMobile ? true : false}
            data-testId='mobileHeader'
          >
            <Grid container className={`groupChat ${classesouter.groupChatHeight}`} >
              <Grid item xs={12} sm={this.state.toggleFunctionality ? 6 : 12} 
              style={{ display: isMobile && this.state.toggleFunctionality ? "none" : "block" }}
              className={this.state.toggleFunctionality ? classesouter.individualChatMobile : `${this.state.loading && classesouter.margin63} ${classesouter.chatListScroll}`}
              >
                <Container className={classesouter.chatContainer} maxWidth={isMobile ? "xl" : "sm"}>
                  <div style={{ background: "white"}} >
                    {this.renderchatsTabPart(classes)}
                    {this.renderChatContainerPart(classes)}
                  </div>
                </Container>
              </Grid>
              {this.state.toggleFunctionality === true && this.state.screen === 'individual-screen' ? 
              <Grid item xs={12} sm={6} className={`${classesouter.individualChat} ${classesouter.chatWindowScroll}`} >
                <IndivisualChat
                key={this.state.childKey}
                data-testId="individual-chat"
                onMuteNotification={this.onMuteNotification}
                createIndividualConnection={this.createIndividualConnection}
                client={this.client} channelUniqueName={'test456'} chatUserInformation={this.state.currentChat} toggleType={this.toggleType} screenType={this.screenType} currentChatTyping={this.state.currentChatTyping}
                closeChatWindow={this.closeChat} clearChat={this.clearChat} chatRoomType={this.state.chatType}/>
              </Grid>: null}
              
            </Grid>
          </DashboardWeb>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

GroupChat.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles as any, { withTheme: true })(
  GroupChat as any
);

// Customizable Area End
