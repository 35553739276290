 {/* Customizable Area Start */}
 export const SET_ACTIVE_PLAN = 'SET_ACTIVE_PLAN'

 export const setActivePlanStatus= (activePlan: any) => ({
     type:SET_ACTIVE_PLAN,
     payload: {activePlan}
 })
 
 export function setActivePlan(activePlan: any){
     return (dispatch: (arg0: { type: string; payload: { activePlan: any } }) => void) => {
         dispatch(setActivePlanStatus(activePlan))
         localStorage.setItem("activePlan", JSON.stringify(activePlan));
     }
 }
 
  {/* Customizable Area End */}