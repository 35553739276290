import React from "react";
import PropTypes from "prop-types";
import UserProfileBasicController, {
    Props,
  } from "./UserProfileBasicController";
  import { isMobile } from "react-device-detect";
  import {Typography,Toolbar,IconButton,Container} from "@material-ui/core";
  import DashboardWeb from "../../dashboard/src/Dashboard.web";
  import { constants,  icons, FONTS,FAMILYFONTS} from "../../../components/src/constants";
  import { COLORS } from "../../../framework/src/Globals";
// @ts-ignore
import classesoutercss from "./UserProfileBasicBlock.module.css";

class BusinessProfileView extends UserProfileBasicController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderLogo(){
    return(
      <div>
                    {
                      this.state.apiUserDetail?.data?.attributes?.show_company_logo ?
                        <img
                          className={classesoutercss.userCompanyLogoImage}
                          src={this.state.apiUserDetail?.data?.attributes?.business_logo?.url}
                          alt="company-logo"
                          style={{
    
                            display:this.state.apiUserDetail?.data?.attributes?.business_logo?.url?"block":"none",
                            border:"none"

                          }}
                        />
                        :
                        null
                    }

                  </div>
    );
  }
  renderCompanyDetails(){
    return(
      <div className={classesoutercss.nameaddress1} >
                    <Typography
                      className={classesoutercss.nameCompanyFont}
                      style={{ ...FONTS.ProfileName ,whiteSpace:'nowrap'}}
                    >
                      Microsoft
                      
                    </Typography>

                    <Typography
                      className={classesoutercss.nameAddressFont}
                      style={{ ...FONTS.dbsForm ,marginTop:'8%' }}

                    >
                     Berkhshire,UK

                    </Typography>
                  </div>
    );
  }
  socials(){
    return(
      <div className={classesoutercss.row} style={{marginLeft:-13,marginBottom:50}}>
      <div className={classesoutercss.column}>
      <IconButton>
      <img src={icons.linkedin} width={37} height={37} />
      </IconButton>
      </div>
      <div className={classesoutercss.column}>
      <IconButton>
      <img src={icons.twitter} width={37} height={37} />
      </IconButton> 
      </div>
      <div className={classesoutercss.column}>
      <IconButton>
      <img src={icons.faceBook} width={37} height={37} />
      </IconButton>
      </div>
      <div className={classesoutercss.column}>
      <IconButton>
      <img src={icons.instagram} width={37} height={37} />
      </IconButton>
      </div>
        </div>
    );
  }
  headerWeb() {
    return (
      <div>
      <Toolbar className={classesoutercss.toolBar}>
        <div className={classesoutercss.backIconWeb}>
          <IconButton data-test-id="backButton"
            color={"inherit"}
            onClick={() => {this.props.navigation.navigate("UserProfileBasicBlock")}}
          >
            <img src={icons.back} />
          </IconButton>
          <Typography
                variant="h6"
                style={{ ...FONTS.dbsTitles, color: COLORS.black }}
                noWrap
              >
            Our Community Focus
          </Typography>
        </div>
      </Toolbar>
      <div style={{borderBottom:'solid 3px #F5F5F5'}}></div>
      </div>
    );
  }
  
           
  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
     <>
        {/* @ts-ignore */}
         <DashboardWeb
          showBackButton={constants._icons.hide}
          showBottomBar={constants._icons.show}
          showDrawerHeader={false}
          open={false}
          mobileOpen={false}
          navigation={this.props.navigation}
        >
          <Container maxWidth={"xl"}>
          
            <div className={classesoutercss.r1Wrapper}>
                {this.headerWeb()}
                <div className={classesoutercss.row} style={{marginLeft:''}}>
                  <div style={{ flex:-1,padding:isMobile?'20px':'0 80px',marginTop:isMobile?0:'5%'}}>
                    {this.renderLogo()}
                  </div>
                  <div style={{ flex:-1,padding:isMobile?'20px':'0 80px',marginTop:isMobile?0:'5%'}}>
                    {this.renderCompanyDetails()}
                  </div>
                </div>
                <div style={{borderBottom:'solid 3px #F5F5F5',marginTop:'5%',marginBottom:'2%'}}></div>
                <div style={{ display:'flex', width:'100%',marginLeft:isMobile?'-1%':'2%'}} className={this.checkSettingsScreen(isMobile) ? classesoutercss.nameCompany2 : classesoutercss.nameCompany}>
               <div  className={classesoutercss.r1Wrapper}>
                <Typography
                variant="h6"
                className={classesoutercss.nameCompanyFont}
                style={{ ...FONTS.dbsTitles, color: COLORS.black,marginBottom:'3%'}}
                noWrap
              >
              Our Community Focus
             </Typography>
             <Typography
             variant="h6" 
             className={classesoutercss.nameCompanyFont}
             style={{...FAMILYFONTS.family3 , color: COLORS.black,fontSize:17.5}}
             >
             Our community outreach initiative helps us to make a difference where help is needed. If you need help or know someone who benefit from our assistance, then get in touch. We're here to help.
             <br/>
             Our community outreach initiative helps us to make a difference where help is needed. If you need help or know someone who benefit from our assistance, then get in touch. We're here to help.
             </Typography>
             </div>
             </div>
             <div style={{borderBottom:'solid 3px #F5F5F5',marginTop:'3%',marginBottom:'2%'}}></div>

             <div  style={{marginLeft: isMobile ? '9%':'5%'}}>
             <Typography className={classesoutercss.nameCompanyFont}
                variant="h6"
                style={{ ...FONTS.dbsTitles, color: COLORS.black,marginBottom:'1.5%'}}
                noWrap
              >
            Visit us at:
             </Typography>
             <Typography className={classesoutercss.nameCompanyFont}
                variant="h6"
                style={{ ...FONTS.dbsTitles, color: COLORS.blue,marginBottom:'3%',cursor:'pointer'}}
                noWrap
              >
             microsoft.com
             </Typography>
            {this.socials()}
        
             </div>
            
              </div>
             
              </Container>
        </DashboardWeb>
     </>
    );
  }
      // Customizable Area End
}

// Customizable Area Start
BusinessProfileView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };

  export default BusinessProfileView ;
//   export default withStyles({ withTheme: true })(BusinessProfileView as any);
// Customizable Area End
  