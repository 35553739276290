import React, { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { HideContainer } from '../shared/hide-container';
import { InitiateLabel } from './label';
import { VerticalSpacer } from '../shared/vertical-spacer';
import { InitiateTextField } from './text-field';
import { icons } from "../constants";
import { InitiateAlert } from './alert';

const useDropdownStyles = makeStyles({
  dropdown: {
    position: 'relative',
  },
  dropdownIconBtn: {
    width: '40px',
    height: '40px',
  },
  paper: {
    boxShadow: '0px 4px 4px #e8e8e8',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
    width: '100%',
    position: 'absolute',
    top: '85px',
    zIndex: 10000,
  },
  optionText: {
    color: '#87888F',
    fontSize: '16px',
    fontFamily: 'Rene_Bieder_Milliard_Medium',
  },
  noRecordsText: {
    color: '#87888F',
    fontSize: '16px',
    fontFamily: 'Rene_Bieder_Milliard_Medium',
  },
});

export function InitiateDropdown({
  options,
  selectedOption,
  onSelect,
  placeholder,
  label,
  error,
}: {
  options: string[],
  selectedOption: string,
  onSelect: (value: string) => void,
  placeholder?: string,
  label?: string,
  error?: string,
}) {

  const classes = useDropdownStyles();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(selectedOption);
  const dropdownRef = useRef<any>(null);

  useEffect(function updateInputValue() {
    setInputValue(selectedOption);
  }, [selectedOption]);

  function handleOuterAreaClick(event: { target: any; }) {
    const dropdownNode = dropdownRef.current;
    if (dropdownNode && !dropdownNode.contains(event.target)) {
      setOpen(false);
    }
  }

  useEffect(function clickAwayListener() {
    document.addEventListener("click", handleOuterAreaClick);
  }, []);


  const filteredOptions = options
    .filter((option) =>
      !inputValue ?
        true
        :
        option.toLowerCase().includes(inputValue.toLowerCase())
    );

  const hasFilteredOptions = filteredOptions.length;


  return (
    <div
      ref={dropdownRef}
      className={classes.dropdown}>

      <HideContainer hideWhen={!label}>
        <InitiateLabel>
          {label}
        </InitiateLabel>
        <VerticalSpacer value={10} />
      </HideContainer>

      <InitiateTextField
        placeholder={placeholder}
        fullWidth
        variant="outlined"
        onChange={e => {
          setInputValue(e.target.value);
          setOpen(true);
        }}
        inputProps={{ "aria-label": "search" }}
        onClick={() => {
          setOpen(true);
        }}
        value={inputValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="open dropdown"
                color="inherit"
                onClick={(event) => {
                  event.stopPropagation();
                  setOpen(x => !x);
                }}
                className={classes.dropdownIconBtn}
              >
                <img
                  src={!open ?
                    icons.downarrowblue
                    :
                    icons.arrowup
                  }
                />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <HideContainer hideWhen={!open}>
        <Paper elevation={1} className={classes.paper}>
          <List dense>

            <HideContainer hideWhen={!hasFilteredOptions}>
              {
                filteredOptions.map((option) => (
                  <ListItem
                    key={option}
                    button
                    onClick={() => {
                      onSelect(option);
                      setOpen(false);
                    }}
                  >
                    <ListItemText
                      key={option}
                      primary={
                        <Typography
                          className={classes.optionText}>
                          {option}
                        </Typography>
                      }
                      data-testId={option}
                    />
                  </ListItem>
                ))
              }
            </HideContainer>
            <HideContainer hideWhen={hasFilteredOptions}>
              <Typography
                style={{ padding: 10 }}
                className={classes.noRecordsText}>
                {'No records found'}
              </Typography>
            </HideContainer>

          </List>
        </Paper>
      </HideContainer>

      <HideContainer hideWhen={!error}>
        <InitiateAlert>
          {error}
        </InitiateAlert>
      </HideContainer>

    </div>
  );
}

