// @ts-nocheck
import React from "react";

import {
    // Customizable Area Start
 IconButton, Popover, Typography,  Switch, Container,  Avatar, Grid, Paper, List,
    ListItem, ListItemText, ListItemAvatar, InputBase, InputAdornment, TextField, Box, Button, Checkbox,
    CircularProgress, Tabs, Tab, Card, CardContent
    // Customizable Area End
} from "@material-ui/core";

import MoreIcon from '@material-ui/icons/MoreVert';

import EmojiPicker from "emoji-picker-react";


// Customizable Area Start
import { isMobile } from "react-device-detect";
import {
    constants,
    images,
    icons,
    FONTS,
} from "../../../components/src/constants";
import PropTypes from 'prop-types';
// @ts-ignore
import classesouter from "./GroupChat.module.css";
import {
    withStyles,
    createStyles,
    ThemeProvider,
    Theme,
} from "@material-ui/core/styles";
import IndividualchatController, {
    Props
} from "./IndividualchatController.web";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ImageLoader from '../../../components/src/custom/ImageLoader';
import { InitiateSwitch } from "../../../components/src/design-system/switch";
import AppBar from '@material-ui/core/AppBar';
import Spinner from "../../../components/src/Spinner.web";
// Customizable Area End


// Customizable Area Start
export type CounterProps = { label?: string };

export const styles = (theme: Theme) =>
    createStyles({
        tabsIndicator: {
            backgroundColor: "#1890ff",
            borderBottom: "5px solid #359ce2",
        },
        root: {
            flexGrow: 1,
        },
        tabRoot: {
            textTransform: "initial",
            color: '#868282',
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: theme.spacing(1),
            fontSize: '18px',
            fontFamily: 'Rene_Bieder_Milliard_Medium',
            "&:hover": {
                color: "#40a9ff",
                opacity: 1,
            },
            "&$tabSelected": {
                fontWeight: theme.typography.fontWeightMedium,
            },
            "&:focus": {
                color: "#40a9ff",
            },
        },
        selectedTab: {
            color: '#40a9ff'
        },
        chatSection: {
            width: '100%',
            height: '80vh'
        },
        table: {
            minWidth: 650,
        },
        headBG: {
            backgroundColor: '#e0e0e0'
        },
        borderRight500: {
            borderRight: '1px solid #e0e0e0'
        },
        messageArea: {
            overflow: 'scroll',
            height: 'calc(100vh - 193px)',
            [theme.breakpoints.down('sm')]: {    
                width: '185vw',
                overflowY: 'scroll',
                marginTop: '67px'
            }, 
        },
        messageMobile: {
            overflow: 'scroll',
            height: '75%',
            [theme.breakpoints.down('sm')]: {    
                height: 'calc(75% - 325px)',
                width: '185vw',
                overflowY: 'scroll',
                marginTop: '115px'
            }, 
        },
        width100: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        alignLeftcss: {
            teaxtAlign: "left"
        },
        alignRighttcss: {
            textAlign: "right"
        },
        _search_logo: {
            height: 25,
            width: 25
        },
        headerSpacing: {
            marginTop: '20px'
        },
        profileIcon: {
            height: '50px',
            width: '50px'
        },
        customChatHead: {
            paddingLeft: '15px'
        },
        customGroupName: {
            color: '#019BE1',
            fontSize: '17px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        customGroupDesc: {
            fontSize: '16px',
            lineHeight: '30px',
            fontFamily: 'Rene_Bieder_Milliard_Light, sans-serif',
            fontWeight: 600,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '50vw'
        },
        moreIconBtn: {
            color: '#019BE1', 
            height: 32
        },
        totalMessage: {
            textAlign: 'center', 
            background: '#fdf8d0',
            padding: '13px',
            fontSize: '15px',
            fontFamily: 'Rene_Bieder_Milliard_Medium, sans-serif',
            marginBottom: '3px',
            marginLeft: '-30px',
            marginRight: '-30px',
            [theme.breakpoints.down('sm')]: {   
                marginLeft: '0px',
                marginRight: '0px',
            }
        },
        senderName: {
            color: '#019BE1', 
            fontWeight: 600,
            fontFamily: 'Rene_Bieder_Milliard_Medium, sans-serif',
            textTransform: 'capitalize'
        },
        senderMessage: {
            fontFamily: 'Rene_Bieder_Milliard_Light, sans-serif',
            color: '#fff',
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px'
            },
        },
        receiverMessage: {
            fontFamily: 'Rene_Bieder_Milliard_Light, sans-serif',
            color: '#000',
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px'
            },  
        },
        sendMessageLink: {
            color: '#fff'
        },
        receiverMessageLink: {
            color: '#000'
        },
        groupChat: {
            color: 'black',
            fontFamily: 'Rene_Bieder_Milliard_Book, sans-serif',
            fontSize: '18px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            cursor: 'default',
            gap: '13px',
            '&>img': {
                cursor: 'pointer',
                height: '23px',
                width: '23px'
            },
            [theme.breakpoints.down('sm')]: {
                '&>img': {
                    height: '23px',
                    width: '23px',
                    marginBottom: '4px',
                },
                display: 'flex',
                gap: '13px',
            }, 
        },
        imageGallery: {
            height: '50%'
        },
        clearChatModal: {
            width: '70%',
            position: "absolute",
            border: '1px solid #bbbbbb94',
            padding: '25px',
            borderRadius: '15px',
            // @ts-ignore
            zIndex: '3',
            backgroundColor: '#fff',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            [theme.breakpoints.down('sm')]: {
                left: '50%',
                top: '50%',
              },          
          },
          clearChatHead: {
            fontSize: '17px',
            fontFamily: 'Rene_Bieder_Milliard_Book, sans-serif',
            color: '#000',
          },
          clearChatSubHead: {
            color: 'grey',
            lineHeight: '25px',
            fontSize: '15px',
            marginBottom: '10px'
          },
          checkBox: {
            display: 'flex',
            gap: '20px',
            marginBottom: '10px',
            position: 'unset !important',
            width: 'unset !important'
          },
          darkButton: {
            display: 'flex',
            justifyContent: 'space-between'
          },
          groupMain: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '91vw'
            },
          },
          mediaTabs: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                alignItems: 'center',
                width: '91vw'
            },
          },
          groupIcon: {
            width: '150px',
            height: '150px',
            marginTop: '30px',
            [theme.breakpoints.down('sm')]: { 
                width: '100px',
                height: '100px',
                marginTop: '10px'
            }
          },
          groupProfileIcon: {
            width: '100%',
            height: '100%', 
            borderRadius: '50%', 
            objectFit: 'cover'
          },
          groupTextMain: {
            textAlign: 'center'
          },
          imageMediaShare: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            marginTop: '5px'
          },
          paddingMedia: {
            paddingBottom: '23px'
          },
          mobileWidth: {
            width: '100vw'
          },
          messageContainer: {
            position: 'relative',
            borderBottomRightRadius: '78px',
            border: '1px solid transparent'
          },          
          tickMarkContainer: {
            position: 'absolute',
            bottom: '-10px',
            right: '-5px'
          },
          mediaLoader: {
            display: 'flex',
            justifyContent: 'center',
            height: '17vh',
            width: 'auto',
            alignItems: 'center'
          },
          loader: {
            color: "#fff"
          },
          pinnedMessage: {
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingRight: '14px',
            gap: '10px',
            color: '#009BE2',
            marginBbottom: '10px'
          },
          moreIcon: {
            height: '25px'
          },
          listItemText: {
            padding: '7px 0px',
            '&.MuiListItem-button-455:hover': {
                textDecoration: 'none',
                backgroundColor: 'transparent',
            },
            cursor: 'pointer',
            [theme.breakpoints.down('md')]: {   
                padding: '7px 16px',
            }
        },
        upDownArrow: {
            padding: '15px 15px',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '50%'
            }
        },
        noDataFound: {
            '&:not(:first-child)':{
                display:"none"
            }
        },
        noDataFoundText: {
            fontFamily: 'Rene_Bieder_Milliard_Book',
            fontSize: '18px'
        },
        centerData: {
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {   
                width: "93vw"
            }
        },
        mediaImages: {
            height: 'auto',
            width: '100%'
        },
        mediaGrid: {
            height: 'max-content',
        },
        listingMedia: {
            height: '100%',
            overflowY: 'scroll'
        },
        listingMediaImages: {
            overflowY: 'scroll',
            alignContent: 'start'
        },
        devideLine: {
            mixBlendMode: 'normal',
            opacity: '0.14',
            border: '1px solid #979797',
            transform: 'matrix(1, 0, 0, -1, 0, 0)',
            marginTop: '10px',
            marginBottom: '10px'
        },
        tabPaper: {
            flexGrow: 1,
            position: 'sticky',
            top: 0,
            zIndex: 1
        },
        cursor: {
            cursor: 'pointer'
        },
        custoGroupInfo: {
            padding: '10px 0px',
            [theme.breakpoints.down('md')]: {
                padding: '0px',
            }
        },
        mediaTab: {
            margin: "5px 3px",
            overflow: "scroll",
            height: "calc(100vh - 132px)",
            [theme.breakpoints.down('md')]: {
                height: 'calc(100vh - 118px)',
            }
        }
    });
// Customizable Area End



export class IndivisualChat extends IndividualchatController {
    static propTypes: {
        classes: PropTypes // Customizable Area Start
        .Validator<object>; theme: PropTypes.Validator<object>;
    };
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderListItemText(classes: any) {
        return this.state.mediaScreen ? (
            <>
                <ListItemText 
                    classes={{ root: classes.custoGroupInfo }} 
                    data-testId='revertMediaBack'
                    primary={
                    <Typography className={classes.groupChat}>
                        <img data-testid="revertMediaBackIcon" onClick={() =>this.handleClose() } src={icons.back} alt="backIcon"/>
                        Medias and Links
                    </Typography>
                    } 
                />
            </> 
        ) : (
            <>
                <ListItemText 
                    classes={{ root: classes.custoGroupInfo }} 
                    data-testId='revertBack' 
                    primary={
                        <Typography className={classes.groupChat}>
                            <img data-testid="revertBackIcon" onClick={() => this.setState({ chatInfo: !this.state.chatInfo })} src={icons.back} alt="backIcon"/>
                            Group Info
                        </Typography>
                    } 
                />
            </> 
        )
    }
    orgName(obj:{attributes:{member_data:[]}}){
        let name = "";
        const acc_id=localStorage.getItem("acc_id")
        obj?.member_data?.forEach((member:any) => {
        const memberid= member?.id.toString()
        if (memberid!==acc_id) {
         name=member?.full_name;
        }
      });
      return name;
      } 
    renderListItemAvatar(classes: any, chatInformation: any){
        return (
        <>
            <ListItemAvatar 
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '13px'
            }}>
                {isMobile ? 
                <img 
                src={icons.back} 
                alt="backIcon" 
                onClick={this.handleProfileBackIcon}
                />:null}
                <Avatar
                    alt={`Avatar `}
                    src={chatInformation.image?.url ?? chatInformation.image}
                    className={classes.profileIcon}
                />
            </ListItemAvatar>
            <ListItemText 
                classes={{ root: classes.customChatHead }} 
                data-testId='groupInfo' 
                onClick={this.displayChatInfo}
                primary={
                    <>
                        <Typography variant="body1" className={classes.customGroupName}>
                         {this.props.chatRoomType==='individualChatRoom'?this.orgName(chatInformation):chatInformation.name}
                        </Typography>
                        <Typography className={classes.customGroupDesc}>
                            {this.state.typingStatus ? 'Typing...' : chatInformation.organisation_name}
                        </Typography>
                    </>
                }
            />
            {isMobile ? null : 
                <IconButton aria-label="search" color="inherit"  data-testid="searchBarTest" onClick={() => this.setState({searchBarChat: true})}>
                    <img className={classes._search_logo} src={icons.search} />
                </IconButton>          
            }                          
        </>
        )
    }

    renderSearchBar(classes: any){
        return(
            <>
            <IconButton classes={{ root: classes.Icon }} aria-label="display more actions" edge="end">
                {this.state.chatInfo ? 
                    null 
                    :
                    <img 
                    src={icons.moreIcon} 
                    className={classes.moreIcon}
                    alt="more"
                    data-testId='openPopover' 
                    onClick={(e) => this.setState({ anchorE1: e.currentTarget })}
                />
    }
            </IconButton>
            {this.state.searchBarChat ? 
                <div className={classesouter.SearchBarDiv}>
                    <IconButton aria-label="search" color="inherit"  data-testid="backBtnTest" onClick={() => this.setState({searchBarChat: false, searchTerm: ''})}>
                    <img className={classesouter._search_logo} src={icons.back} alt="backButton"/>
                    </IconButton>
                    <TextField placeholder={isMobile ? "Search..." : "Search Messages"} data-testid="searchboxText" onKeyDown={() => this.nextSearch('up')} value={this.state.searchTerm} onChange={this.onSearchChange}  className={classesouter.textSearch}/>
                        <img src={icons.arrowup} className={classes.upDownArrow} alt="arrowUp" data-testid="nextSearchArrow" onClick={() => this.nextSearch('up')}/>
                        <img src={icons.downarrowblue} className={classes.upDownArrow} alt="arrowDown" data-testid="previousSearchArrow" onClick={() => this.nextSearch('down')}/>
                </div>
            : 
            null}
            </>
        )
    }

    renderMediaScreen(classesouter: any,classes: any,chatInformation: any) {
        return (
            <div className={classes.root}>
                <Paper className={classes.tabPaper} elevation={0} position="sticky" color="inherit">
                    <Tabs
                        data-testid="mediaTabsMain"
                        variant="fullWidth"
                        value={this.state.mediaTabValue}
                        onChange={this.handleMediaTabChange}
                        aria-label="nav tabs example"
                        indicatorColor="primary"
                        classes={{
                            root: classes.tabRoot,
                            indicator: classes.tabsIndicator,
                        }}
                    >
                        <Tab data-testid="media_tab" label="Media" 
                            classes={{
                                root: classes.tabRoot,
                                selected: classes.selectedTab,
                            }}
                        />
                        <Tab data-testid="link_tab" label="Link" classes={{ root: classes.tabRoot }}/>
                    </Tabs>
                </Paper>
                <div
                    role="tabpanel"
                    // hidden={this.state.mediaTabValue != '0'}
                    id={`nav-tabpanel-0`}
                    aria-labelledby={`nav-tab-0`}
                    data-testid={`tabpanel-0`}
                >
                    {this.state.mediaTabValue == 0 ? (
                        <Box className={classes.mediaTab}>
                            <Grid container spacing={3} className={classes.listingMediaImages} ref={this.chatLinkRef} >
                                {this.state.groupMediaLinks.map((image) => (
                                    <Grid item xs={4} className={classes.mediaGrid}>
                                        <img className={classes.mediaImages} src={image.media_link} alt='images' />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    ) : null}
                </div>
                <div
                    role="tabpanel"
                    // hidden={this.state.mediaTabValue != '1'}
                    id={`nav-tabpanel-1`}
                    aria-labelledby={`nav-tab-1`}
                    data-testid={`tabpanel-1`}
                >
                    {this.state.mediaTabValue == 1 ? (
                        <Box className={classes.mediaTab}>
                            <div className={classes.listingMedia} ref={this.chatLinkRef}>
                                {this.state.groupHyperLinks.map((image) => (
                                    <>
                                        <Card className={classes.root}>
                                            <CardContent>
                                                <a href={image.media_link} target="_blank">{image.media_link}</a>
                                            </CardContent>
                                        </Card>
                                        <div className={classes.devideLine} />
                                    </>
                                ))}
                            </div>
                        </Box>
                    ) : null}
                </div>
            </div>
        );
    }

    renderGroupInformation(classesouter: any,classes: any,chatInformation: any){
        let memberData = chatInformation.member_data;
        return this.state.mediaScreen ? this.renderMediaScreen(classesouter, classes, chatInformation) : (
            <>
                <Box className={classes.groupMain}>
                    <Box className={classes.groupIcon}>
                        <img 
                        data-testid="groupImageTest"
                        src={chatInformation.image === undefined ? images.avatar : chatInformation.image} 
                        alt="groupIcon" 
                        className={classes.groupProfileIcon}
                        />
                    </Box>
                    <Box className={classes.groupTextMain}>
                        <Typography 
                        id={classesouter.groupInfosubTitleText1} 
                        >
                            {chatInformation.organisation_name}
                        </Typography>
                        <Typography 
                        className={classesouter.groupInfosubTitleText2} 
                        style={{fontFamily: 'Rene_Bieder_Milliard_Book',
                            fontSize: '20px'}}
                        >
                            {chatInformation.name}
                        </Typography>
                    </Box>
                </Box>
                <div className={classesouter.devideLine1}/>
                <div>
                    <Typography 
                    id={classesouter.groupdescription} 
                    style={{ ...FONTS.largeTitle }}
                    >
                        Group Description
                    </Typography>
                    <Typography 
                    className={classesouter.groupdescriptionText} 
                    >
                        {chatInformation.description}
                    </Typography>
                </div>
                <div className={classesouter.devideLine} style={{margin: "0px -30px"}}/>
                <div className={classesouter.switchParent}>
                    <Typography 
                        style={{fontFamily: 'Rene_Bieder_Milliard_Light, sans-serif',
                                fontWeight: 600}}>
                        Show organizer message only
                    </Typography>                    
                    <InitiateSwitch
                    data-testid="InitiateSwitchTest"
                    checked={this.state.organizarMessage}
                    onChange={this.toggleOrganiserMsg}
                    name="profileLogoSwitch"
                    aria-label="profileLogoSwitch"
                    defaultChecked data-test-id="profileLogoSwitch"
                    />
                    
                </div>
                <div className={classesouter.devideLine} style={{margin: "0px -30px"}}/>
                <div className={`${classes.cursor} ${classesouter.switchParent}`} data-testid='mediaScreen' onClick={this.state.groupMediaCount + this.state.groupLinkCount > 0 ? this.displayMediaAndLinks : null}>
                    <Typography style={{fontFamily: 'Rene_Bieder_Milliard_Medium',                
                            fontSize: '16px',
                            fontWeight: 600
                        }}>
                        Media, Links & Docs
                    </Typography>
                    <Typography>
                        {this.state.groupMediaCount + this.state.groupLinkCount}
                        <ArrowForwardIosIcon 
                            style={{ color: 'gray', fontWeight: 'bold', fontSize: '0.85rem' }} 
                        /> 
                    </Typography>
                </div>                           
                <div className={classesouter.imageGallery}>
                    {this.state.groupMediaLinks.slice(0, this.state.groupMediaLinks.length < 9 ? this.state.groupMediaLinks.length : 9).map((media, index) => 
                        <div key={index} className={classesouter.individualImageStyle}>
                            <img src={media.media_link} alt='images' />
                        </div>
                    )}
                </div>
                <div className={classesouter.devideLine4} />
                <Typography className={classesouter.groupInfoMember}>
                    {chatInformation.member_data.length} Members                                                             
                </Typography>
                {memberData.map((element,index) => 
                <div key={index} className={classesouter.switchParent}>
                    <div className={classesouter.groupInforDiv}>
                        <img 
                        src={!element.profile_picture?.url ? images.avatar : element.profile_picture?.url} 
                        alt={`pic-${index}`} className={classesouter.groupInfoUserPic} />
                        <span className={classesouter.groupInfoUserName}>{element.full_name}</span>
                    </div>
                    {(element.type !== "business_entity" && element.id.toString() !== localStorage.getItem("acc_id")) && 
                        <img
                            data-testId="direct-msg"
                            src={icons.chats} 
                            alt='pic' 
                            className={classesouter.groupInfoUserChat}
                            onClick={() => this.props.createIndividualConnection(element.id, element.type)}
                        />
                    }
                </div>
                )}
            </>
        )
    }

    groupFooterContainer(classesouter: any){
        return(
            <>
            {this.state.chatInfo === false && <div className={classesouter.groupFooterContainer}>
            {Object.keys(this.state.messages).length == 0 &&this.state.chatInformation.member_data[0]?.type === 'business_entity' ? (
                <div className={classesouter.groupFooterInput}>
                <InputBase
                    multiline={true}
                    data-test-id="chatInput"
                    placeholder={"Type something... (Max 150 characters)"}
                    fullWidth={true}
                    disabled={true}
                    onKeyDown={this.keyUp}
                    value={this.state.messageText}
                    startAdornment={
                        <InputAdornment position="start">
                            <IconButton
                                data-testid="btnEmoji"
                                aria-label="emoji button"
                                edge="end"
                                onClick={this.handleEmojiPicker}
                            >
                                <img
                                    src={icons.chatEmojiIcon}
                                    style={{width: '25px'}}
                                    alt="emoji icon"
                                />
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={this.typingMessage}
                    endAdornment={
                        <InputAdornment position="end">
                            <div style={{ position: 'relative' }}>
                                <input
                                    accept="image/*"
                                    data-test-id='fileInputImport'
                                    type="file"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        cursor: 'pointer',
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                        zIndex: '999'
                                    }}
                                    onChange={this.importData}
                                />
                                <IconButton
                                    edge="end"
                                    data-test-id="btnGallery"
                                    aria-label="gallery button"
                                    // onClick={this.importData}
                                >
                                    <img
                                        className={classesouter.groupIcon}
                                        style={{width: '25px'}}
                                        src={icons.chatGalleryIcon}
                                        alt="gallery Icon"
                                    />
                                </IconButton>
                            </div>
                        </InputAdornment>
                    }
                />
            </div>) :  <div className={classesouter.groupFooterInput}>
                    <InputBase
                        multiline={true}
                        placeholder={"Type something... (Max 150 characters)"}
                        fullWidth={true}
                        data-test-id="chatInput"
                        value={this.state.messageText}
                        onKeyDown={this.keyUp}
                        onChange={this.typingMessage}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    data-testid="btnEmoji"
                                    aria-label="emoji button"
                                    edge="end"
                                    onClick={this.handleEmojiPicker}
                                >
                                    <img
                                        src={icons.chatEmojiIcon}
                                        style={{width: '25px'}}
                                        alt="emoji icon"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        data-test-id='fileInputImport'
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            cursor: 'pointer',
                                            zIndex: '999'
                                        }}
                                        onChange={this.importData}
                                    />
                                    <IconButton
                                        data-test-id="btnGallery"
                                        aria-label="gallery button"
                                        edge="end"
                                        // onClick={this.importData}
                                    >
                                        <img
                                            style={{width: '25px'}}
                                            src={icons.chatGalleryIcon}
                                            alt="gallery Icon"
                                            className={classesouter.groupIcon}
                                        />
                                    </IconButton>
                                </div>
                            </InputAdornment>
                        }
                    />
                </div> }


                {Object.keys(this.state.messages).length == 0 &&this.state.chatInformation.member_data[0]?.type === 'business_entity' ? (
                                    <div className={classesouter.groupSendContainer} data-testid="sendMessageTest" >

                             <IconButton
                             data-test-id="btnSend"
                             aria-label="send button"
                             
                         >
                             <img
                                 src={icons.sendIcon}
                                 className={classesouter.groupIcon}
                                 alt="sendIcon"
                             />
                         </IconButton>
                         </div>
                        ) : 
                        <div className={classesouter.groupSendContainer} data-testid="sendMessageTest" onClick={() => this.sendMessage()}>
                        <IconButton
                        data-test-id="btnSend"
                        aria-label="send button"
                    >
                        <img
                            src={icons.sendIcon}
                            className={classesouter.groupIcon}
                            alt="sendIcon"
                        />
                    </IconButton>
                    </div>}
                   
                
            </div>}
            </>
        )
    }

    renderEmojiPicker(classesouter: any){
        return (
        <>
            {this.state.emojiPicker ?
            <div className={classesouter.emojiPicker} id="emojiListing">
                <EmojiPicker 
                    width="100%"    
                    skinTonesDisabled
                    searchDisabled
                    emojiStyle="google"
                    data-testid="EmojiPickerReact"
                    onEmojiClick={this.handleEmojiSend}
                /> 
            </div>:null}
        </>
        )
    }
    
    makeLinksClickable = (messageText, sendMessageLink, classes) => {
        const urlRegex = /(https?:\/\/[^\s]+)/;    
        const messageParts = messageText.split(urlRegex);    
        const formattedText = messageParts.map((part, index) => {
          if (urlRegex.test(part)) {
            return (
              <a
                key={index}
                href={part}
                target="_blank"
                style={{textDecoration: 'underline'}}
                className={sendMessageLink ? classes.sendMessageLink : classes.receiverMessageLink}
              >
                {part}
              </a>
            );
          } 
          else {
            return part;
          }
        });
        return formattedText;
    };

    handleMessageSendLink(message, sendMessageLink, classes) {
        return (
            <div>
                {this.makeLinksClickable(message.body, sendMessageLink, classes)}
            </div>
        )
    }

    displayChats(data, classes, classesouter) {
        return <>
            {data.map((message, index) => {
                let myMsgs = false;
                if (message.customAttributes &&
                    message.customAttributes.senderId == this.state.userId &&
                    message.customAttributes.senderType == 'user_entity'
                ) {
                    myMsgs = true;
                }
                if (this.state.organizarMessage === true &&
                    message.customAttributes.senderType != 'non_profit_entity') {
                    return
                }
                const pinned = message.customAttributes.pinned;
                let msgTick = icons.singleTick;
                if (this.state.lastReadMessageIndex >= message.index) {
                    msgTick = icons.GreenTick
                } else if (this.state.lastDeliveredMessageIndex >= message.index) {
                    msgTick = icons.doubleTick;
                }
                return (
                    <>
                        {myMsgs ? (
                            <ListItem key={index} 
                            className={`${classesouter.listGroupChatOther} ${classesouter.marginLeft} chatWrapper`}
                            >
                                <Grid container >
                                    <Grid item xs={12}>
                                        {message.type == 'media' ? (
                                            <Box textAlign="center">
                                                <ImageLoader
                                                    loaderStyle={{
                                                        color: "#fff",
                                                        marginTop: "20px",
                                                        alignSelf: "center"
                                                    }}
                                                    classes={classes.imageMediaShare}
                                                    imageUrl={message.media}
                                                    alt="media"
                                                    scrollToBottom={this.scrollToBottom}
                                                />
                                            </Box>
                                        ) : (
                                            <ListItemText             
                                            secondary={
                                            <div className={classes.messageContainer}>
                                                <small className={classes.senderMessage}>
                                                    <span className="messagesContent">{this.handleMessageSendLink(message, true, classes)}</span>
                                                </small>
                                                <div className={classes.tickMarkContainer}>
                                                    <img src={msgTick} alt='doubleTick' style={{height: '20px', width: '20px'}}/>
                                                </div>
                                            </div>
                                            }>
                                            </ListItemText>
                                        )}
                                        
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ListItemText secondary={<small className={classesouter.timeShowingOther} style={{ color: '#fff' }}>
                                            {new Date(message.timestamp.toString()).toLocaleString('en-US', {
                                                hour: 'numeric', minute: 'numeric', hour12: true
                                            })}</small>}>
                                        </ListItemText>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ) : (
                            <>
                                <ListItem key={index} 
                                className={`${classesouter.listGroupChatOther} ${classesouter.receiveWidth} chatWrapper`}
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {message.type == 'media' ? (
                                                <Box display="flex" flexDirection="column">
                                                    <span style={{fontSize: '1rem'}}>
                                                        <small className={classes.senderName}>
                                                        {this.userFullName(message)}
                                                        </small> 
                                                    </span>                                   
                                                    <ImageLoader
                                                        loaderStyle={{
                                                            alignSelf: "center"
                                                        }}
                                                        scrollToBottom={this.scrollToBottom}
                                                        classes={`${classes.imageMediaShare}`}
                                                        imageUrl={message.media}
                                                        alt="mediaReceived"
                                                    />
                                                </Box>
                                            ) : (
                                                <ListItemText
                                                    primary={
                                                        <small className={classes.senderName}>
                                                        {this.userFullName(message)}
                                                        </small>
                                                    } 
                                                    secondary={<small className={classes.receiverMessage}><span className="messagesContent">{this.handleMessageSendLink(message, false, classes)}</span></small>}>
                                                </ListItemText>
                                            )}
                                                    
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ListItemText secondary={<small className={classesouter.timeShowingOther} style={{ color: '#B2B2B2' }}>
                                                {new Date(message.timestamp.toString()).toLocaleString('en-US', {
                                                    hour: 'numeric', minute: 'numeric', hour12: true
                                                })}</small>}></ListItemText>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                {pinned ? (
                                    <Box className={classes.pinnedMessage}>
                                        <Typography style={{fontSize: '14px',fontFamily: 'Rene_Bieder_Milliard_Medium',}}>Message pinned by Organiser </Typography>
                                        <img src={icons.pinIcon} alt="pinIcon" style={{width: '20px', height: '20px'}} /> 
                                    </Box>
                                ) : null}
                            </>
                        )}
                    </>
                )
            })}
        </>
    }
    userFullName = (message: any) => {

        return this.state.chatInformation.member_data
            .filter(item => item.id == message.customAttributes.senderId &&
            message.customAttributes.senderType.toLowerCase() === item.type.toLowerCase())[0]?.full_name  || this.entityAdminFullName()
    }

    entityAdminFullName = () => {
        const entityAdmin = this.state.chatInformation.member_data.find(item => item.type.toLowerCase() === "non_profit_entity")
        return entityAdmin ? entityAdmin.full_name : "No Entity Admin Found";
    }

    shouldRenderGroupInfo = (chatInformation: any) => {
        return isMobile && chatInformation.member_data.length > 0;
    }

    deleteModal = () => {
        return this.state.deleteModal === true;
    }

    chatInfoDevider = (classesouter) => {
        return  this.state.chatInfo ? 
                <div className={classesouter.devideLineGroup}/>
            :   <div className={classesouter.marginAboveMessage}/>
            
    }

    ListItem = (classes, chatInformation) => {
        return this.state.chatInfo ? (
            this.renderListItemText(classes)
        ) : (
            this.renderListItemAvatar(classes, chatInformation)
        )
    }

    renderGroupInformationHeader = (chatInformation) => {
        return this.shouldRenderGroupInfo(chatInformation) && <div className="popoverItems" data-testid="groupInfoTest" onClick={() => this.setState({chatInfo: true,anchorE1: null})}> Group info</div> 
    }

    handleSearchHeader = () => {
        return isMobile && <div className="popoverItems" onClick={() => this.setState({searchBarChat: true,anchorE1: null})}>Search</div>
    }

    handleExitGroupHeader = (chatInformation) => {
        return chatInformation.member_data.length>0 &&
            <div className="popoverItems" data-testid='exitGroupBtn' onClick={this.exitGroup}> Exit Group </div>
    }

    handleDataOrganizer = (messages) => {
        return this.state.organizarMessage
        ? messages.filter(item => item.customAttributes.senderType === "non_profit_entity")
        : messages;
    }
    
    setMessageCount=()=>{
    const msgCount = Object.values(this.state.messages)
    .reduce((acc, dateData) => acc + dateData.data.length, 0);
  return msgCount;
    }

    getClasses = (classes) => {
        return `${(this.state.loading || this.state.msgLoading) && classes.width100} ${this.state.emojiPicker? classes.messageMobile : classes.messageArea}`;
    }

    renderGroupChatList = (classes, classesouter) => {
        return (
            <List
                className={this.getClasses(classes)}
                ref={this.chatMessagesRef}
                id="messagesListing" >
                {this.state.loading || this.state.msgLoading ? <div
                    style={{
                        height: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress style={{ color: "#009be2" }} />
                </div> : (
                    <>
                        {Object.keys(this.state.messages).map(dateKey => {
                            const messages = this.state.messages[dateKey].data;
                            const data =  this.handleDataOrganizer(messages);
                            return data.length > 0 ? (
                                <div key={dateKey}>
                                    <p className={classesouter.dateIssue}>{dateKey}</p>
                                    {this.displayChats(data, classes, classesouter)}
                                </div>
                            ) : null                                                          
                        })}
                        {!this.state.msgLoading && this.state.organizarMessageCount === 0 && this.state.organizarMessage ? (
                            <div data-testid="organizer_no_data" className={`${classes.noDataFound} ${classes.centerData} ${classes.noDataFoundText}`}><p>No data found</p></div>  
                        ) : null}
                        {(Object.keys(this.state.messages).length === 0 && !this.state.msgLoading && !this.state.loading) &&
                            <div data-testid="organizer_no_data" className={`${classes.noDataFound} ${classes.centerData} ${classes.noDataFoundText}`}><p>No chat available</p></div>
                        }
                        {Object.keys(this.state.messages).length == 0 &&this.state.chatInformation.member_data[0].type === 'business_entity' ? (
                            <div data-testid="organizer_no_data" className={`${classes.centerData} ${classes.noDataFoundText}`}><p>Wait for the Business Organization to initiate the chat...</p></div>  
                        ) : null}
                        {this.state.showMediaLoading ? (
                            <ListItem
                                className={`${classesouter.listGroupChatOther} ${classesouter.marginLeft} chatWrapper`}
                            >
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Box textAlign="center">
                                            <ImageLoader
                                                loaderStyle={{
                                                    color: "#fff",
                                                    marginTop: "20px",
                                                    marginBottom: "20px",
                                                    alignSelf: "center"
                                                }}
                                                classes={classes.imageMediaShare}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ) : null}
                    </>
                )}   
            </List>
        );
    }
    // Customizable Area End

    render() {
        const { classes, theme }: any = this.props;
        const { chatInformation }: any = this.state;
                return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Spinner spinnerModal={this.state.loader} />
                <div style={{ background: "white" }}>
                    <ListItem
                        button={isMobile}
                        classes={{ root: classes.listItemText }}>     
                        {this.ListItem(classes, chatInformation )}
                        {this.renderSearchBar(classes)}
                    </ListItem>
                    {this.chatInfoDevider(classesouter)}
                    <div style={this.state.chatInfo ? {display: 'none'} : {display: 'block'}} 
                    className={classes.totalMessage}>Total Messages :{this.setMessageCount()}</div>
                    {this.state.chatInfo ?
                        this.renderGroupInformation(classesouter ,classes,chatInformation)
                        :
                        this.renderGroupChatList(classes, classesouter)}
                        {this.groupFooterContainer(classesouter)}
                        {this.renderEmojiPicker(classesouter)}
                </div>
                <Popover
                    open={Boolean(this.state.anchorE1)}
                    anchorEl={this.state.anchorE1}
                    onClose={() => this.setState({ anchorE1: null })}
                    data-testId='closePopover'
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {this.renderGroupInformationHeader(chatInformation)}
                    <div className={`${classes.cursor} popoverItems`} onClick={()=>{this.setState({mediaPopup:true,chatInfo:true,mediaScreen:true,anchorE1: null})}}> Media, and links </div> 
                    {this.handleSearchHeader()}
                    <div className={`${classes.cursor} popoverItems`} data-testid="deletedModalTest" onClick={() => this.setState({deleteModal: true,anchorE1: null})}> Clear Chat  </div>
                    <div className={`${classes.cursor} popoverItems`} data-testid="muteNotification" onClick={this.onMuteNotification}> {this.state.isMute ? "Unmute" : "Mute" } Notifications </div>
                    <div>
{ this.state.chatInformation?.member_data[0]?.type === "non_profit_entity"? (<div> {this.handleExitGroupHeader(chatInformation)}</div>):null }
</div>
                </Popover>
                {this.deleteModal() &&
                  <Box className={classes.clearChatModal}>
                    <Typography className={classes.clearChatHead}>Clear chat</Typography>
                    <Typography className={classes.clearChatSubHead}>( Messages will be delete for permanently ).</Typography>
                    <Box className={classes.checkBox}>
                      <Checkbox
                        onClick={this.onChangeClearChat}
                        value={1}
                        data-testid="clearchat24hour"
                        checked={this.state.clearChatOption == 1}
                        style={{margin: '0px',padding: '0px'}}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                          </svg>
                        }
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke='#019BE1' fill='#019BE1' stroke-width="1" />
                            <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                          </svg>
                        }
                        disableRipple
                        color="primary"
                      />
                      <Typography style={{fontFamily: 'Rene_Bieder_Milliard_Book'}}>Over 24 hours</Typography>
                    </Box>
                    <Box className={classes.checkBox}>
                      <Checkbox
                        onClick={this.onChangeClearChat}
                        checked={this.state.clearChatOption == 2}
                        value={2}
                        style={{margin: '0px',padding: '0px'}}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                          </svg>
                        }
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke='#019BE1' fill='#019BE1' stroke-width="1" />
                            <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                          </svg>
                        }
                        disableRipple
                        color="primary"
                      />
                      <Typography style={{fontFamily: 'Rene_Bieder_Milliard_Book'}}>Over 1 week</Typography>
                    </Box>
                    <Box className={classes.checkBox}>
                      <Checkbox
                        value={3}
                        onClick={this.onChangeClearChat}
                        checked={this.state.clearChatOption == 3}
                        style={{margin: '0px',padding: '0px'}}
                        icon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                          </svg>
                        }
                        checkedIcon={
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke='#019BE1' fill='#019BE1' stroke-width="1" />
                            <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                          </svg>
                        }
                        disableRipple
                        color="primary"
                      />
                      <Typography style={{fontFamily: 'Rene_Bieder_Milliard_Book'}}>Over 1 month</Typography>
                    </Box>
                    <Box className={classes.darkButton}>
                      <Button
                      variant="outlined"
                      style={{
                          width: '48%',
                          boxShadow: "none",
                          border: '1px solid #009be2',
                          textTransform: "capitalize",
                          color: "#009be2",
                          fontFamily: "Rene_Bieder_Milliard_Medium",
                          borderRadius: 10,
                          marginTop: '10px'
                      }}
                      color="primary"
                      data-testid="cancelButton"
                      onClick={() => this.setState({deleteModal: !this.state.deleteModal})}
                      >
                        Cancel    
                      </Button>
                      <Button
                      data-testid="modalTest"
                      variant="outlined"
                      style={{
                          width: '48%',
                          boxShadow: "none",
                          textTransform: "none",
                          backgroundColor: " #009be2",
                          color: "white ",
                          fontFamily: "Rene_Bieder_Milliard_Medium",
                          borderRadius: 10,
                          border: "none",
                          marginTop: '10px'
                      }}
                      color="primary"
                      onClick={this.clearChat}
                      disabled={this.state.clearChatOption < 1}
                      >
                          Clear chat   
                      </Button>
                    </Box>
                  </Box>
                }
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

IndivisualChat.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

// Customizable Area End

export default withStyles(styles as any, { withTheme: true })(
    IndivisualChat as any
);

// Customizable Area End
