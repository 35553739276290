 {/* Customizable Area Start */}
 export const SET_SELECTED_EVENTS = 'SET_SELECTED_EVENTS'
 export const SET_SELECTED_LOCATION_WISE_EVENTS = 'SET_SELECTED_LOCATION_WISE_EVENTS'
 export const SET_SELECTED_LOCATION_WISE_EVENTS_MESSAGE = 'SET_SELECTED_LOCATION_WISE_EVENTS_MESSAGE'
 export const SET_ALL_EVENTS = 'SET_ALL_EVENTS'

 export const setSelectedEventSuccess= (selectedEvents: any) => ({
     type:SET_SELECTED_EVENTS,
     payload: {selectedEvents}
 })
 
 export function setSelectedEvents(selectedEvents: any){
   
     return (dispatch: (arg0: { type: string; payload: { selectedEvents: any } }) => void) => {
         dispatch(setSelectedEventSuccess(selectedEvents))
         localStorage.setItem("selectedEvents", JSON.stringify(selectedEvents));
     }
 }
 
 export const setAllEventsSuccess= (selectedEvents: any) => ({
    type:SET_ALL_EVENTS,
    payload: {selectedEvents}
 })

 export function setAllEvents(selectedEvents: any){ 
    return (dispatch: (arg0: { type: string; payload: { selectedEvents: any; }; }) => void) => {
        dispatch(setAllEventsSuccess(selectedEvents))
    }
 }

 export const setLocationWiseFilterEventsSuccess= (selectedEvents: any) => ({
     type:SET_SELECTED_LOCATION_WISE_EVENTS,
     payload: {selectedEvents}
 })
 
 export function setLocationWiseFilterEvent(selectedEvents: any){
    
   
     return (dispatch: (arg0: { type: string; payload: { selectedEvents: any; }; }) => void) => {
         dispatch(setLocationWiseFilterEventsSuccess(selectedEvents))
     }
 }
 
 export const setLocationWiseFilterEventMessageSuccess= (message: any) => ({
    type:SET_SELECTED_LOCATION_WISE_EVENTS_MESSAGE,
    payload: {message}
})

export function setLocationWiseFilterEventMessage(message: any){
    return (dispatch: (arg0: { type: string; payload: { message: any; }; }) => void) => {
        dispatch(setLocationWiseFilterEventMessageSuccess(message))
    }
}

 {/* Customizable Area End */}