import React from "react";

// Customizable Area Start
import EmailNotificationsController, {
  Props,
} from "./EmailNotificationsController";
import { EmailNotificationContent } from "./EmailNotificationsContent";
// Customizable Area End

class EmailNotifications extends EmailNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <EmailNotificationContent />
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default EmailNotifications;
export { EmailNotifications }
// Customizable Area End
