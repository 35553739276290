import React from "react";

import {
  // Customizable Area Start
  Typography,
  Box,
  Container,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ContentManagement from "../../ContentManagement/src/ContentManagement";
// @ts-ignore
import classesoutercss from "./NpSurveys.module.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import NpSurveysController, {
  Props, SurveyData
} from "./NpSurveysController";

class NpSurveys extends NpSurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

renderDashboardCardsHeader = () => {
    return (
        <Grid>
            <Box className={classesoutercss.headerContainer}>
                <Typography variant="subtitle1" className={classesoutercss.surveyText}>
                    Survey
                </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={4}>
                        <Paper className={classesoutercss.subContent}>
                            <Box className={classesoutercss.titleContainer}>
                                <Typography>Live surveys</Typography>
                                <Typography variant="h5">{this.state.liveSurveyData?.length ?? 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item sm={6} xs={4}>
                        <Paper className={classesoutercss.subContent}>
                            <Box className={classesoutercss.titleContainer}>
                                <Typography>Completed surveys</Typography>
                                <Typography variant="h5">{this.state.completedSurveyData?.length ?? 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>

                </Grid>
            </Box>
        </Grid>
    )
}

renderLiveSurveysList = () => {
    return (
        <Box>
            <Typography variant="subtitle1" className={classesoutercss.subInnerHead}>
                Live surveys
            </Typography>
            {(this.state.liveSurveyData?.length === 0 || this.state.liveSurveyData === undefined) &&
                <div style={{ background: 'white', padding: "20px", borderRadius: 12, textAlign: 'center' }}>
                    <Typography>No surveys available</Typography>
                </div>
            }
            <Box className={classesoutercss.surveyAccordion}>
                {this.state?.liveSurveyData?.slice(0,this.state.itemsToShow)?.map((item: any, index: number) => {
                    return (
                        <Accordion expanded={true} key={item.id} onClick={() => this.navigateToSurveyPage(item)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: '#009be2', rotate: '90deg' }} />}
                                aria-controls="survey-content"
                                id="survey-header"
                            >
                                <div className={classesoutercss.listBroadcasts}>
                                    <Typography className={classesoutercss.listDesc}><span>{index + 1}.</span> {item.title}</Typography>
                                    <Typography className={classesoutercss.listWrap}>{item.participants}</Typography>
                                    <Typography variant="subtitle2" className={classesoutercss.listTime}>{moment(item.start_date).format("DD/MM/YYYY")}  <span className={classesoutercss.totime}> to </span> {moment(item.end_date).format("DD/MM/YYYY")}</Typography>
                                </div>
                            </AccordionSummary>
                        </Accordion>
                    )
                })}
                {this.renderShowMoreText(this.state.liveSurveyData)}
            </Box>
        </Box>
    )
}

renderDraftSurveysList = () => {
  return (
      <Box>
          <Typography variant="subtitle1" className={classesoutercss.subInnerHead}>
              Draft surveys
          </Typography>
          {(this.state.draftSurveyData?.length === 0 || this.state.draftSurveyData === undefined) &&
              <div style={{ background: 'white', padding: "20px", borderRadius: 12, textAlign: 'center' }}>
                  <Typography>No surveys available</Typography>
              </div>
          }
          <Box className={classesoutercss.surveyAccordion}>
              {this.state?.draftSurveyData?.slice(0,this.state.itemsToShow)?.map((item: any, index: number) => {
                  return (
                      <Accordion expanded={true} data-testId="accordionDraft" onClick={() => this.openDraftSurvey(item.id)}>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon style={{ color: '#009be2', rotate: '90deg' }} />}
                              aria-controls="survey-content"
                              id="survey-header"
                          >
                              <div className={classesoutercss.listBroadcasts}>
                                  <Typography className={classesoutercss.listDesc}><span>{index + 1}.</span> {item.title}</Typography>
                                  <Typography className={classesoutercss.listWrap}>{item.participants}</Typography>
                                  <Typography variant="subtitle2" className={classesoutercss.listTime}>{moment(item.created_at).format("DD/MM/YYYY")} <span style={{ fontWeight:1000, margin: '0 10px !important'}}> to </span>{moment(item.end_date).format("DD/MM/YYYY")}</Typography>
                              </div>
                          </AccordionSummary>
                      </Accordion>
                  )
              })}
              {this.renderShowMoreText(this.state.draftSurveyData)}
          </Box>
      </Box>
  )
}

renderShowMoreText = (arrayData: any) => {
    return (
        arrayData?.length > 0 && this.state.itemsToShow < arrayData.length && <Typography variant="subtitle1" onClick={() => this.handleShowMore()} className={classesoutercss.showMoreLink}>Show more <ChevronRightIcon /> </Typography>
    )
}

renderCompletedSurveys = () => {
    return (
        <Box>
            <Typography variant="subtitle1" className={classesoutercss.subInnerHead}>
                Completed surveys
            </Typography>
            {(this.state.completedSurveyData === undefined || this.state.completedSurveyData?.length === 0) &&
                <div style={{ background: 'white', padding: "20px", borderRadius: 12, textAlign: 'center' }}>
                    <Typography>No surveys available</Typography>
                </div>
            }
            {this.state.completedSurveyData?.map((item: SurveyData, index: number) => {
                return (
                    <Box key={item.id} data-testId={`completed-${index}`} className={classesoutercss.surveyAccordion}>
                        <div>
                            <Box className={classesoutercss.completeSurveyBox}>
                                <div className={classesoutercss.listBroadcasts}>
                                    <Typography className={classesoutercss.listDesc}><span>{index + 1}.</span> {item.title}</Typography>
                                    <Typography className={classesoutercss.listWrap}></Typography>
                                    <Button variant="contained" className={classesoutercss.viewresults} data-testId='viewresults' onClick={() => this.navigateToSurveyPage(item)}>View results</Button>
                                </div>
                            </Box>
                        </div>
                    </Box>
                )
            })}
        </Box>
    )
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <ContentManagement navigation={this.props.navigation} id={""} showBackButton={undefined} title={undefined} showBottomBar={false} showDrawerHeader={false} mobileOpen={false} open={false} classes={undefined} history={undefined} titleColor={undefined} showLine={undefined} cancelClick={undefined} confirmClick={undefined} selectedAdminTab={undefined} tabsFunction={undefined} children={undefined} onSecondTabsCreation={undefined} eventUpdates={undefined} SingleEvent={undefined} single_event_details={undefined} createNeedEvent={undefined} createActivitiesEvent={undefined} projectsEvent={undefined} pledgesDetails={undefined} eventPledgesDetails={undefined} navigationToNeeds={undefined} navigationToActivitiesList={undefined} navigationToProjectsList={undefined} adminSelectedTab={undefined} createEvents={undefined} setSelectedAdminTab={undefined} onEventCreation={undefined} setOfferRoleAction={undefined} offerRoleFormData={undefined} setActivePlan={undefined} resetEventTab={undefined} setSelectedRow={undefined}>
          <Container maxWidth={'xl'} className='ContentManagementUIDiv'>
            {this.renderDashboardCardsHeader()}
            {this.renderLiveSurveysList()}
            {this.renderDraftSurveysList()}
            {this.renderCompletedSurveys()}
            <div className={classesoutercss.addQuestionBox}>
                <Button 
                  className={classesoutercss.descriptionSubmitBtn} data-test-id="btnCreateSurveys"
                  variant="contained"
                  onClick={() => this.props.navigation.navigate("NpCreateSurveys")}
                >
                  <span className={classesoutercss.addIcon}>+</span>  Create a survey
                </Button>
            </div>
          </Container>
        </ContentManagement>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  descPagination: {
    "& .Mui-selected": {
      color: '#009BE2'
    }
  },
};

NpSurveys.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export {NpSurveys}
export default withStyles(webStyle as any, { withTheme: true })(NpSurveys as any);
// Customizable Area End