// Customizable Area Start
import React from "react";

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

import { EmailVerificationPage } from "./EmailVerificationContent";


// Customizable Area End
class EmailVerification extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    // Customizable Area End

    return (
      // Customizable Area Start
        <EmailVerificationPage/>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default EmailVerification
// Customizable Area End
