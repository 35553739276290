 // Customizable Area Start
import React from "react";
import AdminRegistrationController, { Props } from "./AdminRegistrationController";
//@ts-ignore
import classOuter from './NonProfitLandingPage.module.css'
import {
  // Customizable Area Start
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography, Button,
  Switch, List, ListItem, ListItemText, Box,
  BottomNavigation, BottomNavigationAction, Badge, Avatar, InputLabel, MenuItem, FormControl, Select,
   TextField, FormControlLabel, Checkbox, Link,
  Grid, Container,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle
  // Customizable Area End
} from "@material-ui/core";

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import {
  FONTS, icons, images
} from "../../../components/src/constants";
import AdminAccountCreationLayout from "../../../components/src/custom/AdminAccountCreationLayout";
import { COLORS } from "../../../framework/src/Globals";
 // Customizable Area End
class NonProfitLandingPage extends AdminRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
       // Customizable Area Start
      <div   style={{ height: "100vh" }} className={classOuter.mainContainer}>
        <AdminAccountCreationLayout >
        <Box component="form"   sx={{ mt: 42,width:350 }} className={classOuter.footerContainer}>
          <Button className={classOuter.signInAsBtn} variant="contained" onClick={this.handleClickLandingPageModalOpen}>
            <span style={{ textTransform:"initial"}}>Sign in as</span>
          </Button>
          <Button className={classOuter.registerAsBtn}>
            <span style={{ textTransform: "initial" }}>Register as</span>
          </Button>
          </Box>

          <Dialog
        open={this.state.setLandingPageModal}
        onClose={this.handleClickLandingPageModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{" Sign In as"}</DialogTitle>
    
        <DialogActions>
        <Box component="form"   sx={{ mt: 1,width:350 }} className={classOuter.footerContainer}>
          <Button className={classOuter.signInAsBtn} variant="contained" onClick={this.handleClickLandingPageModalOpen}
          endIcon={<ArrowRightAltIcon />}
          startIcon={"Non-Profit User"}
          style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",backgroundColor:COLORS.green}}

          />

          <Button className={classOuter.registerAsBtn} 
           endIcon={<ArrowRightAltIcon />}
           startIcon={"Business User"}
           style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}
           variant="contained"
          />

          </Box>
        </DialogActions>
      </Dialog>
        </AdminAccountCreationLayout>
        {/* <div className={classOuter.landingPageContainer}>
          <div className={classOuter.appLogoContainer}>
            <img src={images.app_logo} className={classOuter.appLogo} />
          </div> */}
        {/* </div> */}
      </div>
       // Customizable Area End
    );
  }
}

export default NonProfitLandingPage;