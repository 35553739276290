import React from "react";
// Customizable Area Start

import PropTypes from "prop-types";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

import ContentManagementController, {
  Props,
} from "./ContentManagementController";
// @ts-ignore
import module from "./ContentManagementEvent.module.css";
import {
  Avatar,
  Dialog,
  Table,
  CircularProgress,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Divider,
  Typography,
  Box,
  Paper,
  Button,
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { icons, images } from "../../../components/src/constants";
import moment from "moment";

export type CounterProps = { label?: string };

const TableCell: any = withStyles({
  root: {
    borderBottom: "1px solid #d3d3d333",
  },
})(MuiTableCell);

const styles = (theme: Theme) => createStyles({});

interface IOrgData {
  organisation_name: string;
  logo: { url: string | null };
  street: string | null;
  town: string | null;
  country: string | null;
  post_code: string | null;
}
// Customizable Area End

export class EventPledgesWeb extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  getOrgLogo = (url: string | null) => {
    return url ?? "";
  }

  fullLocation = (org: IOrgData) => {
    let location = "";
    if (org.street) {
      location += org.street;
    }
    if (org.town) {
      location += location ? `, ${org.town}` : org.town;
    }
    if (org.country) {
      location += location ? `, ${org.country}` : org.country;
    }
    if (org.post_code) {
      location += location ? `, ${org.post_code}` : org.post_code;
    }
    return location;
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const pledgedUserStatus = window.localStorage.getItem("pledgedUserStatus");
    const pledgedUserDetails: any =
      this.state.pledgedUserDetails?.data?.attributes;

    return (
      <div>
        {(() => {
          if (this.state.singleEventPledges.data?.length > 0) {
            return (
              <Paper
                className={classes?.tableroot}
                style={{
                  width: "99.6%",
                  borderRadius: "0 0 12px 12px",
                  marginLeft: "0.2%",
                  // marginTop: 30,
                }}
                elevation={0}
              >
                <Divider style={{ backgroundColor: "lightgrey" }} />

                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {/* @ts-ignore */}
                      <TableCell className={module.thead} align="center">
                        FIRST NAME
                      </TableCell>
                      <TableCell className={module.thead} align="center">
                        LAST NAME
                      </TableCell>
                      <TableCell className={module.thead} align="center">
                        EMAIL ADDRESS
                      </TableCell>
                      <TableCell className={module.thead} align="center">
                        STATUS
                      </TableCell>
                      <TableCell className={module.thead} align="right">
                        REGISTERED ON
                      </TableCell>
                      <TableCell className={module.thead} align="center">
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.singleEventPledges.data.length > 0 &&
                      this.state.singleEventPledges?.data.map(
                        (item: any, index: any) => (
                          <TableRow key={item.id}>
                            <TableCell align="center" className={module.label}>
                              {item.attributes.first_name}
                            </TableCell>
                            <TableCell className={module.label} align="center">
                              {item.attributes.last_name}
                            </TableCell>
                            <TableCell className={module.label} align="center">
                              {item.attributes.email}
                            </TableCell>
                            <TableCell className={module.label} align="center">
                              {item.attributes.status}
                            </TableCell>
                            <TableCell className={module.label} align="right">
                              {moment(item.attributes.registererd_on).format(
                                "DD/MM/YYYY"
                              )}
                            </TableCell>
                            <TableCell className={module.label} align="center">
                              <Button
                                data-testId='viewBtn'
                                onClick={() => {
                                  this.setState({
                                    pledgesUser: true,
                                  });
                                  this.setState({ pledgeId: item.id });
                                  this.pledgedUser(item.attributes.account_id);
                                  localStorage.setItem(
                                    "pledgedUserId",
                                    item.attributes.non_profit_event_id
                                  );
                                  localStorage.setItem(
                                    "pledgedUserStatus",
                                    item.attributes.status
                                  );
                                }}
                                variant="outlined"
                                style={{
                                  width: 100,
                                  boxShadow: "none",
                                  textTransform: "capitalize",
                                  // height: 35,
                                  backgroundColor: " #009be2",
                                  color: "white ",
                                  borderRadius: 10,
                                  marginRight: 13,
                                  marginLeft: 13,
                                  fontFamily: "Rene_Bieder_Milliard_Medium",
                                  border: "none",
                                }}
                                color="primary"
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </Paper>
            );
          } else if (this.state.errorPresent||this.state.singleEventPledges.error || this.state.singleEventPledges.data?.length === 0) {
            return (
              <Paper
                className={classes?.tableroot}
                style={{
                  width: "99.6%",
                  borderRadius: "0 0 12px 12px",
                  marginLeft: "0.2%",
                  // marginTop: 30,
                }}
                elevation={0}
              >
                {" "}
                <Typography
                  style={{
                    textAlign: "center",
                    height: "50%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Rene_Bieder_Milliard_Medium",
                  }}
                >
                  No such data for this particular event
                </Typography>
              </Paper>
            );
          } else {
            return (
              <Paper
                style={{
                  textAlign: "center",
                  height: "50%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <CircularProgress />{" "}
              </Paper>
            );
          }
        })()}

        <Dialog
          hideBackdrop
          aria-labelledby="simple-Dialog-title"
          aria-describedby="simple-Dialog-description"
          open={this.state.pledgesUser}
          onClose={() => {
            this.setState({ pledgesUser: false });
          }}
          PaperProps={{
            style: { borderRadius: 20, width: "40%", padding: 14 },
          }}
        >
          <Box className={module.userModal}>
            <div className={module.modalTitle}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <Avatar alt="User image" src={pledgedUserDetails?.profile_picture.url } />
                </div>
                {/* <div> */}
                <div style={{ marginLeft: 20 }}>
                  <Typography className={module.userName}>
                    {pledgedUserDetails?.full_name}
                  </Typography>
                  <Typography className={module.userEmail}>
                    {pledgedUserDetails?.email}
                  </Typography>
                </div>
              </div>
              <div
                onClick={() => {
                  this.setState({ pledgesUser: false });
                }}
              >
                <HighlightOffRoundedIcon />
              </div>
              {/* </div> */}
            </div>
            <div className={module.modalDiv}>
              <div style={{ width: "35%" }} className={module.modalHeader}>
                Bio
              </div>
              <div style={{ width: "65%" }} className={module.modalText}>
                {pledgedUserDetails?.bio ? pledgedUserDetails?.bio : "-"}
              </div>
            </div>
            <div className={module.modalDiv}>
              <div style={{ width: "35%" }} className={module.modalHeader}>
                Address
              </div>
              <div style={{ width: "65%" }}>
                <Typography className={module.modalText}>
                  {pledgedUserDetails?.street_name
                    ? pledgedUserDetails?.street_name
                    : "-"}
                </Typography>
                <Typography className={module.modalText}>
                  {pledgedUserDetails?.city ? pledgedUserDetails?.city : "-"}
                </Typography>
                <Typography className={module.modalText}>
                  {pledgedUserDetails?.country
                    ? pledgedUserDetails?.country
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={module.modalDiv}>
              <div style={{ width: "35%" }} className={module.modalHeader}>
                DBS
              </div>
              <div style={{ width: "65%" }}>
                <Typography className={module.modalText}>
                  {pledgedUserDetails?.dbs_number}
                </Typography>
                <Typography className={module.modalText}>
                  Enhanced DBS & BL (Child And Adult)
                </Typography>
              </div>
            </div>
            <Box padding="10px 0px 10px 10px !important" className={module.modalDiv}>
              <Box width="35%" className={module.modalHeader}>
                Registered with
              </Box>
              <Box width="65%">
                {pledgedUserDetails?.registered_with.length > 0
                  ? pledgedUserDetails?.registered_with.map((org: IOrgData, index: number) => {
                    return (
                      <Box>
                        {index !== 0 &&
                          <Box margin="5px 0px">
                            <Divider color="lightgray" />
                          </Box>
                        }
                        <Box display="flex" alignItems="center">
                          <Avatar
                            style={{ width: "25px", height: "25px" }}
                            alt={org.organisation_name}
                            src={this.getOrgLogo(org.logo?.url)}
                          />
                          <Box ml="10px">
                            <Typography className={module.modalHeader}>
                              {org.organisation_name}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center" marginTop="5px">
                          <img
                            src={icons.locationblue}
                            height="26px"
                            width="auto"
                          />
                          <Box ml="10px">
                            <Typography className={module.modalText}>
                              {this.fullLocation(org)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })
                  : <div>{"-"}</div>
                }
              </Box>
            </Box>
            <div className={module.modalDiv}>
              <div style={{ width: "35%" }} className={module.modalHeader}>
                Place of work
              </div>
              <div
                style={{ display: "flex", width: "65%", alignItems: "center" }}
              >
                <img
                  src={pledgedUserDetails?.business_logo?.url ?? images.imageWarning }
                  style={{ height: 40, width: "auto", marginRight: 10 }}
                />
                <div>{pledgedUserDetails?.business_name}</div>
              </div>
            </div>
            <div className={module.modalDivEnd}>
              <div style={{ width: "35%" }}>
                <img
                  src={icons.chats}
                  data-testId="messagePl-btn"
                  onClick={() => this.props.messageClick(this.state.pledgedUserDetails?.data?.id)}
                  style={{
                    height: 24,
                    width: "auto",
                    marginLeft: "30%",
                    transition: "all 0.3s ease", // Example transition
                  }}
                />
                <Typography
                  style={{
                    marginLeft: "22%",
                    fontFamily: "Rene_Bieder_Milliard_SemiBold",
                    fontSize: 14,
                  }}
                >
                  Message
                </Typography>
              </div>
              <div style={{ width: "65%", display: "flex" }}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    this.acceptUserPledge(this.state.pledgeId);
                    this.setState({
                      pledgesUser: false,
                      pledgeToggle: true,
                    });
                    this.getAllPledges(this.props.single_event_details.data.id);
                  }}
                  style={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    height: 45,
                    marginRight: "5%",
                    backgroundColor: " #20bb6e",
                    color: "white ",
                    width: 125,
                    fontFamily: "Rene_Bieder_Milliard_SemiBold",
                    borderRadius: 10,
                    display:
                      pledgedUserStatus === "Approved" ? "none" : "inherit",
                  }}
                >
                  Accept user
                </Button>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    this.rejectUserPledge(this.state.pledgeId);
                    this.setState({
                      pledgesUser: false,
                      pledgeToggle: true,
                    });
                    this.getAllPledges(this.props.single_event_details.data.id);
                  }}
                  style={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    height: 45,
                    backgroundColor: " #f12323",
                    width: 125,
                    color: "white ",
                    borderRadius: 10,
                    margin:
                      pledgedUserStatus === "Approved" ? "0 auto" : "inherit",
                    fontFamily: "Rene_Bieder_Milliard_SemiBold",
                  }}
                >
                  Reject user
                </Button>
              </div>
            </div>
          </Box>
        </Dialog>
      </div>

      // Customizable Area End
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(
  EventPledgesWeb as any
);
