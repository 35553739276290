import React, { Component } from 'react';
import {
    Typography, Button, Input, Paper, InputAdornment,
    Grid, IconButton, Box, Avatar
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CustomSuccessModel from '../../../components/src/custom/CustomSuccessModel';

interface IPROPS {
    onClose: () => void;
    handleChangeAvatar: (event: any) => void;
    handleSubmitProfile: (event: any) => void;
    handleProfileFieldChange: (e:any) => void;
    handleCloseSaveModel:(event: any) => void;
    openSaveModel:boolean;
    draft: {
        first_name: string;
        last_name: string;
        email: string;
        mobile: number | null;
        job_role: string;
        change_password: string;
        confirm_password: string;
        avatar: string;
    };
    profileChangeErrors: {
        first_name: boolean;
        last_name: boolean;
        email: boolean;
        mobile: boolean;
        job_role: boolean;
        change_password: boolean;
        confirm_password: boolean;
    };
    
}

interface S{
    showPassword:boolean;
    showPassword1:boolean;
}
class ContentManagementProfile extends Component<IPROPS,S>{
    constructor(props: IPROPS) {
        super(props);
        this.state={
            showPassword:false,
            showPassword1:false,
        }
    }

    togglePasswordVisibility = (value:any) => {
        if(value==="change"){
        this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
        }
        if(value==="confirm"){
            this.setState((prevState) => ({ showPassword1: !prevState.showPassword1 }));
            }
      };

    AvatarAndCloseIcon = () => {
        return (
            <div style={styled.divWrapper}>
                <div style={styled.iconWrapper}>
                    <div>
                        <label htmlFor="avatar-upload">
                            <Avatar alt="Profile Picture" src={this.props.draft.avatar} style={styled.avatar}   data-testId='avatar-input' />
                            <Typography variant="subtitle2" style={{ fontSize: "16px", marginLeft: "10px" }}>
                                Change
                            </Typography>
                        </label>
                        <input
                            id="avatar-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => this.props.handleChangeAvatar(event)} />
                    </div>
                    <div>
                        <IconButton onClick={this.props.onClose}  id="close-icon" style={{borderRadius:"50%",border: '2px solid', padding:"5px", fontWeight:'bolder', color:"black"}}>
                            <CloseIcon style={{ cursor: 'pointer',  fontSize: "15px",stroke:"black" ,strokeWidth:1}} />
                        </IconButton>
                        <Typography variant="subtitle2" style={{ fontSize: "14px", textAlign: "center",marginTop:"2px" }}>
                            Close
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }

    FirstName() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>First Name</Typography>
                <br />
                <Input
                id="first-name-input"
                    name="first_name"
                    style={styled.textInput}
                    disableUnderline
                    placeholder="First Name"
                    value={this.props.draft.first_name.length>0?this.props.draft.first_name.substring(0,25):this.props.draft.first_name}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                />
                {this.props.profileChangeErrors.first_name && <div style={{ color: 'red' }}>Please enter first name</div>}
            </div>
        )
    }

    LastName() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Last Name</Typography>
                <br />
                <Input
                 id="last-name-input"
                    name="last_name"
                    style={styled.textInput}
                    disableUnderline
                    placeholder="Last Name"
                    value={this.props.draft.last_name.length>0?this.props.draft.last_name.substring(0,25):this.props.draft.last_name}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                />
                {this.props.profileChangeErrors.last_name && <div style={{ color: 'red' }}>Please enter last name</div>}
            </div>
        )
    }

    Email() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Email address</Typography>
                <br />
                <Input
                id="email-input"
                    type='email'
                    required
                    name="email"
                    style={{...styled.textInput,...styled.disabledSelect}}
                    disableUnderline
                    placeholder="Email address"
                    value={this.props.draft.email}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                    disabled
                />
                {this.props.profileChangeErrors.email && <div style={{ color: 'red' }}>Please enter valid email address</div>}
            </div>
        )
    }

    Mobile() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Mobile Number</Typography>
                <br />
                <Input
                    id="mobile-input"
                    type='number'
                    name="mobile"
                    style={styled.textInput}
                    disableUnderline
                    placeholder="Mobile Number"
                    value={this.props.draft.mobile}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                    onKeyDown={(e:any)=> (e.key==="ArrowUp" || e.key==="ArrowDown")? e.preventDefault():undefined}
                    startAdornment={this.props.draft.mobile!==null && this.props.draft.mobile.toString().length>0 && (<InputAdornment position="start"><span>+</span></InputAdornment>)}
                />
                {this.props.profileChangeErrors.mobile && <div style={{ color: 'red' }}>Please enter valid contact number</div>}
            </div>
        )
    }

    JobRole() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Job Role</Typography>
                <br />
                <Input
                id="jobRole-input"
                name="job_role"
                disabled
                style={{...styled.textInput,...styled.disabledSelect}}
                disableUnderline
                placeholder="Job Role"
                value={this.props.draft.job_role}
                onChange={(e) => this.props.handleProfileFieldChange(e)}
                />
                {this.props.profileChangeErrors.job_role && (<div style={{ color: 'red' }}>Please select a job role</div>)}
            </div>
        )
    }

    ChangePassword() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Change Password</Typography>
                <br />
                <Input
                id="changePassword-input"
                    name="change_password"
                    type={this.state.showPassword? "text":"password"}
                    style={styled.textInput}
                    disableUnderline
                    placeholder="Change Password"
                    value={this.props.draft.change_password}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                    endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>this.togglePasswordVisibility("change")}
                            // onMouseDown={(e) => e.preventDefault()}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                />
                {this.props.profileChangeErrors.change_password ? <div style={{ color: 'red' }}>Password must be of 8 characters with a capital letter, a special character & a number</div> : <div style={{ marginLeft: "25px", marginTop: "5px", color: "grey", fontSize: "12px" }}>Leave it empty if no change required</div>}
            </div>
        )
    }

    ConfirmPassword() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Confirm Password</Typography>
                <br />
                <Input
                id="confirmPassword-input"
                    name="confirm_password"
                    type={this.state.showPassword1? "text":"password"}
                    style={styled.textInput}
                    disableUnderline
                    placeholder="Confirm Password"
                    value={this.props.draft.confirm_password}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                    endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>this.togglePasswordVisibility("confirm")}
                            // onMouseDown={(e) => e.preventDefault()}
                          >
                            {this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                />
                {this.props.profileChangeErrors.confirm_password && <div style={{ color: 'red' }}>Password do not match!</div>}
            </div>
        )
    }

    SaveBtn() {
        return (
            <div style={styled.divWrapper}>
                <Button data-test-id='saveButton'
                    style={{
                        width: "10%",
                        boxShadow: "none",
                        textTransform: "capitalize",
                        height: 45,
                        backgroundColor: `${this.props.profileChangeErrors.first_name || this.props.profileChangeErrors.last_name || this.props.profileChangeErrors.email || this.props.profileChangeErrors.mobile || this.props.profileChangeErrors.job_role || this.props.profileChangeErrors.change_password || this.props.profileChangeErrors.confirm_password ? "rgba(0, 155, 226, 0.2)" : "#009be2"}`,
                        color: "white ",
                        fontFamily: "Rene_Bieder_Milliard_SemiBold",
                        borderRadius: 10,
                        marginBottom: "20px"
                    }}
                    color="primary"
                    variant="contained"
                    disabled={this.props.profileChangeErrors.first_name || this.props.profileChangeErrors.last_name || this.props.profileChangeErrors.email || this.props.profileChangeErrors.mobile || this.props.profileChangeErrors.job_role || this.props.profileChangeErrors.change_password || this.props.profileChangeErrors.confirm_password ? true : false}
                    onClick={this.props.handleSubmitProfile}
                >
                    Save
                </Button>
            </div>
        )
    }

    render() {


        return (
            <div>
                <Grid style={{ paddingLeft: "28px", paddingRight: "28px" }}>
                    <Paper style={{ backgroundColor: "#fff", marginTop: "60px", width: "100%",borderRadius:"17px" }}>
                        <Box style={{ marginTop: 10 }} />
                        <Typography
                            variant="subtitle1"
                            style={heading}
                        >
                            Profile details
                        </Typography>
                        <Box
                            style={{
                                borderTop: "2px solid #EAEAEA",
                            }}
                        />
                        <>
                            {this.AvatarAndCloseIcon()}
                            {this.FirstName()}
                            {this.LastName()}
                            {this.Email()}
                            {this.Mobile()}
                            {this.JobRole()}
                            {this.ChangePassword()}
                            {this.ConfirmPassword()}
                            {this.SaveBtn()}
                        </>
                    </Paper>
                </Grid>
                <CustomSuccessModel openSuccessModal={this.props.openSaveModel} handleSuccessModalClose={this.props.handleCloseSaveModel} msgTitle='Thank you' msg="Your changes have been updated" />
            </div>
        );
    }
}

const heading = {
    display: "inline-block",
    fontSize: "22px",
    marginLeft: "10px",
    padding: "10px",
    margingLeft: "12px"
};

const styled = {
    divWrapper: {
        marginBottom: "20px",
        paddingLeft: "10px",
        marginLeft: "10px",
        marginTop: "20px",
    },

    label: {
        display: "inline-block",
        fontSize: "18px ",
        marginBottom: "5px ",
        paddingLeft: "5px",
    },

    textInput: {
        width: "50%",
        borderRadius: "10px",
        padding: "10px 10px 10px 20px",
        background: "#ffffff",
        border: "1px solid #d9d4d4",
        fontSize: "16px",
    },

    disabledSelect: {
        color: 'currentColor',
      },

    iconWrapper: {
        display: "flex",
        alignItems: "flex-start",
        marginRight: "80px",
        justifyContent: "space-between"
    },

    avatar: {
        marginBottom: "10px",
        width: "80px",
        height: "80px"
    },
};


export default ContentManagementProfile;
