// Customizable Area Start
import React from "react"
import DonorInfoController, { IUser } from "./DonorInfoController.web";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
// Customizable Area End

export default class DonorInfo extends DonorInfoController {

  render() {
    // Customizable Area Start
    return (
      <Wrapper>
        {this.state.loading ?
          <Paper className="loading">
            <CircularProgress className="loader" />
          </Paper>
          :
          <Paper className="paper" elevation={0}>
            <Box p="10px 34px" display="flex" justifyContent="space-between">
              <Typography className="donation-info">Amount required : <span>£{this.props.single_event_details.data.attributes.target_amount ?? 0}</span></Typography>
              <Typography className="donation-info">Funds raised : <span>£{this.state.fundRaised ?? 0}</span></Typography>
            </Box>
            <Box width="100%" overflow="auto">
              <Table className="table">
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left">FIRST NAME</TableCell>
                    <TableCell align="left">LAST NAME</TableCell>
                    <TableCell align="left">EMAIL ADDRESS</TableCell>
                    <TableCell align="left">DONATED ON</TableCell>
                    <TableCell align="left">DONATED</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tableBody">
                  {this.state.donorList.length > 0 ?
                    this.state.donorList.map((item: IUser) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell align="left">{item.firstName}</TableCell>
                          <TableCell align="left">{item.lastName}</TableCell>
                          <TableCell align="left">{item.email}</TableCell>
                          <TableCell align="left">{item.date}</TableCell>
                          <TableCell align="left">
                            <Button className="btn">£{item.amount}</Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                    :
                    <TableRow>
                      <TableCell className="not-found" align="center" colSpan={5}>No record available!</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Paper>
        }
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  "& .loading": {
    height: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .loader": {
      color: "#009be2",
    }
  },
  "& .paper": {
    width: "99.6%",
    borderRadius: "0 0 12px 12px",
    marginLeft: "0.2%",
  },
  "& .donation-info": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    fontSize: 18,
    "& span": {
      color: "#009be2"
    },
    "&:last-child": {
      paddingRight: "20px"
    }
  },
  "& .table": {
    "& .tableHead": {
      borderTop: "1px solid lightgrey",
    },
    "& th": {
      fontSize: 13,
      padding: "11px 10px",
      paddingLeft: "35px",
      fontFamily: "Rene_Bieder_Milliard_SemiBold",
    },
    "& td": {
      borderBottom: "none",
      fontSize: 14,
      padding: "8px 10px",
      paddingLeft: "35px",
      fontFamily: "Rene_Bieder_Milliard_Book",
    },
    "& .not-found": {
      fontSize: 16,
      height: "140px",
      fontFamily: "Rene_Bieder_Milliard_Book",
    }
  },
  "& .btn": {
    padding: "1px 0px",
    color: "#fff",
    backgroundColor: "#009be2",
    borderRadius: "6px"
  }
});
// Customizable Area End
