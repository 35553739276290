import React from "react";

// Customizable Area Start
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import * as Yup from "yup";
import { createTheme } from "@material-ui/core/styles";
import { SignupForm } from "./SignupForm";

const initialValues = {
  full_name: "",
  email: "",
  full_phone_number: "",
  password: "",
  password_confirmation: "",
  account: "",
  phone_number: "",
};

const phoneRegExp = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
const nameReg = /^[A-Z][a-z]+[,.'-]?(?: [A-Z][a-z]+[,.'-]?)*$/i;
const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

let validationSchemaobj = Yup.object().shape({
  full_name: Yup.string()
    .trim()
    .matches(nameReg, "Please enter valid name")
    .required("Please enter full name"),
  email: Yup.string()
    .matches(emailReg,'Please enter valid email address')
    .email("Please enter valid email address")
    .required("Please enter email address"),
  full_phone_number: Yup.string()
    .matches(phoneRegExp, "Please enter valid mobile number")
    .min(13,"Please enter valid mobile number")
    .max(13,"Please enter valid mobile number")
    .required("Please enter mobile number "),
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must be of 8 characters with a capital letter, a special character & a number."
    ),
  password_confirmation: Yup.string()
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and confirm password does not matched"
      ),
    })
    .required("Please enter confirm password"),
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '383px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '25px',
  p: 4,
};

// Customizable Area End

class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    //console.log(this.state.acceptTC,"accept")
    // Customizable Area End
    return (
      // Customizable Area Start
      <SignupForm/>
      // Customizable Area End
    );
  }
}

//Customizable Area Start

export default EmailAccountRegistration;
export { EmailAccountRegistration };

//Customizable Area End
