import React, { CSSProperties } from "react";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';


const useVerticalSpacerStyles = makeStyles({
  root: {
    background: 'transparent',
    height: '15px',
  },
});

export function VerticalSpacer({ value }: { value?: number }) {

  const classes = useVerticalSpacerStyles();
  let style: CSSProperties = {}

  if (value)
    style['height'] = `${value}px`;

  return (<Divider className={classes.root} style={style} />);
}

