Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
exports.btnExampleTitle = "CLICK ME";
exports.apiMethod = "POST"; 
exports.apiContentType = "application/json";
exports.forgotPwdSendOtpApiEndPoint =
  "bx_block_forgot_password/otps/non_profit_email_otp";
exports.forgotPwdVerifyOtpApiEndPoint =
  "bx_block_forgot_password/otp_confirmations";
exports.npRegistrationEndPoint =
  "bx_block_non_profit_account/non_profit_accounts";
exports.resetPasswordApiEndPoint =
  "bx_block_forgot_password/passwords/non_profit_change_password";
exports.deleteMethod ='DELETE';
exports.patchMethod = 'PATCH';
exports.loginApiEndPoint = "bx_block_login/non_profit_logins";
exports.eventCreationEndPoint = 'non_profit_events'
exports.viewPledgesEndPoint = 'my_pledges/view_pledges';
exports.eventUpdatesEndPoint = 'non_profit_event_updates';
exports.getPledgedUserEndPoint = 'account_block/accounts/get_user_profile';
exports.rejectPledgeEndPoint = 'my_pledges';
exports.getApiMethodType = "GET";

exports.acceptPledgeEndPoint = 'my_pledges/accept_pledge';
exports.searchEventsEndPoint = 'search_event_id';
exports.registeredVolunteersEndPoint = 'get_volunteers';
exports.registeredVolunteersAcceptUserEndPoint = 'approve_volunteer';
exports.registeredVolunteersRemoveUserEndPoint = 'remove_volunteer';
exports.registeredNPOrganisationUser = 'bx_block_non_profit_account/non_profit_accounts/create_staff_member';
exports.registeredOfferAROleUserEndPoint = 'bx_block_non_profit_account/non_profit_accounts/users';
exports.getAllEventsEndPoint = 'dashboard_events'
exports.getNpProfileDataApiEndpoint = 'bx_block_non_profit_account/non_profit_accounts'
exports.getNpOrganizationDataApiEndpoint = 'bx_block_organisations/organisations'
exports.approveRegisteredVolunteerDataApiEndpoint = 'approve_volunteer'
exports.rejectRegisteredVolunteerDataApiEndpoint = 'remove_volunteer'
exports.contactUsApiEndPoint = 'bx_block_contact_us/contacts';
exports.searchNpProfileDataApiEndpoint = 'search_volunteer'
exports.deleteNpOrganisationApiEndPoint='bx_block_organisations/organisations'
exports.freeTrialApiEndPoint='bx_block_custom_user_subs/my_subscription'
exports.orgTypeEndPoint= "bx_block_organisations/organisation_types"
exports.deleteNpStaffApiEndPoint= "bx_block_non_profit_account/non_profit_accounts"
exports.updateNpStaffApiEndPoint= "bx_block_non_profit_account/non_profit_accounts/update_staff_member"
exports.orgOptionsTypeEndPoint="options"
exports.getMembersApiEndPoint="bx_block_organisations/cross_sector_memberships/cross_sector_organisations"
exports.mapsAPIKey = "AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY";
exports.getAnalyticsEndPoint='bx_block_dashboard/get_analytics_details';
exports.getSetNewPasswordApiEndPoint='bx_block_non_profit_account/update_pass'
exports.getFollowersApiEndPoint='bx_block_followers/organisation_follows/org_followers'
exports.getBroadcastMsgApiEndPoint='bx_block_dashboard/broadcasts'
exports.saveBroadcastsMsgApiEndPoint='bx_block_dashboard/broadcasts'
exports.generateChatToken = 'account_block/accounts/token_for_chat?identity='
exports.chatList = 'bx_block_groupchat/conversation_list?filter=';
exports.getTaskPreferenceDataApiEndPoint='task_preferences'
exports.getDBSDataApiEndPoint='dbs_types'
exports.clearChat = 'bx_block_groupchat/create_clear_chat';
exports.pinMessage = 'bx_block_groupchat/create_pin';
exports.unpinMessage = 'bx_block_groupchat/unpin';
exports.nploginEndPoint = "bx_block_login/non_profit_logins";
exports.postApiMethodType = "POST";
exports.viewPledgeEndPoint = 'my_pledges/view_pledges';
exports.saveMedias = 'bx_block_groupchat/save_media';
exports.deleteUserApiMethodType = "DELETE";
// Customizable Area End
