// Customizable Area Start
import React from "react";
import PropTypes from 'prop-types';
import ServiceSpecificSettingsAdminController from "./ServiceSpecificSettingsAdminController";
import BusDashbaordWeb from "./BusDashboard.web";
// @ts-ignore
import classesOuter from './BusFollowing.module.css'
import {
    Box, Container, InputAdornment, Input, IconButton, Typography, Button, Table,
    TableRow,
    TableCell as MuiTableCell, TableHead as MuiTableHead,
    TableBody,
    Divider,
    CircularProgress
} from '@material-ui/core'
import { icons } from "../../../components/src/constants";
import { COLORS } from "../../../framework/src/Globals";
import {
    withStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import BusNonProfitProfile from "./BusNonProfitProfile.web";

export const styles = (theme: Theme) =>
    createStyles({
        toolBar: {
            display: "flex",
            flexDirection: "row",
            padding: "0px",
        },
        search: {
            position: "relative",
            borderRadius: "8px",
            backgroundColor: COLORS.white,
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(0),
            },
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        inputInput: {
            fontFamily: "Rene_Bieder_Milliard_Light",
            fontWeight: 700,
            boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
            paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
            transition: theme.transitions.create("width"),
            marginTop: "15",
            fontSize: "revert",
            padding: "16 45 16 0",
            marginBottom: "10px",
            "@media (max-width: 600px)": {
                marginTop: "15",
                padding: "16 65 16 0",
                marginBottom: "10px",
                boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
                paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
                transition: theme.transitions.create("width"),
                fontSize: "revert",
                fontFamily: "Rene_Bieder_Milliard_Light",
                fontWeight: 700,
            },
            "&::placeholder": {
                color: "#000501",
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: "Rene_Bieder_Milliard_Light",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: COLORS.skyBlue,
        },
        _tabs_logo: {
            height: "20px",
            width: "20px",
            left: "33px",
            top: "788px",
            borderRadius: " 0px",
        },
        image: {
            width: 128,
            height: 128,
        },
        sectionTabDesktop: {
            display: "flex",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        aftersearchIconbutton: {
            borderRadius: "8px",
            color: "text.primary",
            backgroundColor: "white",
            margin: "8px",
            padding: "8px",
            height: 46,
            width: 46,
        },
        sectionDesktop: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        inputRoot: {
            color: "inherit",
            width: "100%"
        },
    });

const TableCell: any = withStyles({
    root: {
        borderBottom: "none !important",
    },
})(MuiTableCell);

const TableHead: any = withStyles({
    root: {
        borderBottom: "3px solid #eaeaea",
    },
})(MuiTableHead);

export class BusFollowing extends ServiceSpecificSettingsAdminController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }


    tableBodyData() {
        return (
            this.state.filteredFollowingData.length > 0 ?
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className={classesOuter.followersNPTableHead}>
                        <TableRow>
                            <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>No.</TableCell>
                            <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>NON-PROFIT ORGANISATIONS</TableCell>
                            <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>ORGANISATION TYPE</TableCell>
                            <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>LOCATION</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classesOuter.followersNPTableCell}>
                        {this.state?.filteredFollowingData.map((item: any, index: any) =>
                            <TableRow key={index} data-test-id={`tableData-${item.id}`} style={{ border: 0, borderBottom: "none" }}>
                                <TableCell>{index + 1}.</TableCell>
                                <TableCell>{item?.attributes?.organisation_name}</TableCell>
                                <TableCell>{item?.attributes?.organisation_type}</TableCell>
                                <TableCell>{item?.attributes?.town !== null ? item?.attributes?.town : '-'}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        data-testId={`viewBtn-${item.id}`}
                                        onClick={() => this.checkFollowStatus(item)}
                                        style={{
                                            width: 100,
                                            boxShadow: "none",
                                            textTransform: "capitalize",
                                            backgroundColor: " #009be2",
                                            color: "white ",
                                            fontFamily: "Rene_Bieder_Milliard_Medium",
                                            borderRadius: 10,
                                            marginRight: 13,
                                            marginLeft: 13,
                                            border: "none",
                                        }}
                                        color="primary"
                                    >
                                        View
                                    </Button>
                                    or
                                    <Button
                                        variant="outlined"
                                        disabled={this.state.loadingmsg}
                                        data-testId= "message-btn4"
                                        onClick={() => this.MessageNpProfile(item?.id)}
                                        style={{
                                            width: 100,
                                            boxShadow: "none",
                                            textTransform: "capitalize",
                                            backgroundColor: " #009be2",
                                            color: "white ",
                                            fontFamily: "Rene_Bieder_Milliard_Medium",
                                            borderRadius: 10,
                                            marginRight: 13,
                                            marginLeft: 13,
                                            border: "none",
                                        }}
                                        color="primary"
                                    >
                                        Message
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                : <Typography
                    style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                    }}
                >
                    Sorry, No data available
                </Typography>


        )
    }
    tableData() {
        return (
            this.state.loader ? <div
                style={{
                    height: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress style={{ color: "#009be2" }} />
            </div> : this.tableBodyData()
        )
    }


    render() {
        const { classes }: any = this.props;
        return (
            <div>
                {/* @ts-ignore */}
                {this.state.showNpProfile ? <BusNonProfitProfile navigation={this.props.navigation} NpDetais={this.state.nonProfitProfileData} data-testId='busNonProfit' followingStatus={true} onBack={() => {this.setState({ showNpProfile: false }); this.getFollowingOrgData()}} /> :
                    < BusDashbaordWeb classes={this.props.classes} navigation={this.props.navigation} id={""}  theme={{}}
                    >
                        <Container maxWidth="xl" style={{ marginLeft: 280, width: 'auto' }}>
                            <Box className={classesOuter.followersBusmainDiv}>
                                <Box className={classesOuter.followersBusheadingDiv}>
                                    <Box className={classesOuter.followersBusheading}>
                                        <Typography style={{ fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }}>Following</Typography>
                                    </Box>
                                    <Box>
                                        <div className={classes.search}>

                                            <Input
                                                data-testId="searchInput"
                                                startAdornment={
                                                    <InputAdornment
                                                        position="start"
                                                        style={{
                                                            width: 40,
                                                            marginLeft: -9,
                                                        }}
                                                    >
                                                        <IconButton>
                                                            <img
                                                                src={icons.searchSVG}
                                                                style={{
                                                                    height: 25,
                                                                    width: "auto",
                                                                    opacity: 0.5555555,
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                placeholder="Search..."
                                                onChange={(e) => this.searchFollowingData(e.target.value)}
                                                className={classesOuter.searchField}
                                                disableUnderline
                                            />
                                        </div>
                                    </Box>
                                </Box>
                                <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
                                <Box>
                                    {this.tableData()}
                                </Box>
                            </Box>
                        </Container>
                    </BusDashbaordWeb >}
            </div >
        )
    }
}

export default withStyles(styles as any, { withTheme: true })(
    BusFollowing as any
);
// Customizable Area End