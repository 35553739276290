import React from "react";
import {
  // Customizable Area Start
  CardContent,
  Typography,
  Container,
  IconButton,
  InputAdornment,
  Grid,
  TextField,
  Box,
  Checkbox
  // Customizable Area End
} from "@material-ui/core";
  // Customizable Area Start
import { COLORS } from "../../../framework/src/Globals";
import { FONTS, icons, images, FAMILYFONTS } from "../../../components/src/constants";
import FormRegitrationController from "./RegistrationFormController";
//@ts-ignore
import classOuter from "./RegistrationFormPart2.module.css";
import { InitiatePhoneInput } from '../../../components/src/shared/phone-input';
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";
import { TermsAndConditionModal } from "../../../components/src/shared/terms-modal";
  // Customizable Area End
class RegistrationFormPart2Business extends FormRegitrationController {

  handleChange = (value: string) => {
    const total = value.trim().split(" ").length;
    if (total <= 150) {
      this.props.registerNextBtnValidation({ desc: value });
      this.descriptionValidation(value);
      this.wordCounter(value, total);
    }
  }

  render() {
     // Customizable Area Start
    const {
      registerNextBtnValidation,
      desc,
      contactNum,
      emailId,
      regPassword,
      regConfirmPwd,
      emailDomain,
      acceptTerms,
      regEmailApiError,
      mobileNumberApiError,
      domainApiError
    } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
        <Container
          maxWidth="xl"
          style={{
            backgroundColor: COLORS.white,
            borderRadius: 24,
            padding: 9,
            margin: "auto",
          }}
        >
          <TermsAndConditionModal
            data-testId="termModal"
            accountType="business"
            open={!!this.state.modalValue}
            onClose={() => {this.setState({ modalValue: "" })}}
            modalValue={this.state.modalValue}
          />
          <CardContent>       
            <div>
              <label style={{
                ...FAMILYFONTS.family3,
                fontSize: 14,
                fontWeight: 400
              }}>
                Brief description of business *
              </label>
              <Box position="relative">
              <TextField
                name="desc"
                data-testid="descTest"
                variant="outlined"
                value={desc}
                maxRows={5}
                minRows={5}
                placeholder="Type description here"
                style={{
                  backgroundColor: COLORS.white,
                  borderRadius: 15,
                  width: "100%"
                }}
                onChange={(e) => {
                  this.handleChange(e.target.value);
                }}
                InputProps={{
                  classes: {
                    root: classOuter.registerTextField,
                  },
                }}
                inputProps={{
                  style: {
                    borderColor: "#d5d5d5",
                  },
                  onFocus: this.handleInputFocus,
                  onBlur: this.handleInputBlur,
                }}
                multiline
              />
                <Box
                  fontSize={14}
                  fontFamily="Rene_Bieder_Milliard_Book"
                  position="absolute"
                  bottom="5px"
                  right="10px"
                  color="lightgrey"
                >
                  {`${0 + this.state.descriptionWordCount}`} /150 words
                </Box>
              </Box>
              <Typography
                style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 12,
                  color: "#fd1d1d",
                }}
              >
                {this.state.orgDesError}
              </Typography>
              <br />
            </div>
            <div>
              <label style={{
                ...FAMILYFONTS.family3,
                fontSize: 14,
                fontWeight: 400
              }}>
                Contact number *
              </label>
              <InitiateThemeProvider>
                <InitiatePhoneInput
                 className={classOuter.borderColorChange}
                 data-testid="contactNumTest"
                  value={contactNum}
                  onChange={(e) => {
                    registerNextBtnValidation({ contactNum: e})
                    this.validContactNumber(e)
                  }}

                />
              </InitiateThemeProvider>

              <Box>
                <Typography
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 12,
                    color: "#fd1d1d",
                  }}
                >
                  {this.state.contactNumberError}
                </Typography>
              </Box>
         
              {mobileNumberApiError &&
                <Box>
                  <Typography
                    style={{
                      ...FAMILYFONTS.family3,
                      fontSize: 12,
                      color: "#fd1d1d",
                    }}
                  >
                     {mobileNumberApiError.toLowerCase().replace(/^\w/, (c: string) => c.toUpperCase())}
                  </Typography>
                </Box>
              }
            </div>
            <br />
            <div>
              <label style={{
                ...FAMILYFONTS.family3,
                fontSize: 14,
                fontWeight: 400
              }}>
                Email address ( User ID ) *
              </label>
              <br />
              <TextField
                data-testid="emailIdTest"
                name="emailId"
                autoComplete="off"
                defaultValue={emailId}
                placeholder="helpclub@gmail.com"
                variant="outlined"
                style={{ ...FONTS.body2, width: "100%" }}
                onChange={(e) => {
                  registerNextBtnValidation({ emailId: e.target.value});
                  this.validateEmail(e.target.value);
                }}
                InputProps={{
                  classes: {
                    root: classOuter.registerTextField,
                  },
                  autoComplete: "off"
                }}
                inputProps={{
                  style: {
                    borderColor: "#d5d5d5",
                  },
                  onFocus: this.handleInputFocus,
                  onBlur: this.handleInputBlur,
                }}
              />
            </div>
            {/* {this.state.emailError &&  */}
            <Typography
              style={{
                ...FAMILYFONTS.family3,
                fontSize: 12,
                color: "#fd1d1d",
              }}
            >
              {this.state.emailError}
            </Typography>
            {regEmailApiError &&
                <Box>
                  <Typography
                    style={{
                      ...FAMILYFONTS.family3,
                      fontSize: 12,
                      color: "#fd1d1d",
                    }}
                  >
                    Email {regEmailApiError}
                  </Typography>
                </Box>
              }
            {/* } */}
            <br />
            <div>
              <label style={{
                ...FAMILYFONTS.family3,
                fontSize: 14,
                fontWeight: 400
              }}>Password*</label>
              <br />
              <TextField
              autoComplete="off"
              style={{
                ...FONTS.body2,
                width: "100%",
              }}
              type={this.state.enablePwdField ? "text" : "password"}
              defaultValue={regPassword}
              data-testid="regPasswordTest"
              onChange={(e) => {
                registerNextBtnValidation({ regPassword: e.target.value});
                this.validatePassword(e.target.value);
              }}
              variant="outlined"
              placeholder="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      data-testId='enablePassword'
                      onClick={() => {
                        this.setState({
                          enablePwdField: !this.state.enablePwdField,
                        });
                      }}
                      edge="end"
                    >
                      {this.state.enablePwdField ? (
                        <img
                          src={images.imgPasswordVisible}
                          alt="passwordVisible"
                        />
                      ) : (
                        <img
                          src={images.imgPasswordInVisible}
                          alt="passwordInVisible"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: classOuter.registerTextField,
                },
              }}
              inputProps={{
                style: {
                  borderColor: "#d5d5d5",
                },
                onFocus: this.handleInputFocus,
                onBlur: this.handleInputBlur,
              }}
          />
            </div>
            <Typography
              style={{
                ...FAMILYFONTS.family3,
                fontSize: 12,
                color: "#fd1d1d",
              }}
            >
              {this.state.passwordError}
            </Typography>
            <br />
            <div>
              <label style={{
                ...FAMILYFONTS.family3,
                fontSize: 14,
                fontWeight: 400
              }}>
                Confirm password *
              </label>
              <br />
              <TextField
              autoComplete="off"
              style={{
                ...FONTS.body2,
                width: "100%",
              }}
              type={this.state.enableConfirmPwdField ? "text" : "password"}
              defaultValue={regConfirmPwd}
              data-testid="confirmPasswordTest"
              onChange={(e) => {
                registerNextBtnValidation({
                  regConfirmPwd: e.target.value
                });
              }}
              placeholder="Confirm password"
              variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      this.setState({
                        enableConfirmPwdField: !this.state
                          .enableConfirmPwdField,
                      });
                    }}
                    edge="end"
                  >
                    {this.state.enableConfirmPwdField ? (
                      <img
                        src={images.imgPasswordVisible}
                        alt="passwordVisible"
                      />
                    ) : (
                      <img
                        src={images.imgPasswordInVisible}
                        alt="passwordInVisible"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                root: classOuter.registerTextField,
              },
            }}
            inputProps={{
              style: {
                borderColor: "#d5d5d5",
              },
              onFocus: this.handleInputFocus,
              onBlur: this.handleInputBlur,
            }}
           />
            </div>
            <Typography
              style={{
                ...FAMILYFONTS.family3,
                fontSize: 12,
                color: "#fd1d1d",
              }}
            >
              {regPassword !== regConfirmPwd && "Password did not match."}
            </Typography>
            <br />
            <div>
              <label style={{
                ...FAMILYFONTS.family3,
                fontSize: 14,
                fontWeight: 400
              }}>
                Corporate email domains
              </label>
              <TextField
                data-testid="emailDomainTest"
                name="emailDomain"
                defaultValue={emailDomain}
                placeholder="@domainname"
                variant="outlined"
                style={{ ...FONTS.body2, width: "100%" }}
                onChange={(e) => {
                  registerNextBtnValidation({ emailDomain: e.target.value});
                  this.validDomainName(e.target.value)
                }}
                InputProps={{
                  classes: {
                    root: classOuter.registerTextField,
                  },
                  autoComplete: "off"
                }}
                inputProps={{
                  style: {
                    borderColor: "#d5d5d5",
                  },
                  onFocus: this.handleInputFocus,
                  onBlur: this.handleInputBlur,
                }}
              />
              <Typography
                style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 12,
                  color: "#fd1d1d",
                }}
              >
                {this.state.domainnameError}
              </Typography>
              <Typography
                style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 12,
                  color: "#fd1d1d",
                }}
              >
                {domainApiError}
              </Typography>
            </div>
            <br/>
          <div>
          <label style={{
                ...FAMILYFONTS.family3,
                fontSize: 14,
                fontWeight: 400
              }}>Logo</label>
            {this.state.logo === '' ? null : 
            <div
              style={{
                display: "flex",
              }}
            >
              <label
                style={{
                  ...FONTS.nonProfitText,
                  marginLeft: "auto",
                  marginRight: 0,
                  cursor: "pointer"
                }}
              >
                <input
                  name="profile_picture"
                  data-testid="profilePictureTest"
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                  style={{ borderRadius: 12, marginTop: 10 }}
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                      this.setState({
                        logo: URL.createObjectURL(
                          event.target.files[0]
                        ),
                      });
                      registerNextBtnValidation({ logo: event.target.files[0] })
                    }
                  }}
                />
                Edit
              </label>
            </div> }
            <CardContent>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  background: COLORS.white,
                  height: 100,
                  width: "52%",
                  border: "1px solid #a7b6b9 ",
                  borderRadius: 10,
                }}
              >
                {this.state.logo ? (
                  <img
                    src={this.state.logo}
                    style={{ height: "100%", width: "100%" }}
                  />
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <img
                      src={icons.plusIcon}
                      style={{ height: 20 }}
                      alt="cameraIcon"
                    />

                    <input
                      data-testid="profilePicTest"
                      name="profile_picture"
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      hidden
                      style={{ borderRadius: 12, marginTop: 10 }}
                      onChange={(event) => {
                        event.target.files && event.target.files[0] && 
                        this.setState({
                              logo: URL.createObjectURL(
                                event.target.files[0]
                              ),
                            });
                            // @ts-ignore
                            registerNextBtnValidation({ logo: event.target.files[0] })     
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </CardContent>
          </div>
            <div>
              <Typography style={{ ...FONTS.nonProfitText }}>
                Fields marked with '<span style={{ color: "red" }}>*</span>' are
                mandatory fields.
              </Typography>
            </div>
          <Box mt="15px" display="flex" alignItems="center">
            <Checkbox
              style={{
                width: "25px",
                height: "25px",
                marginLeft: "-10px"
              }}
              data-testId="termCheckbox"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                  <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                </svg>
              }
              checked={acceptTerms}
              checkedIcon={
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                  <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#019BE1" fill="#019BE1" stroke-width="1" />
                  <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                </svg>
              }
              disableRipple
              name="acceptTerms"
              color="primary"
              onChange={(e) => {
                registerNextBtnValidation({
                  acceptTerms: e.target.checked
                })
              }}
            />
            <Typography
              style={{
                color: acceptTerms ? 'black' : '#9a99a0'
              }}
            >
              {'By creating an account, you agree to Initiate\'s'}&nbsp;
              <Typography
                variant="caption"
                color="primary"
                data-testId="termButton"
                style={{
                  fontSize: 'inherit',
                  cursor: "pointer",
                }}
                onClick={(event: { stopPropagation: () => void; preventDefault: () => void; }) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.setState({ modalValue: "Terms & Conditions" });
                }}
              >
                {'Terms & Conditions'}
              </Typography>
              {' and '}
              <Typography
                data-testId="privacyButton"
                color="primary"
                variant="caption"
                style={{
                  cursor: "pointer",
                  fontSize: 'inherit',
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.setState({ modalValue: "Privacy Policy" });
                }}
              >
                {'Privacy Policy'}
              </Typography>
            </Typography>
          </Box>
          </CardContent>
        </Container>
      // Customizable Area End
    );
  }
}



export default RegistrationFormPart2Business;
export {RegistrationFormPart2Business};
