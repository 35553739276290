import React from 'react';
// Customizable Area Start
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, } from "@material-ui/core";
import { FAMILYFONTS } from "../../../components/src/constants";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { InitiateButton } from "../../../components/src/design-system/button";
import { Formik, Form } from 'formik';
import *  as yup from 'yup';
import { FormikTextField } from '../../../components/src/shared/formik-text-field';
import { useLogin } from '../../../components/src/api/hooks/user.hooks';
import { useNavigate } from '../../../components/src/utils/router-context';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { InitiateAlert } from '../../../components/src/design-system/alert';
// Customizable Area End

// Customizable Area Start
const validationSchema = yup.object().shape({
  email: yup.string().email().required("Email is a required field"),
  password: yup.string().required("Password is a required field")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must be of 8 characters with a capital letter, a special character & a number."
    ),
});

const useStyles = makeStyles((theme) => ({
  forgotPasswordLinkContainer: {
    textAlign: "right",
    padding: '10px 0 40px 0',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    cursor: "pointer",
    fontFamily: 'Rene_Bieder_Milliard_Medium',
  },
  submitBtn: {
    ...FAMILYFONTS.family1,
    fontSize: 20,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  submitBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  signupTextContainer: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  needAccountText: {
    ...FAMILYFONTS.family3,
    fontSize: 16,
    fontWeight: 400,
    color: "rgba(77, 77, 77, 1)",
    paddingRight: '5px',
  },
  signupText: {
    ...FAMILYFONTS.family2,
    fontSize: 16,
    fontWeight: 400,
    textDecoration: 'underline'
  },
  signInText: {
    ...FAMILYFONTS.family2,
    fontSize: 36,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));
// Customizable Area End

// Customizable Area Start
export function LoginForm({fcmTknSend}:any) {
  const classes = useStyles();
  const { mutate: login, error, isLoading } = useLogin();
  const navigate = useNavigate();
  const errorMsg = error?.errors?.[0]?.failed_login;
  // Customizable Area End
  
  return (
    // Customizable Area Start
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {

        login({ ...values }, {
          onSuccess(responseJson) {

            const { meta } = responseJson;
            localStorage.setItem("token", meta?.token)
            localStorage.setItem("acc_id", meta?.id)
            localStorage.setItem("user_type", meta?.user_type);
            localStorage.setItem("user_email", values?.email);
            localStorage.setItem("isLogin", "false");
            navigate("HomeDashBoard");
          },
        });
      }}
    >
      {
        formik => (
          // @ts-ignore
          <Form translate="no">

            <Box>

              <Typography
                color="primary"
                variant="body1"
                className={classes.signInText}>
                {'Sign In'}
              </Typography>

              <Box>
                <VerticalSpacer value={40} />

                <FormikTextField
                  label="Email Address"
                  data-test-id="txtInputEmail"
                  placeholder={"Email address"}
                  fullWidth
                  variant="outlined"
                  name="email"
                  type="email"
                />

                <VerticalSpacer value={40} />

                <FormikTextField
                  label="Password"
                  data-test-id="txtInputPassword"
                  type="password"
                  name="password"
                  placeholder={"Password"}
                  fullWidth
                  variant="outlined"
                />
                              <HideContainer hideWhen={!errorMsg}>
                <InitiateAlert>
                  {errorMsg}
                </InitiateAlert>
              </HideContainer>

                <Box className={classes.forgotPasswordLinkContainer} >
                  <Link
                    className={classes.link}
                    data-test-id={"btnForgotPassword"}
                    to={"ForgotPassword"}
                  >
                    {'Forgot password?'}
                  </Link>
                </Box>
              </Box>




              <Box className={classes.submitBtnContainer}>
                <InitiateButton
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  fullWidth
                  className={classes.submitBtn}
                  disabled={!formik.isValid || isLoading}
                  loading={isLoading}
                >
                  {'Sign In'}
                </InitiateButton>

                <VerticalSpacer />

                <Typography className={classes.signupTextContainer}>
                  <span className={classes.needAccountText}>
                    {'Need an account?'}
                  </span>
                  <Link
                    data-test-id={"btnNavigateToSignUp"}
                    className={classes.link}
                    to={"/EmailAccountRegistrationweb"}
                  >
                    <span className={classes.signupText}>
                      {'Sign Up'}
                    </span>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Form>

        )}
    </Formik>
    // Customizable Area End
  );
}
