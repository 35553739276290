
 {/* Customizable Area Start */}

import * as eventActionTypes from "./filterEventsAction"

const initialstate = {
    selectedEvents:  new Array()
}



const filterEventsReducer = (state = initialstate, action: { type: any; payload: { selectedEvents: any } }) => {
    if (action.type === eventActionTypes.SET_SELECTED_FILTER_EVENTS) {
        return {
            ...state,
            selectedEvents: action.payload.selectedEvents
        }
    } else {
        return state
    }




}

export default filterEventsReducer;


 {/* Customizable Area End */}