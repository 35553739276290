import React from 'react';
import {
  Switch,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";

export const InitiateSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "40px",
      height: "20px",

      borderRadius: '11.5px',
      padding: 0,
      display: "flex",
      overflow: "inherit",
    },
    switchBase: {
      padding: 3,
      color: COLORS.infoGray,
      '&$checked': {
        transform: 'translateX(16px)',
        color: COLORS.skyBlue,
        "& + $track": {
          opacity: 1,
          backgroundColor: COLORS.white,
          borderColor: COLORS.infoGray,
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
    },
    thumb: {
      width: '16px',
      height: '16px',
      marginLeft: '3px',
      marginTop: '-1px',
      boxShadow: 'none',
    },
    track: {
      border: ' 1px solid rgba(151, 151, 151, 0.43272)',
      borderRadius: '11.5px',
      opacity: 1,
      backgroundColor: COLORS.white,
    },
    checked: {},
  })
)(Switch);
