import React from "react";
//Customizable Area Start
import {
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// @ts-ignore
import styles from "./ContentManagementEvent.module.css";

import ContentManagementController from "./ContentManagementController";

export class DeleteEventModal extends ContentManagementController {
  render() {
    return (
      <div style={{ padding: 15 }}>
        <Typography className={styles.heading}>
          Are you sure you want to delete this event?
        </Typography>
        <FormControlLabel
          data-testId="check-2"
          control={
            <Checkbox
              style={{
                color: "lightgrey",
              }}
              checked={this.state.diy}
              checkedIcon={
                <CheckBoxIcon
                  style={{
                    color: "#359ce2",
                  }}
                />
              }
              onChange={(e) => {
                this.setState({
                  diy: e.target.checked,
                });
              }}
            />
          }
          label={
            <span className={styles.label}>
              Do you want to notify the users who pledged this event?
            </span>
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            onClick={() => {
              this.props.confirmClick();
            }}
            style={{
              width: "70%",
              alignSelf: "center",
              boxShadow: "none",
              textTransform: "capitalize",
              height: 45,
              backgroundColor: "#009be2",
              color: "white ",
              borderRadius: 10,
            }}
          >
            Confirm
          </Button>
          <br />
          <Button
            onClick={() => {
              this.props.cancelClick();
            }}
            style={{
              fontFamily: "Rene_Bieder_Milliard_SemiBold",
              width: "70%",
              color: "#009be2",
              boxShadow: "none",
              alignSelf: "center",
              borderRadius: 8,
              height: 45,
              border: "0.1px solid lightgray",
              textTransform: "capitalize",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

//Customizable Area End
