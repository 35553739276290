import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils/utils.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  filteredData: any;
  tableData: any;
  tabActive: number;
  filterToggle: boolean;
  categoryOpen: boolean;
  statusOpen: boolean;
  categoryFilter: string[];
  statusFilter: string[];
  isOpen: any;
  toDate: any;
  fromDate: any;
  value: any;
  values: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ActivityLogController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginAPICallId: string = "";
  getUserPointsHistoryApiCallId: string = "";
  getNonProfitPointsHistoryApiCallId: string = "";
  getEventActivityLogApiCallId: string = "";
  getDonationsActivityLogApiCallId: string = "";
  getUsersActivityLogApiCallId: string = "";
  getBusinessActivityLogApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      filterToggle: false,
      tabActive: 1,
      values: [],
      tableData: [],
      filteredData: [],
      categoryOpen: false,
      statusOpen: false,
      categoryFilter: [],
      statusFilter: [],
      isOpen: false,
      toDate: null,
      fromDate: null,
      value: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let user = localStorage.getItem("user_type");
    if (user !== "business_user") {
      this.getEventActivityLog();
    } else {
      this.getBusinessActivityLog();
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responsesJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responsesJson) {
        if (responsesJson.message) {
          toast.error(`${responsesJson.message}`);
        } else if (responsesJson.errors?.length > 0) {
          toast.error(`${responsesJson.errors[0].token}`);
        } else {
          this.checkAPIResponses(apiRequestCallId, responsesJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkAPIResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getEventActivityLogApiCallId:
        this.setState({
          filteredData: responseJson.map((item: any) => {
            return {
              ...item,
              date: `${item.attributes.date_time ? (moment(item.attributes.date_time).format("DD/MM/YYYY HH:mm A")) : "--"}`,
              category: item.attributes.category ?? "--",
              type: (item.attributes.type.charAt(0).toUpperCase() + item.attributes.type.slice(1)).replace("_", " "),
              participants: item.attributes.participants ?? "--",
              pending_approvals: item.attributes.pending_approvals ?? "--" ,
              status: item.attributes.status ?? "--",
              title: item.attributes.title ?? "--"
            };
          }),
          tableData: responseJson.map((item: any) => {
            return {
              ...item,
              category: item.attributes.category ?? "-",
              date: `${item.attributes.date_time ? (moment(item.attributes.date_time).format("DD/MM/YYYY HH:mm A")) : "--"}`,
              type: (
                item.attributes.type.replace("_", " ").charAt(0).toUpperCase() + item.attributes.type.slice(1)
              ).replace("_", " "),
              participants: item.attributes.participants ?? "--",
              pending_approvals: item.attributes.pending_approvals ?? "--",
              status: item.attributes.status ?? "--",
              title: item.attributes.title ?? "--"
            };
          }),
        });
        break;
      case this.getDonationsActivityLogApiCallId:
        this.setState({
          filteredData: responseJson.map((item:any) =>  {
            return {
              ...item,
              amount: item.attributes.amount ?? "--",
              title: item.attributes.title ?? "--",
              donors: item.attributes.donors ?? "--"
            }
          }),
          tableData: responseJson.map((item:any) =>  {
            return {
              ...item,
              amount: item.attributes.amount ?? "--",
              title: item.attributes.title ?? "--",
              donors: item.attributes.donors ?? "--"
            }
          }),
        });
        break;
      case this.getUsersActivityLogApiCallId:
        this.setState({
          filteredData: responseJson.map((item: any) => {
            return {
              ...item,
              login_date: `${item.login_date ? (moment(item.login_date).format("DD/MM/YYYY HH:mm A")) : "--"}`,
              log_off_date: `${item.log_off_date  ? (moment(item.log_off_date).format("DD/MM/YYYY HH:mm A")) : "--"}`,
            };
          }),
          tableData: responseJson.map((item: any) => {
            return {
              ...item,
              login_date: `${item.login_date ? (moment(item.login_date).format("DD/MM/YYYY HH:mm A")) : "--"}`,
              log_off_date: `${item.log_off_date  ? (moment(item.log_off_date).format("DD/MM/YYYY HH:mm A")) : "--"}`,
            };
          }),
        });
        break;
      case this.getBusinessActivityLogApiCallId:
        this.setState({
          filteredData: responseJson.data.map((item: any) => {
            return {
              ...item,
              title: item.attributes.title,
              organizer: item.attributes.organizer,
              date: `${moment(item.attributes.date_time).format("DD/MM/YYYY HH:mm A") ?? "--"}`,
            };
          }),
          tableData: responseJson.data.map((item: any) => {
            return {
              ...item,
              title: item.attributes.title,
              organizer: item.attributes.organizer,
              date: `${moment(item.attributes.date_time).format("DD/MM/YYYY HH:mm A") ?? "--"}`,
            };
          }),
        });
        break;
    }
  };

  getEventActivityLog = async () => {
    this.setState({ tableData: [], filteredData: [] });
    const header = {
      token: localStorage.getItem("nonprofitToken"),
      type: "non_profit_entity",
    };
    this.getEventActivityLogApiCallId = await apiCall({
      method: "GET",
      header: header,
      endPoint: `/bx_block_profile/events_activity`,
    });
  };

  getUsersActivityLog = async () => {
    this.setState({ tableData: [], filteredData: [] });

    const header = {
      token: localStorage.getItem("nonprofitToken"),
      type: "non_profit_entity",
    };
    this.getUsersActivityLogApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_profile/user_history`,
      header: header,
    });
  };

  getDonationsActivityLog = async () => {
    this.setState({ 
      tableData: [], 
      filteredData: [] 
    });

    const header = {
      token: localStorage.getItem("nonprofitToken"),
      type: "non_profit_entity",
    };
    this.getDonationsActivityLogApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_profile/donation_history`,
      header: header,
    });
  };

  getBusinessActivityLog = async () => {
    const header = {
      token: localStorage.getItem("token"),
      type: "business_entity",
      "content-type": "application/json",
    };
    this.getBusinessActivityLogApiCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_profile/business_activity`,
      header: header,
    });
  };

  formatDate = (date: any) => {
    if (date) return moment(date).format("DD/MM/YYYY");
  };

  handleClearClick = () => {
    this.setState({
      value: null,
      toDate: null,
      fromDate: null,
      statusFilter: [],
      categoryFilter: [],
    });
  };

  handleSearchClick = (search: string, list: string) => {
    const { filteredData } = this.state;

    let searchString = search.startsWith(" ") ? search.trim() : search;

    let tableData = filteredData.filter((item: any) => {
      if (searchString === "") {
        return true;
      } else if (
        item.title?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.user_name?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.user_id?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.login_date?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.log_off_date?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.event_activity?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.amount?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.recurring?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.donors?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.status?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.organizer?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.type?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.category?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.participants?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.pending_approvals?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.date?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
        item.time?.toString().toLowerCase().includes(searchString.toLowerCase())
      ) {
        return item;
      }
    });
    this.setState({ tableData });
  };

  onDateChange = (e: any) => {
    this.setState({
      fromDate: e[0],
      toDate: e[1],
      value: e,
    });
  };

  process = (date: any) => {
    const parts = date.split("/");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };

  compareDates = (date: any, fromDate: any, toDate: any) => {
    const one = this.process(date);
    const two = this.process(fromDate);
    let three = this.process(toDate);
    if (one >= two && one <= three) {
      return true;
    } else {
      return false;
    }
  };

  handleChange = (arrayVal:any ,dropdownName: any) => {
    if (dropdownName === "categoryFilter") {
      this.setState({ categoryFilter: arrayVal });
    } else if (dropdownName === "statusFilter") {
      this.setState({ statusFilter: arrayVal });
    }
  };

  handleApplyClick = () => {
    const { filteredData, fromDate, toDate } = this.state;
    let formatedFromDate = this.formatDate(fromDate);
    let formatedToDate = this.formatDate(toDate);

    let tableData = filteredData.filter((item: any) => {
      let isTrue = true;
      let formatedRowDate = item.date.slice(0, 10);
      if (fromDate && toDate) {
        isTrue = isTrue && this.compareDates(formatedRowDate, formatedFromDate, formatedToDate);
      }
      return isTrue;
    });
    this.setState({ tableData, filterToggle: false });
  };
  // Customizable Area End
}
