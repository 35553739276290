import React from "react";

// Customizable Area Start
import DBSdetailsController, { Props } from "./DBSdetailsController";
import { DBSForm } from "./DBSForm";
// Customizable Area End

class DBSdetails extends DBSdetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <DBSForm/>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default DBSdetails
export {DBSdetails};
// Customizable Area End
