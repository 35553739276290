// Customizable Area Start
//  @ts-nocheck
import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup,
    Checkbox,
    Toolbar,
    IconButton,
    Input
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { icons, constants, FONTS } from "../../../components/src/constants";
import { isMobile } from "react-device-detect";
import { COLORS } from "../../../framework/src/Globals";
// @ts-ignore
import classesoutercss from "./BusSureveys.module.css";
import Pagination from '@material-ui/lab/Pagination';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import BusSurveysController, {
    Props,
} from "./BusSurveysController";
import { withRouter } from "react-router-dom"

class UserSurveys extends BusSurveysController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    surveyDescription = () => {
        return (
            <Box className={classesoutercss.toolBarInner}>
                <Box style={{ marginBottom: 'auto' }}>
                    <div className={classesoutercss.toolBarInnerHead}>
                        <Typography variant="h4"> {this.props.location.state?.surveyData?.title}</Typography>
                    </div>
                    <Box className={classesoutercss.descriptionOuter}>
                        <Typography variant="h5">Survey Description</Typography>
                        <Box className={classesoutercss.descriptionInBox}>
                            <Typography variant="subtitle1">{this.props.location.state?.surveyData?.description}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classesoutercss.descriptionSubmitBox}>
                    <Button
                        onClick={() => this.handleStartSurvey()}
                        className={classesoutercss.descriptionSubmitBtn} data-test-id="startSurvey"
                        variant="contained">
                        Start
                    </Button>
                </Box>
            </Box>
        )
    }

    header() {
        return (
            <Toolbar className={classesoutercss.toolBar}>
                {/*  search  section   */}
                {!isMobile &&
                    <div className={classesoutercss.backIconWeb}>
                        <IconButton
                            onClick={() => this.handleEndSurvey()}
                            data-test-id='backBtn'
                            color={"inherit"}
                            aria-label="Open drawer"
                        >
                            <img src={icons.back} />
                        </IconButton>
                        <Typography variant="h6" style={{ ...FONTS.body1, color: COLORS.black, fontSize: "20px" }} noWrap>
                            Surveys
                        </Typography>
                    </div>
                }
            </Toolbar>
        )
    }

    surveyQuestionsFrame = () => {
        return (
            <Box className={classesoutercss.toolBarInner}>
                <Box style={{ marginBottom: 'auto' }}>
                    <Box className={classesoutercss.toolBarTopHead}>
                        <div className={classesoutercss.toolBarInnerHead}>
                            <Typography variant="h4"> {this.props.location.state?.surveyData?.title}</Typography>
                            <Typography variant="h6">Question {this.state.activePage}/{this.state.surveyQuetionData.length}</Typography>
                        </div>
                        {this.state.activePage !== this.state.surveyQuetionData.length &&
                            <div className={classesoutercss.toolBarInnerIcon}>
                                <div className={classesoutercss.toolBarInnerimage}>
                                    <img src={icons.likeIcon} />
                                    <Typography variant="subtitle2"><span>{this.renderPercentageProgress(this.state.activePage, this.state.surveyQuetionData.length)}%</span> Completed</Typography>
                                </div>
                            </div>}
                    </Box>
                    {this.state.surveyQuetionData.slice((this.state.activePage - 1) * this.state.itemsPerPage, (this.state.activePage - 1) + this.state.itemsPerPage).map((item: any) => this.surveyQuestionBlocks(item))}
                </Box>
                <Box className={classesoutercss.descriptionSubmitBox}>
                    <div className={classesoutercss.descPagination} >
                        <Pagination count={this.state.surveyQuetionData.length} page={this.state.activePage} variant="outlined" shape="rounded" />
                    </div>

                    {this.state.activePage === this.state.surveyQuetionData.length ? <Button className={classesoutercss.descriptionSubmitBtn}
                        variant="contained" data-test-id="completeBtn" onClick={() => this.createSurveysAnswer()}>
                        Complete
                    </Button>
                        :
                        <Button className={classesoutercss.descriptionSubmitBtn} data-test-id="submitBtn"
                            variant="contained" disabled={this.state.isDisabled} onClick={() => this.handleNextPage(this.state.activePage + 1)}>
                            Next
                        </Button>
                    }
                </Box>
            </Box>
        )
    }

    surveyQuestionBlocks = (item: any) => {
        const blockType = {
            text: this.textQuestionBlock(item),
            radio: this.radioQuestionBlock(item),
            check: this.checkboxQuestionBlock(item),
            range: this.rangeQuestionBlock(item),
            completed: this.completeSurveyBlock(),
        }

        return blockType[item.qType]
    }

    completeSurveyBlock = () => {
        return (
            <>
                <div className={classesoutercss.completedSurvey}>
                    <Box className={classesoutercss.descriptionOuter}>
                        <div className={classesoutercss.toolBarInnerIcon}>
                            <div className={classesoutercss.toolBarInnerimage}>
                                <img src={icons.likeIcon} />
                                <Typography variant="subtitle2"><span>100%</span> Completed</Typography>
                            </div>
                        </div>
                    </Box>
                </div>
            </>
        )
    }

    handleRangeNumbers = (start: any, end: any) => {
        const array = []

        for (let index = start; index <= end; index++) {
            array.push(index)
        }

        return array
    }

    rangeQuestionBlock = (item: any) => {
        return (
            <>
                <Box className={classesoutercss.descriptionOuter}>
                    <Typography variant="h5">{item?.attributes?.title}</Typography>
                    <div className={classesoutercss.radioWrapper}>
                        <div style={{ display: 'flex' }}>
                            <span style={{ padding: 10 }}>{item?.attributes?.range?.data?.attributes?.start_range_text}</span>
                            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} name="range" onChange={(e) => this.handleChange(e, item?.id)}>
                                {this.handleRangeNumbers(item?.attributes?.range?.data?.attributes?.start_range, item?.attributes?.range?.data?.attributes?.end_range)?.map((item: any) => {
                                    return (
                                        <FormControlLabel value={item.toString()} control={<Radio className={classesoutercss.descRangeBox} />} />
                                    )
                                })}
                            </RadioGroup>
                            <span style={{ padding: 10 }}>{item?.attributes?.range?.data?.attributes?.end_range_text}</span>
                        </div>
                    </div>
                </Box>
            </>
        )
    }

    checkboxQuestionBlock = (item: any) => {
        return (
            <>
                <Box className={classesoutercss.descriptionOuter}>
                    <Typography variant="h5">{item?.attributes?.title}</Typography>
                    <Typography variant="subtitle1">Choose only {item?.attributes?.choose_limit} options*</Typography>
                    <div>
                        <FormControl component="fieldset">
                            <FormGroup className={classesoutercss.radioDescSection}>
                                {item?.attributes?.options?.data.map((items: any, index: number) => {
                                    return (
                                        <FormControlLabel data-test-id={`check-${index}`} className={classesoutercss.descCheckBox} control={<Checkbox disabled={this.state.multipleOptions.length === item.attributes.choose_limit} onChange={(e) => this.handleMultiChange(e, item?.id, true, item?.attributes?.choose_limit)} name="checked" value={items?.attributes?.id} />} label={items?.attributes?.name} />
                                    )
                                })}
                            </FormGroup>
                        </FormControl>
                    </div>
                </Box>
            </>
        )
    }

    radioQuestionBlock = (item: any) => {
        return (
            <>
                <Box className={classesoutercss.descriptionOuter}>
                    <Typography variant="h5">{item?.attributes?.title}</Typography>
                    <div>
                        <FormControl component="fieldset" className={classesoutercss.radioDescSection}>
                            <RadioGroup aria-label="gender" name="gender" onChange={(e) => this.handleChange(e, item.id)}>
                                {item?.attributes?.options?.data.map((item: any) => {
                                    return (
                                        <FormControlLabel className={classesoutercss.descRadioBox} value={item.id} control={<Radio />} label={item.attributes.name} />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Box>
            </>
        )
    }

    textQuestionBlock = (item: any) => {
        return (
            <>
                <Box className={classesoutercss.descriptionOuter}>
                    <Typography variant="h5">{item?.attributes?.title}</Typography>
                    <div>
                        <FormControl component="fieldset" className={classesoutercss.radioDescSection}>
                            <RadioGroup aria-label="gender" name="gender">
                                <FormControlLabel className={classesoutercss.descRadioBox} data-test-id='textFeild' control={<Input onChange={(e) => this.handleChange(e, item?.id)} disableUnderline />} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Box>
            </>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <DashboardWeb
                    showBackButton={constants._icons.show}
                    title={constants.dashboard_screens.Surveys}
                    showBottomBar={constants._icons.show}
                    showDrawerHeader={false}
                    navigation={this.props.navigation}
                >
                    <div>
                        {this.header()}

                        {
                            this.state.startSurvey ?
                                this.surveyQuestionsFrame()
                                :
                                this.surveyDescription()
                        }
                    </div>
                </DashboardWeb>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

export { UserSurveys }
export default withRouter(UserSurveys)
// Customizable Area End

// Customizable Area Start
const webStyle = {
    descPagination: {
        "& .Mui-selected": {
            color: '#009BE2'
        }
    },
};
// Customizable Area End
