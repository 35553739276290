import {ADV_SEARCH_PAYLOAD} from './advSearchPayloadaction'
const initState = {
    advSearchPayload: {}
}

function advSearchPayloadReducer(state = initState, action: any) {
    switch (action.type) {
        case ADV_SEARCH_PAYLOAD:
            return {
                ...state,
                advSearchPayload: action.payload
            }
        default:
            return state
    }
}

export default advSearchPayloadReducer;