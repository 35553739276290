import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// @ts-nocheck 
import { setSelectedTab } from "../../../framework/src/redux/tab/tabActions";
import { getAllEventsDetails } from "../../../framework/src/redux/advanceSearch/advanceSearchActions";
import { connect } from "react-redux";
import { constants } from "../../../components/src/constants";
import moment from "moment";
import { storeSearchDetails } from "../../../framework/src/redux/savedSearch/savedSearchAction";
import { setSelectedEvents, setAllEvents, setLocationWiseFilterEvent, setLocationWiseFilterEventMessage } from "../../../framework/src/redux/events/eventsActions";
import { setSelectedFilterEvents } from "../../../framework/src/redux/filterEvents/filterEventsAction";
import { setCurrentLocation } from "../../../framework/src/redux/Location/action"
import { savedSearchPayload } from "../../../framework/src/redux/savedSearchPayload/savedSearchPayloadAction";
import { CurrentLocation, getCurrentLocation } from "../../../components/src/utils/current-location";
import { COLORS } from "../../../framework/src/Globals";
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils/utils.web";


// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setSavedSearchPayload: any;
  savedSearchDetailsItem: any;
  setAdvanceSearchToggle: any;
  advanceSearchToggle: any;
  setMainPageLoader: any;
  children?: React.ReactNode | React.ReactNode[];
  showBackButton: any; title: any; showBottomBar: boolean; showDrawerHeader: boolean;
  mobileOpen: boolean;
  open: boolean;
  classes: any;
  mainPageLoader: any;
  theme?: any;
  history: any;
  DashboardWeb?: any;
  titleColor: any;
  showLine: any;
  setSelectedTab: any;
  selectedTab: any;
  setSelectedEvents: any;
  getAllEventsDetails: any;
  setSelectedFilterEvents: any;
  storeSearchDetails: any;
  onChangeCategories: any;
  advanceSearchReducer: any;
  setAllEvents: any,
  setLocationWiseFilterEvent: any;
  setLocationWiseFilterEventMessage: any;
  allEvents: any;
  selectedLocationEvents: any;
  selectedLocationWiseEventsMessageError: any,
  setCurrentLocation: any,
  notificationmodalState: any;
  // location 
  latitude: number | null;
  longitude: number | null;
  postcode: any;
  location: any;
  address: {
    doorName: string;
    area: string;
    city: string;
    country: string;
    postcode: string;
  };

  error: string | null;

  // carousel 
  type: 'PREV' | 'NEXT';
  isEdge: boolean;
  pages: React.Key[];
  activePage: React.Key;
  onClick: (page: React.Key) => void;

  eventTabValue: 'Upcoming' | 'Past';
  loading: boolean;
  attributes: {
    event_type: string;
    event_name: string;
    from_date: Date;
    location: string;
    event_image?: {
      url: string;
    };
    dbs_registration: boolean;
  };
  fetchCurrentLocation: () => void;
  upComingPastEvent: any;
  upComingPastEventError: any;
  searchValue?: any;
  filterEventsReducer?: any;
  homeClick: any;
  getDbsData: any;
  handleCategoryChange: any;
  categories: any;
  searchEvent: any;
  savedSearches: any;
  dbsSwitch: any;
  allDay: any;
  handleDBSSwitch: any
  handleAllDay: any
  fromDate: any
  toDate: any
  calOpen: any
  setFromDate: any
  setToDate: any
  setCalOpen: any
  valueOpen: any
  setValueOpen: any
  startTime: any
  endTime: any
  setStartTime: any
  setEndTime: any
  proxyValue: any
  setProxyValue: any
  storeUpcommingEvents?: any;
  tabHandler?: any;
  handleUpdate?: any;
  showLoader?: any;
  setAdvSearchPayload?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  advanceSearchToggle: boolean;
  badgeBoolean: boolean;
  fromDate: any;
  mainPageLoader: boolean;
  toDate: any;
  calOpen: boolean;
  valueOpen: boolean;
  startTime: any;
  endTime: any;
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  mobileOpen: boolean;
  open: boolean;
  sideBarClose:boolean;
  classes: any;
  theme?: any;
  isMobile: boolean;
  selectedBottomTab: any;
  slideIndex: any;
  eventTabValue: any;
  setAnchorEl: any;
  searchFilterShow: any;
  selectedTab?: any;
  upComingEventData: any;
  pastEventData: any;
  upComingPastEvent: any;
  screenLaoder: any;
  upComingPastEventError: string;
  storeUpcommingEvents: any;
  getDbsData: any;
  categories: any;
  dbsSwitch: any;
  allDay: any;
  locationmodal: any;
  openSuccessModal: boolean;
  showsavedsearch: boolean;
  notificationShow: boolean;
  locationWiseEvents: any;
  initialRender: any;
  searchValue: any;
  proxyValue: any;
  currentLocation: CurrentLocation,
  userDetails: any
  lat: any,
  lng: any,
  post_code: any;
  current_location: any;
  initiateBanner: any
  isAdvanceSearch: any
  eventId: string;
  eventType: any;
  favEventId: string;
  isDialogOpen: boolean;
  broadcastMessage: string;
  isLoading: boolean;
  placeholderImg: any;
  imageLoading: boolean;
  showLoader: boolean;
  fullLocation: any;
  saved_location: string | undefined;
  saved_post_code: string;
  saved_city:string;
  city: any;
  street_name: any;
  addressParts: any;
  anchorEl: any;
  isDashaboardTextHover:boolean;
  carosalDetailHeadingText?:string;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  [key: string]: boolean;
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  removeFromFavApiId: string = "";
  logoffApiCallId: string = ""
  removeDeviceTokenApiCallId: string = "";
  upComingEventsId: any;
  pastEventsId: any;
  searchEventId: any = "";
  getDbsCateId: any;
  searchInputQueryId: any;
  addsavedsearchApiId: any;
  getSavedSearchId: any;
  private _isMounted: boolean;
  getAllEventCallId: any;
  addToFavApiId: any;
  fetchUserDataApiId: any;
  bannerImageApiId: any;
  placeholderImgId: any;
  getNotifyListId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;


    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];


    this.state = {
      // Customizable Area Start
      advanceSearchToggle: false,
      mainPageLoader: false,
      badgeBoolean: false,
      fromDate: new Date(),
      toDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      calOpen: false,
      valueOpen: false,
      dashboardData: [],
      errorMsg: "",
      eventTabValue: "Upcoming",
      token: "",
      loading: false,
      mobileOpen: false,
      open: true,
      sideBarClose:false,
      classes: {},
      theme: {},
      isMobile: false,
      selectedBottomTab: 0,
      slideIndex: 1,
      setAnchorEl: null,
      searchFilterShow: false,
      upComingEventData: {},
      pastEventData: {},
      upComingPastEvent: {},
      screenLaoder: false,
      upComingPastEventError: "",
      storeUpcommingEvents: {},
      getDbsData: new Array(),
      categories: new Array(),
      dbsSwitch: false,
      allDay: true,
      locationmodal: false,
      openSuccessModal: false,
      notificationShow: false,
      showsavedsearch: false,
      locationWiseEvents: new Array(),
      initialRender: true, // Track initial rendering
      searchValue: "",
      proxyValue: 10,
      currentLocation: {
        area: '',
        city: '',
        address: '',
        country: '',
        doorName: '',
        postcode: '',
        latitude: 0,
        longitude: 0,
      },
      fullLocation: "",
      saved_location:"",
      saved_post_code:"",
      saved_city:"",
      city: "",
      street_name: "",
      addressParts: "",
      userDetails: null,
      lng: 0,
      lat: 0,
      post_code: '',
      current_location: '',
      initiateBanner: '',
      isAdvanceSearch: false,
      eventId: "",
      eventType: "",
      favEventId: "",
      isDialogOpen: false,
      broadcastMessage: "",
      isLoading: false,
      placeholderImg: "",
      imageLoading: true,
      showLoader: true,
      anchorEl:null,
      isDashaboardTextHover:false,
      carosalDetailHeadingText:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this._isMounted = false;
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.resize();
    const path = window.location.pathname;
    if (!path.includes("HomeDashBoard")) {
      return;
    }
    if (localStorage.getItem('token') === null && localStorage.getItem('user_type') !== 'user entity') {
      localStorage.clear()
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    this.fetchUser()
    // Simulate an asynchronous API call
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000); // Adjust the timeout duration as needed
    this._isMounted = true;
    this._isMounted &&
      this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    this.setState({ initialRender: false }); // Update initialRender after mounting
    typeof window !== 'undefined' && window.addEventListener("resize", this.resize.bind(this));
    this.getDbsCategories();
    this.getSavedSearch();
    this.getAllEvents();
    if (window.location.pathname === '/HomeDashBoard') {
      this.bannerImage();
      this.getPlaceholderImage();
    }
    this.getNotifyList()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    // Customizable Area Start

    if (apiRequestCallId === this.upComingEventsId) {
      this.handleUpcomingResponse(responseJson)

    } else if (apiRequestCallId === this.pastEventsId) {
      this.handlePastResponse(responseJson)
    } else if (apiRequestCallId === this.getDbsCateId) {
      this.setState({ getDbsData: responseJson?.data });
    } else if (apiRequestCallId === this.searchEventId) {
      this.searchEventIdRefractor(responseJson)
    } else if (apiRequestCallId === this.searchInputQueryId) {
      this.searchInputRefractor(responseJson)
    } else if (apiRequestCallId === this.addsavedsearchApiId) {
      this.getSavedSearch();
    } else if (apiRequestCallId === this.fetchUserDataApiId) {
      this.handlefetchApiResponse(responseJson)
    } else {
      this.handleApiResponse(apiRequestCallId, responseJson);
    }

    // Customizable Area End
  }



  // Customizable Area Start
  async componentDidUpdate(prevProps:any, prevState:any) {
    if(prevProps.eventView && prevProps !== this.props) {
        this.setState({ city: prevProps.city, post_code: prevProps.post_code, street_name: prevProps.street_name, fullLocation: prevProps.fullLocation})
      }
  }
  handleApiResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.addToFavApiId) {
      toast.success("Added to favourites!");
      this.apiSuccessResponse(responseJson);
    } else if (apiRequestCallId === this.removeFromFavApiId) {
      toast.success("Removed from favourites!");
      this.apiSuccessResponse(responseJson);
    } else if (apiRequestCallId === this.bannerImageApiId) {
      this.setState({ initiateBanner: responseJson?.logo?.url })
    } else if (apiRequestCallId === this.getSavedSearchId) {
      this.refactorGetSavedSearch(responseJson);
    } else if (apiRequestCallId === this.getAllEventCallId) {
      this.refactorGetalleventsResponse(responseJson);
    }
    else if (apiRequestCallId === this.placeholderImgId) {
      this.handlePlaceholderImage(responseJson)
    }
    else if (apiRequestCallId === this.getNotifyListId) {
      this.handleBadgeData(responseJson)
    }else if(apiRequestCallId === this.logoffApiCallId) {
      localStorage.clear()
      this.props.navigation.navigate('EmailAccountLoginBlock');
    }

  }

  handleBadgeData(responseJson: any) {
    responseJson.data && responseJson.data.length !== 0 && this.setState({ badgeBoolean: true })
  }
  handleApiRefractor=(responseJson: any)=>{
    let result = this.props.filterEventsReducer;
      result = result.map((item: any) => {
        if (item.id === this.state.favEventId) {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              is_favourite: !item.attributes.is_favourite,
              favourite_id: responseJson?.data?.id
            }
          }
        } else {
          return { ...item };
        }
      });
      this.props.setSelectedFilterEvents(result);
  }
  
  apiSuccessResponse = (responseJson: any) => {
    let isAdvanceSearch=this.state.searchValue || this.props.advanceSearchToggle
    if (isAdvanceSearch) {
        this.handleApiRefractor(responseJson)
    } else {
      let upComingPastEvent = this.state.eventTabValue == "Past" ? this.state.upComingPastEvent : this.props?.storeUpcommingEvents;
      upComingPastEvent = upComingPastEvent?.data?.map((item: any) => {
        if (item.id === this.state.favEventId) {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              is_favourite: !item.attributes.is_favourite,
              favourite_id: responseJson?.data?.id
            }
          }
        } else {
          return { ...item };
        }
      });
      if (this.state.eventTabValue === "Past") {
        this.pastEventRefactor({ data: upComingPastEvent });
      } else {
        this.upCommingRefactor({ data: upComingPastEvent });
      }
    }
  }

  refactorGetalleventsResponse(responseJson: any) {
    if (responseJson) {
      this.props?.setAllEvents?.(responseJson)
      this.setState({ isLoading: false });
    }

  }

  refactorGetSavedSearch(responseJson: any) {
    if (responseJson?.data) {
      this.props.storeSearchDetails(responseJson.data)
    }

  }
  pastEventRefactor(responseJson: any) {
    if (responseJson && responseJson?.data) {
      const currentDate : any = new Date();

      const data = responseJson.data

      // Calculate the difference between each object's dateTime and the current dateTime
      data.forEach((obj: any) => {
        const dateA: any = new Date(obj.attributes.to_date ?? obj.attributes.end_date);
        obj.dateTimeDifference = Math.abs(dateA - currentDate);
      });

      // Sort the array based on the dateTime difference
      data.sort((a: any, b: any) => {
        const dateDifference = a.dateTimeDifference - b.dateTimeDifference;
        if (dateDifference !== 0) {
          return dateDifference;
        }
        // If the dates are the same, sort by time
        return (a.attributes.to_date ?? a.attributes.end_date) - (b.attributes.to_date ?? b.attributes.end_date);
      });


      this.setState({ upComingPastEvent: responseJson, loading: false, showLoader: false });
      if (responseJson.data) {
        this.props.setLocationWiseFilterEvent(responseJson.data)
      }
    }
    else if (responseJson && responseJson.Message) {
      this.setState({ loading: false, upComingPastEventError: responseJson?.message, storeUpcommingEvents: [] });
      this.props.setLocationWiseFilterEventMessage(responseJson?.Message)
    } else {
      this.props.setLocationWiseFilterEventMessage('Something went wrong')
      this.setState({ loading: false, upComingPastEventError: responseJson?.error, storeUpcommingEvents: [] });
    }
  }

  upCommingRefactor(responseJson: any) {
    if (responseJson && responseJson.data) {

      const currentDate : any = new Date();

      const data = responseJson.data

      // Calculate the difference between each object's dateTime and the current dateTime
      data.forEach((obj: any) => {
        const dateA: any = new Date(obj.attributes.from_date ?? obj.attributes.start_date);
        obj.dateTimeDifference = Math.abs(dateA - currentDate);
      });



      // Sort the array based on the dateTime difference
      data.sort((a: any, b: any) => {
        const dateDifference = a.dateTimeDifference - b.dateTimeDifference;
        if (dateDifference !== 0) {
          return dateDifference;
        }
        // If the dates are the same, sort by time
        return (a.attributes.from_date ?? a.attributes.start_date) - (b.attributes.from_date ?? b.attributes.start_date);
      });



      // Remove the dateTimeDifference property (optional)
      data.forEach((obj: any) => delete obj.dateTimeDifference);

      this.setState({ upComingPastEvent: responseJson });
      this.setState({ storeUpcommingEvents: responseJson, showLoader: false });
      if (this.props?.handleUpdate) {
        this.props?.handleUpdate(responseJson)
      }
      if (responseJson.data) {
        this.props.setLocationWiseFilterEvent(responseJson.data)
        this.setState({ loading: false, showLoader: false })
      }
    } else if (responseJson && responseJson.Message) {
      this.props.setLocationWiseFilterEventMessage?.(responseJson?.Message)
      this.setState({ loading: false, upComingPastEvent: {}, upComingPastEventError: responseJson?.Message, storeUpcommingEvents: [] });
    } else {
      this.props.setLocationWiseFilterEventMessage('Something went wrong')
      this.setState({ loading: false, upComingPastEvent: {}, upComingPastEventError: responseJson?.error, storeUpcommingEvents: [] });
    }
  }

  searchFiltereventRefactor(responseJson: any) {
    if (responseJson.MSG) {
      this.setState({ upComingPastEventError: responseJson.MSG });
    } else if (responseJson.data) {
      this.props.setSelectedFilterEvents(responseJson.data)
      this.props.navigation.navigate("SearchScreenWeb")
    }
  }

  //Favourites
  addToFav = (id: any, types: any) => {
    this.setState({ favEventId: id });
    let token = localStorage.getItem("token")
    let type = ""
    if (types === "non_profit_entity") {
      type = "BxBlockEvents::NonProfitEvent"
    }
    else {
      type = "BxBlockEvents::Need"
    }
    const header = {
      "Content-Type": configJSON.dashboardContentType,
    };
    const attrs = {
      data: {
        favouriteable_id: id,
        favouriteable_type: type
      },
      token: token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToFavApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeFromFav = (item: any) => {
    this.setState({ favEventId: item.id });
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeFromFavApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites/${item?.attributes?.favourite_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // get all events 
  getAllEvents = () => {
    this.setState({ isLoading: true });
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    };

    const getAllEventIds = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllEventCallId = getAllEventIds.messageId;

    getAllEventIds.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getAllEventIds.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllEventsApiEndPoint
    );

    getAllEventIds.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardGetMethod
    );
    runEngine.sendMessage(getAllEventIds.id, getAllEventIds);
  }

  // Getting DBS Categories
  getDbsCategories = () => {
    const getDbsCate = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDbsCateId = getDbsCate.messageId;

    getDbsCate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTasksEndPoint
    );

    getDbsCate.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardGetMethod
    );
    runEngine.sendMessage(getDbsCate.id, getDbsCate);
  }

  // savedsearch 

  getSavedSearch = () => {

    const header = {
      "token": localStorage.getItem("token"),
      "type":"user_entity"
    };

    const fetchSavedSearch = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedSearchId = fetchSavedSearch.messageId;

    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.savedsearchApiEndPoint
    );
    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(fetchSavedSearch.id, fetchSavedSearch);
  }

  fetchUser = () => {

    const acc_id = localStorage.getItem('acc_id')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchUserDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // account ID use 1  for testing purpose only.
      `${configJSON.getUserProfileApiEndPoint}?account_id=${acc_id}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 ? true : false });
    if (window.innerWidth >= 760) {
      this.setState({ open: true });
      this.setState({ isMobile: true });
    } else {
      this.setState({ open: false });
    }
  }

  componentWillUnmount(): any {
    this._isMounted = false;
    typeof window !== 'undefined' && window.removeEventListener("resize", this.resize.bind(this));
  }



  // proper search filter 
  handleClick = (event: React.MouseEvent<HTMLElement>) => {

    this.setState({ setAnchorEl: this.state.setAnchorEl ? null : event.currentTarget })
    this.setState({ searchFilterShow: !this.state.searchFilterShow ? true : false })
  };

  handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    const { lat, lng } = this.state;
    this.props.navigation.navigate("AdvancedSearch", { lat, lng });
  };

  mouseOverFnct = (e: any) => {
    e.target.style.backgroundColor = "#b1b1b1";
  }

  mouseOutFnct = (e: any) => {
    e.target.style.backgroundColor = COLORS.white;
  }

  updateFilterSavedSearch=(data:any)=>{
    const {all_day,categories,up_to_km,dbs_registration,from_date,to_date,start_time,end_time}=data?.saved_data
    let getDbsData=this.state.getDbsData.map((item:any)=>{
      if(categories.includes(item.attributes.task)){
        return {...item,isChecked:true}
      }
      else{
        return {...item,isChecked:false}
      }
    })
    let from =moment(from_date, "DD/MM/YYYY")
    let formattedFrom=new Date(from.format("MM/DD/YYYY"))

    let to =moment(to_date, "DD/MM/YYYY")
    let formattedTo=new Date(to.format("MM/DD/YYYY"))

   

    if(!all_day){
      let startTime= moment(start_time, "hh:mm A")
      let endTime= moment(end_time, "hh:mm A")
      this.setState({getDbsData,
        endTime,
      startTime,
      proxyValue: up_to_km,
      allDay: false,
      dbsSwitch: !!dbs_registration,
      fromDate: formattedFrom ?? this.state.fromDate,
      toDate: formattedTo ?? this.state.toDate,
    })
    }
    else{
      this.setState({getDbsData,
        fromDate:formattedFrom??this.state.fromDate,
        toDate:formattedTo??this.state.toDate,
        dbsSwitch:dbs_registration??false,
        allDay:all_day??false,
        categories,
        proxyValue:up_to_km,})
    }


    
  }

  setSavedSearchData = (data: any) => {
    // this.searchEvent(data.saved_data, true)
   
    this.setState({advanceSearchToggle: true,mainPageLoader: true})
    this.searchAdvanceSearch('', data.saved_data)
    this.props.setSavedSearchPayload(data.saved_data)
    this.updateFilterSavedSearch(data)
  }


  handleNoti = () => {
    this.setState({ notificationShow: !this.state.notificationShow })
  }

  // Home dashboard upcoming events
  upComingEvents = () => {
    this.setState({ loading: true, showLoader: true })
    const attrs = {
      location: this.state.saved_location || this.state.userDetails?.attributes?.street_name
    }

    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": localStorage.getItem("token"),
    };

    const getUpComingEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upComingEventsId = getUpComingEvents.messageId;

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upComingEventEndPoint
    );

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardPostMethod
    );
    runEngine.sendMessage(getUpComingEvents.id, getUpComingEvents);
  }

  // Home dashboard past events
  pastEvents = () => {
    this.setState({ loading: true, upComingPastEvent: {} })
    const getPastEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      location: this.state.saved_location || this.state.userDetails?.attributes?.street_name
    }

    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": localStorage.getItem("token"),
    };

    this.pastEventsId = getPastEvents.messageId;

    getPastEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pastEventEndPoint
    );
    getPastEvents.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getPastEvents.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    getPastEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardPostMethod
    );

    runEngine.sendMessage(getPastEvents.id, getPastEvents);
  }
  // check eventtype 
  check_event_type = (type: any) => {
    if (type === "need") {
      return "#03BD95";
    } else if (type === "project_funding") {
      return "#009BE2";
    } else if (type === "activity") {
      return "#B4038C";
    } else if (type === "update") {
      return "#CBEBF8";
    } else {
      return "#03BD95";
    }
  }

  check_event_type_name = (type: any) => {
    if (type === "need") {
      return "Volunteer";
    } else if (type === "project_funding") {
      return "Donate";
    } else if (type === "activity") {
      return "Attend";
    } else if (type === "update") {
      return "Update";
    } else {
      return "Volunteer";
    }
  }


  bottomBarIconSize = (selectedBottomTabs: any) => {
    if (selectedBottomTabs === constants.dashboard_screens.Pledges) {
      return { width: 17, height: 24 }
    } else if (selectedBottomTabs === constants.dashboard_screens.Needs) {
      return { height: 18, width: 24 }
    } else if (selectedBottomTabs === constants.dashboard_screens.Chats) {
      return { height: 26, width: 27 }
    } else if (selectedBottomTabs === constants.dashboard_screens.Profile) {
      return { height: 22, width: 22 }
    } else if (selectedBottomTabs === constants.dashboard_screens.home) {
      return { height: 27, width: 27 }
    }
  }

  savedSearches = (data: any) => {
    this.searchEvent(data)
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem('token'),
      "type":"user_entity"
    };
    const start_time = new Date(data?.start_time);
    start_time.setSeconds(0);
    const end_time = new Date(data?.end_time);
    end_time.setSeconds(0);
    const attrs = {
      saved_data: data?.all_day === true ? {
        dbs_registration: data?.dbs_included,
        all_day: data?.all_day,
        start_time: new Date(moment('09:00', 'HH:mm').toString()).toString(),
        end_time: new Date(moment('17:00', 'HH:mm').toString()).toString(),
        categories: this.state.categories,
        up_to_km: data?.radius,
        saved_name: data.customSearchName,
        location: this.state.saved_location,
        from_date: moment(data?.from_date).format("DD/MM/YYYY"),
        to_date: moment(data?.to_date).format("DD/MM/YYYY"),
      }
        :
        {
          dbs_registration: data?.dbs_included,
          categories: this.state.categories,
          from_date: moment(data?.from_date).format("DD/MM/YYYY"),
          to_date: moment(data?.to_date).format("DD/MM/YYYY"),
          start_time: start_time.toString(),
          end_time: end_time.toString(),
          up_to_km: data?.radius,
          saved_name: data.customSearchName,
          location:this.state.saved_location,
        }
    }
    this.setState({ searchFilterShow: false })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    Object.preventExtensions(this.addsavedsearchApiId)
    this.addsavedsearchApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.savedsearchApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }



  searchEvent = (data: any, savedSearch: any = false) => {
    this.setState({ advanceSearchToggle: true, mainPageLoader: true })
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem('token')
    };

    let from_date, to_date, start_time, end_time
    if (savedSearch) {
      from_date = data?.from_date
      to_date = data?.to_date
      start_time = data?.start_time
      end_time = data?.end_time
    } else {
      from_date = moment(data?.from_date).format("DD/MM/YYYY")
      to_date = moment(data?.to_date).format("DD/MM/YYYY")
      start_time = moment.utc(data?.start_time).format('HH:mm')
      end_time = moment.utc(data?.end_time).format('HH:mm')
    }

    const attrs = data?.all_day === true ? {
      dbs_registration: data?.dbs_included,
      // all_day: data?.all_day ? data?.all_day : null,
       categories: this.getIdFromDbs(this.state.categories),
      up_to_km: data?.radius ? data?.radius : null,
      // latitude: this.state.lat || this.props.latitude,
      // longitude: this.state.lng || this.props.longitude,
      location: this.state.saved_location,
      from_date: from_date,
      to_date: to_date,
      start_time: moment('09:00', 'HH:mm').utc().format('HH:mm'),
      end_time: moment('17:00', 'HH:mm').utc().format('HH:mm'),
    } :
      {
        dbs_registration: data?.dbs_included || data?.dbs_registration,
        categories: this.getIdFromDbs(this.state.categories),
        up_to_km: data?.radius || data?.up_to_km,
        from_date: from_date,
        to_date: to_date,
        start_time: start_time,
        end_time: end_time,
        // latitude: this.state.lat || this.props.latitude,
        // longitude: this.state.lng || this.props.longitude
        location: this.state.saved_location,
      }
    console.log("ATTRS123", attrs);
    this.setState({ searchFilterShow: false })


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchEventId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      !this.state.searchValue ? configJSON.advanceSearchApiEndPoint
        : `${configJSON.advanceSearchApiEndPoint}?search=${this.state.searchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  searchAdvAllDayProps = (data: any) => {
    return {
      dbs_registration: this.state.dbsSwitch,
      categories:this.getIdFromDbs(this.state.categories),
      up_to_km: this.state.proxyValue,
      location: this.state.saved_location || localStorage.getItem('current_location'),
      from_date: moment(this.state.fromDate).format("DD/MM/YYYY"),
      to_date: moment(this.state.toDate).format("DD/MM/YYYY"),
      start_time: moment('09:00', 'HH:mm').utc().format('HH:mm'),
      end_time: moment('17:00', 'HH:mm').utc().format('HH:mm'),
    }
  }

  searchAdvNotAllDayProps = (data: any) => {
    return {
      dbs_registration: this.state.dbsSwitch,
      categories:this.getIdFromDbs(this.state.categories),
      up_to_km: this.state.proxyValue,
      from_date: moment(this.state.fromDate).format("DD/MM/YYYY"),
      to_date: moment(this.state.toDate).format("DD/MM/YYYY"),
      start_time: moment(this.state.startTime).format('HH:mm'),
      end_time: moment(this.state.endTime).format('HH:mm'),
      location: this.state.saved_location || localStorage.getItem('current_location'),
    }
  }

  getIdFromDbs=(userSelected:any)=>{
   let selectedId=this.state.getDbsData.map((item:any)=>{
    if(userSelected?.includes(item?.attributes?.task)){
      return item?.id
    }
   })
   return selectedId?.filter((id:any)=>id)
  }

  searchAdvanceSearch(search: any, data?: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem('token')
    };
    const attrs = this.state.allDay === true ? this.searchAdvAllDayProps(data) : this.searchAdvNotAllDayProps(data)


    this.setState({ searchFilterShow: false })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchEventId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.advanceSearchApiEndPoint}?search=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  bannerImage = () => {
    const image_type = 'banner'
    const token = localStorage.getItem('token')

    const header = {
      token: token
    };
    const bannerImage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bannerImageApiId = bannerImage.messageId;

    bannerImage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    bannerImage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBannerApiEndPoint}?img_type=${image_type}`
    );
    bannerImage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    bannerImage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(bannerImage.id, bannerImage)
  }


  handleCategoryChange = (e: any) => {
    const { name, checked } = e.target;
    console.log(name, checked, e.target, 'insidemethod')
    if (name === "allCategoryCheck") {
      let tempCategory = this.state.getDbsData.map((category: any) => {
        this.setState(prevState => ({
          categories: [...prevState.categories, category.attributes.task]
        }))
        return { ...category, isChecked: checked };
      });
      this.allCheckedfunc(checked, tempCategory);

    } else {
      let tempCategory = this.state.getDbsData.map((category: any) => (
        category.attributes.task === name ? { ...category, isChecked: checked } : category))
      this.singleCheckedfunc(checked, name, tempCategory);
    }

  };

  allCheckedfunc(checked: any, tempCategory: any) {

    this.setState({ getDbsData: tempCategory })
    if (!checked) {
      this.setState({ categories: "" })
    }
  }

  singleCheckedfunc(checked: any, name: any, tempCategory: any) {

    this.setState({ getDbsData: tempCategory })
    if (checked) {
      this.setState(prevState => ({
        categories: [...prevState.categories, name]
      }))

    } else {
      this.setState((prevState) => ({
        categories: prevState.categories.filter((val: any) => val != name)
      }));

    }
  }


  handleClickLocationOpen = () => {
    this.setState({ locationmodal: true });
  };

  handleCloseLocationModal = () => {
    this.setState({ locationmodal: false });
  };

  handleSuccessModalOpen = (location?: string) => {
    this.setState({ saved_location: location ?? this.state.saved_location, openSuccessModal: true, locationmodal: false });
  };

  handleSuccessModalClose = () => {
    this.setState({ openSuccessModal: false, });
    this.fetchUser()
    if(this.state.isAdvanceSearch){
      return;
    }
    if (this.state.eventTabValue === 'Upcoming') {
      this.upComingEvents()

    } else {
      this.pastEvents()
    }
  }

  getFullAddress = (location: any) => {
    const addressParts = [];
    if (this.state.street_name) {
      addressParts.push(this.state.street_name);
    }
    if (this.state.city) {
      addressParts.push(this.state.city);
    }

    if (this.state.post_code) {
      addressParts.push(this.state.post_code);
    } 

    this.setState({ 
      fullLocation: location.location, 
      city: location.city, 
      post_code: location.post_code, 
      street_name: location.street_name,
      addressParts: addressParts.join(", ")
    })
  }


  // searchInput 
  searchinputChange = (queryval: any) => {
    this.setState({ mainPageLoader: true })
    const header = {}

    this.setState({ searchValue: queryval });
    const searchInputMesg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchInputQueryId = searchInputMesg.messageId;

    searchInputMesg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.advanceSearchApiEndPoint}?search=${queryval}&token=${localStorage.getItem("token")}&location=${this.state.saved_location}`
    );

    searchInputMesg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    searchInputMesg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(searchInputMesg.id, searchInputMesg);

  }


  // savedsearch 
  handlesavedSearch = () => {
    this.setState({ showsavedsearch: !this.state.showsavedsearch ? true : false })

  }

  handleToggleState = <K extends keyof S>(stateKeys: K[], oppositeStateKeys: Array<keyof S>) => (event?: React.MouseEvent) => {
    // Create an empty object to store the updated state
    const updatedState = stateKeys.reduce((state, stateKey) => {
      // Toggle the state by negating its current value
      state[stateKey] = !this.state[stateKey];
      return state;
    }, {} as Partial<S>);

    // Set the opposite state keys to false
    oppositeStateKeys.forEach((oppositeStateKey) => {
      // Set the opposite state key to false
      (updatedState as unknown as Record<keyof S, boolean>)[oppositeStateKey] = false;
    });

    // Update the component's state with the updated state object
    this.setState(updatedState as S);
  };



  getCurrentUrl() {
    const currentUrl = window.location.href
    const parts = currentUrl.split('/');
    const lastWord = parts[parts.length - 1];
    const secondLastWord = parts[parts.length - 2]?.toLowerCase();
    if (lastWord === "publishneed") {
      return "MyNeeds";
    } else if (secondLastWord === "activity" || secondLastWord === "need" || secondLastWord === "fund" || secondLastWord === "nonprofitprofile") {
      return "HomeDashBoard";
    } else if (lastWord === "Gamification") {
      return "UserProfileBasicBlock";
    } else if ( lastWord === "UserSurveys") {
      return "SurveysDashboard";
    } else if(secondLastWord === "userprofileview"){
      return "UserProfileBasicBlock";
    } else if(lastWord.includes("GroupChat")){
      return "GroupChat";
    } else {
      return lastWord;
    }
  }

  getUrlName = () => {
    const screenMappings: any = {
      ["HomeDashBoard"]: constants.dashboard_screens.home,
      ["UserProfileBasicBlock"]: constants.dashboard_screens.Profile,
      ["MyNeeds"]: constants.dashboard_screens.Needs,
      ["GroupChat"]: constants.dashboard_screens.Chats,
      ["MyPledges"]: constants.dashboard_screens.Pledges,
      ["Settings"]: constants.dashboard_screens.Settings,
      ["SurveysDashboard"]: constants.dashboard_screens.Surveys,
      ["EmailAccountLoginBlock"]: constants.dashboard_screens.Logout,
    };

    const routeName = screenMappings[this.getCurrentUrl()];
    return routeName;
  }

  getPlaceholderImage = () => {
    const header = {
      token: localStorage.getItem('token')
    };
    const placeholderImgMesg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.placeholderImgId = placeholderImgMesg.messageId;

    placeholderImgMesg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.placeholderImageApiEndpoint}?img_type=placeholder`
    );
    placeholderImgMesg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    placeholderImgMesg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(placeholderImgMesg.id, placeholderImgMesg);

  }
  handlePlaceholderImage = (responseJson: any) => {
    if (responseJson?.logo) {
      localStorage.setItem('placeholderImg', responseJson?.logo?.url)
      this.setState({ placeholderImg: responseJson?.logo?.url })

    }
  }
  handleTabToggle = (value: any) => {
    this.setState({ eventTabValue: value })
  }
  handlefetchApiResponse = (responseJson: any) => {
    localStorage.setItem("full_name", responseJson.data.attributes.full_name)
    const addressParts: string[] = [];
    const attributeNames: string[] = ["street_name", "city", "post_code"];

    attributeNames.forEach((attribute) => {
      if (responseJson.data.attributes[attribute]) {
        addressParts.push(responseJson.data.attributes[attribute]);
      }
    });

    this.setState({
      userDetails: responseJson.data,
      currentLocation: {
        area: responseJson.data.attributes.area,
        city: responseJson.data.attributes.city,
        address: responseJson.data.attributes.street_name,
        country: responseJson.data.attributes.country,
        doorName: responseJson.data.attributes.street_name,
        postcode: responseJson.data.attributes.post_code,
        longitude: responseJson.data.attributes.longitude,
        latitude: responseJson.data.attributes.latitude,
      },
      lng: responseJson.data.attributes.longitude,
      lat: responseJson.data.attributes.latitude,
      post_code: responseJson.data.attributes.post_code,
      current_location: addressParts.join(', '),
      fullLocation: responseJson.data.attributes.location,
      saved_location:responseJson.data.attributes.saved_location,
      saved_post_code:responseJson.data.attributes.saved_post_code,
      saved_city:responseJson.data.attributes.saved_city,
      city: responseJson.data.attributes.city,
      street_name: responseJson.data.attributes.street_name,
      addressParts: addressParts.join(', ')
    });
    localStorage.setItem("user_saved_location", responseJson.data.attributes.saved_location);
    if (!localStorage.getItem("current_location")) {
      localStorage.setItem("current_location", addressParts.join(', '))
      localStorage.setItem("post_code", responseJson.data.attributes.post_code)
      localStorage.setItem("city", responseJson.data.attributes.city)
    }
    if(this.state.isAdvanceSearch){
      const data = {
        dbs_included: this.state.dbsSwitch,
        all_day: this.state.allDay,
        from_date: this.state.fromDate,
        to_date: this.state.toDate,
        start_time: this.state.startTime,
        end_time: this.state.endTime,
        radius: this.state.proxyValue,
      }
      this.searchEvent(data);
      return;
    }
    if (this.state.eventTabValue == "Upcoming") {
      this.upComingEvents();
    }
    else {
      this.pastEvents();
    }
  }
  updateUpcommingEvents = (events: any) => {
    this.setState({ storeUpcommingEvents: events })
  }

  getNotifyList = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotifyListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_push_notifications/push_notifications`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboardGetMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("token"),
        type: "user_entity"
      })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpcomingResponse = (responseJson: any) => {
    if (this.state.eventTabValue == "Upcoming") {
      this.upCommingRefactor(responseJson)
    }
  }

  handlePastResponse = (responseJson: any) => {
    if (this.state.eventTabValue == "Past") {
      this.pastEventRefactor(responseJson)
    }
  }

  searchEventIdRefractor = (responseJson: any) => {
    this.setState({ isAdvanceSearch: true, showsavedsearch: false, mainPageLoader: false })
    if (this.props?.setSelectedFilterEvents) {
      this.props?.setSelectedFilterEvents(responseJson.data)
    }

    if (this.props?.setMainPageLoader) {
      this.props?.setMainPageLoader(false)
    }
  }

  searchInputRefractor = (responseJson: any) => {
    if (this.props?.setSelectedFilterEvents) {
      this.props?.setSelectedFilterEvents(responseJson.data)
    }
    if (this.props?.setMainPageLoader) {
      this.props?.setMainPageLoader(false)
    }
    this.setState({ mainPageLoader: false })

  }

  removeDeviceToken = async() => {
    const header = {
      token: localStorage.getItem("token"),
      type: "user_entity",
      "Content-Type": "application/json",
    };
    this.removeDeviceTokenApiCallId = await apiCall({
      header: header,
      endPoint: `/account_block/device_ids/${localStorage.getItem("device_id")}`,
      method: "DELETE",
    });
  }

  handleLogout = async () => {
    let accId = localStorage.getItem("acc_id")
    const utcTime = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    const body = {
      "activity_log": {
          "accountable_id": accId,
          "accountable_type": "AccountBlock::Account",
          "log_of_date_time": utcTime
      }
    };
    const header = {
      "Content-Type": "application/json"
    }
      this.logoffApiCallId = await apiCall({  
        method: "POST",
        endPoint: `/bx_block_profile/non_profit_activity_logs`,
        body: JSON.stringify(body),
        header: header
      });
  };

  handleOnMouseEnter = (event:React.MouseEvent<HTMLElement>)=>{
    this.setState({isDashaboardTextHover : true, anchorEl : event.currentTarget});
  }
  handleOnMouseLeave = ()=>{
    this.setState({isDashaboardTextHover : false, anchorEl : null});
  }

  // Customizable Area End
}


{/* Customizable Area Start */ }

function mapStateToProps(state: any) {
  return {
    selectedTab: state.tabReducer.selectedTab,
    searchFilterValue: state.advanceSearchReducer.searchFilterValue,
    allEvents: state.eventsReducer?.allEvents,
    selectedLocationEvents: state.eventsReducer?.selectedLocationWiseEvents,
    selectedLocationWiseEventsMessageError: state.eventsReducer?.selectedLocationWiseEventsMessage,
    latitude: state.reducer?.latitude,
    longitude: state.reducer?.longitude,
    postcode: state.reducer?.postcode,
    location: state.reducer?.location,
    filterEventsReducer: state.filterEventsReducer?.selectedEvents,
    savedSearchPayload: state.savedSearchPayloadReducer?.savedPayload
  }
}


function mapDispatchToProps(dispatch: (arg0: any) => any) {
  return {
    setSelectedTab: (selectedTab: any) => {
      return dispatch(setSelectedTab(selectedTab))
    },
    setSelectedEvents: (selectedTab: any) => {
      return dispatch(setSelectedEvents(selectedTab))
    },
    getAllEventsDetails: (selectedTab: any) => {
      return dispatch(getAllEventsDetails(selectedTab))
    },
    setSelectedFilterEvents: (selectedEvents: any) => {
      return dispatch(setSelectedFilterEvents(selectedEvents))
    },
    storeSearchDetails: (savedSearchinfo: any) => {
      return dispatch(storeSearchDetails(savedSearchinfo))
    },
    setAllEvents: (selectedEvents: any) => {
      return dispatch(setAllEvents(selectedEvents))
    },
    setLocationWiseFilterEvent: (selectedEvents: any) => {
      return dispatch(setLocationWiseFilterEvent(selectedEvents))
    },
    setLocationWiseFilterEventMessage: (message: any) => {
      return dispatch(setLocationWiseFilterEventMessage(message))
    },
    setCurrentLocation: (lat: any, lng: any, post_code: any, location: any) => {
      return dispatch(setCurrentLocation(lat, lng, post_code, location))
    },
    setSavedSearchPayload: (data: any) => {
      return dispatch(savedSearchPayload(data))
    }
  }
}

connect(mapStateToProps, mapDispatchToProps)

{/* Customizable Area End */ }
