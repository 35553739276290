import React from 'react';

import {
  Typography,
  IconButton,
  // Customizable Area Start
  Hidden,
  Toolbar,
  InputBase,
  Grid,
  Paper,
  Drawer,
  Box,
  Modal,
  Dialog, DialogContent, DialogTitle, Button,CircularProgress
  // Customizable Area End
} from "@material-ui/core";



// Customizable Area Start
import Check from '@material-ui/icons/Check';
import PropTypes from "prop-types";
import {
  withStyles,
  createStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import { COLORS } from '../../../framework/src/Globals';
import { isMobile } from "react-device-detect";
// @ts-ignore
import classesOuter from "./HomeDashBoard.module.css";
// @ts-ignore
import modalClass from "../../../components/src/custom/ContactusSuccessModal.module.css";

import {
  constants,
  icons,
  FAMILYFONTS,
  FONTS
} from "../../../components/src/constants";

import DashboardController, { Props } from "./DashboardController";
import DashboardWeb from "./Dashboard.web";
import "./HomeDashBoard.css";
import DbsSearch from "../../../components/src/custom/DbsSearch";

//@ts-ignore
import { AppContext } from '../../../web/src/App.js';
import { setLocationWiseFilterEventMessage, setAllEvents, setLocationWiseFilterEvent, setSelectedEvents } from "../../../framework/src/redux/events/eventsActions";
import { getAllEventsDetails } from "../../../framework/src/redux/advanceSearch/advanceSearchActions";
import { connect } from "react-redux";
import { savedSearchPayload } from "../../../framework/src/redux/savedSearchPayload/savedSearchPayloadAction";
import Notifications from '../../notifications/src/Notifications.web';
import Location from '../../location/src/Location';
import { setSelectedFilterEvents } from "../../../framework/src/redux/filterEvents/filterEventsAction"
import SavedSearch from '../../advancedsearch/src/SavedSearch';
import { setCurrentLocation } from "../../../framework/src/redux/Location/action"
import { storeSearchDetails } from '../../../framework/src/redux/savedSearch/savedSearchAction';
import {
  KeyboardTimePicker
} from '@material-ui/pickers';
import HomeDashBoardWeb from './HomeDashBoard.web';
import SearchScreenWeb from './SearchScreenWeb';


function pxToRem(value: number) {
  return `${value / 16}rem`;
}
export type CounterProps = { label?: string };

export const styles = (theme: any) =>
  createStyles({
    FiCardMedia: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
    },
    FiCardContent: {
      position: "relative",
      backgroundColor: "transparent",
    },
    FiCardActions: {
      position: "relative",
    },
    FiCardActionArea: {
      position: "relative",
    },
    FiCard: {
      position: "relative",
      borderRadius: 15,
      boxShadow: "none",
      height: isMobile ? 190 : 145,
    },
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: "12px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      height: 54,
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: COLORS.black10,
    },
    inputInput: {
      padding: theme.spacing(2, 2, 2, 0),
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      color: 'black',
      ...FAMILYFONTS.family3,
      fontSize: 16,
    },
    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 30px 16px 50px",
      width: "100%",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      color: "text.primary",
      // margin: "8px",
      padding: "8px",
      height: 54,
      width: 54,
      background: COLORS.white,
      border: "1px solid #D5D5D5",
      borderRadius: 13,
    },
    searchFilterIconbutton: {
      color: "text.primary",
      // margin: "8px",
      background: COLORS.white,
      padding: "8px",
      height: 46,
      width: 46,
    },
    tabIcons: {
      margin: 6,
      padding: 8,
      border: "1px solid #cdc9c9",
      borderRadius: 8,
      backgroundColor: "white",
    },
    mobileTabIcons: {
      margin: 6,
      padding: 8,
      background: "#FFFFFF",
      boxShadow: "0px 0px 8px rgba(212, 212, 212, 0.474186)",
      borderRadius: 6,
    },

    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      borderRadius: 16,
    },
    image: {
      width: 72,
      height: 42,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    locationIcon: {
      height: "24px",
      width: "24px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
      marginRight: "10px",
    },
    gutter: {
      "&:first-child": {
        marginLeft: 0,
      },
    },
    tabsRoot: {
      marginBottom: "50px",
      backgroundColor: COLORS.white,
    },
    tabsRoot___div___div: {
      marginBottom: "50px",
      backgroundColor: "white",
      justifyContent: "none",
      padding: "0rem",
    },

    upcommingTabsIndicator: {
      backgroundColor: "#009BE2",
      borderRadius: "4px 4px 0px 0px",
      height: 4,
      maxWidth: 160,
    },
    pastTabtabsIndicator: {
      backgroundColor: "#009BE2",
      borderRadius: "4px 4px 0px 0px",
      height: 4,
      maxWidth: 160,
    },
    tabRoot: {
      textTransform: "initial",
      // @ts-ignore
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "#858585",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#000000",
        opacity: 1,
      },
      "&$tabSelected": {
        fontWeight: theme.typography.fontWeightMedium,
        color: "#000000",
      },
      "&:focus": {
        color: "000000",
      },
    },
    tabSelected: {},
    typography: {
      // padding: theme.spacing(3),
    },
    eventloop: {
      marginBottom: "30px",
    },
    carouselWrapper: {
      width: " 100%",
    },
    image_div: {
      backgroundColor: "pink",
    },
    cardCarousel: {
      width: "100%",
      borderStyle: "solid",
      borderColor: "white",
      backgroundColor: "white",
      padding: "24px 24px 24px 14px",
      borderRadius: 18,
      "& after": {
        content: "",
        display: "table",
        clear: "both",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        margin: 0,
      },
    },

    carousel_card: {
      textAlign: "left",
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 2.334,
      [theme.breakpoints.up("md")]: {
        fontSize: pxToRem(16),
      },
    },
    avatar_Groups: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    // web

    showAvatarGroup: { display: "none" },
    showCarouselAvatar: {
      display: "block",
      width: 200,
    },

    icon_holder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 35,
      width: 35,
      background: "white",
      borderRadius: "50%",
      position: "absolute",
      top: 10,
      right: 10,
    },
    eventContent: {
      width: "60%",
      padding: "0 20px",
      "@media (min-width:600px)": {
        width: "40%",
        padding: "0 10px",
      },
    },

    w_60: {
      width: "60%",
      paddingRight: "15px",
      "@media (min-width:600px)": {
        width: "100%",
        paddingRight: 0,
      },
    },
    py_3: {
      padding: ".5rem 0",
      "@media (min-width:600px)": {
        padding: ".5rem 0",
      },
    },

    heading2: {
      fontSize: 15,
      fontWeight: 400,
    },

    heading3: {
      fontSize: 12,
      fontWeight: 300,
    },

    flex: {
      display: "flex",
    },

    event_img_container: {
      maxWidth: 216,
      maxHeight: 130,

      borderRadius: 16,
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
    },

    eventcontent: {
      width: "100%",
      padding: " 0 22px",
      marginTop: -8,
    },
    cardDescription: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    cardDescriptionAvatar_location: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    avatarImgcontainer: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "0.5rem",
    },
    avatarImg: {
      width: 23,
      height: 23,
      justifyContent: "space-between",
      borderRadius: "50%",
      position: "relative",
      left: "-5px",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: 16,
        height: 16,
      },
    },

    eventCard: {
      padding: 24,
      background: "white",
      borderRadius: 24,
      marginBottom: 30,
      height: 230,
      width: "100%",
    },

    icon: {
      height: 25,
      width: 25,
    },

    past_card_status: {
      zIndex: 1,
      position: "absolute",
      padding: "6px",
      height: 25,
      width: 52,
      right: " 12px",
      top: "64px",
      borderRadius: "16px",
      color: COLORS.white,
      fontSize: "12px",
      lineHeight: "27px",
      textAlign: "center",
      letterSpacing: "-0.3px",
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 13,
    },
    btn_dbs: {
      color: COLORS.white,
      height: 30,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: "0 !important",
      borderBottomLeftRadius: 13,
      borderTopRightRadius: 13,
      left: 0,
      top: 103,
      [theme.breakpoints.down("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        top: 100,
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },
    btn_dbs_slider: {
      color: COLORS.white,
      height: 38,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: "0 !important",
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 15,
      left: 0,
      top: 120,
      [theme.breakpoints.up("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },

    mobileLayoutContent: {
      display: "none",
    },
    multiLineEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      width: 180,
    },
    layOutbuttons: {
      width: 76,
      height: 24,
      borderRadius: "8px",
      lineHeight: 2,
      marginTop: 0,
      color: COLORS.white,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },

    navigationIcon: {
      color: COLORS.skyBlue,
      backgroundColor: "white",
      margin: "8px",
      padding: "13px",
      height: 50,
      borderRadius: 30,
      zIndex: 999,
      top: -12,
      right: 11,
    },

    [theme.breakpoints.down("xs")]: {
      showCarouselAvatar: {
        display: "none",
      },
      upcommingTabsIndicator: {
        backgroundColor: "#009BE2",
        marginLeft: 10,
        borderRadius: "4px 4px 0px 0px",
        height: 4,
        maxWidth: 128,
      },
      pastTabtabsIndicator: {
        backgroundColor: "#009BE2",
        marginLeft: -5,
        borderRadius: "4px 4px 0px 0px",
        height: 4,
        maxWidth: 128,
      },
      eventdate_loc: {
        width: 121,
        height: 14,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: -0.3,
        color: " #858585",
      },

      event_img_container: {
        maxWidth: 120,
        maxHeight: 120,
        borderRadius: 12,
      },
      event_Paper: {
        margin: "auto",
        padding: 24,
        marginBottom: 30,
        height: 120,
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #C5C5C5",
        borderRadius: 18,
      },
      eventCard: {
        padding: 0,
        background: "white",
        borderRadius: 15,
        marginBottom: 30,
        height: 120,
      },

      icon: {
        height: 15,
        width: 15,
      },

      heading2: {
        fontSize: 12,
        fontWeight: 400,
      },

      heading3: {
        fontSize: 10,
        fontWeight: 300,
      },

      py_3: {
        padding: ".5rem 0",
      },

      w_60: {
        width: "100%",
        paddingRight: 0,
      },

      h2: {
        fontSize: 15,
      },
      desktopLayoutContent: {
        display: "none",
      },

      layOutbuttons: {
        width: 76,
        height: 21,
        borderRadius: "8px",
        lineHeight: 2,
        marginTop: 0,
        color: COLORS.white,
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
      },

      navigationIcon: {
        color: COLORS.skyBlue,
        backgroundColor: "white",
        margin: "8px",
        padding: "13px",
        height: 50,
        borderRadius: 30,
        zIndex: 999,
        top: -25,
        right: -29,
      },
      mobileLayoutContent: {
        width: "50%",
        display: "inline-grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "self-start",
        "@media (min-width:600px)": {
          width: "50%",
          position: "relative",
        },
      },
    },

    // outer

    searchreletiveenv: {
      position: "relative",
    },
    searchfilterEnv: {
      position: "absolute",
      height: "auto",
      maxHeight: "460px",
      top: "63px",
      background: "#FFFFFF",
      border: "1px solid #D5D5D5",
      borderRadius: "13px",
    },

    leftmenuDetails: {
      height: "100%",
    },
    backIconWeb: {
      display: "flex",
      alignItems: "center",
    },
    settingContent: {
      marginLeft: "50px",
    },

    filterPositions: {
      position: "absolute",
      top: "0px",
      left: "0px",
      transform: "translate3d(575px, 119px, 0px)",
      willChange: "transform",
    },
    pastImg: {
      marginTop: "-36px",
      borderRadius: "15px",
    },
    event_Paper: {
      margin: "auto",
      padding: 24,
      marginBottom: 30,
      height: 180,
      width: "100%",
      background: "#FFFFFF",
      border: "1px solid #C5C5C5",
      borderRadius: 18,
    },

    modalpaper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      height: 54,
    },

    // location modal css
    textInputmodal: {
      marginTop: 10,
    },
    drawerPaper: {
      height: 580,
      padding: 16,
      background: "#FFFFFF",
      borderRadius: "16px 16px 0px 0px",
    },
  });

// Customizable Area End

class HomeDashBoardRoot extends DashboardController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleDBSSwitch(value: any) {
    this.setState({ dbsSwitch: value })
  }

  handleAllDay(value: any) {
    this.setState({ allDay: value })
  }

  setFromDate(value: any) {
    this.setState({ fromDate: value })
  }

  setToDate(value: any) {
    this.setState({ toDate: value })
  }

  setStartTime(value: any) {
    this.setState({ startTime: value })
  }

  setEndTime(value: any) {
    this.setState({ endTime: value })
  }
  setCalOpen(value: any) {
    this.setState({ calOpen: value })
  }

  setValueOpen(value: any) {
    this.setState({ valueOpen: value })
  }

  setProxyValue(value: any) {
    this.setState({ proxyValue: value })
  }

  setAdvanceSearchToggle(value : any) {
    this.setState({advanceSearchToggle : value, isAdvanceSearch : value})
  }

  setMainPageLoader(value : any) {
    this.setState({mainPageLoader : value})
  }


  // Renders the search bar component

  renderIconButton = (label: any, icon: any, onClick: any, className: any) => {
    const { classes } = this.props;

    return (
      <AppContext.Consumer>
        {(context: any) => (
      <IconButton
        data-testId="notification-icon"
        aria-label={label}
        color="inherit"
        className={className}
        onClick={() => {
          onClick();
          if(this.state.notificationShow){
            context.closeIndicator();
          }
        }}
      >
        {context.newNoti && label==="show notifications" && <Box style={{
                  width: '8px',
                  height: '8px',
                  backgroundColor: "red",
                  borderRadius: '50%',
                  position: 'absolute',
                  top: 14,
                  right: 17,
                }}>
                </Box>}
        <img className={classes._tabs_logo} src={icon} alt={label} />
      </IconButton>
      )}
      </AppContext.Consumer>
    );
  };

  // Helper function to render the paper component
  renderPaper = (classes: any, component: any) => {
    return (
      <Paper
        elevation={0}
        style={{
          zIndex: 2,
          width: 450,
          borderRadius: 15,
          top: 73,
          // overflow: "auto",
          right: 0,
        }}
        className={classes.searchfilterEnv}
      >
        {component}
      </Paper>
    );
  };

  renderSearchSection = (classes: any) => {
    const { searchFilterShow, getDbsData } = this.state;

    return (
      <div className={`${classes.search} ${classesOuter.searchreletiveenv}`}>
        {/* Render search icon and InputBase component */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.searchIcon}>
            <img
              style={{
                width: 25,
                height: 26,
                left: "33px",
                top: "788px",
                borderRadius: "0px",
              }}
              src={icons.search}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputBase
              placeholder="Search events"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              data-testId='searchInput'
              inputProps={{ "aria-label": "search" }}
              value={this.state.searchValue}
              onChange={(e) => {
                !this.state.advanceSearchToggle  ?  this.searchinputChange(e.target.value) : this.searchAdvanceSearch(e.target.value, this.props.savedSearchDetailsItem);
                this.setState({searchValue : e.target.value})
            }}
            />
          </div>
        </div>

        {/* Render search filter icon button */}
        {this.renderIconButton(
          "search filter option",
          icons.filterOption,
          this.handleToggleState(
            ["searchFilterShow"],
            ["showsavedsearch", "notificationShow"]
          ),
          classes.searchFilterIconbutton
        )}

        {/* Render search filter section if searchFilterShow is true */}
        {searchFilterShow && (
          <Paper
            elevation={0}
            style={{
              zIndex: 2,
              width: "100%",
              borderRadius: 8,
              border: "1px solid lightgrey",
              height: 450
            }}
            className={classesOuter.searchfilterEnv}
          >
            <DbsSearch
              advanceSearchToggle = {this.state.advanceSearchToggle}
              setAdvanceSearchToggle = {(data : any) =>  this.setAdvanceSearchToggle(data)}
              getDbsData={getDbsData}
              handleCategoryChange={this.handleCategoryChange}
              categories={this.state.categories}
              setMainPageLoader= {(data : any) => this.setMainPageLoader(data)}
              searchEventCall={(data: any) => this.searchEvent(data)}
              savedSearchCall={(data: any) => this.savedSearches(data)}
              searchValue={this.state.searchValue}
              dbsSwitch={this.state.dbsSwitch}
              allDay={this.state.allDay}
              handleDBSSwitch={(value: any) => this.handleDBSSwitch(value)}
              handleAllDay={(value: any) => this.handleAllDay(value)}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              calOpen={this.state.calOpen}
              setFromDate={(value: any) => this.setFromDate(value)}
              setToDate={(value: any) => this.setToDate(value)}
              setCalOpen={(value: any) => this.setCalOpen(value)}
              valueOpen={this.state.valueOpen}
              setValueOpen={(data: any) => this.setValueOpen(data)}
              startTime={this.state.startTime}
              endTime={this.state.endTime}
              setStartTime={(data: any) => this.setStartTime(data)}
              setEndTime={(data: any) => this.setEndTime(data)}
              proxyValue={this.state.proxyValue}
              setProxyValue={(data: any) => this.setProxyValue(data)}
              setSavedSearchPayload = {(data : any) => this.props.setSavedSearchPayload(data)}
            />
          </Paper>
        )}
      </div>
    );
  };

  renderSavedSearchSection = (classes: any) => {
    const { showsavedsearch } = this.state;

    return (
      <div className={classes.sectionDesktop}>
        {/* Render the saved search icon button */}
        {this.renderIconButton(
          "saved search",
          icons.savedsearches,
          this.handleToggleState(
            ["showsavedsearch"],
            ["notificationShow", "searchFilterShow"]
          ),
          classes.aftersearchIconbutton
        )}

        {/* Render the saved search section if showsavedsearch is true */}
        {showsavedsearch &&
          this.renderPaper(
            classes,
            <SavedSearch
              navigation={this.props.navigation}
              id=""
              showsavedsearch={this.handleToggleState(
                ["showsavedsearch"],
                ["notificationShow", "searchFilterShow"]
              )}
              displayInweb={true}
              classes={null}
              Box={null}
              KeyboardTimePicker={KeyboardTimePicker}
              AntSwitch={null}
              setMainPageLoader={(data: any) => this.setMainPageLoader(data)}
              savedSearchData={(data: any) => this.setSavedSearchData(data)} 
              filterEventsReducer={undefined}  
              advSearchPayloadDetails={undefined} />
          )}
      </div>
    );
  };

  renderNotificationSection = (classes: any) => {
    const { notificationShow } = this.state;

    return (
      <AppContext.Consumer>
      {(context: any) => (
      <div className={classes.sectionDesktop}>
        {/* Render the notifications icon button */}

        {this.renderIconButton(
          "show notifications",
          icons.blueBellIcon,
          this.handleToggleState(
            ["notificationShow"],
            ["showsavedsearch", "searchFilterShow"]
          ),
          classes.aftersearchIconbutton
        )}

        {/* Render the notification section if notificationShow is true */}
        {notificationShow &&
          this.renderPaper(
            classes,
            // @ts-ignore 
            <Notifications
              navigation={this.props.navigation}
              id=""
              isCome={context.isCome}
              notificationmodalState={()=>{
                this.handleToggleState(
                ["notificationShow"],
                ["showsavedsearch", "searchFilterShow"]
              );
              this.setState({ notificationShow: false });
              context.closeIndicator();
            }}
              type="user_entity"
            />
          )}
      </div>
      )}
      </AppContext.Consumer>
    );
  };

  renderSearchBar = (classes: any, filteroptionid: any) => {
    return (
      <Grid container>
        <Grid item xs={12} sm={7}>
          <Hidden xsDown implementation="css">
            <Toolbar className={classes.toolBar} style={{ justifyContent: 'space-between' }}>
              <div style={{ width: '75%' }}>{this.renderSearchSection(classes)}</div>
              {this.renderSavedSearchSection(classes)}
              {this.renderNotificationSection(classes)}
            </Toolbar>
          </Hidden>
        </Grid>
      </Grid>
    );
  };

  changeLocationView = (classes: any) => {
    return (
      <div className={classesOuter.readinglocationSearchSreen}>
        {/* Reading location */}
        <div className={classesOuter.locationsreading}>
          <img
            className={classes.locationIcon}
            src={icons.locationblue}
            alt="Location Icon"
          />
          <Typography
            style={{
              fontSize: 12,
              fontFamily: "Rene_Bieder_Milliard_Medium",
              letterSpacing: "0.1px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: isMobile?140:360,  // Adjust this width as needed
            }}
          >
           {this.state.saved_city}
  {this.state.saved_city && this.state.saved_post_code && ", "}
  {this.state.saved_post_code}
          </Typography>
        </div>

        {/* Change Location button */}
        {localStorage.setItem("user_selected_date","false")}
        {localStorage.setItem("all_day","false")}
        <Button
          className={`${classesOuter.changeLoc}`}
          onClick={this.handleClickLocationOpen}
        >
          <Typography
            style={{
              color: "#009BE2",
              // fontWeight: 500,
              fontSize: 16,
              fontFamily: "Rene_Bieder_Milliard_Medium",
              letterSpacing: "0.25px",
              textTransform: "none",
              whiteSpace:"nowrap",
            }}
          >
            Change Location
          </Typography>
        </Button>
      </div>
    );
  };

  renderSuccessModal = () => {
    return (
      // @ts-ignore
      <Modal
        open={this.state.openSuccessModal}
        onClose={this.handleSuccessModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={modalClass.modalWrapper}
      >
        {/* @ts-ignore  */}
        <Box className={modalClass.modalBox}>
          <div>
            <Check className={modalClass.checkSubmit} />

            <Typography variant='h6'>Thank you for submitting!</Typography>
            <Typography variant='body1' style={{ ...FONTS.body1, }}>
              Your location is successfully saved.
            </Typography>
            <div>
              <Button
                className={modalClass.modalBtn}
                variant="contained"
                data-testId="close-modal"
                onClick={() => this.handleSuccessModalClose()}
              >
                Back
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  renderLocationModal = () => {
    return (
      <Dialog
        open={this.state.locationmodal}
        onClose={this.handleCloseLocationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 16, width: "30%" },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <DialogTitle id="responsive-dialog-title" style={{ padding: '10px 24px 0px', }}>
              <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_SemiBold', fontSize: 22 }}>
                Change location
              </Typography>
            </DialogTitle>
          </div>
          <div
            style={{ marginRight: 28 }}
            onClick={this.handleCloseLocationModal}
          >
            <img src={icons.cancelIcon} style={{ height: 32, width: 32 }} />
          </div>
        </div>
        <DialogContent style={{ padding: '0px 24px' }}>
          {/* @ts-ignore  */}
          <Location
            navigation={this.props.navigation}
            id={""}
            data-testId="locationComponent"
            onClose={this.handleCloseLocationModal}
            onSuccessOpen={this.handleSuccessModalOpen}
            presentAddress={{ city: this.props.location, post_code: this.props.postcode }}
            closeModal={this.handleCloseLocationModal}
            getFullAddress={this.getFullAddress}
            fullLocation={this.state.saved_location}
            saved_post_code={this.state.saved_post_code}
            saved_city={this.state.saved_city}
          />
        </DialogContent>
      </Dialog>
    );
  };

  renderBottomDrawer = (classes: any) => {
    return (
      <Drawer
        anchor="bottom"
        open={this.state.locationmodal}
        onClose={this.handleCloseLocationModal}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
          <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        <DialogTitle id="responsive-dialog-title" style={{ padding: '10px 24px 0px', }}>
          <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_SemiBold', fontSize: 22 }}>
            Change location
          </Typography>
        </DialogTitle>
      </div>
      <div
        style={{ marginRight: 28 }}
        onClick={this.handleCloseLocationModal}
      >
        <img src={icons.cancelIcon} style={{ height: 32, width: 32 }} />
      </div>
    </div>
        <Location
          onClose={this.handleCloseLocationModal}
          onSuccessOpen={this.handleSuccessModalOpen}
          navigation={this.props.navigation}
          id={""}
          data-testId="locationComponent"
          latitude={null}
          longitude={null}
          loading={false}
          error={null}
          presentAddress={{ city: this.props.location, post_code: this.props.postcode }}
          closeModal={this.handleCloseLocationModal}
          getFullAddress={this.getFullAddress}
          fullLocation={this.state.saved_location} 
          saved_post_code={this.state.saved_post_code} 
          saved_city={this.state.saved_city}        />
      </Drawer>
    );
  };

  homeClick(data: any) {
    const e = {
      target: {
        name: 'allCategoryCheck',
        checked: false
      }
    }
    this.setState({ advanceSearchToggle : false, categories : [], searchValue: data, isAdvanceSearch: false, searchFilterShow: false, notificationShow: false, showsavedsearch: false, dbsSwitch: false, allDay: false, startTime: new Date(), endTime: new Date(), fromDate: new Date(), toDate: new Date(), })
    this.handleCategoryChange(e)
  }

  // Customizable Area End

  render() {
    const { classes, theme}: any = this.props;

    const openfilter = Boolean(this.state.setAnchorEl);
    const filteroptionid = openfilter ? 'simple-popper' : undefined;

    const { searchValue } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {/* DashboardWeb component */}
        {/* @ts-ignore */}
        <DashboardWeb
          showBackButton={constants._icons.hide}
          title="Dashboard"
          navigation={this.props.navigation}
          showBottomBar={constants._icons.show}
          showDrawerHeader={true}
          titleColor={COLORS.skyBlue}
          showLine={constants._icons.hide}
          homeClick={(data: any) => this.homeClick(data)}
        >
          <Grid container>
            {/* Searchbar and notification section */}
            {this.renderSearchBar(classes, filteroptionid)}{" "}
            {/* Render search bar */}
            {/* Searchbar and notification section end */}
            {/* Reading location section */}
            <Grid item xs={12} sm={7}>
              {this.changeLocationView(classes)} {/* Render location view */}
            </Grid>
            {/* Reading location section end */}
          </Grid>

          {/* HomeDashBoard  */}
          {(() => {
            if(searchValue || this.state.isAdvanceSearch){
              return (
                <SearchScreenWeb navigation={this.props.navigation}
                searchEvent={this.searchEvent}
                searchValue={this.state.searchValue}
                getDbsData={this.state.getDbsData}
                mainPageLoader = {this.state.mainPageLoader}
                handleCategoryChange={this.handleCategoryChange}
                categories={this.state.categories}
                searchEventCall={(data: any) => this.searchEvent(data)}
                savedSearchCall={(data: any) => this.savedSearches(data)}
                dbsSwitch={this.state.dbsSwitch}
                allDay={this.state.allDay}
                handleDBSSwitch={(value: any) => this.handleDBSSwitch(value)}
                handleAllDay={(value: any) => this.handleAllDay(value)}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                calOpen={this.state.calOpen}
                setFromDate={(value: any) => this.setFromDate(value)}
                setToDate={(value: any) => this.setToDate(value)}
                setCalOpen={(value: any) => this.setCalOpen(value)}
                valueOpen={this.state.valueOpen}
                setValueOpen={(data: any) => this.setValueOpen(data)}
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                setStartTime={(data: any) => this.setStartTime(data)}
                setEndTime={(data: any) => this.setEndTime(data)}
                proxyValue = {this.state.proxyValue}
                setProxyValue={(data: any) => this.setProxyValue(data)}
                advanceSearchToggle = {this.state.advanceSearchToggle}
                setAdvanceSearchToggle = {(data: any) => this.setAdvanceSearchToggle(data)}
                setMainPageLoader = {(data: any) => this.setMainPageLoader(data)}
              />
              )
            }else if (this.state.mainPageLoader){
              return(
                <div style = {{height : '50%', width : '40%', display : 'flex',
                alignItems: "center",
                justifyContent: "center",}}>
                  <CircularProgress style ={{color : '#009be2'}} />
                </div>
              )
            }
            else {
              return (
                <HomeDashBoardWeb navigation={this.props.navigation} storeUpcommingEvents={this.state.storeUpcommingEvents} tabHandler={this.handleTabToggle} handleUpdate={this.updateUpcommingEvents} post_code={this.state.post_code} city={this.state.city} street_name={this.state.street_name} fullLocation={this.state.fullLocation} eventView/>
                )
            }

          })()}

          {/* Bottom Drawer or Location Modal */}
          {isMobile
            ? // Render the bottom drawer for mobile view
            this.renderBottomDrawer(classes)
            : // Render the location modal for non-mobile view
            this.renderLocationModal()}
          {this.renderSuccessModal()}
        </DashboardWeb>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
HomeDashBoardRoot.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
HomeDashBoardRoot.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
function mapStateToProps(state: any) {
  return {
    selectedevents: state.eventsReducer?.selectedEvents,
    savedSearchInfo: state.savedSearchInfo?.saved_search,
    allEvents: state.eventsReducer?.allEvents,
    selectedLocationEvents: state.eventsReducer?.selectedLocationWiseEvents,
    selectedLocationWiseEventsMessageError: state.eventsReducer?.selectedLocationWiseEventsMessage,
    latitude: state.reducer.latitude,
    longitude: state.reducer.longitude,
    postcode: state.reducer.postcode,
    location: state.reducer?.location,
    address: state.address,
    loading: state.loading,
    error: state.error,
    savedSearchDetailsItem : state.savedSearchPayloadReducer.savedPayload,
    advSearchAllEvents : state.advSearchEventsReducer?.advSearchEvents

  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedEvents: (selectedTab: any) => {
      return dispatch(setSelectedEvents(selectedTab));
    },
    getAllEventsDetails: (selectedTab: any) => {
      return dispatch(getAllEventsDetails(selectedTab));
    },
    setSelectedFilterEvents: (selectedEvents: any) => {
      return dispatch(setSelectedFilterEvents(selectedEvents));
    },
    storeSearchDetails: (savedSearchinfo: any) => {
      return dispatch(storeSearchDetails(savedSearchinfo));
    },
    setAllEvents: (selectedEvents: any) => {
      return dispatch(setAllEvents(selectedEvents))
    },
    setLocationWiseFilterEvent: (selectedEvents: any) => {
      return dispatch(setLocationWiseFilterEvent(selectedEvents));
    },
    setLocationWiseFilterEventMessage: (selectedEvents: any) => {
      return dispatch(setLocationWiseFilterEventMessage(selectedEvents));
    },
    setCurrentLocation: (lat: any, lng: any, post_code: any, location: any) => {
      return dispatch(setCurrentLocation(lat, lng, post_code, location))
    },
    setSavedSearchPayload: (data : any) => {
      return dispatch(savedSearchPayload(data))
    }
  };
}

export { HomeDashBoardRoot };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any, { withTheme: true })(HomeDashBoardRoot as any));

// Customizable Area End
