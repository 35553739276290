import React from "react";
// Customizable Area Start
import AdminRegistrationController, {
  Props,
} from "./AdminRegistrationController";
//@ts-ignore
import classOuter from "./NpRegSuccess.module.css";
import { FAMILYFONTS, constants, images } from "../../../components/src/constants";
import { Typography, Button, Container, Box } from "@material-ui/core";
import { setSelectedAdminTab } from "../../../framework/src/redux/adminTab/adminTabActions";
import {connect} from "react-redux"
// Customizable Area End

class NpResetPasswordSuccessMessage extends AdminRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      // Customizable Area Start
      <div className={classOuter.mainContainer}>
        {/* Password Success Message Logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.otpLogoMainContainer}>
            <div className={classOuter.otpLogoContainer}>
              <img src={images.imgCheck} className={classOuter.otpLogo} />
            </div>
          </div>
          {/* Form Content */}
          <div>
            <Box>
              <Box className="forgotPwdMsg">
                {/* Congrats Message */}
                <Typography
                  variant="h4"
                  component="h2"
                  className={classOuter.congratsText}
                  style={{
                    ...FAMILYFONTS.family1,
                    fontSize: 24,
                    fontWeight: 400
                  }}
                >
                  Congrats!!
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classOuter.congratsSubText1}
                  style={{
                    ...FAMILYFONTS.family1,
                    fontSize: 20,
                    fontWeight: 400
                  }}
                >
                  
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classOuter.congratsSubText1}
                  style={{
                    ...FAMILYFONTS.family1,
                    fontSize: 16,
                    fontWeight: 400
                  }}
                >
                  Registration successful. Please wait for approval <br/>
                  <div style={{fontSize:'12.5', paddingTop:'20px'}}>
                  Verification link will be sent to your registered mail.
                  </div>
                </Typography>

                {/* Sign In button */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    color="primary"
                    className={classOuter.footerBtn}
                    fullWidth
                    onClick={() => {
                      this.props.navigation.navigate("NpSignIn")
                      //this.props.setSelectedAdminTab(constants.admin_dashboard_screens.Organisation)
                    }}
                    style={{
                      ...FAMILYFONTS.family2,
                      fontSize: 20,
                      fontWeight: 400,
                      textTransform: "initial",
                    }}
                  >
                   Back to Login 
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}


function mapStateToProps(state: any) {
  return {
    selectedTab: state.tabReducer?.selectedTab,

  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedAdminTab: (selectedTab: any) => {
      return dispatch(setSelectedAdminTab(selectedTab))
    },
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NpResetPasswordSuccessMessage)

