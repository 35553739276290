import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Button
} from "@material-ui/core";
import "../assets/css/ForgotPassword.css";
import { validOtp } from "./assets";
import OtpInput from "react-otp-input";
import {
  FAMILYFONTS,
  images,
  FONTS,
} from "../../../components/src/constants";
import { styled } from "@material-ui/styles";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { InitiateButton } from "../../../components/src/design-system/button";
import { HideContainer } from "../../../components/src/shared/hide-container";

// Customizable Area Start
const CustomButton = styled(Button)({
  color: "white",
  backgroundColor: "transparent",
  fontSize: "16px",
  borderRadius: "4px",
  "&:hover": {
    color: "white",
    backgroundColor: "transparent",
  },
  "@media(max-width: 959px)": {
    fontSize: "14px"
  }
});
// Customizable Area End
export default class ForgotPasswordOTPPage extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const otpLength = this.state.otp.length;
    const disabled = otpLength === 6 && !this.state.otpError ? false : true;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box className="otpImgContainer">
          <img src={images.emailIcon} className="otpImg" />
        </Box>
        {/* Title */}
        <Typography
          variant="h4"
          component="h2"
          className="forgotPwdOtpTitle"
          color="primary"
          style={{
            ...FAMILYFONTS.family1,
            fontSize: 26,
            fontWeight: 400
          }}
        >
          {'OTP Verification!'}
        </Typography>
        {/* Form Content */}
        <Box>
          <Box className="forgotPwdOtpMsg">
            <Typography
              variant="h6"
              component="h2"
              className="forgotPwdOtpField"
              style={{
                ...FONTS.myTask,
                fontSize: 16,
                fontWeight: 400,
                textAlign: "left"
              }}
              data-test-id="otpTitle"
            >
              {'Please enter the verification code sent to your registered email address'}
            </Typography>
            <Box
              className="otpInput"
              style={{
                color: this.state.otpError ? "#E41616" : "inherit"
              }}
            >
              <OtpInput
                data-test-id="forgotPwdOtp"
                // @ts-ignore
                name="otpInput"
                value={this.state.otp}
                onChange={(e: any) => {
                  this.setOtp(e);
                }}
                numInputs={6}
                className={
                  this.state.otpError || this.state.expireOtpError
                    ? "innerOtpErrorText"
                    : "innerOtpText"
                }
                separator={<span />}
                isInputNum
              />

              <HideContainer hideWhen={!this.state.otpError}>
                <Box
                  className="errorMsg"
                  display="flex"
                  sx={{ mt: "1rem" }}
                >
                  <Box className="errorIconDiv">
                    <img src={validOtp} alt="inValidOtp" />
                  </Box>
                  <div className="errors">{'Please enter a valid OTP'}</div>
                </Box>
              </HideContainer>

              <HideContainer hideWhen={!this.state.expireOtpError}>
                <Box
                  className="errorMsg"
                  display="flex"
                  sx={{ mt: "1rem" }}
                >
                  <Box className="errorIconDiv">
                    <img src={validOtp} alt="inValidOtp" />
                  </Box>
                  <div className="errors">
                    {this.state.expireOtpError}
                  </div>
                </Box>
              </HideContainer>

            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 0px"
            }}
            className="otpBtnContainer"
          >
            <Box data-test-id="resendLink">
              <Box display="flex" alignItems="center" className="resendCodeOtpContainer">
                <span
                  style={{
                    ...FONTS.myTask,
                    fontWeight: 500,
                  }}
                >
                  {'Didn\'t receive a code?'}
                </span>
                <CustomButton
                  variant="text"
                  className="resendCodeOtpLink"
                  style={{
                    textTransform: "capitalize",
                    ...FONTS.myTask,
                    fontWeight: 500,
                    padding: "0px",
                    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
                    marginLeft: "4px",
                  }}
                  onClick={this.resendOTP}
                >
                  {'Resend Code'}
                </CustomButton>
              </Box>
            </Box>

            <InitiateButton
              data-test-id={"btnVerifyOtp"}
              variant="contained"
              color="primary"
              className="verifyOtpBtn"
              fullWidth
              onClick={this.verifyOTP}
              style={{
                opacity: disabled ? "0.5" : "1",
                cursor: disabled ? "not-allowed" : "pointer",
                ...FAMILYFONTS.family2,
                fontWeight: 400,
                fontSize: 18
              }}
              disabled={disabled}
            >
              {'Verify'}
            </InitiateButton>

          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
