import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area Start
interface ChatMessages {
    type: string;
    author: string;
    sid: string;
    index: number;
    body: string;
    dateUpdated: string;
    media: any;
    timestamp: string;
    memberSid: string;

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    clearChat: (sid: string, updatedChats: Array<any>) => void
    // Customizable Area Start
    client: any;
    id: string;
    chatUserInformation: any;
    // Customizable Area End
  }

interface S {
    // Customizable Area Start
    pinMessagesData: Array<string>,
    pinModal: boolean,
    clearChatOption: number,
    mobileView: boolean;
    messages: any;
    messageText: string;
    userToken: string | null;
    currentPage: number,
    perPage: number,
    searchTerm: string,
    chatInformation: any,
    chatInfo: boolean;
    isMobile: boolean,
    showMediaLoading: boolean,
    searchBar: boolean,
    searchResults: any,
    clearChat: boolean,
    deleteModal: boolean,
    userId: any,
    emojiPicker: boolean,
    selectedEmoji: null,
    clearChatApplied: any,
    chatMessageObj: any,
    currentMessageSelected: string,
    pinUnPinMessageId: string,
    userEmail: string | null;
    currentSearchSelected: number,
    dropDownPin: boolean,
    lastDeliveredMessageIndex:  number | null,
    lastReadMessageIndex: number | null,
    loading: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BusUserChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    chatUserInformation: any;
    chatMessagesRef: any;
    channel: any;
    client: any;
    triggerChatId:string = "";
    clearChatApi: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.getMessageStatus = this.getMessageStatus.bind(this);
        this.receive = this.receive.bind(this);
        this.sendMedia = this.sendMedia.bind(this);
        this.pinMessage = this.pinMessage.bind(this);
        this.handleUserClick = this.handleUserClick.bind(this);
        this.typingMessage = this.typingMessage.bind(this);
        this.importData = this.importData.bind(this);
        this.getUpdatedMessages = this.getUpdatedMessages.bind(this);
        this.handlePinMessage = this.handlePinMessage.bind(this);
        this.handleChannelUpdated = this.handleChannelUpdated.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.keyUp = this.keyUp.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.handleMessagStructure = this.handleMessagStructure.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onChangeClearChat = this.onChangeClearChat.bind(this);
        this.clearChat = this.clearChat.bind(this);
        this.unpinMessage = this.unpinMessage.bind(this);
        this.getMemberChannelId = this.getMemberChannelId.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage), ];

        this.state = {
            // Customizable Area Start
            currentPage: 1,
            mobileView: true,
            chatMessageObj: {},
            searchTerm: "",
            userEmail: '',
            messages: [],
            chatInfo: false,
            messageText: '',
            currentSearchSelected: 0,
            showMediaLoading: false,
            userToken: '',
            perPage: 20,
            chatInformation: {
                attributes: {
                    event_name: '',
                }
            },
            pinModal: false,

            isMobile: false,
            searchBar: false,
            dropDownPin: false,
            clearChat: false,
            lastReadMessageIndex: null,
            deleteModal: false,
            loading: true,
            userId: 0,
            selectedEmoji: null,
            emojiPicker: false,
            clearChatOption: 0,
            clearChatApplied: [],
            pinMessagesData: [],
            pinUnPinMessageId: "",
            searchResults: [],
            currentMessageSelected: '',
            lastDeliveredMessageIndex: null, };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.chatMessagesRef = createRef();
        this.handlePaginationMessages = this.handlePaginationMessages.bind(this);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage) );

        const apiMessageId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)  );

        if (apiMessageId === this.clearChatApi) {
            const updatedChats = [...this.state.clearChatApplied];
            updatedChats.push(responseJson);
            this.props.clearChat(this.chatUserInformation.attributes.sid, 
                updatedChats
                );
            this.setState({clearChatApplied: updatedChats, 
            }); }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.client = this.props.client;
        this.chatUserInformation = this.props.chatUserInformation;
        const userEmail = localStorage.getItem('user_email');
        const userToken = localStorage.getItem('token');
        const userId = localStorage.getItem('busId');
        this.setState({
            userEmail, userToken, chatInformation: this.props.chatUserInformation,
            userId,
            chatMessageObj: {},
            clearChatApplied: this.props.chatUserInformation.attributes.clear_chat,
            messages: {},
            pinMessagesData: this.props.chatUserInformation.attributes.pinned
        });
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.handleUserClick();
    }

    async componentDidUpdate(prevProps: Props, prevState: S) {
        if(prevProps.chatUserInformation !== this.props.chatUserInformation){
            this.chatUserInformation = this.props.chatUserInformation;
            this.client = this.props.client;
            this.handleUserClick();
            this.setState({ chatInformation: this.props.chatUserInformation, loading: true, clearChatApplied: this.props.chatUserInformation.attributes.clear_chat,
                messages: {},
                chatMessageObj: {},}) }
        if (prevState.clearChatApplied.length < this.state.clearChatApplied.length) {
            const lastClearChat = this.state.clearChatApplied[this.state.clearChatApplied.length - 1].clear_chat;
            this.updateChatMessagesList(lastClearChat); }  }

    async componentWillUnmount(): Promise<void> 
    {
        this.channel.removeListener('messageAdded', this.handleMessageAdded);
        this.channel.removeListener("updated", this.handleChannelUpdated); }

    async handlePaginationMessages(event: any)
     { if (event.target.scrollTop <= 20 && this.state.chatMessageObj.hasPrevPage){
            const objMessage = await this.state.chatMessageObj.prevPage();
            this.setState({ chatMessageObj: objMessage });
            this.setMessages(objMessage, true, this.state.messages, true); }}

    resize() {
        this.setState({ isMobile: window.innerWidth <= 760 ? true : false,});
    }

    getTwilioMessages = async () => {
        this.setState({ messages: [] });

        const objMessage = await this.channel.getMessages();
        this.setState({ chatMessageObj: objMessage });
        if (objMessage.items.length > 0) { this.setMessages(objMessage, false, {}, false);}
        this.setState({loading: false }) }
    
    setMessages = (messagesData: any, prevCall: boolean, currentMessages: any, onScroll: boolean) => {
        let messagesArray: any = [];
        messagesArray = messagesData.items.map(async (message: any) => {
            const messagesState = { ...message.state, deleteMessage: message.remove, services: message.services, links: message.links, updateAttributes: message.updateAttributes };
            let chatCleared = false;
            if (this.state.clearChatApplied.length > 0) {
                this.state.clearChatApplied.forEach((dates: any) => {
                    const fromDate = new Date(dates.clear_chat.from).toLocaleString('en-IN', { hour12: false });
                    if (fromDate != 'Invalid Date' && new Date(message.state.timestamp) < new Date(dates.clear_chat.from)) {chatCleared = true;}
                });
            }
            if (chatCleared) return;
            const DiffLabel = this.getDiffLabel(messagesState.timestamp);
            return new Promise(async (resolve, reject) => {
                let messageReturn = {};
                if (messagesState.type == 'media') {const urlLink = await messagesState.media.getContentTemporaryUrl(); messageReturn = this.getMessageState(messagesState, urlLink, DiffLabel);
                } else { messageReturn = this.getMessageState(messagesState, messagesState.media, DiffLabel);}
                resolve(messageReturn);
            });
        });
        Promise.all(messagesArray).then((values) => {this.updateMessagesState(values, prevCall, currentMessages, onScroll, messagesData); })
    }

    updateMessagesState = async (messages: any, prevCall: boolean, currentMessages: any, onScroll: boolean, objMessage: any) => {
        const newMessagesState = this.getUpdatedMessages(messages, currentMessages, prevCall);
        if (!onScroll) {this.scrollToBottom();}
        if (Object.keys(newMessagesState).length < 30 && objMessage.hasPrevPage) {
            const newobjMessage = await objMessage.prevPage();
            this.setState({chatMessageObj: newobjMessage});
            this.setMessages(newobjMessage, true, newMessagesState, false);
        }
    }

    getUpdatedMessages = (messages: any, currentMessages: any, prevCall: boolean) => {
        let newMessagesState: any = {};
        if (prevCall) {
            messages.reverse();
            newMessagesState = currentMessages;
        }
        messages.forEach((message: any) => {
            if (!message) return;
            if (message.DiffLabel in newMessagesState) {
                let messageDataList = [];
                if (prevCall) {messageDataList = [message, ...newMessagesState[message.DiffLabel].data ] }
                 else { messageDataList = [ ...newMessagesState[message.DiffLabel].data, message ]}
                 newMessagesState = {
                    ...newMessagesState,
                    [message.DiffLabel]: {data: messageDataList}};
            } else { if (prevCall) {
                    newMessagesState = {
                        [message.DiffLabel]: {
                            data: [message]},...newMessagesState,
                    };
                } else {
                    newMessagesState = {
                        ...newMessagesState,
                        [message.DiffLabel]: {
                            data: [
                                message
                            ]
                        }
                    };
                }
            }
        })
        this.setState({ messages: newMessagesState, loading: false });
        return newMessagesState;
    }

    getMessageState = (messageState: any, mediaData: any, DiffLabel: string) => {
        return {
            ...messageState,
            author: messageState.author,
            body: messageState.body,
            memberSid: messageState.memberSid,
            dateUpdated: messageState.dateUpdated.toString(),
            timestamp: messageState.timestamp,
            media: mediaData,
            type: messageState.type,
            customAttributes: messageState.attributes,
            sid: messageState.sid,
            DiffLabel,
            deleteMessage: messageState.deleteMessage,
            index: messageState.index,
        }
    }

    importData = () => {
        let input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = () => {
            this.setState({showMediaLoading: true});
            const formData = new FormData();
            formData.append('file', input.files[0]);
            this.scrollToBottom();
            this.sendMedia(formData); };
        input.click();  }

    triggerPush = () => { 
        const httpBody = {
            "data":{
                "attributes": {
                    "remarks": `New message From ${localStorage.getItem("full_name")}`,
                    "notify_type": "chat", }}};
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': localStorage.getItem("token"),
             type:"user_entity"};
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        
        request.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody) );
            
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header) );
        this.triggerChatId = request.messageId;
    
        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST" );
    
        request.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_push_notifications/push_notifications` );

        runEngine.sendMessage(request.id, request) }

    handleMessageAdded = async (message: any) => {
        if (message.conversation.sid != this.channel.sid) return;
        this.channel.updateLastReadMessageIndex(message.state.index);
        this.getMessageStatus(message.conversation, this.state.chatInformation.attributes.member_data, message.conversation.attributes.delivery, message.conversation.attributes.read);
        let duplicateMsg = false;
        let { messages } = this.state;
        Object.values(messages).forEach((msgs: any) => {
            if (msgs.data.filter((mssg: any) => mssg.sid == message.state.sid).length > 0 )
             {duplicateMsg = true;}
        });
        if (duplicateMsg) return;
        let messageState = { ...message.state, deleteMessage: message.remove, services: message.services, links: message.links, updateAttributes: message.updateAttributes };
        const DiffLabel = this.getDiffLabel(messageState.timestamp); let messageObj = {};
        if (messageState.type == 'media') { const urlLink = await messageState.media.getContentTemporaryUrl();
        messageObj = this.getMessageState(messageState, urlLink, DiffLabel); } else{
            messageObj = this.getMessageState(messageState, messageState.media, DiffLabel); }
        if (DiffLabel in messages) {
            messages = {
                ...messages,
                [DiffLabel]: {
                    data: [
                        ...messages[DiffLabel].data,messageObj ]} };
        } else {
            messages = {
                ...messages,
                [DiffLabel]: {
                    data: [ messageObj]  }  };
        }
        this.setState({
            showMediaLoading: false, messages, });
        this.scrollToBottom(); };

    sendMessage = async () => {
        const { messageText: text } = this.state;
        if (text && String(text).trim()) { this.sendTwilioMessage(null, text);
            this.setState({ 
                messageText: "" }
                );
            this.scrollToBottom();
        }
        this.triggerPush()
    };

    getMemberChannelId = async (channel: any, memberData: any) => {
        const participants = await channel.getParticipants();
        const oldIds: Array<string> = [];
        memberData.forEach((item: any) => {
            if (item.email == this.state.userEmail && 'user_entity' == item.type.toLowerCase() && 'non_profit_entity' == item.type.toLowerCase()
            ) { oldIds.push(item.participant_id); } });
        const participantId = participants.filter((participant: any) => participant.state.identity == this.state.userEmail && oldIds.indexOf(participant.state.sid) == -1)[0].state.sid;
        return participantId;  }

    getMessageStatus = async (channelOb: any, memberData: Array<any>, delivery: any, read: any) => {
        if (memberData) {
            let lastReadMessageIndex: any = null;
            let lastDeliveredMessageIndex: any = null;
            const participantId = await this.getMemberChannelId(channelOb, memberData);
            if (!(participantId in delivery) || delivery[participantId] < channelOb._internalState.lastMessage.index) {
                delivery[participantId] = channelOb.lastMessage.index;
                lastDeliveredMessageIndex = Object.values(delivery).sort()[0];
            }
            if (!(participantId in read) || read[participantId] < channelOb._internalState.lastMessage.index) {
                read[participantId] = channelOb.lastMessage.index;
                channelOb.updateLastReadMessageIndex(channelOb.lastMessage.index);
                lastReadMessageIndex = Object.values(read).sort()[0];
                await channelOb.updateAttributes({ ...channelOb.attributes, delivery, read });
            }
            this.setState({
                lastReadMessageIndex,
                lastDeliveredMessageIndex,
            })
        }
    }

    handleUserClick = async () => {
        try {
            this.channel = await this.client.getConversationBySid(this.chatUserInformation.attributes.sid);
            if (this.channel._internalState.status != 'joined') {
                await this.channel.join();}
            this.getMessageStatus(this.channel, 
                this.state.chatInformation.attributes.member_data, 
                this.channel._internalState.attributes.delivery, 
                this.channel._internalState.attributes.read);
            this.channel.on("messageAdded", this.handleMessageAdded);
            this.channel.on("updated", this.handleChannelUpdated);
            this.getTwilioMessages(); }
             catch (e)
         {console.log('spec_check_Chat_channel_err', e);}}
    
    sendTwilioMessage = async (formData: any, textMessage: any) => {
        if (formData) {
            await this.channel.prepareMessage() .setBody('') .setAttributes({senderId: this.state.userId, senderType: 'business_entity'})
            .addMedia(formData)
                .build()
                .send();   } 
                else {await this.channel.prepareMessage()
                .setBody(textMessage)
                .setAttributes({senderId: this.state.userId, senderType: 'business_entity'})
                .build()
                .send(); } }
    
   

    keyUp = (e: any) => {
        if (e.keyCode == 13) {  //checks whether the pressed key is "Enter"
            this.sendMessage();
        } }
    
    typingMessage = (e: any) => {
        this.channel.typing();
        let charLength = 0;
        for (let _ of e.target.value) {
            charLength++;
        }
        if(charLength <= 150){
            this.setState({ messageText: e.target.value }) }
        }


    sendMedia = async (formData: any) => {
        this.sendTwilioMessage(formData, '');this.scrollToBottom();
        this.triggerPush();  }


    scrollToBottom = () => {
        const messageListing = 
        document.getElementById('messagesListing');
        if (messageListing) { messageListing.scrollTo(0, messageListing.scrollHeight + 100) } }

    getDiffLabel = (messageTime: any) => {const date2: any = new Date(messageTime);
        const date1: any = new Date();
        const date3 = new Date();
        
        date3.setHours(0,0,0,0);
        let DiffLabel = '';
        const DiffDays = Math.floor((Math.abs(date2 - date1) / 1000) / (60 * 60 * 24));
        if (DiffDays == 1) { DiffLabel = 'Yesterday'; } 
        else if (DiffDays == 0) {if (date3 > date2) {DiffLabel = 'Yesterday';} 
        else { DiffLabel  = 'Today'; }
        } else { DiffLabel = new Date(messageTime).toLocaleString('en-IN',{ day: 'numeric', month: 'short'});
        const yearLabel = new Date(messageTime).toLocaleString('en-IN',{ year: 'numeric' });
            DiffLabel = DiffLabel + ', ' + yearLabel; }
        return DiffLabel;}

    scrollToBottomEmoji = () => {
        if (typeof document !== 'undefined'){
            let element = global.document.querySelector(".EmojiPickerReact");
            if (element) {element.scrollIntoView({block:'start'}); } }   }

    handleEmojiPicker = () => {
        if(this.state.emojiPicker === false){
            this.setState({emojiPicker: true},() => this.scrollToBottomEmoji())
        }
        if(this.state.emojiPicker === true){
            this.setState({emojiPicker: false },()=>this.scrollToBottomEmoji())
        }  }

    handleEmojiSend = (emojiObject: any) => {
        const emojiText = emojiObject.emoji;        
        const updatedMessageText = this.state.messageText
        ? `${this.state.messageText}${emojiText}`
        : 
        emojiText;
        let charLength = 0;
        for (let _ of updatedMessageText) {
            charLength++;
        }
        if(charLength <= 150){
            this.setState({ selectedEmoji: emojiText,messageText: updatedMessageText  }); };
        }

    onSearchChange = (event: any) => {
        const elements = Array.from(document.querySelectorAll('.messagesContent'));
        const matches: Array<any> = elements
            .filter((element: any) => {
                return element.textContent.toLowerCase().includes(event.target.value.toLowerCase());
            }).reverse();
        if (matches.length > 0) {
            const messageListing = document.getElementById('messagesListing');
            if (messageListing) {
                messageListing.scrollTop = matches[0].closest('li').offsetTop;}}
        this.setState({ searchTerm: event.target.value, searchResults: matches, currentSearchSelected: 0 });}



    onChangeClearChat(event: any) {
        this.setState({
            clearChatOption: event.target.checked ? parseInt(event.target.value) : 0 });}


    onSearchNext = () => 
    {
        let newIndex = this.state.currentSearchSelected + 1;
        if (this.state.searchResults.length > newIndex && newIndex >= 0) {
            const messageListing = document.getElementById('messagesListing');
            if (messageListing) {
                messageListing.scrollTop = this.state.searchResults[newIndex].closest('li').offsetTop;
            }
            this.setState({ currentSearchSelected: newIndex,}); } }



    updateChatMessagesList = (lastClearChat: { from: string, to: string }) => {
        let newMessagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) => {
                let chatCleared = false;
                const fromDate = new Date(lastClearChat.from).toLocaleString('en-IN', { hour12: false });
                if (fromDate != 'Invalid Date' && new Date(message.timestamp) < new Date(lastClearChat.from)) {
                    chatCleared = true;
                }
                if (chatCleared) return;
                if (dateKey in newMessagesData) {
                    newMessagesData =
                     { ...newMessagesData,
                        [message.DiffLabel]: {
                            data: [ ...newMessagesData[message.DiffLabel].data, message] }};
                } else 
                { newMessagesData = {
                        ...newMessagesData,
                        [message.DiffLabel]: { data: [ message ]  } };}
            }) });
        this.setState({messages: newMessagesData })
    }

    clearChat() {
        const { userToken } = this.state;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': userToken,
            'type': 'business_entity' };
        let fromDate = this.getClearDates(this.state.clearChatOption); let toDate = this.getClearDates(0);

        const httpBody = {
            "sid": this.chatUserInformation.attributes.sid,
            "clear_chat": 
            {
                "from": fromDate,
                "to": toDate} }
    
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header));

        this.clearChatApi = request.messageId;

        let url = `${configJSON.clearChat}`;
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),url);

        request.addData( getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody) );

        request.addData( getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);

        runEngine.sendMessage(request.id, request); this.setState({ clearChatOption: 0, deleteModal: false,});
    }



    handlePinMessage = (messageData: any) => {
        let messagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) => {
                if (message.sid == messageData.sid) {
                    message.customAttributes = messageData.customAttributes;  }
                messagesData = messagesData = this.handleMessagStructure(dateKey, messagesData, message);
            })
        });
        this.setState({  messages: messagesData, pinModal: false, dropDownPin: false,currentMessageSelected: '', clearChatOption: 0,
            deleteModal: false}); }

    pinMessage = async (messageData: any) => {
        const message = { 
            ...messageData,
            customAttributes: {...messageData.customAttributes, pinned: true},
        }
        await messageData.updateAttributes(message.customAttributes);
        this.handlePinMessage(message);
    }

    getClearDates = (type: number) => {
        const currentDate = new Date();
        if (type === 0) {
            return new Date(currentDate).toISOString();} 
            else if (type === 1) {
            const oneDay = 24 * 60 * 60 * 1000; const prevDay = currentDate.getTime() - oneDay;
            const prevDate = new Date(prevDay);
            return new Date(prevDate).toISOString();}
             else if (type === 2) {
            const oneDay = 7 * 24 * 60 * 60 * 1000; const prevDay = currentDate.getTime() - oneDay;
            const prevDate = new Date(prevDay);
            return new Date(prevDate).toISOString();}
             else if (type === 3) {
            const prevDate = new Date(currentDate); prevDate.setMonth(currentDate.getMonth() - 1);
            return new Date(prevDate).toISOString();} }


    unpinMessage = async (messageData: any) => {
        const message = { 
            ...messageData,
            customAttributes: {...messageData.customAttributes, pinned: false},
        }
        await messageData.updateAttributes(message.customAttributes);
        this.handlePinMessage(message);}

    handleMessagStructure = (dateKey: string, msgData: any, msg: any) => {
        if (dateKey in msgData) {
            msgData = {
                ...msgData,
                [msg.DiffLabel]: {
                    data: [
                        ...msgData[msg.DiffLabel].data,
                        msg ] }  };  }
                         else {
            msgData = {
                ...msgData,
                [msg.DiffLabel]: {
                    data: [
                        msg ]
                } };
        }
        return msgData; }

        handleChannelUpdated = (data: { conversation: any, updateReasons: Array<string> }) => {
            const { conversation, updateReasons } = data;
            if (updateReasons.indexOf('attributes') == -1) return;
            this.getMessageStatus(conversation, this.state.chatInformation.attributes.member_data, conversation.attributes.delivery, conversation.attributes.read);
        }

    deleteMessage = (messageData: any) => {
        let messagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) =>
             {
                if 
                (message.sid == messageData.sid) return;
                messagesData = this.handleMessagStructure(dateKey, messagesData, message);})
        });
        this.setState({ messages: messagesData});
        messageData.deleteMessage();
    }


}
