// @ts-nocheck
// Customizable Area Start
import React from "react";
import PropTypes from 'prop-types';
import BusEmpolyeesController from "./BusEmployeesController";
import BusDashbaordWeb from "./BusDashboard.web";
// @ts-ignore
import classesOuter from './BusFollowing.module.css'
import {
    Box, Container,
    Typography
} from '@material-ui/core'
import { COLORS } from "../../../framework/src/Globals";
import {
    withStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import BusNonProfitProfile from "./BusNonProfitProfile.web";
import BusRegisteredEmployee from './BusRegisteredEmployee.web'
import BusPendingEmployee from "./BusPendingEmployee.web";

export const styles = (theme: Theme) =>
    createStyles({
        tabsContainer: {
            display: "flex",

        },
        tabItemSelected: {
            cursor: "pointer",
            textAlign: "center",
            width: "50%",
            border: `1px solid ${COLORS.skyBlue}`,
            padding: "15px 2px",
            borderTopLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            color: COLORS.skyBlue
        },
        tabItem: {
            textAlign: "center",
            width: "50%",
            borderBottom: `1px solid lightgrey`,
            padding: "15px 2px",
            cursor: "pointer"


        },
        registeredTab: {
            cursor: "pointer",
            textAlign: "center",
            width: "50%",
            border: `1px solid ${COLORS.skyBlue}`,
            padding: "15px 2px",
            borderBottomLeftRadius: "12px",
            borderTopRightRadius: "12px",
            color: COLORS.skyBlue
        }

    });



export class BusEmployees extends BusEmpolyeesController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }

    render() {
        const { classes }: any = this.props;
        return (
            <div>
                {/* @ts-ignore */}
                {this.state.showNpProfile ? <BusNonProfitProfile NpDetais={this.state.nonProfitProfileData} data-testId='busNonProfit' followStatus={true} onBack={() => this.setState({ showNpProfile: false })} /> :
                    < BusDashbaordWeb children={this.props.children} classes={this.props.classes} navigation={this.props.navigation} id={""} adminSelectedTab={undefined} theme={undefined}
                    >
                        <Container maxWidth="xl" style={{ marginLeft: 279.5, width: 'auto' }}>

                            <Box className={classesOuter.followersBusmainDiv}>
                                <div className={classes.tabsContainer}>
                                    <div  data-testId='confirmed'  className={this.state.tabValue == "confirmed" ? classes.tabItemSelected : classes.tabItem} onClick={this.handleConfirmClick}>
                                        <Typography style={{ fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }}>Confirmed</Typography>
                                    </div>
                                    <div data-testId='pending'  className={this.state.tabValue == "pending" ? classes.registeredTab : classes.tabItem} onClick={this.handlePendingClick}>
                                        <Typography style={{ fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }}>Pending</Typography>
                                    </div>
                                </div>
                                {this.state.tabValue == "confirmed" ? <BusRegisteredEmployee onClickPopup={this.onClickPopup}  /> : <BusPendingEmployee onClickPopup={() => this.onClickPopup} />}
                                {/* */}



                            </Box>
                        </Container>
                    </BusDashbaordWeb >}
            </div >
        )
    }
}

export default withStyles(styles as any, { withTheme: true })(
    BusEmployees as any
);
// Customizable Area End