import { NPSignupFormPayload } from "../../../../blocks/ContentManagement/src/NPSignupForm";
import { makeHttpMessage } from "../../utils/http-message";
import { appendJsonContentType } from "../../utils/http-response";


export const orgMessages = {


  npSignup({ payload }: { payload: NPSignupFormPayload }) {


    let {
      fullName,
      orgName,
      orgType,
      registrationNumber,
      street,
      town,
      country,
      postCode,

      officeStreet,
      officeTown,
      officeCountry,
      officePostCode,

      description,
      contactPhoneNumber,
      adminPhoneNumber,
      email,
      password,
      confirmPassword,
      referralsLink,
      websiteURL,
      logo,
      annualTurnover
    } = payload;

    const bodyFormData = new FormData();

    bodyFormData.append("[data][attributes][full_name]", fullName);
    bodyFormData.append("[data][attributes][email]", email);
    bodyFormData.append("[data][attributes][password]", password);
    bodyFormData.append("[data][attributes][password_confirmation]", confirmPassword);
    bodyFormData.append("[data][attributes][logo]", logo);
    bodyFormData.append("[data][attributes][organisation_type]", orgType);
    bodyFormData.append("[data][attributes][organisation_name]", orgName);
    bodyFormData.append("[data][attributes][registration_number]", registrationNumber);
    bodyFormData.append("[data][attributes][referral]", referralsLink);
    bodyFormData.append("[data][attributes][website_url]", websiteURL);
    bodyFormData.append("[data][attributes][annual_turn_over]", annualTurnover);
    bodyFormData.append("[data][attributes][street]", street);
    bodyFormData.append("[data][attributes][town]", town);
    bodyFormData.append("[data][attributes][post_code]", postCode);
    bodyFormData.append("[data][attributes][country]", country);
    bodyFormData.append("[data][attributes][contact_number]", contactPhoneNumber);
    bodyFormData.append("[data][attributes][admin_mobile_number]", adminPhoneNumber);
    bodyFormData.append("[data][attributes][is_registered]", "true");
    bodyFormData.append("[data][attributes][description]", description);


    return makeHttpMessage({
      header: {},
      url: 'bx_block_non_profit_account/non_profit_accounts',
      httpMethod: 'POST',
      httpBody: bodyFormData,
    });
  },

  fetchOrgsTypes() {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: 'bx_block_organisations/organisation_types',
      httpMethod: 'GET',
    });

  },
}
