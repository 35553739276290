import React from "react";

// Customizable Area Start
import {
  // Customizable Area Start

  Typography,
  Button,

  Box,
  IconButton,
  Grid,
  Container
  // Customizable Area End
} from "@material-ui/core";

//@ts-ignore
import classesOuter from "./RegistrationFormBusiness.module.css";

import PropTypes from 'prop-types';


import {
  withStyles,

} from "@material-ui/core/styles";

import {

  FONTS,
  icons,
  images,
  FAMILYFONTS
} from "../../../components/src/constants";

//@ts-ignore

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start

// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import FormRegitrationController, {
  Props,
} from "./RegistrationFormController";

import { COLORS } from "../../../framework/src/Globals";
import RegistrationFormPart1Business from "./RegistrationFormPart1Business.web";
import RegistrationFormPart2Business from "./RegistrationFormPart2Business.web";

// Customizable Area Start
const styles = (theme: {
  palette: any;
  zIndex: any;
  breakpoints: any;
  transitions: {
    create: (
      arg0: string | string[],
      arg1: { easing: any; duration: any }
    ) => any;
    easing: { sharp: any; easeOut: any };
    duration: {
      leavingScreen: any;
      enteringScreen: any;
    };
  };
  mixins: { toolbar: any };
  spacing: any;
}) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  accountroot: {
    flexGrow: 1,
    background: COLORS.white,
    borderRadius: 24,
    marginTop: 50, marginBottom: 50,
    boxShadow: " 1px 6px 30px 1px #c5c5c4",
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },

  imgLogo: {
    height: 100,
    width: "auto",
  },
  listItemroot: {
    width: "75%",
    margin: "4px auto",
    borderRadius: "8px",
    transition: "all .5s",
    overflow: "hidden",
  },
});


const activeIndex: any = {
  boxSizing: "border-box",
  padding: 0,
  WebkitTransition: "all 250ms ease",
  transition: "all 250ms ease",
  border: "none",
  marginLeft: 12,
  marginRight: 12,
  marginTop: 8,
  marginBottom: 20,
  backgroundColor: COLORS.skyBlue,
  fontSize: "1.3em",
  content: "",
  height: 6,
  width: 6,
  boxShadow: `0 0 1px 3px ${COLORS.skyBlue}`,
  borderRadius: 18,
  outline: "none",
};

const inActiveIndex: any = {
  marginTop: 8,
  marginBottom: 20,
  opacity: 0.3,
  padding: 0,
  WebkitTransition: "all 250ms ease",
  transition: "all 250ms ease",
  border: "none",
  backgroundColor: COLORS.skyBlue,
  boxShadow: `0 0 1px 3px ${COLORS.skyBlue}`,
  fontSize: "1.3em",
  content: "",
  height: 6,
  width: 6,
  borderRadius: 18,
  outline: "none",
};


// Customizable Area End

class RegistrationFormBusiness extends FormRegitrationController {
  static propTypes: { classes: PropTypes.Validator<object>; theme: PropTypes.Validator<object>; };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleDisableFunctionality = () => {
    let status = false; 
    if (this.state.fullName.trim().length <= 1){
      status = true
    }
    if( this.state.orgName.trim().length <= 1 ){
      status = true
    }
    if(this.state.streetName.trim().length <= 1 ){
      status = true
    }
    if(this.state.town.trim().length <= 1){
      status = true
    }
    if(this.state.country.trim().length <= 1 ) {
      status = true
    }
    if(this.state.postCode.trim().length <= 1 ){
      status = true
    }
    return status
  }

  handleToggle = () => {
    let status = false;
    if(this.state.officeStreetName.trim().length <= 1 ){
      status = true
    }
    if(this.state.officeTown.trim().length <= 1){
      status = true
    }
    if(this.state.officeCountry.trim().length <= 1 ) {
      status = true
    }
    if(this.state.officePostCode.trim().length <= 1 ){
      status = true
    }
    return status
  }

  isDisabled = () => {
    return this.state.disablePwd || this.state.disableEmail || this.state.desc.length <= 1 || this.state.contactNum.length <= 1 || this.state.emailId.length <= 1 || this.state.regPassword.length <= 1 || this.state.regConfirmPwd!==this.state.regPassword || this.state.disableDomain || !this.state.acceptTerms;
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
  

    const { classes } = this.props;
    const { activeStep } = this.state;




    let stepperData = [
      {
        id: 1,
        components: (
          // @ts-ignore
          <RegistrationFormPart1Business
            navigation={this.props.navigation}
            id={""}
            children={undefined}
            registerNextBtnValidation={this.checkRegisterBeforeNext}
            fullName={this.state.fullName}
            orgName={this.state.orgName}
            regNum={this.state.regNum}
            postCode={this.state.postCode}
            streetName={this.state.streetName}
            switchStatus={this.state.switchStatus}
            officeStreetName={this.state.officeStreetName}
            officeTown={this.state.officeTown}
            officeCountry={this.state.officeCountry}
            officePostCode={this.state.officePostCode}
            town={this.state.town}
            country={this.state.country}
            desc={this.state.desc}
            contactNum={this.state.contactNum}
            emailId={this.state.emailId}
            regPassword={this.state.regPassword}
            regConfirmPwd={this.state.regConfirmPwd}
            emailDomain={this.state.emailDomain}
            regEmailApiError={this.state.regEmailApiError}
            mobileNumberApiError={this.state.mobileNumberApiError}
            logo={this.state.logo}
            businessApiError={this.state.businessApiError}
          />
        ),
        logo: (
          <>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={images.app_logo} className={classes.imgLogo} />
            </Box>
            <Typography
              style={{ ...FONTS.commonTitle }}
              className={classesOuter.registrationFormHead}
            >
              Registration Form
            </Typography>
          </>
        ), active: activeStep
      },
      {
        id: 2,
        components: (
          // @ts-ignore
          <RegistrationFormPart2Business
          handleDisableEmail={this.handleDisableEmail}
          handleDisablePwd={this.handleDisablePwd}
          navigation={this.props.navigation}
          id={""}
          children={undefined}
          classes={undefined}
          registerNextBtnValidation={this.checkRegisterBeforeNext}
          desc={this.state.desc}
          emailDomain={this.state.emailDomain}
          contactNum={this.state.contactNum}
          emailId={this.state.emailId}
          regPassword={this.state.regPassword}
          regConfirmPwd={this.state.regConfirmPwd}
          acceptTerms={this.state.acceptTerms}
          regEmailApiError={this.state.regEmailApiError}
          mobileNumberApiError={this.state.mobileNumberApiError}
          logo={this.state.logo}
          handledisableDomain={this.handledisableDomain}
          domainApiError={this.state.domainApiError}
          />
        ),
        logo: null,
        active: activeStep
      },
    ];


    // Merge Engine - render - Start
    return (



      <div style={{ height: "max-content", backgroundColor: "white" }}>


        <Container
          maxWidth={"sm"}
          style={{

            borderRadius: 24,
            padding: 10,
            height: "auto",
          }}
        >


          <div className={classes.accountroot}>
            <Grid item xs={'auto'}>
              <>
                {activeStep === 1 && <div style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}>
                  <IconButton
                    data-testid="handleBackBtnTest"
                    color={"inherit"}
                    aria-label="Open drawer"
                    onClick={this.handleBack}
                    disabled={activeStep === 0}

                  >
                    <img src={icons.back} />
                  </IconButton>
                </div>}


                {stepperData[activeStep].components}

                <Box style={{ display: activeStep === 1 ? "none" : "inherit" }} 
                className={
                  this.handleDisableFunctionality() || this.state.switchStatus && this.handleToggle() ? classesOuter.darkButtonDisabledNP : classesOuter.darkButtonNP}
                >
                  {activeStep === 0 && 
                    <Button 
                      data-testid="nextBtnTest"
                      style={{
                      ...FAMILYFONTS.family2,
                      fontSize: 20,
                      fontWeight: 400,
                    }} onClick={this.handleNext} 
                    disabled={this.handleDisableFunctionality() || this.state.switchStatus && this.handleToggle()}
                    >Next
                    </Button>
                  }
                </Box>
                <Box data-testid='registerTestId' className={this.isDisabled() ? 
                  classesOuter.darkButtonDisabledNP : classesOuter.darkButtonNP}>
                  {activeStep === 1 &&
                    <Button 
                    data-testid="registerTest"
                    style={{
                      ...FAMILYFONTS.family2,
                      fontSize: 20,
                      fontWeight: 400,
                  }} type="submit" onClick={this.handleRegisterSubmitButton} 
                  disabled={this.isDisabled()}
                  >
                      Register
                    </Button>
                  }
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                    {stepperData?.map((ele: any, index: any) => {
                      return <div key={index} style={activeStep == index ? activeIndex : inActiveIndex} />
                    })}

                  </div>

                </Box>


              </>
            </Grid>
          </div>
        </Container>



      </div >

    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

RegistrationFormBusiness.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


export default withStyles(styles as any, { withTheme: true })(RegistrationFormBusiness);
export {RegistrationFormBusiness}
