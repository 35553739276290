// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/utils/utils.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id?: string;
  navigation: string;
  single_event_details: {
    data: {
      id: string;
      attributes: {
        target_amount: number | null;
        raised_amount: number | null;
      }
    }
  };
}

// Customizable Area Start
export interface S {
  id: string;
  loading: boolean;
  fundRaised: number;
  donorList: IUser[];
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  date: string;
  amount: string;
}

export interface IResponse {
  attributes: {
    full_name: string;
    email: string;
    donated_on: string;
    amount: number;
  }
}
// Customizable Area End

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class DonorInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  donorListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: "",
      loading: false,
      fundRaised: 0,
      donorList: []
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.message) {
        if (apiRequestCallId === this.donorListApiCallId) {
          let fundRaised = 0;
          const donorList = responseJson.data.map((item: IResponse) => {
            const fullName = item.attributes.full_name.split(" ");
            fundRaised += item.attributes.amount;
            return {
              firstName: fullName[0],
              lastName: fullName[1] ?? "-",
              email: item.attributes.email,
              date: `${moment(item.attributes.donated_on).format("DD/MM/YYYY")} ${moment(item.attributes.donated_on).format("hh:mm A")}`,
              amount: item.attributes.amount
            }
          });
          this.setState({ donorList, fundRaised, loading: false });
        }
      }
      this.setState({ loading: false });
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getDonorList();
  }

  getDonorList = async () => {
    this.setState({ loading: true });
    this.donorListApiCallId = await apiCall({
      method: "GET",
      endPoint: `/event_donations?non_profit_event_id=${this.props.single_event_details.data.id}`,
      header: {
        contentType: "application/json",
        type: "non_profit_entity",
        token: localStorage.getItem('nonprofitToken')
      }
    });
  };
}
// Customizable Area End