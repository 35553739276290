import React from "react";
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
const cautionImg = require('../../../web/assets/images/image_Caution.png');

const useAlertStyles = makeStyles((theme) => ({
  alert: {
    boxSizing: 'border-box',
    margin: '4px 0',
    padding: '1px 12px',
    borderRadius: '6px',
    width: '100%',
    color: theme.palette.error.main,
    background: 'transparent',
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    maxWidth: '20px',
  },
}));


export function InitiateAlert(props: React.ComponentProps<typeof Alert> & { cautionIcon?: boolean }) {

  const classes = useAlertStyles();

  const { children, cautionIcon, ...restProps } = props;

  const textContent = String(children);

  if (!children || !textContent.length) return null;


  return (
    <Alert
      icon={
        cautionIcon ?
          <img src={cautionImg} alt="caution" className={classes.icon} />
          :
          false
      }
      severity='error'
      aria-label={textContent}
      className={clsx(classes.alert, props.className)}
      {...restProps}
    >
      {children}
    </Alert>
  );
}
