import { createTheme } from "@material-ui/core/styles";

export const initiateTheme = createTheme({
  typography: {
    fontFamily: 'Rene_Bieder_Milliard_Medium',
  },
  palette: {
    action: {
    },
    primary: {
      main: "#019BE1",
      contrastText: "#fff",
    },
    error: {
      main: '#fd1d1d',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '10px',
        '&:disabled': {
          backgroundColor: "#019BE1 !important",
          color: 'white !important',
          opacity: 0.6,
        },
      },
      disabled: {},
    },
  },
});

