// Customizable Area Start
import React from "react";


import NotificationsController, { Props } from "./NotificationsController";


import { NotificationsContent } from "./NotificationsContent";


// Customizable Area End

class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
        // Customizable Area Start
        <NotificationsContent/>
        // Customizable Area End
    );
  }
}

// Customizable Area Start


export default Notifications
export {Notifications}
// Customizable Area End
