// Customizable Area Start
// @ts-nocheck
import * as React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import {
  ButtonGroup,
  Divider,
  Hidden,
  Typography,
  Box,
  Container,
  Modal,
  Button,
  Avatar,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";

import AvatarGroup from "@material-ui/lab/AvatarGroup";

import {
  constants,
  images,
  icons,
  FONTS,
} from "../../../components/src/constants";

import EventsController, { Props } from "./EventsController";

// @ts-ignore
import classesoutercss from "./MyPledges.module.css";
import SwipeList from "../../../components/src/custom/SwipeList";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export const styles = (theme: Theme) =>
  createStyles({
    statusMob: {
      [theme?.breakpoints.down("sm")]: {
        color: "green",
        backgroundColor: "lightgreen",
        position: "absolute",
        right: 0,
        bottom: 0,
        border: "1px solid black",
      },
    },
    deleteonCard: {
      borderRadius: "50%",
      border: "1px solid grey",
      position: "absolute",
      right: 20,
      padding: 10,
      bottom: 20,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    activestatusButton: {
      color: COLORS.darkGray,
      textAlign: "center",
      backgroundColor: "#1fe1c2",
      borderRadius: "0px 17px",
      position: "absolute",
      top: "0",
      right: 0,
      padding: 5,
      width: "10%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    paststatusButton: {
      color: COLORS.darkGray,
      textAlign: "center",
      backgroundColor: "#cfe9e5",
      borderRadius: "0px 17px",
      position: "absolute",
      top: "0",
      right: 0,
      padding: 5,
      width: "10%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    activelayOutbuttons: {
      background: '#cfe9e5',
      width: 50,
      height: 24,
      borderRadius: "6px",
      // lineHeight: 2,
      // marginTop: 0,
      color: COLORS.green,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      position: "absolute",
      right: 0,
      paddingTop: "5px",

      "@media (max-width:600px)": {
        top: '10px',
      },
    },

    pastlayOutbuttons: {
      width: 50,
      height: 24,
      borderRadius: "6px",
      // lineHeight: 2,
      // marginTop: 0,
      color: COLORS.darkGray,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
      position: "absolute",
      right: 0,
      background: "#cfe9e5",
      paddingTop: '5px',
      "@media (max-width:600px)": {
        top: '10px'
      },
    },

    FiCardMedia: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
    },
    FiCardContent: {
      position: "relative",
      backgroundColor: "transparent",
    },
    FiCardActions: {
      position: "relative",
    },
    FiCardActionArea: {
      position: "relative",
    },
    FiCard: {
      position: "relative",
      borderRadius: 15,
      boxShadow: "none",
    },
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: "8px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(2, 2, 2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      fontSize: "revert",
    },
    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px",
      width: "93%",
      marginLeft: " 3%",
    },
    sectionDesktop: {
      display: "none",
      // width:"27%",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      // width:"27%",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      borderRadius: "8px",
      color: "text.primary",
      backgroundColor: "white",
      margin: "8px",
      padding: "8px",
      height: 46,
      width: 46,
    },
    tabIcons: {
      margin: 6,
      padding: 8,
      border: "1px solid #cdc9c9",
      borderRadius: 8,
      backgroundColor: "white",
    },
    locationsreading: {
      borderRadius: "8px",
      color: "text.primary",
      margin: "8px",
      padding: "8px",
      display: "flex",
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      // maxWidth: 500,
      borderRadius: 16,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    locationIcon: {
      height: "24px",
      width: "24px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
      marginRight: "13px",
    },
    readinglocation: {
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      paddingLeft: "inherit",
      paddingRight: "inherit",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },

    gutter: {
      // marginLeft: 80, // or something
      "&:first-child": {
        marginLeft: 0,
      },
    },
    tabsRoot: {
      marginBottom: "50px",
      backgroundColor: COLORS.white,
    },
    tabsIndicator: {
      backgroundColor: "#1890ff",
    },
    tabRoot: {
      textTransform: "initial",
      // minWidth: 72,
      fontWeight: theme?.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$tabSelected": {
        color: "#1890ff",
        fontWeight: theme?.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    tabSelected: {},
    typography: {
      padding: theme.spacing(3),
    },
    eventloop: {
      marginBottom: "30px",
    },
    carouselWrapper: {
      width: " 100%",
    },
    image_div: {
      backgroundColor: "pink",
    },
    cardCarousel: {
      width: "100%",
      margin: " 0 0 20 -17",
      borderStyle: "solid",
      borderColor: "white",
      backgroundColor: "white",
      padding: "24px 24px 24px 14px",
      borderRadius: 24,
      "& after": {
        content: "",
        display: "table",
        clear: "both",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        margin: 0,
      },
    },
    card_carousel_div: {
      float: "left",
      width: "50%",
      padding: "0 10px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        display: "block",
        marginBottom: "20px",
      },
    },
    carousel_card: {
      // padding: "16px",
      textAlign: "left",
      // backgroundColor: "#f1f1f1",
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 2.334,
      [theme.breakpoints.up("md")]: {
        fontSize: pxToRem(16),
      },
    },
    avatar_Groups: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    // web

    showAvatarGroup: { display: "none" },
    showCarouselAvatar: {
      display: "block",
      width: 200,
    },

    icon_holder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 35,
      width: 35,
      background: "white",
      borderRadius: "50%",
      position: "absolute",
      top: 10,
      right: 10,
    },
    eventContent: {
      width: "60%",
      padding: "0 20px",
      "@media (min-width:600px)": {
        width: "40%",
        padding: "0 10px",
      },
    },

    w_60: {
      width: "60%",
      paddingRight: "15px",
      "@media (min-width:600px)": {
        width: "100%",
        paddingRight: 0,
      },
    },
    py_3: {
      padding: ".5rem 0",
      "@media (min-width:600px)": {
        padding: ".5rem 0",
      },
    },

    heading2: {
      fontSize: 15,
      fontWeight: 400,
    },

    heading3: {
      fontSize: 12,
      fontWeight: 300,
    },

    flex: {
      display: "flex",
    },

    event_img_container: {
      width: "40%",
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
    },

    eventcontent: {
      width: "100%",
      padding: " 0 22px",
      marginTop: -8,
    },
    cardDescription: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    cardDescriptionAvatar_location: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    avatarImgcontainer: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "0.5rem",
    },
    avatarImg: {
      width: 23,
      height: 23,
      justifyContent: "space-between",
      borderRadius: "50%",
      position: "relative",
      left: "-5px",
      // marginLeft: "-25px",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: 16,
        height: 16,
      },
    },

    event_main_img: {
      width: "100%",
      height: "100%",
      borderRadius: 15,
      objectFit: "cover",
    },

    eventCard: {
      padding: 24,
      background: "white",
      borderRadius: 24,
      marginBottom: 30,
      height: 230,
      width: "94%",
      border: "1px solid #e7dfdf",
      marginTop: '35px'
    },

    icon: {
      height: 25,
      width: 25,
    },

    btn_dbs: {
      color: COLORS.white,
      height: 38,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: 0,
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 15,
      left: 0,
      [theme.breakpoints.down("sm")]: {
        // left: 0,
        // color: "rgb(255,255,255)",
        // bottom: 0,
        // height: 25,
        // width: 52,
        // fontSize: "smaller",
        // padding: "5 14",
        // position: "absolute",
        // backgroundColor: "#F89A01",
        // borderTopRightRadius: 15,
        // borderBottomLeftRadius: 13,
        display: "none",
      },
    },

    mobileLayoutContent: {
      display: "none",
    },
    multiLineEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      width: 180,
    },
    layOutbuttons: {
      width: 76,
      height: 24,
      borderRadius: "8px",
      lineHeight: 2,
      marginTop: 0,
      color: COLORS.white,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },

    navigationIcon: {
      color: COLORS.skyBlue,
      backgroundColor: "white",
      margin: "8px",
      padding: "13px",
      height: 50,
      borderRadius: 30,
      zIndex: 999,
      top: -12,
      right: 11,
    },

    [theme.breakpoints.down("sm")]: {
      showCarouselAvatar: {
        display: "none",
      },
      eventdate_loc: {
        width: 121,
        height: 14,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: -0.3,
        color: " #858585",
      },

      event_img_container: {
        width: "36%",
        position: "relative",
      },
      eventCard: {
        padding: 0,
        background: "white",
        borderRadius: 0,
        marginBottom: 30,
        height: 120,
        border: "1px solid #e7dfdf",
        "@media (max-width:600px)": {
          border: 'none'
        },
      },

      icon: {
        height: 15,
        width: 15,
      },

      heading2: {
        fontSize: 12,
        fontWeight: 400,
      },

      heading3: {
        fontSize: 10,
        fontWeight: 300,
      },

      py_3: {
        padding: ".5rem 0",
      },

      w_60: {
        width: "100%",
        paddingRight: 0,
      },

      h2: {
        fontSize: 15,
      },
      desktopLayoutContent: {
        display: "none",
      },

      layOutbuttons: {
        width: 50,
        height: 24,
        borderRadius: "6px",
        // lineHeight: 2,
        // marginTop: 0,
        color: "green",
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        position: "absolute",
        right: 0,
      },

      navigationIcon: {
        color: COLORS.skyBlue,
        backgroundColor: "white",
        margin: "8px",
        padding: "13px",
        height: 50,
        borderRadius: 30,
        zIndex: 999,
        top: -25,
        right: -29,
      },
      mobileLayoutContent: {
        width: "50%",
        display: "inline-grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "self-start",
        "@media (min-width:600px)": {
          width: "50%",
          position: "relative",
        },
      },
    },
  });
// Customizable Area End
class MyPledges extends EventsController {

  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleChange = (event: any, value: any) => {
    // @ts-ignore
    this.setState({ value });
  };

  buttonGroup() {
    return (
      <Container
        maxWidth={false}
        style={{
          background: COLORS.white,
          marginTop: 10,
        }}
      >
        <Divider />{" "}
        <ButtonGroup
          variant="outlined"
          aria-label="text button group"
          style={{ display: "flex", justifyContent: "center" }}
          className={classesoutercss.btnGrooup}
        >
          <Button
            onClick={() => {
              this.setState({
                pledgesActive: true,
                pledgesPast: false,
              });
              this.ActivePledgesTabData()
            }}
            data-testId="activeBtn"
            style={{
              textTransform: "capitalize",
              textAlign: "center",
              width: '100%',
              borderLeft: 'none',
              color: this.state.pledgesActive ? COLORS.skyBlue : COLORS.darkGray

            }}
          >
            Active
          </Button>
          <Button
            onClick={() => {
              this.setState({
                pledgesActive: false,
                pledgesPast: true,
              });
              this.PastPledgesTabData()
            }}
            data-testId="pastBtn"
            style={{
              textTransform: "capitalize",
              textAlign: "center",
              width: '100%',
              borderRight: 'none',
              color: this.state.pledgesPast ? COLORS.skyBlue : COLORS.darkGray
            }}
          >
            Past
          </Button>
        </ButtonGroup>
        <Divider style={{ marginBottom: 10 }} />
        {/* searchbar and notification  section  end*/}
        {/* Profile section end */}
      </Container>
    )
  }

  showActiveAvatarImg(item) {
    const { attributes } = item.attributes.non_profit_event.data;
    
    // Check if 'attributes.image' is an array and has at least one element
    if (Array.isArray(attributes.image) && attributes.image.length > 0) {
      // Render the AvatarGroup component with avatars for each image
      return (
        <AvatarGroup max={4}>
          {attributes.image.map((it) =>
            <Avatar
              key={`img${it.id}`}
              alt={`img${it.id}`}
              src={it.url}
            />
          )}
        </AvatarGroup>
      );
    } else {
      // Return null if 'attributes.image' is not an array or has no elements
      return null;
    }
  }
  
  

  showDate(item) {
    // Destructure the properties from the item object
    const { from_date, start_time } = item.attributes.non_profit_event.data.attributes;
  
    // Format the from_date and start_time using moment.js
    const formattedFromDate = moment(from_date)?.format('Do MMMM YYYY');
    const formattedStartTime = moment(start_time)?.format('h:mm:ss a');
  
    // Return the formatted date and time, or '---' if any of them is null or undefined
    return `${formattedFromDate ?? '---'}, ${formattedStartTime ?? '---'}`;
  }

  showActivePledge(classes) {
    return (
      this.state.activePledgesTabData.map((item, index) => (
        <SwipeList key={index} name="pledge">
          <div
            className={`${classes.flex} ${classes.eventCard}`}
            style={{ width: "100%" }}
          >
            <div className={classes.event_img_container}>
              <img
                src={item.attributes.non_profit_event.data.attributes.event_image.url}
                alt=""
                className={classes.event_main_img}
              />
              {/* <span className={classes.icon_holder}> */}
              <Hidden xsDown implementation="css">
                <img
                  src={icons.favourites}
                  style={{
                    zIndex: 9,
                    position: "absolute",
                    backgroundColor: COLORS.white,
                    padding: "6px",
                    width: "30px",
                    height: "30px",
                    right: " 12px",
                    top: "12px",
                    borderRadius: "16px",
                    color: COLORS.white,
                    fontSize: "12px",
                    lineHeight: "27px",
                    textAlign: "center",
                    letterSpacing: "-0.3px",
                  }}
                />
              </Hidden>
              {/* </span> */}
              <span className={classes.btn_dbs}>DBS</span>
            </div>

            {/* <Hidden smUp implementation="css"> */}
            <Box className={classes.mobileLayoutContent}>
              {/* button  */}
              <div style={{ paddingLeft: 16 }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <Typography
                    variant="h6"
                    className={classes.multiLineEllipsis}
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: 2,
                      letterSpacing: -0.3499999940395355,
                      textAlign: "left",
                    }}
                  >
                    {item.attributes.non_profit_event.data.attributes.event_name}
                  </Typography>
                  <div
                    className={this.state.pledgesActive ? classes.activelayOutbuttons : classes.pastlayOutbuttons}
                  >
                    {this.state.pledgesActive ? 'Active' : 'Past'}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <Typography
                    variant="body1"
                    className={classes.multiLineEllipsis}
                    style={{
                      width: 201,
                      height: 16,
                      fontSize: 14,
                      lineHeight: 1,
                      letterSpacing: -0.35,
                      color: "#000000",
                    }}
                  >
                    {this.handleDescriptionText(item.attributes.non_profit_event.data.attributes.description, 50)}
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <img
                    src={icons.calender}
                    alt=""
                    className={classes.avatarImg}
                  />
                </div>
                <span className={classes.eventdate_loc}>
                  {this.showDate(item)}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <img
                    src={icons.locationred}
                    alt=""
                    className={classes.avatarImg}
                  />
                </div>
                <span className={classes.eventdate_loc}>
                  {item.attributes.non_profit_event.data.attributes.location}
                </span>
              </div>
            </Box>
            {/* </Hidden> */}

            <div
              className={classes.desktopLayoutContent}
              style={{ width: "60%" }}
            >
              <div className={classes.eventcontent}>
                <Typography
                  className={this.state.pledgesActive ? classes.activestatusButton : classes.paststatusButton}

                >
                  {this.state.pledgesActive ? 'Active' : 'Past'}
                </Typography>
                <Typography
                  style={{
                    ...FONTS.h1,
                    textAlign: "left",
                  }}
                >
                  {item.attributes.non_profit_event.data.attributes.event_name}
                </Typography>

                <div className={classes.cardDescription}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 8,
                    }}
                  >
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: 50,
                      }}
                      src="https://picsum.photos/70"
                    />
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ marginLeft: 9, marginTop: 3 }}
                    >
                      {this.handleDescriptionText(item.attributes.non_profit_event.data.attributes.description, 50)}
                    </Typography>
                  </div>
                </div>
                <div className={classes.cardDescription}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      paddingLeft: 13,
                    }}
                  >
                    <div className={classes.avatarImgcontainer}>
                      <img
                        src={icons.calender}
                        alt=""
                        className={classes.avatarImg}
                        style={{ marginTop: 3 }}
                      />
                    </div>
                    <span className="username">
                      {this.showDate(item)}
                    </span>
                  </div>
                </div>

                <div
                  className={classes.cardDescriptionAvatar_location}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      paddingLeft: 13,
                    }}
                  >
                    <div className={classes.avatarImgcontainer}>
                      <img
                        src={icons.locationred}
                        alt=""
                        style={{ marginTop: 3 }}
                        className={classes.avatarImg}
                      />
                    </div>
                    <span className="username">
                      {item.attributes.non_profit_event.data.attributes.location}
                    </span>
                  </div>
                  <div style={{ width: 300 }}>
                    {this.showActiveAvatarImg(item)}

                  </div>

                  {this.state.pledgesActive && <div
                    className={classes.deleteonCard}
                    onClick={() => {
                      this.setState({ pledgesDelete: true, deleteActivePledgeId: item.id });
                    }}
                    data-testId={`deletePledgeIcon-${item.id}`}
                  >
                    <img
                      src={icons.deleteIcon}
                      style={{ height: 30 }}
                      alt="deleteJpeg"
                    />
                  </div>}

                </div>
              </div>
            </div>
          </div>
        </SwipeList>
      ))
    )
  }

  showPastPledge(classes) {
    return (
      this.state.pastPledgesTabData.map((item, index) => (
        <SwipeList key={index} name="pledge">
          <div
            className={`${classes.flex} ${classes.eventCard}`}
            style={{ width: "100%" }}
          >
            <div className={classes.event_img_container}>
              <img
                src={item.attributes.non_profit_event.data.attributes.event_image.url}
                alt=""
                className={classes.event_main_img}
              />
              <Hidden xsDown implementation="css">
                <img
                  src={icons.favourites}
                  style={{
                    zIndex: 9,
                    position: "absolute",
                    backgroundColor: COLORS.white,
                    padding: "6px",
                    width: "30px",
                    height: "30px",
                    right: " 12px",
                    top: "12px",
                    borderRadius: "16px",
                    color: COLORS.white,
                    fontSize: "12px",
                    lineHeight: "27px",
                    textAlign: "center",
                    letterSpacing: "-0.3px",
                  }}
                />
              </Hidden>
              <span className={classes.btn_dbs}>DBS</span>
            </div>

            {/* <Hidden smUp implementation="css"> */}
            <Box className={classes.mobileLayoutContent}>
              {/* button  */}
              <div style={{ paddingLeft: 16 }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <Typography
                    variant="h6"
                    className={classes.multiLineEllipsis}
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: 2,
                      letterSpacing: -0.3499999940395355,
                      textAlign: "left",
                    }}
                  >
                    {item.attributes.non_profit_event.data.attributes.event_name}
                  </Typography>
                  <div
                    className={this.state.pledgesActive ? classes.activelayOutbuttons : classes.pastlayOutbuttons}
                  >
                    {this.state.pledgesActive ? 'Active' : 'Past'}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <Typography
                    variant="body1"
                    className={classes.multiLineEllipsis}
                    style={{
                      width: 201,
                      height: 16,
                      fontSize: 14,
                      lineHeight: 1,
                      letterSpacing: -0.35,
                      color: "#000000",
                    }}
                  >
                    {this.handleDescriptionText(item.attributes.non_profit_event.data.attributes.description, 50)}
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <img
                    src={icons.calender}
                    alt=""
                    className={classes.avatarImg}
                  />
                </div>
                <span className={classes.eventdate_loc}>
                  {this.showDate(item)}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <img
                    src={icons.locationred}
                    alt=""
                    className={classes.avatarImg}
                  />
                </div>
                <span className={classes.eventdate_loc}>
                  {item.attributes.non_profit_event.data.attributes.location}
                </span>
              </div>
            </Box>
            {/* </Hidden> */}

            <div
              className={classes.desktopLayoutContent}
              style={{ width: "60%" }}
            >
              <div className={classes.eventcontent}>
                <Typography
                  className={this.state.pledgesActive ? classes.activestatusButton : classes.paststatusButton}
                >
                  {this.state.pledgesActive ? 'Active' : 'Past'}
                </Typography>
                <Typography
                  style={{
                    // fontSize: "1.2rem",
                    // fontWeight: 500,
                    ...FONTS.h1,
                    textAlign: "left",
                  }}
                >
                  {item.attributes.non_profit_event.data.attributes.event_name}
                </Typography>

                <div className={classes.cardDescription}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 8,
                    }}
                  >
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: 50,
                      }}
                      src="https://picsum.photos/70"
                    />
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ marginLeft: 9, marginTop: 3 }}
                    >
                      {this.handleDescriptionText(item.attributes.non_profit_event.data.attributes.description, 50)}
                    </Typography>
                  </div>
                </div>
                <div className={classes.cardDescription}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      paddingLeft: 13,
                    }}
                  >
                    <div className={classes.avatarImgcontainer}>
                      <img
                        src={icons.calender}
                        alt=""
                        className={classes.avatarImg}
                        style={{ marginTop: 3 }}
                      />
                    </div>
                    <span className="username">
                      {this.showDate(item)}
                    </span>
                  </div>
                </div>
                <div
                  className={classes.cardDescriptionAvatar_location}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      paddingLeft: 13,
                    }}
                  >
                    <div className={classes.avatarImgcontainer}>
                      <img
                        src={icons.locationred}
                        alt=""
                        style={{ marginTop: 3 }}
                        className={classes.avatarImg}
                      />
                    </div>
                    <span className="username">
                      {item.attributes.non_profit_event.data.attributes.location}
                    </span>
                  </div>
                  <div style={{ width: 300 }}>
                    {this.showActiveAvatarImg(item)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwipeList>
      ))
    )
  }

  errorMsg(text) {
    return (
      <div style={{ textAlign: 'center' }}>
        <p>No {text} Pledges Available</p>
      </div>
    )

  }

  displayActivePledgeData(classes) {
    // Check if 'activePledgesTabData' is an array and has at least one element
    if (Array.isArray(this.state.activePledgesTabData) && this.state.activePledgesTabData.length > 0) {
      // Call the showActivePledge function to render active pledges
      return this.showActivePledge(classes);
    } else {
      // Call the errorMsg function to render an error message for "Active" pledges
      return this.errorMsg('Active');
    }
  }

  displayPasPledgeData(classes) {
    // Check if 'pastPledgesTabData' is defined and an array
    if (this.state.pastPledgesTabData && Array.isArray(this.state.pastPledgesTabData)) {
      // Check if 'pastPledgesTabData' has at least one element
      if (this.state.pastPledgesTabData.length > 0) {
        // Call the showPastPledge function to render past pledges
        return this.showPastPledge(classes);
      } else {
        // Call the errorMsg function to render an error message for "Past" pledges
        return this.errorMsg('Past');
      }
    } else {
      // Call the errorMsg function to render an error message for "Past" pledges
      return this.errorMsg('Past');
    }
  }
  
  

  displayModel() {
    return (
      <Modal
        // hideBackdrop
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.pledgesDelete}
        onClose={() => {
          this.setState({ pledgesDelete: false });
        }}
        data-testId="modalClose"
      >
        <Box className={classesoutercss.modalBox}>
          <img src={images.invalidOtp} alt='warrning' className={classesoutercss.warningIcon} />
          <Typography style={{ fontSize: '20px' }}>Want to cancel this pledge? </Typography>
          <Button
            onClick={() => this.DeleteActivePledgeTab(this.state.deleteActivePledgeId)}
            data-testId='confirmBtn'
            style={{
              width: "80%",
              backgroundColor: "#009be2",
              color: "white",
              borderRadius: "10px",
              marginTop: '35px',
              height: '50px'
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              this.setState({ pledgesDelete: false });
            }}
            data-testId='cancelBtn'
            style={{
              width: "80%",
              color: "black",
              borderRadius: "10px",
              marginTop: 15,
              marginBottom: '30px',
              height: '50px',
              border: '1px solid gray'
            }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, theme }: any = this.props;

    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {/* @ts-ignore  */}
          <div
            className={classesoutercss.userprofileMain}
            style={{ backgroundColor: COLORS.white }}
          >

            {this.buttonGroup()}


            <div className={classes.root}>
              <Container maxWidth="xl" style={{ marginBottom: "30%" }}>
                {this.state.pledgesActive ? this.displayActivePledgeData(classes)
                  : this.displayPasPledgeData(classes)}
              </Container>
            </div>
          </div>

          {this.displayModel()}

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
MyPledges.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export { MyPledges }
export default withStyles(styles as any, { withTheme: true })(MyPledges as any);

// Customizable Area End