 {/* Customizable Area Start */}
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';

export const setSelectedAdminTabSuccess = (adminSelectedTab: any) => ({
  type: SET_SELECTED_TAB,
  payload: { adminSelectedTab }
});

export function setSelectedAdminTab(adminSelectedTab: any) {
  return (
    dispatch: (arg0: {
      type: string;
      payload: { adminSelectedTab: any };
    }) => void
  ) => {
    dispatch(setSelectedAdminTabSuccess(adminSelectedTab));
    localStorage.setItem('adminSelectedTab', JSON.stringify(adminSelectedTab));
  };
}



 {/* Customizable Area End */}