import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { boolean } from "yup";
import { constants } from "../../../components/src/constants";

const types = ["success", "info", "warning", "error"];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  passwordError: string;
  emailError: string;
  passwordValidation: boolean;
  emailValidation: boolean;
  emailPasswordValidation:boolean;
  loginError:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  getLoginCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  idfcm:any;
  fcmDevicesId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
    ];
runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      passwordError: "",
      emailError: "",
      passwordValidation: false,
      emailValidation: false,
      emailPasswordValidation:false,
      loginError:"",
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    if(localStorage.getItem('token') !== null){
      this.props.navigation.navigate('HomeDashBoard')
    }
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
      loginError:""
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
      loginError: ""
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };


  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      await this.handleUserCredentialsMessage(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      await this.handleRestApiResponseMessage(message);
    }
    // Customizable Area End
  }


// Customizable Area Start
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  async handleUserCredentialsMessage(message: Message) {
    const userName = message.getData(getName(MessageEnum.LoginUserName));
    const password = message.getData(getName(MessageEnum.LoginPassword));
    const countryCode = message.getData(getName(MessageEnum.LoginCountryCode));
  
    if (!countryCode && userName && password) {
      this.setState({
        email: userName,
        password: password,
        checkedRememberMe: true,
      });
  //@ts-ignore
  this.txtInputEmailProps.value = userName;
  
  //@ts-ignore
  this.txtInputPasswordProps.value = password;
  
  this.CustomCheckBoxProps.isChecked = true;
  
    }
  }
  
  async handleRestApiResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
    if (apiRequestCallId != null) {
      if (apiRequestCallId === this.validationApiCallId && responseJson !== undefined) {
         this.handleValidationApiResponse(responseJson);
      }
  
      if (apiRequestCallId === this.idfcm && responseJson.message === "device id save successfully") {
        this.fcmDevices();
      }
  if (apiRequestCallId === this.fcmDevicesId) {
    this.handleFcmDevices(responseJson);
  }
  
  if (apiRequestCallId === this.apiEmailLoginCallId) {
    this.handleEmailLoginApiResponse(responseJson, errorReponse);
  }
  
  if (this.getLoginCallId === apiRequestCallId) {
    this.handleLoginApiResponse(responseJson, this.props.navigation);
  }
  
  }
  }
   handleValidationApiResponse(responseJson: any) {
    let arrayholder = responseJson.data;
    if (arrayholder && arrayholder.length !== 0) {
      let regexData = arrayholder[0];
      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }
  
  handleEmailLoginApiResponse(responseJson: any, errorReponse: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.saveLoggedInUserData(responseJson);
      this.sendLoginSuccessMessage();
      this.openInfoPage();
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessage();
    }
  
    this.parseApiCatchErrorResponse(errorReponse);
  }
  
  handleLoginApiResponse(responseJson: any, navigation: any) {
    if (responseJson && responseJson?.meta?.token) {
      const { meta } = responseJson;
      localStorage.setItem("token", meta.token);
      localStorage.setItem("acc_id", meta.id);
      //this.props.setSelectedTab(constants.dashboard_screens.home)
      setTimeout(() => {
        navigation.navigate("HomeDashBoard");
      }, 300);
    }
    if (responseJson && responseJson.errors && responseJson.errors.length > 0) {
      this.setState({ loginError: responseJson.errors[0].failed_login });
    }
  }
  
  handleFcmDevices = (responseJson:any)=>{
    const token = localStorage.getItem("fcm_token")
    const foundDevice = responseJson.data.find((device:any) => device.device_token === token);
    if(foundDevice){
      localStorage.setItem("device_id",foundDevice.id)
    }
  }



  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // custom validation 


  isValidEmail(value: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  }

  validateEmail(value: any) {
    if (value == '') {
      this.setState({ emailError: "", emailValidation: false })
    } else if (this.isValidEmail(value)) {
      this.setState({ emailError: "", emailValidation: false })
    } else {
      this.setState({ emailError: 'Please enter valid email address.', emailValidation: true })
    }
  }

  // validatePassword(value: any) {
  //   if (value.length < 8) {
  //     this.setState({ passwordError: 'Password should contain minimum 8 chars.', passwordValidation: true })
  //   } else {
  //     this.setState({ passwordError: '', passwordValidation: false })
  //   }
  // }


  isValidPassword(value: any) {
    var minMaxLength = /^[\s\S]{8,32}$/,
      upper = /[A-Z]/,
      number = /[0-9]/,
      special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    if (
      minMaxLength.test(value) &&
      upper.test(value) &&
      number.test(value) &&
      special.test(value)
    ) {
      return true;
    }

    return false;
  }

  validatePassword(value: string) {
    if (value == "") {
      this.setState({ passwordError: "Input can not be empty", emailPasswordValidation: true });
    } else if (!this.isValidPassword(value)) {
      this.setState({
        passwordError:
          "Password must be of 8 characters with a capital letter, a special character & a number.",
        emailPasswordValidation: true
      });
    } else {
      this.setState({ passwordError: "", emailPasswordValidation: false });
    }
  }

  doEmailLogIn(): boolean {
    localStorage.setItem("selectedTab", JSON.stringify("Home"));
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLoginCallId = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndPoint
    );

    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    //request body
    const requestBody = {
      data: {
        attributes: {
          email: this.state.email,
          password: this.state.password
        },
        type: "email_account",
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg); 
  }
  
fcmTknSend=()=>{
    const httpBody = {
      "device_token":localStorage.getItem("fcm_token")
    };
const header = {
  "Content-Type": 'application/json',
  "token":localStorage.getItem("token"),
  "type":"user_entity"
};

let requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

  this.idfcm=requestMessage.messageId
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `account_block/device_ids`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "POST"
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(httpBody)
);
 
runEngine.sendMessage(requestMessage.id, requestMessage)

  }

  fcmDevices () {
    const header = {
      'Content-Type': 'application/json',
      "token":localStorage.getItem("token"),
      "type":"user_entity"
    };
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.fcmDevicesId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `account_block/device_ids`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "GET"
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

runEngine.sendMessage(requestMessage.id, requestMessage)

  }
  // Customizable Area End
}


