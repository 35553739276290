// Customizable Area Start
// @ts-ignore
import React from "react";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import {
  CircularProgress as Loader,
  Avatar,
  Typography,
  Box,InputBase
} from "@material-ui/core";
import PropTypes from "prop-types";

import UserProfileBasicController, {
  } from "./UserProfileBasicController";
  // @ts-ignore

import {
  FONTS,
  icons,
} from "../../../components/src/constants";

import { COLORS } from "../../../framework/src/Globals";
// @ts-ignore
import classesAffi from './NonProfitsAffiliations.module.css';
import {
  withStyles 
  } from "@material-ui/core/styles";
import { InitiateButton } from '../../../components/src/design-system/button';
import { InitiateCheckbox } from "../../../components/src/design-system/checkbox";
import {styles} from "./NonProfitsAffiliations.web";
import { HideContainer } from "../../../components/src/shared/hide-container";
import { InitiateSpinner } from "../../../components/src/design-system/spinner";


class NonProfitsAffi extends UserProfileBasicController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End

  renderIntiateButton(){

    return(

      <InitiateButton
          fullWidth
          style={{backgroundColor: "#009BE2", color: 'white',marginTop:'8px' , fontSize:'1.3rem',fontFamily: 'Rene_Bieder_Milliard_Medium' }}
          variant="contained"
          size="large"
          data-testId='saveBtn'
          onClick={() => {
            this.saveNonProfitData(this.state.selesctedCheckBox); 
            this.props.navigation.navigate('UserEditProfile'); 
          } }
          >
          {'Save'}
          </InitiateButton>
    )
  }

  renderMobileHeader(){
    return(

      <MobileHeader
      navigatTo={"/"}
      data-testid="mobileSearchMainContainer"
      navigation={this.props.navigation}
      title={"Non-Profits Affiliations"}
    />
    )
  }

  renderHideContainer(){
    return(

      <HideContainer hideWhen={this.state.nonProfitAffiliationFilterData.length > 0}>
        <div style={{paddingLeft:'22%', paddingTop:'40%'}}>
              <InitiateSpinner />
              </div>
        </HideContainer>
    )
  }

  
  render() {
  // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End


    return (
      // Customizable Area Start
      <>
 {this.renderMobileHeader()}
        <div style={{height:'90%'}}>
        <div className={classesAffi.mainNonProfitsBox11} 
        style={{ overflow: 'hidden', height:'97%',paddingTop:'7%',
      transition: 'max-height 0.5s ease-in-out',   }}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <img className={classes._tabs_logo} src={icons.search} />
          </div>
          <InputBase
            className={classes.inputSearch}
            data-testId="searchOrg"
            style={{ borderRadius:'10px', boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)' , width:'97%', marginLeft:'2%'}}
            onChange={(e) => {
              this.handleSearchOrganization(e.target.value)
            }}
            placeholder="Search organizations"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <Box style={{paddingTop:'7%', fontFamily:'Rene_Bieder_Milliard_Medium', color:'#009BE2', background:'white', paddingBottom:'5%',marginLeft:'3%' }}>
          {
            this.state.orgError ?
            <Typography style={{ ...FONTS.paragraph, color: 'red' }}>Select up to 5 organizations where you are currently a registered member or volunteer</Typography>
            :
            <Typography style={{ }}>Select up to 5 organizations where you are currently a registered member or volunteer</Typography>
          }
        </Box>
      
        <div className={classesAffi.nonprofitalignment1} style={{height:'600px',overflow: 'auto', padding:'0px'}}>
          {this.renderHideContainer()}
        
          {
          this.state.nonProfitAffiliationFilterData.map((obj: any) =>
          <div className={classes.container} >
          <InitiateCheckbox
          formControlLabelProps={{
            
            className: classes.formLabel,
          }}
          checked={this.state.selesctedCheckBox?.includes(obj.id)}
          onChange={(e) => this.handleNonProfits(e, obj.id)}
          name={obj.organisation_name}
          label={
            <div className={classes.root}>
              <Avatar className={classes.icon} alt={obj.organisation_name} src={obj.logo.url !== null ? obj.logo.url : obj?.organisation_name} />
              <div className={classes.textContainer}>
                <Typography>
                  {obj.organisation_name}
                </Typography>
  
                <Typography>
                  {obj.location},{obj.post_code}
                </Typography>
              </div>
  
            </div>
          }
        />
        </div>
          )}
        </div>
        <div className={classesAffi.npMob} style={{}}>
        {this.renderIntiateButton()}
        </div>
      </div>
          
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
NonProfitsAffi.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export { NonProfitsAffi };
export default withStyles(styles as any)(NonProfitsAffi as any);
// Customizable Area End

// Customizable Area End
