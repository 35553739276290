import React, { ReactNode } from "react";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';

const useLabelStyles = makeStyles({
  root: {
    display: 'block',
    color: 'black',
  },
  centerLabel: {
    textAlign: 'center'
  }
});

export function InitiateLabel({
  children, centerLabel, className, htmlFor, id,
}: {
  children: ReactNode, centerLabel?: boolean, className?: any, htmlFor?: string, id?: string
}) {

  const classes = useLabelStyles();

  return (
    <InputLabel
      id={id}
      htmlFor={htmlFor}
      className={clsx(classes.root, className, {
        [classes.centerLabel]: centerLabel
      })}
      style={{
        textAlign:"initial",
      }}>
      {children}
    </InputLabel>
  )
}
