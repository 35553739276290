// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
// @ts-ignore
import classOuter from "./BusDashboard.module.css";


import ServiceSpecificSettingsAdminController, { Props } from './ServiceSpecificSettingsAdminController';

import { Grid, Drawer, Box, CssBaseline, AppBar, Toolbar, Typography, Button, List, MenuItem, withStyles, IconButton, MenuList, Popover, ListItemIcon, ListItemText, ListItem, Modal, TextField } from '@material-ui/core'
import { COLORS } from '../../../framework/src/Globals';
import { AppContext } from '../../../web/src/App.js';
import { FONTS, constants, icons, images } from '../../../components/src/constants';
import { connect } from "react-redux";
import { setSelectedAdminTab } from "../../../framework/src/redux/adminTab/adminTabActions";
import CloseIcon from '@material-ui/icons/Close';
import ContactusSuccessModal from '../../../components/src/custom/ContactusSuccessModal';
import BusChangePW from './BusChangePW.web';
import Notifications from '../../notifications/src/Notifications.web';


const drawerWidth = 280;

const popoverStyle = {
  borderRadius: "20px",
  marginTop: "43px",
  left: "656px",
};

const popoverStyles = {
  width:"600px",
  borderRadius: "20px",
  overflowX: "hidden",
  overflowY: "hidden"
};

export const styles = (theme: {
  zIndex: any;
  breakpoints: any;
  transitions: {
    create: (
      arg0: string | string[],
      arg1: { easing: any; duration: any }
    ) => any;
    easing: { sharp: any; easeOut: any };
    duration: {
      leavingScreen: any;
      enteringScreen: any;
    };
  };
  mixins: { toolbar: any };
  spacing: { unit: number };
}) => ({
  root: {
    display: "flex",
    position: "relative",
    overflowX: "hidden",
    overflowY: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  modalstyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  modalBtn: {
    width: '80%',
    height: '56px',
    backgroundColor: '#009be2',
    color: "white",
    borderRadius: "10px",
    margin: "0 auto 10px",
    textTransform: "capitalize",
  },
  modalWrapper: {
    backgroundColor: '#8d8d8db0',
  },
  modalBox: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'absolute',
    background: '#fff',
    maxWidth: '550px',
    padding: '30px',
    borderRadius: '12px'
  },
  textAreaField: {
    '& ::placeholder': {
      color: '#8d8d8d',
      opacity: 1
    },
    '& .MuiInputBase-root': {
      minHeight: '225px',
      borderRadius: '12px',
      borderWidth: '2px',
      display: 'flex',
      alignItems: 'baseline'
    },
    '& .MuiInputBase-input': {
      minHeight: '225px',
    },
    '& textarea': {
      fontSize: '14px',
      fontFamily: 'Rene_Bieder_Milliard_Book',
    },
    '& .Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#009be2',
      }
    }
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: COLORS.white,
    color: COLORS.black,
    borderBottom: "1px solid #f3f3f3",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.appBar - 1,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: " 2px solid #fff",
  },


  drawerLogoHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 12px",
    ...theme.mixins.toolbar,
    justifyContent: "center",
    marginTop: "16%",
    marginBottom: "30px",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing.unit * 3,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  listItem: {
    transition: "all .5s",
    display: "flex",
    marginLeft: 10,
  },
  listLink: {
    padding: "0 15px",
    textDecoration: "none",
    color: "inherit",
    transition: "all .5s",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  listIcon: {
    height: "20px",
    color: "inherit",
    justifyContent: "center",
    marginLeft: 20,
    height: 20
  },
  listItemText: {
    // fontSize: '0.84em',
    // fontFamily : 'Roboto-Medium',
    ...FONTS.body3,
    // color: COLORS.lightGray,
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.84em",
    },
  },
  selectedlistItemText: {
    // fontSize: '0.84em',
    ...FONTS.body3,
    color: COLORS.white,
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.84em",
    },
  },
  list: {
    listStyle: "none",
  },
  listItemroot: {
    maxWidth: "90%",
    margin: "4px 26px 8px -4px",
    borderRadius: "4px",
    transition: "all .5s",
    overflow: "hidden",
  },
  selected: {
    backgroundColor: "#009BE233",
    borderRadius: "0px 8px 8px 0px",
    color: COLORS.white,
  },
  bottomtabselected: {
    background: COLORS.linearGradientSkyBlue,
    color: COLORS.skyBlue,
  },
  applogo: {
    height: "100%",
    maxHeight: "110px",
  },
  bottomBarroot: {
    width: "100%",
    height: "70px",
    left: "0px",
    top: "774px",
    borderRadius: "0px",
  },
  bottom_bar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  bottom_bar_button: {
    position: "reletive",
    zIndex: theme.zIndex.drawer + 1,
    marginRight: 18,
    marginLeft: 18,
    bottom: 40,
    right: 0,
    left: 0,
  },
  iconButton: {
    color: COLORS.black,
  },
  notificationiconButton: {
    color: COLORS.black,
    padding: "20px",
  },
  toolBarContentCenter: {
    justifyContent: "space-between",
  },
  toolBarContentLeft: {
    justifyContent: "flex-start",
  },
  _tabs_logo: {
    height: "24px",
    width: "24px",
    left: "33px",
    top: "788px",
    borderRadius: " 0px",
  },
  title: {
    color: COLORS.skyBlue,
  },
  dash: {
    border: " 1px solid lightgrey",
    width: "100%",
    height: 0,
  },
  sectionDesktop: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
});

export class BusDashbaordWeb extends ServiceSpecificSettingsAdminController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };


  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start


  onWebSidebarMenuClick = (_index: any, item: any) => {
    const navigationRoutes = {
      [constants.business_dashboard_screens.Dashboard]: "BusHomeDashboard",
      [constants.business_dashboard_screens.BusinessProfile]: "BusProfile",
      [constants.business_dashboard_screens.Following]: "BusFollowing",
      [constants.business_dashboard_screens.RegisteredEmployees]: "BusEmployees",
      [constants.business_dashboard_screens.Communication]: "BusChat",
      [constants.business_dashboard_screens.Surveys]: "BusSurveysDashboard",
    };

    const selectedRoute = navigationRoutes[item.name];

    if (selectedRoute) {
      this.props.navigation.navigate(selectedRoute);
    }
  };

  handleWebSidebarMenuClick = (_index: any, item: any) => {
    if(localStorage.getItem("plan") || item.name === "Dashboard") {
      this.onWebSidebarMenuClick(_index, item);
      this.props.setSelectedAdminTab(item.name);
    }
  };
  indicator(context: any){
          return  <div>
                 {context.newNoti?<Box style={{
                  width: '8px',
                  height: '8px',
                  backgroundColor: "red",
                  borderRadius: '50%',
                  position: 'absolute',
                  top: 24,
                  right: 24,
                }}>
                </Box>:null}
                
                         </div> ;
  }
  // Customizable Area End




  render() {
    const { children, classes, navigation } = this.props;
    const plan = localStorage.getItem("plan");

    return (
      <AppContext.Consumer>
        {(context) => (
      <div>
        <div
          className={classes.root}
          style={{ backgroundColor: "#f0f2f5", height: "100%" }}
        >
          <CssBaseline />

          <Drawer
            transitionDuration={0}
            className={classes.drawer}
            variant="permanent"
            anchor="left"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerLogoHeader}>

              <img src={images.app_logo} className={classes.applogo} />
            </div>

            <List className={classes.list}>
              {constants?.businessSidebar.map((item, _index) => {
                const isSelected = this.getUrlNameBusDash()
                  ? item.name === this.getUrlNameBusDash()
                  : item.name === this.props.selectedAdminTab;
                const listItemClassName = `${classes.listItemroot} ${isSelected && classes.selected}`;
                return (
                  <div
                    key={item.name}
                  >
                    <ListItem
                      button
                      className={listItemClassName}
                      data-testId={`busSidebar-${item.id}`}
                      onClick={() => {
                        this.handleWebSidebarMenuClick(_index, item);
                      }}
                      disableGutters
                      key={_index}

                    >
                      <ListItemIcon className={classes.listIcon}>
                        {(item.icon &&
                          item.name === this.props.adminSelectedTab ? (
                          <img
                            className={classes._tabs_logo}
                            src={item.selectedIcon}
                            style={{ height: "100%", width: "auto" }}
                          />
                        ) : (
                          <img
                            className={classes._tabs_logo}
                            src={item.icon}
                            style={{ height: "100%", width: "auto" }}
                          />
                        )) || ""}
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          color: "#009be2",
                          fontFamily: "Rene_Bieder_Milliard_Medium",
                          fontWeight: 700,
                        }}
                        className={
                          item.name === this.props.adminSelectedTab
                            ? classes.selectedlistItemText
                            : classes.listItemText
                        }
                      >
                        {item.name}
                      </ListItemText>
                    </ListItem>
                    {/* </ListItem> */}
                  </div>
                )
              })}-
            </List>
          </Drawer>
          <main
            className={classNames(classes.content, {
              [classes.contentShift]: this.state.open,
            })}
          >
            <AppBar
              position="static"
              style={{
                background: "linear-gradient(180deg, #00CBF3 0%, #009BE2 100%)",
                height: 100,
              }}
            >
              <Toolbar className={classOuter.dashboardnavicons}>
              {plan && <Box fontFamily="Rene_Bieder_Milliard_Medium" fontSize="18px" border="1.5px solid #fff" borderRadius="8px" padding="5px 10px" marginLeft={"280"}>Subscribed to {plan}</Box>}
                <div
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: 0,
                  }}
                >
                  <IconButton aria-label="show 4 new mails" color="inherit"
                    data-test-id="bell-icon"
                     onClick={(e) => {
                      this.handleNotiOpen(e);
                      if(this.state.openNoti){
                        context.closeIndicator();
                      }
                    }}
                  >
                       {this.indicator(context)}
                    <img
                      src={icons.bellIconNP }
                      style={{ height: 48, width: 48  }}
                    />
                  </IconButton>
                  <Popover 
                    data-test-id="notification-modal"
                    style={{ height: "80%" }}
                    onClose={()=>{
                      this.handleNotiClose();
                      context.closeIndicator();
                    }}
                    open={!!this.state.openNoti}
                    anchorEl={this.state.openNoti}
                    PaperProps={{ style: popoverStyles }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}             
                   >
                    <div>
                    <Notifications 
                      isCome={context.isCome}
                    navigation={this.props.navigation} id={''} onClose={undefined} 
                    notificationmodalState={()=>{this.handleNotiClose();    
                    context.closeIndicator();  
                     }} type="business_entity"/>
                    </div>
                  </Popover>
                  <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                  // style={{ display: 'flex', flexDirection: 'row', justifyContent : 'space-between' }}
                  >
                    <img
                      src={icons.profile}
                      style={{ height: 48, width: 48, background: 'white', borderRadius: '100%' }}
                    />
                  </IconButton>
                  <Button data-testId={'profileClick'} onClick={() => { this.setState({ showBusProfile: true }) }} disableRipple style={{
                    textTransform: 'capitalize', color: 'white', fontFamily: 'Rene_Bieder_Milliard_SemiBold', fontSize: 16, marginRight: -10, paddingTop: 2
                  }}>
                    Profile
                  </Button>

                  <Typography data-testId='goToClickText' onClick={(e) => this.goToClick(e)} style={{ display: 'flex', alignItems: 'center' }}>
                    {this.props.showNp && 'Profile'}
                    <span
                      className={classOuter.arroWImgSpan}
                      onClick={(e) => this.goToClick(e)}
                      data-testId='goToClickImg'
                    >
                      {this.state.anchorEl !== null ? (
                        <img
                          src={icons.view_up}
                          className={classOuter.viewUpImg}
                        />
                      ) : (
                        <img
                          src={icons.view_down}
                          className={classOuter.viewDownImg}
                        />
                      )}
                    </span>
                  </Typography>
                  <Popover
                    data-testId="menu2Popover"
                    open={this.state.anchorEl !== null}
                    onClose={this.handlePopoverClose}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{ style: popoverStyle }}
                  >
                    <MenuList
                      // eslint-disable-next-line react-native/no-inline-styles
                      style={{
                        paddingLeft: 50,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}>
                      <MenuItem data-testId='subClick' button onClick={() => this.props.navigation.navigate('BusChangeSubscriptionPlan')} >Subscriptions</MenuItem>
                      <MenuItem data-testId='activityClick' button onClick={() => this.props.navigation.navigate('ActivityLog')} >Activity Log</MenuItem>
                      <MenuItem onClick={this.handleContactusOpen}>Contact Us</MenuItem>
                      <MenuItem
                        data-testId='signout'
                        button
                        onClick={() => {
                          this.delFcmToken()
                          localStorage.clear()
                          this.props.navigation.navigate("LoginBusiness")
                        }} >Sign out</MenuItem>
                    </MenuList>
                  </Popover>
                </div>
              </Toolbar>

              {/* </AppBar> */}
              <Modal
                open={this.state.openContactusModal}
                onClose={this.handleContactusClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modalWrapper}
              >
                <Box sx={classes.modalstyle}>
                  <Box className={classes.modalBox}
                  // maxWidth="xl"
                  >
                    <Box>
                      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
                        <Typography style={{ ...FONTS.body1, fontSize: '16px', textAlign: 'left', marginBottom: '20px' }}>
                          Drop us a message and we'll come back to you as soon as possible!'
                        </Typography>
                        <CloseIcon onClick={this.handleContactusClose} style={{ position: 'absolute', right: ' 18px', top: '18px', cursor: 'pointer', borderRadius: '50%', border: '2px solid', padding: '3px', fontSize: '24px', }} />
                      </div>
                      <div style={{ marginBottom: "2%" }}>
                        <TextField
                          value={this.state.contactMsg}
                          onChange={(e) => {
                            this.setState({ isSubmitting: false, contactMsg: e.target.value, contactMessageError: "" });
                          }}
                          onBlur={(e) => {
                            this.validateContactMessage(e.target.value)
                          }}
                          data-testId='contactUs'
                          id="outlined-multiline-static"
                          placeholder="What do you want to tell us about?"
                          multiline
                          rows={6}
                          variant="outlined"
                          fullWidth
                          className={classes.textAreaField}
                        />
                      </div>
                      <Box className={classOuter.errorMsg}>
                        <div className={classOuter.errors}>{this.state.contactMessageError}</div>
                      </Box>
                    </Box>
                    <div style={{ display: "flex", justifyContent: "flexEnd", marginTop: 30 }}>
                      <Button
                        className={classes.modalBtn}
                        onClick={this.contactUsSub}
                        variant="contained"
                        disabled={this.state.isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </Box>
                </Box>
              </Modal>
              <ContactusSuccessModal openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose} />

            </AppBar>

            {this.state.showBusProfile ? <BusChangePW onClose={this.handleCloseProfile} draft={this.state.draft} handleProfileFieldChange={this.handleProfileFieldChange} profileChangeErrors={this.state.profileChangeErrors} handleChangeAvatar={this.handleChangeAvatar} handleSubmitProfile={this.handleSubmitProfile} pwError={this.state.pwError} /> : children}
          </main>
        </div>
      </div >
      )}
      </AppContext.Consumer>
    )
  }
}

BusDashbaordWeb.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
// Customizable Area Start

function mapStateToProps(state: any) {
  console.log(state, "npState");
  return {
    adminSelectedTab: state.adminTabReducer.adminSelectedTab,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedAdminTab: (selectedTab: any) => {

      return dispatch(setSelectedAdminTab(selectedTab));
    },
  };
}
// Customizable Area End


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any)(BusDashbaordWeb));