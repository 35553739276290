import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";

import "../assets/css/Settings5.css";
import { FONTS } from "../../../components/src/constants";
import Settings5Controller from "./Settings5Controller";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import CustomSuccessModel from "../../../components/src/custom/CustomSuccessModel";
import { isMobile } from "react-device-detect";

const StyledRating = withStyles({
  iconFilled: {
    color: "#009be2",
  },
  iconHover: {
    color: "#009be2",
  },
})(Rating);

export default class Feedback extends Settings5Controller {
  render() {
    return (
      <Box>
        <div className="mob-header">
          <MobileHeader title="Feedback" navigation={this.props.navigation} />
        </div>
        <Container maxWidth="xl">
          <Box>
            <div style={{ marginLeft:'-2.6%'}}>
              <Box component="fieldset" mb={3} borderColor="transparent" className="starRating">
                <Typography className="feedbackTitle"
                  style={{ fontFamily: 'Rene_Bieder_Milliard_SemiBold'}}
                >
                  How would you rate your experience with us?
                </Typography>
                <div style={{ textAlign: "center", marginTop:`${isMobile ? '0' : '30' }` }}>
                  <StyledRating
                    value={this.state.feedbackRating}
                    size="large"
                    name="customized-color"
                    getLabelText={(value: number) =>
                      `${value} Star${value !== 1 ? "s" : ""}`
                    }
                    //   @ts-ignore
                    onChange={(e, value) => {
                      this.setState({ feedbackRating: value });
                    }}
                    data-testId='feedbackRating'
                  />
                </div>
              </Box>
              <TextField
                className="feedbackMsg"
                value={this.state.feedbackMsg}
                onChange={(e) => this.handleFeedbackMsg(e.target.value)}
                data-testId='feedbackMsg'
                id="outlined-multiline-static"
                placeholder={`Tell us a bit more about, why you choose ${this.state.feedbackRating
                  } `}
                multiline
                rows={10}
                variant="outlined"
              // fullWidth
              />
            </div>
            {this.state.feedbaqckMsgError && <Box className={'errorMsg'} style={{ marginLeft: '7%'}}>
              <div className={'errors'}>Please enter feedback message</div>
            </Box>}
          </Box>
          <div style={{ marginTop: 200, position: "relative" }}>
  <div style={{ position: "sticky", top: 0 , width:"117%", marginLeft:'-10%'}}>
    <Button
      className="signInBtn1"
      variant="contained"
      onClick={this.feedbackSubmit}
      style={{
        textTransform: "capitalize",
      }}
    >
      Submit
    </Button>
  </div>
</div>
        </Container>
        <CustomSuccessModel openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose} msgTitle='Feedback Received! Thank you' msg=""/>
      </Box>
    );
  }
}
