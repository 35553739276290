import React, { useRef, useState } from 'react';
import { useOrgsTypes, useNPSignup } from '../../../components/src/api/hooks/orgs.hooks';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormikTextField } from '../../../components/src/shared/formik-text-field';
import { InitiateButton } from '../../../components/src/design-system/button';
import { InitiatePhoneInput } from '../../../components/src/shared/phone-input';
import { InitiateThemeProvider } from '../../../components/src/theme/theme.provider';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { HorizontalSpacer } from '../../../components/src/shared/horizontal-space';
import { InitiateSwitch } from '../../../components/src/design-system/switch';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { images, } from "../../../components/src/constants";
import { IconButton, Typography, Box, MobileStepper, Checkbox } from "@material-ui/core";
import GooglePlacesAutocomplete,{ geocodeByAddress } from 'react-google-places-autocomplete';
import { InitiateLabel } from '../../../components/src/design-system/label';
import { InitiateDropdown } from '../../../components/src/design-system/dropdown';
import { useCurrentLocation } from '../../../components/src/utils/current-location';
import { useNavigate } from '../../../components/src/utils/router-context';
import { InitiateAlert } from '../../../components/src/design-system/alert';
import { makeStyles } from '@material-ui/core/styles';
import { configJSON } from './AdminRegistrationController';
import { TermsAndConditionModal } from '../../../components/src/shared/terms-modal';

const useStyles = makeStyles({
  countryAndPostCodeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>div': {
      width: '100%',
    },
  },
  checkboxFont: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#9a99a0',
  },
  darkColor: {
    color: "black"
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  helperText: {
    boxSizing: 'border-box',
    position: "absolute",
    top: "0",
    right: "0",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: '15px 0 8px 0',
  },
  bioHelperText: {
    fontSize: '1rem',
    color: '#a4a3a4',
  },
  logo: {
    maxWidth: '160px',
  },
  submitBtn: {
    fontSize: '1.5rem',
  },
  logoTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: "10px"
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
    height: '100px',
    border: '1px solid #a7b6b9',
    borderRadius: '10px',
    '& img': {
      maxWidth: '100%',
      borderRadius: '10px',
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
  },
  noticeText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '14px',
    fontWeight: 400,
    '& span': {
      color: 'red'
    },
  },
  formTitle: {
    fontFamily: 'Rene_Bieder_Milliard_Medium',
    fontSize: '1.7rem',
    margin: '20px 0',
  },
});

const INITIAL_VALUES = {
  fullName: '',
  orgName: '',
  orgType: '',
  registrationNumber: '',
  street: '',
  town: '',
  country: '',
  postCode: '',

  activateOfficeAddress: false,
  officeStreet: '',
  officeTown: '',
  officeCountry: '',
  officePostCode: '',

  description: '',
  contactPhoneNumber: '',
  adminPhoneNumber: '',
  email: '',
  password: '',
  confirmPassword: '',
  referralsLink: '',
  websiteURL: '',
  annualTurnover: '',
  logo: '',
  acceptTerms: false
}

export interface NPSignupFormPayload {
  fullName: string,
  orgName: string,
  orgType: string,
  registrationNumber: string,
  street: string,
  town: string,
  country: string,
  postCode: string,

  officeStreet: string,
  officeTown: string,
  officeCountry: string,
  officePostCode: string,

  description: string,
  contactPhoneNumber: string,
  adminPhoneNumber: string,
  email: string,
  password: string,
  confirmPassword: string,
  referralsLink: string,
  websiteURL: string,
  annualTurnover: string;
  logo: any,
}

const validationSchemaStep1 = yup.object().shape({

  fullName: yup.string()
    .matches(/^[A-Z][a-z]+[,.'-]?(?: [A-Z][a-z]+[,.'-]?)*$/i, 'Please enter full name')
    .required('Full name is required'),

  orgName: yup.string()
    .required('Organisation name is required'),

  orgType: yup.string().required('Organisation type is required'),
  street: yup.string().required('Street is required'),
  town: yup.string().required('Town is required'),
  country: yup.string().required('Country is required'),
  postCode: yup.string().required('Post code is required'),

  activateOfficeAddress: yup.bool(),
  officeStreet: yup.string()
    .when('activateOfficeAddress', {
      is: value => value,
      then: yup.string().required('Office street is required'),
      otherwise: yup.string(),
    }),
  officeTown: yup.string()
    .when('activateOfficeAddress', {
      is: value => value,
      then: yup.string().required('Office town is required'),
      otherwise: yup.string(),
    }),
  officeCountry: yup.string()
    .when('activateOfficeAddress', {
      is: value => value,
      then: yup.string().required('Office country is required'),
      otherwise: yup.string(),
    }),
  officePostCode: yup.string()
    .when('activateOfficeAddress', {
      is: value => value,
      then: yup.string().required('Office post code is required'),
      otherwise: yup.string(),
    }),
});
const referralRegExp = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;
const urlRegExp = /^(https?:\/\/)?([-_\d a-z]+\.)+[a-z\d]+$/i;
const phoneRegExp = /^\+?\d*[-. ]?(?:\(\d+\))?[-. ]?\d{3,}(?:[-. ]?\d{3,}){2}$/;
const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchemaStep2 = yup.object().shape({

  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords did not match"),
      otherwise: yup.string(), }),

      description: yup
      .string()
      .test('word-count', 'Only 150 words are allowed', (value) => {
        if (value) {
          return value.split(' ').filter((x:any) => x).length <= 150;
        }
        return true;
      }).required("Description is required"),
  contactPhoneNumber: yup.string().required("Contact number is required").matches(phoneRegExp, 'Contact number is not valid'),
  adminPhoneNumber: yup.string().required("Admin number is required").matches(phoneRegExp, 'Admin mobile number is not valid'),

  email: yup.string().required("Email is required").matches(emailRegExp, 'Email is not valid'),

  password: yup.string().required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,
      "Password must be of 8 characters with a capital letter, a special character & a number."
    ),
  


  referralsLink: yup.string()
    .matches(referralRegExp, 'Referrals link is not valid'),

  websiteURL: yup.string()
    .matches(urlRegExp, 'Website URL is not valid'),
  annualTurnover: yup.string()
  .test("no-alphabet",'Annual turnover is not valid',(value: any) => {
    return !value || !isNaN(value);
  }),
  acceptTerms: yup.bool().oneOf([true])
});


export function NPSignupForm() {

  const [step, setStep] = useState(0);
  const [modalValue, setModalValue] = useState("");

  const classes = useStyles();

  const { currentLocation } = useCurrentLocation();
  const orgsTypesQuery = useOrgsTypes();
  const orgsTypes = orgsTypesQuery?.data?.data ?? [];

  const imageInputRef = useRef<any>(null);

  const navigate = useNavigate();

  const { mutate: signup, isLoading, error } = useNPSignup();

  const errors = error?.errors?.[0] ?? {};

  const apiEmailError = errors.account || errors.email || '';
  const apiContactError = errors.contact_number || '';
  const apiLogoError = errors.logo || '';
  const apiOrgNameError = errors.organisation_name || ''

  const getAddressDetails = (results: google.maps.GeocoderAddressComponent[]) =>  {
    const addressComponents = {
      city: "",
      postcode: "",
      country: "",
    };

    for (const component of results) {
      if (component.types.includes('postal_code')) {
        addressComponents.postcode = component.long_name;
      } else if (!addressComponents.city && (component.types.includes('postal_town') || component.types.includes("administrative_area_level_2") || component.types.includes('administrative_area_level_1'))) {
        addressComponents.city = component.long_name;
      } else if (component.types.includes('country')) {
        addressComponents.country = component.long_name;
      }
    }
    return addressComponents;
  }

  const getLocation = (description: string | null | undefined) => {
    return description ?? "";
  }

  const officeStreetError = (officeStreetTouched: boolean | undefined, officeStreetError: string | undefined) => {
    return !officeStreetTouched && !officeStreetError;
  }

  const getOrgError = (touched: boolean | undefined, error: string | undefined) => {
    return touched ? error : '';
  }

  return (
    <InitiateThemeProvider>
      <TermsAndConditionModal
        data-testId="termModal"
        accountType="non_profit"
        open={!!modalValue}
        onClose={() => setModalValue("")}
        modalValue={modalValue}
      />
      <Formik
        enableReinitialize
        initialValues={{
          ...INITIAL_VALUES,
          country: currentLocation.country || '',
          street: currentLocation.address || '',
          town: currentLocation.city || '',
          postCode: currentLocation.postcode || '',
        }}
        onSubmit={(values, { setTouched }) => {
          setTouched({});
          if (step == 0)
            setStep(1);
          else {

            const { activateOfficeAddress, ...signupValues } = values;


            signup({ payload: signupValues }, {
              onSuccess(responseJson: any) {

                localStorage.setItem("adminSelectedTab", JSON.stringify("Organisation"));

                localStorage.setItem('org_id', responseJson?.data?.attributes?.organisation_id)
                localStorage.setItem("nonprofitToken", responseJson?.meta?.token)
                localStorage.setItem("showSubscribe", "true")
                localStorage.setItem("np_user_role", responseJson?.data?.attributes?.role);

                navigate("/NpRegSuccess");
              },
              onError(error:any){
                const errors = error?.errors?.[0] ?? {};     
                if (errors.organisation_name !== undefined){
                  setStep(0)
                }
              }
            });
          }
        }}
        validationSchema={step == 0 ? validationSchemaStep1 : validationSchemaStep2}
      >
        { // @ts-ignore
          formik => <Form translate="">
            <InitiateThemeProvider>
              <HideContainer hideWhen={step != 0}>

                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <img src={images.app_logo} className={classes.logo} />
                  <Typography color="primary" className={classes.formTitle}>
                    {'Registration Form'}
                  </Typography>
                </Box>

                <FormikTextField
                  name="fullName"
                  variant="outlined"
                  fullWidth
                  label="Full name*"
                  inputProps={{ maxLength: 20 }}
                  placeholder="Full name"
                />

                <VerticalSpacer value={25} />

                <FormikTextField
                  name="orgName"
                  variant="outlined"
                  fullWidth
                  label="Name of organisation / project *"
                  placeholder="Enter organisation name"
                  inputProps={{ maxLength: 35 }}
                />

                <HideContainer hideWhen={!apiOrgNameError}>
                  <VerticalSpacer value={10} />
                  <InitiateAlert>
                    {apiOrgNameError}
                  </InitiateAlert>
                </HideContainer>

                <VerticalSpacer value={25} />

                <InitiateDropdown
                  label="Organisation type *"
                  options={orgsTypes?.map(org => org?.attributes?.organisation_type)}
                  onSelect={(option) => {
                    formik.setFieldValue('orgType', option);
                  }}
                  selectedOption={formik.values.orgType}
                  placeholder="Choose the type"
                  error={getOrgError(formik.touched.orgType, formik.errors.orgType)}
                />
                <VerticalSpacer value={25} />
                <FormikTextField
                  name="registrationNumber"
                  variant="outlined"
                  fullWidth
                  label="Registration number"
                  placeholder="Registration number"
                />
                <VerticalSpacer value={25} />
                <Box className='address-location npSignUp'>
                  <Box mb="7px">
                    <InitiateLabel htmlFor="street">Address *</InitiateLabel>
                  </Box>
                  <GooglePlacesAutocomplete
                    data-testId="street"
                    apiKey={configJSON.mapsAPIKey}
                    debounce={500}
                    selectProps={{
                      isClearable: true,
                      classNamePrefix: "react-select",
                      placeholder: "Street*",
                      value: formik.values.street ? [{ label: formik.values.street, value: formik.values.street }] : undefined,
                      noOptionsMessage: () => null,
                      onChange: async (event) => {
                        const location = getLocation(event?.value?.description);
                        formik.setFieldValue("street", location);
                        formik.setFieldValue("town", "");
                        formik.setFieldValue("country", "");
                        formik.setFieldValue("postCode", "");
                        geocodeByAddress(location)
                          .then(results => {
                            const { postcode, city, country } = getAddressDetails(results[0].address_components);
                            formik.setFieldValue("town", city);
                            formik.setFieldValue("country", country);
                            formik.setFieldValue("postCode", postcode);
                          }
                        )
                      }
                    }}
                  />
                </Box>
                <HideContainer hideWhen={!formik.touched.street && !formik.errors.street}>
                  <InitiateAlert>
                    {formik.errors.street}
                  </InitiateAlert>
                </HideContainer>
                <VerticalSpacer value={15} />
                <FormikTextField
                  name="town"
                  placeholder="Town*"
                  variant="outlined"
                  fullWidth
                />
                <VerticalSpacer value={15} />
                <div className={classes.countryAndPostCodeContainer}>
                  <FormikTextField
                    name="country"
                    placeholder="Country*"
                    variant="outlined"
                    fullWidth
                  />
                  <HorizontalSpacer />
                  <FormikTextField
                    name="postCode"
                    placeholder="Post code"
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <VerticalSpacer value={25} />

                <div className={classes.switchContainer}>
                  <Typography>
                    {'Office address different from above?'}
                  </Typography>
                  <InitiateSwitch
                    checked={formik.values.activateOfficeAddress}
                    onChange={(event) => {
                      formik.setFieldValue('activateOfficeAddress', event.target.checked);
                    }}
                    name="office-address"
                    aria-label="office-address"
                    data-testid="office-address"
                  />
                </div>

                <HideContainer hideWhen={!formik.values.activateOfficeAddress}>
                  <VerticalSpacer value={25} />
                  <Box className='address-location npSignUp'>
                    <GooglePlacesAutocomplete
                      data-testId="office-street"
                      debounce={500}
                      apiKey={configJSON.mapsAPIKey}
                      selectProps={{
                        isClearable: true,
                        classNamePrefix: "react-select",
                        value: formik.values.officeStreet ? [{ label: formik.values.officeStreet, value: formik.values.officeStreet }] : undefined,
                        placeholder: "Office street",
                        noOptionsMessage: () => null,
                        onChange: async (event) => {
                          const location = getLocation(event?.value?.description);
                          formik.setFieldValue("officeStreet", location);
                          formik.setFieldValue("officeCountry", "");
                          formik.setFieldValue("officeTown", "");
                          formik.setFieldValue("officePostCode", "");
                          geocodeByAddress(location)
                            .then(results => {
                              const { postcode, city, country } = getAddressDetails(results[0].address_components);
                              formik.setFieldValue("officeCountry", country);
                              formik.setFieldValue("officeTown", city);
                              formik.setFieldValue("officePostCode", postcode);
                            }
                          )
                        }
                      }}
                    />
                  </Box>
                  <HideContainer hideWhen={officeStreetError(formik.touched.officeStreet, formik.errors.officeStreet)}>
                    <InitiateAlert>
                      {formik.errors.officeStreet}
                    </InitiateAlert>
                  </HideContainer>
                  <VerticalSpacer value={15} />
                  <FormikTextField
                    name="officeTown"
                    placeholder="Office town"
                    variant="outlined"
                    fullWidth
                  />
                  <VerticalSpacer value={15} />
                  <div className={classes.countryAndPostCodeContainer}>
                    <FormikTextField
                      name="officeCountry"
                      placeholder="Office country"
                      variant="outlined"
                      fullWidth
                    />
                    <HorizontalSpacer />
                    <FormikTextField
                      name="officePostCode"
                      placeholder="Office post code"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </HideContainer>

                <VerticalSpacer value={25} />

              </HideContainer>


              <HideContainer hideWhen={step != 1}>

                <IconButton
                  onClick={() => {
                    setStep(0);
                  }}
                  size="medium">
                  <ChevronLeftIcon />
                </IconButton>
                <VerticalSpacer />

                <FormikTextField
                  name="description"
                  variant="outlined"
                  fullWidth
                  label="Brief description of organisation *"
                  placeholder="Type description here"
                  multiline
                  rows={4}
                  helperText={<>
                    <span className={classes.bioHelperText}>{"*"}</span>
                    <span className={classes.bioHelperText}>{`(${formik.values.description.split(' ').filter(x => x).length}/150)`}</span>
                  </>}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText
                    }
                  }}
                />
                <VerticalSpacer value={25} />
                <InitiatePhoneInput
                  label="Contact number *"
                  placeholder="Contact number"
                  value={formik.values.contactPhoneNumber}
                  onChange={phone => formik.setFieldValue('contactPhoneNumber', phone)}
                  error={formik.touched?.contactPhoneNumber ? formik.errors.contactPhoneNumber : ''}
                />
                 <HideContainer hideWhen={!apiContactError}>
                  <VerticalSpacer value={10} />
                  <InitiateAlert>
                    {apiContactError}
                  </InitiateAlert>
                </HideContainer>
                <VerticalSpacer value={25} />
                <InitiatePhoneInput
                  label="Admin mobile number *"
                  placeholder="Admin mobile number"
                  value={formik.values.adminPhoneNumber}
                  onChange={phone => formik.setFieldValue('adminPhoneNumber', phone)}
                  error={formik.touched.contactPhoneNumber ? formik.errors.adminPhoneNumber : ''}
                />
                <VerticalSpacer value={25} />
                <FormikTextField
                  name="email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  placeholder="example@gmail.com"
                  label="Email address ( User ID ) *"
                />

                <HideContainer hideWhen={!apiEmailError}>
                  <VerticalSpacer value={10} />
                  <InitiateAlert>
                    {apiEmailError}
                  </InitiateAlert>
                </HideContainer>

                <VerticalSpacer value={25} />

                <FormikTextField
                  name="password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  label="Password*"
                  placeholder="Password"
                />
                <VerticalSpacer value={25} />
                <FormikTextField
                  name="confirmPassword"
                  variant="outlined"
                  fullWidth
                  type="password"
                  label="Confirm Password*"
                  placeholder="Confirm Password"
                />
                <VerticalSpacer value={25} />
                <FormikTextField
                  name="referralsLink"
                  variant="outlined"
                  fullWidth
                  label="Link for referrals"
                  placeholder="Email link here"
                />
                <VerticalSpacer value={25} />
                <FormikTextField
                  name="websiteURL"
                  variant="outlined"
                  fullWidth
                  label="Website url"
                  placeholder="www.example.com"
                />
                <VerticalSpacer value={25} />
                <FormikTextField
                  name="annualTurnover"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <Box mr="6px">£</Box>
                    ),
                  }}
                  label="Annual turnover ( estimate )"
                  placeholder="Annual turnover ( estimate )"
                />
                <VerticalSpacer value={25} />

                <input
                  ref={imageInputRef}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(event) => {
                    const file = event.target?.files?.[0];
                    if (file) {
                      formik.setFieldValue('logo', file);
                    }
                  }}
                />

                <div>

                  <div className={classes.logoTextContainer}>
                    <InitiateLabel>{'Logo'}</InitiateLabel>
                    <label
                      onClick={() => {
                        if (imageInputRef?.current)

                          imageInputRef.current.click();
                      }}
                     >
                       <InitiateLabel>
                      {'Edit'}
                       </InitiateLabel>
                    </label>
                  </div>

                  <div className={classes.addButtonContainer}>
                    <HideContainer hideWhen={formik.values.logo}>
                      <IconButton onClick={() => {
                        if (imageInputRef?.current)

                          imageInputRef.current.click();
                      }}>
                        <ControlPointIcon />
                      </IconButton>
                    </HideContainer>
                    <HideContainer hideWhen={!formik.values.logo}>
                      <img src={
                        formik.values.logo ?
                          URL.createObjectURL(formik.values.logo)
                          :
                          ''
                      } />
                    </HideContainer>
                  </div>

                  <HideContainer hideWhen={!apiLogoError}>
                    <VerticalSpacer value={10} />
                    <InitiateAlert>
                      {apiLogoError}
                    </InitiateAlert>
                  </HideContainer>

                  <VerticalSpacer />

                </div>

              </HideContainer>

              <Typography className={classes.noticeText}>
                {'Fields marked with '}
                <span>{"'*'"}</span>
                {' are mandatory fields.'}
              </Typography>
              <VerticalSpacer />
              <HideContainer hideWhen={step != 1}>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    style={{
                      width: "25px",
                      height: "25px",
                      marginLeft: "-10px"
                    }}
                    checked={formik.values.acceptTerms}
                    icon={
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                      </svg>
                    }
                    data-testId="termCheckbox"
                    checkedIcon={
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#019BE1" fill="#019BE1" stroke-width="1" />
                        <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                      </svg>
                    }
                    disableRipple
                    name="acceptTerms"
                    color="primary"
                    onChange={(e) => formik.setFieldValue('acceptTerms', e.target.checked)}
                  />
                  <Typography className={`${classes.checkboxFont} ${formik.values.acceptTerms && classes.darkColor}`}>
                    {'By creating an account, you agree to Initiate\'s '}
                    <Typography
                      color="primary"
                      variant="caption"
                      data-testId="termButton"
                      style={{
                        cursor: "pointer",
                        fontSize: 'inherit',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setModalValue("Terms & Conditions");
                      }}
                    >
                      {'Terms & Conditions'}
                    </Typography>
                    {' and '}
                    <Typography
                      data-testId="privacyButton"
                      color="primary"
                      variant="caption"
                      style={{
                        cursor: "pointer",
                        fontSize: 'inherit',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setModalValue("Privacy Policy");
                      }}
                    >
                      {'Privacy Policy'}
                    </Typography>
                  </Typography>
                </Box>
              </HideContainer>
              <VerticalSpacer />
              <VerticalSpacer />

              <InitiateButton
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                disabled={isLoading || !formik.dirty || !formik.isValid}
                className={classes.submitBtn}
                loading={isLoading}
              >
                {step == 0 ? 'Next' : 'Register'}
              </InitiateButton>

              <VerticalSpacer />

              <DotsStepper stepsCount={2} activeStep={step} />

            </InitiateThemeProvider>
          </Form>
        }
      </Formik>
    </InitiateThemeProvider>
  );
}



const useStepperStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    justifyContent: 'center',
    background: 'none',
  },
});

export function DotsStepper({
  activeStep,
  stepsCount,
}: {
  activeStep: number,
  stepsCount: number,
}) {
  const classes = useStepperStyles();


  return (
    <MobileStepper
      variant="dots"
      steps={stepsCount}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      nextButton={null}
      backButton={null}
    />
  );
}

