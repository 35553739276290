import React, { Children } from 'react';
// Customizable Area Start
import {
	// Customizable Area Start

	CssBaseline, Container
	// Customizable Area End
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../framework/src/Globals';
//@ts-ignore
import classOuter from "./AccountCreationLogo.module.css"
import images from '../constants/images';
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme) => ({
	mainContainer: {
		// height: "100vh"
	},
	paper: {
		//   marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: "center",

	},
	forminnerdiv: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: "center",
		background: COLORS.white,
	},
	avatar: {
		marginBottom: theme.spacing(1),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	app_logo: {
		height: 100,
		width: 100
	}
}));
// Customizable Area End

function AdminAccountCreationLayout({ children }: any) {
    // Customizable Area Start
    const classes = useStyles();
    // Customizable Area End
    return (
        // Customizable Area Start
        <Container component="main" maxWidth="sm" className={classes.mainContainer}>
            <CssBaseline />
            <div className={classes.paper}>
                <div className={`${classes.forminnerdiv} ${classOuter.innerdivadjust}`}>
                <div className={classes.avatar}>
                <img src={images.app_logo} alt="logo" className={classes.app_logo} />
            </div>
                    {children}
                </div>
            </div>
        </Container>
        // Customizable Area End
    )
}

export default AdminAccountCreationLayout