import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  notSelected: {
    color: 'gray',
  },
});

export function InitiateCheckbox(props: React.ComponentProps<typeof Checkbox> & {
  label: React.ComponentProps<typeof FormControlLabel>['label'],
  formControlLabelProps?: Partial<React.ComponentProps<typeof FormControlLabel>>
}) {

  const { label, formControlLabelProps, ...checkboxProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  const primaryColor = '#019BE1';

  return (
    <FormControlLabel
      {...formControlLabelProps}
      className={clsx(formControlLabelProps?.className, {
        [classes.notSelected]: !checkboxProps.checked
      })}
      control={
        <Checkbox
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
            </svg>
          }
          checkedIcon={
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke={primaryColor} fill={primaryColor} stroke-width="1" />
              <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
            </svg>
          }
          disableRipple
          color="primary"
          {...checkboxProps}
        />
      }
      label={label}
    />
  );
}
