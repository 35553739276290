// Customizable Area Start
import {
  Dashboard,
  PeopleAlt,
  MenuBook,
  ImportContacts,
  PostAdd,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { COLORS } from "../../../framework/src/Globals";
// Customizable Area End

// Customizable Area Start
const dashboard_screens = {
  home: "Home",
  Profile: "Profile",
  Needs: "My Needs",
  Chats: "Chats",
  Pledges: "My Pledges",
  Settings: "Settings",
  SavedSearches: "Saved Searches",
  InvitePeople: "Invite People",
  Feedback: "Feedback",
  ContactUs: "Contact Us",
  TermAndCondition: "Terms & Conditions",
  DeactivateAccount: "Deactivate Account",
  PrivacyPolicy: "Privacy Policy",
  Logout: "Logout",
  AccountDetails: "Account Details",
  MyTaskPreferances: "My Task Preferences",
  EmailNotifications: "Email Notifications",
  NotificationSettings: "Notification Settings",
  MyAvailability: "My Availability",
  Surveys: "Surveys",
};

const admin_dashboard_screens = {
  Dashboard: "Dashboard",
  Organisation: "Organisation",
  Events: "Events",
  Followers: "Followers",
  RegisteredVolunteers: "Registered Users",
  Users: "Users (Internal)",
  Communication: "Communication",
  Surveys: "Surveys",
};

const business_dashboard_screens = {
  Dashboard: 'Dashboard',
  BusinessProfile: 'Business Profile',
  Following: 'Following',
  RegisteredEmployees: 'Registered Employees',
  Communication: 'Communication',
  Surveys: "Surveys",
}
// Customizable Area End

// Customizable Area Start
const homesidebar = [
  {
    id: 0,
    name: dashboard_screens.home,
    icon: require("../../assets/icons/homegray.png"),
    selectedIcon: require("../../assets/icons/homewhite.png"),
  },
  {
    id: 1,
    name: dashboard_screens.Profile,
    icon: require("../../assets/icons/profile.png"),
    selectedIcon: require("../../assets/icons/profilewhite.png"),
  },
  {
    id: 2,
    name: dashboard_screens.Needs,
    icon: require("../../assets/icons/needs.png"),
    selectedIcon: require("../../assets/icons/needswhite.png"),
  },
  {
    id: 3,
    name: dashboard_screens.Chats,
    icon: require("../../assets/icons/chatgray.png"),
    selectedIcon: require("../../assets/icons/chatwhite.png"),
  },
  {
    id: 4,
    name: dashboard_screens.Pledges,
    icon: require("../../assets/icons/pledges.png"),
    selectedIcon: require("../../assets/icons/pledgeswhite.png"),
  },
  {
    id: 5,
    name: dashboard_screens.Settings,
    icon: require("../../assets/icons/settings.png"),
    selectedIcon: require("../../assets/icons/settingswhite.png"),
  },
  {
    id: 6,
    name: dashboard_screens.Surveys,
    icon: require("../../assets/icons/Survey_inactive.png"),
    selectedIcon: require("../../assets/icons/Survey_activePage.png"),
  },
  // {
  //   id: 6,
  //   name: dashboard_screens.Logout,
  //   icon: require("../../assets/icons/logoutGray.png"),
  //   selectedIcon: require("../../assets/icons/logoutwhite.png"),
  // },
];

const bottom_tabs = [
  {
    id: 0,
    name: dashboard_screens.home,
    icon: require("../../assets/icons/homegray.png"),
    selectedIcon: require("../../assets/icons/home.png"),
  },
  {
    id: 1,
    name: dashboard_screens.Pledges,
    icon: require("../../assets/icons/pledges.png"),
    selectedIcon: require("../../assets/icons/pledgesblue.png"),
  },
  {
    id: 2,
    name: dashboard_screens.Needs,
    icon: require("../../assets/icons/needs.png"),
    selectedIcon: require("../../assets/icons/heart.png"),
  },
  {
    id: 3,
    name: dashboard_screens.Chats,
    icon: require("../../assets/icons/chatgray.png"),
    selectedIcon: require("../../assets/icons/chats.png"),
  },
  {
    id: 4,
    name: dashboard_screens.Profile,
    icon: require("../../assets/icons/profile.png"),
    selectedIcon: require("../../assets/icons/profileblue.png"),
  },
];

const businessSidebar = [
  {
    id: 0,
    name: business_dashboard_screens.Dashboard,
    icon: require("../../assets/icons/npDashboard.png"),
    selectedIcon: require("../../assets/icons/npDashboard.png"),
  },
  {
    id: 1,
    name: business_dashboard_screens.BusinessProfile,
    icon: require("../../assets/icons/npOrganizations.png"),
    selectedIcon: require("../../assets/icons/npOrganizations.png"),
  },
  {
    id: 2,
    name: business_dashboard_screens.Following,
    icon: require("../../assets/icons/npFollPng.png"),
    selectedIcon: require("../../assets/icons/npFollPng.png"),
  },
  {
    id: 3,
    name: business_dashboard_screens.RegisteredEmployees,
    icon: require("../../assets/icons/npRegVolunteers.png"),
    selectedIcon: require("../../assets/icons/npRegVolunteers.png"),
  },
  {
    id: 4,
    name: business_dashboard_screens.Communication,
    icon: require("../../assets/icons/npCommPng.png"),
    selectedIcon: require("../../assets/icons/npCommPng.png"),
  },
  {
    id: 5,
    name: business_dashboard_screens.Surveys,
    icon: require("../../assets/icons/Survey_active.png"),
    selectedIcon: require("../../assets/icons/Survey_active.png"),
  }
]

const adminsidebar = [
  {
    id: 0,
    name: admin_dashboard_screens.Dashboard,
    icon: require("../../assets/icons/npDashboard.png"),
    selectedIcon: require("../../assets/icons/npDashboard.png"),
  },
  {
    id: 1,
    name: admin_dashboard_screens.Organisation,
    icon: require("../../assets/icons/npOrganizations.png"),
    selectedIcon: require("../../assets/icons/npOrganizations.png"),
  },
  {
    id: 2,
    name: admin_dashboard_screens.Events,
    icon: require("../../assets/icons/npEvents.png"),
    selectedIcon: require("../../assets/icons/npEvents.png"),
  },
  {
    id: 3,
    name: admin_dashboard_screens.Followers,
    icon: require("../../assets/icons/npFollPng.png"),
    selectedIcon: require("../../assets/icons/npFollPng.png"),
  },
  {
    id: 4,
    name: admin_dashboard_screens.RegisteredVolunteers,
    icon: require("../../assets/icons/npRegVolunteers.png"),
    selectedIcon: require("../../assets/icons/npRegVolunteers.png"),
  },
  {
    id: 5,
    name: admin_dashboard_screens.Users,
    icon: require("../../assets/icons/npUsersPng.png"),
    selectedIcon: require("../../assets/icons/npUsersPng.png"),
  },
  {
    id: 6,
    name: admin_dashboard_screens.Communication,
    icon: require("../../assets/icons/npCommPng.png"),
    selectedIcon: require("../../assets/icons/npCommPng.png"),
  },
  {
    id: 6,
    name: admin_dashboard_screens.Surveys,
    icon: require("../../assets/icons/Survey_active.png"),
    selectedIcon: require("../../assets/icons/Survey_active.png"),
  },
];

const mobileHomeSidebar = [
  {
    id: 0,
    name: dashboard_screens.SavedSearches,
    icon: require("../../assets/icons/savedsearches.png"),
  },
  {
    id: 1,
    name: dashboard_screens.Settings,
    icon: require("../../assets/icons/settingsBlue.png"),
  },
  {
    id: 2,
    name: dashboard_screens.InvitePeople,
    icon: require("../../assets/icons/invitepeople.png"),
  },
  {
    id: 3,
    name: dashboard_screens.Feedback,
    icon: require("../../assets/icons/startblue.png"),
  },
  {
    id: 4,
    name: dashboard_screens.ContactUs,
    icon: require("../../assets/icons/contactus.png"),
  },
  {
    id: 5,
    name: dashboard_screens.TermAndCondition,
    icon: require("../../assets/icons/terms.png"),
  },
  {
    id: 6,
    name: dashboard_screens.PrivacyPolicy,
    icon: require("../../assets/icons/privacypolicywhite.png"),
  },
  {
    id: 7,
    name: dashboard_screens.Logout,
    icon: require("../../assets/icons/logout.png"),
  },
];

const mynonprofit = [
  {
    id: 0,
    name: "Trust dog",
    description: "We help street dogs take care of them.",
    image: require("../../assets/icons/DogsTrust.svg"),
  },
  {
    id: 1,
    name: "Moore Charitable Foundation",
    description: "Moore foundation is a non-profitable.",
    image: require("../../assets/icons/nation.svg"),
  },
  {
    id: 2,
    name: "Notion Donations",
    description: "We are building our nation by helping",
    image: require("../../assets/icons/nation.svg"),
  },
  {
    id: 3,
    name: "Isha Trust",
    description: "Isha trust is non profitable trust that help needy",
    image: require("../../assets/icons/nation.svg"),
  },
  {
    id: 4,
    name: "Always Helping",
    description: "Our aim is help to everyone and always",
    image: require("../../assets/icons/AlwaysHelp.svg"),
  },
];

const DBSClearanceType = [
  {
    id: 0,
    label: "Basic DBS",
  },
  {
    id: 1,
    label: "Standard DBS",
  },
  {
    id: 2,
    label: "Enhanced DBS",
  },
  {
    id: 3,
    label: "Enhanced DBS & BL(Adult)",
  },
  {
    id: 4,
    label: "Enhanced DBS & BL(Child)",
  },
  {
    id: 5,
    label: "Enhanced DBS & BL(Child & Adult)",
  },
  {
    id: 6,
    label: "Scotland-issued(PVG Scheme)",
  },
];

const SettingsMenu = [
  {
    id: 0,
    name: dashboard_screens.AccountDetails,
    icon: require("../../assets/icons/profileblue.png"),
  },
  {
    id: 1,
    name: dashboard_screens.MyTaskPreferances,
    icon: require("../../assets/icons/myTaskPreference.png"),
  },
  {
    id: 2,
    name: dashboard_screens.MyAvailability,
    icon: require("../../assets/icons/clock_blue.png"),
  },
  {
    id: 3,
    name: dashboard_screens.NotificationSettings,
    icon: require("../../assets/icons/bluebellIcon.png"),
  },
  {
    id: 4,
    name: dashboard_screens.InvitePeople,
    icon: require("../../assets/icons/invitepeople.png"),
  },
  {
    id: 5,
    name: dashboard_screens.Feedback,
    icon: require("../../assets/icons/startblue.png"),
  },
  {
    id: 6,
    name: dashboard_screens.ContactUs,
    icon: require("../../assets/icons/contactus.png"),
  },
  {
    id: 7,
    name: dashboard_screens.TermAndCondition,
    icon: require("../../assets/icons/terms.png"),
  },
  {
    id: 8,
    name: dashboard_screens.PrivacyPolicy,
    icon: require("../../assets/icons/privacypolicywhite.png"),
  },
  {
    id: 9,
    name: dashboard_screens.Logout,
    icon: require("../../assets/icons/logout.png"),
  },
];

const SettingsMobileMenu = [
  {
    id: 0,
    name: dashboard_screens.AccountDetails,
    icon: require("../../assets/icons/profileblue.png"),
  },
  {
    id: 1,
    name: dashboard_screens.MyTaskPreferances,
    icon: require("../../assets/icons/myTaskPreference.png"),
  },
  {
    id: 2,
    name: dashboard_screens.MyAvailability,
    icon: require("../../assets/icons/clock_blue.png"),
  },
  {
    id: 3,
    name: dashboard_screens.Pledges,
    icon: require("../../assets/icons/pledgesblue.png"),
  },
  {
    id: 4,
    name: dashboard_screens.NotificationSettings,
    icon: require("../../assets/icons/bluebellIcon.png"),
  },
]

const carosel_constants = [
  {
    id: 0,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Street @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022, 9.00am",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Volunteer",
      color: COLORS.green,
    },
  },
  {
    id: 1,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022, 9.00am",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Donate",
      color: COLORS.blue,
    },
  },
  {
    id: 2,
    image: require("../../assets/images/cardImg.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022, 9.00am",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Attend",
      color: COLORS.purple,
    },
  },
  {
    id: 3,
    image: require("../../assets/images/cardimg1.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022, 9.00am",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Volunteer",
      color: COLORS.green,
    },
  },
  {
    id: 4,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022, 9.00am",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Donate",
      color: COLORS.blue,
    },
  },
];

const past_carosel_constants_ = [
  {
    id: 0,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Street @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Volunteer",
      color: COLORS.green,
    },
  },
  {
    id: 1,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Donate",
      color: COLORS.blue,
    },
  },
  {
    id: 2,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Attend",
      color: COLORS.purple,
    },
  },
  {
    id: 3,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Volunteer",
      color: COLORS.green,
    },
  },
  {
    id: 4,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Donate",
      color: COLORS.blue,
    },
  },
  {
    id: 5,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Street @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Volunteer",
      color: COLORS.green,
    },
  },
  {
    id: 6,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Donate",
      color: COLORS.blue,
    },
  },
  {
    id: 7,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Attend",
      color: COLORS.purple,
    },
  },
  {
    id: 8,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Volunteer",
      color: COLORS.green,
    },
  },
  {
    id: 9,
    image: require("../../assets/images/cardImg2.png"),
    heading: {
      title: "Helping Poor People @ Rose Park",
      icon: "icon",
    },
    cards_date: {
      dates: "21 July 2022",
      icon: "icon",
    },
    subheading: {
      subtitle: "We Care UK",
      icon: "icon",
    },
    location: {
      loc: "Reading Borough ",
      icon: "icon",
    },
    state: {
      name: "Donate",
      color: COLORS.blue,
    },
  },
];


const notificationsAPI = [
  {
    id: 0,
    image: require("../../assets/images/cardImg2.png"),
    title: "You have new connection request from Julia harrison",
    connection: true,
    notificationDate: "21 July 2022",
  },
  {
    id: 1,
    image: require("../../assets/images/cardImg2.png"),
    title: "Funding campaign @Central town",
    description: "To help you get started, we've compiled everything you need to know about this event",
    connection: false,
    notificationDate: "2 July 2022",
  },
  {
    id: 2,
    image: require("../../assets/images/cardImg2.png"),
    title: "Upcoming Event of Always Helping trust",
    description: "We are organizing this events for poor children for the food and required things",
    connection: false,
    notificationDate: "2 July 2022",
  },
  {
    id: 3,
    image: require("../../assets/images/cardImg2.png"),
    title: "New events matching your preferences.",
    description: "It can be difficult to know where to begin when starting this event",
    connection: false,
    notificationDate: "2 July 2022",
  },
  {
    id: 4,
    image: require("../../assets/images/cardImg2.png"),
    title: "New Message From Helen dijal",
    connection: false,
    notificationDate: "2 July 2022",
    tapMessage: true
  },
  {
    id: 5,
    image: require("../../assets/images/cardImg2.png"),
    title: "New events matching your preferences.",
    connection: true,
    notificationDate: "2 July 2022",
  },
  {
    id: 6,
    image: require("../../assets/images/cardImg2.png"),
    title: "New Message From Helen dijal",
    connection: false,
    notificationDate: "2 July 2022",
    tapMessage: true
  },
];

const NonProfitsAffiliations = [
  {
    id: 0,
    image: "https://picsum.photos/70",
    title: "Prostate Cancer uk",
    city: "Bagshot, Gk",
  },
  {
    id: 1,
    image: "https://picsum.photos/70",
    title: "Save the children",
    city: "Didcot, GB",
  },
  {
    id: 2,
    image: "https://picsum.photos/70",
    title: "The institute of Cancer",
    city: "Woking, GB",
  },
  {
    id: 3,
    image: "https://picsum.photos/70",
    title: "The Kennel Club Charitable",
    city: "Ascot, GB",
  },
  {
    id: 4,
    image: "https://picsum.photos/70",
    title: "The Salvation Army",
    city: "Englefiled, GB",
  },
  {
    id: 5,
    image: "https://picsum.photos/70",
    title: "We care Uk",
    city: "Reading, GB",
  },
];

const chatsInformation = [
  {
    id: 0,
    userImage: "https://picsum.photos/70",
    userName: "Zarela Reed",
    userStatus: "Okay",
    time: "9:30PM",
  },
  {
    id: 1,
    userImage: "https://picsum.photos/70",
    userName: "Bernarr Dominik",
    userStatus: "Okay",
    time: "9:30PM",
  },
  {
    id: 2,
    userImage: "https://picsum.photos/70",
    userName: "Alexa Richardson",
    userStatus: "Okay",
    time: "9:30PM",
  },
  {
    id: 3,
    userImage: "https://picsum.photos/70",
    userName: "Ainara Vergara",
    userStatus: "typing...",
    time: "8.00 PM",
  },
  {
    id: 4,
    userImage: "https://picsum.photos/70",
    userName: "Deveeprasad Acharya",
    userStatus: "Okay",
    time: "10.30 PM",
  },
];
const groupChatInformation = [
  {
    id: 0,
    userImage: "https://picsum.photos/70",
    userName: "Rising Funds For Dogs",
    userStatus: "Okay",
    time: "9:30PM",
    subTitle: "Dog's trust"
  },
  {
    id: 1,
    userImage: "https://picsum.photos/70",
    userName: "Helping Poor People",
    userStatus: "Okay",
    time: "8:30PM",
    subTitle: "Torch help club"
  },
  {
    id: 2,
    userImage: "https://picsum.photos/70",
    userName: "Giving Medical Help",
    userStatus: "Okay",
    time: "10:00PM",
    subTitle: "help club"
  },
  {
    id: 3,
    userImage: "https://picsum.photos/70",
    userName: "Making Things for needy",
    userStatus: "3+ typing...",
    time: "8.00 PM",
    subTitle: "Torch help club"
  },
  {
    id: 4,
    userImage: "https://picsum.photos/70",
    userName: "Connect to all",
    userStatus: "Okay",
    time: "10.30 PM",
    subTitle: "Club of people"
  },
];
const _icons = {
  show: true,
  hide: false,
};

const userProfile = {
  userImage:
    "https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&h=152&fit=crop&crop=faces",
  userName: "James Jordan",
  userAddress: "Canterbury, UK",
  userCompanyLogo: require("../../assets/images/microsoft.png"),
  userConnections: {
    connectionImg: "",
    connectionTitle: "Connections",
  },
  followingTitle: "Following",
  registeredTitle: "Registered",
  bioTitle: "Bio",
  bioDes:
    "These ideas should not be random. It always helps to jot down quick points quickly in a rough sheet. arrange them into a logical chronological order that flows in one direction making it easier to read",
};

const statusDetail = [
  {
    id: 1,
    name: "Attended",
    Viewers: "0",
  },
  {
    id: 2,
    name: "Connections",
    Viewers: "0",
  },
  {
    id: 3,
    name: "Following",
    Viewers: "0",
  },
  {
    id: 4,
    name: "Registered",
    Viewers: "0",
  },
];

const following = [
  {
    id: 1,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 2, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 2,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 3,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 4,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 5,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 6,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 7,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 8,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
];

const connections = [
  {
    id: 1,
    name: "Alwasys Helping Trust @offi...",
    subtitle: "Reading,GB",
    status: "Following",
    categories: [1, 2, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 2,
    name: "Alwasys Helping Trust @offi...",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 3,
    name: "Alwasys Helping Trust @offi...",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 4,
    name: "Alwasys Helping Trust @offi...",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 5,
    name: "Alwasys Helping Trust @offi...",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 6,
    name: "Alwasys Helping Trust @offi...",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 7,
    name: "Alwasys Helping Trust @offi...",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
  {
    id: 8,
    name: "Alwasys Helping Trust @offi...",
    status: "Following",
    categories: [1, 3],
    image: "https://picsum.photos/70",
  },
];

// Customizable Area End

const connectionscategorystatus = [
  {
    id: 0,
    label: "Connect",
  },
  {
    id: 1,
    label: "Connected",
  },
  {
    id: 2,
    label: "Pending requests",
  },
  {
    id: 3,
    label: "Awaiting response",
  },
];

const MyTaskPreferances = [
  {
    id: 0,
    label: "Child Minding",
  },
  {
    id: 1,
    label: "Cleaning",
  },
  {
    id: 2,
    label: "Collections & deliveries",
  },
  {
    id: 3,
    label: "Company",
  },
  {
    id: 4,
    label: "Cooking",
  },
  {
    id: 5,
    label: "Dog Sitting",
  },
  {
    id: 6,
    label: "Gardening",
  },
  {
    id: 7,
    label: "Painting",
  },
  {
    id: 8,
    label: "Plumbing",
  },
  {
    id: 9,
    label: "Driving",
  },
  {
    id: 10,
    label: "Mentoring",
  },
];

const homeSearchCategories = [
  {
    id: 1,
    label: "Select all",
  },
  {
    id: 2,
    label: "Cleaning streets",
  },
  {
    id: 3,
    label: "Cleaning beaches",
  },
  {
    id: 4,
    label: "Clothing",
  },
  {
    id: 5,
    label: "Debt Management",
  },
];

const organisationDropDown = [
  {
    id: 1,
    label: "Charity",
  },
  {
    id: 2,
    label: "Faith group",
  },
  {
    id: 3,
    label: "Community group",
  },
  {
    id: 4,
    label: "Neighbourhood group",
  },
];

const eventCategories = [
  {
    id: 1,
    label: "Need",
    value: "need",
  },
  {
    id: 2,
    label: "Project Funding",
    value: "project_funding",
  },
  {
    id: 3,
    label: "Upcoming Activity",
    value: "activity",
  },
];

const VolunteersRegistered = [
  {
    id: 1,
    firstName: "Cardarion Hart",
    lastName: "Tua Manuera",
    emailAddress: "james23@gmail.com",
    DBSType: "Enhanced DBS",
    Lastlogin: "22/12/2022 08:00",
    STATUS: "Pending",
  },
  {
    id: 2,
    firstName: "Indira Shree",
    lastName: "Raaid El Syed",
    emailAddress: "hildonjord3@gmail.com",
    DBSType: "Standard DBS",
    Lastlogin: "22/12/2022 08:00",
    STATUS: "Pending",
  },
  {
    id: 3,
    firstName: "Asaka Chimako",
    lastName: "Jarrett Cawsey",
    emailAddress: "hildonjord3@gmail.com",
    DBSType: "Enhanced DBS",
    Lastlogin: "22/12/2022 08:00",
    STATUS: "Accepted",
  },
  {
    id: 4,
    firstName: "Kari Granleese",
    lastName: "Yi Hanying",
    emailAddress: "hildonjord3@gmail.com",
    DBSType: "Basic DBS",
    Lastlogin: "22/12/2022 08:00",
    STATUS: "Accepted",
  },
  {
    id: 5,
    firstName: "Olivia Eklund",
    lastName: "Jes�s Moruga",
    emailAddress: "hildonjord3@gmail.com",
    DBSType: "Standard DBS",
    Lastlogin: "22/12/2022 08:00",
    STATUS: "Accepted ",
  },
];

const BusinessFollowingData = [
  {
    id: 1,
    npOrg: 'Brighter futures reading',
    orgType: 'Charity',
    location: 'Reading'
  },
  {
    id: 2,
    npOrg: 'Brighter futures reading',
    orgType: 'Faith Group',
    location: 'Reading'
  },
  {
    id: 3,
    npOrg: 'Brighter futures Students',
    orgType: 'Community Group',
    location: 'Reading'
  },
]

const needsTabsConstants = [
  {
    id: 0,
    label: "Active",
    type: "Active",
  },
  {
    id: 1,
    label: "Past",
    type: "Past",
  },
  {
    id: 2,
    label: "Publish new need",
    type: "Publish",
  },
];

const chatItms = [
  {
    key: 1,
    image:
      "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
    type: "",
    msg: "Hi Tim, How are you?",
  },
  {
    key: 2,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
    type: "other",
    msg: "I am fine.",
  },
  {
    key: 3,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
    type: "other",
    msg: "What about you?",
  },
  {
    key: 4,
    image:
      "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
    type: "",
    msg: "Awesome these days.",
  },
  {
    key: 5,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
    type: "other",
    msg: "Finally. What's the plan?",
  },
  {
    key: 6,
    image:
      "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
    type: "",
    msg: "what plan mate?",
  },
  {
    key: 7,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
    type: "other",
    msg: "I'm taliking about the tutorial",
  },
];

const FollowersNP = [
  {
    id: 0,
    attributes: {
      first_name: "Lara Madrigal",
      last_name: "Rey Mibourne",
      location: "Reading",
      last_login: "22/12/2022 08:00",
    },
  },
  {
    id: 1,
    attributes: {
      first_name: "Labeeba Al Amer",
      last_name: "Jana Strassmann",
      location: "Reading",
      last_login: "22/12/2022 08:00",
    }

  },
  {
    id: 2,
    attributes: {
      first_name: "Mattie Blooman",
      last_name: "Lisandro Matos",
      location: "Reading",
      last_login: "22/12/2022 08:00",
    }

  },
  {
    id: 3,
    attributes: {
      first_name: "Fyodor Dyuzhenkov",
      last_name: "Alice Krejov",
      location: "Reading",
      last_login: "22/12/2022 08:00",
    }
  },
  {
    id: 4,
    attributes: {
      first_name: "Vicente de la Cruz",
      last_name: "Pan Feng",
      location: "Reading",
      last_login: "22/12/2022 08:00",
    }
  },
];
const communication = [
  {
    id: 1,
    eventName: "Helping students for study",
    type: "project",
    participants: 10,
    lastMsgRead: 5,
    unRead: 5,
  },
  {
    id: 2,
    eventName: "Planting tress in the town for city",
    type: "Activity",
    participants: 10,
    lastMsgRead: 5,
    unRead: 5,
  },
];
const UserNP = [
  {
    id: 1,
    firstName: "Angu Tamba",
    lastName: "Leo Knight",
    emailAddress: "angutamba@gmail.com",
    role: "Entity_admin",
    lastLogin: "22/12/2022 08:00",
  },
  {
    id: 2,
    firstName: "Angu Tamba",
    lastName: "Leo Knight",
    emailAddress: "angutamba@gmail.com",
    role: "Entity_admin",
    lastLogin: "22/12/2022 08:00",
  },
  {
    id: 3,
    firstName: "Angu Tamba",
    lastName: "Leo Knight",
    emailAddress: "angutamba@gmail.com",
    role: "Entity_admin",
    lastLogin: "22/12/2022 08:00",
  },
];

const event_details_button = new Array(
  {
    id: 1,
    name: "Pledge To Help",
    type: "need",
  },
  {
    id: 2,
    name: "Cancel Pledge",
    type: "need",
  },
  {
    id: 3,
    name: "Pending Pledge",
    type: "need",
  },
  {
    id: 4,
    name: "Confirm",
    type: "activity",
  },
  {
    id: 5,
    name: "Cancel Attend",
    type: "activity",
  },
  {
    id: 6,
    name: "Requested",
    type: "activity",
  },
  {
    id: 7,
    name: "Cancel Requested",
    type: "activity",
  },
  {
    id: 8,
    name: "Donate",
    type: "project_funding",
  }
);

const availability_hours = new Array(
  {
    id: 1,
    hours: "12:00 AM",
    to: "01:00 AM",
  },
  {
    id: 2,
    hours: "01:00 AM",
    to: "02:00 AM",
  },
  {
    id: 3,
    hours: "02:00 AM",
    to: "03:00 AM",
  },
  {
    id: 4,
    hours: "03:00 AM",
    to: "04:00 AM",
  },
  {
    id: 5,
    hours: "04:00 AM",
    to: "05:00 AM",
  },
  {
    id: 6,
    hours: "05:00 AM",
    to: "06:00 AM",
  },
  {
    id: 7,
    hours: "06:00 AM",
    to: "07:00 AM",
  },
  {
    id: 8,
    hours: "07:00 AM",
    to: "08:00 AM",
  },
  {
    id: 9,
    hours: "08:00 AM",
    to: "09:00 AM",
  },
  {
    id: 10,
    hours: "09:00 AM",
    to: "10:00 AM",
  },
  {
    id: 11,
    hours: "10:00 AM",
    to: "11:00 AM",
  },
  {
    id: 12,
    hours: "11:00 AM",
    to: "12:00 AM",
  },
  {
    id: 13,
    hours: "12:00 AM",
    to: "01:00 PM",
  },
  {
    id: 14,
    hours: "01:00 PM",
    to: "02:00 PM",
  },
  {
    id: 15,
    hours: "02:00 PM",
    to: "03:00 PM",
  },
  {
    id: 16,
    hours: "03:00 PM",
    to: "04:00 PM",
  },
  {
    id: 17,
    hours: "04:00 PM",
    to: "05:00 PM",
  },
  {
    id: 18,
    hours: "05:00 PM",
    to: "06:00 PM",
  },
  {
    id: 19,
    hours: "06:00 PM",
    to: "07:00 PM",
  },
  {
    id: 20,
    hours: "07:00 PM",
    to: "08:00 PM",
  },
  {
    id: 21,
    hours: "08:00 PM",
    to: "09:00 PM",
  },
  {
    id: 22,
    hours: "09:00 PM",
    to: "10:00 PM",
  },
  {
    id: 23,
    hours: "10:00 PM",
    to: "11:00 PM",
  },
  {
    id: 24,
    hours: "11:00 PM",
    to: "12:00 AM",
  }
);

const funding_media_icons_chat = {
  id: 3,
  media_icon: require("../../assets/icons/chatswhite.png"),
  media_icon1: require("../../assets/icons/chatsblue.png"),
  name: "chats",
  type: "chats",
};

const funding_media_icons_img = {
  id: 2,
  media_icon: require("../../assets/icons/camerawhite.png"),
  media_icon1: require("../../assets/icons/camerablue.png"),
  name: "camera",
  type: "image",
};

const funding_media_icons_video = {
  id: 1,
  media_icon: require("../../assets/icons/videowhite.png"),
  media_icon1: require("../../assets/icons/videoblue.png"),
  name: "video",
  type: "video",
};

const AdvanceSearchSwipeList = [
  {
    id: 1,
    searchType: "My Custom Search",
    address: "Cleaning streets, Radius: 10 miles, All day"
  },
  {
    id: 2,
    searchType: "According my search",
    address: "Cleaning streets, Radius: 10 miles, All day"
  },
  {
    id: 3,
    searchType: "According my search",
    address: "Cleaning streets, Radius: 10 miles, All day"
  },
  {
    id: 4,
    searchType: "My fav. search",
    address: "Cleaning streets, Radius: 10 miles, All day"
  },
  {
    id: 5,
    searchType: "My Custom Search",
    address: "Cleaning streets, Radius: 10 miles, All day"
  },
  {
    id: 6,
    searchType: "According my search",
    address: "Cleaning streets, Radius: 10 miles, All day"
  },
  {
    id: 7,
    searchType: "According my search",
    address: "Cleaning streets, Radius: 10 miles, All day"
  },
  {
    id: 8,
    searchType: "My fav. search",
    address: "Cleaning streets, Radius: 10 miles, All day"
  },
];

const BasicPasswordValidation = "Please enter password";
const PasswordValidation = "Please enter password that consist of 8 character with one letter capital,one special character"

const ScrolleImage = [
  {
    id: 0,
    image: require("../../assets/images/cardImg2.png"),
  },
  {
    id: 1,
    image: require("../../assets/images/cardImg2.png"),
  },
  {
    id: 2,
    image: require("../../assets/images/cardImg.png"),
  },
  {
    id: 3,
    image: require("../../assets/images/cardImg2.png"),
  },
  {
    id: 4,
    image: require("../../assets/images/cardImg.png"),
  },
  {
    id: 5,
    image: require("../../assets/images/cardImg.png"),
  },
  {
    id: 6,
    image: require("../../assets/images/cardImg2.png"),
  },
  {
    id: 7,
    image: require("../../assets/images/cardImg2.png"),
  }
];
const regExp = {
  reg1: /(^\s*)|(\s*$)/gi,
  reg2: /[ ]{2,}/gi,
  reg3: /\n /
}
// Customizable Area Start
export default {
  regExp,
  ScrolleImage,
  BasicPasswordValidation,
  PasswordValidation,
  AdvanceSearchSwipeList,
  funding_media_icons_chat,
  funding_media_icons_img,
  funding_media_icons_video,
  event_details_button,
  FollowersNP,
  organisationDropDown,
  chatItms,
  admin_dashboard_screens,
  adminsidebar,
  business_dashboard_screens,
  businessSidebar,
  past_carosel_constants_,
  SettingsMenu,
  SettingsMobileMenu,
  dashboard_screens,
  homesidebar,
  bottom_tabs,
  mobileHomeSidebar,
  _icons,
  carosel_constants,
  userProfile,
  mynonprofit,
  DBSClearanceType,
  MyTaskPreferances,
  chatsInformation,
  groupChatInformation,
  statusDetail,
  following,
  connectionscategorystatus,
  connections,
  NonProfitsAffiliations,
  homeSearchCategories,
  VolunteersRegistered,
  needsTabsConstants,
  communication,
  eventCategories,
  UserNP,
  availability_hours,
  notificationsAPI,
  BusinessFollowingData,
};
// Customizable Area End
