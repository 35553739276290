// Customizable Area Start
import * as activePlanActionTypes from "./activePlanActions"

const initialstate={
    activePlan:"Free Trial"
}



const activePlanReducer = (state = initialstate , action: { type: any; payload: { activePlan: any } }) => {
    switch(action.type){
        case activePlanActionTypes.SET_ACTIVE_PLAN:
            return {
                ...state,
                activePlan: action.payload.activePlan
            }
            default:
                return state
    }
}

export default activePlanReducer;
// Customizable Area End