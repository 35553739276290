// @ts-nocheck
import React from "react";
// Customizable Area Start
import PropTypes from "prop-types";
import ContentManagementController, {
    Props, configJSON,
} from "./ContentManagementController";
import { connect } from "react-redux";
import { setSelectedEvents } from "../../../framework/src/redux/eventsInnerTab/eventsInnerTabActions";
import ContentManagement from "./ContentManagement.web";
// @ts-ignore
import styles from "./NpOrganisation.module.css";
import {
    Typography, Container, Button, Input, Paper,
    CardContent, Grid, IconButton, Switch, Checkbox, FormGroup, FormControlLabel, CircularProgress, Modal, Box, Dialog,
} from "@material-ui/core";
import { COLORS } from "../../../framework/src/Globals";
import { icons, constants, images, FONTS } from "../../../components/src/constants";
import { styled } from "@material-ui/styles";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import GooglePlacesAutocomplete,{ geocodeByAddress } from 'react-google-places-autocomplete';
import {
    withStyles,
    createStyles,
    Theme,
    ThemeProvider,
    createTheme
} from "@material-ui/core/styles";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";  
import Select from "react-select";
import { Scrollbar } from "react-scrollbars-custom";
import * as Yup from "yup";
import { Formik } from "formik";

export type CounterProps = { label?: string };

const AntSwitch: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 32,
            height: 18,
            padding: 0,
            display: "flex",
        },
        switchBase: {
            padding: 2,
            color: "white",
            "&$checked": {
                transform: "translateX(14px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: "#359ce2",
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 13,
            height: 13,
            boxShadow: "none",
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 18 / 2,
            opacity: 1,
            backgroundColor: "grey",
        },
        checked: {},
    })
)(Switch);

const theme = createTheme({
    typography: {
        subtitle1: {
            fontFamily: "Rene_Bieder_Milliard_Medium",
        },
        subtitle2: {
            fontFamily: "Rene_Bieder_Milliard_Book",
        },
    },
});
// @ts-ignore
const renderScrollbar = (props) => { return (
        <div style={{ height: 120 }}>
        <style>
          {`.ScrollbarsCustom-Track {
                        width : 2px !important;
                        margin-right : 10px !important

              }`}
        </style>
        <Scrollbar>{props.children}</Scrollbar>
      </div>
    );
  };



// Customizable Area End

export class NpOrganisationWeb extends ContentManagementController {
    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    };

    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start

    validationSchema = () => {
        let postcodeRegexp = /^[A-Z]{1,2}\d{1,2}[A-Z]{0,2}\s?\d{1,2}[A-Z]{2}$/;
        const today = new Date();
        const thirteenYearsAgo = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());

        const minimumAgeValidator = (value: string | undefined) => {
            if (!value) {
                return true;
            }
            const parsedDate = new Date(value);
            return parsedDate <= thirteenYearsAgo;
        };
        return Yup.object().shape({
            bank_account_number: Yup.string().required('Bank account number is required.').nullable(),
            bank_code: Yup.string().length(6, 'Bank code must be 6 characters long').required('Bank code is required').nullable(),
            street: Yup.string().required('Street is required'),
            country: Yup.string().required('Country is required'),
            town: Yup.string().required('City is required').nullable(),
            post_code: Yup.string().required('Postcode is required').trim().min(5, 'Postcode must be at least 5 characters').max(10, 'Postcode must be at most 10 characters').matches(postcodeRegexp, 'Invalid postcode format'),
            date: Yup.string().required('Date is required').test('minimumAge', 'Must be at least 13 years old', minimumAgeValidator).nullable(),
            first_name: Yup.string().required('First name is required').matches(/^[a-zA-Z]+$/, 'Only alphabets allowed').nullable(),
            last_name: Yup.string().required('Last name is required').matches(/^[a-zA-Z]+$/, 'Only alphabets allowed').nullable(),
            website_url: Yup.string().matches(/^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9_\-.]+\.[a-zA-Z]{2,}$/, 'Invalid URL format'),
        });
    }

    normalValidationSchema = () => {
        return Yup.object().shape({
            street: Yup.string().required('Street is required'),
            country: Yup.string().required('Country is required'),
            town: Yup.string().required('City is required').nullable(),
            post_code: Yup.string().required('Postcode is required').trim().min(5, 'Postcode must be at least 5 characters').max(10, 'Postcode must be at most 10 characters')
        });
    }
		
    getErrorMessage = (touched: {[field: string]: boolean}, errors: {[field: string]: string | undefined}, value: string) => { 
        return touched[value] && errors[value] &&
        <div className="text-danger" style={{color: "red"}}>{errors[value]}</div>
    }

    OrganisationAccountHolder() {
        return (
                <Typography variant="subtitle1" style={{ marginBottom: 0 }} className={styles.labelWhoareWe}>ACCOUNT HOLDER DETAILS</Typography>
        )
    }

    OrganisationBankDetails() {
        return (
                <Typography variant="subtitle1" style={{ marginBottom: 0 }} className={styles.labelWhoareWe}>BANK DETAILS</Typography>
        )
    }

    openRedirectionModal = () => {
        return (
            <Dialog
                className="dialogWrapDonation"
                open={this.state.openRedirectionModal}
                data-testId="modal-close"
                onClose={() => this.setState({ openRedirectionModal: false })}>
                <DialogWrapper>
                    <div className="dialogWrapper">
                        <div 
                            data-testId="close-btn"
                            onClick={this.handleRedirectionModalClose} 
                            className="closeIcon">
                                X
                            </div>
                        <div className="dialogWrapperInner">
                            <img src={icons.successIcon} alt="success" />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Typography className={`${styles.userName} ${styles.message} titleModal`}>
                                Congratulation!! 
                            </Typography>

                            <Typography className="descriptionModal">
                            Your account is enabled, <a target="_blank" href={`${this.state.redirectionUrl}`}>Click here</a> to get onboarded on stripe and receive the donations!!
                            </Typography>
                        </div>
                    </div>
                </DialogWrapper>
            </Dialog>
        );
    };

    OrganisationName() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={styles.label}>Name of organisation</Typography>
                <br />
                <Input
                    data-testId='orgName'
                    name="organisation_name"
                    className={styles.textInput}
                    disableUnderline
                    placeholder="Name of Organisation"
                    value={this.state.NpOrganizationDetailsData?.organisation_name}
                    onChange={(e) => this.handleTextFieldChange(e)}
                    readOnly={localStorage.getItem('np_user_role') === 'User' ? true : false}
                />
                {this.state.NpOrgErrors.organisation_name && <div style={{ color: 'red' }}>Please enter organisation name</div>}
            </div>
        )
    }

    OrganisationType() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={styles.label}>Organisation type *</Typography>

                <div
                    style={{
                        border: `${this.state.NpOrganizationDropdown.organizationOptins
                            ? "1px solid  #3696C3"
                            : "1px solid lightgrey"
                            }`,
                        width: "50%",
                        borderRadius: 10,
                        borderBottom: `${!this.state.NpOrganizationDropdown.organizationOptins
                            ? "1px solid lightgrey"
                            : "1px solid  #3696C3"
                            }`,
                        height: '54px',
                    }}
                >
                    <Select
                        onMenuOpen={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, organizationOptins: true },
                            });
                        }}
                        onMenuClose={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, organizationOptins: false },
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#009BE2",
                                primary: "dodgerblue",
                            },
                        })}
                        components={{
                            MenuList: 
                            
                            renderScrollbar
                        }}
                        styles={{
                            placeholder: (base) => {
                                return {
                                    ...base,
                                    color: "#a4a3a4",
                                };
                            },
                            menu: (base) => {
                                return {
                                    ...base,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    marginTop: 0,
                                    border: "1px solid rgba(54,150,195)",
                                    borderRadius: '0px 0px 12px 12px',
                                    borderTop: "none",
                                    boxShadow: "none",
                                    color: "lightgrey",
                                    paddingTop: 5,
                                };
                            },
                            dropdownIndicator: (base, state) => {
                                return {
                                    ...base,
                                    color: "dodgerblue",
                                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                                };
                            },
                            control: (base, state) => {
                                return {
                                    ...base,
                                    padding: 10,
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    height: "54px",
                                    borderRadius: state.isFocused ? "10px " : "10px",
                                    border: `${this.state.NpOrganizationDropdown.organizationOptins
                                        ? "1px solid  #009BE2"
                                        : "1px solid lightgrey "
                                        }`,
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    "&:hover": {
                                        borderBottom: `${this.state.NpOrganizationDropdown.organizationOptins
                                            ? "1px solid  #009BE2"
                                            : "1px solid lightgrey"
                                            }`,
                                    },
                                };
                            },
                            indicatorSeparator: (styles) => ({
                                ...styles,
                                display: "none",
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                color: this.dropdownOptionColor(state.isSelected),
                                backgroundColor: state.isSelected
                                    ? "#009BE2"
                                    : "inherit",
                                "&:hover": {
                                    backgroundColor: "#009BE2",
                                    color: "white",
                                },
                            }),
                            menuList: (base) => {
                                return {
                                    ...base,
                                    marginTop: 15,
                                    position: "relative",
                                    top: "-5px",
                                    zIndex: 1,
                                    color: "grey",
                                };
                            },
                        }}
                        name="organisation_type"
                        data-testId='orgTypes'
                        placeholder={"Select Organisation type"}
                        isSearchable={false}
                        isClearable={false}
                        isRtl={false}
                        options={this.state.orgTypeOptions}
                        value={this.state.orgTypeOptions.filter((it: any) => it.value === this.state.NpOrganizationDetailsData?.organisation_type)}
                        className={styles.selectInput}
                        isDisabled={localStorage.getItem('np_user_role') === 'User' ? true : false}
                        onChange={(e: any) => {
                            this.handleDropdownFeildChange(e)
                        }}
                    >

                    </Select>
                </div>
            </div>
        )
    }

    OrganisationEmail() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={styles.label}>Email address</Typography>
                <br />
                <Input
                    data-testId='orgEmail'
                    className={styles.textInput}
                    disableUnderline
                    placeholder="Email Address"
                    name="email"
                    value={this.state.NpOrganizationDetailsData?.email}
                    onChange={(e) => this.validateEmail(e)}
                    disabled
                />
                {this.state.NpOrgErrors.email && <div style={{ color: 'red' }}>Please enter email address</div>}
            </div>
        )
    }

    OrganisationDiscription() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={styles.label}>
                    Brief description of organisation
                </Typography>
                <br />
                <Input
                    data-testId='orgDisc'
                    name="description"
                    multiline
                    rows={6}
                    className={styles.multiLineInput}
                    placeholder="Provide a general description visible to all users on the platform."
                    disableUnderline
                    value={this.state.NpOrganizationDetailsData?.description}
                    onChange={(e) => this.handleTextFieldChange(e)}
                    readOnly={localStorage.getItem('np_user_role') === 'User' ? true : false}
                />
                {this.state.NpOrgErrors.description && <div style={{ color: 'red' }}>Please enter description of organisation</div>}
            </div>
        )
    }

    OrganisationWhoAreWeLogo() {
        return (
            <Typography variant="subtitle1" style={{ marginBottom: 0 }} className={styles.labelWhoareWe}>WHO ARE WE</Typography>
        )
    }

    OrganisationCausee() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" style={{ marginTop: 0 }} className={styles.label}>Cause</Typography>
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 26,
                        border: `${this.state.NpOrganizationDropdown.causeOptions
                            ? "1px solid  #3696C3"
                            : "1px solid lightgrey"
                            }`,
                        width: "38.15%",
                        borderRadius: 10,
                        borderBottom: `${!this.state.NpOrganizationDropdown.causeOptions
                            ? "1px solid lightgrey"
                            : "1px solid #3696C3"
                            }`,
                        height: `${!this.state.NpOrganizationDropdown.causeOptions
                            ? "54px"
                            : "185px"
                            }`,
                    }}
                >
                    <Select
                        onMenuOpen={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, causeOptions: true },
                            });
                        }}
                        onMenuClose={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, causeOptions: false },
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#009BE2",
                                primary: "dodgerblue",
                            },
                        })}
                        styles={{
                            placeholder: (base) => {
                                return {
                                    ...base,
                                    color: "#a4a3a4",
                                };
                            },
                            menu: (base) => {
                                return {
                                    ...base,
                                    border: "none",
                                    marginTop: 0,
                                    borderTop: "none",
                                    boxShadow: "none",
                                    color: "lightgrey",
                                    paddingTop: 5,
                                    // borderTopLeftRadius: "0px",
                                    // borderTopRightRadius: "0px",
                                    "&:hover": {
                                        borderRadius: 12,
                                    },
                                };
                            },
                            dropdownIndicator: (base, state) => {
                                return {
                                    ...base,
                                    color: "dodgerblue",
                                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                                };
                            },
                            control: (base, state) => {
                                return {
                                    ...base,
                                    padding: 10,
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    height: "54px",
                                    borderRadius: state.isFocused ? "10px " : "10px",
                                    border: `${this.state.NpOrganizationDropdown.causeOptions
                                        ? "1px solid  #009BE2"
                                        : "1px solid lightgrey "
                                        }`,
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    "&:hover": {
                                        borderBottom: `${this.state.NpOrganizationDropdown.causeOptions
                                            ? "1px solid  #009BE2"
                                            : "1px solid lightgrey"
                                            }`,
                                    },
                                };
                            },
                            indicatorSeparator: (styles) => ({
                                ...styles,
                                display: "none",
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                color: this.dropdownOptionColor(state.isSelected),
                                backgroundColor: state.isSelected
                                    ? "#009BE2"
                                    : "inherit",
                                "&:hover": {
                                    backgroundColor: "#009BE2",
                                    color: "white",
                                },
                            }),
                            menuList: (base) => {
                                return {
                                    ...base,
                                    "::-webkit-scrollbar": {
                                        width: "2px",
                                        height: "0px",
                                    },
                                    "::-webkit-scrollbar-track": {
                                        background: "#f1f1f1"
                                    },
                                    "::-webkit-scrollbar-thumb": {
                                        background: "#888"
                                    },
                                    "::-webkit-scrollbar-thumb:hover": {
                                        background: "#555"
                                    },
                                    position: "relative",
                                    top: "-5px",
                                    zIndex: -1,
                                    color: "grey",
                                    height: 120
                                };
                            },
                        }}
                        components={{ MenuList: renderScrollbar
                        }}
                        name="cause"
                        data-testId='orgCause'
                        placeholder={"Select from drop down menu"}
                        isSearchable={false}
                        isClearable={false}
                        isRtl={false}
                        options={this.state.causeTypeOptions}
                        className={styles.selectInput}
                        value={this.state.NpOrganizationDetailsData?.cause === null ? null : this.state.causeTypeOptions.filter((it: any) => it.value === this.state.NpOrganizationDetailsData?.cause)}
                        onChange={(e: any) => {
                            this.handleDropdownFeildChange(e)
                        }}
                        isDisabled={localStorage.getItem('np_user_role') === 'User' ? true : false}
                    >
                    </Select>
                </div>
            </div>
        )
    }

    OrganisationWhoWeHelp() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={`${styles.label} ${styles.optionsLabel}`}>Who we help</Typography>
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 24,
                        border: `${this.state.NpOrganizationDropdown.helpOptions
                            ? "1px solid  #3696C3"
                            : "1px solid lightgrey"
                            }`,
                        width: "38.15%",
                        borderRadius: 10,
                        borderBottom: `${!this.state.NpOrganizationDropdown.helpOptions
                            ? "1px solid lightgrey"
                            : "1px solid #3696C3"
                            }`,
                        height: `${!this.state.NpOrganizationDropdown.helpOptions
                            ? "54px"
                            : "185px"
                            }`,
                    }}
                >
                    <Select
                        onMenuOpen={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, helpOptions: true },
                            });
                        }}
                        onMenuClose={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, helpOptions: false },
                            });
                        }}
                        components={{
                            MenuList: renderScrollbar }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#009BE2",
                                primary: "dodgerblue",
                            },
                        })}
                        styles={{
                            placeholder: (base) => {
                                return {
                                    ...base,
                                    color: "#a4a3a4",
                                };
                            },
                            menu: (base) => {
                                return {
                                    ...base,
                                    border: "none",
                                    marginTop: 0,
                                    borderTop: "none",
                                    boxShadow: "none",
                                    color: "lightgrey",
                                    paddingTop: 5,
                                    // borderTopLeftRadius: "0px",
                                    // borderTopRightRadius: "0px",
                                    "&:hover": {
                                        borderRadius: 12,
                                    },
                                };
                            },
                            dropdownIndicator: (base, state) => {
                                return {
                                    ...base,
                                    color: "dodgerblue",
                                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                                };
                            },
                            control: (base, state) => {
                                return {
                                    ...base,
                                    padding: 10,
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    height: "54px",
                                    borderRadius: state.isFocused ? "10px " : "10px",
                                    border: `${this.state.NpOrganizationDropdown.helpOptions
                                        ? "1px solid  #009BE2"
                                        : "1px solid lightgrey "
                                        }`,
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    "&:hover": {
                                        borderBottom: `${this.state.NpOrganizationDropdown.helpOptions
                                            ? "1px solid  #009BE2"
                                            : "1px solid lightgrey"
                                            }`,
                                    },
                                };
                            },
                            indicatorSeparator: (styles) => ({
                                ...styles,
                                display: "none",
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                color: this.dropdownOptionColor(state.isSelected),
                                backgroundColor: state.isSelected
                                    ? "#009BE2"
                                    : "inherit",
                                "&:hover": {
                                    backgroundColor: "#009BE2",
                                    color: "white",
                                },
                            }),
                            menuList: (base) => {
                                return {
                                    ...base,
                                    position: "relative",
                                    top: "-5px",
                                    zIndex: -1,
                                    color: "grey",
                                    height: 120
                                };
                            },
                        }}
                        name="who_we_help"
                        data-testId='orgHelp'
                        placeholder={"Select from drop down menu"}
                        isSearchable={false}
                        isClearable={false}
                        isRtl={false}
                        options={this.state.helpTypeOptions}
                        className={styles.selectInput}
                        value={this.state.NpOrganizationDetailsData?.who_we_help === null ? null : this.state.helpTypeOptions.filter((it: any) => it.value === this.state.NpOrganizationDetailsData?.who_we_help)}
                        onChange={(e: any) => {
                            this.handleDropdownFeildChange(e)
                        }}
                        isDisabled={localStorage.getItem('np_user_role') === 'User' ? true : false}
                    >

                    </Select>
                </div>
            </div>
        )
    }

    OrganisationWhatWeProvide() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={`${styles.label} ${styles.optionsLabel}`}>What we provide/do</Typography>
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 22,
                        border: `${this.state.NpOrganizationDropdown.provideOptions
                            ? "1px solid  #3696C3"
                            : "1px solid lightgrey"
                            }`,
                        width: "38.15%",
                        borderRadius: 10,
                        borderBottom: `${!this.state.NpOrganizationDropdown.provideOptions
                            ? "1px solid lightgrey"
                            : "1px solid #3696C3"
                            }`,
                        height: `${!this.state.NpOrganizationDropdown.provideOptions
                            ? "54px"
                            : "185px"
                            }`,
                    }}
                >
                    <Select
                        onMenuOpen={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, provideOptions: true },
                            });
                        }}
                        components={{MenuList:
                            
                            
                            renderScrollbar
                          }}
                        onMenuClose={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, provideOptions: false },
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#009BE2",
                                primary: "dodgerblue",
                            },
                        })}
                        styles={{
                            placeholder: (base) => {
                                return {
                                    ...base,
                                    color: "#a4a3a4",
                                };
                            },
                            menu: (base) => {
                                return {
                                    ...base,
                                    border: "none",
                                    marginTop: 0,
                                    borderTop: "none",
                                    boxShadow: "none",
                                    color: "lightgrey",
                                    paddingTop: 5,
                                    // borderTopLeftRadius: "0px",
                                    // borderTopRightRadius: "0px",
                                    "&:hover": {
                                        borderRadius: 12,
                                    },
                                };
                            },
                            dropdownIndicator: (base, state) => {
                                return {
                                    ...base,
                                    color: "dodgerblue",
                                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                                };
                            },
                            control: (base, state) => {
                                return {
                                    ...base,
                                    padding: 10,
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    height: "54px",
                                    borderRadius: state.isFocused ? "10px " : "10px",
                                    border: `${this.state.NpOrganizationDropdown.provideOptions
                                        ? "1px solid  #009BE2"
                                        : "1px solid lightgrey "
                                        }`,
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    "&:hover": {
                                        borderBottom: `${this.state.NpOrganizationDropdown.provideOptions
                                            ? "1px solid  #009BE2"
                                            : "1px solid lightgrey"
                                            }`,
                                    },
                                };
                            },
                            indicatorSeparator: (styles) => ({
                                ...styles,
                                display: "none",
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                color: this.dropdownOptionColor(state.isSelected),
                                backgroundColor: state.isSelected
                                    ? "#009BE2"
                                    : "inherit",
                                "&:hover": {
                                    backgroundColor: "#009BE2",
                                    color: "white",
                                },
                            }),
                            menuList: (base) => {
                                return {
                                    ...base,
                                    height: 120,
                                    position: "relative",
                                    top: "-5px",
                                    zIndex: -1,
                                    color: "grey",
                                };
                            },
                        }}
                        name="what_we_provide"
                        data-testId='orgProvider'
                        placeholder={"Select from drop down menu"}
                        isSearchable={false}
                        isClearable={false}
                        isRtl={false}
                        options={this.state.providerTypeOptions}
                        className={styles.selectInput}
                        value={this.state.NpOrganizationDetailsData?.what_we_provide === null ? null : this.state.providerTypeOptions.filter((it: any) => it.value === this.state.NpOrganizationDetailsData?.what_we_provide)}
                        onChange={(e: any) => {
                            this.handleDropdownFeildChange(e)
                        }}
                        isDisabled={localStorage.getItem('np_user_role') === 'User' ? true : false}
                    >

                    </Select>
                </div>
            </div>
        )
    }

    OrganisationWhere() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={`${styles.label} ${styles.optionsLabel}`}>Where we operate</Typography>
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 20,
                        border: `${this.state.NpOrganizationDropdown.whereOptions
                            ? "1px solid  #3696C3"
                            : "1px solid lightgrey"
                            }`,
                        width: "38.15%",
                        borderRadius: 10,
                        borderBottom: `${!this.state.NpOrganizationDropdown.whereOptions
                            ? "1px solid lightgrey"
                            : "1px solid #3696C3"
                            }`,
                        height: `${!this.state.NpOrganizationDropdown.whereOptions
                            ? "54px"
                            : "185px"
                            }`,
                    }}
                >
                    <Select
                        onMenuOpen={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, whereOptions: true },
                            });
                        }}
                        onMenuClose={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, whereOptions: false },
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#009BE2",
                                primary: "dodgerblue",
                            },
                        })}
                        styles={{
                            placeholder: (base) => {
                                return {
                                    ...base,
                                    color: "#a4a3a4",
                                };
                            },
                            menu: (base) => {
                                return {
                                    ...base,
                                    border: "none",
                                    marginTop: 0,
                                    borderTop: "none",
                                    boxShadow: "none",
                                    color: "lightgrey",
                                    paddingTop: 5,
                                    // borderTopLeftRadius: "0px",
                                    // borderTopRightRadius: "0px",
                                    "&:hover": {
                                        borderRadius: 12,
                                    },
                                };
                            },
                            dropdownIndicator: (base, state) => {
                                return {
                                    ...base,
                                    color: "dodgerblue",
                                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                                };
                            },
                            control: (base, state) => {
                                return {
                                    ...base,
                                    padding: 10,
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    height: "54px",
                                    borderRadius: state.isFocused ? "10px " : "10px",
                                    border: `${this.state.NpOrganizationDropdown.whereOptions
                                        ? "1px solid  #009BE2"
                                        : "1px solid lightgrey "
                                        }`,
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    "&:hover": {
                                        borderBottom: `${this.state.NpOrganizationDropdown.whereOptions
                                            ? "1px solid  #009BE2"
                                            : "1px solid lightgrey"
                                            }`,
                                    },
                                };
                            },
                            indicatorSeparator: (styles) => ({
                                ...styles,
                                display: "none",
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                color: this.dropdownOptionColor(state.isSelected),
                                backgroundColor: state.isSelected
                                    ? "#009BE2"
                                    : "inherit",
                                "&:hover": {
                                    backgroundColor: "#009BE2",
                                    color: "white",
                                },
                            }),
                            menuList: (base) => {
                                return {
                                    ...base,
                                    position: "relative",
                                    top: "-5px",
                                    zIndex: -1,
                                    color: "grey",
                                    height: 120
                                };
                            },
                        }}
                        name="where"
                        data-testId='orgWhere'
                        placeholder={"Select from drop down menu"}
                        isSearchable={false}
                        isClearable={false}
                        isRtl={false}
                        components={{ MenuList:renderScrollbar }}
                        options={this.state.whereTypeOptions}
                        className={styles.selectInput}
                        value={this.state.NpOrganizationDetailsData?.where === null ? null : this.state.whereTypeOptions.filter((it: any) => it.value === this.state.NpOrganizationDetailsData?.where)}
                        onChange={(e: any) => {
                            this.handleDropdownFeildChange(e)
                        }}
                        isDisabled={localStorage.getItem('np_user_role') === 'User' ? true : false}
                    >

                    </Select>
                </div>
            </div>
        )
    }


    OrganisationAnnualTurnover() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={styles.label}>Annual turnover ( estimate )</Typography>
                <br />
                <Input
                    id="Annual-turnover-input"
                    name="annual_turn_over"
                    className={styles.textInput}
                    disableUnderline
                    placeholder="Annual turnover ( estimate )"
                    startAdornment={<Box mr="6px">£</Box>}
                    value={this.state.NpOrganizationDetailsData?.annual_turn_over ?? ""}
                    onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                            this.handleTextFieldChange(e);
                        }
                    }}
                />
            </div>
        )
    }

    OrganisationContactNo() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={styles.label}>Contact number</Typography>
                <br />
                <Input
                    data-testId='orgContactNo'
                    type="number"
                    name='contact_number'
                    className={styles.textInput}
                    disableUnderline
                    readOnly={localStorage.getItem('np_user_role') === 'User' ? true : false}
                    placeholder="Contact number"
                    value={this.state.NpOrganizationDetailsData?.contact_number}
                    onChange={(e) => this.handleTextFieldChange(e)}
                    onKeyDown={(e: any) => (e.key === "ArrowUp" || e.key === "ArrowDown") ? e.preventDefault() : undefined}
                    startAdornment={this.state.NpOrganizationDetailsData?.contact_number?.length > 0 && this.state.NpOrganizationDetailsData?.contact_number !== null && '+ '}
                />
                {this.state.NpOrgErrors.contact_number && <div style={{ color: 'red' }}>Please enter valid contact number</div>}
            </div>
        )
    }

    OrganisationNoOfFollowers() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle2" className={styles.label}>Number of followers</Typography>
                <br />
                <Input
                    data-testId='orgFollowers'
                    className={styles.textInput}
                    value={this.state.NpOrganizationDetailsData?.followers}
                    disableUnderline
                    placeholder="Number of followers"
                    readOnly
                />
                <br />
                <div style={{ marginTop: 10 }}>
                    <small style={{ color: '#B4B6B5', padding: 5 }}>Read only</small>
                </div>
            </div>
        )
    }

    OrganisationWebSiteUrl = (setFieldValue: (field: string, value: unknown) => Promise<void | FormikErrors<MyFormValues>>, touched: {[field: string]: boolean}, errors: {[field: string]: string | undefined}) => {
        return (
                <div className={styles.divWrapper}>
                        <Typography variant="subtitle2" className={`${styles.label} ${styles.optionsLabel}`}>Website url</Typography>
                        <br />
                        <Input
                                data-testId='orgWebsiteUrl'
                                name="website_url"
                                className={styles.textInput}
                                disableUnderline
                                placeholder="Enter website Url"
                                value={this.state.website_url}
                                onChange={(event) => {
                                    setFieldValue("website_url", event.target.value);
                                    this.setState({
                                        website_url: event.target.value,
                                    })
                                }}
                                readOnly={localStorage.getItem('np_user_role') === 'User' }
                            />
                        <br />
                        {this.getErrorMessage(touched, errors, "website_url")}
                </div>
        )
    }

    getAddressDetails = (results: google.maps.GeocoderAddressComponent[]) =>  {
        const addressComponents = {
          city: "",
          postcode: "",
          country: "",
        };
    
        for (const component of results) {
          if (component.types.includes('postal_code')) {
            addressComponents.postcode = component.long_name;
          } else if (!addressComponents.city && (component.types.includes('postal_town') || component.types.includes("administrative_area_level_2") || component.types.includes('administrative_area_level_1'))) {
            addressComponents.city = component.long_name;
          } else if (component.types.includes('country')) {
            addressComponents.country = component.long_name;
          }
        }
        return addressComponents;
    }

    addressInfo = (setFieldValue: (field: string, value: unknown) => Promise<void | FormikErrors<MyFormValues>>, touched: {[field: string]: boolean}, errors: {[field: string]: string | undefined}) => {
        return (
            <>
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        Street
                    </Typography>
                    <br />
                    <Box width="50%" className="address-location np-profile">
                        <GooglePlacesAutocomplete
                            data-testId="street"
                            apiKey={configJSON.mapsAPIKey}
                            debounce={500}
                            selectProps={{
                            value: this.state.street ? [{ label: this.state.street, value: this.state.street }] : undefined,
                            isClearable: true,
                            noOptionsMessage: () => null,
                            placeholder: "Enter street",
                            classNamePrefix: "react-select",
                            onChange: async (event) => {
                                const value = event?.value?.description ?? "";
                                setFieldValue("street", value);
                                setFieldValue("town", "");
                                setFieldValue("country", "");
                                setFieldValue("post_code", "");
                                this.setState({ 
                                    street: value,
                                    town: "",
                                    country: "",
                                    post_code: ""
                                });
                                geocodeByAddress(value)
                                .then(results => {
                                    const { postcode, city, country } = this.getAddressDetails(results[0].address_components);
                                    setFieldValue("town", city);
                                    setFieldValue("country", country);
                                    setFieldValue("post_code", postcode);
                                    this.setState({ 
                                        town: city,
                                        country: country,
                                        post_code: postcode
                                    });
                                });
                            }
                            }}
                        />
                    </Box>
                    {this.getErrorMessage(touched, errors, "street")}
                </div>
    
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        Town
                    </Typography>
                    <br />
                    <Input
                        data-testId="town"
                        className={styles.textInput}
                        value={this.state.town}
                        disableUnderline
                        placeholder="Enter town"
                        onChange={(event) => {
                            setFieldValue("town", event.target.value);
                            this.setState({
                                town: event.target.value,
                            });
                        }}
                    />
                    {this.getErrorMessage(touched, errors, "town")}
                </div>
    
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        Country
                    </Typography>
                    <br />
                    <Input
                        data-testId="country"
                        className={styles.textInput}
                        value={this.state.country}
                        disableUnderline
                        placeholder="Enter country"
                        onChange={(event) => {
                            setFieldValue("country", event.target.value);
                            this.setState({
                                country: event.target.value,
                            });
                        }}
                    />
                    {this.getErrorMessage(touched, errors, "country")}
                </div>
    
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        Post code
                    </Typography>
                    <br />
                    <Input
                        data-testId="postCode"
                        className={styles.textInput}
                        value={this.state.post_code}
                        disableUnderline
                        placeholder="Enter post code"
                        onChange={(event) => {
                            setFieldValue("post_code", event.target.value);
                            this.setState({
                                post_code: event.target.value,
                            });
                        }}
                    />
                    {this.getErrorMessage(touched, errors, "post_code")}
                </div>
            </>
        );
    };

    renderBankDetails = (setFieldValue: (field: string, value: unknown) => Promise<void | FormikErrors<MyFormValues>>, touched: {[field: string]: boolean}, errors: {[field: string]: string | undefined}) => {
        return (
            <>
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        Bank account number
                    </Typography>
                    <br />
                    <Input
                        className={styles.textInput}
                        disableUnderline
                        data-testId="bank_account_number"
                        placeholder="Enter bank account number"
                        value={this.state.bank_account_number}
                        onChange={(event) => {
                            setFieldValue("bank_account_number", event.target.value);
                            this.setState({ bank_account_number: event.target.value });
                        }}
                    />
                    {this.getErrorMessage(touched, errors, "bank_account_number")}
                </div>
    
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        Bank code
                    </Typography>
                    <br />
                    <Input
                        data-testId="bank_code"
                        className={styles.textInput}
                        disableUnderline
                        placeholder="Enter bank code"
                        value={this.state.bank_code}
                        onChange={(event) => {
                            setFieldValue("bank_code", event.target.value);
                            this.setState({ bank_code: event.target.value });
                        }}
                    />
                    {this.getErrorMessage(touched, errors, "bank_code")}
                </div>
    
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        Merchant code
                    </Typography>
                    <br />
                    <Input
                        data-testId="mcc"
                        className={styles.textInput}
                        disableUnderline
                        placeholder="Enter merchant code"
                        value={this.state.mcc}
                        onChange={(event) => {
                            setFieldValue("mcc", event.target.value);
                            this.setState({
                                mcc: event.target.value,
                            });
                        }}
                    />
                    {this.getErrorMessage(touched, errors, "mcc")}
                </div>
            </>
        );
    };

    accountHolderInfo = (setFieldValue: (field: string, value: unknown) => Promise<void | FormikErrors<MyFormValues>>, touched: {[field: string]: boolean}, errors: {[field: string]: string | undefined}) => {
        return (
            <>
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        First name
                    </Typography>
                    <br />
                    <Input
                        data-testId="first_name"
                        className={styles.textInput}
                        value={this.state.first_name}
                        disableUnderline
                        placeholder="Enter first name"
                        onChange={(event) => {
                            setFieldValue("first_name", event.target.value);
                            this.setState({
                                first_name: event.target.value,
                            });
                        }}
                    />
                    {this.getErrorMessage(touched, errors, "first_name")}
                </div>
    
                <div className={styles.divWrapper}>
                    <Typography variant="subtitle2" className={styles.label}>
                        Last name
                    </Typography>
                    <br />
                    <Input
                        data-testId="last_name"
                        className={styles.textInput}
                        value={this.state.last_name}
                        disableUnderline
                        placeholder="Enter last name"
                        onChange={(event) => {
                            setFieldValue("last_name", event.target.value);
                            this.setState({
                                last_name: event.target.value,
                            });
                        }}
                    />
                    {this.getErrorMessage(touched, errors, "last_name")}
                </div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DOBWrap className={styles.divWrapper}>
                        <Typography variant="subtitle2" className={styles.label}>
                            Date of birth
                        </Typography>
                        <br />
                        <KeyboardDatePicker
                            autoOk
                            disableToolbar
                            variant="inline"
                            className="textInput"
                            inputVariant="outlined"
                            format="DD/MM/YYYY"
                            margin="normal"
                            placeholder="Enter dd/mm/yyyy"
                            id="date-picker-inline"
                            data-testId="date-picker-inline"
                            keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
                            style={{ fontSize: 16, width: "50%", marginTop: "0" }}
                            value={this.state.date}
                            onChange={(date) => {
                                let dateObj = new Date(date);
                                setFieldValue("date", date);
                                this.setState({ 
                                    date, 
                                    day: dateObj.getDate(), 
                                    month: dateObj.getMonth(), 
                                    year: dateObj.getFullYear()
                                });
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                        {this.getErrorMessage(touched, errors, "date")}
                    </DOBWrap>
                </MuiPickersUtilsProvider>
            </>
        );
    };
		
    OrganisationLogo() {
        return (
            <div className={styles.divWrapper} style={{ width: "50%" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1" className={styles.labelHeading}>Logo</Typography>
                    <Typography style={{ color: '#1593CA' }}>
                        <label htmlFor="changePicture" style={{ cursor: 'Pointer' }}>Change</label>
                        <input
                            name="changePicture"
                            accept="image/png, image/gif, image/jpeg"
                            id="changePicture"
                            type="file"
                            data-testId='changedProfile'
                            hidden
                            disabled={localStorage.getItem('np_user_role') === 'User' ? true : false}
                            onChange={(event) => this.handleUploadLogo(event)}
                        />
                    </Typography>
                </div>


                <CardContent className={styles.cardUploadImage}>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{
                            background: COLORS.white,
                            width: "20%",
                            marginLeft: -12,
                            height: "15%",
                            // border: "1px solid #a7b6b9 ",
                            border: '1px solid #dfdfdf',
                            borderRadius: 10,
                        }}
                    >

                        {this.state.NpOrganizationDetailsData?.logo?.url ? (
                            <img
                                src={this.state.NpOrganizationDetailsData?.logo.url}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: 10,
                                }}
                            />
                        ) : (
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                            >
                                <img
                                    src={icons.plusIcon}
                                    style={{ height: 20 }}
                                    alt="cameraIcon"
                                />

                                <input
                                    name="profile_picture"
                                    accept="image/png, image/jpeg"
                                    id="contained-button-file"
                                    type="file"
                                    hidden
                                    data-testId='userImageUpload'
                                    disabled={localStorage.getItem('np_user_role') === 'User' ? true : false}
                                    onChange={(event) => this.handleUploadLogo(event)}
                                />
                            </IconButton>
                        )}
                    </Grid>
                </CardContent>
                {this.state.NpOrgErrors.logo && <div style={{ color: 'red' }}>Invalid format !! Please upload logo in jpg/jpeg format</div>}
            </div>
        )
    }

    OrganisationCheckBox() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle1" className={styles.labelHeading}>Association to mutual benefit organisations</Typography>
                <br />
                <FormGroup>
                    <span>
                        <FormControlLabel
                            data-testId='check-1'
                            control={
                                <Checkbox
                                    style={{
                                        color: "lightgrey",
                                    }}
                                    icon={<div className={styles.unchecked} />}
                                    checkedIcon={<div className={styles.checked}><div className={styles.blue} /></div>}
                                    checked={this.state.NpOrganizationDetailsData.role === 'cross_sector' ? true : false}
                                    disabled={this.state.disableCheck2 || localStorage.getItem('np_user_role') === 'User'}
                                    onChange={(e) => this.handleCrossMember(e)}
                                />
                            }
                            label={<span style={{ color: '#b0b93b' }}>I am a cross sector or business network</span>}
                        />
                    </span>
                </FormGroup>
                <FormGroup>
                    <span>
                        <FormControlLabel
                            data-testId='check-2'
                            control={
                                <Checkbox
                                    style={{
                                        color: "lightgrey",
                                    }}
                                    icon={<div className={styles.unchecked} />}
                                    checkedIcon={<div className={styles.checked}><div className={styles.blue} /></div>}
                                    checked={this.state.NpOrganizationDetailsData.role === 'member' ? true : false}
                                    disabled={this.state.disableCheck1 || localStorage.getItem('np_user_role') === 'User'}
                                    onChange={(e) => this.handleOrgMember(e)}
                                />
                            }
                            label={<span style={{ color: '#009be2' }}>I am a member of cross sector or business network</span>}
                        />
                    </span>
                </FormGroup>
            </div>
        )
    }

    termsDisabled() {
        return (
            this.state.NpOrganizationDetailsData.role !== "member" || localStorage.getItem('np_user_role') === 'User' ? true : false
        )

    }

    OrganisationTerms() {
        return (
            <div className={styles.divWrapper}>
                <div
                    style={{
                        border: `${this.state.NpOrganizationDropdown.termsOptions
                            ? "1px solid  #3696C3"
                            : "1px solid lightgrey"
                            }`,
                        width: "50%",
                        borderRadius: 10,
                        borderBottom: `${!this.state.NpOrganizationDropdown.termsOptions
                            ? "1px solid lightgrey"
                            : "1px solid  #3696C3"
                            }`,
                        height: '54px',
                    }}
                >
                    <Select
                        onMenuOpen={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, termsOptions: true },
                            });
                        }}
                        onMenuClose={() => {
                            this.setState({
                                NpOrganizationDropdown: { ...this.state.NpOrganizationDropdown, termsOptions: false },
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#009BE2",
                                primary: "dodgerblue",
                            },
                        })}
                        styles={{
                            placeholder: (base) => {
                                return {
                                    ...base,
                                    color: "#a4a3a4",
                                };
                            },
                            menu: (base) => {
                                return {
                                    ...base,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    border: "1px solid rgba(54,150,195)",
                                    borderRadius: '0px 0px 12px 12px',
                                    marginTop: 0,
                                    borderTop: "none",
                                    boxShadow: "none",
                                    color: "lightgrey",
                                    paddingTop: 5,
                                };
                            },
                            dropdownIndicator: (base, state) => {
                                return {
                                    ...base,
                                    color: "dodgerblue",
                                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                                };
                            },
                            control: (base, state) => {
                                return {
                                    ...base,
                                    padding: 10,
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    height: "54px",
                                    borderRadius: state.isFocused ? "10px " : "10px",
                                    border: `${this.state.NpOrganizationDropdown.termsOptions
                                        ? "1px solid  #009BE2"
                                        : "1px solid lightgrey "
                                        }`,
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    "&:hover": {
                                        borderBottom: `${this.state.NpOrganizationDropdown.termsOptions
                                            ? "1px solid  #009BE2"
                                            : "1px solid lightgrey"
                                            }`,
                                    },
                                };
                            },
                            indicatorSeparator: (styles) => ({
                                ...styles,
                                display: "none",
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                color: this.dropdownOptionColor(state.isSelected),
                                backgroundColor: state.isSelected
                                    ? "#009BE2"
                                    : "inherit",
                                "&:hover": {
                                    backgroundColor: "#009BE2",
                                    color: "white",
                                },
                            }),
                            menuList: (base) => {
                                return {
                                    ...base,
                                    marginTop: 15,
                                    position: "relative",
                                    top: "-5px",
                                    zIndex: -1,
                                    color: "grey",
                                };
                            },
                        }}
                        name="orgTerms"
                        data-testId='orgTerms'
                        placeholder={""}
                        isSearchable={false}
                        isClearable={false}
                        isRtl={false}
                        options={this.state.memberCrossSectorOptions}
                        value={this.state.NpOrganizationDetailsData?.selected_cross_sector === null ? null : this.state.memberCrossSectorOptions.filter((it: any) => it.label === this.state.NpOrganizationDetailsData?.selected_cross_sector)}
                        className={styles.selectInput}
                        isDisabled={this.termsDisabled()}
                        onChange={(e) => this.isValidMemeber(e)}
                    >

                    </Select>
                </div>
                {this.state.NpOrgErrors.terms && <div style={{ color: 'red' }}>Please select cross sector organisation</div>}
            </div>
        )
    }

    dropdownOptionColor(show: boolean) {
        return show ? 'white' : "#87888F"
    }

    OrganisationPublishSwitch() {
        return (
            <div className={styles.divWrapper}>
                <Typography variant="subtitle1" className={styles.labelHeading}>Published</Typography>
                <br />
                {/* @ts-ignore */}
                <AntSwitch
                    data-testId='publishSwitch'
                    checked={!!this.state.NpOrganizationDetailsData?.publish}
                    onChange={(e: any) => {
                        this.setState({
                            NpOrganizationDetailsData: {
                                ...this.state.NpOrganizationDetailsData,
                                publish: e.target.checked,
                            }
                        })
                    }}
                    disabled={localStorage.getItem('np_user_role') === 'User' ? true : false}
                />
            </div>
        )
    }

    OrganisationDeleteModel() {
        return (
            <Modal
                // hideBackdrop
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.orgDeleteModal}
                onClose={() => {
                    this.setState({ orgDeleteModal: false });
                }}
                data-testId="modalClose"
            >
                <Box className={styles.modalBox}>
                    <img src={images.invalidOtp} alt='warrning' className={styles.warningIcon} />
                    <Typography style={{ fontSize: '20px' }}>Are you sure to delete this account?</Typography>
                    <Button
                        onClick={() => this.deleteOrganisation()}
                        data-testId='confirmBtn'
                        style={{
                            width: "80%",
                            backgroundColor: "#009be2",
                            color: "white",
                            borderRadius: "10px",
                            marginTop: '35px',
                            height: '50px'
                        }}
                    >
                        Confirm
                    </Button>
                    <Button
                        onClick={() => {
                            this.setState({ orgDeleteModal: false });
                        }}
                        data-testId='cancelModelBtn'
                        style={{
                            width: "80%",
                            color: "black",
                            borderRadius: "10px",
                            marginTop: 15,
                            marginBottom: '30px',
                            height: '50px',
                            border: '1px solid gray'
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Modal>
        )
    }


    // Customizable Area End

    render() {
        // Customizable Area Start
        const plan = localStorage.getItem("plan");

        return (
          <ThemeProvider theme={theme}>
            <ContentManagement
              showBackButton={constants._icons.hide}
              title="Organisation"
              showBottomBar={constants._icons.show}
              showDrawerHeader={true}
              titleColor={COLORS.black}
              open={false}
              mobileOpen={false}
              navigation={this.props.navigation}>
              <Container
                maxWidth={"xl"}
              >
                <div>
                  <Paper
                    style={{
                      marginTop: 30,
                      borderRadius: 12,
                    }}
                    elevation={0}>
                    <div style={{ marginTop: 10 }} />
                      <Typography
                        variant="subtitle1"
                        className={styles.heading}
                        style={{ padding: 10, marginLeft: 12 }}>
                        Organisation details
                      </Typography>
                    <div
                      style={{
                        borderTop: "2px solid #EAEAEA",
                      }}
                    />

                    {this.state.loader ? (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <CircularProgress style={{ color: "#009be2" }} />
                      </div>
                    ) : (
                        <Formik
                        enableReinitialize={this.state.reinitialize}
                        initialValues={{
                          bank_account_number: this.state.bank_account_number,
                          bank_code: this.state.bank_code,
                          mcc: this.state.mcc,
                          street: this.state.street,
                          town: this.state.town,
                          post_code: this.state.post_code,
                          date: this.state.date,
                          country: this.state.country,
                          website_url: this.state.website_url,
                          first_name: this.state.first_name,
                          last_name: this.state.last_name,
                        }}
                        onSubmit={() => this.getUpdatedNpOrganizationData()}
                        validationSchema={plan !== "Trial" && plan !== "Presence-Only" ? this.validationSchema : this.normalValidationSchema}
                      >
                        {({ errors, touched, setFieldValue, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            {plan !== "Trial" && plan !== "Presence-Only" && this.state.accStatus !== "Enabled" && (
                              <AlertWrapper
                                style={{
                                  background: "#ffffd0",
                                  margin: "20px",
                                  padding: "14px",
                                  border: "1px solid yellow",
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                  gap: "13px",
                                }}
                              >
                                <ReportProblemIcon />
                                {
                                  "We cannot process donations at this time. To enable donations, please complete your Stripe onboarding by providing the required information."
                                }
                              </AlertWrapper>
                            )}
                            {this.OrganisationName()}
                            {this.OrganisationType()}
                            {this.OrganisationEmail()}
                            {this.OrganisationDiscription()}
                            {this.addressInfo(setFieldValue, touched, errors)}
                            {plan !== "Trial" && plan !== "Presence-Only" && this.OrganisationAccountHolder()}
                            {plan !== "Trial" && plan !== "Presence-Only" &&  this.accountHolderInfo(setFieldValue, touched, errors)}
                            {plan !== "Trial" && plan !== "Presence-Only" && this.OrganisationBankDetails()}
                            {plan !== "Trial" && plan !== "Presence-Only" && this.renderBankDetails(setFieldValue, touched, errors)}
                            {this.OrganisationWhoAreWeLogo()}
                            {this.OrganisationCausee()}
                            {this.OrganisationWhoWeHelp()}
                            {this.OrganisationWhatWeProvide()}
                            {this.OrganisationWhere()}
                            {this.OrganisationWebSiteUrl(setFieldValue, touched, errors)}
                            {this.OrganisationAnnualTurnover()}
                            {this.OrganisationContactNo()}
                            {this.OrganisationNoOfFollowers()}
                            {this.OrganisationLogo()}
                            {this.OrganisationCheckBox()}
                            {!this.termsDisabled() && this.OrganisationTerms()}
                            {this.OrganisationPublishSwitch()}
                            <div
                              className={styles.divWrapper}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: 15,
                              }}
                            >
                              <div style={{ display: "flex", width: "30%" }}>
                                <Button
                                  data-testId="saveBtn"
                                  type="submit"
                                  style={{
                                    width: "30%",
                                    boxShadow: "none",
                                    textTransform: "capitalize",
                                    height: 45,
                                    backgroundColor: `${
                                      this.state.NpOrgErrors.organisation_name ||
                                      this.state.NpOrgErrors.email ||
                                      this.state.NpOrgErrors.description ||
                                      this.state.NpOrgErrors.contact_number ||
                                      this.state.NpOrgErrors.logo
                                        ? "rgba(0, 155, 226, 0.2)"
                                        : "#009be2"
                                    }`,
                                    color: "white ",
                                    fontFamily: "Rene_Bieder_Milliard_SemiBold",
                                    borderRadius: 10,
                                    marginRight: 13,
                                    marginLeft: 13,
                                  }}
                                  color="primary"
                                  variant="contained"
                                  disabled={
                                    this.state.NpOrgErrors.organisation_name ||
                                    this.state.NpOrgErrors.email ||
                                    this.state.NpOrgErrors.description ||
                                    this.state.NpOrgErrors.contact_number ||
                                    this.state.NpOrgErrors.logo
                                  }
                                >
                                  Save
                                </Button>
                                <Button
                                  onClick={() => this.handleCancelBtn()}
                                  data-testId="cancelBtn"
                                  style={{
                                    fontFamily: "Rene_Bieder_Milliard_SemiBold",
                                    width: "30%",
                                    backgroundColor: COLORS.white,
                                    color: "#009be2",
                                    boxShadow: "none",
                                    borderRadius: 8,
                                    height: 45,
                                    border: ".1 solid lightgray",
                                    textTransform: "capitalize",
                                  }}
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                              </div>
                              <div>
                                <Button
                                  data-testId="deleteBtn"
                                  onClick={() => this.setState({ orgDeleteModal: true })}
                                  style={{
                                    boxShadow: "none",
                                    textTransform: "capitalize",
                                    height: 45,
                                    backgroundColor: "red",
                                    color: "white ",
                                    fontFamily: "Rene_Bieder_Milliard_SemiBold",
                                    borderRadius: 10,
                                    marginRight: 35,
                                    marginLeft: 13,
                                  }}
                                  color="secondary"
                                >
                                  Delete Account
                                </Button>
                              </div>
                            </div>
                            {this.OrganisationDeleteModel()}
                          </form>
                        )}
                      </Formik>
                    )}
                    <br />
                  </Paper>
                </div>
				{this.openRedirectionModal()}
              </Container>
            </ContentManagement>
          </ThemeProvider>
        );
    }
}

NpOrganisationWeb.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

// Customizable Area End

const DialogWrapper = styled(Box)({
  background: "rgb(254, 254, 254)",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 8px",
  borderRadius: "12px",
  border: "1px solid rgb(217, 246, 250)",
  width: "550px",
  padding: "0px",
  position: "relative",
  "& .closeIcon": {
    position: "absolute",
    top: "20px",
    right: "20px",
    fontSize: "18px",
    cursor: "pointer"
  },
  "& .dialogWrapper": {
    padding: "50px",
    borderRadius: "12px !important",
  },
  "& .dialogWrapperInner": {
    textAlign: "center",
    marginBottom: "20px"
  },
  "& .titleModal": {
    ...FONTS.mediumFont,
    fontSize: "22px",
  },
  "& .descriptionModal, .buttonModal": {
    ...FONTS.regularFont,
    "& a": {
      color: "#009dec"
    }
  }
});

const AlertWrapper = styled(Box)({
  background: "#F8F3D6",
  margin: "20px",
  padding: "14px",
  border: "1px solid #815613",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: "13px",
  color: "#815613",
  fontSize: "14px",
  "& svg": {
    fill: "#815613",
    height: "16px",
    width: "16px"
  }
});

const DOBWrap = styled(Box)({
  "& .textInput": {
    "&>div": {
      borderRadius: "10px",
      background: "#ffffff",
      border: "1px solid #fff",
      fontSize: "16px",
      paddingLeft: "5px"
    },
    "& p.Mui-error": {
      display: "none"
    },
    "& fieldset": {
      borderColor: "#d9d4d4 !important",
      border: "1px solid #d9d4d4",
    }
  }
});

function mapStateToProps(state: any) {
    console.log(state, "npState");
    return {
        selectedInnerEvents: state.eventsInnerTabReducer.selectedInnerEvents,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setSelectedEvents: (selectedTab: any) => {
            return dispatch(setSelectedEvents(selectedTab));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NpOrganisationWeb);
