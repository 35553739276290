// Customizable Area Start
export const SIZES = {
  // global sizes
  base: 8,
  font: 14,
  radius: 12,
  padding: 24,

  // font sizes
  signInTitle: 26,
  largeTitle: 18,
  h1: 16,
  h2: 14,
  h3: 12,
  h4: 10,
  h5: 8,
  picker: 16,
  body1: 14,
  body2: 12,
  body3: 10,
  body4: 8,
  body5: 6,
};
// Customizable Area End

// Customizable Area Start

export const FONTS = {
  largeTitle: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: SIZES.largeTitle,
    fontWeight: 400,
  },
  dbsForm: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontWeight: 400,
  },
  mediumFont: {
    fontFamily : 'Rene_Bieder_Milliard_Medium',
  },
  regularFont: {
    fontFamily : 'Rene_Bieder_Milliard_Book',
  },
  semiBoldFont: {
    fontFamily : 'Rene_Bieder_Milliard_SemiBold',
  },
  boldFont: {
    fontFamily: "Rene_Bieder_Milliard_Bold",
  },
  dbsTitles : {
    fontFamily : 'Rene_Bieder_Milliard_Medium',
    fontize : 18
  },
  dbsDateTimeTitle : {
    fontFamily : 'Rene_Bieder_Milliard_Regular',
    fontize : 16
  },
  h1: {
    fontFamily: "Rene_Bieder_Milliard_Bold",
    fontSize: SIZES.h1,
    fontWeight: 400,
  },
  h2: {
    fontFamily: "Rene_Bieder_Milliard_Bold",
    fontSize: SIZES.h2,
    fontWeight: 400,
  },
  h3: {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    fontSize: SIZES.h3,
    fontWeight: 400,
  },
  h4: {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    fontSize: SIZES.h4,
    fontWeight: 400,
  },
  h5: {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    fontSize: SIZES.h5,
    fontWeight: 400,
  },
  datepickerFonts: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: SIZES.picker,
  },
  body1: {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    fontSize: SIZES.body1,
  },
  body2: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: SIZES.body2,
  },
  body3: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: SIZES.body3,
  },
  body4: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: SIZES.body4,
  },
  body5: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: SIZES.body5,
  },
  body6: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: SIZES.body2,
    fontWeight: -10000,
  },
  signInTitle: {
    fontFamily: "Rene_Bieder_Milliard_Bold",
    fontSize: SIZES.signInTitle,
    fontWeight: 400,
  },
  ProfileName: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: SIZES.signInTitle,
    fontWeight: 400,
  },
  settingsTitle: {
    fontFamily: 'Rene_Bieder_Milliard_Medium', 
    fontWeight: 'medium', 
    color: 'rgb(0,0,0)' 
  },
  commonTitle: {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
  },
  selectAll: {
    fontFamily: "Rene_Bieder_Milliard_Book",
  },
  myTask: {
    fontFamily: "Rene_Bieder_Milliard_Book",
  },
  button: {
    fontFamily: "Rene_Bieder_Milliard_Heavy",
  },
  formHead: {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
  },
  paragraph: {
    fontFamily: "Rene_Bieder_Milliard_Book",
  },
  nonProfitText: {
    fontFamily: "Rene_Bieder_Milliard_Book",
    fontSize: SIZES.body1,
    fontWeight: 400,
  },
};

export const FAMILYFONTS = {
  family1: {
    fontFamily: "Rene_Bieder_Milliard_Medium",
  },
  family2: {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
  },
  family3: {
    fontFamily: "Rene_Bieder_Milliard_Book",
  },
};

const appTheme = { SIZES, FONTS, FAMILYFONTS };

// Customizable Area End

// Customizable Area Start

export default appTheme;
// Customizable Area End
