import { InitiateActionCreator } from '../../../../components/src/types/action';
import { SignupFormState } from "./signup-form.reducer";


export const SAVE_SIGNUP_FORM = 'SAVE_SIGNUP_FORM';

export interface SaveSignupFormAction extends Partial<SignupFormState>{
  type: typeof SAVE_SIGNUP_FORM,
}

export const saveSignupForm: InitiateActionCreator<SaveSignupFormAction> =
  (params) => ({
    type: SAVE_SIGNUP_FORM,
    ...params,
  });

