// Customizable Area Start
import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import AuthOtpReducers from './Auth/AuthOtpReducers';
import personalinfoReducers from './personalinformation/personalinfoReducers';
import addWorkEmailReducer from './addWorkEmail/addWorkEmailReducer';
import notificationsReducer from './notifications/notificationsReducer';
import dbsDetailsReducer from './dbsDetails/dbsDetailsReducer';
import emailNotificationsReducer from './emailNotifications/emailNotificationReducer';
import authReducer from './Auth/AuthOtpReducers';
import thunk from 'redux-thunk';
import tabReducer from './tab/tabReducer';
import adminTabReducer from './adminTab/adminTabReducer';
import eventsReducer from './events/eventsReducer';
import eventsInnerTabReducer from './eventsInnerTab/eventsInnerTabReducer';
import offerARoleReducer from './nonProfitUser/offerARoleReducer';
import advanceSearchReducer from "./advanceSearch/advanceSearchReducer";
import filterEventsReducer from "./filterEvents/filterEventsReducer"
import savedSearchInfo from "./savedSearch/savedSearchReducer";
import reducer from "./Location/reducer";
import advFilterReducer from './advFilter/advFilterReducer';
import { signupFormReducer } from './signup-form/signup-form.reducer';
import savedSearchPayloadReducer from './savedSearchPayload/savedSearchPayloadReducer';
// @ts-ignore
import usersNpReducers from "./usersNp/usersNpReducer";
import activePlanReducer from './activePlan/actvePlanReducer'
import advSearchPayloadReducer from './advSearchPayload/advSearchPayloadReducer'

const rootReducer = combineReducers({
  userNp: usersNpReducers,
  adminTabReducer,
  tabReducer,
  personalInfoDetails: personalinfoReducers,
  workEmailDetails: addWorkEmailReducer,
  notificationsDetails: notificationsReducer,
  dbsDetails: dbsDetailsReducer,
  authDetails: authReducer,
  emailNotificationDetails: emailNotificationsReducer,
  AuthOTP: AuthOtpReducers,
  eventsReducer,
  eventsInnerTabReducer,
  offerARoleReducer,
  advanceSearchReducer,
  advFilterReducer,
  filterEventsReducer,
  savedSearchInfo,
  advSearchPayloadReducer,
  reducer,
  savedSearchPayloadReducer,
  signupForm: signupFormReducer,
  activePlanReducer
});

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const stores = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof rootReducer>;

export default stores;
// Customizable Area End
