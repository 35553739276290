// Customizable Area Start
// @ts-nocheck
import React from "react";
import PropTypes from 'prop-types';
import ServiceSpecificSettingsAdminController from "./ServiceSpecificSettingsAdminController";
import BusDashbaordWeb from "./BusDashboard.web";
// @ts-ignore
import classesOuter from './BusChat.module.css';

import {
    Box, Container, InputAdornment, Input, IconButton, Typography, Button, Table,
    TableRow,
    TableCell as MuiTableCell, TableHead as MuiTableHead,
    TableBody,
    Divider,
    TableContainer,
    Paper,
    CircularProgress,
    Accordion,
} from '@material-ui/core'
import { icons, constants } from "../../../components/src/constants";
import { COLORS } from "../../../framework/src/Globals";
import {
    withStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import BusUserChat from "./BusUserChat.web";
import CloseIcon from '@material-ui/icons/Close';
import CustomSuccessModel from "../../../components/src/custom/CustomSuccessModel";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";

export const styles = (theme: Theme) =>
    createStyles({
        toolBar: {
            display: "flex",
            flexDirection: "row",
            padding: "0px",
        },
        search: {
            position: "relative",
            borderRadius: "8px",
            backgroundColor: COLORS.white,
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(0),
            },
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        inputInput: {
            fontFamily: "Rene_Bieder_Milliard_Light",
            fontWeight: 700,
            boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
            paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
            transition: theme.transitions.create("width"),
            marginTop: "15",
            fontSize: "revert",
            padding: "16 45 16 0",
            marginBottom: "10px",
            "@media (max-width: 600px)": {
                marginTop: "15",
                padding: "16 65 16 0",
                marginBottom: "10px",
                boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
                paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
                transition: theme.transitions.create("width"),
                fontSize: "revert",
                fontFamily: "Rene_Bieder_Milliard_Light",
                fontWeight: 700,
            },
            "&::placeholder": {
                color: "#000501",
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: "Rene_Bieder_Milliard_Light",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: COLORS.skyBlue,
        },
        _tabs_logo: {
            height: "20px",
            width: "20px",
            left: "33px",
            top: "788px",
            borderRadius: " 0px",
        },
        image: {
            width: 128,
            height: 128,
        },
        sectionTabDesktop: {
            display: "flex",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        aftersearchIconbutton: {
            borderRadius: "8px",
            color: "text.primary",
            backgroundColor: "white",
            margin: "8px",
            padding: "8px",
            height: 46,
            width: 46,
        },
        sectionDesktop: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        inputRoot: {
            color: "inherit",
            width: "100%"
        },

        cancelIcon: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        icon: {
            background: "rgb(59, 149, 204)",
            color: "#fff",
            borderRadius: "0px 10px 0px 10px",
            width: "50px",
            height: "35px",
            padding: "7px"
        }
    });

const TableCell: any = withStyles({
    root: {
        borderBottom: "none !important",
    },
})(MuiTableCell);

const TableHead: any = withStyles({
    root: {
        borderBottom: "3px solid #eaeaea",
    },
})(MuiTableHead);

class BusChat extends ServiceSpecificSettingsAdminController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }

    previouseBroadcast() {
        return (
<>
            {this.state.loading ? 
                <div
                  style={{
                    height: "30%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress style={{ color: "#009be2" }} />
                </div> : 
            <TableContainer component={Paper} style={{height: '50vh',overflowY: 'scroll'}}>
           {this.state.broadcastMsgData.map((item: string, index: string) => (
             <>
                    <Accordion expanded={this.state.expanded === item.id} data-testId={`acc-${item.id}`} onChange={this.handleAccordianClick(item.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: '#009be2' }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={classesOuter.listBroadcasts}>
                                <Typography style={{ fontWeight: 200, color: "black", fontFamily: 'Rene_Bieder_Milliard_Book' }}>{this.state.broadcastMsgData.length - index}.</Typography>
                                {this.state.expanded === item.id ?
                                    <Typography className={classesOuter.listDesc} style={{ fontWeight: 200, color: "black", fontFamily: 'Rene_Bieder_Milliard_Book' }}>{item?.attributes?.message}</Typography>
                                    : <Typography className={classesOuter.listWrap} style={{ fontWeight: 200, color: "black", fontFamily: 'Rene_Bieder_Milliard_Book' }}>{item?.attributes?.message}</Typography>}

                                <Typography variant="subtitle2" style={{ fontWeight: 200, color: "black" }} className={classesOuter.listTime}>{moment(item.attributes.created_at).format('DD/MM/YYYY hh:mm A')}</Typography>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                    
                    </>
            ))}

{this.state.broadcastMsgData.length == 0 && !this.state.loading ? (
                        <div style={{display:'flex', textAlign:'center', justifyContent:'center',marginTop:'120px'}}>No Broadcasts available.</div>
                      ) : null}
            </TableContainer> 
        }
        </>
        )
    }
    
    getFilterRecord = () => {
        return this.state.chatsList.filter((item) => {
            const name = item.attributes.friendly_name === 'eventChatRoom'
                ? item.attributes.event_name
                : item.attributes.member_data[1].full_name;
          return name?.toLowerCase().includes(this.state.searchTerm?.toLowerCase()) ? true : false;
        });
    };

    tableBodyData() {
        const filterData = this.getFilterRecord();
        return (

            <>
             {this.state.loading ? 
                <div
                  style={{
                    height: "30%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress style={{ color: "#009be2" }} />
                </div> : 
            <TableContainer component={Paper} style={{height: '50vh',overflowY: 'scroll'}}>
            <Table style={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className={classesOuter.followersNPTableHead}>
                    <TableRow>
                        <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>Name</TableCell>
                        <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>Location</TableCell>
                        <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>TYPE</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classesOuter.followersNPTableCell}>
                {filterData.length > 0 && filterData.map((item: string) => (
                        <>
                    <TableRow style={{ border: 0, borderBottom: "none" }}>
                        <TableCell>{item.attributes.friendly_name==='eventChatRoom'?item.attributes.event_name:item.attributes.member_data[1].full_name}</TableCell>
                        <TableCell>{item.attributes.member_data[1].location? item.attributes.member_data[1].location: "--" }</TableCell>
                        <TableCell>{item.attributes.friendly_name==='eventChatRoom'?this.renderEventType(item.attributes.event_type):this.renderUserType(item.attributes.member_data[1].type)}</TableCell>
                        <TableCell>
                            <Button
                                data-testid="ButtonTest"
                                onClick={() => this.handleOpen(item)}
                                variant="outlined"
                                style={{
                                    width: 100,
                                    boxShadow: "none",
                                    textTransform: "capitalize",
                                    backgroundColor: " #009be2",
                                    color: "white ",
                                    fontFamily: "Rene_Bieder_Milliard_Medium",
                                    borderRadius: 10,
                                    marginRight: 13,
                                    marginLeft: 13,
                                    border: "none",
                                }}
                                color="primary"
                            >
                                Message
                            </Button>
                        </TableCell>
                    </TableRow>

                    </>
                      ))}
                       {filterData.length == 0 && !this.state.loading ? (
                        <TableRow style={{ border: 0, borderBottom: "none" }} data-testid={`chat-row-0`} key={0}>
                        <TableCell></TableCell>
                        <TableCell style={{textAlign:'center'}}>No Data</TableCell>
                        </TableRow>
                      ) : null}
                </TableBody>
            </Table>
            </TableContainer>
    }
            </>
            )
    }

    wordCount = (count: string) => {
        if (count.length === 0) {
            return 0;
        } else {
            count = count.replace(constants.regExp.reg1, "");
            count = count.replace(constants.regExp.reg2, " ");
            count = count.replace(constants.regExp.reg3, "\n");
            return count.split(' ').length;
        }
    }

    isBroadcastDisabled = () => {
       const activePlanName = localStorage.getItem("activePlanName")
       const isExpired = localStorage.getItem("expired");
       if(activePlanName === 'none'||activePlanName==="Plan 3 - Non paying - Presence only"||isExpired ==='true') {
         return true;
       } else if(this.wordCount(this.state.broadcastLengthCounter) > 70 || this.state.broadcastLengthCounter?.trim().length === 0) {
        return true;
       } else {
         return false;
       }
    }

    render() {
        const { classes }: any = this.props;
        return (
            <div>
                {/* @ts-ignore */}
                <BusDashbaordWeb classes={this.props.classes} navigation={this.props.navigation} id={""} theme={{}}>
                    <Container maxWidth="xl" style={{ marginLeft: 280, width: 'auto' }}>
                        <Box className={classesOuter.broadcastMessage}>
                            <Box className={classesOuter.followersBusheading}>
                                <Typography
                                    style={{ fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }}>
                                    Broadcast a message to employees
                                </Typography>
                            </Box>
                            <Box style={{ position: 'relative' }}>
                                <Input
                                    className={classesOuter.textArea}
                                    style={{ width: '100%', resize: 'none', fontSize: '1rem', textAlign: 'justify' }}
                                    rows={5}
                                    multiline
                                    disableUnderline
                                    value={this.state.broadcastLengthCounter}
                                    onChange={(e) => this.setState({ broadcastLengthCounter: e.target.value })}
                                    placeholder="Type something you want to broadcast..."
                                    data-testId="textMsg"
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        right: '20px',
                                        color: '#A2A2A2',
                                        fontFamily: 'Rene_Bieder_Milliard_Book',
                                    }}>
                                    {`${this.wordCount(this.state.broadcastLengthCounter)}/70`}
                                </div>
                            </Box>
                            <Box className={classesOuter.messageButton}>
                                <Button
                                    data-testid="modalTest"
                                    variant="outlined"
                                    style={{
                                        width: 100,
                                        boxShadow: "none",
                                        textTransform: "capitalize",
                                        backgroundColor: this.isBroadcastDisabled() ? "rgba(0, 155, 226, 0.2)" : "#009be2",
                                        color: "white ",
                                        fontFamily: "Rene_Bieder_Milliard_Medium",
                                        borderRadius: 10,
                                        border: "none",
                                    }}
                                    color="primary"
                                    onClick={() => this.createBroadcast()}
                                    data-testId="broadCastBtn"
                                    disabled={this.isBroadcastDisabled()}
                                >
                                    Broadcast
                                </Button>
                            </Box>
                        </Box>

                        {<Box className={classesOuter.followersBusmainDiv}>
                            <Box className={classesOuter.followersBusheadingDiv}>
                                <Box className={classesOuter.followersBusheading} style={{marginLeft:"-1.2%"}}>
                                    <Typography style={{ fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }}>Previous broadcasts</Typography>
                                </Box>
                            </Box>
                            <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
                            <Box>{this.previouseBroadcast()}</Box>
                        </Box>}

                        <Box className={classesOuter.followersBusmainDiv1}>
                            <Box className={classesOuter.followersBusheadingDiv}>
                                <Box className={classesOuter.followersBusheading} style={{marginLeft:"-1.2%"}}>
                                    <Typography style={{ fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }}>Chats</Typography>
                                </Box>
                                <Box>
                                    <div className={classes.search}>
                                        <Input
                                            data-testId="searchInput"
                                            startAdornment={
                                                <InputAdornment
                                                    position="start"
                                                    style={{
                                                        width: 40,
                                                        marginLeft: -9,
                                                    }}
                                                >
                                                    <IconButton>
                                                        <img
                                                            src={icons.searchSVG}
                                                            style={{
                                                                height: 25,
                                                                width: "auto",
                                                                opacity: 0.5555555,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            placeholder="Search..."
                                            className={classesOuter.searchField}
                                            disableUnderline
                                            onChange={this.searchGroup}
                                        />
                                    </div>
                                </Box>
                            </Box>
                            <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
                            <Box>{this.tableBodyData()}</Box>
                            
                                {this.state.chatModal === true ?
                                    <div className={classesOuter.modalBox}>
                                        <div className={classes.cancelIcon}>
                                            <CloseIcon className={classes.icon}
                                                data-testid="closeBtnTest"
                                                onClick={() => this.setState({ chatModal: !this.state.chatModal })}
                                            />
                                        </div>
                                        <BusUserChat
                                         client={this.chatClient}
                                         chatUserInformation={this.state.currentChat}
                                         clearChat={this.clearChat} />
                                    </div>
                                    : null}
                            
                        </Box>
                        <CustomSuccessModel openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose} msgTitle='Thank you' msg="Broadcast Message saved succefully" />
                    </Container>
                </BusDashbaordWeb >
            </div >
        )
    }
}

export default withStyles(styles as any, { withTheme: true })(
    BusChat as any
);
export { BusChat }
// Customizable Area End