export const SET_SELECTED_INNER_TABS = 'SET_SELECTED_INNER_TABS';

export const setSelectedEventSuccess = (selectedEvents: any) => ({
  type: SET_SELECTED_INNER_TABS,
  payload: { selectedEvents }
});

export function setSelectedEvents(selectedEvents: any) {
  return (
    dispatch: (arg0: { type: string; payload: { selectedEvents: any } }) => void
  ) => {
    console.log(selectedEvents, 'innertabs');
    dispatch(setSelectedEventSuccess(selectedEvents));
    localStorage.setItem('selectedInnerEvents', JSON.stringify(selectedEvents));
  };
}
