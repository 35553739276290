import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  TextField,
  DialogActions, Button
} from "@material-ui/core";


import {
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import {
  icons,
  FAMILYFONTS
} from "../../../components/src/constants";
import {connect} from "react-redux";
import {fetchCurrentLocation, setCurrentLocation} from "../../../framework/src/redux/Location/action"
import { isMobile } from "react-device-detect";
import { COLORS } from '../../../framework/src/Globals';
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import { styled } from "@material-ui/styles";
// @ts-ignore
import classesOuter from "../../dashboard/src/HomeDashBoard.module.css";

export const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        background: '#FFFFFF',
        boxShadow: ' 0px 0px 8px rgba(212, 212, 212, 0.5)',
        borderRadius: 12,
        height: '56px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '18px',
        color: 'black',
        marginTop:10
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '12px',
      },
      notchedOutline: {
        border: 'none'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '8px',
        marginBottom: '0px'
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        fontSize: '18px',
        borderRadius: '12px'
      },
      containedPrimary: {
        backgroundColor: "#009BE2"
      },
      contained: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.275076)',

      },
      text: {
        color: '#009BE2',
        fontWeight: 400,
        marginTop: '5px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '0'
      }
    }
  },
});

// Customizable Area End

import LocationController, { Props, configJSON } from "./LocationController";

class Location extends LocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  locationFieldPlaces() {
    return (
      <Wrapper>
        <br />
        {/* @ts-ignore */}
        <GooglePlacesAutoComplete
          apiKey={configJSON.mapsAPIKey}
          debounce={500}
          data-testId = 'location'
          selectProps={{
            isClearable: true,
            value: this.state.location ? [{ label: this.state.location, value: this.state.location }] : undefined,
            classNamePrefix: "react-select",
            placeholder: "Enter location",
          noOptionsMessage: () => null,
            onChange: (event: any) => {
              const location = event?.value?.description ?? "";
              this.setState({ location });
              if (location) {
                this.getLatLong(event?.value?.description);
              } else if(event === null) {
                this.setState({ city: "", post_code: ""})
              }
            },
          }}
        >

        </GooglePlacesAutoComplete>
        <img
          src={icons.locationIcon}
          style={{
            height: 23, width: "auto", position: 'absolute', top: 36, right: 5
          }}
        />
      </Wrapper>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
   
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <div >
          
          {this.locationFieldPlaces()}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="city"
            name="city"
            autoFocus
            placeholder='Town/City'
            value={this.state.city}
            disabled={this.state.location !== ''}

            data-test-id="_city"
            onChange={(e) => {
              this.setState({ city: e.target.value, locationError: false, isSubmitting: false });
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="post_code"
            name="post_code"
            value={this.state.post_code}
            disabled={this.state.location !== ''}
            autoFocus
            placeholder='Post code *'
            data-test-id="_post_code"
            onChange={(e) => {
              this.setState({ post_code: e.target.value, locationError: false, isSubmitting: false });
            }}
          />
          {
            this.state.locationError &&
            <Box className={classesOuter.errorMsg}>
            <div className={classesOuter.errors}>Please enter location to save</div>
            </Box>
          }
          {
            <Box className={classesOuter.errorMsg}>
            <div className={classesOuter.errors}>{this.state.locationsuccessmesg}</div>
            </Box>
          }
          <Container maxWidth="sm">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 30 }}

            >
              <div style={{
                opacity: '0.25',
                border: '1px solid #979797',
                width: '100%',

                height: .5
              }} />
              <div><Typography style={{ ...FAMILYFONTS.family3, fontSize: 9, marginRight: 25, marginLeft: 25 }}>or</Typography></div>
              <div style={{
                opacity: '0.25',
                border: '1px solid #979797',
                width: '100%',

                height: .5
              }} />
            </div>

           

          </Container>



          <DialogActions >
         <Box  style={{
          width: '100%',
          marginTop:20
         }}>
         <Button
                type="submit"

                style={{
                  border: '1px solid rgba(151, 151, 151, 0.62653)',
                  height: '56px',
                  width: "100%",
                  background: '#FFFFFF',
                  borderRadius: '12px',
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 20,
                  alignItems: "center",
                  flexDirection: "row"
                }}
                data-test-id="btnAllowLocation"
                onClick={() => this.getLocation()}
              >
                <div style={{ marginRight: 30 }}>
                  <Typography style={{ ...FAMILYFONTS.family3, fontSize: 14,   textTransform: "none", color: COLORS.black }}>Use my current location</Typography>
                </div>
                <div>
                  <img src={icons.locationblack} style={{ height: 20 }} />
                </div>

                

              </Button>

              <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'row' : 'column',
              }}>


              {isMobile &&
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  textTransform: "none",
                  boxShadow: "none",
                  borderRadius: 12,
                  height: 54,
                  border: '1px solid #009BE2',
                  ...FAMILYFONTS.family1, fontSize: 16,
                  color: '#009BE2',
                  marginTop:10,marginBottom:30,
                  marginRight:30
                }}
                
                onClick={() => this.props.closeModal()}
              >
                Cancel
              </Button>}
            <Button
              type="submit"
              fullWidth
              data-testId="submit"
              variant="contained"
              style={{ height: 54, backgroundColor:'#049BE1', color:'#fff', marginTop:10,marginBottom:30}}
              onClick={(e) => this.onSubmitLocation(e)}
              disabled={this.state.isSubmitting}
            >
              Save
            </Button>
              </div>

         </Box>
         


          </DialogActions>      
          

        </div>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  position: "relative",
  "& .react-select__placeholder": {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '16px',
    opacity: '0.42'
  },
  "& .react-select__menu": {
    paddingLeft: 20,
    paddingRight: 20,
    border: "1px solid lightgrey",
    marginTop: 0,
    borderTop: "none",
    boxShadow: "none",

    color: "lightgrey",
    paddingTop: 5,
    "&:hover": {
      borderRadius: 12,
    },
  },
  "& .react-select__control": {
    color: '#000',
    fontSize: 16,
    fontFamily: 'Rene_Bieder_Milliard_Book',
    backgroundColor: "white",
    boxShadow: "0px 0px 8px rgba(212, 212, 212, 0.5)",
    height: "56px",
    width: '100%',
    borderRadius: "12px",
    border: 'none',
  },
  "& .react-select__option": {
    color: "black",
    "&:hover": {
      backgroundColor: "#009BE2",
      color: "white",
    },
  },
  "& .react-select__menuList": {
    marginTop: 15,
    position: "relative",
    top: "-5px",
    zIndex: -1,
    color: "grey",
  },
  "& .react-select__dropdown-indicator": {
    color: "dodgerblue",
    display: 'none'
  },
  "& .react-select__clear-indicator": {
    marginRight: 20
  },
  "& .react-select__indicator-separator": {
    display: "none"
  }
});
const mapStateToProps = (state: { address: any; }) => {
  return {
    address: state.address
  };
};

const mapDispatchToProps = (dispatch: any)   => {
  return {
    fetchCurrentLocation: () => dispatch(fetchCurrentLocation()),
    setCurrentLocation: (lat: any, lng: any, post_code: any, location: any) => {
      return dispatch(setCurrentLocation(lat, lng, post_code, location))
    }
  };
};
export {Location}
export default connect(mapStateToProps, mapDispatchToProps)(Location);

// Customizable Area End
