// Customizable Area Start
import React from "react";

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { createTheme } from "@material-ui/core/styles";
import { SuccessfulRegisteration } from "./AccountVerifiedSuccessContent";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

class AccountVerifiedSuccess extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  render() {
    return (
      // Customizable Area Start
        <>
          <SuccessfulRegisteration type="login"/>
        </>
        // Customizable Area End
    );
  }
}

 // Customizable Area Start
export {AccountVerifiedSuccess}
export default  AccountVerifiedSuccess
// Customizable Area End