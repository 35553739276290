import React, { useEffect, useState } from "react";

export default function CustomMultiValue(props: any) {
  const { getValue, data } = props;
  const [arrayVal, setArrayVal] = useState(1);

  const selectedOptions = getValue();
  useEffect(() => {
    if (selectedOptions.length > 1) {
      setArrayVal(selectedOptions.length - 1);
    }
  }, [selectedOptions.length]);
  const currentOptionIdx = selectedOptions.findIndex(
    (option: any) => option.value === data.value
  );

  return (
    <span>
      {currentOptionIdx === 0 &&
        selectedOptions[0].label + " " +
        (selectedOptions.length > 1 ? `+${arrayVal}` : "")}
    </span>
  );
}
