Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "/";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.pastEventEndPoint = "past_events";
exports.dashboardContentType = "application/json";
exports.dashboardGetMethod = "GET";
exports.dashboardPostMethod = "POST";
exports.upComingEventEndPoint = "upcoming_events";
exports.dbsCategoriesApiEndPoint = "dbs_types";
exports.getTasksEndPoint = "task_preferences";
exports.advanceSearchApiEndPoint = "advance_search";
exports.search_event_by_nameApiEndPoint="search_event_by_name"
exports.savedsearchApiEndPoint="advanced_search_saved"
exports.getAllEventsApiEndPoint="/bx_block_targetedfeed/get_targeted_feeds"
exports.getUserProfileApiEndPoint = "account_block/accounts/get_user_profile";
exports.ApiEndPoint = "/accounts/account_profile";
exports.getBannerApiEndPoint = "bx_block_upload_media/initiate_logo";
exports.placeholderImageApiEndpoint="bx_block_upload_media/initiate_logo";
exports.getFeedDataApiEndPoint = "/bx_block_targetedfeed/get_targeted_feeds";
exports.getUpcomingApiEndPoint = "/upcoming_events";
exports.getEndPoint = "events";
exports.logoutEndpoint = "/bx_block_profile/non_profit_activity_logs"
// exports.advanceSearchApiEndPoint = "non_profit_events";
// Customizable Area End