// Customizable Area Start
const app_logo = require('../../assets/images/logo.png');
const cardImg2 = require('../../assets/images/cardImg2.png');
const bgImg = require('../../assets/images/bgimg.svg');
const microsoft = require('../../assets/images/microsoft.png');
const imgPasswordVisible = require("../../assets/images/image_eye.png");
const imgPasswordInVisible = require("../../assets/images/image_eyeslash.png");
const imgMessage = require("../../assets/images/image_message.png");
const invalidOtp = require("../../assets/images/image_Caution.png");
const profilePic = require("../../assets/images/cardimg1.png")
const imgCheck = require("../../assets/images/image_check.png");
const volunteers = require("../../assets/images/VolunteersImage.svg");
const microSVG = require("../../assets/images/microsoftLogo.svg");
const camera = require("../../assets/images/camera.png");
const emoji = require("../../assets/images/emoji-normal.png");
const gallery = require("../../assets/images/gallery.png");
const sendImg = require("../../assets/images/send.png");
const eventDetailsBanner = require("../../assets/images/eventDetails.svg");
const weCare = require("../../assets/images/weCare.svg");
const initiatestart = require("../../assets/images/initiatestart.png");
const speaker=require("../../assets/images/speaker.png");
const eventdetails = require("../../assets/images/eventdetails.png")
const emailIcon = require("../../assets/images/emailIcon.png");
const eyeCloseIcon = require("../../assets/images/eyeCloseIcon.png");
const eyeOpenIcon = require("../../assets/images/eyeOpenIcon.png");
const eyeCloseIcon1 = require("../../assets/images/eyeCloseIcon1.png");
const eyeOpenIcon1 = require("../../assets/images/eyeOpenIcon1.png");
const roundButtonIcon = require("../../assets/images/buttonicon.png");
const imageWarning = require('../../assets/icons/image_warning.png')
const subscriptionBannerImg = require('../../assets/images/subscriptionBanner.png')
const avatar = require('../../assets/images/avatar.png');

export default {
   subscriptionBannerImg,
   speaker,
   initiatestart,
   microSVG,
   app_logo,
   cardImg2,
   bgImg,
   microsoft,
   imgPasswordVisible,
   imgPasswordInVisible,
   imgMessage,
   invalidOtp,
   imgCheck,
   volunteers,
   profilePic,
   camera,
   emoji,
   sendImg,
   gallery,
   eventDetailsBanner,
   weCare,
   eventdetails,
   emailIcon,
   eyeCloseIcon,
   eyeOpenIcon,
   eyeCloseIcon1,
   eyeOpenIcon1,
   roundButtonIcon,
   imageWarning,
   avatar
};
// Customizable Area End