import React from "react";
// Customizable Area Start

import PropTypes from "prop-types";

import ContentManagementController, {
  Props,
} from "./ContentManagementController";
// @ts-ignore
import module from "./ContentManagementEvent.module.css";
import {
  Avatar,
  Dialog,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow, CircularProgress,
  Divider,
  Typography,
  Box,
  Container,
  Paper,
  Button,
} from "@material-ui/core";
import { COLORS } from "../../../framework/src/Globals";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export type CounterProps = { label?: string };

const TableCell: any = withStyles({
  root: {
    borderBottom: "1px solid #d3d3d333",
  },
})(MuiTableCell);

const styles = (theme: Theme) => createStyles({});

// Customizable Area End

export class EventUpdatesweb extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    return (

      <div>
        {this.state.singleEventUpdates.length === 0 ? (
          <Paper
            style={{
              textAlign: "center",
              height: "50%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <CircularProgress />{" "}
          </Paper>
        ) : (
          <Paper
            className={classes?.tableroot}
            style={{
              width: "99.6%",
              borderRadius: "0 0 12px 12px",
              marginLeft: "0.1%",
              // marginTop: 30,
            }}
            elevation={0}
          >
            <Divider style={{ backgroundColor: "lightgrey" }} />

            {this.state.singleEventUpdates.error ? (
              <Typography
                style={{
                  textAlign: "center",
                  height: "50%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Rene_Bieder_Milliard_Medium",
                }}
              >
                No such data for this particular event
              </Typography>
            ) : (
              <Table className={classes?.table}>
                <TableHead>
                  <TableRow>
                    {/* @ts-ignore */}
                    <TableCell className={module.thead} width="30%" />
                    <TableCell className={module.thead} align="center">
                      ROLE
                    </TableCell>
                    <TableCell className={module.thead} align="center">
                      UPDATED BY
                    </TableCell>
                    <TableCell className={module.thead} align="center">
                      UPDATED ON
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.singleEventUpdates && //error
                    this.state.singleEventUpdates?.map((item: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            fontWeight: 200,
                          }}
                          className={module.label}
                          component="th"
                          scope="row"
                        >
                          {item.update_info}
                        </TableCell>
                        <TableCell
                          className={module.label}
                          align="center"
                        ></TableCell>
                        <TableCell className={module.label} align="center">
                          {item.user_name}
                        </TableCell>
                        <TableCell className={module.label} align="center">
                          {moment(item.updated_at).format("DD/MM/YYYY hh:mm A")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </Paper>
        )}
      </div>

      // Customizable Area End
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(
  EventUpdatesweb as any
);

//
