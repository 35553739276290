import React from 'react';


const makeWrapper = (component: any, wrapper: any) =>
  (props: any) => React.createElement(wrapper, props, React.createElement(component, props));

/*
 * PageWrapper can take 
 *
 * 1- layouts components wrapper where each layout will have the page in its children
 * 2- guards will wrap your component for the purpose of deciding whither a user can access
 * some page or he/she can't, based on your guard metric(e.g: LoginGuard)
 * 3- providers will also wrap your page component, but their purpose are for providing data
 * across react context API
 *
 * In short: your page will be wrapper with layouts, guards, and providers, and if 
 * you want to render your page, you have to render the children prop
 */
export function PageWrapper({ page, layouts, guards, providers }: {
  page: any,
  layouts?: any[],
  guards?: any[],
  providers?: any[],
}) {

  let pageGuards = guards || [];
  let pageLayouts = layouts || [];
  let pageProviders = providers || [];

  let wrappers = pageLayouts.concat(pageGuards, pageProviders);

  const pageComponent = (props: any) => React.createElement(page, props);

  return wrappers.reduce(makeWrapper, pageComponent);
}
