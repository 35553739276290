import React from "react";

export const CameraIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
        >
            <defs>
                <filter id="filter_1">
                    <feColorMatrix
                        colorInterpolationFilters="sRGB"
                        in="SourceGraphic"
                    ></feColorMatrix>
                </filter>
            </defs>
            <g fillOpacity="1" stroke="none" filter="url(#filter_1)">
                <path fill="none" d="M50 0v50H0V0h50z"></path>
                <path
                    fillRule="evenodd"
                    d="M29.47 6.25a6.25 6.25 0 015.393 3.09l.198.365.356.712h4.166a6.25 6.25 0 016.24 5.882l.01.368V37.5a6.25 6.25 0 01-5.882 6.24l-.368.01H10.417a6.25 6.25 0 01-6.24-5.883l-.01-.367V16.667a6.25 6.25 0 015.882-6.24l.368-.01h4.166l.356-.712a6.25 6.25 0 015.175-3.441l.415-.014h8.942zm0 3.125h-8.94a3.125 3.125 0 00-2.796 1.727l-.356.712a3.125 3.125 0 01-2.795 1.728h-4.166a3.125 3.125 0 00-3.125 3.125V37.5c0 1.726 1.399 3.125 3.125 3.125h29.166c1.726 0 3.125-1.4 3.125-3.125V16.667c0-1.726-1.399-3.125-3.125-3.125h-4.166a3.125 3.125 0 01-2.795-1.728l-.356-.712a3.125 3.125 0 00-2.795-1.727zM25 18.75a8.333 8.333 0 110 16.667 8.333 8.333 0 010-16.667zm0 3.125a5.208 5.208 0 100 10.417 5.208 5.208 0 000-10.417zm12.5-5.208a2.083 2.083 0 110 4.166 2.083 2.083 0 010-4.166z"
                ></path>
            </g>
        </svg>
    );
}

export const VideoIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
        >
            <defs>
                <filter id="filter_1">
                    <feColorMatrix
                        colorInterpolationFilters="sRGB"
                        in="SourceGraphic"
                    ></feColorMatrix>
                </filter>
            </defs>
            <g fillOpacity="1" stroke="none" filter="url(#filter_1)">
                <path fill="none" d="M50 0v50H0V0h50z"></path>
                <path
                    fillRule="evenodd"
                    d="M29.167 10.417a6.25 6.25 0 016.25 6.25v1.762l3.71-2.853c2.74-2.108 6.706-.154 6.706 3.303V31.12c0 3.457-3.967 5.41-6.707 3.303l-3.71-2.854v1.763a6.25 6.25 0 01-6.25 6.25h-18.75a6.25 6.25 0 01-6.25-6.25V16.667a6.25 6.25 0 016.25-6.25h18.75zm0 3.125h-18.75a3.125 3.125 0 00-3.111 2.824l-.014.3v16.667c0 1.625 1.239 2.96 2.824 3.111l.3.014h18.75c1.625 0 2.96-1.239 3.111-2.824l.015-.3V16.666c0-1.625-1.24-2.96-2.824-3.111l-.301-.014zm11.865 4.51l-5.615 4.32v5.256l5.615 4.32a1.042 1.042 0 001.676-.827V18.88c0-.865-.991-1.353-1.676-.826z"
                ></path>
            </g>
        </svg>
    );
}

export const BroadcastIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41.667"
            height="43.75"
            viewBox="0 0 41.667 43.75"
        >
            <g stroke="none">
                <path d="M30.9 7.322l6.022-4.015a1.042 1.042 0 011.62.867V23.47c0 1.726-1.4 3.125-3.125 3.125h-2.084v3.125h2.084a6.25 6.25 0 006.25-6.25V4.174c0-3.328-3.71-5.313-6.478-3.467l-6.022 4.015H14.583a6.25 6.25 0 00-6.25 6.25v2.083h3.125v-2.083c0-1.726 1.4-3.125 3.125-3.125h14.584c.617 0 1.22-.183 1.733-.525z"></path>
                <path
                    fillRule="evenodd"
                    d="M33.333 31.805a6.25 6.25 0 01-6.25 6.25H10.417L6.77 40.972C4.04 43.155 0 41.212 0 37.72V19.305a6.25 6.25 0 016.25-6.25h20.833a6.25 6.25 0 016.25 6.25v12.5zm-6.25 3.125H10.417c-.71 0-1.398.241-1.953.685l-3.647 2.917a1.042 1.042 0 01-1.692-.813V19.305c0-1.726 1.4-3.125 3.125-3.125h20.833c1.726 0 3.125 1.399 3.125 3.125v12.5c0 1.726-1.399 3.125-3.125 3.125z"
                ></path>
                <path
                    fillRule="evenodd"
                    d="M10.417 23.992h12.5a1.563 1.563 0 010 3.125h-12.5a1.562 1.562 0 110-3.125z"
                ></path>
            </g>
        </svg>
    );
}
