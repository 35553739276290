import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface PayloadQuestion {
  id: number;
  title: string;
  q_type: string;
  toggleQuestionTypeSelect: boolean;
  error: boolean;
  isFormValid: boolean;
  single_answer?: boolean;
  multi_answer?: boolean;
  choose_limit?: number;
  options_attributes?: { name: string; error: boolean, id: string | number, _destroy?: boolean }[];
  range_attributes?: {
    id?: string | number;
    start_range?: number; 
    end_range?: number; 
    start_range_text?: string;
    end_range_text?: string;
  };
}

export interface QuestionResponse {
  id: string;
  type: string;
  attributes: Attributes;
  errors?: (ErrorsEntity)[] | null;
  error?: string | null;
}
export interface Attributes {
  id: number;
  title: string;
  q_type: string;
  single_answer: boolean;
  multi_answer: boolean;
  choose_limit: number;
  created_at: string;
  updated_at: string;
  options?: Options | null;
  range?: Range | null;
}
export interface Options {
  data?: (DataEntity)[] | null;
}
export interface DataEntity {
  id: string | number;
  type: string;
  attributes: Attributes1;
}
export interface Attributes1 {
  id: number | string;
  name: string;
  created_at: string;
  updated_at: string;
}
export interface Range {
  data: Data;
}
export interface Data {
  id: string | number;
  type: string;
  attributes: RangeAttributes;
}
export interface RangeAttributes {
  id: number;
  start_range: number;
  end_range: number;
  start_range_text: string;
  end_range_text: string;
  created_at: string;
  updated_at: string;
}

export interface DestroyQuestions {
  id: string | number;
  _destroy: boolean;
}

export interface DestroyOptions {
  id: string | number;
  options_attributes: OptionsAttributes;
}
export interface OptionsAttributes {
  id: number | string;
  name: string;
  error: boolean;
  _destroy?: boolean;
}

export interface ErrorsEntity {
  send_to?: string | null;
  end_date?: string | null;
}


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  startSurvey: boolean;
  radioValue: string;
  sendSurveyModal: boolean;
  sendConfirmationModal: boolean;
  toggleQuestionTypeSelect: boolean;
  surveyTitle: string;
  surveyDescription: string;
  surveyStatus: string;
  sendTo: string;
  endDate: null;
  isCalenderOpen: boolean;
  questionsList: any;
  calenderPopover: boolean;
  CreateSurveysErrors: {
    surveyTitle: boolean,
    survetTitleWordLimit: boolean,
    surveyDescription: boolean,
    surveyDescriptionWordLimit: boolean,
    sendTo: boolean,
    endDate: boolean,
    questionsList: boolean,
  },
  isSubmit: boolean,
  isSendDisabled: boolean,
  isFormValid: boolean,
  id: number,
  destroyQuestionIds: DestroyQuestions[],
  destroyOptionsIds: DestroyOptions[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
type Type = "range" | "multiple_choice" | "text";

export default class NpCreateSurveysController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createSurveyApiId: string = '';
  specificSurveyApiId: string = "";
  editSurveyAPICall: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      startSurvey: false,
      radioValue: "",
      sendSurveyModal: false,
      sendConfirmationModal: false,
      toggleQuestionTypeSelect: false,
      surveyTitle: '',
      surveyDescription: '',
      surveyStatus: 'draft',
      sendTo: '',
      endDate: null,
      isCalenderOpen: false,
      questionsList: [{
        title: "",
        q_type: 'text', 
        toggleQuestionTypeSelect: false,
        error: true,
        isFormValid: false,
      }],
      calenderPopover: false,
      CreateSurveysErrors: {
        surveyTitle: false,
        survetTitleWordLimit: false,
        surveyDescription: false,
        surveyDescriptionWordLimit: false,
        sendTo: false,
        endDate: false,
        questionsList: false,
      },
      isSendDisabled: true,
      isSubmit: false,
      isFormValid: false,
      id: 0,
      destroyQuestionIds: [],
      destroyOptionsIds: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.createSurveyApiId) {
      this.handleSurveyDataResponse(responseJson)
    }
    if(apiRequestCallId === this.editSurveyAPICall){
      this.handleEditAPIResponse(responseJson)
    }
    if(apiRequestCallId === this.specificSurveyApiId) {
    const transformedData: QuestionResponse[] = responseJson.data.data.attributes.questions.data.map((question: QuestionResponse) => {
      const { attributes: { title,id, q_type, ...restAttributes } } = question;
    
      const transformedQuestion: PayloadQuestion = {
        id: id,
        title: title.trim(),
        q_type,
        toggleQuestionTypeSelect: false,
        error: false,
        isFormValid: false,
        ...(q_type === "multiple_choice" && {
          single_answer: restAttributes.single_answer,
          multi_answer: restAttributes.multi_answer,
          choose_limit: restAttributes.choose_limit || 1,
          options_attributes: restAttributes.options?.data?.map((option) => ({
            id: option.attributes.id,
            name: option.attributes.name,
            error: false,
          })),
        }),
        ...(q_type === "range" && {
          range_attributes: {
            id: restAttributes.range?.data.id,
            start_range: restAttributes.range?.data.attributes.start_range,
            end_range: restAttributes.range?.data.attributes.end_range,
            start_range_text: restAttributes.range?.data.attributes.start_range_text,
            end_range_text: restAttributes.range?.data.attributes.end_range_text,
          },
        }),
      };
      return transformedQuestion;
    });
      this.setState({ 
        surveyTitle: responseJson.data.data.attributes.title,
        surveyDescription: responseJson.data.data.attributes.description,
        questionsList: transformedData

      })
    }

    // Customizable Area End
  }

  handleSurveyDataResponse = (responseJson: any) => {
    if (responseJson?.error || responseJson?.errors) {
      const errorMsg = responseJson?.error || "Please select end date and whom to send"
      toast.error(`${errorMsg}`);
    } else {
      this.setState({ 
        sendConfirmationModal: true, 
        sendSurveyModal: false,
        surveyTitle: '',
        surveyDescription: '',
        sendTo: '',
        endDate: null,
        questionsList: [{
          title: '',
          q_type: 'text', 
          toggleQuestionTypeSelect: false,
          error: true,
          isFormValid: false,
        }],
      })
    }
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    const id = this.props.navigation.getParam("id");
   
    if (id) {
      this.setState({ id }, () => {
        this.getSpecificSurvey(id);
      });
    }
  }

  handleEditAPIResponse = (responseJson: QuestionResponse) => {
    if (responseJson?.error || responseJson?.errors) {
      const errorMsg = responseJson?.error || "Please select end date and whom to send"
      toast.error(`${errorMsg}`);
    } else {
      this.setState({ 
        sendSurveyModal: false,
        sendConfirmationModal: true, 
        surveyTitle: '',
        surveyDescription: '',
        sendTo: '',
        endDate: null,
        questionsList: [{
          title: '',
          q_type: 'text', 
          toggleQuestionTypeSelect: false,
          error: true,
          isFormValid: false,
        }],
      })
    }
  }
  textValidation = (e: any) => {
    if (e?.target?.value?.trim() === "") {
      this.setState({
        CreateSurveysErrors: { ...this.state.CreateSurveysErrors, [e?.target.name]: true },
      });
    } else {
      this.setState({
        CreateSurveysErrors: { ...this.state.CreateSurveysErrors, survetTitleWordLimit: false, surveyDescriptionWordLimit: false, [e?.target.name]: false },
      });
    }
  }

  handleSurveyTitle = (e: any) => {
    this.textValidation(e) 
    this.setState({surveyTitle: e?.target?.value})
  }
  handleSurveyDescription = (e: any) => {
    this.textValidation(e)
    this.setState({surveyDescription: e?.target?.value})
  }
  handleSendToChange = (e: any) => {
    this.textValidation(e)
    this.setState({sendTo: e.target.value})
    this.checkSendValidation()
  }
  handleEndDate = (date: any) => {
    this.setState({
      endDate: date,
      CreateSurveysErrors: { ...this.state.CreateSurveysErrors, endDate: false }
    });
    this.checkSendValidation()
  }
  
  handleChange = (event: any) => {
    this.setState({radioValue: event.target.value});
  };
  handleEndSurvey = () => {
    this.setState({startSurvey: false})
  }
  handleQuestionDelete = (item: any, i: number) => {
    const questionsListCopy = [...this.state.questionsList];
    const indexToRemove = questionsListCopy.findIndex((ques) => ques.title === item.title);
  
    // Remove the question based on index
    if (indexToRemove !== -1) {
      const questionToRemove =  questionsListCopy.splice(indexToRemove, 1)[0];
  
      // Update destroyQuestionIds if question has id
      if (questionToRemove.id) {
        this.setState({
          questionsList: questionsListCopy,
          destroyQuestionIds: [...this.state.destroyQuestionIds, {id: questionToRemove.id, _destroy: true}],
        });
      } else {
        this.setState({
          questionsList: questionsListCopy
        })
      }
    }
  };

  handleAnswerCountCheck = (e: any, item: any, i: number) => {
    const questionsList = [...this.state.questionsList]
    const obj = {
      ...item,
      single_answer: e.target.name === 'singleAnswer',
      multi_answer: e.target.name === 'multiAnswer'
    }
    questionsList[i] = obj
    this.setState({questionsList})
  }
  handleChoiseOptionCount = (e: any, item: any, i: number) => {
    const { value } = e.target;
    const questionsList = [...this.state.questionsList]
    const obj = {
      ...item,
      choose_limit: value
    }
    questionsList[i] = obj
    this.setState({questionsList})
  } 
  handleQuestionTitleChange = (e: any, item: any, i: number) => {
    const questionsList = [...this.state.questionsList] 
    let obj = {
      ...item,
      title: e.target.value,
      error: e.target?.value.trim() === "",
    }
    questionsList[i] = obj 
    this.setState({questionsList})
  }
  handleQuestionTypeToggle = (item: any, i: number) => {
    const questionsList = [...this.state.questionsList]
    const obj = {
      ...item,
      toggleQuestionTypeSelect: !item.toggleQuestionTypeSelect, 
    }
    questionsList[i] = obj
    this.setState({questionsList})
  }
  handleQuestionTypeSelect = (type: Type, item: any, i: number) => {
    const questionsList = [...this.state.questionsList]
    const obj = {
      q_type: type,
      title: "", 
      error: true,
      toggleQuestionTypeSelect: !item.toggleQuestionTypeSelect,
      ...this.getTypeObjectJson(type),
    }
    questionsList[i] = obj
    this.setState({questionsList})
  }
  handleRange = (e: any, item: any, i: number) => {
    const questionsList = [...this.state.questionsList]
    const obj = {
      ...item,
      range_attributes: {
        ...item.range_attributes,
        [e.target.name]: e.target.value
      },
      toggleQuestionTypeSelect: false,
    }
    questionsList[i] = obj
    this.setState({questionsList})
  }
  handleAddMultiOption = (item: any, i: number) => {
    const questionsList = [...this.state.questionsList]
    let optionsAttrib = {
      name: '',
      error: true,
    }
    const obj = {
      ...item,
      options_attributes: [
        ...item.options_attributes,
        optionsAttrib
      ], 
      toggleQuestionTypeSelect: false,
    }
    questionsList[i] = obj
    this.setState({questionsList})
  }
  handleDeleteMultiOption = (option: any, item: any, i: number) => {
    const questionsListCopy = [...this.state.questionsList];
    const optionsToUpdate = questionsListCopy[i].options_attributes;
  
    const optionToRemoveIndex = optionsToUpdate.findIndex(
      (op: OptionsAttributes) => op.name === option.name
    );
    if (optionToRemoveIndex !== -1) {
      const removedOption = optionsToUpdate.splice(optionToRemoveIndex, 1)[0];
  
      // Update the object in questionsList
      questionsListCopy[i] = {
        ...item,
        options_attributes: optionsToUpdate,
        toggleQuestionTypeSelect: false,
      };

      if (questionsListCopy[i].id) {
        this.setState({
          destroyOptionsIds: [
            ...this.state.destroyOptionsIds,
            {
              id: questionsListCopy[i].id,
              options_attributes: { ...removedOption, _destroy: true },
            },
          ],
        });
      }

      this.setState({
        questionsList: questionsListCopy,
      });
    }
  };
  
  handleMultiOptionText = (e: any, option: any, opIndex: number, item: any, i: number) => {
    const questionsList = [...this.state.questionsList]
    const optionToUpdate = item.options_attributes[opIndex];
    let newOption, optionsAttribList = [...item?.options_attributes];

    if (optionToUpdate?.id) {
      optionToUpdate.name = e.target.value;
      optionToUpdate.error = e.target.value.trim() === "";
    } else {
      newOption = {
        name: e.target.value,
        error: e.target.value.trim() === "",
      };
      optionsAttribList[opIndex] = newOption;
    }
    const obj = {
      ...item,
      options_attributes: optionsAttribList,
      toggleQuestionTypeSelect: false,
    };
    questionsList[i] = obj;
    this.setState({ questionsList });
  }
  handleRangeText = (e: any, item: any, i: number) => {
    const questionsList = [...this.state.questionsList]
    const obj = {
      ...item,
      range_attributes: {
        ...item.range_attributes,
        [e.target.name]: e.target.value
      }, 
      toggleQuestionTypeSelect: false,
    }
    questionsList[i] = obj
    this.setState({questionsList})
  }
  getTypeObjectJson = (type: Type) => {
    let objDic = {
      multiple_choice: {
        single_answer: true,
        multi_answer: false,
        choose_limit: 1,
        options_attributes: [{
          name: '',
          error: true,
        }],
        error: true,
        isFormValid: false,
      },
      text: {},
      range: {
        range_attributes: {
          start_range: 1,
          end_range: 5,
        },
        error: true,
        isFormValid: false,
      } 
    }
    return objDic[type] 
  }
  addNewQuestion = () => {
    const questionsListRef = [...this.state.questionsList]
    
    this.setState({
      questionsList: [...questionsListRef,  
        {
          title: "",
          q_type: 'text', 
          toggleQuestionTypeSelect: false,
          error: true,
          isFormValid: false,
        }
      ]
    })
  }

  checkSendValidation = () => {
    if (this.state.CreateSurveysErrors.sendTo && this.state.CreateSurveysErrors.endDate && this.state.endDate === null ) {
      this.setState({isSendDisabled: true})
    } else {
      this.setState({isSendDisabled: false})
    }
  }

  isQuestionValid = (question: any) => {
    if (question.error) return false;
  
    if (question.options_attributes?.length > 0) {
      for (const option of question.options_attributes) {
        if (option.error) return false;
      }
    }
  
    return true;
  };
  
  isFormValid = () => {
    for (const question of this.state.questionsList) {
      if (!this.isQuestionValid(question)) return false;
    }
  
    if (this.state?.surveyDescription?.trim() === "") {
      this.setState({
        CreateSurveysErrors: { ...this.state.CreateSurveysErrors, surveyDescription: true },
      });
      return false;
    }
  
    if (this.state?.surveyTitle?.trim() === "") {
      this.setState({
        CreateSurveysErrors: { ...this.state.CreateSurveysErrors, surveyTitle: true },
      });
      return false;
    }

    if (this.state.surveyTitle.length > 500) {
      this.setState({
        CreateSurveysErrors: { ...this.state.CreateSurveysErrors, survetTitleWordLimit: true },
      });
      return false;
    }

    if (this.state.surveyDescription.length > 500) {
      this.setState({
        CreateSurveysErrors: { ...this.state.CreateSurveysErrors, surveyDescriptionWordLimit: true },
      });
      return false;
    }
  
    return true;
  };
  
  // Main validation function
  checkCreateFormValidation = () => {
    this.setState({ isSubmit: true });
  
    if (this.isFormValid()) {
      this.setState({ sendSurveyModal: true });
    }
  };

  handleSurveyConfirmationDialog = () => {
    this.setState({ 
      sendConfirmationModal: false, surveyTitle: '',
      surveyDescription: '',
      sendTo: '',
      endDate: null,
    })
    this.props.navigation.navigate("NpSurveys")
  }

  getSpecificSurvey = async(id: number) => {
    const header = {
      "Content-Type": "application/json",
      type: 'non_profit_entity',
      token: localStorage.getItem('nonprofitToken')
    };
    
    this.specificSurveyApiId = await apiCall({
      endPoint: `bx_block_surveys/surveys/${id}`,
      method: "GET",
      header,
    });
  }

  editSurvey = async(status: string) => {
    this.setState({surveyStatus: status})
    let questionsListCopy = [...this.state.questionsList]
    const combinedArray = questionsListCopy.concat(this.state.destroyQuestionIds)
    const finalArray = combinedArray.concat(this.state.destroyOptionsIds)
    
    const body = {
      data: {
        attributes: {
          title: this.state.surveyTitle,
          description: this.state.surveyDescription,
          send_to: this.state.sendTo,
          status: status,
          end_date: this.state.endDate,
          questions_attributes: finalArray,
        }
      }, 
    }
    const header = {
      type: 'non_profit_entity',
      token: localStorage.getItem('nonprofitToken'),
      "Content-Type": "application/json",
    };
    
    this.editSurveyAPICall = await apiCall({
      endPoint: `bx_block_surveys/surveys/${this.state.id}`,
      method: "PUT",
      header,
      body: JSON.stringify(body)
    });
  }

  createSurvey = async (status: string) => {
    this.setState({surveyStatus: status})
    const header = {
      "Content-Type": "application/json",
      type: 'non_profit_entity',
      token: localStorage.getItem('nonprofitToken')
    };
    const attrs = {
      data: {
        attributes: {
          title: this.state.surveyTitle,
          description: this.state.surveyDescription,
          send_to: this.state.sendTo,
          status: status,
          end_date: this.state.endDate,
          questions_attributes: this.state.questionsList,
        }
      }, 
    }

    this.createSurveyApiId = await apiCall({
      body: JSON.stringify(attrs),
      endPoint: `bx_block_surveys/surveys`,
      method: "POST",
      contentType: "application/json",
      header,
    });
  }
  // Customizable Area End
}
