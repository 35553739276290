import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  FormControl,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  FormGroup,
  TextField,
  Grid,
  Dialog,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ContentManagement from "../../ContentManagement/src/ContentManagement";
import { icons } from "../../../components/src/constants";
// @ts-ignore
import classesoutercss from "./NpCreateSurveys.module.css";
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ShortTextRoundedIcon from '@material-ui/icons/ShortTextRounded';
import LinearScaleRoundedIcon from '@material-ui/icons/LinearScaleRounded';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "react-datepicker/dist/react-datepicker.css";
import MomentUtils from "@date-io/moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import NpCreateSurveysController, {
  Props,
} from "./NpCreateSurveysController";

type Option = { value: string; name: string; icon: Element };
type Type = "range" | "multiple_choice" | "text";
class NpCreateSurveys extends NpCreateSurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  
  
  CreateSurveyHeader = () => {
    return (
      <>
        <Box className={classesoutercss.toolBar}>
          <Box className={classesoutercss.toolSurveyHeader}>
            <Typography variant="subtitle1">Creating Survey</Typography>
            <div className={classesoutercss.addQuestion}>
              <Typography  variant="subtitle2" className={classesoutercss.surTotalQue}>Total question - {this.state.questionsList.length}</Typography>
              <div className={classesoutercss.addQuestionBox}>
                <Button 
                  className={classesoutercss.descriptionSubmitBtn} data-test-id="add-question"
                  variant="contained"
                  onClick={() => this.addNewQuestion()}
                >
                  <span className={classesoutercss.addIcon}>+</span>  Add question
                </Button>
              </div>  
            </div>
          </Box>
        </Box>
      </>
    )
  }

  CreateSurveyForm = () => {
    return (
          <Box className={classesoutercss.surveyFormWrap}>
            <Box className={classesoutercss.labelAndInput}>
              <label>Give survey title</label><br />
              <TextField
                variant="outlined"
                className={classesoutercss.textFieldDesigned}
                placeholder="Write here..."
                name="surveyTitle"
                id="survey_title"
                data-test-id="txtInputSurvey"
                type="text"
                value={this.state.surveyTitle}
                onChange={(e) =>this.handleSurveyTitle(e)}
                onBlur={(e) => this.textValidation(e)}
              />
              {this.state.CreateSurveysErrors.surveyTitle && <div style={{ color: 'red' }}>Please enter survey title</div>}
              {this.state.CreateSurveysErrors.survetTitleWordLimit && <div style={{ color: 'red' }}>500 words limit extends</div>}
            </Box>
            <Box className={classesoutercss.labelAndInput}>
              <label>Give survey description</label><br />
              <TextField
                variant="outlined"
                className={classesoutercss.textFieldDesigned}
                placeholder="Write here..."
                name="surveyDescription"
                id="survey_description"
                data-test-id="txtInputDescription"
                value={this.state.surveyDescription}
                type="text"
                helperText="500 words limit"
                onChange={(e) =>this.handleSurveyDescription(e)}
                onBlur={(e) => this.textValidation(e)}
              />
              {this.state.CreateSurveysErrors.surveyDescription && <div style={{ color: 'red' }}>Please enter survey description</div>}
              {this.state.CreateSurveysErrors.surveyDescriptionWordLimit && <div style={{ color: 'red' }}>500 words limit extends</div>}
            </Box>
          <div className={classesoutercss.rateQuetionBlocks}>
            {
              this.state.questionsList.length > 0 && this.state.questionsList.map((item: any, i: number) => (
                this.CreateQuestionFrame(item, i)
              ))
            }
          </div>
          <div className={classesoutercss.addQuestionBottomBtn}>
            <Button 
              onClick={() => this.checkCreateFormValidation()}
              className={classesoutercss.createBtn} data-test-id="btnCreate"
              variant="contained">
                create
            </Button>
            <Button 
              onClick={() => this.props.navigation.navigate("NpSurveys")}
              className={classesoutercss.cancelBtn} data-test-id="btnCloseModal"
              variant="contained">
                Cancel
            </Button>
          </div>  
        </Box>
    )
  }

  TypeSelectOptions = [
    {
      value: 'multiple_choice',
      name: 'Multi choice',
      icon: <CheckBoxOutlineBlankIcon />
    },
    {
      value: 'text',
      name: 'A text',
      icon: <ShortTextRoundedIcon />
    },
    {
      value: 'range',
      name: 'A range',
      icon: <LinearScaleRoundedIcon/>
    }
  ]

  typeDict = (type: Type) => {
    const data = {
      "range": this.TypeSelectOptions[2],
      "text": this.TypeSelectOptions[1],
      "multiple_choice": this.TypeSelectOptions[0],
    }
    return data[type]
  }

  renderSelectOptions = (item: any, index: number) => {
    return (
      <>
      {
        this.TypeSelectOptions.map((element: any, i: number) => (
          <Box data-test-id={`type-select-option-${i}`} key={element.value} className={classesoutercss.customSelectInner} onClick={() => {this.handleQuestionTypeSelect(element.value, item, index)}}>
            <Box className={classesoutercss.customSelectIcon}>
              {element.icon}
              <Typography variant="h6" className={classesoutercss.customSelectText}>{element.name}</Typography>
            </Box>
          </Box>
        ))
      }
      </>
    )
  }

  SelectQuestionType = (item: any, i: number) => {
    return (
      <>
        <Box className={classesoutercss.customSelectWrapper}>
          <Box data-testId={`select-type-${i}`} className={classesoutercss.customSelect} onClick={() => this.handleQuestionTypeToggle(item, i)}>
            <Box className={classesoutercss.customSelectIcon}>
              {/* @ts-ignore */}
              {this.typeDict(item.q_type)?.icon}
              {/* @ts-ignore */}
              <Typography variant="h6" className={classesoutercss.customSelectText}>{this.typeDict(item.q_type)?.name}</Typography>
            </Box>
            <div>
              <KeyboardArrowDownIcon/>
            </div>
          </Box>
          {
            item.toggleQuestionTypeSelect &&
            <div className={classesoutercss.customSelectShow}>
              {this.renderSelectOptions(item, i)}
            </div>  
          }
        </Box>  
      </>
    )
  }

  CreateQuestionFrame = (item: any, index: number) => {
    return (
      <div key={index}>
        <Grid container>
          <Grid item xs={7}> 
            <Box>
              <label className={classesoutercss.labelAndInputQue}>Write question {index + 1}</label><br /> 
            </Box>
            {/* @ts-ignore */}
            {this.QuestionBlockDict(item.q_type, item, index)}
          </Grid>
          <Grid data-testId={`toggleType-${index}`} item xs={5} onClick={() => {this.setState({toggleQuestionTypeSelect: false})}}>
            <Box className={classesoutercss.surveyQueMain} style={{pointerEvents: item.id ? "none": "auto"}}>
              <Box className={classesoutercss.surveySelectBox}>
                {this.SelectQuestionType(item, index)}
              </Box>
              {
                item.q_type === 'multiple_choice' && 
                <Box className={classesoutercss.descSelectAnswer}>
                    {/* <FormGroup className={classesoutercss.answerSelectSection}> */}
                    <FormControlLabel
                      data-test-id="singleAnswer"
                      control={
                     <Checkbox
                        edge="end"
                        color="primary"
                        name={'singleAnswer'}
                        onChange={(e) => this.handleAnswerCountCheck(e, item, index)}
                        //inputProps={{ 'aria-labelledby': index }}
                        checked={item.single_answer}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                          <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                      />
                    }
                    label="Single answer"
                  />
                  <FormControlLabel
                      control={
                    <Checkbox
                      edge="end"
                      color="primary"
                      name={'multiAnswer'}
                      data-testId="multiAnswer"
                      onChange={(e) => this.handleAnswerCountCheck(e, item, index)}
                      //inputProps={{ 'aria-labelledby': index }}
                      checked={item?.multi_answer}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={
                        <CheckBoxIcon style={{ color: "#359ce2" }} />
                      }
                    />
                    }
                    label="Multi answer"
                  />
                      {/* <FormControlLabel className={classesoutercss.descCheckBox} control={<input type="checkbox" checked={true} onChange={(e) => this.handleAnswerCountCheck(e, item, index)} name="checkedA" />} label="Single Answer" />
                      <FormControlLabel className={classesoutercss.descCheckBox} control={<input type="checkbox" checked={false} onChange={(e) => this.handleAnswerCountCheck(e, item, index)} name="checkedB" />} label="Multi Answer" />  */}
                    {/* </FormGroup> */}
                </Box>
              }
              {
                (item.multi_answer && item.q_type === 'multiple_choice') && 
                  <Box className={classesoutercss.descChooseAnswer}>
                    <Typography>How many answer should be chosen?</Typography>
                    <Box className={classesoutercss.valueOptionField}>
                      <div>
                        <select
                        value={item.choose_limit}
                        onChange={(e) => this.handleChoiseOptionCount(e, item, index)}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </select>
                      </div>
                    </Box>    
                  </Box>
              }
            </Box>  
          </Grid>
        </Grid>
      </div>
    )
  }

  radioQuestionBlock = (item: any, i: number) => {
    return (
      <>
        <Box className={classesoutercss.labelAndInputFlex}>
          <TextField
            variant="outlined"
            className={classesoutercss.textFieldDesigned}
            placeholder="Write here..."
            name="question_description"
            id="question_description"
            data-test-id="txtInput"
            type="text"
          />
        </Box>
        <Box className={classesoutercss.labelAndInputFlex}>  
          <FormControl component="fieldset" className={classesoutercss.radioSurveySection}>
            <RadioGroup aria-label="option" name="option1" value={this.state.radioValue} onChange={(e) => this.handleChange(e)}>
              <FormControlLabel className={classesoutercss.descSurveyBox} value="option 1" control={<Radio />} label="option 1" />
              <FormControlLabel className={classesoutercss.descSurveyBox} value="Add option" control={<Radio />} label="Add option" />
            </RadioGroup>
          </FormControl>
        </Box>
      </>  
    )
  }

  textQuestionBlock = (item: any, i: number) => {
    return (
      <>
        <Box className={classesoutercss.labelAndInputFlex}>
          <TextField
            variant="outlined"
            className={classesoutercss.textFieldDesigned}
            name="question_description"
            placeholder="Write here..."
            id="question_description"
            data-test-id={`txtInputFirstName-${i}`}
            type="text"
            helperText="500 words limit"
            value={item?.title}
            onChange={(e) => this.handleQuestionTitleChange(e, item, i)}
          />
         <Box className={classesoutercss.deleteIcon}>
            <IconButton
              color={"inherit"}
              aria-label="delete"
              data-testId={`delete-icon-${i}`}
              onClick={() => this.handleQuestionDelete(item, i)}
            >
                <img src={icons.deleteIcon} alt="delete"/>
            </IconButton>
          </Box>
        </Box>
        {item.error && this.state.isSubmit && <div style={{ color: 'red', left: 10, position: 'relative', marginBottom: 10 }}>Field required</div>}
      </>
    )
  }

  renderMultiOptions = (item: any, i: number) => {
    return (
      <>
      {
        item.options_attributes && item.options_attributes.map((option: any, opIndex: number) => (
          <>
            <Box className={classesoutercss.commSelectOptions}>
              <Box className={classesoutercss.surveyCheckBox}>
                {
                  item.multi_answer ?
                  <CheckBoxOutlineBlankIcon/> :
                  <RadioButtonUncheckedIcon />
                }
                <input data-testId="multi-option-text" key={opIndex} placeholder={`Option ${opIndex + 1}`} value={option.name} type="text" onChange={(e) => this.handleMultiOptionText(e, option, opIndex, item, i)} name="checkedA" />
              </Box>
                <Box className={classesoutercss.deleteIcon} onClick={() => this.handleDeleteMultiOption(option, item, i)}>
                <IconButton
                    color={"inherit"}
                    aria-label="delete"
                    >
                    <img src={icons.deleteIcon} alt="delete"/>
                </IconButton>
              </Box> 
            </Box>  
            {option.error && this.state.isSubmit && <div style={{ color: 'red', position: 'relative', marginBottom: 10 }}>Field required</div>}
          </>
        ))
      }
      </>
    )
  }
  checkboxQuestionBlock = (item: any, i: number) => {
    return (
      <>
        <Box className={classesoutercss.labelAndInputFlex}>
          <TextField
            variant="outlined"
            className={classesoutercss.textFieldDesigned}
            placeholder="Write here..."
            name="multi_question_title"
            id="multi_question_title"
            data-test-id="multiQustionDesc"
            type="text"
            value={item?.title}
            helperText="500 words limit"
            onChange={(e) => this.handleQuestionTitleChange(e, item, i)}
          />
          <Box className={classesoutercss.deleteIcon}>
            <IconButton
              color={"inherit"}
              aria-label="delete"
              onClick={() => this.handleQuestionDelete(item, i)}
            >
              <img src={icons.deleteIcon} alt="delete"/>
            </IconButton>
          </Box>
        </Box>
        {item.error && this.state.isSubmit && <div style={{ color: 'red', position: 'relative', marginBottom: 10 }}>Field required</div>}
        <Box className={classesoutercss.labelAndInputFlex}>
          <FormControl component="fieldset" className={classesoutercss.checkSurveySection}>
            <FormGroup className={classesoutercss.commSelectOptions}>
                {this.renderMultiOptions(item, i)}
                <Box className={classesoutercss.surveyCheckBox} >
                  {
                    item.multi_answer ?
                    <CheckBoxOutlineBlankIcon/> :
                    <RadioButtonUncheckedIcon />
                  }
                  <input className={classesoutercss.pointer} value="Add Option" type="text" onClick={() => this.handleAddMultiOption(item, i)} name="checkedB" readOnly/>  
                </Box>
              </FormGroup>
          </FormControl>
        </Box>
      </>
    )
  }

  rangeQuestionBlock = (item: any, i: number) => {
    return (
      <>
        <Box className={classesoutercss.labelAndInputFlex}>
          <TextField
            variant="outlined"
            className={classesoutercss.textFieldDesigned}
            placeholder="Write here..."
            name="rating_service"
            id="rating_service"
            data-test-id="rateServiceText"
            type="text"
            helperText="500 words limit"
            value={item.title}
            onChange={(e) => this.handleQuestionTitleChange(e, item, i)}
          />
          <Box className={classesoutercss.deleteIcon}>
            <IconButton
              color={"inherit"}
              aria-label="delete"
              onClick={() => this.handleQuestionDelete(item, i)}
            >
              <img src={icons.deleteIcon} alt="delete"/>
            </IconButton>
          </Box>
        </Box>
        {item.error && this.state.isSubmit && <div style={{ color: 'red', position: 'relative', marginBottom: 10 }}>Field required</div>}
        <Box className={classesoutercss.valueOptionField}>
          <div>
            <select name='start_range' data-testId="start_range" value={item?.range_attributes?.start_range} onChange={(e) => this.handleRange(e, item, i)}>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          </div>
          <Typography variant="subtitle1">to </Typography>
          <div>
            <select defaultValue={5} data-testId="end_range" value={item?.range_attributes?.end_range} name="end_range" onChange={(e) => this.handleRange(e, item, i)}>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>  
        </Box>
        <div className={classesoutercss.labelAndInputRateWrap}>
          <Box className={classesoutercss.labelAndInputRating}>
            <label>{item?.range_attributes?.start_range || "1"}</label>
            <TextField
                variant="standard"
                className={classesoutercss.textFieldDesigned}
                value={item?.range_attributes?.start_range_text}
                name="start_range_text"
                id="rating_weak"
                data-test-id="rateService"
                type="text"
                onChange={(e) => this.handleRangeText(e, item, i)}
              />
          </Box>
          <Box className={classesoutercss.deleteIcon}>
            <IconButton
                color={"inherit"}
                aria-label="delete"
              >
                <img src={icons.deleteIcon} alt="delete"/>
            </IconButton>
          </Box>
        </div>  
        <div className={classesoutercss.labelAndInputRateWrap} style={{ marginBottom: "20px"}}>  
          <Box className={classesoutercss.labelAndInputRating}>
            <label>{item?.range_attributes?.end_range || "5"}</label>
            <TextField
                variant="standard"
                className={classesoutercss.textFieldDesigned}
                placeholder="Write Here..."
                name="end_range_text"
                id="rating_strong"
                data-test-id="rateService"
                type="text"
                value={item?.range_attributes?.end_range_text}
                onChange={(e) => this.handleRangeText(e, item, i)}
              />
          </Box>
          <Box className={classesoutercss.deleteIcon}>
            <IconButton
                color={"inherit"}
                aria-label="delete"
              >
                <img src={icons.deleteIcon} alt="delete"/>
            </IconButton>
          </Box>
        </div>  
      </>
    )
  }

  QuestionBlockDict = (type: Type, item: any, i: number) => {
    const blockType = {
      text: this.textQuestionBlock(item, i),
      radio: this.radioQuestionBlock(item, i),
      multiple_choice: this.checkboxQuestionBlock(item, i),
      range: this.rangeQuestionBlock(item, i),
    }

    return blockType[type]
  }

  endDateField(classes: any) {
    return (
      <div className={classesoutercss.divWrapper}>
        <label className={classesoutercss.labelAndInputQue}>Select End Date</label>
        <div className={classesoutercss.divWrapperInput}>
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: red;
              color: #000;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }
            .MuiDialogActions-spacing {
              justify-content: flex-end;
            }
            `}
        </style>
        <InitiateThemeProvider>
          <KeyboardDatePicker
            autoOk
            minDate={new Date()}
            fullWidth
            disableToolbar
            inputVariant="outlined"
            format="DD/MM/YYYY"
            margin="normal"
            id="date-picker-inline"
            data-testId="date-picker-inline"
            placeholder="select from here"
            className={classesoutercss.dateSelectVal}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            name="endDate"
            value={this.state.endDate}
            onChange={(date: any) => this.handleEndDate(date)}
            onBlur={(date: any) => this.textValidation(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            keyboardIcon={<KeyboardArrowDownIcon/>}
            style={{ fontSize: 16, }}
          />
          </InitiateThemeProvider>
          {/* <Button disabled={false} onClick={() => this.setState({ isCalenderOpen: true })}>
            <KeyboardArrowDownIcon/>
          </Button> */}
          {this.state.CreateSurveysErrors.endDate && <div style={{ color: '#ff0000' }}>Please select end date</div>}
        </div>
      </div>
    );
  }

  sendSurveyDialog = () => {
    return (
      <>
        <Dialog
          hideBackdrop
          fullScreen={false}
          open={this.state.sendSurveyModal}
          onClose={() => this.setState({ sendSurveyModal: false })}
          aria-labelledby="responsive-dialog-title"
          data-testId='closeModelSend'
          PaperProps={{
              style: { borderRadius: 16, width: 500, boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)" }
          }}>
          <div className={classesoutercss.surDialogTitle}>
              Survey sent to
          </div>
          <div className={classesoutercss.surModalRadio}>
            <Box className={classesoutercss.labelAndInput}>
              <label className={classesoutercss.labelAndInputQue}>Select whom you want to sent</label><br />  
              <FormControl component="fieldset" className={classesoutercss.radioSurveySection}>
                  <FormControlLabel data-test-id="check-1" className={classesoutercss.surveyModalRadio} value="Registered users" control={<Radio checked={this.state.sendTo === 'registered_users'} value={'registered_users'} name="sendTo" onBlur={(e) => this.handleSendToChange(e)} onChange={(e) => this.handleSendToChange(e)}/>} label="Registered users" />
                  <FormControlLabel data-test-id="check-2" className={classesoutercss.surveyModalRadio} value="Followers" control={<Radio checked={this.state.sendTo === 'followers'} value={'followers'} name="sendTo" onBlur={(e) => this.handleSendToChange(e)} onChange={(e) => this.handleSendToChange(e)} />} label="Followers" />
                  <FormControlLabel data-test-id="check-3" className={classesoutercss.surveyModalRadio} value="Everyone" control={<Radio checked={this.state.sendTo === 'everyone'} value={'everyone'} name="sendTo" onBlur={(e) => this.handleSendToChange(e)} onChange={(e) => this.handleSendToChange(e)}/>} label="Everyone" />
              </FormControl>
              {this.state.CreateSurveysErrors.sendTo && <div style={{ color: 'red' }}>Please select whom to sent</div>}
            </Box>
            {this.endDateField(this.props.classes)}
            {/* <Box className={classesoutercss.labelAndInput}> */}
              {/* <DatePicker
                data-test-id="from-date"
                isOpen={this.state.isCalenderOpen}
                onCalendarClose={() => this.setState({ isCalenderOpen: false })}
                // onChange={(e) => {
                //   setFieldValue("date", "true");
                //   this.onDateChange(e);
                // }}
                // value={this.state.value}
                minDate={new Date()}
              /> */}
              {/* <Box className={classesoutercss.calenderBtns}>
                <Button disabled={false} data-testId='cancel' onClick={() => this.setState({ isCalenderOpen: true })}>
                  Cancel
                </Button>
                <Button disabled={false} data-testId='save' onClick={() => this.setState({ isCalenderOpen: true })}>
                  Save
                </Button>
              </Box> */}
              {/* <label className={classesoutercss.labelAndInputQue}>Select end date</label> */}
              {/* <Box data-test-id='calenderOpen' className={classesoutercss.surModalSelectVal}
                onClick={() => this.setState({ isCalenderOpen: true })}
              > */}
                {/* <Box
                  color={this.state.fromDate ? "black" : "#e1d6d6"}
                  style={{ width: '100%', fontSize: 16 }}>
                  <Typography className="text">{this.state.fromDate ? this.formatDate(this.state.fromDate) : "Select date"}</Typography>
                </Box> */}
                {/* <Typography variant="h6" className={classesoutercss.textFieldDesigned}>2017-05-24</Typography>
                <Button disabled={false} onClick={() => this.setState({ isCalenderOpen: true })}>
                  <KeyboardArrowDownIcon/>
                </Button> */}
              {/* </Box> */}
            {/* </Box> */}
          </div>  
          <div className={classesoutercss.surveyModalFormWrap} style={{ display: 'flex', padding: '10px', marginBottom: 20 }}>
            <Button variant="outlined" className={classesoutercss.sendBtn}  data-testId='sendBtn' disabled={!this.state.sendTo || !this.state.endDate} onClick={() => this.state.id ? this.editSurvey("live") : this.createSurvey('live')}>Send</Button>
            <Button variant="contained" className={classesoutercss.saveBtn} style={{textTransform:'none'}} data-testId='saveBtn'  onClick={() => this.state.id ? this.editSurvey("draft") : this.createSurvey('draft')}>Save as draft</Button> 
          </div>
        </Dialog>
      </>
    )
  }

  sendSurveyConfirmationDialog = () => {
    return (
      <>
        <Dialog
          fullScreen={false}
          open={this.state.sendConfirmationModal}
          onClose={() => this.setState({ sendConfirmationModal: false })}
          aria-labelledby="responsive-dialog-title"
          data-testId='closeModelSendConfirm'
          PaperProps={{
              style: { borderRadius: 16, width: 500, boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)" }
          }}>
          <div className={classesoutercss.surveyCompleteText}>
            <CheckCircleOutlineSharpIcon />
            <Typography variant="h6">Congratulations!</Typography>
            <div className={classesoutercss.congratesText}>
              {`Your survey has been ${this.state.surveyStatus === 'draft' ? 'saved' : 'sent'} successfully.`}
            </div>
          </div>  
          <div className={classesoutercss.surveyModalFormWrap} style={{display: 'block', padding: '10px', margin: '10 auto 20' }}>
              <Button variant="outlined" className={classesoutercss.sendBtn}  data-testId='done' onClick={() => this.handleSurveyConfirmationDialog()}>Done</Button> 
          </div>
        </Dialog>
      </>
    )
  }
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          {/* @ts-ignore */}
          <ContentManagement navigation={this.props.navigation} id={""} showBackButton={undefined} title={undefined} showBottomBar={false} showDrawerHeader={false} mobileOpen={false} open={false} classes={undefined} history={undefined} titleColor={undefined} showLine={undefined} cancelClick={undefined} confirmClick={undefined} selectedAdminTab={undefined} tabsFunction={undefined} children={undefined} onSecondTabsCreation={undefined} eventUpdates={undefined} SingleEvent={undefined} single_event_details={undefined} createNeedEvent={undefined} createActivitiesEvent={undefined} projectsEvent={undefined} pledgesDetails={undefined} eventPledgesDetails={undefined} navigationToNeeds={undefined} navigationToActivitiesList={undefined} navigationToProjectsList={undefined} adminSelectedTab={undefined} createEvents={undefined} setSelectedAdminTab={undefined} onEventCreation={undefined} setOfferRoleAction={undefined} offerRoleFormData={undefined} setActivePlan={undefined} resetEventTab={undefined} setSelectedRow={undefined}>
            <Box className={classesoutercss.npSurveyWrapper}>
              {this.CreateSurveyHeader()}
              {this.CreateSurveyForm()}
              {this.sendSurveyDialog()}
              {this.sendSurveyConfirmationDialog()}
            </Box>

          </ContentManagement>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  option: {
    fontSize: 20,
    backgroundColor: '#000',
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    backgroundColor: '#ccc',
    "& .MuiAutocomplete-listbox": {
      backgroundColor: '#ccc',
      "& li:nth-child(even)": { backgroundColor: "#CCC" },
      "& li:nth-child(odd)": { backgroundColor: "#FFF" }
    }
  },
  '& .MuiAutocomplete-popper': {
    backgroundColor: '#000',
  },
  descPagination: {
    "& .Mui-selected": {
      color: '#009BE2'
    }
  },
  textField: {
    padding: 0,
    borderRadius: 12,
    height: 54,
    width: "50%",
    fontFamily: "Rene_Bieder_Milliard_Book",
    border: "none",
    boxShadow: "0px 0px 8px rgba(212, 212, 212, 0.5)",
  },
  fontResize: {
    fontWeight: 400,
    lineHeight: " 20px",
    letterSpacing: "-0.4px",
    color: "black",
  },
  pickerMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  overrides: {
    
      day: {
          color: "light-gray",
          backgroundColor: "white",
          borderRadius:"0px",
      },
  }
};

NpCreateSurveys.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export {NpCreateSurveys}
export default withStyles(webStyle as any, { withTheme: true })(NpCreateSurveys as any);
// Customizable Area End
