import { SAVED_SEARCH_PAYLOAD } from './savedSearchPayloadAction';

const initState = {
    savedPayload: {}
}

function savedSearchPayloadReducer(state = initState, action: any) {
    switch (action.type) {
        case SAVED_SEARCH_PAYLOAD:
            return {
                ...state,
                savedPayload: action.payload
            }
        default:
            return state
    }
}

export default savedSearchPayloadReducer;