import React from "react";

// Customizable Area Start
import { Box, Button, Typography, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "../assets/css/ForgotPasswordSuccessMessage.css";
import { imgLogo, imgCheck } from "./assets";
import { FAMILYFONTS } from "../../../components/src/constants";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { InitiateButton } from "../../../components/src/design-system/button";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";

// Customizable Area Start
// Customizable Area End

export default class ResetSuccessPage extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box className="forgotPwdMsgContainer">
          <img src={imgCheck} className="otpMsg" />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box className="forgotPwdMsg">

            <Box className="successMsgContent">
              <Typography
                variant="h4"
                component="h2"
                className="forgotPwdMsgTitle"
                style={{ ...FAMILYFONTS.family1 }}
              >
                {'Congrats!!'}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="forgotPwdMsgField"
                style={{ ...FAMILYFONTS.family3 }}
              >
                {'Password successfully updated.'}
              </Typography>
            </Box>

            <VerticalSpacer value={100} />

            <InitiateButton
              data-test-id={"btnEmailLogIn"}
              onClick={this.goToSignIn}
              variant="contained"
              color="primary"
              fullWidth
              style={{
                height: '56px',
                marginBottom: '40px !important',
                ...FAMILYFONTS.family1,
                fontSize: 20,
                fontWeight: 500
              }}
            >
              {'Sign In'}
            </InitiateButton>

          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
