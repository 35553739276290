import React, { ReactNode } from 'react';
import { initiateTheme } from './theme';
import { ThemeProvider } from "@material-ui/core/styles";

export function InitiateThemeProvider({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={initiateTheme}>
      {children}
    </ThemeProvider>
  );
}
