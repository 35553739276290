// Customizable Area Start
import React, { useState } from 'react';
import { makeStyles, withStyles, } from '@material-ui/core/styles';
import { IconButton, Dialog, Drawer } from '@material-ui/core';
import { icons } from "../../../components/src/constants";
import { EventPicker } from './EventPicker';
import { isMobile } from "react-device-detect";
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { InitiateButton } from '../../../components/src/design-system/button';

const useEventPickerModalStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    overflow: 'hidden',
    padding: '15px',
    width: '100%',
    margin: '0',
    boxSizing: 'border-box',
    border: '1px solid #ebebeb',
  },
  closeBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: "35px",
    position: "relative",
    top: "-9px",
    right: "-10px"
  },
  drawerContainer: {
    padding: '50px',
    maxWidth: '400px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 20px',
      maxWidth: '90vw',
    },
  },
}));

const CustomModal = withStyles({
  root: {
    //background: 'transparent',
    // @ts-ignore
    position: 'absolute!important',
  },
  paper: {
    // @ts-ignore
    overflowY: 'initial!important',
    margin: '0',
    maxWidth: '100%',
    borderRadius: '30px',
    boxShadow: 'rgba(212, 212, 212, 0.5) 0px 0px 8px',
  },
})(Dialog);



export function EventPickerModal({
  open,
  onClose,
  filterByDate,
}: {
  open: boolean,
  onClose: () => void,
  filterByDate: Date,
}) {

  const classes = useEventPickerModalStyles();
  const [isDisabled, setIsDisabled] = useState(false);

  const isMobile = useIsMobileScreen();
  const EventPickerChild = (<EventPicker
    onSuccess={onClose}
    filterByDate={filterByDate}
    setIsDisabled={setIsDisabled}
     />
  );
  

  if (isMobile)
    return (
      <Drawer anchor={"bottom"} open={open} onClose={onClose}>
        <div className={classes.drawerContainer}>

          <div className={classes.closeBtnContainer}>
            <span />
            <IconButton aria-label="close-modal" onClick={onClose} size="small">
              <img
                src={icons.cancelIcon}
                style={{ height: 32, width: 32, }}
                alt="close" />
            </IconButton>
          </div>
         
          {EventPickerChild}
         <ButtonGroup onClose={onClose} isDisabled={isDisabled} />
        </div>
      </Drawer>
    );


    

  return (
    <CustomModal
      // hideBackdrop
      container={() => document.getElementById('availability-form')}
      open={open}
      onClose={()=>{onClose();
        localStorage.setItem("all_day","false")}}>
      <div className={classes.paper}>

        <div className={classes.closeBtnContainer}>
          <span />
          <IconButton aria-label="close-modal" onClick={()=>{onClose();
          localStorage.setItem("user_selected_date","false")}} size="small">
            <img
              src={icons.cancelIcon}
              style={{ height: 32, width: 32, }}
              alt="close" />
          </IconButton>
        </div>    
        {EventPickerChild}   
    <ButtonGroup onClose={onClose} isDisabled={isDisabled}/>
        </div>
     
    </CustomModal>
  );
}

export function ButtonGroup({onClose,isDisabled}:{ isDisabled?: boolean, onClose: () => void}){
return  (<div style={{display: 'flex',
justifyContent: 'space-around',
alignItems: 'center',paddingBottom:isMobile?'0px':'20px'}}>     
    <InitiateButton
      color="primary"
      variant="contained"
      size="large"
      onClick={()=>{onClose();
        localStorage.setItem("user_selected_date","false");
        localStorage.setItem("all_day","false")}}
      style={{
      width:163, 
      color:'#3da1e4',
      border: '1px solid #3da1e4',
      backgroundColor: 'white',
      boxShadow: 'none',
      padding:'11px',
    }}
    >
      {'Cancel'}
    </InitiateButton> 
    <InitiateButton
      color="primary"
      variant="contained"
      size="large"
      disabled={!!isDisabled}
      onClick={()=>{onClose();
        localStorage.setItem('set_button','true');
      }}
      style={{
        width:163,whiteSpace:'nowrap',padding:'11px', }}
    >
      {'Update Availability'}
    </InitiateButton>
    </div>);

}
// Customizable Area Start
