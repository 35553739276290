import React from "react";
// Customizable Area Start
import AdminRegistrationController, { Props } from "./AdminRegistrationController";
//@ts-ignore
import classOuter from './NpResetPassword.module.css'
import { FAMILYFONTS, images } from "../../../components/src/constants";
import {
  Typography,
  Button,
  Container,
  Box,
  Input,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
// Customizable Area End

class NpResetPassword extends AdminRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    // Reset Password button validation
    const validInputs = (!this.state.newPwd || !this.state.confirmPwd);
    const disabled = (validInputs || this.state.resetPasswordValidation);
    const updatedToken =  window.location?.search.split('=')[1];
    // Customizable Area End
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        {/* App logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.appLogoContainer}>
            <img src={images.app_logo} className={classOuter.appLogo} />
          </div>

          {/* Reset Password Form */}
          <div>

            {/* Form Title */}
            <Box>
              <Typography
                className={classOuter.titleText}
                style={{
                  ...FAMILYFONTS.family1,
                  fontSize: 24,
                  fontWeight: 400
                }}>Reset Password</Typography>
            </Box>

            {/* Form Content */}
            <Box sx={{ width: "100%" }}>

              {/* New Password Input Field */}
              <Box>
                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 14,
                    fontWeight: 400
                  }}
                >
                  New Password
                </Typography>
                <Input
                  data-test-id="txtInputNewPassword"
                  name="newPasswordInput"
                  type={
                    this.state.enableNewPwd ? "password" : "text"
                  }
                  placeholder={"Password"}
                  disableUnderline={true}
                  className={classOuter.inputField}
                  fullWidth={true}
                  value={this.state.newPwd}
                  classes={{
                    root: classOuter.innerInputText
                  }}
                  onChange={(e) => {
                    this.setNewPassword(e.target.value);
                    this.validResetPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        data-test-id={"btneyeShowHide"}
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowNewPassword}
                        edge="end"
                        className={classOuter.pwdHideShowIcon}
                      >
                        {this.state.enableNewPwd ? (
                          <img
                            src={images.eyeCloseIcon1}
                            alt="passwordVisible"
                          />
                        ) : (
                          <img
                            src={images.eyeOpenIcon1}
                            alt="passwordInVisible"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {/* New Password validation error */}
                <Box className={classOuter.errorMsg}>
                  <div className={classOuter.errors}>{this.state.resetPwdError}</div>
                </Box>
              </Box>

              {/* Confirm Password Input Field */}
              <Box>
                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 14,
                    fontWeight: 400
                  }}
                >
                  Confirm Password
                </Typography>
                <Input
                  data-test-id="txtInputConfirmPassword"
                  name="confirmPasswordInput"
                  type={
                    this.state.enableConfirmPwd ? "password" : "text"
                  }
                  placeholder={"Confirm Password"}
                  disableUnderline={true}
                  className={classOuter.inputField}
                  fullWidth={true}
                  value={this.state.confirmPwd}
                  classes={{
                    root: classOuter.innerInputText
                  }}
                  onChange={(e) => {
                    this.setConfirmPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        data-test-id={"btneyeShowHide"}
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowConfirmPassword}
                        edge="end"
                        className={classOuter.pwdHideShowIcon}
                      >
                        {this.state.enableConfirmPwd ? (
                          <img
                            src={images.eyeCloseIcon1}
                            alt="passwordVisible"
                          />
                        ) : (
                          <img
                            src={images.eyeOpenIcon1}
                            alt="passwordInVisible"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {/* Password Min. characters Message  */}
                <Typography
                  variant="h6"
                  component="h2"
                  className={classOuter.inputMsg}
                  style={{
                    ...FAMILYFONTS.family1,
                    fontSize: 14,
                    fontWeight: 400
                  }}
                >
                  (Min.8 Characters)
                </Typography>
                {/* Validation for both the passwords match or not*/}
                <Box className={classOuter.errorMsg}>
                  <div className={classOuter.errors}>{this.state.matchPwdError}</div>
                </Box>
                 {/* Password Updation Error */}
                <Box className={classOuter.errorMsg}>
                  <div className={classOuter.errors}>{this.state.otpError}</div>
                </Box>
              </Box>

              {/* Reset Password Button */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <Button
                  data-test-id="btnResetPassword"
                  variant="contained"
                  color="primary"
                  className={classOuter.footerBtn}
                  fullWidth
                  style={{
                    ...FAMILYFONTS.family2,
                    fontSize: 20,
                    fontWeight: 400,
                    opacity: disabled ? "0.5" : "1",
                    cursor: disabled ? "not-allowed" : "pointer",
                    textTransform: "capitalize",
                  }}
                  disabled={disabled}
                  onClick={() =>  updatedToken ? this.setNewPasswordRoles(updatedToken) : this.gotoResetPassword()}
                >
                  Reset Password
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }
}

export default NpResetPassword;