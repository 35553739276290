// @ts-nocheck 
// Customizable Area Start
import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Scrollbar } from 'react-scrollbars-custom';
import { InitiateThemeProvider } from '../theme/theme.provider';
import {
  Grid,
  Button,
  Typography,
  Drawer,
  Box,
  TextField,
  Switch,
  Paper, IconButton, InputBase, Slider, Tooltip, InputAdornment, Modal,
  Container, Checkbox, InputLabel, ListItemIcon, List, ListItem, Avatar, ListItemAvatar, ListItemSecondaryAction, ListItemText, MenuItem, Select, FormControl
} from "@material-ui/core";
import {
  constants,
  FAMILYFONTS,
  FONTS,
  icons,
  images,
} from "../../../components/src/constants";

import {
  addSearchFilterDetails, onChangeAllDay, onChangeDbsSearch, onChangeEndTime,
  onChangeFromDate, onChangeProximity, onChangeSavedSearch, onChangeStartTime, onChangeToDate, onChangeCategories
} from "../../../framework/src/redux/advanceSearch/advanceSearchActions";
import { connect } from "react-redux";
import { COLORS } from "../../../framework/src/Globals";

import clssesOuter from "./DbsSearch.module.css"
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import moment from "moment";
import { truncate } from 'lodash';
import { isBefore } from "date-fns";
// Customizable Area End

// Customizable Area Start

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const marks = [
  {
    value: 1,
  },
  {
    value: 5,
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
];


const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      overflow: "inherit"
    },
    switchBase: {
      padding: 2,
      color: "#87888F",
      '&$checked': {
        transform: 'translateX(12px)',
        color: COLORS.skyBlue,
        '& + $track': {
          opacity: 1,
          backgroundColor: COLORS.white,
          borderColor: '#009BE2',
        },
      },
    },
    thumb: {
      width: 12,
      height: 11.6,
      boxShadow: 'none',
    },
    track: {
      border: "1px solid rgba(151, 151, 151, 0.43272)",
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
    inputpicker: {
      borderRadius: 16
    },
  }),
)(Switch);
const useStyles = makeStyles((theme: Theme) => createStyles({

  eventPickerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  dateAbil: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    // marginBottom: 40,
    margin: "1rem 0",

  },

  // forms 

  formControl: {
    // margin: theme.spacing(1),
    width: "inharit"
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },

  textField: {
    padding: 0,
    borderRadius: 12,
    borderColor: "none !important",
    height: 42,
    boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.5)',
    border: "none !important",
    '&::-webkit-calendar-picker-indicator': {
      display: "none",
      webkitAppearance: "none"
    }
  },
  // fontResize: {
  //   fontSize: 12,
  //   marginTop:4,
  //   color: "#767676",
  //   width: 150
  // },

  aftersearchIconbutton: {
    borderRadius: "8px",
    color: "text.primary",
    backgroundColor: "white",
    margin: "8px",
    padding: "8px",
    height: 40,
    width: 30,
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: COLORS.white,
    marginLeft: 0,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    height: 48,
    display: "flex",
    // [theme.breakpoints.up("sm")]: {
    // 	marginLeft: theme.spacing(1),
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: COLORS.skyBlue,
  },
  inputRoot: {
    color: "black",
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create("width"),
    fontSize: "revert",
    color: 'black'
  },

  toolBar: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    width: "100%",
    // marginLeft: " 3%",
  },
  sectionDesktop: {
    display: "none",
    // width:"27%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionTabDesktop: {
    display: "flex",
    // width:"27%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },


  // outer css
  "searchreletiveenv": {
    "position": "relative",
    "boxShadow": "0px 0px 8px rgba(212, 212, 212, 0.8)",
    "borderRadius": "12px",
    "zIndex": 2,
    "borderWidth": "1px",
    "boxSizing": "border-box",
    "outline": "0px !important",
    "WebkitBoxAlign": "center",
    "cursor": "default",
    "WebkitBoxPack": "justify",
    "transition": "all 100ms ease 0s"
  },

  searchEnv: {
    "position": "absolute",
    "top": "49px",
    "padding": "10px",
    "width": "inherit",
    "height": 90,
    "zIndex": 4,
    "background": "#FFFFFF",
    "boxShadow": "0px 0px 8px rgba(212, 212, 212, 0.5) !important",
    "borderBottomLeftRadius": "12px !important",
    "borderBottomRightRadius": "12px !important",
    "borderTopLeftRadius": "0% !important",
    "borderTopRightRadius": "0% !important",
  },
  "webkit_scrollbar": {
    "width": "5px"
  },
  "webkit_scrollbar_track": {
    "background": "#f1f1f1"
  },
  "webkit_scrollbar_button": {
    "width": "50px",
    "height": "50px"
  },
  "webkit_scrollbar_thumb": {
    "background": "rgba(212, 212, 212, 0.8)"
  },
  "webkit_scrollbar_thumb_hover": {
    "background": "rgba(212, 212, 212, 0.8)"
  },
  "catdropdownmenubackground": {
    position: "relative",
    "overflowY": "auto"
  },

  sliderBackground: {
    height: 90,
    overflowY: "auto"
  },
  "dbs_tabs_logo": {
    "width": "18px",
    "height": "8px"
  },
  sliderCustome: {
    color: "#009be2",
    fontSize: 48
  },
  textfieldBox: {
    position: "relative",
  },
  calenderIconBox: {
    height: 25,
    width: 25,
    position: "absolute",
    top: 13,
    right: 10,
    '&>img': {
      height: "100%",
      width: "100%"
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative'
  },
  alarmIconBox: {
    height: 30,
    width: 30,
    position: "absolute",
    top: 14,
    right: 4,
    background: '#fff',
    pointerEvents: 'none',
    '&>img': {
      height: "100%",
      width: "100%"
    }
  },
  alarmtextField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 200,
  },
  inputpicker: {
    borderRadius: 16
  },
  textField: {
    padding: 0,
    borderRadius: 12,
    width: '100%',
    height: 50,
    boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.5)',
    border: "none",

  },
  fontResize: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: ' 20px',
    letterSpacing: '-0.4px',
    color: "#767676", ...FAMILYFONTS.family3,
    marginTop: 4
  }, cancelIconButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  }, pickerMargin: {
    marginTop: 0,
    marginBottom: 0
  },
  inputField: {
    color: "#000000 !important",
    "&::placeholder": {
      color: "black",
      fontFamily: "Rene_Bieder_Milliard_Book",
      fontWeight: 400,
      lineHeight: 20,
      letterSpacing: -0.4,
    }
  }

}));

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  borderRadius: 12,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4
};
// Customizable Area End

// Customizable Area Start

// Customizable Area End

const DbsSearch = (props: any) => {

  // Customizable Area Start
  const classes = useStyles();



  const [dbssearchFilterShow, setDbssearchFilterShow] = React.useState(false);
  const [proximityShow, setProximityShow] = React.useState(false);
  const [dbsInclude, setDbsInclude] = React.useState(props.dbsSwitch);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [saveSearch, setSaveSearch] = React.useState(false);
  const [openSaveSearchModal, setOpenSaveSearchModal] = React.useState(false);
  const [value, onChange] = useState();
  const [Valueopen, setValueOpen] = useState(false);
  const [allDay, setAllday] = React.useState(false);
  const [proxyValue, setProxyValue] = React.useState(10)
  const [customSearchName, SetCustomSearchName] = React.useState("My Custom search")




  const handleChange = (event: Event, newValue: number | number[]) => {
    props.setProxyValue(newValue)
  };

  const handleDateRange = (e) => {
    onChange(e);
    props.setFromDate(e[0])
    props.setToDate(e[1])
    props.setValueOpen(!props.valueOpen);
    props.setCalOpen(!props.calOpen)
    changeDateText(e[0])
  };

  const handleAllDay = (data) => {
    if (data) {
      setAllday(true)

    } else {
      setAllday(false)
    }

  }



  const formatDate = (date) => {
    if (date) return moment(date).format('DD/MM/YYYY');
    else return moment(new Date()).format('DD/MM/YYYY');
  }

  const changeDateText = (date) => {
    let day = moment(date).format('DD MMM YYYY');
    let textElements = document.getElementsByClassName("react-calendar__navigation__label__labelText--from");
    if (textElements.length > 0) {
      var firstElement = textElements[0];
      var updatedText = day;
      firstElement.textContent = updatedText;
    }
  }

  React.useEffect(() => {

    const itemDataButtons = document.querySelectorAll('.react-calendar__month-view__days .react-calendar__tile--active');
    if (itemDataButtons.length > 0) {
      itemDataButtons[0].classList.add('first-item-data');
      itemDataButtons[itemDataButtons.length - 1].classList.add('last-item-data');
    }
    console.log(props.valueOpen, 'valueopen123')
  }, [props.valueOpen]);

  React.useEffect(() => {
    props.onChangeCategories(props.categories)
  }, [props.categories]);


  React.useEffect(() => {
    changeDateText(new Date())
  }, []);

  // const openDatepicker = () => _calendar.setOpen(true);

  const ValueLabelComponent = (props: any) => {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  //Handle Search Onclick 
  const gotoEventSearch = () => {
    const arr=[];
    props.getDbsData.forEach((item)=>{
      if(item?.isChecked){
        arr.push(item.id);
      }
    })
    const data = {
      dbs_included: props.dbsSwitch,
      categories: arr,
      all_day: props.allDay,
      from_date: props.fromDate,
      to_date: props.toDate,
      start_time: props.startTime,
      end_time: props.endTime,
      radius: props.proxyValue,
    }
    props.searchEventCall(data);
    props.setAdvanceSearchToggle(true);
  }

  const handleSaveSearch = (event: any) => {
    setOpenSaveSearchModal(true);
  }

  //Getting Dbs Categories Value

  const getCategoryValue = () => {
    const { getDbsData } = props;
    const getCheckedCategories = getDbsData?.filter((ele: any) => (
      ele.isChecked === true
    ))
    if (getCheckedCategories?.length > 0) {
      if (getCheckedCategories?.length > 1) {
        return `${getCheckedCategories[0]?.attributes?.task} +${getCheckedCategories?.length - 1}`
      }
      else {
        return getCheckedCategories[0]?.attributes?.task
      }
    }
    else {
      return ""
    }
  }


  //Modal on Save Search click
  const saveSearchModalContainer = () => {
    return (<Modal
      open={openSaveSearchModal}
      onClose={() => setOpenSaveSearchModal(!openSaveSearchModal)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <img src={icons.savedsearches} style={{ height: 80, width: 70 }} />
        </Box>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Typography variant="h6" style={{ ...FAMILYFONTS.family3, fontSize: 12 }}>
            <span style={{ ...FAMILYFONTS.family2 }}>Save As: </span> <input style={{ border: 'none' }} onChange={(e) => SetCustomSearchName(e.target.value)} type="text" value={customSearchName} />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "10px 0px"
          }}
        >
          <Button
            data-test-id="btnCloseModal"
            variant="contained"
            style={{
              width: "100%",
              backgroundColor: "#009BE2",
              boxShadow: "none",
              borderRadius: 8,
              height: 45,
              textTransform: "capitalize",
              color: "white"
            }}
            onClick={(event) => {
              setOpenSaveSearchModal(prevState => !prevState); // Toggle the openSaveSearchModal state
              props.setAdvanceSearchToggle(true)
              const data = {
                dbs_included: props.dbsSwitch,
                all_day: props.allDay,
                from_date: props.fromDate,
                to_date: props.toDate,
                start_time: props.startTime,
                end_time: props.endTime,
                radius: props.proxyValue,
                customSearchName: customSearchName
              }

              props.savedSearchCall(data);
            }}

          >
            Save
          </Button>
          <Button
            data-test-id="btnCloseModal"
            variant="contained"
            style={{
              ...FAMILYFONTS.family3,
              width: "100%",
              backgroundColor: "white",
              boxShadow: "none",
              borderRadius: 8,
              height: 45,
              margin: "1rem 0",
              textTransform: "capitalize",
              border: "1px solid #979797",
              color: "black"
            }}
            onClick={() => setOpenSaveSearchModal(!openSaveSearchModal)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
    )
  }

  console.log(props, 'props123')
  // Customizable Area End

  return (
    // Customizable Area Start
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box style={{
        position: "reletive",
        display: "flex",
        justifyContent: "initial",
        alignItems: "initial",
        flexDirection: "column",
        margin: 10,
      }}>

        {/* dbs include and switch  */}
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          margin: ".5rem 0",
        }}>
          <div>
            <Typography variant="body1" style={{ ...FONTS.dbsTitles }}>DBS Included</Typography>
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <AntSwitch name="checkedC" checked={props.dbsSwitch}
              onChange={(e: any) => props.handleDBSSwitch(e.target.checked)}
            />
          </div>
        </div>

        {/* category heading  */}
        <div style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          margin: "1rem 6",
        }}>
          <Typography variant="body1" style={{ ...FONTS.dbsTitles, }}>Categories</Typography>

        </div>

        {/* search  */}

        <div style={{ position: "relative" }}>
          <div className={dbssearchFilterShow ? clssesOuter.modalSearchMain : clssesOuter.hidemodalSearchMain}>


            <div className={clssesOuter.modalInSearchInput} >
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InputBase
                    placeholder="Select the Categories"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onClick={() => setDbssearchFilterShow(!dbssearchFilterShow ? true : false)}
                    value={getCategoryValue()}
                  />
                </div>
                <IconButton
                  aria-label="show 4 new mails"
                  color="inherit"
                  className={classes.aftersearchIconbutton}
                  onClick={() => setDbssearchFilterShow(!dbssearchFilterShow ? true : false)}
                >
                  <img
                    className={classes.dbs_tabs_logo}
                    src={icons.downarrowblue}
                  />

                </IconButton>
              </div>

              {/* filter option ui  */}
              {dbssearchFilterShow &&
                <div >
                  <div style={{
                    position: "relative",
                    height: 215, overflow: "scroll",
                  }}>
                    <style>
                      {`.ScrollbarsCustom-Track {
                        width : 2px !important
                      }`}
                    </style>
                    <Scrollbar style={{ height: '100%', width: '100%' }}>

                      <List dense >
                        <ListItem button>
                          <ListItemText primary={"Select All"} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              color="primary"
                              name="allCategoryCheck"
                              onChange={props.handleCategoryChange}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={
                                <CheckBoxIcon style={{ color: "#359ce2" }} />
                              }
                              checked={!props.getDbsData?.some((cat: any) => cat?.isChecked !== true)}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        {props.getDbsData?.map((ele) => {

                          return (

                            <ListItem key={ele.id} button>
                              <ListItemText id={ele.id} primary={ele.attributes.task} />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  edge="end"
                                  color="primary"
                                  name={ele.attributes.task}
                                  onChange={props.handleCategoryChange}
                                  inputProps={{ 'aria-labelledby': ele.id }}
                                  checked={ele?.isChecked || false}
                                  icon={<CheckBoxOutlineBlankIcon />}
                                  checkedIcon={
                                    <CheckBoxIcon style={{ color: "#359ce2" }} />
                                  }
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Scrollbar >

                  </div>
                </div>

              }


              {/* filter option ui  end */}

            </div>
          </div>
        </div>
        {/* search end  */}



        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 18,
            marginBottom: 10.
          }}>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Typography variant="body1" style={{ ...FONTS.dbsTitles, marginLeft: 6 }}>Availability</Typography>
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >

            <Typography style={{ ...FONTS.dbsTitles, marginLeft: 6, marginRight: "0.75rem" }}>All day</Typography>

            <AntSwitch checked={props.allDay} onChange={(e: any) => props.handleAllDay(e.target.checked)} />
          </div>


        </div>



        <div style={{ width: "100%" }} >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {Valueopen === "fromDate" && 
              <DateRangePicker
                value={props.fromDate}
                selectRange={false}
                isOpen={!!Valueopen}
                onCalendarClose={() => setValueOpen(false)}
                onChange={(e) => {
                  props.setFromDate(e);
                  const toDateIsBeforeFromDate = isBefore(new Date(props.toDate), new Date(e));
                  if (toDateIsBeforeFromDate){
                    props.setToDate(e);
                  }
                }}
              />
            }
            {Valueopen === "toDate" &&
              <DateRangePicker
                value={props.toDate}
                selectRange={false}
                isOpen={!!Valueopen}
                minDate={props.fromDate}
                onCalendarClose={() => setValueOpen(false)}
                onChange={(e) => {
                  props.setToDate(e);
                }}
              />
            }
            <div
              className={clssesOuter.gridContainer}
              style={{
                justifyContent: "space-between", marginBottom: 20, alignItems: "center", display: "flex"
              }}>
              <div style={{ width: '100%' }} >
                <Typography style={{ ...FONTS.family3, fontSize: 16, margin: 5, letterSpacing: "0.35px", color: " #000000", }}>From Date</Typography>

                <Box
                  style={{
                    border: "1px solid #C9C9C9",
                    width: "100%",
                    backgroundColor: "white",
                    boxShadow: "none",
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: 13,
                    paddingRight: 2,
                    height: "50px",
                    boxShadow: "0px 0px 8px rgba(212, 212, 212, 0.5)",
                    borderRadius: "12px"
                  }}
                >
                  <Typography style={{ ...FAMILYFONTS.family3, width: '100%', fontSize: 12, color: " #949494", }}>{formatDate(props.fromDate)}</Typography>
                  <Button disabled={props.advanceSearchReducer.all_day} className={clssesOuter.dateBtn} onClick={() => {
                    setValueOpen("fromDate");
                  }}
                  >
                    <img src={icons.calendarblue} alt="calender"
                      style={{
                        height: 18,
                        fill: COLORS.skyBlue
                      }} />
                  </Button>
                </Box>
              </div>
              <div style={{ width: '100%' }}>
                <Typography style={{ ...FONTS.family3, fontSize: 16, margin: 5, letterSpacing: "0.35px", color: " #000000", }}>To Date</Typography>
                <Box
                  style={{
                    border: "1px solid #C9C9C9",
                    width: "100%",
                    backgroundColor: "white",
                    boxShadow: "none",
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: 13,
                    paddingRight: 2,
                    height: "50px",
                    boxShadow: "0px 0px 8px rgba(212, 212, 212, 0.5)",
                    borderRadius: "12px"
                  }}
                >
                  <Typography style={{ ...FAMILYFONTS.family3, width: '100%', fontSize: 12, color: " #949494", }}>{formatDate(props.toDate)}</Typography>
                  <Button disabled={props.advanceSearchReducer.all_day} className={clssesOuter.dateBtn} onClick={() => setValueOpen("toDate")} >
                    <img src={icons.calendarblue} alt="calender"
                      style={{
                        height: 18,
                        fill: COLORS.skyBlue,
                      }} />
                  </Button>
                </Box>
              </div>
            </div>
            {props.allDay === false ? (
              <div className={clssesOuter.gridContainer} >
                <div style={{
                  justifyContent: "center", alignItems: "inline-grid", display: "flex", flexDirection: "column"
                }}>
                  <Typography style={{ ...FONTS.family3, fontSize: 16, margin: 5, letterSpacing: "0.35px", color: " #000000", }}>From Time</Typography>


                  <InitiateThemeProvider>
                    <KeyboardTimePicker
                      autoOk
                      color="primary"
                      fullWidth
                      ampm={false}
                      disableToolbar
                      disabled={props.advanceSearchReducer.all_day}
                      variant="inline"
                      inputVariant="outlined"
                      margin="normal"
                      id="mui-pickers-time"
                      className={classes.pickerMargin}
                      InputProps={{
                        classes: {
                          root: classes.textField,
                          input: classes.fontResize,
                        },
                      }}
                      format="hh:mm A"
                      placeholder="12:00 AM"
                      mask="__:__ _M"
                      value={props.startTime}
                      onChange={(time) => {
                        props.setStartTime(time);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "Select time",
                      }}
                      style={{ fontSize: 12 }}
                      keyboardIcon={
                        <img
                          style={{ height: "auto", width: 20 }}
                          src={icons.clockBlue}
                          alt="clock"
                        />
                      }
                    />
                  </InitiateThemeProvider>



                </div>
                <div style={{
                  justifyContent: "center", alignItems: "inline-grid", display: "flex", flexDirection: "column"
                }}>
                  <div>
                    <Typography style={{ ...FONTS.family3, fontSize: 16, margin: 5, letterSpacing: "0.35px", color: " #000000", }}>To Time</Typography>
                  </div>

                  <InitiateThemeProvider>
                    <KeyboardTimePicker
                      autoOk
                      color="primary"
                      fullWidth
                      ampm={false}
                      disableToolbar
                      variant="inline"
                      disabled={props.advanceSearchReducer.all_day}
                      inputVariant="outlined"
                      margin="normal"
                      id="mui-pickers-time"
                      className={classes.pickerMargin}
                      InputProps={{
                        classes: {
                          root: classes.textField,
                          input: classes.fontResize,
                        },
                      }}
                      format="hh:mm A"
                      placeholder="12:00 AM"
                      mask="__:__ _M"
                      value={props.endTime}
                      onChange={(time) => {
                        props.setEndTime(time);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "Select time",
                      }}
                      style={{ fontSize: 12 }}
                      keyboardIcon={
                        <img
                          style={{ height: "auto", width: 20 }}
                          src={icons.clockBlue}
                          alt="clock"
                        />
                      }
                    />
                  </InitiateThemeProvider>



                </div>
              </div>
            ) : (
              ""
            )}



          </MuiPickersUtilsProvider>
        </div>




        {/* Proximity Start */}


        {/* Proximity heading  */}
        <div style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          marginTop: 20,
          marginLeft: 6,
        }}>
          <Typography variant="body1" style={{ ...FONTS.dbsTitles, }}>Proximity</Typography>

        </div>
        <div style={{ position: "relative", minHeight: 56 }} >

          <div
            style={{ marginTop: 10 }}
            className={proximityShow ? clssesOuter.proximitySearchMain : clssesOuter.hideProximitySearchMain}>
            <div className={clssesOuter.modalInProximitySearchInput}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {/* <Typography variant="body1" style={{...FAMILYFONTS.family3,fontSize:12 }}>Up to</Typography> */}
                  <InputBase
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    disabled
                    value={`Up to ${props.proxyValue} ${props.proxyValue < 2 ? "mile" : "miles"}`}
                    inputProps={{ "aria-label": "search", className: classes.inputField }}
                    onClick={() => {
                      setProximityShow(!proximityShow ? true : false)
                    }}
                  />
                </div>
                <IconButton
                  aria-label="show 4 new mails"
                  color="inherit"
                  className={classes.aftersearchIconbutton}
                  onClick={() => setProximityShow(!proximityShow ? true : false)}
                >
                  <img
                    className={classes.dbs_tabs_logo}
                    src={icons.downarrowblue}
                  />

                </IconButton>
              </div>
            </div>

            {proximityShow ?
              <div elevation={0} style={{
                position: "relative"
              }}>
                <div
                  style={{
                    width: '100%',
                    height: 115,
                    display: "content",
                    zIndex: 4,
                    position: "relative",
                    borderRadius: 12,
                    padding: 24
                  }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, fontWeight: 600 }}>Radius {props.proxyValue} {props.proxyValue>1?"miles":"mile"}</Typography>
                    <Typography style={{ ...FAMILYFONTS.family3, color: "#009BE2", cursor: "pointer", fontSize: 12, fontWeight: 600 }} onClick={() => setProximityShow(!proximityShow ? true : false)}>Done</Typography>
                  </div>
                  <Box width="auto">
                    <Slider
                      className={classes.sliderCustome}
                      defaultValue={10}
                      step={null}
                      min={0}
                      max={50}
                      valueLabelDisplay="on"
                      value={props.proxyValue}
                      onChange={handleChange}
                      marks={marks}
                    />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, fontWeight: 500 }}>0mi.</Typography>
                    <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, fontWeight: 500 }}>40mi.</Typography>
                  </div>
                </div>
              </div>
              : ""
            }

          </div>



          {/* {!props?.searchValue && !props.rightSideFilter && <Typography style={{
            color: 'red',
            fontSize: 14,
            marginTop: 10,
            marginBottom: 10
          }}>Event name is required.</Typography>} */}




          {/* Proximity End */}



          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "inherit",
              marginTop: 36,
            }}
          >
            {!props.rightSideFilter ? <>

              <Button
                color="primary"
                variant="contained"
                type="submit"

                style={{
                  width: "100%",
                  backgroundColor: "#009BE2",
                  boxShadow: "none",
                  borderRadius: 8,
                  height: 45,
                  textTransform: "capitalize",
                  fontFamily: "Rene_Bieder_Milliard_SemiBold",
                }}
                onClick={() => {gotoEventSearch();
                props.setSavedSearchPayload(undefined);
              props.setMainPageLoader(true)
            }
              }
              >
                Search
              </Button>
              <Box mt="10px">
              <Button
                fullWidth
                onClick={(event: any) => {handleSaveSearch(event);
                this.props?.setSavedSearchPayload(undefined)
                }} type="submit">
                <Typography
                  style={{ fontFamily: "Rene_Bieder_Milliard_SemiBold", fontSize: 14, fontWeight: 600, textAlign: "center", margin: "0px", padding:"6px", color: "#009BE2", textTransform: "initial" }}
                >Save & Search</Typography>
              </Button>
              </Box>

            </> :
              <Button
                color="primary"
                variant="contained"
                type="submit"

                style={{
                  width: "100%",
                  backgroundColor: "#009BE2",
                  boxShadow: "none",
                  borderRadius: 8,
                  height: 45,
                  textTransform: "capitalize"
                }}
                onClick={() => {gotoEventSearch();
                  props.setMainPageLoader(true)}}
              >
                Apply
              </Button>
            }

          </div>


          {/* <div>

          </div> */}
        </div>
        {/* <div style={{ height: 50 }}></div> */}
        {/* modal  */}
        {saveSearchModalContainer()}

      </Box>
    </MuiPickersUtilsProvider >
    // Customizable Area End
  );
}

// Customizable Area Start
DbsSearch.propTypes = {
  classes: PropTypes.object.isRequired,
} as any;
// Customizable Area End

function mapStateToProps(state: any) {
  return {
    selectedevents: state.eventsReducer.selectedEvents,
    searchFilter: state.searchFilter?.filter,
    advanceSearchReducer: state.advanceSearchReducer
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    storeSearchDetails: (savedSearchinfo: any) => {
      return dispatch(storeSearchDetails(savedSearchinfo))
    },
    addSearchFilterDetails: (selectedOptions: any) => {
      return dispatch(addSearchFilterDetails(selectedOptions))
    },
    onChangeAllDay: (selectedOptions: any) => {
      return dispatch(onChangeAllDay(selectedOptions))
    },
    onChangeDbsSearch: (selectedOptions: any) => {
      return dispatch(onChangeDbsSearch(selectedOptions))
    },
    onChangeEndTime: (selectedOptions: any) => {
      return dispatch(onChangeEndTime(selectedOptions))
    },
    onChangeFromDate: (selectedOptions: any) => {
      return dispatch(onChangeFromDate(selectedOptions))
    },
    onChangeProximity: (selectedOptions: any) => {
      return dispatch(onChangeProximity(selectedOptions))
    },
    onChangeStartTime: (selectedOptions: any) => {
      return dispatch(onChangeStartTime(selectedOptions))
    },
    onChangeToDate: (selectedOptions: any) => {
      return dispatch(onChangeToDate(selectedOptions))
    },
    onChangeSavedSearch: (selectedOptions: any) => {
      return dispatch(onChangeSavedSearch(selectedOptions))
    },
    onChangeCategories: (selectedOptions: any) => {
      return dispatch(onChangeCategories(selectedOptions))
    },

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(DbsSearch);




