// Customizable Area Start
import React from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { Typography } from '@material-ui/core';

const useHourLineStyles = makeStyles((theme) => ({
  lineContainer: {
    minHeight: '50px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  timeSlot: {
    width: '70%',
    minHeight: '53px',
    background: '#e6f6fb',
    borderLeft: `10px solid ${theme.palette.primary.main}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "20px",
    boxSizing: "border-box",

  },
  emptyTimeSlot: {
    borderTop: '1px solid #c6c1c169',
    width: '70%',
    minHeight: '50px',
  
  },
  hourText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '0.9rem',
    color: '#575757',
  },
  availablilityText: {
    fontFamily: 'Rene_Bieder_Milliard_Medium',
    fontSize: '0.8rem',
    color: '#101010',
    opacity: 0.8,
  },
}));


export function HourLine({ hour, slotExist, from, to }: { hour: string, slotExist: boolean, from: string, to: string }) {

  const classes = useHourLineStyles();

  return (
       <div className={classes.lineContainer} >
      <div style={{marginTop:-11}}>
      <span className={classes.hourText}>
        {hour.toUpperCase()}
      </span></div>
      <HideContainer hideWhen={!slotExist}>
      <span className={classes.timeSlot}>
          <Typography variant="caption" className={classes.availablilityText}>
            {'Available Timing'}
          </Typography>
          <Typography variant="caption" className={classes.availablilityText}>
            {`(${from} to ${to})`}
          </Typography>
        </span>
      </HideContainer>
      
      <HideContainer hideWhen={slotExist}> 
        <span className={classes.emptyTimeSlot}  >
        </span>
      </HideContainer>
      </div>
  );
}


// Customizable Area End
