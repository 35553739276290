// Customizable Area Start
// @ts-nocheck
import * as React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  CssBaseline,
  Divider,
  Dialog,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Switch,
  List,
  ListItem,
  ListItemText,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Badge,
  InputBase,
  Container,
  Grid,
  Paper,
  ButtonBase,
  Tabs,
  Tab,
  Button,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  CardMedia,
  useMediaQuery,
  Avatar
} from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Redirect } from "react-router-dom";

import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";

// @ts-ignore
import classesoutercss from "./Settings5.module.css";

import {
  constants,
  images,
  icons,
  FONTS,
  FAMILYFONTS,
  SIZES,
} from "../../../components/src/constants";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import InvitePeople from "./InvitePeople.web";


// Customizable Area End



// Customizable Area Start

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
    '&:hover': {
      overflowY: 'auto',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },



  listItem: {
    transition: "all .5s",
    display: "flex",
    flexDirection: "column",
  },
  listLink: {
    padding: "0 15px",
    textDecoration: "none",
    color: "inherit",
    transition: "all .5s",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  listIcon: {
    color: "inherit",
    justifyContent: "center",
  },
  listItemText: {
    fontSize: '12px',
    // fontFamily : 'Roboto-Medium',
    ...FAMILYFONTS.family3,
    color: COLORS.black10,
    [theme.breakpoints.up("sm")]: {
      fontSize: '12px',
    },
  },
  selectedlistItemText: {
    textDecoration: 'underline',
    textDecorationColor: COLORS.black,
    ...FAMILYFONTS.family3,
    fontSize: '12px',
    [theme.breakpoints.up("sm")]: {
      fontSize: '12px',
    },
  },
  list: {
    listStyle: "none",
    // '&:last-child' : {
    //   position: 'absolute',
    //   bottom: '-15%'
    // },

  },
  listItemroot: {
    // width: "75%",
    margin: "4px auto",
    borderRadius: "8px",
    transition: "all .5s",
    overflow: "hidden",
  },
  selected: {
    // background: COLORS.linearGradientBlue,
    // color: COLORS.white,

  },
  bottomtabselected: {
    background: COLORS.linearGradientSkyBlue,
    color: COLORS.skyBlue
  },
  applogo: {
    height: "100%",
    maxHeight: "160px"
  },

  iconButton: {
    color: COLORS.black
  },
  notificationiconButton: {
    color: COLORS.black,
    padding: "20px"
  },
  toolBarContentCenter: {
    justifyContent: "space-between"
  },
  toolBarContentLeft: {
    justifyContent: "flex-start"
  },
  _tabs_logo: {
    height: "24px",
    left: "33px",
    top: "788px",
    borderRadius: " 0px",

  }, title: {
    color: COLORS.skyBlue
  },
  paper: {
    height: '100%',
    textAlign: 'center',
    boxShadow: "none",
    overflow: "auto",

    // [theme.breakpoints.down("md")]: {
    //   height: '550px',
    // },
  },
  paper1: {
    height: '100%',
    textAlign: 'left',
    boxShadow: "none",
    

  },
});
// Customizable Area End

import Settings5Controller, {
  Props,
} from "./Settings5Controller";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { COLORS } from "../../../framework/src/Globals";
import AvilabilityChild from "../../../components/src/custom/AvilabilityChild";
import ContactUs from "./ContactUs.web";
import TermsCondns from "./TermsAndCondns.web";
import Feedback from "./Feedback.web";
import EmailNotifications from "./EmailNotifications.web";
import MyTaskPreference from "../../email-account-registration/src/MyTaskPreference.web";
import MyPledgesWeb from "../../events/src/MyPledges.web";
import UserProfileBasicBlock from "../../user-profile-basic/src/UserProfileBasicBlock.web";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";
import { MyTaskPreferencesForm } from "../../email-account-registration/src/MyTaskPreferencesForm";
import { AvailabilityForm } from "../../email-account-registration/src/AvailabilityForm";
import { NotificationsSettingsForm } from "../../email-account-registration/src/EmailNotificationForm";
import { removeStorageData } from "../../../framework/src/Utilities";





export class Settings5 extends Settings5Controller {
  static propTypes: { classes: PropTypes.Validator<object>; theme: PropTypes.Validator<object>; };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  onMobileSidebarMenuClick = (_index: any, item: any) => {
    this.setState({ selectedTab: _index, selectMenuItem: item.name })
    localStorage.setItem("user_selected_date","false");
    localStorage.setItem("all_day","false");
    localStorage.setItem('set_button','false')
    if (isMobile) {
      switch (item.name) {
        case constants.dashboard_screens.AccountDetails:
          this.props.navigation.navigate("UserProfileBasicBlock");
          break;
        case constants.dashboard_screens.MyTaskPreferances:
          this.props.navigation.navigate("MyTaskPreferenceweb", { id: "SettingsTask" });
          break;
        case constants.dashboard_screens.MyAvailability:
          this.props.navigation.navigate("Availability", { id: "SettingsAvailibility" });
          break;
        default:
          break;
      }

      this.onMobileClickChild(item)
    }

  }
 

  onMobileClickChild(item: any) {
    localStorage.setItem("user_selected_date","false");
                localStorage.setItem("all_day","false");
                localStorage.setItem('set_button','false')
    switch (item.name) {
      case constants.dashboard_screens.EmailNotifications:
        this.setState({redirect:"/EmailNotificationsweb?key=Settings"})
        break;
      case constants.dashboard_screens.NotificationSettings:
        this.props.navigation.navigate("Notificationsweb", { id: "SettingsNotification" });

        break;
      case constants.dashboard_screens.InvitePeople:
        this.props.navigation.navigate("ErrorPage");
        break;
      case constants.dashboard_screens.Feedback:
        this.props.navigation.navigate("FeedbackWeb");
        break;
      case constants.dashboard_screens.ContactUs:
        this.props.navigation.navigate("ContactUsWeb");
        break;
      case constants.dashboard_screens.TermAndCondition:
        this.props.navigation.navigate("TermsAndConditionsWeb");
        break;
      case constants.dashboard_screens.Logout:
        localStorage.clear();
        removeStorageData('organizerMsgChatId');
        this.props.navigation.navigate("EmailAccountLoginBlock");
        break;
      case constants.dashboard_screens.Pledges:
        this.props.navigation.navigate("MyPledges");
        break;
      default:
        // Handle default case here
        break;
    }

  }


  renderSettingsHeaderTittle(classes: any) {
    return (

      <Toolbar className={classes.toolBar}>
        {/*  search  section  */}
        <div
         className={classesoutercss.backIconWebMail} style={{width:'350px',marginLeft:'5%', marginTop:'2%'}}>
          {this.state.selectMenuItem !== "" && <IconButton
            color={"inherit"}
            aria-label="Open drawer"
            onClick={() => 
              {  this.setState({ selectMenuItem: "", selectedTab: "" })
                localStorage.setItem("user_selected_date","false");
                localStorage.setItem("all_day","false");
              }
              }
          //  className={classesoutercss.hidebackbutton}

          >
            <img src={icons.back} />
          </IconButton>}
         
          <Typography variant="h6"
            style={{
              ...FONTS.body1,
              color: COLORS.black,
              justifyContent: "center",
              alignItems: "center", flexDirection: "row", textAlign: "center",marginLeft:'4%'
            }}
            noWrap>
            {constants.dashboard_screens.EmailNotifications === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>Email Notifications</Typography>}
            {constants.dashboard_screens.MyAvailability === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>My Availability</Typography>}
            {constants.dashboard_screens.AccountDetails === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>Account Details</Typography>}
            {constants.dashboard_screens.MyTaskPreferances === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>My Task Preferences</Typography>}
            {constants.dashboard_screens.Feedback === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>Feedback</Typography>}
            {constants.dashboard_screens.ContactUs === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>Contact Us</Typography>}
            {constants.dashboard_screens.TermAndCondition === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>Terms & Conditions</Typography>}
            {constants.dashboard_screens.InvitePeople === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>Invite People</Typography>}
            {constants.dashboard_screens.PrivacyPolicy === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>Privacy Policy</Typography>}
            {constants.dashboard_screens.NotificationSettings === this.state.selectMenuItem && <Typography style={{
              ...FONTS.settingsTitle
            }}>Notifications</Typography>}


          </Typography>

        </div>

      </Toolbar>
    )
  }

  renderLogout() {
    this.setState({ selectMenuItem: "", selectedTab: ""})
    this.handleLogout()
    this.removeDeviceToken();
  }
  renderAfterAvilityTitle() {
    return (
      <>
        {constants.dashboard_screens.AccountDetails === this.state.selectMenuItem && this.props.navigation.navigate('UserProfileBasicBlock')}
        {constants.dashboard_screens.MyTaskPreferances === this.state.selectMenuItem && <InitiateThemeProvider><MyTaskPreferencesForm hideTitle hideSkipButton submitButtonTitle="Save" preventNavigationOnSuccess /></InitiateThemeProvider>}
        {constants.dashboard_screens.EmailNotifications === this.state.selectMenuItem &&
          <InitiateThemeProvider>
            <NotificationsSettingsForm
              title="Notifications"
              type="email"
              onSuccessNavigateTo=""
              preventNavigationOnSuccess
              submitButtonTitle="Save"
              hideTitle
              hideSkipButton
            />
          </InitiateThemeProvider>
        }
        {constants.dashboard_screens.NotificationSettings === this.state.selectMenuItem &&
          <InitiateThemeProvider>
            <NotificationsSettingsForm
              title="Notifications"
              type="app"
              onSuccessNavigateTo=""
              preventNavigationOnSuccess
              submitButtonTitle="Save"
              hideTitle
              hideSkipButton
            />
          </InitiateThemeProvider>
        }
        {constants.dashboard_screens.Feedback === this.state.selectMenuItem && <Feedback navigation={undefined} id={""} history={undefined} />}
        {constants.dashboard_screens.ContactUs === this.state.selectMenuItem && <ContactUs navigation={undefined} id={""} history={undefined} />}
        {constants.dashboard_screens.TermAndCondition === this.state.selectMenuItem && <Typography style={{ whiteSpace: 'pre-wrap',fontFamily:"Rene_Bieder_Milliard_Book",textAlign: 'justify', paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'10px'}}>{this.state.termsandconditiondata?.attributes?.description}</Typography>}
        {constants.dashboard_screens.PrivacyPolicy === this.state.selectMenuItem && <Typography style={{whiteSpace: 'pre-wrap',fontFamily:"Rene_Bieder_Milliard_Book",display:"block", textAlign: 'justify', paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'10px'}}>{this.state.privacyPolicyDescription}</Typography>}
        {constants.dashboard_screens.InvitePeople === this.state.selectMenuItem &&   <InitiateThemeProvider><InvitePeople /></InitiateThemeProvider>}
        {constants.dashboard_screens.Logout === this.state.selectMenuItem && this.renderLogout()}
      </>
    )
  }

  renderMobileSettingMenuList(item: any, _index: any, classes: any) {
    return (
  <ListItem
    button
    className={classes.listItem}
    onClick={() => this.onMobileSidebarMenuClick(_index, item)}
    data-testId={`sidebarClick-${item.id}`}
    disableGutters
    key={_index}
    style={{
      backgroundColor: this.state.selectedTab === item.id ? '#f0f0f0' : 'transparent',
      boxShadow: this.state.selectedTab === item.id ? '8px 8px 16px rgba(0, 0, 0, 0.6)' : 'none', // Darker box shadow
      textDecoration: this.state.selectedTab === item.id ? 'underline' : 'none', // Underline text on clicking
      transition: 'background-color 0.3s, box-shadow 0.3s', // Add smooth transition
    }}
  >
    <Box component="div" className={classes.listLink}>
      <ListItemIcon className={classes.listIcon}>
        {item.icon && <img className={classes._tabs_logo} src={item.icon} />}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={item.name}
        style={{
          // ... other styles for the text (e.g., font, etc.)
        }}
      />
    </Box>
  </ListItem>

    )
  }

  renderDeactivateAccount(classes: any) {
    return (
      <div className={classesoutercss.mobilemenuposion} style={{position:"relative",marginBottom:2}}>
        <List className={classes.list}>
          <Box
            component={"div"}
            // to={`${item.url}`}
            className={
              classes.listLink}>
            <ListItemIcon className={classes.listIcon}>
              <img className={classes._tabs_logo} src={icons.deleteIcon} />
            </ListItemIcon>
            <ListItemText
             disableTypography
              data-testId="deactivateBtn"
              onClick={() => this.setState({ deleteAccountModel: true })}
              primary={<Typography
                style={{
                  display:"flex",
                  fontFamily: "Rene_Bieder_Milliard_Book",
                  fontWeight:"bold",
                  fontSize: '14px',
                  cursor: 'pointer',
                  width:'100%',
                  alignItems:'left',
                }}>Deactivate Account</Typography>}
            />

          </Box>
        </List>
      </div>
    )
  }

  renderDeactivateModel() {
    return (
      <Dialog
        fullScreen={false}
        open={this.state.deleteAccountModel}
        onClose={() => this.handleDeleteAccountOpenModel()}
        aria-labelledby="responsive-dialog-title"
        data-testId='closeModel'
        PaperProps={{
          style: { borderRadius: 16 }
        }}>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontFamily: "Rene_Bieder_Milliard_SemiBold", fontSize: '1.25rem', lineHeight: 1.6, letterSpacing: '0.0075em', padding: '20px 20px 20px 40px' }}>Deactivate Account</div>
          <div style={{ marginRight: 20, marginTop: 24 }}><CancelOutlinedIcon data-testId='cancelModel' onClick={() => this.handleDeleteAccountOpenModel()} style={{ cursor: "pointer" }} /></div>
        </div>

        <Typography style={{ margin: '0 40px' }}>Are you sure to want to deactivate this account ?</Typography>
        <div style={{ display: 'flex', padding: '5% 4% 5% 9%' }}>
          <Button
            data-testId="cancelBtn"
            onClick={() => this.handleDeleteAccountOpenModel()}
            style={{
              width: '45%',
              height: '50px',
              textTransform: 'none',
              marginRight: '5%',
              borderRadius: '12px',
              color: '#3da1e4',
              border: '1px solid #3da1e4',
              fontWeight: 'bold',
              fontFamily: 'Rene_Bieder_Milliard_Book'
            }}>
            Cancel
          </Button>
          <Button color="primary" variant="contained"
            data-testId="submitBtn"
            onClick={() => this.handleDeactivateAccount()}
            style={{
              width: '45%',
              height: '50px',
              textTransform: 'none',
              borderRadius: '12px',
              background: '#3da1e4',
              fontFamily: 'Rene_Bieder_Milliard_Book'
            }}>
            Okay
          </Button>
        </div>

      </Dialog >
    )
  }
  // Customizable Area End

  render() {

    const {
      classes,
      theme,
      children,
      title,
      showBackButton,
      showBottomBar,
      openDilog,
      navigation,
    }: any = this.props;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.redirect && <Redirect to={`${this.state.redirect}`} />}
        {/* @ts-ignore  */}
        <DashboardWeb
          showBackButton={constants._icons.show}
          title="Settings"
          navigation={this.props.navigation}
          showBottomBar={constants._icons.hide}
          
        // openDilog={this.handleClickOpen}
        >
          <div className={classesoutercss.settingContent}>
            <Grid container spacing={3} style={this.state.selectedTab === "" ? { display: 'flex', justifyContent: 'center', alignItems: 'center'} : {}}>

              <Grid item xs={12} sm={5} className={classesoutercss.leftmenuDetails}>
                <Paper className={classes.paper}>
                  {/* header section  */}

                  <Toolbar className={classes.toolBar}>
                    {/*  search  section  */}
                    <div className={classesoutercss.backIconWeb}>


                      {/* <IconButton
                        color={"inherit"}
                        aria-label="Open drawer"
                        onClick={() => navigation.goBack()}
                      //  className={classesoutercss.hidebackbutton}

                      >
                        <img src={icons.back} />
                      </IconButton> */}
                      <Typography variant="h6" style={{ fontFamily: 'Rene_Bieder_Milliard_Book', fontWeight: 'bold', color: COLORS.black }} noWrap>
                        Settings
                      </Typography>

                    </div>

                  </Toolbar>
                  <Divider style={{ background: 'rgb(229 248 251)' }} />

                  <div className={classesoutercss.mobilemenuposion}>
                    <List className={classes.list}>
                      {isMobile ? constants.SettingsMobileMenu.map((item, _index) => (
                        <div
                          data-testId={`sidebarMobileMenu-${item.id}`}
                          key={_index}
                          className={
                            item.id === this.state.selectedTab ? `${classes.listItemroot} ${classes.selected}`
                              : `${classes.listItemroot}`
                          }>
                          {this.renderMobileSettingMenuList(item, _index, classes)}

                        </div>
                      )): 
                        constants.SettingsMenu.map((item, _index) => (
                          <div
                            data-testId={`sidebarMenu-${item.id}`}
                            key={_index}
                            className={
                              item.id === this.state.selectedTab ? `${classes.listItemroot} ${classes.selected}`
                                : `${classes.listItemroot}`
                            }>
                            {this.renderMobileSettingMenuList(item, _index, classes)}

                          </div>
                        ))
                      }

                    </List>
                  </div>

                  {this.renderDeactivateAccount(classes)}

                  {/* header section end */}
                </Paper>
              </Grid>

              {this.state.selectMenuItem !== "" && <Grid item xs={12} sm={7} className={classesoutercss.rightmenuDetails} style={{ position: 'relative' }}>
                {/* <Hidden xsDown implementation="css"> */}
                <Paper className={classes.paper1} >
                  {/* header section */}
                  <div className={classesoutercss.settingsScroll}>


                  {this.renderSettingsHeaderTittle(classes)}
                  {/* <Box className="notificationHead">
                    <Typography variant="h5">
                      Notifications
                    </Typography>
                  </Box> */}

                  <Divider style={{ background: 'rgb(229 248 251)' }} />

                  {/* header section end */}
                  <Container maxWidth='md'>
                    {constants.dashboard_screens.MyAvailability === this.state.selectMenuItem &&
                      <InitiateThemeProvider>
                        <AvailabilityForm hideSkipButton preventNavigationOnSuccess submitButtonTitle="Set Availability" />
                      </InitiateThemeProvider>
                    }

                    {this.renderAfterAvilityTitle()}
                    {/* <AvilabilityChild
                      selectedDay={this.onSelectedDay}
                      enableScroll={true}
                      enableDays={180}
                      navigation={navigation}
                      handleFromDate={this.handleFromDate}
                      handleTODate={this.handleTODate}
                      handleOnTime={this.handleOnTime}
                      handleOffTime={this.handleOffTime}
                      goToSetAvailability={this.goToSetAvailability}
                      hideTitle={true}
                    /> */}
                  </Container>
                  </div>
                </Paper>
                {/* </Hidden> */}
              </Grid>}

            </Grid>
          </div>
          {this.renderDeactivateModel()}
        </DashboardWeb>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
Settings5.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles as any, { withTheme: true })(
  Settings5 as any
);

// Customizable Area End
