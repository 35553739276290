import React, { useState } from 'react';
import { CircularProgress, } from '@material-ui/core'

interface IProps {
    imageUrl?: string;
    alt?: string;
    altText?: string;
    imgstyles?: Record<string, string | number>;
    loaderStyle?: Record<string, string | number>; 
    classes?: string;
    scrollToBottom?: () => void;
}

const ImageLoader = ({ imageUrl, altText, alt, imgstyles, loaderStyle, classes, scrollToBottom }: IProps) => {
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
        setLoading(false);
        if(scrollToBottom){
            scrollToBottom();
        }
    };


    return (
        <>
            {loading && <CircularProgress style={{ height:24,width:24,color: "#009be2", ...loaderStyle }} />
            }
            {imageUrl &&
              <img
                className={classes}
                src={imageUrl}
                alt={altText? altText: alt}
                style={{ display: loading ? 'none' : 'block', ...imgstyles }}
                onLoad={handleImageLoad}
              />
            }
        </>
    );
};


export default ImageLoader;
