import React, { useState } from "react";
import { IconButton, TextField as MaterialTextField, } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { HideContainer } from "../shared/hide-container";
import { InitiateLabel } from "./label";
import { eyeOpenIcon, eyeCloseIcon } from "../../../blocks/email-account-registration/src/assets";
import { VerticalSpacer } from "../shared/vertical-spacer";

const TextField = withStyles({
  root: {
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.119346)',
    border: 'none',
    borderRadius: '10px',
    '& fieldset': {
      borderRadius: '10px',
      borderColor: 'transparent',
    },
  },
})(MaterialTextField);

const useInitiateTextFieldCustomStyles = makeStyles({
  eyeIcon: { maxWidth: '20px' },
});

export function InitiateTextField(props: React.ComponentProps<typeof TextField>) {

  const {
    label,
    ...otherProps
  } = props;

  const classes = useInitiateTextFieldCustomStyles();
  const [showPassword, setShowPassword] = useState(false);
  const isPasswordInput = Boolean(otherProps.type == 'password');


  let id;

  if (label && otherProps.name)
    id = `${otherProps.name}-${label}`;

  let type = otherProps.type;

  if (isPasswordInput)
    if (showPassword)
      type = "text"
    else
      type = "password"


  return (
    <>

      <HideContainer hideWhen={!label}>
        <InitiateLabel htmlFor={id}>{label}</InitiateLabel>
        <VerticalSpacer value={10} />
      </HideContainer>

      <TextField
        id={id}
        {...otherProps}
        type={type}
        onChange={(event) => {
          if (props.onChange) {
            if (type == "email") {
              let value = event.target.value;
              let filteredInput = value.trim();
              filteredInput = filteredInput.slice().replace(/\s/g, '');
              event.target.value = filteredInput;
            }
            props.onChange(event);
          }
        }}
        InputProps={{
          endAdornment: <HideContainer hideWhen={!isPasswordInput}>
            <IconButton size="small" onClick={() => setShowPassword(x => !x)}>
              {
                !showPassword ?
                  <img src={eyeCloseIcon} alt="hide" className={classes.eyeIcon} />
                  :
                  <img src={eyeOpenIcon} alt="show" className={classes.eyeIcon} />
              }
            </IconButton>
          </HideContainer>,
          ...otherProps.InputProps
        }}
      />
    </>
  );
}
