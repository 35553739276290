import { combineReducers } from 'redux';
import {  ADD_WORK_EMAIL } from '../types';


const initInformation = {
    _add_Work_Email:[]
}

function addWorkEmailInfo(state = initInformation,action: { type: any; payload: string | number; }){
    switch(action.type){
        case  ADD_WORK_EMAIL: 
            return{
                ...state,
                _add_Work_Email:action.payload
            }    
        default:
            return state;
    }
}

export default addWorkEmailInfo;