import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
}

export interface S {
  // Customizable Area Start
  id: string;
  eventImage: string;
  isFavorite: boolean;
  title: string;
  startDate: any;
  startTime: any;
  nonProfitProfileUrl: any;
  organisation_id: any;
  sid:string;
  organizationName: string;
  organizationLogo: string;
  location: string;
  post_code: string;
  isEventOpen: boolean;
  participantList: any[];
  description: string;
  userLat: number;
  userLang: number;
  eventLat: number;
  eventLang: number;
  eventStatus: string | null;
  isOpen: boolean;
  isCancelButton: boolean;
  favoriteId: null | number;
  pledgeId: null | number;
  toDate: any;
  toTime: any;
  totalCount: any;
  isPastEvent: boolean;
  joinChatStatus: boolean;
  information: string;
  isImageLoaded: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EventInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getEventDetailApiCallId: string = "";
  addFavoriteEventApiCallId: string = "";
  removeFavoriteEventApiCallId: string = "";
  confirmPledgeApiCallId: string = "";
  cancelPledgeApiCallId: string = "";
  groupChatApiCallId: string = "";
  getUnfollowNpApiCallId: string = "";
  getNpMessageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: "",
      eventImage: "",
      isFavorite: false,
      title: "",
      startDate: "",
      startTime: "",
      nonProfitProfileUrl: '',
      organisation_id: "",
      sid:"",
      organizationName: "",
      organizationLogo: "",
      location: "",
      post_code:"",
      isEventOpen: false,
      participantList: [],
      description: "",
      userLat: 0,
      userLang: 0,
      eventLat: 0,
      eventLang: 0,
      eventStatus: null,
      isOpen: false,
      isCancelButton: false,
      favoriteId: null,
      pledgeId: null,
      toDate: "",
      toTime: "",
      totalCount: 0,
      isPastEvent: false,
      joinChatStatus: false,
      information: "",
      isImageLoaded: false
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        if (responseJson.error) {
          toast.error(`${responseJson.error}`);
        } else if (responseJson.errors?.length > 0) {
          toast.error(`${responseJson.errors[0].token}`);
        } else if (apiRequestCallId === this.getEventDetailApiCallId) {
          this.handleEventDetailApiResponse(responseJson);
        } else if (apiRequestCallId === this.addFavoriteEventApiCallId) {
          toast.success("Event added to favourites!");
          this.setState({ isFavorite: true, favoriteId: responseJson.data.id });
        } else if (apiRequestCallId === this.removeFavoriteEventApiCallId) {
          toast.success("Event removed from favourites!");
          this.setState({ isFavorite: false });
        } else if (apiRequestCallId === this.confirmPledgeApiCallId) {
          toast.success("Request sent successfully!");
          this.getEventInfo(this.state.id);
        } else if (apiRequestCallId === this.cancelPledgeApiCallId) {
          toast.success("Request canceled successfully!");
          this.getEventInfo(this.state.id);
        } else if (apiRequestCallId === this.groupChatApiCallId) {
          this.getEventInfo(this.state.id);
          toast.success("User joined successfully");
        }
        if (apiRequestCallId === this.getNpMessageApiCallId) {
          this.onViewClick(responseJson.data)
        }
      }
    }
  }

  async componentDidMount() {
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.setState({ id }, () => {
        this.getEventInfo(id);
      });
    }
  }

  handleEventDetailApiResponse = (responseJson: any) => {
    const obj = responseJson.data.attributes.my_pledge_id;
    const participantList = responseJson.data.attributes.attended;
    const totalCount = responseJson.data.attributes.max_attendence;
    const toDate = responseJson.data.attributes.to_date;
    const toTime = responseJson.data.attributes.end_time;
    const endDate = this.getDateAndTime(toDate, toTime);
    const isPastEvent = moment(endDate).isBefore(moment());
    let eventStatus = null;
    let pledgeId = null;
    let isEventOpen = true;
    if (obj && obj.length > 0) {
      pledgeId = obj[0].id;
      eventStatus = obj[0].status;
    }
    if (totalCount) {
      isEventOpen = participantList.length < totalCount;
    }
    if (isPastEvent) {
      isEventOpen = false;
    }
    this.setState({
      eventImage: responseJson.data.attributes.event_image?.url,
      organisation_id: responseJson.data.attributes.organisation_id,
      sid:responseJson.data.attributes.sid,
      organizationName: responseJson.data.attributes.organisation_name,
      organizationLogo: responseJson.data.attributes.organisation_image_url?.url,
      information: responseJson.data.attributes.activity_schedule,
      title: responseJson.data.attributes.event_name,
      startDate: responseJson.data.attributes.from_date,
      startTime: responseJson.data.attributes.start_time,
      description: responseJson.data.attributes.description,
      userLat: responseJson.data.attributes.user_latitude,
      userLang: responseJson.data.attributes.user_longitude,
      isFavorite: responseJson.data.attributes.is_favourite,
      favoriteId: responseJson.data.attributes.favourite_id,
      eventLat: responseJson.data.attributes.latitude,
      eventLang: responseJson.data.attributes.longitude,
      location: responseJson.data.attributes.location,
      post_code: responseJson.data.attributes.post_code,
      toDate,
      toTime,
      pledgeId,
      totalCount,
      isEventOpen,
      eventStatus,
      participantList,
      isOpen: false,
      isPastEvent
    },() => {
      let filterResult = this.state.participantList.filter((ele) => ele.account_id === Number(localStorage.getItem("acc_id")))
      this.setState({joinChatStatus: filterResult[0]?.joined_chat})
    });
  };

  openMap = () => {
    window.open(`${configJSON.GOOGLE_MAP_BASE_URL}?api=1&destination=${this.state.eventLat},${this.state.eventLang}`);
  }

  onButtonClick = () => {
    this.state.isCancelButton
      ? this.cancelPledge()
      : this.confirmPledge()
  }
  MessageNpProfile = (id: string) => 
  { const token = localStorage.getItem("token"); const type = 'user_entity'

    const header = {
      "Content-Type": "application/json","type": type,"token": token };

    const httpBody = { "organisation_id": id,
      
      }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNpMessageApiCallId = requestMessage.messageId;

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody) );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getMessageNpApiEndPoint );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.postAPIMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onViewClick = (item: [data:{attributes:{sid: string}}]) =>  {
    if 
    (item && item.length > 0 && item[0].attributes)
     {const msg = new Message(getName(MessageEnum.NavigationMessage));msg.addData(getName(MessageEnum.NavigationTargetMessage), 'GroupChat');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), 
      item[0].attributes.sid);
  msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);this.send(msg);
    } else
     {console.error('Invalid item or missing attributes:', item);}}

  onClickPopup = ( sid: string) =>
   { const msg = new Message(getName(MessageEnum.NavigationMessage)); msg.addData(getName(MessageEnum.NavigationTargetMessage), "GroupChat");
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), sid);msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage); this.send(msg); }

  getDateAndTime = (dates: string, time: string) => {
    if (dates && time) {
      const date = new Date(dates);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();

      const utcDate = new Date(time);
      const hour = utcDate.getUTCHours();
      const minute = utcDate.getUTCMinutes();
      const second = utcDate.getUTCSeconds();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}.000Z`;
    } else {
      return "";
    }
  }

  redirectToChat = async () => {
    if (this.state.eventStatus !== "Approved") {
      return;
    }
    const formData = new FormData();
    formData.append("event_id", this.state.id);
    formData.append("user_id", localStorage.getItem("acc_id") as string);
    formData.append("user_email", 'email');
    formData.append("full_name", 'name');

    this.groupChatApiCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_groupchat/join_chat`,
      body: formData
    });
  }

  getEventInfo = async (id: string) => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getEventDetailApiCallId = await apiCall({
      method: "GET",
      endPoint: `non_profit_events/${id}`,
      header: header
    });
  }

  addToFavorite = async () => {
    const body = {
      "data": {
        "favouriteable_id": this.state.id,
        "favouriteable_type": "BxBlockEvents::NonProfitEvent",
      },
      "token": localStorage.getItem("token")
    }
    this.addFavoriteEventApiCallId = await apiCall({
      endPoint: `/bx_block_favourites/favourites`,
      body: JSON.stringify(body),
      method: "POST",
      contentType: "application/json"
    });
  }

  removeFromFavorite = async () => {
    this.removeFavoriteEventApiCallId = await apiCall({
      endPoint: `bx_block_favourites/favourites/${this.state.favoriteId}`,
      method: "DELETE"
    });
  }

  confirmPledge = async () => {
    const body = {
      "pledge": {
        "non_profit_event_id": this.state.id,
        "account_id": localStorage.getItem("acc_id")
      }
    }
    this.confirmPledgeApiCallId = await apiCall({
      body: JSON.stringify(body),
      endPoint: `my_pledges`,
      method: "POST",
      contentType: "application/json"
    });
  }

  cancelPledge = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.cancelPledgeApiCallId = await apiCall({
      header,
      endPoint: `my_pledges/${this.state.pledgeId}`,
      method: "DELETE",
    });
  }
}
// Customizable Area End