// @ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,Paper,
  Button,
  CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import ContentManagementController, {
  Props,
} from "./ContentManagementController";
import PropTypes from "prop-types";
// @ts-ignore
import module from "./ContentManagementEvent.module.css";
import { COLORS } from "../../../framework/src/Globals";
// @ts-ignore

import NeedsEventUpdate from "./NeedsEventUpdate.web";
import EventPledgesWeb from "./EventPledges.web";
import ProjectsEventUpdate from "./ProjectsEventUpdate.web";
import ActivityEventUpdateWeb from "./ActivityEventUpdate.web";
import EventUpdatesWeb from "./EventUpdates.web";
import DonorInfo from "./DonorInfo.web";

export const styles = (theme: Theme) => createStyles({});

// Customizable Area End

// Customizable Area Start
export class UpdateEventWeb extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  renderDefaultTab(SingleEvent : any){
    return(
      <div>
      {(SingleEvent.data?.attributes?.event_type === "need" && (
        <NeedsEventUpdate
        navigation={undefined}
        navigationToNeeds={this.props.navigationToNeedsList}
          id={""}
          children={undefined}
          onSecondTabsCreation={undefined}
          createNeedEvent={undefined}
          createActivitiesEvent={undefined}
          showBackButton={undefined}
          projectsEvent={undefined}
          title={undefined}
          navigationToActivitiesList={undefined}
          navigationToProjectsList={undefined}
          showBottomBar={false}
          showDrawerHeader={false}
          mobileOpen={false}
          open={false}
          classes={undefined}
          history={undefined}
          titleColor={undefined}
          showLine={undefined}
          adminSelectedTab={undefined}
          createEvents={undefined}
          setSelectedAdminTab={undefined}
          onEventCreation={undefined}
          single_event_details={this.props.SingleEvent}
          SingleEvent={undefined}
          pledgesDetails={undefined}
          eventPledgesDetails={undefined}
          taskPreferenceData={this.state.taskPreferenceData}
          dbsData={this.state.dbsData}
        />
      )) ||
        (SingleEvent.data?.attributes?.event_type ===
          "project_funding" && (
          <ProjectsEventUpdate
            navigation={undefined}
            id={""}
            children={undefined}
            onSecondTabsCreation={undefined}
            createNeedEvent={undefined}
            createActivitiesEvent={undefined}
            showBackButton={undefined}
            projectsEvent={undefined}
            title={undefined}
            navigationToNeedsList={undefined}
            navigationToActivitiesList={undefined}
            showBottomBar={false}
            showDrawerHeader={false}
            mobileOpen={false}
            open={false}
            classes={undefined}
            history={undefined}
            titleColor={undefined}
            showLine={undefined}
            adminSelectedTab={undefined}
            createEvents={undefined}
            setSelectedAdminTab={undefined}
            onEventCreation={undefined}
            single_event_details={this.props.SingleEvent}
            SingleEvent={undefined}
            pledgesDetails={undefined}
            eventPledgesDetails={undefined}
            navigationToProjectsList = {this.props.navigationToProjectsList}
            projectUpdateProp={true}
          />
        )) ||
        (SingleEvent.data?.attributes?.event_type ===
          "activity" && (
          <ActivityEventUpdateWeb
          
            navigation={undefined}
            id={""}
            children={undefined}
            onSecondTabsCreation={undefined}
            createNeedEvent={undefined}
            createActivitiesEvent={undefined}
            showBackButton={undefined}
            projectsEvent={undefined}
            title={undefined}
            navigationToNeedsList={undefined}
            navigationToActivitiesList={this.props.navigationToActivitiesList}
            navigationToProjectsList={undefined}
            showBottomBar={false}
            showDrawerHeader={false}
            mobileOpen={false}
            open={false}
            classes={undefined}
            history={undefined}
            titleColor={undefined}
            showLine={undefined}
            adminSelectedTab={undefined}
            createEvents={undefined}
            setSelectedAdminTab={undefined}
            onEventCreation={undefined}
            single_event_details={this.props.SingleEvent}
            SingleEvent={undefined}
            pledgesDetails={undefined}
            eventPledgesDetails={undefined}
          />
        )) || (!SingleEvent.data &&  <Paper  style={{
  textAlign: "center",
  height: "50%",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  fontFamily: "Rene_Bieder_Milliard_Medium",
}}>  <CircularProgress /> </Paper> )}
    </div>
    )
  }

  renderEventText(SingleEvent : any){
    return(
      <Typography
      style={{
        fontFamily: "Rene_Bieder_Milliard_Medium",
      }}
    >
      {SingleEvent.data && !this.state.loader ? (
        (SingleEvent.data &&
          SingleEvent.data?.attributes.event_type === "need" &&
          "Need Information") ||
        (SingleEvent.data?.attributes.event_type ===
          "project_funding" &&
          "Project Information") ||
        (SingleEvent.data?.attributes.event_type === "activity" &&
          'Activity Information')
      ) : (
        <CircularProgress />
      )}
    </Typography>
    )
  }

  renderEventButtonType(SingleEvent : any){
    return(
      <Button
      onClick={() => this.setState({ eventTabs: "secondTabsOne", secondTabForm : true })}
     data-testId='tab-1'
       style={{
        backgroundColor: COLORS.white,
        textTransform: "capitalize",
        height: 70,
        borderRadius: "12px 0px 0px 0px",
      }}
      className={
          this.state.secondTabForm === true
          ? module.activeEventTabs
          : ""
      }
    >
      {this.renderEventText(SingleEvent)}
     
    </Button>
    )
  }
  renderButtonGroup(SingleEvent : any){
    return(
      <ButtonGroup
      size="large"
      aria-label="large button group"
      fullWidth
      style={{ margin: "2px" }}
    >
   {this.renderEventButtonType(SingleEvent)}
      <Button
        onClick={() => {
          this.setState({ eventTabs: "secondTabsTwo" , secondTabForm : false});
          this.getEventUpdates(
            this.props?.single_event_details?.data.id
          );
        }}
        data-testId='tab-2'
        style={{
          backgroundColor: COLORS.white,
          textTransform: "capitalize",
          height: 70,
        }}
        className={
          this.state.eventTabs === "secondTabsTwo"
            ? module.activeEventTabs
            : ""
        }
      >
        <Typography
          style={{
            fontFamily: "Rene_Bieder_Milliard_Medium",
          }}
        >
          Updates
        </Typography>
      </Button>
      <Button
        onClick={() => this.setState({ eventTabs: "secondTabsThree" ,secondTabForm : false})}
        data-testId='tab-3'
        style={{
          backgroundColor: COLORS.white,
          textTransform: "capitalize",
          height: 70,
          borderRadius: "0px 12px 0px 0px",
        }}
        className={
          this.state.eventTabs === "secondTabsThree"
            ? module.activeEventTabs
            : ""
        }
      >
        <Typography
          style={{
            fontFamily: "Rene_Bieder_Milliard_Medium",
          }}
        >
          {SingleEvent.data ? (
        (SingleEvent.data &&
          SingleEvent.data?.attributes.event_type === "need" &&
          "Pledges") ||
        (SingleEvent.data?.attributes.event_type === "project_funding" &&
          "Donations") ||
        (SingleEvent.data?.attributes.event_type === "activity" &&
          'Attendees')
      ) : (
        <CircularProgress />
      )}
        </Typography>
      </Button>
    </ButtonGroup>
    )
  }

  render() {
    // Customizable Area Start
    const { SingleEvent }: any = this.props;
    return (
      <div>
        <Container maxWidth="xl">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 40,
            }}
          >
   
    {this.renderButtonGroup(SingleEvent)}

          </div>
          <div>
            {(() => {
              switch (this.state.eventTabs) {
                case "secondTabsTwo": {
                  {
                  }
                  return (
                    <EventUpdatesWeb
                      single_event_details={this.props.SingleEvent}
                    />
                  );
                }

                case "secondTabsThree": {
                  if(SingleEvent.data?.attributes.event_type === "project_funding"){
                    return (
                      <DonorInfo single_event_details={this.props.SingleEvent} />
                     );
                  }
                  return (
                    <EventPledgesWeb
                      // eventPledgesDetails={this.props.pledgesDetails}
                      messageClick={(id) => this.directPledgeCommunication(id)}
                      single_event_details={this.props.SingleEvent}
                    />
                  );
                }

                default: {
                  return this.renderDefaultTab(SingleEvent)
                }
              }
            })()}
          </div>
        </Container>
      </div>

      // Customizable Area End
    );
  }
}
// Customizable Area End

UpdateEventWeb.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Customizable Area End

export default withStyles(styles as any, { withTheme: true })(
  UpdateEventWeb as any
);
