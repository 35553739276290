import React from "react";
// Customizable Area Start
import AdvancedSearchController, { Props } from "./AdvancedSearchController";
//@ts-ignore
import classesOuter from './MobileSearch.module.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography, CircularProgress } from '@material-ui/core'
import { icons, SIZES, FONTS, FAMILYFONTS, images } from '../../../components/src/constants';
import { COLORS } from "../../../framework/src/Globals";
import { addSearchFilterDetails } from "../../../framework/src/redux/advanceSearch/advanceSearchActions";
import { setSelectedFilterEvents } from "../../../framework/src/redux/filterEvents/filterEventsAction";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import moment from "moment";
import ImageLoader from "../../../components/src/custom/ImageLoader";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.474186)',
				fontSize: SIZES.font,
			},
			marginNormal: {
				borderRadius: SIZES.radius,
			}
		},
		MuiOutlinedInput: {

			notchedOutline: {
				border: 'none',
			},
			input: {
				padding: '15px 15px 15px 50px',
			}
		},
		MuiAvatar: {
			root: {
				fontSize: SIZES.h4,
			},
			rounded: {
				borderRadius: SIZES.radius,
			},
			colorDefault: {
				backgroundColor: COLORS.skyBlue,
				color: COLORS.white,

			}
		},
		MuiListItemText: {
			multiline: {
				marginLeft: '6px',
			}
		},
		MuiTypography: {
			body1: {
				fontSize: FONTS.h3.fontSize,
				fontFamily: FONTS.h3.fontFamily,
				color: COLORS.black,
			}
		},
		MuiListItemSecondaryAction: {
			root: {
				top: '35%',
				transform: 'translateY(-35%)',

			}
		}
	},

});
// Customizable Area End

class MobileSearch extends AdvancedSearchController {
	static propTypes: { classes: any; theme: any; };
	static MobileSearch: { classes: any; theme: any; };

	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	SearchBar = () => {
		return (
			<>
				<ThemeProvider theme={theme}>
					<div className={classesOuter.filterBox}>
						<TextField variant="outlined" placeholder='Search Events'
							data-test-id={"searchinput"}
							fullWidth={true} margin="normal"
							onChange={(e) => {
								e.target.value ? this.searchinputChange(e.target.value) : this.getAllUpcomingEvents();
							}}
							id='listSearch' />
						<img src={icons.search} className={classesOuter.srchicons} />
					</div>
				</ThemeProvider>
			</>
		)
	}

	loader() {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height : '80%'
				}}
			>
				<CircularProgress style={{ color: "#009be2" }} />
			</div>
		)
	}

	eventTypeBg(value: any) {
		if (value === 'project_funding') return '#009be2'
		else if (value === 'activity') return 'b5028b'
		else return '06bb9d'
	}

	eventTypeR1(value: any) {
		if (value === 'project_funding') return 'Donate'
		else if (value === 'activity') return 'Attend'
		else return 'Volunteer'
	}

	noDataFound() {
		return (
			<Typography
				style={{
					// textAlign: "center",
					// alignItems: "center",
					// display: "flex",
					// justifyContent: "center",
					// fontFamily: "Rene_Bieder_Milliard_Medium",
					fontFamily: "Rene_Bieder_Milliard_Medium",
					margin: 0,
					position: 'absolute',
					top: '40%',
					left: '50%',
					msTransform: 'translate(-50%, -50%)',
					transform: 'translate(-50%, -50%)'
				}}
			>
				No data found
			</Typography>
		)
	}

	renderCountry = (location: string, post_code: string) => {
		return post_code ? post_code.substring(0, post_code.indexOf(" ")) || post_code : location?.slice(location.lastIndexOf(",") + 1).trim();
	}

	renderLocation = (location: string, post_code: string) => {
		if (post_code && !location.includes(post_code)) {
			location += `, ${post_code}`;
		}
		return location;
	}

	handleRedirection = (id: string | number, type: string, event_type: string) => {
		if (type === "non_profit_entity") {
			if (event_type === "project_funding") {
				this.props.navigation.navigate("FundCampaign", { id: id });
			} else if (event_type === "activity") {
				this.props.navigation.navigate("EventInfo", { id: id });
			} else if (event_type === "need") {
				this.props.navigation.navigate("EventsDetails", { id: id });
			}
		}
	}

	allEventsInList(allEvents: any) {
		const placeholderImg=localStorage.getItem('placeholderImg')
		return (
			<div>
				{allEvents.map((event: any) => {
					let event1: any = event?.attributes;
					const pledgeId = event1.my_pledge_id ?? event1.need_my_pledge_id;
					const imageUrl=event?.type === "non_profit_event" ? event1?.event_image?.url : event1?.need_photo?.url
					return (
						<div className={classesOuter.eventCard}
							data-testId="event-info"
							onClick={()=> this.handleRedirection(event.id, event1.type, event1.event_type)}
						>
						<div style={{ width: "30%" }}>
							<ImageLoader loaderStyle={{ display: 'flex', justifyContent: 'center', height: '80%' ,marginLeft : 30}} imgstyles={{height: 83, width: 83, borderRadius: 12, marginLeft: 8 }} 
							imageUrl={imageUrl||placeholderImg} alt="complex" />
						</div>
						<div style={{ width: "70%" }}>
							<div style={{ display: "flex" }}>
								<Typography className={`${classesOuter.eventHeader} ${classesOuter.ellipsis}`}>
									{event?.type === "non_profit_event" ? event1?.event_name : event1?.title_of_need}
								</Typography>
								<div className={classesOuter.eventType} style={{ backgroundColor: this.eventTypeBg(event1?.event_type) }}> {this.eventTypeR1(event1?.event_type)} </div>
							</div>
							<label className={classesOuter.orgName}>
								{event?.type === "non_profit_event" ? event1?.organisation_name : event1?.user_name}
							</label>
							<br />
							<label className={`${classesOuter.orgName} ${classesOuter.imgWithText}`}>
								<img src={icons.calendarblue} style={{ width: 15, marginRight: 3, height: 15 }} />
								{event?.type === "non_profit_event"
									? `${moment(event1?.from_date).format("DD/MM/YYYY")} ${moment(event1?.start_time).format("h:mm A")}`
									: `${moment(event1?.start_date).format("DD/MM/YYYY")} ${moment(event1?.start_time).format("h:mm A")}`}
							</label>
							{/* <br /> */}
							<label className={`${classesOuter.orgName} ${classesOuter.imgWithText} ${classesOuter.ellipsis}`}>
								<img src={icons.locationred} style={{ width: 15, marginRight: 3, height: 15 }} />
								{((pledgeId && pledgeId.length > 0 && pledgeId[0]?.status === "Approved") || event1.event_type === "project_funding" || event1.event_type === "activity")
                                ? this.renderLocation(event1.location, event1.post_code)
                                : this.renderCountry(event1.location, event1.post_code)}
							</label>
						</div>
					</div>

					)
				})}
			</div>
		)
	}
	// Customizable Area End
	render() {
		// Customizable Area Start
		const { filterEventsReducer }: any = this.props;
		// Customizable Area End
		return (
			// Customizable Area Start
			<>
				{/* <MainHeading /> */}
				<div className={classesOuter.Mobwrapper}>
					{this.SearchBar()}
					{(() => {
						if (this.state.searchEventsLoader) {
							return this.loader()
						} 
						else if (this.state.allUpcomingEvents.length > 0 && filterEventsReducer !== 'No data found' && !filterEventsReducer[0] && !this.state.searchEventsLoader) {
							return this.allEventsInList(this.state.allUpcomingEvents)
						} else return this.noDataFound()
					})()}


				</div>
			</>
			// Customizable Area End
		)
	}
}
// Customizable Area Start

MobileSearch.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};


function mapStateToProps(state: any) {

	return {
		selectedevents: state.eventsReducer.selectedEvents,
		searchFilter: state.searchFilter?.filter,
		filterEventsReducer: state.filterEventsReducer?.selectedEvents
	}
}

function mapDispatchToProps(dispatch: any) {
	return {
		addSearchFilterDetails: (selectedOptions: any) => {
			return dispatch(addSearchFilterDetails(selectedOptions))
		},
		setSelectedFilterEvents: (selectedEvents: any) => {
			return dispatch(setSelectedFilterEvents(selectedEvents))
		}
	}
}


export { MobileSearch }
export default connect(mapStateToProps, mapDispatchToProps)(MobileSearch)


  // Customizable Area End
