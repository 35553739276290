// Customizable Area Start
import React from "react";
import "../assets/css/Settings5.css";
import {
  Box,Input,
  Container,
  IconButton,
  Typography,InputBase,Button,Grid
} from "@material-ui/core";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { icons } from "../../../components/src/constants";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import Settings5Controller from "./Settings5Controller";
import { isMobile } from "react-device-detect";

export default class InvitePeople extends Settings5Controller {

  renderSocials = () =>{
    return  <div style={{marginLeft:-5}} className="row">
    <Box maxWidth="50%">
    <IconButton data-testId = "gmailButton"
     onClick={()=>{this.setState({iconOpen:1})}} 
    style={{ backgroundColor: 'transparent',padding: "0px" }}
    disableRipple>
  <img src={icons.gmail} width={53} height={40} />
  </IconButton>
    </Box>
    </div>
  }
  renderEmailField = () =>{
    return this.state.iconOpen===1 && 
    <div>
      <Input
      value={this.state.email}
    data-testId = "emailField"
    disableUnderline
    style={{width:'350px',float:'left'}}
    placeholder="Enter email address to invite"
    onChange={(e) => {
      this.setEmail(e.target.value);
      this.validateEmail(e.target.value);
    }} className="searchField"

    endAdornment={       
          <Button className="btn-disable" data-testId = "send-button"
          onClick={()=>{this.sendInvite(this.state.email)
          this.setState({email:""})}}
          style=
          {{marginRight:0,
            borderRadius:10,
            pointerEvents: (this.state.email===""||
            this.state.emailError!=='') ? 
            "none" : "auto",
          width:'5px'}}>
          <img
          src={icons.bluerightarrow} />
          </Button> 
      }
  
    />
    <Box style={{position:'absolute',marginTop:60,marginLeft:5}} className='errorMsg'>
    <div className='errors' >
      {this.state.emailError}
    </div>
  </Box>
    </div>
  }
  render() {
    return (
    <Box style={{overflowX:'hidden'}}>
          <div className="mob-header" >
            <MobileHeader title="Invite People" navigation={this.props.navigation} />
          </div>
          <Container
          maxWidth="lg"
          style={{ overflowY:'auto', display: "table", textAlign: "center", width: '90%',marginRight:'6.5%',marginBottom:"10%" }}>
          <VerticalSpacer/> 
          <VerticalSpacer/> 
          <Typography style={{textAlign:'left',fontFamily:'Rene_Bieder_Milliard_Book',fontSize:'1.1rem',marginBottom:5}}>Invite link</Typography> 
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <div><InputBase 
                style={{backgroundColor:'#F2F3F5',
                height:45,
                width:'140%',
                padding:10,
                color: '#777777',
                pointerEvents:'none',
                caretColor: 'transparent'}}
                value={this.state.inviteLink}
              /></div>
                <div><Button data-testId = "copybutton" style={{maxWidth:10,backgroundColor:'#F2F3F5',borderRadius:0,padding:0,marginLeft:65,borderLeft:'1px solid lightgrey'}}>
              <img
                data-testId="copy-btn"
                style={{cursor:'pointer',marginTop:5,marginBottom:5}}
                src={icons.copyIcon}
                height={35} width={30}
                onClick={this.handleInviteClick} />
              </Button></div>
              </div>
          <VerticalSpacer/>  
          <VerticalSpacer/>
          <Typography style={{textAlign:'left',fontFamily:'Rene_Bieder_Milliard_Book',fontSize:'1.1rem',marginBottom:3}}>Social Apps</Typography>
           {this.renderSocials()}
           <VerticalSpacer/>  
           {this.renderEmailField()}
        </Container>   
      </Box>
    );
  }
}
// Customizable Area End
