export const SAVED_SEARCH_PAYLOAD = 'SAVED_SEARCH_PAYLOAD';

export const savedSearchPayload = (payload: any) => {
    //     {
    //     type : SAVED_SEARCH_PAYLOAD,
    //     payload 
    // }
    return (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
        dispatch({ type: SAVED_SEARCH_PAYLOAD, payload: payload });
    }
}