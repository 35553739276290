import React from "react";

// Customizable Area Start
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { WorkEmailForm } from "./WorkEmailForm";
// Customizable Area End

class AddWorkEmail extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
      // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      <WorkEmailForm />      
      // Customizable Area End
    );
  }
} 

// Customizable Area Start
export default AddWorkEmail
export {AddWorkEmail}
// Customizable Area End
