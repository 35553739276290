Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.onTermsandCondition="bx_block_privacy_settings/terms_and_conditions";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.deleteMethod="DELETE"
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.apiMethodTypeAddDetail = "POST";
exports.contentTypeApiAddDetail = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.contactUsApiEndPoint = 'bx_block_contact_us/contacts';
exports.inviteLink = "https://www.initiateapp.com/";
exports.feedbackApiEndPoint = 'app_feedbacks';
exports.btnExampleTitle = "CLICK ME";
exports.emailNotificationEndPoint =
  "account_block/accounts/set_email_notification";
exports.deactivateAccountEndPoint="account_block/accounts/delete_user";
exports.PrivacyPolicyEndPoint="bx_block_privacy_settings/privacy_policies"
exports.acceptTermsEndPoint="account_block/accounts/terms_and_condition";
exports.logoutEndpoint = "/bx_block_profile/non_profit_activity_logs"
// Customizable Area End
