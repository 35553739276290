// Customizable Area Start
import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { COLORS } from '../../../framework/src/Globals';
import { icons, FONTS, FAMILYFONTS } from '../constants';
import { Link } from "react-router-dom";
// Customizable Area End

// Customizable Area Start
const styles = createStyles({
    mobileAppBarroot: {
        flexGrow: 1,
        borderBottom: "1px solid #f1f1f1"
    },
    menuButton: {
        marginLeft: -18,
        marginRight: 10,
    },
    appBarstyle: {
        boxShadow: "none",
        color: COLORS.black,
        backgroundColor: COLORS.white
    }
});
// Customizable Area End

// Customizable Area Start
export interface Props extends WithStyles<typeof styles> {
    navigation: any;
}
// Customizable Area End

function MobileHeader(props: Props) {
    // Customizable Area Start
    const { classes, title, navigation,skipButton ,navigatTo }: any = props;
    console.log(props, "props")
    // Customizable Area End
    return (
        // Customizable Area Start
        <div className={classes.mobileAppBarroot}>
            <AppBar position="static" className={classes.appBarstyle}>
                <Toolbar variant="dense" disableGutters={true} style={{
                    display: "flex", justifyContent: "space-between", alignItems: "center", height : 60
                }}>
                    {console.log(navigatTo && `${navigatTo}`, "navigate to")}

                    <div
                        style={{
                            display: "flex", justifyContent: "center", alignItems: "center"
                        }}>
                        <IconButton
                            color={"inherit"}
                            aria-label="Open drawer"
                            onClick={() => navigation.goBack()}

                        >
                            <img src={icons.back} />
                        </IconButton>
                        <Typography  style={{ color: COLORS.black, ...FAMILYFONTS.family1,fontSize: 15,fontWeight:"bold" }} noWrap>
                            {title}
                        </Typography>
                    </div>
                    {skipButton === true ?
                        <Link
                            //    data-testid="nonProfitLinkTest"
                            to={`${navigatTo}`}
                            style={{ ...FONTS.signInTitle, fontSize: "16px", textDecoration: "none" }}
                        >
                            <IconButton
                                color={"inherit"}
                                aria-label="Open drawer"
                            // onClick={() => navigation.navigate(`${navigatTo}`)}

                            >
                                <Typography variant="h6" style={{ color: COLORS.skyBlue, ...FONTS.largeTitle }} noWrap

                                >
                                    Skip
                                </Typography>
                            </IconButton>
                        </Link>
                        : ""
                    }

                </Toolbar>
            </AppBar>
        </div>
        // Customizable Area End
    );
}

// Customizable Area Start
MobileHeader.propTypes = {
    classes: PropTypes.object.isRequired,
} as any;
// Customizable Area End

export default withStyles(styles)(MobileHeader);