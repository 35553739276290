import React from "react";
import { useField } from 'formik';
import { InitiateTextField } from "../design-system/text-field";
import { HideContainer } from "./hide-container";
import { InitiateAlert } from "../design-system/alert";

export function FormikTextField(props: React.ComponentProps<typeof InitiateTextField>) {

  const [inputProps, meta] = useField(props?.name ?? '');

  return (
    <div>
      <InitiateTextField
        fullWidth
        variant="outlined"
        {...inputProps}
        {...props}
      />

      <HideContainer hideWhen={!(meta?.error && meta?.touched)}>
        <InitiateAlert>
          {meta?.error}
        </InitiateAlert>
      </HideContainer>
    </div>
  );
}
