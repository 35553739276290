Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GroupChat";
exports.labelBodyText = "GroupChat Body";

exports.btnExampleTitle = "CLICK ME";
exports.chatToken = 'account_block/accounts/token_for_chat?identity=';
exports.chats = 'bx_block_groupchat/conversation_list?filter=';
exports.clearChat = 'bx_block_groupchat/create_clear_chat';
exports.exitGroup = 'bx_block_groupchat/exit_group';
exports.saveMedias = 'bx_block_groupchat/save_media';
exports.getMedias = 'bx_block_groupchat/get_media';
exports.userType = 'user_entity';
exports.multiPartFormData = 'multipart/form-data';
// Customizable Area End