import React from "react";
// Customizable Area Start
import {
  // Customizable Area Start
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  InputBase,
  InputAdornment,
  TextField,
  Typography,
  Box,
  Checkbox,
  Button,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import {
  icons,
} from "../../../components/src/constants";
import PropTypes from "prop-types";
// @ts-ignore
import classesOuter from "./NpChat.module.css";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import NpChatController, {
  Props,
  ChatObject,
  TwillioMessage,
  MemberDataEntity
} from "./NpChatController.web";
import EmojiPicker from "emoji-picker-react";
import ImageLoader from '../../../components/src/custom/ImageLoader';
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    tabsIndicator: {
      backgroundColor: "#1890ff",
    },
    tabRoot: {
      textTransform: "initial",
      // minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$tabSelected": {
        // color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    table: {
      minWidth: 650,
    },
    chatSection: {
      width: "100%",
      height: "80vh",
    },
    headBG: {
      backgroundColor: "#e0e0e0",
    },
    borderRight500: {
      borderRight: "1px solid #e0e0e0",
    },
    messageArea: {
      maxHeight: "290px",
      overflowY: "auto",
    },
    alignLeftCss: {
      teatAlign: "left",
      backgroundColor: "red",
      width: "fit-content"
    },
    alignRightCss: {
      textAlign: "right",
    },
    messageSend: {
      width: '55%',
      marginLeft: '45%'
    },
    messageReceived: {
      width: '55%',
    },
    chatFont: {
      fontFamily: 'Rene_Bieder_Milliard_Book, serif',
      fontSize: '16px',
      color: '#000000c2'
    },
    sendMessageLink: {
      color: '#fff'
    },
    receivedMsgLink: {
      color: '#000000c2'
    },
    cancelIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
      cursor: 'pointer'
    },
    icon: {
      background: "rgb(59, 149, 204)",
      color: "#fff",
      borderRadius: "0px 10px 0px 10px",
      width: "50px",
      height: "35px",
      padding: "7px"
    },
    clearChatDiv: {
      fontSize: '16px',
      border: '1px solid #efefef',
      padding: '10px 15px',
      borderRadius: '8px',
      position: 'absolute',
      right: '20px',
      backgroundColor: '#fff',
    },
    clearChatModal: {
      position: "absolute",
      border: '1px solid #bbbbbb94',
      padding: '25px',
      borderRadius: '15px',
      // @ts-ignore
      zIndex: '3',
      backgroundColor: '#fff',
      left: '20%',
      top: '27%'
    },
    clearChatHead: {
      fontSize: '17px',
      fontFamily: 'Rene_Bieder_Milliard_Book, sans-serif',
      color: '#000',
    },
    clearChatSubHead: {
      color: 'grey',
      lineHeight: '25px',
      fontSize: '15px',
      marginBottom: '10px'
    },
    checkBox: {
      display: 'flex',
      gap: '20px',
      marginBottom: '10px'
    },
    darkButton: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    imageMediaShare: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    mediaLoader: {
      display: 'flex',
      justifyContent: 'center',
      height: '17vh',
      width: 'auto',
      alignItems: 'center'
    },
    pinnedMessage: {
      width: '60%',
      marginLeft: '40%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: '14px',
      gap: '10px',
      color: '#009BE2',
      marginBottom: '10px'
    },
    pinnedMsgReceiver:{
      marginLeft:20,
      width: '60%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingRight: '14px',
      gap: '10px',
      color: '#009BE2',
      marginBottom: '10px'
    },
    pinModal: {
      width: '22%',
      marginLeft: '77%',
      border: '1px solid #efefef',
      padding: '13px',
      borderRadius: '12px'
    },
    senderName: {
      color: '#019BE1',
      fontWeight: 600,
      fontFamily: 'Rene_Bieder_Milliard_Medium, sans-serif',
      textTransform: 'capitalize'
    },
    messageContainer: {
      textAlign: 'start',
      position: 'relative',
      borderBottomRightRadius: '78px',
      border: '1px solid transparent'
    },
    tickMarkContainer: {
      position: 'absolute',
      bottom: '5px',
      right: '-5px'
    },
    receiverMedia: {
      paddingBottom: '27px',
      paddingTop: '10px',
      position: 'relative'
    },
    mediaImageSharingSend: {
      height: '200px'
    },
    mediaImageSharingReceived: {
      height: '200px'
    }
  });
// Customizable Area End

class NpChat extends NpChatController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getSentMessage = (showDropdown: boolean, pinned: boolean, message: any, classes: any, index: number, tickmark: any) => (
    <>
      {showDropdown ?
        <div className={classes.pinModal}>
          <Typography
            style={{ fontFamily: 'Rene_Bieder_Milliard_Medium', fontSize: '12px', paddingBottom: '10px' }}
            data-testid='pinMessage'
            onClick={() => pinned ? this.unpinMessage(message) : this.pinMessage(message)}
          >
            {pinned ? 'Unpin message' : 'Pin message'}
          </Typography>
          <Typography data-testid="deleteMsg" onClick={() => this.deleteMessage(message)} style={{ fontFamily: 'Rene_Bieder_Milliard_Medium', fontSize: '12px' }}>Delete message</Typography>
        </div> : null}
      <ListItem key={index} className={classes.messageSend} data-testid='pinDropdown' onClick={() => this.setState({ dropDownPin: !this.state.dropDownPin, currentMessageSelected: message.sid })}>
        <Grid container className={classesOuter.alignRightCss}>
          <Grid item xs={12}>
            {message.type == 'media' ? (
              <div className={classes.mediaImageSharingSend}>
                <ImageLoader
                  loaderStyle={{
                    marginTop: '15%',
                    textAlign: "center",
                    marginLeft: '30%',
                  }}
                  classes={classes.imageMediaShare}
                  alt="media"
                  imageUrl={message.media}
                />
              </div>
            ) : (
              <ListItemText
                primary={
                  <div className={classes.messageContainer}>
                    <small style={{ color: '#fff' }} className={classes.chatFont}>
                      {showDropdown ? (
                        <img
                          style={{ position: 'absolute', top: '-13px', right: '-4px', height: '16px', width: '16px' }}
                          src={icons.view_down}
                          alt="arrowDown" />
                      ) : null
                      }
                      <span className="messagesContent">{this.handleMessageSendLink(message, true, classes)}</span>
                    </small>
                    <div className={classes.tickMarkContainer}>
                      <img src={tickmark} alt='doubleTick' style={{ height: '19px', width: '25px' }} />
                    </div>
                  </div>
                }
              ></ListItemText>
            )}
          </Grid>
          <Grid item xs={12}>
            <ListItemText secondary={
              <small style={{ color: '#fff', fontSize: '14px' }}>
                {new Date(message.timestamp.toString()).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
              </small>
            } style={{ textAlign: 'end' }}></ListItemText>
          </Grid>
        </Grid>
      </ListItem>
      {pinned ? (
        <Box className={classes.pinnedMessage}>
          <Typography style={{ fontSize: '12px', fontFamily: 'Rene_Bieder_Milliard_Medium', }}>Message pinned by you </Typography>
          <img src={icons.pinIcon} alt="pinIcon" style={{ width: '16px', height: '16px' }} />
        </Box>
      ) : null}
    </>
  );
  getPinnedMessage(pinned:boolean,message:{customAttributes:{senderType:string}},classes:{pinnedMessage:string}){
    return (pinned ? (
      <Box style={{marginLeft:20,justifyContent: 'flex-start',}} className={classes.pinnedMessage}>
       <Typography style={{ fontSize: '12px', fontFamily: 'Rene_Bieder_Milliard_Medium', }}>Message pinned by {message.customAttributes.senderType == 'non_profit_entity'?'Organiser':'Business'} </Typography>
       <img src={icons.pinIcon} alt="pinIcon" style={{ width: '16px', height: '16px' }} />
      </Box>
     ) : null)
  }

  itemName(obj:ChatObject){
    let userName = "";
    const acc_id = localStorage.getItem("non_profit_acc_id")?.toString()
     if(obj.attributes.first_user_image.id.toString()!==acc_id){
      userName = obj.attributes.first_user_image.name
     }
     else{
      userName = obj.attributes.second_user_image.name
     }
    return userName;
  }

  handleUserName = (message:TwillioMessage) => {
   return this.state.chatInformation.attributes.friendly_name === "eventChatRoom"
  ? this.state.chatInformation.attributes.member_data
      .filter(
        (item: MemberDataEntity) =>
          item.id == message.customAttributes.senderId &&
          message.customAttributes.senderType.toLowerCase() ===
            item.type.toLowerCase()
      ).map((data: MemberDataEntity) => data.full_name)
    : this.itemName(this.state.chatInformation);                         
  }

  renderMsgs(msgsData: any, classes: any, classesOuter: any) {
    return <>{
      msgsData.map((message: any, index: number) => {
        let myMsgs = false;
        const flag = this.props.chatUserInformation.attributes.user_type === "non_profit_entity" ? message.customAttributes.senderId == this.state.userId : true
        if (message.customAttributes && flag &&
          message.customAttributes.senderType == 'non_profit_entity'
        ) {
          myMsgs = true;
        }
        let pinned = message.customAttributes.pinned;
        let showDropdown = false;
        if (this.state.dropDownPin && this.state.currentMessageSelected == message.sid) {
          showDropdown = true;
        }
        let tickmark = icons.singleTick;
        if (this.state.lastReadMessageIndex >= message.index) {
          tickmark = icons.GreenTick
        } else if (this.state.lastDeliveredMessageIndex >= message.index) {
          tickmark = icons.doubleTick;
        }
        return (
          <>
            {myMsgs ? (
              this.getSentMessage(showDropdown, pinned, message, classes, index, tickmark)
            ) : (
              <>
              <ListItem key={index} className={classes.messageReceived}>
                <Grid container className={classesOuter.alignLeftCss}>
                  {message.type == 'media' ? (
                   
                      <Grid item xs={12} style={{paddingTop: '10px'}}>
                      <span style={{fontSize: '1rem'}}>
                        <small className={classes.senderName} 
                        data-testid="userNameTest"
                        style={{fontFamily: 'Rene_Bieder_Milliard_Medium',textTransform: 'capitalize'}}
                        >
                          {this.handleUserName(message)}       
                        </small>
                      </span>
                      <div className={classes.mediaImageSharingReceived}>
                      <ImageLoader
                        loaderStyle={{
                            marginTop:'15%',
                            textAlign:"center",
                            marginLeft:'30%',
                        }}
                        classes={`${classes.imageMediaShare} ${classes.receiverMedia}`}
                        alt="media"
                        imageUrl={message.media}
                      />
                      </div>
                      <span style={{fontSize: '0.875rem',color: 'rgba(0, 0, 0, 0.54)',position: 'absolute',bottom: '15px',right: '27px'}}>
                        {new Date(message.timestamp.toString()).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}
                      </span>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <ListItemText
                          primary={
                            <small className={classes.senderName}
                              style={{ fontFamily: 'Rene_Bieder_Milliard_Medium', textTransform: 'capitalize' }}
                            >
                              {this.handleUserName(message)}
                            </small>
                          }
                          secondary={
                            <small className={classes.chatFont}>
                              <span className="messagesContent">{this.handleMessageSendLink(message, false, classes)}</span>
                            </small>
                          }
                        ></ListItemText>
                        <ListItemText secondary={new Date(message.timestamp.toString()).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} style={{ textAlign: 'end' }}></ListItemText>
                      </Grid>
                  )}
                </Grid>
              </ListItem>
              {this.getPinnedMessage(pinned,message,classes)}
              </>
            )}
          </>
        )
      })
    }
    </>
  }

  makeLinksClickable = (messageText: any, sendMessageLink: boolean, classes: any) => {
    const urlRegex = /(https?:\/\/[^\s]+)/;
    const messageParts = messageText.split(urlRegex);
    const formattedText = messageParts.map((part: any, index: any) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            style={{ textDecoration: 'underline' }}
            className={sendMessageLink ? classes.sendMessageLink : classes.receivedMsgLink}
          >
            {part}
          </a>
        );
      }
      else {
        return part;
      }
    });
    return formattedText;
  };

  handleMessageSendLink(message: any, sendMessageLink: boolean, classes: any) {
    return (
      <div>
        {this.makeLinksClickable(message.body, sendMessageLink, classes)}
      </div>
    )
  }

  renderEmojiPicker(classesOuter: any) {
    return (
      <>
        {this.state.emojiPicker ?
          <div className={classesOuter.emojiPicker} data-testid="emojiPickerTest">
            <EmojiPicker
              width="100%"
              skinTonesDisabled
              searchDisabled
              // @ts-ignore
              emojiStyle="google"
              onEmojiClick={this.handleEmojiSend}
            />
          </div> : null}
      </>
    )
  }
  setImg() {
    let imgUrl = "";
    if (this.state.chatInformation.attributes.friendly_name === 'eventChatRoom') {
      imgUrl = this.state.chatInformation.attributes.image?.url
    }
    else {
      const acc_id = localStorage.getItem("non_profit_acc_id")?.toString();
      imgUrl = this.props.chatUserInformation?.attributes?.first_user_image?.id.toString() !== acc_id
        ? this.props.chatUserInformation?.attributes?.first_user_image?.url?.url
        : this.props.chatUserInformation?.attributes?.second_user_image?.url?.url
    }
    return imgUrl
  }

  setEventName() {
    if (this.state.chatInformation.attributes.friendly_name === 'eventChatRoom') {
      return this.state.chatInformation.attributes.event_name
    }
    else {
      let fullName = "";
      const acc_id = localStorage.getItem("non_profit_acc_id")?.toString()
      if (this.props.chatUserInformation?.attributes?.first_user_image?.id.toString() !== acc_id) {
        fullName = this.props.chatUserInformation?.attributes?.first_user_image?.name
      }
      else {
        fullName = this.props.chatUserInformation?.attributes?.second_user_image?.name
      }
      return fullName;
    }
  }

  checkDisableCondition = () => {
    if (
      localStorage.getItem("np_user_role") === "User" &&
      (this.state.chatInformation.attributes.friendly_name === "eventChatRoom" ||
        this.state.chatInformation.attributes?.member_data?.some((member: MemberDataEntity) => member.type === "user_entity") ||
        this.state.chatInformation.attributes?.member_data?.some((member: MemberDataEntity) => member.type === "business_entity"))
    ) {
      return true;
    } else {
      return false;
    }
  }
  // Customizable Area End
  render() {
    const { classes, theme }: any = this.props;
    const imageLink = this.setImg()
    const userName = this.setEventName()
    // @ts-ignore
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div>
          {!this.state.searchBar ?
            <div className={classesOuter.modalHeaderContainer}>
              <div className={classesOuter.modalPicTitle}>
                <div>
                  {imageLink && imageLink !== '' ?
                    <img src={imageLink} alt="Event Pic" className={classesOuter.modalDp} /> :
                    <Avatar>{userName?.charAt(0)}</Avatar>
                  }
                </div>
                <div className={classesOuter.modalEventTitle}>{userName}</div>
              </div>
              <div className={classesOuter.modalSearchSettingIcon}>
                <div className={classesOuter.modalSearchContainer} data-testid="SearchTest" onClick={() => this.setState({ searchBar: true })}>
                  <IconButton aria-label="search" color="inherit">
                    <img className={classesOuter._search_logo} src={icons.search} />
                  </IconButton>
                </div>
                <div>
                  <IconButton aria-label="display more actions" edge="end" color="inherit">
                    <img data-testid="clearChatTest" className={classesOuter._search_logo} src={icons.chatMoreIcon} onClick={() => this.setState({ clearChat: !this.state.clearChat })} />
                  </IconButton>
                  {this.state.clearChat ?
                    <Box className={classes.clearChatDiv} data-testid="clearChatDivTest" onClick={() => this.setState({ deleteModal: true, clearChat: !this.state.clearChat })}>
                      <Typography>Clear chat</Typography>
                    </Box> :
                    null
                  }
                  {this.state.deleteModal === true ?
                    <Box className={classes.clearChatModal}>
                      <Typography className={classes.clearChatHead}>Clear chat</Typography>
                      <Typography className={classes.clearChatSubHead}>( Messages will be delete for permanently ).</Typography>
                      <Box className={classes.checkBox}>
                        <Checkbox
                          data-testid='clearchat24hour'
                          value={1}
                          checked={this.state.clearChatOption == 1}
                          onClick={this.onChangeClearChat}
                          style={{ margin: '0px', padding: '0px' }}
                          icon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                            </svg>
                          }
                          checkedIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke='#019BE1' fill='#019BE1' stroke-width="1" />
                              <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                            </svg>
                          }
                          disableRipple
                          color="primary"
                        />
                        <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Book' }}>Over 24 hours</Typography>
                      </Box>
                      <Box className={classes.checkBox}>
                        <Checkbox
                          value={2}
                          checked={this.state.clearChatOption == 2}
                          onClick={this.onChangeClearChat}
                          style={{ margin: '0px', padding: '0px' }}
                          icon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                            </svg>
                          }
                          checkedIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke='#019BE1' fill='#019BE1' stroke-width="1" />
                              <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                            </svg>
                          }
                          disableRipple
                          color="primary"
                        />
                        <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Book' }}>Over 1 Week</Typography>
                      </Box>
                      <Box className={classes.checkBox}>
                        <Checkbox
                          value={3}
                          checked={this.state.clearChatOption == 3}
                          onClick={this.onChangeClearChat}
                          style={{ margin: '0px', padding: '0px' }}
                          icon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                            </svg>
                          }
                          checkedIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke='#019BE1' fill='#019BE1' stroke-width="1" />
                              <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                            </svg>
                          }
                          disableRipple
                          color="primary"
                        />
                        <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Book' }}>Over 1 month</Typography>
                      </Box>
                      <Box className={classes.darkButton}>
                        <Button
                          variant="outlined"
                          style={{
                            width: '48%',
                            boxShadow: "none",
                            border: '1px solid #009be2',
                            textTransform: "capitalize",
                            color: "#009be2",
                            fontFamily: "Rene_Bieder_Milliard_Medium",
                            borderRadius: 10,
                            marginTop: '10px'
                          }}
                          color="primary"
                          data-testid="deletedTest"
                          onClick={() => this.setState({ deleteModal: !this.state.deleteModal })}
                        >
                          Cancel
                        </Button>
                        <Button
                          data-testid="modalTest"
                          variant="outlined"
                          style={{
                            width: '48%',
                            boxShadow: "none",
                            textTransform: "none",
                            backgroundColor: " #009be2",
                            color: "white ",
                            fontFamily: "Rene_Bieder_Milliard_Medium",
                            borderRadius: 10,
                            border: "none",
                            marginTop: '10px'
                          }}
                          color="primary"
                          disabled={this.state.clearChatOption < 1}
                          onClick={this.clearChat}
                        >
                          Clear chat
                        </Button>
                      </Box>
                    </Box> :
                    null}
                </div>
              </div>
            </div> :
            <div className={classesOuter.SearchBarDiv}>
              <IconButton aria-label="search" color="inherit">
                <img className={classesOuter._search_logo} src={icons.search} />
              </IconButton>
              <TextField placeholder="Search..." data-testid='messageSearch' onKeyDown={() => this.onSearchNext()} onChange={this.onSearchChange} value={this.state.searchTerm} className={classesOuter.textSearch} />
              <Typography className={classesOuter.cancelButton} data-testid='cancelTest' onClick={() => this.setState({ searchBar: false, searchTerm: '' })}>Cancel</Typography>
            </div>
          }
          {/* Modal body and footer */}
          <div className={classesOuter.modalContent}>
            {/* Modal Chat Body */}
            <div className={classesOuter.modalBodyContainer}>
              <List className={classes.messageArea} id='messagesListing'>
                {this.state.loading ? (
                  <div
                    style={{
                      height: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress style={{ color: "#009be2" }} />
                  </div>
                ) : null}
                {Object.keys(this.state.messages).map((dateKey) => {
                  return <>
                    <p className={classesOuter.dateIssue}>{dateKey}</p>
                    {this.renderMsgs(this.state.messages[dateKey].data, classes, classesOuter)}
                  </>
                })}
                {this.state.showMediaLoading ? (
                  <ListItem className={classes.messageSend}>
                    <Grid container className={classesOuter.alignRightCss}>
                      <Grid item xs={12}>
                        <div className={classes.mediaLoader}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    </Grid>
                  </ListItem>
                ) : null}
              </List>
            </div>
            {/* Modal Footer */}
            <div className={classesOuter.modalFooterContainer}>
              <div className={classesOuter.modalFooterInput}>
                <InputBase
                  data-test-id="chatInput"
                  placeholder={"Type something.... (Max 150 characters)"}
                  value={this.state.messageText}
                  onKeyDown={this.keyUp}
                  onChange={this.typingMessage}
                  fullWidth={true}
                  disabled={this.checkDisableCondition()}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        data-test-id="btnEmoji"
                        aria-label="emoji button"
                        edge="end"
                        onClick={this.handleEmojiPicker}
                      >
                        <img
                          style={{ width: '25px' }}
                          src={icons.chatEmojiIcon}
                          alt="emoji icon"
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="file"
                          accept="image/*"
                          data-test-id='fileInputImport'
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            cursor: 'pointer',
                            zIndex: 999
                          }}
                          onChange={this.importData}
                        />
                        <IconButton
                          data-test-id="btnGallery"
                          aria-label="gallery button"
                          edge="end"
                        >
                          <img
                            src={icons.chatGalleryIcon}
                            style={{ width: '25px' }}
                            alt="gallery Icon"

                          />
                        </IconButton>
                      </div>
                    </InputAdornment>
                  }
                />
              </div>
              <div className={this.checkDisableCondition() ? classesOuter.disableSendContainer : classesOuter.modalSendContainer}>
                <IconButton
                  data-test-id="btnSend"
                  aria-label="send button"
                  onClick={this.sendMessage}
                  style={{padding: '10px'}}
                  disabled={this.checkDisableCondition()}
                >
                  <img
                    src={icons.sendIcon}
                    style={{ width: '25px' }}
                    alt="sendIcon"
                  />
                </IconButton>
              </div>
            </div>
            {this.renderEmojiPicker(classesOuter)}
          </div>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

NpChat.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Customizable Area End

export default withStyles(styles as any, { withTheme: true })(NpChat as any);
export { NpChat }
// Customizable Area End
