// Customizable Area Start
import React, { useRef } from 'react';
import { Typography, IconButton, Button, Box } from "@material-ui/core";
import clsx from 'clsx';
import { Formik, Form, } from "formik";
import * as Yup from "yup";
import GooglePlacesAutocomplete,{ geocodeByAddress } from 'react-google-places-autocomplete';
import { avatar } from "./assets";
import { icons, } from "../../../components/src/constants";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { RootState } from "../../../framework/src/redux/stores";
import { InitiateButton } from "../../../components/src/design-system/button";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { FormikTextField } from "../../../components/src/shared/formik-text-field";
import { InitiateLabel } from "../../../components/src/design-system/label";
import { useUpdateUserBioAndAddress, useUserProfile } from "../../../components/src/api/hooks/user.hooks";
import { useNavigate } from "../../../components/src/utils/router-context";
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../../../components/src/api/keys/user.keys';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { InitiateAlert } from '../../../components/src/design-system/alert';
import { RegisterationMobileHeader } from './RegisterationMobileHeader';
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { useCurrentLocation } from '../../../components/src/utils/current-location';
import { configJSON } from './EmailAccountRegistrationController';


const initialValues = {
  streetName: "",
  town: "",
  country: "",
  postCode: "",
  area: "",

  bio: "",
  profile_picture: "",
  picture_error: null
};

let validationSchemaobj = Yup.object().shape({
  area: Yup.string().required("Enter Area name").trim(),
  streetName: Yup.string().required("Enter Street name").trim(),
  town: Yup.string().required("Enter Town or city").trim(),
  country: Yup.string().required("Enter Country").trim(),
  postCode: Yup.string().required("Enter Post code").trim(),

  bio: Yup.string().required("Enter Bio").trim(),

});

const useStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'min(850px, 90vh)',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
      height: 'max-content',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  titleText: {
    fontSize: '1.8rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
    textAlign: 'center',
  },
  subtitleText: {
    color: '#656469',
    fontSize: '0.8rem',
    fontFamily: 'Rene_Bieder_Milliard_Book',
  },
  submitButton: {
    fontSize: '1.3rem',
  },
  skipButton: {
    fontSize: '1.3rem',
  },
  personalInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  avatarBtn: {
    boxShadow: '0 0 8px rgba(0,0,0,.12)',
    width: '85px',
    height: '85px',
    position: 'relative',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  avatarBtnSelected: {
    padding: '0',
  },
  avatarImg: {
    maxWidth: '80px',
    objectFit: 'cover',
    height: '50px',
  },
  avatarImgSelected: {
    height: '80px',
    width: '80px',
  },
  cameraIcon: {
    position: 'absolute',
    top: '6px',
    right: '6px',
    maxWidth: '17px',
    backgroundColor: '#c7daf6',
    borderRadius: '50%',
    padding: '3px',
  },
  userInfoTitle: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '1.4rem',
    fontWeight: 700,
  },
  userInfoSubtitle: {
    color: '#9d9d9d',
    fontSize: '1rem',
  },
  userInfoContainer: {
    marginLeft: '15px',

  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  requiredText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '0.8rem',
    color: '#656469',
    '& span': {
      color: '#B86D62',
    },
  },
  helperText: {
    boxSizing: 'border-box',
    position: "absolute",
    top: "0",
    right: "0",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: '15px 0 8px 0',
  },
  bioHelperText: {
    fontSize: '1rem',
    color: '#a4a3a4',
  },
}));



export function PersonalInformationForm() {

  const {
    accountId,
    email,
  } = useSelector((state: RootState) => state.signupForm)

  const { currentLocation } = useCurrentLocation();

  const accountQuery = useUserProfile({ accountId: parseInt(accountId) });
  const apiProfile = accountQuery?.data?.data?.attributes;

  const isMobile = useIsMobileScreen();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate: update, isLoading, error } = useUpdateUserBioAndAddress();

  const apiError = String(error?.error ?? '');

  function skipHandler() {
    navigate('/DBSdetailsweb');
  }

  const getAddressDetails = (results: google.maps.GeocoderAddressComponent[]) =>  {
    const addressObj = {
      area: "",
      city: "",
      postcode: "",
      country: "",
    };

    for (const component of results) {
      if (component.types.includes('postal_code')) {
        addressObj.postcode = component.long_name;
      } else if (!addressObj.city && (component.types.includes('postal_town') || component.types.includes("administrative_area_level_2") || component.types.includes('administrative_area_level_1'))) {
        addressObj.city = component.long_name;
      } else if (component.types.includes('country')) {
        addressObj.country = component.long_name;
      }
    }
    return addressObj;
  }

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
          bio: apiProfile?.bio ?? '',
          //profile_picture, 
          country: currentLocation.country || apiProfile?.country || '',
          streetName: currentLocation.address || apiProfile?.street_name || '',
          area: currentLocation.area || apiProfile?.area || '',
          town: currentLocation.city || apiProfile?.city || '',
          postCode: currentLocation?.postcode || apiProfile?.post_code || '',
        }}
        enableReinitialize
        validationSchema={validationSchemaobj}
        onSubmit={(values) => {

          const {
            bio, profile_picture, country, streetName, area, town, postCode
          } = values;

        update({
          bio,
          profilePicture: profile_picture,
          accountId,
          address: {
            country,
            streetName,
            area,
            city: town,
            postCode,
          },
        }, {
          onSuccess() {
            queryClient.invalidateQueries(userKeys.fetchProfile({ accountId: parseInt(accountId) }));
            navigate('/DBSdetailsweb');
          }
        });
      }}
    >
      {({ values, setFieldValue, touched, errors }) => (
        //@ts-ignore
        <Form autoComplete="off" translate="no">

            <div className={classes.formContentRoot}>


              <HideContainer hideWhen={isMobile}>
                <Typography
                  variant="h1"
                  color="primary"
                  className={classes.titleText}
                >
                  {'Personal Information'}
                </Typography>
              </HideContainer>


              <input
                ref={inputRef}
                name='profile_picture'
                accept='image/*'
                type='file'
                hidden
                onChange={(event) => {

                  if (event.target.files && event.target.files[0]) {
                   if(event.target.files[0].type === 'image/jpeg'){
                      setFieldValue('picture_error', null)
                      setFieldValue('profile_picture', event.target.files[0]);
                   }else{
                    setFieldValue('picture_error', 'Please Upload .jpg Image Only')
                   }
                  }

                }}
              />


              <div className={classes.formContent}>

                <VerticalSpacer />

                <Typography>
                  {'Please enter your personal details.'}
                </Typography>

                <VerticalSpacer />

                <div className={classes.personalInfoContainer}>


                  <IconButton
                    className={clsx(classes.avatarBtn, { [classes.avatarBtnSelected]: values?.profile_picture })}
                    onClick={() => {
                      if (inputRef && inputRef.current)
                        inputRef.current.click();
                    }}>
                    <img
                      className={clsx(classes.avatarImg, { [classes.avatarImgSelected]: values?.profile_picture })}
                      src={values?.profile_picture ? URL.createObjectURL(values.profile_picture) : avatar}
                      alt={apiProfile?.full_name + " avatar"} />
                    <img
                      className={classes.cameraIcon}
                      src={icons.camerablack}
                      alt="camera" />
                      
                  </IconButton>

                  <div className={classes.userInfoContainer}>
                    <Typography className={classes.userInfoTitle}>
                      {apiProfile?.full_name}
                    </Typography>
                    <Typography className={classes.userInfoSubtitle}>
                      {email}
                    </Typography>
                    <HideContainer hideWhen={!apiProfile?.full_phone_number}>
                      <Typography className={classes.userInfoSubtitle}>
                        {`+${apiProfile?.full_phone_number}`}
                      </Typography>
                    </HideContainer>
                  </div>

                </div>
                <HideContainer hideWhen={values?.picture_error === null}>
                  <VerticalSpacer value={12} />
                  <InitiateAlert>
                    {values?.picture_error}
                  </InitiateAlert>
                </HideContainer>
                <VerticalSpacer value={20} />

                <InitiateLabel>
                  {'Bio'}
                </InitiateLabel>
                <VerticalSpacer value={12} />
                <FormikTextField
                  inputProps={{ maxLength: 150 }}
                  name="bio"
                  multiline
                  placeholder="Type Something..."
                  rows={4}
                  helperText={<>
                    <span className={classes.bioHelperText}>{"*"}</span>
                    <span className={classes.bioHelperText}>{`(${values?.bio?.length ?? 0}/150)`}</span>
                  </>}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText
                    }
                  }}
                />

                <VerticalSpacer value={25} />

                <InitiateLabel>
                  {'Address'}
                </InitiateLabel>

                <VerticalSpacer value={12} />
                <Box className='address-location personal-info'>
                  <GooglePlacesAutocomplete
                    data-testid="street"
                    apiKey={configJSON.mapsAPIKey}
                    debounce={500}
                    selectProps={{
                      isClearable: true,
                      classNamePrefix: "react-select",
                      placeholder: "Door No/Street Name *",
                      value: values.streetName ? [{ label: values.streetName, value: values.streetName }] : undefined,
                      noOptionsMessage: () => null,
                      onChange: async (event) => {
                        const location = event?.value?.description ?? "";
                        setFieldValue("streetName", location);
                        setFieldValue("area", "");
                        setFieldValue("town", "");
                        setFieldValue("country", "");
                        setFieldValue("postCode", "");
                        geocodeByAddress(location)
                          .then(results => {
                            const { area, postcode, city, country } = getAddressDetails(results[0].address_components);
                            setFieldValue("area", area);
                            setFieldValue("town", city);
                            setFieldValue("country", country);
                            setFieldValue("postCode", postcode);
                          }
                        )
                      }
                    }}
                  />
                </Box>
                <HideContainer hideWhen={!touched.streetName && !errors.streetName}>
                  <InitiateAlert>
                    {errors.streetName}
                  </InitiateAlert>
                </HideContainer>
               <VerticalSpacer value={12} />
                <FormikTextField name="area" placeholder="Area" />
                <VerticalSpacer value={12} />
                <FormikTextField name="town" placeholder="Town/City *" />
                <VerticalSpacer value={12} />
                <FormikTextField name="country" placeholder="Country *" />
                <VerticalSpacer value={12} />
                <FormikTextField name="postCode" placeholder="Post Code *" />

                <VerticalSpacer value={12} />

                <Typography className={classes.requiredText}>
                  {'Fields marked with '}<span>{"'*'"}</span>{' are mandatory fields.'}
                </Typography>

                <HideContainer hideWhen={!apiError}>
                  <VerticalSpacer value={12} />
                  <InitiateAlert>
                    {apiError}
                  </InitiateAlert>
                </HideContainer>

                <VerticalSpacer value={12} />

              </div>

              <div className={classes.submitButtonsContainer}>
                <InitiateButton
                  loading={isLoading}
                  disabled={isLoading}
                  className={classes.submitButton}
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large">
                  {'Continue'}
                </InitiateButton>
                <HideContainer hideWhen={isMobile}>
                  <Button
                    className={classes.skipButton}
                    fullWidth
                    color="primary"
                    variant="text"
                    onClick={skipHandler}
                    size="large">
                    {'Skip'}
                  </Button>
                </HideContainer>
              </div>


            </div>

          </Form>
        )}
      </Formik>

      <RegisterationMobileHeader
        title="Personal Information"
        hideSkipButton
      />
    </>
  );
}



// Customizable Area End
