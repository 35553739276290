import React from "react";
import { useIsMobileScreen } from "../../../components/src/utils/is-mobile";
import { NotificationsSettingsForm } from "./EmailNotificationForm";

export function EmailNotificationContent() {
  const isMobile = useIsMobileScreen();
  return (
    <NotificationsSettingsForm
      title="Email Notifications"
      type="email"
      onSuccessNavigateTo="/Availability"
      hideTitle={isMobile}
    />
  );
}
