{/* Customizable Area Start */ }
// reducer.js

import * as eventActionTypes from "./offerARoleActions"

const initialstate = {
    offerRoleFormData: {}
}

const offerARoleReducer = (state = initialstate, action: { type: any; payload: { offerRoleFormData: any } }) => {
    if (action.type === eventActionTypes.SET_OFFER_A_ROLE_DETAILS) {
        return {
            ...state,
            offerRoleFormData: {
                ...initialstate.offerRoleFormData,
                id: action.payload.offerRoleFormData.id,
                first_name: action.payload.offerRoleFormData.attributes.first_name,
                last_name: action.payload.offerRoleFormData.attributes.last_name,
                email: action.payload.offerRoleFormData.attributes.email,
                role: action.payload.offerRoleFormData?.attributes?.role,
                userId:action.payload.offerRoleFormData.userId,
                isNewUser: false,
            }
        }
    } else if (action.type === eventActionTypes.RESET_OFFER_A_ROLE_DETAILS) {
        return {
            ...state,
            offerRoleFormData: {
                ...state.offerRoleFormData,
                isNewUser: true
            }
        }
    }
    else {
        return state
    }
}

export default offerARoleReducer;

{/* Customizable Area Start */ }
