// Customizable Area Start
import React from "react";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import {
  CircularProgress as Loader,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  InputBase
} from "@material-ui/core";
import { Scrollbar } from "react-scrollbars-custom";
import { styles } from "./UserProfileBasicBlock.web";
import PropTypes from "prop-types";
// @ts-ignore
import classesoutercss from "./UserProfileBasicBlock.module.css";
import { withStyles } from "@material-ui/core/styles";
import {
  icons,FAMILYFONTS
} from "../../../components/src/constants";
export const primaryText = {
  width: 150,
  overflow: "hidden",
  textOverflow: "ellipsis",
};
import { HideContainer } from '../../../components/src/shared/hide-container';

class UserConnections extends UserProfileBasicController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderUserConnections(classes: any) {
    const sortedConnections=this.state.userconnections?.sort((a:any,b:any)=>{
      let first=a?.attributes?.name || a?.full_name
      let second=b?.attributes?.name || b?.full_name
      return first.localeCompare(second)
    })
    const filteredconnectionsmob = this.getFilteredResult(sortedConnections) 
    return this.state.userconnections?.length > 0 ? (
      <div className={classesoutercss.DialogContent}>
        <Scrollbar style={{ height: "100%", width: "100%" }}>
        <style>
            {`.ScrollbarsCustom-Track {
                        width : 2px !important;
                        margin-left : 10px !important

                      }`}
          </style>
          <div>
            {this.searchBox(classes)}
            {filteredconnectionsmob?.map((ele: any, index: any) => (
              <div key={index} data-testId="user-info" className={classesoutercss.followingContainer} onClick={()=> this.handleRedirection(ele)}>
                <List className={classes.root}>
                  {this.renderlistItem(classes,ele)}
                </List>

                <div>{this.renderButton(ele?.attributes?.status, ele?.id)}</div>
              </div>
            ))}
            {this.noItemText(filteredconnectionsmob?.length)}
          </div>
        </Scrollbar>
      </div>
    ) : (
      this.renderNoData()
    );
  }

  handleRedirection = (ele: { attributes: { status: string | null; receipient_id: string | number | null; account_id: string | number | null; }; id: string | number; }) => {
    const status = ele?.attributes?.status;
    if(status === "pending"){
      this.props.navigation.navigate("UserProfileView", { id: ele.attributes.receipient_id });
    } else if(status === "approved"){
      if(localStorage.getItem("acc_id") === ele.attributes?.receipient_id?.toString()){
        this.props.navigation.navigate("UserProfileView",{id: ele.attributes?.account_id});
      } else {
        this.props.navigation.navigate("UserProfileView",{id: ele.attributes?.receipient_id});
      }
    } else {
      this.props.navigation.navigate("UserProfileView", { id: ele.id });
    }
  }

  renderNoData() {
    return (
      <div className={classesoutercss.DialogContent}>
        <p style={{ textAlign: "center", marginTop: 300 }}>No Data Available</p>
      </div>
    );
  }
  renderButton = (status: any, id: any) => {
    if (status == "pending") {
      return (
        <Button
          data-testId="pending-btn"
          style={{width: 100 ,fontFamily: "Rene_Bieder_Milliard_Semibold",}}
          variant="outlined"
          className={classesoutercss.selectedcategoryPendingMenubuttonstyle}
          onClick={(e) => e.stopPropagation()}
        >
          Pending{" "}
        </Button>
      );
    } else if (status == "approved") {
      return (
        <Button
          data-testId="approved-btn"
          style={{
            width: 100,
          }}
          variant="outlined"
          className={classesoutercss.selectedcategoryConnectedMenubuttonstyle}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Connected
        </Button>
      );
    } else {
      return (
        <Button
          style={{  width: 100 }}
          variant="outlined"
          className={classesoutercss.selectedcategoryConnectMenubuttonstyle}
          data-testId="addConnection"
          onClick={(e) => {
            e.stopPropagation();
            this.addConnectionRequest(id);
          }}
        >
          Connect
        </Button>
      );
    }
  };
  searchBox=(classes:any)=>{
    const Inputprops={"aria-label": "search"}
    return (<div className={`${classes.searchdivContainer} ${classes.mobileContainer}`}>
      <div className={classes.flexItem} >
          <div className={classes.searchIcon}>
              <img
                   className={classes.searchImageStyle}
                  src={icons.search}
             />
          </div>

          <InputBase
              placeholder="Search Connection"
              classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
              }}
              inputProps={Inputprops}
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
          />

      </div>
  </div>)
  }
  noItemText=(length:any)=>{
    return ( <HideContainer hideWhen={length}>
      <Typography style={{ ...FAMILYFONTS.family2, fontSize: 16,textAlign:"center",marginTop:10 }}>
          {'There are no connections present'}
      </Typography>
  </HideContainer>)
  }

  renderlistItem=(classes:any,ele:any)=>{
    return  <ListItem className={classes.rootList}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          ele?.profile_picture?.url ||
                          ele?.attributes?.profile_picture?.url
                        }
                        style={{
                          height: "60px",
                          width: "60px",
                          marginRight: "10px",
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          style={{
                            ...primaryText,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {ele?.full_name || ele?.attributes.name}
                        </Typography>
                      }
                    />
                  </ListItem>
  }


  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <MobileHeader
          navigatTo={"/"}
          data-testid="mobileSearchMainContainer"
          navigation={this.props.navigation}
          title={"Connections"}
        />
        <div>
          {this.state.userloader ? (
            <div
             className={classes.userLoaderContainer}
            >
              <Loader className={classes.loaderStyle} />
            </div>
          ) : (
            this.renderUserConnections(classes)
          )}
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
UserConnections.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export { UserConnections };
export default withStyles(styles as any)(UserConnections as any);
// Customizable Area End

// Customizable Area End
