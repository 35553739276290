import React from "react";
import PropTypes from "prop-types";

// @ts-ignore
import module from "./BusHomeDashboard.module.css";
import ServiceSpecificSettingsAdminController, {
  Props,
} from "./ServiceSpecificSettingsAdminController";
import BusDashbaordWeb from "./BusDashboard.web";

import {
  Typography,
  Container,
  Paper,
  Table,
  Dialog,
  TableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow,
  Button,
  IconButton,
  Divider,
  Input,
  InputAdornment,
  CircularProgress,
  Badge,
  Box,
  LinearProgress
} from "@material-ui/core";
import {
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { icons, images } from "../../../components/src/constants";
import BusDropdowns from "../../../components/src/custom/BusDropdowns";
import FilterDrops from "../../../components/src/custom/FilterDrops";
import moment from "moment";
import BusNonProfitProfile from './BusNonProfitProfile.web'
import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter } from 'react-router-dom'
import FundCampaign from "../../events/src/fundCampaign.web";

// Customizable Area Start 

const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);

const TableHead: any = withStyles({
  root: {
    borderBottom: "3px solid #eaeaea",
  },
})(MuiTableHead);

function CircularProgressWithLabel(props: any) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress style={{ height: 90, width: 90, color: props.noOfDays > 7 ? '#45e71d' : 'red' }} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={'column'}
      >
        <Typography variant="caption" component="div" style={{ color: 'white', fontWeight: 'bold' }}>{props.noOfDays}</Typography>
        <Typography variant="caption" component="small" style={{ color: 'white' }}> Days Left</Typography>
      </Box>
    </Box>
  );
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }),
)(LinearProgress);


// Customizable Area End

class BusHomeDashboard extends ServiceSpecificSettingsAdminController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  renderSubscriptionBanner = () => {
    return (
      <div style={{ margin: '30px 25px 0 25px', position: 'relative' }}>
        <img src={images.subscriptionBannerImg} className={module.bannerImg} alt="img" ></img>

        <div className={module.mainSubscribeDiv}>
          <div style={{ display : 'flex'}}>
            <div className={module.progressDiv}>
              <CircularProgressWithLabel value={(this.state.daysRemaning / this.state.totalDays) * 100} totalDays={this.state.totalDays} noOfDays={this.state.daysRemaning} />
            </div>
            <div className={module.textDiiv}>
              <Typography className={module.subText}>Upgrade your subscription</Typography>
              <Typography className={module.noteText}>Upgrade your plan to maintain access to all services.</Typography>
            </div>
          </div>
          <div className={module.btnDiv}>
            <Button className={module.upgradeBtn} onClick={() => this.navigateToPlanChangePage("Upgrade")}>Upgrade Plan</Button>
          </div>
        </div>
      </div>
    )
  }

  renderStartFreeTrialSubscriptionBanner = () => {
    return (
      <div style={{ margin: '30px 25px 0 25px', position: 'relative' }}>
        <img src={images.subscriptionBannerImg} className={module.bannerImg} alt="img" ></img>

        <div className={module.mainSubscribeDiv}>
          <div className={module.textDiivFreeTrial}>
            <Typography className={module.subText}>Start your 14 days FREE trial today</Typography>
            <Typography className={module.noteText}>Start 14 days FREE trial and get access to all services&</Typography>
          </div>
          <div className={module.btnDiv}>
            <Button className={module.upgradeBtn} onClick={() => this.navigateToPlanChangePage("Select")}>Start Free Trial</Button>
          </div>
        </div>
      </div>
    )
  }

  calculateProgress(empCount:number,totalEmpCount:number) {
    const planName = localStorage.getItem('plan')
    if(empCount>totalEmpCount) return 100;
    if ((planName === "Trial-Business")|| empCount===0) {
        return 1;
    }
    else {
        const progressPercentage : number = (empCount / totalEmpCount) * 100
        return progressPercentage 
    }
  }

  renderFreeTrialExpired = () => {
    return (
      <div style={{minHeight:200}} className={module.mainFreeTrialDiv}>
        <div className={module.freeTrialDiv}>
          <Typography className={module.freeTrialText}>Your {localStorage.getItem("plan") === "Non paying - Presence only"?"":localStorage.getItem("plan")} plan is expired!</Typography>
          <div className={module.verifieEmp}>Verified employees</div>
          <BorderLinearProgress className={module.progressEmployeeBar} variant="determinate" value={ this.calculateProgress(this.state.registeredEmployees, this.state.totalEmployees)} />
          <Box className={module.infoText}>{this.state.registeredEmployees} employees verified their employment {localStorage.getItem("plan")==="Trial-Business"?"":`out of ${this.state.totalEmployees}`} </Box>
        </div>
        <div className={module.subNowDiv}>
          <Button className={module.subNowBtn} onClick={() => this.navigateToPlanChangePage("Upgrade")}>Subscribe Now</Button>
        </div>
      </div>
    )
  }

  fixedNestedTurnery = () => {
    return (
      (this.state.expired || localStorage.getItem("plan") === "Non paying - Presence only") ? this.renderFreeTrialExpired() : this.renderSubscriptionBanner()
    )    
  }

  renderSubscriptionData = () => {
    let subscribe = localStorage.getItem('showSubscribe')
    return (
      subscribe === 'true' ? this.renderStartFreeTrialSubscriptionBanner() : this.fixedNestedTurnery()
    )
  }

  renderSkeleton() {
    return (
      <div className={module.mainFreeTrialDiv}>
        <Skeleton variant="rect" animation="wave" width={'100%'} height={130} />
      </div>
    )
  }

  handleEventType(val: any) {
    if (val === 'need') {
      return 'Need';
    } else if (val === 'activity') {
      return 'Upcoming Activity';
    } else {
      return 'Projects';
    }
  }


  tableBodyData() {
    return (
      this.state.filterBusEventsData.length > 0 ?
        <Table>
          <TableHead>
            <TableRow>
              {/* @ts-ignore */}
              <TableCell className={module.thead} width="27%">
                Title
              </TableCell>
              <TableCell
                className={module.thead}
                align="left"
                // @ts-ignore
                width="20%"
              >
                Type
              </TableCell>
              <TableCell className={module.thead} align="center" width='10%'>
                PARTICIPANTS
              </TableCell>
              <TableCell className={module.thead} align="left">
                Location
              </TableCell>

              <TableCell
                className={module.thead}
                align="center"
                // @ts-ignore
                width="20%"
              >
                START AT
              </TableCell>
              <TableCell className={module.thead} align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.filterBusEventsData?.map((item: any, index: any) => (
              <TableRow key={index}>
                <TableCell
                  className={module.tbody}
                  component="th"
                  scope="row"
                >
                  {item.attributes.event_name}
                </TableCell>

                <TableCell className={module.tbody} align="left">
                  {this.handleEventType(item.attributes.event_type)}
                </TableCell>

                <TableCell className={module.tbody} align="center">
                  {item.attributes.max_attendence}
                </TableCell>
                <TableCell className={module.tbody} align="left">
                  {item.attributes?.location.split(",")[0]}
                </TableCell>
                <TableCell className={module.tbody} align="center">
                  {moment(item.attributes?.from_date).format("DD/MM/YYYY")} {moment(item.attributes?.start_time, "hh:mm A").format("hh:mm A")}
                </TableCell>
                <TableCell width="10%">
                  {item.attributes?.event_type === "project_funding" &&
                  <Button
                    variant="outlined"
                    style={{
                      width: 70,
                      height: 30,
                      boxShadow: "none",
                      textTransform: "capitalize",
                      backgroundColor: " #009be2",
                      color: "white",
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                      borderRadius: 10,
                      marginRight: 13,
                      marginLeft: 13,
                      border: "none",
                      fontSize: 14,
                    }}
                    color="primary"
                    data-test-id="view-btn"
                    onClick={() => this.setState({ eventId: item.id })}
                  >
                    View
                  </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        : <Typography
          style={{
            textAlign: "center",
            height: "50%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontFamily: "Rene_Bieder_Milliard_Medium",
          }}
        >
          Sorry, No data available
        </Typography>


    )
  }

  tableData() {
    return (
      this.state.loader ? <div
        style={{
          height: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress style={{ color: "#009be2" }} />
      </div> : this.tableBodyData()
    )
  }


  renderSearchedOrgData() {
    return (
      this.state.searchedOrgData?.length > 0 ?
        <Table>
          <TableHead>
            <TableRow>
              {/* @ts-ignore */}
              <TableCell className={module.thead} width="27%">
                Name
              </TableCell>
              <TableCell className={module.thead} align="center">
                Location
              </TableCell>
              <TableCell className={module.thead} align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.searchedOrgData.map((item: any) =>
              <TableRow data-testId={`searchedOrg-${item.id}`}>
                <TableCell
                  className={module.tbody}
                  component="th"
                  scope="row"
                >
                  {item?.attributes?.organisation_name}
                </TableCell>
                <TableCell className={module.tbody} align="center">
                  {item?.attributes?.town !== null ? item?.attributes?.town : '-'}
                </TableCell>
                {/* @ts-ignore */}
                <TableCell width="10%">
                  <Button
                    data-test-id="viewBtn"
                    variant="outlined"
                    style={{
                      width: 100,
                      boxShadow: "none",
                      textTransform: "capitalize",
                      backgroundColor: " #009be2",
                      color: "white ",
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                      borderRadius: 10,
                      marginRight: 13,
                      marginLeft: 13,
                      border: "none",
                    }}
                    color="primary"
                    onClick={() => this.checkFollowStatus(item)}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table> : <Typography
          style={{
            textAlign: "center",
            height: "15%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontFamily: "Rene_Bieder_Milliard_Medium",
          }}
        >
          Sorry, No data available
        </Typography>
    )
  }

  render() {
    return (
      <div>
        {this.state.eventId && <FundCampaign id="" eventId={this.state.eventId} navigation={this.props.navigation} type="r3"  onClose={this.onClose}/>}
        {this.state.showNpProfile && <BusNonProfitProfile navigation={this.props.navigation} NpDetais={this.state.nonProfitProfileData} data-testId='busNonProfit' followingStatus={false} followStatus={this.state.followStatus} onBack={() => { this.setState({ showNpProfile: false }); this.getUpcomingEventsData(); } } id={""} classes={{}} theme={{}} adminSelectedTab={undefined} />}
        {(!this.state.eventId && !this.state.showNpProfile) && 
          <div>
            {/* @ts-ignore */}
            <BusDashbaordWeb children={this.props.children} navigation={this.props.navigation} id={""} adminSelectedTab={undefined} theme={"undefined"}
            >
              <div style={{ marginLeft: 280 }}>
                {this.state.loader ? this.renderSkeleton() : this.renderSubscriptionData()}
                <div>
                  <Typography className={module.clickText}>
                    Looking for a specific Non Profit Organisation ? {' '}
                    <span
                      data-testId='dropdowns'
                      style={{ color: "#009be2", cursor: 'pointer' }}
                      onClick={() => {
                        if (localStorage.getItem("plan")) {
                          this.setState({
                            showModal: true,
                          });
                        }
                      }}
                    >
                      Click here...
                    </span>
                  </Typography>
                </div>
                {this.state.showNpTable &&
                  <Container fixed style={{ position: 'relative', left: '-17.7%', width: '65%' }}>
                    <Paper style={{
                      marginTop: 40,
                      borderRadius: 16,
                    }}
                      elevation={0}>
                      {this.renderSearchedOrgData()}
                    </Paper>
                  </Container>
                }
                <Divider className={module.dividerLine} />
                <div>
                  <Container maxWidth={"xl"}>
                    <Paper
                      style={{
                        marginTop: 40,
                        borderRadius: 16,
                      }}
                      elevation={0}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: 30,
                        }}
                      >
                        <div>
                          <Typography className={module.heading}>
                            Upcoming events
                          </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <Input
                              data-testId='searchbar'
                              startAdornment={
                                <InputAdornment
                                  position="start"
                                  style={{
                                    width: 40,
                                    marginLeft: -9,
                                  }}
                                >
                                  <IconButton>
                                    <img
                                      src={icons.searchSVG}
                                      style={{
                                        height: 25,
                                        width: "auto",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              }
                              placeholder="Search..."
                              className={module.searchField}
                              disabled={!localStorage.getItem("plan")}
                              onChange={(e) => this.searchEventsData(e.target.value)}
                              disableUnderline
                            />
                          </div>
                          <div>
                            {!this.state.showNpTable &&
                              <Button
                                data-testId='filterIcon'
                                disabled={!localStorage.getItem("plan")}
                                onClick={() => {
                                  this.setState({
                                    filterToggle: !this.state.filterToggle,
                                  });
                                }}
                              >
                                <Badge color="secondary" variant="dot" invisible={this.state.event_type === "" ? true : false}>
                                  <IconButton
                                    style={{
                                      background: "#FAFCFE",
                                      border: `${this.state.filterToggle
                                        ? "1px solid #009be2"
                                        : "1px solid #DEE1EA"
                                        }`,
                                      borderRadius: 12,
                                    }}
                                  >
                                    <img src={icons.busFilter} />
                                  </IconButton></Badge>
                              </Button>}
                          </div>
                        </div>
                      </div>
                      <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />

                      {this.tableData()}

                    </Paper>

                    <Dialog
                      hideBackdrop
                      PaperProps={{
                        style: {
                          background: "#FEFEFE",
                          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                          borderRadius: 18,
                          border: '1px solid #D9F6FA',
                          width: 400,
                          height: 'auto',
                          position: "absolute",
                          padding: 10,
                          top: '30%',
                          right: '2.75%',
                        },
                        elevation: 0,
                      }}
                      open={this.state.filterToggle}
                      data-testId="filterModal"
                      onClose={() => {
                        this.setState({ filterToggle: false });
                      }}
                    >
                      <div>
                        <FilterDrops data-testId='filterDrops' filterData={this.state.event_type} selectedFilter={(val: string) => this.handleEventsFilter(val)} applyFilter={this.filterEvents} 
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        location={this.state.location}
                        proximity={this.state.proximity}
                        onProximityChange={this.onProximityChange}
                        onLocationChange={this.onLocationChange}
                        />
                      </div>
                    </Dialog>
                  </Container>
                </div>
              </div>
            </BusDashbaordWeb>
            <Dialog
              // hideBackdrop
              PaperProps={{
                style: {
                  background: "#FEFEFE",
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
                  borderRadius: 18,
                  width: 500,
                  height: 510,
                },
                elevation: 0,
              }}
              open={this.state.showModal}
              data-testId="busDropModal"
              onClose={() => {
                this.setState({ showModal: false });
              }}
            >
              <div>
                <BusDropdowns
                  data-testId='searchOrgDrops'
                  causeOptions={this.state?.causeTypeOptions}
                  helpOptions={this.state?.helpTypeOptions}
                  whereOptions={this.state?.whereTypeOptions}
                  provideOptions={this.state?.providerTypeOptions}
                  handleCauseChange={(val: any) => this.handleCauseSearchOrgFilter(val)}
                  handleHelpChange={(val: any) => this.handleHelpSearchOrgFilter(val)}
                  handleWhereChange={(val: any) => this.handleWhereSearchOrgFilter(val)}
                  handleProvideChange={(val: any) => this.handleProvideSearchOrgFilter(val)}
                  onClick={() => this.appliedOrgWiseSearchFilter()} />
              </div>
            </Dialog>
          </div>
        }
      </div>
    );
  }
}
export { BusHomeDashboard }
// @ts-ignore
export default withRouter(BusHomeDashboard)
