import React from "react";

// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import "../assets/css/ResetPassword.css";
import {
  FONTS,
  FAMILYFONTS,
} from "../../../components/src/constants";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { InitiateButton } from "../../../components/src/design-system/button";
import { HideContainer } from "../../../components/src/shared/hide-container";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { Formik, Form } from 'formik';
import { FormikTextField } from "../../../components/src/shared/formik-text-field";
import * as Yup from 'yup';
import { InitiateAlert } from "../../../components/src/design-system/alert";
// Customizable Area End

// Customizable Area Start
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must be of 8 characters with a capital letter, a special character & a number."
    ),
  confirmPassword: Yup.string()
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password did not match."
      ),
    })
    .required("Please enter confirm password"),
});
// Customizable Area End
export default class ResetPasswordPage extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          this.changePassword(values);
        }}
      >
        {
          formik => (
            // @ts-ignore
            <Form translate="no">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <Typography
                  variant="h4"
                  component="h2"
                  color="primary"
                  className="resetPwdTitle"
                  style={{
                    ...FAMILYFONTS.family2,
                    fontSize: 36,
                    fontWeight: 400,
                    marginBottom: 30,
                  }}
                >
                  {'Reset Password'}
                </Typography>

                {/* Form Content */}
                <Box>
                  <VerticalSpacer />

                  <FormikTextField
                    label="New Password"
                    variant="outlined"
                    data-test-id="txtInputNewPassword"
                    type="password"
                    placeholder={"Password"}
                    className="resetPwdInput"
                    fullWidth={true}
                    name="password"
                    value={formik.values.password}
                    onChange={(e)=>{
                      formik.setFieldValue("password", e.target.value);
                      this.setState({ otpError: "" });
                    }}
                    helperText={
                      <HideContainer hideWhen={formik.errors.password}>
                        <Typography
                          className="resetPwdConditionMsg"
                          style={{ ...FONTS.body2 }}
                        >
                          {'(Min.8 Characters)'}
                        </Typography>
                      </HideContainer>
                    }
                  />


                  <VerticalSpacer value={40} />

                  <FormikTextField
                    label="Confirm Password"
                    data-test-id="txtInputConfirmPassword"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={(e)=>{
                      formik.setFieldValue("confirmPassword", e.target.value);
                      this.setState({ otpError: "" });
                    }}
                    type="password"
                    placeholder={"Confirm password"}
                    className="resetPwdInput"
                    variant="outlined"
                    fullWidth
                  />

                  <VerticalSpacer />

                </Box>

                <HideContainer hideWhen={!this.state.otpError}>
                  <InitiateAlert>
                    {this.state.otpError}
                  </InitiateAlert>
                </HideContainer>

                {/* Footer Content */}
                <InitiateButton
                  data-test-id={"btnResetPassword2"}
                  variant="contained"
                  color="primary"
                  className="resetPwdBtn"
                  type="submit"
                  fullWidth
                  style={{
                    ...FAMILYFONTS.family1,
                    fontSize: 20,
                    fontWeight: 500
                  }}
                  disabled={!formik.isValid}
                >
                  {'Reset Password'}
                </InitiateButton>
              </Box>
            </Form>
          )
        }

      </Formik>
      // Customizable Area End
    );
  }
}
