// Customizable Area Start
const initialState = {
  selectedRow: null,
};

const usersNpReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case 'SELECT_ROW':
      return {
        ...state,
        selectedRow: action.payload,
      };
    default:
      return state;
  }
};

export default usersNpReducer;
// Customizable Area End