import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    adminSelectedTab: any;
    theme?: any;
    // Customizable Area End
}



interface S {
    // Customizable Area Start
    loader: boolean;
    filteredPendingData: any,
    showNpProfile: boolean,
    pending_employees: any,
    accepted_employees: any,
    registered_employees: any,
    tabValue: string,
    chatModal: boolean,
    currentChat: any;
    filteredRegisteredData: any,
    individualPendingDetails: any;
    openDetailsDialog: boolean;
    isConfirmOpen: boolean;
    individualRegisteredDetails: any,
    messageId: number | string | null;

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BusEmpolyeesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    registeredEmployeesApiCallId: string = "";
    rejectEmployeeApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loader: true,
            filteredPendingData: [],
            showNpProfile: false,
            pending_employees: [],
            accepted_employees: [],
            registered_employees: [],
            currentChat: {},
            tabValue: "confirmed",
            chatModal: false,
            filteredRegisteredData: [],
            individualPendingDetails: {},
            individualRegisteredDetails: {},
            openDetailsDialog: false,
            isConfirmOpen: false,
            messageId: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJsonData = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJsonData !== undefined && !responseJsonData.error) {
                this.handleRegisteredEmployees(apiRequestCallId, responseJsonData, errorReponse)
                this.handleRejectApiResponse(apiRequestCallId, responseJsonData, errorReponse)

            }
            else {
                this.parseApiCatchErrorResponse(errorReponse)
            }

        }


        // Customizable Area End
    }

    // Customizable Area Start


    async componentDidMount() {
        super.componentDidMount();
        this.getRegisteredEmployees();


    }



    searchPendingData = (searchedPending: string) => {
        const pendingData = this.state.pending_employees.filter(
            (item: any) =>
                item?.attributes.full_name
                    ?.toLowerCase()
                    .includes(searchedPending.toLowerCase())
        );
        this.setState({
            filteredPendingData: pendingData,
        });
    }
    searchRegisteredData = (searchReistered: string) => {
        const acceptedEmployees = this.state.accepted_employees.filter(
            (item: any) =>
                item?.attributes.full_name
                    ?.toLowerCase()
                    .includes(searchReistered.toLowerCase())
        );
        this.setState({
            filteredRegisteredData: acceptedEmployees,
        });
    }

    getRegisteredEmployees = () => {

        const token = localStorage.getItem("token")
        const getEmployees = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": token
        };

        this.registeredEmployeesApiCallId = getEmployees.messageId;

        getEmployees.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getRegisteredEmplyeesApiEndPoint
        );
        getEmployees.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getEmployees.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getEmployees.id, getEmployees);
    }


    handleRegisteredEmployees = (apiRequestCallId: any, responseJsonData: any, errorReponse: any) => {
        if (apiRequestCallId == this.registeredEmployeesApiCallId) {
            let { pending_employees, accepted_employees } = responseJsonData
            this.setState({
                loader: false,
                filteredRegisteredData: accepted_employees?.data?accepted_employees?.data:[],
                filteredPendingData: pending_employees?.data?pending_employees?.data:[],
                pending_employees: pending_employees?.data?pending_employees?.data:[],
                accepted_employees: accepted_employees?.data?accepted_employees?.data:[]
            })

        }
    }

    handleConfirmClick = () => {
        this.setState({ tabValue: "confirmed" })
    }

    handlePendingClick = () => {
        this.setState({ tabValue: "pending" })
    }

    handleViewMore = (item: any) => {
        this.setState({
            individualPendingDetails: item,
            individualRegisteredDetails:item,
            openDetailsDialog: true
        })
    }
    handleDetailsModal = () => {
        this.setState({
            openDetailsDialog: !this.state.openDetailsDialog,
        });
    };

    handleRejectEmployee = (id: any) => {
        const token = localStorage.getItem('token')
        const httpBody = {
            "registered_employee_id": id
        };

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.rejectEmployeeApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.rejectEmployeeApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }




    handleRejectApiResponse = (apiRequestCallId: any, responseJsonData: any, errorReponse: any) => {

        if (apiRequestCallId == this.rejectEmployeeApiId) {
            if (responseJsonData?.message == "Employee request rejected") {
                this.getRegisteredEmployees();
                this.setState({ isConfirmOpen: false })
            }

        }
    }

    getFormatedFirstName = (full_name: any) => {
        let first_name = full_name.split(" ")?.[0]
        let str = this.formatOverFlow(first_name)
        return str;

    }
    getFormatedLastName = (full_name: any) => {
        let last_name = full_name.split(" ")?.[1]
        let newstr = this.formatOverFlow(last_name)
        return newstr;

    }

    getDbsFormatDialog = (arr: any) => {
        let dbsStr = arr.map((item: any) => item.dbs_type_name).join(",")
        return dbsStr
    }

    getDbsFormatTable = (dbsArray: any) => {
        if (dbsArray.length == 1) {
            return dbsArray[0]?.dbs_type_name
        }
        else if (dbsArray.length > 1) {
            return dbsArray[0]?.dbs_type_name + "..."
        }
    }
    formatOverFlow = (str: any) => {
        if (str && str?.length > 15) {
            let newStr = str.slice(0, 15)
            return newStr + "...";

        }
        else {
            return str;
        }
    }
    handleRejectBtn=()=>{
        this.setState({ openDetailsDialog: false, isConfirmOpen: true })
    }
    handleClose=()=>{
        this.setState({isConfirmOpen:false})
    }
    
    onClickPopup = ( sid: string) => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "BusChat");
        msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), sid);
        msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(msg);

        
      }
    // Customizable Area End
}
