// @ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Box,
  Switch,
  IconButton, InputBase, Slider, Tooltip,
  Container, Checkbox, Dialog, DialogContent, List, ListItem,ListItemSecondaryAction, ListItemText
} from "@material-ui/core";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Scrollbar } from 'react-scrollbars-custom';

import {createStyles, withStyles } from "@material-ui/core/styles";
// @ts-ignore 
import classesOuter from "./AdvancedSearch.module.css";
import AdvancedSearchController, { Props } from "./AdvancedSearchController";
import { FAMILYFONTS, FONTS, icons } from "../../../components/src/constants";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import { COLORS } from "../../../framework/src/Globals";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  
  
} from '@material-ui/pickers';
import { advSearchPayload } from "../../../framework/src/redux/advSearchPayload/advSearchPayloadaction";
import MomentUtils from '@date-io/moment';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { addSearchFilterDetails } from "../../../framework/src/redux/advanceSearch/advanceSearchActions";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Theme } from "react-native-elements";
import {setSelectedFilterEvents} from "../../../framework/src/redux/filterEvents/filterEventsAction";
import { storeSearchDetails } from "../../../framework/src/redux/savedSearch/savedSearchAction";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";
import { advFilter } from "../../../framework/src/redux/advFilter/advFilterAction";
import { isBefore } from "date-fns";
// Customizable Area End

// Customizable Area Start
const marks = [
  {
    value: 1,
  },
  {
    value: 5,
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
];
export const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      overflow: "inherit"
    },
    switchBase: {
      padding: 2,
      color: "#87888F",
      '&$checked': {
        transform: 'translateX(12px)',
        color: COLORS.skyBlue,
        '& + $track': {
          opacity: 1,
          backgroundColor: COLORS.white,
          borderColor: COLORS.infoGray,
        },
      },
    },
    thumb: {
      width: 12,
      height: 11.6,
      boxShadow: 'none',
    },
    track: {
      border: "1px solid rgba(151, 151, 151, 0.43272)",
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
    inputpicker: {
      borderRadius: 16
    },
  }),
)(Switch);

// Customizable Area End


// Customizable Area Start
export const useStyles = (theme: Theme) => ({

  eventPickerContainer: {
    display: "flex",
    flex: 1,
    // flexDirection: "column",
  },
  dateAbil: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    // marginBottom: 40,
    margin: "1rem 0",

  },

  // forms 

  formControl: {
    // margin: theme.spacing(1),
    width: "inharit"
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  aftersearchIconbutton: {
    borderRadius: "8px",
    color: "text.primary",
    backgroundColor: "white",
    margin: "8px",
    padding: "8px",
    height: 40,
    width: 30,
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: COLORS.white,
    marginLeft: 0,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    height: 48,
    display: "flex",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: COLORS.skyBlue,
  },
  inputRoot: {
    color: "#000000 !important",
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create("width"),
    fontSize: "revert",
  },

  toolBar: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    width: "100%",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionTabDesktop: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },


  // outer css
  "searchreletiveenv": {
    "position": "relative",
    "boxShadow": "0px 0px 8px rgba(212, 212, 212, 0.8)",
    "borderRadius": "12px",
    "zIndex": 2,
    "borderWidth": "1px",
    "boxSizing": "border-box",
    "outline": "0px !important",
    "WebkitBoxAlign": "center",
    "cursor": "default",
    "WebkitBoxPack": "justify",
    "transition": "all 100ms ease 0s"
  },

  searchEnv: {
    "position": "absolute",
    "top": "49px",
    "padding": "10px",
    "width": "inherit",
    "height": 90,
    "zIndex": 4,
    "background": "#FFFFFF",
    "boxShadow": "0px 0px 8px rgba(212, 212, 212, 0.5) !important",
    "borderBottomLeftRadius": "12px !important",
    "borderBottomRightRadius": "12px !important",
    "borderTopLeftRadius": "0% !important",
    "borderTopRightRadius": "0% !important",
  },
  "webkit_scrollbar": {
    "width": "5px"
  },
  "webkit_scrollbar_track": {
    "background": "#f1f1f1"
  },
  "webkit_scrollbar_button": {
    "width": "50px",
    "height": "50px"
  },
  "webkit_scrollbar_thumb": {
    "background": "rgba(212, 212, 212, 0.8)"
  },
  "webkit_scrollbar_thumb_hover": {
    "background": "rgba(212, 212, 212, 0.8)"
  },
  "catdropdownmenubackground": {
    position: "relative",
    "overflowY": "auto"
  },

  sliderBackground: {
    height: 90,
    overflowY: "auto"
  },
  "dbs_tabs_logo": {
    "width": "18px",
    "height": "8px"
  },
  sliderCustome: {
    color: "#009be2",
    fontSize: 48
  },

  calenderIconBox: {
    height: 25,
    width: 25,
    position: "absolute",
    top: 13,
    right: 10,
    '&>img': {
      height: "100%",
      width: "100%"
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative'
  },
  alarmIconBox: {
    height: 30,
    width: 30,
    position: "absolute",
    top: 14,
    right: 4,
    background: '#fff',
    pointerEvents: 'none',
    '&>img': {
      height: "100%",
      width: "100%"
    }
  },
  alarmtextField: {
    width: 200,
  },
  inputpicker: {
    borderRadius: 16
  },
  textFieldShadow: {
    boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.5)',
    border: "1.5px solid rgba(212, 212, 212, 0.5)",
  },
  textField: {
    padding: 0,
    borderRadius: 12,
    width: '100%',
    height: 54,
    position: "relative",
    boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.5)',
    border: "1.5px solid rgba(212, 212, 212, 0.5)",
   
  },
  fontResize: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: ' 20px',
    letterSpacing: '1.3px',
    color: "#767676", ...FAMILYFONTS.family3,
    marginTop: 4,
  }, cancelIconButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  }, pickerMargin: {
    marginTop: 0,
    marginBottom: 0
  },
  inputField: {
    color: "#000000 !important",
    "&::placeholder": {
      color: "black",
      fontFamily: "Rene_Bieder_Milliard_Book",
      fontWeight: 400,
      lineHeight: 20,
      letterSpacing: -0.4,
    }
  }

});
// Customizable Area End



class AdvancedSearch extends AdvancedSearchController {
  static propTypes: {
    classes // Customizable Area Start
    : PropTypes.Validator<object>; theme: PropTypes.Validator<object>;
  };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

 // Customizable Area Start
  renderSearchBox = () => {
    return (

      <Container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10
        }}
      >

        <div className={classesOuter.advancesearchreletiveenv}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

            <div style={{
              display: "flex", justifyContent: "center", alignItems: "center"
            }}>
              <img
                style={{
                  width: 25, height: 26,
                  margin: '16px 22px 12px -9px',
                  borderRadius: 0,
                }} src={icons.search} />
              <InputBase
                data-testId="searchbox"
                placeholder="Search events"
                onClick={()=>this.props.navigation.navigate("MobileSearchContainer")}
                inputProps={{ "aria-label": "search" }}
              />

            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button data-test-id='dbsBtn' className={this.props.advFilterDetails?.dbs_registration ? classesOuter.selecteddbsButton : classesOuter.notselecteddbsButton}
            onClick={() => 
            // this.setState({ dbs_included: !this.state.dbs_included })
            this.props.setAdvFilter({
              ...this.props.advFilterDetails,
              dbs_registration: !this.props.advFilterDetails?.dbs_registration
            })
          }>
            <Typography style={{
              fontSize: 10, ...FAMILYFONTS.family3, textTransform: 'none'
            }}> DBS included</Typography>
          </Button>
        </div>

      </Container>
    )
  }

  rendergoToSaveSearch = () => {
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
       
        >
          <Typography style={{
            fontSize: 16, ...FAMILYFONTS.family2, fontWeight: 600, color: "black", paddingLeft : 8
          }} >Go to saved searches </Typography>
        </div>

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <IconButton
            data-testId="savedSearch"
            aria-label="show 4 new mails"
            color="inherit"
            onClick={()=>{
              this.props.navigation.navigate("SavedSearchContainer");
            }}
          >

            <img
              style={{
                height: 26
              }}
              src={icons.bluerightarrow}
            />

          </IconButton>
        </div>

      </Container>
    )
  }


  renderCategoryList = (ele: any) => {

    return (
      <ListItemSecondaryAction>
        <Checkbox
          edge="end"
          color="primary"
          name={ele.attributes.task}
          onChange={this.handleCategoryChange}
          inputProps={{ 'aria-labelledby': ele.id }}
          checked={ele?.isChecked || false}
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={
            <CheckBoxIcon style={{ color: "#359ce2" }} />
          }
        />
      </ListItemSecondaryAction>
    )
  }

  renderFilterUi = (getDbsData: any) => {
    return (
      <Container>
        <div className={this.state.setadvancefilterCategory ? classesOuter.categoryscrollmain : ""}>
          {this.state.setadvancefilterCategory ?
            <div >
              <div style={{
                position: "relative",
                height: 215, overflow: "scroll"
              }}>
                {console.log(this.state.categories, "cateProps")}
                <style>
                      {`.ScrollbarsCustom-Track {
                        width : 2px !important
                      }`}
                    </style>
                    <Scrollbar style={{ height: '100%', width: '100%' }}>
                <List dense >
                  <ListItem button>

                    <ListItemText primary={"Select All"} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        color="primary"
                        name="allCategoryCheck"
                        onChange={this.handleCategoryChange}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                          <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                        checked={!this.props.advFilterDetails?.getDbsData?.some((cat: any) => cat?.isChecked !== true)}
                      />

                    </ListItemSecondaryAction>
                  </ListItem>
                  {getDbsData?.map((ele: any, index: any) => {
                    return (
                      <ListItem key={ele.id} data-test-id={ele.id} button>
                        <ListItemText id={ele.id} primary={ele.attributes.task} />
                        {this.renderCategoryList(ele)}
                      </ListItem>
                    );
                  })}
                </List>
</Scrollbar>
              </div>
            </div>
            : ""
          }
        </div>
      </Container>
    )
  }

  renderCategorySearch = (getDbsData: any) => {
    return (
      <Container>
        <div style={{
          position: "relative"
        }}>
          <Typography variant="body1" style={{ ...FAMILYFONTS.family1, fontSize: 17, fontWeight: 500, marginBottom: 6, paddingLeft : 8 }}>Categories</Typography>

        </div>

        {/* search  */}

        <div style={{
          position: "relative"
        }}>

          <div className={this.state.setadvancefilterCategory ? classesOuter.modalSearchMain : classesOuter.hidemodalSearchMain}>


            <div className={classesOuter.modalInSearchInput}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                height: 20,
                padding: 16,
              }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                  <InputBase
                    placeholder="Select the Categories"
                    classes={{
                      root: classesOuter.inputRoot,
                      input: classesOuter.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onClick={() => this.setState({ setadvancefilterCategory: !this.state.setadvancefilterCategory ? true : false })}
                    data-test-id='advanceFilter-1'
                    value={this.getCategoryValue()}
                  />
                </div>
                <IconButton
                  aria-label="show 4 new mails"
                  color="inherit"
                  className={classesOuter.aftersearchIconbutton}
                  onClick={() => this.setState({ setadvancefilterCategory: !this.state.setadvancefilterCategory ? true : false })}
                  data-test-id='advanceFilter-2'
                >
                  <img
                    className={classesOuter.dbs_tabs_logo}
                    src={icons.downarrowblue}
                  />

                </IconButton>
              </div>

              {/* filter option ui  */}
              {this.renderFilterUi(getDbsData)}

              {/* filter option ui  end */}

            </div>
          </div>
        </div>


      </Container>
    )
  }



  renderAvailibilyHeader = () => {
    return (
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 6,
            marginBottom: 10.
          }}>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Typography variant="body1" style={{ ...FAMILYFONTS.family1, fontSize: 15, fontWeight: 500, marginLeft: 10, marginTop: 10 }}>Availability</Typography>
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center", marginRight: 12, marginTop: 14
            }}
          >

            <Typography style={{ ...FAMILYFONTS.family1, fontSize: 16, fontWeight: 500, marginLeft: 6, marginRight: "0.75rem" }}>All day</Typography>
            <AntSwitch checked={this.props.advFilterDetails?.all_day} data-test-id='allDaySwitch' onChange={(e : any)=>this.handleAllDay(e?.target?.checked)} />
          </div>
        </div>
      </Container>
    )
  }

  renderAvailibilyInputs = (classes: any) => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
      <Container >
          {this.state.dateVisible === "fromDate" && 
            <DateRangePicker
              data-test-id="from-date"
              selectRange={false}
              isOpen={!!this.state.dateVisible}
              value={this.props.advFilterDetails.from_date}
              onCalendarClose={() => this.setState({ dateVisible: "" })}
              onChange={(e) => {
                this.props.setAdvFilter({...this.props.advFilterDetails, from_date : e });
                const toDateIsBeforeFromDate = isBefore(new Date(this.props.advFilterDetails.to_date), new Date(e));
                if (toDateIsBeforeFromDate){
                  this.props.setAdvFilter({...this.props.advFilterDetails, from_date : e, to_date : e });
                }
              }}
            />
          }
          {this.state.dateVisible === "toDate" &&
            <DateRangePicker
              data-test-id="to-date"
              selectRange={false}
              isOpen={!!this.state.dateVisible}
              value={this.props.advFilterDetails.to_date}
              minDate={this.props.advFilterDetails.from_date}
              onCalendarClose={() => this.setState({ dateVisible: "" })}
              onChange={(e) => {
                this.props.setAdvFilter({...this.props.advFilterDetails, to_date : e });
              }}
            />
          }
          <div
          className={classesOuter.gridContainer}
          style={{marginBottom:20, }}
         
          >
            <div style={{
              width:'100%',
            }}>
              <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, margin: 5, letterSpacing: "-0.35px", color: " #000000", }}>From Date</Typography>

              

                <Box
                  style={{
                    border: "1px solid #C9C9C9",
                    boxShadow: "none",
                    borderRadius: 10,
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: '2px 5px',
                    paddingLeft:'10px'
                  }}
                  className={classes.pickerMargin}
                >
                  <Typography style={{ ...FAMILYFONTS.family3, width: '100%', fontSize: 16, color: " #949494", }}>{this.formatDate('from')}</Typography>
                 

                  <Button 
                  data-test-id ="fromDate"
                  className={classesOuter.dateBtn}                  
                  onClick={()=> this.setState({ dateVisible: "fromDate" })} >
                  <img src={icons.calendarblue} alt="calender"
                      style={{
                        height: 18,
                        fill: COLORS.skyBlue
                      }} />
                  </Button>

                  


                </Box>
              


            </div>

          
            <div style={{
              width:'100%', 
            }}>
              <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, margin: 5, letterSpacing: "-0.35px", color: " #000000", }}>To Date</Typography>

              <Box
                  style={{

                    border: "1px solid #C9C9C9",
                    // width: "100%",
                    backgroundColor: "white",
                    boxShadow: "none",
                    borderRadius: 10,
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: '2px 5px',
                    paddingLeft:'10px'
                  }}
                  className={classes.pickerMargin}
              >
                  <Typography style={{ ...FAMILYFONTS.family3, width: '100%', fontSize: 16, color: " #949494", }}>{this.formatDate('to')}</Typography>
                 

                  <Button 
                  data-test-id = 'toDate'
                  className={classesOuter.dateBtn}                  
                  onClick={()=> this.setState({ dateVisible: "toDate" })} >
                  <img src={icons.calendarblue} alt="calender"
                      style={{
                        height: 18,
                        fill: COLORS.skyBlue
                      }} />
                  </Button>

                  


                </Box>
            

            </div>
          </div>
          {this.props.advFilterDetails?.all_day === false ? (
            <div  className={classesOuter.gridContainer}>
              <div style={{
                justifyContent: "center", alignItems: "flex-start", display: "flex", flexDirection: "column"
              }}>
                <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, margin: 5, letterSpacing: "-0.35px", color: " #000000", }}>From Time</Typography>
              <InitiateThemeProvider>
                <KeyboardTimePicker
                  className={classes.pickerMargin}
                  mask="__:__ _M"
                  ampm={false}
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  id="mui-pickers-time"
                  InputProps={{
                    classes: {
                      root: classes.textField,
                      input: classes.fontResize,
                    },
                  }}
                  data-test-id='onTime'
                  format="hh:mm A"
                  autoOk
                  value={this.props.advFilterDetails?.start_time}
                  onChange={(date : any) => {
                    // this.setState({ onTime: date })
                    this.props.setAdvFilter({
                      ...this.props.advFilterDetails,
                      start_time : date
                    })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Select time',
                  }}
                  style={{ fontSize: 18, width: '100%',  }}
                  keyboardIcon={<img src={icons.clockBlue}
                    style={{
                      height: 18,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                    }}
                    alt="Timer" />}
                />
              </InitiateThemeProvider>
            
              </div>
              <div style={{
                justifyContent: "center", alignItems: "inline-grid", display: "flex", flexDirection: "column"
              }}>
                <div>
                  <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, margin: 5, letterSpacing: "-0.35px", color: " #000000", }}>To time</Typography>
                </div>
              <InitiateThemeProvider>

                <KeyboardTimePicker
                  className={classes.pickerMargin}
                  mask="__:__ _M"
                  ampm={false}
                  format="hh:mm A"
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  id="mui-pickers-time"
                  InputProps={{
                    classes: {
                      root: classes.textField,
                      input: classes.fontResize,
                    },
                  }}
                  value={this.props.advFilterDetails?.end_time}
                  onChange={(date : any) => {
                    // this.setState({ offTime: date })
                    this.props.setAdvFilter({
                      ...this.props.advFilterDetails,
                      end_time : date
                    })
                  }}
                  data-test-id='offTime'
                  KeyboardButtonProps={{
                    'aria-label': 'Select time',
                  }}
                  style={{ fontSize: 12 }}
                  keyboardIcon={<img src={icons.clockBlue}
                  style={{
                    height: 18,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    
                  }}
                  alt="Timer" />}
                  />
                  </InitiateThemeProvider>

              </div>
            </div>
          ) : (
            ""
          )}



     
      </Container>
      </MuiPickersUtilsProvider>
    )

  }


  ValueLabelComponent = (props: any) => {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  handleChange = (event: any, proxySliderValue: any) => {
    // this.setState({ proximitySliderValue: proxySliderValue })
    this.props.setAdvFilter({
      ...this.props.advFilterDetails,
      up_to_km : proxySliderValue})
  };


  renderSearchButton = () => {
    return (
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "inherit",
            marginTop: 80,
          }}
        >
          <Button
            
            color="primary"
            variant="contained"
            type="submit"

            style={{
              width: "100%",
              backgroundColor: "#009BE2",
              boxShadow: "none",
              borderRadius: 8,
              height: 45,
              textTransform: "capitalize",
              ...FAMILYFONTS.family3
            }}
            data-test-id='searchBtn'
            onClick={() => {
              this.searchEvent();
            }}
          >
            Search
          </Button>
          <Button 
          data-test-id="open-modal"
          onClick={this.handleClickOpenadvancesearchModal}>
            <Typography style={{ ...FAMILYFONTS.family3, fontSize: 14, fontWeight: 600, textAlign: "center", margin: "1rem 0", color: "#009BE2", textTransform: "capitalize" }}>Save & Search</Typography>
          </Button>

        </div>
      </Container>
    )
  }

  renderProximeter = (classes: any) => {
    return (
      <Container  >


        {/* Proximity heading  */}
        <div style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          marginTop: 20,
          marginLeft: 6,
        }}>
          <Typography variant="body1" style={{ fontSize:15, fontWeight:'600', marginBottom : 6, paddingLeft : 8 }}>Proximity</Typography>

        </div>

        <div style={{ position: "relative" }}>
          <div
            className={this.state.proximityShow ? classesOuter.proximitySearchMain : classesOuter.hideProximitySearchMain}>
            <div className={classesOuter.modalInProximitySearchInput}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {/* <Typography variant="body1" style={{...FAMILYFONTS.family3,fontSize:12 }}>Up to</Typography> */}
                  <InputBase
                    placeholder={`Up to ${Math.round((this.props?.advFilterDetails?.up_to_km * 1.6).toFixed(2))} ${this.props?.advFilterDetails?.up_to_km < 2 ? "mile" : "miles"}`}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    disabled
                    value={`Up to ${this.props.advFilterDetails?.up_to_km} ${this.props?.advFilterDetails?.up_to_km < 2 ? "mile" : "miles"}`}
                    inputProps={{ "aria-label": "search", className: classes.inputField }}
                    onClick={() => this.setState({ proximityShow: !this.state.proximityShow })}
                    data-test-id='proxiMeter-1'
                  />
                </div>
                <IconButton
                  aria-label="show 4 new mails"
                  color="inherit"
                  className={classes.aftersearchIconbutton}
                  onClick={() => this.setState({ proximityShow: !this.state.proximityShow })}
                  data-test-id='proxiMeter-2'
                >
                  <img
                    className={classes.dbs_tabs_logo}
                    src={icons.downarrowblue}
                  />

                </IconButton>
              </div>
            </div>

            {this.state.proximityShow ?
              <div style={{
                position: "relative"
              }}>
                <div
                  style={{
                    height: 70,
                    display: "content",
                    zIndex: 4,
                    position: "relative",
                    borderRadius: 12,
                    padding: 24
                  }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, fontWeight: 600 }}>Radius {this.props.advFilterDetails?.up_to_km} {this.props?.advFilterDetails?.up_to_km < 2 ? "mile" : "miles"}</Typography>
                  </div>
                  <Box width="auto">
                     <Slider
                      defaultValue={10}
                      className={classes.sliderCustome}
                      step={null}
                      min={0}
                      max={50}
                      valueLabelDisplay="on"
                      marks={marks}
                      value={this.props.advFilterDetails?.up_to_km}
                      onChange={this.handleChange}
                    />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, fontWeight: 500 }}>0mi.</Typography>
                    <Typography style={{ ...FAMILYFONTS.family3, fontSize: 12, fontWeight: 500 }}>40mi.</Typography>
                  </div>
                </div>
              </div>
              : ""
            }

          </div>

        </div>


        {/* Proximity End */}



        <div>

        </div>
      </Container>
    )
  }


  renderSaveSearchModal = () => {
    return (
      <Dialog
        open={this.state.setAdvancesearchModal}
        onClose={this.handleClickCloseadvancesearchModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 12, width: 300 },
        }}
      >

        <DialogContent>
          <Box>
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <img src={icons.savedsearches} style={{ height: 80, width: 70 }} />
            </Box>
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <Typography variant="h6" style={{ ...FAMILYFONTS.family3, fontSize: 12 }}>
                <span data-testId="SaveInput" style={{ ...FAMILYFONTS.family2 }}>Save As: </span> <input style={{border:'none'}} onChange={(e) => this.setState({savedSearchName :e.target.value})} type="text" value={this.state.savedSearchName} />
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "10px 0px"
              }}
            >
              <Button
                data-test-id="btnCloseModal"
                variant="contained"
                style={{
                  width: "100%",
                  backgroundColor: "#009BE2",
                  boxShadow: "none",
                  borderRadius: 8,
                  height: 45,
                  textTransform: "capitalize",
                  color: "white"
                }}
                onClick={() => this.advanceSearchEvent()}
              >
                Save
              </Button>
              <Button
                data-test-id="btnCloseModal"
                variant="contained"
                style={{
                  ...FAMILYFONTS.family3,
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "none",
                  borderRadius: 8,
                  height: 45,
                  margin: "1rem 0",
                  textTransform: "capitalize",
                  border: "1px solid #979797",
                  color: "black"
                }}
                onClick={this.handleClickCloseadvancesearchModal}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>

      </Dialog>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    console.log(this.props.advFilterDetails, 'propsfromweb', this.state.categories)
 
    // Customizable Area End
    return (
      // Customizable Area Start
      <div style={{
        position: "reletive",
        width: "-webkit-fill-available",

      }}>
        {/* @ts-ignore */}
        <MobileHeader navigatTo={"MyTaskPreferenceweb"}
          data-testid="mobileHeader_test"
          title="Search Events"
          skipButton={false}
          navigation={this.props.navigation} />

        <div style={{
          // marginBottom: "30%",
          padding : 10
        }}>

          {/* Customizable Area Start */}
          {/* search  & DBS include */}
          {this.renderSearchBox()}
          {this.rendergoToSaveSearch()}
          {this.renderCategorySearch(this.props.advFilterDetails?.getDbsData)}
          {this.renderAvailibilyHeader()}
          {this.renderAvailibilyInputs(classes)}
          {this.renderProximeter(classes)}
          {this.renderSearchButton()}
          {this.renderSaveSearchModal()}

          {/* <AdvanceSearchCustom /> */}
          {/* Customizable End Start */}
        </div>



      </div>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

AdvancedSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};


export function mapStateToProps(state: any) {
  return {
    selectedevents: state.eventsReducer?.selectedEvents,
    searchFilter: state.searchFilter?.filter,
    filterEventsReducer:state.filterEventsReducer?.selectedEvents,
    advSearchPayloadDetails : state.advSearchPayloadReducer?.advSearchPayload,
    advFilterDetails : state.advFilterReducer?.advFilter
  }
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addSearchFilterDetails: (selectedOptions: any) => {
      return dispatch(addSearchFilterDetails(selectedOptions))
    },
    setSelectedFilterEvents:(selectedEvents: any)=>{
      return dispatch(setSelectedFilterEvents(selectedEvents))
    },
    storeSearchDetails:(savedSearchinfo: any)=>{
      return dispatch(storeSearchDetails(savedSearchinfo))
    },
    setAdvSearchEvents: (data: any) => {
      return dispatch(advSearchEvents(data))
    },
    setAdvSearchPayload: (data: any) => {
      return dispatch(advSearchPayload(data))
    },
    setAdvFilter : (data : any) => {
      return dispatch(advFilter(data))
    }

  }
}


export { AdvancedSearch }
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles as any)(AdvancedSearch))
// Customizable Area End



