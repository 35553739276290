import React from "react";

import {
  Container,
  Box,
  Button,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// @ts-ignore
import { FAMILYFONTS, images } from "../../../components/src/constants";
// @ts-ignore
import classOuter from "./CongratsBusiness.module.css";
import FormRegitrationController, {
  Props
} from "./RegistrationFormController";
// Customizable Area End


export default class CongratsBusiness extends FormRegitrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        <div className={classOuter.formContainer}>
          <div className={classOuter.otpLogoMainContainer}>
            <div className={classOuter.otpLogoContainer}>
              <img src={images.imgCheck} className={classOuter.otpLogo} />
            </div>
          </div>

          <div>
            <Box sx={{ width: "100%" }}>
              <Box className="forgotPwdMsg">
                
                {/* Congrats Message */}
                <Typography
                  variant="h4"
                  component="h2"
                  className={classOuter.congratsText}
                  style={{ ...FAMILYFONTS.family1,fontSize:24 }}
                >
                  Congrats!!
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classOuter.congratsSubText}
                  style={{ ...FAMILYFONTS.family3, fontSize: 24 }}
                >
                  Mobile number successfully verified
                </Typography>

                {/* Reset Password Button */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px"
                  }}
                >
                  <Button
                    data-testid="verifyEmailTest"
                    variant="contained"
                    color="primary"
                    className={classOuter.footerBtn}
                    fullWidth
                    // @ts-ignore
                    onClick={this.handleVerifyEmail}
                    style={{
                      fontFamily: "Rene_Bieder_Milliard_Semibold",
                      fontWeight: 500,
                      fontSize: 18,
                    }}
                  >
                    Verify email
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
