import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { HideContainer } from "../../../components/src/shared/hide-container";
import { InitiateAlert } from "../../../components/src/design-system/alert";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { InitiateLabel } from '../design-system/label';
import { VerticalSpacer } from './vertical-spacer';
import clsx from 'clsx';

const usePhoneInputStyles = makeStyles((theme) => ({
  phoneInputContainerClass: {
  },
  phoneInputInputClass: {
    width: "100% !important",
    height: "54px !important",
    paddingLeft: "78px !important",
    border: "none !important",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.119346)",
    borderRadius: "10px !important",
    fontSize: '16px !important',
    fontFamily: 'Rene_Bieder_Milliard_Medium',
    '&:focus+div': {
      borderTop: `1px solid ${theme.palette.primary.main} !important`,
      borderBottom: `1px solid ${theme.palette.primary.main} !important`,
      borderLeft: `1px solid ${theme.palette.primary.main} !important`,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },
  phoneInputButtonClass: {
    width: "66px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white !important",
    border: "none !important",
    borderRadius: "10px !important",
    borderTopRightRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
  },
  phoneInputSearchClass: {
  },
  phoneInputDropdownClass: {
    top: '43px',
    left: '0',
    borderRadius: "4px !important",
    boxShadow: "none !important",
    border: "1px solid #ddd",
    width: "350px !important"
  },
}));

export function InitiatePhoneInput({
  value,
  onChange,
  error,
  onBlur,
  label,
  placeholder,
  className,
}: {
  value: string,
  onChange: (value: string) => void,
  onBlur?: () => void,
  error?: string,
  label?: string,
  placeholder?: string,
  className?: string
}) {

  const classes = usePhoneInputStyles();
  return (
    <>
      <HideContainer hideWhen={!label}>
        <InitiateLabel>
          {label}
        </InitiateLabel>
        <VerticalSpacer value={10}/>
      </HideContainer>

      <PhoneInput
        containerClass={clsx(classes.phoneInputContainerClass, className)}
        inputClass={classes.phoneInputInputClass}
        buttonClass={classes.phoneInputButtonClass}
        searchClass={classes.phoneInputSearchClass}
        dropdownClass={classes.phoneInputDropdownClass}
        placeholder="Mobile Number *"
        country={'gb'}
        searchPlaceholder={placeholder}
        value={value}
        onChange={(phone) => {
          onChange(phone)
        }}
        onBlur={onBlur}
      />
      <HideContainer hideWhen={!error}>
        <InitiateAlert>
          {error}
        </InitiateAlert>
      </HideContainer>
    </>
  );
}
