// Customizable Area Start
import React from "react";
import {
    Box, CircularProgress, Avatar, List, ListItem,
    ListItemText, Typography, ListItemAvatar
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import { Scrollbar } from "react-scrollbars-custom";
import MobileHeader from "../../../components/src/custom/MobileHeader";
// Customizable Area End

class UserRegistered extends UserProfileBasicController {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    followingData() {
        const { registeredModule } = this.state;
        return registeredModule?.length > 0 ? (
            <div className="DialogContent">
                <Scrollbar className="scrollbar">
                    <div>
                        {registeredModule.map((ele: { organisation_id: number | string; organisation_logo: { url: string | undefined; }; organisation_name: string | null | undefined; }, index: number) => (
                            <div
                                key={index}
                                className="user-wrapper"
                                data-testId="redirect-btn"
                                onClick={() => this.props.navigation.navigate("NonProfitProfile", { id: ele.organisation_id })}
                            >
                                <List className="root">
                                    <ListItem className="rootList">
                                        <ListItemAvatar>
                                            <Avatar
                                                className="avatar"
                                                alt="not found"
                                                src={ele?.organisation_logo?.url}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography className="text">
                                                    {ele.organisation_name}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </div>
                        ))}
                    </div>
                </Scrollbar>
            </div>
        ) : (
            <div className="DialogContent">
                <p className="followingErr">No Data Available</p>
            </div>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <MobileHeader
                    title={"Registered"}
                    navigatTo={"/"}
                    navigation={this.props.navigation}
                />
                <Wrapper>
                    {this.state.registeredLoader ? (
                        <div className="loader">
                            <CircularProgress className="loaderStyle" />
                        </div>
                    ) : (
                        this.followingData()
                    )}
                </Wrapper>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default UserRegistered;
const Wrapper = styled(Box)({
    "& .loader": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 250,
        "& .loaderStyle": {
            height: '24 !important',
            width: '24 !important',
            color: "#009be2",
        }
    },
    "& .DialogContent": {
        width: "auto !important",
        margin: "0px 10px 0px 0",
        padding: "0px 0px 0px 20px !important",
        background: "#ffff",
    },
    "& .root": {
        flexGrow: 1
    },
    "& .user-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .rootList": {
        padding: 0
    },
    "& .followingErr": {
        textAlign: "center",
        marginTop: 250,
    },
    "& .avatar": {
        height: "60px",
        width: "60px",
        marginRight: "10px",
    },
    "& .text": {
        fontFamily: "Rene_Bieder_Milliard_Semibold",
    },
    "& .scrollbar": {
        height: "calc(100vh - 61px) !important",
        width: "100%",
    },
    "& .ScrollbarsCustom-Track": {
        width: "2px !important",
        marginLeft: "10px !important"
    }
});
// Customizable Area End
