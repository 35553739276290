import React from "react";
// Customizable Area Start

import PropTypes from "prop-types";
import Select from "react-select";

import ContentManagementController, {
  Props,
} from "./ContentManagementController";

// @ts-ignore
import module from "./ContentManagementEvent.module.css";
// @ts-ignore

import { Container, Paper } from "@material-ui/core";
import { COLORS } from "../../../framework/src/Globals";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import CustomAlertModal from "../../../components/src/custom/CustomAlertModal";

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export type CounterProps = { label?: string };

const styles = (theme: Theme) => createStyles({});

const eventTypes = [
  { label: "Need", value: "need" },
  { label: "Project funding", value: "project_funding" },
  { label: "Upcoming activity", value: "activity" },
];

// Customizable Area End

class CreateEvent extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const borderStyle1 = this.state.createEventState
      ? "1px solid  #3696C3"
      : "1px solid lightgrey";
    const borderStyle2 = !this.state.createEventState
      ? "1px solid lightgrey"
      : "1px solid  #3696C3";

    const borderStyle3 = this.state.createEventState
      ? "1px solid  #009BE2"
      : "1px solid lightgrey";

    const plan = localStorage.getItem("plan");
    const isDisplayAll = plan !== "Trial" && plan !== "Presence-Only";
    const height = isDisplayAll ? "200px" : "160px";
    return (
      <div>
        <Container maxWidth={"xl"}>
          <div>
            <Paper
              className={classes.tableroot}
              style={{
                marginTop: 30,
                height: "69vh",
              }}
              elevation={0}
            >
              <div style={{ marginTop: 10 }} />
              <label
                className={module.heading}
                style={{ padding: 10, marginBottom: 5 }}
              >
                New event
              </label>
              <div
                style={{
                  borderTop: "2px solid #EAEAEA",
                }}
              />
              <div style={{ marginTop: 20, padding: 10, marginLeft: 10 }}>
                <label className={module.label}>Select type</label>

                <div
                  style={{
                    border: `${borderStyle1}`,
                    width: "50%",
                    borderRadius: 10,
                    borderBottom: `${borderStyle2}`,
                    height: !this.state.createEventState ? "54px" : height,
                  }}
                >
                  <Select
                    onMenuOpen={() => {
                      this.setState({
                        createEventState: true,
                      });
                    }}
                    onMenuClose={() => {
                      this.setState({
                        createEventState: false,
                      });
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#009BE2",
                        primary: "dodgerblue",
                      },
                    })}
                    styles={{
                      placeholder: (base) => {
                        return {
                          ...base,
                          color: "lightgrey",
                        };
                      },
                      menu: (base) => {
                        return {
                          ...base,
                          paddingLeft: 20,
                          paddingRight: 20,
                          border: "none",
                          marginTop: 0,
                          borderTop: "none",
                          boxShadow: "none",

                          color: "lightgrey",
                          paddingTop: 5,
                          "&:hover": {
                            borderRadius: 12,
                          },
                        };
                      },
                      dropdownIndicator: (base) => {
                        return {
                          ...base,
                          color: "dodgerblue",
                        };
                      },
                      control: (base, state) => {
                        return {
                          ...base,
                          backgroundColor: "white",
                          boxShadow: "none",
                          height: "54px",
                          borderRadius:"10px",
                          border: `${borderStyle3}`,
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          "&:hover": {
                            borderBottom: `${borderStyle3}`
                          },
                        };
                      },
                      indicatorSeparator: (styles) => ({
                        ...styles,
                        display: "none",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: "lightgrey",
                        backgroundColor: state.isSelected
                          ? "#192E49"
                          : "inherit",
                        "&:hover": {
                          backgroundColor: "#009BE2",
                          color: "white",
                        },
                      }),
                      menuList: (base) => {
                        return {
                          ...base,
                          marginTop: 15,
                          position: "relative",
                          top: "-5px",
                          zIndex: -1,
                          color: "grey",
                        };
                      },
                    }}
                    name="eventTypes"
                    placeholder={"Select type"}
                    isSearchable={false}
                    isClearable={false}
                    isRtl={false}
                    options={isDisplayAll ? eventTypes: [eventTypes[0], eventTypes[2]]}
                    className={module.selectInput}
                    data-testId= "onChange1"
                    onChange={(e: any) => {
                      if (e.value == "need") {
                        this.props.createNeedEvent();
                      } else if (e.value == "activity") {
                        this.props.createActivitiesEvent();
                      } else if (e.value == "project_funding") {
                        this.props.projectsEvent();
                      }
                    }}
                  />
                </div>
              </div>
            </Paper>
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}
export { CreateEvent };
export default withStyles(styles as any, { withTheme: true })(
  CreateEvent as any
);
