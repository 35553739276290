// App.js - WEB
import React, { Component,createContext } from 'react';
import HomeDashBoardRoot from '../../blocks/dashboard/src/HomeDashBoardRoot.web';
// import { View } from 'react-native';
// import { connect } from 'react-firebase';
import { createStore, applyMiddleware } from 'redux';
import { getFirebaseToken, onMessageListener, displayToast } from "./firebase.js";
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { Message } from 'framework/src/Message';

import { Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import stores from '../../framework/src/redux/stores';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import Portal from '@material-ui/core/Portal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import StripeIntegration from '../../blocks/StripeIntegration/src/StripeIntegration';
import Maps from '../../blocks/maps/src/Maps';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import Favourites from '../../blocks/favourites/src/Favourites';
import AddFavourites from '../../blocks/favourites/src/AddFavourites';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import PrivacySettings from '../../blocks/PrivacySettings/src/PrivacySettings';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Payments from '../../blocks/Payments/src/Payments';
import Gamification from '../../blocks/Gamification/src/Gamification';
import ActivityLog from '../../blocks/ActivityLog/src/ActivityLog';
import PeopleManagement2 from '../../blocks/PeopleManagement2/src/PeopleManagement2';
import Customform from '../../blocks/customform/src/Customform';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import GroupChat from '../../blocks/GroupChat/src/GroupChat';
import FreeTrial from '../../blocks/FreeTrial/src/FreeTrial';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
// import Notifications from '../../blocks/notifications/src/Notifications';
import ServiceSpecificSettingsAdmin from '../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin';
import BusHomeDashboard from '../../blocks/ServiceSpecificSettingsAdmin/src/BusHomeDashboard.web'
import Analytics from '../../blocks/analytics/src/Analytics';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import SubscriptionPlan from '../../blocks/customisableusersubscriptions/src/Subscriptions/SubscriptionPlan.web';
import PaymentOption from '../../blocks/customisableusersubscriptions/src/Subscriptions/PaymentOption.web';
import CurrentPlanInfo from '../../blocks/customisableusersubscriptions/src/Subscriptions/CurrentPlanInfo.web';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import Settings from '../../blocks/Settings5/src/Settings5.web';
import UserProfileBasicBlockMobile from '../../blocks/user-profile-basic/src/UserProfileBasicBlock.web';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlockParent.web';
import UserProfileView from '../../blocks/user-profile-basic/src/UserProfile.web';

import MyNeed from '../../blocks/user-profile-basic/src/ActiveNeeds.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import AddFriends from '../../blocks/AddFriends/src/AddFriends';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement.web';
import ContentManagementDashBoard from '../../blocks/ContentManagement/src/ContentManagementDashBoard.web';
import RegisteredVolunteers from '../../blocks/ContentManagement/src/RegisteredVolunteers.web';
import AdminRegistration from '../../blocks/ContentManagement/src/AdminRegistration.web';
import AccountCreation from '../../blocks/ContentManagement/src/AccountCreation.web';
import CfOfflineCachingMechanism25 from '../../blocks/CfOfflineCachingMechanism25/src/CfOfflineCachingMechanism25';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import LiveFeedScheduling from '../../blocks/LiveFeedScheduling/src/LiveFeedScheduling';
import Share from '../../blocks/share/src/Share';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import Followers from '../../blocks/Followers/src/Followers';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Location from '../../blocks/location/src/Location';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Surveys from '../../blocks/Surveys/src/Surveys';
import NpSurveys from '../../blocks/Surveys/src/NpSurveys.web';
import NpCreateSurveys from '../../blocks/Surveys/src/NpCreateSurveys.web'
import NpLandingPage from '../../blocks/EmailNotifications/src/NpLandingPage.web';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import LiveChatSummary2 from '../../blocks/LiveChatSummary2/src/LiveChatSummary2';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import CfServiceWorkerIntegration19 from '../../blocks/CfServiceWorkerIntegration19/src/CfServiceWorkerIntegration19';
import TargetedFeed from '../../blocks/TargetedFeed/src/TargetedFeed';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import ShareCalendar from '../../blocks/ShareCalendar/src/ShareCalendar';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import TaskList from '../../blocks/TaskList/src/TaskList';
import StoreLocator from '../../blocks/StoreLocator/src/StoreLocator';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import ResetPasswordSuccessMessageWeb from '../../blocks/forgot-password/src/ResetPasswordSuccessMessage.web';
import ResetPasswordWeb from '../../blocks/forgot-password/src/ResetPassword.web';
import EmailAccountRegistrationweb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
// import HomeDashBoard from "../../blocks/dashboard/src/HomeDashBoard.web";
import EmailVerificationweb from "../../blocks/email-account-registration/src/EmailVerification.web";
import MobileVerificationweb from "../../blocks/email-account-registration/src/MobileVerification.web";
import AccountVerifiedSuccessweb from "../../blocks/email-account-registration/src/AccountVerifiedSuccess.web";
import OtpVerifiedSuccess from "../../blocks/email-account-registration/src/OtpVerifiedSuccess.web";
import MyNonProfitsweb from "../../blocks/email-account-registration/src/MyNonProfits.web";
import AddWorkEmailweb from "../../blocks/email-account-registration/src/AddWorkEmail.web";
import MyTaskPreferenceweb from "../../blocks/email-account-registration/src/MyTaskPreference.web";
import Notificationsweb from "../../blocks/email-account-registration/src/Notifications.web";
import EmailNotificationsweb from "../../blocks/email-account-registration/src/EmailNotifications.web";
import EventsDetails from "../../blocks/events/src/UserEvents.web";
import FundCampaign from "../../blocks/events/src/fundCampaign.web"
import EventInfo from "../../blocks/events/src/EventInfo.web";
import NonProfitProfile from "../../blocks/events/src/NonProfitProfile.web";
import PersonalInformationweb from '../../blocks/email-account-registration/src/PersonalInformation.web';
import TermsAndCondnsComp from '../../blocks/email-account-registration/src/TermsAndCondnsComp.web'
import DBSdetailsweb from '../../blocks/email-account-registration/src/DBSdetails.web';
import HomeDashBoard from '../../blocks/dashboard/src/HomeDashBoard.web';
import { images } from '../../components/src/constants';
import Availability from '../../blocks/email-account-registration/src/Availability.web';
import MyPledgesMobile from '../../blocks/events/src/MyPledges.web';
import MyPledges from '../../blocks/events/src/UserPledges.web';
import UserEditProfile from '../../blocks/user-profile-basic/src/userEditProfile.web';
import BusinessProfileView from '../../blocks/user-profile-basic/src/BusinessProfileView.web';
import ErrorPage from '../../components/src/custom/ErrorPage';
import ContactUsWeb from '../../blocks/Settings5/src/ContactUs.web';
import FeedbackWeb from '../../blocks/Settings5/src/Feedback.web';
import TermsAndConditionsWeb from '../../blocks/Settings5/src/TermsAndCondns.web';
import PublishNeed from '../../blocks/user-profile-basic/src/PublishedNeed.web';
import NonProfitsAffiliations from '../../blocks/user-profile-basic/src/NonProfitsAffiliations.web';
import NonProfitsAffi from '../../blocks/user-profile-basic/src/NonProfitsAffiliationsmobile.web';
import NonProfitLandingPage from '../../blocks/ContentManagement/src/NonProfitLandingPage.web';
import NpSignIn from '../../blocks/ContentManagement/src/NpSignIn.web';
import NpForgotPassword from '../../blocks/ContentManagement/src/NpForgotPassword.web';
import NpForgotPasswordOTP from '../../blocks/ContentManagement/src/NpForgotPasswordOTP.web';
import NpResetPassword from '../../blocks/ContentManagement/src/NpResetPassword.web';
import NpAccountVerificationMessage from '../../blocks/ContentManagement/src/NpAccountVerificationMessage.web';
import NpResetPasswordSuccessMessage from '../../blocks/ContentManagement/src/NpResetPasswordSuccessMessage.web';
import ProjectsEvent from '../../blocks/ContentManagement/src/ProjectsEvent.web';

import ActivityEventUpdateWeb from '../../blocks/ContentManagement/src/ActivityEventUpdate.web';
import NeedsEventUpdate from '../../blocks/ContentManagement/src/NeedsEventUpdate.web';
import ContentEvent from '../../blocks/ContentManagement/src/ContentEvent.web';
import RecentEventsWeb from '../../blocks/ContentManagement/src/RecentEvents.web';
import EventPledgesWeb from '../../blocks/ContentManagement/src/EventPledges.web'
import NpRegSuccess from '../../blocks/ContentManagement/src/NpRegSuccess.web';
import ManageNeeds from '../../blocks/ContentManagement/src/ManageNeeds.web';
import ManageProjects from '../../blocks/ContentManagement/src/ManageProjects.web';
import FollowersNP from '../../blocks/ContentManagement/src/Followers.web';
import Communication from '../../blocks/ContentManagement/src/Communication.web';
import NpChat from '../../blocks/ContentManagement/src/NpChat.web';
import UsersNP from '../../blocks/ContentManagement/src/UsersNP.web';
import OrganisationUserNP from '../../blocks/ContentManagement/src/OrganisationUserNP.web';
import LandingPageUserFlow from '../../blocks/email-account-registration/src/LandingPageUserFlow.web';
import NpOrganisation from '../../blocks/ContentManagement/src/NpOrganisation.web'
import SearchScreenWeb from '../../blocks/dashboard/src/SearchScreenWeb';
import MobileSearchContainer from '../../blocks/advancedsearch/src/MobileSearchContainer';
import SavedSearchContainer from '../../blocks/advancedsearch/src/SavedSearchContainer';
import AdvSearchEvents from '../../blocks/advancedsearch/src/AdvSearchEvents.web';
import CongratsBusiness from '../../blocks/ServiceSpecificSettingsAdmin/src/CongratsBusiness.web';
import CongratsBusinessAfterEmail from '../../blocks/ServiceSpecificSettingsAdmin/src/CongratsBusinessAfterEmail.web';
import EmailOtpVerificationRegistration from '../../blocks/ServiceSpecificSettingsAdmin/src/EmailOtpVerificationRegistration.web';
import MobileOtpVerification from '../../blocks/ServiceSpecificSettingsAdmin/src/MobileOtpVerification.web';
import RegistrationFormPart1Business from '../../blocks/ServiceSpecificSettingsAdmin/src/RegistrationFormPart1Business.web';
import RegistrationFormPart2Business from '../../blocks/ServiceSpecificSettingsAdmin/src/RegistrationFormPart2Business.web';
import RegistrationFormBusiness from '../../blocks/ServiceSpecificSettingsAdmin/src/RegistrationFormBusiness.web';
import LoginBusiness from '../../blocks/ServiceSpecificSettingsAdmin/src/LoginBusiness.web';
import BusProfile from '../../blocks/ServiceSpecificSettingsAdmin/src/BusProfile.web'
import ForgotPasswordBusiness from '../../blocks/ServiceSpecificSettingsAdmin/src/ForgotPasswordBusiness.web';
import EmailOtpVerifyBusiness from '../../blocks/ServiceSpecificSettingsAdmin/src/EmailOtpVerifyBusiness.web';
import BusAccountVerificationMessage from '../../blocks/ServiceSpecificSettingsAdmin/src/BusAccountVerificationMessage.web';
import ResetPasswordBusiness from '../../blocks/ServiceSpecificSettingsAdmin/src/ResetPasswordBusiness.web';
import CongratesPasswordUpdated from '../../blocks/ServiceSpecificSettingsAdmin/src/CongratesPasswordUpdated.web';
import PrivacyPolicy from '../../blocks/Settings5/src/PrivacyPolicy.web';
import InvitePeople from '../../blocks/Settings5/src/InvitePeople.web'
import BusFollowing from '../../blocks/ServiceSpecificSettingsAdmin/src/BusFollowing.web'
import BusNonProfitProfile from '../../blocks/ServiceSpecificSettingsAdmin/src/BusNonProfitProfile.web'
import { LoginGuard, nonLoggedInGuard, userTypeGuard, SessionGuard, BusGuard, NpGuard } from '../../components/src/utils/route-guard';
import { PageWrapper } from '../../components/src/utils/page-wrapper';
 import { RouterContextProvider } from '../../components/src/utils/router-context';
 import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { InitiateThemeProvider } from '../../components/src/theme/theme.provider';
import {RegisterLayout} from '../../components/src/layout/register.layout'
import BusEmployees from '../../blocks/ServiceSpecificSettingsAdmin/src/BusEmployees.web'
import BusChat from '../../blocks/ServiceSpecificSettingsAdmin/src/BusChat.web';
import BusUserChat from '../../blocks/ServiceSpecificSettingsAdmin/src/BusUserChat.web';
import BusSubscriptionDetails from '../../blocks/ServiceSpecificSettingsAdmin/src/BusSubscriptionDetails.web'
import BusSubscriptionPlan from '../../blocks/ServiceSpecificSettingsAdmin/src/BusSubscriptionPlan.web'
import BusChangeSubscriptionPlan from '../../blocks/ServiceSpecificSettingsAdmin/src/BusChangeSubscription.web'
import UserFavourites from '../../blocks/user-profile-basic/src/UserFavourites.web'
import UserFollowing from '../../blocks/user-profile-basic/src/UserFollowing.web'
import UserRegistered from '../../blocks/user-profile-basic/src/UserRegistered.web';
import UserConnections from '../../blocks/user-profile-basic/src/UserConnections.web'
import UserMyconnections from '../../blocks/user-profile-basic/src/UserMyconnections.web'
import BusSubscriptionPayment from '../../blocks/ServiceSpecificSettingsAdmin/src/BusPaymentPage.web'
import BusSurveys from '../../blocks/Surveys/src/BusSurveys.web';
import BusSurveysDashboard from '../../blocks/Surveys/src/BusSurveysDashboard.web'
import SurveysDashboard from '../../blocks/Surveys/src/SurveysDashboard.web'
import UserSurveys from '../../blocks/Surveys/src/UserSurveys.web';
import SurveyUserAnswers from '../../blocks/Surveys/src/SurveyUserAnswers.web';

import './App.css';

const routeMap = {
  BusSurveysDashboard: {
    component: BusSurveysDashboard,
    path: '/BusSurveysDashboard'
  },
  SurveyUserAnswers: {
    component: SurveyUserAnswers,
    path: '/SurveyUserAnswers/:id'
  },
  SurveysDashboard: {
    component: SurveysDashboard,
    path: '/SurveysDashboard'
  },
  BusSurveys: {
    component: BusSurveys,
    path: '/BusSurveys'
  },
  UserSurveys: {
    component: UserSurveys,
    path: '/UserSurveys'
  },
  BusSubscriptionPayment: {
    component: BusSubscriptionPayment,
    path: '/BusSubscriptionPayment'
  },
  BusSubscriptionPayments: {
    component: BusSubscriptionDetails,
    path: '/BusSubscriptionPayments'
  },
  BusChangeSubscriptionPlan: {
    component: BusChangeSubscriptionPlan,
    path: '/BusChangeSubscriptionPlan'
  },
  BusSubscriptionPlan: {
    component: BusSubscriptionPlan,
    path: '/BusSubscriptionPlan'
  },
  BusUserChat: {
    component: BusUserChat,
    path: '/BusUserChat'
  },
  BusChat: {
    component: BusChat,
    path: '/BusChat'
  },
  RegistrationFormBusiness: {
    component: RegistrationFormBusiness,
    path: '/RegistrationFormBusiness'
  },
  RegistrationFormPart2Business: {
    component: RegistrationFormPart2Business,
    path: '/RegistrationFormPart2Business'
  },
  RegistrationFormPart1Business: {
    component: RegistrationFormPart1Business,
    path: '/RegistrationFormPart1Business'
  },
  MobileOtpVerification: {
    component: MobileOtpVerification,
    path: '/MobileOtpVerification'
  },
  EmailOtpVerificationRegistration: {
    component: EmailOtpVerificationRegistration,
    path: '/EmailOtpVerificationRegistration'
  },
  CongratsBusinessAfterEmail: {
    component: CongratsBusinessAfterEmail,
    path: '/CongratsBusinessAfterEmail'
  },
  CongratsBusiness: {
    component: CongratsBusiness,
    path: '/CongratsBusiness'
  },
  CongratesPasswordUpdated: {
    component: CongratesPasswordUpdated,
    path: '/CongratesPasswordUpdated'
  },
  BusAccountVerificationMessage: {
    component: BusAccountVerificationMessage,
    path: '/BusAccountVerificationMessage'
  },
  ResetPasswordBusiness: {
    component: ResetPasswordBusiness,
    path: '/ResetPasswordBusiness'
  },
  EmailOtpVerifyBusiness: {
    component: EmailOtpVerifyBusiness,
    path: '/EmailOtpVerifyBusiness'
  },

  LoginBusiness: {
    component: LoginBusiness,
    path: '/LoginBusiness'
  },
  BusProfile: {
    component: PageWrapper({
      page: BusProfile,
      guards: [BusGuard('LoginBusiness')],
    }),
    path: '/BusProfile'
  },
  BusFollowing: {
    component: PageWrapper({
      page: BusFollowing,
      guards: [BusGuard('LoginBusiness')],
    }),
    path: '/BusFollowing'
  },
  ForgotPasswordBusiness: {
    component: ForgotPasswordBusiness,
    path: '/ForgotPasswordBusiness'
  },
  BusEmployees: {
    component: PageWrapper({
      page: BusEmployees,
      guards: [BusGuard('LoginBusiness')],
    }),
    path: '/BusEmployees'
  },
  BusNonProfitProfile: {
    component: BusNonProfitProfile,
    path: '/BusNonProfitProfile'
  },
  MobileSearchContainer: {
    component: MobileSearchContainer,
    path: '/MobileSearchEvent'
  },
  SearchScreenWeb: {
    component: SearchScreenWeb,
    path: '/SearchScreenWeb'
  },
  SavedSearchContainer: {
    component: SavedSearchContainer,
    path: '/SavedSearchContainer'
  },
  AdvSearchEvents: {
    component: AdvSearchEvents,
    path: '/AdvSearchEvents'
  },
  LandingPageUserFlow: {
    component: LandingPageUserFlow,
    path: '/',
    exact: true
  },
  OrganisationUserNP: {
    component: OrganisationUserNP,
    path: '/OrganisationUserNP'
  },
  Organisation: {
    component: PageWrapper({
      page: NpOrganisation,
      guards: [NpGuard('NpSignIn')]
    }),
    path: '/Organisation'
  },
  UsersNP: {
    component: PageWrapper({
      page: UsersNP,
      guards: [NpGuard('NpSignIn')]
    }),
    path: '/UsersNP'
  },
  FollowersNP: {
    component: PageWrapper({
      page: FollowersNP,
      guards: [NpGuard('NpSignIn')]
    }),
    path: '/FollowersNP'
  },
  NpChat: {
    component: NpChat,
    path: '/NpChat'
  },
  Communication: {
    component: PageWrapper({
      page: Communication,
      guards: [NpGuard('NpSignIn')]
    }),
    path: '/Communication'
  },
  NpRegSuccess: {
    component: NpRegSuccess,
    path: '/NpRegSuccess'
  },
  NpLandingPage: {
    component: NpLandingPage,
    path: '/NpLandingPage'
  },
  NpResetPasswordSuccessMessage: {
    component: NpResetPasswordSuccessMessage,
    path: '/NpResetPasswordSuccessMessage'
  },
  NpAccountVerificationMessage: {
    component: NpAccountVerificationMessage,
    path: '/NpAccountVerificationMessage'
  },
  ContentEvent: {
    component: PageWrapper({
      page: ContentEvent,
      guards: [NpGuard('NpSignIn')]
    }),
    path: '/ContentEvent'
  },
  RecentEventsWeb: {
    component: RecentEventsWeb,
    path: '/RecentEventsWeb'
  },
  EventPledgesWeb: {
    component: EventPledgesWeb,
    path: '/EventPledgesWeb'
  },

  ManageNeeds: {
    component: ManageNeeds,
    path: '/ManageNeeds'
  },
  ManageProjects: {
    component: ManageProjects,
    path: '/ManageProjects'
  },
  NeedsEventUpdate: {
    component: NeedsEventUpdate,
    path: '/NeedsEventUpdate'
  },
  NpResetPassword: {
    component: NpResetPassword,
    path: '/NpResetPassword'
  },
  NpForgotPasswordOTP: {
    component: NpForgotPasswordOTP,
    path: '/NpForgotPasswordOTP'
  },
  ProjectsEvent: {
    component: ProjectsEvent,
    path: '/ProjectsEvent'
  },
  ActivityEventUpdateWeb: {
    component: ActivityEventUpdateWeb,
    path: '/ActivityEventUpdateWeb'
  },
  NpForgotPassword: {
    component: NpForgotPassword,
    path: '/NpForgotPassword'
  },
  NpSignIn: {
    component: PageWrapper({
      page: NpSignIn,
    }),
    path: '/NpSignIn'
  },
  NonProfitLandingPage: {
    component: NonProfitLandingPage,
    path: '/NonProfitLandingPage'
  },
  RegisteredVolunteers: {
    component: PageWrapper({
      page: RegisteredVolunteers,
      guards:[NpGuard('NpSignIn')]
    }),
        path: '/RegisteredVolunteers'
  },
  NonProfitsAffiliations: {
    component: NonProfitsAffiliations,
    path: '/NonProfitsAffiliations'
  },

  NonProfitsAffi: {
    component: NonProfitsAffi,
    path: '/NonProfitsAffi'
  },

  ContactUsWeb: {
    component: ContactUsWeb,
    path: '/ContactUsWeb'
  },
  TermsAndConditionsWeb: {
    component: PageWrapper({
      page: TermsAndConditionsWeb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/TermsAndConditionsWeb'
  },
  FeedbackWeb: {
    component: FeedbackWeb,
    path: '/FeedbackWeb'
  },
  ErrorPage: {
    component: ErrorPage,
    path: '/ErrorPage'
  },
  MyPledges: {
    component: PageWrapper({
      page: MyPledges,
      guards: [SessionGuard('EmailAccountLoginBlock')],
    }),
    path: '/MyPledges'
  },
  UserEditProfile: {
    component: UserEditProfile,
    path: '/userEditProfile'
  },
  BusinessProfileView: {
    component: BusinessProfileView,
    path: '/BusinessProfileView'
  },
  Availability: {
    component: PageWrapper({
      page: Availability,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/Availability/:id?'
  },
  EventsDetails: {
    component: EventsDetails,
    path: '/need/:id'
  },
  FundCampaign: {
    component: FundCampaign,
    path: '/fund/:id'
  },
  EventInfo: {
    component: EventInfo,
    path: '/activity/:id'
  },
  NonProfitProfile: {
    component: NonProfitProfile,
    path: '/nonProfitProfile/:id'
  },
  UserProfileView: {
    component: UserProfileView,
    path: '/userProfileView/:id'
  },
  EmailNotificationsweb: {
    component: PageWrapper({
      page: EmailNotificationsweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/EmailNotificationsweb'
  },
  Notificationsweb: {
    component: PageWrapper({
      page: Notificationsweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/Notificationsweb/:id?'
  },
  MyTaskPreferenceweb: {
    component: PageWrapper({
      page: MyTaskPreferenceweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/MyTaskPreferenceweb/:id?'
  },
  AddWorkEmailweb: {
    component: PageWrapper({
      page: AddWorkEmailweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/AddWorkEmailWeb'
  },
  MyNonProfitsweb: {
    component: PageWrapper({
      page: MyNonProfitsweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/MyNonProfitsWeb'
  },
  ResetPasswordWeb: {
    component: PageWrapper({
      page: ResetPasswordWeb,
      providers:[RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/ResetPasswordWeb'
  },
  ResetPasswordSuccessMessageWeb: {
    component: PageWrapper({
      page: ResetPasswordSuccessMessageWeb,
      providers:[RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/ResetPasswordSuccessMessageWeb'
  },
  HomeDashBoard: {
    component: PageWrapper({
      page: HomeDashBoardRoot,
      guards: [LoginGuard('EmailAccountLoginBlock'),SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/HomeDashBoard'
  },
  EmailAccountRegistrationweb: {
    component: PageWrapper({
      page: EmailAccountRegistrationweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/EmailAccountRegistrationweb'
  },
  EmailVerificationweb: {
    component: PageWrapper({
      page: EmailVerificationweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/EmailVerificationweb'
  },
  MobileVerificationweb: {
    component: PageWrapper({
      page: MobileVerificationweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/MobileVerificationweb'
  },
  AccountVerifiedSuccessweb: {
    component: PageWrapper({
      page: AccountVerifiedSuccessweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/AccountVerifiedSuccessweb'
  },
  OtpVerifiedSuccess: {
    component: PageWrapper({
      page: OtpVerifiedSuccess,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/OtpVerifiedSuccess'
  },
  PersonalInformationweb: {
    component: PageWrapper({
      page: PersonalInformationweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/PersonalInformationweb'
  },
  TermsAndCondnsComp: {
    component: PageWrapper({
      page: TermsAndCondnsComp,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/TermsAndCondnsComp'
  },

  DBSdetailsweb: {
    component: PageWrapper({
      page: DBSdetailsweb,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
    }),
    path: '/DBSdetailsweb'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  // Maps:{
  //  component:Maps,
  // path:"/Maps"},
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  AddFavourites: {
    component: AddFavourites,
    path: '/AddFavourites'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: '/PrivacySettings'
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Gamification: {
    component: Gamification,
    path: '/Gamification/:id?'
  },
  ActivityLog: {
    component: ActivityLog,
    path: '/ActivityLog'
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/PeopleManagement2'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  GroupChat: {
    component: PageWrapper({
      page: GroupChat,
      guards: [SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/GroupChat'
  },
  FreeTrial: {
    component: FreeTrial,
    path: '/FreeTrial'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: PageWrapper({
      page: ForgotPassword,
      layouts: [RegisterLayout, InitiateThemeProvider],
      providers: [RouterContextProvider],
    }),
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: PageWrapper({
      page: ForgotPasswordOTP,
      layouts: [RegisterLayout, InitiateThemeProvider],
      providers: [RouterContextProvider],
    }),
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  // Notificationsweb:{
  //  component:Notifications,
  // path:"/Notificationsweb"},
  ServiceSpecificSettingsAdmin: {
    component: ServiceSpecificSettingsAdmin,
    path: '/ServiceSpecificSettingsAdmin'
  },
  BusHomeDashboard: {
      component: PageWrapper({
        page: BusHomeDashboard,
        guards: [userTypeGuard(['business entity'],"LoginBusiness"), BusGuard('LoginBusiness')],
      }),
      path: '/BusHomeDashboard'
  },
  Analytics: {
    component: PageWrapper({
      page: Analytics,
      guards: [userTypeGuard(['business entity'], 'NPSignIn')],
    }),
    path: '/Analytics'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  Settings: {
    component: PageWrapper({
      page: Settings,
      guards: [LoginGuard('EmailAccountLoginBlock')]
    }),
    path: '/Settings'
  },
  UserProfileBasicBlock: {
    component: PageWrapper({
      page: UserProfileBasicBlock,
      guards: [LoginGuard('EmailAccountLoginBlock'),SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/UserProfileBasicBlock'
  },
  UserFollowing: {
    component: PageWrapper({
      page: UserFollowing,
      guards: [LoginGuard('EmailAccountLoginBlock'),SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/userfollowing'
  },
  UserRegistered: {
    component: PageWrapper({
      page: UserRegistered,
      guards: [LoginGuard('EmailAccountLoginBlock'),SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/userregistered'
  },
  UserFavourites: {
    component: PageWrapper({
      page: UserFavourites,
      guards: [LoginGuard('EmailAccountLoginBlock'),SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/userfavourites'
  },


  UserConnections: {
    component: PageWrapper({
      page: UserConnections,
      guards: [LoginGuard('EmailAccountLoginBlock'),SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/userconnections'
  },
  UserMyconnections: {
    component: PageWrapper({
      page: UserMyconnections,
      guards: [LoginGuard('EmailAccountLoginBlock'),SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/usermyconnections'
  },
  MyNeed: {
    component: PageWrapper({
      page: MyNeed,
      guards: [SessionGuard('EmailAccountLoginBlock')]
    }),
    path: '/MyNeeds'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  AddFriends: {
    component: AddFriends,
    path: '/AddFriends'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  ContentManagementDashBoard: {

    component: PageWrapper({
      page: ContentManagementDashBoard,
      guards: [NpGuard('NpSignIn')]
    }),
    path: '/ContentManagementDashBoard'
  },
  SubscriptionPlan: {
    component: SubscriptionPlan,
    path: '/SubscriptionPlan'
  },
  PaymentOption: {
    component: PaymentOption,
    path: '/PaymentOption'
  },
  currentPlanInfo:{
    component: CurrentPlanInfo,
    path: '/CurrentPlanInfo'
  },
  AccountCreation: {
    component: AccountCreation,
    path: '/AccountCreation'
  },
  AdminRegistration: {
    component: AdminRegistration,
    path: '/AdminRegistration'
  },
  CfOfflineCachingMechanism25: {
    component: CfOfflineCachingMechanism25,
    path: '/CfOfflineCachingMechanism25'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  LiveFeedScheduling: {
    component: LiveFeedScheduling,
    path: '/LiveFeedScheduling'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  Followers: {
    component: Followers,
    path: '/Followers'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  InvitePeople: {
    component: InvitePeople,
    path: '/InvitePeople'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  // Contactus: {
  //   component: Contactus,
  //   path: '/Contactus'
  // },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Surveys: {
    component: Surveys,
    path: '/Surveys'
  },
  NpSurveys: {
    component: NpSurveys,
    path: '/NpSurveys'
  },
  NpCreateSurveys: {
    component: NpCreateSurveys,
    path: '/NpCreateSurveys/:id?'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  LiveChatSummary2: {
    component: LiveChatSummary2,
    path: '/LiveChatSummary2'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboards'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  CfServiceWorkerIntegration19: {
    component: CfServiceWorkerIntegration19,
    path: '/CfServiceWorkerIntegration19'
  },
  TargetedFeed: {
    component: TargetedFeed,
    path: '/TargetedFeed'
  },
  EmailAccountLoginBlock: {
    component: PageWrapper({
      page: EmailAccountLoginBlock,
      providers: [RouterContextProvider, InitiateThemeProvider],
      layouts: [RegisterLayout],
      guards: [nonLoggedInGuard('HomeDashBoard')],
    }),
    path: '/EmailAccountLoginBlock'
  },
  ShareCalendar: {
    component: ShareCalendar,
    path: '/ShareCalendar'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  StoreLocator: {
    component: StoreLocator,
    path: '/StoreLocator'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },

  Home: {
    component: HomeScreen,
    path: '/HomeScreen',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  PublishNeed: { component: PublishNeed, path: '/publishneed/:id?' },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

 const initiateQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000 * 60,   // 10 minutes 
    }
  }
});
export const AppContext = createContext();

class App extends Component {
  addFcmTokenApiCallId = "";
  deleteFcmTokenApiCallId = "";
  fcmDevicesApiCallId = "";

  constructor(props) {
    super(props)
    this.state = {
      isCome: true,
      newNoti:false,
    }
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    let permission = Notification.permission;
    let fcmToken = localStorage.getItem('fcm_token') || "";
    if (
      'Notification' in window &&
      permission === 'granted' &&
      fcmToken.length === 0
    ) {
      this.addToken();
    }
    if (permission === 'denied') {
      this.removeDeviceToken();
    }
  }

  async componentDidUpdate(prevProps) {
    const isLogin = localStorage.getItem("isLogin") === "false";
    if (isLogin && (window.location.pathname.toLowerCase() === '/homedashboard' ||
      window.location.pathname.toLowerCase() === '/contentmanagementdashboard' ||
      window.location.pathname.toLowerCase() === '/bushomedashboard')
    ) {
      await this.checkNotificationPermission();
    }
  }

  async receive(from, message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.addFcmTokenApiCallId) {
        if(responseJson.message === "device id save successfully"){
          this.fcmDevices();
        }
      } else if (apiRequestCallId === this.fcmDevicesApiCallId) {
        const token = localStorage.getItem("fcm_token");
        const foundDevice = responseJson.data.find((device) => device.device_token === token);
        if (foundDevice) {
          localStorage.setItem("device_id", foundDevice.id);
        }
      } else if(apiRequestCallId === this.deleteFcmTokenApiCallId){
        if(responseJson.message === "Deleted Successfully."){
          localStorage.removeItem('fcm_token');
          localStorage.removeItem('device_id');
        }
      }
    }
  }

  getUserType = () => {
    const userType = localStorage.getItem("user_type");
    if(userType === "Non profit entity"){
      return "non_profit_entity";
    } else if(userType === "business_user"){
      return "business_entity";
    } else {
      return "user_entity";
    }
  }

  closeIndicator = () => {
    this.setState({ newNoti: false });
  }

  fcmDevices = () => {
    const token = localStorage.getItem("token") ?? localStorage.getItem("nonprofitToken");
    if (token) {
      const header = {
        'Content-Type': 'application/json',
        "token": token,
        "type": this.getUserType()
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.fcmDevicesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/device_ids`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  removeDeviceToken = () => {
    const deviceId = localStorage.getItem("device_id");
    const token = localStorage.getItem("token") ?? localStorage.getItem("nonprofitToken");
    if (deviceId && token) {
      const header = {
        "Content-Type": "application/json",
        "token": token,
        "type": this.getUserType()
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteFcmTokenApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/device_ids/${deviceId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage)
    }
  }

  addDeviceToken = (token, fcmToken) => {
    const header = {
      "Content-Type": 'application/json',
      "token": token,
      "type": this.getUserType()
    };
    const httpBody = {
      "device_token": fcmToken
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFcmTokenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/device_ids`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkNotificationPermission = async () => {
    const permission = Notification.permission;
    const fcmToken = localStorage.getItem('fcm_token') || '';
    if (permission === 'default' && fcmToken.length === 0) {
      this.requestPermission();
    }
    if (permission === 'granted' && fcmToken.length === 0) {
      this.addToken();
    }
    localStorage.setItem("isLogin", "true");
  };

  requestPermission = async () => {
    localStorage.setItem("isLogin", "true");
    const permission = await Notification.requestPermission();
    if (permission === 'denied') {
      this.removeDeviceToken();
    } else if (permission === 'granted') {
      this.addToken();
    }
  }

  addToken = async () => {
    const token = localStorage.getItem("token") ?? localStorage.getItem("nonprofitToken");
    if (token) {
      const fcmToken = await getFirebaseToken();
      localStorage.setItem('fcm_token', fcmToken);
      if(fcmToken){
        this.addDeviceToken(token, fcmToken);
      }
    }
  }
 
  render() {
    onMessageListener()
        .then((data) => {
          this.setState({ isCome: !this.state.isCome, newNoti: true });
          toast(displayToast(data?.notification?.title, data?.notification?.body));
        })
    .catch((err) => console.log("failed: ", err));
    return (
      <QueryClientProvider client={initiateQueryClient}>
        <Provider store={stores}>
          <Switch>
            <div className="backgroundimg">
            <Portal>
              <ToastContainer
                autoClose={3000}
                position="top-right"
                className="toast-container"
                toastClassName="dark-toast"
              />
            </Portal>
            <AppContext.Provider value={{closeIndicator:this.closeIndicator,newNoti:this.state.newNoti, isCome: this.state.isCome}}>
            {WebRoutesGenerator({ routeMap })}
            </AppContext.Provider>
            </div>
          </Switch>
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default App;
