// Customizable Area Start
import React from "react";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import UserProfileController from "./UserProfileController";
// @ts-ignore
import styles from "./UserProfile.module.css";
// @ts-ignore
import webStyle from "../../events/src/NonProfitProfile.module.css";
import { icons, constants } from "../../../components/src/constants";
import {
  Button, Typography, Tabs, Tab, Container, IconButton, Toolbar, Box, Card, CircularProgress
} from '@material-ui/core';
import { styled } from "@material-ui/styles";
import moment from "moment";
//@ts-ignore
import classes from "../../events/src/userEvent.module.css";
import { isMobile } from "react-device-detect";
import { HideContainer } from "../../../components/src/shared/hide-container";
// Customizable Area End

export default class UserProfileView extends UserProfileController {

  // Customizable Area Start
  calenderIcon() {
    return (
      <div className={classes.avatarImgcontainer}>
        <img
          src={icons.calender}
          alt=""
          className={classes.avatarImg}
          style={{ marginTop: 3 }}
        />
      </div>
    )
  }

  locationIcon() {
    return (
      <div className={classes.avatarImgcontainer}>
        <img
          src={icons.locationred}
          alt=""
          style={{ marginTop: 3 }}
          className={classes.avatarImg}
        />
      </div>
    )
  }

  getClassForPoints = () => {
    const medal = this.state.userDetails?.attributes?.medal;
    if (medal === "silver") {
      return styles.silverClass;
    } else if (medal === "gold") {
      return styles.goldClass;
    } else {
      return styles.bronzeClass;
    }
  }

  event_type_name = (type: any) => {
    if (type === "need") {
        return "Volunteer";
    } else if (type === "project_funding") {
        return "Donate";
    } else if (type === "activity") {
        return "Attend";
    } else {
        return "Volunteer";
    }
  }

  event_type_bg_color = (type: any) => {
    if (type === "need") {
        return "#03BD95";
    } else if (type === "project_funding") {
        return "#049BE1";
    } else if (type === "activity") {
        return "#B4038C";
    } else {
        return "#009BE2";
    }
  }

  pledgeEvent(obj: any) {
    let title, eventImage, dateNtime, userName, userImage;
    const location = obj.attributes.location;
    if (obj.type === "need") {
      title = obj.attributes.title_of_need;
      userName = obj.attributes.user_name;
      eventImage = obj.attributes.need_photo.url || constants.carosel_constants[0].image;
      userImage = obj.attributes.user_image?.url || constants.carosel_constants[0].image;
      dateNtime = `${moment(obj.attributes.start_date).format('DD MMM YYYY')} , ${moment(obj.attributes.start_time).format('HH:mm a')}`
    } else if (obj.type === "non_profit_event") {
      title = obj.attributes.event_name;
      userName = obj.attributes.organisation_name;
      eventImage = obj.attributes.event_image?.url || constants.carosel_constants[0].image;
      userImage = obj.attributes.organisation_image_url?.url || constants.carosel_constants[0].image;
      dateNtime = `${moment(obj.attributes.from_date).format('DD MMM YYYY')} , ${moment(obj.attributes.start_time).format('HH:mm a')}`
    }

    return (
      <Card className={webStyle.cardInner}>
        <div className={webStyle.leftCard}>
          <img
            className={webStyle.cardImage}
            src={eventImage}
            alt="event image"
          />
          {obj?.dbs_registration && <span className={webStyle.btnDbsCarousel}>DBS</span>}
        </div>
        <div className={webStyle.rightCard}>
          <div className={webStyle.rightCardEvents}>
            <Typography className={`${webStyle.eventTitle} eventTitle`}>
              {title}
            </Typography>
            <span className={`${webStyle.timeline} timeline`} style={{ background: this.event_type_bg_color(obj?.attributes.event_type) }}>{this.event_type_name(obj?.attributes.event_type)}</span>
          </div>
          <div className={webStyle.innerInfo}>
            <div
              className={webStyle.userInfo}
            >
              <div className={webStyle.avatarImgcontainer}>
                <img src={userImage} alt="" className={webStyle.avatarImg} />
              </div>
              <Typography
                variant="body1"
                className={webStyle.userName}
              > 
                {userName}
              </Typography>
            </div>
            <div className={webStyle.imageTextWrapper}>
              <div className={webStyle.avatarImgcontainer}>
                <img
                  src={icons.calender}
                  alt="avatar"
                  className={webStyle.avatarImg}
                />
              </div>
              <span className={`${webStyle.eventdate_loc} eventdate_loc`}>
                {dateNtime}
              </span>
            </div>
            <div className={webStyle.imageTextWrapper}>
              <div className={webStyle.avatarImgcontainer}>
                <img
                  src={icons.locationred}
                  alt="location"
                  className={webStyle.avatarImg}
                />
              </div>
              <span className={`${webStyle.eventdate_loc} eventdate_loc`}>
                {location}
              </span>
            </div>
          </div>
        </div>
      </Card>
    )
  }

  pledgesFrontTabData() {
    return (
      <Container>
        {this.state.getPledges?.map((item: any) => (
          item.attributes.need_id
            ? this.pledgeEvent(item.attributes.need.data)
            : this.pledgeEvent(item.attributes.non_profit_event.data)
        ))}
        {this.state.getPledges?.length === 0 && (
          <p>
            <div style={{ textAlign: 'center', fontSize: 20 }}>
              <p>No pledges available</p>
            </div>
          </p>
        )}
      </Container>
    );
  }

  getInfo = () => {
    const { userDetails } = this.state;
    return (
      <>
        <div style={{ paddingTop: '2%' }}>
          <p className={`${styles.eventsData} eventsData`}>{userDetails?.attributes?.connection_count > 9 ? `${userDetails?.attributes?.connection_count}` : `0${userDetails?.attributes?.connection_count}`}</p>
          <p className="eventsText">Connection</p>
        </div>
        <div style={{ paddingTop: '2%' }}>
          <p className={`${styles.eventsData} eventsData`}>{userDetails?.attributes?.following > 9 ? `${userDetails?.attributes?.following}` : `0${userDetails?.attributes?.following}`}</p>
          <p className="eventsText">Following</p>
        </div>
        <div style={{ paddingTop: '2%' }}>
          <p className={`${styles.followersData} followersData`}>{userDetails?.attributes?.registered > 9 ? userDetails?.attributes?.registered : `0${userDetails?.attributes?.registered}`}</p>
          <p className="eventsText">Registered</p>
        </div>
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { userDetails } = this.state;
    return (
      <div >
                {/* @ts-ignore */}
        <DashboardWeb
          showBackButton={constants._icons.show}
          showBottomBar={true}
          showDrawerHeader={isMobile}
          navigation={this.props.navigation}
          title={"User Profile"}
        >
          <Wrapper className={classes.eventContainer}>
            <HideContainer hideWhen={isMobile}>
              <Toolbar className={classes.toolBar} style={{ position: 'sticky', top: '0' }}>
                <div className={`${classes.backIconWeb}`}>
                  <IconButton
                    aria-label="Open drawer"
                    color={"inherit"}
                    data-test-id='profile'
                    onClick={() => this.props.navigation.navigate("UserProfileBasicBlock")}
                  >
                    <img src={icons.back} />
                  </IconButton>
                  <Typography
                    variant="h6"
                    className={classes.activityDetails}
                    noWrap
                  >
                    User Profile
                  </Typography>
                </div>
              </Toolbar>
            </HideContainer>
            <div className={webStyle.mainDiv} style={{ height: '100%', overflow: 'auto' }}>
              {this.state.loading ?
              <div>
                <div className="loading">
                  <CircularProgress
                    size={40}
                    color="primary"
                  />
                </div>
              </div>
              :<div className={`${webStyle.orgProfile} orgProfile`}>
                <div className={`${webStyle.orgLogo} orgLogo`}>
                  <img src={userDetails?.attributes?.profile_picture ? userDetails?.attributes?.profile_picture?.url : icons.profile} alt="pr" className={styles.orgImg} data-test-id="profileImgg" />
                </div>
                <div className={webStyle.mainPrgSection}>
                  <div className={`${webStyle.orgInfo} orgInfo`}>
                    <div className={webStyle.orgInfo1}>
                      <p className={`${webStyle.orgProfileTetx} orgProfileTetx`}>{userDetails?.attributes?.full_name}</p>
                      <div className={webStyle.locationData} style={{ display: 'flex', alignItems: 'center' }}>
                        <p className={`${webStyle.location} location`} style={{ color: isMobile ? "gray" : "rgb(38 37 37)" }}>{userDetails?.attributes?.city ?? '-'}, {userDetails?.attributes?.country ?? '-'}</p>
                        <img className={webStyle.locationImg} src={icons.locationred} alt='location' />
                      </div>
                    </div>
                    {userDetails?.attributes?.business_logo && 
                      <div style={{border:'1px solid black', width:'80px', height:'80px'}}>
                        <img src={userDetails?.attributes?.business_logo?.url} alt="pr" height="100%" width="100%" />
                      </div>
                    }
                  </div>
                  <hr className={styles.statsLine1} />
                  <div className="userMeta" style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '-2px' }}>
                    <div style={{ paddingTop: '5%' }}>
                      <p className={`${styles.bgContainer} ${this.getClassForPoints()} favorite`}>
                        <label className="centeredText">{userDetails?.attributes?.points}</label>
                      </p>
                    </div>
                    {this.getInfo()}
                  </div>
                  <hr className={styles.statsLine}/>
                  <div className={`${styles.aboutDiv} aboutDiv`}>
                    <p className={styles.aboutText}>Bio</p>
                    <p className={styles.description}>{userDetails?.attributes?.bio}</p>
                    <div className={styles.mainBtnDiv} style={{ gap: '20px' }}>
                      {this.state.status === "Not connected" && <Button variant="contained" className="btn" onClick={this.onConnectClicked} data-testId="connect-btn">Connect</Button>}
                      {this.state.status === "pending" && <Button variant="contained" disabled className="pending-btn btn">Pending</Button>}
                      {this.state.status === "approved" && <Button variant="contained" disabled className="connected-btn btn">Connected</Button>}
                      {this.state.status === "approved"
                        ? <Button data-testId="message-btn" variant="outlined" className={`${styles.messageBtn} message`} onClick={this.onMessageBtnClick}>Message</Button>
                        : <Box width="100%" marginRight="15px" />
                      }
                    </div>
                  </div>
                  <hr className={styles.dividerOneLine} />
                  <div className={styles.mainTabDiv}>
                    <Tabs
                      value={0}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      <Tab label="Pledges" className={styles.tabHeadDivider} style={{ fontFamily: "Rene_Bieder_Milliard_Medium", color: '#000', textTransform: "unset", fontSize: '20px', }} />
                    </Tabs>
                  </div>
                  <hr className={styles.dividerTwoLine} />
                </div>
              </div>}
              <div className="userPledgeData" style={{ padding: '25' }}>
                <div>{this.pledgesFrontTabData()}</div>
              </div>
            </div>
          </Wrapper>
        </DashboardWeb>
      </div>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  "& .loading": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
    "& .MuiCircularProgress-root": {
      color: '#009BE2'
    }
  },
  "& .orgLogo": {
    "@media(max-width:600px)": {
      height: "60px",
      width: "60px",
      marginLeft: "15px"
    },
    "& img": {
      "@media(max-width:600px)": {
        height: "50px",
        width: "50px"
      }
    }
  },
  "& .orgInfo" : {
    "@media(max-width:600px)": {
      marginTop: "-15%"
    }
  },
  "& .eventsData, .followersData" : {
    "@media(max-width:600px)": {
      fontFamily: "inherit",
      fontSize: "24px"
    }
  },
  "& .orgProfile": {
    "@media(max-width:600px)": {
      width: "100%"
    }
  },
  "& .userMeta" : {
    "@media(max-width:600px)": {
     justifyContent: "space-between !important",
     padding: "20px"
    }
  },
  "& .aboutDiv": {
    "@media(max-width:600px)": {
      padding: "0 20px"
    }
  },
  "& .userPledgeData": {
    "@media(max-width:600px)": {
      padding: "25px 0 70px !important"
    }
  },
  "& .timeline" : {
    "@media(max-width:600px)": {
      fontSize: "12px",
      marginBottom: "8px",
    }
  },
  "& .eventTitle, .eventdate_loc" : {
    "@media(max-width:600px)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis", 
    }
  },
  "& .eventsText": {
    height: "20px",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: "16px",
    fontWeight: "400",
    letterSpacing: "-0.5px",
    "@media(max-width:600px)": {
      margin: "5px 0",
      fontSize: "14px"
    }
  },
  "& .favorite": {
    cursor: "default"
  },
  "& .centeredText": {
    marginTop: "-10px"
  },
  "& .location": {
    width: "unset",
    color: "black"
  },
  "& .message": {
    fontFamily: "Rene_Bieder_Milliard_Semibold",
  },
  "& .btn": {
    borderRadius: "10px",
    border: "none",
    backgroundColor: "#009be2",
    color: "#ffffff",
    width: "100%",
    marginRight: "0",
    textTransform: "none",
    minHeight: "50px",
    fontSize: "16px",
    boxShadow: "none",
    fontFamily: "Rene_Bieder_Milliard_Semibold",
    "&.pending-btn": {
      color: "#269F3C",
      backgroundColor: "#CDF5EA",
    },
    "&.connected-btn": {
      color: "black",
      backgroundColor: "#CFCFCF",
    }
  },
  "& .orgProfileTetx" : {
    "@media(max-width:600px)": {
      marginTop: "10px" 
    }
  },
});
// Customizable Area End