import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
}

export interface S {
  // Customizable Area Start
  id: string;
  eventImage: string;
  organizationLogo: string;
  sid:string;
  isFavorite: boolean;
  title: string;
  startDate: any;
  startTime: any;
  organizationName: string;
  toDate: any;
  toTime: any;
  favoriteId: null | number;
  participantList: any[];
  description: string;
  itinerarv_and_notes:string;
  additional_information:string,
  userLat: number;
  userLang: number;
  eventLat: number;
  eventLang: number;
  eventStatus: string | null;
  pledgeId: null | number;
  location: string;
  post_code: string;
  isOpen: boolean;
  isCancelButton: boolean;
  isEventPast: boolean;
  joinChatStatus: boolean;
  type: string;
  organisation_id: any;
  nonProfitProfileUrl: string;
  isImageLoaded: boolean;
  accountId: string | number | null;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EventInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  removeFavoriteNeedApiCallId: string = "";
  addFavoriteNeedApiCallId: string = "";
  addNeedPledgeApiCallId: string = "";
  getNeedDetailApiCallId: string = "";
  cancelNeedPledgeApiCallId: string = "";
  groupChatApiCallId: string = "";
  getNpMessageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: "",
      type: "",
      isFavorite: false,
      eventImage: "",
      title: "",
      startDate: "",
      startTime: "",
      organizationName: "",
      sid:"",
      toDate: "",
      toTime: "",
      organizationLogo: "",
      location: "",
      post_code:"",
      eventLat: 0,
      userLang: 0,
      isOpen: false,
      participantList: [],
      favoriteId: null,
      description: "",
      itinerarv_and_notes:"",
      additional_information:"",
      userLat: 0,
      eventLang: 0,
      eventStatus: null,
      isCancelButton: false,
      pledgeId: null,
      isEventPast: false,
      joinChatStatus: false,
      organisation_id: '',
      nonProfitProfileUrl: '',
      isImageLoaded: false,
      accountId: ""
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        if (responseJson.error) {
          toast.error(`${responseJson.error}`);
        } else if (responseJson.errors?.length > 0) {
          toast.error(`${responseJson.errors[0].token}`);
        } else if (apiRequestCallId === this.getNeedDetailApiCallId) {
          this.handleDetailApiResponse(responseJson);
        } else if (apiRequestCallId === this.addFavoriteNeedApiCallId) {
          toast.success("Need added to favourites!");
          this.setState({ isFavorite: true, favoriteId: responseJson.data.id });
        } else if (apiRequestCallId === this.removeFavoriteNeedApiCallId) {
          toast.success("Need removed from favourites!");
          this.setState({ isFavorite: false });
        } else {
          this.handleApiResponse(apiRequestCallId);
        }
        if (apiRequestCallId === this.getNpMessageApiCallId) {this.onViewClick(responseJson.data)
        }
      }
    }
  }

  async componentDidMount() {
    let id = this.props.navigation.getParam("id");
    let type = "r2";
    if(id.includes("r1-")){
      type = "r1";
      id = id.split("r1-")[1];
    }
    if (id && type) {
      this.setState({ id, type }, () => {
        this.getNeedInfo(id);
      });
    }
  }

  handleApiResponse = (apiRequestCallId: any) => {
    if (apiRequestCallId === this.addNeedPledgeApiCallId) {
      if (this.state.type === " r2") {
        toast.success("Request sent successfully!");
      } else {
        toast.success("Pledged successfully!");
      }
      this.getNeedInfo(this.state.id);
    } else if (apiRequestCallId === this.cancelNeedPledgeApiCallId) {
      if (this.state.type === "r2") {
        toast.success("Request canceled successfully!");
      } else {
        toast.success("Pledged canceled successfully!");
      }
      this.getNeedInfo(this.state.id);
    } else if (apiRequestCallId === this.groupChatApiCallId) {
      this.getNeedInfo(this.state.id);
      toast.success("User joined successfully");
    }
  }

  handleDetailApiResponse = (responseJson: any) => {
    const res = responseJson.data.attributes;
    const toTime = res.end_time;
    const toDate = res.to_date ?? res.end_date;
    const obj = res.my_pledge_id ?? res.need_my_pledge_id;
    const endDateTime = this.getDateAndTime(toDate, toTime);
    const isEventPast = moment(endDateTime).isBefore(moment());

    let pledgeId = null;
    let eventStatus = null;
    if (obj && obj.length > 0) {
      pledgeId = obj[0].id;
      eventStatus = obj[0].status;
    }

    this.setState({
      toTime,
      toDate,
      pledgeId,
      eventStatus,
      isEventPast,
      isOpen: false,
      startTime: res.start_time,
      sid:res.sid,
      accountId: res.account_id?.toString(),
      location: res.location,
      post_code: res.post_code,
      isFavorite: res.is_favourite,
      description: res.description,
      itinerarv_and_notes:res.itinerarv_and_notes,
      additional_information:res.additional_information,
      userLat: res.user_latitude,
      userLang: res.user_longitude,
      favoriteId: res.favourite_id,
      eventLat: res.latitude,
      eventLang: res.longitude,
      participantList: res.attended,
      title: res.event_name ?? res.title_of_need,
      startDate: res.from_date ?? res.start_date,
      organisation_id: res.organisation_id,
      organizationName: res.organisation_name ?? res.user_name,
      organizationLogo: res.organisation_image_url?.url ?? res.user_image?.url,
      eventImage: res.event_image?.url ?? res.need_photo?.url,
    }, () => {
      let filterResult = this.state.participantList.filter((ele) => ele.account_id === Number(localStorage.getItem("acc_id")))
      this.setState({ joinChatStatus: filterResult[0]?.joined_chat })
    });
  };

  getDateAndTime = (dates: string, time: string) => {
    if (dates && time) {
      const date = new Date(dates);
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();

      const utcDate = new Date(time);
      const minute = utcDate.getUTCMinutes();
      const hour = utcDate.getUTCHours();
      const second = utcDate.getUTCSeconds();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}.000Z`;
    } else {
      return "";
    }
  }

  MessageNpProfile = (id: string) => {
    const token = localStorage.getItem("token");
    const type = 'user_entity'

    const header = {
      "Content-Type": "application/json",
      "type": type,"token": token};

    const httpBody = {
      "organisation_id": id, }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getNpMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getMessageNpApiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onViewClick = (item: [data:{attributes:{sid: string}}]) => 
  {
    if (item && item.length > 0 && item[0].attributes)
     {const msg = new Message(getName(MessageEnum.NavigationMessage));msg.addData(getName(MessageEnum.NavigationTargetMessage), 'GroupChat');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage
        ), 
      item[0].attributes.sid);
  msg.addData(getName(MessageEnum.NavigationRaiseMessage),
   raiseMessage);this.send(msg);
    } 
    else {console.error('Invalid item or missing attributes:', item);}}

  onClickPopup = ( sid: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage
      ),
       "GroupChat");
    msg.addData(getName(MessageEnum.NavigationPropsMessage
      ),this.props
    );
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage)); raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), sid);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);this.send(msg);

    
  }

  onButtonClick = () => {
    this.state.isCancelButton
      ? this.cancelNeedPledge()
      : this.confirmPledgeNeed()
  }

  openMap = () => {
    window.open(`${configJSON.GOOGLE_MAP_BASE_URL}?api=1&destination=${this.state.eventLat},${this.state.eventLang}`);
  }

  redirectToChat = async () => {
    if (this.state.eventStatus !== "Approved") {
      return;
    }
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("acc_id") as string);
    formData.append("user_email", 'emailId');
    formData.append("full_name", 'name');
    formData.append("event_id", this.state.id);

    this.groupChatApiCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_groupchat/join_chat`,
      body: formData
    });
  }

  getNeedInfo = async (id: string) => {
    const header = {
      "type": "user_entity",
      "token": localStorage.getItem("token")
    }
    this.getNeedDetailApiCallId = await apiCall({
      method: "GET",
      endPoint: this.state.type === "r2"
        ? `non_profit_events/${id}`
        : `/bx_block_events/needs/${id}`,
      header: header
    });
  }

  confirmPledgeNeed = async () => {
    const key = this.state.type === "r2" ? "non_profit_event_id" : "need_id";
    const body = {
      "pledge": {
        "account_id": localStorage.getItem("acc_id"),
        [key]: this.state.id,
      }
    }
    this.addNeedPledgeApiCallId = await apiCall({
      endPoint: `my_pledges`,
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify(body),
    });
  }

  cancelNeedPledge = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.cancelNeedPledgeApiCallId = await apiCall({
      method: "DELETE",
      endPoint: `my_pledges/${this.state.pledgeId}`,
      header
    });
  }

  addToFavoriteNeed = async () => {
    const body = {
      "token": localStorage.getItem("token"),
      "data": {
        "favouriteable_type": this.state.type === "r1" ? "BxBlockEvents::Need" : "BxBlockEvents::NonProfitEvent",
        "favouriteable_id": this.state.id,
      },
    }
    this.addFavoriteNeedApiCallId = await apiCall({
      endPoint: `/bx_block_favourites/favourites`,
      body: JSON.stringify(body),
      contentType: "application/json",
      method: "POST"
    });
  }

  removeFromFavoriteNeed = async () => {
    this.removeFavoriteNeedApiCallId = await apiCall({
      method: "DELETE",
      endPoint: `bx_block_favourites/favourites/${this.state.favoriteId}`
    });
  }
}
// Customizable Area End