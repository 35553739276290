import React from 'react';
import jwtDecode from 'jwt-decode';

export type USER_TYPES =
  'user entity' |
  'business entity' |
  'non profit entity';


/*
 * Protect the route based on the user types that you provide
 *
 * let's say for specific route you only want 1 or 2 user types to access it, you can use this utility
 * and pass it only the user types array that you allow to access this route.
 */
export function userTypeGuard(allowedUserTypes: USER_TYPES[], defaultPath?: string) {

  return function RouteGuard({ children, ...props }: any) {
    try {
      let token = localStorage.getItem('token') || '';
      let { user_type: storedUserType }: any = jwtDecode(token);

      storedUserType = storedUserType.toLowerCase();

      if (storedUserType && !allowedUserTypes.includes(storedUserType as USER_TYPES)) {
        props.navigation.navigate(defaultPath || 'EmailAccountLoginBlock');
      }

      return (<>{children}</>);

    } catch (error) {
      console.error(error);
      return (<>{children}</>);
    }
  }
}


/*
 * Only allow non-logged in users to stay in the target page, otherwise redirect them
 *
 * this guard is useful when user open sign in page, and you know that you only allow non-logged in 
 * users to access login page, once logged in, you want to auto-redirect them to some page, because
 * they're already logged in.
 */
export function nonLoggedInGuard(routePath: string) {
  return function NonLoggedInGuard({ children, ...props }: any) {

    try {
      let token = localStorage.getItem('token') || '';

      if (token) {
        jwtDecode(token);
        props.navigation.navigate(routePath || 'LandingPageUserFlow');
      }

      return (<>{children}</>);

    } catch (error) {
      console.error(error);
      return (<>{children}</>);
    }
  }
}


/*
 * only allow logged in users to access specific route
 */
export function LoginGuard(loginPath: string) {
  return function protectedLoginGuard({ children, ...props }: any) {
    try {

      let token = localStorage.getItem('token') || '';

      jwtDecode(token);

      if (!token) {
        props.navigation.navigate(loginPath || 'EmailAccountLoginBlock');
      }

      return (<>{children}</>);

    } catch (error) {
      console.error(error);
      props.navigation.navigate(loginPath || 'EmailAccountLoginBlock');
      return (<>{children}</>);
    }
  }
}

export function SessionGuard(loginPath: string) {
  return function protectedSessionGuard({ children, ...props }: any) {
    const checkTokenExpiration = () => {
      console.log("Token expiration check started");

      try {
        const token = localStorage.getItem('token') || '';
        const decodedToken = jwtDecode(token) as { exp: number }; 

        if (typeof decodedToken.exp !== 'undefined') {
          const expirationTimestamp = decodedToken.exp * 1000; // Convert to milliseconds
          const currentTime = Date.now();

          if (expirationTimestamp < currentTime) {
            console.log("Token expired");

            // Token has expired, redirect to loginPath
            localStorage.clear()

            props.navigation.navigate(loginPath || 'EmailAccountLoginBlock');
          }
        } 
      } catch (error) {
        console.error(error);
        // If there's an error decoding the token, redirect to loginPath
        props.navigation.navigate(loginPath || 'EmailAccountLoginBlock');
      }
    };

    checkTokenExpiration();

    // You might want to call `checkTokenExpiration` periodically to handle token expiration.
    // For example, you can use setInterval here.

    return <>{children}</>;
  };
}


export function BusGuard(loginPath: string) {
  return function protectedSessionGuard({ children, ...props }: any) {
    const checkTokenExpiration = () => {
      console.log("Token expiration check started");

      try {
        const token = localStorage.getItem('token') || '';
        const decodedToken = jwtDecode(token) as { exp: number }; 

        if (typeof decodedToken.exp !== 'undefined') {
          const expirationTimestamp = decodedToken.exp * 1000; // Convert to milliseconds
          const currentTime = Date.now();

          if (expirationTimestamp < currentTime) {
            console.log("Token expired");

            // Token has expired, redirect to loginPath
            localStorage.clear()

            props.navigation.navigate(loginPath || 'LoginBusiness');
          }
        } 
      } catch (error) {
        console.error(error);
        // If there's an error decoding the token, redirect to loginPath
        props.navigation.navigate(loginPath || 'LoginBusiness');
      }
    };

    checkTokenExpiration();

    // You might want to call `checkTokenExpiration` periodically to handle token expiration.
    // For example, you can use setInterval here.

    return <>{children}</>;
  };
}

export function NpGuard(loginPath: string) {
  return function protectedSessionGuard({ children, ...props }: any) {
    const checkTokenExpiration = () => {
      console.log("Token expiration check started");

      try {
        const token = localStorage.getItem('token') || localStorage.getItem('nonprofitToken') || "";
        const decodedToken = jwtDecode(token) as { exp: number }; 

        if (typeof decodedToken.exp !== 'undefined') {
          const expirationTimestamp = decodedToken.exp * 1000; // Convert to milliseconds
          const currentTime = Date.now();

          if (expirationTimestamp < currentTime) {
            console.log("Token expired");

            // Token has expired, redirect to loginPath
            localStorage.clear()

            props.navigation.navigate(loginPath || 'NpSignIn');
          }
        } 
      } catch (error) {
        console.error(error);
        // If there's an error decoding the token, redirect to loginPath
        props.navigation.navigate(loginPath || 'NpSignIn');
      }
    };

    checkTokenExpiration();

    // You might want to call `checkTokenExpiration` periodically to handle token expiration.
    // For example, you can use setInterval here.

    return <>{children}</>;
  };
}
