import React from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { makeStyles, } from '@material-ui/core/styles';
import { icons } from "../constants";
import {stopwatchIcon } from '../../../blocks/email-account-registration/src/assets';

const useCustomKeyboardDatePickerStyles = makeStyles((theme) => ({
  textField: {
    padding: 0,
    borderRadius: 12,
    width: 145,
    height: 48,
    boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.5)',
    border: "none",
    [theme.breakpoints.down('xs')]: {
      width: "154px",
    },
  },
  pickerMargin: {
    width: 145,
    marginTop: 0,
    marginBottom: 0
  },
  fontResize: {
    fontWeight: 400,
    lineHeight: ' 20px',
    letterSpacing: '-0.4px',
    color: "#767676",
    fontFamily: "Rene_Bieder_Milliard_Book",
  },
  inputAdornedEnd: {
    margin: 0,
    fontSize: '0.8rem',
  },
}));


export function InitiateKeyboardTimePicker({
  id,
  date,
  setDate,
  helperText,
  error,
}: {
  id?: string,
  date: Date,
  setDate: (date: Date) => void,
  helperText?: string,
  error?: boolean,
}) {
  const classes = useCustomKeyboardDatePickerStyles();

  return (
    <KeyboardTimePicker
      id={id}
      ampm={false}
      autoOk
      className={classes.pickerMargin}
      disableToolbar
      variant="inline"
      color="primary"
      inputVariant="outlined"
      margin="normal"
      InputProps={{
        classes: {
          root: classes.textField,
          input: classes.fontResize,
        },
      }}
      value={date}
      onChange={(time) => {
        setDate(time);
      }}
      KeyboardButtonProps={{
        'aria-label': 'Select time',
      }}
      helperText={helperText}
      error={error}
      keyboardIcon={<img src={stopwatchIcon} alt="Timer" />}
    />
  );
}
