import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify';
import { apiCall } from "../../../components/src/utils/utils.web";
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface ITermAndCondition {
  attributes : {
    account_type: string;
    description: string;
  }
}

interface S {
  token: string;
  termsandconditiondata: ITermAndCondition | null;
  txtInputValue: string;
  txtSavedValue: string;
  inviteLink:string;
  enableField: boolean;
  // Customizable Area Start
  redirect: string;
  loadingData: boolean;
  selectedTab: any;
  selectedBottomTab: any;
  getFromDate: string;
  getTODate: string;
  getOnTime: string;
  getOffTime: string;
  selectMenuItem: string;
  contactMsg: string;
  contactMessageError: string;
  contactValidation: boolean;
  openSuccessModal: boolean;
  isSubmitting: boolean;
  feedbackMsg: string;
  feedbackRating: any;
  isAllDay: any;
  getCurrentAvailability: any;
  selectAllCheckbox: boolean;
  iconOpen:number;
  emailError:string;
  email:string;
  taskCheck1: boolean;
  taskCheck2: boolean;
  taskCheck3: boolean;
  taskCheck4: boolean;
  taskCheck5: boolean;
  emailNotData: any;
  feedbaqckMsgError: boolean;
  deleteAccountModel: boolean;
  privacyPolicyDescription: any;
  termsChecked: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiID: any;
  sendInviteApiID:any;
  feedbackApiID: any;
  deactivateAccountApiCallId: any;
  privacyPolicyApiCallId: any;
  acceptTermsAndConditionApiCallId: any;
  delFcmIds:any;
  logoffApiCallId: string = ""
  removeDeviceTokenApiCallId: string = "";
  // Customizable Area End
  emailNotificationApiCallId: any;
  onTermsandConditionId: any;
  onTermsandCon: any;

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      token: "",
      redirect: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedTab: '',
      selectedBottomTab: 0,
      iconOpen:0,
      inviteLink:configJSON.inviteLink,
      emailError:"",
      email:"",
      getFromDate: "",
      getTODate: "",
      getOnTime: "",
      getOffTime: "",
      selectMenuItem: "",
      contactMsg: "",
      contactMessageError: "",
      contactValidation: false,
      openSuccessModal: false,
      isSubmitting: false,
      feedbackMsg: "",
      feedbackRating: 0,
      isAllDay: false,
      getCurrentAvailability: new Array(),
      selectAllCheckbox: false,
      taskCheck1: false,
      taskCheck2: false,
      taskCheck3: false,
      taskCheck4: false,
      taskCheck5: false,
      emailNotData: {
        account_id: 1,
        events_matching_my_availability: false,
        events_matching_my_saved_searches: false,
        pledges_on_my_published_need: false,
        my_pledge_is_accepted_or_rejected: false,
        needs_published_by_individuals: false,
        chat_notification: false,
        checked_connections: false,
        email_notification: false
      },
      loadingData: true,
      termsandconditiondata: null,
      feedbaqckMsgError: false,
      deleteAccountModel: false,
      privacyPolicyDescription: "",
      termsChecked: false,


      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

    

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId) {
        if (apiRequestCallId === this.onTermsandConditionId && responseJson != null) {
          this.setState({
            termsandconditiondata: responseJson.data,
            loadingData: false
          });
        }
        if (apiRequestCallId === this.onTermsandCon && responseJson != null) {
          this.props.navigation.navigate("Notificationsweb");
        }
        this.handleAllAPIResponse(apiRequestCallId, responseJson);
      } else {
        this.setState({ isSubmitting: false })
      }
    }
    // Customizable Area End
  }

  handleAllAPIResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.contactUsApiID && responseJson.data != null) {
      this.setState({
        contactMsg: "",
        contactMessageError: "",
        isSubmitting: false,
      })
      this.handleSuccessModalOpen()
    } else {
      this.setState({ isSubmitting: false, contactMsg: "" })
    }
    if (apiRequestCallId === this.feedbackApiID && responseJson !== null) {
      this.setState({
        feedbackMsg: "",
        feedbackRating: 0,
        feedbaqckMsgError: false
      })
      this.handleSuccessModalOpen()
    }
    if (apiRequestCallId === this.privacyPolicyApiCallId && responseJson !== null) {
      this.setState({ privacyPolicyDescription: responseJson.data?.attributes?.description })
    }
    if (apiRequestCallId === this.deactivateAccountApiCallId && responseJson?.success) {
      localStorage.clear();
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    if (apiRequestCallId === this.acceptTermsAndConditionApiCallId && responseJson?.data) {
      this.setState({ termsChecked: false })
      this.handleSuccessModalOpen()
    }
    if(apiRequestCallId === this.logoffApiCallId) {
      localStorage.clear()
      this.props.navigation.navigate('EmailAccountLoginBlock');
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({
        enableField: !this.state.enableField,
      });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({
      enableField: !this.state.enableField,
    });
  };

  // Customizable Area Start
  removeDeviceToken = async() => {
    const header = {
      "Content-Type": "application/json",
      type: "user_entity",
      token: localStorage.getItem("token"),
    };
    this.removeDeviceTokenApiCallId = await apiCall({
      endPoint: `/account_block/device_ids/${localStorage.getItem("device_id")}`,
      method: "DELETE",
      header: header
    });
  }

  // Availability OnChange Event
  handleLogout = async () => {
    let accId = localStorage.getItem("acc_id")
    const utcTime = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    const body = {
      "activity_log": {
          "accountable_id": accId,
          "accountable_type": "AccountBlock::Account",
          "log_of_date_time": utcTime
      }
    };
    const header = {
      "Content-Type": "application/json"
    }
      this.logoffApiCallId = await apiCall({
        method: "POST",
        endPoint: `/bx_block_profile/non_profit_activity_logs`,
        body: JSON.stringify(body),
        header: header
      });
  };

  onSelectedDay = (d: any) => {
  };

  handleFromDate = (value: any) => {
    this.setState({ getFromDate: value });
  };

  handleTODate = (value: any) => {
    this.setState({ getTODate: value });
  };

  handleOnTime = (value: any) => {
    this.setState({ getOnTime: value });
  };

  handleOffTime = (value: any) => {
    this.setState({ getOffTime: value });
  };

  goToSetAvailability = () => {
    const availabilityInput = {
      start_time: this.state.getOnTime,
      end_time: this.state.getOffTime,
      from_date: this.state.getFromDate,
      to_date: this.state.getOnTime,
    };
  };

  toggleAllDay = (status: boolean) => {
    this.setState({ isAllDay: status });
  };

  contactUsSub = () => {
    this.validateContactMessage(this.state.contactMsg)
    this.setState({ isSubmitting: true })
    if (this.state.contactValidation && this.state.contactMsg !== "") {
      const token = localStorage.getItem("token")
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const attrs = {
        description: this.state.contactMsg,
      };
      const data = {
        // type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: {
          description: this.state.contactMsg,
        },
        user_type: "user entity",
        token: token
      };


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.contactUsApiID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      // this.emailVerify(values.email);
      return true;
    } else {
      this.setState({ contactMsg: "" })
    }
  };
 
  sendInvite = (email:any) => {
    const token =localStorage.getItem('token');
    const header = {
      token: token,
      type: "user_entity",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendInviteApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/send_invitation?email=${email}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    toast.success("Invitation sent successfully!") 
    return true;
  }

  validateContactMessage(value: any) {
    if (/^\s*$/.test(value.trim())) {
      this.setState({ isSubmitting: false, contactMessageError: 'Please enter message', contactValidation: false })
    } else {
      this.setState({ contactMessageError: "", contactValidation: true })
    }
  }

  handleSuccessModalOpen = () => {
    this.setState({ openSuccessModal: true });
  };

  handleSuccessModalClose = () => {
    this.setState({ openSuccessModal: false });
  };

  handleDeleteAccountOpenModel = () => {
    this.setState({
      deleteAccountModel: !this.state.deleteAccountModel,
    });
  };

  handleDeactivateAccount = () => {
    const acc_id = localStorage.getItem("acc_id");

    const httpBody = {
      "account_id": acc_id
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deactivateAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactivateAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChanged = (e: any) => {
    let fd = this.state.emailNotData;
    this.setState({ emailNotData: fd });

  };

  handleInviteClick = () =>{
    const inviteLink = this.state.inviteLink;
    navigator.clipboard.writeText(inviteLink)
    .then(() => {
     toast.success("Invite link copied!")
    })
    .catch(error => {
      console.error('Error copying text:', error);
    });
  }

  validateEmail = (value: any) => {
    if (value == '') {
      this.setState({ emailError: ""})
    }
    if(value !== "") {
      (!value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) ?
        this.setState({emailError: "Please enter valid email address"})
        :
        this.setState({emailError: ""}
        )
    }
  }

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };
  emailNotificationSubmit = () => {

    // EmailNotification API Data

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailNotificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify(attrs)
      JSON.stringify(this.state.emailNotData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.onTermsandCond()
    this.privacyPolicy();
  }

  privacyPolicy = () => {
    const header = {
      "Content-Type": "application/json",
      "Accept" : "application/json"
    };

    const account_type = 'user'
    const privacyPolicyTab = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.privacyPolicyApiCallId = privacyPolicyTab.messageId;

    privacyPolicyTab.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    privacyPolicyTab.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PrivacyPolicyEndPoint}?account_type=${account_type}`
    );

    privacyPolicyTab.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(privacyPolicyTab.id, privacyPolicyTab)
  }

  acceptTermsAndCondition = () => {
    const acc_id = localStorage.getItem("acc_id");

    const httpBody = {
      "terms_and_condition": true,
      "account_id": acc_id
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptTermsAndConditionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptTermsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onTermsandCond = async () => {
    const header = {
      "Content-Type": "application/json",
      "Accept" : "application/json"
    };

    const account_type = 'user'
    const onTermsandCondItem = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    onTermsandCondItem.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    this.onTermsandConditionId = onTermsandCondItem.messageId;

    onTermsandCondItem.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.onTermsandCondition}?account_type=${account_type}`
    );

    onTermsandCondItem.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(onTermsandCondItem.id, onTermsandCondItem);
  }

  feedbackSubmit = () => {

    if (this.state.feedbackMsg === "") {
      this.setState({ feedbaqckMsgError: true })
    } else {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const httpBody = {
        feedback_count: this.state.feedbackRating,
        feedback_msg: this.state.feedbackMsg,
        account_id: localStorage.getItem('acc_id'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      // debugger
      this.feedbackApiID = requestMessage.messageId;
      // debugger
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.feedbackApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

  };

  delFcmTokens ():any {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":localStorage.getItem("token"),
      "type":"user_entity"
    };
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.delFcmIds = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `account_block/device_ids/${localStorage.getItem("device_id")}`
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "DELETE"
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

runEngine.sendMessage(requestMessage.id, requestMessage)

  }

  handleFeedbackMsg = (val: any) => {
    if (val.trim() === "") {
      this.setState({ feedbaqckMsgError: true })
    } else {
      this.setState({ feedbaqckMsgError: false })
    }
    this.setState({ feedbackMsg: val })
  }


  getAvailibilityQuery = (filterdate: any) => { };
  // Customizable Area End
}
