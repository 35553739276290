// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: any;
    history?: any;
    location?: any;
    match?: any;
}

export interface S {
    // Customizable Area Start
    id: string;
    openSuccessModal: boolean;
    subusPlanDetails: any;
    loader: boolean;
    selectedPlanId: any,
    planTitle: string;
    planAmount: number;
    currentPlanInfo: any;
    planType: string;
    days: number;
    autoPay: boolean;
    discountAmount: number;
    type: null | string;
    currentPlanId: any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SubscriptionPlanController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getBusinessSubPlanApiCallId: string = "";
    getCurrentPlanInfoApiCallId: string = "";
    createPaymentIntentApiCallId: string = "";
    cancelSubscriptionApiCallId: string = "";
    autoPayApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            id: "",
            openSuccessModal: false,
            subusPlanDetails: [],
            loader: true,
            selectedPlanId: '',
            planTitle: "",
            planAmount: 0,
            currentPlanInfo: {},
            planType: "",
            days: 0,
            autoPay: false,
            discountAmount: 0,
            type: null,
            currentPlanId: null
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getBusinessSubPlanApiCallId) {
                    // code here
                    this.handlePlanDataAPIsResponse(responseJson)
                } else if (apiRequestCallId === this.getCurrentPlanInfoApiCallId) {
                    this.handleCurrentPlanResponse(responseJson)
                } else if (apiRequestCallId === this.cancelSubscriptionApiCallId) {
                    // code here
                    this.handleCancelPlanResponse(responseJson)
                } else if (apiRequestCallId === this.autoPayApiCallId) {
                    // code here
                    this.handleAutoRenewResponse(responseJson)
                }
            }
        }
    }

    async componentDidMount() {
        const path = window.location.pathname;
        this.refactorAPIS(path)
    }

    handleCancelPlanResponse(responseJson : any) {
        if ( ! responseJson?.error) {
           localStorage.setItem('showSubscribe', 'false')
           return this.handleSuccessRedircModalClose()
        } else {
            return this.handleSuccessModalClose()
        }
    }

    handleAutoRenewResponse(responseJson : any) {
        if ( !responseJson?.error) {
           this.setState({ autoPay: responseJson.subscription?.auto_pay, loader: false });
        } else {
            this.setState({ loader: false });
        }
    }

    handleCurrentPlanResponse(responseJson: any) {
        if(responseJson.data === null) {
            return this.navigateToPlanChangePage()
        } else {
            this.setState({
                currentPlanInfo: { ...responseJson?.data?.attributes,descriptions: Object.values(responseJson?.data?.attributes?.subscription?.data?.attributes?.description) ?? {} },
                loader: false,
                autoPay : responseJson?.data?.attributes?.auto_pay 
            })  
        }
    }

    handlePlanDataAPIsResponse(responseJson: any) {
        let planType = "new"
        let addOn = null;
        let planData = responseJson.data.map((item: any) => {
            if (item.attributes.subscribed) {
                planType = "change";
                this.setState({ currentPlanId: item.id });
            }
            if(item.attributes?.add_on_plan?.data){
                let obj = item.attributes.add_on_plan.data[0].attributes;
                addOn = {
                    id: obj.id,
                    type: "addOn",
                    title: obj.name,
                    amount: obj.price,
                    discontAmount: 0,
                    descriptions: Object.values(obj.description),
                    notes: Object.values(obj.notes ?? {}),
                }
            }
            return {
                id: item.id,
                title: this.handlePlanName(item?.attributes?.name),
                days: item.attributes.valid_for_days,
                amount: parseFloat(item.attributes.price),
                duration: item.attributes.valid_for_days,
                isCurrent: item.attributes.subscribed,
                descriptions: Object.values(item.attributes.description ?? {}),
                notes: Object.values(item.attributes.notes ?? {}),
                isExpired: item.attributes.used,
                name: item?.attributes?.name,
                isTrialPurchased: this.isTrialExpired(this.handlePlanName(item?.attributes?.name), item?.attributes?.allow_trial_purchase, item.attributes.subscribed),
                discontAmount: item?.attributes?.discount_on_next_subscription
            }
        })

        const sortedArray = planData.sort((a: any, b: any) => {
            let x = a.name;
            let y = b.name;
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        })

        const [lastElement] = sortedArray.slice(-1);

        // Remove the last element from the array using pop()
        sortedArray.pop();

        // Add the last element at the first position using unshift()
        sortedArray.unshift(lastElement);
        if(addOn){
            planData.push(addOn);
        }
        this.setState({ subusPlanDetails: planData, loader: false, planType })
    }

    refactorAPIS(path: string) {
        if (path.includes('BusSubscriptionPlan')) {
            this.getBusinessSubsPlans()
        } else if (path.includes('BusChangeSubscriptionPlan')) {
            this.getCurrentSubcriptionPlan()
        }
    }

    handleSuccessModalClose = () => {
        this.props.navigation.navigate("BusHomeDashboard");
        this.setState({ openSuccessModal: false });
    }

    handleSuccessRedircModalClose = () => {
        this.setState({ openSuccessModal: false });
        this.props.navigation.navigate('BusHomeDashboard')
    };

    navigateToSubscribePaymentPage = () => {
        this.props.history.push({
            pathname: "/BusSubscriptionPayment",
            state: {
                planId: this.state.selectedPlanId,
                planType: this.state.planAmount === 0 ? "trial" : this.state.planType,
                days: this.state.days,
                planTitle: this.state.planTitle,
                planAmount: this.state.planAmount,
                type: this.state.type,
                currentPlanId: this.state.currentPlanId
            }
        });
    }

    navigateToPlanChangePage = () => {

        this.props.history.push({
            pathname: "/BusSubscriptionPlan",
            state: {
                pageTitle: "Subscribe",
            }
        });
    }

    getBusinessSubsPlans = () => {

        const header = {
            "token": localStorage.getItem("token"),
            "type": 'business_entity'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBusinessSubPlanApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBusinessPlansEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCurrentSubcriptionPlan = () => {

        const header = {
            "token": localStorage.getItem("token"),
            "type": 'business_entity'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCurrentPlanInfoApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCurrentPlanInfoApiEndPoint

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    cancelSubcriptionPlan = () => {

        const header = {
            "token": localStorage.getItem("token"),
            "type": 'business_entity'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.cancelSubscriptionApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cancelSubscriptionApiEndPoint

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    autoPaySubscription = () => {

        this.setState({ loader: true })
        const httpBody = {
            'auto_pay': this.state.autoPay ? "off" : "on"
        }

        const header = {
            "content-type": "application/json",
            "token": localStorage.getItem("token"),
            "type": 'business_entity'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.autoPayApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.autoPayApiEndPoint

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    getTempData = () => {
        const responseJson = {
            "data": [
                {
                    "id": "29",
                    "type": "subscription",
                    "attributes": {
                        "name": "Trial-Business",
                        "price": "0.0",
                        "description": {
                            "1": "Access to all services during trial period",
                        },
                        "valid_for_days": 30,
                        "subscribed": false
                    }
                },
                {
                    "id": "30",
                    "type": "subscription",
                    "attributes": {
                        "name": "Presence-Only",
                        "price": "12.99",
                        "description": {
                            "1": "Non-paying non-profit subscribers with limited platform access to optimised messaging.",
                            "2": "Ability to affirm volunteer affiliation through membership/registration"
                        },
                        "valid_for_days": 90,
                        "subscribed": false
                    }
                },
                {
                    "id": "31",
                    "type": "subscription",
                    "attributes": {
                        "name": "Supported Business",
                        "price": "12.99",
                        "description": {
                            1: "Paying non-profit subscriber with reduced subscription rate",
                            2: "Aimed at smaller non-profit organisations with less than £ 10,000 turnover"
                        },
                        "notes": {
                            1: "Paying non-profit subscriber with reduced subscription rate",
                            2: "Aimed at smaller non-profit organisations with less than £ 10,000 turnover"
                        },
                        "valid_for_days": 90,
                        "subscribed": false
                    }
                },
                {
                    "id": "32",
                    "type": "subscription",
                    "attributes": {
                        "name": "Full Business Subscription",
                        "price": "12.99",
                        "description": {
                            1: "Paying non-profit subscriber with discounted subscription rate on account of membership to cross-sector organisation",
                            2: "Access to all services"
                        },
                        "valid_for_days": 90,
                        "subscribed": false
                    }
                },
            ]
        }

        const planData = responseJson.data.map((item: any, index: number) => {
            return {
                id: item.id,
                title: item.attributes.name,
                amount: item.attributes.price,
                duration: item.attributes.valid_for_days,
                isCurrent: item.attributes.subscribed,
                descriptions: Object.values(item.attributes.description ?? {}),
                notes: Object.values(item.attributes.notes ?? {}),
                isExpired: item.attributes.used,
            }
        })

        this.setState({ subusPlanDetails: planData, loader: false })
    }

    handlePlanName = (name: string) => {
        const plans = ["Trial-Business", "Presence-Only", "Supported Business","Non paying - Presence only","Paying: 50 Employee Presence", "Full Business"]

        return plans.filter((item: any) => name?.includes(item)).toString()
    }

    isTrialExpired = (trial : string, allowPurchase: boolean, isCurrentPlan: boolean) => {
        if((trial === "Trial-Business") &&  !allowPurchase) {
            return true
        } else {
            return false
        }
    }
}
// Customizable Area End