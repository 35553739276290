// Customizable Area Start
import React, { useEffect, useState } from 'react';
import { Divider, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InitiateLabel } from '../../../components/src/design-system/label';
import { InitiateButton } from '../../../components/src/design-system/button';
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { NotificationRequestPayload } from '../../../components/src/types/notifications';
import { useSetNotificationsSettings, useUserProfile } from '../../../components/src/api/hooks/user.hooks';
import { useNavigate } from '../../../components/src/utils/router-context';
import { useSelector } from 'react-redux';
import { RootState } from '../../../framework/src/redux/stores';
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { RegisterationMobileHeader } from './RegisterationMobileHeader';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../../../components/src/api/keys/user.keys';
import { InitiateCheckbox } from '../../../components/src/design-system/checkbox';
import CustomSuccessModel from '../../../components/src/custom/CustomSuccessModel';




const LABELS: {
  [k: string]: { label: string, key: keyof NotificationRequestPayload }[]
} = {
  'Events & Needs': [
    { label: 'Events matching my availability', key: 'events_matching_my_availability' },
    { label: 'Events matching my Saved Searches', key: 'events_matching_my_saved_searches' },
    { label: 'Pledges on my published need', key: 'pledges_on_my_published_need' },
    { label: 'My pledge is accepted/rejected', key: 'my_pledge_is_accepted_or_rejected' },
    { label: 'Needs published by individuals', key: 'needs_published_by_individuals' },
  ],
  Chat: [
    { label: 'New messages', key: 'chat_notification' },
  ],
  Connections: [
    { label: 'New connection requests', key: 'checked_connections' },
  ],
};


const useStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    // height:'93% !important',
    height: 'min(730px, 75vh)',
    padding: '5%',
    paddingLeft:'7%',
    [theme.breakpoints.down('sm')]: {
      height: '80% !important',
      marginLeft:'-5%',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  titleText: {
    fontSize: '1.8rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
    textAlign: 'center',
  },
  submitButton: {
    fontSize: '1.3rem',
  },
  skipButton: {
    fontSize: '1.3rem',
  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dbsTypesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  helperText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#87888f',
    fontSize: '1rem',
    position: 'absolute',
    top: '100%',
    right: '0',
  },

  checkbox: {
    fontWeight: 400,
    width: '100%',
    justifyContent: 'space-between',
    margin: '0',
    '& *': {
      fontFamily: 'Rene_Bieder_Milliard_Book',
    },
  },
}));

export function NotificationsSettingsForm({
  title,
  type,
  onSuccessNavigateTo,
  hideTitle,
  hideSkipButton,
  submitButtonTitle,
  preventNavigationOnSuccess,
}: {
  title: string,
  type: 'app' | 'email',
  onSuccessNavigateTo: string,
  hideTitle?: boolean,
  hideSkipButton?: boolean,
  submitButtonTitle?: string,
  preventNavigationOnSuccess?: boolean,
}) {

  const classes = useStyles();
  const navigate = useNavigate();


  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const currentUrl = window.location.href
  const parts = currentUrl.split('/');
  const lastWord = parts[parts.length - 1];
  const types = new URLSearchParams(document.location.search).get("key");
  const disable = types === "Settings" || lastWord === "SettingsNotification" || lastWord === "Settings";
  const isMobile = useIsMobileScreen();

  const { mutate: setSettings, isLoading } = useSetNotificationsSettings();
  const queryClient = useQueryClient();

  const [selectedNotifications, setSelectedNotifications] = useState({
    events_matching_my_availability: false,
    events_matching_my_saved_searches: false,
    pledges_on_my_published_need: false,
    my_pledge_is_accepted_or_rejected: false,
    needs_published_by_individuals: false,
    chat_notification: false,
    checked_connections: false,
  });

  const nothingSelected = Object.values(selectedNotifications).every(checked => !checked);

  const accountId = localStorage.getItem("acc_id") ?? "";
  const profileQuery = useUserProfile({ accountId: parseInt(accountId) });
  const [openSuccessModal, setSuccessModel] = useState(false)

  useEffect(() => {
    const alertNotifications = profileQuery?.data?.data?.attributes?.alert_notifications ?? [];
    if(alertNotifications.length > 0){
      const notiType = type === "app" ? "app_notification" : "email_notification";
      const targetNotifications = alertNotifications[0].notification_type === notiType ? alertNotifications[0] : alertNotifications[1];
      if (targetNotifications) {
      setSelectedNotifications(state => ({
        ...state,
        events_matching_my_availability: targetNotifications.events_matching_my_availability || false,
        events_matching_my_saved_searches: targetNotifications.events_matching_my_saved_searches || false,
        pledges_on_my_published_need: targetNotifications.pledges_on_my_published_need || false,
        my_pledge_is_accepted_or_rejected: targetNotifications.my_pledge_is_accepted_or_rejected || false,
        needs_published_by_individuals: targetNotifications.needs_published_by_individuals || false,
        chat_notification: targetNotifications.chat_notification || false,
        checked_connections: targetNotifications.checked_connections || false,
      }));
    }
  }
  }, [profileQuery.data, type]);


  function toggleHandler(key: keyof NotificationRequestPayload) {
    setSelectedNotifications((state) => {
      const newState = { ...state, [key]: !state[key] };
      setUnsavedChanges(true);
      return newState;
    });
  }

  function submitHandler() {
    setSettings({
      settings: selectedNotifications,
      type,
      accountId: parseInt(accountId),
    }, {
      onSuccess() {
        queryClient.invalidateQueries(userKeys.fetchProfile({ accountId: parseInt(accountId) }));

        if (!preventNavigationOnSuccess) {
          navigate(onSuccessNavigateTo);
        } else {
          setSuccessModel(true)
        }
      },
    });
  }
  function skipHandler() {
    navigate(onSuccessNavigateTo);
  }

  function mobileNavigate() {
    setSettings({
      settings: selectedNotifications,
      type,
      accountId: parseInt(accountId),
    }, {
      onSuccess() {
        queryClient.invalidateQueries(userKeys.fetchProfile({ accountId: parseInt(accountId) }));

        if (!preventNavigationOnSuccess && !disable) {
          navigate('Settings');
        } else {
          setSuccessModel(true)
        }
      },
    });
  }

  function handleSuccessModalClose() {
    setSuccessModel(false)
  }

  const isSaveButtonDisabled = isLoading || !unsavedChanges || nothingSelected;

  return (
    <>

      <div className={classes.formContentRoot} style={{height: !preventNavigationOnSuccess ? 'min(710px, 80vh)' : 'auto'}}>

        <HideContainer hideWhen={hideTitle}>
          <Typography
            variant="h1"
            color="primary"
            className={classes.titleText}
          >
            {title}
          </Typography>
          <VerticalSpacer value={40} />
        </HideContainer>


        <div className={classes.formContent}>
          {
            Object.entries(LABELS)
              .map(([key, labels]) => (
                <div key={key}>

                  <InitiateLabel>
                    {key}
                  </InitiateLabel>

                  <VerticalSpacer value={10} />

                  {
                    labels.map(({ label, key }) => (
                      <InitiateCheckbox
                        key={key}
                        formControlLabelProps={{ className: classes.checkbox, labelPlacement: 'start' }}
                        checked={selectedNotifications[key]}
                        onChange={() => toggleHandler(key)}
                        name={label}
                        label={label}
                      />
                    ))
                  }

                  <VerticalSpacer value={30} />
                  <Divider />
                  <VerticalSpacer value={30} />

                </div>
              ))
          }
        </div>

        <VerticalSpacer value={30} />

        <div className={classes.submitButtonsContainer}>
          <InitiateButton
            data-testId="submit-btn"
            loading={isLoading}
            className={classes.submitButton}
            onClick={disable ? mobileNavigate : submitHandler}
            disabled={disable ? isSaveButtonDisabled : nothingSelected}
            fullWidth
            color="primary"
            variant="contained"
            size="large">
            {submitButtonTitle || 'Save'}
          </InitiateButton>
          <HideContainer hideWhen={isMobile || hideSkipButton}>
            <Button
              className={classes.skipButton}
              fullWidth
              color="primary"
              variant="text"
              onClick={skipHandler}
              size="large">
              {'Skip'}
            </Button>
          </HideContainer>
        </div>

      </div>

      <RegisterationMobileHeader
        title={title}
        hideSkipButton={disable}
        onSkip={skipHandler}
      />

      <CustomSuccessModel openSuccessModal={openSuccessModal} handleSuccessModalClose={handleSuccessModalClose} msgTitle='Thank you' msg="Changes has been updated" />


    </>
  );
}



// Customizable Area End
