import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// @ts-ignore
import { FAMILYFONTS, images } from "../../../components/src/constants";
// @ts-ignore
import classOuter from "./EmailOtpVerifyBusiness.module.css";
import OtpInput from 'react-otp-input';
import LoginFormController, {
  Props,
} from "./LoginFormController";
// Customizable Area End


export default class EmailOtpVerifyBusiness extends LoginFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const otpLength = this.state.emailOtp.length;
    const disabled = (otpLength === 6 && !this.state.otpError) ? false : true;
    // const temp_otp = JSON.parse((sessionStorage.getItem("np-otp-meta") as string))?.otp || null;
    let temp_otp = "";
    if(typeof window !== "undefined"){
      temp_otp = localStorage.getItem("forgotPasswordOTP") || ""
    }

    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        {/* OTP logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.otpLogoMainContainer}>
            <div className={classOuter.otpLogoContainer}>
              <img src={images.emailIcon} className={classOuter.otpLogo} />
            </div>
          </div>

          {/* Forgot Password OTP Form */}
          <div>
            {/* Form Title */}
            <Box>
              <Typography
                className={classOuter.titleText}
                style={{
                  ...FAMILYFONTS.family1,
                  fontSize: 24,
                  fontWeight: 400
                }}>OTP Verification!</Typography>
            </Box>

            {/* Form Content */}
            <Box>
              <Box className={classOuter.otpContainer}>
                {/* OTP Message */}

                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 16,
                    fontWeight: 400,
                    textAlign: "left"
                  }}>
                  Please enter the verification code sent to your registered email address
                </Typography>

                {/* OTP Input Field */}
                <Box className="otpInput"
                  style={{ color: this.state.otpError ? "#E41616" : "inherit", }}
                >
                  <OtpInput
                    data-test-id="forgotPwdOtp"
                    // @ts-ignore
                    name="otpInput"
                    value={this.state.emailOtp}
                    onChange={(e: any) => { this.setEmailOtp(e)}}
                    numInputs={6}
                    className={this.state.otpError ? classOuter.innerOtpErrorText : classOuter.innerOtpText}
                    separator={<span />}
                    isInputNum
                  />
                  {/* OTP validation Error */}
                  {this.state.otpError && (
                    <Box className={classOuter.errorMsg} display="flex" sx={{ mt: '1rem' }}>
                      <Box className={classOuter.errorIconDiv}><img src={images.invalidOtp} alt="inValidOtp" /></Box>
                      <div className={classOuter.errors}>Please enter valid OTP</div>
                    </Box>
                  )}

                  {/* OTP expire Error */}
                  {this.state.expireOtpError && (
                    <Box className={classOuter.errorMsg} display="flex" sx={{ mt: '1rem' }}>
                      <Box className={classOuter.errorIconDiv}><img src={images.invalidOtp} alt="inValidOtp" /></Box>
                      <div className={classOuter.errors}>{this.state.expireOtpError}</div>
                    </Box>
                  )}

                </Box>
              </Box>

              {/* Resend link and OTP Verify Button */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
                className={classOuter.footerLinkContainer}
              >
                <Box 
                // onClick={this.resendOTP}
                >
                  <Typography className={classOuter.footerLinkText}>
                    <span
                      style={{
                        ...FAMILYFONTS.family3,
                        fontSize: 16,
                        fontWeight: 400
                      }}>
                      Didn't receive a verification code?
                    </span>
                    <Link
                      style={{
                        color: "#009be1",
                        textTransform: "initial",
                        marginLeft: "3px",
                        cursor: "pointer",
                        textDecoration: "none"
                      }}>
                      <span
                        style={{
                          ...FAMILYFONTS.family1,
                          fontSize: 16,
                          fontWeight: 400
                        }}
                        data-testid='resendEmailOtp'
                        onClick={() => this.handleResendOtp()}
                        >
                        Resend code
                      </span>
                    </Link>
                  </Typography>
                </Box>
                <Button
                  data-testid="verifyBtnTest"
                  variant="contained"
                  color="primary"
                  className={classOuter.footerBtn}
                  fullWidth
                  onClick={this.handleOtpConfirmationApi}
                  style={{
                    opacity: disabled ? "0.5" : "1",
                    cursor: disabled ? "not-allowed" : "pointer",
                    ...FAMILYFONTS.family2,
                    fontSize: 20,
                    fontWeight: 400,
                  }}
                  disabled={disabled}>
                  Verify
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
