import React from "react";
// Customizable Area Start
import AdvancedSearchController, { Props } from "./AdvancedSearchController";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import SavedSearch from "./SavedSearch";
// Customizable Area End

class SavedSearchContainer extends AdvancedSearchController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	render() {
		// Customizable Area Start
		// Customizable Area End
		return (
			// Customizable Area Start
			<>
			{/* @ts-ignore  */}
				<MobileHeader
					navigatTo={"/"}
					data-testid="mobileSearchMainContainer"
					navigation={this.props.navigation}
					title={"Saved searches"}
					style={{fontWeight: "bold"}}
				/>
				{/* @ts-ignore  */}
				<SavedSearch navigation={this.props.navigation} id={""}  />
			</>
			// Customizable Area End
		)
	}
}


// Customizable Area Start
export {SavedSearchContainer}
export default  SavedSearchContainer
// Customizable Area End