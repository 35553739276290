   // Customizable Area Start
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles,Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
   // Customizable Area End

      // Customizable Area Start
const styles = (theme: { spacing: { unit: any; }; }) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});
   // Customizable Area End

function ErrorPage(props:any) {
     // Customizable Area Start
  const { classes,navigation } = props;

     // Customizable Area End
  return (
       // Customizable Area Start
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
    <Typography>Under Progress</Typography>
      <Button variant="contained"  onClick={() => navigation.goBack()} color="primary" className={classes.button}>
        Go Back
      </Button>
    </div>
       // Customizable Area End
  );
}

   // Customizable Area Start
ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired,
};
   // Customizable Area End
// @ts-ignore
export default withStyles(styles)(ErrorPage);