// Customizable Area Start
import * as tabActionTypes from "./tabActions"

const initialstate={
    selectedTab:JSON.parse(localStorage.getItem("selectedTab")as any ) || "Home"
}



const tabReducer = (state = initialstate , action: { type: any; payload: { selectedTab: any } }) => {
    switch(action.type){
        case tabActionTypes.SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.payload.selectedTab
            }
            default:
                return state
    }
}

export default tabReducer;
// Customizable Area End