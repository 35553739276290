import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import RestClientBlock from "../../../framework/src/Blocks/RestApiClientBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Alert, Platform, Linking, PermissionsAndroid } from "react-native";
import Geolocation from "react-native-geolocation-service";
import RNAndroidLocationEnabler from "react-native-android-location-enabler";
import { apiCall } from "../../../components/src/utils/utils.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onClose:any;
  onSuccessOpen:any;
  getFullAddress: any;
  latitude: number | null;
  longitude: number | null;
  address: {
    doorName: string;
    area: string;
    city: string;
    country: string;
    postcode: string;
  };
  loading: boolean;
  error: string | null;
  presentAddress:any
  fetchCurrentLocation: () => void;
  setCurrentLocation: any;
  closeModal:any;
  saved_post_code:string;
  saved_city: string;
  fullLocation: any;
  // Customizable Area End
}

interface S {
  allowAccess: boolean;
  gpsLocation: string;
  isSpinnerShowing: boolean;
  // Customizable Area Start
  street_name:any;
  street_nameError: any;
  area:any;
  city:any;
  country:any;
  post_code:any;
  location: any;
  locationsuccessmesg:any;
  locationError:boolean;
  isSubmitting:boolean;
  lat: any,
  lng: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LocationController extends BlockComponent<Props, S, SS> {
  getCityApiCallId: any;
  updateDefaultCityApiCallId: any;
  googleMapAPIId: any;
  createLocationapicallId:any;
  // Customizable Area Start
  googleMapsScript: any = null;
  changeLocationAPICallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      allowAccess: true,
      gpsLocation: "",
      isSpinnerShowing: false,
      street_name:"",
      street_nameError: '',
      area:"",
      city:"",
      country:"",
      post_code:"",
      location: "",
      locationsuccessmesg:"",
      locationError: false,
      isSubmitting: false,
      lat: null,
      lng: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.createLocationapicallId) {
        if(responseJson?.data !== null && !responseJson.errors){
          this.setState({locationsuccessmesg:"location added successfully"})
          this.props.onSuccessOpen()
        }
        if (responseJson.errors) {
          this.setState({locationsuccessmesg: responseJson.errors[0].token})
        }
      }
      else if (apiRequestCallId === this.changeLocationAPICallId) {
        if(responseJson.message){
          this.props.onSuccessOpen(this.state.location);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // this is reducx location key load Start 
  async componentDidMount() {
    let postcode = localStorage.getItem('post_code')
    let city = localStorage.getItem('city') 
    this.setState({
      city: this.props.saved_city !== 'null' ? this.props.saved_city : "",
      post_code: this.props.saved_post_code !== 'null' ? this.props.saved_post_code : "",
      location: this.props.fullLocation
    })
  }

  async  componentWillUnmount() {
    if (this.googleMapsScript) {
      this.googleMapsScript.removeEventListener('load', this.handleScriptLoad);
      document.head.removeChild(this.googleMapsScript);
    }
  }

  handleScriptLoad = () => {
    this.props.fetchCurrentLocation();
  };

  loadGoogleMapsScript = async () => {
    this.googleMapsScript = document.createElement('script');
    this.googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY`;
    this.googleMapsScript.defer = true;

    return new Promise<void>((resolve, reject) => {
      this.googleMapsScript.addEventListener('load', () => {
        this.handleScriptLoad();
        resolve();
      });
      this.googleMapsScript.addEventListener('error', reject);
      document.head.appendChild(this.googleMapsScript);
    });
  };

  // this is reducx location key load end 


  allowLoactionAccess = (value: boolean) => {
    this.setState({ allowAccess: value });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  hasLocationPermissionIOS = async () => {
    const openSetting = () => {
      Linking.openSettings().catch(() => {
        Alert.alert("Unable to open settings");
      });
    };
    const status = await Geolocation.requestAuthorization("whenInUse");

    if (status === "granted") {
      return true;
    }

    if (status === "denied") {
      Alert.alert("Location permission denied");
    }

    if (status === "disabled") {
      Alert.alert(
        `Turn on Location Services to allow VR Digital to determine your location.`,
        "",
        [
          { text: "Go to Settings", onPress: openSetting },
          { text: "Don't Use Location", onPress: () => {} },
        ]
      );
    }

    return false;
  };

  hasLocationPermission = async () => {
    if (Platform.OS === "web") {
      if (navigator.geolocation) {
        return true;
      } else {
        alert("Sorry Not available!");
        return false;
      }
    }

    if (Platform.OS === "ios") {
      const hasPermission = await this.hasLocationPermissionIOS();
      return hasPermission;
    }



    const hasPermission = await PermissionsAndroid.check(
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
    );

    if (hasPermission) {
      return true;
    }

    const status = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
    );

    if (status === PermissionsAndroid.RESULTS.GRANTED) {
      return true;
    }

    if (status === PermissionsAndroid.RESULTS.DENIED) {
      console.log("denied");
      this.allowLoactionAccess(false);
    } else if (status === PermissionsAndroid.RESULTS.NEVER_ASK_AGAIN) {
      console.log("never ask");
      this.allowLoactionAccess(false);
    }

    return false;
  };

  checkGPS = () => {
    RNAndroidLocationEnabler.promptForEnableLocationIfNeeded({
      interval: 10000,
      fastInterval: 5000,
    })
      .then((data: any) => {
        console.log(data, "USER_ALLOA");
        this.getLocation();
        // The user has accepted to enable the location services
        // data can be :
        //  - "already-enabled" if the location services has been already enabled
        //  - "enabled" if user has clicked on OK button in the popup
      })
      .catch((err: any) => {
        console.log(err, "USER_NO_ALLOW");
        this.allowLoactionAccess(false);
        // The user has not accepted to enable the location services or something went wrong during the process
        // "err" : { "code" : "ERR00|ERR01|ERR02|ERR03", "message" : "message"}
        // codes :
        //  - ERR00 : The user has clicked on Cancel button in the popup
        //  - ERR01 : If the Settings change are unavailable
        //  - ERR02 : If the popup has failed to open
        //  - ERR03 : Internal error
      });
  };

  callGetLocationAPI = (url: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.googleMapAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAddressDetails = (results: any) =>  {
    const addressComponents = {
      postcode: null,
      city: null,
      country: null,
      area: null,
      street_name: null,
    };

    console.log('results', results)
    
    for (const component of results) {
      if (component.types.includes('postal_code')) {
        addressComponents.postcode = component.long_name;
      } else if (component.types.includes('locality')) {
        addressComponents.city = component.long_name;
      } else if (component.types.includes('country')) {
        addressComponents.country = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        addressComponents.area = component.long_name;
      } else if (component.types.includes('route')) {
        addressComponents.street_name = component.long_name;
      }
    }
    return addressComponents
  }

  getLatLong(placeId: any) {
    this.setState({ post_code: "", city: "" })
    geocodeByAddress(placeId)
      .then(results => {
        getLatLng(results[0]).then(({lat, lng}) => {
          const {postcode, city, country, area, street_name} = this.getAddressDetails(results[0].address_components)
          console.log('postcode', postcode)
          this.setState({ lat, lng, post_code: postcode, city, country, area, street_name, isSubmitting: false })
        })
      })
  }

  changeLocation = async() => {
    const header = {
      token: localStorage.getItem("token"),
      type: "user_entity",
      "Content-Type": "application/json",
    };
    const httpBody ={
      "saved_location": this.state.location,
      "city": this.state.city,
      "street_name": this.state.street_name,
      "saved_post_code": this.state.post_code
    }
    this.changeLocationAPICallId = await apiCall({
      header: header,
      endPoint: `/change_location`,
      method: "POST",
      body: JSON.stringify(httpBody)
    });
  }

  getLocation = async () => {
    this.setState({ post_code: "", city: "" })
    const hasLocationPermission = await this.hasLocationPermission();

    const googleAPIkey = "AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY";
    this.setState({ isSpinnerShowing: true, locationError: false, isSubmitting: false, post_code: "" });

    if (!hasLocationPermission) {
      this.setState({ isSpinnerShowing: false });
      this.allowLoactionAccess(false);
    }

    Geolocation.getCurrentPosition(
      async (position: any) => {
        const {latitude,longitude} = position.coords;
        
        const googleMapApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleAPIkey}`;
        fetch(googleMapApi).then(res=>res.json()).then((data)=>
        {
          const { results } = data;
          const address = results[0]?.address_components
          const { postcode, city } = this.getAddressDetails(results[0].address_components)
          this.setState({
            street_name:`${address[0]?.long_name}, ${address[1]?.long_name}`,
            area:`${address[2]?.long_name}, ${address[3]?.long_name}`,
            city:city,
            post_code:postcode,
            country:address[8]?.long_name,
            lat:latitude,
            lng:longitude,
            location:results[0].formatted_address,
          });
        }
        )
      },
      (error: any) => {
        this.setState({ isSpinnerShowing: false });
      },
      {
        enableHighAccuracy: false,
        timeout: 15000,
        distanceFilter: 0,
        forceRequestLocation: true,
        showLocationDialog: false,
      }
    );
  };

  handleFormValidation = () => {
    const city = this.state.city || ''; 
    const postCode = this.state.post_code || ''; 
  
    const isValid =
      city.trim() !== '' ||
      postCode.trim() !== '';
  
    this.setState({ locationError: !isValid, isSubmitting: true });
  
    return isValid;
  };

  onSubmitLocation = (e: any) => {
    e.preventDefault();
    this.setState({isSubmitting: true})
    if (this.handleFormValidation() === true) {
      const token = localStorage.getItem("token")
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };
      
      const attrs = {
        area: this.state.area,
        city: this.state.city || '',
        country: this.state.country,
        post_code: this.state.post_code,
        street_name: this.state.street_name,
        account_id: localStorage.getItem("acc_id"),
        token: token,
        lat: this.state.lat,
        lng: this.state.lng,
        location: this.state.location,
      };

      const addressParts:any[] = [];
      if (this.state.street_name) {
        addressParts.push(this.state.street_name);
      }
      if (this.state.city) {
        addressParts.push(this.state.city);
        localStorage.setItem("city", this.state.city)
      }

      if (this.state.post_code) {
        addressParts.push(this.state.post_code);
        localStorage.setItem("post_code", this.state.post_code)  
      } else {
        localStorage.setItem("post_code", "")  
      }

      localStorage.setItem("current_location", addressParts.join(', '))

      this.changeLocation()
      this.props.getFullAddress(attrs)

    }
  }
  // Customizable Area End
}
