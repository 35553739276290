import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id?: string;
  navigation: any;
  openModal?:any;
  onSubmit?:any;
  friends?:any;
  publish?:string;
  classes?:any;
  theme?:any;
  onCloseModal?:any;
}

export interface S {
  // Customizable Area Start
  id: string;
  type: string;
  titleOfNeed: string;
  location: string;
  describeNeeds: string;
  additionalInformation: string;
  fromDate: any;
  toDate: any;
  startTime: any;
  finishTime: any;
  image: any;
  isFlexible: boolean;
  isAllDay: boolean;
  pickerOpen: string;
  selectedImage: any;
  infoWords: number;
  needsWords: number;
  dropdownOpen: boolean;
  typeLab: any;
  isEdit: boolean;
  isModalOpen: boolean;
  latitude: any;
  longitude: any;
  friends: any;
  publish: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PublishedNeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  publishNeedApiCallId: string = "";
  getPublishNeedApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: "",
      type: "",
      titleOfNeed: "",
      location: "",
      describeNeeds: "",
      additionalInformation: "",
      fromDate: null,
      toDate: null,
      startTime: null,
      finishTime: null,
      image: null,
      isFlexible: false,
      isAllDay: false,
      pickerOpen: "",
      selectedImage: null,
      infoWords: 0,
      needsWords: 0,
      dropdownOpen: false,
      typeLab: "",
      isEdit: false,
      isModalOpen: false,
      latitude: null,
      longitude: null,
      friends: [],
      publish: "",
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (responseJson.errors?.length > 0) {
          toast.error(`${responseJson.errors[0].token}`);
        } else if (apiRequestCallId === this.getPublishNeedApiCallId) {
          this.handleInfoApiResponse(responseJson);
        } else if (apiRequestCallId === this.publishNeedApiCallId) {
          this.props.navigation.navigate("MyNeed");
        }
      }
    }
  }

  async componentDidMount() {
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.setState({ id },
        () => this.getPublishNeedInfo()
      );
    }
  }

  componentDidUpdate() {
    if (this.state.isEdit) {
      this.setState({ isEdit: false });
    }
  }

  handleInfoApiResponse = (responseJson: any) => {
    const startDate = new Date(responseJson.data.attributes.start_date);
    const endDate = new Date(responseJson.data.attributes.end_date);
    this.setState({
      type: responseJson.data.attributes.need_type,
      titleOfNeed: responseJson.data.attributes.title_of_need,
      location: responseJson.data.attributes.location,
      latitude: responseJson.data.attributes.latitude,
      longitude: responseJson.data.attributes.longitude,
      describeNeeds: responseJson.data.attributes.description,
      additionalInformation: responseJson.data.attributes.additional_information,
      startTime: moment(responseJson.data.attributes.start_time),
      finishTime: moment(responseJson.data.attributes.end_time),
      isAllDay: responseJson.data.attributes.all_day,
      isFlexible: responseJson.data.attributes.flexible,
      image: {
        url: responseJson.data.attributes.need_photo.url,
        type: "image/jpg",
        isEdit: true
      },
      selectedImage: responseJson.data.attributes.need_photo.url,
      infoWords: responseJson.data.attributes.additional_information?.split(" ").length ?? 0,
      needsWords: responseJson.data.attributes.description?.split(" ").length,
      publish: responseJson.data.attributes.publish,
      friends: responseJson.data.attributes.friend_ids,
      isEdit: true,
      fromDate: startDate,
      toDate: endDate
    });
  };

  validateTime = (isSameDate: boolean) => {
    let startTime = this.state.startTime;
    if (isSameDate && this.state.startTime?.isBefore(moment())) {
      startTime = new Date(Date.now());
      this.setState({ startTime: startTime });
    }
    if (isSameDate && this.state.finishTime?.isBefore(startTime)) {
      this.setState({ finishTime: moment("00:00:00", 'HH:mm:ss') });
    }
  }

  onStartTimeChange = (time: any) => {
    let startTime = time;
    const isSameDate = moment(this.state.fromDate)?.isSame(moment(), "day") && moment(this.state.toDate)?.isSame(moment(), "day");
    if (isSameDate && time.isBefore(moment())) {
      startTime = moment();
    }
    if (isSameDate && this.state.finishTime?.isBefore(startTime)) {
      this.setState({ finishTime: time });
    }
    this.setState({ startTime });
  }

  onFinishTimeChange = (time: any) => {
      const isSameDate = moment(this.state.fromDate)?.isSame(moment(), "day") && moment(this.state.toDate)?.isSame(moment(), "day");
      if (isSameDate && time.isBefore(this.state.startTime)) {
        this.setState({ finishTime: moment("00:00:00", 'HH:mm:ss') });
      } else {
        this.setState({ finishTime: time });
      }
  }

  getPublishNeedInfo = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: localStorage.getItem("token")
    };

    let url = `${configJSON.publishNeedApiEndPoint}`;
    if (this.state.id) {
      url += `/${this.state.id}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPublishNeedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  submitNeed = (publish: string, friend_ids: any) => {
    let url = `${configJSON.publishNeedApiEndPoint}`;
    if (this.state.id) {
      url += `/${this.state.id}`;
    }

    const formData = new FormData();
    formData.append("need[title_of_need]", this.state.titleOfNeed);
    formData.append("need[need_type]", this.state.type);
    formData.append("need[description]", this.state.describeNeeds);
    formData.append("need[additional_information]", this.state.additionalInformation);
    formData.append("need[start_date]", moment(this.state.fromDate).format("DD-MM-YYYY"));
    formData.append("need[end_date]", moment(this.state.toDate).format("DD-MM-YYYY"));
    if (this.state.isAllDay) {
      formData.append("need[start_time]", "3:30");
      formData.append("need[end_time]", "11:30");
    } else {
      formData.append("need[start_time]", this.state.startTime);
      formData.append("need[end_time]", this.state.finishTime);
    }
    formData.append("need[flexible]", this.state.isFlexible.toString());
    formData.append("need[location]", this.state.location);
    formData.append("need[latitude]", this.state.latitude);
    formData.append("need[longitude]", this.state.longitude);
    formData.append("need[all_day]", this.state.isAllDay.toString());
    if (!this.state.image?.isEdit) {
      formData.append("need[need_photo]", this.state.image);
    }
    formData.append("need[account_id]", localStorage.getItem("acc_id") as string);
    formData.append("token", localStorage.getItem("token") as string);
    formData.append("need[publish]", publish);
    if (publish === "friends") {
      formData.append("[connection_ids]", friend_ids);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.publishNeedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.id ? configJSON.putMethod : configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false })
  }
}

// Customizable Area End