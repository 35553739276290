import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// @ts-ignore
import { FAMILYFONTS, icons } from "../../../components/src/constants";
// @ts-ignore
import classOuter from "./MobileOtpVerification.module.css";
import OtpInput from 'react-otp-input';
import FormRegitrationController, {
    Props
  } from "./RegistrationFormController";
// Customizable Area End

class MobileOtpVerification extends FormRegitrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    
     // @ts-ignore
     let Contact = "";
    
    if(typeof window !== "undefined"){
      Contact = localStorage.getItem("Mobile") || ""
    }
    const otpLength = this.state.mobileOtp.length;
    const disabled = (otpLength === 6 && !this.state.otpError) ? false : true;
    // const temp_otp = JSON.parse((sessionStorage.getItem("np-otp-meta") as string))?.otp || null;
    const temp_otp = 123456
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        {/* OTP logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.otpLogoMainContainer}>
            <div className={classOuter.otpLogoContainer}>
              <img src={icons.phoneIcon} className={classOuter.otpLogo} />
            </div>
          </div>
          {/* Forgot Password OTP Form */}
          <div>
            {/* Form Title */}
            <Box>
              <Typography
                className={classOuter.titleText}
                style={{
                  ...FAMILYFONTS.family1,
                  fontSize: 24,
                  fontWeight: 400
                }}>Mobile No. Verification!</Typography>
            </Box>

            {/* Form Content */}
              <Box className={classOuter.otpContainer}>
                {/* OTP Message */}
                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 16,
                    fontWeight: 400,
                    textAlign: "left"
                  }}>
                  Please enter the verification code sent to your registered mobile number {Contact}
                </Typography>

                {/* OTP Input Field */}
                <Box className="otpInput"
                  style={{ color: this.state.otpError ? "#E41616" : "inherit", }}
                >
                  <OtpInput
                    data-testid="setMobileOtp"
                    // @ts-ignore
                    name="otpInput"
                    // @ts-ignore
                    value={this.state.mobileOtp}
                    onChange={(e: any) => this.setOtp(e)}
                    numInputs={6}
                    className={this.state.otpError ? classOuter.innerOtpErrorText : classOuter.innerOtpText}
                    separator={<span />}
                    isInputNum
                  />
                  <Box className={classOuter.errorMsg} style={{marginTop: '8px'}}>
                    <div className={classOuter.errors}>{this.state.mobileOtpError}</div>
                  </Box>
                </Box>
                
              {/* Resend link and OTP Verify Button */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
                className={classOuter.footerLinkContainer}
              >
                <Box 
                // onClick={this.resendOTP}
                >
                  <Typography className={classOuter.footerLinkText}>
                    <span
                      style={{
                        ...FAMILYFONTS.family3,
                        fontSize: 16,
                        fontWeight: 400
                      }}>
                      Didn't receive a verification code?
                    </span>
                    <Link
                      style={{
                        color: "#009be1",
                        textTransform: "initial",
                        marginLeft: "3px",
                        cursor: "pointer",
                        textDecoration: "none"
                      }}>
                      <span
                        style={{
                          ...FAMILYFONTS.family1,
                          fontSize: 16,
                          fontWeight: 400
                        }}
                        data-testid="resendLinkTesting"
                        onClick={() => this.handleMobileResendLink()}
                        >Resend code
                      </span>
                    </Link>
                  </Typography>
                </Box>
                <Button
                  data-testid="verifyButtonTest"
                  variant="contained"
                  color="primary"
                  className={classOuter.footerBtn}
                  fullWidth
                  onClick={this.handleMobileOtpVerify}
                  style={{
                    opacity: disabled ? "0.5" : "1",
                    cursor: disabled ? "not-allowed" : "pointer",
                    ...FAMILYFONTS.family2,
                    fontSize: 20,
                    fontWeight: 400,
                  }}
                  disabled={disabled}>
                  Verify
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default MobileOtpVerification;
export {MobileOtpVerification};
// Customizable Area End
