import { combineReducers } from 'redux';
import { ADD_PERSONAL_INFO } from '../types';


const initInformation = {
    _personal_info:[]
}

function personalInfo(state = initInformation,action: { type: any; payload: string | number; }){
    switch(action.type){
        case  ADD_PERSONAL_INFO: 
            return{
                ...state,
                _personal_info:action.payload
            }    
        default:
            return state;
    }
}

export default personalInfo;