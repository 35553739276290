import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// @ts-ignore
import classOuter from "./LoginBusiness.module.css";
import { setSelectedAdminTab } from "../../../framework/src/redux/adminTab/adminTabActions";
import { connect } from "react-redux";
import { FAMILYFONTS, images } from "../../../components/src/constants";
import { Link } from "react-router-dom";
import LoginFormController, {
  Props,
} from "./LoginFormController";
// Customizable Area End

export class LoginBusiness extends LoginFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const validInputs = !this.state.email || !this.state.password;
    const validError = this.state.emailPasswordValidation || this.state.emailValidation;
    const disabled = validInputs || validError;
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        {/* App logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.appLogoContainer}>
            <img src={images.app_logo} className={classOuter.appLogo} />
          </div>
          {/* Sign-in Form */}
          <div>
            {/* Form Title */}
            <Box>
              <Typography
                className={classOuter.titleText}
                style={{
                  ...FAMILYFONTS.family1,
                  fontSize: 24,
                  fontWeight: 400,
                }}
              >
                Sign In ( Business )
              </Typography>
            </Box>
            {/* Form Content */}
            <Box>
              {/* Email Input Field */}
              <Box>
                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Email Address
                </Typography>
                <Input
                  data-testid="email_address_test"
                  placeholder={"Enter email address"}
                  className={classOuter.inputField}
                  fullWidth={true}
                  name="emailID"
                  disableUnderline={true}
                  value={this.state.email}
                  classes={{
                    root: classOuter.innerInputText,
                  }}
                  onChange={(e) => {
                    this.setEmail(e.target.value);
                    this.validateEmail(e.target.value);
                  }}
                />
                {/* Email validation error */}
                <Box className={classOuter.errorMsg}>
                  <div className={classOuter.errors}>
                    {this.state.emailError}
                  </div>
                </Box>
              </Box>
              {/* Password Input Field */}
              <Box>
                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Password
                </Typography>
                <Input
                  data-testid="txtInputPassword"
                  type={
                    this.state.enablePasswordField ? "password" : "text"
                  }
                  placeholder={"Enter password"}
                  disableUnderline={true}
                  className={classOuter.inputField}
                  fullWidth={true}
                  value={this.state.password}
                  classes={{
                    root: classOuter.innerInputText,
                  }}
                  onChange={(e) => {
                    this.setPassword(e.target.value);
                    this.validatePassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        data-test-id={"btneyeShowHide"}
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                        className={classOuter.pwdHideShowIcon}
                      >
                        {this.state.enablePasswordField ? (
                          <img src={images.eyeCloseIcon} alt="passwordVisible" />
                        ) : (
                          <img
                            src={images.eyeOpenIcon}
                            alt="passwordInVisible"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {/* Password validation error */}
                <Box className={classOuter.errorMsg}>
                  <div className={classOuter.errors}>
                    {this.state.passwordError}
                  </div>
                </Box>
              </Box>
              {/* Forgot Password Link */}
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Link
                  className={classOuter.linkText}
                  style={{
                    ...FAMILYFONTS.family1,
                    fontSize: 14,
                    fontWeight: 400,
                    textDecoration: "none",
                    textDecorationColor: "#359ce2",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    marginLeft: "3px",
                  }}
                  data-test-id={"btnForgotPassword"}
                  //to={"NpForgotPassword"}
                  to={"ForgotPasswordBusiness"}
               //onClick = {this.props.navigation.navigate("RegistrationFormBusiness")}
                >
                  Forgot Password?
                </Link>
              </Box>
              {/* Sign in Button */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <Button
                  data-testid={"btnEmailLogIn"}
                  variant="contained"
                  color="primary"
                  className={classOuter.footerBtn}
                  fullWidth
                  style={{
                    ...FAMILYFONTS.family2,
                    fontSize: 20,
                    fontWeight: 400,
                    opacity: disabled ? "0.5" : "1",
                    cursor: disabled ? "not-allowed" : "pointer",
                    textTransform: "capitalize",
                  }}
                  disabled={!this.state.email || !this.state.password}
                  // onClick={() => this.gotoSignIn()}
                  onClick={
                    () => this.handleSignIn()
                  }
                >
                  Sign In
                </Button>
              </Box>
              {/* Register Link */}
              <Box>
                <Typography className={classOuter.footerLinkText}>
                  <span
                    style={{
                      ...FAMILYFONTS.family3,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    Don't have an account?
                  </span>
                  <Link
                    data-test-id={"btnNavigateToSignUp"}
                    type="submit"
                    className={classOuter.footerLink}
                    style={{
                      textDecoration: "underline",
                      textDecorationColor: "#359ce2",
                      textTransform: "initial",
                      cursor: "pointer",
                      marginLeft: "3px",
                    }}
                    // to={"/AccountCreation"}
                    to="/RegistrationFormBusiness"
                  >
                    <span
                      style={{
                        ...FAMILYFONTS.family1,
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      Register now
                    </span>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export function mapStateToProps(state: any) {
  return {
    selectedTab: state.tabReducer?.selectedTab,

  }
}

export function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedAdminTab: (selectedTab: any) => {
      return dispatch(setSelectedAdminTab(selectedTab))
    },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginBusiness)

// Customizable Area End
