// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  history: any;
  match: any;
  location: any;
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  planId: string;
  planTitle: string;
  planAmount: number;
  discountAmount: number;
  discountPoint: number;
  payable: number;
  planType: string;
  days: number;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PaymentOptionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fetchStripePaymentIntentApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      loading: false,
      planId: "",
      planTitle: "",
      planAmount: 0,
      discountAmount: 0,
      discountPoint: 0,
      payable: 0,
      days: 0,
      planType: "",
      // Customizable Area End
    };
  }

  async componentDidMount() {
    const obj = this.props.location.state;
    if (obj) {
      const payable = obj.planAmount - obj.discountAmount;
      this.setState({
        days: obj.days,
        planId: obj.planId,
        planType: obj.planType,
        planTitle: obj.planTitle,
        planAmount: obj.planAmount,
        discountAmount: obj.discountAmount,
        discountPoint: obj.discountPoints,
        payable: payable > 0 ? payable : 0,
      });
    }
  }
}
// Customizable Area End