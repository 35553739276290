import React from "react";

// Customizable Area Start
import { Box, CircularProgress, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Divider } from '@material-ui/core';
import {
  icons,
  FONTS,
  FAMILYFONTS, SIZES
} from "../../../components/src/constants";
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Redirect } from "react-router-dom";
import { COLORS } from "../../../framework/src/Globals";
//@ts-ignore
import classesOuter from "./Notifications.module.css";
//@ts-ignore
import ReactReadMoreReadLess from "react-read-more-read-less";
import { styled } from "@material-ui/styles";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

 class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onClick = (index: number) => {
    const data = [...this.state.events];
    data[index] = {
      ...data[index],
      isMore: !data[index].isMore
    }
    this.setState({ events: data });
  }

  readMore = (item:any,index:number) => {
    return (
      <span data-testId="read-more" onClick={()=>this.onClick(index)} className="see-msg">{item.isMore ? "Click to see more...":"Click to see less..."}</span>
    );
  }

   renderEventNotification = () => {
     return (
       <Box margin="0px 15px" marginRight="10px">
         <div className="title">{this.props.type==="non_profit_entity"?"Events":"Following"}</div>
         {this.state.events.map((item: any, index: number) => {
           return (
             <div className="list-item" key={`${index + 1}`}>
               <div data-testId="message" className={`message ${this.props.type !== "non_profit_entity" && "cursor"}`} onClick={() => this.surveyClick(item.isSurvey)}>{item.isMore ? item.message.slice(0, 45) + "..." : item.message}</div>
               <div className="right">
                  <Box display="flex" alignItems="center">
                    {item.message.length > 45 && this.readMore(item, index)}
                    <span
                      className="see-msg"
                      data-testId="delete-btn"
                      style={{ marginLeft: "10px", color: "red" }}
                      onClick={() => this.markAsRead(item.id, "events")}
                    >
                      <DeleteIcon/>
                    </span>
                  </Box>
                  <span className="date">{item.date}</span>
               </div>
             </div>
           )
         })
         }
       </Box>
     );
   }

   renderCommunicationNotification = (pathName: string) => {
     return (
       <>
         <div className="line" />
         <Box margin="0px 15px" marginRight="10px">
           <div className="title">Communication</div>
           {this.state.communication.map((item: any, index: number) => {
             return (
               <div className="list-item" key={`${index + 1}`}>
                 <div className="message">{item.message}</div>
                 <div className="right">
                   <Box display="flex" alignItems="center">
                      <span
                        data-testId="see-msg-btn"
                        className="see-msg"
                        onClick={() => {
                          this.showNotificationMsg(item.id, item.sid, pathName)
                        }}
                      >
                        Click to see message
                      </span>
                      <span
                        className="see-msg"
                        data-testId="delete-btn"
                        style={{ marginLeft: "10px", color: "red" }}
                        onClick={() => this.markAsRead(item.id, "communication")}
                      >
                        <DeleteIcon/>
                      </span>
                   </Box>
                   <span className="date">{item.date}</span>
                 </div>
               </div>
             )
           })
           }
         </Box>
       </>
     );
   }

   renderVolunteerNotification = () => {
     return (
       <>
         <div className="line" />
         <Box margin="0px 15px" marginRight="10px">
           <div className="title">Registered users requests</div>
           {this.state.registeredVolunteer.map((item: any, index: number) => {
             return (
               <div className="list-item" key={`${index + 1}`}>
                 <div className="message">{item.message}</div>
                 <div className="right">
                  <Box display="flex" alignItems="center">
                    <span 
                      className="see-msg"
                      data-testId="view-request" 
                      onClick={() => this.onViewClick(item.userId)}
                    >
                      Click to view detail
                    </span>
                    <span 
                      className="see-msg"
                      data-testId="delete-btn" 
                      style={{ marginLeft: "10px", color: "red" }}
                      onClick={() => this.markAsRead(item.id, "registeredVolunteer")}
                    >
                      <DeleteIcon/>
                    </span>
                  </Box>
                   <span className="date">{item.date}</span>
                 </div>
               </div>
             )
           })
           }
         </Box>
       </>
     );
   }

   renderR2Notification = () => {
     return (
       <>
          {this.state.loading && 
          <Box className="not-found">
            <CircularProgress className="loader" />
          </Box>
          }
          {this.state.events.length === 0 && this.state.communication.length === 0 && this.state.registeredVolunteer.length === 0 && !this.state.loading
            ? <Box className="not-found">Not available!</Box>
            :
            <>
              {this.state.events.length > 0 && this.renderEventNotification()}
              {this.state.communication.length > 0 && this.renderCommunicationNotification("Communication")}
              {this.state.registeredVolunteer.length > 0 && this.renderVolunteerNotification()}
           </>
         }
       </>
     );
   }

   renderR3Notification = () => {
    return (
      <>
       {this.state.loading && 
          <Box className="not-found">
            <CircularProgress className="loader" />
          </Box>
        }
        {this.state.events.length === 0 && this.state.communication.length === 0 && !this.state.loading
          ? <Box className="not-found">Not available!</Box>
          :
          <>
             {this.state.events.length > 0 && this.renderEventNotification()}
             {this.state.communication.length > 0 && this.renderCommunicationNotification("BusChat")}
          </>
        }
      </>
    );
  }

  getState = () => {
    return this.state.seeRequests ? 'filteredData' : 'data';
  }

  onRedirect = (item: any) => {
    if(item.attributes.is_survey){
      this.navigateToPath("SurveysDashboard");
    }
    if(item.attributes.push_notificable?.type !== "connection"){
      const obj = item.attributes.push_notificable;
      const event_type = obj?.event_type;
      if(obj?.need_id || obj?.type === "need") {
        this.props.navigation.navigate("EventsDetails", { id: "r1-" + (obj?.need_id ?? obj?.id) });
      } else if (obj?.non_profit_event_id || obj?.type ==="non_profit_event" || obj?.type === "advanced_search_saved" || obj?.type === "availability") {
        const id = obj?.non_profit_event_id ?? obj?.id;
        if (event_type === "project_funding") {
          this.props.navigation.navigate("FundCampaign", { id: id });
        } else if (event_type === "need") {
          this.props.navigation.navigate("EventsDetails", { id: id });
        } else if (event_type === "activity") {
          this.props.navigation.navigate("EventInfo", { id: id });
        }
      }
    }
    if(["EventChatRoom", "IndividualChatRoom"].includes(item.attributes?.notify_type)){
      this.showNotificationMsg(item.id, item.attributes?.sid, "GroupChat");
    }
  }

  getTitle = (val: any) => {
    return val.attributes.push_notificable?.type === "broadcast" ? val.attributes?.sender_details?.name : val.attributes.remarks;
  }

  getCursorPointer = (val: any) => {
   return !["connection", "broadcast"].includes(val.attributes.push_notificable?.type) ? "pointer" : "normal";
  }

  getMsgImage = (val: any) => {
    const validTypes = new Set(["connection", "broadcast"]);
    const validNotifyTypes = new Set(["EventChatRoom", "IndividualChatRoom"]);
    if(validTypes.has(val.attributes.push_notificable?.type) || validNotifyTypes.has(val.attributes?.notify_type)) {
      return val.attributes?.sender_details?.image?.url;
    } else {
      return val.attributes?.push_notificable?.event_image?.url;
    }
  }

  renderR1Notification = () => {
    return(
      <div>
        <Divider className={classesOuter.divide} />
        <Grid container className={classesOuter.notifiTitle}>
          <div style={{ ...FAMILYFONTS.family1 }}><h2>{!this.state.seeRequests ? "All notifications" : "Connection request"}</h2></div>
          <div>
            <Button variant="text" className={classesOuter.leftBtn} style={{ justifyContent: 'flex-end', padding: "3px 5px", minWidth: "0px" }} onClick={this.connectionRequest}>
              <img src={icons.chevronleft} className={classesOuter.leftIconImg} />
            </Button>
          </div>
        </Grid>
        <List className="notification-list">
          {this.state.loading && 
            <Box className="not-found">
              <CircularProgress className="loader"/>
            </Box>
          }
          {this.state[this.getState()].length === 0 && !this.state.loading
            ? <Box className="not-found r1">Not available!</Box>
            : this.state[this.getState()].map((val: any, index: any) => {
            return (
              <ListItem key={`${index + 1}`} data-testId={`notificationClick-${index}`} alignItems="flex-start" className={classesOuter.itemList}  
                onClick={()=> this.onRedirect(val)} 
                style={{
                  cursor: this.getCursorPointer(val)
                }}
              >
                <ListItemAvatar>
                  <Avatar alt={val.title} src={this.getMsgImage(val)} variant="circular" />
                </ListItemAvatar>
                <ListItemText className={classesOuter.listtitle}>
                  <span>{this.getTitle(val)}</span>
                  <div>
                    <div className={classesOuter.overflowPara} style={{ ...FAMILYFONTS.family3 }}>
                      {val.attributes.push_notificable?.type === "connection" && val.attributes.push_notificable?.status === "pending" && (
                        <div className={classesOuter.connectionBtn} style={{ ...FAMILYFONTS.family3, fontSize: 14 }}>
                          <Button data-testId={`accept_connection`} className={classesOuter.acceptBtn} onClick={() => {
                            this.acceptConnection(val.attributes.push_notificable?.account_id)
                            this.markAsRead(val.id, "r1")
                          }}>Accept</Button>
                          <Button className={classesOuter.cancelBtn} data-testId={`cancel_connection`} onClick={() => {
                            this.cancelConnection(val.attributes.push_notificable?.account_id)
                            this.markAsRead(val.id, "r1")
                          }} >Reject</Button>
                        </div>
                      )}
                      {(val.attributes.push_notificable?.description || val.attributes.push_notificable?.type === "broadcast")&& (
                        <span data-testId="description" className={`${this.state.activeClass.find((d: any) => (d - 1) === index) ? classesOuter.moreDetail : classesOuter.lessDetail} see-more-btn`}
                          onClick={() => { this.showNotificationEvent(val.attributes.push_notificable?.type, val.attributes.push_notificable?.id, val.id) }}
                        >
                          <ReactReadMoreReadLess
                            charLimit={50}
                            readMoreText={"Click here..."}
                            readLessText={"Click less..."}
                            readMoreStyle={{
                              color: "rgb(0, 187, 242)",
                              cursor: "pointer"
                            }}
                            readLessStyle={{
                              color: "rgb(0, 187, 242)",
                              cursor: "pointer"
                            }}
                          >
                            {val.attributes.push_notificable?.description ?? val.attributes?.remarks}
                          </ReactReadMoreReadLess>
                        </span>
                      )}
                      {["EventChatRoom", "IndividualChatRoom"].includes(val.attributes?.notify_type) && (
                        <Button data-testId="see-chat">Tap to see message</Button>
                      )}
                      {val.attributes.push_notificable?.type === "connection" && val.attributes.push_notificable?.status === "approved" && (
                        <Button onClick={() => this.showNotificationConnection(val.id)}>Tap to see connections</Button>
                      )}
                    </div>
                      <span className={classesOuter.notifiDate} style={{ display: 'block', ...FAMILYFONTS.family3, fontSize: SIZES.h4, marginBottom: '2px',color: "rgba(0, 0, 0, 0.54)" }}>{this.formatDate(val.attributes.updated_at)}</span>
                  </div>
                </ListItemText>
                <DeleteIcon
                  data-testId="delete-msg"
                  style={{ cursor: "pointer", color: "red" }} 
                  onClick={(e) => {
                    e.stopPropagation();
                    this.markAsRead(val.id, "r1")
                  }}
                 />
              </ListItem>
            )
          })
          }
        </List>
      </div>
    );
  }

  SearchList = () => {
    return (
      <>
        <div className={classesOuter.noti} style={{ margin: "15px" }}>
          <div />
          <div style={{ marginRight: "10px" }} data-testId="back-btn" className={classesOuter.back} onClick={() => { this.props.notificationmodalState(); }}>
            <img src={icons.back} style={{ height: 20, width: 20, cursor: "pointer" }} alt="Icon" />
          </div>
          <div><Typography style={{ ...FAMILYFONTS.family2, fontSize: 18 }}>Notifications</Typography></div>
          <div data-testId="cancel-btn" onClick={() => { this.props.notificationmodalState() }}>
            <img src={icons.cancelIcon} style={{ height: 28, width: 28, cursor: "pointer" }} />
          </div>
        </div>
        <div className="scrollbar">
        {this.props.type === "user_entity" && this.renderR1Notification()}
        {this.props.type === "business_entity" && this.renderR3Notification()}
        {this.props.type === "non_profit_entity" && this.renderR2Notification()}
        </div>
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper>
             {this.state.eventType && <Redirect to={`${this.state.eventType}`} />}
        <div className={classesOuter.Mobwrapper}>
          {this.SearchList()}
        </div>
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  "& .cursor": {
    cursor: "pointer"
  },
  "& .not-found": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    marginTop: "45px",
    marginBottom: "50px",
    "&.r1": {
      marginTop: "25px",
      marginBottom: "65px",
    },
    "& .loader": {
      width: "25px !important",
      height: "25px !important",
      color: "rgb(23, 158, 219)",
    }
  },
  "& .title": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    color: "rgb(0, 187, 242)",
    marginLeft: "15px",
    fontSize: "16px",
    marginTop: "15px",
    marginBottom: "15px"
  },
  "& .line": {
    border: "1px solid #ede2e2"
  },
  "& .list-item": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    paddingBottom: "20px"
  },
  "& .message": {
    marginLeft: "14px",
    marginRight: "10px",
    fontSize: "14px",
    fontFamily: "Rene_Bieder_Milliard_SemiBold"
  },
  "& .not-available": {
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center"
  },
  "& .right": {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    margin: "0px 10px 0px 5px",
  },
  "& .see-msg": {
    fontSize: "14px",
    whiteSpace: "nowrap",
    color: "rgb(0, 187, 242)",
    cursor: "pointer",
    fontFamily: "Rene_Bieder_Milliard_SemiBold"
  },
  "& .date": {
    color: "rgb(169, 169, 169)",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: "12px",
    marginTop: "10px"
  },
  "& .see-more-btn": {
    height: "unset",
    whiteSpace: "break-spaces"
  },
  "& .scrollbar": {
    maxHeight: "400px",
    overflow: "auto",
    marginRight: "5px",
    "@media(max-width: 600px)": {
      maxHeight: "100%",
    }
  },
  "& .scrollbar::-webkit-scrollbar": {
    display: "block !important",
  },
  "&. MuiTypography-body1": {
    fontSize: FONTS.h3.fontSize,
    fontFamily: FONTS.h3.fontFamily,
    color: COLORS.black,
  },
  "& button": {
    padding: '0',
    textTransform: 'none',
    fontWeight: 400,
    fontSize: SIZES.h3,
    color: COLORS.blue, 
  },
  "& .notification-list": {
    maxHeight: "425px",
    padding: '0px',
    "@media(max-width: 900px)": {
      maxHeight: "100%",
    }
  }
});

export default Notifications;
// Customizable Area End 
