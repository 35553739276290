// Customizable Area Start
import { ADD_EMAIL_OTP, ADD_MOBILE_OTP } from '../types';


const authotp = {
    __emailotp: "",
    __mobileotp: ""
}

function AuthOtpReducers(state = authotp, action: { type: any; payload: any; }) {
    switch (action.type) {
        case ADD_EMAIL_OTP:
            return {
                ...state,
                __emailotp: action.payload
            }
        case ADD_MOBILE_OTP:
            return {
                ...state,
                __mobileotp: action.payload
            }
        default:
            return state;
    }
}

export default AuthOtpReducers;
// Customizable Area End
