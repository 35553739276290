import { Account } from "../../types/account";
import { APIArrayData, TermsAndConditionsAttr } from "../../types/api";
import { DBSType } from "../../types/dbs";
import { TermsAndConditions } from "../../types/terms";
import { TaskPreference } from "../../types/task";
import { NPOrgAffiliation, INPOrganization } from "../../types/organization";
import { useBuilderMutation, useBuilderQuery } from "../../utils/react-query";
import { userKeys } from "../keys/user.keys";
import { userMessages } from "../messages/user.messages";
import { Availability } from "../../types/availability";

export function useUserSignup() {

  return useBuilderMutation<Parameters<typeof userMessages.signup>[0]>({
    messageGenerator: userMessages.signup,
    queryKey: userKeys.signup(),
  });
}

export function useGetEmailOTP() {

  return useBuilderMutation<Parameters<typeof userMessages.getEmailOTP>[0]>({
    messageGenerator: userMessages.getEmailOTP,
    queryKey: userKeys.getEmailOTP(),
  });
}

export function useVerifyEmailOTP() {

  return useBuilderMutation<Parameters<typeof userMessages.verifyEmailOTP>[0]>({
    messageGenerator: userMessages.verifyEmailOTP,
    queryKey: userKeys.verifyEmailOTP(),
  });
}

export function useGetMobileOTP() {

  return useBuilderMutation<Parameters<typeof userMessages.getMobileOTP>[0]>({
    messageGenerator: userMessages.getMobileOTP,
    queryKey: userKeys.getMobileOTP(),
  });
}

export function useVerifyMobileOTP() {

  return useBuilderMutation<Parameters<typeof userMessages.verifyMobileOTP>[0]>({
    messageGenerator: userMessages.verifyMobileOTP,
    queryKey: userKeys.verifyMobileOTP(),
  });
}

export function useUpdateUserBioAndAddress() {

  return useBuilderMutation<Parameters<typeof userMessages.updateBioAndAddress>[0]>({
    messageGenerator: userMessages.updateBioAndAddress,
    queryKey: userKeys.updateBioAndAddress(),
  });
}


export function useMySelectedDBS(params: Parameters<typeof userMessages.fetchMySelectedDBS>[0]) {

  return useBuilderQuery<APIArrayData<DBSType>>({
    message: userMessages.fetchMySelectedDBS(params),
    queryKey: userKeys.fetchMySelectedDBs(params),
  });
}

export function useDBSTypes() {

  return useBuilderQuery<APIArrayData<DBSType>>({
    message: userMessages.fetchDBSTypes(),
    queryKey: userKeys.fetchDBSTypes(),
  });
}


export function useUserProfile(params: Parameters<typeof userMessages.fetchProfile>[0]) {

  return useBuilderQuery<{ data: Account }>({
    message: userMessages.fetchProfile(params),
    queryKey: userKeys.fetchProfile(params),
  });
}


export function useSaveDbsTypes(params: Pick<Parameters<typeof userMessages.saveDBsTypes>[0], "accountId">) {

  return useBuilderMutation<Parameters<typeof userMessages.saveDBsTypes>[0]>({
    messageGenerator: userMessages.saveDBsTypes,
    queryKey: userKeys.saveDbsTypes({ accountId: params.accountId }),
  });
}


export function useNPOrganziation() {

  return useBuilderQuery<INPOrganization[]>({
    message: userMessages.fetchNPOrganizations(),
    queryKey: userKeys.fetchNPOrganizations(),
  });
}

export function useRegisterUserAsVolunteerToOrg() {

  return useBuilderMutation<Parameters<typeof userMessages.registerAsVolunteerToOrg>[0]>({
    messageGenerator: userMessages.registerAsVolunteerToOrg,
    queryKey: userKeys.registerAsVolunteerToOrg(),
  });
}

export function useAddWorkEmail() {

  return useBuilderMutation<Parameters<typeof userMessages.addWorkEmail>[0]>({
    messageGenerator: userMessages.addWorkEmail,
    queryKey: userKeys.addWorkEmail(),
  });
}


export function useAvailableTasksPreferences() {

  return useBuilderQuery<APIArrayData<TaskPreference>>({
    message: userMessages.fetchAvailableTasks(),
    queryKey: userKeys.fetchAvailableTasks(),
  });
}


export function useSaveTasksPreferences() {

  return useBuilderMutation<Parameters<typeof userMessages.saveTasksPreferences>[0]>({
    messageGenerator: userMessages.saveTasksPreferences,
    queryKey: userKeys.saveTasksPreferences(),
  });
}

export function useSetNotificationsSettings() {

  return useBuilderMutation<Parameters<typeof userMessages.setNotificationSettings>[0]>({
    messageGenerator: userMessages.setNotificationSettings,
    queryKey: userKeys.setNotificationsSettings(),
  });
}

export function useDayAvailabilities(params: Parameters<typeof userMessages.fetchAvailabilitesOfDay>[0]) {

  return useBuilderQuery<{ data: Availability[] }>({
    message: userMessages.fetchAvailabilitesOfDay(params),
    queryKey: userKeys.fetchAvailabilitiesOfDay({ date: params.date }),
  });
}

export function useSetAvailabilityForDay() {

  return useBuilderMutation<Parameters<typeof userMessages.setAvailabilityForDay>[0]>({
    messageGenerator: userMessages.setAvailabilityForDay,
    queryKey: userKeys.setAvailabilitiesForDay(),
  });
}

export function useAcceptTerms() {

  return useBuilderMutation<Parameters<typeof userMessages.acceptTerms>[0]>({
    messageGenerator: userMessages.acceptTerms,
    queryKey: userKeys.acceptTerms(),
  });
}


export function useMyTaskPreferences(params: Parameters<typeof userMessages.fetchMyTaskPreferences>[0]) {

  return useBuilderQuery<APIArrayData<TaskPreference>>({
    message: userMessages.fetchMyTaskPreferences(params),
    queryKey: userKeys.fetchMyTaskPreferences(params),
  });
}

export function useTermsAndConditions() {
  return useBuilderMutation<Parameters<typeof userMessages.fetchTermsAndConditions>[0]>({
    messageGenerator: userMessages.fetchTermsAndConditions,
    queryKey: userKeys.fetchTermsAndConditions(),
  });
}

export function useMyNonProfitOrgsAffiliations(params: Parameters<typeof userMessages.fetchMyNonProfitOrgsAffiliations>[0]) {

  return useBuilderQuery<NPOrgAffiliation[]>({
    message: userMessages.fetchMyNonProfitOrgsAffiliations(params),
    queryKey: userKeys.fetchMyNonProfitOrgsAffilliation(),
  });
}

export function useLogin(){

  return useBuilderMutation<Parameters<typeof userMessages.login>[0]>({
    messageGenerator: userMessages.login,
    queryKey: userKeys.login(),
  });
}
