import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Input,
  Button,
  Typography,
  InputAdornment,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// @ts-ignore
import { FAMILYFONTS, images } from "../../../components/src/constants";
// @ts-ignore
import classOuter from "./ResetPasswordBusiness.module.css";
import LoginFormController, {
  Props,
} from "./LoginFormController";
// Customizable Area End



export default class ResetPasswordBusiness extends LoginFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const validInputs = (!this.state.newPwd || !this.state.confirmPwd);
    const disabled = (validInputs || this.state.resetPasswordValidation);
    // Customizable Area End
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
      {/* App logo */}
      <div className={classOuter.formContainer}>
        <div className={classOuter.appLogoContainer}>
          <img src={images.app_logo} className={classOuter.appLogo} />
        </div>

        {/* Reset Password Form */}
        <div>

          {/* Form Title */}
          <Box>
            <Typography
              className={classOuter.titleText}
              style={{
                ...FAMILYFONTS.family1,
                fontSize: 24,
                fontWeight: 400
              }}>Reset Password</Typography>
          </Box>

          {/* Form Content */}
          <Box sx={{ width: "100%" }}>

            {/* New Password Input Field */}
            <Box>
              <Typography
                className={classOuter.inputFieldText}
                style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 14,
                  fontWeight: 400
                }}
              >
                New Password
              </Typography>
              <Input
                data-testid="txtInputNewPassword"
                name="newPasswordInput"
                type={
                  this.state.enableNewPwd ? "password" : "text"
                }
                placeholder={"Password"}
                disableUnderline={true}
                className={classOuter.inputField}
                fullWidth={true}
                value={this.state.newPwd}
                classes={{
                  root: classOuter.innerInputText
                }}
                onChange={(e) => {
                  this.setNewPassword(e.target.value);
                  this.validResetPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      data-test-id={"btneyeShowHide"}
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowNewPassword}
                      edge="end"
                      className={classOuter.pwdHideShowIcon}
                    >
                      {this.state.enableNewPwd ? (
                        <img
                          src={images.eyeCloseIcon}
                          alt="passwordVisible"
                        />
                      ) : (
                        <img
                          src={images.eyeOpenIcon}
                          alt="passwordInVisible"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>

            {/* Confirm Password Input Field */}
            <Box>
              <Typography
                className={classOuter.inputFieldText}
                style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 14,
                  fontWeight: 400
                }}
              >
                Confirm Password
              </Typography>
              <Input
                data-test-id="txtInputConfirmPassword"
                name="confirmPasswordInput"
                type={
                  this.state.enableConfirmPwd ? "password" : "text"
                }
                placeholder={"Confirm Password"}
                disableUnderline={true}
                className={classOuter.inputField}
                fullWidth={true}
                value={this.state.confirmPwd}
                classes={{
                  root: classOuter.innerInputText
                }}
                onChange={(e) => {
                  this.setConfirmPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      data-test-id={"btneyeShowHide"}
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowConfirmPassword}
                      edge="end"
                      className={classOuter.pwdHideShowIcon}
                    >
                      {this.state.enableConfirmPwd ? (
                        <img
                          src={images.eyeCloseIcon}
                          alt="passwordVisible"
                        />
                      ) : (
                        <img
                          src={images.eyeOpenIcon}
                          alt="passwordInVisible"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {/* Password Min. characters Message  */}
              <Typography
                variant="h6"
                component="h2"
                className={classOuter.inputMsg}
                style={{
                  ...FAMILYFONTS.family1,
                  fontSize: 14,
                  fontWeight: 400
                }}
              >
                (Min.8 Characters)
              </Typography>
              {/* Validation for both the passwords match or not*/}
              <Box className={classOuter.errorMsg}>
                <div className={classOuter.errors}>{this.state.matchPwdError}</div>
              </Box>
            </Box>
            <Box className={classOuter.errorMsg}>
              <div className={classOuter.errors}>{this.state.resetPwdError}</div>
            </Box>
            {/* Reset Password Button */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              <Button
                data-testid="ResetPasswordTest"
                variant="contained"
                color="primary"
                className={classOuter.footerBtn}
                fullWidth
                style={{
                  ...FAMILYFONTS.family2,
                  fontSize: 20,
                  fontWeight: 400,
                  opacity: disabled ? "0.5" : "1",
                  cursor: disabled ? "not-allowed" : "pointer",
                  textTransform: "capitalize",
                }}
                disabled={disabled}
                onClick={() => this.handleResetPasswordApi()}
              >
                Reset Password
              </Button>
            </Box>
          </Box>
        </div>
      </div>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
