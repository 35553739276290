import React from "react";
import {
  Box,
  TextField,
  Container,
  Button,
  Typography,
} from "@material-ui/core";
import "../assets/css/Settings5.css";
import { FONTS } from "../../../components/src/constants";
import Settings5Controller from "./Settings5Controller";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import ContactusSuccessModal from "../../../components/src/custom/ContactusSuccessModal";
export default class ContactUs extends Settings5Controller {
  render() {
    return (
      <Box>
        <div className="mob-header">
          <MobileHeader title="Contact Us" navigation={this.props.navigation} />
        </div>
        <Container
          maxWidth="lg"
          style={{ display: "table", textAlign: "center", width: '90%',marginRight:'6.5%' }}
        >
          <Box>
            <div>
              <Typography className="drop-message" style={{ ...FONTS.body1, marginTop: "3%",textAlign:'left',  }}>
                Drop us a message and we'll come back to you as soon as possible!'
              </Typography>
            </div>
            
            <div className="contact-msg-field">
              <TextField
                value={this.state.contactMsg}
                // {...this.state.contactMsg}
                onChange={(e) => {
                  this.setState({isSubmitting: false, contactMsg: e.target.value, contactMessageError: ""});
                }}
                onBlur={(e) => {
                  this.validateContactMessage(e.target.value)
                }}
                data-testId='contactUs'
                id="outlined-multiline-static"
                placeholder="What do you want to tell us about?"
                multiline
                rows={6}
                variant="outlined"
                fullWidth
              />
            </div>
          </Box>
          <Box className={'errorMsg'}>
            <div className={'errors'}>{this.state.contactMessageError}</div>
          </Box>
          <div style={{ display: "flex", justifyContent: "flexEnd", width: "88%" }}>
            <Button
              className="signInBtn1"
              onClick={this.contactUsSub}
              variant="contained"
              style={{
                textTransform: "capitalize",
                fontWeight: 600,
              }}
              disabled={this.state.isSubmitting}
            >
              Submit
            </Button>
          </div>
        </Container>
        <ContactusSuccessModal openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose}/>
      </Box>
    );
  }
}

