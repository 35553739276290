import React, { forwardRef } from "react";
// Customizable Area Start
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { DeleteEventModal } from "./DeleteEventModal.web";
import "react-datepicker/dist/react-datepicker.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  Card,
  Dialog,
  CardContent,
  Snackbar,
  SnackbarContent,
  Typography,
  TextField,
  Button,
  Input,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Theme,
  IconButton,
  Checkbox,
  Grid,
  withStyles,
  createStyles,
  Switch,
} from "@material-ui/core";
//   @ts-ignore
import styles from "./ContentManagementEvent.module.css";
import Select from "react-select";
import { FONTS, icons } from "../../../components/src/constants";
import { COLORS } from "../../../framework/src/Globals";
import ContentManagementController, { configJSON } from "./ContentManagementController";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import { ThemeProvider } from "@emotion/react";
import moment from "moment";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";

export const useStyles = (theme: Theme) =>
  createStyles({
    datepicker_dateList: {
      display: "flex",
      margin: "2 0 2 0",
    },

    datepicker_date_day_Item: {
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      width: 45,
      height: 45,
      flexShrink: 0,
      borderRadius: "50%",
      border: "2 solid #fdfbfb",
    },

    datepicker_button_previous_wrapper: {
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      margin: "0 0 0 12",
      width: "45",
      height: "45",
      flexShrink: 0,
      borderRadius: " 50%",
    },

    datepickerStrip: {
      display: "flex",
      flexDirection: "column",
      marginTop: 10,
      width: "max-content",
      maxWidth: "100%",
      overflow: "hidden",
      lineHeight: 1.5,
    },

    date_day_Item_selected: {
      width: 22,
      height: 22,
      borderRadius: "50%",
      backgroundColor: " #55bff0",
      color: "#ffff",
    },

    date_day_Item_selected_text: {
      color: "white",
    },

    date_day_item_disabled: {
      color: "rgb(229 229 229) !important",
      pointerEvents: "none",
    },

    datepicker_datelist_scrollable: {
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      display: "flex",
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        width: "0px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
    },

    hoursScroll: {
      overflow: "auto",
      marginLeft: 6,
      marginRight: 4,
    },

    datepicker: {
      display: "flex",
      alignItems: "center",
      position: "relative",
    },

    datepicker_day_label: {
      fontSize: 12,
      margin: "4px 0px 0px 0px",
      textAlign: "center",
    },

    scrollHead: {
      border: "1 solid #e9e9e9",
      borderRadius: 2,
      margin: "8 0 8 0",
      textAlign: "center",
      height: 18,
      fontSize: 12,
      background: " #e9e9e9",
    },
    blank_space_div: {
      border: "1 solid transparent",
      borderRadius: 2,
      margin: "8 0 8 0",
      textAlign: "center",
      height: 18,
      fontSize: 12,
    },

    datepicker_button_previous: {
      transform: "rotate(180deg)",
    },

    datepicker_month_label: {
      fontSize: 10,
      color: "#54b5e6",
      fontWeight: 500,
      marginLeft: -5,
    },
    datepicker_month_Icon_label: {
      fontSize: 10,
      color: "#54b5e6",
      fontWeight: 500,
    },

    datepicker_date_label: {
      fontSize: 10,
      lineHeight: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    wrapper: {
      position: "relative",
    },
    "@-webkit-keyframes ripple": {
      " 0%": {
        width: 0,
        height: 0,
        opacity: 0.9,
      },
      "100%": {
        width: "100%",
        height: "100%",
        opacity: 0,
      },
    },

    " @keyframes ripple": {
      "0% ": {
        width: 0,
        height: 0,
        opacity: 0.9,
      },
      "100%": {
        width: "100%",
        height: "100%",
        opacity: 0,
      },
    },
    "ripple:before": {
      content: "",
      position: "absolute",
      top: "50%",
      left: " 50%",
      width: 0,
      height: 0,
      "-webkit-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
      borderRadius: " 50%",
      backgroundColor: "currentColor",
      visibility: "hidden",
      zIndex: 2,
    },
    "ripple:not(:active):before": {
      "-webkit-animation: ripple": "0.4s cubic-bezier(0, 0, 0.2, 1)",
      "animation: ripple": "0.4s cubic-bezier(0, 0, 0.2, 1)",
      "transition: visibility": " .9s step-end",
    },
    "ripple:active:before": {
      visibility: "visible",
    },
    hourscontent: {
      flexGrow: 1,
      height: "100%",
      maxHeight: 420,
    },
    scrollbarInnerdiv: {
      width: 9,
      border: "1px solid #FFFFFF",
    },
    trackY: {
      width: "25px !important",
      background: "none !important",
    },
    thumbY: {
      backgroundColor: "#009BE2 !important",
      width: "24px !important",
      height: "54px !important",
      borderRadius: "14px !important ",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    thumbYContent: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      flexDirection: "column",
      height: 16,
    },

    dateFiltercontainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    availabilityHeading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: theme.spacing(2),
      transform: "translate(10px, 25px)",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    footerButton: {
      display: "flex",
      margin: theme.spacing(2),
      flexDirection: "column",
    },
    drawerPaper: {
      height: 354,
      borderTopRightRadius: 14,
      borderTopLeftRadius: 14,
      padding: 16,
    },
    eventPickerContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginRight: 15,
      marginLeft: 15,
    },
    dateAbil: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      marginBottom: 15,
    },
    inputpicker: {
      borderRadius: 16,
    },
    textField: {
      padding: 0,
      borderRadius: 12,
      height: 54,
      width: "50%",
      fontFamily: "Rene_Bieder_Milliard_Book",
      border: "none",
    },
    fontResize: {
      fontWeight: 400,
      lineHeight: " 20px",
      letterSpacing: "-0.4px",
      color: "#black",
    },
    cancelIconButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    pickerMargin: {
      marginTop: 0,
      marginBottom: 0,
    },
  });

const eventTypes = [
  { label: "Need", value: "need" 
  },
  { label: "Project funding", value: "project_funding"
  },
  { label: "Upcoming activity", value: "activity"
  },
];
const scopes: any[] = ["Everyone", "Followers", "Registered user"];

const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 18,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      // color: theme.palette.grey[500],
      color: "white",
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#359ce2",
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 18 / 2,
      opacity: 1,

      backgroundColor: "grey",
    },
    checked: {},
  })
)(Switch);

// Customizable Area End

export class ActivityEventUpdateWeb extends ContentManagementController {
  uploadImg() {
    return (
      <div className={styles.divWrapper} 
      style={{ width: "50%" }}><label 
      className={styles.label}> 
      Upload image *
          </label>
        <CardContent className={styles.cardUploadImage}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              background: COLORS.white,
              width: "106%",
              marginLeft: -12,
              position: 'relative',
              height: "22%",
              // border: "1px solid #a7b6b9 ",
              border: "1px solid #dfdfdf",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: `${this.state.updateEventImg ? "flex" : "none"}`,
                position: "absolute",
                right: 0,
                top: 0,
              }}
              data-testId="imgPreview"
              onClick={(e) => {
                this.setState({
                  updateEventImg: "",
                  needsEventImg: "",
                  needsEventImgPreview: "",
                });
              }}
            >
              <HighlightOffRoundedIcon />
            </div>

            {this.state.updateEventImg ? (
              <img
                src={
                  this.state.updateEventImg?.url
                    ? this.state.updateEventImg?.url
                    : this.state.updateEventImg
                }
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 10,
                }}
              />
            ) : (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <img
                  src={icons.plusIcon}
                  style={{ height: 20 }}
                  alt="cameraIcon"
                />

                <input
                  name="profile_picture"
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                  data-testId="userImageUpload"
                  onChange={(event) => {
                    event.preventDefault();
                    if (event.target.files && event.target.files[0]) {
                      let file = event.target.files[0];
                      this.setState({
                        updateEventImg: URL.createObjectURL(file),
                      });
                      this.setState({
                        updateEventImgPreview: file,
                      });
                    }
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </CardContent>
      </div>
    );
  }

  locationField(details: any) {
    return (
      <div className={styles.divWrapper} style={{ width: '50%', position: 'relative' }}>
        <label className={styles.labelHeading}
        >Location / venue address*</label>
        <br />
        {/* @ts-ignore */}
        <GooglePlacesAutoComplete
          data-testId='location'
          apiKey={configJSON.mapsAPIKey}
          debounce={500}
          selectProps={{
            isClearable: true,
            value: this.state?.location ? [{ label: this.state?.location, value: this.state?.location }] : [{ label: '', value: '' }],
            placeholder: "Enter location",
            noOptionsMessage: () => null,
            onChange: (event: any) => {
              console.log(event, 'event123')
              const location = event?.value?.description ?? "";
              this.setState({ location });
              if (location) {
                this.getLatLong(event?.value?.description);
              }
            },
            styles: {
              placeholder: (base) => {
                return {
                  ...base,
                  color: "lightgrey",
                  fontFamily: 'Rene_Bieder_Milliard_Book',
                  fontSize: '16px'
                };
              },

              menu: (base) => {
                return {
                  ...base,
                  paddingLeft: 20,
                  paddingRight: 20,
                  border: "1px solid lightgrey",
                  marginTop: 0,
                  borderTop: "none",
                  boxShadow: "none",

                  color: "lightgrey",
                  paddingTop: 5,
                  "&:hover": {
                    borderRadius: 12,
                  },
                };
              },
              clearIndicator: (base) => {
                return {
                  ...base,
                  marginRight: 20
                }
              },
              dropdownIndicator: (base) => {
                return {
                  ...base,
                  color: "dodgerblue",
                  display: 'none'
                };
              },
              control: (base) => {
                return {
                  ...base,
                  fontSize: 16,
                  fontFamily: 'Rene_Bieder_Milliard_Book',
                  backgroundColor: "white",
                  boxShadow: "none",
                  height: "54px",
                  width: '101%',
                  borderRadius: "10px",
                  border: '2px solid lightgrey',
                };
              },
              option: (provided, state) => ({
                ...provided,
                color: "black",
                backgroundColor: state.isSelected
                  ? "#192E49"
                  : "inherit",
                "&:hover": {
                  backgroundColor: "#009BE2",
                  color: "white",
                },
              }),
              menuList: (base) => {
                return {
                  ...base,
                  marginTop: 15,
                  position: "relative",
                  top: "-5px",
                  zIndex: -1,
                  color: "grey",
                };
              },
            }
          }}
        >

        </GooglePlacesAutoComplete>
        <img
          src={icons.locationIcon}
          style={{
            height: 23, width: "auto", position: 'absolute', top: 45, right: 8
          }}
        />
      </div >
    );
  }

  divWrapper = () => {
    return (
      <div className={styles.divWrapper}>
        <Input
        data-testId = 'updateInfo'
          onChange={(e: any) => {
            this.setState({ update_info: e.target.value });
          }}
          multiline
          rows={6}
          className={styles.multiLineInput}
          disableUnderline
          placeholder="Enter what is updated...*"
        />
      </div>
    );
  };

  swithCustom(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Publish</label>
        <br />
        <div style={{ marginLeft: 5 }}>
          <AntSwitch
            checked={this.state.publish}
            data-testId="publishSwitch"
            onChange={(e: any) => {
              this.setState({
                publish: e.target.checked,
              });
            }}
          />
        </div>
      </div>
    );
  }

  isUpdateBtnDisable = () => {
    return !this.state.eventName ||
    !this.state.location ||
    !this.state.description ||
    !this.state.updateFromDate ||
    !this.state.update_info ||
    !this.state.updateToDate ||
    !this.state.updateEventImg;
  }

  footerButton(single_event_details: any) {
    return (
      <div
        className={styles.divWrapper}
        style={{ display: "flex", marginTop: 15, marginLeft: -10 }}
      >
        <Button
          data-testId="updateBtn"
          onClick={() => {
            this.updateEvent(single_event_details.data?.id);
          }}
          disabled={this.isUpdateBtnDisable()}
          // variant="outlined"
          style={{
            height: 45,
            textTransform: "capitalize",
            boxShadow: "none",
            width: "12%",
            backgroundColor: `${
              !this.isUpdateBtnDisable()
              ? "#009be2"
              : "#009be233"
              }`,
            color: "white ",
            marginRight: 13,
            borderRadius: 10,
            marginLeft: 13,
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
          }}
          color="primary"
        >
          Update
        </Button>
        <Button
          onClick={() => {
            this.props.navigationToActivitiesList();
          }}
          data-testId="cancelBtn"
          style={{
            // marginLeft: 10,
            width: "12%",
            backgroundColor: COLORS.white,
            color: "#009be2",
            boxShadow: "none",
            textTransform: "capitalize",
            borderRadius: 8,
            height: 45,
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
            border: ".1 solid lightgray",
            // marginBottom: 15,
            // marginTop: 5,
          }}
          variant="outlined"
        > Cancel</Button>
        <Button
          onClick={() => {
            this.setState({ deleteConf: true });
          }}
          style={{
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
            width: "12%",
            backgroundColor: "red",
            color: "white ",
            boxShadow: "none",
            borderRadius: 8,
            height: 45,
            marginLeft: "auto",
            marginRight: 20,
            border: "0.1px solid lightgray",
            textTransform: "none",
          }}
          variant="outlined"
        >
          Cancel event
        </Button>
      </div>
    );
  }

  eventNameInput(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Name of event *</label>
        <br />
        <Input
          value={this.state.eventName}
          data-testId="eventName"
          onChange={(e) => {
            this.setState({ eventType: "activity" });
            this.setState({
              eventName: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
            if (e.target.value.length < 1) {
              this.setState({ eventNameValidation: true });
            } else {
              this.setState({
                eventNameValidation: false,
              });
            }
          }}
          className={styles.textInput}
          disableUnderline
          placeholder="Name of event"
        />
        <br />
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventNameValidation ? "flex" : "none"}`,
          }}
        >
          Event name can't be blank
        </span>
      </div>
    );
  }

  descriptionNeedInput(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Description of need *</label>
        <br />
        <Input
          data-testId="desc"
          disableUnderline
          value={this.state.description}
          onChange={(e) => {
            this.setState({
              description: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          multiline
          rows={6}
          className={styles.multiLineInput}
          placeholder="Provide a general description visible to all users on the platform."
        />
      </div>
    );
  }

  activitySheduler(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Activity schedule</label>
        <br />
        <Input
          disableUnderline
          value={this.state.activity_schedule}
          data-testId="activitySchedule"
          onChange={(e) => {
            this.setState({
              activity_schedule: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          multiline
          rows={6}
          className={styles.multiLineInput}
          placeholder="Describe the activity schedule."
        />
      </div>
    );
  }

  selectedTypeLabel(colourStyles: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Selected type</label>
        <br />
        {/* @ts-ignore */}
        <div
          style={{
            border: `${this.state.createEventState
              ? "1px solid  #009BE2"
              : "1px solid lightgrey"
              }`,
            width: "50%",
            borderRadius: 10,
            borderBottom: `${!this.state.createEventState
              ? "1px solid lightgrey"
              : "1px solid  #009BE2"
              }`,
            height: `${!this.state.createEventState ? "54px" : "180px"}`,
          }}
        >
          {this.selectInputs(colourStyles)}
        </div>
      </div>
    );
  }

  partitipateInput(details: any) {
    // data-testId="noOfParticipant"
    return (
      <div className={styles.divWrapper}>
        <label 
        className={styles.labelHeading}> Maximum attendance</label>
        <br />
        <Input
          value={this.state.volunteers_num}
          inputProps={{
            maxLength: 8,
          }}
          data-testId="noOfParticipant"
          onChange={(e) => {
            const pincode = e.target.value;
            if (/^\d{0,8}$/.test(pincode)) {
              this.setState({
                volunteers_num: pincode,
                eventVolunteerValidation: false,
              });
            } else {
              this.setState({
                eventVolunteerValidation: true,
              });
            }
          }}
          type="text"
          disableUnderline
          placeholder="0"
          className={styles.textInput}
        />
        <Typography
          style={{
            ...FONTS.body1,
            color: "lightgrey",
            fontSize: 12,
            fontWeight: 400,
          }}
        > This will be the maximum number of participants allowed for your
          event.</Typography>
        <Typography
          style={{
            ...FONTS.body1,
            color: "lightgrey",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Leave it 0 for no limit.
        </Typography>
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventVolunteerValidation ? "flex" : "none"}`,
          }}
        >
          Enter valid details
        </span>
      </div>
    );
  }

  selectInputs(colourStyles: any) {
    return (
      <Select
        isDisabled={true}
        onMenuOpen={() => {
          this.setState({ createEventState: true });
        }}
        onMenuClose={() => {
          this.setState({ createEventState: false });
        }}
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,
            primary25: "skyblue",
            primary: "dodgerblue",
          },
        })}
        styles={colourStyles}
        name="eventTypes"
        data-testId="eventTypes"
        isSearchable={false}
        isClearable={false}
        isRtl={false}
        options={eventTypes}
        defaultValue={eventTypes[2]}
        className={styles.selectInput}
        onChange={(e: any) => {
          this.setState({ eventType: e.value });
        }}
      />
    );
  }

  checkOne(details: any) {
    return (
      <span>
        <FormControlLabel
          
          control={
            <Checkbox
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
              onChange={(e) => {
                if (this.state.followers && this.state.registered_user) {
                  this.setState({ everyone: true });
                } 
                this.setState({
                  everyone: e.target.checked,
                  registered_user: e.target.checked,
                  followers: e.target.checked,
                });
              }}
              checked={this.state.everyone }
              style={{
                color: "lightgrey"
              }}
            />
          }
          data-testId="check-8"
          label={
          <span className={styles.label}>
            Everyone else</span>
          }
        />
      </span>
    );
  }

  finishTime(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Finish time *</label>
        <br />
        <InitiateThemeProvider>
          <KeyboardTimePicker
            data-testId='finishTime'
            autoOk
            fullWidth
            ampm={false}
            disabled={this.state.all_day}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            id="mui-pickers-time"
            className={classes.pickerMargin}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            placeholder="12:00 AM"
            mask="__:__ _M"
            value={
              this.state.endTimePost === null
                ? moment(this.state.updateFinishTime)
                : this.state.endTimePost
            }
            onChange={(time) => {
              this.setState({ endTimePost: time });
            }}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                src={icons.clockIcon}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
    );
  }

  startTime(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <div
          style={{
            display: "flex",
            width: "50%",
          }}
        >
          <label 
          className={styles.label}>Start time *</label>
          <Typography
            style={{
              fontFamily: "Rene_Bieder_Milliard_Medium",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>All day</Grid>

              <Grid item>
                {/* @ts-ignore */}
                <AntSwitch
                  checked={this.state.all_day}
                  data-testId="allDaySwitch"
                  onChange={(e: any) => {
                    this.setState({
                      all_day: e.target.checked,
                    });
                    if (e.target.checked) {
                      this.setState({
                        startTimePost: moment().set({ 'hour': 9, 'minute': 0 }),
                        endTimePost: moment().set({ 'hour': 17, 'minute': 0 }),
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        </div>
        <br />
        <InitiateThemeProvider>
          <KeyboardTimePicker
            data-testId='startTime'
            autoOk
            fullWidth
            ampm={false}
            disabled={this.state.all_day}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            id="mui-pickers-time"
            className={classes.pickerMargin}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            placeholder="12:00 AM"
            mask="__:__ _M"
            value={
              this.state.startTimePost === null
                ? moment(this.state.updateStartTime)
                : this.state.startTimePost
            }
            onChange={(time) => {
              this.setState({ startTimePost: time });
            }}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                src={icons.clockIcon}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
    );
  }

  toDate(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>To date *</label>
        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          fullWidth
          autoOk
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          margin="normal"
          id="date-picker-inline"
          data-testId="date-picker-inline-todate"
          InputProps={{
            classes: {
              root: classes.textField,
              input: classes.fontResize,
            },
          }}
          minDate={this.state.updateFromDate}
          // error={availibilityModalError && true}
          // helperText={"helperText"}
          value={this.state.updateToDate}
          onChange={(date) => {
            this.setState({ updateToDate: date });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
          style={{ fontSize: 16, marginTop: 0 }}
        />
      </div>
    );
  }

  fromDate(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>From date *</label>
        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiOutlinedInput-root.Mui-Focused .MuiOutlinedInput-notchedOutline   {
              border : 1px solid black
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          autoOk
          fullWidth
          disableToolbar
          minDate={new Date()}
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          margin="normal"
          id="date-picker-inline"
          InputProps={{
            classes: {
              root: classes.textField,
              input: classes.fontResize,
            },
          }}
          value={this.state.updateFromDate}
          onChange={(date) => {
            this.setState({ updateFromDate: date });
          }}
          data-testId="date-picker-inline"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
          style={{ fontSize: 16, marginTop: 0 }}
        />
      </div>
    );
  }

  publicScope(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Publishing scope</label>
        <br />
        <FormGroup>{this.checkOne(details)}</FormGroup>

        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-2"
              control={
                <Checkbox
                  checked={this.state.followers}
                  onChange={(e) => {
                    if (e.target.checked || this.state.registered_user) {
                      this.setState({ everyone: false });
                    } 
                    this.setState({followers: e.target.checked});
                  }}
                  checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                  style={{ color: "lightgrey" }}
                />
              }
              label={<span className={styles.label}>Followers</span>}
            />
          </span>
        </FormGroup>

        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-3"
              control={
                <Checkbox
                  style={
                    {color: "lightgrey",}}
                  checked={this.state.registered_user}
                  checkedIcon={
                    <CheckBoxIcon
                      style={{
                        color: "#359ce2"
                      }}
                    />
                  }
                  onChange={(e) => {
                    this.setState({
                      registered_user: e.target.checked
                    });
                    if (this.state.followers || e.target.checked ) {
                      this.setState({ everyone: false });
                    } 
                  }}
                />
              }
              label={<span className={styles.label}>
                Registered user</span>}
            />
          </span>
        </FormGroup>
      </div>
    );
  }

  cancelModal = () => {
    this.setState({ deleteConf: false });
  };

  render() {
    const { single_event_details, classes, theme }: any = this.props;
    const details = single_event_details.data?.attributes;

    const colourStyles = {
      menu: (base: any) => {
        return {
          ...base,
          paddingLeft: 20,
          paddingRight: 20,
          border: "none",
          marginTop: 0,
          borderTop: "none",
          boxShadow: "none",
          color: "black",
          paddingTop: 5,
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        };
      },
      singleValue: (base: any) => {
        return {
          ...base,
          color: "black",
        };
      },
      dropdownIndicator: (base: any) => {
        return {
          ...base,
          color: "lightgrey",
        };
      },
      control: (base: any, state: any) => {
        return {
          ...base,

          backgroundColor: "white",
          boxShadow: "none",
          height: 54,
          borderRadius: state.isFocused ? "10px " : "10px",
          border: `${this.state.createEventState
            ? "1px solid  #009BE2"
            : "1px solid lightgrey"
            }`,
          "&:hover": {
            borderBottom: `${this.state.createEventState
              ? "1px solid  #009BE2"
              : "1px solid lightgrey"
              }`,
          },
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
        };
      },
      indicatorSeparator: (styles: any) => ({
        ...styles,
        display: "none",
      }),
      menuList: (base: any) => {
        return {
          ...base,
          marginTop: 15,
          position: "relative",
          top: "-5px",
          zIndex: -1,
        };
      },
    };

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div>
            <Card
              style={{
                width: "99.6%",
                borderRadius: "0 0 12px 12px",
                margin: "auto",
                // marginTop: 30,
              }}
            >
              <div style={{ marginTop: 10 }} />

              <CardContent
                style={{
                  // borderTop: "2px solid #F0F2f5",
                  padding: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-15px",
                    float: "right",
                  }}
                >
                  <Typography className={styles.label}>
                    Id: {single_event_details.data.id}
                  </Typography>
                  <IconButton>
                    <ShareOutlinedIcon />
                  </IconButton>
                </div>

                {this.selectedTypeLabel(colourStyles)}

                {/* name of event  */}

                {this.eventNameInput(details)}

                {/* {Description of need} */}
                {this.descriptionNeedInput(details)}

                {/* activity shedule  */}

                {this.activitySheduler(details)}

                {/* participateinput  */}

                {this.partitipateInput(details)}

                {this.publicScope(details)}

                {this.fromDate(classes)}

                {this.toDate(classes)}

                {this.startTime(classes)}

                {this.finishTime(classes)}

                {this.locationField(details)}

                {this.uploadImg()}


                {this.swithCustom(details)}


                {this.divWrapper()}

                {/* footer button  */}
                {this.footerButton(single_event_details)}
                {this.state.eventUpdateError.length > 0 && (
                  <Snackbar
                    open={this.state.eventUpdateError.length > 0}
                    onClose={() => {
                      this.setState({ eventUpdateError: "" });
                    }}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <SnackbarContent
                      className={styles.snackbar}
                      message={this.state.eventUpdateError ? this.state.eventUpdateError : this.state.eventUpdateErros}
                    />
                  </Snackbar>
                )}
                <Dialog
                  hideBackdrop
                  PaperProps={{
                    style: {
                      background: "#FEFEFE",
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
                      borderRadius: 18,
                      maxHeight: 370,
                      width: 600,
                    },
                    elevation: 0,
                  }}
                  open={this.state.deleteConf}
                  data-testId="modal"
                  onClose={() => {
                    this.setState({ deleteConf: false });
                  }}
                >
                  {/* @ts-ignore */}
                  <DeleteEventModal
                    id={single_event_details.data?.id}
                    cancelClick={this.cancelModal}
                    confirmClick={() => {
                      this.deleteActivityEvent(single_event_details.data?.id);
                    }}
                  />
                </Dialog>
              </CardContent>
            </Card>
          </div>
        </MuiPickersUtilsProvider>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

export default withStyles(useStyles as any, { withTheme: true })(
  ActivityEventUpdateWeb as any
);
