import React from "react";

import {
  // Customizable Area Start
  Card,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Switch,
  Avatar,
  Dialog,
  Box,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Redirect } from "react-router-dom";
import GoogleMap from "../../../components/src/utils/GoogleMap.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import { COLORS } from "../../../framework/src/Globals";
import EventInfoController, { Props } from "./EventInfoController.web";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { constants, icons, images } from "../../../components/src/constants";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
//@ts-ignore
import classes from "./userEvent.module.css";
import { isMobile } from "react-device-detect";
import OutlookCalendar from "../../../components/src/utils/OutlookCalendar.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class EventInfo extends EventInfoController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderLocation=(location:string, postcode:string)=> {
    if(postcode && !location.includes(postcode)){
     location += `, ${postcode}`; 
   }
  return location;
 }

  openDialog = () => {
    return (
      <Dialog
        className={classes.dialogWrapper}
        open={this.state.isOpen}
        data-test-id="modal-close"
        onClose={() => this.setState({ isOpen: false })}
      >
        <div className={classes.dialogStyle}>
          <div>
            <Typography className={classes.titleFont}>
              {this.state.title}
            </Typography>
          </div>
          <div className={classes.userInfo}>
            <div className={classes.avatarImgcontainer}>
              {this.state.organizationLogo
                ? <img
                  src={this.state.organizationLogo}
                  alt="not found!"
                  className={classes.avatarImg}
                />
                : <Avatar style={{ height: "30px", width: "30px" }}>
                  <Box fontSize="14px">{this.state.organizationName.charAt(0).toUpperCase()}</Box>
                </Avatar>
              }
            </div>
            <Typography variant="body1" className={classes.userName}>
              {this.state.organizationName}
            </Typography>
          </div>
          <div className={classes.imageTextWrapper}>
            <div className={classes.avatarImgcontainer}>
              <img
                src={icons.calender}
                alt="avatar"
                className={classes.avatarImg}
              />
            </div>
            <span className={classes.eventdate_loc}>
              {moment(this.state.startDate).format("DD MMM YYYY")},{" "}
              {moment(this.state.startTime).format("HH:mm a")}
            </span>
          </div>

          <div className={classes.imageTextWrapper}>
            <div className={classes.avatarImgcontainer}>
              <img
                src={icons.locationred}
                alt="not-found"
                className={classes.avatarImg}
              />
            </div>
            <div className={classes.eventdate_loc}>
              {this.renderLocation(this.state.location, this.state.post_code)}
            </div>
          </div>
          <div className={classes.joinChat}>
            <AvatarGroup className={`AvatarGroupMyNeed`} max={4}>
              {this.state.participantList.map(
                (person: any, index: number) => {
                  return (
                    <Avatar
                      key={`${index + 1}`}
                      alt={person.full_name}
                      className={`singleAvatar ${classes.multipleAvatarStyle}`}
                      src={person.profile_picture?.url}
                    >
                      {person.full_name.charAt(0).toUpperCase()}
                    </Avatar>
                  )
                }
              )}
            </AvatarGroup>
          </div>
          <div className={classes.dialogBoxText}>
            {this.state.isCancelButton
              ? "You are cancelling your reservation at this upcoming activity."
              : "Thanks for your interest in this event. Look forward to seeing you."
            }
          </div>
          <Button className={classes.BlueBtn} onClick={this.onButtonClick} data-test-id="dialog-submit-button">
            {this.state.isCancelButton
              ? "Confirm Cancellation"
              : "Confirm"
            }
          </Button>
          <Button data-test-id="cancel-attendance" className={`${classes.whiteBtn} ${classes.boldButton}`} onClick={() => this.setState({ isOpen: false })}>
            Cancel
          </Button>
        </div>
      </Dialog>
    );
  }

  headerWeb() {
    return (
      <Toolbar className={classes.toolBar}>
        {!isMobile && (
          <div className={`${classes.backIconWeb}`}>
            <IconButton
              aria-label="Open drawer"
              color={"inherit"}
              data-test-id="back-btn"
              onClick={() => this.props.navigation.goBack()}
            >
              <img src={icons.back} />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.activityDetails}
              noWrap
            >
              Activity Details
            </Typography>
          </div>
        )}
      </Toolbar>
    );
  }

  renderTopCard = () => {
    return (
      <>
        {" "}
        <div className={classes.leftCard}>
          {!this.state.isImageLoaded &&
            <div className={classes.imageLoading}>
              <CircularProgress />
            </div>
          }
          <img
            className={this.state.isImageLoaded ? classes.cardImage : classes.hideImage}
            src={this.state.eventImage ?? images.cardImg2}
            onLoad={() => this.setState({ isImageLoaded: true })}
          />
          {this.state.isFavorite ? (
            <FavoriteIcon
              className={classes.FavoriteIcon}
              onClick={this.removeFromFavorite}
            />
          ) : (
            <FavoriteBorderIcon
              onClick={this.addToFavorite}
              className={classes.FavoriteBorderIcon}
            />
          )}
        </div>
        <div className={classes.eventDetails}>
          <div className={classes.spaceBetweenDivs}>
            <div>
              <Typography className={classes.titleFont}>
                {this.state.title}
              </Typography>
              <div
                className={classes.userInfo}
                onClick={() => this.props.navigation.navigate("NonProfitProfile", {id: this.state.organisation_id})}
              >
                <div className={classes.avatarImgcontainer}>
                  {this.state.organizationLogo
                    ? <img
                      alt="not found!"
                      src={this.state.organizationLogo}
                      className={classes.avatarImg}
                    />
                    : <Avatar style={{ height: "30px", width: "30px" }}>
                      <Box fontSize="14px">{this.state.organizationName.charAt(0).toUpperCase()}</Box>
                    </Avatar>
                  }
                </div>
                <Typography variant="body1" className={classes.userName}>
                  {this.state.organizationName}
                </Typography>
              </div>
            </div>
            {!this.state.isPastEvent &&
              <div>
                <div>
                  <img
                    data-test-id="message-btn"
                    src={icons.chats}
                    className={classes.chatIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if(this.state.eventStatus === "Approved"){
                        this.onClickPopup(this.state.sid)

                      }
                      else{
                        this.MessageNpProfile(this.state.organisation_id) 
                      }
                    } }
                  />
                </div>
                <div style={{ marginRight: "11px" }}>
                  <Typography className={classes.messageText}> Message </Typography>
                </div>
              </div>
            }
          </div>

          <div className={classes.innerInfo}>
            <div className={`${classes.spaceBetweenDivs}`}>
              <div className={classes.imageTextWrapper}>
                <div className={classes.avatarImgcontainer}>
                  <img
                    src={icons.calender}
                    alt="avatar"
                    className={classes.avatarImg}
                  />
                </div>
                {this.state.startDate && (
                  <span className={classes.eventdate_loc}>
                    {moment(this.state.startDate).format("DD MMM YYYY")},{" "}
                    {moment(this.state.startTime).format("HH:mm a")}
                  </span>
                )}
              </div>
              {!this.state.isPastEvent &&
                <OutlookCalendar
                  title={this.state.title}
                  location={this.state.location}
                  classes={classes}
                  description={this.state.description}
                  startDate={this.getDateAndTime(this.state.startDate, this.state.startTime)}
                  endDate={this.getDateAndTime(this.state.toDate, this.state.toTime)}
                />
              }
            </div>
            <div className={classes.imageTextWrapper}>
              <div className={classes.avatarImgcontainer}>
                <img
                  src={icons.locationred}
                  alt="location"
                  className={classes.avatarImg}
                />
              </div>
              <div className={classes.eventdate_loc}>
                {this.renderLocation(this.state.location, this.state.post_code)}
              </div>
            </div>
            {this.renderOpenEventsAndAvailablePlaces()}
          </div>
        </div>
      </>
    );
  };

  renderBottomCard = () => {
    return (
      <div className={classes.eventDetails}>
        <div className={classes.about}>About the activity</div>
        <div className={classes.description}>Description</div>
        {this.state.description.split("\n").map((desc: string, index: number) => {
          return <p key={`${index + 1}`} className={classes.detailDescription}>{desc}</p>
        })}
        {this.state.eventStatus === "Approved" && this.state.information &&
          <>
            <div className={classes.description}>Activity schedule</div>
            {this.state.information.split("\n").map((desc: string, index: number) => {
              return <p key={`${index + 1}`} className={classes.detailDescription}>{desc}</p>
            })}
          </>
        }
        <div className={classes.spaceBetweenDivs}>
          <div className={classes.location}>Location</div>
          <div onClick={this.openMap} className={classes.locationWrapper}>
            <img src={icons.directions} className={classes.directionImg} />
            <div className={classes.getDirection}>
              Get Directions
            </div>
          </div>
        </div>
        <div>
          <GoogleMap
            //@ts-ignore
            lng={this.state.eventLang}
            lat={this.state.eventLat}
          />
        </div>
        <div className={classes.spaceBetweenDivs}>
          {(this.state.eventStatus === null || this.state.eventStatus === "Cancelled") && (
            <Button
              className={
                this.state.isEventOpen
                  ? classes.BlueBtn
                  : classes.grayBtn
              }
              data-test-id="attend"
              onClick={() => {
                if (this.state.isEventOpen) {
                  this.setState({ isOpen: true, isCancelButton: false })
                }
              }}
            >
              Attend
            </Button>
          )}
          {this.state.eventStatus === "Pending" && (
            <>
              <Button className={classes.whiteBtn}>Requested</Button>
              <Button
                className={classes.BlueBtn}
                onClick={() => {
                  if (this.state.isEventOpen) {
                    this.setState({ isOpen: true, isCancelButton: true })
                  }
                }}
                data-test-id="cancel-button">Cancel Request</Button>
            </>
          )}
          {this.state.eventStatus === "Approved" && (
            <>
              <Button className={classes.whiteBtn}>Confirmed</Button>
              <Button
                data-test-id="cancel-pledge"
                className={classes.BlueBtn}
                onClick={() => {
                  if (this.state.isEventOpen) {
                    this.setState({ isOpen: true, isCancelButton: true })
                  }
                }}>
                Cancel Attendance
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };

  renderOpenEventsAndAvailablePlaces = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            className={classes.openEvent}
            style={{
              marginRight: "20px",
              color: this.state.isEventOpen ? "black" : "#9C9C9C"
            }}
          >
            Open event:{" "}
          </Typography>
          <AntSwitch checked={this.state.isEventOpen} />
        </div>
        {this.state.isEventOpen && !!this.state.totalCount &&
          <div>
            <Typography
              className={classes.availablePlace}
              style={{
                color: COLORS.skyBlue,
              }}
            >
              Available places: {this.state.totalCount - this.state.participantList.length}
            </Typography>
          </div>
        }
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {/* @ts-ignore */}
        <DashboardWeb
          title={"Activity Details"}
          showBackButton={constants._icons.show}
          showBottomBar={true}
          navigation={this.props.navigation}
          showDrawerHeader={isMobile ? true : false}
        >
          {this.state.nonProfitProfileUrl && <Redirect to={`${this.state.nonProfitProfileUrl}`} />}
          <div className={classes.eventContainer}>
            {this.headerWeb()}
            <Card>
              {this.renderTopCard()}
              {this.renderBottomCard()}
            </Card>
            {this.openDialog()}
          </div>
        </DashboardWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "11.5px",
      padding: 0,
      height: "20px",
      width: "38px",
      display: "flex",
    },
    switchBase: {
      color: "rgb(136 136 136)",
      padding: 2,
      "&$checked": {
        color: COLORS.skyBlue,
        transform: "translateX(16px)",
        "& + $track": {
          opacity: 1,
          backgroundColor: COLORS.white,
          border: "1px solid #009BE2",
        },
      },
    },
    thumb: {
      height: "16px",
      width: "16px",
      boxShadow: "none",
      "&$checked": {
        color: COLORS.skyBlue,
        transform: "translateX(16px)",
        "& + $track": {
          opacity: 1,
          backgroundColor: COLORS.white,
          border: "1px solid #009BE2",
          borderColor: COLORS.skyBlue,
        },
      },
    },
    track: {
      border: " 1px solid rgba(151, 151, 151, 0.43272)",
      borderRadius: "11.5px",
      backgroundColor: COLORS.white,
      opacity: 1,
    },
    checked: {},
  })
)(Switch);

export default EventInfo;
// Customizable Area End