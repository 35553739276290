// Customizable Area Start
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { format, addHours } from 'date-fns';
import { RootState } from '../../../framework/src/redux/stores';
import { VerticalScrollBarContainer } from './VerticalScrollBarContainer';
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { HourLine } from './HourLine';
import moment from 'moment';
import axios from 'axios';
import {baseURL} from '../../../../packages/framework/src/config.js'
import { appendJsonContentType } from "../../../../packages/components/src/utils/http-response"



const HOURS_FORMAT = "hh:mm a";
export let apiCallBack:any = null;
///////////////////////
export function formatDate(date, ch) {
  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join(ch);
}


export function HourLinesContainer({ filterByDate }: { filterByDate: Date }) {

  const accountId = localStorage.getItem("acc_id") || '';
  const [timeSlotData,setTimeSlotData]=useState([]);
  let availabilitiesQuery;
  const apiCall= async() => {
    await axios
    .get(`${baseURL}/bx_block_appointment_management/availabilities?check_date=${moment(filterByDate).format("DD/MM/YYYY")}&service_provider_id=${accountId}&token=${localStorage.getItem('token') || ''}`, {
      headers: appendJsonContentType(),
    })
    ?.then((res) => {
      availabilitiesQuery= res;
      setTimeSlotData(res);
    })
    .catch((err) => {
      console.log(err)
    });

  }
   useEffect(()=>{
    apiCall();
  },[filterByDate]) 

 apiCallBack= () => { apiCall() };

 availabilitiesQuery= timeSlotData;
localStorage.setItem("current_date",format(filterByDate, 'dd/MM/yyyy'));
  /*
   * timeslots
   * [ {"2023-05-28": {}, "2023-05-29": {}, ...}, { ... }, ... ]
   */
  
  function setStateCallBack(){
    setTimeSlotData([]);
    }
  let availabilities, timeSlots=[] , targetDate,targetTimeSlots=[];
  if(availabilitiesQuery?.data?.message?.length){
    setStateCallBack();
    availabilities=[];
    localStorage.setItem("api_method_availability","POST");
  }
  if(availabilitiesQuery?.data?.data){
    availabilities=availabilitiesQuery?.data?.data;
    localStorage.setItem("api_method_availability","PATCH");
    localStorage.setItem("date_id",availabilities[0]?.attributes?.id) 
   
   timeSlots = availabilities?.map(item => item?.attributes?.time_slots ?? [])?.flat();
  targetDate = localStorage.getItem("new_date").split("/").reverse().join("-");
  targetTimeSlots = timeSlots.filter(item => item).map(item => item).flat();
  }
    return (
      <HourLines
        hoursArray={targetTimeSlots?.map(({ from, to }) => ({ from, to }))}
      />
    );
  
}


///////////////////////


const useHoursLinesStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 20px)',
    overflow: 'auto',
  },
});

const now = new Date();
now.setMinutes(0);


export function HourLines({ hoursArray }: { hoursArray: { from: string, to: string }[] }) {

  const classes = useHoursLinesStyles();

  return (
    
    <VerticalScrollBarContainer>
     
      <div className={classes.root} >
      <VerticalSpacer/>
        { new Array(24).fill(0).map((_x, i) => {
            const hour = format(addHours(new Date().setHours(0,0,0,0), i), HOURS_FORMAT);
            const startHourExist = hoursArray.find((item) => {
              const givenTime = moment(hour, 'hh:mm A');
              const endTime = moment(givenTime).add(1, 'hour');
              const fromTime = moment(item.from, 'hh:mm A');
              return fromTime.isSameOrAfter(givenTime) && fromTime.isBefore(endTime);
            });
            const updatedFromTime=localStorage.getItem("updated_from_time");
            const updatedToTime=localStorage.getItem("updated_to_time");
            const eventPickerDate =localStorage.getItem("eventpicker_date");
            const selectedDate=localStorage.getItem("selected_date")
            if(localStorage.getItem("all_day")==='true' && localStorage.getItem("user_selected_date")==='true'){
              const from= Number(moment("9:00 AM", ["h:mm A"]).format("HH"));
              const to= Number(moment("5:00 PM", ["h:mm A"]).format("HH"))
               return ( 
                
                  <HourLine
                    key={i}
                    hour={hour}
                    slotExist={Boolean((eventPickerDate===selectedDate) && from <=i && i <= to-1)}
                    from={"9:00 AM"}
                    to={"5:00 PM"}
                  />
                 
                ) 
            }
             if(updatedFromTime && updatedToTime && localStorage.getItem("user_selected_date")==='true'){
              const from= Number(moment(updatedFromTime, ["h:mm A"]).format("HH"));
              const hr=Number(moment(updatedToTime, ["h:mm A"]).format("HH"))
              const to= hr==0?24:hr
               return ( 
                
                  <HourLine
                    key={i}
                    hour={hour}
                    slotExist={Boolean((eventPickerDate===selectedDate) && from <=i && i <= to-1)}
                    from={updatedFromTime ??  '' }
                    to={updatedToTime ??  ' ' }
                  />
                ) 
            }
            else{
              localStorage.setItem('set_button','false')
            return ( 
           
              <HourLine
                key={i}
                hour={hour}
                slotExist={Boolean(startHourExist)}
                from={startHourExist?.from ??  '' }
                to={startHourExist?.to ??  ' ' }
              />
           
            )
        }
          })
        }
        </div>
    </VerticalScrollBarContainer>
   
  );
}




// Customizable Area End
