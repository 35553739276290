import React from "react";

// Customizable Area Start
import {
  // Customizable Area Start
  Typography,
  Switch,
  Box, 
  TextField,
  Container,
  // Customizable Area End
} from "@material-ui/core";
//@ts-ignore
import classesOuter from "./RegistrationFormBusiness.module.css";
import {
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import GooglePlacesAutocomplete,{ geocodeByAddress } from 'react-google-places-autocomplete';
import {
  images,
  FAMILYFONTS
} from "../../../components/src/constants";
import FormRegitrationController, {
  Props
} from "./RegistrationFormController";
import { COLORS } from "../../../framework/src/Globals";
import { configJSON } from "../../ContentManagement/src/AdminRegistrationController";
//@ts-ignore

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
// Customizable Area End



// Customizable Area Start
const styles = (theme: {
  palette: any;
  zIndex: any;
  breakpoints: any;
  transitions: {
    create: (
      arg0: string | string[],
      arg1: { easing: any; duration: any }
    ) => any;
    easing: { sharp: any; easeOut: any };
    duration: {
      leavingScreen: any;
      enteringScreen: any;
    };
  };
  mixins: { toolbar: any };
  spacing: any;
}) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  imgLogo: {
    height: 100,
    width: "auto",
  },
  Organizationsearch: {
    position: "relative",

  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  searchDropdownorg: {
    borderRadius: "8px",
    color: "text.primary",
    backgroundColor: "white",
    margin: "8px",
    padding: "8px",
    height: 46,
    width: 46,
  },
  org_tabs_logo: {
    width: "18px",
    height: "8px"
  },
  inputRoot: {
    color: '#87888F',
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    paddingLeft: `calc(1em + 0px)`,
    fontSize: "15px",
    fontFamily: "Rene_Bieder_Milliard_Book",
    color: "#0d0d0d",
  },
  dropdownlabelStyle: {
    fontSize: '16px',
    fontFamily: "Rene_Bieder_Milliard_Medium",
    color: '#87888F',
  },
  listItemroot: {
    width: "95%",
    margin: "4px auto",
    borderRadius: "8px",
    transition: "all .5s",
    overflow: "hidden",
  },
});
export const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "40px",
      height: "20px",

      borderRadius: '11.5px',
      padding: 0,
      display: "flex",
      overflow: "inherit",
    },
    switchBase: {
      padding: 3,
      color: COLORS.infoGray,
      '&$checked': {
        transform: 'translateX(16px)',
        color: COLORS.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: COLORS.skyBlue,
          color: COLORS.white,
          borderColor: COLORS.infoGray,
          border: '1px solid #009BE2',
        },
      },
    },
    thumb: {
      width: '16px',
      height: '16px',

      boxShadow: 'none',
    },
    track: {
      border: ' 1px solid rgba(151, 151, 151, 0.43272)',
      borderRadius: '11.5px',
      opacity: 1,
      backgroundColor: COLORS.white,
    },
    checked: {},
  })
)(Switch);

// Customizable Area End

class RegistrationFormPart1Business extends FormRegitrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  getAddressDetails = (results: google.maps.GeocoderAddressComponent[]) =>  {
    const addressComponents = {
      city: "",
      country: "",
      postcode: "",
    };

    for (const comp of results) {
      if (comp.types.includes('postal_code')) {
        addressComponents.postcode = comp.long_name;
      } else if (!addressComponents.city && (comp.types.includes('postal_town') || comp.types.includes("administrative_area_level_2") || comp.types.includes('administrative_area_level_1'))) {
        addressComponents.city = comp.long_name;
      } else if (comp.types.includes('country')) {
        addressComponents.country = comp.long_name;
      }
    }
    return addressComponents;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, registerNextBtnValidation, fullName, orgName, regNum, streetName, town, country, postCode,switchStatus, officeStreetName, officeTown, officeCountry, officePostCode } = this.props;
    // Merge Engine - render - Start
    return (
      <>

        <Container
          maxWidth="xl"
          style={{
            backgroundColor: COLORS.white,
            borderRadius: 24,
            padding: 30,
            margin: "auto",
          }}
        >
          <form
            className={classes.form}
            noValidate
          >

            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={images.app_logo} className={classes.imgLogo} />
            </Box>
            <Typography
              style={{
                ...FAMILYFONTS.family1,
                fontSize: 28,
                fontWeight: 400
              }}
              className={classesOuter.registrationFormHead}
            >
              Registration Form
            </Typography>

            <div className={classesOuter.mainRegisterContainer}>
              <Box className={classesOuter.labelInputBox}>
                <label className={classesOuter.labelForInput}>Full name *</label>
                <Box className={classesOuter.inputField}>
                  <TextField
                    id="name"
                    name="fullName"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Full name"
                    data-testid="fullNameTest"
                    defaultValue={fullName}
                    InputProps={{
                      classes: {
                        root: classesOuter.registerTextField,
                      },
                    }}
                    inputProps={{
                      style: {
                        borderColor: "#d5d5d5",
                      },
                      onFocus: this.handleInputFocus,
                      onBlur: this.handleInputBlur,
                    }}
                    onChange={(e) => {
                      registerNextBtnValidation({ fullName: e.target.value});
                      this.validFullName(e.target.value);
                    }}
                    fullWidth
                    required
                  />
                </Box>
                <Typography
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 12,
                    color: "#fd1d1d",
                    marginTop: 10
                  }}
                >
                  {this.state.fullNameError}
                </Typography>
              </Box>

              <Box className={classesOuter.labelInputBox}>
                <label className={classesOuter.labelForInput}>
                  Name of business *
                </label>
                <Box className={classesOuter.inputField}>
                  <TextField
                    name="orgName"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Enter business name"
                    data-testid="orgNameTest"
                    defaultValue={orgName}
                    InputProps={{
                      classes: {
                        root: classesOuter.registerTextField,
                      },
                    }}
                    inputProps={{
                      style: {
                        borderColor: "#d5d5d5",
                      },
                      onFocus: this.handleInputFocus,
                      onBlur: this.handleInputBlur,
                    }}
                    onChange={(e) => {
                      registerNextBtnValidation({ orgName: e.target.value});
                      this.validBusinessName(e.target.value);
                    }}
                    required
                    fullWidth                    
                  />
                </Box>
                <Typography
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 12,
                    color: "#fd1d1d",
                    marginTop: 10
                  }}
                >
                  {this.state.projectNameError}
                  {this.props.businessApiError}
                </Typography>
              </Box>

              <Box className={classesOuter.labelInputBox}>
                <label className={classesOuter.labelForInput}>
                  Registration number 
                </label>
                <Box className={classesOuter.inputField}>
                  <TextField
                    name="regNum"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Registration number "
                    data-testid="regNumTest"
                    defaultValue={regNum}
                    InputProps={{
                      classes: {
                        root: classesOuter.registerTextField,
                      },
                    }}
                    inputProps={{
                      style: {
                        borderColor: "#d5d5d5",
                      },
                      onFocus: this.handleInputFocus,
                      onBlur: this.handleInputBlur,
                    }}
                    onChange={(e) => {
                      registerNextBtnValidation({ regNum: e.target.value })
                      this.validRegistrationNumber(e.target.value)
                    }}
                    required
                    fullWidth
                  />
                </Box>
                <Typography
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 12,
                    color: "#fd1d1d",
                    marginTop: 10
                  }}
                >
                  {this.state.registrationNumberError}
                </Typography>
              </Box>

              <Box className={classesOuter.labelInputBox}>
                <label className={classesOuter.labelForInput}>
                  Address
                </label>
                <Box className={`${classesOuter.inputField} address-location business`}>
                  <GooglePlacesAutocomplete
                    data-testid="streetNameTest"
                    apiKey={configJSON.mapsAPIKey}
                    debounce={500}
                    selectProps={{
                      isClearable: true,
                      classNamePrefix: "react-select",
                      placeholder: "Street*",
                      value: streetName ? [{ label: streetName, value: streetName }] : undefined,
                      noOptionsMessage: () => null,
                      onChange: async (event) => {
                        const location = event?.value?.description ?? "";
                        registerNextBtnValidation({ streetName: location });
                        registerNextBtnValidation({ town: "" });
                        registerNextBtnValidation({ country: "" });
                        registerNextBtnValidation({ postCode: "" });
                        geocodeByAddress(location)
                          .then(results => {
                            const { postcode, city, country } = this.getAddressDetails(results[0].address_components);
                            registerNextBtnValidation({ town: city });
                            registerNextBtnValidation({ country: country });
                            registerNextBtnValidation({ postCode: postcode });
                            this.validStreetName(location);
                          }
                        )
                      }
                    }}
                  />
                    <Typography
                      style={{
                        ...FAMILYFONTS.family3,
                        fontSize: 12,
                        color: "#fd1d1d",
                        marginTop: 10
                      }}
                    >
                      {this.state.StreetNameError}
                    </Typography>
                  <TextField
                    name="town"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Town*"
                    data-testid="townTest"
                    value={town}
                    style={{marginTop: '15px'}}
                    InputProps={{
                      classes: {
                        root: classesOuter.registerTextField,
                      },
                    }}
                    inputProps={{
                      style: {
                        borderColor: "#d5d5d5",
                      },
                      onFocus: this.handleInputFocus,
                      onBlur: this.handleInputBlur,
                    }}
                    onChange={(e) => {
                      registerNextBtnValidation({ town: e.target.value })
                      this.validTown(e.target.value)
                    }}
                    required                    
                    fullWidth
                  />
                  <Typography
                    style={{
                      ...FAMILYFONTS.family3,
                      fontSize: 12,
                      color: "#fd1d1d",
                      marginTop: 10
                    }}
                  >
                    {this.state.townError}
                  </Typography>
                  <Box style={{display: 'flex',justifyContent: 'space-between'}}>
                    <Box style={{display: 'flex',flexDirection: 'column'}}>
                      <TextField
                        name="country"
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Country*"
                        data-testid="countryTest"
                        value={country}
                        style={{marginTop: '15px',width: "90%"}}
                        InputProps={{
                          classes: {
                            root: classesOuter.registerTextField,
                          },
                        }}
                        inputProps={{
                          style: {
                            borderColor: "#d5d5d5",
                          },
                          onFocus: this.handleInputFocus,
                          onBlur: this.handleInputBlur,
                        }}
                        onChange={(e) => {
                          registerNextBtnValidation({ country: e.target.value })
                          this.validCountry(e.target.value)
                        }}
                        required                        
                        fullWidth
                      />
                      <Typography
                        style={{
                          ...FAMILYFONTS.family3,
                          fontSize: 12,
                          color: "#fd1d1d",
                          marginTop: 10
                        }}
                      >
                        {this.state.countryError}
                      </Typography>
                    </Box>
                    <Box style={{display: 'flex',flexDirection: 'column'}}>
                      <TextField
                        name="postCode"
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Postcode*"
                        data-testid="postCodeTest"
                        value={postCode}
                        style={{marginTop: '15px',width: "90%"}}
                        InputProps={{
                          classes: {
                            root: classesOuter.registerTextField,
                          },
                        }}
                        inputProps={{
                          style: {
                            borderColor: "#d5d5d5",
                          },
                          onFocus: this.handleInputFocus,
                          onBlur: this.handleInputBlur,
                        }}
                        onChange={(e) => {
                          registerNextBtnValidation({ postCode: e.target.value })
                          this.validPostCode(e.target.value)
                        }}
                        required
                        fullWidth
                      />
                      <Typography
                        style={{
                          ...FAMILYFONTS.family3,
                          fontSize: 12,
                          color: "#fd1d1d",
                          marginTop: 10
                        }}
                      >
                        {this.state.postCodeError}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={classesOuter.labelInputBox} style={{display: 'flex',justifyContent: 'space-between'}}>
                <label className={classesOuter.labelForInput}>
                  Office address different from above?
                </label>
                <AntSwitch name="switchStatus" data-testid="switchTest"  
                onChange={(e) => {
                  registerNextBtnValidation({ switchStatus: !switchStatus })
                }}
                defaultValue={switchStatus}
                checked={switchStatus}
                />
              </Box>
              {switchStatus === true ?
              <>
              <Box className={classesOuter.labelInputBox}>
                <Box className={`${classesOuter.inputField} address-location business`}>
                  <GooglePlacesAutocomplete
                    data-testid="streetNameTest"
                    apiKey={configJSON.mapsAPIKey}
                    debounce={500}
                    selectProps={{
                      isClearable: true,
                      classNamePrefix: "react-select",
                      placeholder: "Street*",
                      value: officeStreetName ? [{ label: officeStreetName, value: officeStreetName }] : undefined,
                      noOptionsMessage: () => null,
                      onChange: async (event) => {
                        const location = event?.value?.description ?? "";
                        registerNextBtnValidation({ officeStreetName: location });
                        registerNextBtnValidation({ officeTown: "" });
                        registerNextBtnValidation({ officeCountry: ""});
                        registerNextBtnValidation({ officePostCode: "" });
                        this.validOfficeStreetName(location);
                        this.validOfficeTown("");
                        this.validOfficeCountry("");
                        this.validOfficePostCode("");
                        geocodeByAddress(location)
                          .then(results => {
                            const { postcode, city, country } = this.getAddressDetails(results[0].address_components);
                            registerNextBtnValidation({ officeTown: city });
                            registerNextBtnValidation({ officeCountry: country });
                            registerNextBtnValidation({ officePostCode: postcode });
                            this.validOfficeTown(city);
                            this.validOfficeCountry(country);
                            this.validOfficePostCode(postcode);
                          }
                        )
                      }
                    }}
                  />
                  <Typography
                    style={{
                      ...FAMILYFONTS.family3,
                      fontSize: 12,
                      color: "#fd1d1d",
                      marginTop: 10
                    }}
                  >
                    {this.state.officeStreetNameError}
                  </Typography>
                  <TextField
                    style={{marginTop: '15px'}}
                    variant="outlined"
                    required
                    data-testid="officeTownTest"
                    name="officeTest"
                    value={officeTown}
                    InputProps={{
                      classes: {
                        root: classesOuter.registerTextField,
                      },
                    }}
                    inputProps={{
                      style: {
                        borderColor: "#d5d5d5",
                      },
                      onFocus: this.handleInputFocus,
                      onBlur: this.handleInputBlur,
                    }}
                    onChange={(e) => {
                      registerNextBtnValidation({ officeTown: e.target.value })
                      this.validOfficeTown(e.target.value)
                    }}
                    fullWidth
                    placeholder="Town*"
                    autoComplete="off"
                  />
                  <Typography
                    style={{
                      ...FAMILYFONTS.family3,
                      fontSize: 12,
                      color: "#fd1d1d",
                      marginTop: 10
                    }}
                  >
                    {this.state.officeTownError}
                  </Typography>
                  <Box style={{display: 'flex',justifyContent: 'space-between'}}>
                    <Box style={{display: 'flex',flexDirection: 'column'}}>
                      <TextField
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Country*"
                        data-testid="officeCountryTest"
                        name="officeCountry"
                        value={officeCountry}
                        InputProps={{
                          classes: {
                            root: classesOuter.registerTextField,
                          },
                        }}    
                        inputProps={{
                          style: {
                            borderColor: "#d5d5d5",
                          },
                          onFocus: this.handleInputFocus,
                          onBlur: this.handleInputBlur,
                        }}
                        onChange={(e) => {
                          registerNextBtnValidation({ officeCountry: e.target.value })
                          this.validOfficeCountry(e.target.value)
                        }}
                        style={{marginTop: '15px',width: "90%"}}
                        required                      
                        fullWidth
                      />
                      <Typography
                        style={{
                          ...FAMILYFONTS.family3,
                          fontSize: 12,
                          color: "#fd1d1d",
                          marginTop: 10
                        }}
                      >
                        {this.state.officeCountryError}
                      </Typography>
                    </Box>
                    <Box style={{display: 'flex',flexDirection: 'column'}}>
                      <TextField
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Postcode*"
                        data-testid="officePostCodeTest"
                        name="officePostCode"
                        value={officePostCode}
                        InputProps={{
                          classes: {
                            root: classesOuter.registerTextField,
                          },
                        }}
                        inputProps={{
                          style: {
                            borderColor: "#d5d5d5",
                          },
                          onFocus: this.handleInputFocus,
                          onBlur: this.handleInputBlur,
                        }}
                        onChange={(e) => {
                          registerNextBtnValidation({ officePostCode: e.target.value })
                          this.validOfficePostCode(e.target.value)
                        }}
                        style={{marginTop: '15px',width: "90%"}}
                        required
                        fullWidth
                      />
                      <Typography
                        style={{
                          ...FAMILYFONTS.family3,
                          fontSize: 12,
                          color: "#fd1d1d",
                          marginTop: 10
                        }}
                      >
                        {this.state.officePostCodeError}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              </>: null}
              <Box className={classesOuter.fieldMandatoryNP}>
                <Typography style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 14,
                  fontWeight: 400,
                }} className={classesOuter.fieldMandatoryNPSentence}>
                  Fields marked with '<span>*</span>' are mandatory fields.
                </Typography>
              </Box>
            </div>
          </form>
        </Container>
      </>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

 // Customizable Area Start

export {RegistrationFormPart1Business}
export default withStyles(styles as any)(RegistrationFormPart1Business);

// Customizable Area End
