import * as eventActionTypes from './eventsInnerTabActions';

const initialstate = {
  selectedInnerEvents:
    JSON.parse(localStorage.getItem('selectedInnerEvents') as any) ||
    new Array()
};

const eventsInnerTabReducer = (
  state = initialstate,
  action: { type: any; payload: { selectedEvents: any } }
) => {
  if (action.type === eventActionTypes.SET_SELECTED_INNER_TABS) {
    return {
      ...state,
      selectedInnerEvents: action.payload.selectedEvents
    };
  } else {
    return state;
  }
};

export default eventsInnerTabReducer;
