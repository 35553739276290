Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Gamification";
exports.labelBodyText = "Gamification Body";
exports.putApiMethodType = "PUT";
exports.btnExampleTitle = "CLICK ME";
exports.donatePointsEndpoint = "/bx_block_gamification/donate_points";
exports.getRangeEndpoint = "/bx_block_gamification/earned_points/get_earn_points_and_range";
exports.getPointsData = "/bx_block_gamification/user_point";
exports.businessAPIEndpoint = "bx_block_profile/business_activity"
// Customizable Area End