// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { emailIcon } from "./assets";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import OtpInput from "react-otp-input";
import { useGetEmailOTP, useVerifyEmailOTP } from "../../../components/src/api/hooks/user.hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../framework/src/redux/stores";
import { useNavigate } from "../../../components/src/utils/router-context";
import { InitiateAlert } from "../../../components/src/design-system/alert";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { HideContainer } from "../../../components/src/shared/hide-container";
import { InitiateButton } from "../../../components/src/design-system/button";
import { RegisterationMobileHeader } from "./RegisterationMobileHeader";




export const useVerifyEmailStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'min(655px, 80vh)',
    [theme.breakpoints.down('sm')]: {
      height: '90vh',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    backgroundImage: "linear-gradient(#00cbf3,#009be2)",
    width: "168px",
    height: "168px",
    minWidth: "168px",
    minHeight: "168px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    margin: "auto"
  },
  icon: {
    maxWidth: '82px',
    width: "82px",
    height: "82px",
  },
  titleText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  subTitleText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#2c2c2e',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
    },
  },
  btn: {
    fontSize: '1rem',
  },
  submitBtn: {
    fontFamily: 'Rene_Bieder_Milliard_Medium',
    fontSize: '1.5rem',
  },
  otpInput: {
    '& input': {
      width: "44px !important",
      height: "50px",
      border: "none",
      borderBottom: "1px solid #ccc !important",
      fontSize: "20px !important",
      fontWeight: "400 !important",
      fontFamily: "Rene_Bieder_Milliard_Book,sans-serif !important",
      margin: '0 6px',
      [theme.breakpoints.down('sm')]: {
        width: '36px !important',
      },
    },
  },
  otpInputError: {
    '& input': {
      color: 'red',
      borderBottom: "1px solid red !important",
    },
  },
  verticalSpacer:{
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
}));


export function EmailVerificationPage() {

  const classes = useVerifyEmailStyles();
  const navigate = useNavigate();

  const { mutate: getOTP } = useGetEmailOTP();
  const { mutate: verify, isLoading, error } = useVerifyEmailOTP();

  const { email } = useSelector((state: RootState) => state.signupForm);

  const [code, setCode] = useState('');
  const [otpToken, setOtpToken] = useState('');

  useEffect(function checkToken() {
    if(!localStorage.getItem("token")){
      navigate('/EmailAccountRegistrationweb');
    }
  }, []);

  function verifyOTP() {
    verify({ token: otpToken, email_otp: code }, {
      onSuccess() {
        navigate('/MobileVerificationweb');
      }
    });
  }

  function getOTPHandler() {
    getOTP({ email }, {
      onSuccess(response) {
        setOtpToken(response?.meta?.token ?? '')
      }
    });
  }

  useEffect(getOTPHandler, [email]);

  let disabled = code.length < 6;
  const errorObj = error?.errors?.[0];
  let errorMessage = errorObj?.otp || errorObj?.pin || error?.error || '';
  let verifyEmailOtpError = Boolean(errorMessage);

  return (
    <>
      <div className={clsx(classes.container, classes.formContentRoot)}>

        <VerticalSpacer value={30} />

        <div className={classes.iconContainer}>
          <img src={emailIcon} alt="email" className={classes.icon} />
        </div>

        <VerticalSpacer value={30} />

        <Typography color="primary" className={classes.titleText}>
          {'Email Verification!'}
        </Typography>

        <div className={classes.formContent}>

          <VerticalSpacer value={30} />

          <Typography className={classes.subTitleText}>
            {'Please enter the verification code sent to your registered email address'}
          </Typography>

          <VerticalSpacer value={40} />

          <OtpInput
            value={code}
            onChange={setCode}
            numInputs={6}
            className={clsx(classes.otpInput, {
              [classes.otpInputError]: verifyEmailOtpError
            })}
            containerStyle={{ justifyContent: 'center' }}
            isInputNum
          />

          <HideContainer hideWhen={!verifyEmailOtpError}>
            <InitiateAlert cautionIcon>
              {errorMessage}
            </InitiateAlert>
          </HideContainer>

          <hr className={classes.verticalSpacer}/>
          <Box textAlign="center">
            <Typography className={classes.subTitleText}>
              {'Didn\'t receive a code?'}
              <InitiateButton variant="text" color="primary" onClick={getOTPHandler} className={classes.btn}>
                {'Resend Code'}
              </InitiateButton>
            </Typography>
          </Box>
          <VerticalSpacer value={5} />
        </div>

        <InitiateButton
          loading={isLoading}
          type="submit"
          disabled={disabled}
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          className={classes.submitBtn}
          onClick={verifyOTP}>
          {`Verify Email`}
        </InitiateButton>
        <VerticalSpacer value={30} />

      </div>

      <RegisterationMobileHeader
        title=""
        hideSkipButton
      />
    </>
  );
}





// Customizable Area End
