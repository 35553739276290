import React from "react";

// Customizable Area Start
import {
  Typography,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import "../assets/css/ForgotPassword.css";
import { validEmail } from "./assets";
import { icons, FAMILYFONTS } from "../../../components/src/constants";
import { isMobile } from "react-device-detect";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { InitiateTextField } from "../../../components/src/design-system/text-field";
import { InitiateButton } from "../../../components/src/design-system/button";
import { InitiateAlert } from "../../../components/src/design-system/alert";
import { HideContainer } from "../../../components/src/shared/hide-container";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";

// Customizable Area Start
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    // remove if any previous records exits.
    window.sessionStorage.removeItem("otp-meta");
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const validateInput = !this.state.emailValue;
    const emailErrorValidation = !this.state.validEmailIcon;
    const disabled = validateInput || emailErrorValidation;
    const loading = this.state.sendCodeLoading;

    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          padding: '40px 0',
        }}
      >
        <Typography
          variant="h4"
          color="primary"
          style={{
            ...FAMILYFONTS.family1,
            fontSize: 33,
            fontWeight: 400,
            textAlign: isMobile ? "center" : 'left',
            lineHeight: '65px',
          }}
        >
          {'Forgot Password'}
        </Typography>

        <form onSubmit={this.handleSubmit}>

          {/* Form Content */}
          <div>

            {/* Forgot password field */}
            <Typography
              className="forgotPwdField"
              style={{
                ...FAMILYFONTS.family3,
                fontSize: '17px',
                fontWeight: 400
              }}
            >
              {'Please enter your registered email address to receive a verification code'}
            </Typography>

            <InitiateTextField
              data-test-id="txtInputEmail"
              placeholder={"Email address"}
              fullWidth
              value={this.state.emailValue}
              name="email"
              onChange={(e: any) => {
                this.setEmail(e.target.value);
                this.validateEmail(e.target.value);
              }}
              variant="outlined"
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      {this.state.validEmailIcon ? (
                        <img src={validEmail} alt="validEmail" />
                      ) : (
                          <img
                            src={icons.rightIcon}
                            alt="validEmail"
                            style={{ display: "none" }}
                          />
                        )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <HideContainer hideWhen={!this.state.emailError}>
              <InitiateAlert>
                {this.state.emailError}
              </InitiateAlert>
            </HideContainer>

          </div>

          <VerticalSpacer value={120}/>

          {/* Footer Content */}
          <div>

            <InitiateButton
              data-test-id={"btnEmailLogIn"}
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              onClick={this.sendOTP}
              style={{
                opacity: disabled ? "0.5" : "1",
                cursor: disabled ? "not-allowed" : "pointer",
                ...FAMILYFONTS.family1,
                fontSize: 20,
              }}
              disabled={disabled || loading}
              loading={loading}
            >
              {'Send code'}
            </InitiateButton>

          </div>
        </form>
      </div>
      // Customizable Area End
    );
  }
}
