// Customizable Area Start
// @ts-nocheck
import React from "react";
import {
    Container,
    Box,
    Typography,
    CircularProgress,
    IconButton,
    Button
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import CheckIcon from '@material-ui/icons/Check';
import BusSubscriptionPlanController, {
    Props,
} from "./BusSubscriptionPlanController.web";
import BusDashbaordWeb from "./BusDashboard.web";
import { SubscriptionImage } from "./assets";
import { icons } from "../../../components/src/constants";
import Carousel from 'react-elastic-carousel'
import { withRouter } from "react-router-dom";

class BusSubscriptionPlan extends BusSubscriptionPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    setId = (id:any,isCurrent:boolean) =>{
        let res:any = null
        if(localStorage.getItem("expired") === 'true'){
            res = (id !== '19')?id:null
        } 
        else if(!isCurrent) return id
            
        return res;
    }
    onPlanClick = (plans: any) => {
        if ( !plans.isExpired && !plans.isTrialPurchased) {
            this.setState({type: plans.type,selectedPlanId: this.setId(plans.id,plans.isCurrent),planAmount: plans.amount, planTitle: plans.title, days: plans.days, discountAmount: (plans.discontAmount/100) });
        }
    }
     
    renderCurrentPlan = () => {
        return  (<div className="selected-btn current-plan">
        <div>Current Plan</div>
         </div>);
    }
    renderCardDetail = () => {
        return this.state.subusPlanDetails.length > 0 ? this.state.subusPlanDetails.map((plans: any, index: number) => (
            <div className={`card-wrapper ${this.state.selectedPlanId === plans.id && "selected"}`} key={`${index + 1}`}>
                <div
                    data-testId={`card-${index}`}
                    className={`card ${!plans.isExpired && !plans.isTrialPurchased && 'active-card'}`}
                    onClick={() => {this.onPlanClick(plans)}}
                >
                    <div className="top">
                        <Typography className="plan-title">{plans.title}</Typography>
                        <Typography className="charge">£ {plans.amount}</Typography>
                        {plans.type === "addOn"?
                        <Box margin="18px 0px"/>
                        :<Typography className="time">{plans.duration === 90 ? 'Per Quarter' : `For ${plans.duration} Days`} </Typography>}
                    </div>
                    <div className="bottom">
                        {plans.descriptions.map((description: string, index2: number) => (
                            <Box display="flex" mb="15px" key={`description-${index2 + 1}`}>
                                <CheckIcon className="check" />
                                <Typography className="description">{description}</Typography>
                            </Box>
                        ))}
                        {plans.notes.map((note: string, index3: number) => (
                            <Box display="flex" mb="15px" key={`${index3 + 1}`}>
                                <span className="star">*</span>
                                <Typography className="notes">{note}</Typography>
                            </Box>
                        ))}
                    </div>
                    <Box pb="45px">
                        <div className="selected-btn select-btn">
                            <div>Selected</div>
                        </div>
                        {localStorage.getItem("expired")==='true'?plans.id==='19' && this.renderCurrentPlan() :
                           plans.isCurrent &&
                           this.renderCurrentPlan()
                        }
                    </Box>
                </div>
            </div>
        )) : <Typography
            style={{
                textAlign: "center",
                height: "50%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                fontFamily: "Rene_Bieder_Milliard_Medium",
            }}
        >
            Sorry, No Plan available
        </Typography>
    }

    renderPagination = ({ pages, activePage, onClick }: any) => {
        return (
            <Box display="flex" alignItems="center" mt="30px">
                {pages.map((page: any) => {
                    const isActivePage = activePage === page;
                    return isActivePage ?
                        <div className="active" onClick={() => onClick(page)}><div /></div>
                        : <div className="in-active" onClick={() => onClick(page)} />
                })}
            </Box>
        )
    }

    fixedRecallCondition = () => {
        return (
            this.props.location.state?.pageTitle === 'Subscribe' ? 'Change your plan according to your needs' : 'To keep using this account after the trial ends, set your subscription now.'
        )
    }

    subscriptionNoteText() {
        return !localStorage.getItem('plan') ? 'Start your 14 days FREE trial to get access' : this.fixedRecallCondition()
    }
    
    planTitleText() {
        return this.props.location.state?.pageTitle !== undefined ? this.props.location.state?.pageTitle : 'Select'
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        let payable = this.state.planAmount - this.state.discountAmount;
        if (payable < 0) {
            payable = 0;
        }
        return (
            // @ts-ignore
            <BusDashbaordWeb children={this.props.children} classes={this.props.classes} navigation={this.props.navigation} id={""} adminSelectedTab={undefined} theme={undefined}
            >
                <Container maxWidth="xl" style={{ marginLeft: 280, padding: 50, width: 'auto' }}>
                    <Wrapper style={{ padding: 25, background: 'white', borderRadius: '12px' }}>
                        {this.state.loader ? <div
                            style={{
                                height: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress style={{ color: "#009be2" }} />
                        </div> :
                            <Box>
                                <Box>
                                    <Box>
                                        <Box display="flex" alignItems="center">
                                            <IconButton
                                                onClick={() => this.props.navigation.goBack()}
                                                data-testId='backBtn'
                                            >
                                                <img src={icons.back} />
                                            </IconButton>
                                            <Typography className="page-title">Business subscriptions</Typography>
                                        </Box>
                                        <Box style={{marginLeft:10}}>
                                        <Box  display="flex" alignItems="center" mt="25px">
                                            <img className="round" src={SubscriptionImage} />
                                            <Box>
                                                <Typography className="title">Subscription</Typography>
                                                <Typography className="description">{this.subscriptionNoteText()}</Typography>
                                            </Box>
                                        </Box>
                                        <Box mt="30px">
                                            <Typography className="select-plan">{this.planTitleText()} Plan</Typography>
                                            <Box className="card-container">
                                                <Carousel
                                                    itemsToShow={3}
                                                    isRTL={false}
                                                    showEmptySlots
                                                    breakPoints={breakPoints}
                                                    renderPagination={this.renderPagination}
                                                >
                                                    {this.renderCardDetail()}

                                                </Carousel>

                                            </Box>
                                        </Box>
                                        </Box>

                                        {this.state.selectedPlanId !== '' &&
                                            <Box className={'cartDetails'}>
                                                <div className="sub-title">Subscription Details</div>
                                                <Box display="flex" justifyContent="space-between" mb="10px">
                                                    <div className="sub-charges">{this.state.planTitle}</div>
                                                    <div className="plan-amount">£ {this.state.planAmount}</div>
                                                </Box>
                                               {this.state.type !== "addOn" && <Box display="flex" justifyContent="space-between" mb="10px">
                                                    <div className="sub-charges">- Discount Amount</div>
                                                    <div className="plan-amount">£ {this.state.discountAmount}</div>
                                                </Box>}
                                                <div className="border" />
                                                <Box display="flex" justifyContent="space-between">
                                                    <div className="total">Total</div>
                                                    <div className="plan-amount">£ {payable.toFixed(2)}</div>
                                                </Box>
                                            </Box>}


                                        <Box mt="40px" mb="15px" display="flex" justifyContent="center">
                                            <Button className="continue" disabled={this.state.selectedPlanId === ""} data-testId='continueBtn' onClick={() => this.navigateToSubscribePaymentPage()}>
                                                Pay £ {payable.toFixed(2)}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>}
                    </Wrapper>
                </Container>
            </BusDashbaordWeb>
        );
        // Customizable Area End
    }
}
export { BusSubscriptionPlan }
export default withRouter(BusSubscriptionPlan);

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1450, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1750, itemsToShow: 3, itemsToScroll: 3 },
]

const Wrapper = styled(Box)({
    "& .MuiTypography-root": {
        fontFamily: "Rene_Bieder_Milliard_SemiBold"
    },
    "& .rec-item-wrapper": {
        height: "100%",
    },
    "& .rec-slider-container": {
        boxShadow: "none",
        margin: "0 -9px !important"
    },
    "& .rec.rec-arrow": {
        display: "none"
    },
    "& .in-active": {
        cursor: "pointer",
        margin: "0px 3px",
        backgroundColor: "#B9B9B9",
        width: "14px",
        height: "14px",
        borderRadius: "50%",
    },
    "& .description": {
        fontSize: "12px",
        color: "rgba(0,0,0,0.59)",
        letterSpacing: "-0.39999998px"
    },
    "& .round": {
        width: "65px",
        height: "65px",
        borderRadius: "50%",
        marginRight: "20px"
    },
    "& .select-plan": {
        fontSize: "20px",
        letterSpacing: "-0.55px",
        marginLeft: 5
    },
    "& .page-title": {
        fontSize: '22px',
    },
    "& .title": {
        fontSize: "20px",
        letterSpacing: "-0.55px"
    },
    "& .continue": {
        color: "#fff",
        padding: "10px 55px",
        backgroundColor: "#019BE3",
        borderRadius: "7px",
        textTransform: "none",
        "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.26) !important"
        },
    },
    "& .active-card": {
        "&:hover": {
            cursor: "pointer",
        },
        "& .top": {
            backgroundImage: "linear-gradient(135deg, #0051f3 0%, #00e2b7 100%)",
            "& p": {
                color: "#fff !important"
            },
        },
        "& .bottom": {
            paddingBottom: "0px !important",
            "& .check": {
                color: "#009be2 !important"
            },
            "& .description": {
                color: "#000 !important"
            }
        }
    },
    "& .card-container": {
        gap: "20px",
        display: "flex",
        overflow: "auto",
        padding: "20px 4px",
        "& .card-wrapper": {
            padding: "25px 0px",
            width: "100%",
            margin: "0px 10px",
            "&:hover": {
                // padding: "0px"
                transform: "scale(1, 1.09)"
            },
            "&.selected": {
                "& .active-card": {
                    border: "1px solid #009be2",
                },
                "& .selected-btn": {
                    display: "block",
                    transform: "scale(1, 1.09)",
                    "&.select-btn": {
                        fontFamily: "Rene_Bieder_Milliard_SemiBold"
                    }
                },
            },
            "&:hover, &.selected": {
                transform: "scale(1, 1.09)",
                "& .selected-btn": {
                    transform: "scale(1, 1.09)",
                },
                "& .check, .description, .time, .charge, .plan-title, .notes, .selected-btn div": {
                    transform: "scale(1, 0.9)"
                },
            }
        },
        "& .card": {
            position: "relative",
            height: "100%",
            transition: "all 0.3s ease",
            borderRadius: "12px",
            boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
            "& .top": {
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                padding: "14px 19px",
                backgroundColor: "#E9E9E9",
                "& .plan-title": {
                    fontFamily: "Rene_Bieder_Milliard_Bold",
                    marginBottom: "10px",
                    fontSize: "20px",
                    color: "#787878",
                    letterSpacing: "0.13636364px"
                },
                "& .charge": {
                    fontFamily: "Rene_Bieder_Milliard_SemiBold",
                    fontSize: "18px",
                    color: "#787878"
                },
                "& .time": {
                    fontFamily: "Rene_Bieder_Milliard_Book",
                    fontSize: "12px",
                    color: "#949494"
                }
            },
            "& .bottom": {
                padding: "20px",
                paddingRight: "34px",
                "& .check": {
                    marginRight: "5px",
                    height: "20px",
                    color: "#C8C8C8"
                },
                "& .description": {
                    lineHeight: "17px",
                    fontSize: "14px",
                    fontFamily: "Rene_Bieder_Milliard_Book",
                    color: "#9A9A9A",
                    letterSpacing: "-0.35px"
                },
                "& .notes": {
                    fontFamily: "Rene_Bieder_Milliard_Book",
                    color: "#87888f",
                    fontSize: "10px",
                },
                "& .star": {
                    color: "#009be2",
                    margin: "0px 12px"
                }
            },
            "& .selected-btn": {
                display: "none",
                position: "absolute",
                left: "0px",
                bottom: "0px",
                width: "100%",
                paddingBottom: "45px",
                "&.current-plan": {
                    display: "block"
                },
                "& div": {
                    backgroundColor: "#b0e6ff",
                    position: "absolute",
                    bottom: "0px",
                    width: "100%",
                    height: "45px",
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }
            }
        },
    },
    "& .active": {
        margin: "0px 3px",
        border: "1px solid black",
        padding: "2px",
        borderRadius: "50%",
        cursor: "pointer",
        "& div": {
            backgroundColor: "#525252",
            width: "14px",
            height: "14px",
            borderRadius: "50%",
        }
    },
    "& .cartDetails": {
        boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
        padding: "30px 45px",
        borderRadius: "12px",
        marginTop: "30px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        "& .sub-title": {
            fontSize: "20px",
            fontFamily: "Rene_Bieder_Milliard_Bold",
            letterSpacing: "-0.7px",
            marginBottom: "15px"
        },
        "& .plan-amount": {
            fontSize: "20px",
            fontFamily: "Rene_Bieder_Milliard_Bold",
            letterSpacing: "-0.5px",
        },
        "& .sub-charges": {
            fontSize: "18px",
            fontFamily: "Rene_Bieder_Milliard_Medium",
            letterSpacing: "-0.45px",
            " & span": {
                color: "#87888f",
                cursor: "pointer",
                borderBottom: "1px solid #87888f",
                marginLeft: "10px"
            }
        },
        "& .border": {
            margin: "10px 0px",
            border: "1px solid #bebebe"
        },
        "& .total": {
            fontSize: "18px",
            fontFamily: "Rene_Bieder_Milliard_Medium",
            letterSpacing: "-0.45px",
        }
    },

});

// Customizable Area End 