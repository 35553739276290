// Customizable Area Start
import React from "react";
import "../assets/css/Settings5.css";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import {
  Typography
} from "@material-ui/core";
import Settings5Controller from "./Settings5Controller";
export default class PrivacyPolicy extends Settings5Controller {
  render() {
    return (
      <div>
        <div>
          <div className="mob-header">
            <MobileHeader title="Privacy Policy" navigation={this.props.navigation} />
          </div>
         <Typography style={{whiteSpace: 'pre-wrap',
         fontFamily:"Rene_Bieder_Milliard_Book",
         display:"block", 
         fontSize:'0.8rem',
         textAlign: 'justify', 
         paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'10px'}}>{this.state.privacyPolicyDescription}</Typography>

        </div>
      </div>
    );
  }
}
// Customizable Area End
