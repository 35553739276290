// Customizable Area Start
import React from "react";


import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { TermsAndConditionContent } from "../../../components/src/shared/terms-modal";
import { useNavigate } from "../../../components/src/utils/router-context";
import { useAcceptTerms } from "../../../components/src/api/hooks/user.hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../framework/src/redux/stores";

class TermsAndCondnsComp extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    return (
      <TermsAndConditionForm />
    );
  }
}

function TermsAndConditionForm() {

  const navigate = useNavigate();
  const { mutate: acceptTerms } = useAcceptTerms();
  const { accountId } = useSelector((state: RootState) => state.signupForm);

  return (
    <TermsAndConditionContent
      onAccept={() => {
        acceptTerms({ accountId: parseInt(accountId) }, {
          onSuccess() {
            navigate("/AccountVerifiedSuccessweb")
          }
        })
      }} modalValue="" />
  );
}


export default TermsAndCondnsComp;
export { TermsAndCondnsComp };


// Customizable Area End