Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.deleteApiType = "DELETE";
exports.validationApiPOSTMethodType = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Events";
exports.labelBodyText = "Events Body";
exports.acceptRequestEndPoint = "/events/requests/accepted";
exports.createEventEndPoint = "/events/events";
exports.GOOGLE_API_KEY = "AIzaSyAQu429K52drmir9450TvUYcy82ZL3QQ9I";
exports.GOOGLE_PACES_API_BASE_URL = "https://maps.googleapis.com/maps/api/place";
exports.GOOGLE_MAP_BASE_URL = "https://www.google.com/maps/dir/";
exports.confirmPladgespApiEndPoint = "/my_pledges";
exports.ViewPladgespApiEndPoint = "/my_pledges/view_pledges";
exports.cancelPladgespApiEndPoint = "/my_pledges";
exports.pledgesActiveTabDataEndPoint = "my_pledges/active_pledges";
exports.pledgesPastTabDataEndPoint = "my_pledges/past_pledges";
exports.joinChatApiEndPoint = "bx_block_groupchat/join_chat";
exports.getMessageNpApiEndPoint="bx_block_groupchat/create_business_conversation";
exports.joinChatApiType = 'POST';

exports.getAPIMethod = "GET";
exports.deleteAPIMethod = "DELETE";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";

exports.getConnectionApiEndpoint = "/bx_block_add_friends/connected_friends";
exports.favouriteApiEndpoint = "bx_block_favourites/favourites";
exports.activePledgeApiEndpoint = '/my_pledges/active_pledges';
exports.pastPledgeApiEndpoint = "my_pledges/past_pledges";
exports.cancelPledgeApiEndpoint = "bx_block_events/needs";
exports.eventDetailApiEndPoint = "non_profit_events";
exports.donatePointsApiEndPoint = "/bx_block_gamification/donate_points";
exports.updateMediaCommentEndPoint = "/bx_block_upload_media/comments/"
exports.getActivityLog = "/bx_block_profile/user_activity_logs";

// Customizable Area End
