// Customizable Area Start
import React, { useEffect, useState, } from "react";
import { Typography, Checkbox, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { InitiateButton } from "../../../components/src/design-system/button";
import { TermsAndConditionModal } from "../../../components/src/shared/terms-modal";
import { FormikTextField } from "../../../components/src/shared/formik-text-field";
import { useUserSignup } from "../../../components/src/api/hooks/user.hooks";
import { useNavigate } from "../../../components/src/utils/router-context";
import { useDispatch, useSelector } from "react-redux";
import { saveSignupForm } from "../../../framework/src/redux/signup-form/signup-form.actions";
import { RootState } from '../../../framework/src/redux/stores';
import { InitiateAlert } from "../../../components/src/design-system/alert";
import { HideContainer } from "../../../components/src/shared/hide-container";
import { InitiatePhoneInput } from '../../../components/src/shared/phone-input';
import { InitiateCheckbox } from "../../../components/src/design-system/checkbox";
import { Account } from "../../../components/src/types/account";
import { RegisterationMobileHeader } from "./RegisterationMobileHeader";

const initialValues = {
  full_name: "",
  email: "",
  full_phone_number: "",
  password: "",
  password_confirmation: "",
  acceptTerms: false,
};

const nameReg = /^[A-Z][a-z]+[,.'-]?(?: [A-Z][a-z]+[,.'-]?)*$/i;

let validationSchemaobj = Yup.object().shape({
  full_name: Yup.string()
    .trim()
    .matches(nameReg, "Please enter valid name")
    .required("Please enter full name"),
  email: Yup.string()
    .email("Please enter valid email address")
    .required("Please enter email address"),
  full_phone_number: Yup.string()
    .min(12, "Please enter valid mobile number")
    .max(12, "Please enter valid mobile number")
    .required("Please enter mobile number "),
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must be of 8 characters with a capital letter, a special character & a number."
    ),
  password_confirmation: Yup.string()
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password did not match."
      ),
    })
    .required("Please enter confirm password"),
  acceptTerms: Yup.bool().oneOf([true]),
});



const useSignupFormStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'min(610px, 80vh)',
    [theme.breakpoints.down('sm')]: {
      height: 'max-content',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  signupText: {
    fontSize: '2.5rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  notice: {
    color: '#656469',
    fontSize: '0.8rem',
    fontFamily: 'Rene_Bieder_Milliard_Book',
  },
  warn: {
    color: '#B86D62',
  },
  checkboxText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#9a99a0',
  },
  darkColor: {
    color: "black"
  },
  submitButton: {
    fontSize: '1.5rem',
  },
  loginText: {
    textAlign: 'center',
    color: '#656469',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));




export function SignupForm() {
  const classes = useSignupFormStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signupForm = useSelector((state: RootState) => state.signupForm);

  const formPrevState = {
    email: signupForm.email,
    acceptTerms: false,
    full_phone_number: signupForm.full_phone_number,
    full_name: signupForm.full_name,
  }

  const { mutate: signup, error: signupErrors, isLoading } = useUserSignup();
  let apiErrors = signupErrors?.errors ?? '';
  let generalError = '';

  if (typeof apiErrors != 'string' && apiErrors.length) {
    apiErrors = apiErrors?.[0];
  } else {
    generalError = String(apiErrors);
  }

  const accountError = apiErrors?.account;

  const [phoneNumberError, setPhoneNumerError] = useState('');
  const [isPhoneNumberTouched, setIsPhoneNumberTouched] = useState(false);
  const [modalValue, setModalValue] = useState("");


  useEffect(function setPhoneError() {
    const phoneNumberError = apiErrors?.full_phone_number || apiErrors?.phone_number;
    setPhoneNumerError(phoneNumberError);
  }, [apiErrors]);


  useEffect(function resetPhoneError() {
    if (phoneNumberError && isPhoneNumberTouched) {
      setPhoneNumerError('');
    }
  }, [isPhoneNumberTouched, phoneNumberError]);

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('acc_id');
    localStorage.removeItem('email');
    localStorage.removeItem('full_phone_number');
    localStorage.removeItem('full_name');
  },[]);

  return (
    <>
      <Formik
        initialValues={{ ...initialValues, ...formPrevState }}
        validationSchema={validationSchemaobj}
        onSubmit={(values) => {

          const {
            acceptTerms,
            ...signupPayload
          } = values;

          signup(signupPayload, {
            onSuccess(response: { data: Account, meta: { token: string } }) {

              const isVerifiedEmail = response?.data?.attributes?.email_verified;
              const isVerifiedMobile = response?.data?.attributes?.mobile_verified;

              dispatch(saveSignupForm({
                ...signupPayload,
                full_phone_number: `+${signupPayload.full_phone_number}`,
                accountId: response?.data.id ?? ""
              }));

              if (isVerifiedMobile)
                navigate('/PersonalInformationweb');
              else if (isVerifiedEmail)
                navigate('/MobileVerificationweb');
              else
                navigate('/EmailVerificationweb');

              localStorage.setItem('token', response?.meta.token);
              localStorage.setItem('acc_id', response?.data.id);
              localStorage.setItem('email', response?.data.attributes.email);
              localStorage.setItem('full_phone_number', response?.data.attributes.full_phone_number);
              localStorage.setItem('full_name', response?.data.attributes.full_name);
              localStorage.setItem("isLogin", "false");
            }
          });

        }}
        enableReinitialize={true}
      >
        {({ isValid, values, setFieldValue, errors, dirty, }) => (
          // @ts-ignore
          <Form translate="no" autoComplete="off">


            <div className={classes.formContentRoot}>

              <Typography
                color="primary"
                className={classes.signupText}
                variant="h1">
                {'Sign up'}
              </Typography>

              <div className={classes.formContent}>

                <VerticalSpacer />


                <TermsAndConditionModal
                  data-testId="termModal"
                  accountType="user"
                  open={!!modalValue}
                  onClose={() => setModalValue("")}
                  modalValue={modalValue}
                />

                <FormikTextField
                  placeholder="Full Name *"
                  name="full_name"
                />


                <VerticalSpacer />

                <FormikTextField
                  placeholder="Email Address *"
                  name="email"
                  type="email"
                />

                <HideContainer hideWhen={!accountError}>
                  <InitiateAlert>
                    {accountError}
                  </InitiateAlert>
                </HideContainer>

                <VerticalSpacer />

                <InitiatePhoneInput
                  value={values.full_phone_number}
                  onChange={(phone) => {
                    setFieldValue('full_phone_number', phone);
                    setIsPhoneNumberTouched(true);
                  }}
                  error={values.full_phone_number.length ? errors?.full_phone_number : ''}
                  onBlur={() => {
                    setIsPhoneNumberTouched(false);
                  }}
                />

                <HideContainer hideWhen={!phoneNumberError}>
                  <InitiateAlert>
                    {phoneNumberError}
                  </InitiateAlert>
                </HideContainer>


                <VerticalSpacer />

                <FormikTextField
                  placeholder="Password *"
                  name="password"
                  type="password"
                />

                <VerticalSpacer />

                <FormikTextField
                  placeholder="Confirm Password *"
                  name="password_confirmation"
                  type="password"
                />

                <VerticalSpacer />
          
                <Typography className={classes.notice} style={{marginTop:6}}>
                  {'Fields marked with '}<span className={classes.warn}>{'\'*\''}</span>{' are mandatory fields.'}
                </Typography>

                <VerticalSpacer />
              </div>
              <Box display="flex" alignItems="center">
                <Checkbox
                  style={{
                    width: "25px",
                    height: "25px",
                    marginLeft: "-10px"
                  }}
                  checked={values.acceptTerms}
                  icon={
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                      <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#aaa" fill="transparent" stroke-width="0.8" />
                    </svg>
                  }
                  checkedIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                      <rect x="2" y="2" rx="4" ry="4" width="20" height="20" stroke="#019BE1" fill="#019BE1" stroke-width="1" />
                      <path style={{ transform: 'scale(0.8) translateY(3px) translateX(2px)' }} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="white" />
                    </svg>
                  }
                  data-testId="termCheckbox"
                  disableRipple
                  color="primary"
                  onChange={(e) => setFieldValue('acceptTerms', e.target.checked)}
                />
                <Typography className={`${classes.checkboxText} ${values.acceptTerms && classes.darkColor}`}>
                  {'By creating an account, you agree to Initiate\'s'}&nbsp;
                  <Typography
                    color="primary"
                    variant="caption"
                    style={{
                      cursor: "pointer",
                      fontSize: 'inherit',
                    }}
                    data-testId="termBtn"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      setModalValue("Terms & Conditions");
                    }}
                  >
                    {'Terms & Conditions'}
                  </Typography>
                  {' and '}
                  <Typography
                    color="primary"
                    variant="caption"
                    style={{
                      cursor: "pointer",
                      fontSize: 'inherit',
                    }}
                    data-testId="privacyBtn"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      setModalValue("Privacy Policy");
                    }}
                  >
                    {'Privacy Policy'}
                  </Typography>
                </Typography>
              </Box>

              <HideContainer hideWhen={!generalError}>
                <InitiateAlert>
                  {generalError}
                </InitiateAlert>
              </HideContainer>

              <VerticalSpacer />


              <InitiateButton
                variant="contained"
                fullWidth
                disabled={isLoading || (dirty ? !isValid : true)}
                color="primary"
                size="large"
                className={classes.submitButton}
                loading={isLoading}
                type="submit">
                {'Sign Up'}
              </InitiateButton>

              <VerticalSpacer />

              <Typography className={classes.loginText}>
                {'Already have an account?'}&nbsp;
              <Link
                  to="/EmailAccountLoginBlock"
                  className={classes.link}
                >
                  {'Sign In'}
                </Link>
              </Typography>


            </div>
          </Form>
        )}
      </Formik>

      <RegisterationMobileHeader
        title=""
        hideSkipButton
      />
    </>
  );
}
// Customizable Area End
