// Customizable Area Start
import React from 'react'

import Modal from '@material-ui/core/Modal';
import { Box, Typography, Button } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import {
  FONTS
} from "../constants";
// @ts-ignore\
import classOuter from "./ContactusSuccessModal.module.css"
// Customizable Area End


export default ({ openSuccessModal, handleSuccessModalClose }: any) => {
  // Customizable Area Start

  // Customizable Area End
  return (
    // Customizable Area Start
    <Modal
      open={openSuccessModal}
      onClose={handleSuccessModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classOuter.modalWrapper}
    >
      <Box>
        <Box className={classOuter.modalBox}
        // maxWidth="xl"
        >
          <Box>
            <div>
            <Check className={classOuter.checkSubmit}/>
                     
              <Typography variant='h6'>Thank you for submitting!</Typography>
              <Typography variant='body1' style={{ ...FONTS.body1,  }}>
                You have successfully submitted the message.
              </Typography>
              <div>
                <Button
                  className={classOuter.modalBtn}
                  variant="contained"
                  onClick={() => handleSuccessModalClose()}
                >
                  Back
                </Button>
              </div>
            </div>
            {/* <Box className={classOuter.errorMsg}>
              <div className={classOuter.errors}>{this.state.contactMessageError}</div>
            </Box> */}
          </Box>

        </Box>
      </Box>
    </Modal>
    // Customizable Area End
  )
}