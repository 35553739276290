// Customizable Area Start
import React, { ReactNode, useState } from 'react';
import { icons } from "../../../components/src/constants";

import {
  format,
} from "date-fns";
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import { InitiateButton } from '../../../components/src/design-system/button';
import moment from 'moment';

export function DatePickerComposer({
  children,
  date, setDate,
}: {
  date: any,
  setDate: (date: any) => void,
  children: ({ open, setOpen }: { open: boolean, setOpen: (value: boolean) => void }) => ReactNode,
}) {

  const [open, setOpen] = useState(false);

  return (
    <>
      {children({ open, setOpen })}

      <MuiPickersUtilsProvider utils={MomentUtils}>

        <DatePicker
          disableToolbar
          variant="inline"
          color="primary"
          open={open}
          onClose={() => setOpen(false)}
          value={date}
          onChange={(value) => {
            setDate(new Date(value));
            setOpen(false);
          }}
          style={{ visibility: "hidden" }}
          minDate={new Date()}
        />
      
        { localStorage.setItem("new_date",moment(date).format('DD/MM/YYYY'))}
          
      </MuiPickersUtilsProvider>
    </>
  );
}

export function MonthDatePicker({
  date,
  setDate,
}: {
  date: any,
  setDate: (date: any) => void,
}) {
  const options = { month: 'long', year: 'numeric' };
  const formattedDate = date.toLocaleString('en-US', options);
  return (
    <DatePickerComposer date={date} setDate={setDate}>
      {({ setOpen }) => (
        <InitiateButton
          style={{ whiteSpace: 'nowrap',
          backgroundColor: '#fff',
          fontWeight:600,
          fontSize:"14px",
          fontFamily:"Rene_Bieder_Milliard_Medium" }}
          variant="text"
          color="primary"
          onClick={() => setOpen(true)}
          endIcon={
            <img src={icons.downarrow} style={{ height: 6, width: 12 }} />
          }
        >
          
          {formattedDate}
        </InitiateButton>
      )}
    </DatePickerComposer>
  );
}


// Customizable Area End
