import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import { constants } from "../../../components/src/constants";
import { NPSignupFormPayload } from "./NPSignupForm";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children: any;
  classes: any;
  theme?: any;
  registerNextBtnValidation: any;
  fullName: any;
  orgName: any;
  organizationType: any;
  regNum: any;
  desc: any;
  contactNum: any;
  adminMobNum: any;
  emailId: any;
  regPassword: any;
  regConfirmPwd: any;
  link: any;
  webUrl: any;
  logo: any;
  regEmailApiError: any;
  logoApiError: any;
  orgApiError: any;
  setSelectedAdminTab: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  mobileOpen: boolean;
  open: boolean;
  classes: any;
  theme?: any;
  isMobile: boolean;
  selectedTab: any;
  selectedBottomTab: any;
  slideIndex: any;
  value: number;
  setAnchorEl: any;
  organizationState: any;
  age: any;
  setLandingPageModal: any;
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  emailValidation: boolean;
  emailPasswordValidation: boolean;
  enablePasswordField: boolean;
  forgotPwdEmail: string;
  otp: string;
  otpError: string;
  newPwd: string;
  confirmPwd: string;
  enableNewPwd: boolean;
  enableConfirmPwd: boolean;
  resetPasswordValidation: boolean;
  resetPwdError: string;
  matchPwdError: string;
  isOtpResend: boolean;
  new_otp: string;
  desc: string;
  contactNum: string;
  adminMobNum: string;
  emailId: string;
  regPassword: string;
  regConfirmPwd: string;
  link: string;
  webUrl: string;
  enablePwdField: boolean;
  enableConfirmPwdField: boolean;
  fullName: any;
  regNum: any;
  orgName: any;
  orgType: any;
  logo: any;
  regEmailValidation: boolean;
  regPwdValidation: boolean;
  regPwdError: string;
  regEmailError: string;
  activeStep: any;
  showorganisationDropdown: any;
  dropdownInputBorder: any;
  organizationType: any;
  fullNameError: any;
  projectNameError: any;
  registrationNumberError: any;
  orgTypeError: any;
  orgDesError: any;
  contactNumberError: any;
  adminMobileNumberError: any;
  regEmailApiError: any;
  expireOtpError: any;
  linkError: any;
  webUrlError: any;
  descriptionWordCount: any;
  logoApiError: any;
  orgApiError: any;
  isFocused: any;
  inputOrgType: string;
  npOrgType: any;
  wordcount: any;
  address:{
    street: string,
    town: string,
    country: string,
    postCode:string,
  },
  officeAddress:{
    street: string,
    town: string,
    country: string,
    postCode:string,
  },
  activateOfficeAddress: boolean,
  apiError: any;
  signinLoading: boolean,
  pwdUpdateError: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getLoginCallId: any;
  // Customizable Area Start
  forgotPwdSendOtpCallId: any;
  forgotPwdVerifyOtpCallId: any;
  resetPasswordApiID: any;
  resendOTPApiId: any;
  registrationId: any;
  dropdownRef: any;
  getOrgTypeId: any;
  getFcmId:any;
  getFcmListId:any; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.dropdownRef = React.createRef();

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),

      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      dashboardData: [],
      errorMsg: "",
      value: 0,
      token: "",
      loading: false,
      mobileOpen: false,
      open: false,
      classes: {},
      theme: {},
      isMobile: false,
      selectedTab: 0,
      selectedBottomTab: 0,
      slideIndex: 1,
      setAnchorEl: null,
      organizationState: "",
      age: "",
      setLandingPageModal: false,
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      emailValidation: false,
      emailPasswordValidation: false,
      enablePasswordField: true,
      forgotPwdEmail: "",
      otp: "",
      otpError: "",
      newPwd: "",
      confirmPwd: "",
      enableNewPwd: true,
      enableConfirmPwd: true,
      resetPasswordValidation: false,
      resetPwdError: "",
      matchPwdError: "",
      isOtpResend: false,
      new_otp: "",
      desc: "some desc",
      contactNum: "+44155413402",
      adminMobNum: "+44155413402",
      emailId: "lalosh.dev+402@gmail.com",
      regPassword: "hello@World123",
      regConfirmPwd: "hello@World123",
      link: "",
      webUrl: "",
      enablePwdField: false,
      enableConfirmPwdField: false,
      fullName: "Louay",
      orgName: "org name",
      orgType: "Charity",
      regNum: "123123",
      logo: "",
      regEmailValidation: false,
      regEmailError: "",
      regPwdValidation: false,
      regPwdError: "",
      activeStep: 0,
      showorganisationDropdown: false,
      dropdownInputBorder: false,
      organizationType: "",
      fullNameError: "",
      projectNameError: "",
      registrationNumberError: "",
      orgTypeError: "",
      orgDesError: "",
      contactNumberError: "",
      adminMobileNumberError: "",
      regEmailApiError: "",
      expireOtpError: "",
      linkError: "",
      webUrlError: "",
      descriptionWordCount: 0,
      logoApiError: '',
      orgApiError: '',
      address:{
        town: '',
        street: '',
        country: '',
        postCode:'',
      },
      officeAddress: {
        town: '',
        street: '',
        country: '',
        postCode:'',
      },
      activateOfficeAddress: false,
      isFocused: false,
      inputOrgType: "",
      npOrgType: [],
      wordcount: 0,
      apiError: [],
      signinLoading: false,
      pwdUpdateError: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  //Handle Non-profit Sign In
  goToNpSignIn = (responseJson: any) => {
    this.setState({ signinLoading: false });
    if (responseJson && responseJson?.meta?.token && responseJson?.meta?.id) {
      localStorage.setItem("np_user_role",responseJson?.meta?.role)
      localStorage.setItem('org_id', responseJson?.meta?.organisation)
      localStorage.setItem("non_profit_acc_id", responseJson?.meta?.id)
      localStorage.setItem("refresh_token", responseJson?.meta?.refresh_token)
      localStorage.setItem("nonprofitToken", responseJson?.meta?.token)
      localStorage.setItem("user_type", responseJson?.meta?.user_type)
      localStorage.setItem('user_email', this.state.email.toLowerCase());
      localStorage.setItem("isLogin", "false");
      this.props.setSelectedAdminTab(constants.admin_dashboard_screens.Dashboard)
      this.props.navigation.navigate("ContentManagementDashBoard")
      localStorage.setItem("showSubscribe", "true")
    }
    if (
      responseJson &&
      responseJson.errors &&
      responseJson.errors.length > 0
    ) {
      // console.log(responseJson?.errors[0], "responseJson.errors[0]")
      if (responseJson?.errors[0].failed_login) {
        this.setState({ emailError: responseJson?.errors[0].failed_login })
      }
      if (responseJson?.errors[0].failed_login === "Entered Password is not valid") {
        this.setState({ emailError: "", passwordError: "Wrong password,please check and try again." })
      }
    }

  }

  //Handle Non-profit Forgot password
  goToForgotPassword = (responseJson: any) => {
    if (responseJson.errors) {
      if (responseJson?.errors[0].otp === "Account not found") {
        this.setState({ emailError: "Account not found or incorrect email" })
      } else {
        this.setState({ emailError: "Error occured please try again afters some time" });
      }
      return
    }

    sessionStorage.setItem("np-otp-meta", JSON.stringify(responseJson.meta));
    setTimeout(() => {
      this.props.navigation.navigate("NpForgotPasswordOTP");
    }, 500);
  }

  // Handle Non-profit Verify OTP
  goToVerifyOTP = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ otpError: responseJson.errors[0].otp || responseJson.errors[0].token });
      this.setState({ expireOtpError: responseJson.errors[0].pin || responseJson.errors[0].token });
      return;
    }
    this.props.navigation.navigate("NpAccountVerificationMessage");
  }

  // Handle Non-profit change password
  goToChangePwd = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ otpError: responseJson.errors[0].token || responseJson.errors[0].otp || responseJson.errors[0].profile });
      toast.error(`${responseJson.errors[0].otp}`)
      return;
    } else if (responseJson.message) {
       this.setState({ pwdUpdateError: responseJson.message})
       return;
    }
    this.props.navigation.navigate("NpResetPasswordSuccessMessage");
  }

  // Handle Non-profit Resend OTP
  goToResendOTP = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ emailError: responseJson.errors[0].otp });
      return;
    }
    sessionStorage.setItem("np-otp-meta", JSON.stringify(responseJson.meta));
    this.setState({ isOtpResend: true, new_otp: responseJson.meta.otp });
  }

  // Handle Non-profit Register
  goToNpRegister = (responseJson: any) => {
    const { data, meta, errors } = responseJson;
    if (data && data.id) {
      localStorage.setItem('org_id', data.attributes?.organisation_id);
      localStorage.setItem("nonprofitToken", meta?.token);
      this.props.navigation.navigate("NpRegSuccess");
      localStorage.setItem("showSubscribe", "true");
    } else if (errors && errors.length > 0) {
      errors.forEach((error:any) => {
        const { email, account, logo, organisation_name } = error;
        switch (true) {
          case !!email:
            this.setState({ regEmailApiError: email });
            break;
          case !!account:
            this.setState({ regEmailApiError: account });
            break;
          case !!logo:
            this.setState({ logoApiError: logo });
            break;
          case !!organisation_name:
            localStorage.setItem("orgApiError", organisation_name);
            this.setState({ orgApiError: organisation_name });
            break;
          default:
            break;
        }
      });
    }
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiRequestCallId === this.getFcmId && responseJson.message === "device id save successfully") {
        this.getFcmDeviceList();
      }
  
      if (apiRequestCallId === this.getFcmListId) {
        this.handleFcmListResponse(responseJson);
      }
  
      if (apiRequestCallId && responseJson) {
        this.handleApiResponses(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  handleApiResponses(apiRequestCallId:any, responseJson:any) {
    // Sign-in (Non-profit)
    if (apiRequestCallId === this.getLoginCallId) {
      this.goToNpSignIn(responseJson);
    }
    // ForgotPassword - Check for Email
    else if (apiRequestCallId === this.forgotPwdSendOtpCallId) {
      this.goToForgotPassword(responseJson);
    }
    // Verify OTP 
    else if (apiRequestCallId === this.forgotPwdVerifyOtpCallId) {
      this.goToVerifyOTP(responseJson);
    }
    // change password 
    else if (apiRequestCallId === this.resetPasswordApiID) {
      this.goToChangePwd(responseJson);
    }
    // resend OTP
    else if (apiRequestCallId === this.resendOTPApiId) {
      this.goToResendOTP(responseJson);
    }
    //Register Non-Profit
    else if (apiRequestCallId === this.registrationId) {
      this.goToNpRegister(responseJson);
    }
    else if (apiRequestCallId === this.getOrgTypeId) {
      this.setState({ npOrgType: responseJson?.data });
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleFcmListResponse =(responseJson:any)=>{
    const token = localStorage.getItem("fcm_token")
    const foundDevice = responseJson.data.find((device:any) => device.device_token === token);
    if(foundDevice){
      localStorage.setItem("device_id",foundDevice.id)
    }
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start



  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    if (localStorage.getItem("nonprofitToken") !== null && localStorage.getItem('user_type') === 'Non profit entity') {
      this.props.navigation.navigate("ContentManagementDashBoard");
    }
    document.addEventListener("click", this.handleOuterAreaClick);
    if (this.props.organizationType) {
      this.setState({
        inputOrgType: this.props.organizationType
      })
    }

    // Organization Type API Handling 
    this.getOrgType()
    // Customizable Area End
  }

  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 ? true : false });
    // let currentHideNav = (window.innerWidth <= 760);
    if (window.innerWidth >= 760) {
      this.setState({ open: true });
      this.setState({ isMobile: true });
    }
  }

  componentWillUnmount(): any {
    document.removeEventListener("click", this.handleOuterAreaClick);
    window.removeEventListener("resize", this.resize.bind(this));
  }

  // proper search filter 
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // console.log(event.currentTarget,"event.currentTarget")
    this.setState({ setAnchorEl: this.state.setAnchorEl ? null : event.currentTarget })
  };

  organizationHandleChange = (event: { target: { value: any; }; }) => {
    this.setState({ organizationState: event.target.value })
  };

  handleClickLandingPageModalOpen = () => {
    this.setState({ setLandingPageModal: true })

  };

  handleClickLandingPageModalClose = () => {
    this.setState({ setLandingPageModal: false })
  };

  regClick() {
    // this.myArrow();
    console.log("adminRegistration", this.state);
    this.props.navigation.navigate("AdminRegistration5");
  }

  // Sign-in (Non-Profit) event handler 

  // For email input
  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  // For password input
  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  //For password hide text
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  }

  // Email validation
  isValidEmail(value: any) {
    const reg =
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return reg.test(String(value).toLowerCase());
  }

  validateEmail(value: any) {
    if (value == '') {
      this.setState({ emailError: "", emailValidation: false })
    } else if (this.isValidEmail(value)) {
      this.setState({ emailError: "", emailValidation: false })
    } else {
      this.setState({ emailError: 'Please enter a valid email address.', emailValidation: true })
    }
  }

  // Password validation
  isValidPassword(value: any) {
    const minMaxLength = /^[\s\S]{8,32}$/,
      upper = /[A-Z]/,
      number = /[0-9]/,
      special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    if (
      minMaxLength.test(value) &&
      upper.test(value) &&
      number.test(value) &&
      special.test(value)
    ) {
      return true;
    }
    return false;
  }

  validatePassword(value: string) {
    if (value == "") {
      this.setState({ passwordError: "Input can not be empty", emailPasswordValidation: true });
    } else if (!this.isValidPassword(value)) {
      this.setState({
        passwordError:
          "Password must be of 8 characters with a capital letter, a special character & a number.",
        emailPasswordValidation: true
      });
    } else {
      this.setState({ passwordError: "", emailPasswordValidation: false });
    }
  }

  // Go to the sign-in button
  gotoSignIn = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLoginCallId = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginApiEndPoint
    );

    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    //request body
    const requestBody = {
      data: {
        attributes: {
          email: this.state.email,
          password: this.state.password
        }
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    this.setState({signinLoading:true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  sendFcmToken ():any {
     const httpBody = {
       "device_token":localStorage.getItem("fcm_token")
     };
 
     const header = {
       "Content-Type": configJSON.apiContentType,
       "token":localStorage.getItem("nonprofitToken"),
       "type":"non_profit_entity"
 
     };
 
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.getFcmId = requestMessage.messageId;
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       `account_block/device_ids`
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       "POST"
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestBodyMessage),
       JSON.stringify(httpBody)
     );
  
     runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   getFcmDeviceList ():any {
    
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token":localStorage.getItem("nonprofitToken"),
      "type":"non_profit_entity"

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFcmListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/device_ids`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
 
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Forgot password (Non-Profit) event handler 

  // For email input
  setForgotPwdEmail = (text: string) => {
    this.setState({
      forgotPwdEmail: text,
    });
  };

  // Go to send code button
  gotoSendCode = () => {
    if (
      this.state.forgotPwdEmail === null ||
      !this.state.forgotPwdEmail.trim().length
    ) {
      return false;
    }

    // store the email for, resend OTP feature
    sessionStorage.setItem("np-user-email", this.state.forgotPwdEmail);
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const body = {
      data: {
        attributes: {
          email: this.state.forgotPwdEmail
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPwdSendOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPwdSendOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //Forgot Password OTP (Non-profit) event handler
  setOtp = (text: any) => {
    this.setState({
      otp: text,
      otpError: "",
      expireOtpError: ""
    });
  };

  // verify the otp
  gotoVerify = () => {
    if (this.state.otp.length !== 6) {
      return;
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const token = JSON.parse(sessionStorage.getItem("np-otp-meta") as string).token;

    const body = {
      data: {
        token,
        otp_code: this.state.otp
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPwdVerifyOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPwdVerifyOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // resendOTP 
  resendOTP = () => {
    const user_email = (sessionStorage.getItem("np-user-email") as string) || null;

    if (!user_email) {
      alert("Something went wrong ...");
      return;
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const body = {
      data: {
        attributes: {
          email: user_email
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOTPApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPwdSendOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePassword = () => {
    this.props.navigation.navigate("NpResetPassword")
  }

  // Reset Password event handler

  // For Reset password validation
  validResetPassword(value: string) {
    if (value == "") {
      this.setState({ resetPwdError: "Input can not be empty", resetPasswordValidation: true });
    } else if (!this.isValidPassword(value)) {
      this.setState({
        resetPwdError:
          "Password must be of 8 characters with a capital letter, a special character & a number.",
        resetPasswordValidation: true
      });
    } else {
      this.setState({ resetPwdError: "", resetPasswordValidation: false });
    }
  }

  // For New Password Input
  setNewPassword = (text: any) => {
    this.setState({
      otpError: "",
      newPwd: text,
      matchPwdError: "",
    });
  };

  // For Confirm Password Input
  setConfirmPassword = (text: any) => {
    this.setState({
      confirmPwd: text,
      matchPwdError: "",
      otpError: "",
      resetPasswordValidation: false
    });
  };

  // Hide and show the New Password
  handleClickShowNewPassword = () => {
    this.setState({
      enableNewPwd: !this.state.enableNewPwd,
    });
  }


  // Hide and show the Confirm Password
  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPwd: !this.state.enableConfirmPwd,
    });
  }

  // Set new Password
  setNewPasswordRoles = (updatedToken: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSetNewPasswordApiEndPoint}?token=${updatedToken}&new_password=${this.state.newPwd}&password_confirmation=${this.state.confirmPwd}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // For Reset Password
  gotoResetPassword = () => {
    if (this.state.newPwd !== this.state.confirmPwd) {
      this.setState({ matchPwdError: "Passwords did not match.", resetPasswordValidation: true })
      return;
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const token = JSON.parse(sessionStorage.getItem("np-otp-meta") as string)?.token;

    const body = {
      data: {
        token,
        new_password: this.state.newPwd,
        password_confirmation: this.state.confirmPwd
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Reset password Success event handler

  // Go to Sign in 
  goToSignIn = () => {
    this.props.navigation.navigate("NpSignIn");
  }

  // Register (Non-profit) Event handler

  registration = (values: NPSignupFormPayload) => {

    localStorage.setItem("adminSelectedTab", JSON.stringify("Organisation"));

    const header = {
      // "Content-Type": configJSON.validationApiContentType,
    };

    let {
      fullName ,
      orgName ,
      orgType ,
      registrationNumber ,
      street ,
      town ,
      country ,
      postCode ,

      officeStreet ,
      officeTown ,
      officeCountry ,
      officePostCode ,

      description ,
      contactPhoneNumber ,
      adminPhoneNumber ,
      email ,
      password ,
      confirmPassword ,
      referralsLink ,
      websiteURL ,
      logo ,
    }=values;


    const formData = new FormData();

    formData.append("[data][attributes][full_name]", fullName);
    formData.append("[data][attributes][email]", email);
    formData.append("[data][attributes][password]", password);
    formData.append("[data][attributes][password_confirmation]", confirmPassword);
    formData.append("[data][attributes][logo]", logo);
    formData.append("[data][attributes][organisation_type]", orgType);
    formData.append("[data][attributes][organisation_name]", orgName);
    formData.append("[data][attributes][registration_number]", registrationNumber);
    formData.append("[data][attributes][referral]", referralsLink);
    formData.append("[data][attributes][website_url]", websiteURL);
    formData.append("[data][attributes][street]",street);
    formData.append("[data][attributes][town]",town);
    formData.append("[data][attributes][post_code]",postCode);
    formData.append("[data][attributes][country]",country);
    formData.append("[data][attributes][office_street]",officeStreet);
    formData.append("[data][attributes][office_town]",officeTown);
    formData.append("[data][attributes][office_post_code]",officePostCode);
    formData.append("[data][attributes][office_country]",officeCountry);
    formData.append("[data][attributes][contact_number]", contactPhoneNumber);
    formData.append("[data][attributes][admin_mobile_number]", adminPhoneNumber);
    formData.append("[data][attributes][is_registered]", "true");
    formData.append("[data][attributes][description]", description);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registrationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.npRegistrationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleNext = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }, 1000);
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep: any) => {
    console.log(activeStep, "activeStep");
    this.setState({ activeStep });
  };

  // Validation for the first registration page
  checkRegisterBeforeNext = (value: any) => {
    const {
      fullName,
      orgName,
      organizationType,
      regNum,
      desc,
      contactNum,
      adminMobNum,
      emailId,
      regPassword,
      regConfirmPwd,
      link,
      webUrl,
      logo
    } = value || {};

    switch (true) {
      case !!fullName:
        this.setState({ fullName: fullName || "" });
        break;
      case !!orgName:
        this.setState({ orgName, orgApiError: " " });
        break;
      case !!organizationType:
        this.setState({ organizationType });
        break;
      case !!regNum:
        this.setState({ regNum });
        break;
      case !!desc:
        this.setState({ desc });
        break;
      case !!contactNum:
        this.setState({ contactNum });
        break;
      case !!adminMobNum:
        this.setState({ adminMobNum });
        break;
      case !!emailId:
        this.setState({ emailId, regEmailApiError: "" });
        break;
      case !!regPassword:
        this.setState({ regPassword });
        break;
      case !!regConfirmPwd:
        this.setState({ regConfirmPwd });
        break;
      case !!link:
        this.setState({ link });
        break;
      case !!webUrl:
        this.setState({ webUrl });
        break;
      case !!logo:
        this.setState({ logo, logoApiError: " " });
        break;
      default:
        break;
    }
  }


  // For full name validation (non-profit) register
  isValidFullName = (value: any) => {
    const reg = /^[A-Z][a-z]+[,.'-]?(?: [A-Z][a-z]+[,.'-]?)*$/i;
    return reg.test(String(value).toLowerCase());
  }

  isValidDes = (value: any) => {
    const reg = /^[\S][\w\d\s\W]+[\S]$/;
    return reg.test(String(value).toLowerCase());
  }

  validFullName = (value: any) => {
    if (value == '') {
      this.setState({ fullNameError: "" })
    } else if (this.isValidFullName(value)) {
      this.setState({ fullNameError: "" })
    } else {
      this.setState({ fullNameError: 'Please enter full name' })
    }
  }

  //  For project name validation (non-profit) register
  validProjectName = (value: any) => {
    if (value == '') {
      this.setState({ projectNameError: '' })
    } else if (this.isValidDes(value)) {
      this.setState({ projectNameError: "" })
    } else {
      this.setState({ projectNameError: 'Please enter organization/project name' })
    }
  }


  //  For registration number validation (non-profit) register
  validRegistrationNumber = (value: any) => {
    if (value == '') {
      this.setState({ registrationNumberError: 'Please enter registration number' })
    } else if (this.isValidDes(value)) {
      this.setState({ registrationNumberError: "" })
    } else {
      this.setState({ registrationNumberError: 'Please enter registration number' })
    }
  }


  //  For registration number validation (non-profit) register
  validOrganizationType = (value: any) => {
    if (value == '') {
      this.setState({ orgTypeError: 'Please select organization type' })
    } else if (value.length >= 1) {
      this.setState({ orgTypeError: "" })
    } else {
      this.setState({ orgTypeError: 'Please select organization type' })
    }
  }

  //For brief description validation (non-profit) register
  descriptionValidation = (value: any) => {
    if (value == '') {
      this.setState({ orgDesError: 'Please enter description of organization' })
    } else if (value.trim().length > 1) {
      this.setState({ orgDesError: "" })
    } else {
      this.setState({ orgDesError: 'Please enter description of organization' })
    }
  }

  //For contact number and admin mobile number validation (non-profit) register

  validContactNumber = (input: any) => {
    try {
      let ISD_CODES = [93, 355, 213, 1684, 376, 244, 1264, 672, 1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975, 591, 387, 267, 55, 246, 1284, 673, 359, 226, 257, 855, 237, 1, 238, 1345, 236, 235, 56, 86, 61, 61, 57, 269, 682, 506, 385, 53, 599, 357, 420, 243, 45, 253, 1767, 1809, 1829, 1849, 670, 593, 20, 503, 240, 291, 372, 251, 500, 298, 679, 358, 33, 689, 241, 220, 995, 49, 233, 350, 30, 299, 1473, 1671, 502, 441481, 224, 245, 592, 509, 504, 852, 36, 354, 91, 62, 98, 964, 353, 441624, 972, 39, 225, 1876, 81, 441534, 962, 7, 254, 686, 383, 965, 996, 856, 371, 961, 266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 222, 230, 262, 52, 691, 373, 377, 976, 382, 1664, 212, 258, 95, 264, 674, 977, 31, 599, 687, 64, 505, 227, 234, 683, 850, 1670, 47, 968, 92, 680, 970, 507, 675, 595, 51, 63, 64, 48, 351, 1787, 1939, 974, 242, 262, 40, 7, 250, 590, 290, 1869, 1758, 590, 508, 1784, 685, 378, 239, 966, 221, 381, 248, 232, 65, 1721, 421, 386, 677, 252, 27, 82, 211, 34, 94, 249, 597, 47, 268, 46, 41, 963, 886, 992, 255, 66, 228, 690, 676, 1868, 216, 90, 993, 1649, 688, 1340, 256, 380, 971, 44, 1, 598, 998, 678, 379, 58, 84, 681, 212, 967, 260, 263],
        //extract numbers from string
        thenum = input.match(/\d[0-9]+/g).join(""),
        totalnums = thenum.length,
        last10Digits = parseInt(thenum) % 10000000000,
        ISDcode = thenum.substring(0, totalnums - 10);

      //phone numbers are generally of 8 to 16 digits
      if (totalnums === 12) {
        if (ISDcode) {
          if (ISD_CODES.includes(parseInt(ISDcode))) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } catch (e) { }

    return false;
  }

  isValidUrl = (urlString: any) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }

  handleDescription = (value: any) => {
    const text = value.trim();
    const length = text.trim().split(/\s+/).length;
    if (length <= 150) {
      this.setState({ wordcount: length })
    }
    else {
      this.setState({ wordcount: 150 })
      this.setState({ orgDesError: 'Please enter 150 words only.' })
    }
  }




  // this is organization events functionality for signup input 

  // Handle input click event
  handleInputClick = () => {
    const { organizationType } = this.props;

    this.setState((prevState) => ({
      showorganisationDropdown: organizationType ? true : false,
      dropdownInputBorder: !prevState.dropdownInputBorder,
    }));
  };

  // Handle dropdown button click event
  handleDropdownButtonClick = () => {
    this.setState((prevState) => ({
      showorganisationDropdown: !prevState.showorganisationDropdown,
      dropdownInputBorder: !prevState.dropdownInputBorder,
    }));
  };

  handleOuterAreaClick = (event: { target: any; }) => {
    const dropdownNode = this.dropdownRef.current;
    if (dropdownNode && !dropdownNode.contains(event.target)) {
      this.setState({ showorganisationDropdown: false, dropdownInputBorder: false });
    }
  };

  // Handle dropdown item click event organizationlist
  handleDropdownItemClick = (label: any) => {
    const { registerNextBtnValidation } = this.props;
    registerNextBtnValidation({ organizationType: label });
    this.validOrganizationType(label);
    this.setState({
      showorganisationDropdown: false,
      dropdownInputBorder: false,
      inputOrgType: label
    });
  };


  // onFocuscolor change  organization input 

  handleInputFocus = (event: any) => {
    const outlineElement = event.target.parentElement.querySelector(
      '.MuiOutlinedInput-notchedOutline'
    );
    if (outlineElement) {
      outlineElement.style.borderColor = '#009BE2';
    }
  };

  handleInputBlur = (event: any) => {
    const outlineElement = event.target.parentElement.querySelector(
      '.MuiOutlinedInput-notchedOutline'
    );
    if (outlineElement) {
      outlineElement.style.borderColor = '#d5d5d5';
    }
  };
  handleOrganizationInput = (e: any) => {
    this.setState({
      inputOrgType
        : e.target.value,
      showorganisationDropdown: true
    })
    const { registerNextBtnValidation } = this.props;
    registerNextBtnValidation({ organizationType: " " });
    this.validOrganizationType(" ");
  }

  // Organization Type API 
  getOrgType = () => {
    const getOrgType = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrgTypeId = getOrgType.messageId;

    getOrgType.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orgTypeEndPoint
    );

    getOrgType.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getOrgType.id, getOrgType);
  }

  // Customizable Area End
}
