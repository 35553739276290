// @ts-nocheck
import React, { useState } from "react";
import Select from "react-select";
import { Box } from "@material-ui/core";
import { components } from "react-select";

const DBSDropDown = (props: any) => {


  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <label>{props.label}</label>
          <input
            style={{ float: "right" }}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
        </components.Option>
      </div>
    );
  };

  const selectedDropdownvalues = () : any[] => {
    let filteredArr: any = [];
    props.catogaryOptions.forEach((each: any) => {
        if (props?.selectedDbsData.length) {
            props?.selectedDbsData.forEach((item: any) => {
                if (String(each.value) === String(item.id)) {
                    filteredArr.push(each);
                }
            });
        }
    });
    return filteredArr
}

  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    // Customizable Area Start
    <Box style={{ margin: 1 }}>
          <div
            style={{
              position: "absolute",
              zIndex: 25,
              border: `${dropdownOpen ? "1px solid  #3696C3" : "1px solid lightgrey"
                }`,
              width: "45.5%",
              borderRadius: 10,
              borderBottom: `${!dropdownOpen ? "1px solid lightgrey" : "1px solid  #3696C3"
                }`,
              height: `${!dropdownOpen ? "54px" : "220px"}`,
            }}
          >
            <Select
              onMenuClose={() => {
                setDropdownOpen(false);
              }}
              onMenuOpen={() => {
                setDropdownOpen(true);
              }}
              defaultValue={props.selectedDbsData?.length > 0  ? selectedDropdownvalues() : []}
              options={props?.catogaryOptions}
              onChange={(e) => props.handleDBSTypeDropdownIds(e.value)}
              isClearable={false}
              components={{
                Option,
              }}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              styles={{
                multiValueLabel: (base) => {
                  return {
                    ...base,
                    fontFamily: "Rene_Bieder_Milliard_Book",
                    backgroundColor: "white",
                  };
                },
                multiValueRemove: (base) => {
                  return {
                    ...base,
                    display: "none",
                  };
                },
                placeholder: (base) => {
                  return {
                    ...base,
                    color: "#7C7C7C",
                  };
                },
                menu: (base) => {
                  return {
                    ...base,
                    border: "none",
                    marginTop: 0,
                    borderTop: "none",
                    boxShadow: "none",
                    color: "lightgrey",
                    paddingTop: 5,
                    // borderTopLeftRadius: "0px",
                    // borderTopRightRadius: "0px",
                    "&:hover": {
                      borderRadius: 12,
                    },
                  };
                },
                dropdownIndicator: (base, state) => {
                  return {
                    ...base,
                    color: "dodgerblue",
                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                  };
                },
                control: (base, state) => {
                  return {
                    ...base,
                    backgroundColor: "white",
                    boxShadow: "none",
                    height: "54px",
                    borderRadius: state.isFocused ? "10px " : "10px",
                    border: `${dropdownOpen ? "1px solid  #009BE2" : "1px solid lightgrey "
                      }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    "&:hover": {
                      borderBottom: `${dropdownOpen
                        ? "1px solid  #009BE2"
                        : "1px solid lightgrey"
                        }`,
                    },
                  };
                },
                indicatorSeparator: (styles) => ({
                  ...styles,
                  display: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: "#7C7C7C",
                  backgroundColor: state.isSelected ? "white" : "inherit",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#009BE2" : "#009BE2",
                    color: "white",
                  },
                }),
                menuList: (base) => {
                  return {
                    ...base,
                    position: "relative",
                    top: "-5px",
                    zIndex: -1,
                    color: "grey",
                    height: '150',
                  };
                },
              }}
            />
          </div>
    </Box>
  );
};

export default DBSDropDown;
