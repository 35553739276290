// Customizable Area Start
import React from "react";
import PropTypes from "prop-types";
import MyTaskPreferenceController, {
  Props,
} from "./MyTaskPreferenceController";
import { MyTaskPreferencesForm } from "./MyTaskPreferencesForm";
import { useIsMobileScreen } from "../../../components/src/utils/is-mobile";
// Customizable Area End

class MyTaskPreference extends MyTaskPreferenceController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  handleChange = (e: any) => {
    this.setState({ selectAll: e.target.checked })
  }
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <MyTaskPreferencesContent />
      // Customizable Area End
    );
  }
}

function MyTaskPreferencesContent() {
  const isMobile = useIsMobileScreen();
  return (
    <MyTaskPreferencesForm
      hideTitle={isMobile}
    />
  );
}


// Customizable Area Start
export default MyTaskPreference;


export { MyTaskPreference }
// Customizable Area End
