import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  enableConfirmPasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  otp: string;
  newPassword: string;
  confirmPassword: string;
  emailError: string;
  passwordError: string;
  otpError: string;
  validEmailIcon: boolean;
  resetPasswordValidation: boolean;
  isOtpResend: boolean;
  new_otp: string;
  expireOtpError: any;
  sendCodeLoading: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  // isChangePassword: boolean;
  labelTitle: string = configJSON.labelTitle;
  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  apiEmailLoginCallId: string = "";
  forgotPasswordVeryAPIId: any;
  apiForgotPasswordCallId: any;
  resetPasswordApiID: any;
  resendOTPApiId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)

      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          )
        })
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      btnConfirmPasswordShowHide: true,
      otp: "",
      newPassword: "",
      confirmPassword: "",
      emailError: "",
      passwordError: "",
      otpError: "",
      validEmailIcon: false,
      resetPasswordValidation: false,
      isOtpResend: false,
      new_otp: "",
      expireOtpError: "",
      sendCodeLoading: false,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.sendOTP();
  };

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        // ForgotPassword - Check for Email
        if (apiRequestCallId === this.apiEmailLoginCallId) {

          this.setState({ sendCodeLoading: false });

          if (responseJson.errors) {
            this.setState({ emailError: responseJson.errors[0].otp });
            return;
          }
          if (typeof window !== "undefined") {
            window.sessionStorage.setItem(
              "otp-meta",
              JSON.stringify(responseJson.meta)
            );
          }
          setTimeout(() => {
            this.props.navigation.navigate("ForgotPasswordOTP");
          }, 500);
        }
        this.handleVerifyOtpApiResponse(apiRequestCallId, responseJson);
        this.handleChangePasswordApiResponse(apiRequestCallId, responseJson);
        this.handleResendOptApiResponse(apiRequestCallId, responseJson);
      }
    }

    // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail"
    });
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
  handleVerifyOtpApiResponse = (apiRequestCallId: string, responseJson: { errors:{token: string;otp:string;pin:string}[] }) => {
    if (apiRequestCallId === this.forgotPasswordVeryAPIId) {
      if (responseJson.errors) {
        this.setState({
          otpError:
            responseJson.errors[0].otp || responseJson.errors[0].token
        });
        this.setState({
          expireOtpError:
            responseJson.errors[0].pin || responseJson.errors[0].token
        });
        return;
      }
      this.props.navigation.navigate("OtpVerifiedSuccess");
    }
  }
 
  handleChangePasswordApiResponse = (apiRequestCallId: string, responseJson: {errors:{otp:string;token:string;profile: string}[]}) => {
    if (apiRequestCallId === this.resetPasswordApiID) {
      if (responseJson.errors) {
        this.setState({
          otpError:
            responseJson.errors[0].token ||
            responseJson.errors[0].otp ||
            responseJson.errors[0].profile
        });
        toast.error(`${responseJson.errors[0].otp}` )
        return;
      }

      this.props.navigation.navigate("ResetPasswordSuccessMessageWeb");
    }
  }

  handleResendOptApiResponse = (apiRequestCallId: string, responseJson: {meta: { otp: string}, errors:{otp:string}[]}) => {
    if (apiRequestCallId === this.resendOTPApiId) {
      if (responseJson.errors) {
        this.setState({ emailError: responseJson.errors[0].otp });
        return;
      }
      if (typeof window !== "undefined") {
        window.sessionStorage.setItem(
          "otp-meta",
          JSON.stringify(responseJson.meta)
        );
      }
      this.setState({ isOtpResend: true, new_otp: responseJson.meta.otp });
    }
  }
  // Web Event Handling
  setEmail = (text: string) => {
    this.setState({
      emailValue: text
    });
  };

  setNewPassword = (text: string) => {
    this.setState({
      newPassword: text
    });
  };

  setConfirmPassword = (text: string) => {
    this.setState({
      confirmPassword: text
    });
  };

  setOtp = (text: any) => {
    this.setState({
      otp: text,
      otpError: "",
      expireOtpError: ""
    });
  };

  goToSignIn = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  goToResetPassword = () => {
    this.props.navigation.navigate("ResetPasswordWeb");
  };

  // send OTP to user email
  sendOTP = () => {
    if (
      this.state.emailValue === null ||
      !this.state.emailValue.trim().length
    ) {
      return false;
    }

    // store the email for, resend OTP feature

    if (typeof window !== "undefined") {
      window.sessionStorage.setItem("user-email", this.state.emailValue);
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    const body = {
      data: {
        attributes: {
          email: this.state.emailValue
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.forgotAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ sendCodeLoading: true });
  };

  // verify the OTP
  verifyOTP = () => {
    if (this.state.otp.length !== 6) {
      return;
    }

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    let token = "";

    if (typeof window !== "undefined") {
      token = JSON.parse(window.sessionStorage.getItem("otp-meta") as string)
        .token;
    }
    const body = {
      data: {
        token,
        otp_code: this.state.otp
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordVeryAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordVerifyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.verifyForgotAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // change the password
  changePassword = ({password,confirmPassword}:{password:string,confirmPassword:string}) => {
    // if (this.state.newPassword !== this.state.confirmPassword) {
    //   this.setState({ passwordError: "Both password should match", resetPasswordValidation: true });
    //   return;
    // }

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    let token = "";

    if (typeof window !== "undefined") {
      token = JSON.parse(window.sessionStorage.getItem("otp-meta") as string)
        .token;
    }

    const body = {
      data: {
        token,
        new_password: password,
        password_confirmation: confirmPassword,
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetPasswordAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // resendOTP
  resendOTP = () => {
    let user_email = null;
    if (typeof window !== "undefined") {
      user_email =
        (window.sessionStorage.getItem("user-email") as string) || null;
    }
    if (!user_email) {
      return;
    }

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    const body = {
      data: {
        attributes: {
          email: user_email
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOTPApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.forgotAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // email validation
  isValidEmail(value: any) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  }

  validateEmail(value: string) {
    if (value == "") {
      this.setState({ emailError: "", validEmailIcon: false });
    } else if (this.isValidEmail(value)) {
      this.setState({ emailError: "", validEmailIcon: true });
    } else {
      this.setState({
        emailError: "Please enter a valid email address.",
        validEmailIcon: false
      });
    }
  }

  // Customizable Area End
}
