{/* Customizable Area Start */ }
// actions.js

export const SET_OFFER_A_ROLE_DETAILS = 'SET_OFFER_A_ROLE_DETAILS'
export const RESET_OFFER_A_ROLE_DETAILS = 'RESET_OFFER_A_ROLE_DETAILS'

export const setOfferRoleFormDataSuccess = (offerRoleFormData: any) => ({
    type: SET_OFFER_A_ROLE_DETAILS,
    payload: { offerRoleFormData }
})

export const resetOfferRoleFormDataSuccess = () => ({
    type: RESET_OFFER_A_ROLE_DETAILS,
    payload: {}
})

export function setOfferRoleAction(offerRoleFormData: any) {
    return (dispatch: (arg0: { type: string; payload: { offerRoleFormData: any } }) => void) => {
        localStorage.setItem("offerRoleFormData", JSON.stringify(offerRoleFormData));
        dispatch(setOfferRoleFormDataSuccess(offerRoleFormData))
    }
}

export function resetOfferRoleAction() {
    return (dispatch: (arg0: { type: string; payload: {} }) => void) => {
        dispatch(resetOfferRoleFormDataSuccess())
    }
}
{/* Customizable Area End */ }
