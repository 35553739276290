// Customizable Area Start
import React from 'react';
// Customizable Area End
import {
  Container,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper, 
  CircularProgress,
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import moment from 'moment';
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import { isMobile } from "react-device-detect";
// @ts-ignore
import classesOuter from "./HomeDashBoard.module.css";



import {
  icons,
  FONTS,
  images, FAMILYFONTS
} from "../../../components/src/constants";

import DashboardController, { Props } from "./DashboardController";
import "./HomeDashBoard.css";
import DbsSearch from "../../../components/src/custom/DbsSearch";


import dateFormat from "dateformat";
import { setLocationWiseFilterEvent, setSelectedEvents,setLocationWiseFilterEventMessage } from "../../../framework/src/redux/events/eventsActions";
import { getAllEventsDetails } from "../../../framework/src/redux/advanceSearch/advanceSearchActions";
import { connect } from "react-redux";
import Skeleton from '@material-ui/lab/Skeleton';
import { setSelectedFilterEvents } from "../../../framework/src/redux/filterEvents/filterEventsAction"
import { storeSearchDetails } from '../../../framework/src/redux/savedSearch/savedSearchAction';
import ImageLoader from '../../../components/src/custom/ImageLoader';
function pxToRem(value: number) {
  return `${value / 16}rem`;
}
export type CounterProps = { label?: string };

export const styles = (theme: Theme) =>
  createStyles({
    FiCardMedia: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
    },
    FiCardContent: {
      position: "relative",
      backgroundColor: "transparent",
    },
    FiCardActions: {
      position: "relative",
    },
    FiCardActionArea: {
      position: "relative",
    },
    FiCard: {
      position: "relative",
      borderRadius: 15,
      boxShadow: "none",
      height: 145
    },
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: "12px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      height: 54,
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: COLORS.black10,
    },
    inputInput: {
      padding: theme.spacing(2, 2, 2, 0),
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      color: COLORS.black10, ...FAMILYFONTS.family3, fontSize: 16, fontWeight: 400
    },
    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 24px 18px",
      width: "100%",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      color: "text.primary",
      margin: "8px",
      padding: "8px",
      height: 54,
      width: 54,
      background: COLORS.white,
      border: '1px solid #D5D5D5',
      borderRadius: 13
    },
    searchFilterIconbutton: {
      color: "text.primary",
      margin: "8px",
      background: COLORS.white,
      padding: "8px",
      height: 46,
      width: 46,
    },
    tabIcons: {
      margin: 6,
      padding: 8,
      border: "1px solid #cdc9c9",
      borderRadius: 8,
      backgroundColor: "white",
    },
    mobileTabIcons: {
      margin: 6,
      padding: 8,
      background: '#FFFFFF',
      boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.474186)',
      borderRadius: 6,
    },
    locationsreading: {
      borderRadius: "8px",
      color: "text.primary",
      margin: "8px",
      padding: "8px",
      display: "flex",
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      borderRadius: 16,
    },
    image: {
      width: 72,
      height: 42,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    locationIcon: {
      height: "24px",
      width: "24px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
      marginRight: "10px",
    },
    gutter: {
      "&:first-child": {
        marginLeft: 0,
      },
    },
    tabsRoot: {
      marginBottom: "50px",
      backgroundColor: COLORS.white,
    },
    "tabsRoot___div___div": {
      "marginBottom": "50px",
      "backgroundColor": "white",
      "justifyContent": "none",
      "padding": "0rem"
    },

    upcommingTabsIndicator: {
      backgroundColor: "#1890ff",
      borderRadius: "4px 4px 0px 0px",
      height: 4,
      maxWidth: 160
    },
    pastTabtabsIndicator: {
      backgroundColor: "#1890ff",
      borderRadius: "4px 4px 0px 0px",
      height: 4,
      maxWidth: 160
    },
    tabRoot: {
      textTransform: "initial",
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "#858585",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#000000",
        opacity: 1,
      },
      "&$tabSelected": {
        fontWeight: theme.typography.fontWeightMedium,
        color: "#000000"
      },
      "&:focus": {
        color: "000000",
      },
    },
    tabSelected: {},
    typography: {
      // padding: theme.spacing(3),
    },
    eventloop: {
      marginBottom: "30px",
    },
    carouselWrapper: {
      width: " 100%",
    },
    image_div: {
      backgroundColor: "pink",
    },
    cardCarousel: {
      width: "100%",
      borderStyle: "solid",
      borderColor: "white",
      backgroundColor: "white",
      padding: "24px 24px 24px 14px",
      borderRadius: 18,
      "& after": {
        content: "",
        display: "table",
        clear: "both",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
        margin: 0,
      },
    },

    carousel_card: {
      textAlign: "left",
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 2.334,
      [theme.breakpoints.up("md")]: {
        fontSize: pxToRem(16),
      },
    },
    avatar_Groups: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    // web

    showAvatarGroup: { display: "none" },
    showCarouselAvatar: {
      display: "block",
      width: 200,
    },

    icon_holder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 35,
      width: 35,
      background: "white",
      borderRadius: "50%",
      position: "absolute",
      top: 10,
      right: 10,
    },
    eventContent: {
      width: "60%",
      padding: "0 20px",
      "@media (min-width:600px)": {
        width: "40%",
        padding: "0 10px",
      },
    },

    w_60: {
      width: "60%",
      paddingRight: "15px",
      "@media (min-width:600px)": {
        width: "100%",
        paddingRight: 0,
      },
    },
    py_3: {
      padding: ".5rem 0",
      "@media (min-width:600px)": {
        padding: ".5rem 0",
      },
    },

    heading2: {
      fontSize: 15,
      fontWeight: 400,
    },

    heading3: {
      fontSize: 12,
      fontWeight: 300,
    },

    flex: {
      display: "flex",
    },

    event_img_container: {
      maxWidth: '100%',
      maxHeight: 130,

      borderRadius: 16,
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
    },

    eventcontent: {
      width: "100%",
      padding: " 0 22px",
      marginTop: -8,
    },
    cardDescription: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    cardDescriptionAvatar_location: {
      display: "flex",
      marginTop: "1rem",
      justifyContent: "space-between",
    },
    avatarImgcontainer: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "0.5rem",
    },
    avatarImg: {
      width: 23,
      height: 23,
      justifyContent: "space-between",
      borderRadius: "50%",
      position: "relative",
      left: "-5px",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: 16,
        height: 16,
      },
    },



    eventCard: {
      padding: 24,
      background: "white",
      borderRadius: 24,
      marginBottom: 30,
      height: 230,
      width: "100%",
    },

    icon: {
      height: 25,
      width: 25,
    },

    past_card_status: {
      zIndex: 1,
      position: "absolute",
      padding: "6px",
      height: 25,
      width: 52,
      right: " 12px",
      top: "64px",
      borderRadius: "16px",
      color: COLORS.white,
      fontSize: "12px",
      lineHeight: "27px",
      textAlign: "center",
      letterSpacing: "-0.3px",
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 13,
    },
    btn_dbs: {
      color: COLORS.white,
      height: 30,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: '0 !important',
      borderBottomLeftRadius: 13,
      borderTopRightRadius: 13,
      left: 0,
      top: 100,
      [theme.breakpoints.down("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        top: 100,
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },
    btn_dbs_slider: {
      color: COLORS.white,
      height: 38,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: '0 !important',
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 15,
      left: 0,
      top: 120,
      [theme.breakpoints.up("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        width: 52,
        fontSize: "smaller",
        padding: "5 14",
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },

    mobileLayoutContent: {
      display: "none",
    },
    multiLineEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      width: 180,
    },
    layOutbuttons: {
      width: 76,
      height: 24,
      borderRadius: "8px",
      lineHeight: 2,
      marginTop: 0,
      color: COLORS.white,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },

    navigationIcon: {
      color: COLORS.skyBlue,
      backgroundColor: "white",
      margin: "8px",
      padding: "13px",
      height: 50,
      borderRadius: 30,
      zIndex: 999,
      top: -12,
      right: 11,
    },

    [theme.breakpoints.down("xs")]: {
      showCarouselAvatar: {
        display: "none",
      },
      upcommingTabsIndicator: {
        backgroundColor: "#1890ff",
        marginLeft: 10,
        borderRadius: "4px 4px 0px 0px",
        height: 4,
        maxWidth: 128
      },
      pastTabtabsIndicator: {
        backgroundColor: "#1890ff",
        marginLeft: -5,
        borderRadius: "4px 4px 0px 0px",
        height: 4,
        maxWidth: 128
      },
      eventdate_loc: {
        width: 121,
        height: 14,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: -0.3,
        color: " #858585",
      },

      event_img_container: {
        maxWidth: 120,
        maxHeight: 120,
        borderRadius: 12,
      },
      event_Paper: {
        margin: "auto",
        padding: 24,
        marginBottom: 30,
        height: 120,
        width: "100%",
        background: '#FFFFFF',
        border: '1px solid #C5C5C5',
        borderRadius: 18,
      },
      eventCard: {
        padding: 0,
        background: "white",
        borderRadius: 15,
        marginBottom: 30,
        height: 120,
      },

      icon: {
        height: 15,
        width: 15,
      },

      heading2: {
        fontSize: 12,
        fontWeight: 400,
      },

      heading3: {
        fontSize: 10,
        fontWeight: 300,
      },

      py_3: {
        padding: ".5rem 0",
      },

      w_60: {
        width: "100%",
        paddingRight: 0,
      },

      h2: {
        fontSize: 15,
      },
      desktopLayoutContent: {
        display: "none",
      },

      layOutbuttons: {
        width: 76,
        height: 21,
        borderRadius: "8px",
        lineHeight: 2,
        marginTop: 0,
        color: COLORS.white,
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
      },

      navigationIcon: {
        color: COLORS.skyBlue,
        backgroundColor: "white",
        margin: "8px",
        padding: "13px",
        height: 50,
        borderRadius: 30,
        zIndex: 999,
        top: -25,
        right: -29,
      },
      mobileLayoutContent: {
        width: "50%",
        display: "inline-grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "self-start",
        "@media (min-width:600px)": {
          width: "50%",
          position: "relative",
        },
      },
    },

    // outer 

    "searchreletiveenv": {
      "position": "relative"
    },
    "searchfilterEnv": {
      "position": "absolute",
      "height": "100vh",
      "maxHeight": "555px",
      "top": "63px",
      "padding": "10px",
      "background": "#FFFFFF",
      "border": "1px solid #D5D5D5",
      "borderRadius": "13px"
    },


    "leftmenuDetails": {
      "height": "100%"
    },
    "backIconWeb": {
      "display": "flex",
      "alignItems": "center"
    },
    "settingContent": {
      "marginLeft": "50px"
    },

    "filterPositions": {
      "position": "absolute",
      "top": "0px",
      "left": "0px",
      "transform": "translate3d(575px, 119px, 0px)",
      "willChange": "transform"
    },
    "pastImg": {
      "marginTop": "-36px",
      "borderRadius": "15px"
    },
    event_Paper: {
      margin: "auto",
      padding: 24,
      marginBottom: 30,
      height: 180,
      width: "100%",
      background: '#FFFFFF',
      border: '1px solid #C5C5C5',
      borderRadius: 18,
      cursor: "pointer"
    },


  });

// Customizable Area End

class SearchScreenWeb extends DashboardController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start






  // Render event tag for desktop view
  renderEventsTagInDesktop = (item: any) => {
    // Styles for the event tag container
    let eventTagContainerStyles: any = {
      position: "absolute",
      top: 0,
      right: 0,
      width: "66px",
      height: "31px",
      borderBottomLeftRadius: "1.2rem",
      borderTopRightRadius: "1rem"
    };

    // Styles for the event tag text
    let eventTagTextStyles: any = {
      top: "12px",
      color: COLORS.white,
      fontSize: "12px",
      lineHeight: "28px",
      textAlign: "center",
      letterSpacing: "-0.3px",
      backgroundColor: this.check_event_type(item.event_type) as any,
      borderRadius: "0px 14px"
    };

    return (
      <div style={eventTagContainerStyles}>
        <Typography style={eventTagTextStyles}>
          {this.check_event_type_name(item.event_type) as any}
        </Typography>
      </div>
    );
  };



  // Render event tag for mobile view
  renderEventsTagInMobile = (item: any) => {
    // Styles for the event tag container
    let eventTagContainerStyles = {
      backgroundColor: this.check_event_type(item.event_type) as any,
      height: 24,
      borderRadius: "8px",
      lineHeight: 2,
      marginTop: 0,
      width: 76,
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      color: COLORS.white,
      marginLeft: 6
    };

    return (
      <div style={eventTagContainerStyles}>
        {this.check_event_type_name(item.event_type) as any}
      </div>
    );
  };

  handleRedirect = (item: any) => {
    if (item.type === "non_profit_entity") {
      this.props.navigation.navigate("NonProfitProfile",{ id: item.organisation_id });
    }
  }

  renderCountry = (location: string, post_code: string) => {
    return post_code ? post_code.substring(0, post_code.indexOf(" ")) || post_code : location?.slice(location.lastIndexOf(",") + 1).trim();
  }

  renderLocation = (location: string, post_code: string) => {
    if (post_code && !location.includes(post_code)) {
      location += `, ${post_code}`;
    }
    return location;
  }

  renderUpcomingPastCardContainer = (item: any) => {
    // Styles for the card container

    // Styles for the organization container
    let organizationContainerStyles: any = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    };

    // Styles for the organization logo
    let organizationLogoStyles: any = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 8,
      width: "100%"
      // marginTop: 29,
    };

    // Styles for the organization logo image
    let organizationLogoImageStyles: any = {
      height: 20,
      width: 20,
      borderRadius: 50,
    };

    // Styles for the organization name
    let organizationNameStyles: any = {
      marginLeft: 9,
      fontFamily: "Rene_Bieder_Milliard_Medium",
      fontSize: "16px",
      width: "100%",
   };

    // Styles for the event info container
    let eventInfoContainerStyles: any = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: 8,
      width: "100%",
    };

    // Styles for the event info icon
    let eventInfoIconStyles: any = {
      display: "flex",
      alignItems: "center",
      marginTop: 11,
    };

    // Styles for the event info icon image
    let eventInfoIconImageStyles: any = {
      height: 20,
      width: 20,
    };

    // Styles for the event info text
    let eventInfoTextStyles: any = {
      marginLeft: 9,
      ...FONTS.paragraph,
      fontSize: 14,
      letterSpacing: "0.0571429px",
      width: 200,
      marginTop: 6,
    };

    const pledgeId = item.my_pledge_id ?? item.need_my_pledge_id;
    const status = pledgeId && pledgeId.length > 0 && pledgeId[0].status;
    const isApproved = status === "Approved" || item.event_type === "project_funding" || item.event_type === "activity";

    return (
      <div style={{ width: "100%" }} className={classesOuter.card_carousel_div}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              {isMobile && <div>{this.renderEventsTagInMobile(item)}</div>}
              <div>
                <Typography
                  variant="body1"
                  style={{
                    marginLeft: 9,
                    ...FAMILYFONTS.family1,
                    fontSize: 16,
                    marginTop: !isMobile ? 0 : 4,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: isMobile ? "100%" : "max-content",
                    overflow: "hidden",
                    maxWidth: 250,
                  }}
                >
                  {item.event_name ?? item.title_of_need}
                </Typography>
              </div>

              {/* Render organization logo and name */}
              <div style={organizationContainerStyles}>
                <div style={organizationLogoStyles} className={classesOuter.cardRightSubTitleText}
                  data-test-id = "redirect-btn"
                 onClick={(e) => {
                  e.stopPropagation();
                  this.handleRedirect(item);
                }}
                >
                  <Avatar
                    style={organizationLogoImageStyles}
                    src={item.organisation_image_url?.url || item.user_image?.url}
                    alt="notFound"
                  />
                  <Typography style={organizationNameStyles} variant="body2" className={classesOuter.cardRightText}>
                    {item.organisation_name ?? item.user_name}
                  </Typography>
                </div>
              </div>

              {/* Render event date */}
              <div style={eventInfoContainerStyles}>
                <div style={eventInfoIconStyles}>
                  <img style={eventInfoIconImageStyles} src={icons.calender} />
                  <Typography style={eventInfoTextStyles} variant="body2" gutterBottom className={classesOuter.cardRightDatenLocation}>
                    {moment(item?.from_date ?? item.start_date).format("DD MMM YYYY")} {moment(item?.start_time).format("HH:mm a")}
                  </Typography>
                </div>
              </div>

              {/* Render event location */}
              <div style={{ flexDirection: "row", display: "flex", alignItems: "center", marginLeft: 6, marginTop: 2 }}>
                <div style={{ display: "flex", alignItems: "center", marginLeft: 2 }}>
                  <img style={{ height: 20, width: 20, marginTop: 5 }} src={icons.locationred} />
                  <Typography title={item.location} variant="body2" gutterBottom style={{
                    marginLeft: 9, fontFamily: 'Rene_Bieder_Milliard_Book', fontSize: 12, fontWeight: 400, marginTop: 6, color: "#aeaeae", marginBottom: 0, textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    maxWidth: 200
                  }}>
                   {isApproved
                    ? this.renderLocation(item.location, item.post_code)
                    : this.renderCountry(item.location, item.post_code)}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };






  // Render the image for upcoming or past event
  renderUpcomingPastImage = (item: any) => {
    // Check if the event has an image
    // const eventImage = item?.event_image?.url || images.cardImg2;
    return <img src={item?.event_image?.url || images.cardImg2} alt="" className={classesOuter.event_main_img} style={{ position: 'relative' }} />;
  };

  renderUpcomingPastCard = (classes: { root: string | undefined; event_Paper: any; event_img_container: string | undefined; btn_dbs: string | undefined; }, item: any, index: React.Key | undefined) => {
    // Handle click event for the card
    const handleClick = (type: any, eventType: any, eventId: string) => {
      if (type === "non_profit_entity") {
        if (eventType === "activity") {
          this.props.navigation.navigate("EventInfo", { id: eventId });
        } else if (eventType === "need") {
          this.props.navigation.navigate("EventsDetails", { id: eventId });
        } else if (item.attributes.event_type === "project_funding") {
          this.props.navigation.navigate("FundCampaign", { id: eventId });
        }
      } else {
        this.props.navigation.navigate("EventsDetails", { id: "r1-" + eventId });
      }
      this.props.setSelectedEvents(item);
    };

    const placeholderImg=localStorage.getItem('placeholderImg')

    return (
      <div
        className={classes.root}
        key={index}
        style={{ marginBottom: 10, position: "relative" }}
        data-testId={`upcomingPastEvents-${item.id}`}
        onClick={() => { handleClick(item.attributes.type, item.attributes.event_type, item.id) }}
      >
        <Paper className={`${classes.event_Paper} ${classesOuter.eventPaperCard}`} elevation={0} style={{ position: 'relative', height: "fit-content" }}>
          {/* Render event tags for desktop view */}
          <div style={{ position: 'absolute', top: 0, right: 0 }}> {!isMobile && this.renderEventsTagInDesktop(item?.attributes)}</div>
          <Grid container >
            <Grid item xs={4} md={5}>
              <div className={classes.event_img_container}>
                <ImageLoader loaderStyle={{textAlign:"center",marginTop:44,marginLeft:54}} imageUrl={item.attributes?.event_image?.url || item.attributes?.need_photo?.url || images.cardImg2 ||placeholderImg } classes={classesOuter.event_main_img}/>

                {/* Display "DBS" tag if the event requires DBS registration */}
                {item.attributes.dbs_registration && <span className={classes.btn_dbs}>DBS</span>}

                {/* Render favorites icon */}
                {!isMobile && (
                  <div style={{ position: 'absolute', right: 0, top: 0 }}>
                    {item.attributes.is_favourite
                      ? <FavoriteIcon
                        data-testId="unfavorite"
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({ searchValue: this.props.searchValue });
                          this.removeFromFav(item)
                        }}
                        className={classesOuter.favorite}
                        style={{ color: "red" }}
                      />
                      :
                      <FavoriteBorderIcon
                        data-testId="favorite"
                        className={classesOuter.favorite}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ searchValue: this.props.searchValue });
                          this.addToFav(item.id, item.attributes.type);
                        }}
                      />
                    }
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={8} md={7}>
              {/* Render the content container for the event card */}
              {this.renderUpcomingPastCardContainer(item?.attributes)}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  };

  renderInitialUpcomingPastCard = () => {
    return (
      <Paper
        style={{
          margin: "auto",
          padding: 24,
          marginBottom: 30,
          height: 150,
          width: "100%",
          background: "#FFFFFF",
          borderRadius: 18,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
        }}
        elevation={0}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}>
            {/* Render a skeleton image */}
            <Skeleton variant="rect" className={classesOuter.event_main_img} height={118} />
          </Grid>
          <Grid item xs={6} md={8}>
            {/* Render skeletons for event details */}
            {[...Array(6)].map((_, index) => (
              <Skeleton key={index} />
            ))}
          </Grid>
        </Grid>
      </Paper>
    );
  };


  renderUpcomingPastEventsDataContainer = (filterEventsReducer: any, classes: any) => {
    const { initialRender } = this.state;

    // Check if filterEventsReducer is undefined or has no events to display
    if (!filterEventsReducer || filterEventsReducer.length === 0) {
       // Render "No data found!" card only after the initial rendering and if search filter is not applied
      if (!initialRender && !filterEventsReducer) {
        return (
          <Paper
            className={`${classes.event_Paper} ${classesOuter.eventPaperCard}`}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}
            elevation={0}
          >
            No data found!
          </Paper>
        );
      }
      if(this.props.mainPageLoader){
        return (
          <div
            style={{
              height: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ color: "#009be2" }} />
          </div>
        );
      }
       // Don't render anything if no events are found or during initial rendering
    }

    return filterEventsReducer?.map((item: any, index: number) => this.renderUpcomingPastCard(classes, item, index));
  };

  loader() {
    return (
      <div
        style={{
          height: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress style={{ color: "#009be2" }} />
      </div>
    ); 
  }


  renderUpcomingPastEvents = (classes: any, filterEventsReducer: any) => {
    return (
      <div className={classes.root}>
        <Container maxWidth="xl">
          {this.renderUpcomingPastEventsDataContainer(filterEventsReducer, classes)}
        </Container>
      </div>
    );
  };


  handleDBSSwitch(value: any) {
    this.setState({ dbsSwitch: value })
  }

  handleAllDay(value: any) {
    this.setState({ allDay: value })
  }

  setFromDate(value: any) {
    this.setState({ fromDate: value })
  }

  setToDate(value: any) {
    this.setState({ toDate: value })
  }

  setStartTime(value: any) {
    this.setState({ startTime: value })
  }

  setEndTime(value: any) {
    this.setState({ endTime: value })
  }
  setCalOpen(value: any) {
    this.setState({ calOpen: value })
  }

  setValueOpen(value: any) {
    this.setState({ valueOpen: value })
  }













  // Renders the  bar component






  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, theme, filterEventsReducer }: any = this.props;
    const openfilter = Boolean(this.state.setAnchorEl);
    console.log(this.props.mainPageLoader, 'mainpageloader')
    const filteroptionid = openfilter ? 'simple-popper' : undefined;
    // Customizable Area Start
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.eventType && <Redirect to={`${this.state.eventType}`} />}
        {/* Dashboard */}
        {/* @ts-ignore */}

        <Container maxWidth="xl">


          <Grid container>
            <Grid item xs={12} sm={7} style={{ overflow: "auto", height: 'calc(100vh - 215px)'}}>
              {/* Tab block */}
              { this.props.mainPageLoader ? this.loader() : this.renderUpcomingPastEvents(classes, filterEventsReducer)} {/* Render upcoming and past events */}
              {/* Tab block end */}
            </Grid>

            <Grid item xs={12} sm={5} className={classesOuter.rightmenuDetails} >
              <Paper elevation={0} style={{ maxHeight: "calc(100vh - 218px)", overflow: "auto" }}
                className={classesOuter.searchfilterEnvDbSRight}>
                {/* DbsSearch component */}
                <DbsSearch
                  rightSideFilter={true}
                  getDbsData={this.props.getDbsData}
                  handleCategoryChange={this.props.handleCategoryChange}
                  categories={this.props.categories}
                  searchEventCall={this.props.searchEvent}
                  savedSearchCall={(data: any) => this.props.savedSearches(data)}
                  searchValue={this.props.searchValue}
                  dbsSwitch={this.props.dbsSwitch}
                  allDay={this.props.allDay}
                  handleDBSSwitch={(value: any) => this.props.handleDBSSwitch(value)}
                  handleAllDay={(value: any) => this.props.handleAllDay(value)}
                  fromDate={this.props.fromDate}
                  toDate={this.props.toDate}
                  calOpen={this.props.calOpen}
                  setFromDate={(value: any) => this.props.setFromDate(value)}
                  setToDate={(value: any) => this.props.setToDate(value)}
                  setCalOpen={(value: any) => this.props.setCalOpen(value)}
                  valueOpen={this.state.valueOpen}
                  setValueOpen={(data: any) => this.setValueOpen(data)}
                  startTime={this.props.startTime}
                  endTime={this.props.endTime}
                  setStartTime={(data: any) => this.props.setStartTime(data)}
                  setEndTime={(data: any) => this.props.setEndTime(data)}
                  proxyValue={this.props.proxyValue}
                  setProxyValue={(data: any) => this.props.setProxyValue(data)}
                  advanceSearchToggle = {this.props.advanceSearchToggle}
                  setAdvanceSearchToggle = {(data : any) =>  this.props.setAdvanceSearchToggle(data)}
                  setMainPageLoader= {(data : any) => this.props.setMainPageLoader(data)}
                />
              </Paper>
            </Grid>
          </Grid>

        </Container>


      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
SearchScreenWeb.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export function mapStateToProps(state: any) {
  console.log(state, "chekeventState")
  return {
    selectedevents: state.eventsReducer?.selectedEvents,
    filterEventsReducer: state.filterEventsReducer?.selectedEvents

  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    storeSearchDetails: (savedSearchinfo: any) => {
      return dispatch(storeSearchDetails(savedSearchinfo))
    },
    setSelectedEvents: (selectedTab: any) => {
      return dispatch(setSelectedEvents(selectedTab))
    },
    getAllEventsDetails: (selectedTab: any) => {
      return dispatch(getAllEventsDetails(selectedTab))
    },
    setSelectedFilterEvents: (selectedEvents: any) => {
      return dispatch(setSelectedFilterEvents(selectedEvents))
    },
    setLocationWiseFilterEvent: (selectedEvents: any) => {
      return dispatch(setLocationWiseFilterEvent(selectedEvents))
    },
    setLocationWiseFilterEventMessage: (message: any) => {
      return dispatch(setLocationWiseFilterEventMessage(message))
    },

  }
}

export { SearchScreenWeb }
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any, { withTheme: true })(
  SearchScreenWeb as any
));

// Customizable Area End