import React, { useEffect } from "react";
// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { successIcon } from "./assets";
import { FONTS, FAMILYFONTS } from "../../../components/src/constants";
import { useNavigate } from "../../../components/src/utils/router-context";
import { InitiateButton } from "../../../components/src/design-system/button";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { useDispatch } from "react-redux";
import { saveSignupForm } from "../../../framework/src/redux/signup-form/signup-form.actions";
// Customizable Area End

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      height: '85vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  headIcon: {
    backgroundImage: "linear-gradient(#00cbf3, #009be2)",
    width: "168px",
    height: "167px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    '& img': {
      height: "82px", margin: "auto", width: "82px"
    },
  },
  textContainer: {
    padding: '40px 0 120px 0',
    textAlign: 'center',
  },
}));

// Customizable Area Start
interface Iprops {
  type:string,
}
export function SuccessfulRegisteration({type}:Iprops) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(function onLoad() {
    localStorage.removeItem('email');
    localStorage.removeItem('full_phone_number');
    localStorage.removeItem('full_name');
    dispatch(saveSignupForm({
      full_name: '',
      email: '',
      full_phone_number: '',
    }));
  }, []);
// Customizable Area End

  return (
    // Customizable Area Start
    <Box className={classes.root}>

      <div>
        <Box className={classes.headIcon}>
          <img src={successIcon} alt="email" />
        </Box>

        <Box className={classes.textContainer}>
          <Typography variant="h5" style={{ ...FONTS.paragraph }}>{'Congrats!!'}</Typography>
          <VerticalSpacer value={10} />
          <Typography style={{ ...FONTS.paragraph }}>{'Account successfully Verified.'}</Typography>
        </Box>
      </div>

      <InitiateButton
        color="primary"
        variant="contained"
        fullWidth
        size="large"
        onClick={() => { 
          if(type==='login'){
            navigate("/HomeDashBoard");
          }
          else{
            navigate('/ResetPasswordWeb');
          }
           }}
        style={{ ...FAMILYFONTS.family1, fontSize: "20px" }}
      >
        {type==='login'?'Go To Dashboard':'Reset Password'}
      </InitiateButton>

    </Box>
    // Customizable Area End
  );
}

