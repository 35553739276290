import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
  label: string;
  value: string;
}
export interface AttendedUsers {
  account_id: number;
  full_name: string;
  status: null | string;
  profile_picture: ProfilePicture;
}
export interface ProfilePicture {
  url: string;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  email: string;
  password: string;
  selectedNeedsTabChange: any;
  pastNeedsTabData: any;
  activeNeedsTabData: any;
  cardData: any;
  needModel: boolean;
  deleteNeedId: number;
  selectedId: any;
  connectionData: any;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginAPICallId: string = "";
  activeNeedsTabApiCallId: string = '';
  pastNeedsTabApiCallId: string = '';
  deleteNeedApiCallId: string = '';
  addFavouriteAPICallID: string = '';
  removeFavouriteAPICallID: string = '';
  connectionDataAPICall: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: "",
      password: "",
      selectedNeedsTabChange: "Active",
      pastNeedsTabData: [],
      activeNeedsTabData: [],
      cardData: [],
      needModel: false,
      deleteNeedId: 0,
      selectedId: '',
      connectionData: [],
      loading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.ActiveNeedsTabData();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.error) {
          toast.error(`${responseJson.error}`);
        } else if (responseJson.errors?.length > 0) {
          toast.error(`${responseJson.errors[0].token}`)
        } else {
          this.checkAPIResponses(apiRequestCallId, responseJson)
         
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkAPIResponses = (apiRequestCallId:any, responseJson:any) => {
    switch (apiRequestCallId) {
      case this.pastNeedsTabApiCallId:
        this.setState({ pastNeedsTabData: responseJson.data, cardData: responseJson.data, loading: false })
        break;

      case this.activeNeedsTabApiCallId:
        this.setState({ activeNeedsTabData: responseJson.data, cardData: responseJson.data, loading: false })
        break;

      case this.deleteNeedApiCallId:
        this.setState({ needModel: false })
        toast.success(responseJson.message);
        this.ActiveNeedsTabData()
        break;

      case this.addFavouriteAPICallID:
        toast.success("Added to favourites!");
        if (this.state.selectedNeedsTabChange === "Active") {
          this.ActiveNeedsTabData(true)
        } else {
          this.PastNeedsTabData(true)
        }
        break;

      case this.removeFavouriteAPICallID:
        toast.success("Removed from favourites!");
        if (this.state.selectedNeedsTabChange === "Active") {
          this.ActiveNeedsTabData(true)
        } else {
          this.PastNeedsTabData(true)
        }
        break;

      case this.connectionDataAPICall:
        this.setState({ connectionData: responseJson.data })
        break;
    }
  }
  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  deleteNeed = () => {
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteNeedApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_events/needs/${this.state.deleteNeedId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  PastNeedsTabData = (isFavoriteApi?: boolean) => {
    this.setState({ loading: isFavoriteApi ? false : true });
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pastNeedsTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_events/needs/past_needs`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ActiveNeedsTabData = (isFavoriteApi?: boolean) => {
    this.setState({ loading: isFavoriteApi ? false : true });
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.activeNeedsTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_events/needs/active_needs`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addFavourite = (id: number) => {
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: token,
    };

    const objToPass = {
      "data": {
        "favouriteable_id": id,
        "favouriteable_type": "BxBlockEvents::Need"

      }, "token": token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFavouriteAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_favourites/favourites`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(objToPass)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeFavourite = (id: number, favourite_id: number) => {
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
    };

    const objToPass = {
      "token": token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFavouriteAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites/${favourite_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(objToPass)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  connectionData = () => {
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectionDataAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_events/needs/connections`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddNewNeed = (id: any) => {
    if(this.state.selectedNeedsTabChange !== "Past"){
      this.props.navigation.navigate("PublishNeed",{ id: id });
    }
  }
  // Customizable Area End
}
