import React from "react";
// Customizable Area Start
import AdminRegistrationController, { Props } from "./AdminRegistrationController";
//@ts-ignore
import classOuter from './NpAccountVerificationMessage.module.css'
import { FAMILYFONTS, images } from "../../../components/src/constants";
import {
  Typography,
  Button,
  Container,
  Box,
} from "@material-ui/core";
// Customizable Area End

class NpResetPasswordSuccessMessage extends AdminRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        {/* Password Success Message Logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.otpLogoMainContainer}>
            <div className={classOuter.otpLogoContainer}>
              <img src={images.imgCheck} className={classOuter.otpLogo} />
            </div>
          </div>

          {/* Form Content */}
          <div>
            <Box sx={{ width: "100%" }}>
              <Box className="forgotPwdMsg">

                {/* Congrats Message */}
                <Typography
                  variant="h4"
                  component="h2"
                  className={classOuter.congratsText}
                  style={{ ...FAMILYFONTS.family1, fontSize: 24, fontWeight: 400 }}
                >
                  Congrats!!
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classOuter.congratsSubText}
                  style={{ ...FAMILYFONTS.family3, fontSize: 24, fontWeight: 400 }}
                >
                  Password successfully updated.
                </Typography>

                {/* Sign In button */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px"
                  }}
                >
                  <Button
                    data-test-id="btnResetPwdToSignIn"
                    variant="contained"
                    color="primary"
                    className={classOuter.footerBtn}
                    fullWidth
                    onClick={this.goToSignIn}
                    style={{
                      fontFamily: "Rene_Bieder_Milliard_Semibold",
                      fontWeight: 500,
                      fontSize: 18,
                    }}
                  >
                    Sign in
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }
}

export default NpResetPasswordSuccessMessage;