import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { KeyboardTimePickerProps } from "@material-ui/pickers/TimePicker/TimePicker";
import { FC } from "react";

// Customizable Area Start
import { CurrentLocation, getCurrentLocation } from "../../../components/src/utils/current-location";
export interface EventObject {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  title_of_need?: string|null;
  need_type?: string;
  event_type?: string;
  unique_name: string;
  event_name?: string;
  address?:boolean|null;
  dbs_registration?:boolean;
  description?: string;
  start_date: string;
  end_date: string;
  start_time:string;
  end_time:string;
  flexible:boolean;
  publish:string;
  latitude:number;
  longitude:number;
  location:string;
  post_code:string;
  need_photo:Url;
  favourite?:boolean|null;
  all_day:boolean;
  account_id:number;
  friend_ids:[];
  user_name:string;
  user_image:Url;
  user_latitude:number|null;
  user_longitude:number|null;
  event:string;
  is_favourite?:boolean|null;
  favourite_id?:string;
  need_my_pledge_id:[];
  type:string;
  attended:[];
  additional_information:string|null;
}
export interface Url {
  url?: string|null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  advFilterDetails : any;
  setAdvFilter : any;
  filterEventsReducer : any;
  setAdvSearchPayload : any;
  advSearchPayloadDetails : any;
  setMainPageLoader: any;
  classes: any;
  Box: any;
  addSearchFilterDetails: any;
  KeyboardTimePicker: FC<KeyboardTimePickerProps>;
  AntSwitch: any;
  setSelectedFilterEvents: any;
  showsavedsearch: any;
  displayInweb: any;
  storeSearchDetails: any;
  savedSearchData: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  searchEventsLoader : boolean;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  setadvancefilterCategory: any;
  proximityShow: any;
  setAdvancesearchModal: any;
  saveSearch: any;
  saveSearchValue: any;
  upComingPastEventError: any;
  isDateVisible: boolean,
  showSaveBtn: boolean
  deleteModal: boolean
  deleteId: any
  savedSearchName: any
  lat: any
  lng: any
  currentLocation: CurrentLocation
  allUpcomingEvents: any
  filteredAllUpcomingEvents:(EventObject)[]
  searchError: boolean
  allSavedSearchData: any
  advSearchEvents : any
  renderLoader:boolean;
  dbsData?:any;
  dateVisible: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  getDbsCateId: any;
  searchEventMobileId: any;
  searchAdvSearchId : any
  searchInputQueryId: any;
  upcomingEventsId: any;
  getSavedSearchId: any;
  savedSearchMobileId: any;
  getAllSavedSearchId: any;
  savedsearchdeleteId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      searchEventsLoader : false,
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      setadvancefilterCategory: false,
      proximityShow: false,
      setAdvancesearchModal: false,
      saveSearch: false,
      saveSearchValue: false,
      upComingPastEventError: "",
      isDateVisible: false,
      showSaveBtn: false,
      deleteModal: false,
      deleteId: 0,
      savedSearchName: "My Custom Search",
      lat: '',
      lng: '',
      currentLocation: {
        area: '',
        city: '',
        address: '',
        country: '',
        doorName: '',
        postcode: '',
        longitude: 0,
        latitude: 0
      },
      allUpcomingEvents: [],
      filteredAllUpcomingEvents:[],
      searchError: false,
      allSavedSearchData: [],
      advSearchEvents : null,
      renderLoader:true,
      dateVisible: "",
      dbsData:[]
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    console.log(this.props, 'propsfrommount')
    this.getDbsCategories();
    this.getAllUpcomingEvents();
    this.getAllSavedSearch();
    this.changeDateText(new Date());
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.getDbsCateId) {
        this.handleDbsResponse(responseJson)
        
      }
      else if (apiRequestCallId === this.searchEventMobileId) {
        this.handleAPIResponse(responseJson)

      } else if (apiRequestCallId === this.upcomingEventsId) {
        this.setState({ allUpcomingEvents: responseJson?.data ,filteredAllUpcomingEvents:responseJson?.data,searchEventsLoader : false})
        this.setState({ searchError: responseJson.error ? true : false })
      } else if (apiRequestCallId === this.savedSearchMobileId) {
        this.handleClickCloseadvancesearchModal()
        this.getSavedSearch()
        //navigate to events based upon filter
        this.searchEvent()
      } else if (apiRequestCallId === this.getSavedSearchId) {
        this.props.storeSearchDetails(responseJson?.data)

      } else if (apiRequestCallId === this.savedsearchdeleteId) {
        this.setState({ deleteModal: false })
        // this.getAllSavedSearch()
      }
      else if (apiRequestCallId === this.getAllSavedSearchId) {
        this.setState({renderLoader:false})
        this.handleGetAllSavedSearch(responseJson)
      }
      else if (apiRequestCallId === this.searchAdvSearchId) {
        this.handleSearchAdvSearchAPI(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleSearchAdvSearchAPI(responseJson : any) {
    if (responseJson?.MSG) {
      this.props.setSelectedFilterEvents(responseJson.MSG)
    } else if (responseJson?.data) {
      this.props.setSelectedFilterEvents(responseJson.data)
    }else if (responseJson?.error) {
      this.props.setSelectedFilterEvents(responseJson.error)
    }
  }

  handleAPIResponse(responseJson: any) {
    if (responseJson?.MSG) {
      this.props.navigation.navigate("AdvSearchEvents")
      this.props.setSelectedFilterEvents(responseJson.MSG)
      this.setState({advSearchEvents : responseJson.MSG})
      this.setState({ upComingPastEventError: responseJson.MSG });
    } else if (responseJson?.data) {
      this.props.setSelectedFilterEvents(responseJson.data)
      this.props.navigation.navigate("AdvSearchEvents")
      this.setState({advSearchEvents : responseJson.data})

    }else if(responseJson?.errors[0]){
      this.props.navigation.navigate("AdvSearchEvents")
      this.props.setSelectedFilterEvents(responseJson.errors[0])
      this.setState({advSearchEvents : responseJson.errors[0]})
      this.setState({ upComingPastEventError: responseJson.errors[0] });

    }
  }

  handleAPIResponse2(responseJson: any) {
    this.setState({ searchError: responseJson.error ? true : false ,searchEventsLoader : false})
    !responseJson.error && this.setState({ allUpcomingEvents: responseJson.data })
  }

  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  getSavedSearch = () => {
    const header = {
      "token": localStorage.getItem("token"),
      type:"user_entity"
    };
    const fetchSavedSearch = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedSearchId = fetchSavedSearch.messageId;

    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.savedsearchApiEndPoint
    );

    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(fetchSavedSearch.id, fetchSavedSearch);
  }


  getDbsCategories = () => {
    const getDbsCate = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDbsCateId = getDbsCate.messageId;

    getDbsCate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTasksEndPoint
    );

    getDbsCate.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getDbsCate.id, getDbsCate);
  }
  handleDbsResponse=(responseJson:any)=>{
    this.setState({dbsData:responseJson?.data})

      this.props.setAdvFilter({
        ...this.props.advFilterDetails,
        getDbsData: responseJson?.data
      })
    
  }


  handleClickOpenadvancesearchModal = () => {
    this.setState({ setAdvancesearchModal: true })
  };

  handleClickCloseadvancesearchModal = () => {
    this.setState({ setAdvancesearchModal: false, saveSearchValue: true })
  };

  getAllSavedSearch = () => {
    this.setState({ renderLoader:true})
    const header = {
      "token": localStorage.getItem("token"),
      type:"user_entity"
    };

    const fetchSavedSearch = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSavedSearchId = fetchSavedSearch.messageId;

    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.advance_search_api_end_point
    );
    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    fetchSavedSearch.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(fetchSavedSearch.id, fetchSavedSearch);
  }


  advanceSearchEvent() {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: localStorage.getItem('token'),
      type:"user_entity"
    };
    const start_time = new Date(this.props.advFilterDetails?.start_time);
    start_time.setSeconds(0);
    const end_time = new Date(this.props.advFilterDetails?.end_time);
    end_time.setSeconds(0);
    const attrs = {
      saved_data: this.props.advFilterDetails?.all_day ? {
        dbs_included: this.props.advFilterDetails?.dbs_registration,
        categories: this.props.advFilterDetails?.categories,
        up_to_km: this.props.advFilterDetails?.up_to_km,
        all_day: this.props.advFilterDetails?.all_day,
        saved_name: this.state.savedSearchName,
        from_date: moment(this.props.advFilterDetails?.from_date).format("DD/MM/YYYY"),
        to_date: moment(this.props.advFilterDetails?.to_date).format("DD/MM/YYYY"),
        start_time: new Date(moment('09:00', 'HH:mm').toString()).toString(),
        end_time: new Date(moment('17:00', 'HH:mm').toString()).toString(),
        location : localStorage.getItem("user_saved_location") || localStorage.getItem("current_location")
      } :
        {
          dbs_included: this.props.advFilterDetails?.dbs_registration,
          categories: this.props.advFilterDetails?.categories,
          from_date: moment(this.props.advFilterDetails?.from_date).format("DD/MM/YYYY"),
          to_date: moment(this.props.advFilterDetails?.to_date).format("DD/MM/YYYY"),
          start_time: start_time.toString(),
          end_time: end_time.toString(),
          up_to_km: this.props.advFilterDetails?.up_to_km,
          all_day: this.props.advFilterDetails?.all_day,
          saved_name: this.state.savedSearchName,
          location : localStorage.getItem("user_saved_location") || localStorage.getItem("current_location")
        }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.savedSearchMobileId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.advance_search_api_end_point
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPOSTMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  getIdsFromDbs=(selected:any)=>{
    let selectedId=this.state.dbsData?.map((item:any)=>{
     if(selected?.includes(item?.attributes?.task)){
       return item?.id
     }
    })
    return selectedId?.filter((id:any)=>id)
   }

  allDayProps = (data : any) => {
    return {
      dbs_registration: data?.dbs_registration || this.props.advFilterDetails?.dbs_registration,
      categories: this.getIdsFromDbs(data?.categories || this.props.advFilterDetails?.categories),
      up_to_km: data?.up_to_km || this.props.advFilterDetails?.up_to_km,
      start_time: data?.start_time || moment('09:00', 'HH:mm').utc().format('HH:mm'),
      end_time: data?.end_time || moment('17:00', 'HH:mm').utc().format('HH:mm'),
      from_date: data?.from_date || moment(this.props.advFilterDetails?.from_date).format("DD/MM/YYYY"),
      to_date: data?.to_date || moment(this.props.advFilterDetails?.to_date).format("DD/MM/YYYY"),
      location: data?.location || localStorage.getItem("user_saved_location") || localStorage.getItem("current_location"),
    }
  }

  notAllDayProps = (data : any) => {
    return {
      dbs_registration: data?.dbs_registration || this.props.advFilterDetails?.dbs_registration,
      categories: this.getIdsFromDbs(data?.categories || this.props.advFilterDetails?.categories),
      from_date: data?.from_date || moment(this.props.advFilterDetails?.from_date).format("DD/MM/YYYY"),
      to_date: data?.to_date || moment(this.props.advFilterDetails?.to_date).format("DD/MM/YYYY"),
      start_time: data?.start_time || moment.utc(this.props.advFilterDetails?.start_time).format('HH:mm'),
      end_time: data?.end_time || moment.utc(this.props.advFilterDetails?.end_time).format('HH:mm'),
      up_to_km: data?.up_to_km || this.props.advFilterDetails?.up_to_km,
      location: data?.location || localStorage.getItem("user_saved_location") || localStorage.getItem("current_location"),
    }
  }



  searchEvent = (data?: any) => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: localStorage.getItem('token')
    };

    const attrs = this.props.advFilterDetails?.all_day ? this.allDayProps(data) : this.notAllDayProps(data)

    console.log("ATTRS",attrs);
    if(this.props.setAdvSearchPayload){
      this.props.setAdvSearchPayload(attrs)
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchEventMobileId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.search_api_end_point
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPOSTMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  searchAdvAllDay = (data : any) => {
return {
  dbs_registration: data?.dbs_registration || this.props.advFilterDetails?.dbs_registration,
  categories: data?.categories || this.props.advFilterDetails?.categories,
  up_to_km: data?.up_to_km || this.props.advFilterDetails?.up_to_km,
  location: data?.location || localStorage.getItem("user_saved_location") || localStorage.getItem("current_location"),
  from_date: data?.from_date || this.props.advFilterDetails?.from_date,
  to_date: data?.to_date || this.props.advFilterDetails?.to_date,
  start_time: data?.start_time || moment('09:00', 'HH:mm').utc().format('HH:mm'),
  end_time: data?.end_time || moment('17:00', 'HH:mm').utc().format('HH:mm'),
} 
  }

  searchAdvNotAllDay = (data : any) => {
    return   {
      dbs_registration: data?.dbs_registration || this.props.advFilterDetails?.dbs_registration,
      categories: data?.categories || this.props.advFilterDetails?.categories,
      up_to_km: data?.up_to_km || this.props.advFilterDetails?.up_to_km,
      from_date: data?.from_date || this.props.advFilterDetails?.from_date,
      to_date: data?.to_date || this.props.advFilterDetails?.to_date,
      start_time: data?.start_time || this.props.advFilterDetails?.start_time,
      end_time: data?.end_time || this.props.advFilterDetails?.end_time,
      location: data?.location || localStorage.getItem("user_saved_location") || localStorage.getItem("current_location"),
    }

  }

  searchAdvSearch(search : any, data? : any) { 
    this.props.setSelectedFilterEvents('')
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: localStorage.getItem('token')
    };
    


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.searchAdvSearchId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.advanceSearchApiEndPoint}?search=${search}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPOSTMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

  }

  deletesavedsearchEvent = () => {

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: localStorage.getItem('token'),
      "type":"user_entity"
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.savedsearchdeleteId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savedsearchApiEndPoint}?id=${this.state.deleteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDELETEMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  // category select 
  handleCategoryChange = (e: any) => {

    
    const { name, checked } = e.target;
    
    if (name === "allCategoryCheck") {
      let categoryRef : any= []
      let tempCategory = this.props.advFilterDetails?.getDbsData.map((category: any) => {
    
        categoryRef.push(category.attributes.task)
    
        return  { ...category, isChecked: checked ? true : false};
      });
      this.allCheckedfunc(checked, tempCategory, categoryRef);

    } else {
      let tempCategory = this.props.advFilterDetails?.getDbsData.map((category: any) => (
        category.attributes.task === name ? { ...category, isChecked: checked } : category))
      this.singleCheckedfunc(checked, name, tempCategory);
    }

  };

  allCheckedfunc(checked: any, tempCategory: any, categoryRef : any) {
    console.log('tempcategory123',tempCategory)
    this.props.setAdvFilter({
      ...this.props.advFilterDetails,
      getDbsData: tempCategory,
      categories : categoryRef
    })
    if (!checked) {
      this.props.setAdvFilter({
        ...this.props.advFilterDetails,
        categories: [],
        getDbsData: tempCategory,

      })
    }
  }

  singleCheckedfunc(checked: any, name: any, tempCategory: any) {

    // this.setState({ getDbsData: tempCategory })
    this.props.setAdvFilter({
      ...this.props.advFilterDetails,
      getDbsData: tempCategory
    })
    if (checked) {
      this.props.setAdvFilter({
        ...this.props.advFilterDetails,
        getDbsData: tempCategory,
        categories : [...this.props.advFilterDetails?.categories, name]
      })

    } else {
      this.props.setAdvFilter({
        ...this.props.advFilterDetails,
        getDbsData: tempCategory,
        categories : this.props.advFilterDetails.categories.filter((val : any) => val != name)
      })

    }
  }

  // Get category values

  getCategoryValue = () => {
    const getCheckedCategories = this.props.advFilterDetails?.getDbsData?.filter((ele: any) => (
      ele.isChecked === true
    ))
    if (getCheckedCategories?.length > 0) {
      if (getCheckedCategories?.length > 1) {
        return `${getCheckedCategories[0]?.attributes?.task} +${getCheckedCategories?.length - 1}`
      }
      else {
        return getCheckedCategories[0]?.attributes?.task
      }
    }
    else {
      return ""
    }
  }

  handleDateRange(e: any) {

    this.changeDateText(e[0])
    this.setState({
      // fromDate: e[0],
      // toDate: e[1],
      // dateValue: e,
      isDateVisible: false,
      showSaveBtn: true
    })

    this.props.setAdvFilter({
      ...this.props.advFilterDetails,
      from_date : e[0],
      to_date: e[1],
      dateValue : e
    })
  }

  onAdvanceCllick() {
    this.setState({ setadvancefilterCategory: !this.state.setadvancefilterCategory ? true : false })
  }

  closeDate() {
    this.setState({
      isDateVisible: false,
    })
  }
  showDate() {
    this.setState({
      isDateVisible: true,
    })
  }

  formatDate(type: string) {
    if (type === 'from') return moment(this.props.advFilterDetails?.from_date).format('DD/MM/YYYY');
    if (type === 'to') return moment(this.props.advFilterDetails?.to_date).format('DD/MM/YYYY');

    // else return moment(new Date()).format('DD/MM/YYYY');
  }


  changeDateText(date: any) {
    let day = moment(date).format('DD MMM YYYY');
    let textElements = document.getElementsByClassName("react-calendar__navigation__label__labelText--from");
    if (textElements.length > 0) {
      let firstElement = textElements[0];
      let updatedText = day;
      firstElement.textContent = updatedText;
    }

  }

  handleAllDay(value : any) {
    this.props.setAdvFilter({
      ...this.props.advFilterDetails,
      all_day : value
    })
    console.log(value, this.props.advFilterDetails, 'propsfrommethod')
      if (!this.props.advFilterDetails?.all_day) {
      this.setState({
        showSaveBtn: true
      })
    } else {
      this.setState({
        showSaveBtn: false
      })
    }
  }


  searchinputChange = (queryval: string) => {
    const filteredRows = this.state.filteredAllUpcomingEvents.filter(
      (row: EventObject) =>
        row.attributes?.event_name
          ?.toLowerCase()
          .includes(queryval.toLowerCase()) ||
        row.attributes?.title_of_need
          ?.toLowerCase()
          .includes(queryval.toLowerCase()) 
    );
    this.setState({
      allUpcomingEvents: filteredRows,
    });   
  }

  setSavedSearchData = (item: any) => {
    this.setState({
      saveSearchValue: this.state.saveSearchValue
    })
   
      this.props.setAdvFilter({
        ...this.props.advFilterDetails,
        dbs_registration: item.saved_data?.dbs_included,
        start_time : item.saved_data.start_time,
        up_to_km : item.saved_data.radius,
        end_time : item.saved_data.end_time,
        categories : item.saved_data.categories,
        from_date : item.saved_data?.from_date,
        to_date : item.saved_data?.to_date
      })
    
    
    this.searchEvent(item?.saved_data)
  }

  getAllUpcomingEvents() {
    this.setState({searchEventsLoader : true})
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": localStorage.getItem("token"),
    };

    const getUpComingEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      location: localStorage.getItem("user_saved_location") || localStorage.getItem("current_location")
    }

    this.upcomingEventsId = getUpComingEvents.messageId;

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upcomingEventsEndPoint
    );

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPOSTMethod
    );
    runEngine.sendMessage(getUpComingEvents.id, getUpComingEvents);
  }

  handleGetAllSavedSearch=(responseJson:any)=>{
    
    if(responseJson?.data){
      this.setState({ allSavedSearchData: responseJson?.data })
      if(this.props.storeSearchDetails){
        this.props.storeSearchDetails(responseJson.data)
      }
      

    }
    else if(responseJson?.error){
      this.setState({ allSavedSearchData: [] })
      this.props?.storeSearchDetails([])
    }
  }

  handleDeleteModal=()=>{
    this.setState({deleteModal : false})
  }

  // Customizable Area End
}
