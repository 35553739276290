Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.httpPOSTMethod = 'POST'
exports.httpDELETEMethod = 'DELETE'
exports.advancedsearchApiContentType = 'application/json'
exports.advance_search_api_end_point = 'advanced_search_saved'
exports.errorTitle = 'Error'
exports.dbsCategoriesApiEndPoint = "dbs_types";
exports.advanceSearchApiEndPoint = "advance_search";
exports.search_event_by_nameApiEndPoint="search_event_by_name"
exports.search_api_end_point = "advance_search"
exports.savedsearchApiEndPoint="advanced_search_saved"
exports.getTasksEndPoint = 'task_preferences'
exports.api_end_point = "advance_search"
exports.upcomingEventsEndPoint = 'upcoming_events'
// Customizable Area End