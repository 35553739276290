// Customizable Area Start
// @ts-nocheck
import React from "react";

import {
    // Customizable Area Start
    Typography,
    Box,
    Container,
    Grid,
    Paper,
    Accordion,
    AccordionSummary,
    Button,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PropTypes from "prop-types";
import moment from "moment";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { constants } from "../../../components/src/constants";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
// @ts-ignore
import classesoutercss from "./SurveysDashboard.module.css";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withRouter } from 'react-router-dom'

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import SurveysDashboardController, {
    Props,
} from "./SurveysDashboardController";

class SurveysDashboard extends SurveysDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    };

    renderShowMoreCompletedText = (arrayData: any) => {
        return (
            arrayData?.length > 0 && this.state.itemToShow < arrayData.length && <Typography variant="subtitle1" data-testId='showMoreCompleted' onClick={() => this.handleItemShowMore()} className={classesoutercss.showMoreLink}>Show more <ChevronRightIcon /> </Typography>
        )
    }

    renderShowMoreText = (arrayData: any) => {
        return (
            arrayData?.length > 0 && this.state.itemsToShow < arrayData.length && <Typography variant="subtitle1" data-testId='showMore' onClick={() => this.handleShowMore()} className={classesoutercss.showMoreLink}>Show more <ChevronRightIcon /> </Typography>
        )
    }

    renderCompletedSurveys = () => {
        return (
            <Box>
                <Typography variant="subtitle1" className={classesoutercss.subInnerHead}>
                    Completed surveys
                </Typography>
                {(this.state.completedSurveyData === undefined || this.state.completedSurveyData?.length === 0) &&
                    <div style={{ background: 'white', padding: "20px", borderRadius: 12, textAlign: 'center' }}>
                        <Typography>No surveys available</Typography>
                    </div>
                }
                {this.state.completedSurveyData?.slice(0, this.state.itemToShow).map((item: any, index: number) => {
                    return (
                        <Box data-testId={`completed-${index}`} className={classesoutercss.surveyAccordion}>
                            <div>
                                <Box className={classesoutercss.completeSurveyBox}>
                                    <div className={classesoutercss.listBroadcasts}>
                                        <Typography className={classesoutercss.listDesc}><span>{index + 1}.</span> {item.title}</Typography>
                                        <Typography className={classesoutercss.listWrap}></Typography>
                                        <Button variant="contained" className={classesoutercss.viewresults} data-testId='viewresults' onClick={() => this.viewResultPage(item.id)}>View results</Button>
                                    </div>
                                </Box>
                            </div>
                        </Box>
                    )
                })}
                {this.renderShowMoreCompletedText(this.state.completedSurveyData)}
            </Box>
        )
    }



    renderDashboardCardsHeader = () => {
        return (
            <Grid>
                <Box className={classesoutercss.headerContainer}>
                    <Typography variant="subtitle1" className={classesoutercss.surveyText}>
                        Survey
                    </Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={6}>
                            <Paper className={classesoutercss.subContent}>
                                <Box className={classesoutercss.titleContainer}>
                                    <Typography>Live surveys</Typography>
                                    <Typography variant="h5">{this.state.liveSurveyData?.length ?? 0}</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <Paper className={classesoutercss.subContent}>
                                <Box className={classesoutercss.titleContainer}>
                                    <Typography>Completed surveys</Typography>
                                    <Typography variant="h5">{this.state.completedSurveyData?.length ?? 0}</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        )
    }



    renderLiveSurveysList = () => {
        return (
            <Box>
                <Typography variant="subtitle1" className={classesoutercss.subInnerHead}>
                    Live surveys
                </Typography>
                {(this.state.liveSurveyData?.length === 0 || this.state.liveSurveyData === undefined) &&
                    <div style={{ background: 'white', padding: "20px", borderRadius: 12, textAlign: 'center' }}>
                        <Typography>No surveys available</Typography>
                    </div>
                }
                <Box className={classesoutercss.surveyAccordion}>
                    {this.state?.liveSurveyData?.slice(0, this.state.itemsToShow)?.map((item: any, index: number) => {
                        return (
                            <Accordion expanded={true} data-testId={`live-${index}`} onClick={() => this.navigateToSurveyPage(item)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: '#009be2', rotate: '90deg' }} />}
                                    aria-controls="survey-content"
                                    id="survey-header"
                                >
                                    <div className={classesoutercss.listBroadcasts}>
                                        <Typography className={classesoutercss.listDesc}><span>{index + 1}.</span> {item.title}</Typography>
                                        <Typography className={classesoutercss.listWrap}>{item?.participants ? item?.participants : '14/30'}</Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Typography variant="subtitle2" style={{ margin: '2px 0' }}>{moment(item.start_date).format("DD/MM/YYYY")}</Typography>
                                            <Typography variant="subtitle2" className={classesoutercss.listTime}>to</Typography>
                                            <Typography variant="subtitle2" style={{ margin: '2px 0' }}>{moment(item.end_date).format("DD/MM/YYYY")}</Typography>
                                        </div>
                                    </div>
                                </AccordionSummary>
                            </Accordion>
                        )
                    })}
                    {this.renderShowMoreText(this.state.liveSurveyData)}
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <DashboardWeb
                    showBackButton={constants._icons.show}
                    title={constants.dashboard_screens.Surveys}
                    showBottomBar={constants._icons.show}
                    showDrawerHeader={false}
                    navigation={this.props.navigation}
                >
                    <div>
                        <Container maxWidth={'xl'} className='ContentManagementUIDiv'>
                            {this.renderDashboardCardsHeader()}
                            {this.renderLiveSurveysList()}
                            {this.renderCompletedSurveys()}
                        </Container>
                    </div>
                </DashboardWeb>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    descPagination: {
        "& .Mui-selected": {
            color: '#009BE2'
        }
    },
};

SurveysDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export { SurveysDashboard }
export default withRouter(withStyles(webStyle as any, { withTheme: true })(SurveysDashboard as any));
// Customizable Area End
// Customizable Area End
