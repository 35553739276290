import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import ActivityLogController, { Props } from "./ActivityLogController";
import { styled } from "@material-ui/styles";
import {
  // Customizable Area Start
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Grid,
  Typography,
  InputBase,
  Container,
  Paper,
  Divider,
  CircularProgress,
  Box,
  ButtonGroup,
  Button,
  TableContainer,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// @ts-ignore
import styles from "../../Gamification/src/Gamification.module.css";
import { constants, icons, FONTS } from "../../../components/src/constants";
import ContentManagement from "../../ContentManagement/src/ContentManagement.web";
import BusDashbaordWeb from "../../ServiceSpecificSettingsAdmin/src/BusDashboard.web";


const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);

const TableHead: any = withStyles({
  root: {
    borderBottom: "3px solid #cbcbcb",
  },
})(MuiTableHead);

// Customizable Area End

class ActivityLog extends ActivityLogController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   renderFilterModal = () => {
    return (
      <DialogWrapper
        className="dialogWrapper"
        data-testId="filterModal"
        >
        <Box>
          <Typography className="label">Time range</Typography>
          <div className={`${styles.flexCenter} activityCalendar`}>
              <DateRangePicker
                data-test-id="from-date"
                isOpen={this.state.isOpen}
                onCalendarClose={() => this.setState({ isOpen: false })}
                value={this.state.value}
                onChange={(e) => {
                  this.onDateChange(e);
                }}
              />
              <Box
                className="dateBox"
                data-test-id="cal1"
                onClick={() => this.setState({ isOpen: true })}>
                <Box className="formDate">
                  <Typography className="text" style={{ color: this.state.fromDate ? "rgb(0, 0, 0, 0.87)" : "rgb(100, 99, 99, 0.87)" }}>
                    {this.state.fromDate ? this.formatDate(this.state.fromDate) : "Select date"}
                  </Typography>
                </Box>
                <Button
                  disabled={false}
                  data-test-id="cal2"
                  onClick={() => this.setState({ isOpen: true })}
                >
                  <img src={icons.calendarblue} alt="calender" className="calendarStyle" />
                </Button>
              </Box>
              <Box className="dateBox">
                <Box className="formDate">
                  <Typography className="text" style={{ color: this.state.fromDate ? "rgb(0, 0, 0, 0.87)" : "rgb(100, 99, 99, 0.87)" }}>
                    {this.state.toDate ? this.formatDate(this.state.toDate) : "Select date"}
                  </Typography>
                </Box>
                <Button disabled={true}>
                  <img src={icons.calendarblue} alt="calender" className="calendarStyle" />
                </Button>
              </Box>
          </div>
          <div className="flexSpaceBetween">
            <Button
              className="clearBtn"
              data-test-id="clearBtn"
              onClick={() => this.handleClearClick()}>
              Clear all
            </Button>
            <Button
              className="applyBtn"
              data-test-id="applyBtn"
              onClick={() => this.handleApplyClick()}>
              Apply
            </Button>
          </div>
        </Box>
      </DialogWrapper>
    );
  };

  renderFilterOption = () => {
    return (
      <div>
        <Button
          data-testId="filterIcon"
          onClick={() => {
            this.setState({
              filterToggle: !this.state.filterToggle,
            });
          }}>
          <IconButton
            className="filterIcon"
            style={{
              border: `${this.state.filterToggle ? "1px solid #009be2" : "1px solid #DEE1EA"}`,
            }}>
            <img src={icons.busFilter} />
          </IconButton>
        </Button>
      </div>
    );
  };

  renderButtonGroup() {
    return (
      <ButtonGroup
        size="large"
        aria-label="large button group"
        fullWidth
        className="activityLogBtns">
        <Button
          data-test-id="eventTab"
          onClick={() => {
            this.setState({ tabActive: 1 });
            this.getEventActivityLog();
          }}
          className={`${this.state.tabActive === 1 ? "activeEventTabs" : "inactiveTab"} ${
            styles.activityLogBtn
          }`}>
          <Typography style={{ ...FONTS.semiBoldFont, fontSize: "20px" }}>Events</Typography>
        </Button>
        <Button
          data-test-id="donationTab"
          onClick={() => {
            this.setState({ tabActive: 2 });
            this.getDonationsActivityLog();
          }}
          className={`${this.state.tabActive === 2 ? "activeEventTabs" : "inactiveTab"} ${
            styles.activityLogBtn
          }`}>
          <Typography style={{ ...FONTS.semiBoldFont, fontSize: "20px"}}>Donations</Typography>
        </Button>
        <Button
          data-test-id="usersTab"
          onClick={() => {
            this.setState({ tabActive: 3 });
            this.getUsersActivityLog();
          }}
          className={`${this.state.tabActive === 3 ? "activeEventTabs" : "inactiveTab"} ${
            styles.activityLogBtn
          }`}>
          <Typography style={{ ...FONTS.semiBoldFont, fontSize: "20px" }}>Users</Typography>
        </Button>
      </ButtonGroup>
    );
  }

  getSearchBar(list: string, placeholder: string) {
    return (
      <div className={styles.search}>
        <div className={styles.searchIcon}>
          <img className={styles.searchIconImg} src={icons.searchSVG} />
        </div>
        <InputBase
          placeholder={placeholder}
          className={styles.searchboxStyle}
          data-test-id="searchFeild"
          onChange={(event) => this.handleSearchClick(event.target.value, list)}
          classes={{ input: styles.inputInput }}
        />
      </div>
    );
  }

  tableHeader() {
    let user = localStorage.getItem("user_type");

    return (
      <div className={styles.activityTable}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={3}>
            <Typography style={{ ...FONTS.semiBoldFont}} className={styles.rewardtitle}>{this.state.tabActive === 3 ? "Users" : "Activity Log"}</Typography>
          </Grid>
          <Grid item md={6} className={styles.flexCenter}>
            {this.getSearchBar("table", "Search...")}
            {user === "business_user" && this.renderFilterOption()}
          </Grid>
        </Grid>
      </div>
    );
  }

  getTextColor(status: string) {
    if (status === "Upcoming") {
      return "#84db67";
    } else if (status === "Cancelled") {
      return "#D93046";
    } else {
      return "#404040";
    }
  }

  renderTableHeader = () => {
    const { tabActive } = this.state;
    let user = localStorage.getItem("user_type");
    if (tabActive === 1 && user !== "business_user") {
      return (
        <TableRow>
          <TableCell align="left" className={`${styles.firstCol} tableHeader`}>
            TITLE
          </TableCell>
          <TableCell className="tableHeader" align="left">STATUS</TableCell>
          <TableCell className="tableHeader" align="left">TYPE</TableCell>
          <TableCell className="tableHeader" align="center">CATEGORY</TableCell>
          <TableCell className="tableHeader" align="center">PARTICIPANTS</TableCell>
          <TableCell className="tableHeader" align="center">PENDING APPROVAL</TableCell>
          <TableCell className="tableHeader" align="left">DATE/TIME</TableCell>
        </TableRow>
      );
    } else if (tabActive === 2 && user !== "business_user") {
      return (
        <TableRow>
          <TableCell align="left" className={`${styles.firstCol} tableHeader`}>
            TITLE
          </TableCell>
          <TableCell className="tableHeader" align="left">AMOUNT</TableCell>
          <TableCell className="tableHeader" align="left">DONORS</TableCell>
        </TableRow>
      );
    } else if (tabActive === 3 && user !== "business_user") {
      return (
        <TableRow>
          <TableCell align="left" className={`${styles.firstCol} tableHeader`}>
            USER NAME
          </TableCell>
          <TableCell className="tableHeader" align="left">USER ID</TableCell>
          <TableCell className="tableHeader" align="left">LOG IN DATE/TIME</TableCell>
          <TableCell className="tableHeader" align="left">LOG OFF DATE/TIME</TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell className={`${styles.firstCol} tableHeader`} align="left">TITLE</TableCell>
          <TableCell className="tableHeader" align="left">ORGANIZER</TableCell>
          <TableCell className="tableHeader" align="left">DATE/TIME</TableCell>
        </TableRow>
      );
    }
  };

  renderTableBody = (item: any) => {
    const { tabActive } = this.state;
    let user = localStorage.getItem("user_type");

    if (tabActive === 1 && user !== "business_user") {
      return (
        <TableRow key={item.id}>
          <TableCell align="left" className={styles.firstCol}>
            {item.title}
          </TableCell>
          <TableCell align="left">{item.status}</TableCell>
          <TableCell align="left">{item.type}</TableCell>
          <TableCell align="center">{item.category}</TableCell>
          <TableCell align="center">{item.participants}</TableCell>
          <TableCell align="center">{item.pending_approvals}</TableCell>
          <TableCell align="left">{item.date}</TableCell>
        </TableRow>
      );
    } else if (tabActive === 2 && user !== "business_user") {
      return (
        <TableRow key={item.id}>
          <TableCell align="left" className={styles.firstCol}>
            {item.title}
          </TableCell>
          <TableCell align="left">{item.amount}</TableCell>
          <TableCell align="left">{item.donors}</TableCell>
        </TableRow>
      );
    } else if (tabActive === 3 && user !== "business_user") {
      return (
        <TableRow key={item.id}>
          <TableCell align="left" className={styles.firstCol}>
            {item.user_name}
          </TableCell>
          <TableCell align="left">{item.user_id}</TableCell>
          <TableCell align="left">{item.login_date}</TableCell>
          <TableCell align="left">{item.log_off_date}</TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow key={item.id}>
          <TableCell align="left">{item.title}</TableCell>
          <TableCell align="left">{item.organizer}</TableCell>
          <TableCell align="left">{item.date}</TableCell>
        </TableRow>
      );
    }
  };

  tableBodyData() {
    return (
      <div className="activitylog-table">
        <TableContainer className={styles.tablecontainer}>
          <Table stickyHeader className="stickyHeaderTable">
            <TableHead>{this.renderTableHeader()}</TableHead>
            <TableBody>
              {this.state.tableData.length > 0 ? (
                <>{this.state.tableData.map((item: any) => this.renderTableBody(item))}</>
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center" className={styles.noRecord}>
                    No record found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  activityTable = (type: string) => {
    return (
      <Paper
        className={`${styles.paperRoot} ${type === "business" && styles.r3Table}`}
        elevation={0}>
        {type !== "business" && this.renderButtonGroup()}
        {this.tableHeader()}
        <Divider className={styles.dividerStyle} />
        <Grid container>
          <Grid item xs={12} className={styles.tableWrapper}>
            {this.tableBodyData()}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  getR2UI = () => {
    return (
      <>
        {/* @ts-ignore */}
        <ContentManagement
          showBackButton={constants._icons.hide}
          title="RegisteredVolunteers"
          showBottomBar={constants._icons.show}
          showDrawerHeader={true}
          titleColor={COLORS.black}
          open={false}
          mobileOpen={false}
          navigation={this.props.navigation}>
          <Container maxWidth={"xl"}>
            {this.state.loading ? (
              <div className={styles.flexCenter} style={{ height: "80%" }}>
                <CircularProgress className={styles.loaderColor} />
              </div>
            ) : (
              this.activityTable("np")
            )}
          </Container>
        </ContentManagement>
      </>
    );
  };

  getR3UI = () => {
    return (
      <>
        {/* @ts-ignore */}
        <BusDashbaordWeb navigation={this.props.navigation} id={""}>
          <div className="moduleWrapper">
            {this.state.loading ? (
              <div className={styles.flexCenter} style={{ height: "80%" }}>
                <CircularProgress className={styles.loaderColor} />
              </div>
            ) : (
              <>
              {this.activityTable("business")}
              {this.state.filterToggle && this.renderFilterModal()}
              </>
            )}
          </div>
        </BusDashbaordWeb>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    let user = localStorage.getItem("user_type");
    // Customizable Area End

    return (
      // Customizable Area Start
      <Wrapper>{user === "business_user" ? this.getR3UI() : this.getR2UI()}</Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  "& .moduleWrapper": {
    paddingLeft: "24px",
    paddingRight: "24px",
    position: "relative"
  },
  "& .filterIcon": {
    borderRadius: 12,
    background: "#FAFCFE",
  },
  "& .tableHeader" : {
    ...FONTS.mediumFont
  },
});

const DialogWrapper = styled(Box)({
  background: "rgb(254, 254, 254)",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 8px",
  borderRadius: "18px",
  border: "1px solid rgb(217, 246, 250)",
  width: "400px",
  height: "auto",
  padding: "20px",
  position: "absolute",
  top: "57px",
  right: "80px",
  zIndex: 999 as const,
  "& .calendarStyle": {
    height: 18,
    fill: "#000",
  },
  "& .dateBox": {
    border: "1px solid lightgrey",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 2,
    height: "50px",
    "&>button": {
      minWidth: "35px",
      width: "35px",
    },
  },
  "& .dateBox:last-child": {
    marginLeft: "15px",
  },
  "& .formDate": {
    width: "100%",
    fontSize: 16,

    "& p": {
      fontSize: 14,
      ...FONTS.regularFont,
      color: "rgb(100, 99, 99, 0.87)",
    },
  },
  "& .label": {
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "7px",
    ...FONTS.mediumFont,
  },
  "& div.MuiDialog-paperScrollPaper": {
    background: "#FEFEFE",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: 18,
    border: "1px solid #D9F6FA",
    width: 400,
    height: "450px",
    position: "absolute",
    padding: 20,
    top: "150px",
    right: "2.75%",
  },
  "& .clearBtn": {
    color: "#019be3",
    fontSize: "16px",
    ...FONTS.mediumFont,
    textTransform: "inherit",
  },
  "& .applyBtn": {
    background: "#019be3",
    color: "#fff",
    padding: "10px 30px",
    fontSize: "16px",
    ...FONTS.mediumFont,
    textTransform: "inherit",
    borderRadius: "10px",
  },
  "& .flexSpaceBetween": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "30px 0 0px",
  },
});

export default ActivityLog;
// Customizable Area End
