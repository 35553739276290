import React, { Component } from 'react';
import {
    Snackbar, SnackbarContent,
    Typography, Button, Input, Paper, InputAdornment,
    Grid, IconButton, Box,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface IPROPS {
    onClose: () => void;
    handleChangeAvatar: (event: any) => void;
    handleSubmitProfile: (event: any) => void;
    handleProfileFieldChange: (e: any) => void;
    pwError: any,
    draft: {
        first_name: string;
        email: string;
        current_password: string;
        change_password: string;
        confirm_password: string;
    };
    profileChangeErrors: {
        first_name: boolean;
        email: boolean;
        current_password: boolean;
        change_password: boolean;
        confirm_password: boolean;
    };
}

interface S {
    showPassword: boolean;
    showPassword1: boolean;
    showPassword2: boolean;
    passwordMatch: boolean;
}
class BusChangePW extends Component<IPROPS, S>{
    constructor(props: IPROPS) {
        super(props);
        this.state = {
            showPassword: false,
            showPassword1: false,
            showPassword2: false,
            passwordMatch: false,
        }
    }

    matchPWFn() {
        if (this.props.draft.current_password.length >= 1) {
            if (this.props.draft.current_password === this.props.draft.change_password)
                return true
            else
                return false
        } else
            return false
    }

    togglePasswordVisibility = (value: any) => {
        if (value === "change") {
            this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
        }
        if (value === "confirm") {
            this.setState((prevState) => ({ showPassword1: !prevState.showPassword1 }));
        }
        if (value === "current") {
            this.setState((prevState) => ({ showPassword2: !prevState.showPassword2 }));
        }
    };


    closeIcon() {
        return (
            <div style={{ position: 'absolute', top: 5, right: 90 }}>
                <IconButton onClick={this.props.onClose} id="close-icon" style={{ borderRadius: "50%", border: '2px solid', padding: "5px", fontWeight: 'bolder', color: "black" }}>
                    <CloseIcon style={{ cursor: 'pointer', fontSize: "22px" }} />
                </IconButton>
                <Typography variant="subtitle2" style={{ fontSize: "14px", textAlign: "center", marginTop: "2px" }}>
                    Close
                </Typography>
            </div>
        )
    }

    FullName() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Full Name</Typography>
                <br />
                <Input
                    id="first-name-input"
                    name="first_name"
                    style={styled.textInput}
                    disableUnderline
                    placeholder="Full Name"
                    value={this.props.draft.first_name}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                />
                {this.props.profileChangeErrors.first_name && <div style={{ color: 'red' }}>Please enter full name</div>}
            </div>
        )
    }


    Email() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Email address</Typography>
                <br />
                <Input
                    inputProps={{
                        style: {
                            cursor: "not-allowed"
                        },

                    }}
                    readOnly
                    id="email-input"
                    type='email'
                    required
                    name="email"
                    style={{ ...styled.textInput, ...styled.disabledSelect, }}
                    disableUnderline
                    placeholder="Email address"
                    value={this.props.draft.email}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                />
                {this.props.profileChangeErrors.email && <div style={{ color: 'red' }}>Please enter valid email address</div>}
            </div>
        )
    }






    CurrentPassword() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Current Password</Typography>
                <br />
                <Input
                    id="currentPassword-input"
                    name="current_password"
                    type={this.state.showPassword2 ? "text" : "password"}
                    style={styled.textInput}
                    disableUnderline
                    placeholder="Current Password"
                    value={this.props.draft.current_password}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.togglePasswordVisibility("current")}
                            // onMouseDown={(e) => e.preventDefault()}
                            >
                                {this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {this.props.profileChangeErrors.current_password ? <div style={{ color: 'red' }}>Please enter your correct current password</div> : <div style={{ marginLeft: "25px", marginTop: "5px", color: "grey", fontSize: "12px" }}>Leave it empty if no change required</div>}
            </div>
        )
    }

    ChangePassword() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Change Password</Typography>
                <br />
                <Input
                    id="changePassword-input"
                    name="change_password"
                    type={this.state.showPassword ? "text" : "password"}
                    style={styled.textInput}
                    disableUnderline
                    placeholder="Change Password"
                    value={this.props.draft.change_password}
                    onChange={(e) => {
                        this.props.handleProfileFieldChange(e);
                        if (this.props.draft?.current_password === this.props.draft?.change_password) this.setState({ passwordMatch: true })
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.togglePasswordVisibility("change")}
                            // onMouseDown={(e) => e.preventDefault()}
                            >
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {this.props?.draft?.change_password && this.props.draft?.current_password === this.props?.draft?.change_password && <div style={{ color: 'red' }}> New password cannot be the same as current password</div>}
                {this.props.profileChangeErrors.change_password ? <div style={{ color: 'red' }}>Password must be of 8 characters with a capital letter, a special character & a number</div> : <div style={{ marginLeft: "25px", marginTop: "5px", color: "grey", fontSize: "12px" }}>Leave it empty if no change required</div>}
            </div>
        )
    }

    ConfirmPassword() {
        return (
            <div style={styled.divWrapper}>
                <Typography variant="subtitle2" style={styled.label}>Confirm Password</Typography>
                <br />
                <Input
                    id="confirmPassword-input"
                    name="confirm_password"
                    type={this.state.showPassword1 ? "text" : "password"}
                    style={styled.textInput}
                    disableUnderline
                    placeholder="Confirm Password"
                    value={this.props.draft.confirm_password}
                    onChange={(e) => this.props.handleProfileFieldChange(e)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.togglePasswordVisibility("confirm")}
                            // onMouseDown={(e) => e.preventDefault()}
                            >
                                {this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {this.props.profileChangeErrors.confirm_password && <div style={{ color: 'red' }}>Password do not match!</div>}
            </div>
        )
    }

    SaveBtn(matchPwValue: any) {
        return (
            <div style={styled.divWrapper}>
                <Button
                    style={{
                        width: "10%",
                        boxShadow: "none",
                        textTransform: "capitalize",
                        height: 45,
                        backgroundColor: `${this.props.profileChangeErrors.first_name || this.props.profileChangeErrors.email || this.props.profileChangeErrors.change_password || this.props.profileChangeErrors.confirm_password || matchPwValue ? "rgba(0, 155, 226, 0.2)" : "#009be2"}`,
                        color: "white ",
                        fontFamily: "Rene_Bieder_Milliard_SemiBold",
                        borderRadius: 10,
                        marginBottom: "20px"
                    }}
                    color="primary"
                    variant="contained"
                    disabled={this.props.profileChangeErrors.first_name || this.props.profileChangeErrors.email || this.props.profileChangeErrors.change_password || this.props.profileChangeErrors.confirm_password || matchPwValue ? true : false}
                    onClick={this.props.handleSubmitProfile}
                >
                    Save
                </Button>
            </div>
        )
    }

    render() {
        const matchPwValue = this.matchPWFn()


        return (
            <div style={{ marginLeft: 280 }}>
                <Grid style={{ paddingLeft: "28px", paddingRight: "28px" }}>
                    <Paper style={{ backgroundColor: "#fff", marginTop: "60px", width: "100%", borderRadius: "17px" }}>
                        <Box style={{ marginTop: 10 }} />
                        <Typography
                            variant="subtitle1"
                            style={heading}
                        >
                            Profile details
                        </Typography>
                        <Box
                            style={{
                                borderTop: "2px solid #EAEAEA",
                            }}
                        />
                        <div style={{ position: 'relative' }}>
                            {this.FullName()}
                            {this.closeIcon()}
                            {this.Email()}
                            {this.CurrentPassword()}
                            {this.ChangePassword()}
                            {this.ConfirmPassword()}
                            {this.SaveBtn(matchPwValue)}
                        </div>
                    </Paper>
                </Grid>
            </div>
        );
    }
}

const heading = {
    display: "inline-block",
    fontSize: "22px",
    marginLeft: "10px",
    padding: "10px",
    margingLeft: "12px"
};

const styled = {
    divWrapper: {
        marginBottom: "20px",
        paddingLeft: "10px",
        marginLeft: "10px",
        marginTop: "20px",
    },

    label: {
        display: "inline-block",
        fontSize: "18px ",
        marginBottom: "5px ",
        paddingLeft: "5px",
    },

    textInput: {
        width: "50%",
        borderRadius: "10px",
        padding: "10px 10px 10px 20px",
        background: "#ffffff",
        border: "1px solid #d9d4d4",
        fontSize: "16px",
    },


    disabledSelect: {
        color: 'currentColor',
    },

    iconWrapper: {
        display: "flex",
        alignItems: "flex-start",
        marginRight: "80px",
        justifyContent: "space-between"
    },

    avatar: {
        marginBottom: "10px",
        width: "80px",
        height: "80px"
    },
};


export default BusChangePW;
