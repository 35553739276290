// Customizable Area Start
// @ts-nocheck 
import React, { useEffect, useState } from 'react';
import { icons } from "../../../components/src/constants";
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Typography, Divider, Button } from "@material-ui/core";
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { InitiateButton } from '../../../components/src/design-system/button';
import { useAvailableTasksPreferences, useMyTaskPreferences, useSaveTasksPreferences } from '../../../components/src/api/hooks/user.hooks';
import { InitiateTextField } from '../../../components/src/design-system/text-field';
import { useSelector } from 'react-redux';
import { RootState } from '../../../framework/src/redux/stores';
import { useNavigate } from '../../../components/src/utils/router-context';
import { InitiateLabel } from '../../../components/src/design-system/label';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { RegisterationMobileHeader } from './RegisterationMobileHeader';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../../../components/src/api/keys/user.keys';
import { InitiateCheckbox } from '../../../components/src/design-system/checkbox';
import { InitiateSpinner } from '../../../components/src/design-system/spinner';
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import CustomSuccessModel from '../../../components/src/custom/CustomSuccessModel';

const useStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    paddingLeft:'7%',
    // height:'93% !important',
    flexDirection: 'column',
    // height: 'min(710px, 80vh)',
    padding: '5%',
    [theme.breakpoints.down('sm')]: {
      height: '80% !important',
      padding: '5px 0',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  titleText: {
    fontSize: '1.8rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
    textAlign: 'center',
  },
  submitButton: {
    fontSize: '1.3rem',
  },
  skipButton: {
    fontSize: '1.3rem',
    textTransform: 'capitalize',
  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchIcon: {
    maxWidth: '20px',
  },
  tasksContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    fontWeight: 400,
    width: '100%',
    justifyContent: 'space-between',
    margin: '0',
    '& *': {
      fontFamily: 'Rene_Bieder_Milliard_Book',
    },
  },
  selectAllCheckbox: {
    '& *': {
      fontWeight: 600,
    },
  },
  disabilityAssistance: {
    '& textarea': {
      fontSize: '0.9rem',
    },
  },
  lineDivider: {
    margin: '0 -50px',
  },
  noAvailableDateText: {
    textAlign: 'center',
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#a4a3a4',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#019be1'
    }
  }
});

export function MyTaskPreferencesForm({
  hideTitle,
  hideSkipButton,
  submitButtonTitle,
  preventNavigationOnSuccess,
  popup,
  close,
}: {
  hideTitle?: boolean,
  hideSkipButton?: boolean,
  submitButtonTitle?: string,
  preventNavigationOnSuccess?: boolean,
  popup?: boolean,
  close?: () => void
}) {

  const classes = useStyles();

  const accountId = localStorage.getItem("acc_id") ?? 0;
  const myTasksQuery = useMyTaskPreferences({ accountId: parseInt(accountId),token: localStorage.getItem('token') || '' });

  const tasksQuery = useAvailableTasksPreferences();

  const { mutate: saveTaskPreferences, isLoading } = useSaveTasksPreferences();

  const isMobile = useIsMobileScreen();

  const [search, setSearch] = useState('');
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [hasSelectedAll, setHasSelectedAll] = useState<boolean>(false);
  const [disabilityAssistance, setDisabilityAssistance] = useState('');
  const [openSuccessModal, setSuccessModel] = useState(false)
  const currentUrl = window.location.href.split('/');
  const key = currentUrl[currentUrl.length - 1];
  const isEdit = key === "edit";
  const disable = key === "SettingsTask";
  const tasks = tasksQuery.data?.data ?? [];
  const tasksLoading = tasksQuery.isLoading;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(function updateTheFormWithMyPreviousData() {
    const myTasks = myTasksQuery.data?.data ?? [];
    setSelectedTasks(myTasks.map(task => task.id));
    const user_disability_assistance=myTasksQuery.data?.data[0]?.attributes?.user_disability_assistance??''
    setDisabilityAssistance(user_disability_assistance)
  }, [myTasksQuery.data]);

  function submitHandler() {

    saveTaskPreferences({
      accountId: parseInt(accountId),
      disabilityAssistance,
      tasksIds: hasSelectedAll ?
        tasks.map(task => parseInt(task.id))
        :
        selectedTasks.map(taskId => parseInt(taskId)),
    }, {
      onSuccess() {
        queryClient.invalidateQueries(userKeys.fetchMyTaskPreferences({ accountId: parseInt(accountId) }));
        if (!preventNavigationOnSuccess && !isEdit && !disable){
          popup?close(): navigate('/Notificationsweb');
        } else {
          setSuccessModel(true)
        }
      },
      
    });
  }

  function skipHandler() {
  
      navigate('/Notificationsweb');

  }

  function handleSuccessModalClose() {
    setSuccessModel(false)
  }

  useEffect(function activateSelectAll() {
    if (selectedTasks.length == tasks.length && selectedTasks.length)
      setHasSelectedAll(true);
  }, [selectedTasks.length, tasks.length]);

  return (
    <>
      <div className={classes.formContentRoot} style={{height: !preventNavigationOnSuccess ? 'min(710px, 80vh)' : 'auto'}}>

        <HideContainer hideWhen={hideTitle}>
          <Typography
            variant="h1"
            color="primary"
            className={classes.titleText}
          >
            {'My Task Preferences'}
          </Typography>
          <VerticalSpacer value={40} />
        </HideContainer>


        <InitiateTextField
          value={search}
          onChange={event => setSearch(event.target.value)}
          placeholder="Search for tasks"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img className={classes.searchIcon} src={icons.search} />
              </InputAdornment>
            ),
          }}
        />

        <VerticalSpacer />

        <div className={classes.formContent}>


          <div className={classes.tasksContainer}>

            <HideContainer hideWhen={tasksLoading || tasks.length}>
              <Typography className={classes.noAvailableDateText}>
                {'There is no tasks yet'}
              </Typography>
            </HideContainer>

            <HideContainer hideWhen={!tasksLoading}>
              <InitiateSpinner />
            </HideContainer>

            <HideContainer hideWhen={!tasks.length}>
              <InitiateCheckbox
                formControlLabelProps={{
                  className: clsx(classes.checkbox, classes.selectAllCheckbox),
                  labelPlacement: 'start',
                }}
                data-test-id="checkbox"
                checked={hasSelectedAll}
                onChange={(event) => {
                  const checked = event.target.checked;
                  setHasSelectedAll(checked);
                  if (checked)
                    setSelectedTasks(tasks.map(task => task.id));
                  else
                    setSelectedTasks([]);
                }}
                name="select_all"
                label={"Select All"}
              />
            </HideContainer>
            {
              tasks
                .filter(task => !search ? true :
                  task.attributes.task.toLowerCase().includes(search.toLowerCase())
                )
                .map(task => (
                  <div key={task.id}>
                    <InitiateCheckbox
                      formControlLabelProps={{
                        className: classes.checkbox,
                        labelPlacement: "start",
                      }}
                      checked={selectedTasks.includes(task.id)}
                      onChange={(event) => {
                        const { checked } = event.target;
                        if (checked)
                          setSelectedTasks(state => state.concat(task.id));
                        else {
                          setSelectedTasks(state => state.filter(taskId => taskId != task.id));
                          setHasSelectedAll(false);
                        }
                      }}
                      name={task.attributes?.task}
                      label={task.attributes?.task}
                    />
                  </div>
                ))
            }
          </div>

          <VerticalSpacer />

          <Divider className={classes.lineDivider} />

          <VerticalSpacer />

          <InitiateLabel>
            {'Disability Assistance'}
          </InitiateLabel>

          <VerticalSpacer />

          <InitiateTextField
            className={classes.disabilityAssistance}
            value={disabilityAssistance}
            onChange={(event) => setDisabilityAssistance(event.target.value)}
            fullWidth
            multiline
            variant="outlined"
            rows={!preventNavigationOnSuccess ? 5 : 9}
            placeholder="Please enter any special assistance you may require to perform tasks you volunteer for."
          />

          <VerticalSpacer />

        </div>

        <div className={classes.submitButtonsContainer}>
          <InitiateButton
            data-testId="submit-btn"
            loading={isLoading}
            className={classes.submitButton}
            disabled={isLoading || !selectedTasks.length}
            onClick={submitHandler}
            fullWidth
            color="primary"
            variant="contained"
            size="large">
            {submitButtonTitle || 'Save'}
          </InitiateButton>

          <HideContainer hideWhen={isMobile || hideSkipButton}>
          <Button
              className={classes.skipButton}
              fullWidth
              color="primary"
              variant="text"
              onClick={skipHandler}
              size="large">
              {'Skip'}
            </Button>
            </HideContainer>
        </div>

      </div>
      <CustomSuccessModel openSuccessModal={openSuccessModal} handleSuccessModalClose={handleSuccessModalClose} msgTitle='Thank you' msg="Changes has been updated" />

      <RegisterationMobileHeader
        title="My Task Preferences"
        hideSkipButton={isEdit || disable}
        onSkip={skipHandler}
      />
    </>
  );
}


// Customizable Area End
