import React, { CSSProperties } from "react";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';


const useHorizontalSpacerStyles = makeStyles({
  root: {
    background: 'transparent',
    width: '15px',
  },
});

export function HorizontalSpacer({ value }: { value?: number }) {

  const classes = useHorizontalSpacerStyles();
  let style: CSSProperties = {}

  if (value)
    style['width'] = `${value}px`;

  return (<Divider className={classes.root} style={style} />);
}

