// Customizable Area Start
import React from "react";

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { SuccessfulRegisteration } from "./AccountVerifiedSuccessContent";
// Customizable Area End

class OtpVerifiedSuccess extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  render() {
    return (
      // Customizable Area Start
        <>
          <SuccessfulRegisteration type="forgotpswrd"/>
        </>
        // Customizable Area End
    );
  }
}

 // Customizable Area Start
export {OtpVerifiedSuccess}
export default  OtpVerifiedSuccess
// Customizable Area End