import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
  type?: string;
  eventId?: string | number;
  onClose: () => void;
}

export interface S {
  // Customizable Area Start
  id: string;
  loading: boolean;
  isFavorite: boolean;
  favoriteId: number | null;
  eventImage: string;
  title: string;
  organizationId: number | string;
  organizationName: string;
  organizationLogo: string;
  description: string;
  isEventPast: boolean;
  amountSliderValue: number;
  project_journey: boolean;
  msgModal: any;
  activeTab: number;
  latestFundUpdates: any;
  currentType: string;
  currentId: number;
  currentComment: string;
  currentCommentId: string;
  projectData: any;
  donateValue: any;
  amountValue: any;
  donatedPoints: any;
  donatedAmount: any;
  currentPoints: number;
  pointsRange: number[];
  openDonateDialog: boolean;
  openPaymentDialog: boolean;
  userEntered: number;
  dynamicText: string;
  location: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EventInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  removeFavoriteFundApiCallId: string = "";
  addFavoriteFundApiCallId: string = "";
  getFundDetailApiCallId: string = "";
  getFundUpdateApiCallId: string = "";
  addCommentApiCallId: string = "";
  getCommentApiCallId: string = "";
  updateCommentApiCallId: string = "";
  getAmountOptionApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: "",
      loading: false,
      projectData: null,
      isFavorite: false,
      favoriteId: null,
      eventImage: "",
      title: "",
      organizationId: "",
      organizationName: "",
      organizationLogo: "",
      description: "",
      isEventPast: false,
      amountSliderValue: 0,
      project_journey: false,
      msgModal: false,
      activeTab: 0,
      latestFundUpdates: [],
      currentType: "",
      currentId: 0,
      currentComment: "",
      currentCommentId: '',
      donateValue: null,
      amountValue: null,
      donatedPoints: null,
      donatedAmount: null,
      currentPoints: 0,
      pointsRange:  [
        100,
        15000,
        200
      ],
      openDonateDialog: false,
      userEntered: 0,
      openPaymentDialog: false,
      dynamicText: "",
      location: ""
      // Customizable Area End
    };
  }

  async receive(from: string, messages: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === messages.id) {
      const apiRequestCallIds = messages.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = messages.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallIds && responseJson) {
        if (responseJson.error) {
          toast.error(`${responseJson.error}`);
        } else if (responseJson.errors?.length > 0) {
          toast.error(`${responseJson.errors[0].donate_point[0]}`);
        }  else {
          this.validateAPIResponses(apiRequestCallIds, responseJson)
        }
      }
    }
  }

  validateAPIResponses = (apiRequestCallIds: any, responseJson: any) => {
    switch (apiRequestCallIds) {
      case this.getFundDetailApiCallId:
        this.setState({ projectData: responseJson.data.attributes, amountSliderValue: responseJson.data.attributes.raised_amount})
        this.handleDetailApiResponse(responseJson);
        break;

      case this.getFundUpdateApiCallId:
        this.setState({
          latestFundUpdates: responseJson.data.attributes.media,
          activeTab: this.state.currentId !== 0 ? this.state.currentId : responseJson.data.attributes.media[0].id,
        })
        break;

      case this.addCommentApiCallId:
        this.setState({ msgModal: false })
        this.getFundUpdates()
        toast.success(`${responseJson.meta.message}`)
        break;

      case this.getCommentApiCallId:
        this.setState({ msgModal: false })
        break;

      case this.updateCommentApiCallId:
        this.getFundUpdates()
        toast.success("Comment updated!")
        break;

      case this.removeFavoriteFundApiCallId:
        toast.success("Removed from favourites!");
        this.setState({ isFavorite: false });
        break;

      case this.addFavoriteFundApiCallId:
        toast.success("Added to favourites!");
        this.setState({ isFavorite: true, favoriteId: responseJson.data.id });
        break;

      case this.getAmountOptionApiCallId:
        this.setState({ pointsRange: responseJson.data.map((amount: string) => parseFloat(amount)) });
        break;
    }
  }

  onChangeState = () => {
    this.setState({
      dynamicText: "amount" ,
      openPaymentDialog: false,
      openDonateDialog: true,
      userEntered: 0,
      amountValue: null
    },() => {
      this.getNeedInfo(this.state.id);
    });
  };

  async componentDidMount() {
    let id = this.props.navigation.getParam("id");
    if(this.props.type === "r3"){
      id = this.props.eventId;
    }
    if (id) {
      this.setState({ id, loading: true }, () => {
        this.getNeedInfo(id);
        this.pointsRange();
        this.getFundUpdates();
      });
    }
  }

  donatevaluetext(donatevalue: number) {
    return `£ ${donatevalue}`;
  }

  handleDonate = async () => {
    const { pointsRange, amountValue, userEntered, projectData } = this.state;
    if (projectData.status !== "Enabled") {
      toast.warn("Currently not accepting donations!");
    } else if ((pointsRange[amountValue] > 0 || userEntered)) {
      this.setState({ openPaymentDialog: true, donatedAmount: userEntered || pointsRange[amountValue] })
    } else {
      toast.error("Please select donation amount");
    }
  }

  pointsRange = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getAmountOptionApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_stripe_integration/donate_amounts`,
      header: header
    });
  }

  handleDetailApiResponse = (responseJson: any) => {
    const toTime = responseJson.data.attributes.end_time;
    const toDate = responseJson.data.attributes.to_date;
    const endDateTime = this.getDateAndTime(toDate, toTime);
    const isEventPast = moment(endDateTime).isBefore(moment());

    
    this.setState({
      organizationLogo: responseJson.data.attributes.organisation_image_url?.url,
      organizationId: responseJson.data.attributes.organisation_id,
      organizationName: responseJson.data.attributes.organisation_name,
      title: responseJson.data.attributes.event_name,
      eventImage: responseJson.data.attributes.event_image?.url,
      isFavorite: responseJson.data.attributes.is_favourite,
      favoriteId: responseJson.data.attributes.favourite_id,
      location: responseJson.data.attributes.location,
      isEventPast,
      description: responseJson.data.attributes.description,
      loading: false
    });
  };

  getDateAndTime = (dates: string, time: string) => {
    if (dates && time) {
      const date = new Date(dates);
      const day = date.getUTCDate();
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1;

      const utcDate = new Date(time);
      const hour = utcDate.getUTCHours();
      const second = utcDate.getUTCSeconds();
      const minute = utcDate.getUTCMinutes();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}.000Z`;
    } else {
      return "";
    }
  }

  getNeedInfo = async (id: string) => {
    const header = {
      "type": this.props.type === "r3"? "business_entity": "user_entity",
      "token": localStorage.getItem("token")
    }
    this.getFundDetailApiCallId = await apiCall({
      method: "GET",
      endPoint: `non_profit_events/${id}`,
      header: header
    });
  }

  getFundUpdates = async () => {
    const header = {
      "token": localStorage.getItem("token")
    }
    this.getFundUpdateApiCallId = await apiCall({
      method: "GET",
      endPoint: `/non_profit_events/${this.state.id}/latest_update`,
      header: header
    });
  }

  postMediaComment = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": this.props.type === "r3" ? "business_entity" : "user_entity",
      "Content-Type": "application/json"
    }
    const body = {
      "comment":{
        "commentable_type": this.state.currentType,
        "content": this.state.currentComment,
        "commentable_id": this.state.currentId,
      }
    }
    this.addCommentApiCallId = await apiCall({
      method: "POST",
      endPoint: `/bx_block_upload_media/comments`,
      header: header,
      body: JSON.stringify(body),
    });
  }

  updateMediaComment = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": this.props.type === "r3" ? "business_entity" : "user_entity",
      "Content-Type": "application/json"
    }
    const body = {
      "comment":{
          "content": this.state.currentComment,
          "commentable_id": this.state.currentId,
      }
    }
    this.updateCommentApiCallId = await apiCall({
      method: "PATCH",
      endPoint: `/bx_block_upload_media/comments/${this.state.currentCommentId}`,
      header: header,
      body: JSON.stringify(body),
    });
  }

  getMediaComments = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity",
      "Content-Type": "application/json"
    }
    
    this.getCommentApiCallId = await apiCall({
      method: "POST",
      endPoint: `/bx_block_upload_media/comments`,
      header: header,
    });
  }

  addToFavoriteNeed = async () => {
    const body = {
      "data": {
        "favouriteable_type": "BxBlockEvents::NonProfitEvent",
        "favouriteable_id": this.state.id,
      },
      "token": localStorage.getItem("token"),
    }
    this.addFavoriteFundApiCallId = await apiCall({
      endPoint: `/bx_block_favourites/favourites`,
      body: JSON.stringify(body),
      contentType: "application/json",
      method: "POST"
    });
  }

  removeFromFavoriteNeed = async () => {
    this.removeFavoriteFundApiCallId = await apiCall({
      method: "DELETE",
      endPoint: `bx_block_favourites/favourites/${this.state.favoriteId}`
    });
  }


  handleTabChange = (event: any, newValue: any) => {
    this.setState({ donateValue: newValue, donatedPoints: event.target.innerText.split(" ", 1)[0] })
  }

  handleAmountTabChange = (event: any, newValue: any) => {
    this.setState({ amountValue: newValue, userEntered: 0})
  }

  handleDonationAmount = (event:any) => {
    this.setState({ userEntered: parseFloat(event.target.value), amountValue: null}) 
  }
  }
// Customizable Area End
