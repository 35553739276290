// Customizable Area Start
import React from 'react';
import {
    icons,
    FAMILYFONTS
} from "../../../components/src/constants";
// @ts-ignore\
import classOuter from "./MyNeed.module.css";
import { Typography, Box, Avatar, InputBase, withStyles, Button } from "@material-ui/core";
import { createStyles, Theme } from '@material-ui/core/styles';
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { InitiateButton } from '../../../components/src/design-system/button';
import Modal from '@material-ui/core/Modal';
import { COLORS } from '../../../framework/src/Globals';
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import PropTypes from "prop-types";
import { HideContainer } from '../../../components/src/shared/hide-container';
import Check from '@material-ui/icons/Check';

export const styles = (theme: Theme) =>
    createStyles({
        submitButton: {
            fontSize: '1.1rem',
            background:"#019BE1"
        },
        publishBtn: {
            fontSize: '14px',
            padding: "2px 15px",
            background:"#019BE1"
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: COLORS.skyBlue,
        },
        inputRoot: {
            color: COLORS.black10,
        },
        inputInput: {
            padding: theme.spacing(2, 2, 2, 0),
            paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
            transition: theme.transitions.create("width"),
            color: COLORS.black10, ...FAMILYFONTS.family3, fontSize: 16, fontWeight: 400
        },
        searchContainer: {
            position: "relative",
            borderRadius: "12px",
            backgroundColor: COLORS.white,
            marginLeft: 0,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            height: 54,
            display: "flex",
            border: "1px solid #d5d5d5",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(0),
            },
        },
        checkBtn: {
            padding: "2px 15px",
            border: "1px solid #019BE1",
            borderRadius: "5px",

        },
        checkIcon: {
            height: "20px",
            width: "45px",
            color: "#019BE1"
        },
        usersContainer: {
            height: "200px",
            overflow: "auto"
        },
        publishContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        },
        connectionContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 10
        },
        connectionItem: {
            display: "flex",
            alignItems: "center"
        },
        closeBtnContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        }
    });

class PublishNeedModal extends UserProfileBasicController {
     static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    };
    constructor(props: Props) {
        super(props);

    }


    render() {
        const { classes, openModal, onCloseModal }: any = this.props;
        const { search, connections, selectedUser, publishAll } = this.state;
        const filteredUsers = connections
            ?.filter((user: any) => !search ?
                true
                :
                user?.attributes?.name.toLowerCase().includes(search.toLowerCase())
            );
        return (

            <div>
                <Modal
                    open={openModal}
                    onClose={() => onCloseModal()}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classOuter.modalWrapper}
                >
                    <Box>
                        <Box className={classOuter.modalBoxPublish}
                        >
                            <Box>
                                <div className={classes.closeBtnContainer}>
                                    <div><Typography style={{ ...FAMILYFONTS.family2, fontSize: 18 }}>Publish To</Typography></div>
                                    <div>
                                        <img data-test-id="closeModal" src={icons.cancelIcon} style={{ height: 28, width: 28, cursor: "pointer" }} onClick={()=>onCloseModal()} />
                                    </div>
                                </div>
                                <VerticalSpacer value={40} />
                                <div className={classes.searchContainer}>
                                    <div style={{ display: "flex", alignItems: "center" }} >
                                        <div className={classes.searchIcon}>
                                            <img
                                                style={{
                                                    width: 25,
                                                    height: 26,
                                                    left: "33px",
                                                    top: "788px",
                                                    borderRadius: "0px",
                                                }}
                                                src={icons.search}
                                                data-test-id="closeBtn"
                                           />
                                        </div>

                                        <InputBase
                                        data-test-id="search"
                                            placeholder="Search connection"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                            value={search}
                                            onChange={(e) => this.setState({ search: e.target.value })}
                                        />

                                    </div>
                                </div>
                                <VerticalSpacer value={40} />
                                <HideContainer hideWhen={!filteredUsers.length}>
                                <div className={classes.publishContainer}>
                                    <div><Typography style={{ ...FAMILYFONTS.family2, fontSize: 16 }}>Publish to all connections</Typography></div>
                                    <div>
                                        {!publishAll ? <InitiateButton
                                            className={classes.publishBtn}
                                            onClick={this.togglePublishAllBtn}
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            disabled={selectedUser.length > 0}
                                            data-test-id="publishBtn"
                                            size="small">
                                                
                                            {'Publish'}
                                        </InitiateButton> : <Button className={classes.checkBtn} onClick={() => this.togglePublishAllBtn()} ><Check className={classes.checkIcon} /></Button>}

                                    </div>
                                </div>
                                </HideContainer>
                                <VerticalSpacer value={40} />
                                <div className={classes.usersContainer}>

                                    {
                                        filteredUsers?.map((user: any, index: any) => (
                                            <div className={classes.connectionContainer} key={index}>
                                                <div className={classes.connectionItem}>
                                                    <Avatar alt={user?.attributes?.name} src={user?.attributes?.profile_picture?.url} variant="circular" />
                                                    <Typography
                                                        style={{
                                                            fontSize: 14,
                                                            fontFamily: "Rene_Bieder_Milliard_Medium",
                                                            letterSpacing: "0.25px",
                                                            marginLeft: 10
                                                        }}
                                                    >{user?.attributes?.name}

                                                    </Typography>
                                                </div>
                                                <div>
                                                    {selectedUser.includes(user?.id) ?
                                                        <Button className={classes.checkBtn} onClick={() => this.togglePublishUser(user?.id)} ><Check className={classes.checkIcon} /></Button> : <InitiateButton
                                                            className={classes.publishBtn}
                                                            fullWidth
                                                            disabled={publishAll}
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => this.togglePublishUser(user.id)}
                                                            size="small">
                                                            {'Publish'}

                                                        </InitiateButton>}

                                                </div>
                                            </div>
                                        ))
                                    }
                                    <HideContainer hideWhen={filteredUsers.length || !connections.length}>
                                        <Typography style={{ ...FAMILYFONTS.family2, fontSize: 16 }}>
                                            {'No connection found!'}
                                        </Typography>
                                    </HideContainer>
                                    <HideContainer hideWhen={connections.length}>
                                        <Typography style={{ ...FAMILYFONTS.family2, fontSize: 16 }}>
                                            {'You need to add connections within your postcode to publish an event!'}
                                        </Typography>
                                    </HideContainer>
                                </div>


                                <VerticalSpacer value={40} />
                                <div className={""}>
                                    <InitiateButton
                                        onClick={() => this.handleDoneBtn()}
                                        className={classes.submitButton}
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        data-test-id="doneBtn"
                                        disabled={this.state.disabled || (!this.state.publishAll && this.state.selectedUser.length === 0)}
                                        size="large">
                                        {'Done'}
                                    </InitiateButton>

                                </div>
                            </Box>

                        </Box>
                    </Box>
                </Modal>
            </div>
        );
    }
}
PublishNeedModal.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles as any)(PublishNeedModal as any);
export { PublishNeedModal }

// Customizable Area End
