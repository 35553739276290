// Customizable Area Start
import React from "react";
import { useIsMobileScreen } from "../../../components/src/utils/is-mobile";
import { NotificationsSettingsForm } from "./EmailNotificationForm";

export function NotificationsContent() {

  const isMobile = useIsMobileScreen();

  return (
    <NotificationsSettingsForm
      title="Notifications"
      type="app"
      onSuccessNavigateTo="/Availability"
      hideTitle={isMobile}
    />
  );
}

// Customizable Area End
