// Customizable Area Start
import React from "react";


import PersonalInformationController, {
  Props,
} from "./PersonalInformationController";



import { PersonalInformationForm } from "./PersonalInformationForm";

// Customizable Area End

class PersonalInformation extends PersonalInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End      

    return (
      // Customizable Area Start
      <PersonalInformationForm/>
      // Customizable Area End      
    );
  }
}

// Customizable Area Start

export default PersonalInformation
export { PersonalInformation }
// Customizable Area End
