// Customizable Area Start
// @ts-nocheck
import React from "react";
import {
    Container,
    Box,
    Typography,
    IconButton,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { icons } from "../../../components/src/constants";
import PaymentPlanController, {
    Props,
} from "./BusPaymentPageController.web";
import BusDashbaordWeb from "./BusDashboard.web";
import CheckoutForm from "../../../components/src/custom/CheckOutForm.web";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { withRouter } from "react-router-dom";
const configJSON = require("./config");;
const baseURL = require("../../../framework/src/config.js").baseURL;
let stripePubKey = configJSON.STRIPE_KEY_LIVE;
if (!baseURL.includes(".prod")) {
  stripePubKey = configJSON.STRIPE_KEY_TEST;
}
const stripePromise = loadStripe(stripePubKey);

class BusSubscriptionPayment extends PaymentPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const appearance = {
            theme: 'stripe',
        };
        return (
            // @ts-ignore
            <BusDashbaordWeb children={this.props.children} classes={this.props.classes} navigation={this.props.navigation} id={""} adminSelectedTab={undefined} theme={undefined}
            >
                <Container maxWidth='xl' style={{ marginLeft: 280, padding: 50, width: 'auto' }}>
                    {/* Checkout Form */}
                    <Wrapper style={{ padding: 25, background: 'white', borderRadius: '12px' }}>
                        <Box>
                            <Box display="flex" alignItems="center">
                                <IconButton
                                    data-testId='backBtn'
                                    onClick={() => this.props.navigation.goBack()}
                                >
                                    <img src={icons.back} />
                                </IconButton>
                                <Typography className="page-header">Subscription payments</Typography>
                            </Box>
                            <Box className="checkout-form">
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm 
                                        planId={this.state.planId}
                                        title={this.state.planTitle}
                                        amount={this.state.planAmount}
                                        point={this.state.discountPoint}
                                        planType={this.state.planType}
                                        days={this.state.days}
                                        type='r3'
                                        isAddOnPlan={this.state.type === "addOn"}
                                        currentPlanId={this.state.currentPlanId}
                                        redirect={() => this.props.navigation.navigate("BusHomeDashboard")} />
                                </Elements>
                            </Box>
                        </Box>
                    </Wrapper>


                </Container>
            </BusDashbaordWeb>
        );
        // Customizable Area End
    }
}
export { BusSubscriptionPayment }
export default withRouter(BusSubscriptionPayment);

const Wrapper = styled(Box)({
    "& .MuiTypography-root": {
        fontFamily: "Rene_Bieder_Milliard_SemiBold"
    },
    "& .page-header": {
        fontSize: '22px',
    },
    "& .checkout-form": {
        display: "flex",
        justifyContent: "center",
        padding: "35px 75px",
        borderRadius: "12px",
        "@media(max-width:1092px)": {
            padding: "20px 35px"
        }
    },
});

// Customizable Area End