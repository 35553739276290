import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// @ts-ignore
import { FAMILYFONTS, images } from "../../../components/src/constants";
// @ts-ignore
import classOuter from "./EmailOtpVerificationRegistration.module.css";
// Customizable Area End

import FormRegitrationController, {
    Props
  } from "./RegistrationFormController";

class EmailOtpVerificationRegistration extends FormRegitrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // const disabled = (otpLength === 6 && !this.state.otpError) ? false : true;
    // const temp_otp = JSON.parse((sessionStorage.getItem("np-otp-meta") as string))?.otp || null;
    const Email =  localStorage.getItem("EmailId")
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        {/* OTP logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.otpLogoMainContainer}>
            <div className={classOuter.otpLogoContainer}>
              <img src={images.emailIcon} className={classOuter.otpLogo} />
            </div>
          </div>

          {/* Forgot Password OTP Form */}
          <div>
            {/* Form Title */}
            <Box>
              <Typography
                className={classOuter.titleText}
                style={{
                  ...FAMILYFONTS.family1,
                  fontSize: 24,
                  fontWeight: 400
                }}>Email Verification!</Typography>
            </Box>

            {/* Form Content */}
            <Box>
              <Box className={classOuter.otpContainer}>
                {/* OTP Message */}
                <Typography style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 16,
                  fontWeight: 400
                }}>We have sent an email to {Email}</Typography>
                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 16,
                    fontWeight: 400
                  }}>
                  Please check your inbox and click on the verification link to verify your email
                </Typography>

                {/* OTP Input Field */}
              </Box>

              {/* Resend link and OTP Verify Button */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
                className={classOuter.footerLinkContainer}
              >
                <Box 
                // onClick={this.resendOTP}
                >
                  <Typography className={classOuter.footerLinkText}>
                    <span
                      style={{
                        ...FAMILYFONTS.family3,
                        fontSize: 16,
                        fontWeight: 400
                      }}>
                      Didn't receive a verification link?
                    </span>
                    <Link
                      style={{
                        color: "#009be1",
                        textTransform: "initial",
                        marginLeft: "3px",
                        cursor: "pointer",
                        textDecoration: "none"
                      }}>
                      <span
                        style={{
                          ...FAMILYFONTS.family1,
                          fontSize: 16,
                          fontWeight: 400
                        }}>
                        
                      </span>
                    </Link>
                  </Typography>
                </Box>
                <Button
                  data-testid="emailOTPTest"
                  variant="contained"
                  color="primary"
                  className={classOuter.footerBtn}
                  fullWidth
                  onClick={this.handleEmailTokenCallId}
                  // style={{
                  //   opacity: disabled ? "0.5" : "1",
                  //   cursor: disabled ? "not-allowed" : "pointer",
                  //   ...FAMILYFONTS.family2,
                  //   fontSize: 20,
                  //   fontWeight: 400,
                  // }}
                  style={{
                    opacity:  "1",
                    cursor:  "pointer",
                    ...FAMILYFONTS.family2,
                    fontSize: 20,
                    fontWeight: 400,
                  }}
                  // disabled={disabled}
                  >
                  Resend Link
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
export default EmailOtpVerificationRegistration;
export {EmailOtpVerificationRegistration};
// Customizable Area End
