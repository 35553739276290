// Customizable Area Start
import React from "react";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import {
  CircularProgress as Loader,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
} from "@material-ui/core";
import { Scrollbar } from "react-scrollbars-custom";
import { styles } from "./UserProfileBasicBlock.web";
import PropTypes from "prop-types";
// @ts-ignore
import classesoutercss from "./UserProfileBasicBlock.module.css";
import { withStyles } from "@material-ui/core/styles";
import { primaryText } from "./UserConnections.web";


class UserFollowing extends UserProfileBasicController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  followingData(classes: any) {
    const {followingModule}=this.state
    return followingModule?.length > 0 ? (
      <div className={classesoutercss.DialogContent}>
        <Scrollbar style={{ height: "100%", width: "100%" }}>
          <style>
            {`.ScrollbarsCustom-Track {
                        width : 2px !important;
                        margin-left : 10px !important

                      }`}
          </style>
          <div>
            {followingModule.map((ele: any, index: any) => (
              <div key={index} data-testId="redirect-btn" className={`${classesoutercss.followingContainer} ${classesoutercss.cursor}`} onClick={()=> this.props.navigation.navigate("NonProfitProfile", {id: ele.id})}>
                <List className={classes.root}>
                  <ListItem className={classes.rootList}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={ele.attributes.logo?.url}
                        style={{
                          height: "60px",
                          width: "60px",
                          marginRight: "10px",
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          style={{
                            ...primaryText,
                            fontFamily: "Rene_Bieder_Milliard_Semibold",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {ele.attributes?.organisation_name}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          style={{
                            ...primaryText,
                            whiteSpace: "nowrap",
                            color: "grey",
                          }}
                        >
                          {ele.attributes?.town + "," + ele.attributes?.country}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>

                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    className={
                      classesoutercss.selectedcategoryFollowingMenubuttonstyle
                    }
                    data-testId="following-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.unfollowNonProfitOrg(ele.id);
                    }}
                  >
                    Unfollow
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </Scrollbar>
      </div>
    ) : (
      this.renderErrorMsg()
    );
  }
  renderErrorMsg() {
    return (
      <div className={classesoutercss.DialogContent}>
        <p className={classesoutercss.followingErr} style={{ textAlign: "center", marginTop: 250 }}>No Data Available</p>
      </div>
    );
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <MobileHeader
          navigatTo={"/"}
          data-testid="mobileSearchMainContainer"
          navigation={this.props.navigation}
          title={"Following"}
        />
        <div>
          {this.state.followingLoader ? (
            <div
              className={classes.loaderContainer}
            >
              <Loader className={classes.loaderStyle} />
            </div>
          ) : (
            this.followingData(classes)
          )}
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
UserFollowing.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export { UserFollowing };
export default withStyles(styles as any)(UserFollowing as any);
// Customizable Area End

// Customizable Area End
