import React from "react";
import { toast } from "react-toastify";
import { PublicClientApplication, Configuration } from "@azure/msal-browser";

interface IProps {
  title: string;
  description: string;
  location: string;
  startDate: string;
  endDate: string;
  classes: any;
}

export default class OutlookCalendar extends React.Component<IProps> {

  isEventAlreadyAdded = async (accessToken: string, startDate: string, endDate: string) => {
    const response = await fetch(`https://graph.microsoft.com/v1.0/me/calendarView?startDateTime=${startDate}&endDateTime=${startDate}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const res = await response.json();
    const events = res.value;
    if (!events || events.length === 0) {
      return false;
    }
    const output = events.reduce(
      (str: string, event: any) => `${str}${event.subject}${event.start.dateTime}${event.end.dateTime}\n`,
      ""
    );
    if (output.includes(this.props.title + startDate + endDate)) {
      return true;
    } else {
      return false;
    }
  }

  createEvent = async (accessToken: string, startDate: string, endDate: string) => {
    const event = {
      subject: this.props.title,
      body: {
        contentType: "text",
        content: this.props.description,
      },
      location: {
        displayName: this.props.location,
      },
      start: {
        dateTime: startDate,
        timeZone: "UTC",
      },
      end: {
        dateTime: endDate,
        timeZone: "UTC",
      },
    };
    const response = await fetch("https://graph.microsoft.com/v1.0/me/events", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(event),
    });
    if (response.status === 201) {
      toast.success("Event added in your outlook calender!");
      return true;
    } else {
      const errorData = await response.json();
      console.error("Error adding event:", errorData);
      return false;
    }
  }

  addToCalender = async () => {
    const msalConfig: Configuration = {
      auth: {
        clientId: "06e02212-3061-4d87-a270-7587e3b611bf",
        redirectUri: `${window.location.origin}`
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
      }
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    try {
      const authResult = await msalInstance.loginPopup({
        scopes: ["calendars.readwrite"],
        prompt: "select_account"
      });
      const accessToken = authResult.accessToken;
      const startDate = this.props.startDate;
      const endDate = this.props.endDate;
      const isExist = await this.isEventAlreadyAdded(accessToken, startDate.replace("000Z", "0000000"), endDate.replace("000Z", "0000000"));
      if (isExist) {
        toast.success("Event already added!");
        return;
      } else {
        await this.createEvent(accessToken, startDate, endDate);
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div className={this.props.classes.addToCal} onClick={this.addToCalender}>Add to Calender</div>
    );
  }
}