// Customizable Area Start
const app_logo = require("../../assets/icons/logo.png");
const locationblue = require("../../assets/icons/locationblue.png");
const locationred = require("../../assets/icons/locationred.png");
const locationSVG = require("../../assets/icons/locationSVG.svg");
const filter = require("../../assets/icons/filter.png");
const filterOption = require("../../assets/icons/filterOption.png");
const menu = require("../../assets/icons/menu.png");
const home = require("../../assets/icons/home.png");
const chats = require("../../assets/icons/chats.png");
const needs = require("../../assets/icons/needs.png");
const notification = require("../../assets/icons/notification.png");
const pladges = require("../../assets/icons/pledges.png");
const search = require("../../assets/icons/search.png");
const profile = require("../../assets/icons/profile.png");
const npProfile = require("../../assets/icons/view_profileimag.svg");
const favourites = require("../../assets/icons/favourites.png");
const calender = require("../../assets/icons/calender.png");
const contactus = require("../../assets/icons/contactus.png");
const right_custom_arrow = require("../../assets/icons/right_custom_arrow.png");
const savedsearches = require("../../assets/icons/savedsearches.png");
const settings = require("../../assets/icons/settings.png");
const invitepeople = require("../../assets/icons/invitepeople.png");
const deleteIcon = require("../../assets/icons/delete.png");
const editIcon = require("../../assets/icons/edit.png");
const navgation = require("../../assets/icons/navigation.png");
const directions = require("../../assets/icons/directions.png");
const back = require("../../assets/icons/imagebutton_Back.png");
const downarrow = require("../../assets/icons/downarrow.png");
const clockBlue = require("../../assets/icons/clock_blue.png");
const camerablack = require("../../assets/icons/camera.png");
const downarrowblue = require("../../assets/icons/arrowdownblue.png");
const calendar = require("../../assets/icons/calendar.svg");
const calendarblue = require("../../assets/icons/calendarblue.svg");
const timer = require("../../assets/icons/timer.svg");
const plusIcon = require("../../assets/icons/plusIcon.png");
const plusBlue = require("../../assets/icons/plusBlue.png");
const faceBook = require("../../assets/icons/facebook.png");
const instagram = require("../../assets/icons/instagram.png");
const linkedin = require("../../assets/icons/linkedin.png");
const twitter = require("../../assets/icons/twitter.png");
const npNotifications = require("../../assets/icons/npNotifications.png");
const npDashboard = require("../../assets/icons/npDashboard.png");
const npEvents = require("../../assets/icons/npEvents.png");
const npOrganizations = require("../../assets/icons/npOrganizations.png");
const npRegVolunteers = require("../../assets/icons/npRegVolunteers.png");
const searchBlack = require("../../assets/icons/searchBlack.png");
const searchSVG = require("../../assets/icons/search1.svg");
const arrowup = require("../../assets/icons/ArrowUp.svg");
const npCommunication = require("../../assets/icons/view_comm.svg");
const npFollowers = require("../../assets/icons/view_followers.svg");
const npUsers = require("../../assets/icons/npUsers.svg");
const cancelIcon = require("../../assets/icons/cancel.svg");
const rightArrowBlack = require("../../assets/icons/rightArrow.png");
const triangle = require("../../assets/icons/Triangle.png");
const rectangle = require("../../assets/icons/Rectangle.png");
const camerawhite = require("../../assets/icons/camerawhite.png");
const camerablue = require("../../assets/icons/camerablue.png");
const videowhite = require("../../assets/icons/videowhite.png");
const videoblue = require("../../assets/icons/videoblue.png");
const chatswhite = require("../../assets/icons/chatswhite.png");
const chatsblue = require("../../assets/icons/chatsblue.png");
const speakerwhite = require("../../assets/icons/speakerwhite.png");
const speakerblue = require("../../assets/icons/speakerblue.png");
const bluerightarrow = require("../../assets/icons/bluerightarrow.png");
const copyIcon = require("../../assets/icons/copyicon.png");
const npEdit = require("../../assets/icons/npUpload.png");
const npUpload = require("../../assets/icons/npEdit.png");
const rightIcon = require("../../assets/icons/right_icon.svg");
const npEformCalendar = require("../../assets/icons/npEFCal.svg");
const blueBellIcon = require("../../assets/icons/bluebellIcon.png");
const dummyImage = require("../../assets/icons/DogsTrust.svg");
const msgIcon = require("../../assets/icons/msg_icon.png");
const view_down = require("../../assets/icons/view_down.svg");
const view_up = require("../../assets/icons/view_up.svg");
const locationblack = require("../../assets/icons/locationblack.png");
const currencySymbol = require("../../assets/icons/currencySymbol.png");
const bellIconNP = require("../../assets/icons/bellIcon.png");
const whatsapp = require("../../assets/icons/whatsapp.png");
const clockIcon = require("../../assets/icons/clockIcon.png");
const locationIcon = require("../../assets/icons/locationIcon.png");
const busFilter = require("../../assets/icons/busFilter.png");
const chevronleft = require("../../assets/icons/chevronleft.png");
const phoneIcon = require("../../assets/icons/phoneIcon.png");
const imgWarning = require("../../assets/icons/image_warning.png");
const instagram1 = require("../../assets/icons/instagram1.png");
const imessage = require("../../assets/icons/imessage.png");
const gmail = require("../../assets/icons/email_icon.jpeg");
const twitter1= require("../../assets/icons/twitter1.png");
const plusSvgIcon = require("../../assets/icons/addCircle.svg");
const taskPreference = require("../../assets/icons/myTaskPreference.png")
const needswhite = require('../../assets/icons/needswhite.png')
const phoneblueIcon = require('../../assets/icons/phoneblue.png')
const chatCameraIcon = require('../../assets/icons/camera.svg');
const chatEmojiIcon = require('../../assets/icons/emoji-normal.svg');
const chatMoreIcon = require('../../assets/icons/more_vertical.svg');
const chatMessageSend = require('../../assets/icons/Group 8.svg');
const chatGalleryIcon = require('../../assets/icons/image.svg');
const sendIcon = require('../../assets/icons/white.svg');
const likeIcon = require('../../assets/icons/Like.png');
const gamificationIcon = require('../../assets/icons/gamification.svg')
const handsIcon = require('../../assets/icons/hands.svg');
const successIcon = require('../../assets/icons/success.svg');
const bronzeMedal = require('../../assets/icons/bronzeMedal.svg');
const silverMedal = require('../../assets/icons/silverMedal.svg');
const singleTick = require('../../assets/icons/singleTick.png');
const doubleTick = require('../../assets/icons/DoubleTick.png');
const GreenTick = require('../../assets/icons/GreenTick.png');
const moreIcon = require('../../assets/icons/more.png');
const pinIcon = require('../../assets/icons/pinIconBlue.png');
const shareIcon = require('../../assets/icons/sharewhite.png');
const singleTickGrey = require('../../assets/icons/singleTickGrey.png');
const doubleTickGrey = require('../../assets/icons/doubleTickGrey.png');

export default {
  phoneblueIcon,
  needswhite,
  taskPreference,
  imgWarning,
  plusSvgIcon,
  phoneIcon,
  chevronleft,
  currencySymbol,
  busFilter,
  view_up,
  view_down,
  msgIcon,
  blueBellIcon,
  rightIcon,
  npEformCalendar,
  npEdit,
  npUpload,
  bluerightarrow,
  copyIcon,
  npProfile,
  camerawhite,
  camerablue,
  videowhite,
  videoblue,
  chatswhite,
  chatsblue,
  speakerwhite,
  speakerblue,
  rectangle,
  triangle,
  instagram1,
  imessage,
  gmail,
  twitter1,
  rightArrowBlack,
  calendarblue,
  clockBlue,
  npCommunication,
  npFollowers,
  npUsers,
  cancelIcon,
  searchSVG,
  searchBlack,
  arrowup,
  npEvents,
  npOrganizations,
  npRegVolunteers,
  npDashboard,
  npNotifications,
  downarrowblue,
  deleteIcon,
  editIcon,
  downarrow,
  back,
  filterOption,
  app_logo,
  locationblue,
  locationred,
  filter,
  locationSVG,
  locationblack,
  menu,
  home,
  chats,
  needs,
  notification,
  pladges,
  search,
  profile,
  favourites,
  calender,
  contactus,
  whatsapp,
  right_custom_arrow,
  savedsearches,
  settings,
  invitepeople,
  directions,
  navgation,
  camerablack,
  calendar,
  timer,
  plusIcon,
  plusBlue,
  faceBook,
  instagram,
  twitter,
  linkedin,
  dummyImage,
  bellIconNP,
  clockIcon,
  locationIcon,
  chatCameraIcon,
  chatEmojiIcon,
  chatMoreIcon,
  chatMessageSend,
  chatGalleryIcon,
  sendIcon,
  likeIcon,
  gamificationIcon,
  handsIcon,
  successIcon,
  bronzeMedal,
  silverMedal,
  singleTick,
  doubleTick,
  GreenTick,
  moreIcon,
  pinIcon,
  shareIcon,
  singleTickGrey,
  doubleTickGrey
};

// Customizable Area End
