// Customizable Area Start
import React from "react";
import PropTypes from "prop-types";
import {
    withStyles,
    createStyles,
    ThemeProvider,
    Theme,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";

import {
    Table, TableBody, TableCell as MuiTableCell, TableHead as MuiTableHead, TableRow,
    Typography,
    InputBase,
    Container,
    Paper,
    Dialog,
    Divider,
    Grid,
    Button,
    CircularProgress,
    Box,
    Modal,
    Avatar
} from "@material-ui/core";
import { connect } from "react-redux";
import { setOfferRoleAction,resetOfferRoleAction } from "../../../framework/src/redux/nonProfitUser/offerARoleActions";
import Check from '@material-ui/icons/Check';
// @ts-ignore
import classesOuter from './RegisteredVolunteers.module.css'
// @ts-ignore
import {
    constants, icons, images
} from "../../../components/src/constants";
import ContentManagementController, { Props } from "./ContentManagementController";
import ContentManagement from "./ContentManagement.web";
import moment from "moment";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CustomAlertModal from "../../../components/src/custom/CustomAlertModal";
// Customizable Area End

// Customizable Area Start
function pxToRem(value: number) {
    return `${value / 16}rem`;
}






export type CounterProps = { label?: string };

const TableCell: any = withStyles({
    root: {
        borderBottom: "none !important",
    },
})(MuiTableCell);

const TableHead: any = withStyles({
    root: {
        borderBottom: "3px solid #eaeaea",
    },
})(MuiTableHead);


export const styles = (theme: Theme) =>
    createStyles({
        FiCardMedia: {
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
        },
        FiCardContent: {
            position: "relative",
            backgroundColor: "transparent",
        },
        FiCardActions: {
            position: "relative",
        },
        FiCardActionArea: {
            position: "relative",
        },
        FiCard: {
            position: "relative",
            borderRadius: 15,
            boxShadow: "none",
            height: 145
        },
        root: {
            flexGrow: 1,
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        title: {
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
        },
        search: {
            position: "relative",
            // borderRadius: "8px",
            backgroundColor: COLORS.white,
            marginLeft: 0,
            // width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            height: 48,
            display: "flex",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(1),
            },
            boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.8)',
            borderRadius: '12px',
            // zIndex: 2,
            borderWidth: 1,
            boxSizing: "border-box",
            // maxWidth: 350
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: COLORS.skyBlue,
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            padding: theme.spacing(2, 2, 2, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
            transition: theme.transitions.create("width"),
            fontSize: "revert",
        },
        toolBar: {
            display: "flex",
            flexDirection: "row",
            padding: "0px",
            width: "100%",
            // marginLeft: " 3%",
        },
        sectionDesktop: {
            display: "none",
            // width:"27%",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        sectionTabDesktop: {
            display: "flex",
            // width:"27%",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        aftersearchIconbutton: {
            borderRadius: "8px",
            color: "text.primary",
            backgroundColor: "white",
            margin: "8px",
            padding: "8px",
            height: 46,
            width: 46,
        },
        tabIcons: {
            margin: 6,
            padding: 8,
            border: "1px solid #cdc9c9",
            borderRadius: 8,
            backgroundColor: "white",
        },
        locationsreading: {
            borderRadius: "8px",
            color: "text.primary",
            margin: "8px",
            padding: "8px",
            display: "flex",
        },
        paper: {
            padding: theme.spacing(2),
            margin: "auto",
            // maxWidth: 500,
            borderRadius: 16,
        },
        image: {
            width: 90,
            height: 50,
        },
        img: {
            margin: "auto",
            display: "block",
            maxWidth: "100%",
            maxHeight: "100%",
        },
        _tabs_logo: {
            height: "20px",
            width: "20px",
            left: "33px",
            top: "788px",
            borderRadius: " 0px",
        },
        locationIcon: {
            height: "24px",
            width: "24px",
            left: "33px",
            top: "788px",
            borderRadius: " 0px",
            marginRight: "13px",
        },
        readinglocation: {
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            paddingLeft: 6,
            paddingRight: "inherit",

            "& > p": {
                fontWeight: 600
            },

            [theme.breakpoints.down("xs")]: {
                paddingLeft: 0,
            },
        },
        changeLocationdash: {
            // marginRight: "40px",
            [theme.breakpoints.up("xs")]: {
                marginLeft: "auto",
                marginight: 0,
            },
        },

        gutter: {
            // marginLeft: 80, // or something
            "&:first-child": {
                marginLeft: 0,
            },
        },
        tabsRoot: {
            marginBottom: "50px",
            backgroundColor: COLORS.white,

        },
        tabsIndicator: {
            backgroundColor: "#1890ff",
        },
        tabRoot: {
            textTransform: "initial",
            // minWidth: 72,
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: theme.spacing(1),
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
            "&:hover": {
                color: "#40a9ff",
                opacity: 1,
            },
            "&$tabSelected": {
                // color: "#1890ff",
                fontWeight: theme.typography.fontWeightMedium,
            },
            "&:focus": {
                color: "#40a9ff",
            },
        },
        tabSelected: {},
        typography: {
            padding: theme.spacing(3),
        },
        eventloop: {
            marginBottom: "30px",
        },
        carouselWrapper: {
            width: " 100%",
        },
        image_div: {
            backgroundColor: "pink",
        },
        cardCarousel: {
            width: "100%",
            // margin: " 0 0 20 -17",
            borderStyle: "solid",
            borderColor: "white",
            backgroundColor: "white",
            padding: "24px 24px 24px 14px",
            borderRadius: 12,
            "& after": {
                content: "",
                display: "table",
                clear: "both",
            },
            [theme.breakpoints.down("xs")]: {
                padding: 0,
                margin: 0,
            },
        },
        card_carousel_div: {
            float: "left",
            width: "50%",
            padding: "0 10px",
            [theme.breakpoints.down("xs")]: {
                width: "100%",
                display: "block",
                marginBottom: "20px",
            },
        },
        carousel_card: {
            // padding: "16px",
            textAlign: "left",
            // backgroundColor: "#f1f1f1",
        },
        body2: {
            fontSize: pxToRem(14),
            lineHeight: 2.334,
            [theme.breakpoints.up("md")]: {
                fontSize: pxToRem(16),
            },
        },
        avatar_Groups: {
            height: theme.spacing(4),
            width: theme.spacing(4),
        },
        // web

        showAvatarGroup: { display: "none" },
        showCarouselAvatar: {
            display: "block",
            width: 200,
        },

        icon_holder: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 35,
            width: 35,
            background: "white",
            borderRadius: "50%",
            position: "absolute",
            top: 10,
            right: 10,
        },
        eventContent: {
            width: "60%",
            padding: "0 20px",
            "@media (min-width:600px)": {
                width: "40%",
                padding: "0 10px",
            },
        },

        w_60: {
            width: "60%",
            paddingRight: "15px",
            "@media (min-width:600px)": {
                width: "100%",
                paddingRight: 0,
            },
        },
        py_3: {
            padding: ".5rem 0",
            "@media (min-width:600px)": {
                padding: ".5rem 0",
            },
        },

        heading2: {
            fontSize: 15,
            fontWeight: 400,
        },

        heading3: {
            fontSize: 12,
            fontWeight: 300,
        },

        flex: {
            display: "flex",
        },

        event_img_container: {
            width: "40%",
            position: "relative",
            [theme.breakpoints.up("xs")]: {
                display: "block",
            },
        },

        eventcontent: {
            width: "100%",
            padding: " 0 22px",
            marginTop: -8,
        },
        cardDescription: {
            display: "flex",
            marginTop: "1rem",
            justifyContent: "space-between",
        },
        cardDescriptionAvatar_location: {
            display: "flex",
            marginTop: "1rem",
            justifyContent: "space-between",
        },
        avatarImgcontainer: {
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "0.5rem",
        },
        avatarImg: {
            width: 23,
            height: 23,
            justifyContent: "space-between",
            borderRadius: "50%",
            position: "relative",
            left: "-5px",
            // marginLeft: "-25px",
            zIndex: 1,
            [theme.breakpoints.up("xs")]: {
                width: 16,
                height: 16,
            },
        },

        event_main_img: {
            width: "100%",
            height: "100%",
            borderRadius: 15,
            objectFit: "cover",
        },

        eventCard: {
            padding: 24,
            background: "white",
            borderRadius: 24,
            marginBottom: 30,
            height: 230,
            width: "100%",
            // marginLeft: "2rem",
        },

        icon: {
            height: 25,
            width: 25,
        },

        past_card_status: {
            zIndex: 9,
            position: "absolute",
            padding: "6px",
            height: 25,
            width: 52,
            right: " 12px",
            top: "64px",
            borderRadius: "16px",
            color: COLORS.white,
            fontSize: "12px",
            lineHeight: "27px",
            textAlign: "center",
            letterSpacing: "-0.3px",
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 13,
        },
        btn_dbs: {
            color: COLORS.white,
            height: 38,
            backgroundColor: "#F89A01",
            padding: "7px 18px",
            position: "absolute",
            bottom: '0 !important',
            borderBottomLeftRadius: 15,
            borderTopRightRadius: 15,
            left: 0,
            [theme.breakpoints.up("xs")]: {
                left: 0,
                color: "rgb(255,255,255)",
                bottom: 0,
                height: 25,
                width: 52,
                fontSize: "smaller",
                padding: "5 14",
                position: "absolute",
                backgroundColor: "#F89A01",
                borderTopRightRadius: 15,
                borderBottomLeftRadius: 13,
            },
        },

        mobileLayoutContent: {
            display: "none",
        },
        multiLineEllipsis: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical",
            width: 180,
        },
        layOutbuttons: {
            width: 76,
            height: 24,
            borderRadius: "8px",
            lineHeight: 2,
            marginTop: 0,
            color: COLORS.white,
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
        },

        navigationIcon: {
            color: COLORS.skyBlue,
            backgroundColor: "white",
            margin: "8px",
            padding: "13px",
            height: 50,
            borderRadius: 30,
            zIndex: 999,
            top: -12,
            right: 11,
        },

        [theme.breakpoints.down("xs")]: {
            showCarouselAvatar: {
                display: "none",
            },
            eventdate_loc: {
                width: 121,
                height: 14,
                fontWeight: 400,
                fontSize: 12,
                letterSpacing: -0.3,
                color: " #858585",
            },

            event_img_container: {
                width: "36%",
                position: "relative",
            },
            eventCard: {
                padding: 0,
                background: "white",
                borderRadius: 15,
                marginBottom: 30,
                height: 120,
            },

            icon: {
                height: 15,
                width: 15,
            },

            heading2: {
                fontSize: 12,
                fontWeight: 400,
            },

            heading3: {
                fontSize: 10,
                fontWeight: 300,
            },

            py_3: {
                padding: ".5rem 0",
            },

            w_60: {
                width: "100%",
                paddingRight: 0,
            },

            h2: {
                fontSize: 15,
            },
            desktopLayoutContent: {
                display: "none",
            },

            layOutbuttons: {
                width: 76,
                height: 24,
                borderRadius: "8px",
                lineHeight: 2,
                marginTop: 0,
                color: COLORS.white,
                fontSize: 12,
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
            },

            navigationIcon: {
                color: COLORS.skyBlue,
                backgroundColor: "white",
                margin: "8px",
                padding: "13px",
                height: 50,
                borderRadius: 30,
                zIndex: 999,
                top: -25,
                right: -29,
            },
            mobileLayoutContent: {
                width: "50%",
                display: "inline-grid",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "self-start",
                "@media (min-width:600px)": {
                    width: "50%",
                    position: "relative",
                },
            },
        },
        table: {
            minWidth: 650
        },
        tableroot: {
            width: "100%",
            marginTop: theme.spacing(3),
            overflow: "auto",
            borderRadius: 16
        },
        "searchreletiveenv": {
            "position": "relative",

        },
        "searchfilterEnv": {
            "position": "absolute",
            "height": "100vh",
            "maxHeight": "555px",
            "top": "55px",
            "padding": "10px",
            // "backgroundColor": "#F0F2F5",
            "background": "#FFFFFF",
            "border": "1px solid #D5D5D5",
            "borderRadius": "13px"
        },
    });

// Customizable Area End

class RegisteredVolunteers extends ContentManagementController {
    // Customizable Area Start
    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    };

    constructor(props: Props) {
        super(props);
    }


    // Customizable Area End
    // Customizable Area Start

    headerRegisterVolunteer(classes: any) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 30 }}>
                <div>
                    <Typography style={{ fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }}>
                        Registered Users
                    </Typography>
                </div>
                <div style={{ background: 'rgb(244 246 252)' }} className={`${classes.search} ${classes.searchreletiveenv}`}>


                    <div className={classes.searchIcon}>
                        <img className={classes._tabs_logo} src={icons.searchSVG} />
                    </div>
                    <InputBase
                        placeholder="Search..."
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        style={{
                            width: '38rem',
                            borderRadius: '12px',
                            border: "1px solid #dee1ea",
                            fontSize: "30px",
                        }}
                        inputProps={{ "aria-label": "search" }}
                        data-testId='searchFeild'
                        onChange={(event) => this.requestSearchVolunteer(event.target.value)}
                    />
                </div>
            </div>
        )
    }

    capitalize(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    userName(name: string) {
        return name ? name : '-'
    }

    tableBodyData(classes: any) {
        return (
            this.state.filteredVolunteerData.length > 0 ?
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>FIRST NAME</TableCell>
                            <TableCell align="center" style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>LAST NAME</TableCell>
                            <TableCell align="center" style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>EMAIL ADDRESS</TableCell>
                            <TableCell align="center" style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>DBS Type</TableCell>
                            <TableCell align="center" style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>Last Login</TableCell>
                            <TableCell align="center" style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.filteredVolunteerData.map((row: any, index: React.Key | undefined) => (
                            <TableRow key={index} data-testId={`rowData-${row.attributes.account_id}`} onClick={() => this.handleRegisterVolDetails(row)}>
                                <TableCell component="th" scope="row">
                                    {this.userName(row.attributes.first_name)}
                                </TableCell>
                                <TableCell align="center">{this.userName(row.attributes.last_name)}</TableCell>
                                <TableCell align="center">{row.attributes.email}</TableCell>
                                <TableCell align="center">{row.attributes.dbs_types.length !== 0 ? row.attributes.dbs_types.map((it: any) => it.dbs_type_name) : '-'}</TableCell>
                                <TableCell align="center">{row.attributes.updated_at ? moment(row.attributes.updated_at).format('DD/MM/YYYY h:mm') : moment(row.attributes.created_at).format('DD/MM/YYYY h:mm')}</TableCell>
                                <TableCell align="center">{row.attributes.status != null ? this.capitalize(row.attributes.status) : 'Pending'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                : <Typography
                    style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                    }}
                >
                    Sorry, No data available
                </Typography>
        )
    }

    tableData(classes: any) {
        return (
            this.state.loader ? <div
                style={{
                    height: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress style={{ color: "#009be2" }} />
            </div> : this.tableBodyData(classes)
        )
    }

    roleButton() {
        return (
            this.state.individualRegiVoluDeatils?.attributes?.status === "accepted" ?
                <Button
                    data-testId='offerRoleVounteer'
                    onClick={() => {
                        this.props.setOfferRoleAction(this.state.individualRegiVoluDeatils);
                        this.isRoleAssigned(this.state.individualRegiVoluDeatils?.attributes?.role_assigned);
                    }}
                    style={{
                        boxShadow: "none",
                        textTransform: "none",
                        height: 45,
                        backgroundColor: "#019BE7",
                        color: "white ",
                        fontFamily: "Rene_Bieder_Milliard_SemiBold",
                        borderRadius: 10,
                        marginRight: 10,
                        marginLeft: 13,
                        width: '40%'
                    }}
                    color="secondary"
                >
                    Offer a role
                </Button> : <Button
                    data-testId='approveVounteer'
                    onClick={() => this.approveRegisteredVolunteer(this.state.individualRegiVoluDeatils.attributes.organisation_id, this.state.individualRegiVoluDeatils.attributes.account_id)}
                    style={{
                        boxShadow: "none",
                        textTransform: "none",
                        height: 45,
                        backgroundColor: "#1DBC6C",
                        color: "white ",
                        fontFamily: "Rene_Bieder_Milliard_SemiBold",
                        borderRadius: 10,
                        marginRight: 10,
                        marginLeft: 13,
                        width: '40%'
                    }}
                    color="secondary"
                >
                    Accept user
                </Button>

        )
    }

    openDetailsDialog() {
        const plan = localStorage.getItem("plan");
        const role = localStorage.getItem("np_user_role");
        console.log('attriiii', this.state.individualRegiVoluDeatils?.attributes?.account_id)

        return (
            <Dialog
                fullScreen={false}
                open={this.state.openRegisteredVolModel}
                onClose={() => this.handleRegisterOpenModel()}
                aria-labelledby="responsive-dialog-title"
                data-testId='closeModel'
                PaperProps={{
                    style: { borderRadius: 16 }
                }}>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ marginRight: '200px', fontFamily: "Rene_Bieder_Milliard_SemiBold", fontSize: '1.25rem', lineHeight: 1.6, letterSpacing: '0.0075em', padding: '20px 20px 20px 40px' }}>Registered user details</div>
                    <div style={{ marginRight: 20 }}><CancelOutlinedIcon data-testId='cancelModel' onClick={() => this.handleRegisterOpenModel()} style={{ cursor: "pointer" }} /></div>
                </div>

                <div style={{ display: "flex" }}>
                    <Avatar 
                        src={this.state.individualRegiVoluDeatils?.attributes?.profile_picture.url ?? ""} 
                        alt="Remy Sharp"
                        style={{ height: '50px', width: '50px', borderRadius: '100%', margin: '0px 20px 20px 40px' }}
                    >{this.state.individualRegiVoluDeatils?.attributes?.first_name.charAt(0).toUpperCase()}</Avatar>
                    <div>
                        <Typography style={{ fontWeight: 'bold' }}>{this.state.individualRegiVoluDeatils?.attributes?.first_name} {this.state.individualRegiVoluDeatils?.attributes?.last_name}</Typography>
                        <Typography style={{ color: '#646464', fontSize: '14px' }}>{this.state.individualRegiVoluDeatils?.attributes?.email}</Typography>
                    </div>
                </div>
                <Divider />

                <div style={{ padding: '15px' }}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={4}>
                            <Typography style={{ color: '#626262'}}>Bio</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography>{this.state.individualRegiVoluDeatils?.attributes?.bio ?? "-"}</Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider />

                <div style={{ padding: '15px' }}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={4}>
                            <Typography style={{ color: '#626262'}}>Contact Number</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography>+{this.state.individualRegiVoluDeatils?.attributes?.contact_number ?? "-"}</Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider />

                <div style={{ padding: '15px' }}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={4}>
                            <Typography style={{ color: '#626262'}}>Address</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography>{this.state.individualRegiVoluDeatils?.attributes?.address ?? "-"}</Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider />

                <div style={{ padding: '15px' }}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={4}>
                            <Typography style={{ color: '#626262'}}>DBS</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography>{this.state.individualRegiVoluDeatils?.attributes?.dbs_number}</Typography>
                            <Typography>{this.state.individualRegiVoluDeatils?.attributes?.dbs_types?.length > 0 ? this.state.individualRegiVoluDeatils?.attributes?.dbs_types.map((it: any) => it.dbs_type_name) : '-'} </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider />

                <div style={{ padding: '15px' }}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={4}>
                            <Typography style={{ color: '#626262'}}>Place of work</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography> 
                            {
                                this.state.individualRegiVoluDeatils?.attributes?.place_of_work ? 
                                <div style={{ display: 'flex', alignItems: 'center' }}> 
                                   {
                                    this.state.individualRegiVoluDeatils?.attributes?.place_of_work?.logo.url && <img src={this.state.individualRegiVoluDeatils?.attributes?.place_of_work?.logo.url} alt='workLogo' style={{ height: '50px', width: '50px', marginLeft: '-10px', marginRight: '10px' }} />
                                   } 
                                    <Typography>{this.state.individualRegiVoluDeatils?.attributes?.place_of_work?.name_of_business}</Typography>
                                </div> : "-"
                            
                            }
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider />

                <div style={{ padding: '25px 15px' }}>
                    <Grid container justifyContent={'center'}>
                        {plan !== "Presence-Only" &&
                            <Grid item xs={4}>
                                <div style={{ marginLeft: '20px' }}>
                                    <img  data-testId='messageVounteer' src={icons.chats} alt='img' style={{ height: '30px', cursor: "pointer" }} onClick={() => this.VolunteerCommunication(this.state.individualRegiVoluDeatils?.attributes?.account_id)} />
                                    <Box marginLeft="-7px" marginTop="6px" fontSize="12px" color="#1593CA">Message</Box>
                                </div>
                            </Grid>
                        }
                        <Grid item xs={7}>
                            {(plan !== "Presence-Only" && role !== "User") &&
                                <div style={{ display: "flex" }}>
                                    {this.roleButton()}
                                    <Button
                                        data-testId='rejectVounteer'
                                        onClick={() => this.rejectRegisteredVolunteer(this.state.individualRegiVoluDeatils.attributes.organisation_id, this.state.individualRegiVoluDeatils.attributes.account_id)}
                                        style={{
                                            boxShadow: "none",
                                            textTransform: "none",
                                            height: 45,
                                            backgroundColor: "#F22324",
                                            color: "white ",
                                            fontFamily: "Rene_Bieder_Milliard_SemiBold",
                                            borderRadius: 10,
                                            marginLeft: 13,
                                            width: '40%'
                                        }}
                                        color="secondary"
                                    >
                                        Reject user
                                    </Button>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </div>

            </Dialog>
        )
    }

    openRoleIsPresentModal() {
        return (
            <Modal
                open={this.state.openRolePresentModal}
                onClose={this.handleOpenRolePresentModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classesOuter.modalWrapper}
            >
                <Box>
                    <Box className={classesOuter.modalBox}>
                        <div>
                            <Check className={classesOuter.checkSubmit} />
                            <Typography variant='h6' style={{ fontFamily: "Rene_Bieder_Milliard_Medium", padding: "0 50px" }}>
                                {`The role has already been assigned to this ${this.state?.individualRegiVoluDeatils?.attributes?.first_name} ${this.state?.individualRegiVoluDeatils?.attributes?.last_name}`}
                            </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Button
                                className={classesOuter.modalBtn}
                                variant="contained"
                                onClick={() => this.handleOpenRolePresentModal()}
                            >
                                Back
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Modal>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, theme }: any = this.props;

        // Customizable Area End

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                {/* @ts-ignore */}
                <ContentManagement
                    showBackButton={constants._icons.hide}
                    title="RegisteredVolunteers"
                    showBottomBar={constants._icons.show}
                    showDrawerHeader={true}
                    titleColor={COLORS.black}
                    open={false}
                    mobileOpen={false}
                    navigation={this.props.navigation}
                >
                    <Container maxWidth={"xl"} >
                        <div>
                            <Paper className={classes.tableroot} style={{
                                marginTop: 30
                            }}
                                elevation={0}
                            >

                                {this.headerRegisterVolunteer(classes)}
                                <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
                                {this.tableData(classes)}
                                {this.openDetailsDialog()}
                                {this.openRoleIsPresentModal()}
                            </Paper>
                        </div>
                    </Container>
                </ContentManagement>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
RegisteredVolunteers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

// Customizable Area End

// Customizable Area End
function mapDispatchToProps(dispatch: any) {
    return {
        setOfferRoleAction: (offerRoleFormData: any) => {
            return dispatch(setOfferRoleAction(offerRoleFormData))
        },
        resetOfferRoleAction: () => {
            return dispatch(resetOfferRoleAction())
        }
    }
}
export default connect(null, mapDispatchToProps)(withStyles(styles as any, { withTheme: true })(
    RegisteredVolunteers as any
));
export { RegisteredVolunteers };
// Customizable Area End