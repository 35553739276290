// Customizable Area Start
// @ts-nocheck
import React from 'react'

import Modal from '@material-ui/core/Modal';
import { Box, Typography, Button } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  FONTS
} from "../constants";
// @ts-ignore\
import classOuter from "./CancelSubscriptionModal.module.css"
// Customizable Area End


export default ({ openSuccessModal, handleSuccessModalClose, handleSuccessRedirectModalClose}: any) => {
  // Customizable Area Start

  // Customizable Area End
  return (
    // Customizable Area Start
    <Modal
      open={openSuccessModal}
      onClose={handleSuccessModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classOuter.modalWrapper}
    >
      <Box>
        <Box className={classOuter.modalBox}
        // maxWidth="xl"
        >
          <Box>
            <div>
            <CancelOutlinedIcon className={classOuter.checkSubmit}/>
                     
              <Typography variant='h6'>Do you want to cancel subscription?</Typography>
              <div style={{ marginTop: 35}}>
              <Button
                  className={classOuter.modalCancelBtn}
                  variant="outlined"
                  onClick={() => handleSuccessRedirectModalClose()}
                >
                  CANCEL SUBSCRIPTION
                </Button>
                <Button
                  className={classOuter.modalBtn}
                  variant="contained"
                  onClick={() => handleSuccessModalClose()}
                >
                  GO BACK
                </Button>
              </div>
            </div>
          </Box>

        </Box>
      </Box>
    </Modal>
    // Customizable Area End
  )
}