import React from "react";
// Customizable Area Start
import PropTypes from "prop-types";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { icons } from "../../../components/src/constants";
import ContentManagementController, {
  Props,
} from "./ContentManagementController";

// @ts-ignore
import module from "./ContentManagementEvent.module.css";
import {
  Box,
  CircularProgress,
  Divider,
  Input,
  InputAdornment,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow,
  IconButton,
  Typography,
  Container,
  Paper,
  Button,
} from "@material-ui/core";
import { COLORS } from "../../../framework/src/Globals";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import CustomAlertModal from "../../../components/src/custom/CustomAlertModal";
function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export type CounterProps = { label?: string };

const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);

const TableHead: any = withStyles({
  root: {
    borderBottom: "3px solid #eaeaea",
  },
})(MuiTableHead);


// Customizable Area End

export class ManageProjectsWeb extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  eventsInTable(classes: any) {
    const role = localStorage.getItem("np_user_role");
    const isNpUser = role === "User" ? true : false;
    return (
      <Box width="100%" overflow="auto">
      <Table className={classes?.table}>
        <TableHead>
          <TableRow>
            {/* @ts-ignore */}
            <TableCell className={module.thead} width="30%">
              PROJECT TITLE
            </TableCell>
            <TableCell className={module.thead} align="center">
              STATUS
            </TableCell>
            <TableCell className={module.thead} align="center">
              REQUIRED AMOUNT
            </TableCell>
            <TableCell className={module.thead} align="center">
              RAISED AMOUNT
            </TableCell>

            <TableCell className={module.thead} align="center">
              START AT
            </TableCell>
            <TableCell className={module.thead} align="center">
              {""}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.allProjectsEvents.data &&
            this.state.allProjectsEvents.data
              .slice(0)
              .reverse()
              .map(
                (item: any, index: any) =>
                  item.attributes.event_type === "project_funding" && (
                    <TableRow key={index} data-testId = 'dataInRow'>
                      <TableCell
                        className={`${module.tbody} ${module.ellipsis}`}
                        component="th"
                        scope="row"
                      >
                        {item.attributes.event_name}
                      </TableCell>
                      <TableCell className={module.tbody} align="center">
                        {item.attributes.publish
                          ? "Published"
                          : "Not Published"}
                      </TableCell>
                      <TableCell className={module.tbody} align="center">
                        {item.attributes.target_amount
                          ? `£${item.attributes.target_amount}`
                          : "-"}
                      </TableCell>
                      <TableCell className={module.tbody} align="center">
                        {item.attributes.raised_amount
                          ? `£${item.attributes.raised_amount}`
                          : "-"}
                      </TableCell>
                      <TableCell className={module.tbody} align="center">
                        {item.attributes.from_date
                          ? `${moment(item.attributes.from_date).format(
                            "DD/MM/YYYY"
                          )}`
                          : "-"}{" "}
                        {item.attributes.start_time
                          ? `${moment(item.attributes.start_time).format("hh:mm A")}`
                          : ""}
                      </TableCell>
                      <TableCell>
                        {!isNpUser &&
                        <Button
                          data-testId="view-btn"
                          onClick={() => {
                            this.props.onSecondTabsCreation(item.id);
                            localStorage.setItem("secondTabs", "secondTabs");
                          }}
                          style={{
                            width: 100,
                            boxShadow: "none",
                            textTransform: "capitalize",
                            height: 40,
                            backgroundColor: " #009be2",
                            fontFamily: "Rene_Bieder_Milliard_Medium",
                            color: "white ",
                            borderRadius: 10,
                            marginRight: 13,
                            marginLeft: 13,
                          }}
                        >
                          View
                        </Button>
                      }
                      </TableCell>
                    </TableRow>
                  )
              )}
        </TableBody>
      </Table>
      </Box>
    );
  }

  createEventBtn() {
    return (
      <Button
        data-test-id="ManageProjectsCreatNewEventBtn"
        onClick={() => {
          this?.props?.projectsEvent();
        }}
        variant="contained"
        style={{
          color: "#009BE2",
          height: 49,
          backgroundColor: "rgb(162 231 225 / 10%)",
          border: "1px solid #009BE2",
          textTransform: "none",
          borderRadius: 10,
          boxShadow: "none",
          fontSize: 16,
          fontFamily: "Rene_Bieder_Milliard_SemiBold",
        }}
      >
        Create new event
      </Button>
    );
  }

  searchField() {
    return (
      <Input
        value={this.state.searchProjectsEvents}
        data-test-id="ManageProjectsSearchInput"
        startAdornment={
          <InputAdornment
            position="start"
            style={{
              width: 40,
              marginLeft: -9,
            }}
          >
            <IconButton>
              <img
                src={icons.searchSVG}
                style={{
                  height: 25,
                  width: "auto",
                  opacity: 0.5,
                }}
              />
            </IconButton>
          </InputAdornment>
        }
        placeholder="Search..."
        className={module.searchField}
        disableUnderline
        onChange={(e) => {
          this.setState({
            searchProjectsEvents: e.target.value,
          });
          this.searchProjectsEvents(e.target.value);
        }}
        endAdornment={
          <IconButton
            style={{
              visibility: this.state.searchProjectsEvents
                ? "visible"
                : "hidden",
            }}
            onClick={() => {
              this.searchProjectsEvents("");
              this.setState({ searchProjectsEvents: "" });
            }}
          >
            {" "}
            <CancelRoundedIcon />{" "}
          </IconButton>
        }
      />
    );
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    window.localStorage.setItem("eventTabs", "project");
    const role = localStorage.getItem("np_user_role");
    const isNpUser = role === "User" ? true : false;

    return (
      <div>
        <Container maxWidth={"xl"}>
          <CustomAlertModal
            openSuccessModal={this.state.openCustomModal}
            handleSuccessModalClose={this.handleFreeTrialPlanModal}
            handleModalClose={this.handleFreeTrialModalClose}
            alertText={"Please subscribe to create  project funding events"}
          />
          <div>
            <Paper
              className={classes?.tableroot}
              style={{
                borderRadius: 10,
                marginTop: 30,
              }}
              elevation={0}
            >
              <Box
                justifyContent="space-between"
                display={{ sm: "block", md: "flex" }}
                alignItems="center"
                p={{ sm: "22px", md: "30px" }}
                >
                  <Box 
                    mb={{ sm: "15px", md: "0px" }} 
                    display="flex" 
                    justifyContent="space-between" 
                    alignItems="center"
                  >
                  <Box ml={{ sm: "0px", md: "-15px" }}>
                  <Typography
                    className={module.heading}
                    style={{ fontSize: 18 }}
                  >
                    Managing projects
                  </Typography>
                  </Box>
                <Box ml={{ sm: "0px", md: "50px" }} mr={{ sm: "0px", md: "25px" }}>
                <div>{!isNpUser && this.createEventBtn()}</div>
                </Box>
                </Box>
                <div>{this.searchField()}</div>
              </Box>

              <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />

              {(() => {
                if (this.state.allProjectsEvents?.data?.length > 0) {
                  return this.eventsInTable(classes);
                } else if (this.state.allProjectsEvents?.data?.length === 0) {
                  return (
                    <Typography
                      style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                      }}
                    >
                      Sorry, We are unable to find this event
                    </Typography>
                  );
                } else if (this.state.allProjectsEvents?.error) {
                  return (
                    <Typography
                      style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                      }}
                    >
                      {this.state?.allProjectsEvents?.error}
                    </Typography>
                  );
                } else {
                  return (
                    <div
                      style={{
                        height: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "#009be2" }} />
                    </div>
                  );
                }
              })()}
            </Paper>
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

export default ManageProjectsWeb;
