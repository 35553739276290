import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast} from "react-toastify"

export interface SurveyData {
  id: number | string;
  title: string;
  description: string | null;
  created_at: string | null;
  updated_at: string | null;
  status: string | null;
  send_to: string | null;
  end_date: string | null;
  is_sent: boolean;
  non_profit_account_id: number;
  start_date: string | null;
  participants: string | null;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?: React.ReactNode | React.ReactNode[];
  classes: any;
  adminSelectedTab: any;
  theme?: any;
  history?: any;
  location?: any;
  match?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  startSurvey: boolean;
  radioValue: string;
  liveSurveyData: any;
  draftSurveyData: any;
  completedSurveyData: any
  itemsToShow: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSurveysApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      startSurvey: false,
      radioValue: "",
      liveSurveyData: [],
      draftSurveyData: [],
      completedSurveyData: [],
      itemsToShow: 3,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getSurveysApiCallId) {
      this.handleSurveyDataResponse(responseJson)
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getSurveysList()
  }

  getSurveysList = async () => {
    const header = {
      type: 'non_profit_entity',
      token: localStorage.getItem('nonprofitToken')
    }

    this.getSurveysApiCallId = await apiCall({
      endPoint: 'bx_block_surveys/surveys',
      method: "GET",
      contentType: "application/json",
      header,
    });
  }

  handleSurveyDataResponse = (responseJson: any) => {
    if (responseJson?.error || responseJson?.errors) {
      const Error = responseJson?.error || responseJson?.errors
      toast.error(`${Error}`);
      this.setState({ liveSurveyData: [], completedSurveyData: [] })
    } else {
      this.setState({ 
        liveSurveyData: responseJson?.data?.live, 
        completedSurveyData: responseJson.data?.completed,
        draftSurveyData: responseJson.data?.draft,
      })
    }
  }

  navigateToSurveyPage = (item: any) => {
    let id = item.id
    this.props.navigation.navigate("SurveyUserAnswers", {id})
  }

  openDraftSurvey = (id: number) => {
    this.props.navigation.navigate("NpCreateSurveys", {id: id})
  }

  handleShowMore = () => {
    this.setState((prevState) => ({
      itemsToShow: prevState.itemsToShow + 3, // Show 3 more items on each click
    }));
  };

  handleChange = (event: any) => {
    this.setState({ radioValue: event.target.value });
  };
  handleStartSurvey = () => {
    this.setState({ startSurvey: true })
  }
  handleEndSurvey = () => {
    this.setState({ startSurvey: false })
  }
  // Customizable Area End
}