import React from "react";
// Customizable Area Start
import AdvancedSearchController, { Props } from "./AdvancedSearchController";
//@ts-ignore
import classesOuter from './MobileSearch.module.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { constants, icons, SIZES, FONTS, FAMILYFONTS } from '../../../components/src/constants';
import { COLORS } from "../../../framework/src/Globals";
import {
    Typography,
    IconButton,
    List,
    Modal,
    Box,
    Button,
    CircularProgress as Loader,
} from "@material-ui/core";

import SwipeSavedSearchList from "../../../components/src/custom/swipeSavedSearchList";
// @ts-ignore 
import classesouter from "./SavedSearch.module.css";
import { connect } from "react-redux";
import { setSelectedEvents } from "../../../framework/src/redux/eventsInnerTab/eventsInnerTabActions";
import { storeSearchDetails } from "../../../framework/src/redux/savedSearch/savedSearchAction";
import { addSearchFilterDetails } from "../../../framework/src/redux/advanceSearch/advanceSearchActions";
import { setSelectedFilterEvents } from "../../../framework/src/redux/filterEvents/filterEventsAction";
import { advSearchPayload } from "../../../framework/src/redux/advSearchPayload/advSearchPayloadaction";
import { advFilter } from "../../../framework/src/redux/advFilter/advFilterAction";
import {theme} from './AdvSearchEvents.web'
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class SavedSearch extends AdvancedSearchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    generateSearchText = (savedData: any) => {
        let searchText = "";
    
        if (savedData.categories && savedData?.categories?.length > 0) {
          searchText += `${savedData.categories[0]}, `;
        }
        if (savedData.up_to_km) {
          const radius=savedData.up_to_km
          searchText += radius>9?`Radius ${radius} miles,`:`Radius ${radius} mile,` ;
        }
        if (savedData.location && savedData.location.includes(',')) {
        const postcodeStr = savedData.location.length < 6 ? "" : savedData.location.slice(-6);
         const postcode= /^\d{6}$/.test(postcodeStr) ? postcodeStr : "";
         searchText+=` ${postcode},`
        }
        if (savedData.all_day) {
          searchText += " All day";
        }
        if(searchText.endsWith(",")){
          searchText = searchText.slice(0,-1);

        }
    
        return searchText.trim();
      };
    
      SearchListMob = (savedSearchInfo: any) => {
        console.log(savedSearchInfo , 'searchInfo')
        return (
          <>
            <ThemeProvider theme={theme}>
            {this.state.renderLoader?this.getLoader():
              <List style={{ maxHeight: "100%", overflowY: 'auto', padding: 0 }}>
                {savedSearchInfo && savedSearchInfo?.length > 0 ? (
                  savedSearchInfo.map((item: any , index: any) => {
                 
                  const indiSearch = item?.saved_data
                    return (
                      <SwipeSavedSearchList key={item?.id} deletesaved={() => {
                        this.setState({deleteModal : true, deleteId : item.id}) 
                            this.confrimDeleteModal()                
                      }}>
                        <div className={classesouter.mobcard}>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <IconButton aria-label="show 4 new mails" color="inherit">
                              <img style={{ height: 20 }} src={icons.search} />
                            </IconButton>
                            <div style={{ justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "column", height: 38 }}>
                              <Typography variant="h4" style={{ ...FAMILYFONTS.family1, fontSize: 13}}>{indiSearch?.saved_name}</Typography>
                              <div>
                                <Typography variant="h3" style={{ ...FAMILYFONTS.family3, fontSize: 12 }}>
                                  {this.generateSearchText(item.saved_data)}
                                </Typography>
                              </div>
                            </div>
                          </div>
                          <IconButton aria-label="show 4 new mails" color="inherit" onTouchStart={() => {
                            this.setSavedSearchData(item);
                          }}>
                            <img style={{ height: 20 }} src={icons.bluerightarrow} />
                          </IconButton>
                        </div>
                      </SwipeSavedSearchList>
                    )
                  })
                ) : (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 230 }}>
                    <Typography>No Saved Search Records Found!</Typography>
                  </div>
                )}
              </List>
            }
            </ThemeProvider>
          </>
        )
      }

    getLoader=()=>{
      return  <div className={classesouter.loaderContainer}>
        <Loader className={classesouter.loaderStyle}  />
      </div>
    } 
    



    SearchListWeb = (savedSearchInfo: any) => {

        return (
            <div
            style={{marginBottom:30}}
            >
                <ThemeProvider theme={theme}>
                  {this.state.renderLoader?this.getLoader():
                    <>
                    <div
                        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                        <div />
                        <div>
                            <Typography style={{
                                fontWeight: 400,
                                fontSize: 16,
                                textAlign: "center",
                                letterSpacing: 0.0714286,
                                ...FAMILYFONTS.family2
                            }}>
                                Saved searches
                            </Typography>
                        </div>
                        <div>
                            <IconButton
                                aria-label="show 4 new mails"
                                color="inherit"
                                data-testId="iconBtn"
                                onClick={() => this.props.showsavedsearch()}
                            >
                                <img
                                    style={{
                                        height: 25
                                    }}
                                    src={icons.cancelIcon}
                                />
                            </IconButton>
                        </div>

                    </div>
                    <List style={{ maxHeight: "395", overflow: 'auto', padding: '7px' }}>
                        {
                         savedSearchInfo?.length > 0 ?
                         ( savedSearchInfo.map((item: any, index: any) => {   
                          return (
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10, cursor: 'pointer' }} key={index}>
                              <div className={classesouter.card}>
                                <div data-testId="searchListWeb" onClick={() => {this.props.savedSearchData(item)
                                      this.props.setMainPageLoader(true)
                                }} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <IconButton
                                    aria-label="show 4 new mails"
                                    color="inherit"
        
                                  >
                                    <img
                                      style={{
                                        height: 20
                                      }}
                                      src={icons.search}
                                    />
        
        
                                  </IconButton>
                                  <div style={{ justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "column", height: 38, }}>
                                    <Typography variant="h4" style={{ ...FAMILYFONTS.family1, fontSize: 14, fontWeight: 500 }}>{item?.saved_data?.saved_name}</Typography>
                                    <div>
                                      <Typography variant="h3"
                                        style={{ ...FAMILYFONTS.family3, fontSize: 12, marginTop: 5 }}
                                      >
                                      {this.generateSearchText(item.saved_data)}

                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                {/* delete icon  */}
                                <div style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  height: 50,
                                  marginRight: '10px',
                                  cursor: 'pointer'
                                }}
                                  onClick={() => {
                                    this.setState({ deleteModal: true, deleteId: item.id })
                                    this.confrimDeleteModal()
                                  }}
                                >
                                  <img
                                    style={{
                                      height: 20,
                                      marginBottom: 3,
                                    }}
                                    src={icons.deleteIcon}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                            })):
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:230}}>
                                <Typography>No Saved Search Records Found!</Typography>
                            </div>
                        }

                    </List>
                    </>
                  }
                </ThemeProvider>
            </div>
        )
    }

    confrimDeleteModal = () => {
        return (<Modal
        data-testId="modal"
          open={this.state.deleteModal}
          onClose={this.handleDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
          <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
            <Typography variant="h6" style={{ ...FAMILYFONTS.family3, fontSize: 12 }}>
            <span style={{ ...FAMILYFONTS.family2 }}>This saved search will be deleted from the list.</span>
            </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "10px 0px"
              }}
            >
              <Button
                data-test-id="btnCloseModal"
                variant="contained"
                style={{
                  width: "100%",
                  backgroundColor: "#009BE2",
                  boxShadow: "none",
                  borderRadius: 8,
                  height: 45,
                  textTransform: "capitalize",
                  color: "white"
                }}
                onClick={() => {
                     this.deletesavedsearchEvent()
                     this.filterDeleteItem(this.state.deleteId)
                }}
    
              >
                Confirm
              </Button>
              <Button
                data-testId="btnCloseModal"
                variant="contained"
                style={{
                  ...FAMILYFONTS.family3,
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "none",
                  borderRadius: 8,
                  height: 45,
                  margin: "1rem 0",
                  textTransform: "capitalize",
                  border: "1px solid #979797",
                  color: "black"
                }}
                onClick={this.handleDeleteModal}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
        )
      }
    
    filterDeleteItem=(id:any)=>{
      const {savedSearchInfo}: any = this.props;
      let arr=savedSearchInfo?.filter((item:any)=>item?.id!=id)
        this.props?.storeSearchDetails(arr)
      this.setState({allSavedSearchData:arr})

    }
      
    // Customizable Area Start

    render() {
        // Customizable Area Start
        const {savedSearchInfo}: any = this.props;
        console.log(this.props, 'searchinfo123')
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
               
                <div >
                    {this.props?.displayInweb ?
                        this.SearchListWeb(savedSearchInfo) : 
                        this.SearchListMob(this.state.allSavedSearchData)
                    }
                    {
                        this.confrimDeleteModal()
                    }
                </div>
            </>
            // Customizable Area End
        )
    }
}


// Customizable Area Start
const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    borderRadius: 12,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4
  };
  export function mapStateToProps(state: any) {
  
      return {
        selectedevents: state.eventsReducer?.selectedEvents,
        savedSearchInfo:state.savedSearchInfo?.saved_search,
        advFilterDetails  : state.advFilterReducer?.advFilter

      }
    }
    
    
    
  export function mapDispatchToProps(dispatch: any) {
      return {
        addSearchFilterDetails: (selectedOptions: any) => {
            return dispatch(addSearchFilterDetails(selectedOptions))
          },
        setSelectedEvents: (selectedTab: any) => {
          return dispatch(setSelectedEvents(selectedTab))
        },
        storeSearchDetails:(savedSearchinfo: any)=>{
            return dispatch(storeSearchDetails(savedSearchinfo))
          },
          setSelectedFilterEvents:(selectedEvents: any)=>{
            return dispatch(setSelectedFilterEvents(selectedEvents))
          },
          setAdvSearchPayload: (data: any) => {
            return dispatch(advSearchPayload(data))
        },
        setAdvFilter : (data : any) => {
          return dispatch(advFilter(data))
        }
       
      }
    }
    
export {SavedSearch}
export default connect(mapStateToProps, mapDispatchToProps) (SavedSearch);




// Customizable Area End