
 {/* Customizable Area Start */}

const initInformation = {
    saved_search: []
    }
    
    function savedSearchInfo(state = initInformation,action: { type: any; payload: string | number; }){
        switch(action.type){
            case  "SET_SAVED_SEARCH": 
                return{
                    ...state,
                    saved_search:action.payload
                }    
            default:
                return state;
        }
    }
    
    export default savedSearchInfo;

 {/* Customizable Area End */}