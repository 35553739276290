import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area Start
interface ChatMessages {
    author: string;
    body: string;
    dateUpdated: string;
    index: number;
    media: any;
    memberSid: string;
    sid: string;
    timestamp: string;
    type: string;
}

export interface ChatObject {
    id: string;
    type: string;
    attributes: Attributes;
  }
  export interface Attributes {
    friendly_name: string;
    sid: string;
    unique_name: string;
    event_name?: null;
    description?: null;
    organisation_name?: null;
    status?: null;
    member_data?: (MemberDataEntity)[] | null;
    first_user_image: FirstUserImageOrSecondUserImage;
    second_user_image: FirstUserImageOrSecondUserImage;
    last_message_status: string;
    last_message: string;
    event_type?: null;
    user_type: string;
    typing: boolean;
    clear_chat?: (null)[] | null;
    pinned?: (null)[] | null;
    read_count: number;
    created_at: string;
    last_message_index: number;
    delivery: DeliveryOrRead;
    read: DeliveryOrRead;
    image?: null;
    last_message_times: string;
    convo_type: string;
  }
  export interface MemberDataEntity {
    id: number | string;
    email: string;
    type: string;
    participant_1?: string | null;
    full_name: string;
    profile_picture?: null;
    participant_id: string;
    last_read_message_index: number;
    location: string;
    business_name: string;
    participant_2?: string | null;
  }
  export interface FirstUserImageOrSecondUserImage {
    id: number;
    name: string;
    type: string;
    url: Url;
  }
  export interface Url {
    url: string;
  }
  export interface DeliveryOrRead {
    MB2fd1daa890c54316b77796ea9506b810: number;
  }

  export interface TwillioMessage {
    DiffLabel: string;
    aggregatedDeliveryReceipt?: null;
    attributes: AttributesOrCustomAttributes;
    author: string;
    body: string;
    contentSid?: null;
    customAttributes: AttributesOrCustomAttributes;
    dateUpdated: string;
    hasChannelMetadata: boolean;
    index: number;
    lastUpdatedBy?: null;
    links: Links;
    media?: null;
    medias?: null;
    memberSid?: null;
    participantSid: string;
    sid: string;
    subject?: null;
    timestamp: string;
    type: string;
  }
  export interface AttributesOrCustomAttributes {
    senderId: string | number;
    senderType: string;
  }
  export interface Links {
    conversation: string;
    messages_receipts: string;
    self: string;
  }
  
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    client: any;
    chatUserInformation: any;
    clearChat: (sid: string, updatedChats: Array<any>) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    chatInfo: boolean;
    mobileView: boolean;
    userEmail: string | null;
    messages: any;
    loading: boolean;
    messageText: string;
    userToken: string | null;
    currentPage: number,
    perPage: number,
    searchTerm: string,
    chatInformation: any,
    fullName:string,
    isMobile: boolean,
    searchBar: boolean,
    clearChat: boolean,
    deleteModal: boolean,
    userId: any,
    emojiPicker: boolean,
    selectedEmoji: null,
    clearChatOption: number,
    clearChatApplied: any,
    chatMessageObj: any,
    pinMessagesData: Array<string>,
    pinUnPinMessageId: string,
    showMediaLoading: boolean,
    currentSearchSelected: number,
    searchResults: any,
    pinModal: boolean,
    dropDownPin: boolean,
    currentMessageSelected: string,
    lastDeliveredMessageIndex:  number,
    lastReadMessageIndex: number,
    delivery: any,
    read: any,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class NpChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    channel: any;
    client: any;
    chatUserInformation: any;
    triggerChatId:string = "";
    clearChatApi: string = "";
    chatMessagesRef: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleUserClick = this.handleUserClick.bind(this);
        this.sendMedia = this.sendMedia.bind(this);
        this.typingMessage = this.typingMessage.bind(this);
        this.importData = this.importData.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.keyUp = this.keyUp.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onChangeClearChat = this.onChangeClearChat.bind(this);
        this.clearChat = this.clearChat.bind(this);
        this.pinMessage = this.pinMessage.bind(this);
        this.unpinMessage = this.unpinMessage.bind(this);
        this.getUpdatedMessages = this.getUpdatedMessages.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.handlePinMessage = this.handlePinMessage.bind(this);
        this.handleMessagStructure = this.handleMessagStructure.bind(this);
        this.getMemberChannelId = this.getMemberChannelId.bind(this);
        this.getMessageStatus = this.getMessageStatus.bind(this);
        this.handleChannelUpdated = this.handleChannelUpdated.bind(this);
        this.getLatestReceiptsData = this.getLatestReceiptsData.bind(this);
        this.saveMediaLink = this.saveMediaLink.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
        ];

        this.state = {
            // Customizable Area Start
            mobileView: true,
            userEmail: '',
            messages: [],
            chatInfo: false,
            loading: true,
            messageText: '',
            userToken: '',
            currentPage: 1,
            perPage: 20,
            searchTerm: "",
            chatInformation: {
                attributes: {
                    event_name: '',
                }
            },
            fullName:'',
            isMobile: false,
            searchBar: false,
            clearChat: false,
            deleteModal: false,
            userId: 0,
            selectedEmoji: null,
            emojiPicker: false,
            clearChatOption: 0,
            clearChatApplied: [],
            chatMessageObj: {},
            pinMessagesData: [],
            pinUnPinMessageId: "",
            showMediaLoading: false,
            currentSearchSelected: 0,
            searchResults: [],
            pinModal: false,
            dropDownPin: false,
            currentMessageSelected: '',
            lastDeliveredMessageIndex: -1,
            lastReadMessageIndex: -1,
            delivery: {},
            read: {}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.chatMessagesRef = createRef();
        this.handlePaginationMessages = this.handlePaginationMessages.bind(this);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const apiMessageId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if (apiMessageId === this.clearChatApi) {
            const updatedChats = [...this.state.clearChatApplied];
            updatedChats.push(responseJson);
            this.props.clearChat(this.chatUserInformation.attributes.sid, updatedChats);
            this.setState({
                clearChatApplied: updatedChats,
            });
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.client = this.props.client;
        this.chatUserInformation = this.props.chatUserInformation;
        
        const userEmail = localStorage.getItem('user_email');
        const userToken = localStorage.getItem('nonprofitToken');
        const userId = localStorage.getItem('non_profit_acc_id');
        this.setState({
            userEmail,
            userToken,
            chatInformation: this.props.chatUserInformation,
            userId,
            clearChatApplied: this.props.chatUserInformation.attributes.clear_chat,
            chatMessageObj: {},
            messages: {},
            pinMessagesData: this.props.chatUserInformation.attributes.pinned,
            delivery: this.props.chatUserInformation.attributes.delivery,
            read: this.props.chatUserInformation.attributes.read,
        });
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.handleUserClick();
    }

    async componentDidUpdate(prevProps: Props, prevState: S) {
        if(prevProps.chatUserInformation !== this.props.chatUserInformation){
            this.client = this.props.client;
            this.chatUserInformation = this.props.chatUserInformation;
            this.handleUserClick();
            this.setState({
                chatInformation: this.props.chatUserInformation,
                loading: true,
                clearChatApplied: this.props.chatUserInformation.attributes.clear_chat,
                messages: {},
                chatMessageObj: {},
                pinMessagesData: this.props.chatUserInformation.attributes.pinned,
                delivery: this.props.chatUserInformation.attributes.delivery,
                read: this.props.chatUserInformation.attributes.read,
            })
        }
        if (prevState.clearChatApplied.length < this.state.clearChatApplied.length) {
            const lastClearChat = this.state.clearChatApplied[this.state.clearChatApplied.length - 1].clear_chat;
            this.updateChatMessagesList(lastClearChat);
        }
    }

    async componentWillUnmount(): Promise<void> {
        this.channel.removeListener('messageAdded', this.handleMessageAdded);
        this.channel.removeListener("updated", this.handleChannelUpdated);
    }

    async handlePaginationMessages(event: any) {
        if (event.target.scrollTop <= 20 && this.state.chatMessageObj.hasPrevPage) {
            const objMessage = await this.state.chatMessageObj.prevPage();
            this.setState({
                chatMessageObj: objMessage
            });
            this.setMessages(objMessage, true, this.state.messages, true);
        }
    }

    resize() {
        this.setState({
            isMobile: window.innerWidth <= 760 ? true : false,
        });
    }

    getTwilioMessages = async () => {
        this.setState({
            messages: []
        });
        const objMessage = await this.channel.getMessages();
        this.setState({
            chatMessageObj: objMessage
        });
        if (objMessage.items.length > 0) {
            this.setMessages(objMessage, false, {}, false);
        }
        this.setState({
            loading: false
        })
    }
    
    setMessages = (messagesData: any, prevCall: boolean, currentMessages: any, onScroll: boolean) => {
        let messagesArray: any = [];
        messagesArray = messagesData.items.map(async (message: any) => {
            const messagesState = { ...message.state, deleteMessage: message.remove, services: message.services, links: message.links, updateAttributes: message.updateAttributes };
            let chatCleared = false;
            if (this.state.clearChatApplied.length > 0) {
                this.state.clearChatApplied.forEach((dates: any) => {
                    const fromDate = new Date(dates.clear_chat.from).toLocaleString('en-IN', { hour12: false });
                    if (fromDate != 'Invalid Date' && new Date(message.state.timestamp) < new Date(dates.clear_chat.from)) {
                        chatCleared = true;
                    }
                });
            }
            if (chatCleared) return;
            const DiffLabel = this.getDiffLabel(messagesState.timestamp);
            return new Promise(async (resolve, reject) => {
                let messageReturn = {};
                if (messagesState.type == 'media') {
                    const urlLink = await messagesState.media.getContentTemporaryUrl();
                    messageReturn = this.getMessageState(messagesState, urlLink, DiffLabel);
                } else {
                    messageReturn = this.getMessageState(messagesState, messagesState.media, DiffLabel);
                }
                resolve(messageReturn);
            });
        });
        Promise.all(messagesArray).then((values) => {
            this.updateMessagesState(values, prevCall, currentMessages, onScroll, messagesData);
        })
    }

    updateMessagesState = async (messages: any, prevCall: boolean, currentMessages: any, onScroll: boolean, objMessage: any) => {
        const newMessagesState = this.getUpdatedMessages(messages, currentMessages, prevCall);
        if (!onScroll) {
            this.scrollToBottom();
        }
        if (Object.keys(newMessagesState).length < 30 && objMessage.hasPrevPage) {
            const newobjMessage = await objMessage.prevPage();
            this.setState({
                chatMessageObj: newobjMessage
            });
            this.setMessages(newobjMessage, true, newMessagesState, false);
        }
    }

    getUpdatedMessages = (messages: any, currentMessages: any, prevCall: boolean) => {
        let newMessagesState: any = {};
        if (prevCall) {
            messages.reverse();
            newMessagesState = currentMessages;
        }
        messages.forEach((message: any) => {
            if (!message) return;
            if (message.DiffLabel in newMessagesState) {
                let messageDataList = [];
                if (prevCall) {
                    messageDataList = [
                        message,
                        ...newMessagesState[message.DiffLabel].data
                    ]
                } else {
                    messageDataList = [
                        ...newMessagesState[message.DiffLabel].data,
                        message
                    ]
                }
                newMessagesState = {
                    ...newMessagesState,
                    [message.DiffLabel]: {
                        data: messageDataList
                    }
                };
            } else {
                if (prevCall) {
                    newMessagesState = {
                        [message.DiffLabel]: {
                            data: [
                                message
                            ]
                        },
                        ...newMessagesState,
                    };
                } else {
                    newMessagesState = {
                        ...newMessagesState,
                        [message.DiffLabel]: {
                            data: [
                                message
                            ]
                        }
                    };
                }
            }
        })
        this.setState({ messages: newMessagesState, loading: false });
        return newMessagesState;
    }

    getMessageState = (messageState: any, mediaData: any, DiffLabel: string) => {
        return {
            ...messageState,
            author: messageState.author,
            body: messageState.body,
            dateUpdated: messageState.dateUpdated.toString(),
            index: messageState.index,
            media: mediaData,
            customAttributes: messageState.attributes,
            memberSid: messageState.memberSid,
            sid: messageState.sid,
            timestamp: messageState.timestamp,
            DiffLabel,
            type: messageState.type,
            deleteMessage: messageState.deleteMessage
        }
    }

    importData = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            this.setState({
                showMediaLoading: true
            });
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            this.scrollToBottom();
            this.sendMedia(formData);
            const mediaFormData = new FormData();
            mediaFormData.append('sid', this.chatUserInformation.attributes.sid);
            mediaFormData.append('media_type', "file");
            mediaFormData.append('media_link', event.target.files[0]);
            this.saveMediaLink(mediaFormData);
        }  
    }

    triggerPush = () => { 
        const httpBody = {
            "data":{
                "attributes": {
                    "remarks": `New message From ${localStorage.getItem("full_name")}`,
                    "notify_type": "chat",
                }
            }
        };
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': localStorage.getItem("token"),
             type:"user_entity"
        };
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        
        request.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
            
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.triggerChatId = request.messageId;
    
        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
    
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_push_notifications/push_notifications`
        );

    
        runEngine.sendMessage(request.id, request)
    }

    handleMessageAdded = async (message: any) => {
        if (message.conversation.sid != this.channel.sid) return;
        this.getMessageStatus(message.conversation, this.state.chatInformation.attributes.member_data, this.state.delivery, this.state.read, message.state.index);
        let duplicateMsg = false;
        let { messages } = this.state;
        Object.values(messages).forEach((msgs: any) => {
            if (
                msgs.data.filter((mssg: any) => mssg.sid == message.state.sid).length > 0
            ) {
                duplicateMsg = true;
            }
        });
        if (duplicateMsg) return;
        let messageState = { ...message.state, deleteMessage: message.remove, services: message.services, links: message.links, updateAttributes: message.updateAttributes };
        const DiffLabel = this.getDiffLabel(messageState.timestamp);
        let messageObj = {};
        if (messageState.type == 'media') {
            const urlLink = await messageState.media.getContentTemporaryUrl();
            messageObj = this.getMessageState(messageState, urlLink, DiffLabel);
        } else {
            messageObj = this.getMessageState(messageState, messageState.media, DiffLabel);
        }
        if (DiffLabel in messages) {
            messages = {
                ...messages,
                [DiffLabel]: {
                    data: [
                        ...messages[DiffLabel].data,
                        messageObj
                    ]
                }
            };
        } else {
            messages = {
                ...messages,
                [DiffLabel]: {
                    data: [
                        messageObj
                    ]
                }
            };
        }
        this.setState({
            showMediaLoading: false,
            messages,
        });
        this.scrollToBottom();
    };

    sendMessage = async () => {
        const { messageText: text } = this.state;
        if (text && String(text).trim()) {
            this.sendTwilioMessage(null, text);
            const urlRegex = /(https?:\/\/[^\s]+)/;    
            const messageArray = text.split(urlRegex);    
            messageArray.forEach((part) => {
                if (urlRegex.test(part)) {
                    const httpBody = {
                        "sid": this.chatUserInformation.attributes.sid,
                        "media_link": part,
                        "media_type": "link"
                    };
                    this.saveMediaLink(JSON.stringify(httpBody));
                }
            });
            this.setState({ messageText: "" });
            this.scrollToBottom();
        }
        this.triggerPush()
    };

    getMemberChannelId = async (channel: any, memberData: any) => {
        const participants = await channel.getParticipants();
        const oldIds: Array<string> = [];
        memberData.forEach((item: any) => {
            if ((item.email == this.state.userEmail && 'user_entity' == item.type.toLowerCase())&&(item.email == this.state.userEmail && 'non_profit_entity' == item.type.toLowerCase()) ){
                oldIds.push(item.participant_id);
            }
        });
        const participantId = participants.filter((participant: any) => participant.state.identity == this.state.userEmail && oldIds.indexOf(participant.state.sid) == -1)[0].state.sid;
        return participantId;
    }

    getLatestReceiptsData = (delivery: any, chatDelivery: any, converationRead: any, chatRead: any) => {
        let deliveries: any =chatDelivery;
        Object.keys(delivery).forEach((participantId) => {
            let messageIndex = participantId && !(participantId in chatDelivery) ||
                chatDelivery[participantId] < delivery[participantId] ? delivery[participantId] :
                chatDelivery[participantId];
            deliveries[participantId] = messageIndex;
        });

        let read: any = chatRead;
        Object.keys(converationRead).forEach((participantId) => {
            let messageIndex = participantId && !(participantId in chatRead) ||
            chatRead[participantId] < converationRead[participantId] ? converationRead[participantId] :
            chatRead[participantId];
            read[participantId] = messageIndex;
        });
        
        return { deliveries, read };
    }

    getMessageStatus = async (channelOb: any, memberData: Array<any>, delivery: any, chatRead: any, lastMessageIndex: number) => {
        let lastReadMessageIndex: any = -1;
        let lastDeliveredMessageIndex: any = -1;
        let callUpdateGroup = false;
        const participantId = await this.getMemberChannelId(channelOb, memberData);
        if (!(participantId in delivery) || delivery[participantId] < lastMessageIndex) {
            delivery[participantId] = lastMessageIndex;
        }
        if (!(participantId in chatRead) || chatRead[participantId] < lastMessageIndex) {
            callUpdateGroup = true;
            chatRead[participantId] = lastMessageIndex;
            channelOb.updateLastReadMessageIndex(lastMessageIndex);
        }
        const { deliveries, read } = this.getLatestReceiptsData(
            channelOb._internalState.attributes.delivery,
            delivery,
            channelOb._internalState.attributes.read,
            chatRead
        );
        const tempDelieveries: Array<number> = Object.values(deliveries);
        tempDelieveries.sort((a: number, b: number) => a - b);
        lastDeliveredMessageIndex = tempDelieveries.length >= memberData.length ? tempDelieveries[0] : -1;
        const tempRead: Array<number> = Object.values(read);
        tempRead.sort((a: number, b: number) => a - b)
        lastReadMessageIndex = tempRead.length >= memberData.length ? tempRead[0] : -1;
        if (callUpdateGroup) {
            await channelOb.updateAttributes({ ...channelOb.attributes, read });
        }
        this.setState({
            lastReadMessageIndex: this.state.lastReadMessageIndex > lastReadMessageIndex ? this.state.lastReadMessageIndex : lastReadMessageIndex,
            lastDeliveredMessageIndex: this.state.lastDeliveredMessageIndex > lastDeliveredMessageIndex ? this.state.lastDeliveredMessageIndex : lastDeliveredMessageIndex,
            delivery: deliveries,
            read,
        })
    }
    
    handleUserClick = async () => {
        try {
            this.channel = await this.client.getConversationBySid(this.chatUserInformation.attributes.sid);
            if (this.channel._internalState.status != 'joined') {
                try {
                    await this.channel.join();
                } catch(e){}
            }
            if (this.channel.lastMessage) {
                this.getMessageStatus(this.channel, this.state.chatInformation.attributes.member_data, this.chatUserInformation.attributes.delivery, this.chatUserInformation.attributes.read, this.channel.lastMessage.index);
            }
            this.channel.on("messageAdded", this.handleMessageAdded);
            this.channel.on("updated", this.handleChannelUpdated);
            this.getTwilioMessages();
        } catch (e) {
            console.log('spec_check_Chat_channel_err', e);
        }
    }
    
    sendTwilioMessage = async (formData: any, textMessage: any) => {
        if (formData) {
            await this.channel.prepareMessage()
                .setBody('')
                .setAttributes({senderId: this.state.userId, senderType: 'non_profit_entity'})
                .addMedia(formData)
                .build()
                .send();
            
        } else {
            await this.channel.prepareMessage()
                .setBody(textMessage)
                .setAttributes({senderId: this.state.userId, senderType: 'non_profit_entity'})
                .build()
                .send();
        }
    }
    
    sendMedia = async (formData: any) => {
        this.sendTwilioMessage(formData, '');
        this.scrollToBottom();
        this.triggerPush();
    }

    keyUp = (e: any) => {
        if (e.keyCode == 13) {  //checks whether the pressed key is "Enter"
            this.sendMessage();
        }
    }
    
    typingMessage = (e: any) => {
        this.channel.typing();
        let len = 0;
        for (let _ of e.target.value) {
            len++;
        }
        if(len <= 150){
            this.setState({ messageText: e.target.value })
        }
    }


    scrollToBottom = () => {
        const messageListing = document.getElementById('messagesListing');
        if (messageListing) {
            messageListing.scrollTo(0, messageListing.scrollHeight + 100)
        }
    }

    getDiffLabel = (messageTime: any) => {
        const date2: any = new Date(messageTime);
        const date1: any = new Date();
        const date3 = new Date();
        date3.setHours(0,0,0,0);
        let DiffLabel = '';
        const DiffDays = Math.floor((Math.abs(date2 - date1) / 1000) / (60 * 60 * 24));
        if (DiffDays == 1) {
            DiffLabel = 'Yesterday';
        } else if (DiffDays == 0) {
            if (date3 > date2) {
                DiffLabel = 'Yesterday';
            } else {
                DiffLabel  = 'Today';
            }
        } else {
            DiffLabel = new Date(messageTime).toLocaleString('en-IN',{ day: 'numeric', month: 'short'});
            const yearLabel = new Date(messageTime).toLocaleString('en-IN',{ year: 'numeric' });
            DiffLabel = DiffLabel + ', ' + yearLabel;
        }
        return DiffLabel;
    }

    scrollToBottomEmoji = () => {
        if (typeof document !== 'undefined'){
            let element = global.document.querySelector(".EmojiPickerReact");
            if (element) {
                element.scrollIntoView({block:'start'});
            }
        }  
    }

    handleEmojiPicker = () => {
        if(this.state.emojiPicker === false){
            this.setState({
                emojiPicker: true
            },() => this.scrollToBottomEmoji())
        }
        if(this.state.emojiPicker === true){
            this.setState({
                emojiPicker: false
            },()=>this.scrollToBottomEmoji())
        }        
    }

    handleEmojiSend = (emojiObject: any) => {
        const emojiText = emojiObject.emoji;        
        const updatedMessageText = this.state.messageText
        ? `${this.state.messageText}${emojiText}`
        : emojiText;
        let len = 0;
        for (let _ of updatedMessageText) {
            len++;
        }
        if(len <= 150){
            this.setState({ 
            selectedEmoji: emojiText,
            messageText: updatedMessageText 
            });
        }
    };

    onSearchChange = (event: any) => {
        const elements = Array.from(document.querySelectorAll('.messagesContent'));
        const matches: Array<any> = elements
            .filter((element: any) => {
                return element.textContent.toLowerCase().includes(event.target.value.toLowerCase());
            }).reverse();
        if (matches.length > 0) {
            const messageListing = document.getElementById('messagesListing');
            if (messageListing) {
                messageListing.scrollTop = matches[0].closest('li').offsetTop;
            }
        }
        this.setState({
            searchTerm: event.target.value,
            searchResults: matches,
            currentSearchSelected: 0
        });
    }

    onSearchNext = () => {
        let newIndex = this.state.currentSearchSelected + 1;
        if (this.state.searchResults.length > newIndex && newIndex >= 0) {
            const messageListing = document.getElementById('messagesListing');
            if (messageListing) {
                messageListing.scrollTop = this.state.searchResults[newIndex].closest('li').offsetTop;
            }
            this.setState({
                currentSearchSelected: newIndex,
            });
        }
    }

    onChangeClearChat(event: any) {
        this.setState({
            clearChatOption: event.target.checked ? parseInt(event.target.value) : 0
        });
    }

    clearChat() {
        const { userToken } = this.state;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': userToken,
            'type': 'non_profit_entity'
        };

        let fromDate = this.getClearDates(this.state.clearChatOption);
        let toDate = this.getClearDates(0);

        const httpBody = {
            "sid": this.chatUserInformation.attributes.sid,
            "clear_chat": 
            {
                "from": fromDate,
                "to": toDate
            }
        }
    
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.clearChatApi = request.messageId;

        let url = `${configJSON.clearChat}`;
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(request.id, request);
        this.setState({
            clearChatOption: 0,
            deleteModal: false,
        });
    }

    updateChatMessagesList = (lastClearChat: { from: string, to: string }) => {
        let newMessagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) => {
                let chatCleared = false;
                const fromDate = new Date(lastClearChat.from).toLocaleString('en-IN', { hour12: false });
                if (fromDate != 'Invalid Date' && new Date(message.timestamp) < new Date(lastClearChat.from)) {
                    chatCleared = true;
                }
                if (chatCleared) return;
                if (dateKey in newMessagesData) {
                    newMessagesData = {
                        ...newMessagesData,
                        [message.DiffLabel]: {
                            data: [
                                ...newMessagesData[message.DiffLabel].data,
                                message
                            ]
                        }
                    };
                } else {
                    newMessagesData = {
                        ...newMessagesData,
                        [message.DiffLabel]: {
                            data: [
                                message
                            ]
                        }
                    };
                }
            })
        });
        this.setState({
            messages: newMessagesData
        })
    }


    getClearDates = (type: number) => {
        const currentDate = new Date();
        if (type === 0) {
            return new Date(currentDate).toISOString();
        } else if (type === 1) {
            const oneDay = 24 * 60 * 60 * 1000;
            const prevDay = currentDate.getTime() - oneDay;
            const prevDate = new Date(prevDay);
            return new Date(prevDate).toISOString();
        } else if (type === 2) {
            const oneDay = 7 * 24 * 60 * 60 * 1000;
            const prevDay = currentDate.getTime() - oneDay;
            const prevDate = new Date(prevDay);
            return new Date(prevDate).toISOString();
        } else if (type === 3) {
            const prevDate = new Date(currentDate);
            prevDate.setMonth(currentDate.getMonth() - 1);
            return new Date(prevDate).toISOString();
        }
    }

    handlePinMessage = (messageData: any) => {
        let messagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) => {
                if (message.sid == messageData.sid) {
                    message.customAttributes = messageData.customAttributes;
                }
                messagesData = messagesData = this.handleMessagStructure(dateKey, messagesData, message);
            })
        });
        this.setState({
            messages: messagesData,
            pinModal: false,
            dropDownPin: false,
            currentMessageSelected: '',
            clearChatOption: 0,
            deleteModal: false
        });
    }

    pinMessage = async (messageData: any) => {
        const message = { 
            ...messageData,
            customAttributes: {...messageData.customAttributes, pinned: true},
        }
        await messageData.updateAttributes(message.customAttributes);
        this.handlePinMessage(message);
    }

    unpinMessage = async (messageData: any) => {
        const message = { 
            ...messageData,
            customAttributes: {...messageData.customAttributes, pinned: false},
        }
        await messageData.updateAttributes(message.customAttributes);
        this.handlePinMessage(message);
    }

    handleMessagStructure = (dateKey: string, msgData: any, msg: any) => {
        if (dateKey in msgData) {
            msgData = {
                ...msgData,
                [msg.DiffLabel]: {
                    data: [
                        ...msgData[msg.DiffLabel].data,
                        msg
                    ]
                }
            };
        } else {
            msgData = {
                ...msgData,
                [msg.DiffLabel]: {
                    data: [
                        msg
                    ]
                }
            };
        }
        return msgData;
    }

    deleteMessage = (messageData: any) => {
        let messagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) => {
                if (message.sid == messageData.sid) return;
                messagesData = this.handleMessagStructure(dateKey, messagesData, message);
            })
        });
        this.setState({
            messages: messagesData
        });
        messageData.deleteMessage();
    }

    handleChannelUpdated = (data: { conversation: any, updateReasons: Array<string> }) => {
        const { conversation, updateReasons } = data;
        if (updateReasons.indexOf('attributes') == -1) return;
        this.getMessageStatus(conversation, this.state.chatInformation.attributes.member_data, this.state.delivery, this.state.read, conversation.lastMessage.index);
    }

    saveMediaLink = (body: FormData | string) => {
        const { userToken } = this.state;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': userToken,
            type: 'non_profit_entity'
        };
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.saveMedias
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
    
        runEngine.sendMessage(request.id, request);
    }
}
