// @ts-nocheck
// Customizable Area Start
import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {
  withStyles,MuiThemeProvider, createTheme, createStyles, ThemeProvider, Theme,
} from "@material-ui/core/styles";
import { Scrollbar } from 'react-scrollbars-custom';
import { COLORS } from "../../../framework/src/Globals";
import {
  AppBar,
  Hidden,Paper, InputBase, TableContainer, TableCell as MuiTableCell, TableHead as MuiTableHead,
  IconButton, Toolbar, CircularProgress as Loader, Typography, Box, Container, Tabs, Tab, Button, Avatar, Grid, List, ListItem, ListItemText, ListItemAvatar
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { constants, icons, FONTS,FAMILYFONTS } from "../../../components/src/constants";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBlackBorderIcon from '@material-ui/icons/FavoriteBorder';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Carousel from "react-elastic-carousel";
import { setSelectedEvents } from "../../../framework/src/redux/events/eventsActions";
import { connect } from "react-redux";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  NotificationsNone as NotificationsNoneIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";
import moment from "moment";
// @ts-ignore
import classesoutercss from "./UserProfileBasicBlock.module.css";
// @ts-ignore
import style from "../../Gamification/src/Gamification.module.css";
import UserEditProfile from "./userEditProfile.web";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Divider from '@material-ui/core/Divider';
import dateFormat from "dateformat";
import ImageLoader from '../../../components/src/custom/ImageLoader';
import { InitiateSpinner } from "../../../components/src/design-system/spinner";
import { AvailabilityForm } from "../../email-account-registration/src/AvailabilityForm";
import classesOuter from "../../dashboard/src/HomeDashBoard.module.css"
import { MyTaskPreferencesForm } from "../../email-account-registration/src/MyTaskPreferencesForm";
import { HideContainer } from '../../../components/src/shared/hide-container';
import  { styled } from "@material-ui/styles";
// Customizable Area End

// Customizable Area Start
function pxToRem(value: number) {
  return `${value / 16}rem`;
}

const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);

const TableHead: any = withStyles({
  root: {
    borderBottom: "1px solid #cbcbcb",
  },
})(MuiTableHead);

export const styles = (theme: Theme) =>
  createStyles({
    FiCardMedia: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "100%",
    },
    root: {
      flexGrow: 1
    },
    width100: {
      width: "100%"
    },


    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px",
      width: "50%",
      textAlign:"left",
    },








    tabsRoot: {
      backgroundColor: COLORS.white,
    },
    tabsIndicator: {
      backgroundColor: "#019be1",
      height: '4px',
      borderRadius: '10px'
    },
    tabRoot: {
      textTransform: "initial",
      // minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$tabSelected": {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },

      [theme.breakpoints.down('sm')]: {
        width:'33.33%',
        marginRight: '0',
        marginLeft: '0',
       // Adjust the font size as needed
      },
      
    },
    tabSelected: {},











    flex: {
      display: "flex",
    },

    event_img_container: {
      width: "326px",
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
    },


    eventcontent: {
      width: "100%",
      padding: " 0 22px",
      marginTop: -8,
    },
    cardDescription: {
      display: "flex",
      margin: "15px auto",
      justifyContent: "space-between",
    },
    cardDescriptionAvatar_location: {
      display: "flex",
      marginTop: "0.5rem",
      justifyContent: "space-between",
    },
    avatarImgcontainer: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "0.5rem",
    },
    avatarImg: {
      width: 23,
      height: 23,
      justifyContent: "space-between",
      borderRadius: "50%",
      position: "relative",
      left: "-5px",
      // marginLeft: "-25px",
      zIndex: 1,
      [theme.breakpoints.up("xs")]: {
        width: 16,
        height: 16,
      },
    },

    event_main_img: {
      width: "326px",
      height: "170px",
      borderRadius: 15,
      objectFit: "cover",
      "@media (max-width:600px)": {
        width: "100%",
        height: "100%",
      },
    },
    event_img: {
      width: "147px",
      height: "140px",
      borderRadius: 15,
      objectFit: "cover",
      "@media (max-width:600px)": {
        width: "100%",
        height: "100%",
      },
    },
    event_image_new: {
      objectFit: "cover",
      width: "125px",
      height: "115px",
      borderRadius: 15,
      "@media (max-width:600px)": {
        height: "100%",
        width: "100%",
      },
    },
    eventCard: {
      padding: 24,
      background: "white",
      borderRadius: 24,
      marginBottom: 30,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: "100%",
      border: '1px solid rgb(211,211,211)',
      "@media (max-width:600px)": {
        border: 'none',
      },
    },
    FavoriteBorderIcon: {
      padding: "6px",
      backgroundColor: COLORS.white,
      position: "absolute",
      height: "30px",
      borderRadius: "50%",
      width: "30px",
      right: " 12px",
      color: "#292d32",
      top: "12px",
      cursor: "pointer",
      "@media (max-width:600px)": {
        width: "20px",
        height: "20px",
      },
    },
    FavoriteIcon: {
      position: "absolute",
      padding: "6px",
      backgroundColor: COLORS.white,
      width: "30px",
      fill: "red",
      top: "12px",
      height: "30px",
      color: "#292d32",
      borderRadius: "50%",
      right: " 12px",
      cursor: "pointer",
      "@media (max-width:600px)": {
        width: "20px",
        height: "20px",
      },
    },
   



    btn_dbs: {
      color: COLORS.white,
      height: 38,
      backgroundColor: "#F89A01",
      padding: "7px 18px",
      position: "absolute",
      bottom: 0,
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 15,
      left: 0,
      [theme.breakpoints.up("xs")]: {
        left: 0,
        color: "rgb(255,255,255)",
        bottom: 0,
        height: 25,
        fontSize: "12px",
        padding: "5 14",
        position: "absolute",
        backgroundColor: "#F89A01",
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 13,
      },
    },

    mobileLayoutContent: {
      display: "none",
    },
    multiLineEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      width: 180,
    },
    layOutbuttons: {
      width: 76,
      height: 37,
      borderRadius: "8px",
      lineHeight: 2,
      marginTop: '-35px',
      color: COLORS.white,
      fontSize: 14,
      padding: "5px 10px",
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },



    [theme.breakpoints.down("xs")]: {

      eventdate_loc: {
        width: 121,
        height: 14,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: -0.3,
        color: " #858585",
        "@media (max-width:600px)": {
          width: 'auto',
        },
      },

      event_img_container: {
        width: "36%",
        position: "relative",
      },
      eventCard: {
        padding: 0,
        background: "white",
        borderRadius: 15,
        marginBottom: 30,
        height: 120,
      },








      desktopLayoutContent: {
        display: "none",
      },

      layOutbuttons: {
        width: 76,
        height: 24,
        borderRadius: "8px",
        lineHeight: 2,
        marginTop: 0,
        color: COLORS.white,
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        padding: "2px 10px"
      },


      mobileLayoutContent: {
        width: "50%",
        display: "inline-grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "self-start",
        "@media (min-width:600px)": {
          width: "50%",
          position: "relative",
        },
      },
    },
    rootContainer: {
      margin: "0 10px 0 0",
      padding: "24px",
    },
    rootList: {
      padding: 0
    },
    loaderContainer:{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop:250 
    },
    userLoaderContainer:{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 300,
    },
    loaderStyle:{ 
      height:'24 !important',
      width:'24 !important',
      color: "#009be2"
    },
      contentWidth:{
        height:"95%",
        overflow:"scroll",
        "@media (max-width:600px)": {
          height:"auto",
        overflow:"inherit !important",
        },
      },
      searchdivContainer: {
        position: "relative",
        borderRadius: "12px",
        backgroundColor: COLORS.white,
        width: "87%",
        justifyContent: "space-between",
        alignItems: "center",
        height: 54,
        display: "flex",
        border: "1px solid #d5d5d5",
        marginLeft: 23,
    marginTop: 5,
    marginBottom: 10
    },
    mobileContainer: {	
      marginLeft: "0 !important",	
      width:"99% !important",	
    },
    publishBtn: {
      fontSize: '14px',
      padding: "2px 15px",
      background:"#019BE1"
  },
  searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
  },
  inputRoot: {
      color: COLORS.black10,
  },
  inputInput: {
      padding: theme.spacing(2, 2, 2, 0),
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      color: COLORS.black10, ...FAMILYFONTS.family3, fontSize: 16, fontWeight: 400
  },
  searchImageStyle:{
    width: 25,
    height: 26,
    left: "33px",
    top: "788px",
    borderRadius: "0px",
  },
  flexItem:{
    display: "flex", alignItems: "center"
  }
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: '#019be1'
      }
    }
  });

  const primaryText = {
    width: 150,
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
// Customizable Area End
class UserProfileBasicBlock extends UserProfileBasicController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  myArrow({ type, onClick, isEdge }: any) {
    if (type === 'PREV' && isEdge) {
      return (<></>)
    }
    if (type === 'NEXT' && isEdge) {
      return (<></>)
    }
    const pointer = type === "PREV" ? <ArrowBackIcon /> : <ArrowForwardIcon />;
    return (
      <div
        className="PrivateHiddenCss-xsDown-67"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={onClick}
          disabled={isEdge}
          style={{
            color: COLORS.skyBlue,
            backgroundColor: "white",
            margin: "8px 4px",
            padding: "13px",
            height: "45px",
            width: "45px",
            borderRadius: 30,
            zIndex: 999,
            boxShadow: "1px 2px 11px 2px rgb(0 0 0 / 16%)",
          }}
        >
          {pointer}
        </IconButton>
      </div>
    );
  }


  handleChange = (event: any, value: any) => {
    this.setState({ value });
    if(value==1){
      this.PledgesTabData();
    } else if(value == 2){
      this.NeedsTabData();
    } else if(value == 3) {
      this.getActivityLog()
    }
   
  };
  renderActivitylog = () => {
    return (
     <div className={style.activityLogPageTable}>
      <Paper className={style.paperRoot} elevation={0}>
      {this.tableHeader()}
      <Divider className={style.dividerStyle} />
      {this.tableBodyData()}
      </Paper>
     </div>
    )
  }
  getSearchbar() {
    return (
      <div className={style.search}>
        <div className={style.searchIcon}>
          <img className={style.searchIconImg} src={icons.searchSVG} />
        </div>
        <InputBase
          placeholder="Search..."
          className={style.searchboxStyle}
          data-test-id="searchFeild"
          onChange={(event) => this.handleSearchClick(event.target.value)}
          classes={{ input: style.inputInput }}
        />
      </div>
    );
  }
  tableHeader() {
    return (
      <div className={style.activityTable}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={3}>
            <Typography className={style.activityLogTitle} style={{ ...FONTS.semiBoldFont}}>
              Activity Log
            </Typography>
          </Grid>
          <Grid item md={7}>
            {this.getSearchbar()}
          </Grid>
        </Grid>
      </div>
    );
  }

  getTextColor(status: string) {
    if(status === "Upcoming") {
      return "#84db67" 
    } else if (status === "Cancelled") {
      return "#D93046" 
    } else {
      return "#404040"
    }
  }
  tableBodyData() {
    return (
      <div className="activitylog-table">
        <TableContainer >
        <Table >
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ ...FONTS.regularFont}}>
                TITLE
              </TableCell>
              <TableCell style={{ ...FONTS.regularFont}}>TYPE</TableCell>
              <TableCell style={{ ...FONTS.regularFont}}>ORGANIZER</TableCell>
              <TableCell align="center" style={{ ...FONTS.regularFont}}>
                STATUS
              </TableCell>
              <TableCell align="center" style={{ ...FONTS.regularFont}}>
                DATE/TIME
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.tableData.length > 0 ? (
              <>
                {this.state.tableData.map((item: any) => (
                  <TableRow key={item.id}>
                    <TableCell align="left" style={{ ...FONTS.regularFont}}>{item.title ?? "--"} </TableCell>
                    <TableCell style={{ ...FONTS.regularFont}}>{item.type ?? "--"}</TableCell>
                    <TableCell style={{ ...FONTS.regularFont}}>{item.pledge_organisation ?? "--"}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        ...FONTS.regularFont,
                        color: this.getTextColor(item.status)
                        }}
                    >
                      {item.status ?? "--"}
                    </TableCell>
                    <TableCell align="center" style={{ ...FONTS.regularFont}}>
                      {item.date}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  className={style.noRecord}
                >
                  No record found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        </TableContainer>
      </div>
    );
  }
  userFullName() {
    return (this.state.apiUserDetail?.data === undefined ? '---' : this?.userDynamicFullName())
  }


  searchBarAndNotification(classes) {
    return (
      <Container style={{ padding:'0', marginTop: isMobile ? 0 : '102px', background: COLORS.white , borderTop: isMobile ? '1px solid rgb(221,222,227)': '0' }}>
        {/* searchbar and notification  section */}
        <Hidden xsDown implementation="css">
          <Toolbar className={`${classes.toolBar} stickyHeader`} style={{
  position: 'fixed',
  top:'0',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'white',
  width:'100%',
  zIndex:'1000',
  textAlign:'left',
}}>
            {/*  search  section  */}
            <div className={classesoutercss.backIconWeb} style={{ marginLeft:'90px', marginTop:'70px', display: 'flex', alignItems: 'center'}}>
              <img src={icons.back} data-testId='backToProfile' onClick={() => {
                this.setState({ editProfileView: false })
                this.fetchUser('none')
              }}
                style={{ cursor: 'pointer', marginRight: '20px' }}
              />
              <Typography
                variant="h6"
                style={{ ...FONTS.dbsTitles, color: COLORS.black }}
                noWrap
              >
                {this.state.editProfileView ? 'Edit Profile' : 'Profile'}
              </Typography>
            </div>
          </Toolbar>
          <hr style={{ width: '100%',margin:'0', backgroundColor: "rgb(232 230 220)", borderBottom: '1px solid rgb(232, 230, 220)' }} />


          {/* User Profile Header Start */}
          {this.state.editProfileView ? null : (<>

            <div className={classesoutercss.profileHeaderContainer} style={{paddingRight:'60px'}}>
              <Button className={classesoutercss.profileHeaderText} data-testId='connReqBtn' onClick={() => this.handleClickOpenCategoryDilog('My Connections')}>Connection Requests</Button>
              <Button className={classesoutercss.profileHeaderText} data-testId='myfavBtn' onClick={() => this.handleClickOpenCategoryDilog('My Favourites')}>My Favourites</Button>
              <Button className={classesoutercss.profileHeaderText} data-testId='myAvailBtn' onClick={() => this.handleClickOpenCategoryDilog('My Availability')}>My Availability</Button>
              <Button className={classesoutercss.profileHeaderText} data-testId='myTaskPrefBtn' onClick={() => {this.handleClickOpenCategoryDilog('My Task Preferences') }}>My Task Preferences</Button>
            </div>
            <hr style={{ width:'100%', margin:'0',backgroundColor: "rgb(232 230 220)", borderBottom: '1px solid rgb(232, 230, 220)' }} />       
          </>)}
          {/* User Profile Header End */}
         

        </Hidden>
        {/* searchbar and notification  section  end*/}


        {/* Profile section */}
        {this.state.editProfileView ? <UserEditProfile area={this.state.area} postCode={this.state.postCode} country={this.state.country} town={this.state.town} streetName={this.state.streetName} navigation={this.props.navigation} view={this.state.editProfileView} setBack={() => {
          this.setState({ editProfileView: false })
          this.fetchUser('none')
        }} /> : (
          <GridWrapper className={classesoutercss.mainContainer} style={{padding:'0'}}>
            <div id="myDiv" ref={this.divRef} className={classesoutercss.profileContainer}>
              <div style={{width: '100%', display: 'flex', flexDirection:'row'}} className={classesoutercss.userProfileContainer}>
                <div className="imageWrap" >
                <div className={classesoutercss.profilePicimg} style={{  display: 'flex'}}>
                  <style>
                    {`
                    .responsive-image {
                      width:100%;
                      max-width: 165px;
                      height: auto;
                    }
                    `}
                  </style>
                  <img className={classesoutercss.profileMob}
                      src={this.userProfilePicture()}
                      alt="profile-logo"
                      data-testId='profileImg'
                      // style={{borderRadius:'50%',  border: '6px solid white'}}
                    />
                </div>
                </div>
                <div className="userProfile"  >
                  <div className="nameaddress" >
                    <div
                      className={classesoutercss.nameCompanyFont}
                      style={{ ...FONTS.ProfileName }}

                      data-testId='fullName'
                    >

                      {this.state?.apiUserDetail?.data?.attributes?.full_name}
                      
                    </div>

                    <div
                      className={classesoutercss.nameAddressFont}
                      style={{ ...FONTS.dbsForm }}

                    >
                      {this.userAddress()}

                    </div>
                  </div>
                  <div style={{ marginTop:'1%', marginLeft:'0'}} className="companyLogo">

                  <div>
                    {
                      this.state.apiUserDetail?.data?.attributes?.show_company_logo ?
                      <div style={{ display: "flex", alignItems: "center"}}>
                        <img
                          className={classesoutercss.userCompanyLogoImage}
                          src={this.state.apiUserDetail?.data?.attributes?.business_logo?.url}
                          alt="company-logo"
                          onClick={() => this.props.navigation.navigate("BusinessProfileView")}
                          style={{
                            cursor:'pointer',
                            display:this.state.apiUserDetail?.data?.attributes?.business_logo?.url?"block":"none",
                            border:"none"

                          }}
                        />
                        <div className={classesoutercss.mobileView }>
                          <div style={{ fontFamily: "Rene_Bieder_Milliard_Medium", fontSize: "12px"}}>{this.state.apiUserDetail?.data.attributes.business_name}</div>
                          <div style={{ fontFamily: 'Rene_Bieder_Milliard_Book', fontSize: "10px"}}>{this.state.apiUserDetail?.data.attributes.busiess_profile_details?.town}, {this.state.apiUserDetail?.data.attributes.busiess_profile_details?.country}</div>
                        </div>
                        </div>
                        :
                        null
                    }

                  </div>
                  </div>
                </div>
              </div>
              {isMobile    && 
                <AppBar color="inherit" elevation={0} position="fixed">
             <div className={classesoutercss.mobileMenu} >
  <div className={classesoutercss.hamburgerMenu} onClick={() => this.setState({openMenu:!this.state.openMenu}) }>
    <div className={classesoutercss.hamburgerLine}></div>
    <div className={classesoutercss.hamburgerLine}></div>
    <div className={classesoutercss.hamburgerLine}></div>
  </div>
  <div className={classesoutercss.dropdownContent} style={{display: this.state.openMenu ? "block" : "none"}}>
    <div className={classesoutercss.profileHeaderText1} data-testId="myfavBtn"  onClick={() => this.handleClickOpenCategoryDilog('My Favourites')}>My Favourites</div>
    <div className={classesoutercss.profileHeaderText1} data-testId="myAvailBtn" onClick={() => this.handleClickOpenCategoryDilog('My Availability')}>My Availability</div>
    <div className={classesoutercss.profileHeaderText1} data-testId="connReqBtn" onClick={() => this.handleClickOpenCategoryDilog('My Connections')}>Connection Requests</div>
    <div className={classesoutercss.profileHeaderText1} data-testId="myTaskPrefBtn" onClick={() => {this.handleClickOpenCategoryDilog('My Task Preferences') }}>My Task Preferences</div>
  </div>
</div>
</AppBar>     
} 
              <div>
                <div className={this.checkSettingsScreen(isMobile) ? classesoutercss.connectionsDetails2 : classesoutercss.connectionsDetails}>
                  {this.modelNamesAndViews()}
              </div>
              <div className={classesoutercss.userBioDetails}>
                <div
                  style={{ ...FONTS.h1 }}
                  className={classesoutercss.userBioTitle}
                >
                  {constants.userProfile.bioTitle}
                </div>
                <div
                  style={{ ...FONTS.body1, wordWrap: "break-word"
                  }}
                  className={classesoutercss.userBioDes}
                >
                  {this.userBio()}
                </div>
              </div>
              <div className={classesoutercss.footerContainer}>
                <div
                  className={classesoutercss.editProfile}
                  style={{ display: this.isMobileDisplayNone(isMobile) }}
                >
                  <Button
                    className={isMobile ? classesoutercss.editProfileMobileBtn : classesoutercss.editProfileBtn}
                    style={{ ...FONTS.h1 }}
                    fullWidth={this.checkIsMobile(isMobile)}
                    onClick={() => {
                      this.showEditProfile()
                    }}
                    data-testId="editProfile"
                    id="editProfile"
                    startIcon={<EditIcon style={{ color: '#00cbf3' }} />}
                  >
                    Edit Profile
                  </Button>
                </div>
                </div>
                <div
                  style={{ display: isMobile ? "flex" : "none" }}
                  className={classesoutercss.mobileFooter}
                >
                  <Button
                    className={classesoutercss.MsgBtn}
                    style={{ ...FONTS.h1 }}
                    fullWidth
                    startIcon={<EditIcon style={{ color: '#00cbf3' }} />}
                    onClick={() => this.props.navigation.navigate('UserEditProfile')}
                    data-testId="editProfileMobileBtn"
                  >
                    Edit Profile
                  </Button>
                </div>
              </div>
            </div>
          </GridWrapper>)}

        {/* Profile section end */}
      </Container>
    )
  }

  connectionCatogaryStatus(breakPoints) {
    return (
      (this.state.selectedCategoryStatus === "My Connections" || this.state.selectedCategoryStatus === "Connections") &&

      <Carousel
        isRTL={false}
        breakPoints={[
          { width: 1, itemsToShow: 4, itemsToScroll: 1 },
          { width: 550, itemsToShow: 4, itemsToScroll: 1 },
          { width: 768, itemsToShow: 4, itemsToScroll: 1 },
          { width: 1200, itemsToShow: 4, itemsToScroll: 1 }
        ]}
        renderArrow={this.myArrow}
        showArrows={this.checkIsMobile(!isMobile)}
        itemPadding={[-10, -10]}
        itemsToShow={5}
        pagination={false}
        disableArrowsOnEnd={true}
      >
        {constants.connectionscategorystatus.map((item, index) => (
          <Button key={index} variant="outlined" size="small" style={{ fontFamily: 'Rene_Bieder_Milliard_Semibold', marginLeft:5}}
            className={this.state.selectedCategoryMenu === item.label ?
              classesoutercss.selectedcategorymenubuttonstyle : classesoutercss.categorymenubuttonstyle}
            onClick={() => this.categoryMenuCHange(item.label)}
            data-testId="tab-btn"
          // fullWidth
          >
            {item.label}
          </Button>
        ))}

      </Carousel>

    )
  }

  followerCatogaryModelStatus(classes) {
    
    return (
      this.state.loader ?
        <DialogContent className={classesoutercss.DialogContent}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Loader loading={true} />
            </Grid>
          </Grid>
        </DialogContent>
        : this.followingModelData(classes)
    )
  }
  registeredCatogaryModelStatus(classes) {
    
    return (
      this.state.loader ?
        <DialogContent className={classesoutercss.DialogContent}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Loader loading={true} />
            </Grid>
          </Grid>
        </DialogContent>
        : this.registeredModelData(classes)
    )
  }

  favouritesContent(classes) {


    return (
      this.state.selectedCategoryStatus === "My Favourites" &&
        this.state?.favData?.length <=0 ?
        <InitiateSpinner/>
        : this.favouritesData(classes)
    )
  }

  AvailabilityContent(classes) {
    return (
      this.state.selectedCategoryStatus === "My Availability" && 
      <div style={{height:"100%", width:"80%",marginLeft:35,position:'relative',bottom:25}}>
      <AvailabilityForm preventNavigationOnSuccess hideSkipButton  popup ={true}
       close={ () => this.handleClose()} submitButtonTitle="Set Availability"/>    
      </div>
    );
  }


TaskPreferencesContent(classes){
   return (this.state.selectedCategoryStatus === "My Task Preferences" &&
   <div style={{width: "100%", height:"80%"}}>
    <MuiThemeProvider theme={theme}>
    <MyTaskPreferencesForm popup close={
      () => this.handleClose()}
    hideSkipButton hideTitle/>
    </MuiThemeProvider>
   </div>  
   );

}

   connectionModelContent(classes) {
    return (
      this.state.selectedCategoryStatus === "My Connections" &&
        this.state.connLoader ?
        <DialogContent className={classesoutercss.DialogContent} >
          <div className={classes.loaderContainer}
          >
            <Loader className={classes.loaderStyle} />
          </div>
        </DialogContent>
        : this.connectModelData(classes)
    )
  }



  connectedModelContent(classes) {
    return (
      this.state.connLoader ?
        <DialogContent className={classesoutercss.DialogContent}>
          <div className={classes.loaderContainer}
          >
            <Loader className={classes.loaderStyle}/>
          </div>
        </DialogContent>
        : this.connectedModelData(classes)
    )
  }

  PendingRequestModelContent(classes) {
    return (
      this.state.connLoader ?
        <DialogContent className={classesoutercss.DialogContent}>
          <div className={classes.loaderContainer}
          >
            <Loader className={classes.loaderStyle}/>
          </div>
        </DialogContent>
        : this.pendingRequestModelData(classes)
    )
  }

  pendingRequestModelData(classes) {
    const filteredPending = this.getFilteredResult(this.state.pendingRequestModule)  
    return (
      this.state.pendingRequestModule?.length > 0 ?
        <DialogContent className={classesoutercss.DialogContent}>
          <Scrollbar style={{ height: '100%', width: '100%' }}>
              {this.connectSearchBox(classes)}
              {filteredPending?.map((ele, index) => (
                <Container>
                <Grid key={index}>
                  <Grid container spacing={3} data-testId="pending-redirect" className="cursor" onClick={()=> this.props.navigation.navigate("UserProfileView",{id: ele.attributes.receipient_id})}>
                    <Grid item xs={9}>
                      <List className={classes.root}>
                        <ListItem className={classes.rootList}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={ele.attributes.profile_picture?.url} style={{ height: "60px", width: "60px", marginRight: "20px" }} />
                          </ListItemAvatar>
                          <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        style={{
                          ...primaryText,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ele.attributes?.name}
                      </Typography>
                    }

                  ></ListItemText>
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={3} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                      <div>
                        <Button style={{ fontFamily: 'Rene_Bieder_Milliard_Semibold' }}
                          variant="outlined" size="small" fullWidth
                          className={classesoutercss.selectedcategoryPendingMenubuttonstyle}
                          data-testId="pending-button"
                          onClick={(e) => e.stopPropagation()}
                        >
                          Pending
                        </Button>

                      </div>
                    </Grid>

                  </Grid>
                </Grid>
                </Container>
              ))}
              {this.hiddenText(filteredPending?.length)}
          </Scrollbar>
        </DialogContent> : this.errorModelMsg()
    )
  }
  connectSearchBox=(classes)=>{
    return (<div className={classes.searchdivContainer}>
      <div className={classes.flexItem} >
          <div className={classes.searchIcon}>
              <img
                  className={classes.searchImageStyle}
                  src={icons.search}
             />
          </div>

          <InputBase
              placeholder="Search Connection"
              classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
          />

      </div>
  </div>)
  }

  connectModelData(classes) {
    const filteredConnections = this.getFilteredResult(this.state.allConnectionModule)
    return (
      this.state.allConnectionModule?.length > 0 ?

        <DialogContent className={`${classesoutercss.DialogContent} scrollbars1`} >
          <Scrollbar style={{ height: '100%', width: '100%', }}>
          <div>
            {this.connectSearchBox(classes)}
            </div> 
            {filteredConnections.map((ele) => (
              <Container >
                <Grid key={ele.id} data-testId={`connectionData`} className="cursor" onClick={()=> this.props.navigation.navigate("UserProfileView",{id: ele.id})}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <List className={classes.root}>
                        <ListItem className={classes.rootList} >
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={ele.profile_picture?.url} style={{ height: "60px", width: "60px", marginRight: "20px" }} />
                          </ListItemAvatar>
                          <ListItemText primary={ele?.full_name} />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={3} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                      <div>
                        <Button style={{ fontfamily: 'Rene_Bieder_Milliard_Semibold' }}
                          variant="outlined" size="small" fullWidth
                          className={classesoutercss.selectedcategoryConnectMenubuttonstyle}  
                          data-testId="connect-button"
                          onClick={(e) => {
                            e.stopPropagation(); 
                            this.addConnectionRequest(ele.id)
                            }}
                          >
                          Connect
                        </Button>

                      </div>
                    </Grid>


                  </Grid>
                </Grid>
              </Container>
            ))}
            {this.hiddenText(filteredConnections?.length)}
          </Scrollbar >

        </DialogContent>

        : this.errorModelMsg()
    )
  }

  followingModelData(classes) {
    return (
      this.state.followingModule?.length > 0 ?
        <DialogContent className={classesoutercss.DialogContent}>
          <Scrollbar style={{ height: '100%', width: '100%', }}>
            <Container >
              {this.state.followingModule.map((ele, index) => (
                <Grid key={index} data-testId={`followingData-${ele.id}`}>
                  <Grid container justifyContent="space-between" className="cursor">
                    <Grid item md={9}>
                      <List className={classes.root}>
                        <ListItem data-testId='npProfileBtn' onClick={()=> this.props.navigation.navigate("NonProfitProfile", {id: ele.id})} className={classes.rootList}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={ele.attributes.logo?.url} style={{ height: "60px", width: "60px", marginRight: "20px" }} />
                          </ListItemAvatar>
                          <ListItemText primary={ele.attributes?.organisation_name} />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item md={3} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                      <div>
                        <Button data-testId='unfollowBtn' onClick={(e) => this.unfollowNonProfitOrg(ele.id)} variant="outlined" size="small" fullWidth className={classesoutercss.selectedcategoryFollowingMenubuttonstyle}>
                          Unfollow
                        </Button>
                      </div>
                    </Grid>

                  </Grid>
                </Grid>
              ))}
            </Container>
          </Scrollbar>
        </DialogContent> : this.errorModelMsg()
    )
  }

  registeredModelData(classes) {
    return (
      this.state.registeredModule?.length > 0 ?
        <DialogContent className={classesoutercss.DialogContent}>
          <Scrollbar style={{ height: '100%', width: '100%', }}>
            <Container >  
            {this.state.registeredModule.map((ele, index) => (
                <Grid key={index} data-testId={`registeredData-${ele?.organisation_id}`}>
                <Grid container>
                  <Grid item className={classes.width100}>
                    <List className={classes.root}>
                <ListItem data-testId="redirect-btn" className={`${classes.rootList} cursor`} onClick={()=> this.props.navigation.navigate("NonProfitProfile", { id: ele.organisation_id })}>
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src={ele?.organisation_logo?.url}
                      style={{
                        height: "60px",
                        width: "60px",
                        marginRight: "20px",
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        style={{
                          ...primaryText,
                          width: "100%"
                        }}
                      >
                        {ele?.organisation_name}
                      </Typography>
                    }
                  />
                </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              ))}              
            </Container>
          </Scrollbar>
        </DialogContent>: this.errorModelMsg()
    )
  }

  getEventImage=(placeholderImg,event_image,need_photo)=>{
    if(event_image && event_image?.url){
      return event_image?.url
    }
    else if(need_photo){
      return need_photo
    }
    else if (placeholderImg){
      return placeholderImg
    }
  }
  
  handleFavTitle(item:any){
   console.log(item)
   return item.attributes.favouriteable_type==='BxBlockEvents::NonProfitEvent'?item.attributes.favouriteable_attributes.event_name: item.attributes.favouriteable_attributes.title_of_need;
  }

  favouritesData(classes) {
    const placeholderImg=localStorage.getItem('placeholderImg')
    return (
      this.state.favData?.length > 0 ?
        <DialogContent className={classesoutercss.DialogContent} style={{ overflowY: "scroll" }} >
          {this.state.typeOfEvent  && <Redirect to={`${this.state.typeOfEvent}`} />}
          {this.state.favData.map((item) => {
            const postcode= item.attributes.favouriteable_attributes.post_code;
            const location= item.attributes.favouriteable_attributes.location;
            const pledgeId = item.attributes.my_pledge_id ?? item.attributes.need_my_pledge_id;
            const eventStatus=pledgeId[0]?.status;
            const obj = item.attributes.favouriteable_attributes;
            return (<div key={item.id}>
              <Grid container style={{ paddingLeft: "25px", paddingRight: "25px" }}
                data-testId="favorite-card"
                onClick={() => this.handleCardClick(
                  item.attributes.favouriteable_id, 
                  item.attributes.favouriteable_type,
                  item.attributes.favouriteable_attributes.event_type,
                  item.attributes.favouriteable_attributes.account_id
                )}
              >
                <Grid item xs={4} style={{ marginRight: "-10px" }}>
                <ImageLoader loaderStyle={{textAlign:"center",marginTop:'15%',marginLeft:'30%'}}  classes={classes.event_image_new} imageUrl={this.getEventImage(placeholderImg,item?.attributes?.event_image,item?.attributes?.need_photo)} alt="complex" />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: 'flex', flexDirection: 'column', }}>
                    <Typography variant="h6" style={{ fontWeight: "bold", fontSize: "18px" }} >{this.handleFavTitle(item)}</Typography>
                    <Typography variant="subtitle1" style={{ color: "grey" }}><span> <img
                      style={{
                        height: 15,
                        width: 15,
                      }}
                      src={icons.calender}
                      alt="Calendar Icon"
                    />  </span> {dateFormat(item.attributes.favouriteable__attributes?.from_date, "dd mmm yyyy, h:MM tt")} </Typography>                  
                   <Typography variant="subtitle1" style={{ color: "grey" }}><span> <img
                      style={{
                        height: 15,
                        width: 15,
                      }}
                      src={icons.locationred}
                      alt="Calendar Icon"
                    />  </span>  {eventStatus === "Approved" || item.attributes.favouriteable_attributes.event_type === "project_funding" || item.attributes.favouriteable_attributes.event_type === "activity" || obj.account_id?.toString() === localStorage.getItem("acc_id")
                    ? this.renderLocation(location,postcode):
                    this.renderAddress(location,postcode)}</Typography> 
                  </div>
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                  <FavoriteIcon data-testId='favIcon' onClick={(e) => { e.stopPropagation(); this.handleOpenRemove(item.id)}} fontSize="large" htmlColor="#009be2" style={{ borderRadius: '50%', border: '1px solid #00000033', padding: '6px', fontSize: "40px" }} />
                </Grid>
              </Grid>
              <Divider style={{ margin: "20px" }} />
             
            </div>)
          })}
        </DialogContent>
        : this.errorModelMsg()
    )
  }

  connectedModelData(classes) {
    const filteredConnected = this.getFilteredResult(this.state.connectedModule)
    return (
      this.state.connectedModule?.length > 0 ?
        <DialogContent className={`${classesoutercss.DialogContent} scrollbars1`}>
          <Scrollbar style={{ height: '100%', width: '100%', }}>
            <div>
            {this.connectSearchBox(classes)}
            </div> 
          {filteredConnected?.map((ele, index) => (
            <Container >
            <Grid key={index} data-testId={`connectedData-${ele.id}`}>
              <Grid container spacing={2} className="cursor"
              data-testId="connected-user"
              onClick={()=> {
                console.log(localStorage.getItem("acc_id"),"xyz")
                if(localStorage.getItem("acc_id") === ele.attributes?.receipient_id?.toString()){
                  this.props.navigation.navigate("UserProfileView",{id: ele.attributes?.account_id});
                }else {
                  this.props.navigation.navigate("UserProfileView",{id: ele.attributes?.receipient_id});
                }
              }}>
                <Grid item xs={9}>
                  <List className={classes.root}>
                    <ListItem className={classes.rootList}>
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={ele.attributes.profile_picture?.url} style={{ height: "60px", width: "60px", marginRight: "20px" }} />
                      </ListItemAvatar>
                      <ListItemText primary={ele.attributes?.name} />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={3} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                  <div>
                    <Button style={{ fontfamily: 'Rene_Bieder_Milliard_Semibold', color: 'red' }}
                      variant="outlined" size="small" fullWidth
                      className={classesoutercss.selectedcategoryConnectedMenubuttonstyle}
                      data-testId="connected-button"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Connected
                    </Button>

                  </div>
                  
                </Grid>

              </Grid>
            </Grid>
            </Container>
          ))}
          {this.hiddenText(filteredConnected.length)}
          </Scrollbar>
        </DialogContent> : this.errorModelMsg()
    )
  }

  awaitResponseModelContent(classes) {
    const { awaitResponseUsers } = this.state;
    if (this.state.connLoader) {
      return (
        <DialogContent className={classesoutercss.DialogContent}>
          <div
           className={classes.loaderContainer}
          >
            <Loader className={classes.loaderStyle} />
          </div>
        </DialogContent>
      )
    }
    if (awaitResponseUsers?.length > 0) {
      const filteredAwaited = this.getFilteredResult(awaitResponseUsers)
      return (
        <DialogContent   className={`${classesoutercss.DialogContent} scrollbars1`} >
           <Scrollbar style={{ height: '100%', width: '100%' }}>
          {this.connectSearchBox(classes)}
          {filteredAwaited?.map((ele, index) => (
            <Container>
            <Grid key={index} className="cursor" data-testId="await-redirect" onClick={()=> this.props.navigation.navigate("UserProfileView",{id: ele.attributes.account_id})}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <List className={classes.root}>
                    <ListItem className={classes.rootList}>
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={ele?.attributes?.profile_picture.url} style={{ height: "60px", width: "60px", marginRight: "20px" }} />
                      </ListItemAvatar>
                      <ListItemText primary={ele?.attributes.name} secondary={ele.subtitle} />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={3} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }} >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      fullWidth
                      data-testId="cancel-button"
                      onClick={(e) => {e.stopPropagation(); this.cancelConnRequest(ele?.attributes.account_id)}}
                      className={classesoutercss.selectedcategoryAwaitMenubuttonstyle}>

                      <ClearIcon className={classesoutercss.cancelAwaitIcon} />
                    </Button>
                    <Button
                      fullWidth
                      data-testId="accept-button"
                      onClick={(e) => {
                        e.stopPropagation(); 
                        this.acceptConnRequest(ele?.attributes.account_id)
                      }}
                      className={classesoutercss.selectedcategoryAwaitMenubuttonstyle}>

                      <CheckIcon className={classesoutercss.checkAwaitIcon} />

                    </Button>

                  </div>
                </Grid>

              </Grid>
            </Grid>
            </Container>
          ))}
          {this.hiddenText(filteredAwaited?.length)}
          </Scrollbar>
        </DialogContent>
      )
    }
    else if (!this.state.awaitResponseUsers) {
      return this.errorModelMsg();
    }
    else {
      return (
        <DialogContent className={classesoutercss.DialogContent} >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop:150
            }}
          >
            <Loader />
          </div>
        </DialogContent>
      )
    }

  }

  profileModels(classes, breakPoints) {
    return (
      <Container maxWidth="xl" style={{ marginBottom: this.state.value === 3 ? "0px" : "30%" }}>
        <Wrapper
          fullScreen={isMobile}
          open={this.state.openstatusmodal}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: { borderRadius: 16, width: 600, minHeight: 600 }
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
            <div></div>
            <div><DialogTitle id="responsive-dialog-title" >
              <Typography variant="h6"
                style={{ fontSize: 26, fontFamily: 'Rene_Bieder_Milliard_Medium' }}>
                {this.state.selectedCategoryStatus}
              </Typography>
            </DialogTitle></div>
            <div style={{ marginRight: -28 }}><CancelOutlinedIcon data-testId='closeModal'
             onClick={() => {this.handleClose();
              localStorage.setItem("user_selected_date",'false');
             console.log("Update date",localStorage.getItem("user_selected_date"));
             } }
             style={{ cursor: "pointer" }} /></div>
          </div>

          {/* connections category status  */}
          {this.connectionCatogaryStatus(breakPoints)}


          {/* connections category status end  */}
          <style>
            {`.ScrollbarsCustom-Track {
                        width : 2px !important;
                        margin-left : 10px !important

                      }`}
          </style>
          <div >
            {(this.state.selectedCategoryStatus === "My Connections" || this.state.selectedCategoryStatus==="Connections") && this.state.selectedCategoryMenu === 'Connect' && this.connectionModelContent(classes)}
            {(this.state.selectedCategoryStatus === "My Connections" || this.state.selectedCategoryStatus==="Connections") && this.state.selectedCategoryMenu === 'Connected' && this.connectedModelContent(classes)}
            {(this.state.selectedCategoryStatus === "My Connections" || this.state.selectedCategoryStatus==="Connections") && this.state.selectedCategoryMenu === 'Pending requests' && this.PendingRequestModelContent(classes)}
            {(this.state.selectedCategoryStatus === "My Connections" || this.state.selectedCategoryStatus==="Connections") && this.state.selectedCategoryMenu === 'Awaiting response' && this.awaitResponseModelContent(classes)}

            {this.state.selectedCategoryStatus === "Following" && this.followerCatogaryModelStatus(classes)}
            {this.state.selectedCategoryStatus === "Registered" && this.registeredCatogaryModelStatus(classes)}
            {this.state.selectedCategoryStatus === "My Favourites" && this.favouritesContent(classes)}
            {this.state.selectedCategoryStatus === "My Availability" && this.AvailabilityContent(classes)}
            {this.state.selectedCategoryStatus === "My Task Preferences" && this.TaskPreferencesContent(classes)}
          </div>
        </Wrapper>
      </Container>
    )
  }

  errorModelMsg() {
    return (
      <DialogContent className={classesoutercss.DialogContent}><p style={{ textAlign: "center" ,marginTop:150 }}>
        No data available
        </p></DialogContent>
    )

  }

  getClassForPoints = () => {
    const { medal } = this.state;
    if(medal === "silver") {
      return classesoutercss.silverClass
    } else if (medal === "gold") {
      return classesoutercss.goldClass
    } else {
      return classesoutercss.bronzeClass
    }
  }
  getConnectionCount=()=>{
    return this.state.connectedModule?.length?this.state.connectedModule?.length:0
  }

  modelNamesAndViews() {
    const { currentPoints } = this.state;
    const  registered=this.state.profileUserData?.attributes?.registered ||0
    const following =this.state.profileUserData?.attributes?.following|| 0
    return (
      constants.statusDetail.map((ele) => (
        ele.name === 'Attended' ?
          <div className={classesoutercss.innerconnection} key={ele.id}
            data-testId={ele.name}
            onClick={() => this.handleClickOpenCategoryDilog(ele.name)}>
           <div
            className={`${classesoutercss.bgContainer} 
              ${this.getClassForPoints()}`}
            onClick={() => this.props.navigation.navigate("Gamification")}
          >
              <label className={classesoutercss.centeredText}>{currentPoints}</label>
            </div>
          </div> :
          <div className={classesoutercss.innerconnection} key={ele.id} data-testId={ele.name} onClick={() => {
            this.handleClickOpenCategoryDilog(ele.name);
          }}>
            <div
              className={classesoutercss.connectionNumbers}
              style={{ ...FONTS.h1 }}
            >
              {ele.name === 'Registered' && registered}
              {ele.name === 'Following' && following}
              {ele.name === 'Connections' && this.getConnectionCount()}
            </div>
            <div>
              <Button
                style={{
                  fontSize: '18px',
                  fontWeight: '400', textTransform: "capitalize", letterSpacing: 'initial'
                }}
                className={classesoutercss.connectionName}
              >
                {
                  ele.name
                }
              </Button>
            </div>
          </div>
      ))
    )
  }

  tabs(classes, value) {
       localStorage.setItem("user_selected_date","false");
        localStorage.setItem("all_day","false");
    return (
      <Tabs
        centered
        value={value}
        onChange={this.handleChange}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
        }}
        data-testId="tabs"
      >
        <Tab
          disableRipple
          value={1}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
          style={this.state.value === 1 ? { ...FONTS.body1, color: 'black' } : { ...FONTS.body1 }}
          label="My Pledges"
        />
        <Tab
          value={2}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
          style={this.state.value === 2 ? { ...FONTS.body1, color: 'black' } : { ...FONTS.body1 }}
          label="Needs"
        />
        <Tab
          value={3}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
          style={this.state.value === 3 ? { ...FONTS.body1, color: 'black' } : { ...FONTS.body1 }}
          label="Activity log"
        />
      </Tabs>
    )
  }

  needsImg(item) {
    return (
      item.attributes.need_photo === null ? constants.carosel_constants[0].image : item.attributes.need_photo.url
    )
  }

  finalConnections = (item: any) => {
    const { connections } = this.state;
    return connections?.filter((item2: any) =>
      item.attributes.friend_ids.length > 0 && item.attributes.friend_ids.some((result: string) => item2.attributes.receipient_id.toString() === result)
    );
  }

  handleCardClick = (id: any, type: string, event_type: string, account_id?: undefined | number | string) => {
    if (type === "non_profit_entity" || type === "BxBlockEvents::NonProfitEvent") {
      if (event_type === "project_funding") {
        this.props.navigation.navigate("FundCampaign", { id: id });
      } else if (event_type === "need") {
        this.props.navigation.navigate("EventsDetails", { id: id });
      } else if (event_type === "activity") {
        this.props.navigation.navigate("EventInfo", { id: id });
      }
    } else if(localStorage.getItem("acc_id") === account_id?.toString()){
      this.props.navigation.navigate("PublishNeed", { id: id });
    } else {
      this.props.navigation.navigate("EventsDetails", { id: "r1-" + id });
    }
  };

  renderAvatars = (people:any, classes:any) => {
    return (
      <AvatarGroup className={`AvatarGroupMyNeed ${classesoutercss.AvatarGroup}`} max={4}>
      {people?.map((personObject: any) => {
        return (
          personObject.attributes ? 
          <Avatar key={personObject.id} className={`singleAvatar ${classes.multipleAvatarStyle}`} src={personObject.attributes.profile_picture.url} alt="Remy Sharp"  >
            {personObject.attributes.name?.charAt(0).toUpperCase()}
          </Avatar> 
          : <Avatar key={personObject.account_id} className={`singleAvatar ${classes.multipleAvatarStyle}`} src={personObject.profile_picture.url} alt="Remy Sharp"  >
            {personObject.full_name.charAt(0).toUpperCase()}
          </Avatar>
        )
      })}
    </AvatarGroup>
    )
  }

  getBackgroundColor = (needType:string) => {
    if(needType === 'need') {
      return COLORS.green
    } else if (needType === "project_funding") {
      return COLORS.blue
    } else {
      return  "#B4028C"
    }
  }

  getEventType = (needType:string) => {
    if(needType === 'need') {
      return 'Volunter'
    } else if (needType === "project_funding") {
      return 'Donate'
    } else {
      return "Attend"
    }
  }
  checkFavCondition = (nonProfitEventId:any,item:any) => {
    this.setState({ selectedId: nonProfitEventId ??  item.id })
    this.addPledgeFavourite(nonProfitEventId ?? item.id, nonProfitEventId ? "R2" : "R1")
  }
  renderAddress = (address:string,postalCode:string) => {
    return postalCode?postalCode.substring(0, postalCode.indexOf(" "))||postalCode:
    address.slice(address.lastIndexOf(",") + 1).trim();
  }
  renderLocation=(location:string, postcode:string)=> {
    if(postcode && !location.includes(postcode)){
     location += `, ${postcode}`; 
   }
  return location;
 }
  renderR2data = (item:any, classes: any) => {
    let title, location,post_code, eventImage, dateNtime, eventType, userName, userImage, people, isItFav, nonProfitEventId, isDBS;
    if(item.type === "need") {
      title = item.attributes.title_of_need;
      location = item.attributes.location;
      post_code = item.attributes.post_code;
      eventImage = item.attributes.need_photo.url || constants.carosel_constants[0].image;
      eventType = item.attributes.event; 
      userName = item.attributes.user_name ;
      userImage = item.attributes.user_image?.url || constants.carosel_constants[0].image;
      dateNtime = `${moment(item.attributes.start_date).format('DD MMM YYYY')} , ${moment(item.attributes.start_time).format('HH:mm a')}` 
      people = this.finalConnections(item);
      isItFav = item.attributes.is_favourite;
      isDBS =  false;
      nonProfitEventId = null;
    } else if (item.type === "non_profit_event") {
      title = item.attributes.event_name;
      location = item.attributes.location;
      post_code = item.attributes.post_code;
      eventImage = item.attributes.event_image?.url || constants.carosel_constants[0].image
      dateNtime = `${moment(item.attributes.from_date).format('DD MMM YYYY')} , ${moment(item.attributes.start_time).format('HH:mm a')}` 
      eventType = item.attributes.event_type;
      isDBS =  item.attributes.dbs_registration;
      userName = item.attributes.organisation_name;
      userImage = item.attributes.organisation_image_url?.url || constants.carosel_constants[0].image;
      people =  item.attributes.attended;
      isItFav = item.attributes.favourite_id;
      nonProfitEventId = item.id;
    }

    const placeholderImg=localStorage.getItem('placeholderImg')||'';
    return (
      <div 
      key={item.id} 
      className={`${classes.flex} ${classes.eventCard}`} 
      data-test-id={`pledgeCard`}
      onClick={() =>
        this.handleCardClick(item.id, item.attributes.type, item.attributes.event_type)
      }
      >
        <div className={classes.event_img_container}>
          {/* <img
            src={eventImage}
            alt="event image"
            className={classes.event_main_img}
          /> */}
          <ImageLoader loaderStyle={{textAlign:"center",marginTop:isMobile?'50%':'20%',marginLeft:"36%"}}  classes={classes.event_main_img} imageUrl={eventImage??placeholderImg} alt="complex" />
<HideContainer hideWhen={isMobile}>
          {isItFav ?
            <FavoriteIcon
              data-test-id="favoriteBtn"
              className={classes.FavoriteIcon}
              onClick={(event) => {
                event.stopPropagation()
                this.setState({ selectedId: item.id })
                this.removePledgeFavourite(item)
              }}
            /> :
            <FavoriteBlackBorderIcon
              data-test-id="unfavoriteBtn"
              className={classes.FavoriteBorderIcon}
              onClick={(event) => {
                event.stopPropagation()
                this.checkFavCondition(nonProfitEventId, item)
               }}
            />
          }
          </HideContainer>
          {isDBS && <span className={classes.btn_dbs}>DBS</span>}
        </div>

        <Box className={classes.mobileLayoutContent}>
          {/* button  */}
          <div style={{ paddingLeft: 16 }}>
            <div
              className={classes.layOutbuttons}
              style={{ background: this.getBackgroundColor(eventType) }}
            >
              {this.getEventType(eventType)}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingLeft: 13,
            }}
          >
            <div className={classes.avatarImgcontainer} style={{ marginLeft:isMobile? '2%': '0%', fontWeight:isMobile? '1000': ""}}>
              <Typography
                variant="h6"
                className={classes.multiLineEllipsis}
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 2,
                  letterSpacing: -0.3499999940395355,
                  textAlign: "left",
                }}
              >
                {title}
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingLeft: 13,
            }}
          >
            <div className={classes.avatarImgcontainer} style={{ marginLeft:isMobile? '2%': '0%'}}>
              <Typography
                variant="body1"
                className={classes.multiLineEllipsis}
                style={{
                  width: 201,
                  height: 16,
                  fontSize: 12,
                  lineHeight: 1,
                  letterSpacing: -0.35,
                  color: "#000000",
                }}
              >
                {userName}
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingLeft: 13,
            }}
          >
            <div className={classes.avatarImgcontainer} style={{ marginLeft:isMobile? '3.5%': '0%'}}>
              <img
                src={icons.calender}
                alt="calendar"
                className={classes.avatarImg}
              />
            </div>
            <span className={classes.eventdate_loc}>
              {dateNtime}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingLeft: 13,
            }}
          >
            <div className={classes.avatarImgcontainer} style={{ marginLeft:isMobile? '3.5%': '0%'}}>
              <img
                src={icons.locationred}
                alt="location"
                className={classes.avatarImg}
              />
            </div>
            <span className={classes.eventdate_loc}>
              {this.renderLocation(location,post_code)}
            </span>
          </div>
        </Box>
        {/* </Hidden> */}

        <div
          className={classes.desktopLayoutContent}
          style={{ width: "60%" }}
        >
          <div className={classes.eventcontent}>
            <div
              style={{
                marginTop: '10px',
                marginLeft: '10px',
              }}
            >
              <Typography
                style={{
                  fontFamily: "Rene_Bieder_Milliard_Bold",
                  fontSize: "18px",
                  fontWeight: 400,
                  textAlign: "left"
                }}
              >
                {title}
              </Typography>
            </div>
            <div className={classes.cardDescription}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 8,
                }}
              >
                <img
                  style={{
                    height: "25px",
                    width: "25px",
                    borderRadius: 50,
                  }}
                  src={userImage}
                />
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ marginLeft: 9, marginTop: 3, fontSize: 16}}
                >
                 {userName}
                </Typography>
              </div>
              <div
                className={classes.layOutbuttons}
                style={{ background: this.getBackgroundColor(eventType) }}
              >
                {this.getEventType(eventType)}
              </div>
            </div>
            <div className={classes.cardDescription}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <img
                    src={icons.calender}
                    alt=""
                    className={classes.avatarImg}
                    style={{ marginTop: 3 }}
                  />
                </div>
                <span className="username">
                {dateNtime}
                </span>
              </div>
            </div>
            <div className={classes.cardDescriptionAvatar_location}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingLeft: 13,
                }}
              >
                <div className={classes.avatarImgcontainer}>
                  <img
                    src={icons.locationred}
                    alt=""
                    style={{ marginTop: 3 }}
                    className={classes.avatarImg}
                  />
                </div>
                <span className="username">
                {this.renderLocation(location,post_code)}
                </span>
              </div>
              <div>
              {this.renderAvatars(people, classes)}
             
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  pledgesFrontTabData(classes) {
    return (
      <Container>
        {
          this.state.pledgesTabData.map((item: any) => (
            item.attributes.need_id ?
            this.renderR2data(item.attributes.need.data, classes) 
            : this.renderR2data(item.attributes.non_profit_event.data, classes)
          ))}
      </Container>
    )
  }

  needsFrontTabData(classes) {
    return (
      <Container>
        {this.state.typeOfEvent  && <Redirect to={`${this.state.typeOfEvent}`} />}
               { this.state.needsTabData.map((item) => {
                const pledgeId = item.attributes.need_my_pledge_id ?? item.attributes.my_pledge_id;
           return <div key={item.id} className={`${classes.flex} ${classes.eventCard}`} 
            onClick={() => {
              this.props.navigation.navigate("EventsDetails", { id: "r1-" + item.id });
              this.props.setSelectedEvents(item)
            }
            } data-testId={`needData-${item.id}`}>
              <div className={classes.event_img_container}>
               
                <ImageLoader loaderStyle={{textAlign:"center",marginTop:isMobile?'50%':'20%',marginLeft:"36%"}}  classes={classes.event_main_img} imageUrl={this.needsImg(item)} alt="complex" />

                <Hidden xsDown implementation="css">
                {item.attributes.is_favourite ?
            <FavoriteIcon
              data-test-id="needfavoriteBtn"
              className={classes.FavoriteIcon}
              onClick={(event) => {
                event.stopPropagation();
                this.removeFromFav(item);
              }}
            /> :
            <FavoriteBlackBorderIcon
              data-test-id="needunfavoriteBtn"
              className={classes.FavoriteBorderIcon}
              onClick={(event) => {
                event.stopPropagation();
                this.addToFavr(event,item);
               }}
            />
          }
                </Hidden>
              </div>

              <Box className={classes.mobileLayoutContent}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    paddingLeft: 13,
                  }}
                >
                  <div className={classes.avatarImgcontainer}>
                    <Typography
                      variant="h6"
                      className={classes.multiLineEllipsis}
                      style={{
                        fontSize: 12,
                        fontWeight: 500,
                        lineHeight: 2,
                        letterSpacing: -0.3499999940395355,
                        textAlign: "left",
                      }}
                    >
                      {item.attributes.title_of_need}
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    paddingLeft: 13,
                  }}
                >
                  <div className={classes.avatarImgcontainer}>
                    <Typography
                      variant="body1"
                      className={classes.multiLineEllipsis}
                      style={{
                        width: 201,
                        height: 16,
                        fontSize: 14,
                        lineHeight: 1,
                        letterSpacing: -0.35,
                        color: "#000000",
                      }}
                    >
                      {this.handleDescriptionText(item.attributes.description, 50)}
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    paddingLeft: 13,
                  }}
                >
                  <div className={classes.avatarImgcontainer}>
                    <img
                      src={icons.calender}
                      alt=""
                      className={classes.avatarImg}
                    />
                  </div>
                  <span className={classes.eventdate_loc}>
                    {item.attributes.start_date === null || item.attributes.start_time === null ? 
                     '---' : `${moment(item.attributes.start_date).format('Do MMMM YYYY')},${moment(item.attributes.start_time).format('h:mm:ss a')}`
                    }
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    paddingLeft: 13,
                  }}
                >
                  <div className={classes.avatarImgcontainer}>
                    <img
                      src={icons.locationred}
                      alt=""
                      className={classes.avatarImg}
                    />
                  </div>
                  <span className={classes.eventdate_loc}>
                  {pledgeId && pledgeId.length > 0 && pledgeId[0]?.status === "Approved"
                  ? this.renderLocation(item.attributes.location, item.attributes.post_code)
                  : this.renderAddress(item.attributes.location, item.attributes.post_code)}
                  </span>
                </div>
              </Box>
              {/* </Hidden> */}

              <div
                className={classes.desktopLayoutContent}
                style={{ width: "60%" }}
              >
                <div className={classes.eventcontent}>
                  <div
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px'
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Rene_Bieder_Milliard_Bold",
                        fontSize: "18px",
                        fontWeight: 400,
                        textAlign: "left",
                        marginTop: '10px !important',
                        marginLeft: '10px !important',
                      }}
                    >
                      {item.attributes.title_of_need }
                    </Typography>
                  </div>

                  <div className={classes.cardDescription}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 8,
                      }}
                    >
                      <img
                        style={{
                          height: "25px",
                          width: "25px",
                          borderRadius: 50,
                        }}
                        src={item.attributes.user_image.url}
                      />
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ marginLeft: 9, marginTop: 3 }}
                      >
                        {this.handleDescriptionText(item.attributes.description, 50)}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.cardDescription}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        paddingLeft: 13,
                      }}
                    >
                      <div className={classes.avatarImgcontainer}>
                        <img
                          src={icons.calender}
                          alt=""
                          className={classes.avatarImg}
                          style={{ marginTop: 3 }}
                        />
                      </div>
                      <span className="username">
                        {item.attributes.start_date === null || item.attributes.start_time === null ? '---' 
                        : `${moment(item.attributes.start_date).format('Do MMMM YYYY')},${moment(item.attributes.start_time).format('h:mm:ss a')}`}
                      </span>
                    </div>
                  </div>
                  <div className={classes.cardDescriptionAvatar_location}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        paddingLeft: 13,
                      }}
                    >
                      <div className={classes.avatarImgcontainer}>
                        <img
                          src={icons.locationred}
                          alt=""
                          style={{ marginTop: 3 }}
                          className={classes.avatarImg}
                        />
                      </div>
                      <span className="username">
                      {pledgeId && pledgeId.length > 0 && pledgeId[0]?.status === "Approved"
                      ? this.renderLocation(item.attributes.location, item.attributes.post_code)
                      : this.renderAddress(item.attributes.location, item.attributes.post_code)}
                      </span>
                    </div>
                    <div style={{ width: 150 }}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        })}
      </Container>
    )
  }

  errorDiv(text) {
    return (
      <Container style={{overflow: 'none'}}fixed root maxWidth="xl">
      <Paper className={`${classesOuter.eventPaperCard}`} 
      style={{ display: "flex",
       justifyContent: "center",
      alignItems: "center", 
      textAlign: "center",
      margin: "auto",
      padding: 24,
      marginBottom: 30,
      height: 120,
      width: "100%",
      background: '#FFFFFF',
      border: '1px solid #C5C5C5',
      borderRadius: 18, }} elevation={0}>
        No {text} available
      </Paper>
    </Container>
    )
  }

  pledgeTabSelected(classes) {
    if(this.state.pledgeLoading){
      return ''
    }
  else  if (this.state.pledgesTabData?.length > 0 && this.state.pledgesTabData !== undefined) {
      return this.pledgesFrontTabData(classes);
    } else {
      return this.errorDiv('pledges');
    }
  }


  needTabSelected(classes) {
    if(this.state.pledgeLoading){
      return ''
    }
    else{
    return (
      this.state.needsTabData.length > 0 && this.state.needsTabData !== undefined ?
        this.needsFrontTabData(classes) : this.errorDiv('Needs')
    )
    }
  }
  renderUserConnectionsWeb(classes: any) {
    const sortedConnections=this.state.userconnections?.sort((a,b)=>{
      let first=a?.attributes?.name || a?.full_name
      let second=b?.attributes?.name || b?.full_name
      return first.localeCompare(second)
    })
    const filteredconnections = this.getFilteredResult(sortedConnections)
    return this.state.userconnections?.length > 0 ? (
      <div className={classesoutercss.connectionContent} >
        <Scrollbar style={{ height: "100%", width: "100%" }}>
        {this.connectSearchBox(classes)}
          <div>
            {filteredconnections?.map((ele: any, index: any) => (
              <Container>
              <div key={index} className={`${classesoutercss.followingContainer} cursor`} data-testId="connect-redirect" onClick={()=> this.props.navigation.navigate("UserProfileView",{id: ele.id})}>
                <List className={classes.root}>
                  <ListItem className={classes.rootList}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          ele?.profile_picture?.url ||
                          ele?.attributes?.profile_picture?.url
                        }
                        style={{
                          height: "60px",
                          width: "60px",
                          marginRight: "10px",
                        }}
                        data-testId='avatarClick'
                      />
                    </ListItemAvatar>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          style={{
                            ...primaryText,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {ele?.full_name || ele?.attributes.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>

                <div>{this.renderButtonWeb(ele?.attributes?.status, ele?.id)}</div>
              </div>
              </Container>
            ))}
          </div>
          {this.hiddenText(filteredconnections?.length)}
        </Scrollbar>
      </div>
    ) : (
      this.renderNoData()
    );
  }
  renderNoData() {
    return (
      <div className={classesoutercss.DialogContent}>
        <p style={{ textAlign: "center", marginTop: 150 }}>No data available</p>
      </div>
    );
  }
  renderButtonWeb = (type: any, id: any) => {
    if (type == "pending") {
      return (
        <Button
          style={{width: 100 ,fontFamily: "Rene_Bieder_Milliard_Semibold",}}
          variant="outlined"
          className={classesoutercss.selectedcategoryPendingMenubuttonstyle}
        >
          Pending{" "}
        </Button>
      );
    } else if (type == "approved") {
      return (
        <Button
          style={{
            width: 100,
          }}
          variant="outlined"
          className={classesoutercss.selectedcategoryConnectedMenubuttonstyle}
        >
          Connected
        </Button>
      );
    } else {
      return (
        <Button
          style={{  width: 100 }}
          variant="outlined"
          className={classesoutercss.selectedcategoryConnectMenubuttonstyle}
          data-testId="addConnection"
          onClick={() => this.addConnectionRequest(id)}
        >
          Connect
        </Button>
      );
    }
  };

  renderTabs = (classes:any) => {
    if(this.state.value === 1) {
      return this.pledgeTabSelected(classes) 
    } else if(this.state.value === 2) {
      return this.needTabSelected(classes)
    } else {
      return this.renderActivitylog()
    }
  }
  hiddenText=(length:any)=>{
    return ( <HideContainer hideWhen={length}>
      <Typography style={{ ...FAMILYFONTS.family2, fontSize: 16,textAlign:"center",marginTop:10 }}>
          {'There are no connections present'}
      </Typography>
  </HideContainer>)
  }
  
  allmixedconnections(classes) {
    return (
      this.state.userloader ?
        <DialogContent className={classesoutercss.DialogContent}>
          <div className={classes.loaderContainer}
          >
            <Loader className={classes.loaderStyle}/>
          </div>
        </DialogContent>
        : this.renderUserConnectionsWeb(classes)
    )
  }


  // Customizable Area End


  render() {
    // Customizable Area Start
    const { classes, theme }: any = this.props;
    const { value } = this.state;
    const breakPoints = [
      { width: 1, itemsToShow: 3, itemsToScroll: 1 },
      { width: 550, itemsToShow: 3, itemsToScroll: 1 },
      { width: 768, itemsToShow: 3, itemsToScroll: 1 },
      { width: 1200, itemsToShow: 3, itemsToScroll: 1 }
    ];
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.eventType && <Redirect to={`${this.state.eventType}`} />}
        {this.state.UserProfileUrl && <Redirect to={`${this.state.UserProfileUrl}`} />}
        <style>
          {`.rec.rec-slider-container {
            box-shadow: none !important;
            border-radius: 18px;
            background-color: white;
            margin-left:25px !important;
            }
            .rec-item-wrapper{
              width:auto !important;
              margin-left:15px;
            }
           
       `
          }
        </style>
        <div
          className={`${classesoutercss.userprofileMain} ${classes.contentWidth}`}
          style={{ backgroundColor: COLORS.white,
          }}
        >
          {this.state.loader ?
            <div className={""}>
              <div style={{marginTop:isMobile? '300px':'10',marginRight:10}}>
                <InitiateSpinner />
              </div>
            </div>
            :
            <>
              {this.searchBarAndNotification(classes)}

              {!this.state.editProfileView && (
                <div className={classes.root}>
                  <div
                    style={{
                      marginTop: "1rem",
                      border: "1px solid rgb(224 224 224)",
                      backgroundColor: "#d3d3d3",
                    }}
                  />

                  {this.tabs(classes, value)}
                  <div
                    style={{
                      border: "1px solid rgb(224 224 224)",
                      backgroundColor: "#d3d3d3",
                    }}
                  />
                  <div style={{
                    padding: '10px', height: 'auto',
                    overflow: 'auto',
                  }}>
                    <div style={{display:"flex",justifyContent:"center"}}>
                    {(this.state.pledgeLoading && this.state.value !== 3)? <Loader style={{ color: "#009be2",marginTop:150 }}/>:""}
                    </div>

                    {this.renderTabs(classes)}
                   
                  </div>

                  {this.profileModels(classes, breakPoints)}

                </div>)}
            </>
          }
          {this.state.removePopup &&
            <Dialog open={this.state.removePopup} onClose={this.handleCloseRemove} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', }}
              PaperProps={{ style: { width: '370px', borderRadius: "20px", padding: "20px", } }}>
              <DialogTitle><Typography style={{ fontSize: "16px", fontWeight: "800", marginBottom: "-5px" }}>Remove from my favourites list?</Typography></DialogTitle>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Button data-testId="popupConfirm" variant="contained" style={{ textTransform: "none", borderRadius: '10px', backgroundColor: "#019BE1", color: "white", marginBottom: "13px", width: "92%", height: "50px" }} onClick={() => this.handleRemove()}>
                  Confirm
                </Button>
                <Button data-testId="popupCancel" variant="outlined" style={{ textTransform: "none", borderRadius: '10px', color: "grey", width: "92%", height: "50px" }} onClick={() => this.handleCloseRemove()}>
                  Cancel
                </Button>
              </div>
            </Dialog>}
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


UserProfileBasicBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Customizable Area Start
const Wrapper = styled(Dialog)({
  "& .cursor" :{
    cursor: "pointer"
  }
});

const GridWrapper = styled(Container)({
  "& .cursor" :{
    cursor: "pointer"
  }, 
  "& .imageWrap" : {
    width: "35%",
    justifyContent:'center',
     display:'flex' ,
     "@media (max-width: 600px)": {
      width:'20%',
      paddingLeft: "30px"
    },
  },
  "& .userProfile": {
    marginTop:'1%',
    display:'flex', 
    width:'45%',
    marginLeft:'0',
    justifyContent: "space-around",
    "@media (max-width: 600px)": {
      width:'70%',
      justifyContent: "space-between"
    },
  },
  "& .nameaddress": {
    marginLeft: "-35%",
    "@media (max-width: 600px)": {
       marginLeft: "30px",
      },
  }, 
  "& .companyLogo" : {
    "@media (max-width: 600px)": {
      display: "block !important",
        alignItems: "center",
        width: "35% !important",
        justifyContent: "space-around",
        marginLeft: "5%", 
     },
  }
});

function mapStateToProps(state: any) {
  return {
    selectedevents: state.eventsReducer.selectedEvents,

  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedEvents: (selectedTab: any) => {
      return dispatch(setSelectedEvents(selectedTab))
    }
  }
}

export { UserProfileBasicBlock };
// Customizable Area End
// Customizable Area Start
export {mapDispatchToProps, mapStateToProps }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any, { withTheme: true })(UserProfileBasicBlock as any));
// Customizable Area End