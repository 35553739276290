// Customizable Area Start
import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { RootState } from "../../../framework/src/redux/stores";
import { InitiateButton } from "../../../components/src/design-system/button";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { InitiateLabel } from "../../../components/src/design-system/label";
import { useDBSTypes, useSaveDbsTypes, useUserProfile, useMySelectedDBS } from "../../../components/src/api/hooks/user.hooks";
import { useNavigate } from "../../../components/src/utils/router-context";
import { InitiateTextField } from '../../../components/src/design-system/text-field';
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { RegisterationMobileHeader } from './RegisterationMobileHeader';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../../../components/src/api/keys/user.keys';
import { InitiateSpinner } from '../../../components/src/design-system/spinner';
import { InitiateCheckbox } from '../../../components/src/design-system/checkbox';
import { InitiateAlert } from '../../../components/src/design-system/alert';

const useStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'min(615px, 80vh)',
    [theme.breakpoints.down('sm')]: {
      height: '90vh',
      paddingRight:'20px',
      paddingLeft:'20px',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  titleText: {
    fontSize: '1.8rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
    textAlign: 'center',
  },
  submitButton: {
    fontSize: '1.3rem',

    
  },
  skipButton: {
    fontSize: '1.3rem',
  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginBottom:'5%'
    },
  },
  dbsTypesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  helperText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#87888f',
    fontSize: '1rem',
    position: 'absolute',
    top: '100%',
    right: '0',
  },
}));


export function DBSForm() {

  const navigate = useNavigate();

  const classes = useStyles();

  const queryClient = useQueryClient();


  const signupFormState = useSelector((state: RootState) => state.signupForm);
  const { accountId } = signupFormState;
  const { mutate: saveDbsType, isLoading, error } = useSaveDbsTypes({ accountId: parseInt(accountId) });
  const apiError = String(error?.errors ?? '');

  const selectedDBsQuery = useMySelectedDBS({ accountId: parseInt(accountId) });
  const selectedDbs = selectedDBsQuery?.data?.data ?? [];

  const types = new URLSearchParams(document.location.search).get("key");
  const disable = types === "DBSdetailsEdit" ;
  const accountQuery = useUserProfile({ accountId: parseInt(accountId) });
  const apiProfile = accountQuery?.data?.data?.attributes;

  const [dbsNumber, setDbsNumber] = useState('');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const isMobile = useIsMobileScreen();

  const dbsTypesQuery = useDBSTypes();
  const dbsData = dbsTypesQuery?.data?.data ?? [];
  const dbsTypesQueryLoading = dbsTypesQuery.isLoading;

  useEffect(() => {
    if (selectedDbs.length)
      setSelectedIds(selectedDbs.map(item => item.id));
  }, [selectedDbs]);

  useEffect(() => {
    if (apiProfile?.dbs_number && apiProfile?.dbs_number!=='null' && apiProfile?.dbs_number!=='undefined')
      setDbsNumber(apiProfile?.dbs_number ?? '');
  }, [apiProfile]);


  function skipHandler() {
    navigate('/MyNonProfitsWeb');
  }

  function submitHandler() {
    saveDbsType({
      accountId: parseInt(accountId),
      dbsNumber: parseInt(dbsNumber),
      dbsTypesIds: selectedIds.map(x => parseInt(x)),
    }, {
      onSuccess() {

        queryClient.invalidateQueries(userKeys.fetchProfile({ accountId: parseInt(accountId) }));
        queryClient.invalidateQueries(userKeys.fetchMySelectedDBs({ accountId: parseInt(accountId) }));

        navigate('/MyNonProfitsWeb');
      },
    });
  }

  function editNavigate() {
    saveDbsType({
      accountId: parseInt(accountId),
      dbsNumber: parseInt(dbsNumber),
      dbsTypesIds: selectedIds.map(x => parseInt(x)),
    }, {
      onSuccess() {

        queryClient.invalidateQueries(userKeys.fetchProfile({ accountId: parseInt(accountId) }));
        queryClient.invalidateQueries(userKeys.fetchMySelectedDBs({ accountId: parseInt(accountId) }));

        navigate('/userEditProfile');
      },
    });
  }



  return (
    <>
      <div className={classes.formContentRoot}>



        <div className={classes.formContent}>



          <HideContainer hideWhen={isMobile}>
            <Typography
              variant="h1"
              color="primary"
              className={classes.titleText}
            >
              {'DBS details'}
            </Typography>
            <VerticalSpacer value={40} />
          </HideContainer>
          <HideContainer hideWhen={!isMobile}>
            <VerticalSpacer value={10} />
          </HideContainer>

          <InitiateTextField
            label="DBS Number"
            name="dbsNumber"
            fullWidth
            variant="outlined"
            value={dbsNumber}
            onChange={(event) => {
              const numericValue = event.target.value.replace(/[^0-9]/g, '');
              setDbsNumber(numericValue);
          
            }}
            helperText={"(Optional)"}
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
          />

          <VerticalSpacer value={50} />

          <InitiateLabel>
            {'Select DBS clearance type'}
          </InitiateLabel>

          <VerticalSpacer value={10} />

          <HideContainer hideWhen={!dbsTypesQueryLoading}>
            <InitiateSpinner />
          </HideContainer>

          <div className={classes.dbsTypesContainer}>
            {
              dbsData?.map(({ id, attributes: { dbs_type_name: dbsType } }) =>
                <InitiateCheckbox
                  key={id}
                  checked={selectedIds.includes(id)}
                  onChange={(event) => {
                    const newValue = event.target.checked;
                    if (newValue){
                      setSelectedIds([id]);
                    }                   
                    else
                      setSelectedIds(state => state.filter(_id => _id != id));
                  }}
                  name={dbsType}
                  label={dbsType}
                />
              )
            }
          </div>

          <HideContainer hideWhen={!apiError}>
            <InitiateAlert>
              {apiError}
            </InitiateAlert>
          </HideContainer>

          <VerticalSpacer value={50} />

        </div>

        <div className={classes.submitButtonsContainer}>
          <InitiateButton
            loading={isLoading}
            disabled={isLoading || !selectedIds.length}
            className={classes.submitButton}
            onClick={disable? editNavigate :submitHandler}
            fullWidth
            color="primary"
            variant="contained"
            size="large">
            Continue
          </InitiateButton>
          <HideContainer hideWhen={isMobile}>
            <Button
              className={classes.skipButton}
              fullWidth
              color="primary"
              variant="text"
              onClick={skipHandler}
              size="large">
              {'Skip'}
            </Button>
          </HideContainer>
        </div>


      </div>

      <RegisterationMobileHeader
        title="DBS details"
        hideSkipButton={disable}
        onSkip={skipHandler}
      />
    </>
  );
}
// Customizable Area End

