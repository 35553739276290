import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast} from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?: React.ReactNode | React.ReactNode[];
  classes: any;
  adminSelectedTab: any;
  theme?: any;
  history?: any;
  location?: any;
  match?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  startSurvey: boolean;
  radioValue: string;
  liveSurveyData: any;
  completedSurveyData: any
  itemsToShow: number;
  itemToShow: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSurveysApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      startSurvey: false,
      radioValue: "",
      liveSurveyData: [],
      completedSurveyData: [],
      itemsToShow: 3,
      itemToShow: 3,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getSurveysApiCallId) {
      this.handleSurveyDataResponse(responseJson)
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getSurveysList()
  }

  handleSurveyDataResponse = (responseJson: any) => {
    if (responseJson?.error || responseJson?.errors) {
      const Error = responseJson?.error || responseJson?.errors
      toast.error(`${Error}`);
      this.setState({ liveSurveyData: [], completedSurveyData: [] })
    } else {
      this.setState({ liveSurveyData: responseJson?.data?.live, completedSurveyData: responseJson?.data?.completed })
    }
  }
  
  getSurveysList = async () => {
    const header = {
      type: 'user_entity',
      token: localStorage.getItem('token')
    }

    this.getSurveysApiCallId = await apiCall({
      endPoint: configJSON.getSurveysApiEndPoint,
      method: "GET",
      contentType: "application/json",
      header,
    });
  }

 


  handleShowMore = () => {
    this.setState((prevState) => ({
      itemsToShow: prevState.itemsToShow + 3, // Show 3 more items on each click
    }));
  };

  handleItemShowMore = () => {
    this.setState((prevState) => ({
      itemToShow: prevState.itemToShow + 3, // Show 3 more items on each click
    }));
  };

  navigateToSurveyPage = (item: any) => {
    this.props.history.push({
      pathname: "/UserSurveys",
      state: {
        surveyData : item
      }
    });
  }

  viewResultPage = (id: number) => {
    this.props.navigation.navigate("SurveyUserAnswers", { id })
  }
  // Customizable Area End
}
