// @ts-nocheck
import React, { forwardRef } from "react";
// Customizable Area Start
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "react-datepicker/dist/react-datepicker.css";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import {
  Card,
  CardContent,
  Dialog,
  InputAdornment,
  FormGroup,
  Snackbar,
  SnackbarContent,
  TextField,
  IconButton,
  Input,
  Switch,
  Grid,
  Checkbox,
  Button,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";

import Select from "react-select";
import { COLORS } from "../../../framework/src/Globals";
import { FONTS, icons } from "../../../components/src/constants";
import ContentManagementController, {
  Props, configJSON
} from "./ContentManagementController";

// @ts-ignore
import styles from "./ContentManagementEvent.module.css";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";
import { DeleteEventModal } from "./DeleteEventModal.web";
import CatogaryDropDown from "../../../components/src/custom/NeedCatogaoryDropdown";
import DBSDropDown from "../../../components/src/custom/NeedDBSDropdown";

export const useStyles = (theme: Theme) =>
  createStyles({
    textField: {
      padding: 0,
      borderRadius: 12,
      height: 54,
      width: "50%",
      fontFamily: "Rene_Bieder_Milliard_Book",
      border: "none",
    },
    fontResize: {
      fontWeight: 400,
      lineHeight: " 20px",
      letterSpacing: "-0.4px",
      color: "black",
    },
  });

const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 18,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      // color: theme.palette.grey[500],
      color: "white",
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#359ce2",
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor: "grey",
    },
    checked: {},
  })
)(Switch);

// @ts-ignore

const eventTypes = [
  { label: "Need", value: "need" },
  { label: "Project funding", value: "project_funding" },
  { label: "Upcoming activity", value: "activity" },
];

// Customizable Area End

export class NeedsEventUpdate extends ContentManagementController {
  nameOfEvent(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label} style={{ marginBottom: 10 }}>
          Name of event *
        </label>
        <br />
        <Input
          value={this.state.eventName}
          data-testId="eventName"
          onChange={(e) => {
            this.setState({
              eventName: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
            if (e.target.value.length < 1) {
              this.setState({
                eventNameValidation: true,
              });
            } else {
              this.setState({
                eventNameValidation: false,
              });
            }
          }}
          className={styles.textInput}
          disableUnderline
          placeholder="Name of event"
        />
        <br />
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventNameValidation ? "flex" : "none"}`,
          }}
        >
          Event name can't be blank
        </span>
      </div>
    );
  }

  categories(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Categories</label>
        <br />

        <CatogaryDropDown
          data-testId='taskPreferenceData'
          catogaryOptions={this.props.taskPreferenceData}
          handleTaskPreferenceDropdownIds={(val: any) => this.handleTaskPreferenceDropdownIds(val)}
          selectedTaskPreference={details.task_preference}
        />
        <br /><br />
      </div>
    );
  }


  dbsSwitch(single_event_details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>
          Disclosure and Barring Service (DBS) registration required
        </label>
        {/* @ts-ignore */}
        <AntSwitch
          checked={this.state.dbs_registration}
          data-testId="dbsSwitch"
          onChange={(e: any) => {
            this.setState({
              dbs_registration: e.target.checked,
            });
          }}
        />
      </div>
    );
  }

  descField(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Description of need *</label>
        <br />
        <Input
          value={this.state.description}
          data-testId="desc"
          onChange={(e) => {
            this.setState({
              description: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          multiline
          rows={6}
          disableUnderline
          className={styles.multiLineInput}
          placeholder="Provide a general description visible to all users on the platform."
        />
      </div>
    );
  }

  notesField(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Itinerary and notes</label>
        <br />
        <Input
          disableUnderline
          value={this.state.itinerarv_and_notes}
          data-testId="notes"
          onChange={(e) => {
            this.setState({
              itinerarv_and_notes: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          multiline
          rows={5}
          className={styles.multiLineInput}
          placeholder="Sensitive/personal information only visible to volunteer(s) confirmed for need fulfilment"
        />
      </div>
    );
  }

  volunteersNumField(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>
          Number of volunteers required
        </label>
        <br />
        <Input
          value={this.state.volunteers_num}
          inputProps={{
            maxLength: 8,
          }}
          data-testId="voluntener"
          onChange={(e) => {
            const pincode = e.target.value;
            if (/^\d{0,8}$/.test(pincode)) {
              this.setState({
                volunteers_num: pincode,
                eventVolunteerValidation: false,
              });
            } else {
              this.setState({
                eventVolunteerValidation: true,
              });
            }
          }}
          type="text"
          disableUnderline
          placeholder="0"
          className={styles.textInput}
        />
        <Typography
          style={{
            ...FONTS.body1,
            color: "lightgrey",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          This will be the maximum number of participants required for your
          event.
        </Typography>
        <Typography
          style={{
            ...FONTS.body1,
            color: "lightgrey",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Leave it 0 for no limit.
        </Typography>
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventVolunteerValidation ? "flex" : "none"}`,
          }}
        >
          Enter valid details
        </span>
      </div>
    );
  }

  everyoneCheck() {
    return (
      <span>
        <FormControlLabel
          data-testId="check-8"
          control={
            <Checkbox
              style={{
                color: "lightgrey",
              }}
              checked={
                this.state.everyone
              }
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
              onChange={(e) => {
                if (this.state.followers && this.state.registered_user) {
                  this.setState({ everyone: true });
                }
                this.setState({
                  everyone: e.target.checked,
                  followers: e.target.checked,
                  registered_user: e.target.checked,
                });
              }}
            />
          }
          label={<span className={styles.label}>Everyone else</span>}
        />
      </span>
    );
  }

  publishScopeField(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Publishing scope</label>
        <br />
        <FormGroup>{this.everyoneCheck(details)}</FormGroup>
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-9"
              control={
                <Checkbox
                  style={{
                    color: "lightgrey",
                  }}
                  checked={this.state.followers}
                  checkedIcon={
                    <CheckBoxIcon
                      style={{
                        color: "#359ce2",
                      }}
                    />
                  }
                  onChange={(e) => {
                    if (e.target.checked || this.state.registered_user) {
                      this.setState({ everyone: false });
                    }
                    this.setState({
                      followers: e.target.checked,
                    });
                  }}
                />
              }
              label={<span className={styles.label}>Followers</span>}
            />
          </span>
        </FormGroup>
        <FormGroup>
          <span>
            <FormControlLabel
              label={<span className={styles.label}>Registered user</span>}
              data-testId="check-10"
              control={
                <Checkbox
                  checked={this.state.registered_user}
                  checkedIcon={
                    <CheckBoxIcon style={{ color: "#359ce2" }} />
                  }
                  style={{
                    color: "lightgrey",
                  }}
                  onChange={(e) => {
                    this.setState({ registered_user: e.target.checked });
                    if (e.target.checked || this.state.followers) {
                      this.setState({ everyone: false });
                    }
                  }}
                />
              }
            />
          </span>
        </FormGroup>
      </div>
    );
  }

  fromDateField(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>From date *</label>
        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          autoOk
          minDate={new Date()}
          fullWidth
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          margin="normal"
          id="date-picker-inline"
          data-testId="date-picker-inline"
          InputProps={{
            classes: {
              root: classes.textField,
              input: classes.fontResize,
            },
          }}
          value={this.state.updateFromDate}
          onChange={(date) => {
            this.setState({ updateFromDate: date });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
          style={{ fontSize: 16 }}
        />
      </div>
    );
  }

  toDateField(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>To date *</label>
        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiOutlinedInput-root.Mui-Focused .MuiOutlinedInput-notchedOutline   {
              border : 1px solid black
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          autoOk
          fullWidth
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          margin="normal"
          id="date-picker-inline"
          data-testId="date-picker-inline-todate"
          InputProps={{
            classes: {
              root: classes.textField,
              input: classes.fontResize,
            },
          }}
          minDate={this.state.updateFromDate}
          value={this.state.updateToDate}
          onChange={(date) => {
            this.setState({ updateToDate: date });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
          style={{ fontSize: 16 }}
        />
      </div>
    );
  }

  startTimeField(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <div
          style={{
            display: "flex",
            width: "50%",
          }} > 
          <label className={styles.label}>
            Start time *</label>
          <Typography
            style={{
              fontFamily: "Rene_Bieder_Milliard_Medium",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>All day</Grid>

              <Grid item>
                {/* @ts-ignore */}
                <AntSwitch
                  checked={this.state.all_day}
                  data-testId="allDaySwitch"
                  onChange={(e: any) => {
                    this.setState({
                      all_day: e.target.checked,
                    });
                    if (e.target.checked) {
                      this.setState({
                        startTimePost: moment().set({ 'hour': 9, 'minute': 0 }),
                        endTimePost: moment().set({ 'hour': 17, 'minute': 0 }),
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        </div>
        <br />
        <InitiateThemeProvider>
          <KeyboardTimePicker
            autoOk
            fullWidth
            ampm={false}
            disabled={this.state.all_day}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            id="mui-pickers-time"
            className={classes.pickerMargin}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            data-testId='startTime'
            placeholder="12:00 AM"
            mask="__:__ _M"
            value={
              this.state.startTimePost === null
                ? moment(this.state.updateStartTime)
                : this.state.startTimePost
            }
            onChange={(time) => {
              // if(time>=new Date().getTime())

              this.setState({ startTimePost: time });
              console.log("CURRENT TIME:", this.state.startTimePost)
              console.log("UPDATE TIME:", this.state.updateStartTime)
            }}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                src={icons.clockIcon}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
    );
  }

  finishTimeField(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Finish time *</label>
        <br />
        <InitiateThemeProvider>
          <KeyboardTimePicker
            autoOk
            data-testId='finishTime'
            fullWidth
            ampm={false}
            disabled={this.state.all_day}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            id="mui-pickers-time"
            className={classes.pickerMargin}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            placeholder="12:00 AM"
            mask="__:__ _M"
            value={
              this.state.endTimePost === null
                ? moment(this.state.updateFinishTime)
                : this.state.endTimePost
            }
            onChange={(time) => {
              this.setState({ endTimePost: time });
            }}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                src={icons.clockIcon}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
    );
  }

  locationField() {
    return (
      <div className={styles.divWrapper} style={{ width: '50%', position: 'relative' }}>
        <label className={styles.labelHeading}>Location *</label>
        <br />
        {/* @ts-ignore */}
        <GooglePlacesAutoComplete
          data-testId='location'
          apiKey={configJSON.mapsAPIKey}
          debounce={500}
          selectProps={{
            isClearable: true,
            value: this.state?.location ? [{ label: this.state?.location, value: this.state?.location }] : [{ label: '', value: '' }],
            placeholder: "Enter location",
            noOptionsMessage: () => null,
            onChange: (event: any) => {
              console.log(event, 'event123')
              const location = event?.value?.description ?? "";
              this.setState({ location });
              if (location) {
                this.getLatLong(event?.value?.description);
              }
            },
            styles: {
              placeholder: (base) => {
                return {
                  ...base,
                  color: "lightgrey",
                  fontFamily: 'Rene_Bieder_Milliard_Book',
                  fontSize: '16px'
                };
              },

              menu: (base) => {
                return {
                  ...base,
                  paddingLeft: 20,
                  paddingRight: 20,
                  border: "1px solid lightgrey",
                  marginTop: 0,
                  borderTop: "none",
                  boxShadow: "none",

                  color: "lightgrey",
                  paddingTop: 5,
                  "&:hover": {
                    borderRadius: 12,
                  },
                };
              },
              clearIndicator: (base) => {
                return {
                  ...base,
                  marginRight: 20
                }
              },
              dropdownIndicator: (base) => {
                return {
                  ...base,
                  color: "dodgerblue",
                  display: 'none'
                };
              },
              control: (base) => {
                return {
                  ...base,
                  fontSize: 16,
                  fontFamily: 'Rene_Bieder_Milliard_Book',
                  backgroundColor: "white",
                  boxShadow: "none",
                  height: "54px",
                  width: '101%',
                  borderRadius: "10px",
                  border: '2px solid lightgrey',
                };
              },
              option: (provided, state) => ({
                ...provided,
                color: "black",
                backgroundColor: state.isSelected
                  ? "#192E49"
                  : "inherit",
                "&:hover": {
                  backgroundColor: "#009BE2",
                  color: "white",
                },
              }),
              menuList: (base) => {
                return {
                  ...base,
                  marginTop: 15,
                  position: "relative",
                  top: "-5px",
                  zIndex: -1,
                  color: "grey",
                };
              },
            }
          }}
        >

        </GooglePlacesAutoComplete>
        <img
          src={icons.locationIcon}
          style={{
            height: 23, width: "auto", position: 'absolute', top: 45, right: 8
          }}
        />
      </div >
    );
  }

  publishSwitch(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Publish</label>
        <br />
        {/* @ts-ignore */}
        <div style={{ marginLeft: 5 }}>
          <AntSwitch
            checked={this.state.publish}
            data-testId="publishSwitch"
            onChange={(e: any) => {
              this.setState({
                publish: e.target.checked,
              });
            }}
          />
        </div>
      </div>
    );
  }

  updateInfo() {
    return (
      <div className={styles.divWrapper}>
        <Input
          onChange={(e: any) => {
            this.setState({ update_info: e.target.value });
          }}
          data-testId="updateInfo"
          multiline
          rows={6}
          className={styles.multiLineInput}
          disableUnderline
          placeholder="Enter what is updated...*"
        />
      </div>
    );
  }

  isUpdateBtnDisable = () => {
    return !this.state.eventName ||
    !this.state.description ||
    !this.state.location ||
    !this.state.updateFromDate ||
    !this.state.updateToDate ||
    !this.state.update_info ||
    !this.state.updateEventImg ||
    this.state.createEventBtn;
  }

  btnGrp(single_event_details: any) {
    return (
      <div
        className={styles.divWrapper}
        style={{
          display: "flex",
          marginTop: 15,
          marginLeft: -10,
        }}
      >
        <Button
          disabled={this.isUpdateBtnDisable()}
          data-testId="updateBtn"
          onClick={() => {
            this.updateEvent(single_event_details.data?.id);
          }}
          style={{
            width: "12%",
            boxShadow: "none",
            textTransform: "capitalize",
            height: 45,
            backgroundColor: `${
              !this.isUpdateBtnDisable()
              ? "#009be2"
              : "#009be233"
              }`,
            color: "white ",
            borderRadius: 10,
            marginRight: 13,
            marginLeft: 13,
          }}
          color="primary"
        >
          Update
        </Button>
        <Button
          data-testId="cancelBtn"
          onClick={() => {
            this.props.navigationToNeeds();
          }}
          style={{
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
            width: "12%",
            color: "#009be2",
            boxShadow: "none",
            borderRadius: 8,
            height: 45,
            border: "0.1px solid lightgray",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            this.setState({ deleteConf: true });
          }}
          style={{
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
            width: "12%",
            textTransform: "none",
            backgroundColor: "red",
            color: "white ",
            boxShadow: "none",
            borderRadius: 8,
            height: 45,
            marginLeft: "auto",
            marginRight: 20,
            border: "0.1px solid lightgray",      
          }}
          variant="outlined"
        >Cancel event
        </Button>
      </div>
    );
  }

  uploadImg() {
    return (
      <div className={styles.divWrapper} 
      style={{ width: "50%" }}><label 
        className
        ={styles.label}>
          Upload image *</label>
        <CardContent 
        className={styles.cardUploadImage}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              background: COLORS.white,
              width: "106%",
              marginLeft: -12,
              position: 'relative',
              height: "22%",
              // border: "1px solid #a7b6b9 ",
              border: "1px solid #dfdfdf",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: `${this.state.updateEventImg ? "flex" : "none"}`,
                position: "absolute",
                right: 0,
                top: 0,
              }}
              data-testId="imgPreview"
              onClick={(e) => {
                this.setState({
                  updateEventImg: "",
                  needsEventImg: "",
                  needsEventImgPreview: "",
                });
              }}
            >
              <HighlightOffRoundedIcon />
            </div>
            {this.state.updateEventImg ? (
              <img
                src={
                  this.state.updateEventImg?.url
                    ? this.state.updateEventImg?.url
                    : this.state.updateEventImg
                }
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 10,
                }}
              />
            ) : (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <img
                  src={icons.plusIcon}
                  style={{ height: 20 }}
                  alt="cameraIcon"
                />

                <input
                  data-testId="userImageUpload"
                  name="profile_picture"
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={(event) => {
                    event.preventDefault();
                    if (event.target.files && event.target.files[0]) {
                      let file = event.target.files[0];
                      this.setState({
                        updateEventImg: URL.createObjectURL(file),
                      });
                      this.setState({
                        updateEventImgPreview: file,
                      });
                    }
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </CardContent>
      </div>
    );
  }

  constructor(props: Props) {
    super(props);
  }

  cancelModal = () => {
    this.setState({ deleteConf: false });
  };

  render() {
    // Customizable Area Start
    const { single_event_details, classes, theme }: any = this.props;
    console.log(this.state.location, 'eventlocation')
    console.log(this.state.eventUpdateError, 'error123', this.state.eventUpdateErros)
    const details = single_event_details.data?.attributes;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <Card
            style={{
              width: "99.6%",
              borderRadius: "0 0 12px 12px",
              margin: "auto",
              // marginTop: 30,
            }}
          >
            <div style={{ marginTop: 10 }} />

            <CardContent
              style={{
                // borderTop: "2px solid #F0F2f5",
                padding: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "-15px",
                  float: "right",
                }}
              >
                <Typography className={styles.label}>
                  Id: {single_event_details.data.id}
                </Typography>
                <IconButton>
                  <ShareOutlinedIcon />
                </IconButton>
              </div>

              <div className={styles.divWrapper}>
                <label className={styles.label}>Selected type</label>
                <br />
                {/* @ts-ignore */}
                <div
                  style={{
                    border: `${this.state.createEventState
                      ? "1px solid  #009BE2"
                      : "1px solid lightgrey"
                      }`,
                    width: "50%",
                    borderRadius: 10,
                    borderBottom: `${!this.state.createEventState
                      ? "1px solid lightgrey"
                      : "1px solid  #009BE2"
                      }`,
                    height: `${!this.state.createEventState ? "54px" : "180px"
                      }`,
                  }}
                >
                  <Select
                    isDisabled={true}
                    onMenuOpen={() => {
                      this.setState({ createEventState: true });
                    }}
                    onMenuClose={() => {
                      this.setState({ createEventState: false });
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "skyblue",
                        primary: "dodgerblue",
                      },
                    })}
                    styles={{
                      menu: (base) => {
                        return {
                          ...base,
                          paddingLeft: 20,
                          paddingRight: 20,
                          border: "none",
                          marginTop: 0,
                          borderTop: "none",
                          boxShadow: "none",
                          color: "black",
                          paddingTop: 5,
                          borderTopLeftRadius: "0px",
                          borderTopRightRadius: "0px",
                        };
                      },
                      dropdownIndicator: (base) => {
                        return {
                          ...base,
                          color: "lightgrey",
                        };
                      },
                      singleValue: (base) => {
                        return {
                          ...base,
                          color: "black",
                          cursor: "not-allowed",
                        };
                      },
                      control: (base, state) => {
                        return {
                          ...base,
                          cursor: "not-allowed",
                          height: "54px",
                          backgroundColor: "white",
                          boxShadow: "none",
                          borderRadius: state.isFocused ? "10px " : "10px",
                          border: `${this.state.createEventState
                            ? "1px solid  #009BE2"
                            : "1px solid lightgrey"
                            }`,
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          "&:hover": {
                            borderBottom: `${this.state.createEventState
                              ? "1px solid  #009BE2"
                              : "1px solid lightgrey"
                              }`,
                          },
                        };
                      },

                      indicatorSeparator: (styles) => ({
                        ...styles,
                        display: "none",
                      }),
                      menuList: (base) => {
                        return {
                          ...base,
                          marginTop: 15,
                          position: "relative",
                          top: "-5px",
                          zIndex: -1,
                        };
                      },
                    }}
                    name="eventTypes"
                    isSearchable={false}
                    isClearable={false}
                    isRtl={false}
                    options={eventTypes}
                    defaultValue={eventTypes[0]}
                    className={styles.selectInput}
                    onChange={(e: any) => {
                      this.setState({ eventType: e.value });
                    }}
                    data-testId="eventTypes"
                  />
                </div>
              </div>

              {this.nameOfEvent(details)}

              {this.categories(details)}

              {this.dbsSwitch(single_event_details)}

              <div
                className={styles.divWrapper}
                style={{
                  display: this.state.dbs_registration ? "block" : "none",
                }}
              >
                <DBSDropDown
                    catogaryOptions={this.props.dbsData}
                    handleDBSTypeDropdownIds={(val: any) => this.handleDBSTypeDropdownIds(val)}
                    selectedDbsData={details.dbs_type}
                  />
                  <br />
                  <br />
                  <br />
              </div>

              {this.descField(details)}

              {this.notesField(details)}

              {this.volunteersNumField(details)}

              {this.publishScopeField(details)}

              {this.fromDateField(classes)}

              {this.toDateField(classes)}

              {this.startTimeField(classes)}

              {this.finishTimeField(classes)}

              {this.locationField(details)}

              {this.uploadImg()}

              {this.publishSwitch(details)}

              {this.updateInfo(details)}

              {this.btnGrp(single_event_details)}
              {this.state.eventUpdateError.length > 0 && (
                <Snackbar
                  open={this.state.eventUpdateError.length > 0}
                  onClose={() => {
                    this.setState({ eventUpdateError: "" });
                  }}
                  autoHideDuration={3000}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <SnackbarContent
                    className={styles.snackbar}
                    message={this.state.eventUpdateError ? this.state.eventUpdateError : this.state.eventUpdateErros}
                  />
                </Snackbar>
              )}

              <Dialog
                hideBackdrop
                PaperProps={{
                  style: {
                    background: "#FEFEFE",
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
                    borderRadius: 18,
                    maxHeight: 370,
                    width: 600,
                  },
                  elevation: 0,
                }}
                open={this.state.deleteConf}
                data-testId="modal"
                onClose={() => {
                  this.setState({ deleteConf: false });
                }}
              >
                <DeleteEventModal
                  id={single_event_details.data?.id}
                  cancelClick={this.cancelModal}
                  confirmClick={() => {
                    this.deleteSpecificEvent(single_event_details.data?.id);
                  }}
                />
              </Dialog>
            </CardContent>
          </Card>
        </div>
      </MuiPickersUtilsProvider>
      // Customizable Area End
    );
  }
}

export default withStyles(useStyles as any, { withTheme: true })(
  NeedsEventUpdate as any
);
