import React from "react";

import {
  // Customizable Area Start
  Card,
  Button,
  Typography,
  ButtonGroup,
  IconButton,
  Toolbar,
  Avatar,
  Modal,
  Box,
  Paper,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Skeleton from '@material-ui/lab/Skeleton';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import moment from "moment";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
//@ts-ignore
import classesoutercss from "./MyNeed.module.css";
import { COLORS } from "../../../framework/src/Globals";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import {
  constants,
  icons,
  images
} from "../../../components/src/constants";
import ActiveNeedsController, {
  Props, 
  AttendedUsers
} from "./ActiveNeedsController.web";
import ImageLoader from "../../../components/src/custom/ImageLoader";
// Customizable Area End

// Customizable Area Start
export const styles: any = (theme: Theme) =>
  createStyles({
    cardWrapper: {
      padding: "30px 60px 30px 100px",
      "@media (max-width:600px)": {
        width: "100%",
        padding: "10px 10px 60px 5px",
      },
    },
    cardInner: {
      position: "relative" as const,
      padding: "30px",
      marginBottom: "20px",
      boxShadow: "none",
      borderRadius: "16px",
      border: "1px solid #d5d5d5",
      background: "#ffffff",
      display: "flex",
      justifyContent: "left",
      "@media (max-width:600px)": {
        padding: "10px",
      },
    },
    leftCard: {
      textAlign:"center",
      width: "313px",
      height: "170px",
      position: "relative" as const,
      "@media (max-width:600px)": {
        width: "85px",
        height: "85px"
      },
    },
    cardImage: {
      width: "313px",
      height: "170px",
      borderRadius: "16px",
      objectFit: "cover" as const,
      "@media (max-width:600px)": {
        width: "85px",
        height: "85px"
      },
    },
    needType: {
      background: "#f89a01",
      position: "absolute" as const,
      color: "#ffffff",
      fontSize: "13px",
      left: 0,
      top: 142,
      borderRadius: "0px 16px 0px 16px",
      padding: "5px 10px",
      "@media (max-width:600px)": {
        display: "none",
      },
    },
    rightCard: {
      position: "relative" as const,
    },
    activeTimeline: {
      background: "#B3E8E2",
      color: "#11A48C",
      position: "absolute" as const,
      fontSize: "16px",
      right: 0,
      top: 0,
      borderRadius: "0px 16px 0px 16px",
      padding: "5px 30px",
      "@media (max-width:600px)": {
        fontSize: "10px",
        padding: "5px 15px",
      },
    },
    timeline: {
      background: "#ececec",
      position: "absolute" as const,
      color: "#87888f",
      fontSize: "16px",
      right: 0,
      top: 0,
      borderRadius: "0px 16px 0px 16px",
      padding: "5px 30px",
      border: "1px solid #d5d5d5"
    },
    eventTitle: {
      fontSize: "18px",
      fontWeight: 600,
      margin: "0px 0 25px 30px",
      "@media (max-width:600px)": {
        fontSize: "16px",
        margin: "0px 0 10px 15px",
      },
    },
    avatarImgcontainer: {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "15px"
    },
    avatarImg: {
      width: 23,
      height: 23,
      borderRadius: "50%",
      zIndex: 1,
      "@media (max-width:600px)": {
        width: 15,
        height: 15,
      },
    },
    eventdate_loc: {
      fontWeight: 400,
      fontSize: 14,
      color: " #87888f",
      "@media (max-width:600px)": {
        fontSize: 12,
      },
    },
    innerInfo: {
      padding: "0 0 0 35px",
      "@media (max-width:600px)": {
        padding: "0px 0 0px 15px",
      },
    },
    imageTextWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 15,
      "@media (max-width:600px)": {
        marginBottom: 5,
      },
    },
    multipleAvatarStyle: {
      height: 24,
      width: 24,
      border: "2px solid white",
    },
    deleteonCard: {
      borderRadius: "50%",
      border: "1px solid grey",
      position: "absolute" as const,
      right: 20,
      padding: 10,
      bottom: 20,
      cursor: "pointer",
      "@media (max-width:600px)": {
        padding: 5
      },
    },
    deleteButton: {
      height: 25,
      "@media (max-width:600px)": {
        height: 10,
      },
    },
    editButtonCard: {
      borderRadius: "50%",
      border: "1px solid grey",
      position: "absolute" as const,
      right: 20,
      padding: 10,
      bottom: 20,
      cursor: "pointer",
      marginRight: 70,
      "@media (max-width:600px)": {
        padding: 5,
        marginRight: 30,
      },
    },
    FavoriteBorderIcon: {
      position: "absolute",
      backgroundColor: COLORS.white,
      padding: "6px",
      width: "30px",
      height: "30px",
      right: " 12px",
      top: "12px",
      borderRadius: "50%",
      color: "#292d32",
      cursor: "pointer",
      "@media (max-width:600px)": {
        width: "20px",
        height: "20px",
      },
    },
    tabStyle: {
      padding: "7px",
      textTransform: "capitalize",
      fontSize: "16px",
      "@media (max-width:600px)": {
        padding: "0px",
        fontSize: "16px",
      },
    },
    FavoriteIcon: {
      position: "absolute",
      backgroundColor: COLORS.white,
      padding: "6px",
      width: "30px",
      height: "30px",
      right: " 12px",
      top: "12px",
      borderRadius: "50%",
      color: "#292d32",
      fill: "red",
      cursor: "pointer",
      "@media (max-width:600px)": {
        width: "20px",
        height: "20px",
      },
    },
    userName: {
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: -0.35,
      color: "#57565c",
      "@media (max-width:600px)": {
        fontSize: 14,
      },
    },
    userInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 20,
      "@media (max-width:600px)": {
        marginBottom: 10,
      },
    },
    myneedTabgroupActive: {
      border: "none",
      background: '#FFFFFF',
      boxShadow: ' 0px 0px 8px rgba(212, 212, 212, 0.5)',
      textTransform: "none"
    },
    mobilePublishBtn: {
      display: 'none',
      "@media (max-width:600px)": {
        display: "block",
        position: 'fixed',
        bottom: "115px",
        right: 10
      },
    }
  });
// Customizable Area End

class ActiveNeeds extends ActiveNeedsController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headerWeb() {
    return (
      <Toolbar className={"toolBar"} style={{ minHeight: isMobile ? 0 : 56 }}>
        {!isMobile &&
          <div className={classesoutercss.backIconWeb}>
            <IconButton
              color={"inherit"}
              aria-label="Open drawer"
            >
              <img src={icons.back} />
            </IconButton>
            <Typography variant="h6" style={{ fontSize: "20px", color: "#000", marginLeft: "30px" }} noWrap>
              My Needs
            </Typography>
          </div>
        }
      </Toolbar>
    )
  }

  applyClass = (type: any) => {
    if (type === this.state.selectedNeedsTabChange) {
      return `${"myneedTabgroupActive"} ${classesoutercss.myNeedsTabs}`
    } else if (type === "Publish") {
      return `${"myneedTabgroup"} ${classesoutercss.myNeedsTabs}`
    } else {
      return `${"myneedTabgroup"} ${classesoutercss.myNeedsTabs}`
    }
  }

  mobilePublishBtn(classes: any) {
    return (
      <div className={classes.mobilePublishBtn}>
        <Button className={classesoutercss.publishNeedResponsiveIcons} data-testId='mobilePublishBtn' onClick={() => this.props.navigation.navigate("PublishNeed")}>
          <img src={icons.plusBlue} className={classesoutercss.publishNeedResponsivePlusIcon} />
        </Button>
      </div>
    )
  }

  buttonGroup(classes: any) {
    return (
      <div
        style={{
          justifyContent: "center", alignItems: "center", display: "flex"
        }}>
        <ButtonGroup size="large" aria-label="large button group" fullWidth
          className={"myneedTabgroup"}
        >
          {
            constants.needsTabsConstants.slice(0, isMobile ? 2 : 3).map((ele, index) => (
              <Button
                key={ele.type}
                data-testId={ele.type}
                onClick={() => {
                  this.setState({ selectedNeedsTabChange: ele.type });
                  ele.type === "Publish" && this.props.navigation.navigate("PublishNeed");
                  ele.type === "Past" ? this.PastNeedsTabData() : this.ActiveNeedsTabData();
                }}
                className={this.applyClass(ele.type)}
              >
                <Typography
                  className={classes.tabStyle}
                  style={
                    { color: this.state.selectedNeedsTabChange === ele.type || ele.type === 'Publish' ? COLORS.skyBlue : COLORS.darkGray }
                  }>{ele.label}</Typography>
                {ele.type === "Publish" ?
                  <img src={icons.plusBlue} style={{ height: 24, width: 24, marginLeft: 8 }} /> : ""}
              </Button>
            ))}
        </ButtonGroup>
      </div>
    )
  }

  fallbackMessage() {
    return (
      <div style={{ textAlign: 'center', fontSize: 20 }}><p>No needs available</p></div>
    )
  }

  deleteModel() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.needModel}
        onClose={() => {
          this.setState({ needModel: false });
        }}
        data-testId="modalClose"
      >
        <Box className={classesoutercss.myNeedmodalBox}>
          <img src={images.imageWarning} alt='warrning' className={classesoutercss.warningIcon} />
          <Typography style={{ fontSize: '20px' }} className={classesoutercss.alertText}>Want to cancel this need?</Typography>
          <Button
            onClick={() => this.deleteNeed()}
            data-testId='confirmBtn'
            style={{
              width: "80%",
              backgroundColor: "#009be2",
              color: "white",
              borderRadius: "10px",
              marginTop: '35px',
              height: '50px',
              textTransform: 'capitalize'
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              this.setState({ needModel: false });
            }}
            data-testId='cancelBtn'
            style={{
              width: "80%",
              color: "black",
              borderRadius: "10px",
              marginTop: 15,
              marginBottom: '30px',
              height: '50px',
              border: '1px solid #E4E4E4',
              textTransform: "capitalize"
            }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    )
  }

  renderSkeleton = (classes: any) => {
    const tab = this.state.selectedNeedsTabChange;
    const card = <Paper
      style={{
        padding: 24,
        width: "100%",
        margin: "auto",
        marginBottom: 30,
        height: 170,
        background: '#FFFFFF',
        borderRadius: 18,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid rgba(0, 0, 0, 0.11)"
      }}
      elevation={0}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
          <Skeleton variant="rect" animation="wave" className={classes.cardImage} style={{ height: 123, width: "100%" }} />
        </Grid>
        <Grid item xs={8} md={8}>
          <Skeleton height={30} animation="wave"/>
          <Skeleton height={30} animation="wave"/>
          {tab === "Past" ?
            <>
              <Skeleton height={30} animation="wave"/>
              <Skeleton height={30} animation="wave"/>
            </>
            :
            <Grid container spacing={2}>
              <Grid item xs={6} md={9}>
                <Skeleton height={30} animation="wave"/>
                <Skeleton height={30} animation="wave"/>
              </Grid>
              <Grid item xs={6} md={3} style={{ display: "flex", justifyContent: "end" }}>
                <Box display="flex" alignItems="end">
                  <Skeleton variant="circle" animation="wave" width={40} height={40} style={{ marginRight: "20px" }} />
                  <Skeleton variant="circle" animation="wave" width={40} height={40} />
                </Box>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Paper>
    return [...Array(4)].map((_, index) => (card));
  }

  eventCard = (classes: any) => {
    const { cardData } = this.state;
    if(this.state.loading){
      return this.renderSkeleton(classes);
    }
    return (
      cardData?.length > 0 ?
        cardData.map((item: any) => (
          this.currentData(item, classes)
        )) : this.fallbackMessage()
    )
  }

  renderLocation=(location:string, postcode:string)=> {
    if(postcode && !location.includes(postcode)){
     location += `, ${postcode}`; 
   }
  return location;
 }

  currentData = (item: any, classes: any) => {
    const placeholderImg=localStorage.getItem('placeholderImg')
    return (
      <Card className={classes.cardInner} key={item.id}>
        <div className={classes.leftCard}>
          <ImageLoader loaderStyle={{marginTop:isMobile?35:70}} classes={classes.cardImage} imageUrl={item.attributes.need_photo.url ? item.attributes.need_photo.url:placeholderImg}/>
          {item.attributes.is_favourite ?
            <FavoriteIcon
              onClick={(event) => {
                event.stopPropagation()
                this.setState({ selectedId: item.id })
                this.removeFavourite(item.id, item.attributes.favourite_id)
              }}
              className={classes.FavoriteIcon}

            /> :
            <FavoriteBorderIcon
              onClick={(event) => {
                event.stopPropagation()
                this.setState({ selectedId: item.id })
                this.addFavourite(item.id)
              }}
              className={classes.FavoriteBorderIcon}
            />
          }
          <span className={classes.needType}>{(item.attributes.need_type)}</span>
        </div>
        <div className={classes.rightCard}>
          <Typography className={classes.eventTitle}>
            {item.attributes.title_of_need}
          </Typography>
          <div className={classes.innerInfo}>
            <div
              className={classes.userInfo}
            >
              <div className={classes.avatarImgcontainer}>
                <img src={item.attributes.user_image.url ? item.attributes.user_image.url : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"} alt="" className={classes.avatarImg} />
              </div>
              <Typography
                variant="body1"
                className={classes.userName}
              >
                {item.attributes.user_name}
              </Typography>
            </div>
            <div className={classes.imageTextWrapper}>
              <div className={classes.avatarImgcontainer}>
                <img
                  src={icons.calender}
                  alt="avatar"
                  className={classes.avatarImg}
                />
              </div>
              <span className={classes.eventdate_loc}>
                {moment(item.attributes.start_date).format('DD MMM YYYY')}, {moment(item.attributes.start_time).format('HH:mm a')}
              </span>
            </div>
            <div className={classes.imageTextWrapper}>
              <div className={classes.avatarImgcontainer}>
                <img
                  src={icons.locationred}
                  alt="location"
                  className={classes.avatarImg}
                />
              </div>
              <span className={classes.eventdate_loc}>
                {this.renderLocation(item.attributes.location, item.attributes.post_code)}
              </span>
              <AvatarGroup className={`AvatarGroupMyNeed ${classesoutercss.AvatarGroup}`} max={4}>
                {item.attributes.attended?.map((personObj: AttendedUsers) => {
                  let sourceImage = personObj.profile_picture.url
                  if(personObj.status !== "Cancelled"){
                  return (
                    <Avatar key={personObj.account_id} className={`singleAvatar ${classes.multipleAvatarStyle}`} src={sourceImage} alt="Remy Sharp"  >
                      {personObj.full_name?.charAt(0).toUpperCase()}
                    </Avatar>
                  )
                  }
                })}
              </AvatarGroup>
            </div>
          </div>
        </div>


        {this.state.selectedNeedsTabChange === 'Active' && <div>
          <div
            data-testId={`deleteNeedIcon-${item.id}`}
            className={classes.deleteonCard}
            onClick={(event) => {
              event.stopPropagation()
              this.setState({ needModel: true, deleteNeedId: item.id });
            }}

          >
            <img
              src={icons.deleteIcon}
              className={classes.deleteButton}
              alt="deleteJpeg"
            />
          </div>
          <div className={classes.editButtonCard} data-testId="redirect-btn" onClick={() => this.handleAddNewNeed(item.id)}
          >
            <img
              src={icons.editIcon}
              className={classes.deleteButton}
              alt="deleteJpeg"
            />
          </div>
        </div>}


        {this.state.selectedNeedsTabChange === 'Active' ?
          <span className={classes.activeTimeline}>Active</span> : <span className={classes.timeline}>Past</span>}
      </Card>
    )
  }

  mainCard = (classes: any) => {
    return (
      <div className={classes.cardWrapper}>{this.eventCard(classes)}</div>
    )
  }
  // Customizable Area End

  render() {
    const { classes, theme }: any = this.props;
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          {/* @ts-ignore */}
          <DashboardWeb
            showBackButton={constants._icons.show}
            title={'My Needs'}
            showBottomBar={true}
            navigation={this.props.navigation}
            showDrawerHeader={isMobile ? true : false}
          >
            <div
              className={classesoutercss.userprofileMain}
              style={{ backgroundColor: COLORS.white }}
            >
              {this.headerWeb()}
              {this.buttonGroup(classes)}
              {this.mobilePublishBtn(classes)}
              {this.mainCard(classes)}
              {this.deleteModel()}
            </div>

          </DashboardWeb>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}


// Customizable Area Start
ActiveNeeds.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export { ActiveNeeds }
export default withStyles(styles, { withTheme: true })(ActiveNeeds as any);
// Customizable Area End
