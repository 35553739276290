import React from "react";
// Customizable Area Start
import AdminRegistrationController, {
  Props,
} from "./AdminRegistrationController";
//@ts-ignore
import classOuter from "./NpSignIn.module.css";
import { Link } from "react-router-dom";
import { FAMILYFONTS, images, } from "../../../components/src/constants";
import {
  Typography,
  Container,
  Box,
} from "@material-ui/core";
import { setSelectedAdminTab } from "../../../framework/src/redux/adminTab/adminTabActions";
import { connect } from "react-redux";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { HideContainer } from "../../../components/src/shared/hide-container";
import { InitiateAlert } from "../../../components/src/design-system/alert";
import { Formik, Form } from 'formik';
import { FormikTextField } from "../../../components/src/shared/formik-text-field";
import * as yup from 'yup';
import { InitiateButton } from "../../../components/src/design-system/button";

const validationSchema = yup.object().shape({
  email: yup.string().email().required("Email is a required field"),
  password: yup.string().required("Password is a required field")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must be of 8 characters with a capital letter, a special character & a number."
    ),
});
// Customizable Area End

class NpSignIn extends AdminRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    // sign In button validation
    const validInputs = !this.state.email || !this.state.password;
    const validError =
      this.state.emailPasswordValidation || this.state.emailValidation;
    const disabled = validInputs || validError;
    // Customizable Area End
    return (
      // Customizable Area Start
      <InitiateThemeProvider>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            this.setState({ email: values.email, password: values.password }, () => {
              // localStorage.clear()
              this.gotoSignIn()
            })
          }}
        >
          {formik =>
            // @ts-ignore
            <Form translate="no">

              <Container maxWidth="xl" className={classOuter.mainContainer}>

                {/* App logo */}
                <div className={classOuter.formContainer}>
                  <div className={classOuter.appLogoContainer}>
                    <img src={images.app_logo} className={classOuter.appLogo} />
                  </div>

                  {/* Sign-in Form */}
                  <div>
                    {/* Form Title */}
                    <Box>
                      <Typography
                        className={classOuter.titleText}
                        style={{
                          ...FAMILYFONTS.family1,
                          fontSize: 24,
                          fontWeight: 400,
                        }}
                      >
                        {'Sign In (Non-Profit)'}
                      </Typography>
                    </Box>

                    {/* Form Content */}
                    <Box>
                      {/* Email Input Field */}
                      <Box>
                        <FormikTextField
                          label="Email Address"
                          data-testid="email_address_test"
                          placeholder={"Enter email address"}
                          fullWidth
                          variant="outlined"
                          name="email"
                        />
                        <HideContainer hideWhen={!this.state.emailError}>
                          <InitiateAlert>
                            {this.state.emailError}
                          </InitiateAlert>
                        </HideContainer>

                      </Box>

                      <VerticalSpacer value={30} />

                      {/* Password Input Field */}
                      <Box>
                        <FormikTextField
                          label="Password"
                          data-testid="txtInputPassword"
                          type="password"
                          name="password"
                          placeholder="Enter password"
                          className={classOuter.inputField}
                          fullWidth
                          variant="outlined"
                        />

                        <HideContainer hideWhen={!this.state.passwordError}>
                          <InitiateAlert>
                            {this.state.passwordError}
                          </InitiateAlert>
                        </HideContainer>
                      </Box>

                      {/* Forgot Password Link */}
                      <Box
                        sx={{
                          textAlign: "right",
                          paddingTop: '10px',
                        }}
                      >
                        <Link
                          className={classOuter.linkText}
                          style={{
                            ...FAMILYFONTS.family1,
                            fontSize: 14,
                            fontWeight: 400,
                            textDecoration: "none",
                            textDecorationColor: "#359ce2",
                            textTransform: "capitalize",
                            cursor: "pointer",
                            marginLeft: "3px",
                          }}
                          data-test-id={"btnForgotPassword"}
                          to={"NpForgotPassword"}
                        >
                          {'Forgot Password?'}
                        </Link>
                      </Box>

                      {/* Sign in Button */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "10px 0px",
                        }}
                      >
                        <InitiateButton
                          data-testid={"btnEmailLogIn"}
                          variant="contained"
                          color="primary"
                          className={classOuter.footerBtn}
                          fullWidth
                          style={{
                            ...FAMILYFONTS.family2,
                            fontSize: 20,
                            fontWeight: 400,
                          }}
                          disabled={this.state.signinLoading || !formik.dirty || !formik.isValid}
                          type="submit"
                          loading={this.state.signinLoading}
                        >
                          {'Sign In'}
                        </InitiateButton>
                      </Box>

                      {/* Register Link */}
                      <Box>
                        <Typography className={classOuter.footerLinkText}>
                          <span
                            style={{
                              ...FAMILYFONTS.family3,
                              fontSize: 16,
                              fontWeight: 400,
                            }}
                          >
                            {'Don\'t have an account?'}
                          </span>
                          <Link
                            data-test-id={"btnNavigateToSignUp"}
                            type="submit"
                            className={classOuter.footerLink}
                            style={{
                              textDecoration: "underline",
                              textDecorationColor: "#359ce2",
                              textTransform: "initial",
                              cursor: "pointer",
                              marginLeft: "3px",
                            }}
                            to={"/AccountCreation"}
                          >
                            <span
                              style={{
                                ...FAMILYFONTS.family1,
                                fontSize: 16,
                                fontWeight: 400,
                              }}
                            >
                              {'Register now'}
                            </span>
                          </Link>
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Container>

            </Form>
          }
        </Formik>
      </InitiateThemeProvider>
      // Customizable Area End
    );
  }
}


export { NpSignIn };
function mapStateToProps(state: any) {
  return {
    selectedTab: state.tabReducer?.selectedTab,

  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedAdminTab: (selectedTab: any) => {
      return dispatch(setSelectedAdminTab(selectedTab))
    },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NpSignIn)
