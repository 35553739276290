// @ts-nocheck
import { NotificationRequestPayload } from "../../types/notifications";
import { TermsAndConditionAttributes } from "../../types/terms";
import { makeHttpMessage } from "../../utils/http-message";
import { appendJsonContentType } from "../../utils/http-response";

export interface SignupRequestPayload {
  full_name: string,
  email: string,
  full_phone_number: string,
  password: string,
  password_confirmation: string,
}



export const userMessages = {

  saveDBsTypes({
    accountId,
    dbsNumber,
    dbsTypesIds,
  }: {
    accountId: number,
    dbsNumber?: number,
    dbsTypesIds?: number[],
  }) {

    const httpBody: any = {
      "account_id": accountId,
    }

    if (dbsNumber)
      httpBody.dbs_number = dbsNumber;

    if (dbsTypesIds)
      httpBody.dbs_type_ids = dbsTypesIds;

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: 'account_block/accounts/assign_dbs_types',
      httpMethod: 'POST',
      httpBody,
    });

  },

  fetchMySelectedDBS({ accountId }: { accountId: number }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/account_block/accounts/my_selected_dbs',
      httpMethod: 'GET',
      params: { account_id: accountId }
    });
  },

  login({ email, password }: { email: string, password: string }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/bx_block_login/logins',
      httpBody: {
        data: {
          attributes: {
            email,
            password,
          },
          type: "email_account",
        },
      },
      httpMethod: 'POST',
    });
  },

  fetchTermsAndConditions({ accountType, type }: { type:string; accountType?: TermsAndConditionAttributes['account_type'] }) {
    const url = type !== "Privacy Policy"
    ? '/bx_block_privacy_settings/terms_and_conditions'
    : '/bx_block_privacy_settings/privacy_policies';
    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: url,
      params: {
        account_type: accountType || 'user',
      },
      httpMethod: 'GET',
    });
  },

  fetchMyTaskPreferences({ accountId,token }: { accountId: number,token:String }) {

    return makeHttpMessage({
      header: appendJsonContentType({ type:"user_entity",
      token}),
      url: '/account_block/accounts/get_task_preference',
      httpMethod: 'GET',
    });
  },

  fetchProfile({ accountId }: { accountId: number }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/account_block/accounts/get_user_profile',
      params: {
        account_id: accountId,
      },
      httpMethod: 'GET',
    });
  },

  signup(payload: SignupRequestPayload) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/account_block/accounts',
      httpMethod: 'POST',
      httpBody: {
        "data": {
          "type": "email_account",
          "attributes": payload
        }
      }
    });
  },

  getEmailOTP({ email }: { email: string }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/bx_block_forgot_password/otps/email_otp',
      httpMethod: 'POST',
      httpBody: {
        "data": {
          "attributes": { email }
        }
      }
    });
  },


  verifyEmailOTP({ token, email_otp }: { token: string, email_otp: string }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/account_block/accounts/account_confirm',
      httpMethod: 'POST',
      httpBody: {
        "data": {
          otp_code: email_otp,
          token,
        }
      }
    });
  },



  getMobileOTP({ phoneNumber }: { phoneNumber: string }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/bx_block_phone_verification/send_sms_otp',
      httpMethod: 'POST',
      httpBody: {
        "data": {
          "attributes": { full_phone_number: phoneNumber }
        }
      }
    });
  },



  verifyMobileOTP({ otpToken, mobileOtp }: { otpToken: string, mobileOtp: string }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/bx_block_phone_verification/verify_sms_otp',
      httpMethod: 'POST',
      httpBody: {
        token: otpToken,
        pin: mobileOtp,
      }
    });
  },

  addWorkEmail({ accountId, workEmail }: { accountId: number, workEmail: string }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/account_block/accounts/add_work_email_address',
      httpMethod: 'POST',
      httpBody: {
        "work_email": workEmail,
        "account_id": accountId
      },
    });
  },

  registerAsVolunteerToOrg({ token, organizationsIds }: { token: string, organizationsIds: number[] }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/affiliate_organisation',
      httpMethod: 'POST',
      httpBody: {
        "organisation_ids": organizationsIds,
        token,
      },
    });
  },

  fetchNPOrganizations() {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: `/get_available_organisations?token=${localStorage.getItem("token")}`,
      httpMethod: 'GET',
    });
  },


  updateBioAndAddress({
    address,
    profilePicture,
    bio,
    accountId,
  }: {
    profilePicture: any,
    accountId: string,
    bio: string,
    address: {
      streetName: string,
      area: string,
      city: string,
      country: string,
      postCode: string,
    },
  }) {


    const formData = new FormData();

    formData.append("data[attributes][profile_picture]", profilePicture);
    formData.append("data[attributes][account_id]", accountId);
    formData.append("data[attributes][bio]", bio);
    formData.append("data[attributes][street_name]", address.streetName);
    formData.append("data[attributes][area]", address.area);
    formData.append("data[attributes][city]", address.city);
    formData.append("data[attributes][country]", address.country);
    formData.append("data[attributes][post_code]", address.postCode);
    formData.append("data[attributes][terms_and_condition]", 'true');

    return makeHttpMessage({
      header: {
        // for some reason we don't have to pass this
        //"Content-Type": "multipart/form-data"
      },
      url: '/account_block/accounts/user_profile',
      httpMethod: 'POST',
      httpBody: formData
    });
  },

  fetchDBSTypes() {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/dbs_types',
      httpMethod: 'GET',
    });
  },

  fetchAvailableTasks() {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/task_preferences',
      httpMethod: 'GET',
    });
  },

  acceptTerms({
    accountId,
  }: {
    accountId: number,
  }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/account_block/accounts/terms_and_condition',
      httpMethod: 'POST',
      httpBody: {
        "terms_and_condition": true,
        "account_id": accountId,
      },
    });
  },

  saveTasksPreferences({
    accountId,
    tasksIds,
    disabilityAssistance,
  }: {
    accountId: number,
    tasksIds: number[],
    disabilityAssistance: string,
  }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/account_block/accounts/assign_task_preference',
      httpMethod: 'POST',
      httpBody: {
        "account_id": accountId,
        "task_ids": tasksIds,
        "disability_assistance": disabilityAssistance
      },
    });
  },

  setNotificationSettings({
    accountId,
    settings,
    type,
  }: {
    accountId: number,
    settings: NotificationRequestPayload,
    type: 'email' | 'app',
  }) {

    let url = type == 'email' ?
      '/account_block/accounts/set_email_notification'
      :
      '/account_block/accounts/set_app_notification';

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url,
      httpMethod: 'POST',
      httpBody: {
        "account_id": accountId,
        ...settings,
      },
    });
  },

  fetchMyNonProfitOrgsAffiliations({
    token,
  }: {
    token: string,
  }) {

    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: '/get_available_organisations',
      httpMethod: 'GET',
      params: {
        token,
      },
    });
  },

  fetchAvailabilitesOfDay({
    accountId,
    date,
    token,
  }: {
    accountId: number,
    date: string,
    token: string,
  }) {
    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: 'bx_block_appointment_management/availabilities',
      httpMethod: 'GET',
      params: {
        check_date: date,
        service_provider_id: accountId,
        token,
      },
    });
  },

  setAvailabilityForDay({
    token,
    start_time,
    end_time,
    from_date,
    to_date,
    allDay,
  }: {
    from_date: string,
    to_date: string,

       token: string,

    start_time?: string,
    end_time?: string,
    allDay?: boolean,
  }) {
    const id= Number(localStorage.getItem("date_id"));
    const fromDate=from_date.split("T")[0];
    const from=fromDate.split("-").reverse("").join("/");
    const toDate=to_date.split("T")[0];
    const to=toDate.split("-").reverse("").join("/");
    let url;
    const httpBody: any = {
      token,
    }

    if (allDay){
      httpBody.all_day = true;
      if(localStorage.getItem("api_method_availability")==="POST" && from && to){
        url= `bx_block_appointment_management/availabilities`
        httpBody.from_date=from;
          httpBody.to_date=to;
      }
      if(localStorage.getItem("api_method_availability")==="PATCH"){
        url= `bx_block_appointment_management/availabilities/${id}`
      httpBody.availability_date=from;
      }
    }
    else {
      if (start_time){
        const date = new Date();
        const [time, period] = start_time.split(' ');
        const [hours, minutes] = time.split(':').map(Number);
        let hours24 = hours  % 12;
        if (period.toLowerCase().includes("pm") && hours24 <= 12) {
          hours24 += 12;
        }
        date.setHours(hours24);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        httpBody.start_time = date.toString();
      }
      if (end_time){
        const date = new Date();
        const [time, period] = end_time.split(' ');
        const [hours, minutes] = time.split(':').map(Number);
        let hours24 = hours % 12;
        if (period.toLowerCase().includes("pm") && hours24 <= 12) {
          hours24 += 12;
        }
        date.setHours(hours24);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        httpBody.end_time = date.toString();
      }
        
        if(localStorage.getItem("api_method_availability")==="POST" && from && to){
          httpBody.from_date=from;
          httpBody.to_date=to;
           url =`bx_block_appointment_management/availabilities`
        }
        if(localStorage.getItem("api_method_availability")==="PATCH"){
          httpBody.availability_date=from;
          url= `bx_block_appointment_management/availabilities/${id}`
        } 
      }     


    return makeHttpMessage({
      header: appendJsonContentType({}),
      url: url,
      httpMethod: localStorage.getItem("api_method_availability"),
      httpBody,
    });

  },

}
