// Customizable Area Start
import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography, IconButton, AppBar, Toolbar, } from '@material-ui/core';
import { InitiateButton } from '../../../components/src/design-system/button';
import { useRouteInfo } from '../../../components/src/utils/router-context';
import { makeStyles } from '@material-ui/core/styles';
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { HideContainer } from '../../../components/src/shared/hide-container';
import {icons} from "../../../components/src/constants";

const useStyles = makeStyles({
  root: {
    position: 'fixed', top: '0', left: '0', width: '100vw'
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop:'-3%',
    borderBottom: '0.1px solid #eee',


  },
  removeBorder: {
    borderBottom: "none"
  },
  spaceBetween1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft:'-5%',
    alignItems: 'center',
  },
  appBar: {
    boxShadow: 'none',
    padding: '12px 0',
  },
  title: {
    fontWeight: 'bold',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
  },
});

export function RegisterationMobileHeader({
  title,
  onSkip,
  hideSkipButton,
  isWorkEmailPage
}: {
  title: string,
  onSkip?: () => void,
  hideSkipButton?: boolean,
  isWorkEmailPage?: boolean
}) {

  const classes = useStyles();
  const { goBack } = useRouteInfo();

  const isMobile = useIsMobileScreen();

  if (!isMobile)
    return null;

  return (
    <div className={classes.root}>

      <AppBar color="inherit" className={classes.appBar}>
        <Toolbar className={`${classes.spaceBetween} ${isWorkEmailPage && classes.removeBorder}`}>

          <div className={classes.spaceBetween1}>
            <IconButton onClick={goBack}>
              <img src={icons.back} />
            </IconButton>

            <Typography className={classes.title}>
              {title}
            </Typography>
          </div>


          <HideContainer hideWhen={hideSkipButton}>
            <InitiateButton
              variant="text"
              onClick={onSkip}
              color="primary"
              size="small"
            >
              Skip
            </InitiateButton>
          </HideContainer>

        </Toolbar>
      </AppBar>

    </div>
  );
}
// Customizable Area End
