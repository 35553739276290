// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
  withStyles,
  createStyles,
  Theme,
  Grid
} from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { styled } from "@material-ui/styles";
import CheckIcon from '@material-ui/icons/Check';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
// @ts-ignore
import classesOuter from '../../../ContentManagement/src/Followers.module.css';
import CurrentPlanInfoController, {
  Props, IPointData
} from "./CurrentPlanInfoController.web";
import ContentManagement from "../../../ContentManagement/src/ContentManagement.web";
import { icons } from "../../../../components/src/constants";
import Spinner from "../../../../components/src/Spinner.web";
// Customizable Area End

class CurrentPlanInfo extends CurrentPlanInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getDateDifference = () => {
    const start = new Date();
    const end = new Date(this.state.endDate);

    const timeDiff = end.getTime() - start.getTime();

    if (timeDiff <= 0) {
      return "0 days left";
  }

    let yearDiff = end.getFullYear() - start.getFullYear();
    let monthDiff = end.getMonth() - start.getMonth();
    let daysLeft = end.getDate() - start.getDate();

    if (daysLeft < 0) {
      monthDiff--;
      const lastDayOfPreviousMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
      daysLeft += lastDayOfPreviousMonth;
  }
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff = 12 + monthDiff;
    }

    const parts = [];

    if (yearDiff > 0) {
      parts.push(`${yearDiff} year${yearDiff === 1 ? '' : 's'}`);
    }
    if (monthDiff > 0) {
      parts.push(`${monthDiff} month${monthDiff === 1 ? '' : 's'}`);
    }
    if (daysLeft > 0) {
      parts.push(`${daysLeft} day${daysLeft === 1 ? '' : 's'}`);
    }
    return parts.length ? parts.join(' and ') + ' left' : '0 days left';
  }



  calculateTimeProgress = () => {
    const start = new Date(this.state.startDate).getTime();
    const end = new Date(this.state.endDate).getTime();
    const now = new Date().getTime();

    const totalDuration = end - start;
    const timeElapsed = now - start;

    const progressPercentage = (timeElapsed / totalDuration) * 100;
    return Math.min(Math.max(progressPercentage, 0), 100);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      // @ts-ignore
      <ContentManagement navigation={this.props.navigation} id={""} selectedTab="Dashboard" children={undefined} showBackButton={undefined} title={"Followers"} showBottomBar={false} showDrawerHeader={false} mobileOpen={false} open={false} classes={undefined} history={undefined} titleColor={undefined} showLine={undefined}>
        <Spinner spinnerModal={this.state.loading} />
        <Container maxWidth="xl" style={{ paddingBottom: "80px" }}>
          <Wrapper>
            <Box className={classesOuter.followersNPmainDiv} mb="40px">
              <Box padding="25px 30px" pb="35px">
                <Box className={classesOuter.followersNPheading}>
                  <Box display="flex" alignItems="center">
                    <Link to={`${localStorage.getItem("routePath") ?? "/ContentManagementDashBoard"}`}>
                      <IconButton
                        className="back-btn"
                        data-test-id="back-btn"
                      >
                        <img src={icons.back} />
                      </IconButton>
                    </Link>
                    <Typography className="page-title">Non-profit organisation subscriptions</Typography>
                  </Box>
                  <Box mt="15px" mb="25px">
                    <Typography className="current-plan">Current Plan</Typography>
                  </Box>
                  <Box className="plan-details">
                    <Box className="main-card">
                      <div className="firstBox">
                        <div className="planText">Plan</div>
                        <div className="planType">{this.state.title}</div>
                      </div>
                      <div className="firstBox">
                        <div className="planText">Payment</div>
                        <div className="planType sub-text"><span className="text">£ {this.state.price}</span> <span className="planText time">{this.state.duration}</span></div>
                      </div>
                      <Box className="options">
                        <Link
                          to={{
                            pathname: "/SubscriptionPlan",
                            state: {
                              isAvailable: false,
                              text: "Upgrade",
                              description: "Change your plan according to your needs"
                            }
                          }}
                          className="changePlan"
                          data-testId={"changeBtn"}
                        >
                          Change Plan
                        </Link>
                        <div
                          className="cancelPlan"
                          data-testId={"cancelBtn"}
                          onClick={() => this.setState({ isModalOpen: true })}
                        >
                          Cancel Subscription
                        </div>
                      </Box>
                    </Box>
                    <div className="subscriptionDaysText">Subscription days</div>
                    <BorderLinearProgress className="progressDaysBar" variant="determinate" value={this.calculateTimeProgress()} />
                    <Box className="infoText">{this.getDateDifference()}</Box>
                    {parseFloat(this.state.price) !== 0 &&
                      <Box display="flex" alignItems="center" marginLeft="40px" mt="8px">
                        <label className="switch">
                          <input
                            data-test-id="flexible"
                            type="checkbox"
                            name="isPhoneNumberPrivate"
                            checked={this.state.isAutoRenew}
                            onChange={this.onChangeAutoRenew}
                          />
                          <span className="slider round" />
                        </label>
                        <div className="autoRenewtext">Auto-renew every 3 months</div>
                      </Box>
                    }
                    <Box mb="15px" className="subscriptionDaysText">Plan details</Box>
                    <Box ml="40px">
                      {this.state.descriptions.map((description: string, index2: number) => (
                        <Box display="flex" mb="15px" key={`${index2 + 1}`}>
                          <CheckIcon className="check" />
                          <Typography className="description">{description}</Typography>
                        </Box>
                      ))}
                      {this.state.notes.map((note: string, index3: number) => (
                        <Box display="flex" mb="15px" key={`${index3 + 1}`}>
                          <span className="star">*</span>
                          <Typography className="notes">{note}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {parseFloat(this.state.price) !== 0 &&
              <Box className="bottom-section">
                <div className="get-discount">Get discount with your hard-earned points</div>
                <Grid container justifyContent="center">
                  <Grid item xs={3} md={4}>
                    <Box>
                      <div className="info">You have got</div>
                      <div className="total-point"><span>{this.state.totalPoint}</span>Points</div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={4}>
                      {this.state.pointsData.map((points: IPointData, index: number) => (
                        <Grid item xs={12} sm={6} md={4} key={`${index + 1}`}>
                          <div className="discount">
                            <div className="amount">£ {points.amount}</div>
                            <div className="points">{points.point} points</div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            }
          </Wrapper>
          <CustomDialog
            open={this.state.isModalOpen}
            fullWidth={false}
            maxWidth={"sm"}
            scroll="body"
            aria-describedby="scroll-dialog-description"
            aria-labelledby="scroll-dialog-title"
            onClose={() => { }}
          >
            <DialogContent>
              <Box textAlign="center">
                <CancelOutlinedIcon className="close-btn" />
                <Box>
                  <div className="cancel-text">Do you want to cancel subscription?</div>
                </Box>
                <Box mt="25px">
                  <Button
                    data-testId="cancel-subscription"
                    className="cancel-btn"
                    onClick={this.onCancelSubscription}
                  >
                    Cancel Subscription
                  </Button>
                  <Button
                    className="go-back"
                    data-testId="goBackBtn"
                    onClick={() => this.setState({ isModalOpen: false })}
                  >
                    Go Back
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </CustomDialog>
        </Container>
      </ContentManagement >
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withRouter(CurrentPlanInfo);
const Wrapper = styled(Box)({
  "& .back-btn": {
    padding: "0px",
    marginRight: "12px"
  },
  "& .MuiTypography-root": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold"
  },
  "& .page-title": {
    fontSize: '22px',
  },
  "& .current-plan": {
    fontSize: "20px",
    letterSpacing: "-0.55px"
  },
  "& .plan-details": {
    boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
    borderRadius: 12,
    padding: "15px 0px"
  },
  "& .main-card": {
    display: 'flex',
    justifyContent: 'space-between',
    "@media(max-width: 1000px)": {
      display: 'block',
    }
  },
  "& .options": {
    display: 'flex',
    verticalAlign: 'middle',
    marginTop: 35,
    marginRight: 60,
    "@media(max-width: 1000px)": {
      marginLeft: "40px",
      marginTop: "0px"
    }
  },
  "& .description": {
    lineHeight: "17px",
    fontSize: "14px",
    fontFamily: "Rene_Bieder_Milliard_Book",
    letterSpacing: "-0.35px"
  },
  "& .firstBox": {
    padding: "10px 40px"
  },
  "& .planText": {
    color: "#87888f",
    fontFamily: "Rene_Bieder_Milliard_Medium, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.095454544px",
    "&.time": {
      fontSize: "16px"
    }
  },
  "& .planType": {
    wordBreak: "break-word",
    fontFamily: "Rene_Bieder_Milliard_Medium, sans-serif",
    fontSize: "26px",
    fontWeight: "500",
    letterSpacing: "0.17727272px",
    "& .text": {
      whiteSpace: "nowrap"
    },
    "&.sub-text": {
      whiteSpace: "nowrap"
    }
  },
  "& .changePlan": {
    textDecoration: "none",
    color: "#009be2",
    fontFamily: "Rene_Bieder_Milliard_Medium, sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0.10909091px",
    marginRight: "10px",
    cursor: "pointer",
    whiteSpace: "nowrap"
  },
  "& .cancelPlan": {
    color: "#87888f",
    fontFamily: "Rene_Bieder_Milliard_Medium, sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0.10909091px",
    cursor: "pointer",
    whiteSpace: "nowrap"
  },
  "& .subscriptionDaysText": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold, sans-serif",
    fontSize: "18px",
    fontWeight: "500",
    letterSpacing: "0.122727275px",
    marginTop: "40px",
    marginLeft: "40px"
  },
  "& .progressDaysBar": {
    width: "40%",
    margin: "10 0 5 40"
  },
  "& .infoText": {
    color: "#87888f",
    fontFamily: "Rene_Bieder_Milliard_Book, sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0.10909091p",
    marginLeft: "40px"
  },
  "& .check": {
    marginRight: "5px",
    width: "20px",
    height: "20px",
    color: "#009be2"
  },
  "& .notes": {
    fontFamily: "Rene_Bieder_Milliard_Book",
    color: "#87888f",
    fontSize: "13px",
  },
  "& .star": {
    color: "#009be2",
    margin: "0px 10px",
    marginTop: "4px"
  },
  "& .autoRenewtext": {
    color: "#000501",
    fontFamily: "Rene_Bieder_Milliard_Book, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    marginLeft: "10px"
  },
  "& .bottom-section": {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
    borderRadius: 12,
    padding: "45px 70px",
    "& .get-discount": {
      fontFamily: "Rene_Bieder_Milliard_SemiBold, sans-serif",
      fontSize: "24px",
      letterSpacing: "-0.7px",
      marginBottom: "20px"
    },
    "& .info": {
      fontFamily: "Rene_Bieder_Milliard_Book, sans-serif",
      fontSize: "18px",
      letterSpacing: "-0.45px",
    },
    "& .total-point": {
      fontFamily: "Rene_Bieder_Milliard_Book, sans-serif",
      fontSize: "18px",
      letterSpacing: "-0.45px",
      color: "#87888f",
      display: "flex",
      alignItems: "center",
      "& span": {
        marginRight: "10px",
        color: "#009be2",
        fontFamily: "Rene_Bieder_Milliard_SemiBold, sans-serif",
        fontSize: "35px"
      }
    },
    "& .discount": {
      boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
      borderRadius: 5,
      padding: "15px 10px",
      textAlign: "center",
      "& .amount": {
        fontFamily: "Rene_Bieder_Milliard_Medium, sans-serif",
        letterSpacing: "-0.65px",
        fontSize: "24px"
      },
      "& .points": {
        fontFamily: "Rene_Bieder_Milliard_Medium, sans-serif",
        color: "#87888f",
        letterSpacing: "-0.45px",
        fontSize: "16px",
        textTransform: "uppercase"
      }
    }
  }
});

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    boxShadow: "0px 1px 3px rgba(0,0,0,0.2),0px 2px 1px -1px rgba(0,0,0,0.12),0px 1px 1px rgba(0,0,0,0.14)"
  },
  "& .MuiDialogContent-root": {
    padding: "40px 65px",
    paddingTop: "30px",
    "media(max-width: 576px)": {
      padding: "30px"
    }
  },
  "& .close-btn": {
    color: "#ff7070",
    height: "55px",
    width: "55px",
    fontSize: "25px",
    marginBottom: "20px",
  },
  "& .cancel-text": {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: "20px"
  },
  "& .cancel-btn": {
    border: "1px solid #009be2",
    borderRadius: "5px",
    color: "#009be2",
    backgroundColor: "#fff",
    padding: "8px 15px",
    marginRight: "25px",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    letterSpacing: "-0.375px"
  },
  "& .go-back": {
    border: "1px solid #009be2",
    borderRadius: "5px",
    color: "#fff",
    backgroundColor: "#009be2",
    padding: "8px 35px",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    letterSpacing: "-0.375px"
  }
});

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 5,
      height: 9,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      backgroundColor: '#1a90ff',
      borderRadius: 5,
    },
  }),
)(LinearProgress);
export { CurrentPlanInfo };
// Customizable Area End