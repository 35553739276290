import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


import { isMobile } from "react-device-detect";
const { Client } = require('@twilio/conversations');


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string; 
}
interface S {
  openModal: boolean;
  isMobile: boolean;
  searchBar: boolean;
  buttonModal:boolean,
  userEmail: string | null;
  userToken: string | null;
  searchTerm: string;
	currentPage: number;
	currentChat: any;
	loading: boolean;
  selectedTab:number,
  	chatsList: any;
  memberList:any;
	totalPages: number;
	paginationLoading: boolean;
  messageId: number | string | null;
}

interface SS {
  id: any;
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  groupChatListNp: string = "";
  connectionChatListNp:string ="";
  generateTokenMsg: string = "";
  chatClient: any;
	paginationChat: string = "";
	listingContainer: any;
  
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.getChatToken = this.getChatToken.bind(this);
    this.getChatList = this.getChatList.bind(this);
    this.getEntityChatList= this.getEntityChatList.bind(this);
		this.searchGroup = this.searchGroup.bind(this);
		this.getNextChat = this.getNextChat.bind(this);
		this.handlePagination = this.handlePagination.bind(this);
    this.clearChat = this.clearChat.bind(this);
    this.getParticipantId = this.getParticipantId.bind(this);
    this.handleMessageList = this.handleMessageList.bind(this);
    this.handleChannelUpdated = this.handleChannelUpdated.bind(this);
    this.getLatestReceiptsData = this.getLatestReceiptsData.bind(this);
    this.updateChannelData = this.updateChannelData.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      
    ];
    this.state = {
      openModal: false,
      isMobile: false,
      searchBar: false,
      buttonModal:false,
      userEmail: "",
      userToken: "",
      searchTerm: "",
			currentPage: 1,
			currentChat: {},
			loading: true,
      selectedTab:1,
      chatsList: [],
      memberList:[],
			totalPages: 0,
			paginationLoading: false,
      messageId: null
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message?.id) {
      const id = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      this.setState({ messageId: id });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.groupChatListNp) {
        this.setState({
          loading: false,
          chatsList: responseJson.data,
          currentPage: responseJson.meta.data.current_page,
          totalPages: responseJson.meta.data.total_pages,
        }, 
        () => { this.handleRedirection(responseJson)}
        );
        this.addEvents(responseJson.data);
      }
      if (apiRequestCallId === this.connectionChatListNp) {
        this.setState({
          loading: false,
          memberList: responseJson.data,
          currentPage: responseJson.meta.data.current_page,
          totalPages: responseJson.meta.data.total_pages,
        }, () => { this.handleRedirection(responseJson)});
        
        this.addEvents(responseJson.data);
      }
      if (apiRequestCallId === this.generateTokenMsg  && !this.chatClient) {
        this.chatClient = new Client(responseJson.token);
        this.chatClient.on('initialized', () => {});
        
        this.chatClient.on('initFailed', (error: any) => {});
      }


			if (apiRequestCallId === this.paginationChat) {
        this.setState({
          chatsList: [...this.state.chatsList, ...responseJson.data],
          totalPages: responseJson.meta.data.total_pages,
          paginationLoading: false,
				});
        this.addEvents(responseJson.data);
			}

    }
    
  }

  async componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    const userEmail = localStorage.getItem('user_email');
    const userToken = localStorage.getItem('nonprofitToken');
    this.setState({
      userEmail,
      userToken,
    });
        this.getChatToken(userEmail, userToken);
       this.getEntityChatList();
  }

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
		if (!this.listingContainer) {
			this.listingContainer = document.getElementById('chatList');
		} else {
			this.listingContainer.removeEventListener('scroll', this.handlePagination);
		}
		if (this.listingContainer) {
			this.listingContainer.addEventListener('scroll', this.handlePagination)
		}
	}
  handleRedirection = (responseJson: any) => {
    if (this.state.messageId) {
      const obj = responseJson.data.filter((item: any) => {
        return item.attributes.sid === this.state.messageId;
      });
      this.handleOpen(obj[0]);
    }
  }
  resize() {
    this.setState({
      isMobile: window.innerWidth <= 760 ? true : false,
    });
    if (window.innerWidth >= 760) {
      this.setState({ isMobile: true });
    }
  }

	handlePagination = (e: any) => {
		const scrollableHeight = this.listingContainer.scrollHeight - this.listingContainer.clientHeight

		if (
			this.listingContainer.scrollTop >= scrollableHeight &&
			!this.state.paginationLoading &&
			this.state.currentPage < this.state.totalPages
		) {
				this.setState({
						currentPage: this.state.currentPage + 1,
						paginationLoading: true
				}, () => {
						this.getNextChat();
				});
		}
	}

  componentWillUnmount(): any {
    this.state.chatsList.forEach(async (chat: any) => {
      const channelOb = await this.chatClient.getConversationBySid(chat.attributes.sid);
      if (channelOb._internalState.status != 'joined') {
        await channelOb.join();
      }
      channelOb.removeListener("messageAdded", this.handleMessageList);
      channelOb.removeListener('updated', this.handleChannelUpdated);
    });
    window.removeEventListener("resize", this.resize.bind(this));
  }

  handleOpen = (item: any) => {
    const plan = localStorage.getItem("plan");
    if(plan === "Presence-Only"){
      return;
    }
		if (isMobile) {
			this.props.navigation.navigate("NpChat", {
				chatInfo: item
			});
		} else {
			this.setState({
				openModal: true,
				currentChat: item
			})
		}
    this.setState({messageId:null})
      };
  handleClose = () => {
    this.setState({ openModal: false, currentChat: {} });
  };
  getChatToken = (userEmail: string | null, userToken: string | null) => {
    if (this.chatClient) return;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'token': userToken,
    };
    
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.generateChatToken}${userEmail}`
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
      
    this.generateTokenMsg = request.messageId;
    runEngine.sendMessage(request.id, request);
  }
	getChatList = () => {
    this.setState({ loading: true });
    const { userToken, searchTerm } = this.state;
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        'token': userToken,
        'type': 'non_profit_entity'
    };
   
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    let url = `${configJSON.chatList}group&page=${1}&per_page=20`;
    if (searchTerm.trim().length > 0) {
        url += `&search_term=${searchTerm}`;
    }
    request.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
    );

    request.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    this.groupChatListNp = request.messageId;
    runEngine.sendMessage(request.id, request);
	}
  getEntityChatList = () => {
    this.setState({ loading: true });
    const { searchTerm } = this.state;
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        'token': localStorage.getItem('nonprofitToken'),
        'type': 'non_profit_entity'
    };
   
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    let url = `${configJSON.chatList}connection&page=${1}&per_page=20`;
    if (searchTerm.trim().length > 0) {
        url += `&search_term=${searchTerm}`;
    }
    request.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
    );

    request.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    this.connectionChatListNp = request.messageId;
    runEngine.sendMessage(request.id, request);
	}
  searchGroup = (event: any) => {
    this.setState({ searchTerm: event.target.value });
  }

	debounce = () => {
		let timeout = 500;
		let timer: any;
		return (...args: any) => {
				clearTimeout(timer);
				timer = setTimeout(() => { 
          this.state.selectedTab===3?
          this.getChatList():this.getEntityChatList() }, timeout);
		};
	}
	getNextChat = () => {    
		const { userToken, searchTerm, currentPage } = this.state;
		const header = {
				"Content-Type": configJSON.validationApiContentType,
				'token': userToken,
				'type': 'non_profit_entity'
		};
		
		const request = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
		);
		request.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
		);
		this.paginationChat = request.messageId;

		let url = `${configJSON.chats}group&page=${currentPage}&per_page=20`;
		if (searchTerm.trim().length > 0) {
			url += `&search_term=${searchTerm}`;
		}
		request.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			url
		);

		request.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);

		runEngine.sendMessage(request.id, request);
	}
  clearChat = (channelSid: string, clearChats: Array<any>) => {
    let chats = [...this.state.chatsList];
    chats.forEach((chat: any, index: number) => {
        if (chat.attributes.sid == channelSid) {
          chats[index].attributes.clear_chat = clearChats;
        }
    });
    this.setState({
        chatsList: chats
    });
  }

  addEvents = (chats: any) => {
    chats.forEach(async  (chat: any) => {
      const channelOb = await this.chatClient.getConversationBySid(chat.attributes.sid);
      if (channelOb._internalState.status != 'joined') {
          await channelOb.join();
      }
      channelOb.on("messageAdded", this.handleMessageList);
      channelOb.on('updated', this.handleChannelUpdated);
    });
  }

  getParticipantId = async (channelOb: any, memberData: any) => {
    const participants = await channelOb.getParticipants();
    const oldIds: Array<string> = [];
    memberData.forEach((item: any) => {
        if (item.email == this.state.userEmail && 'user_entity' == item.type.toLowerCase()) {
          oldIds.push(item.participant_id);
        }
    });
    const participant = participants.filter((participant: any) => {
      return participant.state.identity == this.state.userEmail && oldIds.indexOf(participant.state.sid) == -1;
    });
    return participant[0].state.sid;
  }

  getLatestReceiptsData = (
    delivery: any,
    chatDelivery: any,
    converationRead: any,
    chatRead: any,
  ) => {
      let read: any = chatRead;
      
      Object.keys(converationRead).forEach((participantId) => {
        let messageIndex = participantId && !(participantId in chatRead) ||
        chatRead[participantId] < converationRead[participantId] ? converationRead[participantId] :
        chatRead[participantId];
        read[participantId] = messageIndex;
      });
      
      let deliveries: any = chatDelivery;
      Object.keys(delivery).forEach((participantId) => {
          let messageIndex = participantId && !(participantId in chatDelivery) ||
              chatDelivery[participantId] < delivery[participantId] ? delivery[participantId] :
              chatDelivery[participantId];
          deliveries[participantId] = messageIndex;
      });
      return { deliveries, read };
  }

  updateChannelData = async (conversation: any) => {
    await Promise.all(this.state.chatsList.map(async (chat: any) => {
      return new Promise(async (resolve, reject) => {
        if (chat.attributes.sid == conversation.sid) {
          const participantId = await this.getParticipantId(conversation, chat.attributes.member_data);
          let callUpdateGroup = false;
          if (!(participantId in chat.attributes.delivery) || chat.attributes.delivery[participantId] < conversation._internalState.lastMessage.index) {
            chat.attributes.delivery[participantId] = conversation.lastMessage.index;
            callUpdateGroup = true;
            if (conversation._internalState.lastReadMessageIndex < conversation.lastMessage.index) {
              conversation.updateLastReadMessageIndex(conversation.lastMessage.index);
            }
          }
          const { deliveries, read } = this.getLatestReceiptsData(
            conversation._internalState.attributes.delivery,
            chat.attributes.delivery,
            conversation._internalState.attributes.read,
            chat.attributes.read
          );
          chat.attributes.delivery = deliveries;
          chat.attributes.read = read;
          chat.attributes.read_count = Object.values(chat.attributes.read).filter((msgId: any) => msgId == conversation._internalState.lastMessage.index).length;
          if (callUpdateGroup) {
            conversation.updateAttributes({ ...conversation.attributes, delivery: deliveries });
          }
          resolve(chat);
        } else {
          resolve(chat);
        }
      })
    })).then((values: any) => {
      this.setState({
        chatsList: values
      });
    });
  }

  handleChannelUpdated = async (data: { conversation: any, updateReasons: Array<string> }) => {
    const { conversation, updateReasons } = data;
    if (updateReasons.indexOf('attributes') == -1) return;
    this.updateChannelData(conversation);
  }

  getLastMessageStatus = async (channelOb: any, memberData: Array<any>, delivery: any, chatRead: any, lastMessageIndex: number) => {
    let deliveryData = delivery;
    const participantId = await this.getParticipantId(channelOb, memberData);
    if (!(participantId in delivery) || delivery[participantId] < lastMessageIndex) {
      delivery[participantId] = lastMessageIndex;
    }
    const receiptData = this.getLatestReceiptsData(
      channelOb._internalState.attributes.delivery,
      delivery,
      channelOb._internalState.attributes.read,
      chatRead
    );
    deliveryData = receiptData.deliveries;

    channelOb.updateAttributes({ ...channelOb.attributes, delivery: deliveryData });
    channelOb.updateLastReadMessageIndex(lastMessageIndex);
    return receiptData;
  }

  handleMessageList = async (messageData: any) => {
    const { chatsList } = this.state;
    let chats: any = [];
  
    for (const chat of chatsList) {
      if (chat.attributes.sid == messageData.conversation.sid) {
        let receiptData = await this.getLastMessageStatus(messageData.conversation, chat.attributes.member_data ?? [], chat.attributes.delivery, chat.attributes.read, messageData.index);
        chat.attributes.delivery = receiptData.deliveries;
        chat.attributes.read = receiptData.read;
        chat.attributes.read_count = Object.values(chat.attributes.read).filter((msgId: any) => msgId == messageData.conversation._internalState.lastMessage.index).length;
        chats = [
          {...chat},
          ...chats,
        ]
      } else {
        chats.push(chat);
      }
    }
    this.setState({
        chatsList: chats
    });
  }

  
}
