import React from "react";
import {Grid, Box, Button} from '@material-ui/core'
import { FAMILYFONTS } from "../../../components/src/constants";
import { logo } from "../../email-account-registration/src/assets";
import EmailNotificationsController from "./EmailNotificationsController";

export default class NpLandingPage extends EmailNotificationsController{
    render() {
        return (
            <>
        <Grid container style={{backgroundColor: '#fff'}}>
          <Grid className="mainContainer">      
            <Box className="landingPagelogo">
              <img className="logoImg" src={logo} alt="logo" />
            </Box>
            <Box className="buttonContainer">
              <Box className="landingPageDarkButton">
                <Button
                  type="submit"
                  style={{
                    ...FAMILYFONTS.family1,
                  }}
                  onClick={this.navToSignIn}
                  data-testId = 'npLogin'
                >
                  Sign in as
                </Button>
              </Box>
              <Box className="landingPageLightButton">
                <Button
                  type="submit"
                  style={{
                    ...FAMILYFONTS.family1,
                  }}
                  data-testId = 'npReg'
                  onClick={this.navToReg}
                >
                  Register as
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
        )
    }
}