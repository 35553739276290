import React from "react";
// Customizable Area Start
import PropTypes from 'prop-types';
import ServiceSpecificSettingsAdminController from "./ServiceSpecificSettingsAdminController";
import BusDashbaordWeb from "./BusDashboard.web";
// @ts-ignore
import styles from "../../events/src/NonProfitProfile.module.css";
import { styled } from "@material-ui/styles";
import { icons, FONTS } from "../../../components/src/constants";
import FilledInput from "@material-ui/core/FilledInput";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckoutForm from "../../../components/src/custom/CheckOutForm.web";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    Button, Typography, Tabs, Tab, Card, IconButton, Dialog, Checkbox, Box
} from '@material-ui/core'
import moment from "moment";
import CustomSuccessModel from "../../../components/src/custom/CustomSuccessModel";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const configJSON = require("./config");;
const baseURL = require("../../../framework/src/config.js").baseURL;
let stripePubKey = configJSON.STRIPE_KEY_LIVE;
if (!baseURL.includes(".prod")) {
  stripePubKey = configJSON.STRIPE_KEY_TEST;
}
const stripePromise = loadStripe(stripePubKey);

// Customizable Area End

// Customizable Area Start
export default class BusNonProfitProfile extends ServiceSpecificSettingsAdminController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }

    event_type_bg_color = (type: any) => {
        if (type === "need") {
            return "#03BD95";
        } else if (type === "project_funding") {
            return "#049BE1";
        } else if (type === "activity") {
            return "#B4038C";
        } else {
            return "#009BE2";
        }
    }

    event_type_name = (type: any) => {
        if (type === "need") {
            return "Volunteer";
        } else if (type === "project_funding") {
            return "Donate";
        } else if (type === "activity") {
            return "Attend";
        } else {
            return "Volunteer";
        }
    }

    currentData(item: any) {
        return (
            <Card className={styles.cardInner}>
                <div className={styles.leftCard}>
                    <img
                        className={styles.cardImage}
                        src={item?.event_image?.url ? item?.event_image?.url : "https://images.unsplash.com/photo-1591197172062-c718f82aba20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"}
                        alt="event image" />
                </div>
                <div className={styles.rightCard}>
                    <Typography className={styles.eventTitle}>
                        {item.event_name}
                    </Typography>
                    <div className={styles.innerInfo}>
                        <div
                            className={styles.userInfo}
                        >
                            <div className={styles.avatarImgcontainer}>
                                <img src={this.props.NpDetais.attributes?.logo ? this.props.NpDetais.attributes?.logo?.url : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"} alt="" className={styles.avatarImg} />
                            </div>
                            <Typography
                                variant="body1"
                            >
                                {this.props.NpDetais.attributes?.organisation_name}
                            </Typography>
                        </div>
                        <div className={styles.imageTextWrapper}>
                            <div className={styles.avatarImgcontainer}>
                                <img
                                    src={icons.calender}
                                    alt="avatar"
                                    className={styles.avatarImg}
                                />
                            </div>
                            <span className={styles.eventdate_loc}>
                                {moment(item.from_date).format('DD MMM YYYY')}, {moment(item.start_time).format('HH:mm a')}
                            </span>
                        </div>
                        <div className={styles.imageTextWrapper}>
                            <div className={styles.avatarImgcontainer}>
                                <img
                                    src={icons.locationred}
                                    alt="location"
                                    className={styles.avatarImg}
                                />
                            </div>
                            <span className={styles.eventdate_loc}>
                                {item.location}
                            </span>
                        </div>
                    </div>
                </div>
                <span className="timeline" style={{ background: this.event_type_bg_color(item.event_type) }}>{this.event_type_name(item.event_type)}</span>
            </Card>
        )
    }

  eventCard = () => {
    const { NpDetais } = this.props;
    return (
      NpDetais?.attributes.upcoming_events?.length > 0 ?
        <div className="cardWrapper">
          {NpDetais.attributes.upcoming_events.map((item: any) => (
            this.currentData(item)
          ))}
        </div>
        : <div className="not-available">No events available</div>
    )
  }

    followBtn = () => {
        return (
            this.state.followStatus ? <Button
                variant="outlined"
                color="primary"
                data-testId={'unfollowBtn'}
                disabled={this.isDisabled()}
                onClick={() => this.unfollowNpProfile(this.props.NpDetais.attributes.id)}
                className="btn following-btn"
            >
                Following
            </Button> :
                <Button
                    variant="outlined"
                    className="btn follow-btn"
                    color="primary"
                    data-testId={'followBtn'}
                    disabled={this.isDisabled()}
                    onClick={() => this.setState({ followAlertModal: true })}
                >
                    Follow
                </Button>
        )
    }

    followAlertDialog = () => {
        return (
            <Dialog
                hideBackdrop
                fullScreen={false}
                open={this.state.followAlertModal}
                onClose={() => this.setState({ followAlertModal: false })}
                aria-labelledby="responsive-dialog-title"
                data-testId='closeModel'
                PaperProps={{
                    style: { borderRadius: 16, width: 500, boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)" }
                }}>
                <div
                    style={{
                        fontFamily: "Rene_Bieder_Milliard_SemiBold",
                        fontSize: '1.25rem',
                        lineHeight: 1.6,
                        letterSpacing: '0.0075em',
                        padding: '20px'
                    }}>
                    Select alerts you would like to receive.
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Select All</Typography>
                    <Checkbox
                        color="primary"
                        name="selectAll"
                        data-testId="selectAll"
                        onChange={(e) => this.handleAllOptions(e)}
                        checked={this.state.followOptionsModal.selectAll}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Published need</Typography>
                    <Checkbox
                        color="primary"
                        name="publishedNeed"
                        data-testId="publishedNeed"
                        checked={this.state.followOptionsModal.selectAll || this.state.followOptionsModal.publishedNeed}
                        onChange={(e) => this.handleAllOptions(e)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Published upcoming activity</Typography>
                    <Checkbox
                        color="primary"
                        name="publishedActivity"
                        data-testId="publishedActivity"
                        checked={this.state.followOptionsModal.selectAll || this.state.followOptionsModal.publishedActivity}
                        onChange={(e) => this.handleAllOptions(e)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Published project funding</Typography>
                    <Checkbox
                        color="primary"
                        name="publishedProjectFund"
                        data-testId="publishedProjectFund"
                        checked={this.state.followOptionsModal.selectAll || this.state.followOptionsModal.publishedProjectFund}
                        onChange={(e) => this.handleAllOptions(e)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Broadcasted message</Typography>
                    <Checkbox
                        color="primary"
                        name="broadcastedMsgs"
                        data-testId="broadcastedMsgs"
                        checked={this.state.followOptionsModal.selectAll || this.state.followOptionsModal.broadcastedMsgs}
                        onChange={(e) => this.handleAllOptions(e)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', padding: '10px', marginBottom: 20 }}>
                    <Button variant="outlined" className={styles.cancelBtn}  data-testId='cancelBtn' onClick={() => this.setState({ followAlertModal: false})}>Cancel</Button>
                    <Button variant="contained" className={styles.saveBtn}  data-testId='saveBtn' onClick={() => this.followNotifications(this.props.NpDetais.attributes?.id)}>Save</Button>
                </div>
            </Dialog>
        )
    }

    openSuceessDialog = () => {
      return (
        <Dialog
          className="dialogWrapDonation"
          open={this.state.openSuccessDialog}
          data-test-id="modal-close"
          >
          <DialogWrapper>
            <div className="dialogWrapper">
              <div className={"dialogWrapperInner"}>
                <img src={icons.successIcon} />
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography className={`${styles.userName} ${styles.message} titleModal`}>
                  Thank you for your donation!
                </Typography>
                <div className={`${styles.donatedPoints} descriptionModal`}>
                  You have successfully donated <span>  
                    {`£${this.state.donatedAmount}`}
                   </span> to
                  '{`${this.props.NpDetais?.attributes.organisation_name}`}'
                </div>
                <Button
                  data-test-id="backtoProfile"
                  className={"goToProfile"}
                  onClick={() => this.props.onBack()}
                  >
                  GO BACK TO DASHBOARD
                </Button>
              </div>
            </div>
          </DialogWrapper>
        </Dialog>
      );
    };
   

    openStripePage = () => {
        const { amountValue, userEntered, pointsRange } = this.state;
        return (
            <PaymentDialog>
              <div className="header">
              <img src={icons.back} alt="back" data-testId='backIcon' 
                onClick={this.handleBackClick} style={{ cursor: "pointer"}}/>
              <p className="titleModal"> Make Donation</p>
              </div>
              <div className="topHeader"> 
                <div className="sub-title">Payment</div>
                <div className="sub-title">Donation Details</div>
              </div>
              <div className="content">
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  type="r1"
                  data-testId="checkoutForm"
                  redirect={() => this.props.navigation.navigate("ContentManagementDashBoard")}
                  onChangeState={this.onChangeState}
                  donationAmount={amountValue !== null ? pointsRange[amountValue] : userEntered}
                  eventId={this.props.NpDetais.id}
                  destination_account={this.props.NpDetais?.attributes?.destination_account}
                />
              </Elements>
              <Box className="checkout">
                <div>
                    <Box justifyContent="space-between" display="flex" mb="10px">
                      <div className="total">Donation for '{this.props.NpDetais.attributes?.organisation_name}'</div>
                      <div className="plan-amount"> £ {this.state.pointsRange[this.state.amountValue] || this.state.userEntered}</div>
                    </Box>
                  <div className="border" />
                  <Box justifyContent="space-between" display="flex">
                    <div className="total">Total</div>
                    <div className="plan-amount">£ {this.state.pointsRange[this.state.amountValue] || this.state.userEntered}</div>
                  </Box>
                </div>
              </Box>
              </div>
          </PaymentDialog>
        );
      };

    renderButtonName = () => {
         if (this.state.amountValue !== null){
          return `Donate £ ${this.state.pointsRange[this.state.amountValue]}`
        } else if (this.state.userEntered){
          return `Donate £ ${this.state.userEntered}`
        } else {
          return "Donate"
        }
      }

    renderPaymentDialog = () => {
        const { amountValue, pointsRange } = this.state;
        return (
          <CustomDialog
            className="dialogWrapDonation"
            open={this.state.openDonateDialog}
            data-test-id="modal-close2"
            onClose={() => this.setState({ openDonateDialog: false })}
            maxWidth="md">
            <div className="title">
            <div>Donation</div>
            <div><HighlightOffIcon  data-test-id="close-btn" className="close-btn" onClick={() => this.setState({ openDonateDialog: false, userEntered: 0, amountValue: null })}/></div>
            </div>
             <div className="mainBtnDiv">
              <Tabs
                onChange={this.handleAmountTabChange}
                value={amountValue}
                className="donateTabs"
                data-test-id="tabs"
                TabIndicatorProps={{
                style: {display: "none"},
                }}>
                  {pointsRange.map((point: number, index: number) => {
                  return (
                    <DonateTab
                      label={`£ ${point}`}
                      key={point}
                      className={`${amountValue === index && "activeBtn"} ${"normalBtn"}`}
                    />);
                  })}
              </Tabs>
            </div>
            <Donation>
              <FilledInput
                type="number"
                id="filled-adornment-amount"
                data-test-id="donationInput"
                className="totalAmount"
                onChange={this.handleDonationAmount}
                value={this.state.userEntered}
                startAdornment={
                <InputAdornment position="start">£</InputAdornment>
                }
                placeholder="Enter donation amount"
              />
            </Donation>
            <div className="spaceBetweenDivs">
            <Button
              className="BlueBtn"
              data-test-id="donate-button"
              onClick={this.handleDonate}>
              {this.renderButtonName()}
            </Button>
          </div>
          </CustomDialog>
        );
      };

    contactNPODialog = () => {
      return (
        <ContactModal
          open={this.state.contactModal}
          onClose={() => this.setState({ contactModal: false })}
          data-testId='closeModel'
          fullScreen={false}
          PaperProps={{
            style: { borderRadius: 16, width: 500, boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)", padding: "40px" }
          }}>
          <div className="modalTitle">Non profit organisation</div>
          <div className="close" data-testId="contactModal" role="button" onClick={() => this.setState({ contactModal: false })}>X</div>
          <Typography variant='h6' className="contactText">
            Contact number: <a className="link" href={`tel:${this.props.NpDetais?.attributes?.contact_number}`}>
              {this.props.NpDetais?.attributes?.contact_number ? "+" + this.props.NpDetais?.attributes?.contact_number : "--"}
            </a>
          </Typography>
        </ContactModal>
      )
    }

    render() {
        const { NpDetais } = this.props
        return (
          <Wrapper>
                {/* @ts-ignore */}
            <BusDashbaordWeb children={this.props.children} classes={this.props.classes} navigation={this.props.navigation} id={""} adminSelectedTab={undefined} theme={undefined}>
              {this.state.openStripePage ? this.openStripePage() :
                <Box marginLeft="280px">
                  <div className="mainDiv">
                    <Box display="flex" alignItems="center" padding="13px 21px">
                      <img src={icons.back} alt="back" className="backIcon" data-testId='backIcon' onClick={() => this.props.onBack()} />
                      <p className="headerText">Non-Profit Profile</p>
                    </Box>
                    <hr className="dividerLine" />
                    <Box padding="20px 95px">
                      <Box display="flex">
                        <div className={styles.orgLogo}>
                          <img src={NpDetais?.attributes?.logo ? NpDetais.attributes.logo?.url : icons.profile} alt="pr" className={styles.orgImg} />
                        </div>
                        <Box marginLeft="50px">
                          <Box marginLeft="30px">
                            <Box mt="25px" display="flex" justifyContent="space-between" alignItems="center">
                              <div>
                                <div className={styles.orgProfileTetx}>{NpDetais?.attributes.organisation_name}</div>
                                <Box display='flex' alignItems="center">
                                  <p>{NpDetais?.attributes?.town ? NpDetais.attributes.town + "," : ''} {NpDetais?.attributes?.country ? NpDetais.attributes.country : '-'}</p>
                                  <img className={styles.locationImg} src={icons.locationred} alt='location' />
                                </Box>
                              </div>
                              {this.followBtn()}
                            </Box>
                            <Box display="flex" justifyContent="space-around" margin="0px 45px" marginTop="54px">
                              <div>
                                <div className={styles.eventsData}>{NpDetais?.attributes?.upcoming_events_count > 9 ? NpDetais.attributes?.upcoming_events_count : `0${NpDetais?.attributes.upcoming_events_count}`}</div>
                                <div className="eventsText">Upcoming events</div>
                              </div>
                              <div>
                                <div className={styles.followersData}>{NpDetais?.attributes?.followers > 9 ? NpDetais.attributes.followers : `0${NpDetais?.attributes.followers}`}</div>
                                <div className="eventsText">Followers</div>
                              </div>
                            </Box>
                          </Box>
                          <hr className="statsLine" />
                          <Box marginLeft="30px">
                            <Box margin="15px 0px" className={styles.aboutText}>About</Box>
                            <div className={styles.description}>{NpDetais?.attributes?.description}</div>
                            <Box marginTop="26px" className={styles.mainBtnDiv}>
                              <Button variant="outlined" className={styles.messageBtn} disabled={this.state.loadingmsg}data-testId='message-btn' startIcon={<IconButton><img src={icons.chats} style={{ width: 22 }} /></IconButton>} onClick={() => this.NpchatNavigate()}>Message</Button>
                              <Button variant="outlined" data-testId="contact-btn" onClick={() => this.setState({ contactModal: true })} className={styles.contactBtn} startIcon={<IconButton><img src={icons.phoneblueIcon} style={{ width: 22 }} /></IconButton>}>Contact</Button>
                              <Button variant="contained" onClick={() => this.setState({ openDonateDialog: true })} className={styles.donateBtn} startIcon={<IconButton><img src={icons.needswhite} style={{ width: 22, transform: 'scaleX(-1)' }} /></IconButton>}>Donate</Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <hr className={styles.dividerOneLine} />
                    <Box position="relative">
                      <div className="mainTabDiv">
                        <div>
                          <Tabs className="customTab" disableRipple value={0} centered>
                            <Tab value={0} disableRipple label="Upcoming events" style={{ fontFamily: "Rene_Bieder_Milliard_SemiBold", color: 'black', textTransform: "none", fontSize: '18px', opacity: 0.8 }} />
                          </Tabs>
                        </div>
                      </div>
                      <hr className={styles.dividerTwoLine} />
                      <Box position="relative">
                        {this.eventCard()}
                      </Box>
                    </Box>
                  </div>
                </Box>
              }
            </BusDashbaordWeb>
            <CustomSuccessModel openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose} msgTitle='Thank you' msg="Your changes have been updated" />
            {this.renderPaymentDialog()}
            {this.followAlertDialog()}
            {this.openSuceessDialog()}
            {this.contactNPODialog()}
          </Wrapper>
        )
    }
}
// Customizable Area End

// Customizable Area Start
const Wrapper = styled(Box)({
  "& .timeline": {
    "position": "absolute",
    "color": "white",
    "fontSize": "16px",
    "right": "37px",
    "top": "33px",
    "borderRadius": "10px",
    "padding": "5px 30px",
    "border": "1px solid #d5d5d5"
  },
  "& .btn": {
    position: 'relative',
    left: 0,
    boxShadow: "none",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    border: "none",
    textTransform: "capitalize",
    color: "white ",
    borderRadius: 10,
    fontSize: "17px",
  },
  "& .follow-btn": {
    backgroundColor: " #009be2",
    padding: "4px 23px",
  },
  "& .following-btn": {
    border:"none",
    backgroundColor: "#E9E9E9",
    color: '#000',
    padding: "4px 12px",
  },
  "& .not-available":{
    "height": "186px",
    "fontSize":"20px",
    "width":"90%",
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center"
  },
  "& .cardWrapper": {
      "height": "510px",
      "margin": "30 55 5",
      "marginRight": "13px",
      "overflow": "auto",
      "paddingRight": "37px",
      "paddingTop": "0px",
      "&::-webkit-scrollbar": {
      "display": "block !important"
    }
  },
  "& .mainTabDiv": {
    "display": "flex",
    "justifyContent": "center",
    "position": "relative",
    "fontFamily": "Rene_Bieder_Milliard_Medium",
    "fontSize": "20px",
    "fontWeight": "400",
    "letterSpacing": "-0.5px",
    "width": "90%"
  },
  "& .statsLine": {
    marginTop: "20px",
    "opacity": "0.2",
    "width": "100%",
    "height": "1px",
    "border": "1px solid #979797"
  },
  "& .eventsText": {
    marginTop: "16px",
    "height": "24px",
    "fontFamily": "Rene_Bieder_Milliard_Medium",
    "fontSize": "20px",
    "fontWeight": "400",
    "letterSpacing": "-0.5px"
  },
  "& .backIcon": {
    width: "23.62px",
    height: "21px"
  },
  "& .dividerLine": {
    "width": "100%",
    "height": "1px",
    "border": "1px solid #979797",
    "boxShadow": "0px 2px 4px rgba(0,0,0,0.5)",
    opacity: ".14127605"
  },
  "& .mainDiv": {
    position: "relative",
    left: "40px",
    top: "40px",
    width: "93%",
    // "height": "200vh",
    "border-radius": "12px",
    "border": "1px dashed #ffffff",
    "background": "#ffffff",
    "backgroundBlendMode": "normal"
  },
  "& .headerText": {
    margin: "0px",
    marginLeft: "34px",
    "width": "auto",
    "fontFamily": "Rene_Bieder_Milliard_Medium",
    "fontSize": "24px",
    "fontWeight": "400"
  },
  "& .customTab": {
    overflow: "inherit !important",
    "& .MuiTabs-fixed": {
      overflow: "initial !important"
    },
    "& .MuiTabs-indicator": {
      "height": "4px !important",
      "borderRadius": "4px 4px 0px 0px",
      "backgroundColor": "#009BE2 !important",
      "left": "0px !important",
      "width": "180px !important"
    }
  }
});

const Donation = styled(Box)({
    justifyContent: "left",
    display: "flex",
    alignItems: "center",
    "& .totalAmount": {
      width: "100%",
      padding: "20px 15px",
      borderRadius: "15px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      background: "white",
      ...FONTS.regularFont,
      "& div p": {
          ...FONTS.regularFont,
        color: "#000",
      },
      "& .donationFreq": {
        marginLeft: "50px",
        "& label span": {
          fontSize: "14px",
          ...FONTS.regularFont,
        },
        "& label span:first-child": {
          color: "#009be2",
          padding: "3px",
        },
      },
      "&::after": {
        borderBottom: "none",
      },
      "&::before": {
        borderBottom: "none",
      },
      "& .MuiFilledInput-input": {
        padding: 0,
      },
    },
  });

const DonateTab = styled(Tab)({
    "&.normalBtn" : {
      border: "1px solid #0091be !important",
      borderRadius: "5px !important",
      marginRight: "15px !important",
      textTransform: "lowercase",
      minHeight: "50px",
      color: "#030303",
      fontSize: "16px !important",
      ...FONTS.mediumFont,
      "@media (max-width: 600px)": {
        marginBottom: "15px !important",
        fontSize: "14px !important",
      }
    },
    "&.activeBtn" : {
      border: "1px solid #009be2 !important",
      color: "#ffffff !important",
      backgroundColor: "#009be2 !important",
      ...FONTS.mediumFont,
      "@media (max-width: 600px)": {
        marginBottom: "15px !important",
        fontSize: "14px !important",
      }
    }
  })

  const PaymentDialog = styled(Box)({
    marginLeft: "320px",
    background: "#fff",
    borderRadius: "12px",
    border: "1px solid #fff",
    marginTop: "40px",
    padding: "20px 40px",
    width: "calc(100% - 340px)",
    "& .header" : {
      alignItems: "center",
      display: "flex",
      justifyContent: "start",
      "& .titleModal" : {
        fontSize: "20px",
        margin: "10px 20px",
        ...FONTS.mediumFont,
      },
    }, 
    "& .topHeader": {
      display: "flex",
      "&>div:nth-child(2)": {
        paddingLeft:"30px"
      }
    },
    "& .sub-title": {
      width: "50%",
      fontSize: "18px",
      letterSpacing: "-0.7px",
      margin: "15px 0",
      ...FONTS.mediumFont,
      color: "#000"
    },
    "& .content" : {
      display: "flex",
      alignItems: "start",
      "&>div:nth-child(1)": {
        "& form":{
            boxShadow: "none !important",
            padding: "0px 20px 20px 0 !important",
            minWidth: "unset !important",
            width: "100% !important",
            borderRight: "2px solid #bebebe"
        }
      },
      "&>div": {
        width: "50% !important",
      },
      "& .checkout": {
        display: "flex",
        padding: "0px 30px",
        borderRadius: "12px",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        "& .sub-title": {
          fontSize: "18px",
          letterSpacing: "-0.7px",
          marginBottom: "15px",
          ...FONTS.semiBoldFont,
        },
        "& .plan-amount": {
          letterSpacing: "-0.5px",
          fontSize: "18px",
          ...FONTS.semiBoldFont,
          textWrap: "nowrap"
        },
        "& .border": {
          border: "1px solid #bebebe",
          margin: "10px 0px",
        },
        "& .total": {
          fontSize: "16px",
          letterSpacing: "-0.45px",
          ...FONTS.mediumFont,
        }
      },
  
    }
  })

const DialogWrapper = styled(Box)({
  background: "rgb(254, 254, 254)",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 8px",
  width: "550px",
  padding: "0px",
  borderRadius: "12px",
  border: "1px solid rgb(217, 246, 250)",
  "& .dialogWrapper": {
    padding: "50px",
    borderRadius: "12px !important",
    "& .dialogWrapperInner": {
      textAlign: "center",
      marginBottom: "20px"
    },
    "& .goToProfile" : {
      color: "#ffffff !important",
      textTransform: "uppercase",
      backgroundColor: "#009be2 !important",
      fontSize: "15px !important",
      fontWeight: "400",
      borderRadius: "5px !important",
      padding: "10px 60px !important",
    }
  },
  "& .titleModal" : {
    ...FONTS.semiBoldFont,
    fontSize: "20px",
  },
  "& .descriptionModal, .buttonModal": {
    ...FONTS.regularFont,
    margin: "20px auto",
    fontSize: "16px",
    "& span": {
      color: "#009be2 !important"
    }
  }
});
  
  const CustomDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
      padding: "30px",
      borderRadius: "12px",
      width: "500px",
      boxShadow: "0px 1px 3px rgba(0,0,0,0.2),0px 2px 1px -1px rgba(0,0,0,0.12),0px 1px 1px rgba(0,0,0,0.14)",
      "& .title": {
        fontFamily: "Rene_Bieder_Milliard_Medium",
        alignItem: "center",
        marginBottom: "10px",
        display: "flex",
        fontSize: "25px",
        justifyContent: "space-between",
        "& .close-btn": {
          cursor: "pointer",
          height: "30px",
          width: "30px"
        }
      },
      "& .mainBtnDiv": {
        flexWrap: "wrap",
        display:"flex",
        "& button": {
          minWidth: "130px",
          margin: "15px 0",
        }
      },
      "& .Bluebtn": {
        margin: "30px 0 10px",
        color: "#fff ",
        fontWeight: "600",
        background: "#009be2 ",
        fontSize: "16px ",
        textAlign: "center",
        border: "1px solid #009be2 ",
        textTransform: "capitalize" as const,
        borderRadius: "12px",
        padding: "10px ",
        fontFamily: "Rene_Bieder_Milliard_Book, Serif, Sans-serif ",
        width: "100%",
      }
    }
  });

const ContactModal = styled(Dialog)({
  position: "relative",
  "& .close": {
    position: "absolute",
    right: "20px",
    top: "20px",
    fontSize: "20px",
    cursor: "pointer"
  },
  "& .modalTitle": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    fontSize: '20px',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
    padding: '0px 0 20px',
    textAlign: "center"
  },
  "& .contactText": {
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
    textAlign: "center"
  },
  "& .link": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    color: "inherit",
    cursor: "default"
  }
});
// Customizable Area End 