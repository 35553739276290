import React from "react";

import {
  // Customizable Area Start
  Card,
  Button,
  Typography,
  ButtonGroup,
  IconButton,
  Toolbar,
  Avatar,
  Modal,
  Box,
  Grid,
  Paper
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import moment from "moment";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
//@ts-ignore
import classesoutercss from "../../user-profile-basic/src/MyNeed.module.css";
import { COLORS } from "../../../framework/src/Globals";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import {
  icons,
  images
} from "../../../components/src/constants";
import UserPledgesController, {
  Props,
  ConnectionData
} from "./UserPledgesController.web";
import ImageLoader from "../../../components/src/custom/ImageLoader";
// Customizable Area End

// Customizable Area Start
export const styles: any = (theme: Theme) =>
  createStyles({
    cardWrapper: {
      padding: "30px 60px 30px 100px",
      "@media (max-width:600px)": {
        padding: "10px 10px 60px 5px",
        width: "100%",
      },
    },
    cardInner: {
      marginBottom: "20px",
      position: "relative" as const,
      padding: "30px",
      boxShadow: "none",
      background: "#ffffff",
      borderRadius: "16px",
      border: "1px solid #d5d5d5",
      justifyContent: "left",
      display: "flex",
      "@media (max-width:600px)": {
        padding: "10px",
      },
    },
    leftCard: {
      position: "relative" as const,
      width: "313px",
      height: "170px",
      textAlign:"center",
      "@media (max-width:600px)": {
        height: "85px",
        width: "85px",
      },
    },
    cardImage: {
      borderRadius: "16px",
      width: "313px",
      height: "170px",
      objectFit: "cover" as const,
      "@media (max-width:600px)": {
        height: "85px",
        width: "85px",
      },
    },
    rightCard: {
      position: "relative" as const,
    },
    needType: {
      color: "#ffffff",
      fontSize: "13px",
      background: "#f89a01",
      left: 0,
      position: "absolute" as const,
      borderRadius: "0px 16px 0px 16px",
      padding: "5px 10px",
      top: 142,
      "@media (max-width:600px)": {
        display: "none",
      },
    },
    activeTimeline: {
      fontSize: "16px",
      position: "absolute" as const,
      color: "#11A48C",
      background: "#B3E8E2",
      right: 0,
      borderRadius: "0px 16px 0px 16px",
      padding: "5px 30px",
      top: 0,
      "@media (max-width:600px)": {
        padding: "5px 15px",
        fontSize: "10px",
      },
    },
    timeline: {
      color: "#87888f",
      fontSize: "16px",
      background: "#ececec",
      position: "absolute" as const,
      borderRadius: "0px 16px 0px 16px",
      right: 0,
      padding: "5px 30px",
      top: 0,
      border: "1px solid #d5d5d5"
    },
    eventTitle: {
      margin: "0px 0 25px 30px",
      fontWeight: 600,
      fontSize: "18px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@media (max-width:600px)": {
        margin: "0px 0 10px 15px",
        fontSize: "16px",
        width:190
      },
    },
    avatarImgcontainer: {
      display: "inline-block",
      marginRight: "15px",
      verticalAlign: "middle",
    },
    avatarImg: {
      borderRadius: "50%",
      height: 23,
      zIndex: 1,
      width: 23,
      "@media (max-width:600px)": {
        height: 15,
        width: 15,
      },
    },
   
    eventdate_loc: {
      color: " #87888f",
      fontSize: 14,
      fontWeight: 400,
      overflow: "hidden",
      textOverflow: "ellipsis",
      width:270,
      "@media (max-width:600px)": {
        width:140,
        fontSize: 12,
       
      },
    },
    imageTextWrapper: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 15,
      width: "350px",
      "@media (max-width:600px)": {
        marginBottom: 5,
        width: "100%"
      },
    },
    innerInfo: {
      padding: "0 0 0 35px",
      "@media (max-width:600px)": {
        padding: "0px 0 0px 15px",
      },
    },
    deleteButton: {
      height: 25,
      "@media (max-width:600px)": {
        height: 10,
      },
    },
    multipleAvatarStyle: {
      height: 24,
      border: "2px solid white",
      width: 24,
    },
    deleteonCard: {
      position: "absolute" as const,
      right: 20,
      borderRadius: "50%",
      bottom: 20,
      cursor: "pointer",
      border: "1px solid grey",
      padding: 10,
      "@media (max-width:600px)": {
        padding: 5
      },
    },
    FavoriteBorderIcon: {
      backgroundColor: COLORS.white,
      position: "absolute",
      padding: "6px",
      height: "30px",
      width: "30px",
      right: " 12px",
      borderRadius: "50%",
      top: "12px",
      cursor: "pointer",
      color: "#292d32",
      "@media (max-width:600px)": {
        height: "20px",
        width: "20px",
      },
    },
    tabStyle: {
      padding: "7px",
      fontSize: "16px",
      textTransform: "capitalize",
      "@media (max-width:600px)": {
        fontSize: "16px",
        padding: "0px",
      },
    },
    FavoriteIcon: {
      padding: "6px",
      backgroundColor: COLORS.white,
      position: "absolute",
      width: "30px",
      top: "12px",
      height: "30px",
      fill: "red",
      color: "#292d32",
      right: " 12px",
      cursor: "pointer",
      borderRadius: "50%",
      "@media (max-width:600px)": {
        height: "20px",
        width: "20px",
      },
    },
    userName: {
      letterSpacing: -0.35,
      fontSize: 16,
      lineHeight: 1,
      color: "#57565c",
      "@media (max-width:600px)": {
        fontSize: 14,
      },
    },
    userInfo: {
      justifyContent: "flex-start",
      display: "flex",
      marginBottom: 20,
      flexDirection: "row",
      alignItems: "center",
      "@media (max-width:600px)": {
        marginBottom: 10,
      },
    },
    myneedTabgroupActive: {
      boxShadow: ' 0px 0px 8px rgba(212, 212, 212, 0.5)',
      border: "none",
      textTransform: "none",
      background: '#FFFFFF',
    },
    deleteButtonStyle: {
      width: "80%",
      backgroundColor: "#009be2",
      color: "white",
      borderRadius: "10px",
      marginTop: '35px',
      height: '50px',
      textTransform: 'capitalize'
    },
    cancelButtonStyle: {
      borderRadius: "10px",
      width: "80%",
      color: "black",
      marginTop: 15,
      border: '1px solid #E4E4E4',
      marginBottom: '30px',
      height: '50px',
      textTransform: "capitalize"
    }
  });

const pledgesTabsConstants = [
  {
    id: 0,
    label: "Active",
    type: "Active",
  },
  {
    id: 1,
    label: "Past",
    type: "Past",
  },
];
// Customizable Area End

class UserPledges extends UserPledgesController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headerWeb() {
    return (
      <Toolbar className={"toolBar"}>
        {!isMobile &&
          <div className={classesoutercss.backIconWeb}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
            >
              <img src={icons.back} alt="loading" />
            </IconButton>
            <Typography variant="h6" style={{ fontSize: "20px", color: "#000", marginLeft: "30px" }} noWrap>
              My Pledges
            </Typography>
          </div>
        }
      </Toolbar>
    )
  }

  applyClass = (type: string) => {
    const { selectedPledgesTabChange } = this.state;
    if (type === selectedPledgesTabChange) {
      return `${"myneedTabgroupActive"} ${classesoutercss.myNeedsTabs}`
    } else {
      return `${"myneedTabgroup"} ${classesoutercss.myNeedsTabs}`
    }
  }

  buttonGroup(classes: any) {
    const { selectedPledgesTabChange } = this.state;
    return (
      <div
        style={{
          justifyContent: "center", alignItems: "center", display: "flex"
        }}>
        <ButtonGroup size="large" aria-label="large button group" fullWidth
          className={"myneedTabgroup"}
        >
          {
            pledgesTabsConstants.map((ele, index) => (
              <Button
                key={ele.type}
                data-test-id={ele.type}
                onClick={() => {
                  this.setState({ selectedPledgesTabChange: ele.type });
                  ele.type === "Past" ? this.PastNeedsTabData() : this.ActiveNeedsTabData();
                }}
                className={this.applyClass(ele.type)}
              >
                <Typography
                  className={classes.tabStyle}
                  style={
                    { color: selectedPledgesTabChange === ele.type ? COLORS.skyBlue : COLORS.darkGray }
                  }>{ele.label}</Typography>

              </Button>
            ))}
        </ButtonGroup>
      </div>
    )
  }

  deleteModel(classes:any) {
    const { pledgeModal } = this.state;
    return (
      <Modal
        open={pledgeModal}
        data-testId="modal-close"
      >
        <Box className={classesoutercss.myNeedmodalBox}>
          <img src={images.imageWarning} alt='warrning' className={classesoutercss.warningIcon} />
          <Typography style={{ fontSize: '20px' }} className={classesoutercss.alertText}>Want to cancel this pledge?</Typography>
          <ConfirmButton
            onClick={() => this.cancelPledge()}
            data-test-id='confirmBtn'
            className={classes.deleteButtonStyle}
          >
            Confirm
          </ConfirmButton>
          <Button
            onClick={() => {
              this.setState({ pledgeModal: false });
            }}
            data-test-id='cancelBtn'
            className={classes.cancelButtonStyle}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    )
  }

  finalConnections = (item: any) => {
    const { connectionData } = this.state;
    return connectionData?.filter((item2: ConnectionData) =>
      item.attributes.friend_ids.length > 0 && item.attributes.friend_ids.some((result: string) => item2.attributes.receipient_id.toString() === result)
    );
  }

  leftCardData = (classes: any, imageUrl: string, isItFav: boolean, needInfoId: any, needfavId: any, needType: string, nonProfitEventId: any) => {
    let placeholderImg=localStorage.getItem('placeholderImg')
    return (
      <div className={classes.leftCard}>
        <ImageLoader loaderStyle={{marginTop:isMobile?35:70}} classes={classes.cardImage} imageUrl={ imageUrl??placeholderImg}/>
        {isItFav ?
          <FavoriteIcon
            data-test-id="favoriteBtn"
            className={classes.FavoriteIcon}
            onClick={() => {
              this.setState({ selectedId: needInfoId })
              this.removeFavourite(needInfoId, needfavId)
            }}
          /> :
          <FavoriteBorderIcon
            data-test-id="unfavoriteBtn"
            className={classes.FavoriteBorderIcon}
            onClick={() => {
              this.setState({ selectedId: nonProfitEventId ? nonProfitEventId : needInfoId })
              this.addFavourite(nonProfitEventId ? nonProfitEventId : needInfoId, nonProfitEventId ? "R2" : "R1")
            }}
          />
        }
        <span className={classes.needType}>{(needType)}</span>
      </div>
    )
  }

  renderLocation=(location:string|null, postcode:string)=> {
    if(postcode && location!==null && !location.includes(postcode)){
     location += `, ${postcode}`; 
   }
  return location;
 }

  rightCardData = (classes:any, title_of_need:string,imageUrl:string, username:string, dateNtime:string, post_code:string,location:string|null, final:ConnectionData[] ) => {
    let altImg = "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
    return (
      <div className={classes.rightCard}>
        <Typography className={classes.eventTitle}>
          {title_of_need}
        </Typography>
        <div className={classes.innerInfo}>
          <div className={classes.userInfo}>
            <div className={classes.avatarImgcontainer}>
              <img src={imageUrl ? imageUrl : altImg} alt="" className={classes.avatarImg} />
            </div>
            <Typography
              variant="body1"
              className={classes.userName}
            >
              {username}
            </Typography>
          </div>
          <div className={classes.imageTextWrapper}>
            <div className={classes.avatarImgcontainer}>
              <img
                src={icons.calender}
                alt="avatar"
                className={classes.avatarImg}
              />
            </div>
            <span className={classes.eventdate_loc}>
             {dateNtime}
            </span>
          </div>
          <div className={classes.imageTextWrapper}>
            <div className={classes.avatarImgcontainer}>
              <img
                src={icons.locationred}
                alt="location"
                className={classes.avatarImg}
              />
            </div>
            <span className={classes.eventdate_loc}>
              {this.renderLocation(location,post_code)}
            </span>
              <AvatarGroup className={`AvatarGroupMyNeed ${classesoutercss.AvatarGroup}`} max={4}>
                {final?.map((personObj: any) => {
                  return (
                    personObj.attributes ? 
                      <Avatar key={personObj.id} className={`singleAvatar ${classes.multipleAvatarStyle}`} src={personObj.attributes.profile_picture.url} alt="Remy Sharp"  >
                        {personObj.attributes.name?.charAt(0).toUpperCase()}
                      </Avatar> 
                      : <Avatar key={personObj.account_id} className={`singleAvatar ${classes.multipleAvatarStyle}`} src={personObj.profile_picture.url} alt="Remy Sharp"  >
                        {personObj.full_name.charAt(0).toUpperCase()}
                      </Avatar>
                  )
                })}
              </AvatarGroup>
          </div>
        </div>
      </div>
    )
  }

  renderR1data = (item: any, classes: any) => {
    const { selectedPledgesTabChange } = this.state;
    let needInfo = item.attributes.need.data
    let final = this.finalConnections(needInfo);
    let dateNtime = `${moment(needInfo.attributes.start_date).format('DD MMM YYYY')} , ${moment(needInfo.attributes.start_time).format('HH:mm a')}`
    return (
      <>
        {this.leftCardData(classes, needInfo.attributes.need_photo.url, needInfo.attributes.is_favourite, needInfo.id, needInfo.attributes.favourite_id, needInfo.attributes.need_type,  null)}
        {this.rightCardData(classes,needInfo.attributes.title_of_need, needInfo.attributes.user_image.url, needInfo.attributes.user_name, dateNtime, needInfo.attributes.event_type,needInfo.attributes.location,final)}
        {selectedPledgesTabChange === 'Active' && <div
          data-test-id={`deleteNeedIconR1`}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ pledgeModal: true, deletePledgeId: item.id });
          }}
          className={classes.deleteonCard}
        >
          <img
            alt="delete"
            src={icons.deleteIcon}
            className={classes.deleteButton}
          />
        </div>}
        {selectedPledgesTabChange === 'Past' ?  
          <span className={classes.timeline}>Past</span> :
          <span className={classes.activeTimeline}>Active</span> }
      </>
    )
  }

  renderR2data = (item: any, classes: any) => {
    const { selectedPledgesTabChange } = this.state;
    let imageUrl = item.attributes?.non_profit_event.data.attributes.event_image?.url;
    let isFavourite = item.attributes?.non_profit_event.data.attributes.is_favourite;
    let eventTitle = item.attributes?.non_profit_event.data.attributes.event_name;
    let orgImage = item.attributes?.non_profit_event.data.attributes.organisation_image_url.url;
    let orgTitle = item.attributes?.non_profit_event.data.attributes.organisation_name;
    let date = item.attributes?.non_profit_event.data.attributes.from_date;
    let time = item.attributes?.non_profit_event.data.attributes.start_time;
    let post_code = item.attributes?.non_profit_event.data.attributes.post_code;
    let location = item.attributes?.non_profit_event.data.attributes.location;
    let eventType = item.attributes?.non_profit_event.data.attributes.event_type;
    let people = item.attributes?.non_profit_event.data.attributes.attended;
    let favId = item.attributes?.non_profit_event.data.attributes.favourite_id;
    let timeNdate = `${moment(date).format('DD MMM YYYY')}, ${moment(time).format('HH:mm a')}`
    return (
      <>
        {this.leftCardData(classes, imageUrl, isFavourite, item.id, favId, eventType, item.attributes.non_profit_event_id)}
        {this.rightCardData(classes, eventTitle, orgImage, orgTitle, timeNdate, post_code,location, people)}
        {selectedPledgesTabChange === 'Active' && <div
          data-test-id={`deleteNeedIconR2`}
          className={classes.deleteonCard}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ pledgeModal: true, deletePledgeId: item.id });
          }}
        >
          <img
            src={icons.deleteIcon}
            className={classes.deleteButton}
            alt="deleteJpeg"
          />
        </div>}
        {selectedPledgesTabChange === 'Active' ?
          <span className={classes.activeTimeline}>Active</span> : <span className={classes.timeline}>Past</span>}
      </>
    )
  }

  currentData = (item: any, classes: any) => {
    return (
      <Card className={classes.cardInner} key={item.id} data-testId="cardInner"  onClick={() => {
          if(item.attributes.non_profit_event_id){
            this.handleCardClick(item.attributes.non_profit_event.data.id, item.attributes.non_profit_event.data.attributes.event_type)
          }
        }}>
        {
          item.attributes?.need_id ?
            this.renderR1data(item, classes)
            : this.renderR2data(item, classes)
        }
      </Card>
    )
  }

  renderSkeleton = (classes: any) => {
    const tab = this.state.selectedPledgesTabChange;
    const card = <Paper
      style={{
        margin: "auto",
        marginBottom: 30,
        width: "100%",
        padding: 24,
        alignItems: "center",
        height: 170,
        background: '#FFFFFF',
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 18,
        border: "1px solid rgba(0, 0, 0, 0.11)"
      }}
      elevation={0}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
          <Skeleton animation="wave" variant="rect" className={classes.cardImage} style={{ height: 123, width: "100%" }} />
        </Grid>
        <Grid item xs={8} md={8}>
          <Skeleton animation="wave" height={30}/>
          <Skeleton animation="wave" height={30}/>
          {tab === "Past" ?
            <>
              <Skeleton height={30} animation="wave"/>
              <Skeleton height={30} animation="wave"/>
            </>
            :
            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Skeleton animation="wave" height={30}/>
                <Skeleton  animation="wave" height={30}/>
              </Grid>
              <Grid item xs={6} md={2} style={{ display: "flex", justifyContent: "end" }}>
                <Box display="flex" alignItems="end">
                  <Skeleton animation="wave" variant="circle" width={40} height={40}/>
                </Box>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Paper>
    return [...Array(4)].map((_) => (card));
  }

  eventCard = (classes: any) => {
    const { cardData } = this.state;
    if(this.state.loading){
      return this.renderSkeleton(classes);
    }
    return (
      cardData?.length > 0 ?
        cardData.map((item: any) => (
          this.currentData(item, classes)
        )) : this.fallbackMessage()
    )
  }

  mainCard = (classes: any) => {
    return (
      <div className={classes.cardWrapper}>{this.eventCard(classes)}</div>
    )
  }

  fallbackMessage() {
    return (
      <div style={{ textAlign: 'center', fontSize: 20 }}><p>No pledges available</p></div>
    )
  }
  // Customizable Area End

  render() {
    const { classes, theme }: any = this.props;
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          {/* @ts-ignore */}
          <DashboardWeb
            showBackButton={true}
            showBottomBar={true}
            title={'My Pledges'}
            navigation={this.props.navigation}
          >
            <div
              className={classesoutercss.userprofileMain}
              style={{ backgroundColor: COLORS.white }}
            >
              {this.headerWeb()}
              {this.buttonGroup(classes)}
              {this.mainCard(classes)}
              {this.deleteModel(classes)}
            </div>
          </DashboardWeb>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}


// Customizable Area Start
const ConfirmButton = styled(Button)({
  backgroundColor: "#009be2",
  color: "white",
  "&:hover": {
    color: "white",
    backgroundColor: "#009be2",
  }
});

UserPledges.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export { UserPledges }
export default withStyles(styles, { withTheme: true })(UserPledges as any);
// Customizable Area End
