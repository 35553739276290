import React from "react";
// Customizable Area Start
import PropTypes from "prop-types";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { icons } from "../../../components/src/constants";
import ContentManagementController, {
  Props,
} from "./ContentManagementController";

// @ts-ignore
import module from "./ContentManagementEvent.module.css";

import {
  InputAdornment,
  Table,
  CircularProgress,
  TableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow,
  Divider,
  Input,
  IconButton,
  Typography,
  Container,
  Paper,
  Button,
  Box
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";

export type CounterProps = { label?: string };

const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);

const TableHead: any = withStyles({
  root: {
    borderBottom: "3px solid #eaeaea",
  },
})(MuiTableHead);

const styles = (theme: Theme) => createStyles({});

// Customizable Area End

export class RecentEventsWeb extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  renderTableShellevent_name(index: any, item: any) {
    return (
      <TableCell
        style={{ fontWeight: 200 }}
        className={`${module.tbody} ${module.ellipsis}`}
        component="th"
        scope="row"
      >
        {item.attributes.event_name}
      </TableCell>
    );
  }

  renderTableshellPublishstatus(item: any) {
    return (
      <TableCell className={module.tbody} align="center">
        {item.attributes.publish ? "Published" : "Not Published"}
      </TableCell>
    );
  }

  tableRowEventType(item: any) {
    return (
      <TableCell className={module.tbody} align="center">
        {(() => {
          if (item.attributes.event_type === "need") {
            return "Need";
          } else if (item.attributes.event_type === "project_funding") {
            return "Project";
          } else {
            return "Activity";
          }
        })()}
      </TableCell>
    );
  }

  renderTableshellattendnanceStatus(item: any) {
    return (
      <TableCell className={module.tbody} align="center">
        {item.attributes.max_attendence ? item.attributes.max_attendence : "-"}
      </TableCell>
    );
  }

  renderAllNpEventsTableRow(index: any, item: any) {
    const role = localStorage.getItem("np_user_role");
    const isNpUser = role === "User" ? true : false;
    return (
      item.attributes.publish && <TableRow
        key={index}
        onClick={() => {
          !isNpUser && this.props.onSecondTabsCreation(item.id);
        }}
      >
        {this.renderTableShellevent_name(index, item)}
        {this.renderTableshellPublishstatus(item)}

        {this.tableRowEventType(item)}
        {this.renderTableshellattendnanceStatus(item)}

        <TableCell className={module.tbody} align="center">
          {item?.attributes?.pending_approval
            ? item.attributes.pending_approval
            : "-"}
        </TableCell>
        <TableCell className={module.tbody} align="center">
          {item.attributes.from_date
            ? `${moment(item.attributes.from_date).format("DD/MM/YYYY")} `
            : "-"}{" "}
          {moment(item.attributes.start_time).format("hh:mm A")}
        </TableCell>
      </TableRow>
    );
  }

  renderAllNpEventsTable(classes: any) {
    return (
      <Box width="100%" overflow="auto">
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {/* @ts-ignore */}
            <TableCell className={module.thead} width="30%">
              TITLE
            </TableCell>
            <TableCell className={module.thead} align="center">
              STATUS
            </TableCell>
            <TableCell className={module.thead} align="center">
              TYPE
            </TableCell>
            <TableCell className={module.thead} align="center">
              PARTICIPANTS
            </TableCell>
            <TableCell className={module.thead} align="center">
              PENDING APPROVAL
            </TableCell>
            <TableCell className={module.thead} align="center">
              DATE/TIME
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.allNpEvents.data &&
            this.state.allNpEvents.data
              .slice(0)
              .reverse()
              .map((item: any, index: any) =>
                this.renderAllNpEventsTableRow(index, item)
              )}
        </TableBody>
      </Table>
      </Box>
    );
  }

  rencentLyPublishDiv() {
    const role = localStorage.getItem("np_user_role");
    const isNpUser = role === "User" ? true : false;
    return (
      <Box
        display={{ sm: "block", md: "flex" }}
        p={{ sm: "22px", md: "30px" }}
        alignItems="center"
        justifyContent="space-between"
        >
          <Box 
            display="flex" 
            justifyContent="space-between" 
            alignItems="center"
            mb={{ sm: "15px", md: "0px" }} 
          >
          <Box ml={{ sm: "0px", md: "-15px" }}>
          <Typography className={module.heading} style={{}}>
            Recently published
          </Typography>
          </Box>
          <Box ml={{ sm: "0px", md: "50px" }} mr={{ sm: "0px", md: "25px" }} data-test-id="bottomDiv">
          {!isNpUser &&
          <Button
            onClick={() => {
              this?.props?.createEvents();
            }}
            data-test-id="CreateNewEvent"
            variant="contained"
            style={{
              color: "#009BE2",
              height: 49,
              backgroundColor: "rgb(162 231 225 / 10%)",
              border: "1px solid #009BE2",
              textTransform: "none",
              borderRadius: 10,
              boxShadow: "none",
              fontSize: 16,
              fontFamily: "Rene_Bieder_Milliard_SemiBold",
            }}
          >
            Create new event
          </Button>
          }
          </Box>
        </Box>
        <div>
          <Input
            value={this.state.searchAllEvents}
            data-test-id="searchInput"
            startAdornment={
              <InputAdornment
                position="start"
                style={{
                  width: 40,
                  marginLeft: -9,
                }}
              >
                <IconButton>
                  <img
                    src={icons.searchSVG}
                    style={{
                      height: 25,
                      width: "auto",
                      opacity: 0.5555555,
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
            placeholder="Search..."
            className={module.searchField}
            disableUnderline
            onChange={(e) => {
              this.setState({ searchAllEvents: e.target.value });
              this.searchAllEvents(e.target.value);
            }}
            endAdornment={
              <IconButton
                style={{
                  visibility: this.state.searchAllEvents ? "visible" : "hidden",
                }}
                onClick={() => {
                  this.searchAllEvents("");
                  this.setState({ searchAllEvents: "" });
                }}
              >
                {" "}
                <CancelRoundedIcon />{" "}
              </IconButton>
            }
          />
        </div>
      </Box>
    );
  }

  render() {
    // Customizable Area Start

    const { classes }: any = this.props;
    window.localStorage.removeItem("eventTabs");
    window.localStorage.removeItem("secondTabs");

    return (
      <div id="createEventButton" data-test-id={"mainDivWrapper"}>
        <Container maxWidth={"xl"}>
          <div>
            <Paper
              style={{
                marginTop: 30,
                borderRadius: 10,
              }}
              elevation={0}
            >
              {this.rencentLyPublishDiv()}

              <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />

              {(() => {
                if (this.state.allNpEvents?.data?.length > 0) {
                  //table
                  return this.renderAllNpEventsTable(classes);
                } else if (this.state.allNpEvents?.data?.length === 0) {
                  return (
                    <Typography
                      style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                      }}
                    >
                      Sorry, We are unable to find this event
                    </Typography>
                  );
                } else if (this.state.allNeedsEvents?.error) {
                  return (
                    <Typography
                      style={{
                        textAlign: "center",
                        height: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                      }}
                    >
                      {this.state.allNeedsEvents?.error}
                    </Typography>
                  );
                } else {
                  //circularprogress
                  return (
                    <div
                      style={{
                        height: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "#009be2" }} />
                    </div>
                  );
                }
              })()}
            </Paper>
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(
  RecentEventsWeb as any
);
