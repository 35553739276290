// @ts-nocheck
import React from "react";
// Customizable Area Start
import { Scrollbar } from "react-scrollbars-custom";
import RecentEventsWeb from "./RecentEvents.web";
import PropTypes from "prop-types";

import { constants } from "../../../components/src/constants";
import ContentManagementController, {
  Props,
} from "./ContentManagementController";
import { connect } from "react-redux";
import { setSelectedEvents } from "../../../framework/src/redux/eventsInnerTab/eventsInnerTabActions";
import ContentManagement from "./ContentManagement.web";
// @ts-ignore
import module from "./ContentManagementEvent.module.css";
import { Typography, Container, Button, ButtonGroup } from "@material-ui/core";
import { COLORS } from "../../../framework/src/Globals";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import ManageNeedsWeb from "./ManageNeeds.web";
import ManageProjectsWeb from "./ManageProjects.web";
import NeedsEventWeb from "./NeedsEvent.web";
import ProjectsEventWeb from "./ProjectsEvent.web";
import ManageActivitiesWeb from "./ManageActivities.web";
import ActivityEventWeb from "./ActivityEvent.web";
import CreateEventWeb from "./CreateEvent.web";
import UpdateEventWeb from "./UpdateEvent.web";

export type CounterProps = { label?: string };

// Customizable Area End

// Customizable Area Start
export class ContentEventWeb extends ContentManagementController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }
  tabsFunction = (data: any) => {
    this.setState({ eventTabs: data, eventBtnTabs: data });
    this.setState({ singleEventDetails: {} });
  };

  render() {
    // Customizable Area Start
    const eventType =
      this.state?.singleEventDetails?.data?.attributes?.event_type;
    const { setSelectedEvents } = this.props;
    const plan = localStorage.getItem("plan");

    return (
      <ContentManagement
        showBackButton={constants._icons.hide}
        title="Events"
        showBottomBar={constants._icons.show}
        showDrawerHeader={true}
        titleColor={COLORS.black}
        open={false}
        mobileOpen={false}
        navigation={this.props.navigation}
        tabsFunction={this.tabsFunction}
        // handleTabsBar = {this.handleTabs()}
      >
        <Scrollbar style={{ height: "100%", width: "100%" }}>
          <Container maxWidth="xl">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 40,
              }}
            >
              <ButtonGroup
                size="large"
                aria-label="large button group"
                fullWidth
                style={{ margin: "2px" }}
              >
                <Button
                  onClick={() => {
                    this.setState({ eventTabs: "need" });
                    setSelectedEvents("need");
                    this.setState({ singleEventDetails: "" });
                  }}
                  data-testId="tab-1"
                  className={
                    this.state.eventBtnTabs === "need" ||
                    this.state.eventTabs === "needsEventCreation" ||
                    (eventType && eventType === "need")
                      ? module.activeEventTabs
                      : ""
                  }
                  style={{
                    backgroundColor: COLORS.white,
                    textTransform: "capitalize",
                    height: 70,
                    borderRadius: "12px 0px 0px 12px",
                    border: "1px solid #f5f5f5",
                    webkitBoxShadow: "0 0 3px rgb(0 0 0 / 12%)",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                      fontSize: 20,
                    }}
                  >
                    Needs
                  </Typography>
                </Button>
                {plan !== "Trial" && plan !== "Presence-Only" &&
                  <Button
                    className={`${
                      this.state.eventBtnTabs === "project" ||
                      this.state.eventTabs === "projectEventCreation" ||
                      (eventType && eventType === "project_funding")
                        ? module.activeEventTabs
                        : ""
                    }`}
                    data-testId="tab-2"
                    onClick={() => {
                      this.setState({ eventTabs: "project" });
                      setSelectedEvents("project");
                      this.setState({ singleEventDetails: "" });
                    }}
                    style={{
                      backgroundColor: COLORS.white,
                      textTransform: "capitalize",
                      height: 70,
                      border: "1px solid #f5f5f5",
                      webkitBoxShadow: "0 0 3px rgb(0 0 0 / 12%)",
                      // borderRadius: 12,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 20,
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                      }}
                    >
                      Projects
                    </Typography>
                  </Button>
                }
                <Button
                  data-testId="tab-3"
                  onClick={() => {
                    this.setState({
                      eventTabs: "activities",
                    });
                    setSelectedEvents("activities");
                    this.setState({ singleEventDetails: "" });
                  }}
                  style={{
                    backgroundColor: COLORS.white,
                    textTransform: "capitalize",
                    height: 70,
                    borderRadius: "0px 12px 12px 0px",
                    border: "1px solid #f5f5f5",
                    webkitBoxShadow: "0 0 3px rgb(0 0 0 / 12%)",
                  }}
                  className={`${
                    this.state.eventBtnTabs === "activity" ||
                    this.state.eventTabs === "activitiesEventCreation" ||
                    (eventType && eventType === "activity")
                      ? module.activeEventTabs
                      : ""
                  }`}
                >
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                    }}
                  >
                    Activities
                  </Typography>
                </Button>
              </ButtonGroup>
            </div>
          </Container>
          {(() => {
            switch (this.state.eventTabs) {
              case "need":
                return (
                  <div>
                    <ManageNeedsWeb
                      navigation={this.props.navigation}
                      id={""}
                      children={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      projectsEvent={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                      createNeedEvent={this.onCreateEvent}
                      onSecondTabsCreation={this.onSecondTabs}
                      eventUpdates={this.getEventUpdates}
                    />
                  </div>
                );

              case "project":
                return (
                  <div>
                    <ManageProjectsWeb
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      createNeedEvent={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                      projectsEvent={this.onCreateProjectEvent}
                      onSecondTabsCreation={this.onSecondTabs}
                      resetEventTab={this.handleTabs}
                    />
                  </div>
                );

              case "needsEventCreation":
                return (
                  <div>
                    <NeedsEventWeb
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      createNeedEvent={undefined}
                      projectsEvent={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                      onEventCreation={undefined}
                      navigationToNeeds={this.onNavigateFromNeedsCreation}
                      navigationToActivitiesList={undefined}
                      navigationToProjectsList={undefined}
                      onSecondTabsCreation={undefined}
                      eventUpdates={undefined}
                      SingleEvent={undefined}
                      single_event_details={undefined}
                      pledgesDetails={undefined}
                      eventPledgesDetails={undefined}
                    />
                  </div>
                );
              case "activities":
                return (
                  <div>
                    <ManageActivitiesWeb
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      createNeedEvent={undefined}
                      projectsEvent={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                      onSecondTabsCreation={this.onSecondTabs}
                      createActivitiesEvent={this.onCreateActivitiesEvent}
                    />
                  </div>
                );

              case "secondTabsOne":
                return (
                  <div>
                    <UpdateEventWeb
                      navigationToProjectsList={
                        this.onNavigateFromProjectsCreation
                      }
                      navigationToNeedsList={this.onNavigateFromNeedsCreation}
                      navigationToActivitiesList={
                        this.onNavigateFromActivitiesCreation
                      }
                      mobileOpen={false}
                      open={false}
                      id={""}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      children={undefined}
                      title={undefined}
                      adminSelectedTab={undefined}
                      setSelectedAdminTab={undefined}
                      navigation={this.props.navigation}
                      createNeedEvent={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      projectsEvent={undefined}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      createEvents={undefined}
                      onEventCreation={undefined}
                      SingleEvent={this.state.singleEventDetails}
                      pledgesDetails={this.getAllPledges}
                      eventUpdates={this.getEventUpdates}
                    />
                  </div>
                );

              case "projectEventCreation":
                return (
                  <div>
                    <ProjectsEventWeb
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      createNeedEvent={undefined}
                      projectsEvent={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                      onEventCreation={undefined}
                      navigationToProjectsList={
                        this.onNavigateFromProjectsCreation
                      }
                      navigationToNeedsList={undefined}
                      navigationToActivitiesList={undefined}
                      onSecondTabsCreation={undefined}
                      eventUpdates={undefined}
                      SingleEvent={undefined}
                      single_event_details={undefined}
                      pledgesDetails={undefined}
                      eventPledgesDetails={undefined}
                    />
                  </div>
                );

              case "activities":
                return (
                  <div>
                    <ManageActivitiesWeb
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      createNeedEvent={undefined}
                      projectsEvent={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                    />
                  </div>
                );

              case "createAllEvents":
                return (
                  <div>
                    <CreateEventWeb
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      // createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                      createNeedEvent={this.onCreateEvent}
                      createActivitiesEvent={this.onCreateActivitiesEvent}
                      projectsEvent={this.onCreateProjectEvent}
                      resetEventTab={this.handleTabs}
                    />
                  </div>
                );

              case "activitiesEventCreation":
                return (
                  <div>
                    <ActivityEventWeb
                      navigationToActivitiesList={
                        this.onNavigateFromActivitiesCreation
                      }
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      createNeedEvent={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      projectsEvent={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                      onEventCreation={undefined}
                      navigationToNeedsList={undefined}
                      onSecondTabsCreation={this.onSecondTabs}
                      navigationToProjectsList={undefined}
                      SingleEvent={undefined}
                      single_event_details={undefined}
                      pledgesDetails={undefined}
                      eventPledgesDetails={undefined}
                      eventUpdates={undefined}
                    />
                  </div>
                );

              case "eventsCreation":
                return (
                  <div>
                    <CreateEventWeb
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      createNeedEvent={undefined}
                      projectsEvent={undefined}
                      adminSelectedTab={undefined}
                      createEvents={undefined}
                      setSelectedAdminTab={undefined}
                     
                    />
                  </div>
                );

              default:
                return (
                  <div>
                    <RecentEventsWeb
                      navigation={undefined}
                      id={""}
                      children={undefined}
                      createActivitiesEvent={undefined}
                      showBackButton={undefined}
                      title={undefined}
                      showBottomBar={false}
                      showDrawerHeader={false}
                      mobileOpen={false}
                      open={false}
                      history={undefined}
                      titleColor={undefined}
                      showLine={undefined}
                      createNeedEvent={undefined}
                      projectsEvent={undefined}
                      adminSelectedTab={undefined}
                      setSelectedAdminTab={undefined}
                      createEvents={this.onEventCreation}
                      onSecondTabsCreation={this.onSecondTabs}
                    />
                  </div>
                );
            }
          })()}
        </Scrollbar>
      </ContentManagement>
    );
  }
}

// Customizable Area End

ContentEventWeb.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Customizable Area End

function mapStateToProps(state: any) {
  return {
    selectedInnerEvents: state.eventsInnerTabReducer.selectedInnerEvents,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedEvents: (selectedTab: any) => {
      return dispatch(setSelectedEvents(selectedTab));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEventWeb);
