import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Modal, CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useTermsAndConditions } from "../api/hooks/user.hooks";
import { HideContainer } from "./hide-container";

const useTermsAndConditionsModalStyles = makeStyles({
  title: {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,
    fontSize: '1rem',
    paddingTop: '0px',
  },
  content: {
    color: '#454545',
    fontFamily: "Rene_Bieder_Milliard_Medium",
    fontSize: 16,
    overflowY: 'auto',
    maxHeight: '60vh',
    textAlign: 'justify',
    whiteSpace: 'pre-wrap'
  },
  submitBtn: {
    fontSize: '1.4rem',
  },
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: "383px",
    transform: 'translate(-50%, -50%)',
    background: 'white',
    borderRadius: '15px',
    padding: '20px',
    "@media (max-width: 576px)": {
      width: "85vw"
    },

  },
  checkboxText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#9a99a0',
  },
  modalContainer: {
  },
  loader: {
    margin: '10px auto',
    marginTop: '13%',
    display: "block",
    color: '#009BE2'
  },
  closeIcon: {
    position: "absolute",
    top: "0px",
    right: "-2px",
    cursor: "pointer"
  }
});

interface IProps {
  modalValue: string;
  accountType?: "user" | "business" | "non_profit" | undefined;
  onClose?: () => void;
  onAccept?: () => void;
}

export function TermsAndConditionContent(props: IProps) {
  const classes = useTermsAndConditionsModalStyles();
  const { mutate: getData, isLoading } = useTermsAndConditions();
  const [description, setDescription] = useState<string | null | undefined>("");

  useEffect(() => {
    const abc = getData({ accountType: props.accountType, type: props.modalValue }, {
      onSuccess(response: { data: { attributes: { description: string | null } } }) {
        setDescription(response?.data?.attributes?.description);
      }
    });
  }, []);

  return (
    <Box position="relative">
      <Typography
        variant="h5"
        className={classes.title}
      >
        {props.modalValue}
      </Typography>
      <CloseIcon 
        className={classes.closeIcon}
        onClick={props.onClose}
      />
      <HideContainer hideWhen={!isLoading}>
          <CircularProgress
          size={40}
          className={classes.loader}
          color="primary"
        />
      </HideContainer>
    <Typography className={classes.content}>
      {description}
    </Typography>
    </Box>
  );
}

export function TermsAndConditionModal({
  open,
  onClose,
  modalValue,
  accountType
}: {
  open: boolean,
  onClose: () => void,
  modalValue: string;
  accountType: "user" | "business" | "non_profit" | undefined;
}) {

  const classes = useTermsAndConditionsModalStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="terms-and-condition"
      aria-describedby="terms-and-condition"
      className={classes.modalContainer}
    >
      <Box className={classes.box}>
        <TermsAndConditionContent onClose={onClose} accountType={accountType} modalValue={modalValue} />
      </Box>
    </Modal>
  );
}
