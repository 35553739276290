// @ts-nocheck
import React, { useState } from "react";
import Select from "react-select";
import { Button, Typography, Box, Container } from "@material-ui/core";
import { components } from "react-select";

import CustomMultiValue from "./CustomMutliValue.tsx";
import module from "./BusDropdowns.module.css";
import { FAMILYFONTS } from "../../../components/src/constants";
import { Scrollbar } from "react-scrollbars-custom";

const BusDropdowns = (props: any) => {

  const renderScrollbar = props => {
    return (
      <div style={{ height: 120 }}>
        <style>
          {`.ScrollbarsCustom-Track {
                        width : 2px !important;
                        margin-right : 10px !important

              }`}
        </style>
        <Scrollbar>{props.children}</Scrollbar>
      </div>
    );
  };

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <label>{props.label}</label>
          <input
            style={{ float: "right" }}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
        </components.Option>
      </div>
    );
  };

  const [causesOpen, setCausesOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [whereOpen, setWhereOpen] = useState(false);
  const [whatOpen, setWhatOpen] = useState(false);

  return (
    // Customizable Area Start
    <Box style={{ margin: 18 }}>
      <div>
        <div>
          <Typography className={module.label} style={{ ...FAMILYFONTS.family3, fontSize: 16 }}>
            What cause(s) are you interested in ?
          </Typography>
          <div
            style={{
              position: "absolute",
              zIndex: 26,
              border: `${causesOpen ? "1px solid  #3696C3" : "1px solid lightgrey"
                }`,
              width: "92%",
              borderRadius: 10,
              borderBottom: `${!causesOpen ? "1px solid lightgrey" : "1px solid  #3696C3"
                }`,
              height: `${!causesOpen ? "40px" : "170px"}`,
            }}
          >
            <Select
              onMenuClose={() => {
                setCausesOpen(false);
              }}
              onMenuOpen={() => {
                setCausesOpen(true);
              }}
              options={props?.causeOptions}
              onChange={(e) => props.handleCauseChange(e.map((it: any) => it.value))}
              isClearable={false}
              isMulti
              components={{
                Option,
                MultiValue: CustomMultiValue,
                MenuList: renderScrollbar
              }}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              styles={{
                multiValueLabel: (base) => {
                  return {
                    ...base,
                    fontFamily: "Rene_Bieder_Milliard_Book",
                    backgroundColor: "white",
                  };
                },
                multiValueRemove: (base) => {
                  return {
                    ...base,
                    display: "none",
                  };
                },
                placeholder: (base) => {
                  return {
                    ...base,
                    color: "#7C7C7C",
                  };
                },
                menu: (base) => {
                  return {
                    ...base,
                    border: "none",
                    marginTop: 0,
                    borderTop: "none",
                    boxShadow: "none",
                    color: "lightgrey",
                    paddingTop: 5,
                    // borderTopLeftRadius: "0px",
                    // borderTopRightRadius: "0px",
                    "&:hover": {
                      borderRadius: 12,
                    },
                  };
                },
                dropdownIndicator: (base, state) => {
                  return {
                    ...base,
                    color: "dodgerblue",
                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                  };
                },
                control: (base, state) => {
                  return {
                    ...base,
                    backgroundColor: "white",
                    boxShadow: "none",
                    height: "40px",
                    borderRadius: state.isFocused ? "10px " : "10px",
                    border: `${causesOpen ? "1px solid  #009BE2" : "1px solid lightgrey "
                      }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    "&:hover": {
                      borderBottom: `${causesOpen
                        ? "1px solid  #009BE2"
                        : "1px solid lightgrey"
                        }`,
                    },
                  };
                },
                indicatorSeparator: (styles) => ({
                  ...styles,
                  display: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: "#7C7C7C",
                  backgroundColor: state.isSelected ? "white" : "inherit",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#009BE2" : "#009BE2",
                    color: "white",
                  },
                }),
                menuList: (base) => {
                  return {
                    ...base,
                    position: "relative",
                    top: "-5px",
                    zIndex: -1,
                    color: "grey",
                    height: 120,
                  };
                },
              }}
            />
          </div>
        </div>
        <div>
          <Typography style={{ marginTop: 64, ...FAMILYFONTS.family3, fontSize: 16 }} className={module.label}>
            Who do they help ?
          </Typography>
          <div
            style={{
              position: "absolute",
              zIndex: 24,
              border: `${helpOpen ? "1px solid  #3696C3" : "1px solid lightgrey"
                }`,
              width: "92%",
              borderRadius: 10,
              borderBottom: `${!helpOpen ? "1px solid lightgrey" : "1px solid  #3696C3"
                }`,
              height: `${!helpOpen ? "40px" : "170px"}`,
            }}
          >
            <Select
              onMenuClose={() => {
                setHelpOpen(false);
              }}
              onMenuOpen={() => {
                setHelpOpen(true);
              }}
              options={props?.helpOptions}
              onChange={(e) => props.handleHelpChange(e.map((it: any) => it.value))}
              isClearable={false}
              isMulti
              components={{
                Option,
                MultiValue: CustomMultiValue,
                MenuList: renderScrollbar
              }}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              styles={{
                multiValueLabel: (base) => {
                  return {
                    ...base,
                    fontFamily: "Rene_Bieder_Milliard_Book",
                    backgroundColor: "white",
                  };
                },
                multiValueRemove: (base) => {
                  return {
                    ...base,
                    display: "none",
                  };
                },
                placeholder: (base) => {
                  return {
                    ...base,
                    color: "#7C7C7C",
                  };
                },
                menu: (base) => {
                  return {
                    ...base,

                    border: "none",
                    marginTop: 0,
                    borderTop: "none",
                    boxShadow: "none",

                    color: "lightgrey",
                    paddingTop: 5,
                    // borderTopLeftRadius: "0px",
                    // borderTopRightRadius: "0px",
                    "&:hover": {
                      borderRadius: 12,
                    },
                  };
                },
                dropdownIndicator: (base, state) => {
                  return {
                    ...base,
                    color: "dodgerblue",
                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                  };
                },
                control: (base, state) => {
                  return {
                    ...base,
                    backgroundColor: "white",
                    boxShadow: "none",
                    height: "40px",
                    borderRadius: state.isFocused ? "10px " : "10px",
                    border: `${helpOpen ? "1px solid  #009BE2" : "1px solid lightgrey "
                      }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    "&:hover": {
                      borderBottom: `${helpOpen ? "1px solid  #009BE2" : "1px solid lightgrey"
                        }`,
                    },
                  };
                },
                indicatorSeparator: (styles) => ({
                  ...styles,
                  display: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: "#7C7C7C",
                  backgroundColor: state.isSelected ? "white" : "inherit",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#009BE2" : "#009BE2",
                    color: "white",
                  },
                }),
                menuList: (base) => {
                  return {
                    ...base,
                    position: "relative",
                    top: "-5px",
                    zIndex: -1,
                    color: "grey",
                    height: 120,
                  };
                },
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: 64 }}>
          <Typography className={module.label} style={{ ...FAMILYFONTS.family3, fontSize: 16 }}>
            Where do they operate ?
          </Typography>
          <div
            style={{
              position: "absolute",
              zIndex: 22,
              border: `${whereOpen ? "1px solid  #3696C3" : "1px solid lightgrey"
                }`,
              width: "92%",
              borderRadius: 10,
              borderBottom: `${!whereOpen ? "1px solid lightgrey" : "1px solid  #3696C3"
                }`,
              height: `${!whereOpen ? "40px" : "170px"}`,
            }}
          >
            <Select
              onMenuClose={() => {
                setWhereOpen(false);
              }}
              onMenuOpen={() => {
                setWhereOpen(true);
              }}
              options={props?.whereOptions}
              onChange={(e) => props.handleWhereChange(e.map((it: any) => it.value))}
              isClearable={false}
              isMulti
              components={{
                Option,
                MultiValue: CustomMultiValue,
                MenuList: renderScrollbar
              }}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              styles={{
                multiValueLabel: (base) => {
                  return {
                    ...base,
                    fontFamily: "Rene_Bieder_Milliard_Book",
                    backgroundColor: "white",
                  };
                },
                multiValueRemove: (base) => {
                  return {
                    ...base,
                    display: "none",
                  };
                },
                placeholder: (base) => {
                  return {
                    ...base,
                    color: "#7C7C7C",
                  };
                },
                menu: (base) => {
                  return {
                    ...base,
                    border: "none",
                    marginTop: 0,
                    borderTop: "none",
                    boxShadow: "none",
                    color: "lightgrey",
                    paddingTop: 5,
                    "&:hover": {
                      borderRadius: 12,
                    },
                  };
                },
                dropdownIndicator: (base, state) => {
                  return {
                    ...base,
                    color: "dodgerblue",
                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                  };
                },
                control: (base, state) => {
                  return {
                    ...base,
                    backgroundColor: "white",
                    boxShadow: "none",
                    height: "40px",
                    borderRadius: state.isFocused ? "10px " : "10px",
                    border: `${whereOpen ? "1px solid  #009BE2" : "1px solid lightgrey "
                      }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    "&:hover": {
                      borderBottom: `${whereOpen ? "1px solid  #009BE2" : "1px solid lightgrey"
                        }`,
                    },
                  };
                },
                indicatorSeparator: (styles) => ({
                  ...styles,
                  display: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: "#7C7C7C",
                  backgroundColor: state.isSelected ? "white" : "inherit",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#009BE2" : "#009BE2",
                    color: "white",
                  },
                }),
                menuList: (base) => {
                  return {
                    ...base,
                    position: "relative",
                    top: "-5px",
                    zIndex: -1,
                    color: "grey",
                    height: 100,
                  };
                },
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: 64 }}>
          <Typography className={module.label} style={{ ...FAMILYFONTS.family3, fontSize: 16 }}>
            What they do ?
          </Typography>
          <div
            style={{
              position: "absolute",
              zIndex: 20,
              border: `${whatOpen ? "1px solid  #3696C3" : "1px solid lightgrey"
                }`,
              width: "92%",
              borderRadius: 10,
              borderBottom: `${!whatOpen ? "1px solid lightgrey" : "1px solid  #3696C3"
                }`,
              height: `${!whatOpen ? "40px" : "170px"}`,
            }}
          >
            <Select
              onMenuClose={() => {
                setWhatOpen(false);
              }}
              onMenuOpen={() => {
                setWhatOpen(true);
              }}
              options={props?.provideOptions}
              onChange={(e) => props.handleProvideChange(e.map((it: any) => it.value))}
              isClearable={false}
              isMulti
              components={{
                Option,
                MultiValue: CustomMultiValue,
                MenuList: renderScrollbar
              }}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              styles={{
                multiValueLabel: (base) => {
                  return {
                    ...base,
                    fontFamily: "Rene_Bieder_Milliard_Book",
                    backgroundColor: "white",
                  };
                },
                multiValueRemove: (base) => {
                  return {
                    ...base,
                    display: "none",
                  };
                },
                placeholder: (base) => {
                  return {
                    ...base,
                    color: "#7C7C7C",
                  };
                },
                menu: (base) => {
                  return {
                    ...base,
                    border: "none",
                    marginTop: 0,
                    borderTop: "none",
                    boxShadow: "none",

                    color: "lightgrey",
                    paddingTop: 5,
                    // borderTopLeftRadius: "0px",
                    // borderTopRightRadius: "0px",
                    "&:hover": {
                      borderRadius: 12,
                    },
                  };
                },
                dropdownIndicator: (base, state) => {
                  return {
                    ...base,
                    color: "dodgerblue",
                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                  };
                },
                control: (base, state) => {
                  return {
                    ...base,
                    backgroundColor: "white",
                    boxShadow: "none",
                    height: "40px",
                    borderRadius: state.isFocused ? "10px " : "10px",
                    border: `${whatOpen ? "1px solid  #009BE2" : "1px solid lightgrey "
                      }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    "&:hover": {
                      borderBottom: `${whatOpen ? "1px solid  #009BE2" : "1px solid lightgrey"
                        }`,
                    },
                  };
                },
                indicatorSeparator: (styles) => ({
                  ...styles,
                  display: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: "#7C7C7C",
                  backgroundColor: state.isSelected ? "white" : "inherit",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#009BE2" : "#009BE2",
                    color: "white",
                  },
                }),
                menuList: (base) => {
                  return {
                    ...base,
                    position: "relative",
                    top: "-5px",
                    zIndex: -1,
                    color: "grey",
                    height: 120,
                  };
                },
              }}
            />
          </div>
        </div>
        <Button onClick={() => props.onClick()} className={module.btn} style={{ ...FAMILYFONTS.family3, fontSize: 18 }}>Search</Button>
      </div>
    </Box>
  );
};

export default BusDropdowns;
