import React, { forwardRef } from "react";
// Customizable Area Start
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import {
  Snackbar,
  SnackbarContent,
  Card,
  CardContent,
  InputAdornment,
  FormGroup,
  Container,
  TextField,
  IconButton,
  Input,
  Switch,
  Grid,
  Checkbox,
  Button,
  FormControlLabel,
  withStyles,
  createStyles,
  Typography,
  Theme,
} from "@material-ui/core";
import Select from "react-select";
import { COLORS } from "../../../framework/src/Globals";
import { FONTS, icons } from "../../../components/src/constants";
import ContentManagementController, { configJSON } from "./ContentManagementController";

// @ts-ignore
import styles from "./ContentManagementEvent.module.css";
import moment from "moment";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";
import CatogaryDropDown from "../../../components/src/custom/NeedCatogaoryDropdown";
import DBSDropDown from "../../../components/src/custom/NeedDBSDropdown";


export const useStyles = (theme: Theme) =>
  createStyles({
    textField: {
      padding: 0,
      borderRadius: 12,
      height: 54,
      width: "50%",
      fontFamily: "Rene_Bieder_Milliard_Book",
      border: "none",
      boxShadow: "0px 0px 8px rgba(212, 212, 212, 0.5)",
    },
    // textField1: {
    //   padding: 0,
    //   borderRadius: 12,
    //   height: 54,
    //   width: "180%",
    //   fontFamily: "Rene_Bieder_Milliard_Book",
    //   border: "none",
    // },
    fontResize: {
      fontWeight: 400,
      lineHeight: " 20px",
      letterSpacing: "-0.4px",
      color: "black",
    },
    pickerMargin: {
      marginTop: 0,
      marginBottom: 0,
    },
  });

const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 18,
      padding: 0,
      display: "flex",
      marginLeft: 5,
    },
    switchBase: {
      padding: 2,
      // color: theme.palette.grey[500],
      color: "white",
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#359ce2",
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 18 / 2,
      opacity: 1,
      // backgroundColor: theme.palette.common.white,
      backgroundColor: "grey",
    },
    checked: {},
  })
)(Switch);

// @ts-ignore
const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <TextField
    variant="outlined"
    placeholder="dd/mm/yyyy"
    value={value}
    className={styles.textInput}
    onClick={onClick}
    data-testid="textInputTest"
    name="dateTest"
    onChange={onChange}
    InputProps={{
      classes: {
        root: styles.locationField,
      },
      endAdornment: (
        <div>
          <InputAdornment position="end">
            <img
              src={icons.npEformCalendar}
              style={{ width: 18 }}
            // style={{ height: 28, width: "auto" }}
            />
          </InputAdornment>
        </div>
      ),
    }}
  />
));

const eventTypes = [
  { label: "Need", value: "need" },
  { label: "Project funding", value: "project_funding" },
  { label: "Upcoming activity", value: "activity" },
];


// Customizable Area End
export class NeedsEventWeb extends ContentManagementController {
  nameOfEvent() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading} style={{ marginBottom: 10 }}>
          Name of event *
        </label>
        <br />
        <Input
          data-testid="inputTest1"
          value={this.state.eventName}
          onChange={(e) => {
            this.setState({ eventType: "need" });
            this.setState({
              eventName: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
            if (e.target.value.length < 1) {
              this.setState({ eventNameValidation: true });
            } else {
              this.setState({
                eventNameValidation: false,
              });
            }
          }}
          name="eventInput"
          data-testId="eventName"
          className={styles.textInput}
          disableUnderline
          placeholder="Name of event"
        />
        <br />
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventNameValidation ? "flex" : "none"}`,
          }}
        >
          Event name can't be blank
        </span>
      </div>
    );
  }

  befriendingCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-1"
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                onChange={(e) => {
                  this.setState({ befriending: e.target.checked });
                }}
                data-testid="checkboxTest"
                name="checkboxName"
              />
            }
            label={<span className={styles.label}>Befriending</span>}
          />
        </span>
      </FormGroup>
    );
  }

  diyCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-2"
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                onChange={(e) => {
                  this.setState({
                    diy: e.target.checked,
                  });
                }}
              />
            }
            label={<span className={styles.label}>DIY</span>}
          />
        </span>
      </FormGroup>
    );
  }

  environmentCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-3"
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                onChange={(e) => {
                  this.setState({
                    environment: e.target.checked,
                  });
                }}
              />
            }
            label={<span className={styles.label}>Environment</span>}
          />
        </span>
      </FormGroup>
    );
  }

  elderlyCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-4"
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                onChange={(e) => {
                  this.setState({
                    elderly: e.target.checked,
                  });
                }}
              />
            }
            label={<span className={styles.label}>Elderly</span>}
          />
        </span>
      </FormGroup>
    );
  }

  gardeningCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-5"
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                onChange={(e) => {
                  this.setState({
                    gardening: e.target.checked,
                  });
                }}
              />
            }
            label={<span className={styles.label}>Gardening</span>}
          />{" "}
        </span>
      </FormGroup>
    );
  }

  mentoringCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-6"
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                onChange={(e) => {
                  this.setState({
                    mentoring: e.target.checked,
                  });
                }}
              />
            }
            label={<span className={styles.label}>Mentoring</span>}
          />
        </span>
      </FormGroup>
    );
  }

  neighbourhoodCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-7"
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                onChange={(e) => {
                  this.setState({
                    neighbourhood: e.target.checked,
                  });
                }}
              />
            }
            label={<span className={styles.label}>Neighbourhood</span>}
          />
        </span>
      </FormGroup>
    );
  }

  dbsCheck() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>
          Disclosure and Barring Service (DBS) registration required?
        </label>
        {/* @ts-ignore */}
        <div style={{ marginLeft: -5 }}>
          <AntSwitch
            value={this.state.dbs_registration}
            data-testId="dbsSwitch"
            onChange={(e: any) => {
              this.setState({ dbs_registration: e.target.checked });
            }}
          />
        </div>
      </div>
    );
  }

  needDesc() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Description of need *</label>
        <br />
        <Input
          disableUnderline
          value={this.state.description}
          onChange={(e) => {
            this.setState({
              description: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          data-testId="desc"
          multiline
          rows={6}
          className={styles.multiLineInput}
          placeholder="Provide a general description visible to all users on the platform."
        />
      </div>
    );
  }

  notesField() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Itinerary and notes</label>
        <br />
        <Input
          disableUnderline
          data-testid="personalInfoTest"
          name="personalInfo"
          value={this.state.itinerarv_and_notes}
          onChange={(e) => {
            this.setState({
              itinerarv_and_notes: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          data-testId="notes"
          multiline
          rows={6}
          className={styles.multiLineInput}
          placeholder="Sensitive/personal information only visible to volunteer(s) confirmed for need fulfilment"
        />
      </div>
    );
  }

  volunteersField() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>
          Number of volunteers required
        </label>
        <br />
        <Input
          value={this.state.volunteers_num}
          inputProps={{
            maxLength: 8,
          }}
          data-testId="voluntener"
          onChange={(e) => {
            const pincode = e.target.value;
            if (/^\d{0,8}$/.test(pincode)) {
              this.setState({
                volunteers_num: pincode,
                eventVolunteerValidation: false,
              });
            } else {
              this.setState({
                eventVolunteerValidation: true,
              });
            }
          }}
          type="text"
          disableUnderline
          placeholder="0"
          className={styles.textInput}
        />
        <Typography
          style={{
            ...FONTS.body1,
            color: "lightgrey",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          This will be the maximum number of participants required for your
          event.
        </Typography>
        <Typography
          style={{
            ...FONTS.body1,
            color: "lightgrey",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Leave it 0 for no limit.
        </Typography>
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventVolunteerValidation ? "flex" : "none"}`,
          }}
        >
          Enter valid details
        </span>
      </div>
    );
  }

  everyoneCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.everyone}
                style={{
                  color: "lightgrey",
                }}
                onChange={(e) => {
                  if (this.state.registered_user && this.state.followers) {
                    this.setState({ everyone: true });
                  }
                  this.setState({
                    everyone: e.target.checked,
                    registered_user: e.target.checked,
                    followers: e.target.checked,
                  });
                }}
                checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
              />
            }
            data-testId="check-8"
            label={<span className={styles.label}>Everyone else</span>}
          />
        </span>
      </FormGroup>
    );
  }

  followersCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-9"
            label={<span className={styles.label}>Followers</span>}
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checkedIcon={
                  <CheckBoxIcon style={{ color: "#359ce2" }} />
                }
                checked={this.state.followers}
                onChange={(e) => {
                  this.setState({
                    followers: e.target.checked,
                  });
                  if (this.state.registered_user || e.target.checked) {
                    this.setState({ everyone: false })
                  }
                }}
              />
            }

          />
        </span>
      </FormGroup>
    );
  }

  registeredUserCheck() {
    return (
      <FormGroup>
        <span>
          <FormControlLabel
            data-testId="check-10"
            control={
              <Checkbox
                style={{
                  color: "lightgrey",
                }}
                checked={this.state.registered_user}
                checkedIcon={
                  <CheckBoxIcon
                    style={{
                      color: "#359ce2",
                    }}
                  />
                }
                onChange={(e) => {
                  if (e.target.checked || this.state.followers) {
                    this.setState({ everyone: false })
                  }
                  this.setState({
                    registered_user: e.target.checked,
                  });
                }}
              />
            }
            label={<span className={styles.label}>Registered user</span>}
          />
        </span>
      </FormGroup>
    );
  }

  fromDate(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>From date *</label>
        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiOutlinedInput-root.Mui-Focused .MuiOutlinedInput-notchedOutline   {
              border : 1px solid black
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          placeholder="dd/mm/yyyy"
          disableToolbar
          label=""
          format="DD/MM/YYYY"
          minDate={new Date()}
          variant="inline"
          inputVariant="outlined"
          margin="normal"
          fullWidth
          data-testid="datePickerTest"
          id="date-picker-inline"
          data-testId="date-picker-inline"
          InputProps={{
            classes: {
              root: classes.textField,
              input: classes.fontResize,
            },
          }}
          value={this.state.from_date}
          autoOk
          onChange={this.onFromDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
          style={{ fontSize: 16, marginTop: 0 }}
        />
      </div>
    );
  }

  toDate(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>To date *</label>

        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          fullWidth
          margin="normal"
          autoOk
          placeholder="dd/mm/yyyy"
          disabled={!this.state.from_date}
          disableToolbar
          label=""
          minDate={this.state.from_date}
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          id="date-picker-inline"
          data-testId="date-picker-inline-todate"
          InputProps={{
            classes: {
              input: classes.fontResize,
              root: classes.textField,
            },
          }}
          value={this.state.to_date}
          onChange={this.onToDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
          style={{ fontSize: 16, marginTop: 0 }}
        />
      </div>
    );
  }

  startTime(classes: any) {
    return (
      <div className={styles.divWrapper} style={{ position: "relative" }}>
        <div style={{ display: "flex", width: "50%" }}><label 
        className={styles.labelHeading}>Start time *
        </label>
          <Typography
            style={{
              fontFamily: "Rene_Bieder_Milliard_Medium",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item className={styles.labelHeading}>
                All day
              </Grid>

              <Grid item>
                {/* @ts-ignore */}
                <AntSwitch
                  checked={this.state.all_day}
                  data-testId="allDaySwitch"
                  onChange={(e: any) => {
                    this.setState({ all_day: e.target.checked });
                    if (e.target.checked) {
                      this.setState({
                        start_time: moment().set({ 'hour': 9, 'minute': 0 }),
                        finish_time: moment().set({ 'hour': 17, 'minute': 0 }),
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        </div>

        <InitiateThemeProvider>
          <KeyboardTimePicker
            autoOk
            data-testId='startTime'
            color="primary"
            fullWidth
            ampm={false}
            disabled={this.state.all_day}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            id="mui-pickers-time"
            className={classes.pickerMargin}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            placeholder="12:00 AM"
            mask="__:__ _M"
            value={this.state.start_time}
            onChange={this.onStartTimeChange}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                src={icons.clockIcon}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
    );
  }

  finishTime(classes: any) {
    return (
      <div className={styles.divWrapper} style={{ position: "relative" }}>
        <label className={styles.labelHeading}>Finish time *</label>
        <br />
        <InitiateThemeProvider>
          <KeyboardTimePicker
            id="mui-pickers-time"
            autoOk
            data-testId='finishTime'
            inputVariant="outlined"
            ampm={false}
            disableToolbar
            fullWidth
            disabled={this.state.all_day}
            variant="inline"
            className={classes.pickerMargin}
            margin="normal"
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            placeholder="12:00 AM"
            mask="__:__ _M"
            onChange={this.onFinishTimeChange}
            value={this.state.finish_time}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
              src={icons.clockIcon}
              style={{ height: "auto", width: 20 }}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
    );
  }

  locationFieldPlaces() {
    return (
      <div className={styles.divWrapper} style={{ width: '50%', position: 'relative' }}>
        <label className={styles.labelHeading}>Location *</label>
        <br />
        {/* @ts-ignore */}
        <GooglePlacesAutoComplete
          data-testId='location'
          apiKey={configJSON.mapsAPIKey}
          debounce={500}
          selectProps={{
            isClearable: true,
            value: this.state.location ? [{ label: this.state.location, value: this.state.location }] : undefined,
            // classNamePrefix: "react-select",
            placeholder: "Enter location",
            noOptionsMessage: () => null,
            onChange: (event: any) => {
              const location = event?.value?.description ?? "";
              this.setState({ location });
              if (location) {
                this.getLatLong(event?.value?.description);
              }
            },
            styles: {
              placeholder: (base) => {
                return {
                  ...base,
                  color: "lightgrey",
                  fontFamily: 'Rene_Bieder_Milliard_Book',
                  fontSize: '16px'
                };
              },

              menu: (base) => {
                return {
                  ...base,
                  paddingLeft: 20,
                  paddingRight: 20,
                  border: "1px solid lightgrey",
                  marginTop: 0,
                  borderTop: "none",
                  boxShadow: "none",

                  color: "lightgrey",
                  paddingTop: 5,
                  "&:hover": {
                    borderRadius: 12,
                  },
                };
              },
              clearIndicator: (base) => {
                return {
                  ...base,
                  marginRight: 20
                }
              },
              dropdownIndicator: (base) => {
                return {
                  ...base,
                  color: "dodgerblue",
                  display: 'none'
                };
              },
              control: (base) => {
                return {
                  ...base,
                  fontSize: 16,
                  fontFamily: 'Rene_Bieder_Milliard_Book',
                  backgroundColor: "white",
                  boxShadow: "none",
                  height: "54px",
                  width: '101%',
                  borderRadius: "10px",
                  border: '2px solid lightgrey',
                };
              },
              option: (provided, state) => ({
                ...provided,
                color: "black",
                backgroundColor: state.isSelected
                  ? "#192E49"
                  : "inherit",
                "&:hover": {
                  backgroundColor: "#009BE2",
                  color: "white",
                },
              }),
              menuList: (base) => {
                return {
                  ...base,
                  marginTop: 15,
                  position: "relative",
                  top: "-5px",
                  zIndex: -1,
                  color: "grey",
                };
              },
            }
          }}
        >

        </GooglePlacesAutoComplete>
        <img
          src={icons.locationIcon}
          style={{
            height: 23, width: "auto", position: 'absolute', top: 45, right: 8
          }}
        />
      </div >
    )
  }


  uploadImg() {
    return (
      <div className={styles.divWrapper} style={{ width: "50%" }}>
        <label className={styles.labelHeading}>Upload image *</label>
        <CardContent className={styles.cardUploadImage}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              background: COLORS.white,
              width: "106%",
              position: 'relative',
              marginLeft: -12,
              height: "22%",
              // border: "1px solid #a7b6b9 ",
              border: "1px solid #dfdfdf",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: `${this.state.needsEventImg ? "flex" : "none"}`,
                top: 0,
                position: "absolute",
                right: 0,
              }}
              data-testId="imgPreview"
              onClick={(e) => {
                this.setState({
                  needsEventImg: "",
                  needsEventImgPreview: "",
                });
              }}
            >
              <HighlightOffRoundedIcon />
            </div>
            {this.state.needsEventImg ? (
              <img
                src={this.state.needsEventImgPreview}
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 10,
                }}
              />
            ) : (
              <IconButton
                color="primary"
                // className={classes.imageuploadicon}
                aria-label="upload picture"
                component="label"
              >
                <img
                  src={icons.plusIcon}
                  style={{ height: 20 }}
                  alt="cameraIcon"
                />

                <input
                  accept="image/png, image/jpeg"
                  type="file"
                  hidden
                  data-testId="userImageUpload"
                  onChange={(event) => {
                    event.preventDefault();
                    if (event.target.files && event.target.files[0]) {
                      let file = event.target.files[0];
                      this.setState({
                        needsEventImgPreview: URL.createObjectURL(file),
                      });
                      this.setState({
                        needsEventImg: file,
                      });
                    }
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </CardContent>
      </div>
    );
  }

  isSaveBtnDisable = () => {
    return !this.state.eventName ||
    !this.state.location ||
    !this.state.from_date ||
    !this.state.to_date ||
    !this.state.description ||
    !this.state.needsEventImg ||
    !this.state.start_time ||
    !this.state.finish_time ||
    this.state.createEventBtn;
  }

  btnGrp() {
    return (
      <div
        className={styles.divWrapper}
        style={{ display: "flex", marginTop: 15, marginLeft: -10 }}
      >
        <Button
          disabled={this.isSaveBtnDisable()}
          onClick={() => {
            this.setState({ createEventBtn: true });
            this.needsEvent();
          }}
          data-testId="createBtn"
          style={{
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
            width: "12%",
            boxShadow: "none",
            textTransform: "capitalize",
            height: 45,
            backgroundColor: `${
              !this.isSaveBtnDisable()
              ? "#009be2"
              : "#009be233"
              }`,
            color: "white ",
            borderRadius: 10,
            marginRight: 13,
            marginLeft: 13,
          }}
          color="primary"
        >
          Save
        </Button>
        <Button
          data-testId="cancelBtn"
          onClick={() => {
            this.props.navigationToNeeds();
          }}
          style={{
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
            width: "12%",
            backgroundColor: COLORS.white,
            color: "#009BE2",
            boxShadow: "none",
            borderRadius: 8,
            height: 45,
            border: ".1 solid lightgray",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
      </div>
    );
  }

  publishSwitch() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Publish</label>
        <br />
        {/* @ts-ignore */}
        <AntSwitch
          value={this.state.publish}
          data-testId="publishSwitch"
          onChange={(e: any) => {
            this.setState({ publish: e.target.checked });
          }}
        />
      </div>
    );
  }

  render() {
    const { classes }: any = this.props;

    return (
      // Customizable Area Start
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <Container maxWidth="xl">
            <Card
              style={{
                borderRadius: 12,
                margin: "auto",
                marginTop: 30,
              }}
            >
              <div style={{ marginTop: 10 }} />
              <div className={styles.newEventTitle}>
                <label className={styles.heading} 
                style={{ padding: 15 }}>  New event </label>
              </div>
              <CardContent
                style={{
                  borderTop: "2px solid #eaeaea",
                  padding: 15,
                }}
              >
                <div className={styles.divWrapper}>
                  <label className={styles.labelHeading}>Selected type</label>
                  <br />
                  {/* @ts-ignore */}
                  <div
                    style={{
                      // marginTop: 10,
                      border: `${this.state.createEventState
                        ? "1px solid  #009BE2"
                        : "1px solid lightgrey"
                        }`,
                      width: "50%",
                      borderRadius: 10,
                      borderBottom: `${!this.state.createEventState
                        ? "1px solid lightgrey"
                        : "1px solid  #009BE2"
                        }`,
                      height: `${!this.state.createEventState ? "54px" : "180px"
                        }`,
                    }}
                  >
                    <Select
                      isDisabled={true}
                      onMenuOpen={() => {
                        this.setState({ createEventState: true });
                      }}
                      onMenuClose={() => {
                        this.setState({ createEventState: false });
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "skyblue",
                          primary: "dodgerblue",
                        },
                      })}
                      styles={{
                        menu: (base) => {
                          return {
                            ...base,
                            paddingLeft: 20,
                            paddingRight: 20,
                            border: "none",
                            marginTop: 0,
                            borderTop: "none",
                            boxShadow: "none",
                            color: "black",
                            paddingTop: 5,
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px",
                          };
                        },
                        singleValue: (base) => {
                          return {
                            ...base,
                            color: "black",
                          };
                        },
                        dropdownIndicator: (base) => {
                          return {
                            ...base,
                            color: "lightgrey",
                          };
                        },
                        control: (base, state) => {
                          return {
                            ...base,
                            height: "54px",
                            cursor: "not-allowed",
                            backgroundColor: "white",
                            boxShadow: "none",
                            borderRadius: state.isFocused ? "10px " : "10px",
                            border: `${this.state.createEventState
                              ? "1px solid  #009BE2"
                              : "1px solid lightgrey"
                              }`,
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            "&:hover": {
                              borderBottom: `${this.state.createEventState
                                ? "1px solid  #009BE2"
                                : "1px solid lightgrey"
                                }`,
                            },
                          };
                        },
                        indicatorSeparator: (styles) => ({
                          ...styles,
                          display: "none",
                        }),
                        menuList: (base) => {
                          return {
                            ...base,
                            marginTop: 15,
                            position: "relative",
                            top: "-5px",
                            zIndex: -1,
                          };
                        },
                      }}
                      name="eventTypes"
                      data-testId="eventTypes"
                      isSearchable={false}
                      isClearable={false}
                      isRtl={false}
                      options={eventTypes}
                      defaultValue={eventTypes[0]}
                      className={styles.selectInput}
                      onChange={(e: any) => {
                        this.setState({ eventType: e.value });
                      }}
                    />
                  </div>
                </div>
                {this.nameOfEvent()}

                <div className={styles.divWrapper}>
                  <label className={styles.labelHeading}>Categories</label>
                  <br />
                  <CatogaryDropDown
                    catogaryOptions={this.state.taskPreferenceData}
                    handleTaskPreferenceDropdownIds={(val : any) => this.handleTaskPreferenceDropdownIds(val)}
                  />
                </div>
                <br />
                <br />
                <br />

                {this.dbsCheck()}
                <div
                  className={styles.divWrapper}
                  style={{
                    display: this.state.dbs_registration ? "block" : "none",
                  }}
                >

                  <DBSDropDown
                    catogaryOptions={this.state.dbsData}
                    handleDBSTypeDropdownIds={(val: any) => this.handleDBSTypeDropdownIds(val)}
                  />
                  <br />
                  <br />
                  <br />
                </div>
                {this.needDesc()}
                {this.notesField()}
                {this.volunteersField()}
                <div className={styles.divWrapper}>
                  <label className={styles.labelHeading}>
                    Publishing scope
                  </label>
                  <br />
                  {this.everyoneCheck()}
                  {this.followersCheck()}
                  {this.registeredUserCheck()}
                </div>
                {this.fromDate(classes)}
                {this.toDate(classes)}
                {this.startTime(classes)}
                {this.finishTime(classes)}
                {this.locationFieldPlaces()}
                {this.uploadImg()}
                {this.publishSwitch()}
                {this.btnGrp()}
                {this.state.eventUpdateError.length > 0 && (
                  <Snackbar
                    open={this.state.eventUpdateError.length > 0}
                    onClose={() => {
                      this.setState({ eventUpdateError: "" });
                    }}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <SnackbarContent
                      className={styles.snackbar}
                      message={"Server Error. Please try again later."}
                    />
                  </Snackbar>
                )}
              </CardContent>
            </Card>
          </Container>
        </div>
      </MuiPickersUtilsProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(useStyles as any)(NeedsEventWeb as any);
// Customizable Area End
