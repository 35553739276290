// Customizable Area Start
import React, { useEffect, useState } from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import { useQueryClient } from '@tanstack/react-query';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { isMobile } from "react-device-detect";
import {
  format,
  isBefore,
  isAfter,
  addHours,
  differenceInMinutes,
} from "date-fns";
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { InitiateSwitch } from '../../../components/src/design-system/switch';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { InitiateLabel } from '../../../components/src/design-system/label';
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { useSetAvailabilityForDay } from '../../../components/src/api/hooks/user.hooks';
import { InitiateAlert } from '../../../components/src/design-system/alert';
import { userKeys } from '../../../components/src/api/keys/user.keys';
import { InitiateKeyboardDatePicker } from '../../../components/src/design-system/date-picker';
import { InitiateKeyboardTimePicker } from '../../../components/src/design-system/time-picker';
import { formatDate } from './HoursLines';


const useEventPickerStyles = makeStyles({
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timePickersContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  allDayContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  allDayText: {
    fontFamily:"Rene_Bieder_Milliard_SemiBold",
    paddingLeft: '8px',
    fontSize:"18px",
    
  },
  headingText:{
    fontFamily:"Rene_Bieder_Milliard_SemiBold",
    fontSize:"18px",
    marginLeft:4,
  },
  fontText:{
    fontFamily:"Rene_Bieder_Milliard_Medium",
  },
  noticeText: {
    color: '#838383',
    fontSize: '0.8rem',
    whiteSpace:'normal'
  },
});

const nineAM = new Date();
nineAM.setHours(9);
nineAM.setMinutes(0);

const tenAM = new Date();
tenAM.setHours(10);
tenAM.setMinutes(0);


export function useDates(filterByDate: Date, setIsDisabled?: (val: boolean) => void) {

  const [fromDate, setFromDate] = useState(filterByDate);
  const [toDate, setToDate] = useState(filterByDate);

  const [fromTime, setFromTime] = useState(nineAM);
  const [toTime, setToTime] = useState(tenAM);

  const [toTimeError, setToTimeError] = useState('');
  const [timeDiffError, setTimeDiffError] = useState('');



  useEffect(() => {
    const diff = differenceInMinutes(new Date(fromTime), new Date(toTime));
    if (diff == 0)
      setTimeDiffError("From and To time are identical");
    else
      setTimeDiffError('');
  }, [fromTime, toTime]);

  const checkTimeValidation = (fromTime: Date, toTime: Date) => {
    let isBeforeEndTime = false;
    if(fromTime && toTime){
      const startDate = new Date(moment(fromTime).format());
      const endDate = new Date(moment(toTime).format());
      isBeforeEndTime = startDate.getDate() === endDate.getDate() && startDate.getTime() < endDate.getTime();
    }
    if (!isBeforeEndTime){
      setToTimeError('Select a valid time!');
      setIsDisabled?.(true);
    } else {
      setToTimeError('');
      setIsDisabled?.(false);
    }
  }

  return {
    fromDate,
    setFromDate: (date: Date) => {
      // when from date change, to date should be equal or greater than from date
      setFromDate(date);
      const toDateIsBeforeFromDate = isBefore(new Date(toDate), new Date(date))
      if (toDateIsBeforeFromDate)
        setToDate(date);
    },
    toDate, setToDate,
    fromTime,
    setFromTime: (newFromTime: Date) => {
      checkTimeValidation(newFromTime,toTime);
      setFromTime(newFromTime);
    },
    toTime,
    setToTime: (newToTime: Date) => {
      checkTimeValidation(fromTime, newToTime);
      setToTime(newToTime);
    },
    toTimeError,
    timeDiffError,
  }
}
export let submitHandlerCallBack:any = null;


export function EventPicker({
  filterByDate,
  onSuccess,
  setIsDisabled
}: {
  filterByDate: Date,
  onSuccess: () => void,
  setIsDisabled: (val: boolean) => void
}) {

  const classes = useEventPickerStyles();

  const [isAllDay, setIsAllDay] = useState(false);

  const {
    fromDate, setFromDate,
    toDate, setToDate,
    fromTime, setFromTime,
    toTime, setToTime, toTimeError,
    timeDiffError,
  } = useDates(filterByDate, setIsDisabled);

  const { mutate: setAvailability, error, isLoading } = useSetAvailabilityForDay();
  const queryClient = useQueryClient();

  function submitHandler() {
    setAvailability({
      allDay: isAllDay,
      token: localStorage.getItem('token') || '',
      from_date: fromDate.toISOString(),
      to_date: toDate.toISOString(),
      start_time: format(new Date(fromTime),'hh:mm a').toUpperCase(),
      end_time: format(new Date(toTime), 'hh:mm a').toUpperCase(),
    }, {
      async onSuccess() {
        try {
          await queryClient.invalidateQueries(userKeys.fetchAvailabilitiesOfDay({ date: format(new Date(fromDate), 'dd/MM/yyyy') }))
          await queryClient.invalidateQueries(userKeys.fetchAvailabilitiesOfDay({ date: format(new Date(toDate), 'dd/MM/yyyy') }));
        } catch (error) {
          console.error(error);
        } finally {
          onSuccess();
        }
      },
    });
  }

 submitHandlerCallBack= () => { submitHandler() };
 const currentdate=new Date()
 const disableSwitch=currentdate.getHours()>=17 && format(currentdate, 'dd/MM/yyyy')==format(new Date(fromDate), 'dd/MM/yyyy')
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div style={{padding:isMobile?'0px':'6px'}}>
        <div className={classes.spaceBetween}>
          <Typography className={classes.headingText}>
            {'Set Availability'}
          </Typography>

          <div className={classes.allDayContainer}>
            <InitiateSwitch
            disabled={disableSwitch}
              checked={isAllDay}
              onChange={(event) => {
                setIsAllDay(event.target.checked);
                if(event.target.checked){
                  setIsDisabled(false);
                } else {
                  setIsDisabled(!!toTimeError);
                }
                localStorage.setItem('all_day',`${!isAllDay}`);
              }}
              name="allDay"
              aria-label="allDay"
              data-testid="allDay"
            />
            <Typography className={classes.allDayText}>
              {'All day'}
            </Typography>
          </div>
        </div>

        <VerticalSpacer />

        <div className={classes.timePickersContainer}>
          <div>
          {localStorage.setItem('eventpicker_date',formatDate(fromDate,'/').toLowerCase())}
            <InitiateLabel className={classes.fontText} htmlFor="from-date">{'From Date'}</InitiateLabel>
            <VerticalSpacer value={5} />
            <InitiateKeyboardDatePicker
              id="from-date"
              date-testid="from-date"
              date={fromDate}
              setDate={setFromDate}
              helperText=""
              error={false}
              minDate={new Date()}
            />
          </div>
          <div>
            <InitiateLabel className={classes.fontText} htmlFor="to-date">{'To Date'}</InitiateLabel>
            <VerticalSpacer value={5} />
            <InitiateKeyboardDatePicker
              id="to-date"
              date={toDate}
              date-testid="to-date"
              setDate={setToDate}
              helperText=""
              error={false}
              minDate={fromDate}
            />
          </div>
        </div>

        <VerticalSpacer />

        <HideContainer hideWhen={isAllDay}>
          <div className={classes.timePickersContainer}>
            <div>
              {localStorage.setItem('updated_from_time',moment(fromTime).format('hh:mm A'))}
              {localStorage.setItem('updated_to_time',moment(toTime).format('hh:mm A'))}
              <InitiateLabel className={classes.fontText} htmlFor="from-time">{'From Time'}</InitiateLabel>
              <VerticalSpacer value={5} />
              <InitiateKeyboardTimePicker
                id="from-time"
                data-testid="from-time"
                date={fromTime}
                setDate={setFromTime}
                helperText=""
                error={false}
              />
            </div>
            <div>
              <InitiateLabel className={classes.fontText} htmlFor="to-time">{'To Time'}</InitiateLabel>
              <VerticalSpacer value={5} />
              <InitiateKeyboardTimePicker
                id="to-time"
                data-testid="to-time"
                date={toTime}
                setDate={setToTime}
                helperText={toTimeError || timeDiffError}
                error={Boolean(toTimeError || timeDiffError)}
              />
            </div>
          </div>
        </HideContainer>

        <VerticalSpacer />
        <div style={{width:'99%',marginLeft:10}}>
        <Typography className={classes.noticeText}>
          {'Times I am likely to be available to help (for notifications only)'}
        </Typography>
        </div>
        <VerticalSpacer />

        <HideContainer hideWhen={!error}>
          <InitiateAlert>
            {error?.errors?.[0]?.slot_error ?? ''}
          </InitiateAlert>
        </HideContainer>
      
      </div>
    </MuiPickersUtilsProvider>
  );
}



// Customizable Area End

