// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Typography, Button, Box } from "@material-ui/core";
import { phoneIcon } from "./assets";
import OtpInput from "react-otp-input";
import clsx from 'clsx';
import { useNavigate } from "../../../components/src/utils/router-context";
import { useGetMobileOTP, useVerifyMobileOTP } from "../../../components/src/api/hooks/user.hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../framework/src/redux/stores";
import { VerticalSpacer } from "../../../components/src/shared/vertical-spacer";
import { InitiateAlert } from "../../../components/src/design-system/alert";
import { HideContainer } from "../../../components/src/shared/hide-container";
import { InitiateButton } from "../../../components/src/design-system/button";
import { makeStyles } from '@material-ui/core/styles';
import { RegisterationMobileHeader } from "./RegisterationMobileHeader";

export const useStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'min(655px, 80vh)',
    [theme.breakpoints.down('sm')]: {
      height: '90vh',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    backgroundImage: "linear-gradient(#00cbf3,#009be2)",
    width: "168px",
    height: "168px",
    minWidth: "168px",
    minHeight: "168px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    margin: "auto"
  },
  icon: {
    maxWidth: '82px',
    width: "82px",
    height: "82px",
  },
  titleText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  subTitleText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    color: '#2c2c2e',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
    },
  },
  centerText: {
    textAlign: 'center',
  },
  submitBtn: {
    fontFamily: 'Rene_Bieder_Milliard_Medium',
    fontSize: '1.5rem',
  },
  otpInput: {
    '& input': {
      width: "44px !important",
      height: "50px",
      border: "none",
      borderBottom: "1px solid #ccc !important",
      fontSize: "20px !important",
      fontWeight: "400 !important",
      fontFamily: "Rene_Bieder_Milliard_Book,sans-serif !important",
      margin: '0 6px',
      [theme.breakpoints.down('sm')]: {
        width: '36px !important',
      },
    },
  },
  otpInputError: {
    '& input': {
      color: 'red',
      borderBottom: "1px solid red !important",
    },
  },
  verticalSpacer:{
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '10px',
    },
  },
}));


export function MobileVerificationPage() {

  const navigate = useNavigate();

  const { mutate: getOTP } = useGetMobileOTP();
  const { mutate: verify, isLoading, error } = useVerifyMobileOTP();

  const { email, full_phone_number } = useSelector((state: RootState) => state.signupForm);

  const [code, setCode] = useState('');
  const [otpToken, setOtpToken] = useState('');
  const [errorMsg, setErrorMsg] = useState('');


  function verifyOTP() {
    verify({ mobileOtp: code, otpToken }, {
      onSuccess() {
        navigate('/PersonalInformationweb');
      }
    });
  }

  function getOTPHandler() {
    getOTP({ phoneNumber: full_phone_number.replace('+', '') }, {
      onSuccess(response) {
        if (response.token)
          setOtpToken(response.token)
      },
      onError(response){ setErrorMsg(response.errors?.[0]?.account); }
    });
  }

  useEffect(getOTPHandler, [email]);

  let disabled = code.length < 6;
  let errorMessage = error?.errors?.[0]?.pin ?? '';
  let verifyMobileOtpError = Boolean(errorMessage);

  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.container, classes.formContentRoot)}>

        <VerticalSpacer value={30} />

        <div className={classes.iconContainer}>
          <img src={phoneIcon} alt="phone" className={classes.icon} />
        </div>

        <VerticalSpacer value={30} />

        <Typography color="primary" className={classes.titleText}>
          {'Mobile No'}<span style={{ padding: '0 1px' }}>{'\. '}</span>{' Verification!'}
        </Typography>

        <div className={classes.formContent}>
          <VerticalSpacer value={30} />

          <Typography className={classes.subTitleText}>
            {'Please enter the verification code sent to your registered phone number'}
          </Typography>

          <VerticalSpacer value={40} />

          <OtpInput
            value={code}
            onChange={setCode}
            numInputs={6}
            className={clsx(classes.otpInput, {
              [classes.otpInputError]: verifyMobileOtpError
            })}
            containerStyle={{ justifyContent: 'center' }}
            isInputNum
          />

          <HideContainer hideWhen={!verifyMobileOtpError}>
            <InitiateAlert cautionIcon>
              {errorMessage}
            </InitiateAlert>
          </HideContainer>
          <hr className={classes.verticalSpacer}/>
          <HideContainer hideWhen={!!errorMsg}>
          <Typography className={clsx(classes.subTitleText, classes.centerText)}>
            {'Didn\'t receive a code?'}
            <Button variant="text" color="primary" onClick={getOTPHandler}>
              {'Resend Code'}
            </Button>
          </Typography>
          <VerticalSpacer value={5} />
          </HideContainer>
        </div>

        <InitiateButton
          loading={isLoading}
          type="submit"
          disabled={disabled || !!errorMsg}
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          className={classes.submitBtn}
          onClick={verifyOTP}>
          {`Verify Phone`}
        </InitiateButton>
        <VerticalSpacer value={30} />
        <HideContainer hideWhen={!errorMsg}>
          <Box display="flex" justifyContent="center">
          <InitiateAlert cautionIcon>
            {errorMsg}
          </InitiateAlert>
          </Box>
        </HideContainer>
      </div>

      <RegisterationMobileHeader
        title=""
        hideSkipButton
      />

    </>
  );
}

// Customizable Area End
