// Customizable Area Start
import React from "react";


import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

import { createTheme } from "@material-ui/core/styles";
import { MobileVerificationPage } from "./MobileVerificationContent";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class MobileVerification extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
   
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
       <MobileVerificationPage/> 
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export {MobileVerification}
// Customizable Area End
