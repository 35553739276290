import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { isMobile } from "react-device-detect";
const { Client } = require('@twilio/conversations');
import { apiCall } from "../../../components/src/utils/utils.web";
// Customizable Area Start
interface ChatMessages {
    author: string;
    body: string;
    dateUpdated: string;
    index: number;
    media: any;
    memberSid: string;
    sid: string;
    timestamp: string;
    type: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabValue: any;
    chatcategoryvalue: any;
    anchorE1: any;
    chatInfo: boolean;
    mobileView: boolean;
    chatToken: string;
    toggleFunctionality: boolean;
    email: string | null;
    currentChat: any;
    loading: boolean;
    loader: boolean;
    token: string | null;
    userChats: Array<any>;
    totalPages: number,
    currentPage: number,
    perPage: number,
    searchTerm: string,
    paginationLoading: boolean,
    isSelectedChat: boolean,
    chatType:string,
    currentChatTyping: boolean,
    screen:string,
    channelReceipts: any;
    userId: number | null | string;
    messageId: any;
    lastTab: any;
    childKey: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GroupChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    generateTokenMsg: string = '';
    channel: any;
    client: any;
    userChatsMsg: any;
    nextPageChatsMsg: string = '';
    box: any;
    createIndividualConnectionApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleUserClick = this.handleUserClick.bind(this);
        this.searchChat = this.searchChat.bind(this);
        this.debounce = this.debounce.bind(this);
        this.getChats = this.getChats.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.addTypingEvent = this.addTypingEvent.bind(this);
        this.handleMessageList = this.handleMessageList.bind(this);
        this.closeChat = this.closeChat.bind(this);
        this.clearChat = this.clearChat.bind(this);
        this.handleChannelUptaded = this.handleChannelUptaded.bind(this);
        this.getParticipantId = this.getParticipantId.bind(this);
        this.getLatestReceiptsData = this.getLatestReceiptsData.bind(this);
        this.updateChannel = this.updateChannel.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            // Customizable Area Start
            tabValue: "all",
            chatcategoryvalue: 0,
            anchorE1: null,
            mobileView: true,
            chatToken: '',
            toggleFunctionality: false,
            email: '',
            chatInfo: false,
            currentChat: {},
            loading: false,
            loader: false,
            token: '',
            userChats: [],
            totalPages: 0,
            currentPage: 1,
            perPage: 20,
            searchTerm: "",
            paginationLoading: false,
            isSelectedChat: false,
            chatType:"",
            currentChatTyping: false,
            screen: "chat-screen",
            channelReceipts: {},
            userId: 0,
            messageId: null,
            lastTab: "#All",
            childKey: 1
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message?.id) {
            const id = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
            this.setState({ messageId: id });
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.generateTokenMsg) {
                    this.client = new Client(responseJson.token);
                    this.getChats();
                }
                if (apiRequestCallId === this.userChatsMsg) {
                    if(responseJson.errors){
                        this.setState({ loading: false });
                        return;
                    }
                    const chats = this.addTypingEvent(responseJson);
                    this.setState({
                        loading: false,
                        userChats: chats,
                        currentPage: responseJson.meta.data.current_page,
                        totalPages: responseJson.meta.data.total_pages,
                    },() => { this.handleRedirection(chats) })
                }
                this.handleResponse(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleResponse = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.nextPageChatsMsg) {
            const chats = this.addTypingEvent(responseJson);
            this.setState({
                userChats: [...this.state.userChats, ...chats],
                totalPages: responseJson.meta.data.total_pages,
                paginationLoading: false,
            });
        } else if(apiRequestCallId === this.createIndividualConnectionApiCallId){
            let chatInfo = responseJson.data[0];
            chatInfo = this.addTypingEvent(responseJson);
            this.setState({ loader: false, userChats: [...this.state.userChats, ...chatInfo ]},
                () => { this.handleUserClick(chatInfo[0]); }
            );
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        const email = localStorage.getItem('user_email');
        const token = localStorage.getItem('token');
        let userId: string | null | number = "";
        if (localStorage.getItem('acc_id')) {
            userId = localStorage.getItem('acc_id');
        }
        this.setState({
            loading: true,
            email,
            token,
            userId,
        }, () => {
            this.getChatToken(email);
        });
        this.handleHashChange()
    }
    
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (!this.box) {
            this.box = document.getElementById('chatlisting');
        } else {
            this.box.removeEventListener('scroll', this.handleScroll);
        }
        if (this.box) {
            this.box.addEventListener('scroll', this.handleScroll)
        }
        window.addEventListener('hashchange', this.handleHashChange);
    }

    handleHashChange = () => {
    const currentHash = window.location.hash; 
    if (currentHash === '#All'|| currentHash === "") {
        this.setState({ chatcategoryvalue: 0, tabValue: 'all' });
    } else if (currentHash === '#Group') {
        this.setState({ chatcategoryvalue: 1, tabValue: 'group' });
    } else if (currentHash === '#Connection') {
        this.setState({ chatcategoryvalue: 2, tabValue: 'connection' });
    }
    if(this.state.currentChat && !currentHash.includes("viewChat") && isMobile) {
        this.setState({ toggleFunctionality: false, currentChat: null, screen: "chat-screen" });
    }
    };

    async componentWillUnmount(): Promise<void> {
        window.removeEventListener('hashchange', this.handleHashChange);
        this.state.userChats.forEach(async (chat: any) => {
            const channelOb = await this.client.getConversationBySid(chat.attributes.sid);
            if (channelOb._internalState.status != 'joined') {
                await channelOb.join();
            }
            channelOb.removeListener('updated', this.handleChannelUptaded);
            channelOb.removeListener('typingStarted', (member: any) => {
                this.handleTypingStatus(member, channelOb, true);
            });
            channelOb.removeListener('typingEnded', (member: any) => {
                this.handleTypingStatus(member, channelOb, false);
            });
            channelOb.removeListener("messageAdded", this.handleMessageList);
        });
    }
    
    handleScroll = (e: any) => {
        const scrollableHeight = this.box.scrollHeight - this.box.clientHeight

        if (this.box.scrollTop >= scrollableHeight && !this.state.paginationLoading && this.state.currentPage < this.state.totalPages) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                paginationLoading: true
            }, () => {
                this.getPaginationChat();
            });
        }
    }

    handleRedirection = (chats: any) => {
        if (this.state.messageId) {
            const chat = chats.filter((item: any) => {
                return item.attributes.sid === this.state.messageId;
            });
            this.handleUserClick(chat[0]);
        }
    }
    // chat category Change 

    handleChangeChatCategory = (event: any, value: any) => {
        let tabValue = 'all';
        if (value == 1) {
            tabValue = 'group';
        } else if (value == 2) {
            tabValue = 'connection';
        }
        this.setState({ chatcategoryvalue: value, userChats: isMobile ? []: this.state.userChats, tabValue, loading: true, lastTab: `#${tabValue.charAt(0).toUpperCase() + tabValue.slice(1)}` }, () => {
            this.getChats();
        });
    };

    renderSearchFilterPlaceHolder = () => {

        if (this.state.chatcategoryvalue == 0) {
            return "Search or start new chat"
        }
        if (this.state.chatcategoryvalue == 1) {
            return "Search group"
        }
        else {
            return "Search connection"
        }
    }

    handleBack = (hash:string) => {
        let tabValue = "", value = 0;
        if (hash == "#Groups") {
            tabValue = 'group';
            value = 1;
        } else if (hash == "#Connections") {
            tabValue = 'connection';
            value = 2;
        }
        this.setState({ 
            chatcategoryvalue: value, 
            tabValue, 
            loading: true }, () => {
            this.getChats();
        });
    }
 
    getChatToken = (email: string | null) => {
        let authToken = this.state.token
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': authToken,
        };
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.generateTokenMsg = request.messageId;
    
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.chatToken}${email}`
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(request.id, request);
    }

    getChats = () => {
        let authToken = this.state.token;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': authToken,
            'type': 'user_entity'
        };
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.userChatsMsg = request.messageId;
    
        let url = `${configJSON.chats}${this.state.tabValue}&page=${1}&per_page=${this.state.perPage}`;
        if (this.state.searchTerm.trim().length > 0) {
            url += `&search_term=${this.state.searchTerm}`;
        }
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(request.id, request);
    }
 // function to change screen
    screenType = (screen: string) => {
        this.setState({screen: screen})
    }

    toggleType = (toggle: boolean) => {
        this.setState({toggleFunctionality: toggle})
    }
    
    handleUserClick = async (chatInfo: any) => {
        const memberData = chatInfo.attributes.member_data ?? [];
        const participantId = memberData.filter((item: { type: string; }) => item.type.toLowerCase() === "non_profit_entity")[0]?.participant_id;
        window.location.hash = "viewChat";
        this.setState({ 
            mobileView: false,
            childKey: this.state.childKey + 1,
            toggleFunctionality: true,
            currentChat: {...chatInfo, participantId },
            isSelectedChat: chatInfo.id,
            chatType:chatInfo.attributes.friendly_name,
        })
            this.screenType("individual-screen")
            this.toggleType(true)
    }

    searchChat = (event: any) => {
        this.setState({ searchTerm: event.target.value, loading: true });
        this.debounce()();
    }

    debounce = () => {
        let timeout = 500;
        let timer: any;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => { this.getChats(); }, timeout);
        };
    }

    getPaginationChat = () => {    
        let authToken = this.state.token;
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': authToken,
            'type': 'user_entity'
        };
        
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.nextPageChatsMsg = request.messageId;

        let url = `${configJSON.chats}${this.state.tabValue}&page=${this.state.currentPage}&per_page=${this.state.perPage}`;
        if (this.state.searchTerm.trim().length > 0) {
            url += `&search_term=${this.state.searchTerm}`;
        }
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(request.id, request);
    }

    handleTypingStatus = (member: any, channel: any, typingStatus: boolean) => {
        const chats = [...this.state.userChats];
        const updatedChats = chats.map((chat) => {
            if (channel.sid === chat.attributes.sid) {
                chat.attributes.typing = typingStatus;
            }
            return chat;
        });
        let { currentChatTyping } = this.state;
        if (
            this.state.currentChat &&
            Object.keys(this.state.currentChat).length > 0 &&
            this.state.currentChat.attributes.sid == channel.sid
        ) {
            
            currentChatTyping = typingStatus;
        }
        this.setState({
            userChats: updatedChats,
            currentChatTyping
        });
    }

    addTypingEvent = (responseJson: any) => { 
        responseJson.data.map(async (chat: any) => {
            const channelOb = await this.client.getConversationBySid(chat.attributes.sid);
            if (channelOb._internalState.status != 'joined') {
               try {
               await channelOb.join();
               } catch(e){}
            }
            channelOb.on('updated', this.handleChannelUptaded);
            channelOb.on('typingStarted', (member: any) => {
                this.handleTypingStatus(member, channelOb, true);
            });
            channelOb.on('typingEnded', (member: any) => {
                this.handleTypingStatus(member, channelOb, false);
            });
            channelOb.on("messageAdded", this.handleMessageList);
        });
        const chats = responseJson.data.map((chat: any) => {
            if (chat.attributes.clear_chat.length > 0) {
                chat.attributes.clearedChatTime = new Date(chat.attributes.clear_chat[chat.attributes.clear_chat.length - 1].clear_chat.from);
                chat.attributes.lastMessageActualTime = new Date(chat.attributes.last_message_times);
            }
            if (chat.attributes.last_message_times) {
              const date = new Date(chat.attributes.last_message_times);
              const options = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              };
            // @ts-ignore
              chat.attributes.last_message_times = date.toLocaleString('en-US', options);
            }
            return chat;
        });
        return chats;
    }

    getLastMessageStatus = (channelOb: any, memberData: Array<any>, delivery: any, chatRead: any, lastMessageIndex: number) => {
        let status = 'send';
        let deliveryData = delivery;
        let readData = chatRead;
        if (memberData && channelOb.lastMessage) {
            const participantId = this.getParticipantId(memberData);
            let callUpdateGroup = false;
            if (!(participantId in delivery) || delivery[participantId] < lastMessageIndex) {
                callUpdateGroup = true;
                delivery[participantId] = lastMessageIndex;
            }
            if (channelOb._internalState.lastReadMessageIndex < channelOb.lastMessage.index) {
                channelOb.updateLastReadMessageIndex(lastMessageIndex);
            }
            const { deliveries, read } = this.getLatestReceiptsData(
                channelOb._internalState.attributes.delivery,
                delivery,
                channelOb._internalState.attributes.read,
                chatRead
            );
            deliveryData = deliveries;
            readData = read;
            status = this.checkLastMessageStatus(channelOb, memberData, deliveries, read, lastMessageIndex);
            if (callUpdateGroup) {
                channelOb.updateAttributes({ ...channelOb.attributes, delivery: deliveries });
            }
        }
        return { status, delivery: deliveryData, readData };
    }

    checkLastMessageStatus = (channelOb: any, memberData: Array<any>, delivery: any, read: any, latestMessageIndex: null | number) => {
        let status = 'send';
        let lastMessageIndex = latestMessageIndex != null ? latestMessageIndex : channelOb.lastMessage.index;
        if (
            Object.keys(delivery).length >= memberData.length &&
            Object.values(delivery).filter((messageIndex: any) => messageIndex < lastMessageIndex).length == 0
        ) {
            status = 'delivered';
        }
        if (
            Object.keys(read).length >= memberData.length &&
            Object.values(read).filter((messageIndex: any) => messageIndex < lastMessageIndex).length == 0
        ) {
            status = 'read';
        }
        return status;
    }

    getParticipantId = (memberData: any) => {
        const participantId = memberData
            .filter((item: any) => item.id == this.state.userId &&
                'user_entity' == item.type.toLowerCase()
            )[0].participant_id;
        return participantId;
    }

    handleMessageList = async (message: any) => {
        const { userChats } = this.state;
        let chats: any = [];
        userChats.forEach(async (chat: any) => {
            if (chat.attributes.sid == message.conversation.sid) {
                const messageData = this.getLastMessageStatus(message.conversation, chat.attributes.member_data ?? [], chat.attributes.delivery, chat.attributes.read, message.state.index);
                chat.attributes.delivery = messageData.delivery;
                chat.attributes.last_message_status = messageData.status;
                chat.attributes.read = messageData.readData;
                let lastMsgText = '';
                if (message.state.type == 'text') {
                    lastMsgText = message.state.body;
                } else {
                    lastMsgText = 'media';
                }
                chats = [
                    {
                        ...chat,
                        attributes: {
                            ...chat.attributes,
                            last_message: lastMsgText,
                            last_message_times: 
                            new Date(message.state.timestamp.toString()).toLocaleString('en-US', {
                                hour: 'numeric', minute: 'numeric', hour12: true
                            }),
                            lastMessageActualTime: new Date(message.state.timestamp.toString())
                        }
                    },
                    ...chats,
                ];
            } else {
                chats.push(chat);
            }
        });
        this.setState({
            userChats: chats
        });
    }

    closeChat() {
        this.setState({
            toggleFunctionality: false,
        });
        this.getChats();
    }

    clearChat = (sid: string, updatedChats: Array<any>) => {
        const chats = this.state.userChats.map((chat: any) => {
            if (chat.attributes.sid == sid) {
                chat.attributes.clearedChatTime = new Date(updatedChats[updatedChats.length - 1].clear_chat.from);
                chat.attributes.clear_chat = updatedChats;
            }
            return chat;
        });
        this.setState({
            userChats: chats
        });
    }

    getLatestReceiptsData = (
        delivery: any,
        chatDelivery: any,
        converationRead: any,
        chatRead: any,
    ) => {
        let deliveries: any = chatDelivery;
        let read: any = chatRead;
        return { deliveries, read };
    }

    updateChannel = (conversation: any) => {
        let chats: any = [];
        this.state.userChats.forEach(async (chat: any) => {
            if (chat.attributes.sid == conversation.sid) {
                let callUpdateGroup = false;
                const participantId = this.getParticipantId(chat.attributes.member_data);
                if (!(participantId in chat.attributes.delivery) || chat.attributes.delivery[participantId] < conversation.lastMessage.index) {
                    chat.attributes.delivery[participantId] = conversation.lastMessage.index;
                    callUpdateGroup = true;
                    conversation.updateLastReadMessageIndex(conversation.lastMessage.index);
                }
                const { deliveries, read } = this.getLatestReceiptsData(
                    conversation._internalState.attributes.delivery,
                    chat.attributes.delivery,
                    conversation._internalState.attributes.read,
                    chat.attributes.read
                );
                chat.attributes.read = read;
                chat.attributes.delivery = deliveries;
                if (callUpdateGroup) {
                    conversation.updateAttributes({ ...conversation.attributes, delivery: deliveries });
                }
                const status = this.checkLastMessageStatus(conversation, chat.attributes.member_data ?? [], chat.attributes.delivery, chat.attributes.read, null);
                chat.attributes.last_message_status = status;
                chats.push(chat);
            } else {
                chats.push(chat);
            }
        });
        this.setState({
            userChats: chats
        });
    }

    handleChannelUptaded = (data: any) => {
        if (data.updateReasons.indexOf('attributes') == -1) return;
        this.updateChannel(data.conversation);
    }

    createIndividualConnection = async (id: string, type: string) => {
        const userChats = this.state.userChats.filter((item) => {
            return item.attributes.first_user_image?.id === id || item.attributes.second_user_image?.id === id;
        });
        if(userChats.length > 0){
            this.handleUserClick(userChats[0]);
            return;
        }
        this.setState({ loader: true });
        const header = {
            "content-type": "application/json",
            "token": localStorage.getItem("token"),
            "type": "user_entity"
        }
        this.createIndividualConnectionApiCallId = await apiCall({
            method: "POST",
            endPoint: "/bx_block_groupchat/create_individual_conversation",
            header: header,
            body: JSON.stringify({
                "receiver_id": id,
                "receiver_entity": type
            })
        });
    }

    onMuteNotification = async (muteChatId: string, isMute: boolean) => {
        const userChats = this.state.userChats.map((item) => {
            if(muteChatId === item.id){
                return {
                    ...item,
                    attributes: {
                        ...item.attributes,
                        is_notification_mute: isMute
                    }
                }
            }
            return item;
        });
        this.setState({ userChats });
    }
    // Customizable Area End
}
