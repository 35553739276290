import React from "react";

// Customizable Area Start
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

// Customizable Area Start
import { LoginForm } from "./LoginForm";

// Customizable Area End
class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Required for all blocks
      // Customizable Area Start
      <LoginForm fcmTknSend={this.fcmTknSend}/> 
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export { EmailAccountLoginBlock }
export default EmailAccountLoginBlock
// Customizable Area End
