import React, { ReactNode, } from "react";
import { makeStyles, } from '@material-ui/core/styles';
import clsx from 'clsx';
import { logo } from "../../../blocks/email-account-registration/src/assets";
import { useRouteInfo } from "../utils/router-context";
import { HideContainer } from "../shared/hide-container";
import { useIsMobileScreen } from '../utils/is-mobile';

const useRegisterLayoutStyles = makeStyles((theme) => ({
  container: {
    height:'100vh',
    width: '100vw',
    boxSizing: 'border-box',
    background: 'white',
    display: 'grid',
    gridTemplateColumns: '30% 30%',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%',
      //gridTemplateRows: '300px 1fr',
      //padding: '20px 0 0 0',
    },
  },
  logo: {
    maxWidth: '350px',
    justifySelf: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      margin: 0,
      zIndex: 1
    },
  },
  formContainer: {
    boxSizing: 'border-box',
    justifySelf: 'center',
    width: '450px',
    padding: '40px 40px',
    background: '#fff',
    borderRadius: '25px',
    boxShadow: '1px 6px 30px 1px #c5c5c4',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'stretch',
      alignSelf: 'start',
      boxShadow: 'none',
      padding: '0 10px',
      width: 'auto',
    },
  },
  formContainerWhenLogoIsHidden: {
    marginTop: '70px',
  },
}));


export function RegisterLayout({ children }: { children: ReactNode }) {

  const isMobile = useIsMobileScreen();
  const { url } = useRouteInfo();
  const classes = useRegisterLayoutStyles();

  const isSignupPage = url.toLowerCase().includes('emailaccountregistrationweb');
  const isForgotPassword = url.toLowerCase().includes('forgotpassword');
  const isResetPassword = url.toLowerCase().includes('resetpassword');
  const isLoginPage = url.toLowerCase().includes('emailaccountlogin');

  const hideLogo = !isResetPassword && !isForgotPassword && !isSignupPage && !isLoginPage && isMobile;

  return (
    <div className={classes.container}>

      <HideContainer hideWhen={hideLogo}>
        <img className={classes.logo} src={logo} alt="Initiate" />
      </HideContainer>

      <div className={clsx(classes.formContainer, {
        [classes.formContainerWhenLogoIsHidden]: hideLogo
      })}>
        {children}
      </div>
    </div>
  );
}
