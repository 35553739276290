import { TermsAndConditionAttributes } from "../../types/terms";

export const userKeys = {

  fetchMySelectedDBs: (payload: {accountId:number}) => ['user-selected-dbs',payload] as const,
  login: () => ['user-login'] as const,
  fetchMyNonProfitOrgsAffilliation: () => ['my-non-profit-orgs-affiliations'] as const,
  acceptTerms: () => ['accept-terms'] as const,
  setAvailabilitiesForDay: () => ['set-availabilities'] as const,
  fetchAvailabilitiesOfDay: (params:{date:string}) => ['availabilities',params] as const,
  setNotificationsSettings: () => ['set-notifications-settings'] as const,
  saveTasksPreferences: () => ['user-save-tasks-preferences'] as const,
  fetchAvailableTasks: () => ['available-tasks-preferences'] as const,
  addWorkEmail: () => ['add-work-email'] as const,
  registerAsVolunteerToOrg: () => ['user-register-as-volunteer'] as const,
  fetchNPOrganizations: () => ['np-orgs'] as const,
  fetchProfile: (payload: {accountId:number}) => ['user-profile', payload] as const,
  saveDbsTypes: (payload: {accountId:number}) => ['user-dbs', payload] as const,

  fetchMyTaskPreferences: (payload: {accountId:number}) => ['my-task-preferences', payload] as const,

  signup: () => ['user-signup'] as const,

  fetchTermsAndConditions: () => ['terms-and-conditions'] as const,

  getEmailOTP: () => ['get-email-otp'] as const,
  verifyEmailOTP: () => ['verify-email-otp'] as const,
  getMobileOTP: () => ['get-mobile-otp'] as const,
  verifyMobileOTP: () => ['verify-mobile-otp'] as const,

  updateBioAndAddress: () => ['update-bio-and-address'] as const,

  fetchDBSTypes: () => ['dbs-types'] as const,
}
