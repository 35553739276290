import { ADV_FILTER } from "./advFilterAction";
const initState = {
    advFilter: {
        categories: new Array(),
        dbs_registration: false,
        up_to_km: 10,
        start_time: new Date(),
        end_time: new Date(),
        from_date: new Date(),
        to_date: new Date(),
        location: localStorage.getItem('current_location'),
        all_day: false,
        dateValue: [new Date(), new Date()],
        getDbsData: new Array()

    }
}

const addFilter = (state: any, action: any) => {
    console.log('statereducer', action.payload)
    return {
        ...state,
        advFilter: action.payload
    }
}

function advFilterReducer(state = initState, action: any) {
    switch (action.type) {
        case ADV_FILTER:
            return addFilter(state, action)
        default:
            return state
    }
}

export default advFilterReducer;