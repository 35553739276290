// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: any;
    history?: any;
    location?: any;
    match?: any;
}

export interface S {
    // Customizable Area Start
    id: string;
    openSuccessModal: boolean;
    subusPlanDetails: any;
    loader: boolean;
    selectedPlanId: any,
    planTitle: string;
    planAmount: number;
    paymentData: any;
    clientSecretId: any;
    planType: string;
    days: number;
    planId: string;
    type: null | string;
    currentPlanId: any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class PaymentPlanController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getBusinessSubPlanApiCallId: string = "";
    createPaymentIntentApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            id: "",
            openSuccessModal: false,
            subusPlanDetails: [],
            loader: true,
            selectedPlanId: '',
            planTitle: "",
            planAmount: 0,
            paymentData: null,
            clientSecretId: 'pi_3NpU4gKB34vhzJgk1zGTo3aL_secret_9kRq4kKRQUFaR63LusWyVfTdN',
            planType: "",
            days: 0,
            planId: "",
            type: null,
            currentPlanId: ""
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId && responseJson) {
                if (!responseJson.error && apiRequestCallId === this.createPaymentIntentApiCallId) {
                    this.setState({ clientSecretId: responseJson.client_secret });
                }
            }
        }
    }

    async componentDidMount() {
        const obj = this.props.location.state;
        if (obj) {
            this.setState({
                days: obj.days,
                planId: obj.planId,
                type: obj.type,
                currentPlanId: obj.currentPlanId,
                planType: obj.planType,
                planTitle: obj.planTitle,
                planAmount: obj.planAmount,
            });
        }
    }

    navigateToPlanChangePage = () => {
        this.props.navigation.navigate('BusSubscriptionPlan')
    }

}
// Customizable Area End