// Customizable Area Start
import React from "react";
import PropTypes from "prop-types";

import PersonalInformationController, {
  Props,
} from "./PersonalInformationController";



import { AvailabilityForm } from "./AvailabilityForm";

  // Customizable Area End

class Availability extends PersonalInformationController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);

  }
  
// Customizable Area End


  // Customizable Area Start
// Customizable Area End
  render() {
    // Customizable Area Start
// Customizable Area End
    return (
      // Customizable Area Start
      <AvailabilityForm  submitButtonTitle="Set Availability"/>
      // Customizable Area End
    );
  }
}


// Customizable Area Start
export default Availability;
// Customizable Area End
