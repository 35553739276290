 {/* Customizable Area Start */}
export const ADD_ADVANCE_SEARCH_FILTER_VALUE="ADD_ADVANCE_SEARCH_FILTER_VALUE"
export const GET_ALL_EVENTS_DATA = "GET_ALL_EVENTS_DATA"

export const DBS_INCLUDE="DBS_INCLUDE"
export const FROM_DATE = "FROM_DATE"
export const TO_DATE="TO_DATE"
export const START_TIME = "START_TIME"
export const END_TIME="END_TIME"
export const PROXIMITY_VALUE = "PROXIMITY_VALUE"
export const SAVED_SEARCH="SAVED_SEARCH"
export const ALL_DAY = "ALL_DAY"
export const CATEGORIES = "CATEGORIES"


export function onChangeDbsSearch(payload: any) {
  console.log(payload, 'payload in onChangeDbsSearch');
  return {
    type: DBS_INCLUDE,
    payload
  };
}

export function onChangeFromDate(payload: any) {
  console.log(payload, 'payload in onChangeFromDate');
  return {
    type: FROM_DATE,
    payload
  };
}


export function onChangeToDate(payload: any) {
  console.log(payload, 'payload in onChangeToDate');
  return {
    type: TO_DATE,
    payload
  };
}

export function onChangeStartTime(payload: any) {
  console.log(payload, 'payload in onChangeStartTime');
  return {
    type: START_TIME,
    payload
  };
}

export function onChangeEndTime(payload: any) {
  console.log(payload, 'payload in onChangeEndTime');
  return {
    type: END_TIME,
    payload
  };
}


export function onChangeProximity(payload: any) {
  console.log(payload, 'payload in onChangeProximity');
  return {
    type: PROXIMITY_VALUE,
    payload
  };
}

export function onChangeSavedSearch(payload: any) {
  console.log(payload, 'payload in onChangeSavedSearch');
  return {
    type: SAVED_SEARCH,
    payload
  };
}

export function onChangeAllDay(payload: any) {
  console.log(payload, 'payload in onChangeAllDay');
  return {
    type: ALL_DAY,
    payload
  };
}


export function onChangeCategories(payload: any) {
  console.log(payload, 'payload in onChangeAllDay');
  return {
    type: CATEGORIES,
    payload
  };
}
  
  export function addSearchFilterDetails(payload: any) {
    console.log(payload, 'payload in action');
    return {
      type: ADD_ADVANCE_SEARCH_FILTER_VALUE,
      payload
    };
  }
  

  export function getAllEventsDetails(payload: any) {
    console.log(payload, 'payload in action');
    return {
      type: GET_ALL_EVENTS_DATA,
      payload
    };
  }


   {/* Customizable Area End */}