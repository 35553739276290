// Customizable Area Start
// @ts-nocheck
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Dialog, DialogContent, Box, Button } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { styled } from "@material-ui/styles";
import { baseURL } from "../../../framework/src/config";
import "./CheckOutForm.css";
import { toast } from "react-toastify";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [numberErr, setNumberErr] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [cvvErr, setCvvErr] = useState("");

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const sendCardInfo = async (id: string, email: string) => {
    try {
      const responseJson = await fetch(`${baseURL}/bx_block_custom_user_subs/stripe_subscription`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          token: props.type === 'r3' ? localStorage.getItem("token") : localStorage.getItem("nonprofitToken"),
          type: props.type === 'r3' ? 'business_entity' :"non_profit_entity"
        },
        body: JSON.stringify({
          payment_method: id,
          subscription_plan_id: props.planId,
          point: props.point ? props.point : undefined,
          discount_amount: props.discountAmount ? props.discountAmount : undefined,
          email: email,
        })
      });
      const response = await responseJson.json();
      if (response.subscription_status === "Your subscription is active now") {
        setIsModelOpen(true);
      } else if (response.error) {
        setMessage(response.error);
      } else {
        setMessage(response?.subscription_status);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setMessage("Something went wrong!");
    }
  }

  const onAddPlan = async(id: string) => {
    try {
      const responseJson = await fetch(`${baseURL}/bx_block_custom_user_subs/buy_add_on`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
          type: 'business_entity'
        },
        body: JSON.stringify({
          "payment_method": id,
          "add_on_id": props.planId,
          "subscription_plan_id": props.currentPlanId
      })
      });
      const response = await responseJson.json();
      if (response.purchase_status === "Your purchase is successful") {
        setIsModelOpen(true);
      } else if (response.error) {
        setIsLoading(false);
        setMessage(response.error);
      }
    } catch (error) {
    setIsLoading(false);
    setMessage("Something went wrong!");
    }
  }

  const isNameValid = () => {
    if (name.length < 1) {
      setNameErr("Your name is incomplete.");
      return false;
    } else if (!(/^[A-Za-z]+( [A-Za-z]+)*$/i.test(name)) || name.trim() === "") {
      setNameErr("Your name is invalid.");
      return false;
    } else {
      setNameErr("");
      return true;
    }
  }

  const isEmailValid = () => {
    if (email.length < 1) {
      setEmailErr("Your email is incomplete.");
      return false;
    } else {
      setEmailErr("");
      return true;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const nameValid = isNameValid();
    const emailValid = isEmailValid();
    if (numberErr != "" && dateErr != "" && cvvErr != "") {
      if (!nameValid || !emailValid) {
        setIsLoading(false);
        return;
      }
    }
    const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
    const { paymentMethod, error } = await stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: name,
          email: email
        },
      });
    if (!nameValid || !emailValid || numberErr || dateErr || cvvErr) {
      setIsLoading(false);
      return;
    }
    if (error) {
      setIsLoading(false);
      setMessage(error.message);
    } else {
      const { id } = paymentMethod;
      if(props.isAddOnPlan){
        onAddPlan(id);
      } else {
        props.type !== "r1" ? sendCardInfo(id,email) :  payNowAPI(id) ;
      }
    }
  };

  const paymentType = () => {
    let userType = localStorage.getItem("user_type")

    if(props.paymentableType) {
      return props.paymentableType
    } else if (userType === "business_user"){
      return "BxBlockOrganisations::Organisation"
    } else {
      return "BxBlockEvents::NonProfitEvent"
    }
  }

  const payNowAPI = async (id:string) => {
    let userType = localStorage.getItem("user_type")
    try {
      const responseJson = await fetch(`${baseURL}/bx_block_stripe_integration/create_payments`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
          type: userType === "business_user" ? "business_entity" : "user_entity"
        },
        body: JSON.stringify({
          "data": {
              "attributes": {
                  "amount": props.donationAmount,
                  "paymentable_id": props.eventId,
                  "paymentable_type": paymentType(),
                  "card_token": id,
                  ...props.destination_account && { destination_account: props.destination_account },
                }
              }
            })
          });
      const response = await responseJson.json();
      if (response.meta) {
        toast.success(response.meta.message)
        props.onChangeState()
      } else if (response.message || response.errors) {
        toast.error(response.message.payment[0] ?? response.errors[0].token)
      } else if (response.error || response.status === 500) {
        setMessage(response.error);
      } 
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setMessage("Something went wrong!");
    }
  }

  const getTitle = () => {
    let title = "Trial started successfully!";
    if (props.planType === "new") {
      title = "Thank you for subscribing!";
    } else if (props.planType === "change") {
      title = "Plan changed successfully!";
    }
    if(props.isAddOnPlan){
      title = "Add on plan activated successfully!";
    }
    return title;
  }

  const getDescription = () => {
    let title = `You have started your ${props.days} days FREE trial successfully`;
    if (props.planType === "new") {
      title = `You have successfully subscribed to the ${props.title} subscription plan`;
    } else if (props.planType === "change") {
      title = `You have successfully changed your plan to the ${props.title} subscription plan.`;
    }
    if(props.isAddOnPlan){
      title = `You have successfully purchased ${props.title} plan`;
    }
    return title;
  }

  return (
    <>
      <R1Form>
      <form className={props.type === "r1" ? "r1form" : ""} id="payment-form" onSubmit={handleSubmit}>
        <Box mb="20px" display="flex" flexDirection="column">
          <label>Name</label>
          <input
            placeholder="Name"
            onChange={(e: any) => {
              setName(e.target.value);
              setNameErr("");
              setMessage("");
            }}
            onBlur={(e: any) => isNameValid()}
          />
          <div className="error">{nameErr}</div>
        </Box>
        <Box mb="20px" display="flex" flexDirection="column">
          <label>Email</label>
          <input
            placeholder="Email"
            onChange={(e: any) => {
              setEmail(e.target.value);
              setEmailErr("");
              setMessage("");
            }}
            onBlur={(e: any) => isEmailValid()}
          />
          <div className="error">{emailErr}</div>
        </Box>
        <Box mb="20px">
          <label>Card Number</label>
          <CardNumberElement
            id='cardNumberId'
            options={{ showIcon: true }}
            onChange={(e: any) => {
              setNumberErr(e.error?.message);
              setMessage("");
            }}
          />
          <div className="error">{numberErr}</div>
        </Box>
        <Box display="flex">
          <Box mb="20px" mr="10px" width="50%">
            <label>Card Expiry Date</label>
            <CardExpiryElement
              id='cardExpiryId'
              onChange={(e: any) => {
                setDateErr(e.error?.message);
                setMessage("");
              }}
            />
            <div className="error">{dateErr}</div>
          </Box>
          <Box mb="20px" width="50%">
            <label>Card CVV</label>
            <CardCvcElement
              id='cardCvvId'
              onChange={(e: any) => {
                setCvvErr(e.error?.message);
                setMessage("");
              }}
            />
            <div className="error">{cvvErr}</div>
          </Box>
        </Box>
        <br />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
      </R1Form>
      <SuccessDialog
        open={isModelOpen}
        fullWidth={false}
        maxWidth={"sm"}
        scroll="body"
        aria-describedby="scroll-dialog-description"
        aria-labelledby="scroll-dialog-title"
        onClose={() => { }}
      >
        <DialogContent>
          <Box textAlign="center">
            <Check className="check" />
            <div className="title">{getTitle()}</div>
            <div className="msg">{getDescription()}</div>
            <Button
              className="button"
              onClick={() => props.redirect()}
            >
              Go Back to Dashboard
            </Button>
          </Box>
        </DialogContent>
      </SuccessDialog>
    </>
  );
}

const R1Form = styled(Box)({
  width: "100%",
  "& .r1form": {
    boxShadow: "none !important",
    padding: "20px 20px 0px 0 !important",
    borderRadius: "0 !important",
    borderRight: "1px solid #dcdcdc",
    "@media (max-width: 600px)": {
      minWidth: "100% !important",
      width: "100% !important",
      borderTop: "1px solid #dcdcdc",
      borderRight: "none",
      padding: "20px 0 0 !important",
    },
  },
});

const SuccessDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    boxShadow: "0px 1px 3px rgba(0,0,0,0.2),0px 2px 1px -1px rgba(0,0,0,0.12),0px 1px 1px rgba(0,0,0,0.14)"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "540px"
  },
  "& .MuiDialogContent-root": {
    padding: "40px 70px",
    paddingTop: "30px",
    "media(max-width: 576px)": {
      padding: "30px"
    },
    "& .check": {
      color: "#009be2",
      border: "2px solid #009be2",
      borderRadius: "50%",
      padding: "10px",
      height: "55px",
      width: "55px",
      fontSize: "25px",
      marginBottom: "20px"
    }
  },
  "& .title": {
    fontSize: "24px",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    marginBottom: "8px"
  },
  "& .msg": {
    fontSize: "18px",
    fontFamily: "Rene_Bieder_Milliard_Book",
  },
  "& .button": {
    fontSize: "15px",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    marginTop: "35px",
    backgroundColor: "#009be2",
    borderRadius: "5px",
    width: "100%",
    color: "#fff",
    padding: "10px 0px"
  }
});
// Customizable Area End;