import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export interface MakeHttpMessageParams {
  header: any;
  httpBody?: any;
  url: string;
  httpMethod: string;
  params?: { [key: string]: string | number | boolean },
}

const hasSearchParams = (url: string) => /.*\?.*=.*/g.test(url);

export function makeHttpMessage({ header, httpBody, url, httpMethod, params }: MakeHttpMessageParams) {

  let urlParams = new URLSearchParams();
  let urlParamsString = '';

  if (params) {
    Object.entries(params)
      ?.forEach(([key, value]) => urlParams.append(key, String(value)))

    urlParamsString = decodeURIComponent(urlParams.toString());
    urlParamsString = urlParamsString && hasSearchParams(url) ? `&${urlParamsString}` : `?${urlParamsString}`;
  }

  let requestURL = `${url}${urlParamsString}`;
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestURL
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  const isJsonPayload = header['Content-Type'] == 'application/json';

  if (httpBody) {

    if (isJsonPayload && Object.keys(httpBody).length > 0)
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody),
      );
    else 
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    httpMethod
  );

  return requestMessage;
}

