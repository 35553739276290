 {/* Customizable Area Start */}
export const SET_SAVED_SEARCH = 'SET_SAVED_SEARCH'





export const setSelectedSavedSearchSuccess= (payload: any) => ({
    type:SET_SAVED_SEARCH,
    payload
})

export function storeSearchDetails(payload: any){
    // console.log(selectedTab,"selectedTab")
    return (dispatch: (arg0: { type: string; payload: any; }) => void) => {
        dispatch(setSelectedSavedSearchSuccess(payload))
    }
}

 {/* Customizable Area End */}