// Customizable Area Start
import React from "react";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import {
  CircularProgress as Loader,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  InputBase

} from "@material-ui/core";
import { Scrollbar } from "react-scrollbars-custom";
import { styles } from "./UserProfileBasicBlock.web";
import PropTypes from "prop-types";
// @ts-ignore
import classesoutercss from "./UserProfileBasicBlock.module.css";
import { withStyles } from "@material-ui/core/styles";
import {
  constants,icons,FAMILYFONTS
} from "../../../components/src/constants";
import Carousel from "react-elastic-carousel";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { HideContainer } from '../../../components/src/shared/hide-container';

const connectiontext = {
  width: 150,
  overflow: "hidden",
  textOverflow: "ellipsis",
};
class UserMyconnections extends UserProfileBasicController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderLoader = (classes: any) => {
    return <div className={classesoutercss.DialogContent}>
      <div className={classes.loaderContainer}>
        <Loader className={classes.loaderStyle} />
      </div>
    </div>
  }
  connectionTab(classes: any) {
    return this.state.connLoader ? (
      this.renderLoader(classes)
    ) : (
      this.connectionData(classes)
    );
  }
  connectionData(classes: any) {
    const filteredConnectionsmob = this.getFilteredResult(this.state.allConnectionModule)
    return this.state.allConnectionModule?.length > 0 ? (
      <>
        {this.connectSearchBoxMob(classes)}
        {filteredConnectionsmob?.map((ele: any) => (
          <div key={ele?.id} className={classesoutercss.followingContainer}
          data-testId="connect-btn"
          onClick={()=> this.props.navigation.navigate("UserProfileView", { id: ele.id })}
          >
            {this.renderUserDetails(classes,ele.profile_picture?.url,ele?.full_name)}

            <div>
              <Button
                style={{ fontFamily: "Rene_Bieder_Milliard_Semibold" }}
                variant="outlined"
                size="small"
                fullWidth
                className={
                  classesoutercss.selectedcategoryConnectMenubuttonstyle
                }
                data-testId="addConnection"
                onClick={(e) => {
                  e.stopPropagation();
                  this.addConnectionRequest(ele.id)
                }}
              >
                Connect
              </Button>
            </div>
          </div>
        ))}
         {this.noconnectionText(filteredConnectionsmob?.length)}
      </>
    ) : (
      this.errorModelMsg()
    );
  }
  errorModelMsg() {
    return (
      <div className={classesoutercss.DialogContent}>
        <p style={{ textAlign: "center", marginTop: 250 }}>No Data Available</p>
      </div>
    );
  }

  renderUserDetails=(classes:any,profile_img:any,name:any)=>{
    return <List className={classes.root}>
    <ListItem className={classes.rootList}>
      <ListItemAvatar>
        <Avatar
          alt="Remy Sharp"
          src={profile_img}
          style={{
            height: "60px",
            width: "60px",
            marginRight: "20px",
          }}
        />
      </ListItemAvatar>
      <ListItemText primary={name} />
    </ListItem>
  </List>
  }


  connectedTab(classes: any) {
    return (
      this.state.connLoader ?
        this.renderLoader(classes)
        : this.connectedData(classes)
    )
  }
  connectedData(classes: any) {
    const filteredConnectedmob = this.getFilteredResult(this.state.connectedModule)      
    return (
      this.state.connectedModule?.length > 0 ?
        <>
          {this.connectSearchBoxMob(classes)}
          {filteredConnectedmob?.map((ele: any, index: any) => (
            <div key={ele?.id+index} className={classesoutercss.followingContainer}
            data-testId="connected-btn"
            onClick={()=> {
              if(localStorage.getItem("acc_id") === ele.attributes?.receipient_id?.toString()){
                this.props.navigation.navigate("UserProfileView",{id: ele.attributes?.account_id});
              } else {
                this.props.navigation.navigate("UserProfileView",{id: ele.attributes?.receipient_id});
              }
            }}>
              {this.renderUserDetails(classes,ele?.attributes?.profile_picture?.url,ele.attributes?.name)}
              <div>
                <Button style={{ fontFamily: 'Rene_Bieder_Milliard_Semibold', color: 'red' }}
                  variant="outlined" size="small" fullWidth
                  className={classesoutercss.selectedcategoryConnectedMenubuttonstyle}
                  data-testId="connected"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  Connected
                </Button>

              </div>


            </div>
          ))}
          {this.noconnectionText(filteredConnectedmob?.length)}
        </> : this.errorModelMsg()
    )
  }

  pendingconnectionDataTab(classes: any) {
    return (
      this.state.connLoader ?
        this.renderLoader(classes)
        : this.pendingRequestModelData(classes)
    )
  }

  pendingRequestModelData(classes: any) {
    const filteredPendingmob = this.getFilteredResult(this.state.pendingRequestModule)
    
    return (
      this.state.pendingRequestModule?.length > 0 ?
        <>
          {this.connectSearchBoxMob(classes)}
          {filteredPendingmob?.map((ele: any, index: any) => (
            <div key={ele?.id+index} className={classesoutercss.followingContainer}
            data-testId="pending-btn"
            onClick={()=> this.props.navigation.navigate("UserProfileView", { id: ele.attributes.receipient_id })}
            >

              <List className={classes.root}>
                <ListItem className={classes.rootList}>
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={ele.attributes.profile_picture?.url} style={{ height: "60px", width: "60px", marginRight: "20px" }} />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        style={{
                          ...connectiontext,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ele.attributes?.name}
                      </Typography>
                    }

                  ></ListItemText>

                </ListItem>
              </List>
              <div>
                <Button style={{ fontFamily: 'Rene_Bieder_Milliard_Semibold' }}
                  variant="outlined" size="small" fullWidth
                  className={classesoutercss.selectedcategoryPendingMenubuttonstyle}
                  data-testId="pending"
                  onClick={(e) => e.stopPropagation()}
                  >
                  Pending
                </Button>

              </div>
            </div >
          ))
          }
          {this.noconnectionText(filteredPendingmob?.length)}

        </> : this.errorModelMsg()
    )
  }

  awaitConnectionData(classes: any) {
    const { awaitResponseUsers } = this.state;
    if (this.state.connLoader) {
      return this.renderLoader(classes)
    }
    if (awaitResponseUsers?.length > 0) {
      const filteredAwaitedmob = this.getFilteredResult(awaitResponseUsers)
      return (
        <>
          {this.connectSearchBoxMob(classes)}
          {filteredAwaitedmob?.map((ele: any) => (
            <div key={ele?.id} className={classesoutercss.followingContainer}
            data-testId="await-btn"
            onClick={()=> this.props.navigation.navigate("UserProfileView",{id: ele.attributes.account_id})}
            >

              <List className={classes.root}>
                <ListItem className={classes.rootList}>
                  <ListItemAvatar>
                    <Avatar alt="username" src={ele?.attributes?.profile_picture.url} style={{ height: "60px", width: "60px", marginRight: "20px" }} />
                  </ListItemAvatar>
                  <ListItemText primary={ele?.attributes.name} secondary={ele.subtitle} />
                </ListItem>
              </List>


              <div className={classesoutercss.flexcontainer} style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  data-testId="cancel-req"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.cancelConnRequest(ele?.attributes.account_id)
                  }}
                  >

                  <ClearIcon  className={classesoutercss.mobilecancelAwaitIcon} />
                </div>
                <div
                  data-testId="accept-req"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.acceptConnRequest(ele?.attributes.account_id);
                  }}
                  >

                  <CheckIcon className={classesoutercss.mobilecheckAwaitIcon} />

                </div>

              </div>

            </div>
          ))}
          {this.noconnectionText(filteredAwaitedmob?.length)}
        </>
      )
    }
    else if (!this.state.awaitResponseUsers) {
      return this.errorModelMsg();
    }

  }
  connectSearchBoxMob=(classes:any)=>{
    const props={"aria-label": "search"}
    return (<div className={`${classes.searchdivContainer} ${classes.mobileContainer}`}>
      <div className={classes.flexItem} >
          <div className={classes.searchIcon}>
              <img
                 className={classes.searchImageStyle}
                  src={icons.search}
             />
          </div>
          <InputBase
              placeholder="Search Connection"
              classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
              }}
              inputProps={props}
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
          />

      </div>
  </div>)
  }
  noconnectionText=(length:any)=>{
    return ( <HideContainer hideWhen={length}>
      <Typography style={{ ...FAMILYFONTS.family2, fontSize: 16,textAlign:"center",marginTop:10 }}>
          {'There are no connections present'}
      </Typography>
  </HideContainer>)
  }


  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const textWrap = { textWrap: "nowrap" };
    const breakPoints = [
      { width: 1, itemsToShow: 3, itemsToScroll: 3 },
      { width: 550, itemsToShow: 3, itemsToScroll: 2 },
      { width: 768, itemsToShow: 3, itemsToScroll: 2 },
      { width: 1200, itemsToShow: 3, itemsToScroll: 2 },
    ];
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <MobileHeader
          navigatTo={"/"}
          data-testid="mobileSearchMainContainer"
          navigation={this.props.navigation}
          title={"My Connections"}
        />
        <div style={{ paddingTop: 30 }}>
          <style>
            {`.rec.rec-slider-container {
            box-shadow: none !important;
            border-radius: 0 !important;
            background-color: white;
            }
            .ScrollbarsCustom-Track {
              width : 2px !important;
              margin-left : 10px !important

            }
              .rec-item-wrapper{
              width:auto !important;
              margin-left:15px;
            }`}
          </style>

          <Carousel
            isRTL={false}
            breakPoints={breakPoints}
            showArrows={false}
            itemPadding={[-10, -10]}
            pagination={false}
            disableArrowsOnEnd={true}
          >
            {constants.connectionscategorystatus.map((item, index) => (
              <div key={index}>
                <Button
                  variant="outlined"
                  style={{
                    ...textWrap,
                    width: "auto",
                    fontFamily: "Rene_Bieder_Milliard_Semibold",
                  }}
                  className={
                    this.state.connectionTab === item.label
                      ? classesoutercss.selectedcategorymenubuttonstyle
                      : classesoutercss.categorymenubuttonstyle
                  }
                  onClick={() => this.categoryMenuCHange(item.label)}
                  data-testId={item.label}
                >
                  {item.label}
                </Button>
              </div>
            ))}
          </Carousel>

          <div>
            <div className={`${classesoutercss.DialogContent} scrollbars1`}>
              <Scrollbar style={{ height: "100%", width: "100%" }}>
              <style>
            {`
            .ScrollbarsCustom-Track {
              width : 2px !important;
              margin-left : 10px !important

            }`
            }
          </style>
                {this.state.connectionTab === "Connect" &&
                  this.connectionTab(classes)}
                {this.state.connectionTab === 'Connected' && this.connectedTab(classes)}
                {this.state.connectionTab === 'Pending requests' && this.pendingconnectionDataTab(classes)}
                {this.state.connectionTab === 'Awaiting response' && this.awaitConnectionData(classes)}
              </Scrollbar>
            </div>




          </div >
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
UserMyconnections.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export { UserMyconnections };
export default withStyles(styles as any)(UserMyconnections as any);
// Customizable Area End

// Customizable Area End
