import { combineReducers } from 'redux';
import { ADD_EMAIL_NOTIFICATION } from '../types';


const initInformation = {
    _email_notifications:[]
}

function EmailNotifications(state = initInformation,action: { type: any; payload: string | number; }){
    switch(action.type){
        case  ADD_EMAIL_NOTIFICATION: 
            return{
                ...state,
                _email_notifications:action.payload
            }    
        default:
            return state;
    }
}

export default EmailNotifications;