import { APIArrayData } from "../../types/api";
import { useBuilderMutation, useBuilderQuery } from "../../utils/react-query";
import { orgKeys } from "../keys/org.keys";
import { orgMessages } from "../messages/org.messages";
import { OrganziationType } from '../../types/organization';

export function useNPSignup() {

  return useBuilderMutation<Parameters<typeof orgMessages.npSignup>[0]>({
    queryKey: orgKeys.npSignup(),
    messageGenerator: orgMessages.npSignup,
  });
}

export function useOrgsTypes() {

  return useBuilderQuery<APIArrayData<OrganziationType>>({
    queryKey: orgKeys.orgTypes(),
    message: orgMessages.fetchOrgsTypes(),
  });
}
