import React from "react";

// Customizable Area Start
import {
  IconButton,
  Typography,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Box,
  TextField,
  Paper,
  Container,
  Grid,
  // Customizable Area End
} from "@material-ui/core";
//@ts-ignore
import classesOuter from "./AdminRegistration.module.css";

import { withStyles } from "@material-ui/core/styles";
import { AntSwitch } from '../../ServiceSpecificSettingsAdmin/src/RegistrationFormPart1Business.web';

import {
  constants,
  icons,
  images,
  FAMILYFONTS
} from "../../../components/src/constants";

//@ts-ignore

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import AdminRegistrationController, {
  Props
} from "./AdminRegistrationController";
import { COLORS } from "../../../framework/src/Globals";

// import { npRegistrationFn } from "../../../framework/src/redux/npRegistration/npRegistrationAction";
import { connect } from "react-redux";
import { HideContainer } from "../../../components/src/shared/hide-container";

// Customizable Area Start
const styles = (theme: {
  palette: any;
  zIndex: any;
  breakpoints: any;
  transitions: {
    create: (
      arg0: string | string[],
      arg1: { easing: any; duration: any }
    ) => any;
    easing: { sharp: any; easeOut: any };
    duration: {
      leavingScreen: any;
      enteringScreen: any;
    };
  };
  mixins: { toolbar: any };
  spacing: any;
}) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1)
    // backgroundColor: theme.palette.secondary.main,
  },
  imgLogo: {
    height: 100,
    width: "auto"
  },
  Organizationsearch: {
    position: "relative"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  searchDropdownorg: {
    borderRadius: "8px",
    color: "text.primary",
    backgroundColor: "white",
    margin: "8px",
    padding: "8px",
    height: 46,
    width: 46
  },
  org_tabs_logo: {
    width: "18px",
    height: "8px"
  },
  inputRoot: {
    color: "#87888F"
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 0px)`,
    fontSize: "15px",
    fontFamily: "Rene_Bieder_Milliard_Book",
    color: "#0d0d0d"
  },

  dropdownlabelStyle: {
    fontSize: "16px",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    color: "#87888F"
  },
  listItemroot: {
    width: "95%",
    margin: "4px auto",
    borderRadius: "8px",
    transition: "all .5s",
    overflow: "hidden"
  }
});
// Customizable Area End

class AdminRegistration extends AdminRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = () => {
    console.log("Working dropdown");
  };

  renderFormHeader() {
    return (
      <>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={images.app_logo} className={this.props.classes.imgLogo} />
        </Box>
        <Typography
          style={{
            ...FAMILYFONTS.family1,
            fontSize: 28,
            fontWeight: 400
          }}
          className={classesOuter.registrationFormHead}
        >
          Registration Form
        </Typography>
      </>
    );
  }

  renderFullNameInput() {
    return (
      <Box className={classesOuter.labelInputBox}>
        <label className={classesOuter.labelForInput}>Full name *</label>
        <Box className={classesOuter.inputField}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="name"
            placeholder="Full name"
            defaultValue={this.props.fullName || ""}
            InputProps={{
              classes: {
                root: classesOuter.registerTextField
              }
            }}
            inputProps={{
              style: {
                borderColor: "#d5d5d5"
              },
              onFocus: this.handleInputFocus,
              onBlur: this.handleInputBlur
            }}
            data-testId="fullName"
            onChange={e => {
              this.props.registerNextBtnValidation({
                fullName: e.target.value ? e.target.value : " "
              });
              this.validFullName(e.target.value);
            }}
            autoComplete="off"
          />
        </Box>
        {this.state.fullNameError && (
          <Box>
            <Typography
              style={{
                ...FAMILYFONTS.family3,
                fontSize: 12,
                color: "#fd1d1d",
                marginTop: 10
              }}
            >
              {this.state.fullNameError}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  renderOrganizationNameInput() {
    return (
      <Box className={classesOuter.labelInputBox}>
        <label className={classesOuter.labelForInput}>
          Name of organization / project *
        </label>
        <Box className={classesOuter.inputField}>
          <TextField
            variant="outlined"
            required
            fullWidth
            placeholder="Enter organization name"
            defaultValue={this.props.orgName}
            data-testId="orgName"
            InputProps={{
              classes: {
                root: classesOuter.registerTextField
              }
            }}
            inputProps={{
              style: {
                borderColor: "#d5d5d5"
              },
              onFocus: this.handleInputFocus,
              onBlur: this.handleInputBlur
            }}
            onChange={e => {
              this.props.registerNextBtnValidation({
                orgName: e.target.value ? e.target.value : " "
              });
              this.validProjectName(e.target.value);
            }}
            autoComplete="off"
          />
        </Box>
        {this.state.projectNameError && (
          <Box>
            <Typography
              style={{
                ...FAMILYFONTS.family3,
                fontSize: 12,
                color: "#fd1d1d",
                marginTop: 10
              }}
            >
              {this.state.projectNameError}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  renderOrganizationTypeDropdown() {
    const { organizationType, classes } = this.props;
    const { showorganisationDropdown, dropdownInputBorder } = this.state;

    return (
      <Box className={classesOuter.labelInputBox}>
        <label className={classesOuter.labelForInput}>
          Organization type *
        </label>

        <Box className={classesOuter.dropdown}>
          <div
            className={`${classes.Organizationsearch} ${classesOuter.organisationssearchreletiveenv
              }`}
          >
            <div
              ref={this.dropdownRef}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
              className={
                !dropdownInputBorder
                  ? classesOuter.organisationdropdownInput
                  : classesOuter.activeorganisationdropdownInput
              }
            >
              <InputBase
                placeholder="Choose the type"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                style={{ width: "100%" }}
                onChange={e => this.handleOrganizationInput(e)}
                inputProps={{ "aria-label": "search" }}
                data-testId="orgTypeInput"
                onClick={() =>
                  this.setState({
                    showorganisationDropdown: organizationType ? true : false,
                    dropdownInputBorder: true
                  })
                }
                value={this.state.inputOrgType}
              />

              <IconButton
                aria-label="show 4 new mails"
                color="inherit"
                className={classes.searchDropdownorg}
                data-testId="orgTypeBtn"
                onClick={() =>
                  this.setState({
                    showorganisationDropdown: !showorganisationDropdown,
                    inputOrgType: ""
                  })
                }
              >
                <img
                  className={classes.org_tabs_logo}
                  src={
                    !showorganisationDropdown
                      ? icons.downarrowblue
                      : icons.arrowup
                  }
                />
              </IconButton>
            </div>

            {/* filter option UI */}
            {showorganisationDropdown && (
              <Paper elevation={1} className={classesOuter.orgsearchfilterEnv}>
                <List dense>
                  {this.state?.npOrgType.filter((item:any) => {
                    if (
                      this.state.inputOrgType === "" ||
                      item.attributes.organisation_type
                        .toLowerCase()
                        .includes(this.state.inputOrgType.toLowerCase())
                    ) {
                      return true;
                    }
                    return false;
                  }).length > 0 ? (
                      this.state.npOrgType
                        .filter((item:any) => {
                          if (
                            this.state.inputOrgType === "" ||
                            item.attributes.organisation_type
                              .toLowerCase()
                              .includes(this.state.inputOrgType.toLowerCase())
                          ) {
                            return true;
                          }
                          return false;
                        })
                        .map((ele:any) => (
                          <ListItem
                            key={ele.id}
                            button
                            onClick={() =>
                              this.handleDropdownItemClick(ele.attributes.organisation_type)
                            }
                          >
                            <ListItemText
                              key={ele.id}
                              primary={
                                <Typography className={classes.dropdownlabelStyle}>
                                  {ele.attributes.organisation_type}
                                </Typography>
                              }
                              data-testId={ele.id}
                            />
                          </ListItem>
                        ))
                    ) : (
                      <Typography style={{ padding: 10 }} className={classes.dropdownlabelStyle}>No records found</Typography>
                    )}
                </List>
              </Paper>
            )}
            {/* filter option UI end */}
          </div>
        </Box>
      </Box>
    );
  }

  renderFieldMandatoryNotification() {
    return (
      <Box className={classesOuter.fieldMandatoryNP}>
        <Typography
          style={{
            ...FAMILYFONTS.family3,
            fontSize: 14,
            fontWeight: 400
          }}
          className={classesOuter.fieldMandatoryNPSentence}
        >
          Fields marked with '<span>*</span>' are mandatory fields.
        </Typography>
      </Box>
    );
  }

  renderRegistrationNumberInput() {
    const { regNum, registerNextBtnValidation } = this.props;
    return (
      <Box className={classesOuter.labelInputBox}>
        <label className={classesOuter.labelForInput}>
          Registration number *
        </label>
        <Box className={classesOuter.inputField}>
          <TextField
            variant="outlined"
            required
            defaultValue={regNum}
            InputProps={{
              classes: {
                root: classesOuter.registerTextField
              }
            }}
            inputProps={{
              style: {
                borderColor: "#d5d5d5"
              },
              onFocus: this.handleInputFocus,
              onBlur: this.handleInputBlur
            }}
            data-testId="regNumber"
            onChange={e => {
              registerNextBtnValidation({
                regNum: e.target.value ? e.target.value : " "
              });
              this.validRegistrationNumber(e.target.value);
            }}
            fullWidth
            placeholder="Registration number *"
            autoComplete="off"
          />
        </Box>
        {this.state.registrationNumberError && (
          <Box>
            <Typography
              style={{
                ...FAMILYFONTS.family3,
                fontSize: 12,
                color: "#fd1d1d",
                marginTop: 10
              }}
            >
              {this.state.registrationNumberError}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  renderAddress() {
    return (
      <>
        <Box className={classesOuter.labelInputBox}>
          <label className={classesOuter.labelForInput}>Address *</label>

          <Box className={classesOuter.inputField}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="street"
              placeholder="Street*"
              value={this.state.address.street}
              InputProps={{
                classes: {
                  root: classesOuter.registerTextField
                }
              }}
              inputProps={{
                style: {
                  borderColor: "#d5d5d5"
                },
                onFocus: this.handleInputFocus,
                onBlur: this.handleInputBlur
              }}
              data-testId="street"
              onChange={e => {
                const value = e.target.value;

                this.setState((state) => ({
                  ...state,
                  address: {
                    ...state.address,
                    street: value,
                  },
                }));
              }}
              autoComplete="off"
            />
            <p>{`the stree: ${this.state.address.street}`}</p>
          </Box>

          <HideContainer hideWhen={!this.state.fullNameError}>
            <Box>
              <Typography
                style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 12,
                  color: "#fd1d1d",
                  marginTop: 10
                }}
              >
                {this.state.fullNameError}
              </Typography>
            </Box>
          </HideContainer>

          <Box className={classesOuter.inputField}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="town"
              placeholder="Town*"
              value={this.state.address.town || ""}
              InputProps={{
                classes: {
                  root: classesOuter.registerTextField
                }
              }}
              inputProps={{
                style: {
                  borderColor: "#d5d5d5"
                },
                onFocus: this.handleInputFocus,
                onBlur: this.handleInputBlur
              }}
              data-testId="street"
              onChange={e => {
                const value = e.target.value;

                this.setState((state) => ({
                  ...state,
                  address: {
                    ...state.address,
                    town: value,
                  },
                }));
              }}
              autoComplete="off"
            />
          </Box>

          <HideContainer hideWhen={!this.state.fullNameError}>
            <Box>
              <Typography
                style={{
                  ...FAMILYFONTS.family3,
                  fontSize: 12,
                  color: "#fd1d1d",
                  marginTop: 10
                }}
              >
                {this.state.fullNameError}
              </Typography>
            </Box>
          </HideContainer>

          <Grid style={{ display: 'flex' }} justifyContent="space-between">
            <Box className={classesOuter.inputField}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="country"
                placeholder="Country*"
                value={this.state.address.country || ""}
                InputProps={{
                  classes: {
                    root: classesOuter.registerTextField
                  }
                }}
                inputProps={{
                  style: {
                    borderColor: "#d5d5d5"
                  },
                  onFocus: this.handleInputFocus,
                  onBlur: this.handleInputBlur
                }}
                data-testId="country"
                onChange={e => {
                  const value = e.target.value;

                  this.setState((state) => ({
                    ...state,
                    address: {
                      ...state.address,
                      country: value,
                    },
                  }));

                }}
                autoComplete="off"
              />
            </Box>

            <HideContainer hideWhen={!this.state.fullNameError}>
              <Box>
                <Typography
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 12,
                    color: "#fd1d1d",
                    marginTop: 10
                  }}
                >
                  {this.state.fullNameError}
                </Typography>
              </Box>
            </HideContainer>

            <Box className={classesOuter.inputField}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="postcode"
                placeholder="Postcode*"
                value={this.state.address.postCode || ""}
                InputProps={{
                  classes: {
                    root: classesOuter.registerTextField
                  }
                }}
                inputProps={{
                  style: {
                    borderColor: "#d5d5d5"
                  },
                  onFocus: this.handleInputFocus,
                  onBlur: this.handleInputBlur
                }}
                data-testId="street"
                onChange={e => {
                  const value = e.target.value;

                  this.setState((state) => ({
                    ...state,
                    address: {
                      ...state.address,
                      postCode: value,
                    },
                  }));
                }}
                autoComplete="off"
              />
            </Box>

            <HideContainer hideWhen={!this.state.fullNameError}>
              <Box>
                <Typography
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 12,
                    color: "#fd1d1d",
                    marginTop: 10
                  }}
                >
                  {this.state.fullNameError}
                </Typography>
              </Box>
            </HideContainer>

          </Grid>

        </Box>

        <Box className={classesOuter.labelInputBox}>

          <Box className={classesOuter.labelInputBox} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label className={classesOuter.labelForInput}>
              Office Address different from above?
                </label>
            <AntSwitch
              name="switchStatus"
              data-testid="switchTest"
              value={this.state.activateOfficeAddress}
              onChange={(e) => {
                this.setState({ activateOfficeAddress: e.target.checked });
                //registerNextBtnValidation({ switchStatus: !switchStatus })
              }}
            //defaultValue={false}
            />
          </Box>

          <HideContainer hideWhen={!this.state.activateOfficeAddress}>

            <Box className={classesOuter.inputField}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="street"
                placeholder="Street*"
                value={this.state.officeAddress.street}
                InputProps={{
                  classes: {
                    root: classesOuter.registerTextField
                  }
                }}
                inputProps={{
                  style: {
                    borderColor: "#d5d5d5"
                  },
                  onFocus: this.handleInputFocus,
                  onBlur: this.handleInputBlur
                }}
                data-testId="street"
                onChange={e => {
                  const value = e.target.value;

                  this.setState((state) => ({
                    ...state,
                    officeAddress: {
                      ...state.officeAddress,
                      street: value,
                    },
                  }));
                }}
                autoComplete="off"
              />
            </Box>

            <HideContainer hideWhen={!this.state.fullNameError}>
              <Box>
                <Typography
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 12,
                    color: "#fd1d1d",
                    marginTop: 10
                  }}
                >
                  {this.state.fullNameError}
                </Typography>
              </Box>
            </HideContainer>

            <Box className={classesOuter.inputField}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="town"
                placeholder="Town*"
                value={this.state.officeAddress.town || ""}
                InputProps={{
                  classes: {
                    root: classesOuter.registerTextField
                  }
                }}
                inputProps={{
                  style: {
                    borderColor: "#d5d5d5"
                  },
                  onFocus: this.handleInputFocus,
                  onBlur: this.handleInputBlur
                }}
                data-testId="town"
                onChange={e => {
                  const value = e.target.value;

                  this.setState((state) => ({
                    ...state,
                    officeAddress: {
                      ...state.officeAddress,
                      town: value,
                    },
                  }));
                }}
                autoComplete="off"
              />
            </Box>

            <HideContainer hideWhen={!this.state.fullNameError}>
              <Box>
                <Typography
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 12,
                    color: "#fd1d1d",
                    marginTop: 10
                  }}
                >
                  {this.state.fullNameError}
                </Typography>
              </Box>
            </HideContainer>

            <Grid style={{ display: 'flex' }} justifyContent="space-between">
              <Box className={classesOuter.inputField}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="country"
                  placeholder="Country*"
                  value={this.state.officeAddress.country || ""}
                  InputProps={{
                    classes: {
                      root: classesOuter.registerTextField
                    }
                  }}
                  inputProps={{
                    style: {
                      borderColor: "#d5d5d5"
                    },
                    onFocus: this.handleInputFocus,
                    onBlur: this.handleInputBlur
                  }}
                  data-testId="country"
                  onChange={e => {
                    const value = e.target.value;

                    this.setState((state) => ({
                      ...state,
                      officeAddress: {
                        ...state.officeAddress,
                        country: value,
                      },
                    }));

                  }}
                  autoComplete="off"
                />
              </Box>

              <HideContainer hideWhen={!this.state.fullNameError}>
                <Box>
                  <Typography
                    style={{
                      ...FAMILYFONTS.family3,
                      fontSize: 12,
                      color: "#fd1d1d",
                      marginTop: 10
                    }}
                  >
                    {this.state.fullNameError}
                  </Typography>
                </Box>
              </HideContainer>

              <Box className={classesOuter.inputField}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="postcode"
                  placeholder="Postcode*"
                  value={this.state.officeAddress.postCode || ""}
                  InputProps={{
                    classes: {
                      root: classesOuter.registerTextField
                    }
                  }}
                  inputProps={{
                    style: {
                      borderColor: "#d5d5d5"
                    },
                    onFocus: this.handleInputFocus,
                    onBlur: this.handleInputBlur
                  }}
                  data-testId="street"
                  onChange={e => {
                    const value = e.target.value;

                    this.setState((state) => ({
                      ...state,
                      officeAddress: {
                        ...state.officeAddress,
                        postCode: value,
                      },
                    }));
                  }}
                  autoComplete="off"
                />
              </Box>

              <HideContainer hideWhen={!this.state.fullNameError}>
                <Box>
                  <Typography
                    style={{
                      ...FAMILYFONTS.family3,
                      fontSize: 12,
                      color: "#fd1d1d",
                      marginTop: 10
                    }}
                  >
                    {this.state.fullNameError}
                  </Typography>
                </Box>
              </HideContainer>

            </Grid>


          </HideContainer>

        </Box>


      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    // Merge Engine - render - Start
    return (
      <>
        <Container
          maxWidth="xl"
          style={{
            backgroundColor: COLORS.white,
            borderRadius: 24,
            padding: 30,
            margin: "auto"
          }}
        >
          <form
            className={this.props.classes.form}
            noValidate
            data-testId="formSubmit"
            onSubmit={() => {
              console.log("clicked");
            }}
          >
            {this.renderFormHeader()}

            <div className={classesOuter.mainRegisterContainer}>
              {this.renderFullNameInput()}

              {/* Render other blocks using respective functions */}

              {/* Organization Name input field */}
              {this.renderOrganizationNameInput()}

              {/* Organization Type dropdown */}
              {this.renderOrganizationTypeDropdown()}

              {/* Registration Number input field */}
              {this.renderRegistrationNumberInput()}


              {this.renderAddress()}

              {/* Field Mandatory Notification */}
              {this.renderFieldMandatoryNotification()}
            </div>
          </form>
        </Container>
      </>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

const mapStateToProps = (state: { npRegistrationDetails: any }) => {
  console.log(state, "adminRegState");
  return {
    npRegistrationDetails: state.npRegistrationDetails
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    npRegisterFn: () => {
      //   return dispatch(npRegistrationFn(info));
    }
  };
}

// Customizable Area Start
export { AdminRegistration, mapDispatchToProps, mapStateToProps };
// Customizable Area End
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any)(AdminRegistration));
