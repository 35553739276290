Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ServiceSpecificSettingsAdmin";
exports.label = "ServiceSpecificSettingsAdmin";
exports.labelBodyText = "ServiceSpecificSettingsAdmin Body";
exports.contactUsApiEndPoint = 'bx_block_contact_us/contacts';
exports.chatList = 'bx_block_groupchat/conversation_list?filter=';
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ServiceSpecificSettingsAdmin";
exports.labelBodyText = "ServiceSpecificSettingsAdmin Body";

exports.btnExampleTitle = "CLICK ME";
// Signup Api End Point
exports.registrationApiEndPoint = 'bx_block_business_account/business_accounts'
exports.mobileVerificationRegistrationApiEndPoint = 'bx_block_phone_verification/send_sms_otp';
exports.mobileOtpVerifyApiEndPoint = 'bx_block_phone_verification/verify_sms_otp';
exports.emailTokenApiEndPoint = 'bx_block_business_account/send_verification_email';
exports.emailOtpEnterEndPoint = 'bx_block_business_account/verify_email?token='
exports.apiMethodType = "POST";
exports.getApiMethodType = 'GET';
// Login Api Key Point
exports.signInApiEndPoint = "bx_block_login/business_login";
exports.forgotPasswordSendOtpEndPoint = 'bx_block_forgot_password/otps/non_profit_email_otp';
exports.forgotOtpConfirmationEndPoint = 'bx_block_forgot_password/otp_confirmations';
exports.resetNewPasswordEndPoint = 'bx_block_forgot_password/passwords/non_profit_change_password';
exports.apiMethodType = "POST";
// Customizable Area End

exports.btnExampleTitle = "CLICK ME";
exports.btnTitle = "CLICK HERE";
exports.freeTrialApiEndPoint="bx_block_custom_user_subs/my_subscription"
// Signup Api End Point
exports.registrationApiEndPoint = 'bx_block_business_account/business_accounts'
exports.mobileVerificationRegistrationApiEndPoint = 'bx_block_phone_verification/send_sms_otp';
exports.mobileOtpVerifyApiEndPoint = 'bx_block_phone_verification/verify_sms_otp';
exports.emailTokenApiEndPoint = 'bx_block_business_account/send_verification_email';
exports.emailOtpEnterEndPoint = 'bx_block_business_account/verify_email?token='
exports.getBusProfileEndPoint = 'bx_block_business_account/business_accounts'
exports.apiMethodType = "POST";
exports.getApiMethodType = 'GET';
// Login Api Key Point
exports.signInApiEndPoint = "bx_block_login/business_login";
exports.forgotPasswordSendOtpEndPoint = 'bx_block_forgot_password/otps/non_profit_email_otp';
exports.forgotOtpConfirmationEndPoint = 'bx_block_forgot_password/otp_confirmations';
exports.resetNewPasswordEndPoint = 'bx_block_forgot_password/passwords/non_profit_change_password';
exports.apiMethodType = "POST";
exports.patchMethod = 'PATCH';
exports.deleteMethodType = 'DELETE';
exports.upcomingEventsDataApiEndPoint='bx_block_business_account/upcoming_events_for_business'
exports.orgOptionsTypeEndPoint="options"
exports.getFollowingOrgApiEndpoint="bx_block_followers/get_followings"
exports.getSearchOrgFilterApiEndpoint="bx_block_organisations/search_specific_organisation"
exports.getFollowNpApiEndPoint='bx_block_followers/organisation_follows'
exports.getFollowStausApiEndPoint='bx_block_followers/check_follow_status'
exports.getUnfollowNPApiEndPoint='bx_block_followers/unfollow'
exports.getRegisteredEmplyeesApiEndPoint="bx_block_business_account/registered_employees"
exports.rejectEmployeeApiEndpoint="bx_block_business_account/reject_employee_request"
exports.acceptEmployeeApiEndpoint="bx_block_business_account/accept_employee_request"
exports.getMethodType = "GET";
exports.followNotificationSettings="bx_block_settings/notification_settings"
exports.getBusinessPlansEndPoint="bx_block_custom_user_subs/subscriptions"
exports.getCurrentPlanInfoApiEndPoint="bx_block_custom_user_subs/my_subscription"
exports.createStripeIntentApiEndPoint = "/bx_block_custom_user_subs/create_payment_intent";
exports.cancelSubscriptionApiEndPoint="bx_block_custom_user_subs/cancel_subscription";
exports.autoPayApiEndPoint="bx_block_custom_user_subs/update_auto_pay"
exports.createBroadcastsMsgApiEndPoint="bx_block_dashboard/broadcasts"
exports.clearChat = 'bx_block_groupchat/create_clear_chat';
exports.generateChatToken = 'account_block/accounts/token_for_chat?identity='
exports.getBroadcastMsgApiEndPoint="bx_block_dashboard/broadcasts";
exports.MsgApiEndPoint="GET";
exports.postMethodType = "POST";
exports.getMessageNpApiEndPoint="bx_block_groupchat/create_business_conversation";
exports.STRIPE_KEY_TEST = "pk_test_51MHv2nKB34vhzJgkvvEQj6o6aj4gniJAsxz5yNcTFqlZd4DmEKMwrpjKnq9n0Jdt5q6T5ZmVPSmxGTiLkAKat3ow00z6FiJvcV";
exports.STRIPE_KEY_LIVE = "pk_live_51MHv2nKB34vhzJgkXFEkogbFXzVHx1Oi2Ff34wmSDhCE0W0RYAstVUyo4qreqJpNvlFCwLxrQPxknO4GAEVP48LN00wjTDBe9v";
// Customizable Area End
