import React from "react";

// Customizable Area Start
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  withStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import {
  // Customizable Area Start
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Switch,
  List,
  ListItem,
  ListItemText,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Badge,
  Popover,
  MenuList,
  MenuItem,
  Tab,
  Modal,
  TextField,
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// @ts-ignore
import classOuter from "./ContentManagement.module.css";
import { AppContext } from './ContentManagementDashBoard.web';

import InputBase from "@material-ui/core/InputBase";

// import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import CloseIcon from '@material-ui/icons/Close';

import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  GitHub,
  NotificationsNone as NotificationsNoneIcon,
} from "@material-ui/icons";
import {
  constants,
  icons,
  FONTS,
  images,
} from "../../../components/src/constants";
// Customizable Area Start

const drawerWidth = 300;

const popoverStyle = {
  borderRadius: "20px",
  marginTop: "43px",
  left: "656px",
};

const popoverStyles: React.CSSProperties = {
  width:"600px",
  borderRadius: "20px",
  overflowX: "hidden",
  overflowY: "hidden"
};

const styles = (theme: {
  zIndex: any;
  breakpoints: any;
  transitions: {
    create: (
      arg0: string | string[],
      arg1: { easing: any; duration: any }
    ) => any;
    easing: { sharp: any; easeOut: any };
    duration: {
      leavingScreen: any;
      enteringScreen: any;
    };
  };
  mixins: { toolbar: any };
  spacing: { unit: number };
}) => ({
  root: {
    display: "flex",
    position: "relative",
    overflowX: "hidden",
    overflowY: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  modalBtn: {
    width: '80%',
    height: '56px',
    backgroundColor: '#009be2',
    color: "white",
    fontWeight: "600",
    borderRadius: "10px",
    margin: "0 auto 10px",
    textTransform: "capitalize",
  },
  modalWrapper: {
    backgroundColor: '#8d8d8db0',
  },
  modalBox: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'absolute',
    background: '#fff',
    maxWidth: '550px',
    padding: '30px',
    borderRadius: '12px'
  },
  contactManagement:{
    '& div':{
        minHeight: '225px !important',
        borderRadius: '12px',
        borderWidth: '2px',
        display: 'flex',
        alignItems: 'baseline',
        '& textarea': {
          fontSize: '14px',
          minHeight: '225px !important',
        }
    }
  },
  textAreaField: {
      '& ::placeholder': {
        color: '#8d8d8d',
        opacity: 1
      },
      '& .MuiInputBase-input': {
        minHeight: '225px',
      },
      '& textarea': {
        fontSize: '14px',
        fontFamily: 'Rene_Bieder_Milliard_Book',
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#009be2',
        }
      }
    },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: COLORS.white,
    color: COLORS.black,
    borderBottom: "1px solid #f3f3f3",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.appBar - 1,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: " 2px solid #fff",
  },
  mobiledrawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 12px",
    ...theme.mixins.toolbar,
    justifyContent: "center",
    background: COLORS.blue,
  },
  drawerLogoHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 12px",
    ...theme.mixins.toolbar,
    justifyContent: "center",
    marginTop: "16%",
    marginBottom: "30px",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing.unit * 3,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  listItem: {
    transition: "all .5s",
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
  },
  listLink: {
    padding: "0 15px",
    textDecoration: "none",
    color: "inherit",
    transition: "all .5s",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  listIcon: {
    color: "inherit",
    justifyContent: "center",
  },
  listItemText: {
    // fontSize: '0.84em',
    // fontFamily : 'Roboto-Medium',
    ...FONTS.body3,
    // color: COLORS.lightGray,
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.84em",
    },
  },
  selectedlistItemText: {
    // fontSize: '0.84em',
    ...FONTS.body3,
    color: COLORS.white,
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.84em",
    },
  },
  list: {
    listStyle: "none",
    paddingRight: '30px'
  },
  listItemroot: {
    maxWidth: "100%",
    margin: "4px 26px 8px -4px",
    borderRadius: "4px",
    transition: "all .5s",
    overflow: "hidden",
  },
  selected: {
    backgroundColor: "#009BE233",
    borderRadius: "0px 8px 8px 0px",
    color: COLORS.white,
  },
  bottomtabselected: {
    background: COLORS.linearGradientSkyBlue,
    color: COLORS.skyBlue,
  },
  applogo: {
    height: "100%",
    maxHeight: "110px",
  },
  bottomBarroot: {
    width: "100%",
    height: "70px",
    left: "0px",
    top: "774px",
    borderRadius: "0px",
  },
  bottom_bar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  bottom_bar_button: {
    position: "reletive",
    zIndex: theme.zIndex.drawer + 1,
    marginRight: 18,
    marginLeft: 18,
    bottom: 40,
    right: 0,
    left: 0,
  },
  iconButton: {
    color: COLORS.black,
  },
  notificationiconButton: {
    color: COLORS.black,
    padding: "20px",
  },
  toolBarContentCenter: {
    justifyContent: "space-between",
  },
  toolBarContentLeft: {
    justifyContent: "flex-start",
  },
  _tabs_logo: {
    height: "24px",
    width: "24px",
    left: "33px",
    top: "788px",
    borderRadius: " 0px",
  },
  title: {
    color: COLORS.skyBlue,
  },
  dash: {
    border: " 1px solid lightgrey",
    width: "100%",
    height: 0,
  },
  sectionDesktop: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
});
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
import CfOfflineCachingMechanism25Controller from "../../CfOfflineCachingMechanism25/src/CfOfflineCachingMechanism25Controller";
import { connect } from "react-redux";
import { setSelectedTab } from "../../../framework/src/redux/tab/tabActions";
import { setSelectedAdminTab } from "../../../framework/src/redux/adminTab/adminTabActions";
import  ContentManagementProfile  from "./ContentManagementProfile.web";
import ContactusSuccessModal from "../../../components/src/custom/ContactusSuccessModal";
import { setActivePlan } from "../../../framework/src/redux/activePlan/activePlanActions";
import Notifications from "../../notifications/src/Notifications.web";
class ContentManagement extends ContentManagementController {
  static propTypes: {
    classes: // Customizable Area Start
    PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area Start
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  onWebSidebarMenuClick = (_index: any, item: any) => {
    const { adminSelectedTab } = this.props;

    const navigationRoutes = {
      [constants.admin_dashboard_screens.Dashboard]:
        "ContentManagementDashBoard",
      [constants.admin_dashboard_screens.Organisation]: "Organisation",
      [constants.admin_dashboard_screens.Events]: "ContentEvent",
      [constants.admin_dashboard_screens.Followers]: "FollowersNP",
      [constants.admin_dashboard_screens.RegisteredVolunteers]:
        "RegisteredVolunteers",
      [constants.admin_dashboard_screens.Users]: "UsersNP",
      [constants.admin_dashboard_screens.Communication]: "Communication",
      [constants.admin_dashboard_screens.Surveys]: "NpSurveys",
    };

    const selectedRoute = navigationRoutes[item.name];

    if (selectedRoute) {
      this.props.navigation.navigate(selectedRoute);

      if (
        item.name === constants.admin_dashboard_screens.Events &&
        adminSelectedTab === "Events"
      ) {
        this.props.tabsFunction('');
      }

      if (item.name === constants.admin_dashboard_screens.Events) {
        this.props.setSelectedAdminTab(
          constants.admin_dashboard_screens.Events
        );
      }
    }
  };

  renderSideBarDrawer() {
    const { classes, selectedAdminTab } = this.props;
    const { open } = this.state;

    const handleWebSidebarMenuClick = (_index: any, item: any) => {
      if(localStorage.getItem("plan") || item.name === "Dashboard"){
        this.onWebSidebarMenuClick(_index, item);
        setSelectedAdminTab(item.name);
        this.setState({openProfile:false})
      }
    };

    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        transitionDuration={0}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerLogoHeader}>
          <img src={images.app_logo} className={classes.applogo} />
        </div>

        <List className={classes.list}>
          {constants?.adminsidebar.map((item, _index) => {
            const isSelected = this.getUrlNameAdminDash()
              ? item.name === this.getUrlNameAdminDash()
              : item.name === selectedAdminTab;
            const listItemClassName = `${classes.listItemroot} ${
              isSelected ? classes.selected : ""
            }`;
            return (
              <ListItem
                button
                data-test-id="list-item"
                className={listItemClassName}
                onClick={() => handleWebSidebarMenuClick(_index, item)}
                disableGutters
                key={_index}
                disabled={this.isSidebarOptionDisabled(item.name)}
              >
                <Box component="div" className={classes.listLink}>
                  <ListItemIcon className={classes.listIcon}>
                   <img
                      className={classes._tabs_logo}
                      src={isSelected ? item.selectedIcon : item.icon}
                      style={{ height: "100%", width: "auto", maxHeight: "20px"}}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: "#009be2",
                      fontFamily: "Rene_Bieder_Milliard_Medium",
                      fontWeight: 700,
                    }}
                    className={
                      isSelected
                        ? classes.selectedlistItemText
                        : classes.listItemText
                    }
                  >
                    {item.name}
                  </ListItemText>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    );
  }

  indicator(context: any) {
    return <div>
      {context.newNoti && <Box style={{
        width: '8px',
        height: '8px',
        backgroundColor: "red",
        borderRadius: '50%',
        position: 'absolute',
        top: 24,
        right: 24,
      }}>
      </Box>}
    </div>;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, theme, children } = this.props;
    const { open } = this.state;
    const plan = localStorage.getItem("plan");
    // Customizable Area End
    return (
      // Customizable Area Start
      <AppContext.Consumer>
        {(context: any) => (
      <ThemeProvider theme={theme}>
        <div
          className={classes.root}
          style={{ backgroundColor: "#f0f2f5", height:"100%" }}
        >
          <CssBaseline />

          {/* web  */}
          <Hidden xsDown implementation="css">
            {this.renderSideBarDrawer()}
          </Hidden>
          {/* web end  */}
          <main
            className={classNames(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <AppBar
              position="static"
              style={{
                background: "linear-gradient(180deg, #00CBF3 0%, #009BE2 100%)",
                height: 66,
              }}
            >
              <Toolbar className={classOuter.dashboardnavicons}>
                {plan && <Box fontFamily="Rene_Bieder_Milliard_Medium" fontSize="16px" border="1.5px solid #fff" borderRadius="8px" padding="5px 10px">Subscribed to {plan}</Box>}
                <div
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: 0,
                  }}
                >
                  <IconButton aria-label="show 4 new mails" color="inherit" 
                    data-test-id="bell-icon"
                    onClick={(e) => {
                      this.handleNotiOpen(e);
                      if(this.state.openNoti){
                        context.closeIndicator();
                      }
                    }} 
                  >
                    {this.indicator(context)}
                    <img
                      src={icons.bellIconNP}
                      style={{ height: 48, width: 48 }}
                    />
                  </IconButton>
                  <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                  <Avatar src={this.state.draft.avatar}  style={{ height: 48, width: 48}}/>     
                  </IconButton>
                  <Popover
                    data-test-id="notification-modal"
                    open={!!this.state.openNoti}
                    onClose={()=>{
                      this.handleNotiClose();
                      context.closeIndicator();
                    }}
                    style={{ height: "80%" }}
                    anchorEl={this.state.openNoti}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    PaperProps={{ style: popoverStyles }}
                  >
                    <div>
                    <Notifications navigation={this.props.navigation} id={''} onClose={undefined} 
                      data-test-id="notification"
                      isCome={context.isCome}
                      notificationmodalState={() => {
                        this.handleNotiClose();
                        context.closeIndicator();
                      }}
                      type="non_profit_entity"
                    />
                    </div>
                  </Popover>
                  <Typography
                    onClick={this.handleClickProfile}
                    style={{ display: "flex", alignItems: "center", cursor:"pointer" }}
                  >
                    Profile
                    <span
                      className={classOuter.arroWImgSpan}
                      data-test-id="dropIcons"
                      onClick={(e) => this.goToClick(e)}
                    >
                      {this.state.anchorEl !== null ? (
                        <img
                          src={icons.view_up}
                          className={classOuter.viewUpImg}
                        />
                      ) : (
                        <img
                          src={icons.view_down}
                          className={classOuter.viewDownImg}
                        />
                      )}
                    </span>
                  </Typography>
                  <Popover
                    data-testId="menu2Popover"
                    open={this.state.anchorEl !== null}
                    onClose={this.handlePopoverClose}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{ style: popoverStyle }}
                  >
                    <MenuList
                      style={{
                        paddingLeft: 50,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          if (!["/subscriptionplan", "/paymentoption", "/currentplaninfo", "/gamification"].includes(window.location.pathname.toLocaleLowerCase())) {
                            localStorage.setItem("routePath", window.location.pathname);
                          }
                          this.props.navigation.navigate('currentPlanInfo')
                        }}>
                        Subscriptions
                      </MenuItem>
                      {plan !== null && plan !== "Trial" && plan !== "Presence-Only" &&
                      <MenuItem
                        button
                        onClick={() => {
                        
                          if (!["/subscriptionplan", "/paymentoption", "/currentplaninfo", "/gamification"].includes(window.location.pathname.toLocaleLowerCase())) {
                            localStorage.setItem("routePath", window.location.pathname);
                          }
                          this.props.navigation.navigate("Gamification");
                        }}
                      >
                        Rewarded Points
                      </MenuItem>
                      }
                      {plan !== null && 
                      <MenuItem 
                        button
                        data-test-id="ActivityLog"
                        onClick={() => {this.props.navigation.navigate("ActivityLog")
                        }}>Activity Log</MenuItem>}
                      <MenuItem  data-test-id="contactUs" onClick={this.handleContactusOpen}>Contact Us</MenuItem>
                      <MenuItem data-test-id="signout" onClick={() => {
                        this.handleLogout()
                        this.deleteFcmToken()
                      }} >Sign out</MenuItem>
                    </MenuList>
                  </Popover>
                </div>
              </Toolbar>
              <Modal
                open={this.state.openContactusModal}
                onClose={this.handleContactusClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modalWrapper}
              >
                <Box sx={classes.modalstyle}>
                  <Box className={classes.modalBox}
                  // maxWidth="xl"
                  >
                    <Box>
                      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
                        <Typography style={{ ...FONTS.body1, fontSize: '16px', textAlign: 'left', marginBottom: '20px' }}>
                          Drop us a message and we'll come back to you as soon as possible!'
                        </Typography>
                        <CloseIcon onClick={this.handleContactusClose} style={{ position: 'absolute', right: ' 18px', top: '18px', cursor: 'pointer', borderRadius: '50%', border: '2px solid', padding: '3px', fontSize: '24px', }} />
                      </div>
                      <div className={classes.contactManagement} style={{ marginBottom: "2%" }}>
                        <TextField
                          value={this.state.contactMsg}
                          onChange={(e) => {
                            this.setState({ isSubmitting: false, contactMsg: e.target.value, contactMessageError: "" });
                          }}
                          onBlur={(e) => {
                            this.validateContactMessage(e.target.value)
                          }}
                          data-testId='contactUs'
                          id="outlined-multiline-static"
                          placeholder="What do you want to tell us about?"
                          multiline
                          rows={6}
                          variant="outlined"
                          fullWidth
                          className={classes.textAreaField}
                        />
                      </div>
                      <Box className={classOuter.errorMsg}>
                        <div className={classOuter.errors}>{this.state.contactMessageError}</div>
                      </Box>
                    </Box> 
                    <div style={{ display: "flex", justifyContent: "flexEnd", marginTop: 30 }}>
                      <Button
                        className={classes.modalBtn}
                        onClick={this.contactUsSub}
                        variant="contained"
                        disabled={this.state.isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </Box>
                </Box>
              </Modal>
              <ContactusSuccessModal openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose}/>
            </AppBar>

            {this.state.openProfile? <ContentManagementProfile openSaveModel={this.state.openSaveModel} handleCloseSaveModel={this.handleCloseSaveModel} onClose={this.handleCloseProfile} draft={this.state.draft} handleProfileFieldChange={this.handleProfileFieldChange} profileChangeErrors={this.state.profileChangeErrors} handleChangeAvatar={this.handleChangeAvatar} handleSubmitProfile={this.handleSubmitProfile} />: children}
          </main>
        </div>
      </ThemeProvider>
      )}
      </AppContext.Consumer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export { ContentManagement };
ContentManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
// Customizable Area End

function mapStateToProps(state: any) {
  return {
    adminSelectedTab: state.adminTabReducer.adminSelectedTab,
    activePlan:state.activePlanReducer.activePlan
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedAdminTab: (selectedTab: any) => {
      return dispatch(setSelectedAdminTab(selectedTab));
    },
    setActivePlan: (selectedTab: any) => {
      return dispatch(setActivePlan(selectedTab));
    },
    
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any)(ContentManagement));
