import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RouterContextProvider } from '../../../components/src/utils/router-context';

const useSignupLayoutStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#white'
  },
  container: {
    boxShadow: '1px 6px 30px 1px #c5c5c4',
    backgroundColor: 'white',
    width: '400px',
    padding: '40px',
    borderRadius: '24px',
    margin: '50px 0',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      padding: '20px',
    },
  },
}));


export function NPSignupFormLayout({ children }: { children: ReactNode }) {

  const classes = useSignupLayoutStyles();

  return (
    <RouterContextProvider>
      <div className={classes.root}>

        <div className={classes.container}>
          {children}
        </div>

      </div>
    </RouterContextProvider>
  );
}
