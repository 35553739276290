// @ts-nocheck
import React from "react";
// Customizable Area Start
import { Container, Box, Typography } from "@material-ui/core";
import GooglePlacesAutocomplete,{ geocodeByAddress } from 'react-google-places-autocomplete';
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";
// @ts-ignore
import classesoutercss from "./UserProfileBasicBlock.module.css";
import {
  constants,
  FONTS,
  icons
} from "../../../components/src/constants";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { COLORS } from "../../../framework/src/Globals";
import { isMobile } from "react-device-detect";
import NonProfitsAffiliationsWeb from "./NonProfitsAffiliations.web";
import { InitiateSwitch } from '../../../components/src/design-system/switch';
import { InitiateButton } from '../../../components/src/design-system/button';
import { InitiateSpinner } from "../../../components/src/design-system/spinner";
import {Redirect} from "react-router-dom"
import { configJSON } from "./ActiveNeedsController.web";
// Customizable Area End


export default class UserEditProfile extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  userProfilePicture() {
    return (
      this.state.profileUserData === undefined ? icons.profile : this.userDynamicProfilePicture()
    )
  }
  userDynamicProfilePicture() {
    return (
      this.state.profileUserData?.attributes?.profile_picture?.url === null ? icons.profile : this.state.profileUserData?.attributes?.profile_picture?.url
    )
  }
  handleImageClick = () => {
    this.fileInputRef.current.click();
  };
  getWorkEmail = () => {
    return (
      <Box style={{ position: "relative" }}>
        <input
          type="email"
          required
          style={{ ...FONTS.dbsForm, background: this.state.editWorkEmail ? "#fff" : "#f8f8f8" }}
          className={classesoutercss.editInputField}
          data-test-id="work_email"
          name="work_email"
          value={this.state.profileUserData?.attributes?.work_email}
          onChange={(event) => this.handleChangeUpload(event)}
          disabled={!this.state.editWorkEmail}
          onBlur={() => this.setState({ editWorkEmail: false })}
        />
        <Typography
          style={{ ...FONTS.largeTitle }}
          className={classesoutercss.edit}
          data-test-id="editBtn"
          onClick={() => this.setState({ editWorkEmail: !this.state.editWorkEmail })}
        >
          {this.state.editWorkEmail ? "" : "Edit"}
        </Typography>
        {
          <div className="error" style={{ marginTop: "-15px", position: "absolute" }}>
            {this.state.workEmailError}
          </div>
        }
      </Box>
    );
  }

  renderSaveBtn = () => {
   return <Box marginTop='10px' marginBottom="30px">
      <InitiateButton
        fullWidth
        data-test-id="saveBtn"
        style={{backgroundColor: "009BE2", color: 'white', fontSize:'1.3rem', fontFamily: 'Rene_Bieder_Milliard_Medium'}}
        variant="contained"
        size="large"
        onClick={() => {
          if(this.state.workEmailState !== this.state.profileUserData?.attributes?.work_email) {
            this.checkWorkEmail(this.state.profileUserData?.attributes?.work_email);
          } else {
            this.handleUpdateProfile();
            setTimeout( () => {
              if (this.props.setBack) {
                this.props.setBack();
              } else {
                this.props.navigation.navigate('UserProfileBasicBlock'); 
              }
            }, 1000);
          }
        }}
      >
        {'Save'}
      </InitiateButton>
    </Box>
  }

  getAddressDetails = (results: google.maps.GeocoderAddressComponent[]) =>  {
    const obj = {
      area: "",
      city: "",
      country: "",
      postcode: "",
    };

    for (const component of results) {
      if (component.types.includes('postal_code')) {
        obj.postcode = component.long_name;
      } else if (!obj.city && (component.types.includes('postal_town') || component.types.includes("administrative_area_level_2") || component.types.includes('administrative_area_level_1'))) {
        obj.city = component.long_name;
      } else if (component.types.includes('country')) {
        obj.country = component.long_name;
      }
    }
    return obj;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { profileImageSrc } = this.state


    return (
      //Required for all blocks
      <DashboardWeb
      data-test-id='dashboard'
      showBackButton={constants._icons.show}
      title="Edit"
      showBottomBar={constants._icons.hide}
      showDrawerHeader={isMobile}
      titleColor={COLORS.black}
      showLine={true}
      navigation={this.props.navigation}
    >
      {this.state.redirect && <Redirect to={`${this.state.redirect}`} />}

      {
         this.state.loader ?
         <div className={classesoutercss.loaderRoot}>
              <div className={classesoutercss.circularContainer}>
              <InitiateSpinner />
              </div>
            </div>
         :
         <div style={{  position: 'fixed',
         display: 'flex',
         flexDirection: 'row',
         backgroundColor: 'white',
         width:'100%',
         justifyContent:'left',
         marginLeft:'-15.6%',
         zIndex:'1000',
         textAlign:'left'}}>
      <Container maxWidth="sm" >
        <form className={classesoutercss.EditprofileFrom}>
          <Box className={classesoutercss.profileIconBox}>
            <div className={classesoutercss.EditprofilePicImg}>
            <img src={profileImageSrc || this.userProfilePicture()} alt="profile-logo" className={classesoutercss.EditprofilePicImage} />
            </div>
            <input accept='image/*' type='file' style={{ display: 'none' }} ref={this.fileInputRef}
              onChange={this.handleFileChange} />
            <img src={icons.camerablack} alt="camera-icon" className={classesoutercss.cameraIcon} onClick={this.handleImageClick} />
            
            <div className={classesoutercss.editPicture}>
                <Typography className={classesoutercss.imageErrorText} style={{ ...FONTS.dbsTitles }}>{this.state.imageError}</Typography>
               <div className={classesoutercss.titleprof}><Typography style={{ ...FONTS.dbsTitles }}>Change profile picture</Typography></div> 
              </div>
              </Box>
          {
            this.state.nonProfitDisplay ?
              <Box className={classesoutercss.profileEditBox} style={{ borderRadius: '10px', marginTop: '60px' }}>
                <NonProfitsAffiliationsWeb data-test-id="nonProfitBackButton" backButton={() => this.setState({ nonProfitDisplay: false })} profileType={this.state.editProfileType} />
              </Box>
              :
              <Box className={classesoutercss.profileEditBox} style={{ paddingLeft:'25px',paddingTop:'25px', paddingRight:'25px', borderRadius: '10px' }}>
                <label className={classesoutercss.label} style={{ ...FONTS.largeTitle }}>Full name</label>
                <input type="text" style={{ ...FONTS.dbsForm }} className={classesoutercss.editInputField} data-test-id='full_name' name="full_name" value={this.state.profileUserData?.attributes?.full_name} onChange={(event) => this.handleChangeUpload(event)} />
                <label className={classesoutercss.label} style={{ ...FONTS.largeTitle }}>Email address</label>
                <input type="email" style={{ ...FONTS.dbsForm }} className={classesoutercss.editInputField} data-test-id='email' name="email" value={this.state.profileUserData?.attributes?.email} readOnly />
                <label className={classesoutercss.label} style={{ ...FONTS.largeTitle }}>Bio</label>
                <textarea placeholder="Type something... (Max 150 characters)" style={{ ...FONTS.dbsForm }} maxLength="150" col={3} type="text" className={classesoutercss.bioInput} id="bio" data-test-id='bio' name="bio" value={this.state.profileUserData?.attributes?.bio} onChange={(event) => this.handleChangeUpload(event)} />
                <label className={classesoutercss.label} style={{ ...FONTS.largeTitle }}>Address</label>
                <br/>
                <br/>
                <label className={classesoutercss.label} style={{ ...FONTS.regularFont }}>Street Name</label>
                <Box mt="7px" mb="15px" className='address-location user-profile'>
                  <GooglePlacesAutocomplete
                    data-test-id='street_name'
                    apiKey={configJSON.apiKey}
                    debounce={500}
                    selectProps={{
                      isClearable: true,
                      classNamePrefix: "react-select",
                      placeholder: "",
                      value: this.state.profileUserData?.attributes?.street_name ? 
                      [{ label: this.state.profileUserData?.attributes?.street_name, value: this.state.profileUserData?.attributes?.street_name }] : undefined,
                      noOptionsMessage: () => null,
                      onChange: async (event) => {
                        const location = event?.value?.description ?? "";
                        this.setState({
                          profileUserData: {
                            ...this.state.profileUserData,
                            attributes: {
                              ...this.state.profileUserData?.attributes,
                              street_name: location,
                              area:  "",
                              city: "",
                              country: "",
                              post_code: ""
                            }
                          }
                        });
                        geocodeByAddress(location)
                          .then(results => {
                            const { area, postcode, city, country } = this.getAddressDetails(results[0].address_components);
                            this.setState({
                              profileUserData: {
                                ...this.state.profileUserData,
                                attributes: {
                                  ...this.state.profileUserData?.attributes,
                                  area: area,
                                  city: city,
                                  country: country,
                                  post_code: postcode
                                }
                              }
                            });
                          }
                        )
                      }
                    }}
                  />
                </Box>
                <label className={classesoutercss.label} style={{ ...FONTS.regularFont  }}>Area</label>
                <input type="text" style={{ ...FONTS.dbsForm }} className={classesoutercss.editInputField} data-test-id='area' name="area" value={this.state.profileUserData?.attributes?.area} onChange={(event) => this.handleChangeUpload(event)}/>
                <label className={classesoutercss.label} style={{ ...FONTS.regularFont  }}>City</label>
                <input type="text" style={{ ...FONTS.dbsForm }} className={classesoutercss.editInputField} data-test-id='city' name="city" value={this.state.profileUserData?.attributes?.city} onChange={(event) => this.handleChangeUpload(event)}/>
                <label className={classesoutercss.label} style={{ ...FONTS.regularFont  }}>Country</label>
                <input type="text" style={{ ...FONTS.dbsForm }} className={classesoutercss.editInputField} data-test-id='country' name="country" value={this.state.profileUserData?.attributes?.country} onChange={(event) => this.handleChangeUpload(event)}/>
                <label className={classesoutercss.label} style={{ ...FONTS.regularFont  }}>Post code</label>
                <input type="text" style={{ ...FONTS.dbsForm }} className={classesoutercss.editInputField} data-test-id='post_code' name="post_code" value={this.state.profileUserData?.attributes?.post_code} onChange={(event) => this.handleChangeUpload(event)}/>
                <Box style={{ position: "relative" }}>
                  <input style={{ ...FONTS.dbsForm }} type="text" value="Non-profits registered with" data-test-id='non-profitText' name="non-profitText" className={classesoutercss.editInputField} readOnly />
                  <Typography  style={{ ...FONTS.largeTitle }} className={classesoutercss.edit} data-test-id='editNonProfitAffilliation' onClick={isMobile? () => this.props.navigation.navigate('NonProfitsAffi'): () => this.handleEditView('nonProfit')}>Edit</Typography>
                </Box>
                <Box style={{ position: "relative" }}>
                  <input style={{ ...FONTS.dbsForm }} type="text" value="DBS" data-test-id='dbsText' name="dbsText" className={classesoutercss.editInputField} readOnly />
                  <Typography  style={{ ...FONTS.largeTitle }} className={classesoutercss.edit} onClick={isMobile? () => this.setState({redirect:"/DBSdetailsweb?key=DBSdetailsEdit"}):() => this.handleEditView('dbs')}>Edit</Typography>
                </Box>
                <label className={classesoutercss.label} style={{ ...FONTS.largeTitle }}>Work email address</label>
                {this.getWorkEmail()}
                <Box style={{ position: "relative" }} >
                    <input style={{ ...FONTS.dbsForm }} type="text" value="Show company logo in my profile" className={classesoutercss.editInputField} readOnly />
                    <Typography className={classesoutercss.switchEdit}>
                      <InitiateSwitch
                        checked={this.state.show_company_logo}
                        onChange={(event) => {
                          this.setState({show_company_logo:event.target.checked});
                        }}
                        name="profileLogoSwitch"
                        aria-label="profileLogoSwitch"
                        defaultChecked data-test-id="profileLogoSwitch"
                      />
                    </Typography>
                  </Box>
                  <div className={classesoutercss.saveMob}  >
                    {this.renderSaveBtn()}
                  </div>
              </Box>

          }
        </form>
      </Container>
      </div>
      }
    </DashboardWeb>
      );
    // Customizable Area End
  }
  
  // Customizable Area Start
  // Customizable Area End
  
}
// Customizable Area Start

// Customizable Area End