// @ts-nocheck
// Customizable Area Start
import React from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { constants } from "../../../components/src/constants";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { setSelectedEvents } from "../../../framework/src/redux/events/eventsActions";
import { connect } from "react-redux";
// @ts-ignore
import UserProfileBasicBlock from "./UserProfileBasicBlock.web";
// Customizable Area End

// Customizable Area Start


// Customizable Area End
class UserProfileBasicBlockParent extends UserProfileBasicController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End


  render() {
    // Customizable Area Start
    
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <DashboardWeb
          showBackButton={constants._icons.show}
          title={"Profile"}
          navigation={this.props.navigation}
          showBottomBar={constants._icons.show}
          showDrawerHeader={isMobile ? true : false}
          
        >
          <UserProfileBasicBlock navigation={this.props.navigation} />
        </DashboardWeb>
      </>
      // Customizable Area End
    );
  }
}


UserProfileBasicBlockParent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Customizable Area Start
function mapStateToProps(state: any) {
  return {
    selectedevents: state.eventsReducer.selectedEvents,

  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedEvents: (selectedTab: any) => {
      return dispatch(setSelectedEvents(selectedTab))
    }
  }
}

export { UserProfileBasicBlockParent };
// Customizable Area End
// Customizable Area Start
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileBasicBlockParent as any);
// Customizable Area End