import React, { useEffect, useState } from "react";
// @ts-ignore
import module from "./BusDrops.module.css";
import { Button, Box, Typography, Slider } from "@material-ui/core";
import Select from "react-select";
import { components } from "react-select";
// @ts-ignore
import CustomMultiValue from "./CustomMutliValue.tsx";
import { FAMILYFONTS } from "../../../components/src/constants";
import { styled } from "@material-ui/styles";
import { configJSON } from "../../../blocks/ContentManagement/src/ContentManagementController";
import GooglePlacesAutocomplete,{ geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const marksList = [
  { value: 1 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
  { value: 30 },
  { value: 35 },
  { value: 40 },
  { value: 45 },
  { value: 50 }
];

const FilterDrops = (props: any) => {
  const whereOptions = [
    { label: "Bedfordshire", value: "Bedfordshire" },
    { label: "Berkshire", value: "Berkshire" },
    { label: "Bristol", value: "Bristol" },
    { label: "Buckinghamshire", value: "Buckinghamshire" },
    { label: "Cambridgeshire", value: "Cambridgeshire" },
    { label: "Cheshire", value: "Cheshire" },
    { label: "City of London", value: "City_of_London" },
    { label: "Cornwall", value: "Cornwall" },
    { label: "Cumbria", value: "Cumbria" },
    { label: "Derbyshire", value: "Derbyshire" },
    { label: "Devon", value: "Devon" },
    { label: "Dorset", value: "Dorset" },
    { label: "Durham", value: "Durham" },
    {
      label: "East Riding of Yorkshire",
      value: "East_Riding_of_Yorkshire",
    },
    { label: "East Sussex", value: "East_Sussex" },
    { label: "Essex", value: "Essex" },
    { label: "Gloucestershire", value: "Gloucestershire" },
    { label: "Greater London", value: "Greater_London" },
    { label: "Greater Manchester", value: "Greater_Manchester" },
    { label: "Hampshire", value: "Hampshire" },
    { label: "Herefordshire", value: "Herefordshire" },
    { label: "Hertfordshire", value: "Hertfordshire" },
    { label: "Isle of Wight", value: "Isle_of_Wight" },
    { label: "Kent", value: "Kent" },
    { label: "Lancashire", value: "Lancashire" },
    { label: "Leicestershire", value: "Leicestershire" },
    { label: "Lincolnshire", value: "Lincolnshire" },
    { label: "Merseyside", value: "Merseyside" },
    { label: "Norfolk", value: "Norfolk" },
    { label: "North Yorkshire", value: "North_Yorkshire" },
    { label: "Northamptonshire", value: "Northamptonshire" },
    { label: "Northumberland", value: "Northumberland" },
    { label: "Nottinghamshire", value: "Nottinghamshire" },
    { label: "Oxfordshire", value: "Oxfordshire" },
    { label: "Rutland", value: "Rutland" },
    { label: "Shropshire", value: "Shropshire" },
    { label: "Somerset", value: "Somerset" },
    { label: "South Yorkshire", value: "South_Yorkshire" },
    { label: "Staffordshire", value: "Staffordshire" },
    { label: "Suffolk", value: "Suffolk" },
    { label: "Surrey", value: "Surrey" },
    { label: "Tyne and Wear", value: "Tyne and Wear" },
    { label: "Warwickshire", value: "Warwickshire" },
    { label: "West Midlands", value: "West_Midlands" },
    { label: "West Sussex", value: "West_Sussex" },
    { label: "West Yorkshire", value: "West_Yorkshire" },
    { label: "Wiltshire", value: "Wiltshire" },
    { label: "Worcestershire", value: "Worcestershire" },
  ];

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <label>{props.label}</label>
          <input
            style={{ float: "right" }}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
        </components.Option>
      </div>
    );
  };

  const [whereOpen, setWhereOpen] = useState(false);
  return (
   <Wrapper>
    <Box mt="7px">
      <label className={module.labelBold}>Sort by</label>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent:"space-around"
        }}
      >
        <Button className={props.filterData === 'project_funding' ? module.selectedBtn : module.btns} onClick={() => props.selectedFilter('project_funding')}>Projects</Button>
        <Button className={props.filterData === 'need' ? module.selectedBtn : module.btns} onClick={() => props.selectedFilter('need')}>Need</Button>
        <Button className={props.filterData === 'activity' ? module.selectedBtn : module.btns} style={{width:160}} onClick={() =>props.selectedFilter('activity')}>Upcoming Activity</Button>
      </div>
      <div>
        <label className="label">
          Location
        </label>
        <Box border='1px solid lightgrey' borderRadius='10px' margin="0px 10px">
        <GooglePlacesAutocomplete
  apiKey={configJSON.mapsAPIKey}
  debounce={500}
  selectProps={{
    styles: {
      menu: (provided, state) => ({
        ...provided,
        position: 'absolute',
        height: '100px', 
      }),
      menuList: (base) => {
        return {
          ...base,
          position: "relative",
          height:'105px',
          border:"1px solid lightgrey",
          borderRadius:"10px",
          color: "grey",
        };
      },
    },
    isClearable: true,
    value: props.location ? [{ label: props.location, value: props.location }] : [],
    classNamePrefix: "react-select",
    placeholder: "Enter location",
    noOptionsMessage: () => null,
    onChange: async (event) => {
      const location = event?.value?.description ?? "";
      props.onLocationChange(0, 0, location);
      geocodeByAddress(location)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          props.onLocationChange(lat, lng, location);
        });
    },
    
  }}
/>
</Box>
          {props.location &&
          <div className="proximity">
            <div className="wrap-box">
              <label className="label">
                Proximity (miles)
              </label>
              <Box width="auto" padding="0px 10px" marginTop="25px">
                <Slider
                  defaultValue={props.proximity}
                  className="slider"
                  step={null}
                  min={1}
                  max={50}
                  valueLabelDisplay="on"
                  marks={marksList}
                  value={props.proximity}
                  onChange={(e, value) => props.onProximityChange(value)}
                />
              </Box>
              <div className="title">
                <Typography className="miles">1 mi.</Typography>
                <Typography className="miles">50 mi.</Typography>
              </div>
            </div>
          </div>}
      </div>
      <Button className={module.applyBtn} style={{ ...FAMILYFONTS.family1, fontSize:18, marginRight:10,marginBottom:10}} onClick={() => props.applyFilter()}>Apply</Button>
      <Button className={module.clearBtn} style={{ ...FAMILYFONTS.family1, fontSize:18, marginRight:10,marginBottom:10}} onClick={() => props.selectedFilter('')}>Clear</Button>
    </Box>
     </Wrapper>

  );
};
const Wrapper = styled(Box)({
  position: "relative",
  "& .label": {
    display: "inline-block",
    fontFamily: "Rene_Bieder_Milliard_Medium !important",
    fontSize: "16px",
    marginBottom: "9px !important",
    paddingLeft: "10px",
    marginTop: "15px"
  },
  "& .proximity": {
    position: "relative",
    "& .wrap-box": {
      height: 70,
      display: "content",
      zIndex: 4,
      position: "relative",
      borderRadius: 12,
      "& .title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .text":{
          ...FAMILYFONTS.family3, 
          fontSize: 12, 
          fontWeight: 600
        }
      },
      "& .slider": {
        color: "#009be2",
        fontSize: 48
      },
      "& .miles": {
        ...FAMILYFONTS.family3,
        fontSize: 12, 
        fontWeight: 500
      }
    },
  },
  "& .react-select__multiValueLabel": {
    fontFamily: "Rene_Bieder_Milliard_Book",
    backgroundColor: "white",
  },
  "& .react-select__multiValueRemove": {
    display:'none',
  },
  "& .react-select__placeholder": {
    color: "#7C7C7C",
  },
  "& .react-select__menu": {
    border: "none",
    marginTop: 0,
    borderTop: "none",
    boxShadow: "none",
    overflowY: "auto",
    color: "lightgrey",
    paddingTop: 5,
    zIndex: 99,
    "&:hover": {
        borderRadius: 12,
      },
    height:120
  },
  "& .react-select__control": {
    backgroundColor: "white",
    boxShadow: "none",
    height: "40px",
    borderRadius: "10px",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom:"1px solid lightgrey",
    "&:hover": {
      borderBottom:"1px solid lightgrey",
    },
  },
  "& .react-select__option": {
    color: "#7C7C7C",
    backgroundColor: 'inherit',
    "&:hover": {
    backgroundColor: "#009BE2" ,
    color: "white",
    }, 
  },
  "& .react-select__menuList": {
    position: "absolute",
    top: "-5px",
    zIndex: -20,
    color: "grey",
    height: 115,
   
  },
  "& .react-select__dropdown-indicator": {
    color: "dodgerblue",
  },
  "& .react-select__indicator-separator": {
    display: "none"
  },
});

export default FilterDrops;
