import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/utils/utils.web";
import moment from "moment"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading: boolean;
  orgLoader: boolean;
  openOrgModal: boolean;
  orgClicked: boolean;
  openDonateDialog: boolean;
  currentPoints: number;
  pointsEarnedLifetime: number;
  currentDonatablePointsFromBalance: number;
  reedemableAmount: number;
  filteredData: any;
  organisationData: any;
  singleOrgData: any;
  NpDetais: any;
  currentOrgName: string;
  tableData: any;
  organisationFilteredData: any;
  donateValue: number | null;
  donatedPoints: number;
  pointsRange: number[];
  medal: string;
  id: string;
  radioValue: string;
  amountValue: number | null;
  userEntered: number | string;
  dynamicText: string;
  openPaymentDialog: boolean;
  donatedAmount: number | null ;

  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class GamificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginAPICallId: string = "";
  getNonProfitProfileApiCallId: string = "";
  getR1CurrentPointsApiCallId: string = "";
  getR2CurrentPointsApiCallId: string = "";
  getUserPointsHistoryApiCallId: string = "";
  getNonProfitPointsHistoryApiCallId: string = "";
  donatePointsToOrgApiCallId: string = "";
  getFollowedOrgApiCallId: string = "";
  getPointsRange: string ="";
  donatePointsApiCallId: string = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loading: false,
      orgLoader: false,
      openOrgModal: false,
      orgClicked: false,
      openDonateDialog: false,
      NpDetais: {},
      currentOrgName: "",
      currentPoints: 0,
      pointsEarnedLifetime: 0,
      currentDonatablePointsFromBalance: 0,
      reedemableAmount: 0,
      donateValue: null,
      donatedPoints: 100,
      singleOrgData: {},
      organisationFilteredData: [],
      organisationData: [],
      filteredData: [],
      tableData: [],
      pointsRange:  [
        100,
        150,
        200
      ],
      medal: "",
      id: "",
      radioValue: "points",
      amountValue: null,
      userEntered:0,
      dynamicText: "",
      openPaymentDialog: false,
      donatedAmount: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const id = this.props.navigation.getParam("id");
   
    if (id) {
      this.setState({ id, orgClicked: true }, () => {
        this.getR1CurrentPointBalance()
        this.getNonProfitProfile(id);
      });
      return;
    }
    let user = localStorage.getItem("user_type");
    if(user === "user entity"){
      this.getR1pointsHistory()
      this.getR1CurrentPointBalance()
    } else {
      this.getR2pointsHistory();
      this.getR2CurrentPointBalance()
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responsesJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responsesJson) {
        if (responsesJson.errors?.length > 0) {
          toast.error(`${responsesJson.errors[0].token}`)
        } else {
          this.checkAPIResponses(apiRequestCallId, responsesJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  renderCellData = (cellData: string) => {
    return cellData ?? "--"
  }

  renderPonits = (type:string, points: string) => {
    return `${type === "earn" ? "+" : "-"} ${points + " points"}`
  }
  checkAPIResponses = (apiRequestCallId:any, responseJson:any) => {
    switch (apiRequestCallId) {
      case this.getNonProfitProfileApiCallId:
        this.setState({
          NpDetais: responseJson.data,
          currentOrgName: responseJson.data.attributes.organisation_name,
        });
        break;
      case this.getR1CurrentPointsApiCallId:
        this.setState({
          medal: responseJson.data.medal ?? "bronze",
          currentPoints: responseJson.data.current_point_blance.toLocaleString() ?? 0,
          pointsEarnedLifetime: responseJson.data.points_earned_lifetime.toLocaleString() ?? 0,
          currentDonatablePointsFromBalance:
            responseJson.data.current_donatable_points_from_balance.toLocaleString() ?? 0,
        });
        break;
      case this.getR2CurrentPointsApiCallId:
        this.setState({
          medal: responseJson.data.medal ?? "bronze",
          currentPoints: responseJson.data.current_point_balance.toLocaleString() ?? 0,
          pointsEarnedLifetime: responseJson.data.points_earned_lifetime.toLocaleString() ?? 0,
          reedemableAmount:
            responseJson.data.total_redeemable_amount_with_current_balance.toLocaleString() ?? 0,
        });
        break;
      case this.getUserPointsHistoryApiCallId:
        this.setState({
          loading: false,
          filteredData: responseJson.data.map((item:any) => {
            return {
               ...item,
               points : this.renderPonits(item.type,item.points),
               date_time: moment(item.date_time).format("DD/MM/YYYY hh:mm A"),
               activity_type: this.renderCellData(item.activity_type),
               description: this.renderCellData(item.description)
             }
          }),
          tableData: responseJson.data.map((item:any) => {
            return {
               ...item,
               points : this.renderPonits(item.type,item.points) ,
               date_time: moment(item.date_time).format("DD/MM/YYYY hh:mm A"),
               activity_type: this.renderCellData(item.activity_type),
               description: this.renderCellData(item.description)
             }
          }),
        });
        break;
      case this.getNonProfitPointsHistoryApiCallId:
        this.setState({
          filteredData: responseJson.data.map((item:any) => {
            return {
               ...item,
               points : this.renderPonits(item.type,item.points),
               date_time: moment(item.date_time).format("DD/MM/YYYY HH:mm "),
               description: this.renderCellData(item.description)
             }
          }),
          loading: false,
          tableData: responseJson.data.map((item:any) => {
            return {
               ...item,
               points : this.renderPonits(item.type,item.points),
               date_time: moment(item.date_time).format("DD/MM/YYYY HH:mm"),
               description: this.renderCellData(item.description)
             }
          }),
        });
        break;
      case this.donatePointsToOrgApiCallId:
        toast.success(responseJson.message);
        this.setState({ loading: false });
        break;
      case this.getFollowedOrgApiCallId:
        this.setState({
          organisationData: responseJson,
          organisationFilteredData: responseJson,
          orgLoader: false
        });
        break;
      case this.getPointsRange:
        this.setState({
          pointsRange: responseJson.data.range,
        });
        break;
    }
  }

  handleTabChange = (event: any, newValue: any) => {
    this.setState({ donateValue: newValue, donatedPoints: event.target.innerText.split(" ", 1)[0] })
  }
  getNonProfitProfile = async (id: string) => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getNonProfitProfileApiCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_organisations/organisations/${id}`,
      header: header
    });
  }

  getR1CurrentPointBalance = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getR1CurrentPointsApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_gamification/donate_points/r1_rewarded_points`,
      header: header
    });
  }

  getR2CurrentPointBalance = async () => {
    const header = {
      "token": localStorage.getItem("nonprofitToken"),
      "type": "non_profit_entity"
    }
    this.getR2CurrentPointsApiCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_gamification/donate_points/r2_rewarded_points`,
      header: header
    });
  }

  getR1pointsHistory = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getUserPointsHistoryApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_gamification/earned_points/get_earn_donate_points`,
      header: header
    });
  }

  getR2pointsHistory = async () => {
    const header = {
      "token": localStorage.getItem("nonprofitToken"),
      "type": "non_profit_entity"
    }
    this.getNonProfitPointsHistoryApiCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_gamification/donate_points`,
      header: header
    });
  }

  donatePointsToOrg = async (orgId:any) => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity",
      "Content-Type": 'application/json',
    }
    const body =  {
      "data":{
        "attributes": {
            "organisation_id": orgId,
            "points": this.state.donatedPoints
        }
     }
    }
    this.donatePointsToOrgApiCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_gamification/donate_points`,
      header: header,
      body: JSON.stringify(body),
    });
  }

  getFollowedOrg = async () => {
    this.setState({ orgLoader: true})
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getFollowedOrgApiCallId = await apiCall({
      method: "GET",
      endPoint: `/get_available_organisations`,
      header: header
    });
  }

  pointsRange = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getPointsRange = await apiCall({
      method: "GET",
      endPoint: `/bx_block_gamification/earned_points/get_earn_points_and_range`,
      header: header
    });
  }
  
  handleSearchClick = (search: string, list:string) => {
    const { filteredData, organisationData } = this.state;
    let searchString = search.startsWith(" ") ? search.trim() : search;

    if (list === "org") {
      let organisationFilteredData = organisationData.filter((item: any) => {
        if (searchString === "") {
          return true;
        } else if (
          item.organisation_name.toString().toLowerCase().includes(searchString.toLowerCase())
        ) {
          return item;
        }
      });
      this.setState({ organisationFilteredData });
    } else {
      let tableData = filteredData.filter((item: any) => {
        if (searchString === "") {
          return true;
        } else if (
          item.description?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
          item.activity_type?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
          item.type?.toString().toLowerCase().includes(searchString.toLowerCase()) ||
          item.points.toString().toLowerCase().includes(searchString.toLowerCase()) ||
          item.date_time.toString().toLowerCase().includes(searchString.toLowerCase())
        ) {
          return item;
        }
      });
      this.setState({ tableData });
    }
  }
  handleBackClick = () => {
    let user = localStorage.getItem("user_type");

    if(user === "user entity") {
      this.props.navigation.navigate("UserProfileBasicBlock")
    } else {
      this.props.navigation.navigate(localStorage.getItem("routePath")?.substring(1))
    }            
  }
  handleAmountTabChange = (event:  React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ amountValue: newValue, userEntered: 0})
  }

  handleRadioChange = (event: React.SyntheticEvent) => {
    const radioButton = event.target as HTMLInputElement;
    this.setState({ radioValue: radioButton.value });
  }

  handleDonationAmount = (event:React.SyntheticEvent) => {
    const userEnter = event.target as HTMLInputElement;
    this.setState({ userEntered: userEnter.value, amountValue: null}) 
  }

  handleDonate = async () => {
    const { radioValue, pointsRange, donateValue, amountValue, userEntered, NpDetais } = this.state;
    if(radioValue === 'points' && donateValue !== null && (pointsRange[donateValue] > 0 || pointsRange[donateValue])) {
      this.setState({ dynamicText: "points", openDonateDialog: true })
        this.setState({ openDonateDialog: true });
        this.donatePointsToOrg(document.location.pathname.split("/")[2]);
    } else if(radioValue === "amount" && ((amountValue !== null && pointsRange[amountValue] > 0) || userEntered) ) {
      if(NpDetais.attributes.status !== "Enabled") {
        toast.warn("Currently not accepting donations!")
      } else {
        this.setState({ 
          openPaymentDialog: true, 
          donatedAmount: Number(userEntered) || (amountValue !== null ? pointsRange[amountValue] : null) 
        })
      }
    } else {
      toast.error("Please select donation amount")
    }
  }

  onChangeState = () => {
    this.setState({
      dynamicText: "amount" ,
      openPaymentDialog: false, 
      openDonateDialog: true, 
      userEntered: 0, 
      amountValue: null
    });
  };

 
  handleBackIconClick = () => {
    if(this.state.orgClicked) {
      if(this.state.id){
        this.props.navigation.goBack();
      } else {
        this.setState({ orgClicked: false });
      }
    } else {
      this.handleBackClick()
    }    
  }

  onGoBackClick = () => {
    if (this.state.id) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.navigate("UserProfileBasicBlock");
    }
  }

  btnShowHideImageProps = {
    source: imgPasswordVisible
  };
  btnShowHideImageProp ={
    source: imgPasswordInVisible
  }
  // Customizable Area End
}
