import React, { useState } from 'react';

export interface CurrentLocation {
  address: string,
  doorName: string,
  area: string,
  city: string,
  country: string,
  postcode: string,
  longitude : any,
  latitude : any
}

export async function getCurrentLocation(): Promise<CurrentLocation> {
  return new Promise((resolve, reject) => {

    const googleapiKey = "AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY";


    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const apiKey = googleapiKey;
          const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

          fetch(geocodeUrl)
            .then((response) => response.json())
            .then((data) => {

              const { results } = data;


              const address = results[0].formatted_address;

              let doorName = '';
              let area = '';
              let city = '';
              let country = '';
              let postcode = '';
              let longitude = position.coords.longitude;
              let latitude = position.coords.latitude;
              let doorNamePart2Added = false;

              for (let result of results) {

                const addressComponents = result.address_components;


                for (let component of addressComponents) {

                  const types = component.types;

                  switch (true) {

                    case types.includes('street_number'):
                      if (!doorName) {
                        doorName = component.long_name;
                        doorNamePart2Added = true;
                      }
                      break;

                    case types.includes('route'):
                      if (!doorNamePart2Added)
                        doorName += ' ' + component.long_name;
                      break;

                    case types.includes('sublocality_level_1'):
                      if (!area)
                        area = component.long_name;
                      break;

                    case types.includes('locality'):
                      if (!city)
                        city = component.long_name;
                      break;

                    case types.includes('country'):
                      if (!country)
                        country = component.long_name;
                      break;

                    case types.includes('postal_code'):
                      if (!postcode)
                        postcode = component.long_name;
                      break;
                  }
                }

              }

              const location: CurrentLocation = {
                address,
                doorName,
                area,
                city,
                country,
                postcode,
                latitude,
                longitude,
              };

              resolve(location);


            })
            .catch((fetchError) => {
              console.error(fetchError);
              reject(fetchError);
            });
        }, (geolocationError) => {
          console.error(geolocationError);
          reject(geolocationError);
        });
    }
  });
}

export function useCurrentLocation(): { currentLocation: CurrentLocation, error: any } {

  const googleapiKey = "AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY";

  const [error, setError] = useState<any>();
  const [locationAcquired, setLocationAcquired] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<CurrentLocation>({
    address: '',
    doorName: '',
    area: '',
    city: '',
    country: '',
    postcode: '',
    latitude: 0,
    longitude: 0,
  });

  if (navigator.geolocation && !locationAcquired) {

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const apiKey = googleapiKey;
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        fetch(geocodeUrl)
          .then((response) => response.json())
          .then((data) => {

            const { results } = data;

            const address = results[0].formatted_address;
            let doorName = '';
            let area = '';
            let city = '';
            let country = '';
            let postcode = '';
            let completeDoorName = false;

            for (let result of results) {

              const addressComponents = result.address_components;

              for (let component of addressComponents) {

                const types = component.types;

                switch (true) {

                  case types.includes('street_number'):
                    if (!doorName) {
                      doorName = component.long_name;
                      completeDoorName = true;
                    }
                    break;

                  case types.includes('route'):
                    if (completeDoorName) {
                      doorName += ' ' + component.long_name;
                      completeDoorName = false;
                    }
                    break;

                  case types.includes('sublocality_level_1'):
                    if (!area)
                      area = component.long_name;
                    break;

                  case types.includes('locality'):
                    if (!city)
                      city = component.long_name;
                    break;

                  case types.includes('country'):
                    if (!country)
                      country = component.long_name;
                    break;

                  case types.includes('postal_code'):
                    if (!postcode)
                      postcode = component.long_name;
                    break;
                }
              }
            }


            const location = {
              address,
              doorName,
              area,
              city,
              country,
              postcode,
              latitude,
              longitude,
            };

            setCurrentLocation(location);
            setLocationAcquired(true);


          })
          .catch((fetchError) => {
            console.error(fetchError);
            if (!error)
              setError(fetchError);
          });
      }, (geolocationError) => {
        console.error(geolocationError);
        if (!error)
          setError(geolocationError);
      });
  }

  return {
    currentLocation,
    error,
  }
}
