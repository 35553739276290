import React from "react";
// Customizable Area Start
import AdvancedSearchController, { Props } from "./AdvancedSearchController";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import MobileSearch from "./MobileSearch";
import {setSelectedFilterEvents} from "../../../framework/src/redux/filterEvents/filterEventsAction"
import { connect } from "react-redux";
import { addSearchFilterDetails } from "../../../framework/src/redux/advanceSearch/advanceSearchActions";

// Customizable Area End

class MobileSearchContainer extends AdvancedSearchController {
		// Customizable Area Start
		// Customizable Area End
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	render() {
		// Customizable Area Start
		// Customizable Area End
		return (
			// Customizable Area Start
			<>
			{/* @ts-ignore */}
				<MobileHeader
					navigatTo={"/"}
					data-testid="mobileSearchMainContainer"
					navigation={this.props.navigation}
					title={"Search Events"}
				/>
				{/* @ts-ignore */}
				<MobileSearch
				 navigation={this.props.navigation} id={""} 
				
				 />
			</>
			// Customizable Area End
		)
	}
}

// Customizable Area Start


  
  
  function mapStateToProps(state: any) {
	console.log(state, "check in mobile");
	return {
	  selectedevents: state.eventsReducer.selectedEvents,
	  searchFilter: state.searchFilter?.filter,
	  filterEventsReducer:state.filterEventsReducer?.selectedEvents
	}
  }
  
  function mapDispatchToProps(dispatch: any) {
	return {
	  addSearchFilterDetails: (selectedOptions: any) => {
		return dispatch(addSearchFilterDetails(selectedOptions))
	  },
	  setSelectedFilterEvents:(selectedEvents: any)=>{
		return dispatch(setSelectedFilterEvents(selectedEvents))
	  }
	}
  }
  
  
  export { MobileSearchContainer }
  export default connect(mapStateToProps, mapDispatchToProps) (MobileSearchContainer)


  // Customizable Area End
