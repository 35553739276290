// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Divider,
  Typography,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogContent
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// @ts-ignore
import classesOuter from '../../../ContentManagement/src/Followers.module.css';
import PaymentOptionController, {
  Props
} from "./PaymentOptionController.web";
import ContentManagement from "../../../ContentManagement/src/ContentManagement.web";
import { icons } from "../../../../components/src/constants";
import { Elements } from '@stripe/react-stripe-js';
// @ts-ignore
import CheckoutForm from "../../../../components/src/custom/CheckOutForm.web";
import { loadStripe } from '@stripe/stripe-js';
const configJSON = require("../../../ServiceSpecificSettingsAdmin/src/config.js");
const baseURL = require("../../../../framework/src/config.js").baseURL;
let stripePubKey = configJSON.STRIPE_KEY_LIVE;
if (!baseURL.includes(".prod")) {
  stripePubKey = configJSON.STRIPE_KEY_TEST;
}
const stripePromise = loadStripe(stripePubKey);
// Customizable Area End

class PaymentOption extends PaymentOptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      // @ts-ignore
      <ContentManagement navigation={this.props.navigation} id={""} children={undefined} showBackButton={undefined} title={"Followers"} showBottomBar={false} showDrawerHeader={false} mobileOpen={false} open={false} classes={undefined} history={undefined} titleColor={undefined} showLine={undefined}>
        <Container maxWidth="xl">
          <Wrapper>
            <Box className={classesOuter.followersNPmainDiv}>
              <Box padding="25px 30px" pb="20px">
                <Box className={classesOuter.followersNPheading}>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      className="back-btn"
                      data-test-id="back-btn"
                      onClick={() => this.props.navigation.goBack()}
                    >
                      <img src={icons.back} />
                    </IconButton>
                    <Typography className="page-title">Subscription payments</Typography>
                  </Box>
                  <Box className="payment">
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        planId={this.state.planId}
                        title={this.state.planTitle}
                        discountAmount={this.state.discountAmount}
                        point={this.state.discountPoint}
                        planType={this.state.planType}
                        days={this.state.days}
                        redirect={() => this.props.navigation.navigate("ContentManagementDashBoard")}
                      />
                    </Elements>
                  </Box>
                </Box>
              </Box>
              <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
            </Box>
          </Wrapper>
          <CustomDialog
            open={false}
            maxWidth={"sm"}
            fullWidth={false}
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogContent>
              <div className="title">
                <div>Remove/Change Points</div>
                <div><HighlightOffIcon className="close-btn" /></div>
              </div>
              <div className="box">
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={7}>
                    <div className="text">Your current chosen <br />points to be deducted</div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div>
                      <div className="points">100 points</div>
                      <div className="money">Equal to £ 10</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <div>
                      <span className="remove-btn">Remove</span>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Box mt="30px" mb="10px">
                <div className="available-points">You have <span>256 points</span></div>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomButton
                    data-test-id="point-btn"
                    disabled={false}
                  >
                    <input type="radio" checked={true} />
                    <span className="amount">£ 10</span>
                    <span className="point"> (100 POINTS)</span>
                  </CustomButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomButton
                    data-test-id="point-btn"
                    disabled={false}
                  >
                    <input type="radio" checked={false} />
                    <span className="amount">£ 20</span>
                    <span className="point"> (200 POINTS)</span>
                  </CustomButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomButton
                    data-test-id="point-btn"
                    disabled={false}
                  >
                    <input type="radio" checked={false} />
                    <span className="amount">£ 30</span>
                    <span className="point"> (300 POINTS)</span>
                  </CustomButton>
                </Grid>
              </Grid>
              <Button className="continue">Continue</Button>
            </DialogContent>
          </CustomDialog>
        </Container>
      </ContentManagement>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withRouter(PaymentOption);
const Wrapper = styled(Box)({
  "& .payment": {
    // margin: "35px",
    display: "flex",
    justifyContent: "center",
    padding: "35px 75px",
    borderRadius: "12px",
    // boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
    "@media(max-width:1092px)": {
      padding: "20px 35px"
    }
  },
  "& .back-btn": {
    padding: "0px",
    marginRight: "12px"
  },
  "& .MuiTypography-root": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold"
  },
  "& .page-title": {
    fontSize: '22px',
  },
  "& .title": {
    fontSize: "20px",
    letterSpacing: "-0.55px"
  },
});

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    boxShadow: "0px 1px 3px rgba(0,0,0,0.2),0px 2px 1px -1px rgba(0,0,0,0.12),0px 1px 1px rgba(0,0,0,0.14)"
  },
  "& .MuiDialogContent-root": {
    minWidth: "500px",
    padding: "30px",
    "@media(max-width:600px)": {
      minWidth: "100%",
    }
  },
  "& .title": {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    marginBottom: "10px",
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    fontSize: "20px",
    "& .close-btn": {
      cursor: "pointer"
    }
  },
  "& .box": {
    boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
    borderRadius: "12px",
    padding: "30px 25px"
  },
  "& .text, .points": {
    fontFamily: "Rene_Bieder_Milliard_Book",
    lineHeight: "27px",
    fontSize: "16px"
  },
  "& .money": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    color: "#87888f",
    lineHeight: "27px",
  },
  "& .remove-btn": {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    color: "#87888f",
    borderBottom: "1px solid #87888f",
    letterSpacing: "-0.375px",
    cursor: "pointer"
  },
  "& .available-points": {
    letterSpacing: "-0.55px",
    marginTop: "13px",
    fontSize: "18px",
    marginBottom: "10px",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    "& span": {
      color: "#4FBCD8"
    }
  },
  "& .continue": {
    padding: "10px",
    color: "#fff",
    borderRadius: "5px",
    backgroundColor: "#009be2",
    width: "100%",
    marginTop: "25px"
  },
});

const CustomButton = styled(Button)({
  justifyContent: "start",
  letterSpacing: "-0.45px",
  width: '100%',
  borderRadius: "12px",
  boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
  "& .Mui-disabled": {
    color: "8788#8f",
    backgroundColor: "#fff",
  },
  "& .amount": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    fontSize: "22px"
  },
  "& .point": {
    marginLeft: "7px",
    marginBottom: "-6px",
    fontSize: "12px",
    color: "#87888f"
  },
});
export { PaymentOption };
// Customizable Area End