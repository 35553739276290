// Customizable Area Start
import React from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
  InputBase,
  Checkbox,
  withStyles
} from "@material-ui/core";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import GooglePlacesAutocomplete,{ geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { styled } from "@material-ui/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { COLORS } from "../../../framework/src/Globals";
import { icons,FAMILYFONTS, constants } from "../../../components/src/constants";
import PublishedNeedController, {
  Props,
} from "./PublishedNeedController.web";
import { addImageIcon } from "./assets";
import "./PublishedNeed.css";
import { configJSON } from "./UserProfileBasicController";
import { PublishNeedModal } from "./PublishNeedModal.web";
import PropTypes from "prop-types";
import { createStyles, Theme } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";

class NeedPublish extends PublishedNeedController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  constructor(props: Props) {
    super(props);
  }

  eventSchema = () => {
    return Yup.object().shape({
      type: Yup.string().required("*Type is required!"),
      titleOfNeed: Yup.string().required("*Title of need is required!"),
      location: Yup.string().required("*Location is required!"),
      describeNeeds: Yup.string().required("*Above field can't be blank!"),
      fromDate: Yup.string().required("*Above date is required!").nullable(),
      toDate: Yup.string().required("*Above date is required!").nullable(),
      startTime: Yup.mixed().when("isAllDay",
        (value: any, schema: any) => {
          if (value === false) {
            return schema.required("*Above time is required!");
          }
        }
      ),
      finishTime: Yup.mixed().when("isAllDay",
        (value: any, schema: any) => {
          if (value === false) {
            return schema.required("*Above time is required!");
          }
        }
      ),
      image: Yup.mixed().required("*Image is required!").nullable()
        .test('format', '*Only jpg, jpeg image allowed!', (value) =>
          (value && ['image/jpg', 'image/jpeg'].includes(value.type)))
    });
  }

  getErrorMessage = (touched: any, errors: any, value: string) => {
    return touched[value] && errors[value] &&
      <div className="text-danger">{errors[value]}</div>
  }

  formatDate = (date: any) => {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    }
  }

  renderDescribeNeedComponent = (setFieldValue: any) => {
    return (
      <CustomInputBase
        data-test-id="description"
        fullWidth
        multiline
        minRows={5}
        placeholder="Provide a general description visible to all users alerted to your need."
        value={this.state.describeNeeds}
        onChange={(event) => {
          const value = event.target.value;
          const total = value.trim().split(" ").length;
          if (total <= 70) {
            setFieldValue("describeNeeds", event.target.value);
            this.setState({
              describeNeeds: value,
              needsWords: value.trim() === "" ? 0 : total
            });
          }
        }}
      />
    );
  }

  renderAdditionalInfoComponent = (setFieldValue: any) => {
    return (
      <CustomInputBase
        data-test-id="additional-info"
        multiline
        fullWidth
        minRows={5}
        placeholder="Include sensitive / personal information;only visible to volunteer confirmed for need fulfilment."
        value={this.state.additionalInformation}
        onChange={(event) => {
          const value = event.target.value;
          const total = value.trim().split(" ").length;
          if (total <= 70) {
            setFieldValue("additionalInformation", value);
            this.setState({
              additionalInformation: value,
              infoWords: value.trim() === "" ? 0 : total
            });
          }
        }}
      />
    );
  }

  renderImagePicker = (setFieldValue: any) => {
    return (
      <input
        data-test-id="image"
        accept="image/jpg, image/jpeg"
        id="changePicture"
        type="file"
        hidden
        onChange={(event) => {
          const file = event.target.files && event.target.files[0];
          if (file) {
            setFieldValue("image", file);
            this.setState({
              image: file,
              selectedImage: file ? URL.createObjectURL(file) : null,
            });
          }
        }}
      />
    );
  }

  renderGoogleLocation(setFieldValue: any) {
    return ( <div style={{ width: '100%', position: 'relative' }}>
    {/* @ts-ignore */}
    <GooglePlacesAutocomplete
      apiKey={configJSON.apiKey}
      debounce={500}
      selectProps={{
        isClearable: true,
        value: this.state.location ? [{ label: this.state.location, value: this.state.location }] : undefined,
        classNamePrefix: "react-select",
        placeholder: "Enter address of need",
        noOptionsMessage: () => null,
        onChange: async (event: any) => {
          const location = event?.value?.description ?? "";
          setFieldValue("location", location);
          this.setState({ location });
          geocodeByAddress(location)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
              this.setState({ latitude: lat, longitude: lng });
            });
          },
        }}
     />
      <img
        src={icons.locationIcon}
        style={{
          height: 23, width: "auto", position: 'absolute', top: 15, right: 10
        }}
      />
        </div >
  )
}

  fromDateColor = () => {
    return this.state.fromDate ? "black" : "#e1d6d6";
  }

  toDateColor = () => {
    return this.state.toDate ? "black" : "#e1d6d6";
  }

  fromDateText = () => {
    return this.state.fromDate ? this.formatDate(this.state.fromDate) : "Select date";
  }

  toDateText = () => {
    return this.state.toDate ? this.formatDate(this.state.toDate) : "Select date";
  }

  allDayText = () => {
    return this.state.isAllDay ? "Timings" : "Start Time";
  }
  // Customizable Area End

  render() {
    const {classes,theme}=this.props
    return (
      // Customizable Area Start
      // @ts-ignore
      <DashboardWeb
        data-test-id="dashboard"
        title={"My needs"}
        showDrawerHeader={isMobile}
        showBackButton={constants._icons.show}
        showBottomBar={true}
        navigation={this.props.navigation}
      >
        <>
          <Box mt={{ xs: "0px", sm: "20px" }} className="published-need">
            <PublishNeedModal id={this.state.id} spacing={undefined} openModal={this.state.isModalOpen} publish={this.state.publish} onSubmit={this.submitNeed} friends={this.state.friends} navigation={undefined} classes={classes} theme={theme} onCloseModal={this.handleModalClose} streetName={""} area={""} town={""} country={""} postCode={""} />
            <Container maxWidth="lg">
            <Box
                style={{
                  backgroundColor: COLORS.white,
                  marginBottom: 30,
                  position: "relative",
                  marginLeft: 20,
                  minHeight: 120,
                }}
                display={{ xs: "none", sm: "flex" }}
              >
                <Typography
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    marginBottom: 20,
                    marginLeft: 20,
                  }}
                >
                  <IconButton
                    data-test-id="back-btn"
                    onClick={() => this.props.navigation.goBack()}
                  >
                    <img src={icons.back} />
                  </IconButton>
                  My Needs
                </Typography>
              </Box>
              <Box mt={{ xs: isMobile ? "0px" : "60px", sm: "0px" }}>
              <Card className="card">
                <CardContent className="card-content">
                  <Formik
                    data-test-id="formik"
                    enableReinitialize={this.state.isEdit}
                    initialValues={{
                      titleOfNeed: this.state.titleOfNeed,
                      type: this.state.type,
                      location: this.state.location,
                      describeNeeds: this.state.describeNeeds,
                      fromDate: this.state.fromDate,
                      toDate: this.state.toDate,
                      additionalInformation: this.state.additionalInformation,
                      isAllDay: this.state.isAllDay,
                      startTime: this.state.startTime,
                      finishTime: this.state.finishTime,
                      image: this.state.image
                    }}
                    validationSchema={this.eventSchema}
                    onSubmit={(values) => {
                      this.setState({
                        isModalOpen: true
                      })
                    }}
                  >
                    {({ errors, touched, setFieldValue, handleSubmit }) => (
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div style={{ position: "relative", minHeight: 49, marginLeft: "3px", marginRight: "3px" }}>
                                <div
                                  style={{ marginTop: 10 }}
                                  className={this.state.dropdownOpen ? "proximitySearchMain" : "hideProximitySearchMain"}>
                                  <div
                                    className="modalInProximitySearchInput"
                                    data-test-id="dropdown-btn"
                                    onClick={() => {
                                      this.setState({ dropdownOpen: !this.state.dropdownOpen });
                                    }}
                                  >
                                    <div style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      paddingLeft: "25px"
                                    }}>
                                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Box fontSize="16px" fontFamily="Rene_Bieder_Milliard_Book">
                                          {TypeList.filter((item) => item.value === this.state.type)[0]?.label ?? <Box color="#cdc9c9">Type</Box>}
                                        </Box>
                                      </div>
                                      <IconButton color="inherit">
                                        <img
                                          className="dbs_tabs_logo"
                                          src={icons.downarrowblue}
                                        />
                                      </IconButton>
                                    </div>
                                  </div>
                                  {this.state.dropdownOpen &&
                                    <div
                                      style={{
                                        position: "relative"
                                      }}>
                                      <div
                                        style={{
                                          width: '100%',
                                          height: 171,
                                          display: "content",
                                          zIndex: 4,
                                          position: "relative",
                                          borderRadius: 12,
                                          padding: "13px 11px"
                                        }}>
                                        <div style={{ height: "144px", overflow: "auto" }}>
                                          {TypeList.map((item: any) => {
                                            return (
                                              <>
                                                <Box display="flex" alignItems="center" style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setFieldValue("type", item.value);
                                                    this.setState({
                                                      type: item.value,
                                                      typeLab: item.label,
                                                      dropdownOpen: false
                                                    });
                                                  }}
                                                >
                                                  <Checkbox
                                                    edge="end"
                                                    color="primary"
                                                    name="allCategoryCheck"
                                                    icon={<div className="unchecked" />}
                                                    checkedIcon={<div className="checked"><div className="blue" /></div>}
                                                    checked={item.value === this.state.type}
                                                  />
                                                  <Box className="text" ml="20px" fontSize="15px" color="black">{item.label}</Box>
                                                </Box>
                                              </>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                              {this.getErrorMessage(touched, errors, "type")}
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <Box ml="8px" mb="8px">
                                  <Typography className="title">Title of need</Typography>
                                </Box>
                                <CustomInputBase
                                  data-test-id="title"
                                  fullWidth
                                  multiline
                                  style={{ padding: "15px 25px" }}
                                  placeholder="Please enter title of need"
                                  value={this.state.titleOfNeed}
                                  onChange={(event) => {
                                    setFieldValue("titleOfNeed", event.target.value);
                                    this.setState({ titleOfNeed: event.target.value });
                                  }}
                                />
                                {this.getErrorMessage(touched, errors, "titleOfNeed")}
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <Box ml="8px" mb="8px">
                                  <Typography className="title">Location</Typography>
                                </Box>
                                {this.renderGoogleLocation(setFieldValue)}
                                {this.getErrorMessage(touched, errors, "location")}
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <Box ml="8px" mb="8px">
                                  <Typography className="title">Describe your need</Typography>
                                </Box>
                                <Box position="relative">
                                  {this.renderDescribeNeedComponent(setFieldValue)}
                                  <Box className="text" position="absolute" bottom="5px" right="10px" color="#bab1b1">{this.state.needsWords}/70 words</Box>
                                </Box>
                                {this.getErrorMessage(touched, errors, "describeNeeds")}
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <Box ml="8px" mb="8px">
                                  <Typography className="title">Additional information</Typography>
                                </Box>
                                <Box position="relative">
                                  {this.renderAdditionalInfoComponent(setFieldValue)}
                                  <Box position="absolute" bottom="5px" right="10px" color="#bab1b1">{this.state.infoWords}/70 words</Box>
                                </Box>
                                {this.getErrorMessage(touched, errors, "additionalInformation")}
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className="title" style={{ marginBottom: "10px" }}>Select date and time when need is required</Typography>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}>
                                <div style={{ width: '100%' }}>
                                    <DateRangePicker
                                      data-test-id="from-date"
                                      selectRange={false}
                                      isOpen={this.state.pickerOpen === "fromDate"}
                                      onCalendarClose={() => this.setState({ pickerOpen: "" })}
                                      onChange={(e) => {
                                        this.setState({ fromDate: e });
                                        setFieldValue("fromDate", e?.toString());
                                        if (this.state.toDate && e !== null && e > this.state.toDate) {
                                          this.setState({ toDate: null });
                                          setFieldValue("toDate", null);
                                        }
                                      }}
                                      value={this.state.fromDate}
                                      minDate={new Date()}
                                    />
                                  <Box ml="8px" mb="8px">
                                    <Typography className="title">From Date</Typography>
                                  </Box>
                                  <Box
                                    data-test-id="from-date-btn"
                                    onClick={() => this.setState({ pickerOpen: "fromDate" })}
                                    style={{
                                      border: "1px solid #D5D5D5",
                                      width: "100%",
                                      backgroundColor: "white",
                                      boxShadow: "none",
                                      borderRadius: 10,
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      display: "flex",
                                      paddingLeft: 25,
                                      paddingRight: 2,
                                      height: "50px",
                                    }}
                                  >
                                    <Box
                                      color={this.fromDateColor()}
                                      style={{ width: '100%', fontSize: 16 }}>
                                      <Typography className="text">{this.fromDateText()}</Typography>
                                    </Box>
                                    <Button>
                                      <img src={icons.calendarblue} alt="calender"
                                        style={{
                                          height: 18,
                                          fill: COLORS.skyBlue
                                        }} />
                                    </Button>
                                  </Box>
                                  {this.getErrorMessage(touched, errors, "fromDate")}
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}>
                                <div style={{ width: '100%' }}>
                                  <DateRangePicker
                                      data-test-id="to-date"
                                      selectRange={false}
                                      isOpen={this.state.pickerOpen === "toDate"}
                                      onCalendarClose={() => this.setState({ pickerOpen: "" })}
                                      onChange={(e) => {
                                        this.setState({ toDate: e });
                                        setFieldValue("toDate", e?.toString());
                                      }}
                                      value={this.state.toDate}
                                      minDate={this.state.fromDate}
                                    />
                                  <Box ml="8px" mb="8px">
                                    <Typography className="title">To Date</Typography>
                                  </Box>
                                  <Box
                                    data-test-id="to-date-btn"
                                    onClick={() => this.setState({ pickerOpen: "toDate" })}
                                    style={{
                                      border: "1px solid #D5D5D5",
                                      width: "100%",
                                      backgroundColor: "white",
                                      boxShadow: "none",
                                      borderRadius: 10,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      paddingLeft: 25,
                                      paddingRight: 2,
                                      height: "50px",
                                    }}
                                  >
                                    <Box
                                      color={this.toDateColor()}
                                      style={{ width: '100%', fontSize: 16 }}>
                                      <Typography className="text">{this.toDateText()}</Typography>
                                    </Box>
                                    <Button>
                                      <img
                                        src={icons.calendarblue}
                                        alt="calender"
                                        style={{
                                          height: 18,
                                          fill: COLORS.skyBlue
                                        }} />
                                    </Button>
                                  </Box>
                                  {this.getErrorMessage(touched, errors, "toDate")}
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <Box mb="8px">
                                <Box ml="8px" mb="4px" display="flex" justifyContent="space-between">
                                  <Box>
                                    <Typography className="title">{this.allDayText()}</Typography>
                                  </Box>
                                  <div className="antSwitch-div">
                                    <Box mr="20px">
                                      <Typography className="title">All Day</Typography>
                                    </Box>
                                    <label className="switch">
                                      <input
                                        data-test-id="all-day"
                                        type="checkbox"
                                        name="isPhoneNumberPrivate"
                                        checked={this.state.isAllDay}
                                        onChange={(event) => {
                                          setFieldValue("isAllDay", event.target.checked);
                                          this.setState({ isAllDay: event.target.checked });
                                        }}
                                      />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </Box>
                                {!this.state.isAllDay &&
                                  <>
                                    <KeyboardTimePicker
                                      data-test-id="start-time"
                                      autoOk
                                      color="primary"
                                      fullWidth
                                      ampm={false}
                                      disableToolbar
                                      variant="inline"
                                      inputVariant="outlined"
                                      className="time-picker"
                                      format="hh:mm A"
                                      placeholder="Select time"
                                      mask="__:__ _M"
                                      value={this.state.startTime}
                                      onChange={(time) => {
                                        setFieldValue("startTime", time);
                                        this.onStartTimeChange(time);
                                      }}
                                      KeyboardButtonProps={{
                                        "aria-label": "Select time",
                                      }}
                                      style={{ fontSize: 12 }}
                                      keyboardIcon={
                                        <img
                                          style={{ height: "auto", width: 20 }}
                                          src={icons.clockBlue}
                                          alt="clock"
                                        />
                                      }
                                      InputProps={{
                                        readOnly: true, // Add readOnly attribute to disable manual input
                                      }}
                                    />
                                    <>
                                      {this.getErrorMessage(touched, errors, "startTime")}
                                    </>
                                  </>
                                }
                              </Box>
                            </Grid>
                            {!this.state.isAllDay &&
                              <Grid item xs={12}>
                                <Box>
                                  <Box ml="8px" mb="8px">
                                    <Typography className="title">Finish Time</Typography>
                                  </Box>
                                  <KeyboardTimePicker
                                    data-test-id="finish-time"
                                    autoOk
                                    color="primary"
                                    fullWidth
                                    ampm={false}
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    className="time-picker"
                                    format="hh:mm A"
                                    placeholder="Select time"
                                    mask="__:__ _M"
                                    value={this.state.finishTime}
                                    onChange={(time) => {
                                      setFieldValue("finishTime", time);
                                      this.onFinishTimeChange(time);
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "Select time",
                                    }}
                                    style={{ fontSize: 12 }}
                                    keyboardIcon={
                                      <img
                                        style={{ height: "auto", width: 20 }}
                                        src={icons.clockBlue}
                                        alt="clock"
                                      />
                                    }
                                    InputProps={{
                                      readOnly: true, // Add readOnly attribute to disable manual input
                                    }}
                                  />
                                  {this.getErrorMessage(touched, errors, "finishTime")}
                                </Box>
                              </Grid>
                            }
                            <Grid item xs={12}>
                              <Box ml="8px" mb="4px" display="flex" justifyContent="space-between">
                                <Box>
                                  <Typography className="title">Flexible on when it can be done</Typography>
                                </Box>
                                <div className="antSwitch-div">
                                  <Box mr="20px">
                                    <Typography className="title">{this.state.isFlexible ? "Yes" : "No"}</Typography>
                                  </Box>
                                  <label className="switch">
                                    <input
                                      data-test-id="flexible"
                                      type="checkbox"
                                      name="isPhoneNumberPrivate"
                                      checked={this.state.isFlexible}
                                      onChange={(event) => {
                                        setFieldValue("isFlexible", event.target.checked);
                                        this.setState({ isFlexible: event.target.checked });
                                      }}
                                    />
                                    <span className="slider round" />
                                  </label>
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box ml="8px" mb="4px" display="flex" justifyContent="space-between">
                                {this.renderImagePicker(setFieldValue)}
                                <Box>
                                  <Typography className="title">Upload photo</Typography>
                                </Box>
                                <label className="text" htmlFor="changePicture" style={{ color: "#009BE2", cursor: 'Pointer' }}>Change</label>
                              </Box>
                              <Box style={{
                                background: "#EFEFEF",
                                height: "180px",
                                borderRadius: 10,
                                backgroundImage: `url(${this.state.selectedImage})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                              }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                {!this.state.image &&
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                  >
                                    <img
                                      src={addImageIcon}
                                      style={{ height: 20 }}
                                      alt="cameraIcon"
                                    />
                                    {this.renderImagePicker(setFieldValue)}
                                  </IconButton>
                                }
                              </Box>
                              {this.getErrorMessage(touched, errors, "image")}
                            </Grid>
                            <Grid item xs={12}>
                              <Box mb={{ xs: "50px", sm: "0px" }}>
                                <PublishNeedButton
                                  fullWidth
                                  type="submit"
                                >
                                  Publish Need
                                </PublishNeedButton>
                              </Box>
                            </Grid>
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </form>
                    )}
                  </Formik>
                </CardContent>
              </Card>
              </Box>
            </Container>
          </Box>
        </>
      </DashboardWeb>
      // Customizable Area End
    );
  }
}

const TypeList = [
  { label: "Domestic", value: "domestic" },
  { label: "Errand", value: "errand" },
  { label: "DIY", value: "diy" },
  { label: "Befriending", value: "befriending" }
];

const CustomInputBase = styled(InputBase)({
  background: "#FFF",
  border: "1px solid #D5D5D5",
  borderRadius: "12px",
  minHeight: "45px",
  padding: "20px 25px",
  fontSize: "16px",
  fontFamily: "Rene_Bieder_Milliard_Book",
  "& .MuiInputBase-inputMultiline": {
    "&::placeholder": {
      fontSize: "16px",
      color: "#898989"
    },
  },
});

const PublishNeedButton = styled(Button)({
  backgroundColor: "#009BE2",
  textTransform: "none",
  color: "white",
  borderRadius: "12px",
  marginTop: "10px",
  height: "50px",
  fontSize: "18px",
  fontFamily: "Rene_Bieder_Milliard_Book",
  "&:hover": {
    color: "white",
    backgroundColor: "#009BE2",
  },
});
NeedPublish.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export const styles = (theme: Theme) =>
  createStyles({
    submitButton: {
      fontSize: '1.1rem',
      background: "#019BE1"
    },
    publishBtn: {
      fontSize: '14px',
      padding: "2px 15px",
      background: "#019BE1"
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: COLORS.black10,
    },
    inputInput: {
      padding: theme.spacing(2, 2, 2, 0),
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      color: COLORS.black10, ...FAMILYFONTS.family3, fontSize: 16, fontWeight: 400
    },
    searchContainer: {
      position: "relative",
      borderRadius: "12px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      height: 54,
      display: "flex",
      border: "1px solid #d5d5d5",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
      },
    },
    checkBtn: {
      padding: "2px 15px",
      border: "1px solid #019BE1",
      borderRadius: "5px",

    },
    checkIcon: {
      height: "20px",
      width: "45px",
      color: "#019BE1"
    },
    usersContainer: {
      height: "200px",
      overflow: "auto"
    },
    publishContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    connectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10
    },
    connectionItem: {
      display: "flex",
      alignItems: "center"
    },
    closeBtnContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }
  });
export default withStyles(styles as any)(NeedPublish as any);
export { NeedPublish }
