import React from "react";
// Customizable Area Start
import ContentManagementController, {
  Props,
} from "./ContentManagementController";
import ContentManagement from "./ContentManagement";
import {
  Container,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  InputBase,
  Button,
  CircularProgress
} from "@material-ui/core";
// @ts-ignore
import classesOuter from './UserNP.module.css'
import { constants, icons } from "../../../components/src/constants";
import {
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import { connect } from 'react-redux';
// @ts-ignore
import { setSelectedRow } from '../../../framework/src/redux/usersNp/usersNpAction';
import { setOfferRoleAction, resetOfferRoleAction } from "../../../framework/src/redux/nonProfitUser/offerARoleActions";

const styles = (theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: "8px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
    inputInput: {
      border: "1px solid rgb(222, 225, 234)",
      borderRadius: "12px",
      padding: "19 16 16 58",
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      fontSize: "revert",
      fontFamily: "Rene_Bieder_Milliard_Book",
      "@media (max-width: 600px)": {
        marginTop: "15",
        padding: "16 65 16 0",
        marginBottom: "10px",
        boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
        paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
        transition: theme.transitions.create("width"),
        fontSize: "revert",
        fontFamily: "Rene_Bieder_Milliard_Light",
        fontWeight: 700,
      },
      "&::placeholder": {
        color: "#000501",
        fontSize: "14px",
        fontWeight: "700",
        fontFamily: "Rene_Bieder_Milliard_Light",
      },
    },

    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      borderRadius: "8px",
      color: "text.primary",
      backgroundColor: "white",
      margin: "8px",
      padding: "8px",
      height: 46,
      width: 46,
    },
    image: {
      width: 128,
      height: 128,
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  });

// Customizable Area End

class UserNP extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  async componentDidMount() {
    this.getAllNPUserData();
    this.setState({ offerAroleLoading: true });
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const np_user_role = localStorage.getItem("np_user_role");
    const isNpAdminUser = np_user_role === "Entity_admin" ? true : false;
    // Customizable Area End
    return (
      // Customizable Area Start
      // @ts-ignore
      <ContentManagement
        showBackButton={constants._icons.hide}
        title="UserNp"
        showBottomBar={constants._icons.show}
        showDrawerHeader={true}
        titleColor={COLORS.black}
        open={false}
        mobileOpen={false}
        navigation={this.props.navigation}
      >
        <Container >
          <Box className={classesOuter.usersNPmainDiv}>
            <Box className={classesOuter.usersNPheadingDiv}>
              <Box className={classesOuter.usersNPheading}>
                <Typography variant="h3">Users (Internal)</Typography>
              </Box>
              <Box>
                <div className={classes.search} style={{ width: "38rem", borderRadius: 12, background: "rgb(255,255,255)" }}>
                  <div className={classes.searchIcon}>
                    <img className={classes._tabs_logo} src={icons.searchSVG} />
                  </div>
                  <InputBase
                    data-testid="searchBar_test"
                    name="search_Bar"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleUserSearch(event)}
                    placeholder="Search..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </Box>
            </Box>
            <Box>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className={classesOuter.usersNPTableHead}>
                    <TableRow>
                      <TableCell>FIRST NAME</TableCell>
                      <TableCell>LAST NAME</TableCell>
                      <TableCell>EMAIL ADDRESS</TableCell>
                      <TableCell>ROLE</TableCell>
                      <TableCell>LAST LOGIN</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classesOuter.usersNPTableCell}>
                    {this.state.offerAroleLoading ? (
                      <TableRow className={classesOuter.dataNotFound}>
                        <TableCell colSpan={6}>
                          <CircularProgress style={{ color: "#009be2" }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {this.state.offerAroleUserFilterList?.length > 0 ?
                          this.state?.offerAroleUserFilterList?.map((item, index) => (
                            <TableDetails
                              item={item}
                              first_name={item.attributes.first_name}
                              last_name={item.attributes.last_name}
                              email={item.attributes.email}
                              role={item.attributes.role}
                              lastLogin={this.getlastUSerSeen(item.attributes.last_login)}
                              handleRow={isNpAdminUser && this.handleRowClick}
                              index={index}
                              handleMessageClick={this.handleMessageClick}
                            />
                          )) : (
                            <TableRow className={classesOuter.dataNotFound}>
                              <TableCell colSpan={6}>Data not found </TableCell>
                            </TableRow>
                          )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          {
            this.getUserType() === "Non profit entity" && isNpAdminUser && (
              <Box className={classesOuter.addNewUserBtn} style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>
                <Button onClick={this.handleNewUser}>Add new user</Button>
              </Box>
            )

          }
        </Container>
      </ContentManagement>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const TableDetails = ({
  first_name,
  last_name,
  email,
  role,
  lastLogin,
  handleRow,
  index,
  item,
  handleMessageClick
}: any) => {
  const currentUser = localStorage.getItem("non_profit_acc_id")

  const handleClick = () => {
    handleRow(item); // Pass the entire item data to the handleRow function
  };

  return (
    <TableRow data-test-id="userData" style={{ border: 0, borderBottom: "none", cursor: "pointer" }} key={index} onClick={handleClick}>
      <TableCell >{first_name || '-'}</TableCell>
      <TableCell >{last_name || '-'}</TableCell>
      <TableCell >{email || '-'}</TableCell>
      <TableCell>{role || '-'}</TableCell>
      <TableCell>{lastLogin || '-'}</TableCell>
      {currentUser !== item?.id && 
        <TableCell style={{ padding: "0px"}}>
        <button 
          style={{ background:"#009be2", border: "0", color:"#fff", padding: "10px", borderRadius: "10px", cursor: "pointer"}}
          onClick={(e) => handleMessageClick(e, item)}
          data-testId="messageBtn"
          >Message</button>
        </TableCell>
      }
    </TableRow>
  )
}
// Customizable Area End

// Customizable Area Start

const mapStateToProps = (state: any) => {
  console.log("rowObj", state.userNp);
  return {
    selectedUserNp: state.userNp,
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedRow: (selectedRow: any) => {
      dispatch(setSelectedRow(selectedRow))
      dispatch(setOfferRoleAction({ ...selectedRow, userId: selectedRow.id }))
    },
    resetOfferRoleAction: () => {
      console.log("I'm Coming")
      return dispatch(resetOfferRoleAction())
    }
  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any, { withTheme: true })(
  UserNP as any
));
export { UserNP };
// Customizable Area End
