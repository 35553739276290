// @ts-nocheck
import React from "react";
// Customizable Area Start
import { Container, Box, TextField, Input, Typography, Grid, Button,Avatar,Checkbox,InputBase,Divider } from "@material-ui/core";
import UserProfileBasicController, {
    Props
  } from "./UserProfileBasicController";
  // @ts-ignore
import classesoutercss from "./UserProfileBasicBlock.module.css";
import {
  constants,
  FONTS,
  icons,
} from "../../../components/src/constants";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { COLORS } from "../../../framework/src/Globals";
import { isMobile } from "react-device-detect";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// @ts-ignore
import classesAffi from './NonProfitsAffiliations.module.css';
import {
    withStyles,
    createStyles,
    ThemeProvider,
    Theme,
  } from "@material-ui/core/styles";
import { backIcon } from './assets';
import { InitiateButton } from '../../../components/src/design-system/button';
import { InitiateCheckbox } from "../../../components/src/design-system/checkbox";
import Loader from "../../../components/src/Loader.web";
import { HideContainer } from "../../../components/src/shared/hide-container";
import { InitiateSpinner } from "../../../components/src/design-system/spinner";


export const styles = (theme: Theme) =>
    createStyles({
      search: {
        position: "relative",
        borderRadius: "8px",
        backgroundColor: COLORS.white,
        marginLeft: 0,
        [theme.breakpoints.up("sm")]: {
          marginLeft: theme.spacing(0),
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: COLORS.skyBlue,
      },
      inputRoot: {
        color: "inherit",
        width: "100%"
      },
      _tabs_logo: {
        height: "20px",
        width: "20px",
        left: "33px",
        top: "788px",
        borderRadius: " 0px",
      },
      inputInput: {
        margin: "8",
        padding: "5",
        paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
        transition: theme.transitions.create("width"),
        fontSize: "revert",
        fontFamily: "Rene_Bieder_Milliard_Light",
        fontWeight: 700,
        "@media (max-width: 600px)": {
          margin: "8",
          padding: "5",
          paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
          transition: theme.transitions.create("width"),
          fontSize: "revert",
          fontFamily: "Rene_Bieder_Milliard_Light",
          fontWeight: 700,
        },
        "&::placeholder": {
          color: "#000501",
          fontSize: "16px",
          fontWeight: "700",
          fontFamily: "Rene_Bieder_Milliard_Light",
        },
        height:'25px !important'
      },
      container: {
        margin: '1px 0',
        borderBottom: '1px solid #ececec',
        padding: '10px 10px',
      },
      formLabel: {
        margin: '0',
      },
      icon: {
        width: '50px',
        height: '50px',
        minWidth: '50px',
        minHeight: '50px',
        objectFit: 'cover',
        borderRadius: '50%',
        margin: '0 10px',
      },
      textContainer: {
        '& p:first-child': {
          fontFamily: 'Rene_Bieder_Milliard_Book',
          fontWeight: 700,
          fontSize: '1rem',
        },
        '& p:last-child': {
          fontFamily: 'Rene_Bieder_Milliard_Book',
          fontWeight: 700,
          color: '#a4a3a4',
          fontSize: '0.8rem',
        },
      },
      root: {
        display: 'flex',
        alignItems: 'center',
      },
    });
// Customizable Area End


class NonProfitsAffiliations extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLoader = () => {
    return (
      <div style={{ marginBottom: "30%" }}>
        <InitiateSpinner />
      </div>
    )
  }

  renderProfileType = (type:string) => {
    return type === "nonProfit"? "Non-Profit Affiliations" : "DBS"
  }
  showDbsNumber=(dbsNumber:string|null)=>{
    return(dbsNumber==="null"||dbsNumber==="undefined")?"":dbsNumber;
  }
  handleBtn = (condition:any) => {
    return condition < 1 ? "" : "#009BE2"
  }

  handleImageSrc = (item:any) => {
    return item.logo.url !== null ? item.logo.url : item?.organisation_name
  }
  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    return (
      //Required for all blocks
      // Customizable Area Start
      <div className={classesAffi.nonProfitAffiMainBox} style={{marginBottom:'-20px'}}>
      <div className={classesAffi.backIcon} >
        <img style={{ cursor: 'pointer' }} onClick={() => this.props.backButton()} src={backIcon} alt="arrowIcon" />
        <Typography>{this.renderProfileType(this.props.profileType)} </Typography>
      </div>
      {
        this.props.profileType === "nonProfit" ?
      <div className={classesAffi.mainNonProfitsBox} style={{
      overflow: 'hidden',  // Hide overflow to start
      height: this.state.openNonProfits ? 'auto' : '88%',  
      transition: 'max-height 0.5s ease-in-out',   }}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <img className={classes._tabs_logo} src={icons.search} />
          </div>
          <InputBase
            className={classes.inputSearch}
            data-testId="searchOrg"
            onChange={(e) => {
              this.handleSearchOrganization(e.target.value)
            }}
            placeholder="Search organizations"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <Box className={classesAffi.currentlyRegisteredAffi}>
          {
            this.state.orgError ?
            <Typography style={{ ...FONTS.paragraph, color: 'red' }}>Select up to 5 organizations where you are currently a registered member or volunteer</Typography>
            :
            <Typography style={{ ...FONTS.paragraph }}>Select up to 5 organizations where you are currently a registered member or volunteer</Typography>
          }
        </Box>
              {this.state.npoLoader ?
                this.renderLoader()
                :
                <div className={classesAffi.nonprofitalignment} style={{ height: '330px', overflowY: 'scroll', padding: '0px', overflowX: 'hidden' }}>
                  {this.state.nonProfitAffiliationFilterData.length > 0 ?
            this.state.nonProfitAffiliationFilterData.map((item: any) =>
              <div className={classes.container}>
                <InitiateCheckbox
                  formControlLabelProps={{
                    className: classes.formLabel,
                  }}
                  checked={this.state.selesctedCheckBox?.includes(item.id)}
                  className={`InitiateCheckbox-${item.id}`}
                  onChange={(e) => this.handleNonProfits(e, item.id)}
                  name={item.organisation_name}
                  label={
                    <div className={classes.root}>
                      <Avatar className={classes.icon} alt={item.organisation_name} src={this.handleImageSrc(item)} />
                      <div className={classes.textContainer}>
                        <Typography>
                          {item.organisation_name}
                        </Typography>
                        <Typography>
                          {item.location},{item.post_code}
                        </Typography>
                      </div>
                    </div>
                  }
                />
              </div>
            ) : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90%" }}> No NPO matching! </div>}
                </div>
              }
        <div className={classesAffi.npMob} >
        <InitiateButton
          fullWidth
          style={{backgroundColor: this.handleBtn(this.state.nonProfitAffiliationFilterData.length) , color: 'white',marginTop:'10px' }}
          variant="contained"
          disabled={this.state.nonProfitAffiliationFilterData.length < 1}
          size="large"
          data-testId='saveBtn'
          onClick={() => { 
            this.saveNonProfitData(this.state.selesctedCheckBox)
            setTimeout(() =>{
              this.props.backButton()
            },1000)
          }}
          >
          {'Save'}
          </InitiateButton>
        </div>
      </div>
      :
      <div className={classesAffi.mainDbsBox} style={{paddingLeft:'25px'}}>
        <div>
            <input type="text" className={classesoutercss.editInputField} data-test-id='dbs_number' name="dbs_number"
             value={this.showDbsNumber(this.state.profileUserData?.attributes?.dbs_number)}
              onChange={(event) => this.handleChangeUpload(event)} />
            <label style={{float:'right'}}>(Optional)</label>   
        </div>
        <div>
          <br/>
        <label style={{ ...FONTS.dbsForm }}>Select DBS Clearence Type</label>
        <br/>
        <div className={classesAffi.dbsTypesContainer} style={{height:'250px'}}>
        <HideContainer hideWhen={this.state.dbsData.length > 0}>
              <InitiateSpinner />
        </HideContainer>
            {
              this.state.dbsData?.map((item) =>
                <InitiateCheckbox
                  key={item.id}
                   checked={this.state.dbsUserData?.includes(item.id)}
                  onChange={(event) => {
                    const id = item.id;
                    const newValue = event.target.checked;
                  
                    if (newValue) {
                      this.setState((prevState) => ({
                        dbsUserData: [id],
                      }));
                    } else {
                      this.setState((prevState) => ({
                        dbsUserData: prevState.dbsUserData.filter((_id) => _id !== id),
                      }));
                    }
                  }}
                  name={item.attributes.dbs_type_name}
                  label={item.attributes.dbs_type_name}
                />
              )
            }
            </div>
          </div>
          <div style={{marginTop:'10px'}}>
            <InitiateButton
            fullWidth
            style={{backgroundColor: "#009BE2", color: 'white', marginTop:'3%'}}
            variant="contained"
            size="large"
            data-testId='saveBtn'
            onClick={() => { 
              this.handleUpdateDBSDetails()
              setTimeout(() =>{
                this.props.backButton()
              },1000)
            }}
            >
            {'Save'}
            </InitiateButton>
          </div>
      </div>
      }
    </div>
     // Customizable Area End
    );
  }
  
  // Customizable Area Start
  // Customizable Area End
  
}
// Customizable Area Start
export {NonProfitsAffiliations}
export default withStyles(styles as any, { withTheme: true })(
    NonProfitsAffiliations as any
);
// Customizable Area End