import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface ConnectionData {
  id: string;
  type: string;
  attributes: ConnectionDataInner;
}

export interface ConnectionDataInner {
  account_id: number;
  receipient_id: number;
  name: string;
  profile_picture: ProfilePic
}

export interface ProfilePic {
  url: string
}

interface CardDataInner {
  account_id: number;
  non_profit_event_id: any;
  need_id: any;
  non_profit_event: any;
  need: any;
}

interface CardData {
  id: string;
  type: string;
  attributes: CardDataInner;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedPledgesTabChange: string;
  cardData: CardData[];
  pledgeModal: boolean;
  deletePledgeId: number;
  selectedId: string;
  connectionData: ConnectionData[];
  loading: boolean;
  eventType: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  activePledgeTabApiCallId: string = '';
  pastPledgeTabApiCallId: string = '';
  addFavouriteAPICallID: string = '';
  removeFavouriteAPICallID: string = '';
  connectionDataAPICall: string = '';
  cancelPledgeAPICallID: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPledgesTabChange: "Active",
      cardData: [],
      pledgeModal: false,
      deletePledgeId: 0,
      selectedId: '',
      connectionData: [],
      loading: false,
      eventType: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.error) {
          toast.error(`${responseJson.error}`)
        } else if (responseJson.errors?.length > 0) {
          toast.error(`${responseJson.errors[0].token}`)
        } else {
          this.checkAPIsResponses(apiRequestCallId, responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.connectionData();
    this.ActiveNeedsTabData();
  }
  checkAPIsResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.pastPledgeTabApiCallId:
        this.setState({ cardData: responseJson.data, loading: false })
        break;

      case this.activePledgeTabApiCallId:
        this.setState({ cardData: responseJson.data, loading: false })
        break;

      case this.addFavouriteAPICallID:
        toast.success(responseJson.message ? responseJson.message : "Added to favourites!");
        if (this.state.selectedPledgesTabChange === "Active") {
          this.ActiveNeedsTabData(true)
        } else {
          this.PastNeedsTabData(true)
        }
        break;

      case this.removeFavouriteAPICallID:
        toast.success("Removed from favourites!");
        if (this.state.selectedPledgesTabChange === "Active") {
          this.ActiveNeedsTabData(true)
        } else {
          this.PastNeedsTabData(true)
        }
        break;

      case this.connectionDataAPICall:
        this.setState({ connectionData: responseJson.data })
        break;

      case this.cancelPledgeAPICallID:
        this.setState({ pledgeModal: false })
        this.ActiveNeedsTabData()
        toast.success("Pledge cancelled successfully!")
        break;
    }
  }

  handleCardClick = (id: any, event_type: string) => {
    if(event_type === "activity") {
      this.props.navigation.navigate("EventInfo", { id})
    } else {
      this.props.navigation.navigate("EventsDetails", { id })
    }
  };

  PastNeedsTabData = (isFavoriteApi?: boolean) => {
    this.setState({ loading: isFavoriteApi ? false : true });
    const token = localStorage.getItem('token');
    const account_id = localStorage.getItem('acc_id');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
      "type": "user_entity"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pastPledgeTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pastPledgeApiEndpoint}?account_id=${account_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ActiveNeedsTabData = (isFavoriteApi?: boolean) => {
    this.setState({ loading: isFavoriteApi ? false : true });
    const loginToken = localStorage.getItem('token');
    const account_id = localStorage.getItem('acc_id')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: loginToken,
      "type": "user_entity"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.activePledgeTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.activePledgeApiEndpoint}?account_id=${account_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addFavourite = (id: number, type: string) => {
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const objToPass = {
      "data": {
        "favouriteable_id": id,
        "favouriteable_type": type === "R1" ? "BxBlockEvents::Need" : "BxBlockEvents::NonProfitEvent"

      }, "token": token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFavouriteAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(objToPass)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeFavourite = (id: number, favourite_id: number) => {
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const objToPass = {
      "token": token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFavouriteAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouriteApiEndpoint}/${favourite_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(objToPass)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  connectionData = () => {
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectionDataAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getConnectionApiEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.getAPIMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  cancelPledge = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelPledgeAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/my_pledges/${this.state.deletePledgeId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
