// Customizable Area Start
import React, { useEffect, useRef, } from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  format,
  addDays,
  differenceInDays,
} from "date-fns";
import { formatDate } from './HoursLines';

const DATE_WIDTH = 50;
const today = new Date();

const useDayLineStyles = makeStyles((theme) => ({
  root: {
    margin: '10px 0',
    maxWidth: '100%',
    overflow: 'auto',
    display: 'flex',
  },
  dayContainer: {
    width: `${DATE_WIDTH}px`,
    minWidth: `${DATE_WIDTH}px`,
    maxWidth: `${DATE_WIDTH}px`,
    padding: '0 5px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  dayNumber: {
    fontFamily: 'Rene_Bieder_Milliard_Medium',
    color: '#858585',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
  },
  dayNumberSelected: {
    background: theme.palette.primary.main,
    borderRadius:14,
    padding:"2px",
    width: "50%",
    fontFamily: 'Rene_Bieder_Milliard_Medium',
    color: 'white',
    fontSize: '0.8rem',
  },
  day: {
    fontFamily: 'Rene_Bieder_Milliard_Medium',
    color: '#858585',
    fontSize: '0.8rem',
  },
  selectedDay: {
    color: 'black',
  },
}));

export function DaysLine({ selectedDate, onChange, }: { selectedDate: any, onChange: (value: any) => void }) {

  const classes = useDayLineStyles();
  const parentRef = useRef<any>(null);
  const changedByMe = useRef<boolean>(false);

  useEffect(() => {
   
    if (parentRef && parentRef.current) {
      const diffInDays = differenceInDays(selectedDate, today) + 1;
  
      // only move the parent scroll if the 
      // change was not made by this component
      // (it was made by some parent or other component)
      if (!changedByMe.current) {
        parentRef.current.scrollLeft = Math.abs(DATE_WIDTH * diffInDays);
        
      }
     
      changedByMe.current = false;
    }
  }, [selectedDate]);


  return (
    <div 
      ref={parentRef}
      className={classes.root}
    >
      {
        new Array(365).fill(0).map((_x, index) => {

          const date = addDays(today, index - 1);
          const isSelected = formatDate(date, '/').toLowerCase() === formatDate(selectedDate, '/').toLowerCase();
         localStorage.setItem("selected_date",formatDate(selectedDate, '/').toLowerCase());
          return (
            <div
             style={{marginBottom:6}}
              title={`day-date-${format(date, 'dd-MM-yyyy')}`}
              onClick={() => {
                onChange(date)
                changedByMe.current = true;
                localStorage.setItem("user_selected_date","false");
                localStorage.setItem("all_day","false");
                localStorage.setItem('set_button','false')
              }}
              className={classes.dayContainer}
              key={index}>
              <span 
                className={clsx(classes.dayNumber, { [classes.selectedDay]: isSelected })}>
                {format(date,'E')}
              </span>
              <span
                style={{marginLeft:5,color:isSelected?'white':'#858585'}}
                className={clsx(classes.day, { [classes.dayNumberSelected]: isSelected })} >
                {format(date, 'dd')}
              </span>
            </div>
          );
        })
      }
    </div>
  );
}
// Customizable Area End

