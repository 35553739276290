import React from "react";
// Customizable Area Start
import AdminRegistrationController, { Props } from "./AdminRegistrationController";
//@ts-ignore
import classOuter from './NpForgotPassword.module.css'
import { FAMILYFONTS, images } from "../../../components/src/constants";
import {
  Typography,
  Button,
  Container,
  Box,
  Input
} from "@material-ui/core";
// Customizable Area End

class NpForgotPassword extends AdminRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    // Send code button validation
    const validateInput = !this.state.forgotPwdEmail;
    const emailErrorValidation = this.state.emailError;
    let disabled = validateInput || emailErrorValidation;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className={classOuter.mainContainer}>
        {/* App logo */}
        <div className={classOuter.formContainer}>
          <div className={classOuter.appLogoContainer}>
            <img src={images.app_logo} className={classOuter.appLogo} />
          </div>

          {/* Forgot Password Form */}
          <div>
            {/* Form Title */}
            <Box>
              <Typography 
              className={classOuter.titleText} 
              style={{
                ...FAMILYFONTS.family1,
                fontSize: 24,
                fontWeight: 400
              }}>Forgot Password</Typography>
            </Box>

            {/* Form Content */}
            <Box sx={{ width: "100%" }}>

              {/* Email Input Field */}
              <Box>
                <Typography
                  className={classOuter.inputFieldText}
                  style={{
                    ...FAMILYFONTS.family3,
                    fontSize: 16,
                    fontWeight: 400
                  }}
                >
                  Please enter your registered email address to receive a verification code
                </Typography>
                <Input
                  data-test-id="txtInputEmail"
                  name="email"
                  placeholder={"Enter email address"}
                  className={classOuter.inputField}
                  fullWidth={true}
                  //@ts-ignore
                  name="email"
                  disableUnderline={true}
                  value={this.state.forgotPwdEmail}
                  classes={{
                    root: classOuter.innerInputText,
                  }}
                  onChange={(e) => {
                    this.setForgotPwdEmail(e.target.value);
                    this.validateEmail(e.target.value);
                  }}
                />

                {/* Email validation error */}
                <Box className={classOuter.errorMsg}>
                  <div className={classOuter.errors}>{this.state.emailError}</div>
                </Box>
              </Box>

              {/* Send code Button */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <Button
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  color="primary"
                  className={classOuter.footerBtn}
                  fullWidth
                  style={{
                    ...FAMILYFONTS.family2,
                    fontSize: 20,
                    fontWeight: 400,
                    opacity: disabled ? "0.5" : "1",
                    cursor: disabled ? "not-allowed" : "pointer",
                    textTransform: "initial",
                  }}
                  disabled={!this.state.forgotPwdEmail}
                  onClick={() => this.gotoSendCode()}
                >
                  Send code
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      // Customizable Area End
    );
  }
}

export default NpForgotPassword;