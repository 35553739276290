// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Divider,
  Typography,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';
import Carousel from 'react-elastic-carousel'
import CheckIcon from '@material-ui/icons/Check';
// @ts-ignore
import classesOuter from '../../../ContentManagement/src/Followers.module.css';
import SubscriptionPlanController, {
  Props, IPlan
} from "./SubscriptionPlanController.web";
import ContentManagement from "../../../ContentManagement/src/ContentManagement.web";
import { SubscriptionImage } from "../assets";
import { icons } from "../../../../components/src/constants";
// Customizable Area End

class SubscriptionPlan extends SubscriptionPlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPagination = ({ pages, activePage, onClick }: any) => {
    return (
      <Box display="flex" alignItems="center" mt="10px">
        {pages.map((page: any) => {
          const isActivePage = activePage === page;
          return isActivePage ?
            <div className="active" onClick={() => onClick(page)}><div /></div>
            : <div className="in-active" onClick={() => onClick(page)} />
        })}
      </Box>
    )
  }

  renderCardSkeleton = () => {
    const card = (
      <div className="card-wrapper">
        <div className="card">
          <div className="top">
            <Skeleton height={30} animation="wave" />
            <Skeleton height={30} width={50} animation="wave" />
            <Skeleton height={30} width={90} animation="wave" />
          </div>
          <div className="bottom">
            <Skeleton height={30} animation="wave" />
            <Skeleton height={30} width={150} animation="wave" />
            <Skeleton height={30} width={210} animation="wave" />
            <Skeleton height={30} width={75} animation="wave" />
          </div>
        </div>
      </div>
    );
    return [...Array(3)].map((_) => (card));
  }

  
  renderButtonSkeleton = () => {
    const buttons = (
      <Grid item xs={6} md={4}>
        <Skeleton height={90} animation="wave" />
      </Grid>
    );
    return [...Array(3)].map((_) => (buttons));
  }

  renderCardDetail = () => {
    return this.state.planData.map((plan: IPlan, index: number) => (
      <div className={`card-wrapper ${this.state.selectedPlanId === plan.id && "selected"}`} key={`${index + 1}`}>
        <div
          data-test-id="card"
          className={`card ${!plan.isExpired && !plan.isTrialPurchased && "active-card"}`}
          onClick={() => {
            if (!plan.isCurrent && !plan.isExpired && !plan.isTrialPurchased) {
              const isVisible = plan.amount !== 0;
              this.setState({ isVisible, days: plan.days, planTitle: plan.title, planAmount: plan.amount, selectedPlanId: plan.id, pointsData: plan.pointsData, buttonId: -1 });
            }
          }}
        >
          <div className="top">
            <Typography className="plan-title">{plan.title}</Typography>
            <Typography className="charge">£ {plan.amount}</Typography>
            <Typography className="time">{plan.duration}</Typography>
          </div>
          <div className="bottom">
            {plan.descriptions.map((description: string, index2: number) => (
              <Box display="flex" mb="15px" key={`${index2 + 1}`}>
                <CheckIcon className="check" />
                <Typography className="description">{description}</Typography>
              </Box>
            ))}
            {plan.notes.map((note: string, index3: number) => (
              <Box display="flex" mb="15px" key={`${index3 + 1}`}>
                <span className="star">*</span>
                <Typography className="notes">{note}</Typography>
              </Box>
            ))}
          </div>
          <Box pb="45px">
            <div className="selected-btn select-btn">
              <div>Selected</div>
            </div>
            {plan.isCurrent &&
              <div className="selected-btn current-plan">
                <div>Current Plan</div>
              </div>
            }
          </Box>
        </div>
      </div>
    ))
  }

  onBackClick = () => {
    if (this.props.location.state?.isAvailable) {
      this.props.history.push({ pathname: localStorage.getItem("routePath") ?? "/ContentManagementDashBoard" });
    } else {
      this.props.navigation.goBack();
    }
  }

  onPointButtonClick = (item: any) => {
    if (this.state.buttonId === item.id) {
      this.setState({ buttonId: -1, discountAmount: 0, discountPoints: 0 });
    } else {
      this.setState({ buttonId: item.id, discountAmount: item.amount, discountPoints: item.point });
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    let amount = this.state.planAmount - this.state.remainingAmount;
    let payable = amount - this.state.discountAmount;
    if (payable < 0) {
      payable = 0;
    }
    if (amount < 0) {
      amount = 0;
    }
    return (
      // @ts-ignore
      <ContentManagement navigation={this.props.navigation} id={""} children={undefined} showBackButton={undefined} title={"Followers"} showBottomBar={false} showDrawerHeader={false} mobileOpen={false} open={false} classes={undefined} history={undefined} titleColor={undefined} showLine={undefined}>
        <Container maxWidth="xl" style={{ paddingBottom: "30px" }}>
          <Wrapper>
            <Box mb="50px" className={classesOuter.followersNPmainDiv}>
              <Box padding="25px 30px">
                <Box className={classesOuter.followersNPheading}>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      className="back-btn"
                      data-test-id="back-btn"
                      onClick={() => this.onBackClick()}
                    >
                      <img src={icons.back} />
                    </IconButton>
                    <Typography className="page-title">Non-profit organisation subscriptions</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt="25px">
                    <img className="round" src={SubscriptionImage} />
                    <Box>
                      <Typography className="title">Subscription</Typography>
                      <Typography className="description">{this.props.location.state.description ?? "To keep using this account after the trial ends, set your subscription now."}</Typography>
                    </Box>
                  </Box>
                  <Box mt="30px">
                    <Typography className="select-plan">{this.props.location.state.text ?? "Select"} Plan</Typography>
                    <Box className="card-container">
                      <Carousel
                        itemsToShow={3}
                        isRTL={false}
                        showEmptySlots
                        breakPoints={breakPoints}
                        renderPagination={this.renderPagination}
                      >
                        {this.state.loading ?
                          this.renderCardSkeleton()
                          : this.renderCardDetail()
                        }
                      </Carousel>
                    </Box>
                  </Box>
                  {this.state.isVisible && <div className="discount-section">
                    <Typography className="title">Get discount with your hard-earned points</Typography>
                    <Typography className="points">You have <span>{this.state.totalPoint} points</span></Typography>
                    <Grid container spacing={2}>
                      {this.state.loading ?
                        this.renderButtonSkeleton()
                        :
                        <>
                          {this.state.pointsData.map((item: any, index4: number) => {
                            return (
                              <Grid item xs={6} md={4} key={`${index4 + 1}`}>
                                <CustomButton
                                  data-test-id="point-btn"
                                  disabled={item.isDisabled}
                                  onClick={() => this.onPointButtonClick(item)}>
                                  <input type="radio" checked={this.state.buttonId === item.id} />
                                  <span className="amount">£ {item.amount}</span>
                                  <span className="point"> ({item.point} POINTS)</span>
                                </CustomButton>
                              </Grid>
                            );
                          })}
                        </>
                      }
                    </Grid>
                  </div>
                  }
                  {this.state.selectedPlanId !== "-1" &&
                    <Box className="checkout">
                      <div>
                        <div className="sub-title">Subscription Details</div>
                        <Box display="flex" justifyContent="space-between" mb="10px">
                          <div className="sub-charges">{this.state.planTitle}</div>
                          <div className="plan-amount">£ {this.state.planAmount.toFixed(2)}</div>
                        </Box>
                        {!!this.state.remainingAmount &&
                          <Box display="flex" justifyContent="space-between" mb="10px">
                            <div className="sub-charges">- Remaining balance</div>
                            <div className="discount">- £ {this.state.remainingAmount}</div>
                          </Box>
                        }
                        {this.state.buttonId !== -1 &&
                          <>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <div className="sub-charges">
                                - Redeemed Points
                                <span
                                  data-test-id="remove-btn"
                                  onClick={() => this.setState({ buttonId: -1, discountAmount: 0, discountPoints: 0 })}
                                >
                                  Remove
                                </span>
                              </div>
                              <div className="discount">- £ {this.state.discountAmount > amount ? amount.toFixed(2) : this.state.discountAmount.toFixed(2)}</div>
                            </Box>
                            <Box className="sub-text">You have used {this.state.discountPoints} points out of {this.state.totalPoint} points</Box>
                          </>
                        }
                        <div className="border" />
                        <Box display="flex" justifyContent="space-between">
                          <div className="total">Total</div>
                          <div className="plan-amount">£ {payable.toFixed(2)}</div>
                        </Box>
                      </div>
                    </Box>
                  }
                  <Box mt="40px" mb="15px" display="flex" justifyContent="center">
                    <Button className="continue" disabled={this.state.loading || this.state.selectedPlanId === "-1"} onClick={this.onContinue} data-test-id="continue">
                      Pay £ {payable.toFixed(2)}
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
            </Box>
          </Wrapper>
        </Container>
      </ContentManagement>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withRouter(SubscriptionPlan);

const Wrapper = styled(Box)({
  "& .checkout": {
    boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
    padding: "30px 45px",
    borderRadius: "12px",
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "& .sub-title": {
      fontSize: "20px",
      fontFamily: "Rene_Bieder_Milliard_Bold",
      letterSpacing: "-0.7px",
      marginBottom: "15px"
    },
    "& .discount": {
      fontSize: "18px",
      fontFamily: "Rene_Bieder_Milliard_Medium",
      letterSpacing: "-0.45px",
    },
    "& .plan-amount": {
      fontSize: "20px",
      fontFamily: "Rene_Bieder_Milliard_Bold",
      letterSpacing: "-0.5px",
    },
    "& .sub-charges": {
      fontSize: "18px",
      fontFamily: "Rene_Bieder_Milliard_Medium",
      letterSpacing: "-0.45px",
      " & span": {
        color: "#87888f",
        cursor: "pointer",
        borderBottom: "1px solid #87888f",
        marginLeft: "10px"
      }
    },
    "& .sub-text": {
      fontFamily: "Rene_Bieder_Milliard_Medium",
      color: "#87888f",
      letterSpacing: "-0.35px",
      fontSize: "14px"
    },
    "& .border": {
      margin: "10px 0px",
      border: "1px solid #bebebe"
    },
    "& .total": {
      fontSize: "18px",
      fontFamily: "Rene_Bieder_Milliard_Medium",
      letterSpacing: "-0.45px",
    }
  },
  "& .back-btn": {
    padding: "0px",
    marginRight: "12px"
  },
  "& .MuiTypography-root": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold"
  },
  "& .page-title": {
    fontSize: '22px',
  },
  "& .title": {
    fontSize: "20px",
    letterSpacing: "-0.55px",
    marginBottom: "8px"
  },
  "& .description": {
    fontSize: "14px",
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    color: "rgb(58 54 54 / 59%)",
    letterSpacing: "-0.39999998px"
  },
  "& .round": {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    marginRight: "20px"
  },
  "& .select-plan": {
    fontSize: "20px",
    letterSpacing: "-0.55px",
    marginBottom: "7px"
  },
  "& .card-container": {
    gap: "20px",
    display: "flex",
    overflow: "auto",
    padding: "0px 4px",
    "& .card-wrapper": {
      padding: "30px 0px",
      paddingBottom: "35px",
      width: "100%",
      margin: "0px 10px",
      "&.selected": {
        "& .active-card": {
          border: "1px solid #009be2",
        },
        "& .selected-btn": {
          display: "block",
          transform: "scale(1, 1.09)",
          "&.select-btn": {
            fontFamily: "Rene_Bieder_Milliard_SemiBold"
          }
        },
      },
      "&:hover, &.selected": {
        transform: "scale(1, 1.09)",
        "& .selected-btn": {
          transform: "scale(1, 1.09)",
        },
        "& .check, .description, .time, .charge, .plan-title, .notes, .selected-btn div": {
          transform: "scale(1, 0.9)"
        },
      }
    },
    "& .card": {
      position: "relative",
      height: "100%",
      transition: "all 0.3s ease",
      borderRadius: "12px",
      boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
      "& .top": {
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        padding: "14px 19px",
        backgroundColor: "#E9E9E9",
        "& .plan-title": {
          fontFamily: "Rene_Bieder_Milliard_Bold",
          marginBottom: "10px",
          fontSize: "20px",
          color: "#787878",
          letterSpacing: "0.13636364px"
        },
        "& .charge": {
          fontFamily: "Rene_Bieder_Milliard_SemiBold",
          fontSize: "18px",
          color: "#787878"
        },
        "& .time": {
          fontFamily: "Rene_Bieder_Milliard_Book",
          fontSize: "12px",
          color: "#949494"
        }
      },
      "& .bottom": {
        padding: "20px",
        paddingRight: "34px",
        "& .check": {
          marginRight: "5px",
          height: "20px",
          color: "#C8C8C8"
        },
        "& .description": {
          lineHeight: "17px",
          fontSize: "14px",
          fontFamily: "Rene_Bieder_Milliard_Book",
          color: "#9A9A9A",
          letterSpacing: "-0.35px"
        },
        "& .notes": {
          fontFamily: "Rene_Bieder_Milliard_Book",
          color: "#87888f",
          fontSize: "10px",
        },
        "& .star": {
          color: "#009be2",
          margin: "0px 12px"
        }
      },
      "& .selected-btn": {
        display: "none",
        position: "absolute",
        left: "0px",
        bottom: "0px",
        width: "100%",
        paddingBottom: "45px",
        "&.current-plan": {
          display: "block"
        },
        "& div": {
          backgroundColor: "#b0e6ff",
          position: "absolute",
          bottom: "0px",
          width: "100%",
          height: "45px",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }
      }
    },
  },
  "& .active": {
    margin: "0px 3px",
    border: "1px solid black",
    padding: "2px",
    borderRadius: "50%",
    cursor: "pointer",
    "& div": {
      backgroundColor: "#525252",
      width: "14px",
      height: "14px",
      borderRadius: "50%",
    }
  },
  "& .in-active": {
    cursor: "pointer",
    margin: "0px 3px",
    backgroundColor: "#B9B9B9",
    width: "14px",
    height: "14px",
    borderRadius: "50%",
  },
  "& .discount-section": {
    marginTop: "70px",
    borderRadius: "12px",
    boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
    padding: "30px 45px",
    paddingBottom: "45px",
    "& .title": {
      fontFamily: "Rene_Bieder_Milliard_Bold",
      letterSpacing: "-0.7px"
    },
    "& .points": {
      letterSpacing: "-0.55px",
      marginTop: "13px",
      marginBottom: "20px",
      fontFamily: "Rene_Bieder_Milliard_Medium",
      "& span": {
        color: "#4FBCD8"
      }
    },
  },
  "& .continue": {
    color: "#fff",
    padding: "10px 55px",
    backgroundColor: "#019BE3",
    borderRadius: "7px",
    letterSpacing: "-0.375px",
    textTransform: "none",
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.26) !important"
    },
  },
  "& .active-card": {
    "&:hover": {
      cursor: "pointer",
    },
    "& .top": {
      backgroundImage: "linear-gradient(135deg, #0051f3 0%, #00e2b7 100%)",
      "& p": {
        color: "#fff !important"
      },
    },
    "& .bottom": {
      paddingBottom: "0px !important",
      "& .check": {
        color: "#009be2 !important"
      },
      "& .description": {
        color: "#000 !important"
      }
    }
  },
  "& .rec-item-wrapper": {
    height: "100%",
  },
  "& .rec-slider-container": {
    boxShadow: "none",
    margin: "0 -9px !important"
  },
  "& .rec.rec-arrow": {
    display: "none"
  }
});

const CustomButton = styled(Button)({
  justifyContent: "start",
  letterSpacing: "-0.45px",
  width: '100%',
  borderRadius: "12px",
  boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
  padding: "8px 15px",
  "& .Mui-disabled": {
    color: "8788#8f",
    backgroundColor: "#fff",
  },
  "& .amount": {
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    fontSize: "22px"
  },
  "& .point": {
    marginLeft: "7px",
    marginBottom: "-6px",
    fontSize: "12px",
    color: "#87888f"
  },
});

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 3, itemsToScroll: 3 },
  { width: 1450, itemsToShow: 3, itemsToScroll: 3 },
  { width: 1750, itemsToShow: 3, itemsToScroll: 3 },
];
export { SubscriptionPlan };
// Customizable Area End