import React, { forwardRef } from "react";
// Customizable Area Start
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import subDays from "date-fns/subDays";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  Card,
  CardContent,
  Typography,
  Snackbar,
  SnackbarContent,
  TextField,
  Button,
  Input,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Theme,
  IconButton,
  Checkbox,
  Grid,
  withStyles,
  createStyles,
  Container,
  Switch,
} from "@material-ui/core";
//   @ts-ignore
import styles from "./ContentManagementEvent.module.css";
export const useStyles = (theme: Theme) =>
  createStyles({
    textField: {
      padding: 0,
      borderRadius: 12,
      height: 54,
      width: "50%",
      fontFamily: "Rene_Bieder_Milliard_Book",
      border: "none",
    },
    fontResize: {
      fontWeight: 400,
      lineHeight: " 20px",
      letterSpacing: "-0.4px",
      color: "black",
    },
    pickerMargin: {
      marginTop: 0,
      marginBottom: 0,
    },
  });
import Select from "react-select";
import { FONTS, icons } from "../../../components/src/constants";
import { COLORS } from "../../../framework/src/Globals";
import ContentManagementController, { configJSON } from "./ContentManagementController";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";

const eventTypes = [
  { label: "Need",
   value: "need" },
  { label: "Project funding",
   value: "project_funding" },
  { label: "Upcoming activity",
   value: "activity" },
];
const scopes: any[] = ["Everyone", "Followers", "Registered user"];

const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 18,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: "white",
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#359ce2",
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor: "grey",
    },
    checked: {},
  })
)(Switch);

// @ts-ignore
const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <TextField
    variant="outlined"
    placeholder="dd/mm/yyyy"
    value={value}
    className={styles.textInput}
    onClick={onClick}
    onChange={onChange}
    InputProps={{
      classes: {
        root: styles.locationField,
      },
      endAdornment: (
        <div>
          <InputAdornment position="end">
            <img src={icons.npEformCalendar} />
          </InputAdornment>
        </div>
      ),
    }}
  />
));

// Customizable Area End

export class ActivityEventWeb extends ContentManagementController {
  fromDate(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>From date *</label>
        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          autoOk
          placeholder="dd/mm/yyyy"
          fullWidth
          disableToolbar
          label=""
          minDate={new Date()}
          variant="inline"
          inputVariant="outlined"
          format="DD/MM/YYYY"
          margin="normal"
          id="date-picker-inline"
          data-testid="datePickerTest"
          data-testId="date-picker-inline"
          InputProps={{
            classes: {
              root: classes.textField,
              input: classes.fontResize,
            },
          }}
          value={this.state.from_date}
          onChange={this.onFromDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
          style={{ fontSize: 16, marginTop: 0 }}
        />
      </div>
    );
  }

  publishScope() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Publishing scope</label>
        <br />
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-1"
              control={
                <Checkbox
                  style={{
                    color: "lightgrey",
                  }}
                  checked={this.state.everyone }
                  onChange={(e) => {
                    if (this.state.followers && this.state.registered_user) {
                      this.setState({ everyone: true });
                    }
                    this.setState({
                      followers: e.target.checked,
                      everyone: e.target.checked,
                      registered_user: e.target.checked,
                    });
                  }}
                  checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                />
              }
              label={
              <span className={styles.label}>Everyone</span>
            }
            />
          </span>
        </FormGroup>
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-2"
              control={
                <Checkbox
                  style={{
                    color: "lightgrey",
                  }}
                  checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                  onChange={(e) => {
                    this.setState({
                      followers: e.target.checked,
                    });
                    if(e.target.checked || this.state.registered_user) {
                      this.setState({ everyone: false})
                    }
                  }}
                  checked={this.state.followers}
                />
              }
              label={<span className={styles.label}>Followers</span>}
            />
          </span>
        </FormGroup>
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-3"
              control={
                <Checkbox
                  checked={this.state.registered_user}
                  style={{
                    color: "lightgrey",
                  }}
                  onChange={(e) => {
                    this.setState({
                      registered_user: e.target.checked,
                    });
                    if(e.target.checked || this.state.followers) {
                      this.setState({ everyone: false})
                    }
                  }}
                  checkedIcon={
                    <CheckBoxIcon
                      style={{
                        color: "#359ce2",
                      }}
                    />
                  }
                />
              }
              label={<span className={styles.label}>Registered user</span>}
            />
          </span>
        </FormGroup>
      </div>
    );
  }

  maxAttendance() {
    return (
      <div className={styles.divWrapper}>
        <label 
        className={styles.labelHeading}>
          Maximum attendance </label>
        <br />
        <Input
          value={this.state.volunteers_num}
          inputProps={{
            maxLength: 8,
          }}
          data-testId="noOfParticipant"
          onChange={(e) => {
            const pincode = e.target.value;
            if (/^\d{0,8}$/.test(pincode)) {
              this.setState({
                volunteers_num: pincode,
                eventVolunteerValidation: false,
              });
            } else {
              this.setState({
                eventVolunteerValidation: true,
              });
            }
          }}
          type="text"
          disableUnderline
          placeholder="0"
          className={styles.textInput}
        />
        <Typography
          style={{
            ...FONTS.body1,
            color: "lightgrey",
            fontSize: 12,
            fontWeight: 400,
          }}
        > This will be the maximum number of participants allowed for your
          event. </Typography>
        <Typography
          style={{
            ...FONTS.body1,
            color: "lightgrey",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Leave it 0 for no limit.
        </Typography>
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventVolunteerValidation ? "flex" : "none"}`,
          }}
        >
          Enter valid details
        </span>
      </div>
    );
  }

  activitySchedule() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Activity schedule</label>
        <br />
        <Input
          value={this.state.activity_schedule}
          data-testId="activitySchedule"
          onChange={(e) => {
            this.setState({
              activity_schedule: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          multiline
          rows={6}
          className={styles.multiLineInput}
          placeholder="Describe the activity schedule."
          disableUnderline
        />
      </div>
    );
  }

  description() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Description of activity *</label>
        <br />
        <Input
          value={this.state.description}
          data-testId="desc"
          onChange={(e) => {
            this.setState({
              description: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          multiline
          rows={6}
          className={styles.multiLineInput}
          placeholder="Provide a general description visible to all users on the platform."
          disableUnderline
        />
      </div>
    );
  }

  nameofEvent() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Name of event *</label>
        <br />
        <Input
          value={this.state.eventName}
          data-testId="eventName"
          onChange={(e) => {
            this.setState({ eventType: "activity" });
            this.setState({
              eventName: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
            if (e.target.value.length < 1) {
              this.setState({ eventNameValidation: true });
            } else {
              this.setState({
                eventNameValidation: false,
              });
            }
          }}
          className={styles.textInput}
          disableUnderline
          placeholder="Name of event"
        />
        <br />
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventNameValidation ? "flex" : "none"}`,
          }}
        >
          Event name can't be blank
        </span>
      </div>
    );
  }

  toDate(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>To date *</label>
        <br />
        <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
            .MuiOutlinedInput-root.Mui-Focused .MuiOutlinedInput-notchedOutline   {
              border : 1px solid black
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
        <KeyboardDatePicker
          autoOk
          placeholder="dd/mm/yyyy"
          disabled={!this.state.from_date}
          fullWidth
          disableToolbar
          label=""
          variant="inline"
          minDate={this.state.from_date}
          format="DD/MM/YYYY"
          inputVariant="outlined"
          margin="normal"
          id="date-picker-inline"
          data-testId="date-picker-inline-todate"
          value={this.state.to_date}
          InputProps={{
            classes: {
              root: classes.textField,
              input: classes.fontResize,
            },
          }}
          onChange={this.onToDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          style={{ fontSize: 16, marginTop: 0 }}
          keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
        />
      </div>
    );
  }

  startTime(classes: any) {
    return (
      <div className={styles.divWrapper} style={{ position: "relative" }}>
        <div style={{ display: "flex", width: "50%" }}>
          <label 
          className={styles.labelHeading}>Start time *
          </label>
          <Typography
            style={{
              fontFamily: "Rene_Bieder_Milliard_Medium",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>All day</Grid>

              <Grid item>
                {/* @ts-ignore */}
                <AntSwitch
                  checked={this.state.all_day}
                  data-testId="allDaySwitch"
                  onChange={(e: any) => {
                    this.setState({ all_day: e.target.checked });
                    if (e.target.checked) {
                      this.setState({
                        start_time: moment().set({ 'hour': 9, 'minute': 0 }),
                        finish_time: moment().set({ 'hour': 17, 'minute': 0 }),
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        </div>
        <InitiateThemeProvider>
          <KeyboardTimePicker
            data-testId='startTime'
            fullWidth
            ampm={false}
            autoOk
            disabled={this.state.all_day}
            variant="inline"
            disableToolbar
            inputVariant="outlined"
            className={classes.pickerMargin}
            margin="normal"
            id="mui-pickers-time"
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            value={this.state.start_time}
            mask="__:__ _M"
            placeholder="12:00 AM"
            onChange={this.onStartTimeChange}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                alt="clock"
                src={icons.clockIcon}
              />
            }
          />
        </InitiateThemeProvider>
      </div>
    );
  }

  finishTime(classes: any) {
    return (
      <div className={styles.divWrapper} style={{ position: "relative" }}>
        <label className={styles.labelHeading}>Finish time *</label>
        <br />
        <InitiateThemeProvider>
          <KeyboardTimePicker
            data-testId='finishTime'
            autoOk
            fullWidth
            ampm={false}
            disabled={this.state.all_day}
            disableToolbar
            variant="inline"
            id="mui-pickers-time"
            inputVariant="outlined"
            margin="normal"
            className={classes.pickerMargin}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            mask="__:__ _M"
            value={this.state.finish_time}
            onChange={this.onFinishTimeChange}
            placeholder="12:00 AM"
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                src={icons.clockIcon}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
    );
  }
  locationField() {
    return (
      <div className={styles.divWrapper} style={{ width: '50%', position: 'relative' }}>
        <label className={styles.labelHeading}>
          Location / venue address *</label>
        <br />
        {/* @ts-ignore */}
        <GooglePlacesAutoComplete
          apiKey={configJSON.mapsAPIKey}
          debounce={500}
          data-testId = 'location'
          selectProps={{
            isClearable: true,
            value: this.state.location ? [{ label: this.state.location, value: this.state.location }] : undefined,
            // classNamePrefix: "react-select",
            placeholder: "Enter location",
            noOptionsMessage: () => null,
            onChange: (event: any) => {
              console.log(event, 'event123')
              const location = event?.value?.description ?? "";
              this.setState({ location });
              if (location) {
                this.getLatLong(event?.value?.description);
              }
            },
            styles: {
              placeholder: (base) => {
                return {
                  ...base,
                  color: "lightgrey",
                  fontFamily: 'Rene_Bieder_Milliard_Book',
                  fontSize: '16px'
                };
              },

              menu: (base) => {
                return {
                  ...base,
                  paddingLeft: 20,
                  paddingRight: 20,
                  border: "1px solid lightgrey",
                  marginTop: 0,
                  borderTop: "none",
                  boxShadow: "none",

                  color: "lightgrey",
                  paddingTop: 5,
                  "&:hover": {
                    borderRadius: 12,
                  },
                };
              },
              clearIndicator: (base) => {
                return {
                  ...base,
                  marginRight: 20
                }
              },
              dropdownIndicator: (base) => {
                return {
                  ...base,
                  color: "dodgerblue",
                  display: 'none'
                };
              },
              control: (base) => {
                return {
                  ...base,
                  fontSize: 16,
                  fontFamily: 'Rene_Bieder_Milliard_Book',
                  backgroundColor: "white",
                  boxShadow: "none",
                  height: "54px",
                  width: '101%',
                  borderRadius: "10px",
                  border:  '2px solid lightgrey',
                };
              },
              option: (provided, state) => ({
                ...provided,
                color: "black",
                backgroundColor: state.isSelected
                  ? "#192E49"
                  : "inherit",
                "&:hover": {
                  backgroundColor: "#009BE2",
                  color: "white",
                },
              }),
              menuList: (base) => {
                return {
                  ...base,
                  marginTop: 15,
                  position: "relative",
                  top: "-5px",
                  zIndex: -1,
                  color: "grey",
                };
              },
            }
          }}
        >

        </GooglePlacesAutoComplete>
        <img
          src={icons.locationIcon}
          style={{
            height: 23, width: "auto", position: 'absolute', top: 45, right: 8
          }}
        />
      </div >
    );
  }

  publishSwitch() {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.labelHeading}>Publish</label>
        <br />
        {/* @ts-ignore */}
        <AntSwitch
          data-testId="publishSwitch"
          value={this.state.publish}
          onChange={(e: any) => {
            this.setState({ publish: e.target.checked });
          }}
        />
      </div>
    );
  }

  isSaveButtonDisable = () => {
    return !this.state.to_date ||
    !this.state.eventName ||
    !this.state.location ||
    !this.state.from_date ||
    !this.state.description ||
    !this.state.needsEventImg ||
    !this.state.start_time ||
    !this.state.finish_time ||
    this.state.createEventBtn;
  }

  footerBtns() {
    return (
      <div
        className={styles.divWrapper}
        style={{ display: "flex", marginTop: 15, marginLeft: -10 }}
      >
        <Button
          disabled={this.isSaveButtonDisable()}
          data-testId="createBtn"
          onClick={() => {
            this.setState({ createEventBtn: true });
            this.needsEvent();
          }}
          style={{
            width: "12%",
            boxShadow: "none",
            textTransform: "capitalize",
            height: 45,
            backgroundColor: `${
              !this.isSaveButtonDisable()
              ? "#009be2"
              : "#009be233"
              }`,
            color: "white ",
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
            borderRadius: 10,
            marginRight: 13,
            marginLeft: 13,
          }}
          color="primary"
        >
          Save
        </Button>
        <Button
          onClick={() => {
            this.props.navigationToActivitiesList();
          }}
          data-testId="cancelBtn"
          style={{
            fontFamily: "Rene_Bieder_Milliard_SemiBold",
            width: "12%",
            backgroundColor: COLORS.white,
            color: "#009be2",
            boxShadow: "none",
            borderRadius: 8,
            height: 45,
            border: ".1 solid lightgray",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
      </div>
    );
  }

  eventType = () => {
    return (
      <Select
        isDisabled={true}
        onMenuOpen={() => {
          this.setState({ createEventState: true });
        }}
        onMenuClose={() => {
          this.setState({ createEventState: false });
        }}
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,
            primary25: "skyblue",
            primary: "dodgerblue",
          },
        })}
        styles={{
          menu: (base) => {
            return {
              ...base,
              paddingLeft: 20,
              paddingRight: 20,
              border: "none",
              marginTop: 0,
              borderTop: "none",
              boxShadow: "none",
              color: "black",
              paddingTop: 5,
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
            };
          },
          singleValue: (base) => {
            return {
              ...base,
              color: "black",
            };
          },
          dropdownIndicator: (base) => {
            return {
              ...base,
              color: "lightgrey",
            };
          },
          control: (base, state) => {
            return {
              ...base,

              backgroundColor: "white",
              boxShadow: "none",
              height: 54,
              borderRadius: state.isFocused ? "10px " : "10px",
              border: `${this.state.createEventState
                ? "1px solid  #009BE2"
                : "1px solid lightgrey"
                }`,
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              "&:hover": {
                borderBottom: `${this.state.createEventState
                  ? "1px solid  #009BE2"
                  : "1px solid lightgrey"
                  }`,
              },
            };
          },
          indicatorSeparator: (styles) => ({
            ...styles,
            display: "none",
          }),
          menuList: (base) => {
            return {
              ...base,
              marginTop: 15,
              position: "relative",
              top: "-5px",
              zIndex: -1,
            };
          },
        }}
        name="eventTypes"
        data-testId="eventTypes"
        isSearchable={false}
        isClearable={false}
        isRtl={false}
        options={eventTypes}
        defaultValue={eventTypes[2]}
        className={styles.selectInput}
        onChange={(e: any) => {
          this.setState({ eventType: e.value });
        }}
      />
    );
  };
  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <Container maxWidth="xl">
            <Card
              style={{
                //  width: 90%;
                borderRadius: 12,
                margin: "auto",
                marginTop: 30,
              }}
            >
              <div style={{ marginTop: 10 }} />
              <div className={styles.newEventTitle}>
                <label className={styles.heading} 
                style={{ padding: 15 }}>New event</label>
              </div>
              <CardContent
                style={{
                  borderTop: "2px solid #eaeaea",
                  padding: 15,
                }}
              >
                <div className={styles.divWrapper}>
                  <label className={styles.labelHeading}>Select type</label>
                  <br />
                  {/* @ts-ignore */}
                  <div
                    style={{
                      border: `${this.state.createEventState
                        ? "1px solid  #009BE2"
                        : "1px solid lightgrey"
                        }`,
                      width: "50%",
                      borderRadius: 10,
                      borderBottom: `${!this.state.createEventState
                        ? "1px solid lightgrey"
                        : "1px solid  #009BE2"
                        }`,
                      height: `${!this.state.createEventState ? "54px" : "180px"
                        }`,
                    }}
                  >
                    {/* sonarqube select */}
                    {this.eventType()}
                  </div>
                </div>

                {this.nameofEvent()}

                {this.description()}

                {this.activitySchedule()}

                {this.maxAttendance()}

                {this.publishScope()}

                {this.fromDate(classes)}

                {this.toDate(classes)}

                {this.startTime(classes)}

                {this.finishTime(classes)}

                {this.locationField()}

                <div className={styles.divWrapper} style={{ width: "50%" }}>
                  <label className={styles.labelHeading}>Upload image *</label>

                  <CardContent className={styles.cardUploadImage}>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{
                        background: COLORS.white,
                        width: "106%",
                        marginLeft: -12,
                        height: "22%",
                        position: 'relative',
                        // border: "1px solid #a7b6b9 ",
                        border: "1px solid #dfdfdf",
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          display: `${this.state.needsEventImg ? "flex" : "none"
                            }`,
                          top: 0,
                          position: "absolute",
                          right: 0,
                        }}
                        data-testId="imgPreview"
                        onClick={(e) => {
                          this.setState({
                            needsEventImg: "",
                            needsEventImgPreview: "",
                          });
                        }}
                      >
                        <HighlightOffRoundedIcon style={{}} />
                      </div>

                      {this.state.needsEventImg ? (
                        <img
                          src={this.state.needsEventImgPreview}
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: 10,
                          }}
                        />
                      ) : (
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <img
                            src={icons.plusIcon}
                            style={{ height: 20 }}
                            alt="cameraIcon"
                          />

                          <input
                            name="profile_picture"
                            accept="image/png, image/jpeg"
                            id="contained-button-file"
                            type="file"
                            hidden
                            data-testId="userImageUpload"
                            onChange={(event) => {
                              event.preventDefault();
                              if (event.target.files && event.target.files[0]) {
                                let file = event.target.files[0];
                                this.setState({
                                  needsEventImgPreview:
                                    URL.createObjectURL(file),
                                });
                                this.setState({
                                  needsEventImg: file,
                                });
                              }
                            }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </CardContent>
                </div>

                {this.publishSwitch()}

                {this.footerBtns()}
                {this.state.eventUpdateError.length > 0 && (
                  <Snackbar
                    open={this.state.eventUpdateError.length > 0}
                    onClose={() => {
                      this.setState({ eventUpdateError: "" });
                    }}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <SnackbarContent
                      className={styles.snackbar}
                      message={"Server Error. Please try again later."}
                    />
                  </Snackbar>
                )}
              </CardContent>
            </Card>
          </Container>
        </div>
      </MuiPickersUtilsProvider>
      // Customizable Area End
    );
  }
}

//Customizable Area Start
export default withStyles(useStyles as any)(ActivityEventWeb as any);
//Customizable Area End
