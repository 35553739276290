// Customizable Area Start
import React, { ReactNode, } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import { makeStyles, } from '@material-ui/core/styles';

const useVerticalScrollBarContainerStyles = makeStyles((theme) => ({
  trackY: {
    width: "25px !important",
    background: "none !important",
  },
  thumbY: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    width: "24px !important",
    height: "54px !important",
    borderRadius: "14px !important ",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  thumbYContent: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    height: 16,
  },
  scrollbarInnerdiv: {
    width: 9,
    border: "1px solid #FFFFFF",
  },
}));


export function VerticalScrollBarContainer({ children }: { children: ReactNode }) {
  const classes = useVerticalScrollBarContainerStyles();
  return (
    <Scrollbar
      style={{ height: 400 }}
      trackYProps={{
        // @ts-ignore
        renderer: (props: { [x: string]: any; elementRef: any }) => {
          const { elementRef, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className={classes.trackY}
            />
          );
        },
      }}
      thumbYProps={{
        // @ts-ignore
        renderer: (props: { [x: string]: any; elementRef: any }) => {
          const { elementRef, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className={classes.thumbY}
            >
              <div className={classes.thumbYContent}>
                <div className={classes.scrollbarInnerdiv} />
                <div className={classes.scrollbarInnerdiv} />
                <div className={classes.scrollbarInnerdiv} />
              </div>
            </span>
          );
        },
      }}
    >
      {children}
    </Scrollbar>
  );
}

// Customizable Area End