import React from "react";
// Customizable Area Start
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import MomentUtils from "@date-io/moment";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import { DeleteEventModal } from "./DeleteEventModal.web";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import {
  Card,
  CardContent,
  Button,
  Input,
  Snackbar,
  SnackbarContent,
  Dialog,
  Typography,
  FormGroup,
  FormControlLabel,
  IconButton,
  TextField,
  Checkbox,
  Divider,
  Grid,
  Switch,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
// @ts-ignore
import styles from "./ContentManagementEvent.module.css";
import { FAMILYFONTS, icons } from "../../../components/src/constants";
import { COLORS } from "../../../framework/src/Globals";
import ContentManagementController, { configJSON } from "./ContentManagementController";

import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { InitiateThemeProvider } from "../../../components/src/theme/theme.provider";

const eventTypes = [
  { label: "Need", value: "need" },
  { label: "Project funding", value: "project_funding" },
  { label: "Upcoming activity", value: "activity" },
];

const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 18,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      // color: theme.palette.grey[500],
      color: "white",
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#359ce2",
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor: "grey",
    },
    checked: {},
  })
)(Switch);

export const useStyles = (theme: Theme) =>
  createStyles({
    textField: {
      padding: 0,
      borderRadius: 12,
      height: 54,
      width: "50%",
      fontFamily: "Rene_Bieder_Milliard_Book",
      border: "none",
    },
    fontResize: {
      fontWeight: 400,
      lineHeight: " 20px",
      letterSpacing: "-0.4px",
      color: "black",
    },
  });

// @ts-ignore

// Customizable Area End

export class ProjectsEventUpdate extends ContentManagementController {
  nameOfEvent(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Name of event *</label>
        <br />
        <Input
          value={this.state.eventName}
          className={styles.textInput}
          disableUnderline
          data-testId="eventName"
          placeholder="Name of event"
          onChange={(e) => {
            this.setState({
              eventName: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
            if (e.target.value.length < 1) {
              this.setState({ eventNameValidation: true });
            } else {
              this.setState({
                eventNameValidation: false,
              });
            }
          }}
        />
        <br />
        <span
          style={{
            color: "red",
            position: "absolute",
            fontSize: 14,
            fontFamily: "Rene_Bieder_Milliard_Medium",
            display: `${this.state.eventNameValidation ? "flex" : "none"}`,
          }}
        >
          Event name can't be blank
        </span>
      </div>
    );
  }

  desc(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Description of project *</label>
        <br />
        <Input
          disableUnderline
          data-testId="desc"
          value={this.state.description}
          onChange={(e) => {
            this.setState({
              description: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          multiline
          rows={6}
          className={styles.multiLineInput}
          placeholder="Provide a general description visible to all users alerted to your project."
        />
      </div>
    );
  }

  projectOutcome(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>What is the project outcome</label>
        <br />
        <Input
          value={this.state.projectOutcome}
          data-testId="projectOutcome"
          onChange={(e) => {
            this.setState({
              projectOutcome: e.target.value.startsWith(" ")
                ? e.target.value.trim()
                : e.target.value,
            });
          }}
          placeholder="Describe what is the project outcome"
          multiline
          className={styles.multiLineInput}
          disableUnderline
          rows={6}
        />
      </div>
    );
  }

  beneficiaries(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Who are the beneficiaries ?</label>
        <br />
        <Input
          disableUnderline
          data-testId="benefit"
          value={this.state.beneficiaries}
          onChange={(e) => {
            this.setState({ beneficiaries: e.target.value });
          }}
          placeholder="Beneficiaries"
          className={styles.textInput}
        />
      </div>
    );
  }

  amountRequired(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>How much is required</label>
        <br />
        <Input
          data-testId="amount"
          startAdornment={
            <IconButton>
              <img src={icons.currencySymbol} style={{ marginTop: -2 }} />
            </IconButton>
          }
          type="number"
          value={this.state.amount_required}
          onChange={(e) => {
            this.setState({ amount_required: e.target.value });
          }}
          placeholder="Required amount"
          disableUnderline
          className={styles.textInput}
        />
      </div>
    );
  }

  projectDelivery(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>
          Where will the project be delivered
        </label>
        <br />
        <div className="published-need projectOutcome">
          {/* @ts-ignore */}
          <GooglePlacesAutoComplete
          className={styles.textInput}
          apiKey={configJSON.mapsAPIKey}
          data-testId = 'location'
          debounce={500}
          selectProps={{
            placeholder:"Enter here",
            classNamePrefix: "react-select",
            isClearable: true,
            value: this.state.location ? [{ label: this.state.location, value: this.state.location }] : undefined,
            noOptionsMessage: () => null,
            onChange: (event: any) => {
              const location = event?.value?.description ?? "";
              this.setState({ location });
            },
          }}
        >
        </GooglePlacesAutoComplete>
        </div>
      </div>
    );
  }

  postUpdateExt() {
    return (
      <div
        style={{
          display: `${this.state.post_updates ? "block" : "none"}`,
        }}
      >
        <IconButton
          onClick={() => {
            this.setState({ editNoteModal: true });
          }}
        >
          <img src={icons.npEdit} style={{ height: 25, width: 25 }} />
        </IconButton>

        <Button
          onClick={() => {
            this.setState({ viewUpdatesModal: true });
          }}
          style={{
            width: "20%",
            height: 30,
            textTransform: "capitalize",
            boxShadow: "none",
            backgroundColor: "#009be2",
            color: "white ",
            borderRadius: 10,
          }}
        >
          View
        </Button>
      </div>
    );
  }

  videoCheck(details: any) {
    return (
      <FormGroup>
        <FormControlLabel
          data-testId="check-1"
          control={
            <Checkbox
              checked={this.state.video_uploads}
              style={{ color: "lightgrey" }}
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
              onChange={(e) => {
                this.setState({
                  video_uploads: e.target.checked,
                });
              }}
            />
          }
          label={<label className={styles.label}>Video uploads</label>}
        />
      </FormGroup>
    );
  }

  videoBtnGrp(details: any) {
    return (
      <div
        style={{
          display: `${this.state.video_uploads ? "block" : "none"}`,
        }}
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <img src={icons.npUpload} style={{ height: 20 }} alt="cameraIcon" />

          <input
            accept="video/mp4"
            type="file"
            hidden
            multiple
            onChange={this.handleMultiVideo}
          />
        </IconButton>
        <Button
          data-testId="modalOpen"
          onClick={() => {
            this.setState({ multiVideoModal: true });
          }}
          style={{
            width: "20%",
            height: 30,
            textTransform: "capitalize",
            boxShadow: "none",
            backgroundColor: "#009be2",
            color: "white ",
            borderRadius: 10,
          }}
        >
          View
        </Button>
      </div>
    );
  }

  imgCheck(details: any) {
    return (
      <FormGroup>
        <FormControlLabel
          data-testId="check-2"
          control={
            <Checkbox
              checked={this.state.upload_images}
              style={{ color: "lightgrey" }}
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
              onChange={(e) => {
                this.setState({
                  upload_images: e.target.checked,
                });
              }}
            />
          }
          label={<label className={styles.label}>Upload images</label>}
        />
      </FormGroup>
    );
  }

  updateCheck(details: any) {
    return (
      <FormGroup>
        <FormControlLabel
          data-testId="check-3"
          control={
            <Checkbox
              checked={this.state.post_updates}
              style={{ color: "lightgrey" }}
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
              onChange={(e) => {
                this.setState({
                  post_updates: e.target.checked,
                });
              }}
            />
          }
          label={<label className={styles.label}>Post updates</label>}
        />
      </FormGroup>
    );
  }

  proofOfDelivery(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>
          What proof-of-delivery artefacts will be implemented
        </label>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {this.videoCheck(details)}
          {this.videoBtnGrp(details)}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {this.imgCheck(details)}
          <div
            style={{
              display: `${this.state.upload_images ? "block" : "none"}`,
            }}
          >
            <IconButton
              color="primary"
              // className={classes.imageuploadicon}
              aria-label="upload picture"
              component="label"
            >
              <img
                src={icons.npUpload}
                style={{ height: 20 }}
                alt="cameraIcon"
              />

              <input
                // name="profile_picture"
                accept="image/*"
                // id="contained-button-file"
                type="file"
                hidden
                multiple
                onChange={this.handleMultiImage}
              />
            </IconButton>
            <Button
              data-testId="modalImgOpen"
              onClick={() => {
                this.setState({ multiImageModal: true });
              }}
              style={{
                width: "20%",
                height: 30,
                textTransform: "capitalize",
                boxShadow: "none",
                backgroundColor: "#009be2",
                color: "white ",
                borderRadius: 10,
              }}
            >
              View
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {this.updateCheck(details)}
          {this.postUpdateExt()}
        </div>
      </div>
    );
  }

  viewNoteDialog(details: any) {
    return (
      <Dialog
        hideBackdrop
        PaperProps={{
          style: {
            background: "#FEFEFE",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
            borderRadius: 18,
            maxHeight: 370,
            width: 500,
          },
          elevation: 0,
        }}
        open={this.state.viewUpdatesModal}
        // data-testId="modalEdit"
        onClose={() => {
          this.setState({ viewUpdatesModal: false });
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 20,
            }}
          >
            <label className={styles.label} style={{ fontWeight: 600 }}>
              Updates on project!
            </label>
            <div
              // data-testId="modalOpenUpdates"
              onClick={() => {
                this.setState({ viewUpdatesModal: false });
              }}
            >
              <HighlightOffRoundedIcon />
            </div>
          </div>
          <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />
          <div style={{ padding: 20 }}>
            <div
              style={{
                display: this.state.projectNote ? "flex" : "none",
                border: "1px solid #e5e7f0",
                borderRadius: 10,
                marginBottom: 10,
                padding: 10,
                background: "#FFFFFF",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                position: "relative",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    border: "1px solid rgb(0, 155, 226)",
                    fontFamily: "Rene_Bieder_Milliard_Medium",
                    background: "rgb(0, 155, 226)",
                    color: "rgb(255, 255, 255)",
                    padding: "0px 15px 0px 17px",
                    borderRadius: "0px 9px",
                  }}
                >
                  New
                </span>
                <Typography className={styles.label} style={{ marginTop: 10 }}>
                  {this.state.projectNote}
                </Typography>
              </div>
            </div>
            {this.state.noteUpdate?.map((item: any) => (
              <div
                style={{
                  display: "flex",
                  border: "1px solid #e5e7f0",
                  borderRadius: 10,
                  marginBottom: 10,
                  padding: 10,
                  background: "#FFFFFF",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>{item.note}</Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    );
  }

  everyoneCheck(details: any) {
    return (
      <span>
        <FormControlLabel
          data-testId="check-6"
          control={
            <Checkbox
              style={{ color: "lightgrey" }}
              checked={
                this.state.everyone
              }
              checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
              onChange={(e) => {
                this.setState({
                  everyone: e.target.checked,
                  followers: e.target.checked,
                  registered_user: e.target.checked,
                });
                if (this.state.followers && this.state.registered_user) {
                  this.setState({ 
                    everyone: true 
                  });
                } 
              }}
            />
          }
          label={<span className={styles.label}>Everyone</span>}
        />
      </span>
    );
  }

  publishingScope(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Publishing scope</label>
        <br />
        <FormGroup>{this.everyoneCheck(details)}</FormGroup>
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-7"
              control={
                <Checkbox
                  checked={this.state.followers}
                  style={{ color: "lightgrey" }}
                  checkedIcon={<CheckBoxIcon style={{ color: "#359ce2" }} />}
                  onChange={(e) => {
                    this.setState({
                      followers: e.target.checked,
                    });
                    if(e.target.checked
                      || this.state.registered_user ) {
                      this.setState({ everyone: false})
                    }
                  }}
                />
              }
              label={<span className={styles.label}>Followers</span>}
            />
          </span>
        </FormGroup>
        <FormGroup>
          <span>
            <FormControlLabel
              data-testId="check-8"
              control={
                <Checkbox
                  checked={this.state.registered_user}
                  style={{ color: "lightgrey" }}
                  checkedIcon={
                    <CheckBoxIcon
                      style={{
                        color: "#359ce2",
                      }}
                    />
                  }
                  onChange={(e) => {
                    if( e.target.checked 
                      || this.state.followers) {
                      this.setState({ everyone: false})
                    }
                    this.setState({
                      registered_user: e.target.checked,
                    });
                  }}
                />
              }
              label={<span className={styles.label}>Registered user</span>}
            />
          </span>
        </FormGroup>
      </div>
    );
  }

  projectDate(classes: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Date *</label>
        <br />
          <style>
          {`.MuiPickersSlideTransition-transitionContainer > *{
              margin-top: 0;
              color: #000;
              font-weight: bold;
              font-size: 0.944rem;
            }
             .MuiOutlinedInput-root.Mui-Focused .MuiOutlinedInput-notchedOutline   {
              border : 1px solid black
            }
            .MuiPickersCalendarHeader-dayLabel{
              color: #359ce2;
              font-weight: 600;
            }
            .MuiPickersCalendarHeader-iconButton{
              border-radius: 50%;
              border: 1px solid #000;
              margin-top: 25px;
              margin-right: 20px;
              margin-left: 20px;
              height: 20px;
              width: 20px;
            }
            .MuiPickersDay-daySelected{
              border-radius: 50%;
              background-color: rgb(0, 155, 226);
              color: #fff;
            }  
            .MuiPickersDay-daySelected:hover{
              background-color: rgb(0, 155, 226);
            }`}
        </style>
          <KeyboardDatePicker
            color="primary"
            autoOk
            fullWidth
            disableToolbar
            minDate={new Date()}
            variant="inline"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            margin="normal"
            id="date-picker-inline"
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            value={this.state.updateFromDate}
            data-testId='updateFromDate'
            onChange={(date) => {
              this.setState({ updateFromDate: date });
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            keyboardIcon={<img src={icons.npEformCalendar} alt="calender" />}
            style={{ fontSize: 16, marginTop: 0 }}
          />
      </div>
    );
  }

  projectTime(classes: any) {
    return (
      <>
      <div className={styles.divWrapper}>
        <label className={styles.label}>Time</label>
        <br />
        <InitiateThemeProvider>
          <KeyboardTimePicker
            autoOk
            fullWidth
            ampm={false}
            data-testId='updateStartTime'
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            id="mui-pickers-time"
            className={classes.pickerMargin}
            InputProps={{
              classes: {
                root: classes.textField,
                input: classes.fontResize,
              },
            }}
            format="hh:mm A"
            placeholder="12:00 AM"
            mask="__:__ _M"
            value={
              this.state.startTimePost === null
                ? moment(this.state.updateStartTime)
                : this.state.startTimePost
            }
            onChange={(time) => {
              this.setState({ startTimePost: time });
            }}
            KeyboardButtonProps={{
              "aria-label": "Select time",
            }}
            keyboardIcon={
              <img
                style={{ height: "auto", width: 20 }}
                src={icons.clockIcon}
                alt="clock"
              />
            }
          />
        </InitiateThemeProvider>
      </div>
      <div className={styles.divWrapper} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "50%"}}>
        <div className={styles.label}>
          Donors will be updated on progress during project delivery.
        </div>
        <AntSwitch 
         data-testId="projectUpdate"
         checked={this.state.projectUpdate === "true"}
         onChange={(e: any) => {
           this.setState({ projectUpdate: e.target.checked ? "true" : "false" });
         }}  />
      </div>
      </>
    );
  }

  socialMedia1(details: any) {
    return (
      <Input
        disableUnderline
        value={this.state.social_media1}
        onChange={(e) => {
          this.setState({ social_media1: e.target.value });
        }}
        style={{ marginBottom: 10 }}
        data-testId="media-1"
        className={styles.textInput}
        placeholder="Enter link here"
      />
    );
  }

  socialMedia2(details: any) {
    return (
      <Input
        disableUnderline
        value={this.state.social_media2}
        onChange={(e) => {
          this.setState({ social_media2: e.target.value });
        }}
        style={{ marginBottom: 10 }}
        data-testId="media-2"
        className={styles.textInput}
        placeholder="Enter link here"
      />
    );
  }

  socialMedia3(details: any) {
    return (
      <Input
        disableUnderline
        value={this.state.social_media3}
        onChange={(e) => {
          this.setState({ social_media3: e.target.value });
        }}
        style={{ marginBottom: 10 }}
        data-testId="media-3"
        className={styles.textInput}
        placeholder="Enter link here"
      />
    );
  }

  projectBanner() {
    return (
      <div style={{ width: "50%" }} className={styles.divWrapper}>
        <label className={styles.label}>Upload project banner *</label>

        <CardContent className={styles.cardUploadImage}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              background: COLORS.white,
              width: "106%",
              marginLeft: -12,
              position : 'relative',
              height: "22%",
              // border: "1px solid #a7b6b9 ",
              border: "1px solid #dfdfdf",
              borderRadius: 10,
            }}
          >
            <div
              data-testId="imgPreview"
              style={{
                display: `${this.state.updateEventImg ? "flex" : "none"}`,
                position: "absolute",
                right: 0,
                top : 0,
              }}
              onClick={(e) => {
                this.setState({
                  updateEventImg: "",
                  needsEventImg: "",
                  needsEventImgPreview: "",
                });
              }}
            >
              <HighlightOffRoundedIcon style={{}} />
            </div>

            {this.state.updateEventImg ? (
              <img
                src={
                  this.state.updateEventImg?.url
                    ? this.state.updateEventImg?.url
                    : this.state.updateEventImg
                }
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 10,
                }}
              />
            ) : (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <img
                  src={icons.plusIcon}
                  style={{ height: 20 }}
                  alt="cameraIcon"
                />

                <input
                  data-testId="userImageUpload"
                  name="profile_picture"
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={(event) => {
                    event.preventDefault();
                    if (event.target.files && event.target.files[0]) {
                      let file = event.target.files[0];
                      this.setState({
                        updateEventImg: URL.createObjectURL(file),
                      });
                      this.setState({
                        updateEventImgPreview: file,
                      });
                    }
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </CardContent>
        <div />
      </div>
    );
  }

  publishSwitch(details: any) {
    return (
      <div className={styles.divWrapper}>
        <label className={styles.label}>Publish</label>
        <br />
        <div style={{ marginLeft: 5 }}>
          <AntSwitch
            checked={this.state.publish}
            data-testId="publishSwitch"
            onChange={(e: any) => {
              this.setState({
                publish: e.target.checked,
              });
            }}
          />
        </div>
      </div>
    );
  }

  updateField() {
    return (
      <div className={styles.divWrapper}>
        <Input
          onChange={(e) => {
            this.setState({ update_info: e.target.value });
          }}
          data-testId="updateInfo"
          disableUnderline
          placeholder="Enter what is updated..."
          multiline
          className={styles.multiLineInput}
          rows={6}
        />
      </div>
    );
  }

  cancelModal = () => {
    this.setState({ deleteConf: false });
  };

  isUpdateBtnDisable = () => {
    return !this.state.eventName ||
    !this.state.description ||
    !this.state.updateStartTime ||
    !this.state.location ||
    !this.state.updateFromDate ||
    !this.state.update_info ||
    !this.state.updateEventImg;
  }

  render() {
    // Customizable Area Start
    const { single_event_details, classes, theme }: any = this.props;
    const details = single_event_details.data?.attributes;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div>
            <Card
              style={{
                width: "99.6%",
                borderRadius: "0 0 12px 12px",
                margin: "auto",
              }}
            >
              <div style={{ marginTop: 10 }} />

              <CardContent
                style={{
                  padding: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-15px",
                    float: "right",
                  }}
                >
                  <Typography className={styles.label}>
                    Id: {single_event_details.data.id}
                  </Typography>
                  <IconButton>
                    <ShareOutlinedIcon />
                  </IconButton>
                </div>

                <div className={styles.divWrapper}>
                  <label className={styles.label}>Selected type</label>
                  <br />
                  {/* @ts-ignore */}
                  <div
                    style={{
                      // marginTop: 10,
                      border: `${
                        this.state.createEventState
                          ? "1px solid  #009BE2"
                          : "1px solid lightgrey"
                      }`,
                      width: "50%",
                      borderRadius: 10,
                      borderBottom: `${
                        !this.state.createEventState
                          ? "1px solid lightgrey"
                          : "1px solid  #009BE2"
                      }`,
                      height: `${
                        !this.state.createEventState ? "54px" : "180px"
                      }`,
                    }}
                  >
                    <Select
                      isDisabled={true}
                      onMenuOpen={() => {
                        this.setState({ createEventState: true });
                      }}
                      onMenuClose={() => {
                        this.setState({ createEventState: false });
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "skyblue",
                          primary: "dodgerblue",
                        },
                      })}
                      styles={{
                        menu: (base) => {
                          return {
                            ...base,
                            paddingLeft: 20,
                            paddingRight: 20,
                            border: "none",
                            marginTop: 0,
                            borderTop: "none",
                            boxShadow: "none",
                            color: "black",
                            paddingTop: 5,
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px",
                          };
                        },
                        singleValue: (base) => {
                          return {
                            ...base,
                            color: "black",
                          };
                        },
                        dropdownIndicator: (base) => {
                          return {
                            ...base,
                            color: "lightgrey",
                          };
                        },
                        control: (base, state) => {
                          return {
                            ...base,
                            height: "54px",
                            cursor: "not-allowed",
                            backgroundColor: "white",
                            boxShadow: "none",
                            borderRadius: state.isFocused ? "10px " : "10px",
                            border: `${
                              this.state.createEventState
                                ? "1px solid  #009BE2"
                                : "1px solid lightgrey"
                            }`,
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            "&:hover": {
                              borderBottom: `${
                                this.state.createEventState
                                  ? "1px solid  #009BE2"
                                  : "1px solid lightgrey"
                              }`,
                            },
                          };
                        },
                        indicatorSeparator: (styles) => ({
                          ...styles,
                          display: "none",
                        }),
                        menuList: (base) => {
                          return {
                            ...base,
                            marginTop: 15,
                            position: "relative",
                            top: "-5px",
                            zIndex: -1,
                          };
                        },
                      }}
                      name="eventTypes"
                      isSearchable={false}
                      isClearable={false}
                      isRtl={false}
                      options={eventTypes}
                      defaultValue={eventTypes[1]}
                      className={styles.selectInput}
                      onChange={(e: any) => {
                        this.setState({ eventType: e.value });
                      }}
                      data-testId="eventTypes"
                    />
                  </div>
                </div>

                {this.nameOfEvent(details)}
                {this.desc(details)}
                {this.projectOutcome(details)}
                {this.beneficiaries(details)}
                {this.amountRequired(details)}
                {this.projectDelivery(details)}
                {this.proofOfDelivery(details)}

                {this.publishingScope(details)}
                <div className={styles.divWrapper}>
                  <label className={styles.label}>Campaign launch</label>
                  <br />
                </div>
                {this.projectDate(classes)}
                {this.projectTime(classes)}
                <div className={styles.divWrapper}>
                  <label className={styles.label}>Social media presence</label>
                  <br />
                  {this.socialMedia1(details)}
                  <br />
                  {this.socialMedia2(details)}
                  <br />
                  {this.socialMedia3(details)}
                  <br />
                </div>
                {this.projectBanner()}
                {this.publishSwitch(details)}
                {this.updateField()}
                <div
                  className={styles.divWrapper}
                  style={{ display: "flex", marginTop: 15, marginLeft: -10 }}
                >
                  <Button
                    disabled={this.isUpdateBtnDisable()}
                    data-testId="updateBtn"
                    onClick={() => {
                      this.updateEvent(single_event_details.data?.id);
                    }}
                    style={{
                      textTransform: "capitalize",
                      width: "12%",
                      boxShadow: "none",
                      height: 45,
                      backgroundColor: `${
                        !this.isUpdateBtnDisable()
                          ? "#009be2"
                          : "#009be233"
                      }`,
                      marginRight: 13,
                      color: "white ",
                      borderRadius: 10,
                      fontFamily: "Rene_Bieder_Milliard_SemiBold",
                      marginLeft: 13,
                    }}
                    color="primary"
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.navigationToProjectsList();
                    }}
                    style={{
                      fontFamily: "Rene_Bieder_Milliard_SemiBold",
                      width: "12%",
                      backgroundColor: COLORS.white,
                      color: "#009be2",
                      boxShadow: "none",
                      borderRadius: 8,
                      height: 45,
                      border: ".1 solid lightgray",
                      // marginBottom: 15,
                      // marginTop: 5,
                      textTransform: "capitalize",
                    }}
                    variant="outlined"
                    data-testId="cancelBtn"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ deleteConf: true });
                    }}
                    style={{
                      fontFamily: "Rene_Bieder_Milliard_SemiBold",
                      width: "12%",
                      backgroundColor: "red",
                      color: "white ",
                      boxShadow: "none",
                      borderRadius: 8,
                      height: 45,
                      marginLeft: "auto",
                      marginRight: 20,
                      border: "0.1px solid lightgray",
                      textTransform: "none",
                    }}
                    variant="outlined"
                  >
                    Cancel event
                  </Button>
                </div>
                {this.state.eventUpdateError.length > 0 && (
                  <Snackbar
                    open={this.state.eventUpdateError.length > 0}
                    onClose={() => {
                      this.setState({ eventUpdateError: "" });
                    }}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <SnackbarContent
                      className={styles.snackbar}
                      message={this.state.eventUpdateError ? this.state.eventUpdateError : this.state.eventUpdateErros}
                    />
                  </Snackbar>
                )}
                <Dialog
                  hideBackdrop
                  PaperProps={{
                    style: {
                      background: "#FEFEFE",
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
                      borderRadius: 18,
                      maxHeight: 370,
                      width: 600,
                    },
                    elevation: 0,
                  }}
                  open={this.state.deleteConf}
                  onClose={() => {
                    this.setState({ deleteConf: false });
                  }}
                >
                  {/* @ts-ignore */}
                  <DeleteEventModal
                    id={single_event_details.data?.id}
                    cancelClick={this.cancelModal}
                    confirmClick={() => {
                      this.deleteProjectEvent(single_event_details.data?.id);
                    }}
                  />
                </Dialog>
              </CardContent>
            </Card>
            <Dialog
              hideBackdrop
              PaperProps={{
                style: {
                  background: "#FEFEFE",
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
                  borderRadius: 18,
                  maxHeight: 370,
                  width: 500,
                },
                elevation: 0,
              }}
              open={this.state.multiVideoModal}
              data-testId="modal"
              onClose={() => {
                this.setState({ multiVideoModal: false });
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 20,
                  }}
                >
                  <label className={styles.label} style={{ fontWeight: 600 }}>
                    Video proofs on project!
                  </label>
                  <div
                    data-testId="modalOpenVideo"
                    onClick={() => {
                      this.setState({ multiVideoModal: false });
                    }}
                  >
                    <HighlightOffRoundedIcon />
                  </div>
                </div>
                <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />
                <div style={{ padding: 20 }}>
                  {this.multiVideosDisplay.map((item,index:any) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        border: "1px solid #e5e7f0",
                        borderRadius: 10,
                        marginBottom: 10,
                        padding: 10,
                        background: "#FFFFFF",
                        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          marginLeft: "77%",
                          marginTop: "-10px",
                          border: "1px solid rgb(0, 155, 226)",
                          fontFamily: "Rene_Bieder_Milliard_Medium",
                          background: "rgb(0, 155, 226)",
                          color: "rgb(255, 255, 255)",
                          padding: "0px 15px 0px 17px",
                          borderRadius: "0px 9px",
                        }}
                      >
                        New
                      </span>
                      <div style={{ marginRight: 10, borderRadius: 8 }}>
                        <video src={item} style={{ height: 64, width: 64 }} />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          className={styles.label}
                          style={{ fontWeight: 600, fontSize: 16 }}
                        >
                          Video proof of project
                        </Typography>
                        <Typography
                          className={styles.updatedText}
                          style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}
                        >
                          Updated on : {moment(item.date).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </div>
                  ))}
                  {details.video.map((item: any) => (
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #e5e7f0",
                        borderRadius: 10,
                        marginBottom: 10,
                        padding: 10,
                        background: "#FFFFFF",
                        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <div style={{ marginRight: 10, borderRadius: 8 }}>
                        <video
                          src={item.url}
                          style={{ height: 64, width: 64 }}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          className={styles.label}
                          style={{ fontWeight: 600, fontSize: 16 }}
                        >
                          Video proof of project
                        </Typography>
                        <Typography
                          className={styles.updatedText}
                          style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}
                        >
                          Updated on : {moment(item.date).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Dialog>
            <Dialog
              hideBackdrop
              PaperProps={{
                style: {
                  background: "#FEFEFE",
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
                  borderRadius: 18,
                  maxHeight: 370,
                  width: 500,
                },
                elevation: 0,
              }}
              data-testId="modalEdit"
              open={this.state.editNoteModal}
              onClose={() => {
                this.setState({ editNoteModal: false });
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 20,
                  }}
                >
                  <label>Post update on project!</label>

                  <div
                    data-testId="editModalOpen"
                    onClick={() => {
                      this.setState({ editNoteModal: false });
                    }}
                  >
                    <HighlightOffRoundedIcon />
                  </div>
                </div>
                <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />

                <div style={{ padding: 20 }}>
                  <Input
                    disableUnderline
                    className={styles.multiLineInput}
                    style={{ borderRadius: 12, width: "100%" }}
                    value={this.state.projectNote}
                    data-testId="notes"
                    onChange={(e: any) => {
                      this.setState({ projectNote: e.target.value });
                    }}
                    multiline
                    rows={6}
                    placeholder="Enter what is updated"
                  />
                </div>
                <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />
                <div style={{ padding: 20 }}>
                  <Button
                    style={{
                      backgroundColor: "#009be2",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      height: 45,
                      width: 100,
                      ...FAMILYFONTS.family2,
                      borderRadius: 10,
                      color: "white ",
                    }}
                    onClick={() => {
                      this.setState({ editNoteModal: false });
                    }}
                  >
                    Post
                  </Button>
                </div>
              </div>
            </Dialog>
            <Dialog
              hideBackdrop
              PaperProps={{
                style: {
                  background: "#FEFEFE",
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
                  borderRadius: 18,
                  maxHeight: 370,
                  width: 500,
                },
                elevation: 0,
              }}
              data-testId="multiImgModal"
              open={this.state.multiImageModal}
              onClose={() => {
                this.setState({ multiImageModal: false });
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 20,
                  }}
                >
                  <label className={styles.label} style={{ fontWeight: 600 }}>
                    Image proofs on project!
                  </label>
                  <div
                    data-testId="openMultiImgModal"
                    onClick={() => {
                      this.setState({ multiImageModal: false });
                    }}
                  >
                    <HighlightOffRoundedIcon />
                  </div>
                </div>
                <Divider style={{ color: "#EAEAEA", marginBottom: 10 }} />
                <div style={{ padding: 20 }}>
                  {this.multiImagesdisplay.map((item,index:any) => (
                    <div
                    key={index}
                      style={{
                        display: "flex",
                        border: "1px solid #e5e7f0",
                        borderRadius: 10,
                        marginBottom: 10,
                        padding: 10,
                        background: "#FFFFFF",
                        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          marginLeft: "77%",
                          marginTop: -10,
                          border: "1px solid rgb(0, 155, 226)",
                          fontFamily: "Rene_Bieder_Milliard_Medium",
                          background: "rgb(0, 155, 226)",
                          color: "rgb(255, 255, 255)",
                          padding: "0px 15px 0px 17px",
                          borderRadius: "0px 9px",
                        }}
                      >
                        New
                      </span>
                      <div style={{ marginRight: 10, borderRadius: 8 }}>
                        <img src={item.url} style={{ height: 64, width: 64 }} />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          className={styles.label}
                          style={{ fontWeight: 600, fontSize: 16 }}
                        >
                          Image proof of project
                        </Typography>
                        <Typography
                          className={styles.updatedText}
                          style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}
                        >
                          Updated on : {moment(item.date).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </div>
                  ))}
                  {details.image.map((item: any) => (
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #e5e7f0",
                        borderRadius: 10,
                        marginBottom: 10,
                        padding: 10,
                        background: "#FFFFFF",
                        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <div style={{ marginRight: 10, borderRadius: 8 }}>
                        <img src={item.url} style={{ height: 64, width: 64 }} />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          className={styles.label}
                          style={{ fontWeight: 600, fontSize: 16 }}
                        >
                          Image proof of project
                        </Typography>
                        <Typography
                          className={styles.updatedText}
                          style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}
                        >
                          Updated on : {moment(item.date).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Dialog>
            {this.viewNoteDialog(details)}
          </div>
        </MuiPickersUtilsProvider>
      // Customizable Area End
    );
  }
}

export default withStyles(useStyles as any, { withTheme: true })(
  ProjectsEventUpdate as any
);
