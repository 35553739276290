import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { constants } from "../../../components/src/constants";
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast } from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  projectUpdateProp?: boolean;
  cancelClick: any;
  confirmClick: any;
  selectedAdminTab: any;
  tabsFunction: any;
  children: any;
  onSecondTabsCreation: any;
  eventUpdates: any;
  SingleEvent: any;
  single_event_details: any;
  messageClick: (id: string) => void;
  createNeedEvent: any;
  createActivitiesEvent: any;
  showBackButton: any;
  projectsEvent: any;
  title: any;
  pledgesDetails: any;
  eventPledgesDetails: any;
  navigationToNeeds: any;
  navigationToActivitiesList: any;
  navigationToProjectsList: any;
  showBottomBar: boolean;
  showDrawerHeader: boolean;
  mobileOpen: boolean;
  open: boolean;
  classes: any;
  theme?: any;
  history: any;
  DashboardWeb?: any;
  titleColor: any;
  showLine: any;
  adminSelectedTab: any;
  createEvents: any;
  setSelectedAdminTab: any;
  onEventCreation: any;
  setOfferRoleAction: any;
  offerRoleFormData: any;
  setActivePlan: any;
  resetEventTab: any;
  setSelectedRow: any;
  selectedUserNp?: any;
  location?:any;
  match?: any;
  // Customizable Area End
}

interface AttributesProps {
  non_profit_event_id: number;
  account_id: number;
  status: string;
  created_at: string;
  first_name: string;
  last_name: string;
  email: string;
  dbs_types: [];
  bio: string;
  contact_number: number;
  address: string;
  dbs_number: string;
}
export interface MyFormValues {
  first_name: string;
  last_name: string;
  email: string;
  role: any;
}
interface volunteerProps {
  id: number;
  type: string;
  attributes: AttributesProps;
}

interface DropdownOptions {
  organizationOptins: boolean;
  causeOptions: boolean;
  helpOptions: boolean;
  provideOptions: boolean;
  whereOptions: boolean;
  termsOptions: boolean;
}

interface ValidationErrors {
  organisation_name: boolean;
  description: boolean;
  email: boolean;
  contact_number: boolean;
  logo: boolean;
  website_url?: boolean;
  terms?: boolean;
}

interface DbsTypesProps {
  id: string | number;
  dbs_type_name: string;
  created_at: string;
  updated_at: string;
}

interface VolunteerPropsData {
  id: string;
  type: string;
  attributes: {
    organisation_id: string;
    account_id: string;
    status: string;
    created_at: string;
    first_name: string;
    last_name: string;
    email: string;
    dbs_types: DbsTypesProps;
    bio: string;
    contact_number: string;
    address: string;
    dbs_number: string;
  };
}

// Customizable Area Start
export interface BankDetails {
  bank_account_number: string;
  bank_code: string;
  mcc: string;
}
export interface BankAPIRes {
  data: DataReturn;
  message: string;
  status?: number;
  error?: string;
}
export interface DataReturn {
  account_id: string;
}

export interface GenerateAccountRes {
  url: string;
  message: string;
}

// Customizable Area End
interface S {
  openNoti: any;
  draft: {
    first_name: string;
    last_name: string;
    email: string;
    mobile: number | null;
    job_role: string;
    annual_turnover: string;
    change_password: string;
    confirm_password: string;
    avatar: string;
  };
  contactMsg: string;
  contactMessageError: string;
  contactValidation: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  dashboardData: any;
  createEventBtn: any;
  updateEventImg: any;
  dateCompareToggle: any;
  dateCompare: any;
  noteUpdate: any;
  eventLat: any;
  eventLong: any;
  updateEventImgPreview: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  mobileOpen: boolean;
  open: boolean;
  classes: any;
  theme?: any;
  imageFilesUpload: any;
  videoFilesUpload: any;
  isMobile: boolean;
  selectedTab: any;
  selectedBottomTab: any;
  slideIndex: any;
  value: number;
  setAnchorEl: any;
  openProfile: any;
  volunteerData: any;
  usersData: any;
  volunteerSearched: any;
  openModal: boolean;
  openContactusModal: boolean;
  openSuccessModal: boolean;
  isSubmitting: boolean;
  allNpEvents: any;
  filteredallNpEvents:any;
  allNeedsEvents: any;
  filteredAllNeedEvents:any;
  allProjectsEvents: any;
  allFilteredProjectEvents:any;
  allActivitiesEvents: any;
  allFilteredActivitiesEvents:any;
  singleEventDetails: any;
  errorPresent:boolean;
  pledgeId: any;
  eventType: string;
  projectUpdate: string;
  basicDBS: boolean;
  npNewStaffEmailNotificationFlag: any;
  updateNpRoleToCoordinator: any;
  openRolePresentModal: any;
  // basicDBSUpdate: boolean;
  stdDBS: boolean;
  // stdDBSUpdate: boolean;
  enhancedDBS: boolean;
  // enhancedDBSUpdate: boolean;
  enhancedDBSBnLChild: boolean;
  // enhancedDBSBnLChildUpdate: boolean;
  enhancedDBSBnLAdult: boolean;
  // enhancedDBSBnLAdultUpdate: boolean;
  enhancedDBSBnLChildnAdult: boolean;
  // enhancedDBSBnLChildnAdultUpdate: boolean;
  eventName: string;
  eventId: string;
  pledgesUser: any;
  eventNameValidation: boolean;
  eventLocationValidation: boolean;
  eventVolunteerValidation: boolean;
  eventFromDateValidation: boolean;
  eventToDateValidation: boolean;
  eventNameErrorMsg: string;
  eventLocationErrorMsg: string;
  eventFromDateErrorMsg: string;
  eventToDateErrorMsg: string;
  befriending: boolean;
  // befriendingUpdate: boolean;
  diy: boolean;
  // diyUpdate: boolean;
  environment: boolean;
  // environmentUpdate: boolean;
  elderly: boolean;
  // elderlyUpdate: boolean;
  gardening: boolean;
  // gardeningUpdate: boolean;
  mentoring: boolean;
  // mentoringUpdate: boolean;
  neighbourhood: boolean;
  // neighbourhoodUpdate: boolean;
  video_uploads: any;
  // dbsUpdate: boolean;
  upload_images: any;
  post_updates: any;
  planned_update_show: any;
  planned_virtual_update_show: any;
  all_day: boolean;
  multiImages: any;
  multiVideos: any;
  singleEventUpdates: any;
  eventUpdateError: any;
  eventUpdateErros: any;
  singleEventPledges: any;
  pledgedUserDetails: any;
  multiImagesPreview: any;
  dbs_registration: boolean;
  description: string;
  itinerarv_and_notes: string;
  volunteers_num: string;
  start_time: any;
  finish_time: any;
  from_date: any;
  updateFromDate: any;
  updateToDate: any;
  today: any;
  to_date: any;
  location: string;
  eventCategories: any[];
  needsEventImg: any;
  needsEventImgPreview: any;
  everyone: boolean;
  followers: boolean;
  beneficiaries: string;
  amount_required: string;
  pledgeToggle: any;
  project_delivered: string;
  registered_user: boolean;
  publish: any;
  activity_schedule: string;
  eventTabs: any;
  showEventsDropdown: any;
  dropdownInputBorder: any;
  createEventState: boolean;
  update_info: string;
  multiImageModal: any;
  projectNote: string;
  viewProjectNoteModal: any;
  editNoteModal: any;
  multiVideoModal: any;
  deleteConf: any;
  viewUpdatesModal: any;
  eventBtnTabs: any;
  eventSecondTabs: any;
  social_media1: string;
  social_media2: string;
  social_media3: string;
  searchAllEvents: string;
  searchNeedsEvents: string;
  searchActivitiesEvents: string;
  searchProjectsEvents: string;
  projectOutcome: string;
  registeredVolunteersAllDetails: any[];
  volunteersDetails: volunteerProps | null;
  offerAroleUser: any[];
  offerAroleUserFilterList: any[];
  offerAroleLoading: boolean;
  npUserOrganisation: any;
  registeredVolunteersLoading: boolean;
  anchorEl: any;
  secondTabForm: boolean;
  updateStartTime: any;
  startTimePost: any;
  endTimePost: any;
  updateFinishTime: any;
  NpOrganizationDropdown: DropdownOptions;
  NpOrganizationId: string;
  NpOrganizationDetailsData: any;
  loader: boolean;
  uploadPic: any;
  selectedeventsImage: any;
  NpOrgErrors: ValidationErrors;
  profileChangeErrors: {
    first_name: boolean;
    last_name: boolean;
    email: boolean;
    mobile: boolean;
    job_role: boolean;
    change_password: boolean;
    confirm_password: boolean;
  };
  openSaveModel:boolean;
  filteredVolunteerData: any;
  openRegisteredVolModel: boolean;
  individualRegiVoluDeatils: any;
  NpOrgFixedDetails: any;
  showSubscribe: boolean;
  daysRemaning: number;
  filterBroadcastModal: boolean;
  broadcastMsgCounter: string
  expanded: string | boolean;
  analyticsData: any;
  rowData: any;
  deleteNpStaffApiError: any;
  activePlanName: any,
  openCustomModal: boolean;
  updateStaffValueError: any;
  orgDeleteModal: boolean
  orgTypeOptions: any,
  causeTypeOptions: any
  helpTypeOptions: any
  providerTypeOptions: any
  whereTypeOptions: any
  allTypeOptions: any
  isCrossMember: boolean
  disableCheck1: boolean
  disableCheck2: boolean
  memberCrossSectorOptions: any
  slectedMemberCrossSector: any
  isDataPassed: boolean;
  orgEmailApiError: any;
  followersData: any;
  filteredFollowersData: any
  broadcastMsgData: any
  taskPreferenceData: any
  dbsData: any
  chatModalStatus: boolean;
  searchBar: boolean;
  taskPreferenceIds: any;
  dbsTypeIds: any;
  clearChat: boolean;
  deleteModal: boolean;
  totalDays: number;
  isBannerVisible: any;
  userId: any;
  bank_account_number: string;
  bank_code: string;
  mcc: string;
  day: number;
  month: number;
  year: number;
  street: string;
  town: string;
  post_code: string;
  country: string;
  website_url: string;
  first_name: string;
  last_name: string;
  openRedirectionModal: boolean;
  redirectionUrl: string;
  accStatus: string | null;
  reinitialize: boolean;
  date: Date | null;
  recallBankAPI: boolean;
  copyOfBankAccountNumber: string;
  copyOfBankCode: string;
  copyOfMerchantCode: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contactUsApiID: any;
  getNotificationListId: any;
  needsEventId: any;
  getAllEventsId: any;
  getActivitiesEventsId: any;
  getNeedsEventsId: any;
  getProjectsEventsId: any;
  searchAllEventsId: any;
  searchNeedsEventId: any;
  searchProjectsEventId: any;
  searchActivitiesEventId: any;
  deleteUserPledgeId: any;
  acceptUserPledgeId: any;
  updateEventId: any;
  deleteSpecEvent: any;
  deleteProjEvent: any;
  deleteActEvent: any;
  getSpecificEventId: any;
  getPledgedUser: any;
  getEventUpdatesListId: any;
  getPledgesId: any;
  getAnalyticsId: any;
  deleteStaffNpId: any;
  updateStaffNpId: any;
  projectImgObj: any = new Array();
  multiImagesdisplay = new Array();
  projectVideoObj: any = new Array();
  multiVideosDisplay = new Array();
  getVolunteersId: string = "";
  getVolunteersAccesptUserId: string = "";
  getVolunteersRemoveUserId: string = "";
  getOfferRoleUserId: string = "";
  getNPOrganisation: string = "";
  getNpOrganizationProfile: string = "";
  getUpdateProfileId: string = "";
  getNpOrganizationDataApiId: string = "";
  getEditNpOrganizationDataApiId: string = "";
  updateNPOBankDetails: string = "";
  generateAccountAPIId: string = "";
  getRegisteredVolunteerDataApiId: string = "";
  approveRegisteredVolunteerApiId: string = "";
  rejectRegisteredVolunteerApiId: string = "";
  _isMounted: any = false;
  searchRegisteredVolunteerApiId: string = "";
  deleteNpOrganisationApiCallId: string = "";
  getFreeTrialApiId: string = "";
  getOrgTypeId: string = "";
  allTypesOptionsAPICallId: string = "";
  getCrossMemberApiCallId: string = "";
  delFcmId: string = "";
  getFollowersDataApiCallId: string = "";
  getBroadcastDataApiCallId: string = "";
  createBroadcastDataApiCallId: string = "";
  groupChatApiCallId: string = "";
  getTaskPreferenceDataApiCallId: string = "";
  getDBSDataAPICallId: string = "";
  logoffApiCallId: string = "";
  directCommunicationId: string = "";
  directPledgeCommunicationId: string = "";
  directVolunteerCommunicationId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];
    this.state = {
      openNoti: false,
      draft: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: null,
        job_role: "",
        annual_turnover: "",
        change_password: "",
        confirm_password: "",
        avatar: "",
      },
      analyticsData: {},
      expanded: false,
      contactMsg: "",
      contactMessageError: "",
      contactValidation: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      eventLat: null,
      eventLong: null,
      noteUpdate: null,
      dateCompare: new Date(),
      dateCompareToggle: false,
      updateEventImg: "",
      updateEventImgPreview: "",
      openSaveModel:false,
      createEventBtn: false,
      dashboardData: [],
      errorMsg: "",
      value: 0,
      token: "",
      loading: false,
      pledgesUser: false,
      mobileOpen: false,
      open: false,
      classes: {},
      theme: {},
      imageFilesUpload: new Array(),
      videoFilesUpload: new Array(),
      isMobile: false,
      selectedTab: 0,
      selectedBottomTab: 0,
      slideIndex: 1,
      eventNameValidation: false,
      eventLocationValidation: false,
      eventVolunteerValidation: false,
      eventFromDateValidation: false,
      eventToDateValidation: false,
      eventNameErrorMsg: "",
      eventLocationErrorMsg: "",
      eventFromDateErrorMsg: "",
      eventToDateErrorMsg: "",
      setAnchorEl: null,
      openProfile: false,
      allNpEvents: {},
      filteredallNpEvents:[],
      allNeedsEvents: {},
      filteredAllNeedEvents:[],
      allProjectsEvents: {},
      allFilteredProjectEvents:[],
      allActivitiesEvents: {},
      allFilteredActivitiesEvents:[],
      singleEventDetails: {},
      eventUpdateError: "",
      eventUpdateErros: [],
      pledgeId: "",
      eventType: "",
      projectUpdate: "",
      basicDBS: false,
      errorPresent:false,
      stdDBS: false,
      enhancedDBS: false,
      enhancedDBSBnLChild: false,
      enhancedDBSBnLAdult: false,
      enhancedDBSBnLChildnAdult: false,
      npNewStaffEmailNotificationFlag: false,
      updateNpRoleToCoordinator: false,
      openRolePresentModal: false,
      // basicDBSUpdate:
      //   this.props.single_event_details?.data?.attributes?.basic_dbs,
      // stdDBSUpdate:
      //   this.props.single_event_details?.data?.attributes?.standard_dbs,
      // enhancedDBSUpdate:
      //   this.props.single_event_details?.data?.attributes?.enhanced_dbs,
      // enhancedDBSBnLChildUpdate:
      //   this.props.single_event_details?.data?.attributes
      //     ?.enhanced_dbs_and_bl_child,
      // enhancedDBSBnLAdultUpdate:
      //   this.props.single_event_details?.data?.attributes
      //     ?.enhanced_dbs_and_bl_adult,
      // enhancedDBSBnLChildnAdultUpdate:
      //   this.props.single_event_details?.data?.attributes
      //     ?.enhanced_dbs_and_bl_child_and_adult,
      eventName: "",
      eventId: "",
      befriending: false,
      diy: false,
      environment: false,
      elderly: false,
      gardening: false,
      mentoring: false,
      project_delivered: "",
      video_uploads: false,
      // dbsUpdate:
      //   this.props.single_event_details?.data?.attributes?.dbs_registration,
      // befriendingUpdate:
      //   this.props.single_event_details?.data?.attributes?.befriending,
      // diyUpdate: this.props.single_event_details?.data?.attributes?.diy,
      // environmentUpdate:
      //   this.props.single_event_details?.data?.attributes?.environment,
      // elderlyUpdate: this.props.single_event_details?.data?.attributes?.elderly,
      // gardeningUpdate:
      //   this.props.single_event_details?.data?.attributes?.gardenibg,
      // mentoringUpdate:
      //   this.props.single_event_details?.data?.attributes?.mentoring,
      // neighbourhoodUpdate:
      //   this.props.single_event_details?.data?.attributes?.neighourhood,
      upload_images: false,
      post_updates: false,
      planned_update_show: false,
      planned_virtual_update_show: false,
      neighbourhood: false,
      dbs_registration: false,
      description: "",
      itinerarv_and_notes: "",
      volunteers_num: "",
      start_time: null,
      finish_time: null,
      from_date: null,
      secondTabForm: true,
      updateFinishTime: null,
      updateStartTime: null,
      startTimePost: null,
      endTimePost: null,
      updateToDate: this.props?.single_event_details?.data?.attributes?.to_date,
      updateFromDate:
        this.props.single_event_details?.data?.attributes?.from_date,
      to_date: null,
      today: new Date(),
      all_day: false,
      location: "",
      eventCategories: [],
      projectOutcome: "",
      beneficiaries: "",
      amount_required: "",
      pledgeToggle: false,
      needsEventImg: "",
      needsEventImgPreview: "",
      everyone: false,
      followers: false,
      registered_user: false,
      publish: false,
      activity_schedule: "",
      eventTabs: "",
      showEventsDropdown: false,
      dropdownInputBorder: false,
      createEventState: false,
      update_info: "",
      multiImageModal: false,
      multiVideoModal: false,
      deleteConf: false,
      viewUpdatesModal: false,
      eventBtnTabs: "",
      eventSecondTabs: "",
      editNoteModal: false,
      projectNote: "",
      viewProjectNoteModal: false,
      social_media1: "",
      social_media2: "",
      social_media3: "",
      searchAllEvents: "",
      searchNeedsEvents: "",
      searchActivitiesEvents: "",
      searchProjectsEvents: "",
      multiImages: {},
      multiVideos: {},
      singleEventUpdates: [],
      singleEventPledges: [],
      pledgedUserDetails: {},
      multiImagesPreview: new Array(null),
      usersData: new Array(
        {
          FIRSTNAME: "Cadrion heart",
          LASTNAME: "Tua maunera",
          EMAILADDRESS: "james@gmail.com",
          DBSNUMBER: "123456",
          DBSTYPE: "Enhance DBS",
          LASTLOGIN: "22/12/2022 8pm",
          STATUS: "pending",
        },
        {
          FIRSTNAME: "Cadrion heart",
          LASTNAME: "Tua maunera",
          EMAILADDRESS: "james@gmail.com",
          DBSNUMBER: "123456",
          DBSTYPE: "Enhance DBS",
          LASTLOGIN: "22/12/2022 8pm",
          STATUS: "pending",
        },
        {
          FIRSTNAME: "Cadrion heart",
          LASTNAME: "Tua maunera",
          EMAILADDRESS: "james@gmail.com",
          DBSNUMBER: "123456",
          DBSTYPE: "Enhance DBS",
          LASTLOGIN: "22/12/2022 8pm",
          STATUS: "pending",
        },
        {
          FIRSTNAME: "Cadrion heart",
          LASTNAME: "Tua maunera",
          EMAILADDRESS: "james@gmail.com",
          DBSNUMBER: "123456",
          DBSTYPE: "Enhance DBS",
          LASTLOGIN: "22/12/2022 8pm",
          STATUS: "pending",
        },
        {
          FIRSTNAME: "Cadrion heart",
          LASTNAME: "Tua maunera",
          EMAILADDRESS: "james@gmail.com",
          DBSNUMBER: "123456",
          DBSTYPE: "Enhance DBS",
          LASTLOGIN: "22/12/2022 8pm",
          STATUS: "pending",
        }
      ),
      // volunteerData: new Array(
      //   {
      //     FIRSTNAME: "Cadrion heart",
      //     LASTNAME: "Tua maunera",
      //     EMAILADDRESS: "james@gmail.com",
      //     DBSNUMBER: "123456",
      //     DBSTYPE: "Enhance DBS",
      //     LASTLOGIN: "22/12/2022 8pm",
      //     STATUS: "pending",
      //   },
      //   {
      //     FIRSTNAME: "Cadrion heart",
      //     LASTNAME: "Tua maunera",
      //     EMAILADDRESS: "james@gmail.com",
      //     DBSNUMBER: "123456",
      //     DBSTYPE: "Enhance DBS",
      //     LASTLOGIN: "22/12/2022 8pm",
      //     STATUS: "pending",
      //   },
      //   {
      //     FIRSTNAME: "Cadrion heart",
      //     LASTNAME: "Tua maunera",
      //     EMAILADDRESS: "james@gmail.com",
      //     DBSNUMBER: "123456",
      //     DBSTYPE: "Enhance DBS",
      //     LASTLOGIN: "22/12/2022 8pm",
      //     STATUS: "pending",
      //   },
      //   {
      //     FIRSTNAME: "Cadrion heart",
      //     LASTNAME: "Tua maunera",
      //     EMAILADDRESS: "james@gmail.com",
      //     DBSNUMBER: "123456",
      //     DBSTYPE: "Enhance DBS",
      //     LASTLOGIN: "22/12/2022 8pm",
      //     STATUS: "pending",
      //   },
      //   {
      //     FIRSTNAME: "Cadrion heart",
      //     LASTNAME: "Tua maunera",
      //     EMAILADDRESS: "james@gmail.com",
      //     DBSNUMBER: "123456",
      //     DBSTYPE: "Enhance DBShttps://a5c9-20-204-223-174.ngrok-free.app",
      //     LASTLOGIN: "22/12/2022 8pm",
      //     STATUS: "pending",
      //   },
      //   {
      //     FIRSTNAME: "Cadrion heart",
      //     LASTNAME: "Tua maunera",
      //     EMAILADDRESS: "james@gmail.com",
      //     DBSNUMBER: "123456",
      //     DBSTYPE: "Enhance DBS",
      //     LASTLOGIN: "22/12/2022 8pm",
      //     STATUS: "pending",
      //   }
      // ),
      volunteerData: [],
      volunteerSearched: new Array(),
      openModal: false,
      openContactusModal: false,
      openSuccessModal: false,
      isSubmitting: false,
      registeredVolunteersAllDetails: [],
      volunteersDetails: null,
      offerAroleUser: [],
      offerAroleUserFilterList: [],
      offerAroleLoading: false,
      npUserOrganisation: {
        first_name: "",
        last_name: "",
        email: "",
        role: "",
      },
      registeredVolunteersLoading: false,
      anchorEl: null,
      NpOrganizationDropdown: {
        organizationOptins: false,
        causeOptions: false,
        helpOptions: false,
        provideOptions: false,
        whereOptions: false,
        termsOptions: false,
      },
      NpOrganizationId: "",
      NpOrganizationDetailsData: {},
      NpOrgFixedDetails: {},
      loader: true,
      uploadPic: "",
      selectedeventsImage: "",
      NpOrgErrors: {
        organisation_name: false,
        description: false,
        email: false,
        contact_number: false,
        logo: false,
        terms: false,
      },
      profileChangeErrors: {
        first_name: false,
        last_name: false,
        email: false,
        mobile: false,
        job_role: false,
        change_password: false,
        confirm_password: false,
      },
      filteredVolunteerData: [],
      openRegisteredVolModel: false,
      individualRegiVoluDeatils: {},
      showSubscribe: false,
      daysRemaning: 0,
      filterBroadcastModal: false,
      broadcastMsgCounter: '',
      rowData: {},
      deleteNpStaffApiError: "",
      activePlanName: "Trial-NP",
      openCustomModal: false,
      updateStaffValueError: "",
      orgDeleteModal: false,
      orgTypeOptions: [],
      causeTypeOptions: [],
      helpTypeOptions: [],
      providerTypeOptions: [],
      whereTypeOptions: [],
      allTypeOptions: [],
      isCrossMember: false,
      disableCheck1: false,
      disableCheck2: false,
      memberCrossSectorOptions: [],
      slectedMemberCrossSector: "",
      isDataPassed: false,
      orgEmailApiError: "",
      followersData: [],
      filteredFollowersData: [],
      broadcastMsgData: [],
      taskPreferenceData: [],
      dbsData: [],
      chatModalStatus: false,
      searchBar: false,
      taskPreferenceIds: '',
      dbsTypeIds: '',
      clearChat: false,
      deleteModal: false,
      totalDays: 0,
      userId: "",
      isBannerVisible: localStorage.getItem("showSubscribe"),
      bank_account_number: "",
      bank_code: "",
      mcc: "",
      street: "",
      town: "",
      post_code: "",
      country: "",
      website_url: "",
      first_name: "",
      last_name: "",
      day: 0,
      month: 0,
      year: 0,
      redirectionUrl: "",
      accStatus: null,
      openRedirectionModal: false,
      reinitialize: false,
      date: null,
      recallBankAPI: false,
      copyOfBankAccountNumber: "",
      copyOfBankCode: "",
      copyOfMerchantCode: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  handleNeedsEventResponse(responseJson: any) {
    this.setState({ createEventBtn: false });
    if (!responseJson?.meta) {
      console.log(responseJson?.errors[0].token, 'responsejsno123')
      responseJson.error
        ? this.setState({ eventUpdateError: responseJson?.error })
        : this.setState({ eventUpdateError: responseJson?.errors[0].token ? responseJson?.errors[0].token : responseJson?.errors[0].invalid_date })
    }
    else if (responseJson.data.attributes.event_type === "need") {
      this.props.navigationToNeeds();
    } else if (responseJson.data.attributes.event_type === "project_funding") {
      this.props.navigationToProjectsList();
    } else if (responseJson.data.attributes.event_type === "activity") {
      this.props.navigationToActivitiesList();
    }
  }

  handleGetVolunteersResponse(responseJson: any) {
    this.setState({ registeredVolunteersLoading: false });
    this.setState({
      registeredVolunteersAllDetails: responseJson?.data,
    });
  }

  getVolunteersUserResponse(responseJson: any) {
    if (responseJson) {
      this.handleClose();
      this.getAllVolunteersData();
    }
  }

  getOfferRoleUserIdResponse(responseJson: any) {
    console.log(responseJson, "responseakhilesh");
    this.setState({ offerAroleLoading: false });
    if (responseJson.data) {
      this.setState({
        offerAroleUserFilterList: responseJson?.data,
        offerAroleUser: responseJson?.data,
      });
    }
  }

  // For adding new np staff members
  getNPOrganisationResponse(responseJson: any) {
    if (responseJson && responseJson?.data) {
      this.setState({ npNewStaffEmailNotificationFlag: true })
    } else {
      if (responseJson?.errors && responseJson?.errors[0].email) {
        this.setState({ orgEmailApiError: responseJson?.errors[0].email })
      } else {
        this.setState({ npNewStaffEmailNotificationFlag: true });
      }
    }
  }

  // For close modal after clicking save Search for adding new member
  handleEmailNotificationModalClose = () => {
    this.props.history.push("UsersNP");
    this.setState({ npNewStaffEmailNotificationFlag: false, updateNpRoleToCoordinator: false })
  }

  // For managing orgEmailState during email onchange
  handleResetEmailApiError = () => {
    this.setState({ orgEmailApiError: "" })
  }

  handleStateCommonFun(responseJson: any) {
    this.setState({
      allNpEvents: responseJson,
      filteredallNpEvents: responseJson.data,
    });
  }

  handleAllNeedsEvents(responseJson: any) {
    this.setState({
      allNeedsEvents: responseJson,
      filteredAllNeedEvents:responseJson.data
    });
  }

  handleAllProjectsEvents(responseJson: any) {
    this.setState({
      allProjectsEvents: responseJson,
      allFilteredProjectEvents:responseJson.data
    });
  }

  handleActivitiesEvents(responseJson: any) {
    this.setState({
      allActivitiesEvents: responseJson,
      allFilteredActivitiesEvents: responseJson.data,
    });
  }

  getSpecificEventIdResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      this.setState({
        singleEventDetails: responseJson,
        projectUpdate: responseJson.data.attributes.project_update ? "true" : "false"
      });
    }
  }

  getPledgesIdResponse(responseJson: any) {
    if (responseJson && responseJson?.data) {
      this.setState({
        singleEventPledges: responseJson,
      });
    }
    this.setState({
      singleEventDetails: responseJson,
    });
    this.props.navigationToNeeds();
  }

  handleError = (responseJson:any) =>{     
     if(responseJson && responseJson?.error ){
      this.setState({errorPresent:true})
     }
  }
  handleTabs = () => {
    this.setState({ eventTabs: '' })
    console.log('workingggggggggggggggggggg', this.state.eventTabs)
  }

  handleAllApiExtn2(apiRequestCallId: any, responseJson: any) {
    if (this.needsEventId === apiRequestCallId) {
      this.handleNeedsEventResponse(responseJson);
    }
    if (apiRequestCallId === this.deleteSpecEvent) {
      this.props.navigationToNeeds();
    }
    if (this.deleteProjEvent === apiRequestCallId) {
      this.props.navigationToProjectsList()
    }
    if (apiRequestCallId === this.deleteActEvent) {
      this.props.navigationToActivitiesList()
    }

    if (this.updateEventId === apiRequestCallId) {
      this.handleNeedsEventResponse(responseJson);
    }

    if (apiRequestCallId === this.getAllEventsId) {
      this.handleStateCommonFun(responseJson);
    }

    if (apiRequestCallId === this.getNeedsEventsId) {
      this.handleAllNeedsEvents(responseJson);
    }
    if (apiRequestCallId === this.getProjectsEventsId) {
      this.handleAllProjectsEvents(responseJson);
    }
    if (apiRequestCallId === this.getActivitiesEventsId) {
      this.handleActivitiesEvents(responseJson);
    }
    if (apiRequestCallId === this.searchAllEventsId) {
      this.handleStateCommonFun(responseJson);
    }
  }

  handleAllApiExtn(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.searchNeedsEventId) {
      this.handleAllNeedsEvents(responseJson);
    }

    if (apiRequestCallId === this.searchProjectsEventId) {
      this.handleAllProjectsEvents(responseJson);
    }
    if (apiRequestCallId === this.searchActivitiesEventId) {
      this.handleActivitiesEvents(responseJson);
    }
    if (apiRequestCallId === this.getEventUpdatesListId) {
      this.setState({ singleEventUpdates: responseJson });
    }
    if (apiRequestCallId === this.getSpecificEventId) {
      this.getSpecificEventIdResponse(responseJson);
    }

    if (apiRequestCallId === this.getPledgedUser) {
      this.setState({ pledgedUserDetails: responseJson });
    }
    if(apiRequestCallId === this.logoffApiCallId) {
      this.props.setSelectedAdminTab("Dashboard")
      this.props.navigation.navigate('NpSignIn')
    }
    if(apiRequestCallId === this.directCommunicationId){
      if (responseJson.status === 500) {
        toast.error(`Internal server error`);
      }  else {
        this.onViewClick(responseJson.data[0].attributes.sid)
      }
    }
    if(apiRequestCallId === this.directPledgeCommunicationId){ 
        this.onViewClick(responseJson.data[0].attributes.sid)
    }
    if(apiRequestCallId === this.directVolunteerCommunicationId){ 
      this.onViewClick(responseJson.data[0].attributes.sid)
  }
  }

  handleAllAPIResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getPledgesId) {
      this.handleError(responseJson);
      let apiResponse = responseJson.data.filter((item:any) =>  item.attributes.status !== "Cancelled")
      this.setState({ singleEventPledges: {data: apiResponse} });
    }
    if (apiRequestCallId === this.getAnalyticsId) {
      this.setState({ analyticsData: responseJson?.data })
    }

    if (apiRequestCallId === this.acceptUserPledgeId) {
      console.log(responseJson, 'response1234')
      const pledgedUserId = localStorage.getItem('pledgedUserId');
      this.getAllPledges(pledgedUserId)
      this.addUserInGroup();
    }
    if (apiRequestCallId === this.deleteUserPledgeId) {
      console.log(responseJson, 'response1234')
      const pledgedUserId = localStorage.getItem('pledgedUserId');
      this.getAllPledges(pledgedUserId)
    }

    if (this.getVolunteersId === apiRequestCallId) {
      this.handleGetVolunteersResponse(responseJson);
    }

    if (this.getVolunteersAccesptUserId === apiRequestCallId) {
      this.getVolunteersUserResponse(responseJson);
    }

    if (this.getVolunteersRemoveUserId === apiRequestCallId) {
      this.getVolunteersUserResponse(responseJson);
    }

    if (this.getOfferRoleUserId === apiRequestCallId) {
      this.getOfferRoleUserIdResponse(responseJson);
    }

    if (this.getNPOrganisation === apiRequestCallId) {
      this.getNPOrganisationResponse(responseJson);
    }

    if (this.contactUsApiID === apiRequestCallId && responseJson.data) {
      this.handleContactusClose();
      this.setState({ contactMsg: "", isSubmitting: false })
      this.handleSuccessModalOpen()
    } else {
      console.log("error contactus NP", responseJson);
      this.setState({ isSubmitting: false })
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message?.id) {
      const userId = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      this.onNavigateFromRegisteredVolunteers(userId);
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      this.handleDeleteNpStaff(apiRequestCallId, responseJson);
      this.handleUpdateNpStaff(apiRequestCallId, responseJson);

      if (apiRequestCallId == this.getFreeTrialApiId) {
        this.handleFreeTriallResponse(responseJson, errorReponse);
      }

      if (responseJson !== undefined) {
        this.handleAllAPIResponse(apiRequestCallId, responseJson);
        this.handleAllApiExtn(apiRequestCallId, responseJson);
        this.handleAllApiExtn2(apiRequestCallId, responseJson);
        this.handleAllOrganisationAPIResponse(apiRequestCallId, responseJson);
        this.handleAllOrganisationOptionsAPIResponse(apiRequestCallId, responseJson)
      } else {
        this.setState({ isSubmitting: false })
        console.log("API Error", errorReponse)
      }
      if (apiRequestCallId === this.getNpOrganizationProfile) {
        this.handleAllUserProfileAPIResponse(responseJson, errorReponse)
      }
      if (apiRequestCallId === this.getUpdateProfileId) {
        this.handleAllUserProfileAPIResponse(responseJson, errorReponse)
        this.handleOpenSaveModel();
      }
      if (apiRequestCallId == this.getFreeTrialApiId) {
        this.handleFreeTriallResponse(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.deleteNpOrganisationApiCallId) {
        this.singnout();
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({
        enableField: !this.state.enableField,
      });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({
      enableField: !this.state.enableField,
    });
  };

  // Customizable Area Start

  onViewClick = (sid: any) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Communication");
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), sid);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }
  handleMessageClick = (e: any, item:any) => {
    e.stopPropagation()
    this.directCommunication(item.id, "non_profit_entity")
  }
  handleNavigate = (e: any, item:any) => {
    e.stopPropagation()
    this.props.navigation.navigate("Communication")
    this.onViewClick(item.attributes?.sid)
  }
  onNavigateFromRegisteredVolunteers = (userId: any) => {
    if (this.state.userId === "" || (this.state.userId !== "" && userId)) {
      const path = window.location.pathname;
      this.setState({ userId }, () => {
        this.refactorAPICall(path);
      });
    }
  }
  refactorAPICall(path: string) {
    if (path.includes('Organisation')) {
      this.getNpOrganizationData();
      this.getOrgTypeOptions();
      this.getAllTypeOptions();
      this.getMembersOption();
    } else if (path.includes('RegisteredVolunteers')) {
      this.getRegisteredVolunteerData()
    } else if (path.includes('FollowersNP')) {
      this.getFollowersData()
    } else if (path.includes('ContentEvent')) {
      this.getEvents();
      this.getActivitiesEvents();
      this.getNeedsEvents();
      this.getProjectsEvents();
      this.getTaskPreferncesData()
      this.getDBSData();
    } else if (path.includes('ContentManagementDashBoard')) {
      this.getOrgAnalytics()
      this.getBroadcastsMsgs()
      this.getFreeTrialData()
    }
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    if(this.props.projectUpdateProp){
      this.onSecondTabs(this.props.single_event_details.data.id)
    }

    const path = window.location.pathname;
    this.refactorAPICall(path)
    this.getNpProfileData()
    if (localStorage.getItem('nonprofitToken') === null && localStorage.getItem('user_type') !== 'Non profit entity') {
      localStorage.clear()
      this.props.navigation.navigate('NpSignIn')
    }
    let eventID = localStorage.getItem('eventID')
    console.log(eventID, 'eventID')
    this._isMounted = true;
    // this for initial events Loads
    for (const key in this.props.single_event_details?.data?.attributes) {
      // @ts-ignore
      const propKey = this.propStateMapping()[key] || key;
      // @ts-ignore
      this.setState({
        [propKey]: this.props.single_event_details?.data?.attributes[key],
      });
    }
    if (this.props.single_event_details?.data?.attributes.publishing_scope === "everyone_else") {
      this.setState({ followers: true, everyone: true, registered_user: true })
    } else if (this.props.single_event_details?.data?.attributes.publishing_scope === "followers_and_registered_users") {
      this.setState({ followers: true, everyone: false, registered_user: true })
    } else if (this.props.single_event_details?.data?.attributes.publishing_scope === "followers") {
      this.setState({ followers: true, everyone: false, registered_user: false })
    } else if (this.props.single_event_details?.data?.attributes.publishing_scope === "registered_users") {
      this.setState({ followers: false, everyone: false, registered_user: true })
    }
    this.setState({
      npUserOrganisation: {
        ...this.props?.offerRoleFormData?.offerRoleFormData,
      },
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    if (this.props?.single_event_details?.data.id) {
      this.getEventUpdates(this.props.single_event_details.data.id);
      this.getAllPledges(this.props.single_event_details.data.id);
    } 

    this.setState({
      upload_images:
        this.props.single_event_details?.data?.attributes?.upload_images,
      video_uploads:
        this.props.single_event_details?.data?.attributes?.video_uploads,
      // dbsUpdate:
      //   this.props.single_event_details?.data?.attributes?.dbs_registration,
    });
    this.setState({
      updateEventImg:
        this.props.single_event_details?.data?.attributes?.event_image.url,
    });

    // Customizable Area End
  }

  resize() {
    this.setState({
      isMobile: window.innerWidth <= 760 ? true : false,
    });
    if (window.innerWidth >= 760) {
      this.setState({ open: true });
      this.setState({ isMobile: true });
    }
  }

  componentWillUnmount(): any {
    this._isMounted = false;
    window.removeEventListener("resize", this.resize.bind(this));
  }

  componentDidUpdate(prevProps: any, prevState: any): any {
    this._isMounted = true;

    const prev = prevProps.single_event_details; // Assuming 'singleEventDetails' is the prop containing previous data
    const curr = this.props.single_event_details; // Assuming 'singleEventDetails' is the prop containing current data

    this.checkForDifferences(prev, curr);
    if (this.state.to_date && this.state.from_date > this.state.to_date) {
      this.setState({ to_date: null });
    }
    if(this.state.reinitialize){
      this.setState({ reinitialize: false})
    }

    const eventTabs = window.localStorage.getItem("eventTabs");
    const secondTabs = window.localStorage.getItem("secondTabs");
    if (eventTabs !== this.state.eventBtnTabs) {
      this.setState({ eventBtnTabs: eventTabs });
      this.setState({ eventSecondTabs: secondTabs });
    }
    if (this.state.NpOrganizationId !== prevState.NpOrganizationId) {
      this.getRegisteredVolunteerData();
    }
    if (this.state.allTypeOptions !== prevState.allTypeOptions) {
      this.setState({
        causeTypeOptions: this.state.allTypeOptions?.cause?.map((it: any) => {
          return {
            label: it?.value,
            value: it?.value,
            name: 'cause'
          }
        }),
        providerTypeOptions: this.state.allTypeOptions?.what_we_provide?.map((it: any) => {
          return {
            label: it?.value,
            value: it?.value,
            name: 'what_we_provide'
          }
        }),
        helpTypeOptions: this.state.allTypeOptions?.who_we_help?.map((it: any) => {
          return {
            label: it?.value,
            value: it?.value,
            name: 'who_we_help'
          }
        }),
        whereTypeOptions: this.state.allTypeOptions?.where?.map((it: any) => {
          return {
            label: it?.value,
            value: it?.value,
            name: 'where'
          }
        }),
      })
    }
  }

  propStateMapping = () => {
    return {
      event_name: "eventName",
      dbs_registration: "dbs_registration",
      befriending: "befriending",
      diy: "diy",
      environment: "environment",
      elderly: "elderly",
      gardenibg: "gardening",
      mentoring: "mentoring",
      neighourhood: "neighbourhood",
      description: "description",
      basic_dbs: "basicDBS",
      standard_dbs: "stdDBS",
      enhanced_dbs: "enhancedDBS",
      enhanced_dbs_and_bl_adult: "enhancedDBSBnLAdult",
      enhanced_dbs_and_bl_child: "enhancedDBSBnLChild",
      enhanced_dbs_and_bl_child_and_adult: "enhancedDBSBnLChildnAdult",
      event_image: "updateEventImg",
      event_note: "noteUpdate",
      project_outcomes: "projectOutcome",
      beneficiaries: "beneficiaries",
      target_amount: "amount_required",
      project_delivered: "project_delivered",
      video_uploads: "video_uploads",
      upload_images: "upload_images",
      activity_schedule: "activity_schedule",
      post_updates: "post_updates",
      planned_update_show: "planned_update_show",
      planned_virtual_update_show: "planned_virtual_update_show",
      social_media1: "social_media1",
      social_media3: "social_media3",
      social_media2: "social_media2",
      max_attendence: "volunteers_num",
      itinerarv_and_notes: "itinerarv_and_notes",
      from_date: "updateFromDate",
      to_date: "updateToDate",
      start_time: "updateStartTime",
      end_time: "updateFinishTime",
      location: "location",
      publish: "publish",
    };
  };

  checkForDifferences(
    prev: { data: { attributes: any } },
    curr: { data: { attributes: any } }
  ) {
    const prevAttributes = prev?.data?.attributes;
    const currAttributes = curr?.data?.attributes;

    if (prevAttributes?.publishing_scope !== currAttributes?.publishing_scope) {
      if (currAttributes.publishing_scope === "everyone_else") {
        this.setState({ followers: true, everyone: true, registered_user: true })
      } else if (currAttributes.publishing_scope === "followers_and_registered_users") {
        this.setState({ followers: true, everyone: false, registered_user: true })
      } else if (currAttributes.publishing_scope === "followers") {
        this.setState({ followers: true, everyone: false, registered_user: false })
      } else if (currAttributes.publishing_scope === "registered_users") {
        this.setState({ followers: false, everyone: false, registered_user: true })
      }
    }

    for (const key in prevAttributes) {
      if (
        prevAttributes.hasOwnProperty(key) &&
        prevAttributes[key] !== currAttributes[key]
      ) {
        // @ts-ignore
        const propKey = this.propStateMapping()[key] || key;
        // @ts-ignore
        this.setState({ [propKey]: currAttributes[key] });
      }
    }
  }

  // proper search filter
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      setAnchorEl: this.state.setAnchorEl ? null : event.currentTarget,
    });
  };

  requestSearchVolunteer = (searchedVal: string) => {
    const filteredRows = this.state.volunteerData.filter(
      (row: any) =>
        row.attributes.first_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.attributes.last_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.attributes.email?.toLowerCase().includes(searchedVal.toLowerCase())
    );
    this.setState({
      filteredVolunteerData: filteredRows,
    });
  };

  requestSearchFollowers = (seachVal: string) => {
    const filteredRows = this.state.followersData.filter(
      (row: any) =>
        row.attributes.full_name
          ?.toLowerCase()
          .includes(seachVal.toLowerCase()) ||
        row.attributes.location?.toLowerCase().includes(seachVal.toLowerCase())
    );
    this.setState({
      filteredFollowersData: filteredRows,
    });
  }

  validateTime = (isSameDate: boolean) => {
    let startTime = this.state.start_time;
    if (isSameDate && this.state.start_time?.isBefore(moment())) {
      startTime = new Date(Date.now());
      this.setState({ start_time: startTime });
    }
    if (isSameDate && this.state.finish_time?.isBefore(startTime)) {
      this.setState({ finish_time: moment("00:00:00", 'HH:mm:ss') });
    }
  }

  onToDateChange = (date: any) => {
    this.setState({ to_date: date });
    const isSameDate = date.isSame(moment(), "day") && this.state.from_date?.isSame(moment(), "day");
    this.validateTime(isSameDate);
  }

  onFromDateChange = (date: any) => {
    this.setState({ from_date: date });
    const isSameDate = date.isSame(moment(), "day") && this.state.to_date?.isSame(moment(), "day");
    this.validateTime(isSameDate);
  }

  onStartTimeChange = (time: any) => {
    const isSameDate = this.state.from_date?.isSame(moment(), "day") && this.state.to_date?.isSame(moment(), "day");
    if (isSameDate && time.isBefore(moment())) {
      this.setState({ start_time: moment() });
    } else {
      this.setState({ start_time: time });
    }
    if (isSameDate && this.state.finish_time?.isBefore(time)) {
      this.setState({ finish_time: time });
    }
  }

  onFinishTimeChange = (time: any) => {
    if (this.state.start_time) {
      const isSameDate = this.state.from_date?.isSame(moment(), "day") && this.state.to_date?.isSame(moment(), "day");
      if (isSameDate && time.isBefore(this.state.start_time)) {
        this.setState({ finish_time: moment("00:00:00", 'HH:mm:ss') });
      } else {
        this.setState({ finish_time: time });
      }
    }
  }

  handleRowClick = (item: any) => {
    localStorage.removeItem("np_staff_role");
    localStorage.setItem("np_staff_id", item?.attributes?.id);
    localStorage.setItem("np_staff_role", item?.attributes?.role);
    this.props.setSelectedRow(item);
    this.props.navigation.navigate("OrganisationUserNP");
  };

  handleOpen = (item: any) => {
    this.setState({ openModal: true });
    if (item.attributes.status === "rejected") {
      this.setState({ openModal: true });
    } else {
      this.setState({ volunteersDetails: item });
      if (isMobile) {
        this.props.navigation.navigate("NpChat");
      } else {
        this.setState({ openModal: true });
      }
    }
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  handleCancelSearch = () => {
    console.log('Cancel')
  }

  handleSuccessModalOpen = () => {
    this.setState({ openSuccessModal: true, everyone: false, followers: false, registered_user: false });
  };

  handleSuccessModalClose = () => {
    this.setState({ openSuccessModal: false });
  };

  handleContactusOpen = (item: any) => {
    this.handlePopoverClose()
    this.setState({ openContactusModal: true });
  };

  handleContactusClose = () => {
    this.setState({ isSubmitting: false, openContactusModal: false, contactMsg: "", contactMessageError: "" });
  };

  validateContactMessage(value: any) {
    if (/^\s*$/.test(value.trim())) {
      this.setState({ isSubmitting: false, contactMessageError: 'Please enter message', contactValidation: false })
    } else {
      this.setState({ contactMessageError: "", contactValidation: true })
    }
  }

  acceptUserPledge = (id: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem('nonprofitToken')
    };


    const acceptPledge = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptUserPledgeId = acceptPledge.messageId;

    acceptPledge.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptPledgeEndPoint
    );

    acceptPledge.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    acceptPledge.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ pledge_id: id })
    );
    acceptPledge.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    runEngine.sendMessage(acceptPledge.id, acceptPledge);
    return true;
  };

  checkBoxConditions = (updateEventFormData: any) => {
    if (this.state.everyone) {
      updateEventFormData.append("non_profit_event[publishing_scope]", "everyone_else");
    } else if (this.state.followers && this.state.registered_user) {
      updateEventFormData.append("non_profit_event[publishing_scope]", "followers_and_registered_users");
    } else if (this.state.registered_user) {
      updateEventFormData.append("non_profit_event[publishing_scope]", "registered_users");
    } else if (this.state.followers) {
      updateEventFormData.append("non_profit_event[publishing_scope]", "followers");
    }
  }
  updateEvent = (id: any) => {
    const header = {
      // "Content-Type": configJSON.apiContentType,
    };
    const updateEvent = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateEventId = updateEvent.messageId;

    updateEvent.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventCreationEndPoint}/${id}`
    );

    updateEvent.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let updateEventFormData = new FormData();

    //formData
    this.updateEventNameToVideo(updateEventFormData);

    this.projectOutcomeToEvent(updateEventFormData);

    updateEventFormData.append(
      "non_profit_event[planned_virtual_update_show]",
      this.state.planned_virtual_update_show
    );
    updateEventFormData.append("non_profit_event[project_update]", this.state.projectUpdate);

    this.state.social_media1 &&
      updateEventFormData.append(
        "non_profit_event[social_media1]",
        this.state.social_media1
      );
    this.state.social_media2 &&
      updateEventFormData.append(
        "non_profit_event[social_media2]",
        this.state.social_media2
      );
    this.state.social_media3 &&
      updateEventFormData.append(
        "non_profit_event[social_media3]",
        this.state.social_media3
      );
      updateEventFormData.append(
        "non_profit_event[task_preference_ids]",
        this.state.taskPreferenceIds
      );
  
      updateEventFormData.append(
        "non_profit_event[dbs_type_ids]",
        this.state.dbsTypeIds
      );

    updateEventFormData.append(
      "non_profit_event[dbs_registration]",
      // @ts-ignore
      this.state.dbs_registration
    );
    this.state.volunteers_num &&
      updateEventFormData.append(
        "non_profit_event[max_attendence]",
        this.state.volunteers_num
      );
    this.state.description &&
      updateEventFormData.append(
        "non_profit_event[description]",
        this.state.description
      );
    this.state.itinerarv_and_notes &&
      updateEventFormData.append(
        "non_profit_event[itinerarv_and_notes]",
        this.state.itinerarv_and_notes
      );
    updateEventFormData.append(
      "non_profit_event[basic_dbs]",
      // @ts-ignore
      this.state.basicDBS
    );
    updateEventFormData.append(
      "non_profit_event[standard_dbs]",
      // @ts-ignore
      this.state.stdDBS
    );
    updateEventFormData.append(
      "non_profit_event[enhanced_dbs]",
      // @ts-ignore
      this.state.enhancedDBS
    );
    updateEventFormData.append(
      "non_profit_event[enhanced_dbs_and_bl_adult]",
      // @ts-ignore
      this.state.enhancedDBSBnLAdult
    );
    updateEventFormData.append(
      "non_profit_event[enhanced_dbs_and_bl_child]",
      // @ts-ignore
      this.state.enhancedDBSBnLChild
    );
    updateEventFormData.append(
      "non_profit_event[enhanced_dbs_and_bl_child_and_adult]",
      // @ts-ignore
      this.state.enhancedDBSBnLChildnAdult
    );

    this.checkBoxConditions(updateEventFormData)


    moment(this.state.updateFromDate).isAfter(moment(this.state.today).format('DD/MM/YYYY')) === true && updateEventFormData.append(
      "non_profit_event[from_date]",
      moment(this.state.updateFromDate).format("DD/MM/YYYY")
    );

    updateEventFormData.append(
      "non_profit_event[to_date]",
      moment(this.state.updateToDate).format("DD/MM/YYYY")
    );
    this.state.startTimePost &&
      updateEventFormData.append(
        "non_profit_event[start_time]",
        this.state.startTimePost
      );
    this.state.endTimePost &&
      updateEventFormData.append(
        "non_profit_event[end_time]",
        this.state.endTimePost
      );
    this.state.location &&
      updateEventFormData.append(
        "non_profit_event[location]",
        this.state.location
      );
    // @ts-ignore
      updateEventFormData.append(
        "non_profit_event[publish]",
        this.state.publish.toString()
      );
    const org_id = localStorage.getItem("org_id");
    updateEventFormData.append(
      "non_profit_event[organisation_id]",
      String(org_id)
    );
    const nonprofitToken = localStorage.getItem('nonprofitToken')

    updateEventFormData.append(
      "token",
      String(nonprofitToken)
    );

    updateEventFormData.append(
      "non_profit_event[update_info]",
      this.state.update_info
    );

    updateEvent.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify(updateEventFormData)
      updateEventFormData
    );
    updateEvent.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    runEngine.sendMessage(updateEvent.id, updateEvent);
    return true;
  };

  updateEventNameToVideo(updateEventFormData: any) {
    this.state.eventName &&
      updateEventFormData.append(
        "non_profit_event[event_name]",
        this.state.eventName
      );
    updateEventFormData.append(
      "non_profit_event[event_type]",
      this.props.single_event_details.data?.attributes.event_type
    );
    this.state.updateEventImgPreview &&
      updateEventFormData.append(
        "non_profit_event[event_image]",
        this.state.updateEventImgPreview
      );

    this.projectVideoObj &&
      updateEventFormData.append(
        "non_profit_event[videos]",
        this.projectVideoObj
      );
  }

  projectOutcomeToEvent(updateEventFormData: any) {
    this.state.projectOutcome &&
      updateEventFormData.append(
        "non_profit_event[project_outcomes]",
        this.state.projectOutcome
      );
    this.state.activity_schedule &&
      updateEventFormData.append(
        "non_profit_event[activity_schedule]",
        this.state.activity_schedule
      );
    this.state.beneficiaries &&
      updateEventFormData.append(
        "non_profit_event[beneficiaries]",
        this.state.beneficiaries
      );
    this.state.amount_required &&
      updateEventFormData.append(
        "non_profit_event[target_amount]",
        this.state.amount_required
      );
    this.state.project_delivered &&
      updateEventFormData.append(
        "non_profit_event[project_delivered]",
        this.state.project_delivered
      );
    this.state.video_uploads &&
      updateEventFormData.append(
        "non_profit_event[video_uploads]",
        this.state.video_uploads
      );

    this.state.imageFilesUpload.map((item: any) => {
      updateEventFormData.append(
        `non_profit_event[imgs_attributes][][image]`,
        item
      );
    });
    this.state.projectNote &&
      updateEventFormData.append(
        "non_profit_event[event_notes_attributes][][event_note]",
        this.state.projectNote
      );

    this.state.videoFilesUpload.map((item: any) => {
      updateEventFormData.append(
        `non_profit_event[event_videos_attributes][][video]`,
        item
      );
    });

    this.state.upload_images &&
      updateEventFormData.append(
        "non_profit_event[upload_images]",
        this.state.upload_images
      );

    this.state.post_updates &&
      updateEventFormData.append(
        "non_profit_event[post_updates]",
        this.state.post_updates
      );
    this.state.planned_update_show &&
      updateEventFormData.append(
        "non_profit_event[planned_update_show]",
        this.state.planned_update_show
      );
  }

  //evnt creation

  needsEvent = () => {
    this.setState({ createEventBtn: true });
    const header = {
      // "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.needsEventId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventCreationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const formData = new FormData();
    formData.append("non_profit_event[event_name]", this.state.eventName);
    formData.append("non_profit_event[event_type]", this.state.eventType);
    formData.append("non_profit_event[event_image]", this.state.needsEventImg);
    formData.append("non_profit_event[project_update]", this.state.projectUpdate);
    formData.append(
      "non_profit_event[project_outcomes]",
      this.state.projectOutcome
    );
    formData.append(
      "non_profit_event[beneficiaries]",
      this.state.beneficiaries
    );
    formData.append(
      "non_profit_event[target_amount]",
      this.state.amount_required
    );
    formData.append(
      "non_profit_event[project_delivered]",
      this.state.project_delivered
    );
    this.state.video_uploads &&
      formData.append(
        "non_profit_event[video_uploads]",
        this.state.video_uploads
      );

    this.state.upload_images &&
      formData.append(
        "non_profit_event[upload_images]",
        this.state.upload_images
      );
    formData.append(
      "non_profit_event[activity_schedule]",
      this.state.activity_schedule
    );

    const nonprofitToken = localStorage.getItem('nonprofitToken');
    formData.append("token", String(nonprofitToken));

    formData.append("non_profit_event[post_updates]", this.state.post_updates);
    formData.append(
      "non_profit_event[planned_update_show]",
      this.state.planned_update_show
    );
    formData.append(
      "non_profit_event[planned_virtual_update_show]",
      this.state.planned_virtual_update_show
    );
    formData.append(
      "non_profit_event[social_media1]",
      this.state.social_media1
    );
    formData.append(
      "non_profit_event[social_media2]",
      this.state.social_media2
    );
    formData.append(
      "non_profit_event[social_media3]",
      this.state.social_media3
    );

    formData.append(
      "non_profit_event[task_preference_ids]",
      this.state.taskPreferenceIds
    );

    formData.append(
      "non_profit_event[dbs_type_ids]",
      this.state.dbsTypeIds
    );
    formData.append(
      "non_profit_event[dbs_registration]",
      // @ts-ignore
      this.state.dbs_registration
    );
    formData.append(
      "non_profit_event[max_attendence]",
      this.state.volunteers_num
    );
    formData.append("non_profit_event[description]", this.state.description);
    formData.append(
      "non_profit_event[itinerarv_and_notes]",
      this.state.itinerarv_and_notes
    );
    formData.append("non_profit_event[volunteers_required]", "0");

    formData.append(
      "non_profit_event[from_date]",
      moment(this.state.from_date).format("llll")
    );

    this.state.to_date &&
      formData.append(
        "non_profit_event[to_date]",
        moment(this.state.to_date).format("llll")
      );
    formData.append("non_profit_event[start_time]", this.state.start_time);
    formData.append("non_profit_event[end_time]", this.state.finish_time);
    formData.append("non_profit_event[location]", this.state.location);
    // @ts-ignore
    formData.append("non_profit_event[publish]", this.state.publish.toString());
    const org_id = localStorage.getItem("org_id");
    formData.append("non_profit_event[organisation_id]", String(org_id));

    this.state.imageFilesUpload.map((item: any) => {
      formData.append(`non_profit_event[imgs_attributes][][image]`, item);
    });
    this.state.projectNote &&
      formData.append(
        "non_profit_event[event_notes_attributes][][event_note]",
        this.state.projectNote
      );

    this.state.videoFilesUpload.map((item: any) => {
      formData.append(
        `non_profit_event[event_videos_attributes][][video]`,
        item
      );
    });
    if (this.state.everyone) {
      formData.append("non_profit_event[publishing_scope]", "everyone_else");
    } else if (this.state.followers && this.state.registered_user) {
      formData.append("non_profit_event[publishing_scope]", "followers_and_registered_users");
    } else if (this.state.registered_user) {
      formData.append("non_profit_event[publishing_scope]", "registered_users");
    } else if (this.state.followers) {
      formData.append("non_profit_event[publishing_scope]", "followers");
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify(formData)
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleMultiVideo = (event: any) => {
    event.preventDefault();

    const chosenFiles = Array.prototype.slice.call(event.target.files);

    this.setState({
      videoFilesUpload: chosenFiles,
    });
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files;
      this.projectVideoObj.push(event.target.files);

      //to post - projectVideoObj[0]
      //to local modal display - multiimagePreview

      for (let i = 0; i < this.projectVideoObj[0].length; i++) {
        this.multiVideosDisplay.push(
          URL.createObjectURL(this.projectVideoObj[0][i])
        );
      }
      this.setState({ multiImages: this.multiVideosDisplay });
    }
  };

  handleMultiImage = (event: any) => {
    event.preventDefault();

    const chosenFiles = Array.prototype.slice.call(event.target.files);

    this.setState({
      imageFilesUpload: chosenFiles,
    });

    if (event.target.files && event.target.files[0]) {
      let file = event.target.files;
      this.projectImgObj.push(event.target.files);

      //to post - projectImgObj[0]
      //to local modal display - multiimagePreview

      for (let i = 0; i < this.projectImgObj[0].length; i++) {
        this.multiImagesdisplay.push(
          URL.createObjectURL(this.projectImgObj[0][i])
        );
      }

      this.setState({ multiImages: this.multiImagesdisplay });
    }
  };

  rejectUserPledge = (id: any) => {
    const header = {
      type: "non_profit_entity"
    }
    const rejectPledge = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const token = localStorage.getItem("nonprofitToken");

    this.deleteUserPledgeId = rejectPledge.messageId;

    rejectPledge.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rejectPledgeEndPoint}/${id}?token=${token}`
    );
    rejectPledge.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    rejectPledge.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(rejectPledge.id, rejectPledge);
  };

  directCommunication = (id: string, type: string) => {
    const token = localStorage.getItem("nonprofitToken");
    const header = {
      type: "non_profit_entity",
      token: token,
      "Content-Type": "application/json"
    }

    const httpBody = {
      "receiver_id": id,
      "receiver_entity": type,
    }
    const directCom = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.directCommunicationId = directCom.messageId;

    directCom.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_groupchat/create_individual_conversation`
    );
    directCom.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    directCom.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    directCom.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(directCom.id, directCom);
  };

  directPledgeCommunication = (id: string) => {
    const token = localStorage.getItem("nonprofitToken");
    const header = {
      type: "non_profit_entity",
      token: token,
      "Content-Type": "application/json"
    }

    const httpBody = {
      "receiver_id": id,
      "receiver_entity":"user_entity",
    }
    const userCom = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.directPledgeCommunicationId = userCom.messageId;

    userCom.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_groupchat/create_individual_conversation`
    );
    userCom.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    userCom.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    userCom.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(userCom.id, userCom);
  };


  VolunteerCommunication = (id: string) => {
    const token = localStorage.getItem("nonprofitToken");
    const header = {
      type: "non_profit_entity",
      token: token,
      "Content-Type": "application/json"
    }

    const httpBody = {
      "receiver_id": id,
      "receiver_entity":"user_entity",}
    const volunteerCom = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.directVolunteerCommunicationId = volunteerCom.messageId;

    volunteerCom.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_groupchat/create_volunteer_conversation`
    );
    volunteerCom.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    volunteerCom.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    volunteerCom.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(volunteerCom.id, volunteerCom);
  };
  //all events in list
  getEvents = () => {
    const nonprofitToken = localStorage.getItem('nonprofitToken');
    const org_id = localStorage.getItem("org_id");
    const getAllEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllEventsId = getAllEvents.messageId;

    getAllEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllEventsEndPoint}?event_filter=all&organisation_id=${org_id}&token=${nonprofitToken}`
    );

    getAllEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getAllEvents.id, getAllEvents);
  };

  // all needs event in list
  getNeedsEvents = () => {
    const org_id = localStorage.getItem("org_id");
    const nonprofitToken = localStorage.getItem('nonprofitToken');

    const getNeedsEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNeedsEventsId = getNeedsEvents.messageId;

    getNeedsEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllEventsEndPoint}?event_filter=need&organisation_id=${org_id}&token=${nonprofitToken}`
    );

    getNeedsEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getNeedsEvents.id, getNeedsEvents);
  };

  //all projects events in list
  getProjectsEvents = () => {
    const org_id = localStorage.getItem("org_id");
    const nonprofitToken = localStorage.getItem('nonprofitToken');

    const getProjectsEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectsEventsId = getProjectsEvents.messageId;

    getProjectsEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllEventsEndPoint}?event_filter=project_funding&organisation_id=${org_id}&token=${nonprofitToken}`
    );

    getProjectsEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getProjectsEvents.id, getProjectsEvents);
  };

  // all activities events in list
  getActivitiesEvents = () => {
    const org_id = localStorage.getItem("org_id");
    const nonprofitToken = localStorage.getItem('nonprofitToken');

    const getActivitiesEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getActivitiesEventsId = getActivitiesEvents.messageId;

    getActivitiesEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllEventsEndPoint}?event_filter=activity&organisation_id=${org_id}&token=${nonprofitToken}`
    );

    getActivitiesEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getActivitiesEvents.id, getActivitiesEvents);
  };

  searchAllEvents = (searchedVal: any) => {
     const filteredRows = this.state.filteredallNpEvents.filter(
      (row: any) =>
        row.attributes.event_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.attributes.event_type
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) 
    );
    this.setState({
      allNpEvents: {data: filteredRows},
    });
  };

  searchNeedsEvents = (searchedVal: any) => {
    const filteredRows = this.state.filteredAllNeedEvents.filter(
      (row: any) =>
        row.attributes.event_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) 
    );
    this.setState({
      allNeedsEvents: {data: filteredRows},
    });
  };

  searchProjectsEvents = (searchedVal: any) => {
    const filteredRows = this.state.allFilteredProjectEvents.filter(
      (row: any) =>
        row.attributes.event_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) 
    );
    this.setState({
      allProjectsEvents: {data: filteredRows},
    });
  };

  searchActivitiesEvents = (searchedVal: any) => {
    const filteredRows = this.state.allFilteredActivitiesEvents.filter(
      (row: any) =>
        row.attributes.event_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) 
    );
    this.setState({
      allActivitiesEvents: {data: filteredRows},
    });
  };

  onCreateEvent = () => {
    this.setState({ eventBtnTabs: "need" });
    this.setState({
      eventTabs: "needsEventCreation",
    });
  };

  onCreateProjectEvent = () => {
    this.setState({
      eventTabs: "projectEventCreation",
    });
  };

  toAllEvents = () => {
    this.setState({ eventTabs: 'need' })
    // this.setState({eventBtnTabs : ''})
  }

  onNavigateFromNeedsCreation = () => {
    this.setState({ eventTabs: "need" });
  };

  onNavigateFromProjectsCreation = () => {
    this.setState({ eventTabs: "project" });
  };

  onNavigateFromActivitiesCreation = () => {
    this.setState({ eventTabs: "activities" });
  };

  onCreateActivitiesEvent = () => {
    this.setState({
      eventTabs: "activitiesEventCreation",
    });
  };

  onEventCreation = () => {
    this.setState({
      eventTabs: "createAllEvents",
    });
  };

  pledgedUser = (id: any) => {
    const getPledgedUser = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPledgedUser = getPledgedUser.messageId;
    getPledgedUser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPledgedUserEndPoint}?account_id=${id}`
    );
    getPledgedUser.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getPledgedUser.id, getPledgedUser);
  };

  onSecondTabs = (id: any) => {
    localStorage.setItem('eventID', "yes");
    const token = localStorage.getItem("nonprofitToken");
    const header = {
      token: token,
      type: "non_profit_entity"
    }
    this.setState({
      eventTabs: "secondTabsOne",
    });

    const getSpecificEvent = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpecificEventId = getSpecificEvent.messageId;
    getSpecificEvent.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventCreationEndPoint}/${id}`
    );
    getSpecificEvent.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    getSpecificEvent.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(getSpecificEvent.id, getSpecificEvent);
  };

  imageFn = () => {
    if (this.state.upload_images) {
      return true;
    } else if (
      this.props.single_event_details?.data?.attributes?.upload_images
    ) {
      return true;
    } else return false;
  };

  getEventUpdates = (id: any) => {
    const token = localStorage.getItem("nonprofitToken");
    const header = {
      token: token,
      type: "non_profit_entity"
    }
    const getEventUpdates = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventUpdatesListId = getEventUpdates.messageId;
    getEventUpdates.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventUpdatesEndPoint}?event_id=${id}`
    );
    getEventUpdates.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getEventUpdates.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getEventUpdates.id, getEventUpdates);
  };

  getOrgAnalytics() {
    const token = localStorage.getItem("nonprofitToken");
    const header = {
      token: token,
      type: 'non_profit_entity'
    }
    const getAnalytics = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAnalyticsId = getAnalytics.messageId;
    getAnalytics.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAnalyticsEndPoint);
    getAnalytics.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    getAnalytics.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(getAnalytics.id, getAnalytics);
  }

  getAllPledges = (id: any) => {
    this.setState({ eventTabs: "secondTabsThree" });
    const token = localStorage.getItem("nonprofitToken");
    const header = {
      token: token,
      type: "non_profit_entity"
    }

    const getPledges = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPledgesId = getPledges.messageId;
    getPledges.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewPledgesEndPoint}?non_profit_event_id=${id}`
    );
    getPledges.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    getPledges.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(getPledges.id, getPledges);
  };

  //event update
  getAllVolunteersData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVolunteersId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.registeredVolunteersEndPoint}?organisation_id=18`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleLogout = async () => {
    let accId = localStorage.getItem("non_profit_acc_id")
    let np_user_role = localStorage.getItem("np_user_role")
    const utcTime = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    const bodyObj: Record<string, any> = {
      "accountable_id": accId,
      "log_of_date_time": utcTime
    }
    if(np_user_role !== "Entity_admin"){
      bodyObj["accountable_type"] = "BxBlockNonProfitAccount::NonProfitAccount" 
    }
    
    const body = {
      "activity_log": bodyObj
    };
   
    const header = {
      "Content-Type": "application/json"
    }
      this.logoffApiCallId = await apiCall({
        method: "POST",
        endPoint: `/bx_block_profile/non_profit_activity_logs`,
        body: JSON.stringify(body),
        header: header
      });
  };

  deleteFcmToken(): any {

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("nonprofitToken"),
      "type": "non_profit_entity"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.delFcmId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/device_ids/${localStorage.getItem("device_id")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    localStorage.clear() 
  }


  handleAcceptVolunteersUser = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      non_profit_account_id: 58,
      account_id: 57,
    };

    this.getVolunteersAccesptUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.registeredVolunteersAcceptUserEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleRemoveVolunteersUser = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      non_profit_account_id: 58,
      account_id: 57,
    };

    this.getVolunteersRemoveUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.registeredVolunteersRemoveUserEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleOfferRole = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      non_profit_account_id: 58,
      account_id: 57,
    };

    this.getOfferRoleUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.registeredVolunteersOfferRole}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // get NP Offer A Role Users Detals.
  getAllNPUserData = () => {
    const token = localStorage.getItem("nonprofitToken");
    const non_profit_acc_id = localStorage.getItem("non_profit_acc_id");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOfferRoleUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.registeredOfferAROleUserEndPoint}?non_profit_account_id=${non_profit_acc_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getlastUSerSeen(date: string) {
    const datetime = moment(date);
    const formattedDateTime = datetime.format("DD/MM/YYYY HH:mm");
    return formattedDateTime;
  }

  getNewStaff = (values: any, token: any, func: any) => {
    console.log("newStaff");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };



    const httpBody = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role.label,
    };

    this.getNPOrganisation = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.registeredNPOrganisationUser}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    setTimeout(() => {
      func.setSubmitting(false);
    }, 2000);

    return true;
  }

  getUpdateStaff = (values: any, token: any, staff_id: any, func: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      id: staff_id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role.value,
      token: token,
    };

    this.updateStaffNpId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateNpStaffApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    setTimeout(() => {
      func.setSubmitting(false);
    }, 2000);

    return true;
  }

  onSubmitHandler = (values: any, func: any) => {
    const token = localStorage.getItem("nonprofitToken");
    const staff_id = localStorage.getItem("np_staff_id");
    if (values?.id) {
      this.getUpdateStaff(values, token, staff_id, func);
    } else {
      this.getNewStaff(values, token, func);
    }
  };

  handleRedirectionModalClose = () => {
    this.setState({ openRedirectionModal: false} )
    this.getNpOrganizationData()
    this.setState({ recallBankAPI: false})
  }

  handleBankSubmit = () => {
    const org_id = localStorage.getItem("org_id");
  
    const requestBody = {
      connected_account: {
        mcc: this.state.mcc,
        bank_code: this.state.bank_code,
        bank_account_number: this.state.bank_account_number,
        organisation_id: org_id, 
      },
    };
  
    const header = {
      "Content-Type": "application/json",  
      type: "non_profit_entity",
      token: localStorage.getItem("nonprofitToken"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.updateNPOBankDetails = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/connected_accounts`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }  

  // Popover handle event
  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  goToClick = (e: any) => {
    e.stopPropagation();
    this.setState({ anchorEl: e.currentTargets });
  };

  handleNotiOpen = (e: any) => {
    this.setState({ openNoti:  e?.currentTarget  });
  }

  handleNotiClose = () => {
    this.setState({ openNoti: false })
  }

  handleClickProfile = () => {
    this.setState({ openProfile: true })
    this.setState({
      profileChangeErrors: {
        first_name: false,
        last_name: false,
        email: false,
        mobile: false,
        job_role: false,
        change_password: false,
        confirm_password: false,
      }
    })
    this.getNpProfileData()
  }
 handleOpenSaveModel = () =>{
 this.setState({openSaveModel:true});
 console.log("IT IS WORKING",this.state.openSaveModel);
 }
 handleCloseSaveModel = ()=>{
 this.setState({openSaveModel:false})
 }
  handleCloseProfile = () => {
    this.setState({ openProfile: false })
  }

  ecode = (e: any): boolean => {
    if (e?.keyCode == 13) {
      return true;
    } else {
      return false;
    }
  };

  temp = (item: any) => {
    if (item?.attributes?.event_type === "need") {
      return "Need";
    } else if (item?.attributes?.event_type === "project_funding") {
      return "Project";
    } else {
      return "Activity";
    }
  };
  temp2 = (item: any) => {
    if (item?.attributes?.event_type === "need") {
      return true;
    } else {
      return false;
    }
  };

  colourStyles = {
    menu: (base: any) => {
      return {
        ...base,
        paddingLeft: 20,
        paddingRight: 20,
        border: "none",
        marginTop: 0,
        borderTop: "none",
        boxShadow: "none",
        color: "black",
        paddingTop: 5,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      };
    },
    singleValue: (base: any) => {
      return {
        ...base,
        color: "black",
      };
    },
    dropdownIndicator: (base: any) => {
      return {
        ...base,
        color: "lightgrey",
      };
    },
    control: (base: any, state: any) => {
      return {
        ...base,

        backgroundColor: "white",
        boxShadow: "none",
        height: 54,
        borderRadius: state.isFocused ? "10px " : "10px",
        border: `${this.state.createEventState
          ? "1px solid  #009BE2"
          : "1px solid lightgrey"
          }`,
        "&:hover": {
          borderBottom: `${this.state.createEventState
            ? "1px solid  #009BE2"
            : "1px solid lightgrey"
            }`,
        },
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
      };
    },
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: "none",
    }),
    menuList: (base: any) => {
      return {
        ...base,
        marginTop: 15,
        position: "relative",
        top: "-5px",
        zIndex: -1,
      };
    },
  };

  // Delete Np staff 
  handleDeleteNpStaff = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteStaffNpId) {
      console.log("dlt", responseJson);
      if (responseJson) {
        if (responseJson?.message?.length > 0) {
          this.props.navigation.navigate("UsersNP");
        } else {
          if (responseJson?.errors?.length > 0) {
            this.setState({ deleteNpStaffApiError: responseJson.errors[0].token });
          }
        }
      }
    }
  }

  // Update Np Staff
  handleUpdateNpStaff = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.updateStaffNpId) {
      if (responseJson && responseJson?.data) {
        if (this.state.updateNpRoleToCoordinator) {
          this.setState({ npNewStaffEmailNotificationFlag: true })
        } else {
          this.props.navigation.navigate("UsersNP")
        }
      }
    }
  }


  // NP Organisation Module

  handleAllOrganisationAPIResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getNpOrganizationDataApiId) {
      const today = new Date();
		  const thirteenYearsAgo = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());
      this.setState({
        NpOrganizationDetailsData: responseJson.data.attributes,
        NpOrgFixedDetails: responseJson.data.attributes,
        loader: false,
        street: responseJson.data.attributes.street,
        town: responseJson.data.attributes.town,
        country: responseJson.data.attributes.country,
        website_url: responseJson.data.attributes.website_url,
        post_code: responseJson.data.attributes.post_code,
        first_name: responseJson.data.attributes.first_name,
        last_name: responseJson.data.attributes.last_name,
        day: responseJson.data.attributes.day,
        month: responseJson.data.attributes.month - 1,
        year: responseJson.data.attributes.year,
        date: responseJson.data.attributes.day ? new Date(responseJson.data.attributes.year, responseJson.data.attributes.month - 1, responseJson.data.attributes.day) : null,
        accStatus: responseJson.data.attributes.status,
        reinitialize: true,
        bank_account_number: responseJson.data.attributes.bank_account_number,
        bank_code: responseJson.data.attributes.bank_code,
        mcc: responseJson.data.attributes.mcc,
        copyOfBankAccountNumber: responseJson.data.attributes.bank_account_number, 
        copyOfBankCode: responseJson.data.attributes.bank_code, 
        copyOfMerchantCode: responseJson.data.attributes.mcc
      });
    }
    if (apiRequestCallId === this.getRegisteredVolunteerDataApiId) {
     this.handleVolunteerDataApiResponse(responseJson);
    }

    if (apiRequestCallId === this.getEditNpOrganizationDataApiId) {
      this.handleErrorResponseForCrossSector(responseJson)
    }

    if(apiRequestCallId === this.updateNPOBankDetails) {
      this.handleBankAPIResponse(responseJson)
    }

    if(apiRequestCallId === this.generateAccountAPIId) {
      this.handleGenerateAccount(responseJson)
    }

    if (apiRequestCallId === this.approveRegisteredVolunteerApiId) {
      this.setState({ openRegisteredVolModel: false });
      this.getRegisteredVolunteerData();
    }

    if (apiRequestCallId === this.rejectRegisteredVolunteerApiId) {
      this.setState({ openRegisteredVolModel: false });
      this.getRegisteredVolunteerData();
    }

    if (apiRequestCallId === this.searchRegisteredVolunteerApiId) {
      this.setState({ filteredVolunteerData: responseJson.data });
    }
    if (apiRequestCallId === this.getFollowersDataApiCallId) {
      this.setState({ filteredFollowersData: responseJson?.data, followersData: responseJson?.data, loader: false });
    }
    if (apiRequestCallId === this.getBroadcastDataApiCallId) {
      this.setState({ broadcastMsgData: responseJson?.data, loader: false })
    }
    if (apiRequestCallId === this.createBroadcastDataApiCallId) {
      this.setState({ broadcastMsgCounter: '', filterBroadcastModal: false })
      this.handleSuccessModalOpen()
      this.getBroadcastsMsgs()
    }
    if (apiRequestCallId === this.getTaskPreferenceDataApiCallId) {
      this.setState({ taskPreferenceData: responseJson?.data?.map((item: any) => { return { label: item.attributes.task, value: item.id } }), loader: false, });
    }
    if (apiRequestCallId === this.getDBSDataAPICallId) {
      this.setState({ dbsData: responseJson?.data?.map((item: any) => { return { label: item.attributes.dbs_type_name, value: item.id } }), loader: false, });
    }
  }

  handleVolunteerDataApiResponse = (responseJson:any, ) => {
    this.setState({
      volunteerData: responseJson.data,
      filteredVolunteerData: responseJson.data,
      loader: false,
    });
    const item = responseJson.data.filter((item: any) => item.id === this.state.userId)[0];
    this.setState({
      openRegisteredVolModel: !!item,
      individualRegiVoluDeatils: item,
    });
  }

  handleAllOrganisationOptionsAPIResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getOrgTypeId) {
      this.setState({
        orgTypeOptions: responseJson?.data.map((it: any) => {
          return {
            label: it.attributes?.organisation_type,
            value: it.attributes?.organisation_type,
            name: 'organisation_type'
          }
        }),
        loader: false,
      });
    }
    if (apiRequestCallId === this.allTypesOptionsAPICallId) {
      this.setState({ allTypeOptions: responseJson })
    }
    if (apiRequestCallId === this.getCrossMemberApiCallId) {
      this.setState({
        memberCrossSectorOptions: responseJson?.data.map((it: any) => {
          return {
            label: it.organisation_name,
            value: it.id,
          }
        }),
        loader: false,
      });
    }


  }

  handleAllUserProfileAPIResponse(responseJson: any, errorReponse: any) {
    const { first_name, last_name, email, role, admin_mobile_number, logo, annual_turnover } = responseJson.data.attributes
    this.setState({
      draft: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: admin_mobile_number,
        job_role: role,
        annual_turnover: annual_turnover,
        change_password: "",
        confirm_password: "",
        avatar: logo,
      }
    });
  }

  getUpdatedProfile = () => {
    const acc_token = localStorage.getItem("nonprofitToken");
    const { first_name, last_name, email, mobile, avatar, change_password, confirm_password, job_role, annual_turnover } = this.state.draft

    const httpBody = {
      "first_name": first_name,
      "last_name": last_name,
      "email": email,
      "password": change_password !== "" ? change_password : undefined,
      "password_confirmation": change_password !== "" ? confirm_password : undefined,
      "logo": avatar,
      "annual_turnover": annual_turnover,
      "contact_number": mobile,
      "role": job_role
    };

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUpdateProfileId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_non_profit_account/non_profit_accounts/update_np_profile?token=${acc_token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 

  getNpProfileData = () => {
    const acc_id = localStorage.getItem("non_profit_acc_id");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNpOrganizationProfile = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_non_profit_account/non_profit_accounts/${acc_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNpOrganizationData = () => {
    const org_id = localStorage.getItem("org_id");
    const header = {
      token: localStorage.getItem("nonprofitToken"),
      type: 'non_profit_entity'
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNpOrganizationDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNpOrganizationDataApiEndpoint}/${org_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrgTypeOptions = () => {
    const getOrgType = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrgTypeId = getOrgType.messageId;

    getOrgType.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orgTypeEndPoint
    );

    getOrgType.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getOrgType.id, getOrgType);
  }

  getAllTypeOptions = () => {
    const getCauseOptions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allTypesOptionsAPICallId = getCauseOptions.messageId;

    getCauseOptions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orgOptionsTypeEndPoint
    );

    getCauseOptions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getCauseOptions.id, getCauseOptions);

  }

  getMembersOption = () => {

    const token = localStorage.getItem("nonprofitToken");

    const getMembersOptions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCrossMemberApiCallId = getMembersOptions.messageId;

    getMembersOptions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMembersApiEndPoint}?token=${token}`
    );

    getMembersOptions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getMembersOptions.id, getMembersOptions);
  }

  handleGenerateAccount = (responseJson: GenerateAccountRes) => {
    toast.success(responseJson.message)
    this.setState({ openRedirectionModal: true, redirectionUrl : responseJson.url})
  }

  handleBankAPIResponse = (responseJson: BankAPIRes) => {
    if (responseJson.status === 500) {
      toast.error(`Internal server error`);
    } else if (responseJson?.error) {
      toast.error(`Something went wrong!`)
    } else {
      this.generateAccount(responseJson.data.account_id)
      toast.success(responseJson.message)
    }
  }

  generateAccount = (id: string) => {
    const httpBody = {
      "data": {
          "attributes": {
              "account_id": id
          }
      }
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.generateAccountAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_stripe_integration/generate_account_link"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleErrorResponseForCrossSector = (responseJson : any) => {
    if (!responseJson?.errors) {
      this.setState({ loader: true });
      this.getNpOrganizationData();
      this.state.recallBankAPI && this.handleBankSubmit()
    } else {
      if(responseJson.errors && responseJson.errors.length > 0){
        toast.error(responseJson.errors[0].contact_number);
      }
      this.setState({
        NpOrgErrors: { ...this.state.NpOrgErrors, terms: true },
      })
    }
  }

  isValidMemeber(e: any) {
    if (e.value !== "") {
      this.setState({
        NpOrgErrors: { ...this.state.NpOrgErrors, terms: false },
        slectedMemberCrossSector: e.value,
        NpOrganizationDetailsData: {
          ...this.state.NpOrganizationDetailsData,
          selected_cross_sector: e.label,
        },
      })
    } else {
      this.setState({
        NpOrgErrors: { ...this.state.NpOrgErrors, terms: true },
      })
    }
  }

  handleCrossMember(e: any) {
    if (e.target.checked) {
      this.setState({
        NpOrganizationDetailsData: {
          ...this.state.NpOrganizationDetailsData,
          role: 'cross_sector',
        },
      });
    } else {
      this.setState({
        NpOrganizationDetailsData: {
          ...this.state.NpOrganizationDetailsData,
          role: null,
        },
      });
    }

  }

  handleOrgMember(e: any) {
    if (e.target.checked) {
      this.setState({
        NpOrganizationDetailsData: {
          ...this.state.NpOrganizationDetailsData,
          role: 'member',
        },
      });
    } else {
      this.setState({
        NpOrganizationDetailsData: {
          ...this.state.NpOrganizationDetailsData,
          role: null,
        },
      });
    }

  }

  isValidEmail(value: any) {
    const reg =
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return reg.test(String(value).toLowerCase());
  }

  validateEmail(e: any) {
    if (this.isValidEmail(e.target.value)) {
      this.setState({
        NpOrgErrors: { ...this.state.NpOrgErrors, email: false },
      })
    } else {
      this.setState({
        NpOrgErrors: { ...this.state.NpOrgErrors, email: true },
      })
    }

    this.setState({
      NpOrganizationDetailsData: {
        ...this.state.NpOrganizationDetailsData,
        email: e.target.value,
      },
    });
  }

  handleTextFieldChange = (e: any) => {
    if (e.target.value.trim() === "") {
      this.setState({
        NpOrgErrors: { ...this.state.NpOrgErrors, [e.target.name]: true },
      });
    } else {
      this.setState({
        NpOrgErrors: { ...this.state.NpOrgErrors, [e.target.name]: false },
      });
    }

    this.setState({
      NpOrganizationDetailsData: {
        ...this.state.NpOrganizationDetailsData,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeAvatar = (event: any) => {
    let file = URL.createObjectURL(event.target.files[0]);

    if (file == undefined) {
      console.log("File removed");
    } else {
      this.setState((prevState) => ({
        draft: { ...prevState.draft, avatar: file },
      }));
    }
  }

  handleProfileFieldChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      draft: { ...prevState.draft, [name]: value },
    }));
    this.validateProfile(name, value)

  };

  validateProfile = (name: any, value: any) => {
    const { profileChangeErrors, draft } = this.state;

    const updateErrors = (newErrors: any) => {
      this.setState({
        profileChangeErrors: { ...profileChangeErrors, ...newErrors },
      });
    };

    const isValidName = (value: string) => {
      const nameRegex = /^[a-zA-Z]+(\s[a-zA-Z]*)*$/

        ;
      return nameRegex.test(value);
    };

    const isValidEmail = (value: string) => {
      const emailRegex =
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      return emailRegex.test(String(value).toLowerCase());
    };

    const isValidPassword = (value: string) => {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;
      return value === "" || passwordRegex.test(value);
    };

    const isConfirmedPassword = (value: string) => {
      const password = draft.change_password;
      const isPasswordEmpty = password.trim() === "";
      return value === password && !isPasswordEmpty;
    };

    const isValidMobile = (value: string) => {
      return value.length === 12;
    };

    switch (name) {
      case "first_name":
      case "last_name":
        updateErrors({ [name]: !isValidName(value) });
        break;

      case "email":
        updateErrors({ [name]: !isValidEmail(value) });
        break;

      case "change_password":
        updateErrors({
          [name]: !isValidPassword(value),
          confirm_password: value !== draft.confirm_password,
        });
        break;

      case "confirm_password":
        updateErrors({ [name]: !isConfirmedPassword(value) });
        break;

      case "mobile":
        updateErrors({ mobile: !isValidMobile(value) });
        break;

      default:
        updateErrors({ [name]: false });
        break;
    }
  };


  handleSubmitProfile = () => {
    this.setState((prevState) => ({
      draft: { ...prevState.draft, change_password: "", confirm_password: "" },
    }));
    this.getUpdatedProfile();
  }

  handleCancelBtn = () => {
    this.setState({
      NpOrgErrors: {
        organisation_name: false,
        description: false,
        email: false,
        contact_number: false,
        logo: false,
      },
      NpOrganizationDetailsData: this.state.NpOrgFixedDetails,
    });
  };

  handleDropdownFeildChange = (e: any) => {
    this.setState({
      NpOrganizationDetailsData: {
        ...this.state.NpOrganizationDetailsData,
        [e.name]: e.value,
      },
    });
  };

  handleUploadLogo = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      if (
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg"
      ) {
        this.setState({
          NpOrganizationDetailsData: {
            ...this.state.NpOrganizationDetailsData,
            logo: { url: URL.createObjectURL(event.target.files[0]) },
          },
          selectedeventsImage: URL.createObjectURL(event.target.files[0]),
          uploadPic: event.target.files[0],
          NpOrgErrors: { ...this.state.NpOrgErrors, logo: false },
        });
      } else {
        this.setState({
          NpOrgErrors: { ...this.state.NpOrgErrors, logo: true },
        });
      }
    }
  };

  deleteActivityEvent = (eventId: any) => {
    console.log('clicked delete', eventId)
    const nonprofitToken = localStorage.getItem('nonprofitToken');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteActEvent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventCreationEndPoint}/${eventId}?token=${nonprofitToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteProjectEvent = (eventId: any) => {
    console.log('clicked delete', eventId)
    const nonprofitToken = localStorage.getItem('nonprofitToken');
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProjEvent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventCreationEndPoint}/${eventId}?token=${nonprofitToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteSpecificEvent = (eventId: any) => {
    //delete need event

    const nonprofitToken = localStorage.getItem('nonprofitToken');

    console.log('clicked delete', eventId)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteSpecEvent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventCreationEndPoint}/${eventId}?token=${nonprofitToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteOrganisation = () => {
    const org_id = localStorage.getItem("org_id");
    const token = localStorage.getItem("nonprofitToken");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteNpOrganisationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteNpOrganisationApiEndPoint}/${org_id}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  singnout = () => {
    localStorage.clear();
    this.props.navigation.navigate("NpSignIn");
    // this.setState({ orgDeleteModal : false})
  };

  getUpdatedNpOrganizationData = () => {
    const plan = localStorage.getItem("plan");
    if((plan === "Trial" || plan === "Presence-Only") ||
    (this.state.bank_account_number?.toString() === this.state.copyOfBankAccountNumber?.toString() &&
     this.state.bank_code?.toString() === this.state.copyOfBankCode?.toString() &&
     this.state.mcc?.toString() === this.state.copyOfMerchantCode?.toString() && 
     this.state.accStatus === "Enabled")){
      this.setState({ recallBankAPI: false})
    } else {
      this.setState({ recallBankAPI: true})
    }
    const org_id = localStorage.getItem("org_id");
    const formData = new FormData();
    formData.append(
      "[organisation][organisation_name]",
      this.state.NpOrganizationDetailsData.organisation_name
    );
    formData.append(
      "[organisation][organisation_type]",
      this.state.NpOrganizationDetailsData.organisation_type
    );
    formData.append(
      "[organisation][email]",
      this.state.NpOrganizationDetailsData.email
    );
    formData.append(
      "[organisation][description]",
      this.state.NpOrganizationDetailsData.description
    );
    formData.append(
      "[organisation][street]",
      this.state.street
    );
    formData.append(
      "[organisation][town]",
      this.state.town
    );
    formData.append(
      "[organisation][country]",
      this.state.country
    );
    formData.append(
      "[organisation][post_code]",
      this.state.post_code
    );
    formData.append(
      "[organisation][cause]",
      this.state.NpOrganizationDetailsData.cause
    );
    formData.append(
      "[organisation][who_we_help]",
      this.state.NpOrganizationDetailsData.who_we_help
    );
    formData.append(
      "[organisation][what_we_provide]",
      this.state.NpOrganizationDetailsData.what_we_provide
    );
    formData.append(
      "[organisation][where]",
      this.state.NpOrganizationDetailsData.where
    );
    formData.append(
      "[organisation][website_url]",
      this.state.website_url
    );
   
    const today = new Date();
    formData.append(
      "[organisation][day]",
      this.state.day ? this.state.day.toString() : today.getDate().toString()
    );
    formData.append(
      "[organisation][month]",
      this.state.month ? (this.state.month + 1).toString() : today.getMonth().toString()
    );
    formData.append(
      "[organisation][year]",
      this.state.year ? this.state.year.toString() : (today.getFullYear() - 13).toString()
    );
    formData.append(
      "[organisation][first_name]",
      this.state.first_name
    );
    formData.append(
      "[organisation][last_name]",
      this.state.last_name
    );
    formData.append(
      "[organisation][annual_turn_over]",
      this.state.NpOrganizationDetailsData.annual_turn_over
    );
    formData.append(
      "[organisation][contact_number]",
       this.state.NpOrganizationDetailsData.contact_number
    );

    formData.append(
      "[organisation][publish]",
      this.state.NpOrganizationDetailsData.publish
    );
   
    this.state.uploadPic !== "" &&
      formData.append("[organisation][logo]", this.state.uploadPic);
    formData.append("token", `${localStorage.getItem("nonprofitToken")}`);

    this.state.NpOrganizationDetailsData.role !== null && formData.append("[organisation][role]", this.state.NpOrganizationDetailsData.role);

    this.state.NpOrganizationDetailsData.role === 'member' && formData.append("[organisation][cross_sector_id]", this.state.slectedMemberCrossSector);

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      type: "non_profit_entity",
      token: localStorage.getItem("nonprofitToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditNpOrganizationDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNpOrganizationDataApiEndpoint}/${org_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRegisteredVolunteerData = () => {
    const org_id = localStorage.getItem("org_id");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRegisteredVolunteerDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.registeredVolunteersEndPoint}/?organisation_id=${org_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRegisterOpenModel = () => {
    this.setState({
      openRegisteredVolModel: !this.state.openRegisteredVolModel,
    });
  };

  handleRegisterVolDetails = (item: any) => {
    this.setState({
      openRegisteredVolModel: true,
      individualRegiVoluDeatils: item,
    });
  };

  approveRegisteredVolunteer = (org_id: number, acc_id: number) => {
    const httpBody = {
      organisation_id: org_id,
      account_id: acc_id,
    };

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.approveRegisteredVolunteerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.approveRegisteredVolunteerDataApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  rejectRegisteredVolunteer = (org_id: number, acc_id: number) => {

    const httpBody = {
      organisation_id: org_id,
      account_id: acc_id,
    };

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rejectRegisteredVolunteerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rejectRegisteredVolunteerDataApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFollowersData = () => {

    const token = localStorage.getItem("nonprofitToken");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowersDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFollowersApiEndPoint}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBroadcastsMsgs = () => {
    const np_token = localStorage.getItem("nonprofitToken");
    const header = {
      token: np_token,
      type: 'non_profit_entity'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBroadcastDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBroadcastMsgApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  handleTaskPreferenceDropdownIds = (arrayVal: any) => {
    this.setState({ taskPreferenceIds: arrayVal.toString() })
  }

  handleDBSTypeDropdownIds = (arrayVal: any) => {
    this.setState({ dbsTypeIds: arrayVal.toString() })
  }

  getTaskPreferncesData = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskPreferenceDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskPreferenceDataApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDBSData = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDBSDataAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDBSDataApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveBroadcast = () => {
    const np_token = localStorage.getItem("nonprofitToken");
    const header = {
      'Content-Type': 'application/json',
      token: np_token,
      type: 'non_profit_entity'
    };
    let visibility = "";
    if (this.state.everyone) {
      visibility = "everyone";
    } else if (this.state.followers && this.state.registered_user) {
      visibility = "both";
    } else if (this.state.registered_user) {
      visibility = "volunteers";
    } else if (this.state.followers) {
      visibility = "followers";
    }
    const httpBody = {
      broadcast: {
        visibility,
        message: this.state.broadcastMsgCounter,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createBroadcastDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveBroadcastsMsgApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  contactUsSub = () => {
    this.validateContactMessage(this.state.contactMsg)
    this.setState({ isSubmitting: true })
    if (this.state.contactValidation) {
      const token = localStorage.getItem("nonprofitToken")
      const header = {
        "Content-Type": configJSON.apiContentType,
      };

      const httpBody = {
        data: {
          description: this.state.contactMsg,
        },
        user_type: "Non profit entity",
        token: token
      };

      console.log("httpBOdy", httpBody);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.contactUsApiID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.setState({ contactMsg: "" })
    }
  };






  // dashboard events function 

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleUserSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event?.target?.value;

    if (query?.trim()?.length === 0) {
      this.setState({
        offerAroleUserFilterList: this.state.offerAroleUser,
      });
    } else {
      const filtered = this.state.offerAroleUser.filter((item: any) => {
        const name = item.attributes?.first_name?.toLowerCase() ?? "Unknown";
        return name?.includes(query?.toLowerCase());
      });
      this.setState({
        offerAroleUserFilterList: filtered,
      });
    }
  }


  getUserType() {
    return localStorage.getItem("user_type")
  }

  onWebSidebarMenuClick = (
    _index: number,
    item: { id: number; name: string; icon: any; selectedIcon: any },
    navigation: any
  ) => {
    // Handle sidebar menu click logic
  };

  handleNotificationClick = () => {
    // Handle notification click logic
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleProfileClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSignOut = () => {
    localStorage.clear();
    this.props.setSelectedAdminTab("Dashboard");
    this.props.navigation.navigate("NpSignIn");
  };

  // Browser backbotton navigation

  getCurrentUrlAdmin() {
    const currentUrl = window.location.href;
    const parts = currentUrl.split("/");
    const lastWord = parts[parts.length - 1];
    if (["subscriptionplan", "paymentoption", "currentplaninfo", "gamification"].includes(lastWord.toLocaleLowerCase())) {
      return localStorage.getItem("routePath")?.substring(1) ?? "ContentManagementDashBoard";
    }
    return lastWord;
  }


  getUrlNameAdminDash = () => {
    const screenMappings: any = {
      ["ContentManagementDashBoard"]: constants.admin_dashboard_screens.Dashboard,

      ["Organisation"]: constants.admin_dashboard_screens.Organisation,
      ["ContentEvent"]: constants.admin_dashboard_screens.Events,
      ["FollowersNP"]: constants.admin_dashboard_screens.Followers,
      ["RegisteredVolunteers"]: constants.admin_dashboard_screens.RegisteredVolunteers,
      ["UsersNP"]: constants.admin_dashboard_screens.Users,
      ["Communication"]: constants.admin_dashboard_screens.Communication,
      ["NpSurveys"]: constants.admin_dashboard_screens.Surveys,
    };

    const routeName = screenMappings[this.getCurrentUrlAdmin()];
    return routeName;
  }
  // Browser backbotton navigation 
  handleSubscribeNow = () => {
    if (this.state.daysRemaning == 0) {
      this.setState({
        showSubscribe: false
      })
      localStorage.removeItem("showSubscribe")
    }
  };
  getFreeTrialData = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("nonprofitToken"),
      type: 'non_profit_entity'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFreeTrialApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.freeTrialApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePlanName = (name: string) => {
    const plans = [
      { name: "Trial", title: "Trial" },
      { name: "Discounted", title: "Discounted Non-Profit" },
      { name: "Presence-Only", title: "Presence-Only" },
      { name: "Supported", title: "Supported Non-Profit" },
      { name: "Full", title: "Full Non-Profit" }
    ];
    return plans.filter((item: any) => name?.includes(item.name))[0]?.title.toString();
  }

  handleFreeTriallResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson?.data && responseJson?.data?.attributes) {
      const isBannerVisible = localStorage.getItem("showSubscribe");
      const days = responseJson.data.attributes?.remaining_days;
      const totalDays = responseJson.data.attributes?.subscription?.data?.attributes?.valid_for_days;
      let planName = responseJson.data.attributes?.subscription?.data?.attributes?.name;
      localStorage.setItem("plan", this.handlePlanName(planName));
      const showSubscribe = Boolean(planName == "Trial-NP")
      this.setState({
        totalDays,
        showSubscribe,
        loading: false,
        daysRemaning: days,
        activePlanName: planName,
        isBannerVisible: days !== 0 ? "true" : isBannerVisible
      });
      if (this.props?.setActivePlan) {
        this.props?.setActivePlan({ daysRemaning: days, planName })
      }
    } else {
      localStorage.removeItem("plan");
      this.setState({
        daysRemaning: 30,
        activePlanName: "",
        loading: false,
      });
    }
  };

  handleAccordianClick = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false })
  };

  handleNewUser = async () => {
    //@ts-ignore
    await this.props.resetOfferRoleAction();
    this.props.navigation.navigate("OrganisationUserNP");
  }

  // For deleting the Np user 

  handleDeleteUser = (npStaffId: any) => {
    const np_token = localStorage.getItem("nonprofitToken");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: np_token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteStaffNpId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteNpStaffApiEndPoint + `/${npStaffId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFreeTrialPlanModal = () => {
    this.setState({
      openCustomModal: true
    })
    return true;
  }

  handleFreeTrialModalClose = () => {
    this.setState({
      openCustomModal: false,
    })
    this.props.resetEventTab()

  }

  handleProjectFundingType = () => {
    if (this.state.activePlanName == "Trial-NP") {
      this.handleFreeTrialPlanModal();
    }
    else {
      this.props.projectsEvent();
    }
  }
  handleAcceptRejectModal = () => {
    if (this.state.activePlanName == "Trial-NP" && this.state.daysRemaning == 0) {
      this.handleRegisterOpenModel()
      this.setState({ openCustomModal: true })
      return true;
    }

  }
  handleCustomModalClose = () => {
    this.setState({ openCustomModal: !this.state.openCustomModal })
  }

  handleAlertModalClose = () => {
    this.setState({ openCustomModal: !this.state.openCustomModal })
    this.props.navigation.navigate("ContentManagementDashBoard")
  }

  handleCloseOrg = () => {
    this.props.navigation.navigate("UsersNP");
  }

  isUpdateRole = (item: any) => {
    const getExistNpStaffRole = localStorage.getItem("np_staff_role");
    const getUpdateNpStaffRole = item?.value;

    if (getExistNpStaffRole !== getUpdateNpStaffRole) {
      if (getUpdateNpStaffRole === "Coordinator") {
        this.setState({ updateNpRoleToCoordinator: true });
      }
    }
  }

  // handle role is aasigned to NP Registered Volunteer or not  
  isRoleAssigned = (isRolePresent: any) => {
    if (isRolePresent) {
      this.setState({ openRolePresentModal: true })
    } else {
      this.props.navigation.navigate("OrganisationUserNP")
    }
  }

  handleOpenRolePresentModal = () => {
    this.setState({ openRolePresentModal: false, openRegisteredVolModel: !this.state.openRegisteredVolModel })
  }

  getLatLong(placeId: any) {
    geocodeByAddress(placeId)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('Successfully got latitude and longitude', { lat, lng });
        this.setState({ eventLat: lat, eventLong: lng })
      }
      );
  }

  addUserInGroup = async () => {
    let token = localStorage?.getItem('nonprofitToken') as string
    const formData = new FormData();
    formData.append("event_id", 'this.state.id');

    this.groupChatApiCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_groupchat/join_chat`,
      body: formData,
      token: token
    });
  }

  isSidebarOptionDisabled = (itemName:string)=>{
    const userPlan = localStorage.getItem("plan") === "Presence-Only" as string 
    return userPlan && ![constants.admin_dashboard_screens.Dashboard, constants.admin_dashboard_screens.RegisteredVolunteers, constants.admin_dashboard_screens.Organisation].includes(itemName);
  }

  // Customizable Area End
}
