// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../../components/src/utils/utils.web";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  location: any;
  history: any;
  match: any;
}

export interface IPointData {
  id: number;
  amount: number;
  point: number;
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  isModalOpen: boolean;
  isAutoRenew: boolean;
  id: string;
  title: string;
  price: string;
  duration: string;
  startDate: string;
  endDate: string;
  descriptions: any[];
  notes: any[];
  totalPoint: number;
  pointsData: any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CurrentPlanInfoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fetchPlanInfoApiCallId: string = "";
  cancelSubscriptionApiCallId: string = "";
  changeAutoRenewApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      loading: false,
      isModalOpen: false,
      isAutoRenew: false,
      id: "",
      title: "",
      price: "",
      duration: "",
      startDate: "",
      endDate: "",
      descriptions: [],
      notes: [],
      totalPoint: 0,
      pointsData: [],
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.error && apiRequestCallId === this.fetchPlanInfoApiCallId) {
          this.handleApiResponse(responseJson);
        } else if (!responseJson.error && apiRequestCallId === this.cancelSubscriptionApiCallId) {
          this.props.navigation.navigate("ContentManagementDashBoard");
        } else if (!responseJson.error && apiRequestCallId === this.changeAutoRenewApiCallId) {
          this.setState({ isAutoRenew: responseJson.subscription?.auto_pay });
        }
        this.setState({ loading: false });
      }
    }
  }

  handlePlanName = (name: string) => {
    const plans = [
      { name: "Trial", title: "Trial" },
      { name: "Presence-Only", title: "Presence-Only" },
      { name: "Supported", title: "Supported Non-Profit" },
      { name: "Discounted", title: "Discounted Non-Profit" },
      { name: "Full", title: "Full Non-Profit" }
    ];
    return plans.filter((item: any) => name?.includes(item.name))[0]?.title.toString();
  }

  handleApiResponse = (responseJson: any) => {
    if (!responseJson?.data) {
      this.props.history.push({
        pathname: "/SubscriptionPlan",
        state: {
          isAvailable: true,
          text: "Select",
          description: "Select your plan according to your needs"
        }
      })
      return;
    }
    const totalPoint = parseFloat(responseJson.data.attributes.subscription.data.attributes.total_points ?? 0);
    this.setState({
      totalPoint,
      loading: false,
      id: responseJson.data.id,
      isAutoRenew: responseJson.data.attributes.auto_pay,
      title: this.handlePlanName(responseJson.data.attributes.subscription.data.attributes.name),
      price: responseJson.data.attributes.subscription.data.attributes.price,
      startDate: responseJson.data.attributes.start_date,
      endDate: responseJson.data.attributes.valid_upto,
      duration: responseJson.data.attributes.subscription.data.attributes.valid_for_days >= 90 ? "Per Quarter" : "For 30 Days",
      descriptions: Object.values(responseJson.data.attributes.subscription.data.attributes.description ?? {}),
      notes: Object.values(responseJson.data.attributes.subscription.data.attributes.notes ?? {}),
      pointsData: Object.entries(responseJson.data.attributes.subscription.data.attributes.discount_points).map((obj: any, index: number) => {
        return {
          id: index,
          amount: parseInt(obj[0]),
          point: parseInt(obj[1]),
          isDisabled: parseInt(obj[1]) > totalPoint,
        }
      }),
    });
  }

  async componentDidMount() {
    this.fetchCurrentPlan();
  }

  fetchCurrentPlan = async () => {
    this.setState({ loading: true });
    const header = {
      "token": localStorage.getItem("nonprofitToken"),
      "type": "non_profit_entity"
    }
    this.fetchPlanInfoApiCallId = await apiCall({
      method: "GET",
      endPoint: configJSON.currentPlanInfo,
      header: header
    });
  }

  onChangeAutoRenew = async () => {
    this.setState({ loading: true });
    const header = {
      "content-type": "application/json",
      "token": localStorage.getItem("nonprofitToken"),
      "type": "non_profit_entity"
    }
    this.changeAutoRenewApiCallId = await apiCall({
      method: "POST",
      endPoint: configJSON.autoRenew,
      header: header,
      body: JSON.stringify({
        auto_pay: this.state.isAutoRenew ? "off" : "on"
      })
    });
  }

  onCancelSubscription = async () => {
    this.setState({ loading: true });
    const header = {
      "token": localStorage.getItem("nonprofitToken"),
      "type": "non_profit_entity"
    }
    this.cancelSubscriptionApiCallId = await apiCall({
      method: "POST",
      endPoint: configJSON.cancelSubscription,
      header: header
    });

    const today = new Date().toISOString().split("T")[0]; 

    this.setState({
      endDate: today,     
      loading: false,      
      isModalOpen: false   
    });
  }
}
// Customizable Area End