// Customizable Area Start
import { Redirect } from "react-router-dom";
import { icons } from "../../../components/src/constants";
import UserProfileBasicController from "./UserProfileBasicController";
import FavoriteIcon from '@material-ui/icons/Favorite';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';// @ts-ignore
import classesoutercss from "./UserProfileBasicBlock.module.css";
import Divider from '@material-ui/core/Divider';
import dateFormat from "dateformat";
import React from "react";
import MobileHeader from "../../../components/src/custom/MobileHeader";
import {
  CircularProgress as Loader,
  Grid,
  Button,
  Typography,
  Box
} from "@material-ui/core";
import { styles } from "./UserProfileBasicBlock.web";
import PropTypes from "prop-types";
// @ts-ignore
import { withStyles } from "@material-ui/core/styles";


class UserFavourites extends UserProfileBasicController {
  // Customizable Area Start
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  // Customizable Area End


  async componentDidMount() {
    this.getFavourites();
  }


  renderCountry = (location: string, post_code: string) => {
		return post_code ? post_code.substring(0, post_code.indexOf(" ")) || post_code : location?.slice(location.lastIndexOf(",") + 1).trim();
	}

	renderLocation = (location: string, postcode: string) => {
		if (postcode && !location.includes(postcode)) {
			location += `, ${postcode}`;
		}
		return location;
	}

  handleClick = (id: string, type: string, eventCategory: string, account_id: undefined | number | string) => {
    if (type === "BxBlockEvents::NonProfitEvent") {
      if (eventCategory === "project_funding") {
        this.props.navigation.navigate("FundCampaign", { id: id });
      } else if (eventCategory === "need") {
        this.props.navigation.navigate("EventsDetails", { id: id });
      } else if (eventCategory === "activity") {
        this.props.navigation.navigate("EventInfo", { id: id });
      }
    } else if(localStorage.getItem("acc_id") === account_id?.toString()){
      this.props.navigation.navigate("PublishNeed", { id: id });
    } else {
      this.props.navigation.navigate("EventsDetails", { id: "r1-" + id });
    }
  };

  favouritesData() {
    return (
      this.state.favData?.length > 0 ?
        <DialogContent className={classesoutercss.DialogContent} style={{ overflowY: "scroll" }} >
          {this.state.typeOfEvent  && <Redirect to={`${this.state.typeOfEvent}`} />}
          {this.state.favData.map((item) => {
            const pledgeId = item.attributes.my_pledge_id ?? item.attributes.need_my_pledge_id;
            const obj = item.attributes.favouriteable_attributes;
            return (
                <>
              <Grid container style={{marginBottom: '8%'}}>
              <Box display='flex' flexDirection='row'
                data-testId="favorite-card"
                onClick={() => this.handleClick(
                  item.attributes.favouriteable_id, 
                  item.attributes.favouriteable_type,
                  item.attributes.favouriteable_attributes.event_type,
                  item.attributes.favouriteable_attributes.account_id,
                )}
              >
                <Grid item xs={4} style={{  }}>
                
                  <img src={item?.attributes?.event_image?.url ?? item?.attributes?.need_photo} alt="Your Image" style={{ width: '90px', height: "90px", borderRadius: "20px" }} />
                </Grid>
                <Grid item xs={7}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft:'-6.8  %'}}>
                    <Typography variant="h6" style={{ fontWeight: "bold", fontSize: "16px", maxWidth: '20ch' }} >{obj.event_name ?? obj.title_of_need}</Typography>
                    <Typography variant="subtitle1" style={{ color: "grey", whiteSpace: 'nowrap',fontSize: "11px" }}><span> <img
                      style={{
                        height: 13,
                        width: 13,
                      }}
                      src={icons.calender}
                      alt="Calendar Icon"
                    />  </span> {dateFormat(item.attributes.favouriteable__attributes?.from_date, "dd mmm yyyy, h:MM tt")} </Typography>
                    <Typography variant="subtitle1" style={{ color: "grey" , whiteSpace: 'nowrap', fontSize: "11px" }}><span> <img
                      style={{
                        height: 13,
                        width: 13,
                      }}
                      src={icons.locationred}
                      alt="Calendar Icon"
                    />  </span> 
                    {((pledgeId && pledgeId.length > 0 && pledgeId[0]?.status === "Approved") || item.attributes.favouriteable_attributes.event_type === "project_funding" || item.attributes.favouriteable_attributes.event_type === "activity" || obj.account_id?.toString() === localStorage.getItem("acc_id"))
                    ? this.renderLocation(item.attributes.favouriteable_attributes.location, item.attributes.favouriteable_attributes.post_code)
                    : this.renderCountry(item.attributes.favouriteable_attributes.location, item.attributes.favouriteable_attributes.post_code)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                  <FavoriteIcon data-testId='favIcon' onClick={(e) => { e.stopPropagation(); this.handleOpenRemove(item.id)}} fontSize="large" htmlColor="#009be2" style={{ borderRadius: '50%', border: '1px solid #00000033', padding: '6px', fontSize: "20px" }} />
                </Grid>
                </Box>
              </Grid>
              
              <Divider style={{ display:"none",margin: "10px" }} />
              </>
            )
          })}

{this.state.removePopup && (
          <Dialog
            open={this.state.removePopup} // Open the dialog when removePopup is true
            onClose={this.handleCloseRemove}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
            PaperProps={{ style: { width: "300px", borderRadius: "20px", padding: "20px" } }}
          >
            <DialogTitle>
              <Typography style={{ fontSize: "16px", marginBottom: "-5px", whiteSpace: 'nowrap', fontFamily:"Rene_Bieder_Milliard_Medium"}}>Remove from my favourites list?</Typography>
            </DialogTitle>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Button
                data-testId="popupConfirm"
                variant="contained"
                style={{ textTransform: "none", borderRadius: "10px", backgroundColor: "#019BE1", color: "white", marginBottom: "13px", width: "92%", height: "50px" , fontFamily:"Rene_Bieder_Milliard_Medium"}}
                onClick={() => {
                  // Handle the remove action here
                  // Close the dialog
                  this.handleRemove();
                }}
              >
                Confirm
              </Button>
              <Button
                data-testId="popupCancel"
                variant="outlined"
                style={{ textTransform: "none", borderRadius: "10px", color: "grey", width: "92%", height: "50px" ,fontFamily:"Rene_Bieder_Milliard_Medium"}}
                onClick={() => this.handleCloseRemove()}
              >
                Cancel
              </Button>
            </div>
          </Dialog>
        )}
        </DialogContent>
        : this.renderErrorMsg()
    )
  }
  renderErrorMsg() {
    return (
      <div className={classesoutercss.DialogContent}>
        <p style={{ textAlign: "center", marginTop: 150 }}>No Data Available</p>
      </div>
    );
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <MobileHeader
          navigatTo={"/"}
          data-testid="mobileSearchMainContainer"
          navigation={this.props.navigation}
          title={"My Favourites"}
        />
        <div>
          {this.state.loader ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 150,
              }}
            >
              <Loader className={classes.loaderStyle} />
            </div>
          ) : (
            this.favouritesData()
          )}
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
UserFavourites.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export { UserFavourites };
export default withStyles(styles as any)(UserFavourites as any);
// Customizable Area End

// Customizable Area End
