// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils/utils.web";
import { toast } from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?: React.ReactNode | React.ReactNode[];
  classes: any;
  adminSelectedTab: any;
  theme?: any;
  history?: any;
  location?: any;
  match?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  startSurvey: boolean;
  radioValue: string;
  nextQuestion: string;
  surveyQuetionData: any;
  activePage: number;
  itemsPerPage: number;
  isDisabled: boolean;
  questionId: any;
  surveyQuestionAnswers: any;
  multipleOptions: any;
  completedPages: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BusSurveysController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSurveysQuestionApiCallId: string = '';
  createSurveysAnswerApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      startSurvey: false,
      radioValue: "",
      nextQuestion: "radio",
      surveyQuetionData: [],
      activePage: 1,
      itemsPerPage: 1,
      isDisabled: true,
      questionId: "",
      surveyQuestionAnswers: [],
      multipleOptions: [],
      completedPages: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getSurveysQuestionApiCallId) {
      this.handleQuestionDataResponse(responseJson)
    } else if (apiRequestCallId === this.createSurveysAnswerApiCallId) {
      toast.success('Survey submitted successfully')
      this.handleNavigation()
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getSurveysQuestionList(this.props.location?.state?.surveyData?.id)
  }

  handleQuestionDataResponse = (responseJson: any) => {
    const Error = responseJson?.error || responseJson?.errors
    if (Error) {
      toast.error('Something went wrong! please try again later')
    } else {
      let queData = responseJson?.data?.data?.attributes?.questions?.data?.map((item: any) => {
        return {
          ...item,
          qType: this.handleQuestionType(item.attributes.q_type, item.attributes.single_answer)
        }
      })

      const lastObj = { qType: 'completed' }

      queData = [...queData, lastObj]

      this.setState({ surveyQuetionData: queData })
    }
  }

  handleNavigation = () => {
    const userType = localStorage.getItem("user_type")

    if (userType === 'user entity') {
      this.props.navigation.navigate('SurveysDashboard')
    } else {
      this.props.navigation.navigate('BusSurveysDashboard')
    }
  }

  handleMultiChange = (event: any, queId?: any, isMulti?: boolean, limit?: any) => {
    const value = event.target.value;

    if (isMulti) {
      // Multi-select logic
      const { multipleOptions } = this.state;

      if (event.target.checked) {
        // If the checkbox is checked, add the value to the multipleOptions array
        this.setState({ multipleOptions: [...multipleOptions, value] }, () => {
          if (this.state.multipleOptions.length === limit) {
            // You have selected 3 options
            this.setState({ isDisabled: false, questionId: queId })
          } else {
            this.setState({ isDisabled: true })
          }
        });
      } else {
        // If the checkbox is unchecked, remove the value from the multipleOptions array
        this.setState(
          {
            multipleOptions: multipleOptions.filter((option: any) => option !== value),
          },
          () => {
            if (this.state.multipleOptions.length === limit) {
              // You have selected 3 options
              this.setState({ isDisabled: false })
            } else {
              this.setState({ isDisabled: true })
            }
          }
        );
      }
    } else {
      // Single-select logic
      this.setState({
        radioValue: value,
        isDisabled: true,
        questionId: queId,
        multipleOptions: [], // Reset multipleOptions when a single option is selected
      });
    }
  };

  handleChange = (event: any, queId?: string) => {

    this.setState({ radioValue: event.target.value, isDisabled: false, questionId: queId });
  };
  handleStartSurvey = () => {
    this.setState({ startSurvey: true })
  }
  handleEndSurvey = () => {
    this.setState({ startSurvey: false })
  }

  getSurveysQuestionList = async (surveyId: number) => {
    const userType = localStorage.getItem("user_type")
    const header = {
      type: userType === 'user entity' ? 'user_entity' : 'business_entity',
      token: localStorage.getItem('token')
    }

    this.getSurveysQuestionApiCallId = await apiCall({
      endPoint: `${configJSON.getSurveysApiEndPoint}/${surveyId}`,
      method: "GET",
      contentType: "application/json",
      header,
    });
  }

  createSurveysAnswer = async () => {

    const surveyId = this.props.location?.state?.surveyData?.id;
    const userType = localStorage.getItem("user_type")
    const header = {
      type: userType === 'user entity' ? 'user_entity' : 'business_entity',
      token: localStorage.getItem('token')
    }

    const httpBody = {
      "data": {
        "attributes": {
          "is_answer": true,
          "survey_answers_attributes": this.state.surveyQuestionAnswers
        }
      }
    }

    this.createSurveysAnswerApiCallId = await apiCall({
      endPoint: `${configJSON.getSurveysApiEndPoint}/${surveyId}/participant_answer`,
      method: "PUT",
      contentType: "application/json",
      header,
      body: JSON.stringify(httpBody)
    });
  }

  handleQuestionType(type: string, isMulti?: boolean) {
    if (type === 'text') {
      return 'text'
    } else if (type === 'range') {
      return 'range'
    } else if (type === 'multiple_choice' && isMulti) {
      return 'radio'
    } else {
      return 'check'
    }
  }


  handleNextPage = (newPage: any) => {

    const ansData =
    {
      "question_id": this.state.questionId,
      "answer": this.state.multipleOptions?.length > 0 ? "" : this.state.radioValue,
      "options": this.state.multipleOptions?.length > 0 ? this.state.multipleOptions : ""
    }


    if (this.state.surveyQuestionAnswers.length > 0) {
      this.setState({ surveyQuestionAnswers: [...this.state.surveyQuestionAnswers, ansData] })
    } else {
      this.setState({ surveyQuestionAnswers: [ansData] })
    }

    this.setState({ activePage: newPage, isDisabled: true, multipleOptions: [], radioValue: "", completedPages: [...this.state.completedPages, newPage - 1] });
  }

  renderPercentageProgress = (num1: number, num2: number) => {
    if (num1 === num2) {
      return 100
    } else {
      return Math.ceil(((num1 - 1) / (num2 - 1)) * 100);
    }
  }
  // Customizable Area End
}
// Customizable Area End
