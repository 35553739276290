// Customizable Area Start
import React, { useState, useEffect } from 'react';
import { Typography, Dialog, Button } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { InitiateButton } from '../../../components/src/design-system/button';
import { useAddWorkEmail, useUserProfile } from '../../../components/src/api/hooks/user.hooks';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useNavigate } from '../../../components/src/utils/router-context';
import { FormikTextField } from '../../../components/src/shared/formik-text-field';
import { useSelector } from 'react-redux';
import { RootState } from '../../../framework/src/redux/stores';
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { RegisterationMobileHeader } from './RegisterationMobileHeader';
import { HideContainer } from '../../../components/src/shared/hide-container';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../../../components/src/api/keys/user.keys';
import clsx from 'clsx';
import { InitiateAlert } from "../../../components/src/design-system/alert";


const useStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'min(530px, 80vh)',
    [theme.breakpoints.down('sm')]: {
      height: '85vh',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  titleText: {
    fontSize: '1.8rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
    textAlign: 'center',
    color: "#019BE1",
    "@media (max-width:960px)": {
      color: "black",
      marginTop: "30px",
      fontSize: '22px',
    },
  },
  subtitleText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '1rem',
    fontWeight: 400,
  },
  noticeText: {
    fontFamily: 'Rene_Bieder_Milliard_Book',
    fontSize: '0.8rem',
    color: '#656469',
    "@media (max-width:600px)": {
      position: 'absolute',
      bottom: '100px'
    },
  },
  submitButton: {
    fontSize: '1.3rem',
  },
  skipButton: {
    fontSize: '1.3rem',
  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContainer: {
    position: 'relative',
  },
}));


const CustomDialog = withStyles({
  root: {
    background: 'transparent',
    // @ts-ignore
    position: 'absolute!important',
  },
  paper: {
    // @ts-ignore
    overflowY: 'initial!important',
    margin: '0',
    maxWidth: '100%',
    borderRadius: '30px',
    boxShadow: 'rgba(212, 212, 212, 0.5) 0px 0px 8px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
})(Dialog);

const useCustomDialogStyles = makeStyles({
  titleText: {
    fontSize: '1.2rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
  },
  redColor: {
    color: "#BB5B5F"
  },
  bodyText: {
    fontSize: '1rem',
    fontFamily: 'Rene_Bieder_Milliard_Medium',
  },
  okBtn: {
    padding: "5px 38px"
  }
});

const validationSchema = yup.object().shape({
  email: yup.string().email("Email must be a valid email").required("Email is a required field"),
});

export function WorkEmailForm() {

  const classes = useStyles();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const isMobile = useIsMobileScreen();
  const customDialogStyles = useCustomDialogStyles();

  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);

  const { accountId } = useSelector((state: RootState) => state.signupForm);
  const { mutate: addWorkEmail, error: emailErrors, isLoading } = useAddWorkEmail();

  const profileQuery = useUserProfile({ accountId: parseInt(accountId) });

  function submitHandler({ email }: { email: string }) {
    addWorkEmail({ accountId: parseInt(accountId), workEmail: email }, {
      onSuccess() {
        queryClient.invalidateQueries(userKeys.fetchProfile({ accountId: parseInt(accountId) }));
        setOpenVerificationDialog(true);
      },
    });
  }
  function skipHandler() {
    navigate('/MyTaskPreferenceweb');
  }
  useEffect(function setEmailError() {
    if(emailErrors?.error){
      setWorkEmailError("true");
    }
  }, [emailErrors]);
  const [workEmailError, setWorkEmailError] = useState("")



  return (
    <>

      <CustomDialog
        fullWidth
        maxWidth="md"
        container={() => document.getElementById('work-email-form')}
        hideBackdrop
        open={openVerificationDialog}
      >

        <Typography color="primary" className={customDialogStyles.titleText}>
          {'Employer is Registered !'}
        </Typography>

        <VerticalSpacer />

        <Typography className={customDialogStyles.bodyText}>
          {'Please check your inbox for an email message with a verification link ( or junk mail folder).'}
        </Typography>

        <VerticalSpacer />

        <InitiateButton
          variant="contained"
          color="primary"
          className={customDialogStyles.okBtn}
          onClick={() => {
            setOpenVerificationDialog(false);
            navigate('/MyTaskPreferenceweb');
          }}
        >
          {'OK'}
        </InitiateButton>

      </CustomDialog>

      <CustomDialog
        fullWidth
        maxWidth="md"
        container={() => document.getElementById('work-email-form')}
        hideBackdrop
        open={!!workEmailError}
      >
        <Typography color="primary" className={`${customDialogStyles.titleText} ${customDialogStyles.redColor}`}>
          {'Employer Not Registered !'}
        </Typography>
        <VerticalSpacer />
        <Typography className={`${customDialogStyles.bodyText} ${customDialogStyles.redColor}`}>
          {"It looks like your work email has been entered incorrectly, or your Employer is not registered on the Initiate platform."}
        </Typography>
        <VerticalSpacer />
        <Typography className={`${customDialogStyles.bodyText} ${customDialogStyles.redColor}`}>
          {"Please verify your email, otherwise you could skip this section for now, and update this section in your profile settings once your Employer is on the platform."}
        </Typography>
        <VerticalSpacer />
        <InitiateButton
          variant="contained"
          color="primary"
          className={customDialogStyles.okBtn}
          data-test-id="ok-btn"
          onClick={() => {
            setWorkEmailError("");
          }}
        >
          {'OK'}
        </InitiateButton>
      </CustomDialog>

      <Formik
        enableReinitialize
        initialValues={{ email: profileQuery?.data?.data.attributes.work_email || '' }}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      ><>
          {/* @ts-ignore */}
          <Form translate="no">

            <div id="work-email-form" className={clsx(classes.formContentRoot, classes.dialogContainer)}>
                <VerticalSpacer />
                <Typography
                  className={classes.titleText}
                >
                  {'Add Work Email'}
                </Typography>
                <VerticalSpacer value={40} />
              <div className={classes.formContent}>
                <Typography className={classes.subtitleText}>
                  {'Would you like to add your work email address?'}
                </Typography>

                <VerticalSpacer />

                <FormikTextField
                  placeholder="Work Email address"
                  name="email"
                  type="email"
                  onClick={() => setWorkEmailError("")}
                />
                <VerticalSpacer value={140} />

                <Typography className={classes.noticeText}>
                  {'Note: Entering your work email adds your employer logo to your profile. Your employer needs to be registered on initiate platform for their logo to be added to your profile.'}
                </Typography>

                <VerticalSpacer value={15} />
              </div>


              <div className={classes.submitButtonsContainer}>
                <InitiateButton
                  loading={isLoading}
                  disabled={isLoading}
                  className={classes.submitButton}
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large">
                  {'Continue'}
                </InitiateButton>

                <HideContainer hideWhen={isMobile}>
                  <Button
                    className={classes.skipButton}
                    fullWidth
                    color="primary"
                    variant="text"
                    onClick={skipHandler}
                    size="large">
                    {'Skip'}
                  </Button>
                </HideContainer>
              </div>
            </div>

          </Form>
          </>
        </Formik>

        <RegisterationMobileHeader
          title=""
          isWorkEmailPage={true}
          onSkip={skipHandler}
        />

      </>
      );
}

// Customizable Area End
