import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils/utils.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
}

export interface S {
  // Customizable Area Start
  id: string;
  sid: string;
  medal: string;
  getPledges: any;
  userDetails: any;
  loading: boolean;
  currentPoints: number;
  pledgeLoading: boolean;
  status: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPledgesApiCallId: string = "";
  getUserProfileApiCallId: string = "";
  getStatusApiCallId: string = "";
  postConnectApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: "",
      sid: "",
      medal: "",
      getPledges: [],
      userDetails: {},
      currentPoints: 0,
      loading: false,
      pledgeLoading: false,
      status: "Not connected"
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage
      ));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getUserProfileApiCallId) {
          this.setState({
            loading: false,
            userDetails: responseJson.data
          });
        } else if (apiRequestCallId === this.getPledgesApiCallId) {
          if (responseJson?.data) {
            this.setState({ getPledges: responseJson.data });
          }
        } else if(apiRequestCallId === this.getStatusApiCallId){
          this.setState({ status: responseJson.status, sid: responseJson.converstion_sid });
        } else if(apiRequestCallId === this.postConnectApiCallId){
          this.setState({ status: "pending" });
        }
      }
    }
  }

  async componentDidMount() {
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.setState({ id }, () => {
        this.getUserProfile(id);
        this.getPledges(id);
        this.getStatus(id);
      });
    }
  }

  onMessageBtnClick = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "GroupChat");
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), this.state.sid);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  getUserProfile = async (id: string) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    };

    this.getUserProfileApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `account_block/accounts/get_user_profile?account_id=${id}`,
    });
  }

  getPledges = async (id: string) => {
    this.setState({ pledgeLoading: true });
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    };

    this.getPledgesApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `my_pledges/active_pledges?account_id=${id}`,
    });
  }

  getStatus = async (id: string) => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    };

    this.getStatusApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `bx_block_add_friends/connection_status?account_id=${id}`,
    });
  }

  onConnectClicked = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    };

    const body = {
      connection: {
        receipient_id: this.state.id
      }
    }

    this.postConnectApiCallId = await apiCall({
      header,
      body: JSON.stringify(body),
      method: "POST",
      endPoint: `/bx_block_add_friends/connection`,
    });
  }
}

// Customizable Area End