import React from "react";

import {
  // Customizable Area Start
  Card,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Switch,
  Avatar,
  Dialog,
  Box,
  Grid,
  Paper,
  Slider,
  Tabs,
  Tab,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FilledInput from "@material-ui/core/FilledInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { styled } from "@material-ui/styles";
import { isMobile } from "react-device-detect";
import InsertCommentOutlinedIcon from "@material-ui/icons/InsertCommentOutlined";
//@ts-ignore
import styles from "../../Gamification/src/Gamification.module.css";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import FundCampaignController, { Props } from "./fundCampaignController.web";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import moment from "moment";
import { icons, images, FONTS} from "../../../components/src/constants";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { CameraIcon, VideoIcon, BroadcastIcon } from "../../../components/src/utils/Icons.web";
//@ts-ignore
import userEvent from "./userEvent.module.css";
import { Elements } from '@stripe/react-stripe-js';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// @ts-ignore
import CheckoutForm from "../../../components/src/custom/CheckOutForm.web";
import { loadStripe } from '@stripe/stripe-js';
import BusDashbaordWeb from "../../ServiceSpecificSettingsAdmin/src/BusDashboard.web";
const configJSON = require("../../ServiceSpecificSettingsAdmin/src/config.js");
const baseURL = require("../../../framework/src/config.js").baseURL;
let stripePubKey = configJSON.STRIPE_KEY_LIVE;
if (!baseURL.includes(".prod")) {
  stripePubKey = configJSON.STRIPE_KEY_TEST;
}
const stripePromise = loadStripe(stripePubKey);
// Customizable Area End

// Customizable Area Start

// Customizable Area End
class FundCampaign extends FundCampaignController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  goBack = () => {
    this.props.type === "r3"
    ? this.props.onClose()
    : this.props.navigation.goBack()
  }

  headerWeb() {
    return (
      <>
      <Toolbar className={`${userEvent.toolBar} ${this.props.type === "r3" && userEvent.toolBarR3}`}>
        <Box margin={this.props.type === "r3" ? "0px":""} className={`${userEvent.backIconWeb} ${this.state.project_journey ? userEvent.projectJourney : ''}`}
        >
          <IconButton
            data-test-id="back-btn"
            aria-label="Open drawer"
            color="inherit"
            onClick={() =>
              this.state.project_journey
                ? this.setState({ project_journey: false })
                : this.goBack()
            }>
            <img src={icons.back} />
          </IconButton>
          <Typography className={userEvent.projectFundingTitle} variant="h6" noWrap>
            {this.state.project_journey ? "Project Journey" : "Funding Campaign"}
          </Typography>
        </Box>
      </Toolbar>
      {this.props.type === "r3" && <hr className={userEvent.divider} />}
      </>
    );
  }

  renderJournySoFarButtonForWeb = () => {
    const { latestFundUpdates, projectData } = this.state;
    return (
    <>
      {(latestFundUpdates.length > 0 &&  projectData?.project_update && projectData?.donors) &&(
        <div className={"showinWebjourny"}>
          <Button
            className={userEvent.journeybutton}
            endIcon={<img src={icons.bluerightarrow} />}
            onClick={() => {
              this.setState({ project_journey: !this.state.project_journey });
            }}
            data-test-id="journeyBtn">
            <Typography>Journey so far</Typography>
          </Button>
        </div>
      )}
    </>
    );
  };

  renderFundAmountPicker = () => {
    return (
      <Grid container spacing={3} className="fundSlider">
        <Grid item xs={12}>
          <Paper elevation={0} className={userEvent.amountPickerWrapper}>
            <div className={userEvent.amountPickerHeader}>
              <Typography className={userEvent.amountPickerText}>Target Amount:</Typography>
              <Typography className={userEvent.amountPickerText}>
                <span>&#163;</span>{" "}
                {this.state.projectData?.target_amount?.toLocaleString() ?? "--"}
              </Typography>
            </div>
            <Slider
              max={this.state.projectData?.target_amount ?? 0}
              getAriaValueText={this.donatevaluetext}
              className={userEvent.amountrangeSliderColor}
              value={this.state.amountSliderValue ?? 0}
              valueLabelDisplay="on"
              valueLabelFormat={() => (
                <div>
                  <span>&#163; </span>
                  {this.state.amountSliderValue?.toLocaleString() ?? "0"}
                </div>
              )}
            />
            <div>
              <Typography className={userEvent.raisedText}>Raised so far</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  renderPaymentDialog = () => {
    const { id, amountValue, userEntered, pointsRange } = this.state;
    return (
      <Dialog
        className="dialogWrapDonation"
        open={this.state.openPaymentDialog}
        data-test-id="modal-close2"
        onClose={() => this.setState({ openPaymentDialog: false })}
        maxWidth="md">
        <PaymentDialog>
          <div className="header">
            <div className="titleModal">Make donation</div>
            <HighlightOffIcon onClick={() => {this.setState({openPaymentDialog: false })}} />
          </div>
          <div className="content">
          <Elements stripe={stripePromise}>
            <CheckoutForm
              data-test-id="checkout"
              type="r1"
              eventId={id}
              onChangeState={this.onChangeState}
              donationAmount={amountValue !== null ? pointsRange[amountValue] : userEntered}
              paymentableType="BxBlockEvents::NonProfitEvent"
            />
          </Elements>
          <Box className="checkout">
            <div>
              <div className="sub-title">Donation Details</div>
                <Box display="flex" justifyContent="space-between" mb="10px">
                  <div className="total">Donation for {this.state.title}</div>
                  <div className="plan-amount"> £ {this.state.pointsRange[this.state.amountValue] || this.state.userEntered}</div>
                </Box>
              <div className="border" />
              <Box display="flex" justifyContent="space-between">
                <div className="total">Total</div>
                <div className="plan-amount">£ {this.state.pointsRange[this.state.amountValue] || this.state.userEntered}</div>
              </Box>
            </div>
          </Box>
          </div>
        </PaymentDialog>
      </Dialog>
    );
  };

  favoriteIcon = () => {
    return(
      <>
      {this.state.isFavorite ? (
      <FavoriteIcon
        className={userEvent.FavoriteIcon}
        onClick={this.removeFromFavoriteNeed}
        data-test-id="favBtn"
      />
    ) : (
      <FavoriteBorderIcon
        onClick={this.addToFavoriteNeed}
        className={userEvent.FavoriteBorderIcon}
        data-test-id="unFavBtn"
      />
    )}
    </>
    )
  }

  renderTopCard = () => {
    return (
      <>
        <div className={this.props.type === "r3" ? userEvent.leftCardR3 : userEvent.leftCard}>
          <img
            className={userEvent.cardImage}
            src={this.state.eventImage ?? images.cardImg2}
            alt="event image"
          />
          {this.props.type !== "r3" && this.favoriteIcon()}
        </div>
        <Box className={`${userEvent.fundCampaign} ${this.props.type === "r3" && userEvent.fundCampaignR3}`}>
          <div className={userEvent.spaceBetweenDivs}>
            <div>
              <Typography className={userEvent.titleFont}>{this.state.title}</Typography>
              <div 
                className={`${userEvent.userInfo} ${this.props.type === "r3" && userEvent.defaultCursor}`} 
                data-test-id="profile-info"
                onClick={() => this.props.type !== "r3" && this.props.navigation.navigate("NonProfitProfile", { id: this.state.organizationId})}
              >
                <div className={userEvent.avatarImgcontainer}>
                  {this.state.organizationLogo ? (
                    <img
                      alt="not found!"
                      src={this.state.organizationLogo}
                      className={userEvent.avatarImg}
                    />
                  ) : (
                    <Avatar style={{ height: "30px", width: "30px" }}>
                      <Box fontSize="14px">
                        {this.state.organizationName.charAt(0).toUpperCase()}
                      </Box>
                    </Avatar>
                  )}
                </div>
                <Typography variant="body1" className={userEvent.userName}>
                  {this.state.organizationName}
                </Typography>
              </div>
            </div>
            {!this.state.project_journey && <div>{this.renderJournySoFarButtonForWeb()}</div>}
          </div>
          <Box display="flex" alignItems="center" mt="14px" className={userEvent.avatarImgcontainer}>
            <img
              src={icons.locationred}
              className={userEvent.avatarImg}
              alt="not-found"
            />
            <Box ml="14px" className={userEvent.eventdate_loc}>{this.state.location}</Box>
          </Box>
          {this.renderFundAmountPicker()}
        </Box>
      </>
    );
  };

  renderBottomCard = () => {
    return (
      <BottomCard>
        {this.state.projectData?.description && (
          <div className={userEvent.fundCampaign}>
            <div className={userEvent.about}>Description</div>
            <div className={userEvent.description}>
              {this.detectUrls(this.state.projectData.description)}
            </div>
          </div>
        )}
        {this.state.project_journey ? this.renderFundUpdates() : this.renderfundDetails()}
      </BottomCard>
    );
  };

  detectUrls = (data:any) => {
    return (data.split("\n")
    .map((desc: string, index: number) => {
      const key = index + 1
      const parts = desc.split(/(https?:\/\/[^\s]+)/);
      const elements = parts.map((part:any, index:number) =>
        /^https?:\/\/[^\s]+$/i.test(part) ? (
        <a key={key} href={part} target="_blank" rel="noopener noreferrer">{part}</a>
        ) : (part)
      );
      return <p key={key} className="descParagraph">{elements}</p>;
    }))
  }

  renderProjectOutcome = () => {
    return (
      <>
       {this.state.projectData?.beneficiaries && (
          <div className={userEvent.fundCampaign}>
            <div className={userEvent.about}>Beneficiaries</div>
            <div className={userEvent.description}>
            {this.detectUrls(this.state.projectData.beneficiaries)}
            </div>
          </div>
        )}

        {this.state.projectData?.project_outcomes && (
          <div className={userEvent.fundCampaign}>
            <div className={userEvent.about}>Project outcome</div>
            <div className={userEvent.description}>
              {this.detectUrls(this.state.projectData.project_outcomes)}
            </div>
          </div>
        )}
      </>
    )
  }

  renderButtonName = () => {
   if (this.state.amountValue !== null){
      return `Donate £ ${this.state.pointsRange[this.state.amountValue]}`
    } else if (this.state.userEntered){
      return `Donate £ ${this.state.userEntered}`
    } else {
      return "Donate"
    }
  }

  renderfundDetails = () => {
    const { pointsRange, amountValue } = this.state;
    return (
      <>
        {this.renderProjectOutcome()}
        <div className={`${userEvent.fundCampaign} ${this.props.type === "r3" && userEvent.fundCampaignR3}`}>
          <div className={userEvent.about}>Donations</div>
            <Paper className={styles.tableroot} elevation={0}>
            <div className="formControlLabel">Donate with amount</div>
              <>
                  <div className={styles.mainBtnDiv}>
                    <Tabs
                      value={amountValue}
                      onChange={this.handleAmountTabChange}
                      data-test-id="tabs"
                      className="donateTabs"
                      TabIndicatorProps={{
                        style: {
                          display: "none",
                        },
                      }}>
                      {pointsRange.map((point: number, index: number) => {
                        return (
                          <DonateTab
                            key={point}
                            label={`£ ${point}`}
                            className={`${amountValue === index && "activeBtn"} ${
                              "normalBtn"
                            }`}
                          />
                        );
                      })}
                    </Tabs>
                  </div>

                  <Donation>
                    <FilledInput
                      id="filled-adornment-amount"
                      type="number"
                      data-test-id="donationInput"
                      placeholder="Enter donation amount"
                      className="totalAmount"
                      onChange={this.handleDonationAmount}
                      value={this.state.userEntered}
                      startAdornment={
                       <InputAdornment position="start">£</InputAdornment>
                      }
                    />
                  </Donation>
              </>
            </Paper>
          <div className={userEvent.spaceBetweenDivs}>
            <Button
              className={`${userEvent.BlueBtn} ${userEvent.donateButton}`}
              data-test-id="donate-button"
              onClick={this.handleDonate}>
              {this.renderButtonName()}
            </Button>
          </div>
        </div>
      </>
    );
  };

  openDonateDialog = () => {
    return (
      <Dialog
        className="dialogWrapDonation"
        open={this.state.openDonateDialog}
        data-test-id="modal-close"
        onClose={() => this.setState({ openDonateDialog: false })}>
        <DialogWrapper>
          <Box className={styles.dialogWrapper} padding={{ xs: "25px", sm: "50px" }}>
            <div className={`${styles.dialogWrapperInner}`}>
              <img src={icons.successIcon} />
            </div>
            <div style={{ textAlign: "center" }}>
              <Typography className={`${styles.userName} ${styles.message} titleModal`}>
                Thank you for your donation!
              </Typography>
              <div className={`${styles.donatedPoints} descriptionModal`}>
                You have successfully donated <span> 
                  {this.state.dynamicText === "amount" ? 
                  `£${this.state.donatedAmount}` :
                  `${this.state.donatedPoints} points`
                } </span> to {" "}
                {this.state.title}
              </div>
              <Button
                data-test-id="backtoProfile"
                className={`${styles.goToProfile} buttonModal`}
                onClick={() => this.setState({ openDonateDialog: false })}>
                GO BACK
              </Button>
            </div>
          </Box>
        </DialogWrapper>
      </Dialog>
    );
  };
 

  getIcons = (type: string) => {
    if (type === "image") {
      return <CameraIcon />;
    } else if (type === "video") {
      return <VideoIcon />;
    } else {
      return <BroadcastIcon />;
    }
  };

  getFundMedia = () => {
    let currentTab =
      this.state.activeTab &&
      this.state.latestFundUpdates.filter((item: any) => item.id === this.state.activeTab);
    if (currentTab && currentTab[0].type === "image") {
      return <img src={currentTab[0].url} />;
    } else if (currentTab && currentTab[0].type === "video") {
      return (
        <video width="320" height="240" controls src={currentTab[0].url}>
          <source src={currentTab[0].url} />
        </video>
      );
    } else if (currentTab && currentTab[0].type === "note") {
      return (
        <div className={userEvent.Notesfund}>
            <div className={userEvent.content}>

          {currentTab[0].note.split("\n").map((desc: string, index: number) => {
            return (
              <p key={`${index + 1}`} className={userEvent.detailDescription}>
                {desc}
              </p>
            );
          })}
          </div>
        </div>
      );
    }
  };

  getfundingUpdate = () => {
    let currentTab =
      this.state.activeTab &&
      this.state.latestFundUpdates.filter((item: any) => item.id === this.state.activeTab);
    return (
      <div className={userEvent.fundUpdates}>
        <InsertCommentOutlinedIcon
          className={userEvent.commentIcon}
          data-test-id="commentIcon"
          onClick={() => {
            this.setState({
              msgModal: true,
              currentType: currentTab[0].type,
              currentId: currentTab[0].id,
              currentComment: currentTab[0].comments[0]?.comment ?? "",
              currentCommentId: currentTab[0].comments[0]?.comment_id ?? "",
            });
          }}
        />
        <div className={`${userEvent.cameraIcon} ${userEvent.fund}`}>{this.getFundMedia()}</div>
      </div>
    );
  };

  renderFundUpdates = () => {
    return (
        <div className={userEvent.fundCampaign}>
          <div className={userEvent.updatesSection}>Updates</div>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={9} md={9}>
              <div className={userEvent.fundUpdates}>
                <InsertCommentOutlinedIcon
                  className={userEvent.commentIcon}
                  data-test-id="commentIcon"
                  onClick={() => {
                    this.setState({ msgModal: true });
                  }}
                />
                <div className={userEvent.cameraIcon}>{this.getfundingUpdate()}</div>
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={3} className="updatesSection">
              <div className={userEvent.updateresponsive}>
                {this.state.latestFundUpdates.map((item: any) => {
                  return (
                    <div
                      key={item.id}
                      data-test-id="updatesTab"
                      onClick={() => this.setState({ activeTab: item.id })}
                      className={
                        item.id === this.state.activeTab
                          ? `${userEvent.tabBtn} ${userEvent.tabBtnActive}`
                          : `${userEvent.tabBtn}`
                      }>
                      <div className={userEvent.btnIcon}>{this.getIcons(item.type)}</div>
                      <div className={userEvent.btnText}>
                        {moment(item.date).format("DD  /MM")}{" "}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
    );
  };

  fundModalBoxContainer = () => {
    return (
      <Dialog
        data-test-id="fundModal"
        open={this.state.msgModal}
        onClose={() => this.setState({ msgModal: false })}
        className="commentModal">
        <div className={`${userEvent.dialogWrapper} ${userEvent.fundFeedback}`}>
          <div className={userEvent.spaceBetweenDivs}>
            <Typography className={userEvent.userName} style={{ fontWeight: 600 }}>
              Comment on updates
            </Typography>
            <img
              data-test-id="closeCommentModal"
              src={icons.cancelIcon}
              style={{ cursor: "pointer" }}
              onClick={() => this.setState({ msgModal: false })}
            />
          </div>

          <Typography
            style={{
              fontSize: 14,
              marginTop: 12,
            }}>
            Your feedback will be sent to the organiser
          </Typography>

          <div className={userEvent.dilogContainerWidth}>
            <textarea
              rows={7}
              maxLength={70}
              data-test-id="commentInput"
              value={this.state.currentComment}
              className={userEvent.commentInput}
              placeholder="Type something....."
              onChange={(e) => {
                this.setState({ currentComment: e.target.value });
              }}
            />
            <Typography className={userEvent.commentTextCount}>
              {this.state.currentComment.length ? this.state.currentComment.length : 0}/70
            </Typography>
          </div>

          <Button
            data-test-id="submitBtn"
            className={userEvent.BlueBtn}
            onClick={() => {
              if (this.state.currentCommentId) {
                this.updateMediaComment();
              } else {
                this.postMediaComment();
              }
            }}>
            Submit
          </Button>
        </div>
      </Dialog>
    );
  };

  children = () => {
    return (
      <Box className={`${userEvent.eventContainer} ${this.props.type === "r3" && userEvent.ml0}`}>
        {this.props.type !== "r3" && !isMobile && this.headerWeb()}
        <Card>
          {this.renderTopCard()}
          {this.renderBottomCard()}
        </Card>
        {this.fundModalBoxContainer()}
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {this.props.type !== "r3" ?
       <>
        {/* @ts-ignore */}
        <DashboardWeb
          data-testId="sidebar"
          title={this.state.project_journey ? "Project Journey" : "Funding Campaign"}
          showBackButton={true}
          showBottomBar={true}
          showDrawerHeader={isMobile}
          navigation={{
            ...this.props.navigation,
            goBack: () => {
              this.state.project_journey
                ? this.setState({ project_journey: false })
                : this.goBack()
            }
          }}
        >{this.children()}</DashboardWeb>
      </>
      :
      <BusDashbaordWeb classes={{}} theme={{}} navigation={this.props.navigation} id={""}>
        <Wrapper>
          <Box className="wrapper">
          {this.state.loading ? 
          <Box className="loading">
            <CircularProgress className="loader" /> 
          </Box>
          : this.children()}
          </Box>
        </Wrapper>
        </BusDashbaordWeb>
        }
        {this.openDonateDialog()}
        {this.renderPaymentDialog()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    switchBase: {
      "&$checked": {
        color: COLORS.skyBlue,
        transform: "translateX(16px)",
        "& + $track": {
          backgroundColor: COLORS.white,
          opacity: 1,
          border: "1px solid #009BE2",
        },
      },
      padding: 2,
      color: "rgb(136 136 136)",
    },
    track: {
      border: " 1px solid rgba(151, 151, 151, 0.43272)",
      opacity: 1,
      backgroundColor: COLORS.white,
      borderRadius: "11.5px",
    },
    root: {
      borderRadius: "11.5px",
      display: "flex",
      height: "20px",
      padding: 0,
      width: "38px",
      margin: "30px 0 15px",
    },
    thumb: {
      "&$checked": {
        "& + $track": {
          opacity: 1,
          border: "1px solid #009BE2",
          backgroundColor: COLORS.white,
          borderColor: COLORS.skyBlue,
        },
        transform: "translateX(16px)",
        color: COLORS.skyBlue,
      },
      width: "16px",
      height: "16px",
      boxShadow: "none",
    },
    checked: {},
  })
)(Switch);

const Donation = styled(Box)({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  "& .totalAmount": {
    width: "50%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    padding: "20px 15px",
    borderRadius: "15px",
    background: "white",
    ...FONTS.regularFont,
    "& div p": {
      color: "#000",
      ...FONTS.regularFont,
    },
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-input": {
      padding: 0,
    },
  },
  "& .donationFreq": {
    marginLeft: "50px",
    "& label span": {
      fontSize: "14px",
      ...FONTS.regularFont,
    },
    "& label span:first-child": {
      color: "#009be2",
      padding: "3px",
    },
  },
});

const DialogWrapper = styled(Box)({
  background: "rgb(254, 254, 254)",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 8px",
  borderRadius: "12px",
  border: "1px solid rgb(217, 246, 250)",
  maxWidth: "550px",
  padding: "0px",
  "& .titleModal" : {
    ...FONTS.mediumFont
  },
  "& .descriptionModal, .buttonModal": {
    ...FONTS.regularFont
  }
});

const PaymentDialog = styled(Box)({
  background: "rgb(254, 254, 254)",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 8px",
  borderRadius: "12px",
  border: "1px solid rgb(217, 246, 250)",
  width: "930px",
  padding: "25px",
  "@media (max-width: 600px)": {
   width:"100%",
   padding: "20px"
  },
 
  "& .header" : {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .titleModal" : {
      ...FONTS.mediumFont,
      fontSize: "20px"
    },
    "& svg": {
      cursor: "pointer"
    }
  }, 
  "& .content" : {
    display: "flex",
    alignItems: "start",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse"
    },
    "& .checkout": {
      padding: "20px 30px",
      borderRadius: "12px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      "@media (max-width: 600px)": {
        padding: "20px 0",
      },
      "& .sub-title": {
        fontSize: "18px",
        ...FONTS.mediumFont,
        letterSpacing: "-0.7px",
        marginBottom: "15px"
      },
      "& .plan-amount": {
        fontSize: "18px",
        fontFamily: "Rene_Bieder_Milliard_Medium",
        letterSpacing: "-0.5px",
        textWrap: "nowrap"
      },
      "& .border": {
        margin: "10px 0px",
        border: "1px solid #bebebe"
      },
      "& .total": {
        fontSize: "16px",
        ...FONTS.regularFont,
        letterSpacing: "-0.45px",
      }
    },

  }
})

const BottomCard = styled(Box)({
  "& .descParagraph": {
    "& a": {
      color: "009be0",
      wordWrap: "break-word"
    }
  },
  "& .currentPoints":{
    fontSize:"24px",
    color: "rgb(0, 155, 226)",
    ...FONTS.semiBoldFont,

  }
})

const DonateTab = styled(Tab)({
  "&.disabledBtn": {
    borderRadius: "5px !important",
    border: "1px solid #ebebeb !important",
    color: "#99999B !important",
    marginRight: "15px !important",
    textTransform: "lowercase",
    minHeight: "50px",
    fontSize: "16px !important",
    ...FONTS.mediumFont,
    background: "#ebebeb !important",
    "@media (max-width: 600px)": {
      fontSize: "14px !important",
      marginBottom: "15px !important",
    }
  },
  "&.normalBtn" : {
    borderRadius: "5px !important",
    border: "1px solid #0091be !important",
    marginRight: "15px !important",
    color: "#030303",
    textTransform: "lowercase",
    minHeight: "50px",
    fontSize: "16px !important",
    ...FONTS.mediumFont,
    "@media (max-width: 600px)": {
      fontSize: "14px !important",
      marginBottom: "15px !important",
    }
  },
  "&.activeBtn" : {
    border: "1px solid #009be2 !important",
    backgroundColor: "#009be2 !important",
    color: "#ffffff !important",
    ...FONTS.mediumFont,
    "@media (max-width: 600px)": {
      fontSize: "14px !important",
      marginBottom: "15px !important",
    }
  }
})

const Wrapper = styled(Box)({
  marginLeft: "280px",
  "& .wrapper": {
    position: "relative",
    left: "40px",
    top: "40px",
    width: "93%",
    borderRadius: "12px",
    border: "1px dashed #ffffff",
    overflow: "hidden"
  },
  "& .loading": {
    height: "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    "& .loader": {
      color: "rgb(0, 155, 226)"
    }
  }
});

export default FundCampaign;
// Customizable Area End