import React from "react";
import PropTypes from 'prop-types';
import ServiceSpecificSettingsAdminController from "./ServiceSpecificSettingsAdminController";
import BusDashbaordWeb from "./BusDashboard.web";
import GooglePlacesAutocomplete,{ geocodeByAddress } from 'react-google-places-autocomplete';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// @ts-ignore
import styles from './BusProfile.module.css'
import { Box, Card, Container, Dialog, Input, Snackbar, SnackbarContent, Typography, CardContent, Button, Switch, withStyles, createStyles, Theme } from '@material-ui/core'
import { FONTS, icons } from "../../../components/src/constants";
import { configJSON } from "../../ContentManagement/src/ContentManagementController";


const validationSchema = Yup.object().shape({
	name: Yup.string().required('Please enter Name'),
	regNum: Yup.string(),
	contact_num: Yup.string().test('len', 'Please enter valid mobile number', (val) => val && val.length === 12),
	email_domain: Yup.string(),
	desc: Yup.string().required('Please enter description'),
	focus: Yup.string().nullable(),
	street: Yup.string(),
	town: Yup.string(),
	country: Yup.string(),
	pincode: Yup.string(),
	offPincode: Yup.string(),
	offStreet: Yup.string(),
	offTown: Yup.string(),
	offCountry: Yup.string(),
	employeeNum: Yup.string(),
	offAddress: Yup.boolean(),


})
const AntSwitch: any = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 32,
			height: 18,
			padding: 0,
			display: "flex",
			marginLeft: 5,
		},
		switchBase: {
			padding: 2,
			// color: theme.palette.grey[500],
			color: "white",
			"&$checked": {
				transform: "translateX(14px)",
				color: theme.palette.common.white,
				"& + $track": {
					opacity: 1,
					backgroundColor: "#359ce2",
					borderColor: theme.palette.primary.main,
				},
			},
		},
		thumb: {
			width: 13,
			height: 13,
			boxShadow: "none",
		},
		track: {
			border: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: 18 / 2,
			opacity: 1,
			// backgroundColor: theme.palette.common.white,
			backgroundColor: "grey",
		},
		checked: {},
	})
)(Switch);


export default class BusProfile extends ServiceSpecificSettingsAdminController {

	static propTypes: {
		classes: PropTypes.Validator<object>;
		theme: PropTypes.Validator<object>;
	}


	handleFileChange = (e: any, setFieldValue: any): any => {
		if (e.target.files[0]) {
			setFieldValue('logo', URL.createObjectURL(e.target.files[0]));
			setFieldValue('logoPost', e.target.files[0]);
		}
	};

	handleNumChange = (e: any, setFieldValue: any) => {
		if (e.target.value.length < 13) {
			setFieldValue('contact_num', e.target.value)
		}
	}

	handleSwitch = (e: any, setFieldValue: any) => {
		console.log(e.target.checked, e.target.value, 'values123')
		setFieldValue('offAddress', e.target.checked)
	}

	getAddressDetails = (results: google.maps.GeocoderAddressComponent[]) =>  {
        const addressComponents = {
		  country: "",
          city: "",
          postcode: "",
        };
    
        for (const obj of results) {
          if (obj.types.includes('postal_code')) {
            addressComponents.postcode = obj.long_name;
		  } else if (!addressComponents.city && (obj.types.includes('postal_town') || obj.types.includes("administrative_area_level_2") || obj.types.includes('administrative_area_level_1'))) {
            addressComponents.city = obj.long_name;
          } else if (obj.types.includes('country')) {
            addressComponents.country = obj.long_name;
          }
        }
        return addressComponents;
    }

	formikField(initialValues: any) {
		return (
			<Formik initialValues={initialValues}
				enableReinitialize
				onSubmit={values => this.saveOrg(values)}
				validationSchema={validationSchema}
			>
				{({ values, touched, handleSubmit, errors, handleBlur,
					handleChange, setFieldValue, }) => (
                      // @ts-ignore
					<Form translate={"no"} onSubmit={handleSubmit} >
						<div className={styles.divWrapper}>
							<label className={styles.label}>Name of business</label>
							<br />
							<Input data-testId='name' error={Boolean(errors?.name)} className={styles.textInput} placeholder="Name" disableUnderline name='name' value={values.name} id='name' onChange={handleChange} type="text" onBlur={handleBlur} />
							<br />
							<label className={styles.errorMsg}> {touched?.name && errors?.name && String(errors?.name)} </label>
						</div>
						<div className={styles.divWrapper}>
							<label className={styles.label}>Registration number</label>
							<br />
							<Input type='text' data-testId='regNum' className={styles.textInput} onKeyDown={(e) => {
								if (e.keyCode === 38 || e.keyCode === 40) {
									e.preventDefault()
								}
							}} disableUnderline placeholder="Reg number" name='regNum' value={values.regNum} id='regNum' onChange={handleChange} onBlur={handleBlur} />
						</div>
						<div className={styles.divWrapper}>
							<label className={styles.label}>Contact number</label>
							<br />
							<Input type='number' data-testId='contactNum' onKeyDown={(e) => {
								if (e.keyCode === 38 || e.keyCode === 40) {
									e.preventDefault()
								}
							}}
								className={styles.textInput} disableUnderline placeholder="Number" name='contact_num' value={values.contact_num} id='contact_num' onChange={(e: any) => { this.handleNumChange(e, setFieldValue) }} onBlur={handleBlur} />
							<br />
							<label className={styles.errorMsg}> {errors?.contact_num} </label>
						</div>
						<div className={styles.divWrapper}>
							<label className={styles.label}>Corporate email domains</label>
							<br />
							<Input className={styles.textInput} data-testId='email_domain' disableUnderline name='email_domain' placeholder="Corporate email domains" value={values.email_domain} id='email_domain' onChange={handleChange} onBlur={handleBlur} />
						</div>

						<div className={styles.divWrapper}>
							<label className={styles.label}>Brief description of business</label>
							<br />
							<Input multiline className={styles.multiLineInput} rows={6} data-testId='desc' disableUnderline name='desc' placeholder="Description" value={values.desc} id='desc' onChange={handleChange} onBlur={handleBlur} />
					        <br />
							<label className={styles.errorMsg}> {errors?.desc} </label>	
						</div>
						<div className={styles.divWrapper}>
							<label className={styles.label}>Our social focus</label>
							<br />
							<Input multiline className={styles.multiLineInput} rows={6} data-testId='focus' disableUnderline name='focus' placeholder="Social focus" value={values.focus} id='focus' onChange={handleChange} onBlur={handleBlur} />
							<br />
							<label style={{
								...FONTS.body1,
								color: "lightgrey",
								fontSize: 12,
								fontWeight: 400,
							}}>This text will be shown in your profile</label>
						</div>
						<div className={styles.divWrapper}>
							<label className={styles.label}>Address</label>
							<br />
							<Box width="50%" className="address-location business">
								<GooglePlacesAutocomplete
									data-testId='street'
									debounce={500}
									apiKey={configJSON.mapsAPIKey}
									selectProps={{
										isClearable: true,
										placeholder: "Street",
										value: this.state.street ? [{ label: this.state.street, value: this.state.street }] : undefined,
										noOptionsMessage: () => null,
										classNamePrefix: "react-select",
										onChange: async (event) => {
											const location = event?.value?.description ?? "";
											setFieldValue("street", location);
											setFieldValue("country", "");
											setFieldValue("town", "");
											setFieldValue("pincode", "");
											this.setState({
												street: location,
												town: "",
												country: "",
												pincode: ""
											});
											geocodeByAddress(location)
												.then(results => {
													const { postcode, city, country } = this.getAddressDetails(results[0].address_components);
													setFieldValue("country", country);
													setFieldValue("town", city);
													setFieldValue("pincode", postcode);
													this.setState({
														town: city,
														country: country,
														pincode: postcode
													});
												}
											);
										}
									}}
								/>
							</Box>
							<br />
							<Input className={styles.textInput} disableUnderline name='town' id='town' data-testId='town' placeholder="Town" value={values.town} onChange={handleChange} onBlur={handleBlur} />
							<div className={styles.twoInputs}>
								<Input disableUnderline name="country" id='country' data-testId='country' className={styles.halfInput} value={values?.country} placeholder="Country" onChange={handleChange} onBlur={handleBlur} />
								<Input disableUnderline className={styles.halfInput} data-testId='pincode' name="pincode" id='pincode' value={values?.pincode} placeholder="Pincode" onChange={handleChange} onBlur={handleBlur} />
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
							<label className={styles.label} style={{ marginLeft: 10 }}>Office address different from above?</label>
							<div style={{ marginTop: 10, marginRight: 10 }}>
								<AntSwitch
									name='offAddress' id='offAddress'
									checked={values?.offAddress}
									onChange={(e: any) => this.handleSwitch(e, setFieldValue)}
									onBlur={handleBlur}
									data-testId='offSwitch'
								/>
							</div>
						</div>
						<>{values?.offAddress ?
							<div className={styles.divWrapper}>
								<Box width="50%" className="address-location business">
									<GooglePlacesAutocomplete
										data-testId='office-street'
										debounce={500}
										apiKey={configJSON.mapsAPIKey}
										selectProps={{
											isClearable: true,
											placeholder: "Street",
											value: values.offStreet ? [{ label: values.offStreet, value: values.offStreet }] : undefined,
											noOptionsMessage: () => null,
											classNamePrefix: "react-select",
											onChange: async (event) => {
												const location = event?.value?.description ?? "";
												setFieldValue("offStreet", location);
												setFieldValue("offCountry", "");
												setFieldValue("offTown", "");
												setFieldValue("offPincode", "");
												geocodeByAddress(location)
													.then(results => {
														const { postcode, city, country } = this.getAddressDetails(results[0].address_components);
														setFieldValue("offCountry", country);
														setFieldValue("offTown", city);
														setFieldValue("offPincode", postcode);
													}
												);
											}
										}}
									/>
								</Box>
								<br />
								<Input className={styles.textInput} disableUnderline name='offTown' data-testId='offTown' id='offTown' placeholder="Town" value={values.offTown} onChange={handleChange} onBlur={handleBlur} />
								<div className={styles.twoInputs}>
									<Input disableUnderline className={styles.halfInput} data-testId='offCountry' name="offCountry" id='offCountry' placeholder="Country" value={values?.offCountry} onChange={handleChange} onBlur={handleBlur} />
									<Input disableUnderline className={styles.halfInput} placeholder="Pincode" data-testId='offPincode' value={values?.offPincode} onChange={handleChange} onBlur={handleBlur} name="offPincode" id='offPincode' />
								</div>
							</div>
							: null}
						</>
						<div className={styles.divWrapper}>
							<label className={styles.label}>Number of employees</label>
							<br />
							<Input readOnly className={styles.textInput} placeholder="Number of employees" value={values?.employeeNum} name="employeeNum" id='employeeNum' disableUnderline type="number" onChange={handleChange} onBlur={handleBlur} />
							<br />
							<label style={{
								...FONTS.body1,
								color: "lightgrey",
								fontSize: 12,
								fontWeight: 400,
							}}>Read only</label>
						</div>
						<div className={styles.divWrapper} style={{ width: '50%' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<label className={styles.label} style={{ marginTop: 10 }}>Logo</label>
								<Button style={{ color: '009be2', fontSize: 18, textTransform: 'capitalize', fontFamily: 'Rene_Bieder_Milliard_SemiBold', }}>
									<label htmlFor="changeLogo">Change</label>
									<input
										accept="image/jpeg"
										type="file"
										hidden
										name='changeLogo'
										id='changeLogo'
										onChange={(e) => this.handleFileChange(e, setFieldValue)}
									/>
								</Button>
							</div>
							<Card style={{ height: 90, width: 90 }}>
								<img src={values?.logo} alt="logo" style={{ height: 90, width: 90 }} />
							</Card>
						</div>
						{this.btnGrp()}
					</Form>
				)}

			</Formik>
		)
	}




	btnGrp() {
		return (
			<div
				className={styles.divWrapper}
				style={{ display: "flex", marginTop: 15, marginLeft: -10 }}
			>
				<Button type='submit'
					style={{
						fontFamily: "Rene_Bieder_Milliard_SemiBold",
						width: "12%",
						boxShadow: "none",
						textTransform: "capitalize",
						height: 45,
						backgroundColor: "#009be2",
						color: "white ",
						borderRadius: 10,
						marginRight: 13,
						marginLeft: 13,
					}}
					color="primary"
					data-testId='saveBtn'
				>
					Save
				</Button>
				<Button
					data-testId='cancelBtn'
					style={{
						fontFamily: "Rene_Bieder_Milliard_SemiBold",
						width: "12%",
						backgroundColor: 'white',
						color: "#009BE2",
						boxShadow: "none",
						borderRadius: 8,
						height: 45,
						border: ".1 solid lightgray",
						textTransform: "capitalize",
					}}
					variant="outlined"
				>
					Cancel
				</Button>
				<Button
					data-testId='deleteBtn'
					onClick={() => { this.setState({ deleteConf: true }) }}
					style={{
						fontFamily: "Rene_Bieder_Milliard_SemiBold",
						width: "14%",
						backgroundColor: "red",
						color: "white ",
						boxShadow: "none",
						borderRadius: 8,
						height: 45,
						marginLeft: "auto",
						marginRight: 20,
						border: "0.1px solid lightgray",
						textTransform: "capitalize",
					}}
					variant="outlined"
				>
					Delete Account
				</Button>
			</div>
		)
	}

	snackbarContent() {
		return (
			<Snackbar
				open={this.state.successMsg.length > 0}
				onClose={() => {
					this.setState({ successMsg: "" });
				}}
				autoHideDuration={3000}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<SnackbarContent
					className={styles.snackbar}
					message={this.state.successMsg}
				/>
			</Snackbar>
		)
	}

	dialogContent() {
		return (
			<Dialog
				hideBackdrop
				PaperProps={{
					style: {
						background: "#FEFEFE",
						boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.121066)",
						borderRadius: 18,
						maxHeight: 370,
						width: 600,
					},
					elevation: 0,
				}}
				open={this.state.deleteConf}
				data-testId="modal"
				onClose={() => {
					this.setState({ deleteConf: false });
				}}
			>
				{this.contentInDialog()}
			</Dialog>
		)
	}

	contentInDialog() {
		return (
			<div style={{ padding: 15 }}>
				<div style={{ textAlign:"center" }}>
					<Typography className={styles.heading}>
					Are you sure you want to delete this account?
				</Typography>
				</div>
				
				<div
					style={{
						display: "flex",
						flexDirection: "column",
		
					}}
				>
					<Button
						onClick={() => {
							this.deleteAccount();
							this.setState({ deleteConf: false });
						}}
						style={{
							width: "70%",
							alignSelf: "center",
							boxShadow: "none",
							textTransform: "capitalize",
							height: 45,
							backgroundColor: "#009be2",
							color: "white ",
							borderRadius: 10,
							marginTop:10,
						marginBottom:10
						}}
					>
						Confirm
					</Button>
					<br />
					<Button
						onClick={() => { this.setState({ deleteConf: false }) }}
						style={{
							fontFamily: "Rene_Bieder_Milliard_SemiBold",
							width: "70%",
							color: "#009be2",
							boxShadow: "none",
							alignSelf: "center",
							borderRadius: 8,
							height: 45,
							border: "0.1px solid lightgray",
							textTransform: "capitalize",
						}}
						variant="outlined"
					>
						Cancel
					</Button>
				</div>
			</div>
		)
	}
	render() {


		const initialValues = {
			name: this.state?.busName,
			regNum: this.state?.regNum,
			contact_num: this.state?.busNum,
			email_domain: this.state?.emailDomain,
			desc: this.state?.desc,
			focus: this.state?.focus,
			street: this.state?.street,
			town: this.state?.town,
			country: this.state?.country,
			pincode: this.state?.pincode,
			offPincode: this.state?.offPincode,
			logo: this.state?.busLogo ? this.state?.busLogo : icons.app_logo,
			logoPost: this.state?.busLogoPost,
			offStreet: this.state?.offStreet,
			offTown: this.state?.offTown,
			offCountry: this.state?.offCountry,
			employeeNum: this.state.employeeNum,
			offAddress: this.offField(this.state?.offStreet),

		}

		return (
			<div >
				{/* @ts-ignore */}
				<BusDashbaordWeb children={this.props.children} classes={this.props.classes} navigation={this.props.navigation} id={""} adminSelectedTab={this.props.adminSelectedTab} theme={undefined}
				>
					<div style={{ marginLeft: 280 }}>
						<Container>
							<Card style={{ borderRadius: 12, marginTop: 30 }}>
								<div style={{ height: 70, position: 'relative' }}>
									<div className={styles.headingDiv}>	<label className={styles.heading} style={{ paddingLeft: 20 }} >
										Business details
									</label>
									</div>
								</div>
								<CardContent
									style={{
										borderTop: "2px solid #eaeaea",
										padding: 15,
									}}
								>
									{this.formikField(initialValues)}
									{this.snackbarContent()}
									{this.dialogContent()}
								</CardContent>
							</Card>
						</Container>
					</div>
				</BusDashbaordWeb>
			</div>
		)
	}
}