Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.workEmailEndPoint = 'account_block/accounts/add_work_email';
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/accounts/";

exports.emailOtpVerifyApiEndPoint =
  "account_block/accounts/account_confirm";

// exports.emailOTPverifyAPiEndPoint =
//   "bx_block_phone_verification/verify_sms_otp";
  
  // exports.mobileVerifyAPiEndPoint =
  // "bx_block_forgot_password/otps";
  
  exports.mobileVarifyAPiEndPoint =
  "bx_block_forgot_password/otps";
  
  exports.mobileVerifyAPiEndPoint=
  "bx_block_phone_verification/verify_sms_otp";

exports.emailSendOtpApiEndPoint =
  "bx_block_forgot_password/otps/email_otp";

exports.mobileSendOtpApiEndPoint =
 "bx_block_phone_verification/send_sms_otp"


exports.availabilityApiEndPoint =
"bx_block_appointment_management/availabilities"

exports.termsAndCondnsApiEndPoint = 'account_block/accounts/terms_and_condition'
    
exports.apiMethodTypeAddDetail = "PATCH";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.userProfileEndPoint = "account_block/accounts/user_profile";
exports.createAvailabilityApiEndPoint ="bx_block_appointment_management/availabilities";

  exports.createPersonalInfoWebApiEndPoint =
  "account_block/accounts/user_profile";

  exports.validOtp = "assets/image_caution.png";
exports.onTermsandCondition="bx_block_privacy_settings/terms_and_conditions";
exports.myTaskPreference = "task_preferences";
exports.sendDataOfMyTaskPreference = "account_block/accounts/assign_task_preference";
exports.notificationApiEndPoint = "account_block/accounts/set_app_notification";
exports.dbsDetailsApiEndPoint = "dbs_types";
exports.emailNotificationEndPoint =
  "account_block/accounts/set_email_notification";
exports.dbsDetailsApiEndPoint = "dbs_types";
exports.sendDataOfMyTaskPreference = "account_block/accounts/assign_task_preference";
exports.emailNotificationEndPoint =
  "account_block/accounts/set_email_notification";
exports.sendDataOfMyTaskPreference = "account_block/accounts/assign_task_preference";
exports.emailNotificationEndPoint =
  "account_block/accounts/set_email_notification";
exports.myNonProfitsEndPoints = 'bx_block_organisations/organisations/';
exports.myNonProfitsAPIType = 'get';
// Customizable Area End
