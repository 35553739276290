import React, { useEffect } from "react";
// Customizable Area Start
import ContentManagementController, {
  MyFormValues,
  Props,
} from "./ContentManagementController";
import ContentManagement from "./ContentManagement";
import {
  Container,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Modal
} from "@material-ui/core";
// @ts-ignore
import classesOuter from './OrganisationUserNP.module.css'
import { icons } from "../../../components/src/constants";
import {
  Formik,
  FormikProps,
  Form,
} from 'formik';
import * as Yup from 'yup'
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import Check from '@material-ui/icons/Check';

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline-79": {
          border: "2px solid #009be2",
        }
      }
    }
  },
});

const npUserRoleOptions = [
  { label: "Coordinator", value: "Coordinator" },
  { label: "User", value: "User" },
];
const createNPRoleOptions = [
  { label: "Coordinator", value: "Coordinator",  disabled: true},
  { label: "User", value: "User" },
] 

const validationSchema = Yup.object({
  first_name: Yup.string().required('Please enter first name'),
  last_name: Yup.string().required('Please enter last name'),
  email: Yup.string().email("Email is invalid").required('Enter valid email'),
  role: Yup.string().required('Please select your role'),
})

// Customizable Area End

class OrganisationUserNP extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start    
    // Customizable Area End
    return (
      // Customizable Area Start
      // @ts-ignore
      <ContentManagement navigation={this.props.navigation} id={""} children={undefined} showBackButton={undefined} title={undefined} showBottomBar={false} showDrawerHeader={false} mobileOpen={false} open={false} classes={undefined} history={undefined} titleColor={undefined} showLine={undefined}>
        <Container maxWidth="lg">
          {/* @ts-ignore */}
          <Box data-test-id="organisation-user-container" className={classesOuter.organisationUserMainDiv}>
            {/* @ts-ignore */}
            <Box className={classesOuter.organisationUserHeading}>
              <Typography variant="h3" style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>Organisation user</Typography>
            </Box>
            <Divider />
            {/* @ts-ignore */}
            <Box className={classesOuter.profileMainDiv}>
              {/* @ts-ignore */}
              <Box className={classesOuter.profileInformDiv}>
                <Typography variant='h6' style={{ fontFamily: "Rene_Bieder_Milliard_Medium" }}>PROFILE INFORMATION</Typography>
                <Typography style={{ fontFamily: "Rene_Bieder_Milliard_Book" }}>This information is for read only.</Typography>
              </Box>
              {/* @ts-ignore */}
              <Box className={classesOuter.crossIconBox} onClick={this.handleCloseOrg}>
                <img src={icons.plusIcon} alt="multiplyIcon" />
                <Typography>Close</Typography>
              </Box>
            </Box>
            {this.state.updateStaffValueError && <Typography style={{ fontFamily: 'Rene_Bieder_Milliard_Medium', color: "#fd1d1d", marginLeft: 15, fontSize: "0.875rem" }}>{this.state.updateStaffValueError}</Typography>}
            <Formik
              initialValues={{
                first_name: this.state.npUserOrganisation && this.state.npUserOrganisation.isNewUser ? "": this.state.npUserOrganisation.first_name || '',
                last_name: this.state.npUserOrganisation && this.state.npUserOrganisation.isNewUser ? "": this.state.npUserOrganisation.last_name || '',
                email: this.state.npUserOrganisation && this.state.npUserOrganisation.isNewUser ? "": this.state.npUserOrganisation.email || '',
                role: this.state.npUserOrganisation.isNewUser ? "": npUserRoleOptions.find((option) => option.value === this.props?.selectedUserNp?.role),
                id: this.state.npUserOrganisation.isNewUser? "" : this.state.npUserOrganisation.userId
              }}
              // @ts-ignore
              onSubmit={this.onSubmitHandler}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {(props: FormikProps<MyFormValues>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  setFieldValue
                } = props
                return (
                  // @ts-ignore
                  <Form translate={undefined} onSubmit={handleSubmit}>
                    <OrganizationWebForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isSubmitting={isSubmitting}
                      setFieldValue={setFieldValue}
                      selectedUserNp={this.props?.selectedUserNp}
                      deleteUser={this.handleDeleteUser}
                      orgEmailApiError={this.state.orgEmailApiError}
                      npNewStaffEmailNotificationFlag={this.state.npNewStaffEmailNotificationFlag}
                      handleEmailNotificationModalClose={this.handleEmailNotificationModalClose}
                      handleEmailApiError={this.handleResetEmailApiError}
                      updateNpRoleToCoordinator={this.state.updateNpRoleToCoordinator}
                      isUpdateRole={this.isUpdateRole}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </Container>
      </ContentManagement>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const OrganizationWebForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  selectedUserNp,
  deleteUser,
  orgEmailApiError,
  npNewStaffEmailNotificationFlag,
  handleEmailNotificationModalClose,
  handleEmailApiError,
  updateNpRoleToCoordinator,
  isUpdateRole
}: any) => {
  const [npRole, setNpRole] = React.useState(false);
  
  const { role } = selectedUserNp
  const npStaffId = localStorage.getItem("np_staff_id");

  const handleDltUser = () => {
    deleteUser(npStaffId);
  }

  const disabled = role == "Entity_admin" ? true : false;
  const getExistNpStaffRole = localStorage.getItem("np_staff_role");
  const isRoleDisabled = getExistNpStaffRole === "Coordinator" ? true : false

  const emailNotification = () => {
    const {first_name, last_name} = values;
    const npNewStaffEmailNotificationMsg = `An invitation has been sent to ${first_name} ${last_name} to join your organisation on INITIATE`;
    const updateNpRoleToCoordinatorMsg = `The elevation is pending acceptance from ${first_name} ${last_name}`;
    return(
      <Modal
      open={npNewStaffEmailNotificationFlag}
      onClose={handleEmailNotificationModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classesOuter.modalWrapper}
    >
      <Box>
        <Box className={classesOuter.modalBox}>
        <div>
          <Check className={classesOuter.checkSubmit}/>
          <Typography variant='h6' style={{fontFamily:"Rene_Bieder_Milliard_Medium",padding: "0 50px"}}>
            {updateNpRoleToCoordinator?updateNpRoleToCoordinatorMsg:npNewStaffEmailNotificationMsg}
          </Typography>
        </div>
        <div style={{width:"50%"}}>
          <Button
            className={classesOuter.modalBtn}
            variant="contained"
            onClick={() => handleEmailNotificationModalClose()}
          >
            Back
          </Button>
        </div>
        </Box>
      </Box>
    </Modal>
    )
  }
  const firstNameContainer = () => {
    return (
      <Box className={classesOuter.labelAndInput}>
        <label>First Name</label><br />
        <TextField
          variant="outlined"
          className={classesOuter.textFieldDesigned}
          placeholder="First Name"
          name="first_name"
          id="first_name"
          data-test-id="txtInputFirstName"
          value={values?.first_name}
          type="text"
          // @ts-ignore
          helperText={
            touched?.first_name && Boolean(errors?.first_name) && String(errors?.first_name)
          }
          error={
            Boolean(errors?.first_name && touched?.first_name)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            classes: {
              root: classesOuter.innerTextField,
            },
          }}
        />
      </Box>
    )
  }
  const lastNameContainer = () => {
    return (
      <Box className={classesOuter.labelAndInput}>
        <label>Last Name</label><br />
        <TextField
          variant="outlined"
          className={classesOuter.textFieldDesigned}
          placeholder="Last Name"
          name="last_name"
          id="last_name"
          data-test-id="txtInputLastName"
          value={values?.last_name}
          type="text"
          // @ts-ignore
          helperText={
            touched?.last_name && Boolean(errors?.last_name) && String(errors?.last_name)
          }
          error={
            Boolean(errors?.last_name && touched?.last_name)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            classes: {
              root: classesOuter.innerTextField,
            },
          }}
        />
      </Box>
    )
  }
  const emailContainer = () => {
    return (
      <Box className={classesOuter.labelAndInput}>
        <label>Email address</label><br />
        <TextField placeholder="email@gmail.com"
          variant="outlined"
          className={classesOuter.textFieldDesigned}
          name="email"
          id="email"
          data-test-id="txtInputEmail"
          value={values?.email}
          type="text"
          // @ts-ignore
          helperText={
            touched?.email && Boolean(errors?.email) && String(errors?.email)
          }
          error={
            Boolean(errors?.email && touched?.email)
          }
          onChange={(e) => {
            handleChange(e);
            handleEmailApiError();
          }}
          onBlur={handleBlur}
          InputProps={{
            classes: {
              root: classesOuter.innerTextField,
            },
          }}
        />
        {orgEmailApiError && <Box
          style={{
            fontFamily: 'Rene_Bieder_Milliard_Medium',
            color: "#fd1d1d",
            fontSize: "0.875rem"
          }}>{orgEmailApiError}</Box>}
      </Box>
    )
  }
  const roleContainer = () => {
    return (
      <Box className={classesOuter.labelAndInput} style={{display:"block"}}>
        <label>Role</label><br />
        <div
          style={{
            border: `${npRole
              ? "1px solid  #3696C3"
              : "1px solid lightgrey"
              }`,
            width: "50%",
            borderRadius: 10,
            borderBottom: `${!npRole
              ? "1px solid lightgrey"
              : "1px solid  #3696C3"
              }`,
            height: `${!npRole ? "54px" : "160px"
              }`,
          }}>
          <Select
            className={classesOuter.roleOption}
            isDisabled={isRoleDisabled}
            options={(!values?.id) ? createNPRoleOptions : npUserRoleOptions }
            isOptionDisabled={(option) => option.disabled}
            isSearchable={false}
            isClearable={false}
            name="role"
            data-test-id="txtSelectRole"
            value={values?.role}
            onChange={item => {
              setFieldValue('role',item);
              getExistNpStaffRole && isUpdateRole(item)
            }}
            onBlur={handleBlur}
            // @ts-ignore
            helperText={
              touched?.role && Boolean(errors?.role) && String(errors?.role)
            }
            error={
              Boolean(errors?.role && touched?.role)
            }
            onMenuOpen={() => {
              setNpRole(true)
            }}
            onMenuClose={() => {
              setNpRole(false)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#009BE2",
                primary: "dodgerblue",
              },
            })}
            styles={{
              placeholder: (base) => {
                return {
                  ...base,
                  color: "grey",
                  fontFamily: "Rene_Bieder_Milliard_Book",
                  fontSize: 16
                };
              },
              menu: (base) => {
                return {
                  ...base,
                  paddingLeft: 20,
                  paddingRight: 20,
                  border: "none",
                  marginTop: 0,
                  borderTop: "none",
                  boxShadow: "none",
                  color: "lightgrey",
                  paddingTop: 5,
                  "&:hover": {
                    borderRadius: 12,
                  },
                };
              },
              dropdownIndicator: (base) => {
                return {
                  ...base,
                  color: "dodgerblue",
                };
              },
              control: (base, state) => {
                return {
                  ...base,
                  backgroundColor: "white",
                  boxShadow: "none",
                  height: "54px",
                  borderRadius: state.isFocused ? "10px " : "10px",
                  border: `${npRole
                    ? "1px solid  #009BE2"
                    : "1px solid lightgrey "
                    }`,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  "&:hover": {
                    borderBottom: `${npRole
                      ? "1px solid  #009BE2"
                      : "1px solid lightgrey"
                      }`,
                  },
                };
              },
              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: "white",
                color: state.isDisabled ? "#ccc" : "grey",
                "&:hover": {
                  backgroundColor: "#009BE2",
                  color: "white",
                },
              }),
              menuList: (base) => {
                return {
                  ...base,
                  marginTop: 10,
                  position: "relative",
                  top: "-5px",
                  zIndex:-1,
                  color: "grey",
                };
              },
            }}
          />
        </div>
      </Box>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      {/* @ts-ignore */}
      {firstNameContainer()}
      {/* @ts-ignore */}
      {lastNameContainer()}
      {/* @ts-ignore */}
      {emailContainer()}
      {/* @ts-ignore */}
      {roleContainer()}
      {errors?.role && touched?.role && <div className={classesOuter.roleSelect}>{errors.role}</div>}
      {/* @ts-ignore */}
      <Box className={classesOuter.buttonMainBox} style={{display:npRole?"none":"flex"}}>
        <Button className={classesOuter.saveBtn}
          type="submit"
          disabled={isSubmitting}
          data-test-id="savechanges"
        >Save changes</Button> 
        <Button data-test-id="deleteUser"
          disabled={disabled}
          style={{
            color: disabled ? "white" : "",
            opacity: disabled ? "0.5" : "1",
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          className={classesOuter.deleteBtn}
          onClick={handleDltUser}>Delete user</Button>
      </Box>
      {npNewStaffEmailNotificationFlag && emailNotification()}
    </ThemeProvider>
  )
}
// Customizable Area End
// Customizable Area Start
function mapStateToProps(state: any) {
  return {
    offerRoleFormData: state.offerARoleReducer,
    selectedUserNp: state.offerARoleReducer.offerRoleFormData,
  }
}

export default connect(mapStateToProps, null)(withRouter<any, any>(OrganisationUserNP));
export { OrganisationUserNP };
// Customizable Area End
