import React from "react";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDRa_Ig5h6xBZgNSFxRJ0-PuzanWdeg8-Q",
  authDomain: "initiate-final-build-397411.firebaseapp.com",
  projectId: "initiate-final-build-397411",
  storageBucket: "initiate-final-build-397411.appspot.com",
  messagingSenderId: "1092766733802",
  appId: "1:1092766733802:web:e76b61a0ead84285cc4e93",
  measurementId: "G-55TC5E9S2N"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const getFirebaseToken = async () => {
  try {
    const res = await getToken(messaging, {
      vapidKey:
        // "BB6mZeOIkFHr3Wt-wlZpCwjQCMgekriDit2mMoaRrn8Vm4pqK01UU54_gwiz6YA3nSGyj5SGsWlxDCvHC9gG_dY",
        "BLoj9OIiVg6__O7u2zGmwqMzfk2NkRXzhE3Ho57R2tBp_3mVvEtqhFMelK_5Zm9Zgbbgz0wM_sM4UTCkijOKaF0"
    });
    return res;
  } catch (e) {
    console.log(e);
    return "";
  }
};

const displayToast = (title, body) => {
  return (
    <div>
      <strong>{title}</strong>
      <div style={{ "paddingTop": "10px" }}>{body}</div>
    </div>
  )
};
export { firebaseApp, getFirebaseToken, onMessageListener, displayToast };
