import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { constants } from "../../../components/src/constants";
import { toast } from 'react-toastify'
import { apiCall } from "../../../components/src/utils/utils.web";
// Customizable Area End
const { Client } = require('@twilio/conversations');

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?: React.ReactNode | React.ReactNode[];
  classes: any;
  adminSelectedTab: any;
  theme?: any;
  showNp?: boolean,
  onBack?: any
  NpDetais?: any
  followStatus?: boolean
  followingStatus?: boolean
  history?: any;
  location?: any;
  match?: any;
  // Customizable Area End
}

interface FollowAlertsOptions {
  selectAll: boolean,
  publishedNeed: boolean,
  publishedActivity: boolean,
  publishedProjectFund: boolean,
  broadcastedMsgs: boolean
}



interface S {

  contactMsg: string;
  contactMessageError: string;
  contactValidation: boolean;
  openContactusModal: boolean;
  currentPage: number;
  openSuccessModal: boolean;
  isMobile: boolean;
  isSubmitting: boolean
  userToken: string | null;
  userEmail: string | null;
  loading: boolean;
  loadingmsg: boolean;
  txtInputValue: string;
  currentChat: any;
  txtSavedValue: string;
  enableField: boolean;
  chatsList: any;
  // Customizable Area Start
  draft: {
    first_name: string;
    current_password: string;
    email: string;
    change_password: string;
    confirm_password: string;
  };
  openDonateDialog: boolean;
  openStripePage: boolean;
  openSuccessDialog: boolean;
  amountValue: any;
  userEntered: number;
  pointsRange: number[];
  openPaymentDialog: boolean;
  donatedAmount: number;
  profileChangeErrors: {
    first_name: boolean;
    email: boolean;
    current_password: boolean;
    change_password: boolean;
    confirm_password: boolean;
  };
  openNoti: boolean | EventTarget;
  anchorEl: any;
  showModal: boolean;
  busName: any;
  showBusProfile: boolean;
  profileError: any;
  pwError: any;
  successMsg: any;
  busNum: any;
  regNum: any;
  emailDomain: any;
  desc: any;
  focus: any;
  street: any;
  town: any;
  country: any;
  offStreet: any;
  deleteConf: boolean;
  offTown: any;
  offCountry: any;
  pincode: any;
  offPincode: any;
  employeeNum: any;
  busLogo: any,
  busLogoPost: any;
  filterToggle: boolean;
  causesDropdown: boolean;
  open: any;
  busEvents: any;
  showNpTable: boolean
  daysRemaning: number;
  totalDays: number;
  showSubscribe: boolean;
  registeredEmployees:number,
  totalEmployees:number,
  expired:boolean,
  filterBusEventsData: any;
  loader: boolean;
  event_type: string;
  causeTypeOptions: any,
  helpTypeOptions: any,
  providerTypeOptions: any,
  whereTypeOptions: any,
  allTypeOptions: any,
  followingData: any,
  filteredFollowingData: any,
  allFilterEmptyOrgSelection: any,
  allCausesFilterOrgSelection: any,
  allHelpFilterOrgSelection: any
  allWhereFilterOrgSelection: any
  allProvideFilterOrgSelection: any
  searchedOrgData: any
  nonProfitProfileData: any,
  showNpProfile: boolean
  followStatus: boolean
  followAlertModal: boolean
  followOptionsModal: FollowAlertsOptions,
  chatModal: boolean,
  searchTerm: string;
  totalPages: number;
  paginationLoading: boolean;
  searchBar: boolean,
  clearChat: boolean,
  deleteModal: boolean,
  broadcastLengthCounter: number | string,
  broadcastMsgData: any,
  expanded: boolean | string,
  activePlanName: string,
  messageId: number | string | null;
  eventId: number | null | string;
  contactModal: boolean;
  latitude: number;
  longitude: number;
  location: string | null | undefined;
  proximity: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
interface BusProfValues {
  name: any;
  contact_num: any;
  email_domain: any;
  desc: any;
  focus: any;
  street: any;
  town: any;
  country: any;
  offStreet: any;
  offTown: any;
  offCountry: any;
  pincode: any;
  offPincode: any;
  employeeNum: number;
  busLogo: any,
  busLogoPost: any;
  offAddress: boolean;
}

export default class ServiceSpecificSettingsAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contactUsApiID: any;
  getProfileId: any;
  updatePWID: any;
  deleteAccountId: any;
  listingContainer: any;
  updateBusDetailsId: any = '';
  getFreeTrialApiId: string = "";
  getUpcomingEventsDataApiCallId: string = "";
  paginationChat: string = "";
  allTypesOptionsAPICallId: string = '';
  getFollowingOrgApiCallId: string = '';
  getSearchOrgApiCallId: string = '';
  getFollowNpApiCallId: string = '';
  getMessageApiCallId: string = '';
  getFollowStatusApiCallId: string = '';
  groupChatListBus: string = "";
  getUnfollowNpApiCallId: string = ''
  deleteFcmId: string = "";
  generateTokenMsg: string = "";
  chatClient: any;
  getNotifyListId: string = "";
  getFollowingNotifyApicallId: string = "";
  createBroadcastDataApiCallId: string = "";
  getBroadcastDataApiCallId: string = "";
  getPointsRange: string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.getChatToken = this.getChatToken.bind(this);
    this.handleMessageList = this.handleMessageList.bind(this);
    this.getNextChat = this.getNextChat.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.searchGroup = this.searchGroup.bind(this);
    this.clearChat = this.clearChat.bind(this);
    this.handleChannelUpdated = this.handleChannelUpdated.bind(this);
    this.getParticipantId = this.getParticipantId.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      draft: {
        first_name: '',
        current_password: "",
        email: "",
        change_password: "",
        confirm_password: "",
      },
      contactMsg: "",
      contactMessageError: "",
      contactValidation: false,
      userEmail: "",
      loading: true,
      loadingmsg: false,
      currentPage: 1,
      currentChat: {},
      userToken: "",
      openContactusModal: false,
      openSuccessModal: false,
      isSubmitting: false,
      txtInputValue: "",
      txtSavedValue: "A",
      totalPages: 0,
      causesDropdown: false,
      searchTerm: "",
      enableField: false,
      // Customizable Area Start
      openDonateDialog: false,
      openStripePage: false,
      openSuccessDialog: false,
      amountValue: null,
      donatedAmount: 0,
      openPaymentDialog: false,
      userEntered: 0,
      pointsRange:  [],
      openNoti: false,
      profileChangeErrors: {
        first_name: false,
        current_password: false,
        email: false,
        change_password: false,
        confirm_password: false,
      },
      anchorEl: null,
      showModal: false,
      filterToggle: false,
      profileError: null,
      pwError: null,
      isMobile: false,
      showBusProfile: false,
      successMsg: '',
      busName: '',
      regNum: null,
      busNum: '',
      deleteConf: false,
      emailDomain: '',
      desc: '',
      focus: '',
      street: '',
      town: '',
      country: '',
      offStreet: '',
      offTown: '',
      offCountry: '',
      pincode: '',
      offPincode: '',
      employeeNum: '',
      busLogo: null,
      busLogoPost: null,
      busEvents: [],
      open: false,
      showNpTable: false,
      daysRemaning: 0,
      totalDays: 0,
      showSubscribe: false,
      filterBusEventsData: [],
      loader: true,
      registeredEmployees:0,
      totalEmployees:0,
      expired:false,
      event_type: "",
      causeTypeOptions: [],
      helpTypeOptions: [],
      providerTypeOptions: [],
      whereTypeOptions: [],
      allTypeOptions: [],
      followingData: [],
      filteredFollowingData: [],
      allFilterEmptyOrgSelection: true,
      allCausesFilterOrgSelection: [],
      allHelpFilterOrgSelection: [],
      allWhereFilterOrgSelection: [],
      allProvideFilterOrgSelection: [],
      searchedOrgData: [],
      nonProfitProfileData: {},
      showNpProfile: false,
      followStatus: false,
      followAlertModal: false,
      followOptionsModal: {
        selectAll: false,
        publishedActivity: false,
        publishedNeed: false,
        publishedProjectFund: false,
        broadcastedMsgs: false
      },
      chatModal: false,
      searchBar: false,
      clearChat: false,
      deleteModal: false,
      broadcastLengthCounter: "",
      broadcastMsgData: [],
      expanded: false,
      activePlanName: "",
      chatsList: [],
      paginationLoading: false,
      messageId: null,
      eventId: null,
      contactModal: false,
      latitude: 0,
      longitude: 0,
      location: "",
      proximity: 1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  handleDashboardAPIs(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getUpcomingEventsDataApiCallId) {
      responseJson?.data ? this.setState({ busEvents: responseJson?.data, filterBusEventsData: responseJson?.data, loader: false, filterToggle: false }) : this.setState({ busEvents: [], filterBusEventsData: [], loader: false, filterToggle: false })
    }
    if (apiRequestCallId === this.allTypesOptionsAPICallId) {
      this.setState({ allTypeOptions: responseJson })
    }
    if (apiRequestCallId === this.getFollowingOrgApiCallId) {
      responseJson?.data ? this.setState({ followingData: responseJson?.data, filteredFollowingData: responseJson?.data, loader: false, }) : this.setState({ followingData: [], filteredFollowingData: [], loader: false, })
    }
    if (apiRequestCallId === this.getSearchOrgApiCallId) {
      this.setState({ searchedOrgData: responseJson?.data, allFilterEmptyOrgSelection: true, showModal: false, showNpTable: true, allCausesFilterOrgSelection: [], allHelpFilterOrgSelection: [], allProvideFilterOrgSelection: [], allWhereFilterOrgSelection: [] })
    }
    if (apiRequestCallId === this.getFollowNpApiCallId) {
      this.setState({ followStatus: !this.state.followStatus })
    }
    if (apiRequestCallId === this.getMessageApiCallId) {
      this.navigationPopup(responseJson.data)
    }
    if (apiRequestCallId === this.getUnfollowNpApiCallId) {
      this.setState({ followStatus: !this.state.followStatus })
      this.handleSuccessModalOpen()
    }

    if (apiRequestCallId === this.getFollowStatusApiCallId) {
      responseJson?.data ? this.setState({ followStatus: true, showNpProfile: true }) : this.setState({ followStatus: false, showNpProfile: true })
    }
    if (apiRequestCallId === this.getFollowingNotifyApicallId) {
      this.setState({ followAlertModal: false })
      this.handleSuccessModalOpen()
    }
  }
  handleCommunicationTabAPIs(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.createBroadcastDataApiCallId) {
      this.setState({ broadcastLengthCounter: "" })
      this.handleSuccessModalOpen()
      this.fetchBroadcastData()
    }
    if (apiRequestCallId === this.getBroadcastDataApiCallId) {
      this.setState({ broadcastMsgData: responseJson?.data })
    }
    if(apiRequestCallId === this.getPointsRange){
      this.setState({
        pointsRange: responseJson.data,
      });
    }
    if (apiRequestCallId === this.generateTokenMsg) {
      this.chatClient = new Client(responseJson?.token);
      this.chatClient.on('initialized', () => {});
      
      this.chatClient.on('initFailed', (error: string) => {});
      this.getChatList();
      
    }
    if (apiRequestCallId === this.groupChatListBus) {
      this.setState({
        loading: false,
        chatsList: [...this.state.chatsList, ...responseJson.data],
        currentPage: responseJson.meta.data.current_page,
        totalPages: responseJson.meta.data.total_pages,
      }, () => { this.handleRedirection(responseJson)});
      this.addEvents(responseJson.data);
    }

    if (apiRequestCallId === this.paginationChat) {
      this.addEvents(responseJson.data);
      this.setState({
          chatsList: [...this.state.chatsList, ...responseJson.data],
          totalPages: responseJson.meta.data.total_pages,
          paginationLoading: false,
      });
    }
  }
  handleApi(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.updatePWID) {
      if(responseJson.account){
        this.setState((prevState) => ({
          draft: { ...prevState.draft, current_password: "", change_password: "", confirm_password: "" },
        }));
        toast.success(responseJson.account);
      } else if(responseJson.error){
        toast.error(responseJson.error);
      }
    }
    if (apiRequestCallId === this.deleteAccountId) {
      this.props.navigation.navigate('LoginBusiness')
    }
    if (apiRequestCallId === this.updateBusDetailsId) {
      this.handleProfileUpdationResponse(responseJson)
    }
    if (apiRequestCallId === this.getProfileId) {
      this.setProFields(responseJson?.data?.attributes)
    }
  }

  handleAllAPIResponse(apiRequestCallId: any, responseJson: any) {
    if (this.contactUsApiID === apiRequestCallId) {
      this.handleContactusClose();
      this.setState({ contactMsg: "", isSubmitting: false })
      this.handleSuccessModalOpen()
    } else {
      this.setState({ isSubmitting: false })
    }
  }

  validateContactMessage(value: any) {
    if (/^\s*$/.test(value.trim())) {
      this.setState({ isSubmitting: false, contactMessageError: 'Please enter message', contactValidation: false })
    } else {
      this.setState({ contactMessageError: "", contactValidation: true })
    }
  }
  renderEventType(type:string){
    return type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
}
 renderUserType(type:string){
  return type === 'user_entity' ? 'Employee' : 'Non-Profit';
 }
  handleProfileUpdationResponse = (responseJson : any) => {
    if (!responseJson?.error) {
      toast.success('Profile updated successfully')
    } else if(responseJson?.error?.corporate_email_domain?.length){
      toast.error(`${responseJson?.error?.corporate_email_domain[0]}`);
    } else if(responseJson?.error?.logo?.length) {
      toast.error(`${responseJson?.error?.logo[0]}`);
    } else {
      toast.error("Something went wrong!");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message?.id) {
      const id = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      this.setState({ messageId: id });
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getFreeTrialApiId) {
        this.handleFreeTriallResponse(responseJson, errorReponse)
      }
      else if (responseJson !== undefined) {
        this.handleApi(apiRequestCallId, responseJson)
        this.handleDashboardAPIs(apiRequestCallId, responseJson)
        this.handleCommunicationTabAPIs(apiRequestCallId, responseJson)
      }

      if (responseJson.data && responseJson !== undefined) {
        this.handleAllAPIResponse(apiRequestCallId, responseJson);
      } else {
        this.setState({ isSubmitting: false })
        // console.log("API Error", errorReponse)
        this.setState({ profileError: errorReponse })
      }

    
    }


    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };


  addEvents = (chats: any) => {
    chats.forEach(async  (chat: any) => {
      const channelOb = await this.chatClient.getConversationBySid(chat.attributes.sid);
      if (channelOb._internalState.status != 'joined') {
          await channelOb.join();
      }
      channelOb.on("messageAdded", this.handleMessageList);
      channelOb.on('updated', this.handleChannelUpdated);
    });
  }
  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  onClose = () => {
    this.setState({ eventId: null });
  }

  // Popover handle event
  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  goToClick = (e: any) => {
    this.setState({ anchorEl: e.currentTargets });
  };

  handleNpTable = () => {
    this.setState({ showNpTable: true, showModal: false })
  }
  handleContactusOpen = (item: any) => {
    this.handlePopoverClose()
    this.setState({ openContactusModal: true });
  };

  handleContactusClose = () => {
    this.setState({ isSubmitting: false, openContactusModal: false, contactMsg: "", contactMessageError: "" });
  };

  handleSuccessModalOpen = () => {
    this.setState({ openSuccessModal: true });
  };

  handleSuccessModalClose = () => {
    this.setState({ 
      openSuccessModal: false,
      followOptionsModal: {
        selectAll: false,
        publishedActivity: false,
        publishedNeed: false,
        publishedProjectFund: false,
        broadcastedMsgs: false
      }
    });
  };

  handleSuccessRedircModalClose = () => {
    this.setState({ openSuccessModal: false });
    this.props.navigation.navigate('BusHomeDashboard')
  };

  handleNotiOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ openNoti: e?.currentTarget });
  }

  handleNotiClose = () => {
    this.setState({ openNoti: false })
  }




  // Contact us API
  contactUsSub = () => {
    this.validateContactMessage(this.state.contactMsg)
    this.setState({ isSubmitting: true })
    if (this.state.contactValidation) {
      const token = localStorage.getItem("EmailToken")
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const httpBody = {
        data: {
          description: this.state.contactMsg,
        },
        user_type: "business account",

        token: token
      };


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.contactUsApiID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } else {
      this.setState({ contactMsg: "" })
    }
  };
  handleSubscribeNow = () => {
    if (this.state.daysRemaning == 0) {
      this.setState({
        showSubscribe: false
      })
      localStorage.removeItem("showSubscribe")
    }
  }

  getFreeTrialData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("EmailToken"),
      "type": 'business_entity'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFreeTrialApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.freeTrialApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void  {
    if (this.state.allTypeOptions !== prevState.allTypeOptions) {
      this.setState({
        whereTypeOptions: this.state.allTypeOptions?.where?.map((it: any) => {
          return {
            label: it?.value,
            value: it?.value,
            name: 'where'
          }
        }),
        helpTypeOptions: this.state.allTypeOptions?.who_we_help?.map((it: any) => {
          return {
            label: it?.value,
            value: it?.value,
            name: 'who_we_help'
          }
        }),
        providerTypeOptions: this.state.allTypeOptions?.what_we_provide?.map((it: any) => {
          return {
            label: it?.value,
            value: it?.value,
            name: 'what_we_provide'
          }
        }),
        causeTypeOptions: this.state.allTypeOptions?.cause?.map((it: any) => {
          return {
            label: it?.value,
            value: it?.value,
            name: 'cause'
          }
        }),
      })
    }

    if (!this.listingContainer) {
			this.listingContainer = document.getElementById('chatList');
		} else {
			this.listingContainer.removeEventListener('scroll', this.handlePagination);
		}
		if (this.listingContainer) {
			this.listingContainer.addEventListener('scroll', this.handlePagination)
		}
  }

  async componentDidMount() {
    super.componentDidMount();
    if (localStorage.getItem('token') === null && localStorage.getItem('user_type') !== 'business_user') {
      localStorage.clear()
      this.props.navigation.navigate('LoginBusiness')
    }

    this.getProfile();



    const path = window.location.pathname;
    this.refactorAPICall(path)
    this.pointsRange();
    if(this.props.followingStatus || this.props.followStatus){
      this.setState({
        followStatus: !!(this.props.followingStatus || this.props.followStatus)
      });
    }
  }

  refactorAPICall(path: string) {
    if (path.includes('BusHomeDashboard')) {
      this.getFreeTrialData();
      this.getUpcomingEventsData();
      this.getAllTypeOptions();
    } else if (path.includes('BusProfile')) {
      this.getProfile();
    } else if (path.includes('BusFollowing')) {
      this.getFollowingOrgData();
    } else if (path.includes('BusEmployees')) {
      //  Reisterd Employees Tab APIs here
    } else if (path.includes('BusChat')) {
      //  Communication Tab APIs here
      const userEmail = localStorage.getItem('user_email');
      const userToken = localStorage.getItem("token");
      this.setState({
          userEmail,
          userToken,
      }, () => {
          this.getChatToken();
      });
      
      this.fetchBroadcastData()
    }
  }


  handleFreeTriallResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson?.data && responseJson?.data?.attributes) {
      localStorage.setItem('showSubscribe', 'false')
      const registeredEmployees = responseJson.data.attributes?.registered_employees;
      const totalEmployees = responseJson.data.attributes?.can_affiliate_upto;
      const days = responseJson.data.attributes?.remaining_days;
      const totalDays = responseJson.data.attributes?.subscription?.data?.attributes?.valid_for_days
      let daysRemaning = days
      if (days == "expired") {
        daysRemaning = 0
        this.setState({expired:true})
        localStorage.setItem("expired","true");
      }
      else {
        daysRemaning = days;
      }
      const planName = responseJson.data.attributes?.subscription?.data.attributes?.name
      const showSubscribe = Boolean(planName == "Trial-Business")
      this.setState({
        daysRemaning,
        totalDays,
        showSubscribe,
        registeredEmployees,
        totalEmployees,
        loader: false,
        activePlanName: planName
      });
      localStorage.setItem('showSubscribe', 'false')
      localStorage.setItem('activePlanName', this.state.activePlanName)
      localStorage.setItem("plan", this.handlePlanName(planName));
    } else {
      localStorage.removeItem('plan');
      localStorage.setItem('showSubscribe', 'true')
      localStorage.setItem('activePlanName', 'none')
    }
  }

  handlePlanName = (name: string) => {
    const plans = ["Trial-Business", "Presence-Only","Non paying - Presence only","Paying: 50 Employee Presence", "Supported Business", " Full Business"]

    return plans.filter((item: any) => name?.includes(item)).toString()
}

  getCurrentUrlAdmin() {
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    const lastWord = parts[parts.length - 1];
    if (["BusSubscriptionPlan", "BusChangeSubscriptionPlan", "BusSubscriptionPayment", "BusSubscriptionPayments"].includes(lastWord)) {
      return "BusHomeDashboard";
    } else if (["BusSurveys"].includes(lastWord)) {
      return "BusSurveysDashboard";
    }
    return lastWord;

  }


  handlePagination = (e: any) => {
		const scrollableHeight = this.listingContainer.scrollHeight - this.listingContainer.clientHeight

		if (
			this.listingContainer.scrollTop >= scrollableHeight &&
			!this.state.paginationLoading &&
			this.state.currentPage < this.state.totalPages
		) {
				this.setState({
						currentPage: this.state.currentPage + 1,
						paginationLoading: true
				}, () => {
						this.getNextChat();
				});
		}
	}

  getChatToken = async () => {
    const { userToken, userEmail} = this.state;

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        'token': userToken,};
    
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage) );
    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.generateChatToken}${userEmail}`);
      request.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType);

    this.generateTokenMsg = request.messageId;
    runEngine.sendMessage(request.id, request); }


  getChatList = () => {

    const { userToken, searchTerm } = this.state;
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        'token': userToken,
        'type': 'business_entity' };
   
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage) );
    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    this.groupChatListBus = request.messageId;

    let url = `${configJSON.chatList}all&page=${1}&per_page=20`;
    if (searchTerm.trim().length > 0) {
        url += `&search_term=${searchTerm}`; }
    request.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url );

    request.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType );

    runEngine.sendMessage(request.id, request);
	}


  searchGroup = (event: any) => {
    this.setState({ searchTerm: event.target.value });
  }

  handleChannelUpdated = async (data: { conversation: any, updateReasons: Array<string> }) => {
    const { conversation, updateReasons } = data;
    if (updateReasons.indexOf('attributes') == -1) return;
    const { chatsList } = this.state;
    let chats: any = [];

    this.setState({
      chatsList: chats
    });
  }
  handleMessageList = async (messageData: any) => {
    const { chatsList } = this.state;
    await messageData.conversation.updateLastReadMessageIndex(messageData.state.index);
    let chats: any = [];
  
    for (const chat of chatsList) {
      if (chat.attributes.sid == messageData.conversation.sid) {
        chats = [
          {...chat},
          ...chats,
        ]
      } else {
        chats.push(chat);
      }
    }
    this.setState({ chatsList: chats });}

  getParticipantId = async (channelOb: any, memberData: any) => {
    const participants = await channelOb.getParticipants();
    const oldIds: Array<string> = [];
    memberData.forEach((item: any) => {
        if (item.email == this.state.userEmail && 'user_entity' == item.type.toLowerCase()) {oldIds.push(item.participant_id);} });
    const participant = participants.filter((participant: any) => {
      return participant.state.identity == this.state.userEmail && oldIds.indexOf(participant.state.sid) == -1;});
    return participant[0].state.sid; }


clearChat = (channelSid: string, clearChats: Array<any>) => {
  let chats = [...this.state.chatsList];
  chats.forEach((chat: any, index: number) => {
      if (chat.attributes.sid == channelSid) {
        chats[index].attributes.clear_chat = clearChats;
      }
  });
  this.setState({
      chatsList: chats
  });
}

getNextChat = () => {    
  const { userToken, searchTerm, currentPage } = this.state;
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      'token': userToken,
      'type': 'business_entity'
  };
  
  const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  this.paginationChat = request.messageId;

  let url = `${configJSON.chats}connection&page=${currentPage}&per_page=20`;
  if (searchTerm.trim().length > 0) {
    url += `&search_term=${searchTerm}`;
  }
  request.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  request.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(request.id, request);
}


  getUrlNameBusDash = () => {
    const screenMappings: any = {
      ['BusHomeDashboard']: constants.business_dashboard_screens.Dashboard,
      ['BusProfile']: constants.business_dashboard_screens.BusinessProfile,
      ['BusFollowing']: constants.business_dashboard_screens.Following,
      ['BusEmployees']: constants.business_dashboard_screens.RegisteredEmployees,
      ['BusChat']: constants.business_dashboard_screens.Communication,
      ['BusSurveysDashboard']: constants.business_dashboard_screens.Surveys

    }

    const routeName = screenMappings[this.getCurrentUrlAdmin()];

    return routeName;
  }


  saveOrg(values: any): any {
    const header = {
      token: localStorage.getItem('token')
    }
    const busId = localStorage.getItem('busId');
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateBusDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    let updateForm = new FormData();
    updateForm.append('profile[registration_number]', values.regNum);
    updateForm.append('profile[office_country]', values.offCountry);
    updateForm.append('profile[name_of_business]', values.name);
    updateForm.append('profile[corporate_email_domain]', values.email_domain);
    updateForm.append('profile[our_social_focus]', values.focus ? values.focus : "");
    if(values.logoPost){
    updateForm.append('profile[logo]', values.logoPost);
    }
    updateForm.append('profile[description]', values.desc);
    updateForm.append('profile[street]', values.street);
    updateForm.append('profile[town]', values.town);
    updateForm.append('profile[country]', values.country);
    updateForm.append('profile[post_code]', values.pincode);
    updateForm.append('profile[office_street]', values.offAddress ? values.offStreet : '');
    updateForm.append('profile[office_town]', values.offAddress ? values.offTown : '');
    updateForm.append('profile[office_country]', values.offAddress ? values.offCountry : '');
    updateForm.append('profile[office_post_code]', values.offAddress ? values.offPincode : '');

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBusProfileEndPoint}/${busId}`
    )
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), updateForm);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  offField(offStreet: any) {
    return (
      offStreet ? true : false
    )
  }

  getProfile() {
    const busId = localStorage.getItem('busId');
    const getProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileId = getProfile.messageId;

    getProfile.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBusProfileEndPoint}/${busId}`);
    getProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getProfile.id, getProfile);
  }

  setProFields(data: any) {
    this.setState({
      regNum: data?.registration_number,
      desc: data?.description,
      busNum: data?.contact_number,
      busName: data?.name_of_business,
      emailDomain: data?.corporate_email_domain,
      focus: data?.our_social_focus,
      street: data?.street,
      town: data?.town,
      country: data?.country,
      pincode: data?.post_code,
      offStreet: data?.office_street,
      offTown: data?.office_town,
      offCountry: data?.office_country,
      employeeNum: data?.no_of_employees,
      offPincode: data?.office_post_code,
      busLogo: data?.logo?.url,
      draft: {
        ...this.state.draft,
        first_name: data?.full_name,
        email: data?.email,
      }
    })
  }

  deleteAccount() {
    const header = {
      token: localStorage.getItem('token')
    }
    const busId = localStorage.getItem('busId');
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBusProfileEndPoint}/${busId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseProfile = () => {
    this.setState({ showBusProfile: false })
  }

  handleProfileFieldChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      draft: { ...prevState.draft, [name]: value },
    }));
    this.validateProfile(name, value)
    if (e.target.value.length == 0) {

      this.setState((prevState) => ({
        draft: {
          ...prevState.draft, change_password: '', confirm_password: ''
        }
      })
      )
    }
  };

  validateProfile = (name: any, value: any) => {
    const { profileChangeErrors, draft } = this.state;

    const updateErrors = (newErrors: any) => {
      this.setState({
        profileChangeErrors: { ...profileChangeErrors, ...newErrors },
      });
    };

    const isValidName = (value: string) => {
      const nameRegex = /^[a-zA-Z]+(\s[a-zA-Z]*)*$/

        ;
      return nameRegex.test(value);
    };

    const isValidEmail = (value: string) => {
      const emailRegex =
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      return emailRegex.test(String(value).toLowerCase());
    };

    const isValidPassword = (value: string) => {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;
      return value === "" || passwordRegex.test(value);
    };

    const isConfirmedPassword = (value: string) => {
      const password = draft.change_password;
      const isPasswordEmpty = password.trim() === "";
      return value === password && !isPasswordEmpty;
    };

    switch (name) {
      case "first_name":
        updateErrors({ [name]: !isValidName(value) });
        break;

      case "email":
        updateErrors({ [name]: !isValidEmail(value) });
        break;

      case "current_password":
        updateErrors({ [name]: !isValidPassword(value) });
        break;

      case "change_password":
        updateErrors({
          [name]: !isValidPassword(value),
          confirm_password: value !== draft.confirm_password,
        });
        break;

      case "confirm_password":
        updateErrors({ [name]: !isConfirmedPassword(value) });
        break;

      default:
        updateErrors({ [name]: false });
        break;
    }
  };

  handleSubmitProfile = () => {
    this.getUpdatedProfile();
  }

  getUpdatedProfile = () => {
    const acc_token = localStorage.getItem("EmailToken");
    const { change_password, current_password, first_name } = this.state.draft

    const httpBody = {
      "data": {
        "full_name": first_name,
        "old_password": current_password,
        "new_password": change_password !== "" && change_password,
      },
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: acc_token,
    };



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePWID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_business_account/change_password/?token=${acc_token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Business Dashboard APIs

  getUpcomingEventsData = () => {
    const token = localStorage.getItem("token");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUpcomingEventsDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.upcomingEventsDataApiEndPoint}/?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllTypeOptions = () => {
    const getCauseOptions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allTypesOptionsAPICallId = getCauseOptions.messageId;

    getCauseOptions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orgOptionsTypeEndPoint
    );

    getCauseOptions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getCauseOptions.id, getCauseOptions);

  }

  getFollowingOrgData = () => {
    const token = localStorage.getItem("token");
    const type = 'business_account'

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowingOrgApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFollowingOrgApiEndpoint}/?token=${token}&type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchEventsData = (searchedVal: string) => {
    const filteredRows = this.state.busEvents.filter(
      (row: any) =>
        row.attributes.event_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.attributes.event_type
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase())
    );
    this.setState({
      filterBusEventsData: filteredRows,
    });
  }

  searchFollowingData = (searchedVal: string) => {
    const filteredRows = this.state.followingData.filter(
      (row: any) =>
        row.attributes.organisation_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.attributes.organisation_type
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase())
    );
    this.setState({
      filteredFollowingData: filteredRows,
    });
  }

  handleEventsFilter = (val: string) => {
    if (val !== "") {
      this.setState({ event_type: val })
    } else {
      this.setState({ event_type: val, filterToggle: false, proximity: 1, filterBusEventsData: this.state.busEvents, location: "", latitude: 0, longitude: 0 });
    }

  }

  onProximityChange= (proximity: number)=>{
    this.setState({ proximity });
  }

  onLocationChange = (latitude: number, longitude: number, location: string | null | undefined) => {
    this.setState({ latitude, longitude, location });
  }

  toRadians = (degrees: number) => {
    return degrees * Math.PI / 180;
  }

  calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371;
    const dLat = this.toRadians(lat2 - lat1);
    const dLon = this.toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance * 0.621371;
  }

  filterEvents = () => {
    const eventData = this.state.busEvents.map((event: { attributes: { latitude: number; longitude: number; }; }) => {
      const distance = this.calculateDistance(event.attributes.latitude, event.attributes.longitude, this.state.latitude, this.state.longitude);
      return { ...event, distance };
    });
    const filterBusEventsData = eventData.filter((event: { distance: number; attributes: { event_type: string; }; }) => {
      let isTrue = true;
      if(this.state.event_type){
        isTrue = event.attributes.event_type === this.state.event_type;
      }
      if (this.state.latitude || this.state.longitude) {
        isTrue = isTrue && event.distance <= this.state.proximity;
      }
      return isTrue;
    });
    this.setState({
      filterToggle: false,
      filterBusEventsData
    });
  }

  appliedFilter = (selectlocation: string) => {
    const token = localStorage.getItem("token");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUpcomingEventsDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.upcomingEventsDataApiEndPoint}/?token=${token}&event_type=${this.state.event_type}&location=${selectlocation}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCauseSearchOrgFilter = (arrayVal: any) => {
    this.setState({ allCausesFilterOrgSelection: arrayVal.toString(), allFilterEmptyOrgSelection: false })
  }

  handleHelpSearchOrgFilter = (arrayVal: any) => {
    this.setState({ allHelpFilterOrgSelection: arrayVal.toString(), allFilterEmptyOrgSelection: false })
  }

  handleProvideSearchOrgFilter = (arrayVal: any) => {
    this.setState({ allProvideFilterOrgSelection: arrayVal.toString(), allFilterEmptyOrgSelection: false })
  }

  handleWhereSearchOrgFilter = (arrayVal: any) => {
    this.setState({ allWhereFilterOrgSelection: arrayVal.toString(), allFilterEmptyOrgSelection: false })
  }

  appliedOrgWiseSearchFilter = () => {
    if (this.state.allFilterEmptyOrgSelection) {
      this.setState({ showModal: false })
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchOrgApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchOrgFilterApiEndpoint}?cause=${this.state.allCausesFilterOrgSelection}&who_we_help=${this.state.allHelpFilterOrgSelection}&where=${this.state.allWhereFilterOrgSelection}&what_we_provide=${this.state.allProvideFilterOrgSelection}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  followNpProfile = (id: any) => {
    const token = localStorage.getItem("token");
    const type = 'business_account'

    const header = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      "organisation_id": id,
      "type": type,
      "token": token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowNpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFollowNpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  MessageNpProfile = (id: number) => {
    const token = localStorage.getItem("token");
    const type = 'business_entity'

    const header = {
      "Content-Type": "application/json",
      "type": type,
      "token": token
    };

    const httpBody = {
      "organisation_id": id,
      
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMessageNpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRedirection = (responseJson: {data:[]}) => {
    if (this.state.messageId) {
      const obj = responseJson.data.filter((item: {attributes:{sid:string}}) => {
        return item.attributes.sid === this.state.messageId;
      });
      this.handleOpen(obj[0]);
    }
  }

  navigationPopup = (item: [data:{attributes:{sid: string}}]) => {
    if (item && item.length > 0 && item[0].attributes) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'BusChat');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      
      const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item[0].attributes.sid);
  
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg);
    } else {
      console.error('Invalid item or missing attributes:', item);
    }
  }

  NpchatNavigate() {
    
      this.setState({ loadingmsg: true });
  
       this.MessageNpProfile(this.props.NpDetais?.attributes?.id);
       console.log('MessageNpProfile completed');
  
     
  }
  
  delFcmToken(): any {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("token"),
      "type": "business_entity"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFcmId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/device_ids/${localStorage.getItem("device_id")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  }
  unfollowNpProfile = (id: any) => {
    const token = localStorage.getItem("token");
    const type = 'business_account'

    const header = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      "organisation_id": id,
      "type": type,
      "token": token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUnfollowNpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUnfollowNPApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  followNotifications = (id: any) => {

    this.followNpProfile(id);

    const httpBody = {
      "data": {
        "attributes": {
          "published_need": this.state.followOptionsModal.publishedNeed,
          "broadcasted_message": this.state.followOptionsModal.broadcastedMsgs,
          "published_project_funding": this.state.followOptionsModal.publishedProjectFund,
          "published_upcoming_activity": this.state.followOptionsModal.publishedActivity
        }
      }
    };

    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token"),
      "type": "business_entity"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowingNotifyApicallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followNotificationSettings
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkFollowStatus = (item: any) => {
    this.setState({ nonProfitProfileData: item })

    const token = localStorage.getItem("token");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFollowStausApiEndPoint}?organisation_id=${item?.id}&type=business_account&token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllOptions = (e: any) => {
    if (e.target.name === 'selectAll' && e.target.checked === true) {
      this.setState({
        followOptionsModal: {
          selectAll: true,
          publishedActivity: true,
          publishedNeed: true,
          publishedProjectFund: true,
          broadcastedMsgs: true
        }
      })
    } else if (e.target.name === 'selectAll' && e.target.checked === false) {
      this.setState({
        followOptionsModal: {
          selectAll: false,
          publishedActivity: false,
          publishedNeed: false,
          publishedProjectFund: false,
          broadcastedMsgs: false
        }
      })
    } else {
      this.setState({ followOptionsModal: { ...this.state.followOptionsModal, [e.target.name]: e.target.checked } })
    }
  }



  resize() {
    this.setState({
      isMobile: window.innerWidth <= 760 ? true : false,
    });
    if (window.innerWidth >= 760) {
      this.setState({ isMobile: true });
    }
  }


  componentWillUnmount(): any {
    this.state.chatsList.forEach(async (chat: any) => {
      const channelOb = await this.chatClient.getConversationBySid(chat.attributes.sid);
      if (channelOb._internalState.status != 'joined') {
        await channelOb.join();}
        channelOb.removeListener("messageAdded", this.handleMessageList);
      channelOb.removeListener('updated', this.handleChannelUpdated);});
    window.removeEventListener("resize", this.resize.bind(this));}


  handleOpen = (item: any) => {
    const plan = localStorage.getItem("plan");
    if(plan === "Presence-Only"){
      return;
    }
		 else {
			this.setState({
				chatModal: true,
				currentChat: item
			})
		}
  };

  handleClearChat = () => {
    this.setState({ clearChat: !this.state.clearChat })
  }

  isDisabled = () => {
    const activePlanName = localStorage.getItem("activePlanName")
    if(activePlanName === 'none'|| activePlanName==="Plan 3 - Non paying - Presence only"||localStorage.getItem("expired")==='true') {
       return true
    } else {
      return false
    }
  }

  navigateToPlanChangePage = (text: string) => {

    this.props.history.push({
      pathname: "/BusSubscriptionPlan",
      state: {
        pageTitle: text,
      }
    });
  }

  createBroadcast = () => {
    const business_token = localStorage.getItem("token");
    const header = {
      'Content-Type': 'application/json',
      token: business_token,
      type: 'business_entity'
    };
    const httpBody = {
      broadcast: {
        message: this.state.broadcastLengthCounter,
        visibility: "both"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createBroadcastDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBroadcastsMsgApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchBroadcastData = () => {
    const business_token = localStorage.getItem("token");
    const header = {
      token: business_token,
      type: 'business_entity'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBroadcastDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBroadcastMsgApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleAccordianClick = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false })
  };
  onChangeState = () => {
    this.setState({ openSuccessDialog: true, userEntered: 0, amountValue: null});
  };

  handleCloseSuceessModal = () => {
    this.setState({ openStripePage: false, userEntered: 0, amountValue: null, openSuccessDialog: false});
  }
  handleDonate = async () => {
    const { pointsRange, amountValue, userEntered } = this.state;
     if(this.props.NpDetais?.attributes?.status !== "Enabled"){
       toast.warn("Currently not accepting donations!")
     } else if(pointsRange[amountValue] > 0 || userEntered ) {
      this.setState({ openStripePage: true, donatedAmount:  userEntered || pointsRange[amountValue], openDonateDialog: false })
    } else {
      toast.error("Please select donation amount")
    }
  }

  pointsRange = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getPointsRange = await apiCall({
      method: "GET",
      endPoint: `/bx_block_stripe_integration/donate_amounts`,
      header: header
    });
  }
  handleAmountTabChange = (event: any, newValue: any) => {
    this.setState({ amountValue: newValue, userEntered: 0})
  }
  handleDonationAmount = (event:any) => {
    this.setState({ userEntered: event.target.value, amountValue: null}) 
  }

  handleBackClick = () => {
    if(this.props.NpDetais !== null) {
      this.setState({ openStripePage: false, userEntered: 0, amountValue: null})
    }
  }
  // Customizable Area End
}
