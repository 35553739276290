import React  from 'react';
import MaterialButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { HideContainer } from '../shared/hide-container';

const useSpinnerStyles = makeStyles({
  root: { margin: '0 5px' },
});

const useStyles = makeStyles({
  bold: {
    fontWeight: 600,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  }
})

const Button = withStyles({
  root: {
    borderRadius: '10px',
    textTransform: 'none',
    '&.MuiButton-text': {
      padding: '4px',
    },
    '&.MuiButton-contained': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
})(MaterialButton);

type Props = React.ComponentProps<typeof Button> & {
  bold?: boolean,
  noWrap?: boolean,
  loading?: boolean,
}

export function InitiateButton(props: Props) {

  const { bold, noWrap, loading, ...buttonProps } = props;

  const classes = useStyles();
  const spinnerClasses = useSpinnerStyles();

  return (
    <Button
      {...buttonProps}
      children={<>
        {buttonProps?.children}
        <HideContainer hideWhen={!loading}>
          <CircularProgress
            size={20}
            className={spinnerClasses.root}
            color='inherit' />
        </HideContainer>
      </>}
      className={clsx(props.className, {
        [classes.bold]: bold,
        [classes.noWrap]: noWrap,
      })}
    />
  );
}
