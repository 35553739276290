import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { apiCall } from "../../../components/src/utils/utils.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Questions {
  id: string;
  type: string;
  attributes: QuestionsAttributes;
}
interface RangeInt {
  id: string;
  type: string;
  attributes: RangeAttributes;
}
export interface QuestionsAttributes {
  question: SingleQuestionAttributes;
  answer: string | string[];
}

export interface SingleQuestionAttributes {
  id: number;
  survey_id: number;
  title: string;
  q_type: string;
  single_answer: boolean;
  multi_answer: boolean;
  choose_limit: number;
  created_at: string;
  updated_at: string;
  options: RangeAttributes | OptionsInt[] ;
}

export interface RangeAttributes {
  id: number;
  start_range: number;
  end_range: number;
  start_range_text: string;
  end_range_text: string;
  created_at: string;
  updated_at: string;
}

export interface OptionsInt {
  id: number;
  name: string;
  question_id: number;
  created_at: string;
  updated_at: string;
  start_range?: number;
  end_range?: number;
  start_range_text?: string;
  end_range_text?: string;
}
export interface SurveyDataInterface {
  total_participants: string;
}

interface ImageInt {
  url: string;
}
interface DateRange {
  start_date: string;
  end_date: string;
}

export interface ParticipantDetails {
  name: string;
  image: ImageInt;
  date_range: DateRange;
  date_of_completion: string;
  survey_answer_details: AnswersDetail;
}
interface AnswersDetail {
  data: Questions[]
}
export interface ColumnInt {
  id: string;
  label: string;
  minWidth: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  openResultPage: boolean;
  userImage: string;
  userName: string;
  completionDate: string;
  surveyData: SurveyDataInterface;
  survey_details: any;
  participant_details: ParticipantDetails[];
  questions: Questions[];
  userType: string | null;
  userToken: string | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class SurveyUserAnswersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginAPICallId: string = "";
  getSurveyResultsAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      openResultPage: false,
      userImage: "",
      userName: "",
      completionDate: "",
      survey_details: {},
      participant_details: [],
      surveyData: { total_participants: "" },
      questions: [],
      userType: null,
      userToken: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const id = this.props.navigation.getParam("id");
    let user = localStorage.getItem("user_type")
    this.setState({ userType: this.getUserType(user), userToken: this.getUserToken(user) },() => {
      this.getSurveyResults(id);
    });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responsesJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId && responsesJson) {
        if (responsesJson.errors?.length > 0) {
          toast.error(`${responsesJson.errors[0].token}`);
        } else if (apiRequestCallId === this.getSurveyResultsAPICallId) {
          this.setState({
            loading: false,
            survey_details: responsesJson.data.survey_details.data,
            participant_details: responsesJson.data.participant_details,
            surveyData: responsesJson.data,
            questions: responsesJson.data.survey_details.data.attributes.questions.data,
          });
          if (this.state.userType === "user_entity" || this.state.userType === "business_entity") {
            this.setState({
              openResultPage: true,
              userImage: responsesJson.data.participant_details[0].image?.url,
              userName: responsesJson.data.participant_details[0].name,
              completionDate: moment(
                responsesJson.data.participant_details[0].date_of_completion
              ).format("DD/MM/YYYY"),
              questions: responsesJson.data.participant_details[0].survey_answer_details.data,
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getUserType = (type: string | null) => {
    if (type === "user entity") {
      return "user_entity";
    } else if (type === "Non profit entity") {
      return "non_profit_entity";
    } else {
      return "business_entity";
    }
  };

  getUserToken = (type: string | null) => {
    let userToken: string | null = "";
    if (type !== "Non profit entity") {
      userToken = localStorage.getItem("token");
    } else {
      userToken = localStorage.getItem("nonprofitToken");
    }
    return userToken;
  };

  handleBackRedirection = () => {
    const { userType } = this.state;
    if (userType === "user_entity") {
      this.props.navigation.navigate("SurveysDashboard");
    } else if (userType === "non_profit_entity" && !this.state.openResultPage) {
      this.props.navigation.navigate("NpSurveys");
    } else if (userType === "business_entity"){
      this.props.navigation.navigate("BusSurveysDashboard");
    } else {
      this.setState({ openResultPage: false });
    }
  };
  
  handleResultBtn = (row: ParticipantDetails) => {
    this.setState({
      openResultPage: true,
      userImage: row.image?.url,
      userName: row.name,
      completionDate: moment(row.date_of_completion).format("DD/MM/YYYY"),
      questions: row.survey_answer_details.data,
    });
  };

  getSurveyResults = async (id: string) => {
    const header = {
      type: this.state.userType,
      token: this.state.userToken,
    };
    this.getSurveyResultsAPICallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_surveys/surveys/${id}/get_survey_results`,
      header: header,
    });
  };
  // Customizable Area End
}