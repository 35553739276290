import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  createStyles,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import { Elements } from '@stripe/react-stripe-js';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GamificationController, {
  Props,
} from "./GamificationController";
import {
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Grid,
  Typography,
  InputBase,
  Container,
  Button,
  IconButton,
  Paper,
  Divider,
  Toolbar,
  CircularProgress,
  // Customizable Area Start
  Dialog,
  Tabs, 
  Tab,
  TableContainer,
  Radio,
  FormControlLabel,
  FilledInput,
  styled,
  RadioGroup,
  InputAdornment,
  Box
  // Customizable Area End
} from "@material-ui/core";
// @ts-ignore
import styles from "./Gamification.module.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { constants, icons, FONTS } from "../../../components/src/constants";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import ContentManagement from "../../ContentManagement/src/ContentManagement.web";
// @ts-ignore
import userEvent from "../../events/src/userEvent.module.css";
import CheckoutForm from "../../../components/src/custom/CheckOutForm.web";
import { loadStripe } from '@stripe/stripe-js';
import { isMobile } from "react-device-detect";
const configJSON = require("../../ServiceSpecificSettingsAdmin/src/config.js");
const baseURL = require("../../../framework/src/config.js").baseURL;
let stripePubKey = configJSON.STRIPE_KEY_LIVE;
if (!baseURL.includes(".prod")) {
  stripePubKey = configJSON.STRIPE_KEY_TEST;
}
const stripePromise = loadStripe(stripePubKey);

// Customizable Area Start
const TableCell: any = withStyles({
  root: {
    borderBottom: "none !important",
  },
})(MuiTableCell);

const TableHead: any = withStyles({
  root: {
    borderBottom: "3px solid #cbcbcb",
  },
})(MuiTableHead);

export const style = (theme: Theme) =>
  createStyles({
    tableroot: {
      width: "100%"
    },
  });
// Customizable Area End


class Gamification extends GamificationController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headerWeb() {
    return (
      <Toolbar className={styles.toolBar}>
        <div className={styles.backIconWeb}>
          <IconButton
            color={"inherit"}
            data-test-id="backIcon"
            onClick={() => this.handleBackIconClick()}
          >
            <img src={icons.back} alt="back" />
          </IconButton>
          <Typography className={styles.backTitle} variant="h6" noWrap>
            {this.state.orgClicked
              ? `Donate points to '${this.state.currentOrgName}'`
              : "Rewarded Points"}
          </Typography>
        </div>
      </Toolbar>
    );
  }

  displayOrgList = () => {
    if (this.state.orgLoader) {
      return <div className={styles.flexCenter} style={{ height: "200px" }}>
        <CircularProgress className={styles.loaderColor} />
      </div>
    } else if (this.state.organisationFilteredData.length > 0) {
      return <>
        {this.state.organisationFilteredData.map((orgItem: any) => {
          return (
            <div
              key={orgItem.id}
              className={styles.orgDetails}
              data-test-id="orgdetails"
              onClick={() => {
                this.setState({
                  orgClicked: true,
                  openOrgModal: false,
                });
                this.getNonProfitProfile(orgItem.id);
                this.pointsRange();
              }}
            >
              <img
                src={orgItem.logo.url ?? icons.searchSVG}
                alt="logo"
                className={styles.organisationLogo}
              />
              <div>
                <div className={styles.organisationName}>
                  {orgItem.organisation_name}
                </div>
                <div className={styles.organisationDescription}>
                  {orgItem.description.length > 27
                    ? orgItem.description.substring(0, 27) +
                    "..."
                    : orgItem.description}
                </div>
              </div>
            </div>
          );
        })}
      </>
    } else {
      return <div className={styles.noRecord}>No data available!</div>
    }
  }

  openDialog = () => {
    return (
      <Dialog
        className={`${styles.dialogWrap} orgDialogWrapper`}
        open={this.state.openOrgModal}
        data-test-id="modal-close"
        onClose={() => this.setState({ openOrgModal: false })}
      >
        <div className={`${styles.dialogWrap} ${styles.donateOrg}`}>
          <div className={userEvent.spaceBetweenDivs}>
            <Typography className={`${styles.userName} ${styles.modalDialog}`}>
              Donate points
            </Typography>
            <img
              src={icons.cancelIcon}
              alt="cancel"
              className={styles.cancelIcon}
              data-test-id="cancelIcon"
              onClick={() => this.setState({ openOrgModal: false })}
            />
          </div>
          {this.getSearchbar("org", "Search Non-Profit Organizations...")}
          <div>
            <Typography className={`${styles.userName} ${styles.nonProfit}`}>
              Non-Profit Organizations
            </Typography>
            {this.displayOrgList()}
          </div>
        </div>
      </Dialog>
    );
  };

  openDonateDialog = () => {
    return (
      <Dialog
        className={styles.dialogWrap}
        open={this.state.openDonateDialog}
        data-test-id="modal-close"
        onClose={() => this.setState({ openDonateDialog: false })}
      >
        <div className={styles.dialogWrapper}>
          <div className={`${styles.dialogWrapperInner}`}>
            <img src={icons.successIcon} alt="success" />
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography className={`${styles.userName} ${styles.message}`}>
              Thank you for your donation!
            </Typography>
            <div className={styles.donatedPoints}>
                You have successfully donated <span> 
                  {this.state.dynamicText === "amount" ? 
                  `£${this.state.donatedAmount}` :
                  `${this.state.donatedPoints} points`
                } </span> to '{this.state.currentOrgName}'
            </div>
            <Button
              data-test-id="backtoProfile"
              className={styles.goToProfile}
              onClick={this.onGoBackClick}
            >
              GO BACK TO {this.state.id ? "ORGANIZATION PROFILE" : "MY PROFILE"}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  fetchMedal = () => {
    if (this.state.medal === "gold") {
      return <img src={icons.gamificationIcon} alt="gold"/> ;
    } else if (
      this.state.medal === "silver"
    ) {
      return <img src={icons.silverMedal} alt="silver"/>;
    } else {
      return <img src={icons.bronzeMedal} alt="bronze"/>;
    }
  };

  topRewardSection = () => {
    let user = localStorage.getItem("user_type");

    return (
      <div>
        <div className={styles.rewardtitle}>Your total points rewarded</div>
        <div className={styles.topRewardSection}>
          <div className={styles.imgContainer}>{this.fetchMedal()}</div>
          <div className={styles.pointsContainer}>
            <div className={styles.pointsEarned}>{this.state.currentPoints}</div>
            <div className={styles.pointsText}>Current Point Balance</div>
          </div>
          <div className={styles.pointsContainer}>
            <div className={styles.points}>{this.state.pointsEarnedLifetime}</div>
            <div className={styles.pointsText}>Points earned lifetime</div>
          </div>
          <div className={styles.pointsContainer}>
            <div className={styles.points}>{user === "user entity" ? this.state.currentDonatablePointsFromBalance : `£ ${this.state.reedemableAmount}`}</div>
            {user === "user entity" ?
              <div className={styles.pointsText}>Current donatable points from balance</div>
              : <div className={styles.pointsText}>Total reedemable amount with current balance</div>}
          </div>
          {user === "user entity" &&  
            <div className={`${styles.flexCenter} ${styles.donateBtnContainer}`}>
              <Button
                className={styles.donateBtn}
                data-test-id="donateBtn"
                onClick={() => {this.setState({ openOrgModal: true }); this.getFollowedOrg(); }}
              >
                <img className={styles.btnImg} alt="donate" src={icons.handsIcon} />
                Donate points
              </Button>
            </div>
            }
        </div>
      </div>
    );
  };

  getSearchbar(list: string, placeholder:string) {
    return (
      <div className={styles.search}>
        <div className={styles.searchIcon}>
          <img className={styles.searchIconImg} alt="search" src={icons.searchSVG} />
        </div>
        <InputBase
          placeholder={placeholder}
          className={styles.searchboxStyle}
          data-test-id="searchFeild"
          onChange={(event) => this.handleSearchClick(event.target.value, list)}
          classes={{ input: styles.inputInput }}
        />
      </div>
    );
  }

  tableHeader() {
    return (
      <div className={styles.pointsTable}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={3}>
            <Typography className={styles.rewardtitle}>
              Points history
            </Typography>
          </Grid>
          <Grid item md={7}>
            {this.getSearchbar("table", "Search...")}
          </Grid>
        </Grid>
      </div>
    );
  }

  tableBodyData() {
    let user = localStorage.getItem("user_type");

    return (
      <div className="manage-client-table">
        <TableContainer className={styles.tablecontainer}>
        <Table stickyHeader className="stickyHeaderTable">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                DESCRIPTION
              </TableCell>
              {user === "user entity" &&  
              <TableCell>ACTIVITY TYPE</TableCell>}
              <TableCell align="center">
                POINTS
              </TableCell>
              <TableCell align="center">
                DATE AND TIME
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.tableData.length > 0 ? (
              <>
                {this.state.tableData.map((item: any) => (
                  <TableRow key={item.id}>
                    <TableCell align="left">{item.description} </TableCell>
                    {user === "user entity" &&  <TableCell>{item.activity_type}</TableCell>}
                    <TableCell
                      align="center"
                      style={{color: item.type === "earn" ? "#84db67" : "#d97c89"}}
                    >
                      {item.points}
                    </TableCell>
                    <TableCell align="center">
                      {item.date_time}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  className={styles.noRecord}
                >
                  No record found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        </TableContainer>
      </div>
    );
  }


  getSingleOrgInfo = () => {
    const { NpDetais, pointsRange, currentPoints, donateValue } = this.state;
    let user = localStorage.getItem("user_type");
    return (
      <>
      <Paper className={styles.tableroot} elevation={0}>
      <div className={styles.mainOrgWrapper}>
        <div className={styles.orgProfile}>
          <div className={styles.orgLogo}>
            <img
              alt="pr"
              src={NpDetais.attributes?.logo ? NpDetais.attributes?.logo?.url : icons.profile}
              className={styles.orgImg}
            />
          </div>
          <div className={styles.mainPrgSection}>
            <div className={styles.orgInfo}>
              <div>
                <p className={styles.orgProfileTetx}>{NpDetais?.attributes?.organisation_name}</p>
                <div className={styles.flexAlignCenter}>
                  <p className={styles.location}>{NpDetais.attributes?.town ? NpDetais.attributes?.town : "-"}
                    ,{NpDetais.attributes?.country ? NpDetais.attributes?.country : "-"}
                  </p>
                  <img
                    alt="location"
                    className={styles.locationImg}
                    src={icons.locationred}
                  />
                </div>
              </div>
            </div>
            <div className={styles.aboutDiv}>
              <p className={styles.aboutText}>About</p>
              <p className={styles.description}>
                {NpDetais.attributes?.description}
              </p>
            </div>
          </div>
        </div>
        </div>
        </Paper>
       {document.location.pathname === "/Gamification" ? 
       <>
       <Paper className={styles.tableroot} elevation={0}>
       <div className={styles.currentPoints}>
         You have <span>{currentPoints}</span> <span className={styles.grayColor}>Points.</span>
       </div>
       <div className={styles.mainBtnDiv}>
         <Tabs
           value={donateValue}
           onChange={this.handleTabChange}
           data-test-id="tabs"
           className="donateTabs"
           TabIndicatorProps={{
             style: {
               display: "none",
             },
           }}
         >
           {pointsRange.map((point:number, index:number) => {
             return  <Tab
             key={point}
             label={`${point} Points`}
             className={`${donateValue === index && styles.activeBtn} ${point > currentPoints ? styles.disabledBtn : styles.normalBtn }`}
             disabled={(point > currentPoints)}
           />
           })}
         </Tabs>
       </div>
       <div className={`${styles.currentPoints} ${styles.grayColor}`}>
         Donate with your earned points
       </div>
      </Paper>
      <div className={styles.donateBtnWrap}>
        <Box mb={{ xs: user === "user entity" ? "32px": "0px", sm:"0px" }}>
        <Button
          className={pointsRange[0] > currentPoints ?  styles.disabledDonateBtn : styles.donateNowBtn }
          data-test-id="donateNowBtn"
          onClick={() => {
            this.setState({ openDonateDialog: true });
            this.donatePointsToOrg(NpDetais.id);
          }}
          disabled={pointsRange[0] > currentPoints}
        >
          Donate Now
        </Button>
        </Box>
      </div>
      </>
      : this.renderfundDetails()} 
      </>
    );
  };
  renderPaymentDialog = () => {
    const { id, amountValue, userEntered, pointsRange } = this.state;
    return (
      <Dialog
        open={this.state.openPaymentDialog}
        className="dialogWrapDonation"
        onClose={() => this.setState({ openPaymentDialog: false })}
        data-test-id="modal-close2"
        maxWidth="md">
        <PaymentDialog>
          <div className="header">
            <div className="titleModal">Make donation</div>
            <HighlightOffIcon 
              data-test-id="closeBtn" 
              onClick={() => {this.setState({openPaymentDialog: false })}} 
            />
          </div>
          <div className="content">
          <Elements stripe={stripePromise}>
            <CheckoutForm
              redirect={() => this.props.navigation.navigate("Gamification", { id: id })}
              type="r1"
              donationAmount={amountValue !== null ? pointsRange[amountValue] : userEntered}
              eventId={id}
              onChangeState={this.onChangeState}
              paymentableType="BxBlockOrganisations::Organisation"
              destination_account={this.state.NpDetais?.attributes?.destination_account}
            />
          </Elements>
          <Box className="checkout">
            <div>
              <div className="sub-title">Donation Details</div>
                <Box display="flex" justifyContent="space-between" mb="10px">
                  <div className="total">{`Donation for ${this.state.NpDetais?.attributes?.organisation_name}`}</div>
                  <div className="plan-amount"> £ {amountValue !== null && pointsRange[amountValue] || userEntered}</div>
                </Box>
              <div className="border" />
              <Box display="flex" justifyContent="space-between">
                <div className="total">Total</div> 
                <div className="plan-amount">£ {amountValue !== null && pointsRange[amountValue] || userEntered}</div>
              </Box>
            </div>
          </Box>
          </div>
        </PaymentDialog>
      </Dialog>
    );
  };
  renderButtonName = () => {
    const { radioValue, donateValue, pointsRange, amountValue, userEntered } = this.state;
    if (radioValue === "points" && donateValue !== null){
      return `Donate ${pointsRange[donateValue]} points`
    } else if (radioValue === "amount" && amountValue !== null){
      return `Donate £ ${pointsRange[amountValue]}`
    } else if (radioValue === "amount" && userEntered){
      return `Donate £ ${userEntered}`
    } else {
      return "Donate"
    }
  }
  renderfundDetails = () => {
    const { donateValue, currentPoints, pointsRange, amountValue, radioValue } = this.state;
    return (
      <div>
        <RadioStyle
          data-test-id="radioButtons"
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="points"
          name="radio-buttons-group"
          onChange={this.handleRadioChange}
          value={radioValue}
          >
          <Paper className={styles.tableroot} elevation={0}>
            <FormControlLabel
              value="points"
              label="Donate with points"
              className="formControlLabel"
              control={
                <Radio
                  size="small"
                  style={{
                    color: "#009be2",
                  }}
                />
              }
            />
            {radioValue === "points" && (
              <>
                <div className={styles.currentPoints}>
                  You have <span className="currentPoints">{currentPoints}</span>{" "}
                  <span className={styles.grayColor}>Points.</span>
                </div>
                <div className={styles.mainBtnDiv}>
                  <Tabs
                    onChange={this.handleTabChange}
                    value={donateValue}
                    className="donateTabs"
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    data-test-id="pointsTabs"
                    >
                    {pointsRange.map((point: number, index: number) => {
                      return (
                        <DonateTab
                          label={`${point} Points`}
                          key={point}
                          disabled={point > currentPoints}
                          className={`${donateValue === index && "activeBtn"} ${
                            point > currentPoints ? "disabledBtn" : "normalBtn"
                          }`}
                        />
                      );
                    })}
                  </Tabs>
                </div>
                <div className={`${styles.grayColor} ${styles.currentPoints}`}>
                  Donate with your earned points
                </div>
              </>
            )}
          </Paper>

          <Paper elevation={0} className={styles.tableroot}>
            <FormControlLabel
              value="amount"
              className="formControlLabel"
              label="Donate with amount"
              control={
                <Radio
                  style={{
                    color: "#009be2",
                  }}
                  size="small"
                />
              }
            />
            {radioValue === "amount" && (
              <>
                <div className={styles.mainBtnDiv}>
                  <Tabs
                    onChange={this.handleAmountTabChange}
                    value={amountValue}
                    className="donateTabs"
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    data-test-id="tabs"
                   >
                    {pointsRange.map((point: number, index: number) => {
                      return (
                        <DonateTab
                          key={point}
                          className={`${amountValue === index && "activeBtn"} ${
                            "normalBtn"
                          }`}
                          label={`£ ${point}`}
                        />
                      );
                    })}
                  </Tabs>
                </div>

                <Donation>
                  <FilledInput
                    type="number"
                    id="filled-adornment-amount"
                    placeholder="Enter donation amount"
                    data-test-id="donationInput"
                    onChange={this.handleDonationAmount}
                    className="totalAmount"
                    startAdornment={
                      <InputAdornment position="start">£</InputAdornment>
                    }
                    value={this.state.userEntered}
                  />
                </Donation>
              </>
            )}
          </Paper>
        </RadioStyle>
        <div className={userEvent.spaceBetweenDivs}>
          <Box mb={{xs: "45px", sm: "0px"}} width="100%">
          <Button
            onClick={this.handleDonate}
            className={userEvent.BlueBtn}
            data-test-id="donate-button"
            >
            {this.renderButtonName()}
          </Button>
          </Box>
        </div>
      </div>
    );
  };
  gamificationUI = () => {
    return (
      <>
        <Paper className={styles.tableroot} elevation={0}>
          {this.topRewardSection()}
        </Paper>
        <Box marginBottom={{ xs: "81px", sm: "0px" }}>
        <Paper className={styles.tableroot} elevation={0}>
          {this.tableHeader()}
          <Divider className={styles.dividerStyle} />
          <Grid container>
            <Grid item xs={12} className={styles.tableWrapper}>
              {this.tableBodyData()}
            </Grid>
          </Grid>
        </Paper>
        </Box>
      </>
    );
  };

  displayedPage = () => {
    if(this.state.orgClicked) {
      return this.getSingleOrgInfo()
    } else {
      return this.gamificationUI()
    }
  }

  getR1UI = () => {
    return (
      <>
        {/* @ts-ignore */}
        <DashboardWeb
          data-testId="sidebar"
          title={
            this.state.orgClicked
              ? `Donate points to '${this.state.currentOrgName}'`
              : "Rewarded Points"
          }
          showDrawerHeader={isMobile}
          showBackButton={constants._icons.show}
          showBottomBar={true}
          navigation={{
            ...this.props.navigation,
            goBack: () => {
              this.handleBackIconClick();
          }}
          }
        >
          <Container maxWidth={"xl"}>
            <div className={styles.r1Wrapper}>
              {!isMobile && this.headerWeb()}
              {this.state.loading ? (
                <div className={styles.flexCenter} style={{ height: "80%" }}>
                  <CircularProgress className={styles.loaderColor} />
                </div>
              ) : (
                this.displayedPage()
              )}
            </div>
            {this.openDialog()}
            {this.openDonateDialog()}
          </Container>
        </DashboardWeb>
      </>
    );
  }

  getR2UI = () => {
    return (
      <>
        {/* @ts-ignore */}
        <ContentManagement
          showBackButton={constants._icons.hide}
          title="RegisteredVolunteers"
          showBottomBar={constants._icons.show}
          showDrawerHeader={true}
          titleColor={COLORS.black}
          open={false}
          mobileOpen={false}
          navigation={this.props.navigation}
        >
          <Container maxWidth={"xl"}>
            <div>
              {this.headerWeb()}
              {this.state.loading ? (
                <div className={styles.flexCenter} style={{ height: "80%" }}>
                  <CircularProgress className={styles.loaderColor} />
                </div>
              ) : (
                this.displayedPage()
              )}
            </div>
          </Container>
        </ContentManagement>
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { theme }: any = this.props;
    let user = localStorage.getItem("user_type");
    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {user === "user entity" ? this.getR1UI() : this.getR2UI()}
        {this.renderPaymentDialog()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
Gamification.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const Donation = styled(Box)({
  justifyContent: "left",
  display: "flex",
  alignItems: "center",
  "& .totalAmount": {
    width: "50%",
    borderRadius: "15px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    padding: "20px 15px",
    background: "white",
    ...FONTS.regularFont,
    "& div p": {
      color: "#000",
      ...FONTS.regularFont,
    },
    "& input": {
      padding: 0,
    },
    "&::after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-input": {
      padding: 0,
    },
    "&::before": {
      borderBottom: "none",
    },
  },
  "& .donationFreq": {
    marginLeft: "50px",
    "& label span:first-child": {
      color: "#009be2",
      padding: "3px",
    },
    "& label span": {
      fontSize: "14px",
      ...FONTS.regularFont,
    },
  },
});

const PaymentDialog = styled(Box)({
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 8px",
  background: "rgb(254, 254, 254)",
  border: "1px solid rgb(217, 246, 250)",
  width: "930px",
  padding: "40px",
  borderRadius: "12px",
  "@media (max-width: 600px)": {
    padding: "20px",
   width:"100%",
  },
 
  "& .header" : {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& svg": {
      cursor: "pointer"
    },
    "& .titleModal" : {
      ...FONTS.mediumFont,
      fontSize: "20px"
    },
  }, 
  "& .content" : {
    display: "flex",
    alignItems: "start",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse"
    },
    "& .checkout": {
      borderRadius: "12px",
      padding: "20px 30px",
      justifyContent: "center",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      "@media (max-width: 600px)": {
        padding: "20px 0",
      },
      "& .plan-amount": {
        fontFamily: "Rene_Bieder_Milliard_Medium",
        fontSize: "18px",
        textWrap: "nowrap",
        letterSpacing: "-0.5px",
      },
      "& .sub-title": {
        ...FONTS.mediumFont,
        fontSize: "18px",
        marginBottom: "15px",
        letterSpacing: "-0.7px",
      },
      "& .total": {
        ...FONTS.regularFont,
        fontSize: "16px",
        letterSpacing: "-0.45px",
      },
      "& .border": {
        border: "1px solid #bebebe",
        margin: "10px 0px",
      },
    }
  }
})

const RadioStyle = styled(RadioGroup)({
  "& .formControlLabel .MuiFormControlLabel-label": {
   ...FONTS.regularFont
  }
 })

const DonateTab = styled(Tab)({
  "&.disabledBtn": {
    border: "1px solid #ebebeb !important",
    borderRadius: "5px !important",
    marginRight: "15px !important",
    color: "#99999B !important",
    minHeight: "50px",
    textTransform: "lowercase",
    ...FONTS.mediumFont,
    fontSize: "16px !important",
    background: "#ebebeb !important",
    "@media (max-width: 600px)": {
      marginBottom: "15px !important",
      fontSize: "14px !important",
    }
  },
  "&.normalBtn" : {
    border: "1px solid #0091be !important",
    borderRadius: "5px !important",
    color: "#030303",
    marginRight: "15px !important",
    minHeight: "50px",
    textTransform: "lowercase",
    ...FONTS.mediumFont,
    fontSize: "16px !important",
    "@media (max-width: 600px)": {
      marginBottom: "15px !important",
      fontSize: "14px !important",
    }
  },
  "&.activeBtn" : {
    backgroundColor: "#009be2 !important",
    border: "1px solid #009be2 !important",
    ...FONTS.mediumFont,
    color: "#ffffff !important",
    "@media (max-width: 600px)": {
      marginBottom: "15px !important",
      fontSize: "14px !important",
    }
  }
})

export { Gamification };
export default withStyles(style, { withTheme: true })(Gamification as any);
// Customizable Area End
