import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Toolbar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { constants, icons, FONTS } from "../../../components/src/constants";
import { isMobile } from "react-device-detect";
import { COLORS } from "../../../framework/src/Globals";
// @ts-ignore
import classesoutercss from "./Surveys.module.css";
import Pagination from '@material-ui/lab/Pagination';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SurveysController, {
  Props,
  configJSON,
} from "./SurveysController";
import { CheckBox } from "react-native";

export default class Surveys extends SurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headerWeb() {
    return (
      <Toolbar className={classesoutercss.toolBar}>
        {/*  search  section  */}
        {!isMobile &&
          <div className={classesoutercss.backIconWeb}>
            <IconButton
              onClick={() => this.handleEndSurvey()}
              color={"inherit"}
              aria-label="Open drawer"
            >
              <img src={icons.back} />
            </IconButton>
            <Typography variant="h6" style={{ ...FONTS.body1, color: COLORS.black, fontSize: "20px" }} noWrap>
              Surveys
            </Typography>
          </div>
        }
      </Toolbar>
    )
  }

  radioQuestionBlock = () => {
    return (
      <>
        <Box className={classesoutercss.descriptionOuter}>
          <Typography variant="h5">What is your gender?</Typography>
          <div>
            <FormControl component="fieldset" className={classesoutercss.radioDescSection}>
              <RadioGroup aria-label="gender" name="gender1" value={this.state.radioValue} onChange={(e) => this.handleChange(e)}>
                <FormControlLabel className={classesoutercss.descRadioBox} value="female" control={<Radio />} label="Female" />
                <FormControlLabel className={classesoutercss.descRadioBox} value="male" control={<Radio />} label="Male" />
                <FormControlLabel className={classesoutercss.descRadioBox} value="other" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
          </div>
        </Box>
      </>
    )
  }

  checkboxQuestionBlock = () => {
    return (
      <>
        <Box className={classesoutercss.descriptionOuter}>
          <Typography variant="h5">If you have a "How To" question, what method do you use to solve the issue?</Typography>
          <Typography variant="subtitle1">Choose only 2 options</Typography>
          <div>
            Checkbox
          </div>
        </Box>
      </>
    )
  }

  rangeQuestionBlock = () => {
    return (
      <>
        <Box className={classesoutercss.descriptionOuter}>
          <Typography variant="h5">Give your rating to our service</Typography>
          <div className={classesoutercss.radioWrapper}>
            <div>
              <span>Poor</span>
              <Radio
                checked={false}
                onChange={(e) => this.handleChange(e)}
                value="a"
                name="radio-button-range"
                className={classesoutercss.descRangeBox}
              />
              <Radio
                checked={false}
                onChange={(e) => this.handleChange(e)}
                value="b"
                name="radio-button-range"
                className={classesoutercss.descRangeBox}
              />
              <Radio
                checked={false}
                onChange={(e) => this.handleChange(e)}
                value="c"
                name="radio-button-range"
                className={classesoutercss.descRangeBox}
              />
              <Radio
                checked={true}
                onChange={(e) => this.handleChange(e)}
                value="d"
                name="radio-button-range"
                className={classesoutercss.descRangeBox}
              />
              <Radio
                checked={false}
                onChange={(e) => this.handleChange(e)}
                value="e"
                name="radio-button-range"
                className={classesoutercss.descRangeBox}
              />
              <span>Excellent</span>
            </div>
          </div>
        </Box>
      </>
    )
  }

  completeSurveyBlock = () => {
    return (
      <>
        <div className={classesoutercss.completedSurvey}>
         <Box className={classesoutercss.descriptionOuter}>
          <div className={classesoutercss.toolBarInnerIcon}>
            <div className={classesoutercss.toolBarInnerimage}>
              <img src={icons.likeIcon}/>
              <Typography variant="subtitle2"><span>100%</span> Completed</Typography>
            </div>
          </div>
         </Box>
        </div> 
      </>
    )
  }

  surveyQuestionBlocks = () => {
    const blockType = {
      radio: this.radioQuestionBlock(),
      check: this.checkboxQuestionBlock(),
      range: this.rangeQuestionBlock(),
      completed: this.completeSurveyBlock(),
    }

    return blockType['radio']
  }

  surveyQuestionsFrame = () => {
    return (
      <Box className={classesoutercss.toolBarInner}>
        <Box style={{marginBottom: 'auto'}}>
          <Box className={classesoutercss.toolBarTopHead}>
            <div className={classesoutercss.toolBarInnerHead}>
              <Typography variant="h4"> Survey for initiateapp</Typography>
              <Typography variant="h6">Question 1/8</Typography>
            </div>
            <div className={classesoutercss.toolBarInnerIcon}>
              <div className={classesoutercss.toolBarInnerimage}>
                <img src={icons.likeIcon}/>
                <Typography variant="subtitle2"><span>0%</span> Completed</Typography>
              </div>
            </div>
          </Box>
          {this.surveyQuestionBlocks()}  
        </Box>  
        <Box className={classesoutercss.descriptionSubmitBox}>
          <div className={classesoutercss.descPagination} >
            <Pagination count={10} variant="outlined" shape="rounded" />
          </div>
          <Button className={classesoutercss.descriptionSubmitBtn} data-test-id="btnCloseModal"
            variant="contained">
            Submit
          </Button>
        </Box>
      </Box>
    )
  }

  surveyDescription = () => {
    return (
      <Box className={classesoutercss.toolBarInner}>
        <Box style={{marginBottom: 'auto'}}>
          <div className={classesoutercss.toolBarInnerHead}>
            <Typography variant="h4"> Survey for initiateapp</Typography>
          </div>
          <Box className={classesoutercss.descriptionOuter}>
            <Typography variant="h5">Survey Description</Typography>
            <Box className={classesoutercss.descriptionInBox}>
              <Typography variant="subtitle1">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste incidunt ullam quos quae reiciendis architecto nemo omnis doloribus rerum laudantium! Molestiae dignissimos officia omnis consequuntur dicta. Enim dolores aperiam quos? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum, facilis? Itaque veniam, voluptate atque officia cumque laudantium! Dolore autem repellat, est ab magnam distinctio et laborum ipsum excepturi, temporibus quasi? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam quo laudantium tenetur eos fuga ut, voluptas ratione soluta nesciunt, cumque ex eius, aliquam neque id voluptatum maxime explicabo nostrum omnis!</Typography>
            </Box>
          </Box>
        </Box>  
        <Box className={classesoutercss.descriptionSubmitBox}>
          <Button 
            onClick={() => this.handleStartSurvey()}
            className={classesoutercss.descriptionSubmitBtn} data-test-id="btnCloseModal"
            variant="contained">
            Start
          </Button>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        
          {this.headerWeb()}
          {
            this.state.startSurvey ?
            this.surveyQuestionsFrame()
            :
            this.surveyDescription()
          }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  descPagination: {
    "& .Mui-selected": {
      color: '#009BE2'
    }
  },
};
// Customizable Area End
