export const ADD_PERSONAL_INFO = "ADD_PERSONAL_INFO"
export const ADD_DBS_DETAILS = "ADD_DBS_DETAILS"
export const ADD_MY_NON_PROFIT = "ADD_MY_NON_PROFIT"
export const ADD_WORK_EMAIL = "ADD_WORK_EMAIL"
export const ADD_MY_TASK_PREFERANCE = "ADD_MY_TASK_PREFERANCE"
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const ADD_EMAIL_NOTIFICATION = "ADD_EMAIL_NOTIFICATION"
export const ADD_AVAILABILITY1 = "ADD_AVAILABILITY1"
export const ADD_AVAILABILITY2 = "ADD_AVAILABILITY2"
export const ADD_AVAILABILITY3 = "ADD_AVAILABILITY3"
export const ADD_EMAIL_OTP = "ADD_EMAIL_OTP"
export const ADD_MOBILE_OTP = "ADD_MOBILE_OTP"
