import React from 'react';


import {
  Box,
  Typography,
  IconButton,
  // Customizable Area Start
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  Toolbar,
  List, ListItem, ListItemText,
  BottomNavigation, BottomNavigationAction, Badge
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import { COLORS } from "../../../framework/src/Globals"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
  Menu as MenuIcon,

} from "@material-ui/icons";
import {
  constants,
  icons, FONTS,
  images,
  FAMILYFONTS,
} from "../../../components/src/constants"
import DashboardController, { Props } from "./DashboardController";
import { setSelectedTab } from '../../../framework/src/redux/tab/tabActions';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { getAllEventsDetails } from '../../../framework/src/redux/advanceSearch/advanceSearchActions';
import { setSelectedEvents, setLocationWiseFilterEvent } from '../../../framework/src/redux/events/eventsActions';
import { setSelectedFilterEvents } from '../../../framework/src/redux/filterEvents/filterEventsAction';
import { storeSearchDetails } from '../../../framework/src/redux/savedSearch/savedSearchAction';
import Notifications from '../../notifications/src/Notifications.web';
import { AppContext } from '../../ContentManagement/src/ContentManagementDashBoard.web';

const drawerWidth = 240;

const styles = (theme:
  {
    zIndex: any;
    breakpoints: any;
    transitions: {
      create: (arg0: string | string[], arg1:
        { easing: any; duration: any; }) =>
        any; easing: { sharp: any; easeOut: any; }; duration: {
          leavingScreen:
          any; enteringScreen: any;
        };
    }; mixins: { toolbar: any; }; spacing: { unit: number; };
  }) => ({
    root: {
      display: 'flex',
      position: 'relative',
      overflowX: 'hidden',
      overflowY: 'hidden',
      '&:hover': {
        overflowY: 'auto',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    appBar: {
      backgroundColor: COLORS.white,
      color: COLORS.black,
    },
    appBarTrans:{
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,}
      )},
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: theme.zIndex.appBarTrans - 1,
    },
    drawerPaper: {
     width: drawerWidth,
      borderRight: " 2px solid #fff",

    },
    mobiledrawerPaper: {
  
      borderRight: " 2px solid #fff",
      marginTop: "4px",
      boxShadow: '0px 0px 8px rgba(212, 212, 212, 0.474186)',
      borderTopRightRadius: "10px"
    },
    mobiledrawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 12px',
      ...theme.mixins.toolbar,
      justifyContent: 'center',
    },
    drawerLogoHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 12px',
      ...theme.mixins.toolbar,
      justifyContent: 'center',
      marginTop: "30%",
      marginBottom: "30px"

    },
    content: {
      flexGrow: 1,
      marginLeft: -drawerWidth,
    },
    contentTrans:{ 
      transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    listItem: {
      transition: "all .5s",
      display: "flex",
      flexDirection: "column",
    },
    listLink: {
      padding: "0 15px",
      textDecoration: "none",
      color: "inherit",
      transition: "all .5s",
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    listIcon: {
      color: "inherit",
      justifyContent: "center",
    },
    listItemText: {
      ...FONTS.body3,
      color: "#B2B2B2",
      [theme.breakpoints.up("sm")]: {
        fontSize: '0.84em',
      },
    },
    selectedlistItemText: {
      ...FONTS.body3,
      color: COLORS.white,
      [theme.breakpoints.up("sm")]: {
        fontSize: '0.84em',
      },
    },
    mobileSidebarlistItemText: {
      fontFamily: 'Rene_Bieder_Milliard_Book',
      fontSize: 15,
      color: "#0A0A0A",
    },
    list: {
      listStyle: "none",
      paddingTop: "25px"
    },
    listItemroot: {
      width: "75%",
      margin: "4px auto",
      borderRadius: "8px",
      transition: "all .5s",
      overflow: "hidden",
    },
    mobilelistItemroot: {
      width: "95%",
      margin: "4px auto",
      borderRadius: "8px",
      transition: "all .5s",
      overflow: "hidden",
    },
    selected: {
      background: COLORS.linearGradientBlue,
      color: COLORS.white,

    },
    bottomtabselected: {
      background: COLORS.linearGradientSkyBlue,
      color: COLORS.skyBlue
    },
    applogo: {
      height: "100%",
      maxHeight: "130px"
    },
    bottomBarroot: {
      width: "100%",
      height: "70px",
      left: "0px",
      top: "774px",
      borderRadius: "0px",
      background: ' #FFFFFF',
      boxShadow: '0px -2px 4px rgb(0 0 0 / 10%)'
    },
    bottom_bar: {
      position: 'fixed',
      zIndex: theme.zIndex.drawer + 1,
      right: '0px',
      bottom: '0px',
      left: '0px'
    },
    bottom_bar_button: {
      position: 'reletive',
      zIndex: theme.zIndex.drawer + 1,
      marginRight: 18,
      marginLeft: 18,
      bottom: 40,
      right: 0,
      left: 0

    },
    iconButton: {
      color: COLORS.black
    },
    notificationiconButton: {
      color: COLORS.black,
      padding: "20px"
    },
    toolBarContentCenter: {
      justifyContent: "space-between",
      padding: 0
    },
    toolBarContentLeft: {
      justifyContent: "flex-start"
    },
    _tabs_logo: {
      height: "24px",
      width: "24px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",

    }, title: {
      color: COLORS.skyBlue
    },
    dash: {
      border: "0.5px solid #f7f7f7",
      width: "100%",
      height: 0,
    }
  });


// Customizable Area End

 class Dashboard extends DashboardController {
  static propTypes: { classes: PropTypes.Validator<object>; theme: PropTypes.Validator<object>; };

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleDrawerOpen = () => {
    this.setState({ open: !this.state.open });

  };

  handleDrawerClose = () => {
    this.setState({ open: false,sideBarClose:true });
  };

  onWebSidebarMenuClick = (_index: any, item: any, navigation: any) => {
    const screenMappings = {
      [constants.dashboard_screens.home]: "HomeDashBoard",
      [constants.dashboard_screens.Profile]: "UserProfileBasicBlock",
      [constants.dashboard_screens.Needs]: "MyNeed",
      [constants.dashboard_screens.Chats]: "GroupChat",
      [constants.dashboard_screens.Pledges]: "MyPledges",
      [constants.dashboard_screens.Settings]: "Settings",
      [constants.dashboard_screens.Surveys]: "SurveysDashboard",
      [constants.dashboard_screens.Logout]: "EmailAccountLoginBlock",
    };

    this.setState({ selectedTab: _index });

    const screenName = screenMappings[item.name];

    if (screenName) {
      navigation.navigate(screenName);
      if (item.name === constants.dashboard_screens.home) {
        this.props.homeClick('');
      }
      else if (item.name === constants.dashboard_screens.Logout) {
        localStorage.removeItem("token");
      }
    }
  };

  onMobileSidebarMenuClick = (_index: any, item: any, navigation: any) => {
    this.setState({ selectedTab: _index })
    if (item.name === constants.dashboard_screens.SavedSearches) {
      navigation.navigate("SavedSearchContainer")
    } else if (item.name === constants.dashboard_screens.Settings) {
      navigation.navigate("Settings")
    } else if (item.name === constants.dashboard_screens.InvitePeople) {
      navigation.navigate("InvitePeople")
    } else if (item.name === constants.dashboard_screens.Feedback) {
      navigation.navigate("FeedbackWeb")
    } else if (item.name === constants.dashboard_screens.ContactUs) {
      navigation.navigate("ContactUsWeb")
    } else if (item.name === constants.dashboard_screens.TermAndCondition) {
      navigation.navigate("TermsAndConditionsWeb")
    } else if (item.name === constants.dashboard_screens.PrivacyPolicy) {
      navigation.navigate("PrivacyPolicy")
    }
    else if (item.name === constants.dashboard_screens.Logout) {
      this.handleLogout()
      this.removeDeviceToken();
    }
  }


  onBottomBarClick = (_index: any, item: any, navigation: any) => {
    this.setState({ selectedBottomTab: item.id })
    if (item.name === constants.dashboard_screens.home) {
      navigation.navigate("HomeDashBoard")
    } else if (item.name === constants.dashboard_screens.Pledges) {
      navigation.navigate("MyPledges")
    } else if (item.name === constants.dashboard_screens.Profile) {
      navigation.navigate("UserProfileBasicBlock")
    } else if (item.name === constants.dashboard_screens.Needs) {
      navigation.navigate("MyNeed")
    } else if (item.name === constants.dashboard_screens.Chats) {
      navigation.navigate("GroupChat")
    } else if (item.name === constants.dashboard_screens.Settings) {
      // history.push("settings")
      navigation.navigate("ErrorPage")
    }
  }


  renderAppBar() {
    const { classes, title, titleColor, showBackButton, showLine, navigation } = this.props;
    const { open } = this.state;
    const isSettings = window.location.href.includes('Settings');
    const isEdit = window.location.href.includes('UserEditProfile');

   
    return (
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: this.state.open,
          [classes.appBarTrans]:this.state.sideBarClose
                  })}
        elevation={0}
      >
        <Toolbar disableGutters={!this.state.open}
          className={showBackButton === true ? `${classes.toolBarContentLeft}` : `${classes.toolBarContentCenter}`}>

          {showBackButton === true ?
            <>

              <IconButton
                color={"inherit"}
                aria-label="Open drawer"
                data-testid='backBtn'
                onClick={() => {
                  if (isSettings) {
                    this.props.navigation.navigate('HomeDashBoard');
                  } else if (isEdit) {
                    this.props.navigation.navigate('UserProfileBasicBlock');
                  }
                   else {
                    navigation.goBack();
                  }
                }}
                data-testId='backBtn'
              >
                <img src={icons.back} />
              </IconButton>
              <Typography  style={{ ...FONTS.body1, color: titleColor, fontSize:'16px' }} noWrap data-testid='titleheader'>
                {title}
              </Typography>
            </>
            : <>
              <IconButton
                color={"inherit"}
                aria-label="Open drawer"
                data-testid='opendrawer'
                onClick={this.handleDrawerOpen}
                className={classNames(classes.iconButton)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" style={{ ...FAMILYFONTS.family1, color: titleColor, paddingLeft: this.state.open ? 50 : "", paddingTop: 3 }} >
                {title}
              </Typography>
              <AppContext.Consumer>
                {(context: any) => (
              <IconButton
                color={"inherit"}

                style={{
                  position: 'relative'
                }}
              >
                {context.newNoti && <Box style={{
                  width: '8px',
                  height: '8px',
                  backgroundColor: "red",
                  borderRadius: '50%',
                  position: 'absolute',
                  top: 10,
                  right: 14,
                }}>

                </Box>}
                <img src={icons.notification} style={{
                  width: 24,
                  height: 24
                }}
                data-testId="close-btn"
                onClick={() => {
                  this.handleNoti();
                  if(this.state.notificationShow){
                    context.closeIndicator();
                  }
                }}
                />
              </IconButton>
                )}
              </AppContext.Consumer>
            </>
          }

        </Toolbar>
        {
          showLine === true ? <div className={classes.dash}></div> : ""
        }
      </AppBar>
    )
  }

  renderMobileDrawer() {
    const { classes, setSelectedTab, navigation } = this.props;
    return (
      <Drawer
       className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={this.state.open}
classes={{
  paper: this.state.sideBarClose ? classes.drawerPaper : classes.mobiledrawerPaper,
        }}
      >
        <List className={classes.list}>
          {this.state.open && constants?.mobileHomeSidebar.map((item, _index) => (
            <div
              key={_index}
              className={
                classes.mobilelistItemroot
              }>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => {
                  this.onMobileSidebarMenuClick(_index, item, navigation)
                  item.name === constants.dashboard_screens.Logout ? setSelectedTab("Home") : setSelectedTab(item.name)
                }}
                disableGutters
                key={_index}
                data-testId={`mobileSidebar-${item.id}`}

              >
                <Box
                  component={"div"}
                  className={
                    classes.listLink}>
                  <ListItemIcon className={classes.listIcon}>
                    {(item.icon && <img className={classes._tabs_logo} src={item.icon} />) || ""}
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary:
                        classes.mobileSidebarlistItemText
                    }}
                    primary={item.name}
                  />

                </Box>
              </ListItem>
            </div>
          ))}
        </List>

      </Drawer>
    )
  }


  renderBottomBar() {
    const { classes, showBottomBar, setSelectedTab, navigation } = this.props; 
    return (
      <div className={classes.bottom_bar}  >
        {showBottomBar === true ?
          <BottomNavigation className={classes.bottomBarroot} showLabels>
            {constants?.bottom_tabs.map((item, _index) => (
              <BottomNavigationAction key={_index} label={<Typography style={{
                color: item.name ===  this.getUrlName() ? COLORS.skyBlue : '#979797',
                marginTop: 8, width: 70,
                ...FAMILYFONTS.family3, fontSize: 12
              }}>{item.name}</Typography>} value={item.name}
                icon={(item.icon && item.name === this.getUrlName() ?
                  <img className={classes._tabs_logo}
                    style={
                      this.bottomBarIconSize(this.getUrlName())
                    }
                    src={
                      item.selectedIcon} /> :
                  <img className={classes._tabs_logo} src={
                    item.icon} />
                ) || ""}

                className={
                  item.name === this.getUrlName() ? ` ${classes.bottomtabselected}`
                    : ""
                }
                data-testId={`mobileBottomBar-${item.id}`}
                onClick={() => {
                  this.onBottomBarClick(_index, item, navigation)
                  setSelectedTab(item.name)
                }}
              />
            ))}
          </BottomNavigation>
          :
          <></>
        }
      </div>
    )
  }

  renderWebDrawer() {
    // Destructure props for easier access
    const { classes, setSelectedTab, navigation } = this.props;

    // Render each sidebar item using map
    const sidebarItems = constants?.homesidebar.map((item, index) => {
      const isSelected = item.name === this.getUrlName();
      return (
        <div
          key={index}
          className={`${classes.listItemroot} ${isSelected ? classes.selected : ''}`}
        >
          <ListItem
            button
            className={classes.listItem}
            onClick={(e: any) => {
              console.log(this.props, "this.props");
              this.onWebSidebarMenuClick(index, item, navigation);
              setSelectedTab(isSelected ? 'Home' : item.name);
            }}
            disableGutters
            data-testId={`webSidebar-${item.id}`}
          >
            <Box component="div" className={classes.listLink}>
              <ListItemIcon className={classes.listIcon}>
                <img
                  className={classes._tabs_logo}
                  src={isSelected ? item.selectedIcon : item.icon}
                  style={{ width: isSelected && item.name === constants.dashboard_screens.Pledges ? 17 : 24 }}
                />
              </ListItemIcon>
              <ListItemText
                className={isSelected ? classes.selectedlistItemText : classes.listItemText}
              >
                <Typography style={{ ...FAMILYFONTS.family3, fontSize: 16, margin: "3px 0 3px" }}>
                  {item.name}
                </Typography>
              </ListItemText>
            </Box>
          </ListItem>
        </div>
      );
    });

    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={this.state.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* Logo header */}
        <div className={classes.drawerLogoHeader}>
          <img src={images.app_logo} className={classes.applogo} />
        </div>

        {/* Sidebar item list */}
        <List className={classes.list}>{sidebarItems}</List>
      </Drawer>
    );
  }






  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, theme, children, showDrawerHeader } = this.props;
    const { open } = this.state;

    // Customizable Area End
    
    return (
      // Customizable Area Start
          <>
        {this.state.notificationShow ?
          <AppContext.Consumer>
            {(context: any) => (
              <Notifications data-testId="notification" navigation={this.props.navigation} id={''} onClose={undefined} isCome={context.isCome} notificationmodalState={() => { this.handleNoti(); context.closeIndicator(); }} type="user_entity" />
            )}
          </AppContext.Consumer>
      :<ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />

          {/* Mobile view */}
          {/* @ts-ignore */}
          <Hidden smUp implementation="css">
            {/* Render the app bar */}
            {this.renderAppBar()}

            {/* Render the mobile drawer */}
            {this.renderMobileDrawer()}

            {/* Render the bottom bar */}
            {this.renderBottomBar()}
          </Hidden>
          {/* End of mobile view */}

          {/* Web view */}
          <Hidden xsDown implementation="css">
            {/* Render the web drawer */}
            {this.renderWebDrawer()}
          </Hidden>
          {/* End of web view */}

          <main
            className={classNames(classes.content, {
              [classes.contentShift]: open,
              [classes.contentTrans]:this.state.sideBarClose
                             })}
            style={{ height: isMobile ? '100%' : "100vh", width: "100%", overflow: this.props.title === "Dashboard" ? "hidden" : "auto" }}
          >
            {/* Render the drawer header if showDrawerHeader is true */}
            {showDrawerHeader === true ? <div className={classes.drawerHeader} /> : ""}

            {/* Render the children components */}
            {children}
          </main>
        </div>

        {/* Bottom bar */}
        <Hidden smUp implementation="css">
          {/* Render the bottom bar */}
        </Hidden>
        {/* End of bottom bar */}
      </ThemeProvider>
        }
          </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
// Customizable Area End


// Customizable Area Start
function mapStateToProps(state: any) {
  return {
    selectedTab: state.tabReducer?.selectedTab,

  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedTab: (selectedTab: any) => {
      return dispatch(setSelectedTab(selectedTab))
    },
    setSelectedEvents: (selectedTab: any) => {
      return dispatch(setSelectedEvents(selectedTab))
    },
    getAllEventsDetails: (selectedTab: any) => {
      return dispatch(getAllEventsDetails(selectedTab))
    },
    setSelectedFilterEvents: (selectedEvents: any) => {
      return dispatch(setSelectedFilterEvents(selectedEvents))
    },
    storeSearchDetails: (savedSearchinfo: any) => {
      return dispatch(storeSearchDetails(savedSearchinfo))
    },
    setLocationWiseFilterEvent: (selectedEvents: any) => {
      return dispatch(setLocationWiseFilterEvent(selectedEvents))
    }
  }
}



export { Dashboard }
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(Dashboard))
// Customizable Area End