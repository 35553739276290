import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from 'react-toastify';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { IBlock } from "../../../framework/src/IBlock";
import { createRef } from "react";
import { constants, icons } from "../../../components/src/constants";
import { COLORS } from "../../../framework/src/Globals";
import { isMobile } from "react-device-detect";
import { apiCall } from "../../../components/src/utils/utils.web";
import moment from "moment";

export interface AffiliatedOrg {
  id: number | string;
  logo: Logo;
  organisation_name: string;
  description: string;
  location: string;
  registered: boolean;
}
export interface Logo {
  url: string;
}

type PostCode = string | number | null;
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  spacing: any;
  profileType?: any;
  setSelectedEvents?: any;
  userProfileDetails?: any;
  openModal: boolean;
  publish: string;
  onSubmit: any;
  friends: any;
  streetName: string;
  town: string;
  country: string;
  postCode: string;
  area:string;
  classes?: any;
  theme?: any;
  onCloseModal?:any;
  setBack?: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  favData: any[];
  removePopup: any;
  removeId: any;
  firstName: any;
  lastName: any;
  email: any;
  favPledgeId:any;
  favNeedId:any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  account_id:string;
  showHam: boolean;
  UserProfileUrl: string;
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  openstatusmodal: boolean;
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  pledgedUserDetails: any;
  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;
  openMenu: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;
  selectedCategoryStatus: any;
  typeOfEvent:any;
  lat:number|null;
  lng:number|null;
  selectedCategoryMenu: any;
  saveButtonDisable: boolean;
  open: boolean;
  connCount : any;
  value: any;
  apiUserDetail: any;
  peldgesTabData: any;
  searchKey1: string;

  hasStartDate: boolean;
  needsDelete: boolean;
  titleOfNeed: string;
  location: string;
  describeNeeds: string;
  additionalInformation: string;
  fromDate: any;
  toDate: any;
  startTime: any;
  finishTime: any;
  uploadPic: any;
  needType: any;
  selectedNeedsTabChange: any;
  selectedeventsImage: any;
  upComingPastEvent: any;
  editProfileView: boolean
  connectToast: boolean;
  acceptToast: boolean;
  rejectToast: boolean;
  needsTabData: any,
  pledgesTabData: any,
  count: number
  counter: number
  needModel: boolean
  activeNeedsTabData: any
  pastNeedsTabData: any
  deleteNeedId: any
  allConnectionModule: any
  loader: boolean
  connLoader: boolean;
  npoLoader: boolean;
  connectedModule: any
  pendingRequestModule: any
  awaitResponseModule: any
  nonProfitDisplay: boolean
  followingModule: any,
  registeredModule: any,
  nonProfitAffiliationData: any
  nonProfitAffiliationFilterData: any
  selesctedCheckBox: any
  payloadCheckBox: number[]
  myNonProfit: any
  profileImageSrc: any
  profileUserData: any
  workEmailState: any
  currentPoints: number
  medal: string
  updatedProfileImage: any
  show_company_logo: any
  imageError: any
  editProfileType: any
  dbsData: any
  dbsUserData: any
  orgError: any,
  search: string;
  selectedUser: any;
  connections: any;
  openPublishModal: boolean,
  publishAll: boolean
  awaitResponseUsers: any;
  streetName: string;
  area:string|null;
  town: string;
  country: string|null;
  postCode: string;
  divWidth: any;
  selectedId: string;
  eventType: string;
  pledgeLoading:boolean;
  userconnections:any;
  userloader:boolean;
  connectionTab:string
  followingLoader:boolean
  tableData: any;
  filteredData: any;
  editWorkEmail: boolean;
  workEmailError: string;
  disabled: boolean;
  registeredLoader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelFirstName: string;
  myTaskPreferenceId: any;
  getPledgedUser: any;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  getUserApiCallId: any;
  fetchUserDataApiId: any;
  pledgesTabApiId: any;
  needsApiCallId: any;
  deleteNeedApiCallId: any;
  pastEventsId: string = "";
  upComingEventsId: string = "";
  getR1CurrentPointsApiCallId: string = "";
  pledgesTabApiCallId: string = "";
  needsTabApiCallId: string = '';
  activeNeedsTabApiCallId: string = '';
  pastNeedsTabApiCallId: string = '';
  deleteActiveNeedsTabApiCallId: string = '';
  allConnectionUserModuleApiCallId: string = '';
  addToFavrApiId: string = "";
  getUnfollowNpApiCallId:string="";
  removeFromFavApiId: string = "";
  connectedUserModuleApiCallId: string = '';
  pendingRequestUserModuleApiCallId: string = '';
  awaitResponseUserModuleApiCallId: string = '';
  followingUserModuleApiCallId: string = '';
  registeredUserModuleApiCallId:string='';
  addConnectionRequestModuleApiCallId: string = '';
  acceptConnId: any;
  removeFavApiId: string = '';
  cancelConnId: any;
  getFavouritesApiCallId: string = "";
  nonProfitAffiliationSeceletApiCallId: string = '';
  saveNonProfitDataApiCallId: string = ''
  updateUserProfileDataApiCallId: string = ''
  fetchDbsDetailsId: string = "";
  fetchUserDbsDetailsId: string = "";
  updateUserDBSDataApiCallId: string = "";
  getConnectionsId: string = "";
  awaitResponseId: any;
  publishConnectionsApiId: string = "";
  divRef: any;
  addFavouriteAPICallId: string = '';
  removeFavouriteAPICallId: string = '';
  getActivityLogApiCallId: string = "";
  checkWorkEmailApiCallId: string = "";


  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.divRef = createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
    ];

    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);


    this.state = {
      favData: [],
      removePopup: false,
      account_id:'',
      removeId: "",
      firstName: "",
      lastName: "",
      email: "",
      favPledgeId:"",
      favNeedId:"",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      UserProfileUrl: '',
      showHam: true,
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      pledgedUserDetails: {},
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",
      typeOfEvent:null, 
      openMenu:false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      open: false,
      connCount : 0,
      value: 1,
      lat:null,
      lng:null,
      apiUserDetail: {},
      peldgesTabData: [],
      openstatusmodal: false,
      searchKey1: "",
      selectedCategoryStatus: "",
      selectedCategoryMenu: "Awaiting response",
      hasStartDate: false,
      needsDelete: false,
      streetName: "",
      area:"",
      town: "",
      country: "",
      postCode: "",
      titleOfNeed: "",
      location: "",
      describeNeeds: "",
      additionalInformation: "",
      fromDate: "",
      toDate: "",
      startTime: "",
      finishTime: "",
      uploadPic: null,
      needType: "",
      selectedNeedsTabChange: "Active",
      selectedeventsImage: "",
      upComingPastEvent: {},
      editProfileView: false,
      connectToast: false,
      acceptToast: false,
      rejectToast: false,
      needsTabData: [],
      pledgesTabData: [],
      count: 0,
      counter: 0,
      needModel: false,
      activeNeedsTabData: [],
      pastNeedsTabData: [],
      deleteNeedId: '',
      allConnectionModule: [],
      loader: true,
      connLoader: true,
      npoLoader: false,
      connectedModule: [],
      awaitResponseModule: [],
      pendingRequestModule: [],
      nonProfitDisplay: false,
      followingModule: [],
      registeredModule: [],
      nonProfitAffiliationData: [],
      nonProfitAffiliationFilterData: [],
      selesctedCheckBox: [],
      payloadCheckBox: [],
      myNonProfit: [],
      profileImageSrc: null,
      profileUserData: null,
      workEmailState: null,
      currentPoints: 0,
      medal: "",
      updatedProfileImage: null,
      show_company_logo: true,
      imageError: null,
      editProfileType: null,
      dbsData: [],
      dbsUserData: [],
      orgError: null,
      search: "",
      selectedUser: [],
      connections: [],
      openPublishModal: false,
      publishAll: false,
      awaitResponseUsers: [],
      divWidth: 0,
      selectedId: '',
      eventType: '',
      pledgeLoading:false,
      userconnections:[],
      userloader:true,
      connectionTab:"Awaiting response",
      followingLoader:true,
      tableData: [],
      filteredData: [],
      editWorkEmail: false,
      workEmailError: "",
      registeredLoader: false,
      disabled: false
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }


  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken,
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) },
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd },
        };
      }

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken,
    };

    const data = {
      new_phone_number: countryCode + mobileNo,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false,
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false,
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: "",
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUser() {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
    };

    const attrs = {
      account_id: 1,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    },
  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    },
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text });

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true,
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text });
        this.txtInputEmailProps.value = text;
      }
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true),
  };

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text });
      this.txtInputCurrentPasswordProps.value = text;
    },
    value: "",
    secureTextEntry: true,
  };

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry,
      });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this
        .txtInputCurrentPasswordProps.secureTextEntry;
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgPasswordShowhideProps = {
    source: imgPasswordVisible,
  };

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text });
      this.txtInputNewPasswordProps.value = text;
    },
    value: "",
    secureTextEntry: true,
  };

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry,
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this
        .txtInputNewPasswordProps.secureTextEntry;
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible,
  };

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text });
      this.txtInputReTypePasswordProps.value = text;
    },
    secureTextEntry: true,
    value: "",
  };

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible,
  };

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps
          .secureTextEntry,
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this
        .txtInputReTypePasswordProps.secureTextEntry;
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  
  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false),
  };

  apiSuccessResponse1 = (responseJson:any) =>{
  this.setState((prevState) => ({
    pledgesTabData: 
      prevState.pledgesTabData.map((item:any) => {
        if(( item.attributes.need_id) && (item.attributes.need_id == this.state.favPledgeId) )  
        {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              need:{
                ...item.attributes.need,
                data:{
                  ...item.attributes.need.data,
                  attributes:{
                    ...item.attributes.need.data.attributes,
                    is_favourite: !item.attributes.need.data.attributes.is_favourite,
                    favourite_id: responseJson?.data?.id
                  }
                }
              }
            }
          }
        }
    else if(( item.attributes.non_profit_event_id) && (item.attributes.non_profit_event_id == this.state.favPledgeId) ){
      return {
        ...item,
        attributes: {
          ...item.attributes,
          non_profit_event:{
            ...item.attributes.non_profit_event,
            data:{
              ...item.attributes.non_profit_event.data,
              attributes:{
                ...item.attributes.non_profit_event.data.attributes,
                is_favourite: !item.attributes.non_profit_event.data.attributes.is_favourite,
                favourite_id: responseJson?.data?.id
              }
            }
          }
        }
      }
    }
  else {
    return { ...item };
  }
      })
  }))
  } 
  apiSuccessResponse2 = (responseJson: any) => { 
     this.setState((prevState) => ({
    needsTabData: 
       prevState.needsTabData.map((item:any) => {
        if (item.id === this.state.favNeedId) {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              is_favourite: !item.attributes.is_favourite,
              favourite_id: responseJson?.data?.id
            }
          }
        }
        else {
          return {...item};
        }
      }),
       
  })
  )
    }

  handleAPIresponse1 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.fetchUserDataApiId) {
      this.setState({ apiUserDetail: responseJson, profileUserData: responseJson.data, workEmailState: responseJson.data.attributes?.work_email, show_company_logo: responseJson.data.attributes?.show_company_logo, connCount : responseJson?.data?.attributes?.connections?.connection_count });
      this.updateDivWidth()
    }
    if (apiRequestCallId === this.needsTabApiCallId) {
      this.setState({ needsTabData: responseJson.data,pledgeLoading:false })
      
    }

    if (apiRequestCallId === this.pledgesTabApiCallId) {
      this.setState({ pledgesTabData: responseJson.data,pledgeLoading:false })
    }
    if (apiRequestCallId === this.fetchDbsDetailsId) {
      this.setState({ loader: false, dbsData: responseJson.data })
    }
    if (apiRequestCallId === this.fetchUserDbsDetailsId) {
      this.setState({
        loader: false, dbsUserData: responseJson?.data?.map((item: any) => {
          return item.id
        })
      })
    }
    if (apiRequestCallId === this.updateUserDBSDataApiCallId) {
      this.fetchUser('edit')
    }
    if(apiRequestCallId === this.addFavouriteAPICallId) {
      toast.success("Added to favourites!")
      this.apiSuccessResponse1(responseJson);
    }

    if(apiRequestCallId === this.removeFavouriteAPICallId) {
      toast.success("Removed from favourites!")
      this.handleCloseRemove(); 
      this.apiSuccessResponse1(responseJson);
    }
    if(apiRequestCallId === this.addToFavrApiId) {
      toast.success("Added to favourites!")
      this.apiSuccessResponse2(responseJson);
    }

    if(apiRequestCallId === this.removeFromFavApiId) {
      toast.success("Removed from favourites!") 
      this.apiSuccessResponse2(responseJson);
    }
  }

  handleAPIresponse2 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.activeNeedsTabApiCallId) {
      this.setState({ activeNeedsTabData: responseJson.data })
    }
    if (apiRequestCallId === this.pastNeedsTabApiCallId) {
      this.setState({ pastNeedsTabData: responseJson.data })
    }

    if (apiRequestCallId === this.deleteActiveNeedsTabApiCallId) {
      this.ActiveNeedsTabData();
      this.setState({ needModel: false })
    }
    if (apiRequestCallId === this.nonProfitAffiliationSeceletApiCallId) {
      let filteredDataSort:number[] = [];
      responseJson.filter((obj: AffiliatedOrg) => obj.registered === true).forEach((it: number) => filteredDataSort.push(it)) 
      responseJson.filter((obj: AffiliatedOrg) => obj.registered === false).forEach((it: number) => filteredDataSort.push(it));
      this.setState({ npoLoader: false, 
        nonProfitAffiliationData: filteredDataSort, 
        nonProfitAffiliationFilterData: filteredDataSort,
        selesctedCheckBox: responseJson.filter((obj: AffiliatedOrg) => obj.registered === true).map((it: AffiliatedOrg) => it.id) })
    }
    if (apiRequestCallId === this.saveNonProfitDataApiCallId) {
      this.nonProfitAffiliationSelectCheckBox();
    }
    if(apiRequestCallId === this.checkWorkEmailApiCallId) {
      if(responseJson.error){
        toast.error(responseJson.error)
      } else if(responseJson.message){
        toast.success(responseJson.message)
        this.handleUpdateProfile();
        setTimeout(() => {
          this.props.setBack();
        }, 1000);
      }
    }
  }

  handleAPIResponse5 = (apiRequestCallId: any, responseJson: any) => {
    if(apiRequestCallId === this.getActivityLogApiCallId) {
      if (responseJson.status === 500) {
        toast.error(`Something went wrong!`);
      } else if(responseJson.message) {
        this.setState({ pledgeLoading: false, tableData: [], filteredData: []})
      } else{
        this.setState({ 
          pledgeLoading: false, 
          tableData: responseJson.map((item:any) => {
            return {
              ...item,
              title: item.attributes.title,
              status: item.attributes.status,
              type: item.attributes.type,
              pledge_organisation: item.attributes.pledge_organisation,
              date: `${item.attributes.date_time ? (moment(item.attributes.date_time).format("DD/MM/YYYY HH:mm A")) : "--"}`,
            }
          }), 
          filteredData: responseJson.map((item:any) => {
            return {
              ...item,
              title: item.attributes.title,
              status: item.attributes.status,
              type: item.attributes.type,
              pledge_organisation: item.attributes.pledge_organisation,
              date: `${item.attributes.date_time ? (moment(item.attributes.date_time).format("DD/MM/YYYY HH:mm A")) : "--"}`,
            }
          })
        })
      }
    }
  }

  handleAPIresponse3 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.allConnectionUserModuleApiCallId) {
      this.setState({allConnectionModule: responseJson?.sort((a:any,b:any)=>a?.full_name.localeCompare(b?.full_name)), loader: false, connLoader: false })
      if(responseJson){
        this.setState({userconnections:[...this.state.userconnections,...responseJson],userloader:false })
      }
    }
    if (apiRequestCallId === this.connectedUserModuleApiCallId) {
      this.setState({ connectedModule: responseJson?.data?.sort((a:any,b:any)=>a?.attributes?.name.localeCompare(b?.attributes?.name)), loader: false, connLoader: false})
      if(responseJson?.data){
        this.setState({userconnections:[...this.state.userconnections,...responseJson?.data],userloader:false })
      }
    }
    if (apiRequestCallId === this.pendingRequestUserModuleApiCallId) {
      this.setState({ pendingRequestModule: responseJson?.data?.sort((a:any,b:any)=>a?.attributes?.name.localeCompare(b?.attributes?.name)), connLoader: false, loader: false})
      if(responseJson?.data){
        this.setState({userconnections:[...this.state.userconnections,...responseJson?.data],userloader:false })
      }
    }
    if (apiRequestCallId === this.awaitResponseId) {
      this.setState({ awaitResponseUsers: responseJson?.data?.sort((a:any,b:any)=>a?.attributes?.name.localeCompare(b?.attributes?.name)), loader: false, connLoader: false })
    }
    if (apiRequestCallId === this.followingUserModuleApiCallId) {
      this.setState({ followingModule: responseJson?.data, loader: false,followingLoader:false })
      
    }
    if (apiRequestCallId === this.registeredUserModuleApiCallId) {
      this.setState({ registeredModule: responseJson, registeredLoader: false })
    }
    if (apiRequestCallId === this.getUnfollowNpApiCallId) {
      this.allFollowingData();
      this.fetchUser('edit')
    }
    if(apiRequestCallId === this.getR1CurrentPointsApiCallId) {
      this.setState({ currentPoints: responseJson.data.current_point_blance, medal: responseJson.data.medal ?? "bronze"})
    }
    
  
  }

  handleAPIResponse4(apiRequestCallId: any, responseJson: any) {
    // Get user detail API response
    if (apiRequestCallId === this.fetchUserDataApiId) {
      this.setState({ ...this.state.apiUserDetail , connCount : responseJson?.data?.attributes?.connections?.connection_count});
    } else if (apiRequestCallId === this.pledgesTabApiId) {
      this.setState({ peldgesTabData: responseJson.data });
    } else if (apiRequestCallId === this.updateUserProfileDataApiCallId) {
      this.setState({ editProfileView: false });
      this.fetchUser('edit')
    } else if (apiRequestCallId === this.cancelConnId) {
      this.setState({ connLoader: false })
      responseJson?.meta ? (!this.state.rejectToast && toast.success('Request rejected successfully!')) : (!this.state.rejectToast && toast.error('Something went wrong'))
      this.setState({ rejectToast: true })
      this.awaitResponseUsers();
    } else if (apiRequestCallId === this.acceptConnId) {
      this.setState({ connLoader: false })
      !this.state.acceptToast && toast.success('Request accepted successfully!')
      this.setState({ acceptToast: true })
      this.awaitResponseUsers()
    }
    
      else if (apiRequestCallId === this.addConnectionRequestModuleApiCallId) {
      this.setState({ connectToast: true});
      this.allConnectionData();
      this.allConnectedData();
      this.allPendingRequestData();

    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.handleAllApiExtn(apiRequestCallId, responseJson);


      this.handleRemoveResponse(apiRequestCallId, responseJson);

      this.handleFavResponse(apiRequestCallId, responseJson);

      this.handleAPIresponse1(apiRequestCallId, responseJson);
      this.handleAPIresponse2(apiRequestCallId, responseJson);
      this.handleAPIresponse3(apiRequestCallId, responseJson);
      this.handleAPIResponse5(apiRequestCallId,responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleAPIResponse4(apiRequestCallId, responseJson)
        if (apiRequestCallId === this.getConnectionsId) {
          this.handleConnectionApiResponse(responseJson)
        }
        else if (apiRequestCallId === this.needsApiCallId) {
          this.openPublishModalhandler()
        }
      } else {
        this.setState({ activeNeedsTabData: [], connectedModule: [], pendingRequestModule: [], allConnectionModule: [], followingModule: [],registeredModule: [] })
      }
    }

  }

  async componentDidMount(): Promise<void> {
      this.allConnectedData()
      this.getConnections();
      this.fetchUser('none');
      this.fetchDbsDetails()
      this.fetchUserDbsDetails()
      this.PledgesTabData();
      this.ActiveNeedsTabData();
      this.nonProfitAffiliationSelectCheckBox();
      this.getR1CurrentPointBalance();

    this.allConnectionData();
    this.allPendingRequestData();
    this.awaitResponseUsers();
    this.allFollowingData();
    this.allRegisteredData();
    
      
  }
  componentDidUpdate(prevProps: any, prevState: any): any {
    if (prevProps.openModal !== this.props.openModal) {
      if (this.props.publish == "friends" && this.props.friends) {
        let arr = this.props.friends
        const userId = localStorage.getItem("acc_id");
        let selectedUser = this.state.connections.map((connection: any) => {
          const friendId = userId === connection.attributes.receipient_id.toString()
          ? connection.attributes.account_id
          : connection.attributes.receipient_id;
          if (arr.includes(friendId.toString())) {
            return connection.id
          }
        }).filter((id: any) => id)
        this.setState({ selectedUser })
      }
      else if (this.props.publish == "everyone") {
        this.setState({ publishAll: true })
      }
    }
  }

  updateDivWidth = () => {
    this.setState({ divWidth: this.divRef.current ? this.divRef.current.offsetWidth : 0 });
  };

  userDynamicProfilePicture() {
    return (
      this.state.apiUserDetail.data.attributes.profile_picture.url === null ? icons.profile : this.state.apiUserDetail.data.attributes.profile_picture.url
    )
  }

  userDynamicFullName() {
    return (
      this.state.apiUserDetail.data.attributes.full_name === null ? '---' : this.state?.apiUserDetail?.data?.attributes?.full_name
    )
  }

  userDynamicConnectionsCount() {
    return (
      this.state.apiUserDetail?.data?.attributes?.connections?.connection_count ? this.state.apiUserDetail?.data?.attributes?.connections?.connection_count : 0
    )
  }  

  userProfilePicture() {
    return (
      this.state.apiUserDetail.data === undefined ? icons.profile : this.userDynamicProfilePicture()
    )
  }

  userFullName() {
    return (this.state.apiUserDetail.data === undefined ? '---' : this.userDynamicFullName())
  }

  userAddress() {
    return ((this.state.apiUserDetail.data === undefined || this.state.apiUserDetail.data === null||this.state.apiUserDetail.data.attributes.city===null||this.state.apiUserDetail.data.attributes.country===null)? " " : `${this.state.apiUserDetail.data.attributes.city} , ${this.state.apiUserDetail.data.attributes.country}`)
  }

  userBio() {
    return (this.state.apiUserDetail.data === undefined ? constants.userProfile.bioDes : this.state.apiUserDetail.data.attributes.bio)
  }

  checkSettingsScreen = (isMobile: any) => {
    if (!isMobile) {
      if (this.state.divWidth < 400) {
        return true
      }
    } else {
      return false
    }
  }
  checkIsMobile = (isMobile: any) => {
    return isMobile ? true : false
  }
  isMobileDisplayNone = (isMobile: any) => {
    return isMobile ? "none" : "block"
  }


  onEventDelete = () => {
    const header = {
      // "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    let authToken: any = localStorage.getItem("token");

    const httpBody = {
      'token': authToken,
    }

    const deleteNeeds = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteNeedApiCallId = deleteNeeds.messageId;

    // deleteNeeds.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    deleteNeeds.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // `${configJSON.deleteNeedEndPoint}/5`
      "https://initiatefinalbuild-215298-ruby.b215298.dev.eastus.az.svc.builder.cafe/needs/:25"
    );

    deleteNeeds.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );


    deleteNeeds.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiType
    );

    runEngine.sendMessage(deleteNeeds.id, deleteNeeds);

    return true
  }
  // my need api integration start
  handleTitleOfNeed = (e: any) => {
    this.setState({ titleOfNeed: e.target.value })

  }

  handleLocation = (e: any) => {
    this.setState({ location: e.target.value })
  }

  handleDescribeNeeds = (e: any) => {
    this.setState({ describeNeeds: e.target.value, counter: e.target.value.length })
  }

  handleAdditionalInformation = (e: any) => {
    this.setState({ additionalInformation: e.target.value, count: e.target.value.length })
  }

  handleFromDate = (date: any) => {

    this.setState({ fromDate: date })
  }

  handleToDate = (date: any) => {
    this.setState({ toDate: date })
  }

  handleStartTime = (time: any) => {
    this.setState({ startTime: time })
  }

  handleFinishTime = (time: any) => {
    this.setState({ finishTime: time })
  }

  handleUploadImage = (e: any) => {
    if (e.target.files[0]) {
      this.setState({ uploadPic: e.target.files[0].name })
    }
  }

  handleNeedType = (e: any) => {
    this.setState({ needType: e.target.value })
  }
  handleChangeUpload = (event: any) => {
    const { name, value } = event.target;
    if(name === "work_email"){
        if(!this.checkValidEmail(value)) {
        this.setState({ workEmailError: "Invalid email address"})
     } else {
        this.setState({ workEmailError: ""})
      }
    }
    
    this.setState({
      profileUserData: {
        ...this.state.profileUserData,
        attributes: {
          ...this.state.profileUserData?.attributes,
          [name]: value
        }
      }
    });
  }


  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file.type === 'image/jpeg') {
      this.setState({ imageError: null })
      reader.onload = () => {
        const imageDataUrl = reader.result;
        this.setState({ profileImageSrc: imageDataUrl, updatedProfileImage: file });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({ imageError: "Please Upload .jpg Images Only" })
    }
  };

  handleEditView = (type: any) => {
    {
      this.setState({ editProfileType: type, nonProfitDisplay: !this.state.nonProfitDisplay })
    }
  }

  handleSearchOrganization(value: string) {

    let data
    if (value.length === 0) {
      data = this.state.nonProfitAffiliationData
    } else {
      data = this.state.nonProfitAffiliationData.filter((item: any) => {
        return item.organisation_name.toLowerCase().includes(value.toLowerCase())
      })
    }

    this.setState({
      searchKey1: value,
      nonProfitAffiliationFilterData: data
    });
  }

  checkValidEmail = (email: string) => {
    return this.emailReg.test(email);
  }


  checkWorkEmail = async (workEmail: string) => {
    // Check if workEmail is null, undefined, or empty
    if (!workEmail || workEmail.trim() === '') {
      workEmail = ''; // Set workEmail to an empty string
    }
  
    this.setState({ workEmailError: "" });
  
    const header = {
      "token": localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
  
    const body = {
      "work_email": workEmail,
      "account_id": Number(localStorage.getItem("acc_id"))
    };
  
    this.checkWorkEmailApiCallId = await apiCall({
      method: "POST",
      endPoint: `/account_block/accounts/add_work_email_address`,
      body: JSON.stringify(body),
      header: header
    });             
  }
  

  handleUpdateProfile = () => {
    let acc_id: any = localStorage.getItem("acc_id");
    let authToken: any = localStorage.getItem("token");

    const header = {
      'token': authToken,
    };

    const formData = new FormData();
    formData.append("full_name", this.state.profileUserData?.attributes?.full_name);
    if (this.state.updatedProfileImage !== null) {
      formData.append("profile_picture", this.state.updatedProfileImage);
    }
    formData.append("bio", this.state.profileUserData?.attributes?.bio);
    formData.append("work_email", this.state.profileUserData?.attributes?.work_email);
    formData.append("dbs_number", this.state.profileUserData?.attributes?.dbs_number);
    formData.append("account_id", acc_id);
    formData.append("street_name", this.state.profileUserData?.attributes?.street_name);
    formData.append("area", this.state.profileUserData?.attributes?.area);
    formData.append("city", this.state.profileUserData?.attributes?.city);
    formData.append("country", this.state.profileUserData?.attributes?.country);
    formData.append("post_code", this.state.profileUserData?.attributes?.post_code);
    formData.append("token", authToken);
    formData.append("show_company_logo", this.state.show_company_logo);

    const updateProfileData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserProfileDataApiCallId = updateProfileData.messageId;

    updateProfileData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    updateProfileData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateProfileData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileEndPoint
    );

    updateProfileData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(updateProfileData.id, updateProfileData);
  }
  handleUpdateDBSDetails = () => {
    let acc_id: any = localStorage.getItem("acc_id");

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      "account_id": parseInt(acc_id),
      "dbs_number": this.state.profileUserData?.attributes?.dbs_number,
      "dbs_type_ids": this.state.dbsUserData
    }


    const updateProfileData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserDBSDataApiCallId = updateProfileData.messageId;

    updateProfileData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    updateProfileData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    updateProfileData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDbsEndPoint
    );

    updateProfileData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(updateProfileData.id, updateProfileData);
  }
  myNeed = async () => {

    const header = {
      // "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      "need":
      {
        "title_of_need": this.state.titleOfNeed,
        "need_type": this.state.needType,
        "description": this.state.describeNeeds,
        "additional_information": this.state.additionalInformation,
        "start_date": this.state.fromDate,
        "end_date": this.state.toDate,
        "start_time": this.state.startTime,
        "end_time": this.state.finishTime,
        "location": this.state.location,
        "flexible": true,
        "need_photo": {
          "url": this.state.uploadPic
        },
        "account_id": localStorage.getItem("acc_id")
      },
      "token": localStorage.getItem("mobile_otp_token")
    }


    let acc_id: any = localStorage.getItem("acc_id");
    let authToken: any = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("need[title_of_need]", this.state.titleOfNeed);
    formData.append("need[need_type]", this.state.needType);
    formData.append("need[description]", this.state.describeNeeds);
    formData.append("need[additional_information]", this.state.additionalInformation);
    formData.append("need[start_date]", this.state.fromDate);
    formData.append("need[end_date]", this.state.toDate);
    formData.append("need[start_time]", this.state.startTime);
    formData.append("need[end_time]", this.state.finishTime);
    formData.append("need[location]", this.state.location);
    formData.append("need[flexible]", "true");
    formData.append("need[need_photo]", this.state.uploadPic);
    formData.append("need[account_id]", acc_id);
    formData.append("token", authToken);

    const needsData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.needsApiCallId = needsData.messageId;

    needsData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    needsData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    needsData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.needsEnterData
    );

    needsData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(needsData.id, needsData);
  }
  // my need api integration end

  fetchUser = (type: string) => {
    if (type == "edit") {
      this.setState({ editProfileView: false })
    }
    const acc_id = localStorage.getItem('acc_id')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchUserDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // account ID use 1  for testing purpose only.
      `${configJSON.getUserProfileApiEndPoint}?account_id=${acc_id}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchDbsDetails = () => {


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDbsDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // account ID use 1  for testing purpose only.
      configJSON.dbsTypesEndPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchUserDbsDetails = () => {

    let acc_id: any = localStorage.getItem("acc_id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchUserDbsDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // account ID use 1  for testing purpose only.
      `${configJSON.getSelectedDbsEndPoint}?account_id=${acc_id}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickOpenCategoryDilog = (selectedcategory: any) => {
    // this.setState({openMenu:false, showHam:false})
    if(isMobile){
      this.handleMobileNavigation(selectedcategory)
    }
    else{    
      this.handleWebNavigation(selectedcategory)
    
  }


   
  };

  categoryMenuCHange = (categorylabel: any) => {
    this.setState({search:"",selectedCategoryMenu: categorylabel })
    if (categorylabel === 'Connect') {    
      this.allConnectionData()
    }
    if (categorylabel === 'Connected') {
      this.allConnectedData()
    }
    if (categorylabel === 'Pending requests') {
      this.allPendingRequestData()
    }
    if (categorylabel === "Awaiting response") {
      this.awaitResponseUsers()
    }
    else {
      this.setState({ selectedCategoryMenu: categorylabel });
    }
    this.setState({connectionTab:categorylabel})
  };

  handleClose = () => {
    this.setState({ openstatusmodal: false });
  };

  // Need Tab API Integration

  NeedsTabData = () => {
    this.setState({pledgeLoading:true})
    const token =localStorage.getItem('token');
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "token":token
    };

    this.needsTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.needsTabDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Pledges Tab API Intetgration

  PledgesTabData = () => {
    this.setState({pledgeLoading:true})
    const acc_id = localStorage.getItem('acc_id');
    const token = localStorage.getItem('token')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "type": "user_entity",
      "token": token
    };

    this.pledgesTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pledgesTabDataEndPoint}?account_id=${acc_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDescriptionText = (string: string, limit: number) => {
    let dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }

    return string;
  }

  handleAllApiExtn(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getPledgedUser) {
      this.setState({ pledgedUserDetails: responseJson });
    }
  }

  handleRemoveResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.removeFavApiId) {
      this.getFavourites();
      this.handleCloseRemove()
    }
  }

  handleFavResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getFavouritesApiCallId) {
      this.setState({ loader: false, favData: responseJson.data })
    }
  }

  upComingEvents = () => {
    const getUpComingEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upComingEventsId = getUpComingEvents.messageId;

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upComingEventEndPoint
    );

    getUpComingEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getUpComingEvents.id, getUpComingEvents);
  }

  // Home dashboard past events
  pastEvents = () => {
    const getPastEvents = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pastEventsId = getPastEvents.messageId;

    getPastEvents.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pastEventEndPoint
    );

    getPastEvents.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPastEvents.id, getPastEvents);
  }

  // My Needs Tab- Active Needs API Integration

  ActiveNeedsTabData = () => {

    const acc_id = localStorage.getItem('acc_id');
    const token = localStorage.getItem('token');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.activeNeedsTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.activeNeedsTabEndPoint}?account_id=${acc_id}&token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  PastNeedsTabData = () => {

    const acc_id = localStorage.getItem('acc_id');
    const token = localStorage.getItem('token');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pastNeedsTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pastNeedsTabEndPoint}?account_id=${acc_id}&token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  DeleteActiveNeedsTabData = (id: any) => {
    const token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      token: token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteActiveNeedsTabApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteActiveNeedsTabEndpoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showEditProfile() {
    this.setState({ editProfileView: true })

  }

  handleOpenRemove(id: any) {
    this.setState({ removePopup: true, removeId: id })
  }

  handleCloseRemove() {
    this.setState({ removePopup: false, removeId: "" })
  }

  //favourites
  addToFavr = (e: any, item: any) => {
    this.setState({favNeedId:item.id});
    let token = localStorage.getItem("token")
    let type = ""
    if (item.type === "non_profit_event") {
      type = "BxBlockEvents::NonProfitEvent"
    }
    else {
      type = "BxBlockEvents::Need"
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const attrs = {
      data: {
        favouriteable_id: item.id,
        favouriteable_type: type
      },
      token: token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToFavrApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeFromFav = (item: any) => {
    this.setState({favNeedId:item.id
    })
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": localStorage.getItem("token"),};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeFromFavApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites/${item?.attributes?.favourite_id}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE");
    runEngine.sendMessage(requestMessage.id, 
      requestMessage
      );
    return true;
  }

  // Connections Models API Integration

  allConnectionData = () => {
    const token = localStorage.getItem('token');
    this.setState({ connectToast: false,connLoader:true,userloader:true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allConnectionUserModuleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.allConnectionModulesEndpoint}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  allConnectedData = () => {
    const token = localStorage.getItem('token');
    this.setState({connLoader:true,userloader:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectedUserModuleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.connectedModulesEndpoint}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  allPendingRequestData = () => {
    const token = localStorage.getItem('token');
    this.setState({connLoader:true,userloader:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pendingRequestUserModuleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pendingRequestModulesEndpoint}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  allFollowingData = () => {
    const token = localStorage.getItem('token');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followingUserModuleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followingResponseModulesEndpoint}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  unfollowNonProfitOrg = async (id:string) =>{
    const body = {
      "organisation_id": id,
      "type": 'user_account',
      "token": localStorage.getItem("token") 
    }
    this.getUnfollowNpApiCallId = await apiCall({
      body: JSON.stringify(body),
      endPoint: `bx_block_followers/unfollow`,
      method: "POST",
      contentType: "application/json"
    });
  }
  allRegisteredData = () => {
    this.setState({ registeredLoader: true });
    const token = localStorage.getItem('token');
 
    const header = {
      "token":token,
      "type":'user_entity',
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registeredUserModuleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.registeredModulesEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFavourites = () => {
    const token = localStorage.getItem('token');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFavouritesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemove = () => {
    let token = localStorage.getItem("token")

    const header = {
      "Content-Type": configJSON.dashboardContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFavApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites/${this.state.removeId}?token=${token}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addConnectionRequest = (id: string) => {
    this.setState({userconnections:[],userloader:true})
    const httpBody = {
      connection: {
        receipient_id: id
      },
      token: localStorage.getItem('token')
    }

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addConnectionRequestModuleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addConnectionModulesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  nonProfitAffiliationSelectCheckBox = () => {
    const token = localStorage.getItem('token');
    this.setState({
      npoLoader: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.nonProfitAffiliationSeceletApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.nonProfitAffiliationsEndPoint}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  handleNonProfits = (e: any, id: any) => {
    const { checked } = e.target;

    //@ts-ignore	
    if (checked) {
      if (this.state.selesctedCheckBox.length === 5) {
        this.setState({ orgError: 'Error Message' });
      } else {
        this.setState({ selesctedCheckBox: [...this.state.selesctedCheckBox, id], 
          payloadCheckBox: [...this.state.payloadCheckBox, id] });
      }
    } else {
      this.setState({ selesctedCheckBox: this.state.selesctedCheckBox.filter((it: number) => it !== id),
        payloadCheckBox: [...this.state.payloadCheckBox, id] });
    }

    const newState = this.state.nonProfitAffiliationFilterData.map((obj: any) =>
      obj.id === id ? { ...obj, registered: checked } : obj
    );

    this.setState({ orgError: null, nonProfitAffiliationFilterData: newState });

  };

  saveNonProfitData = (data: any) => {
    const httpBody = {
      token: localStorage.getItem('token'),
      organisation_ids: this.state.payloadCheckBox
    }

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveNonProfitDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveNonProfitData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  togglePublishUser = (userid: any) => {
    if (this.state.selectedUser.includes(userid)) {

      let selectedUser = this.state.selectedUser.filter((id: any) => id != userid)
      this.setState({ selectedUser })

    }
    else {
      let selectedUser = this.state.selectedUser.concat(userid)
      this.setState({ selectedUser })
    }


  }

  getConnections = () => {
    const token = localStorage.getItem('token');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getConnectionsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getconnectionApi}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleConnectionApiResponse = (responseJson: any) => {
    if (responseJson?.data) {
      let selectedUser: string[] = [];
      if(!this.props.id){
        selectedUser = responseJson.data.map((connection: { id: string; attributes: { user_post_code: PostCode; friend_post_code: PostCode; } }) => {
          if (connection.attributes.user_post_code === connection.attributes.friend_post_code) {
            return connection.id;
          }
        }).filter((id: string) => id);
      }
      const connections = responseJson.data.filter((connection: { id: string; attributes: { user_post_code: PostCode; friend_post_code: PostCode } }) => {
        if (connection.attributes.user_post_code === connection.attributes.friend_post_code) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({ selectedUser, connections });
    }
  }


  openPublishModalhandler = () => {
    this.setState({
      openPublishModal: true
    })
  }
  closePublishModalhandler = () => {
    this.setState({
      openPublishModal: false
    })
  }
  togglePublishAllBtn = () => {
    this.setState({
      publishAll: !this.state.publishAll
    })
  }
  mouseOverFnc = (e: any) => {
    e.target.style.backgroundColor = "#b1b1b1";
  }

  mouseOutFnc = (e: any) => {
    e.target.style.backgroundColor = COLORS.white;
  }
  handleDoneBtn = () => {
    this.setState({ disabled: true });
    if (this.state.selectedUser.length > 0) {
      this.props.onSubmit("friends", this.state.selectedUser)
    }
    else if (this.state.publishAll) {
      this.props.onSubmit("everyone", [])
    }
    else {
      this.props.onSubmit("circle", [])
    }
  }

  handleTabClick = (type: any) => {
    this.setState({ selectedNeedsTabChange: type });
    if (type === 'Publish') {
      this.props.navigation.navigate("PublishNeed");
    } else if (type === 'Past') {
      this.PastNeedsTabData();
    } else {
      this.ActiveNeedsTabData();
    }
  };

  awaitResponseUsers() {
    this.setState({connLoader:true})
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: token
    };
    const getAwaitREsponseUsers = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.awaitResponseId = getAwaitREsponseUsers.messageId;

    getAwaitREsponseUsers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAwaitREsponseEndPoint
    );

    getAwaitREsponseUsers.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getAwaitREsponseUsers.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(getAwaitREsponseUsers.id, getAwaitREsponseUsers);

  }

  cancelConnRequest(id: any) {
    const token = localStorage.getItem('token');
    this.setState({connLoader : true})

    const header = {
      "Content-Type": configJSON.dashboardContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelConnId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_add_friends/decline_connection?account_id=${id}&token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  acceptConnRequest(id: any) {
    const token = localStorage.getItem('token')

    this.setState({ connLoader: true })

    const httpBody = {
      account_id: id,
      token: token
    }

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptConnId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptConnReqEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addPledgeFavourite = (id: number, type:string) => {
    this.setState({favPledgeId:id})
    const token = localStorage.getItem('token');
  
    const bodyToPass = {
      "token": token,
      "data": {
        "favouriteable_type": type === "R1" ? "BxBlockEvents::Need" : "BxBlockEvents::NonProfitEvent",
        "favouriteable_id": Number(id),
      } 
    }

    const header = {
      "Content-Type":  "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFavouriteAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyToPass)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_favourites/favourites"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removePledgeFavourite = ( item:any) => {
    this.setState({favPledgeId:item.id})
    const token = localStorage.getItem('token');
   
    const objToPass = {
      "token": token
    }

    const header = {
      "Content-Type":  "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFavouriteAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites/${item.attributes.favourite_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(objToPass)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMobileNavigation=(selectedcategory:any)=>{
    if(selectedcategory === 'My Connections'){
      this.props.navigation.navigate("UserMyconnections")
    }
   else if (selectedcategory === 'Connections') {
    this.props.navigation.navigate("UserConnections")
    }
   else if (selectedcategory === 'Following') {
    this.props.navigation.navigate("UserFollowing")
    } 
    else if (selectedcategory === 'My Favourites') {
      this.props.navigation.navigate("UserFavourites")
      } 
      else if (selectedcategory === 'My Task Preferences') {
        this.props.navigation.navigate("MyTaskPreferenceweb", { id: "edit" })
        } 
        else if (selectedcategory === 'My Availability') {
          this.props.navigation.navigate("Availability", { id: "edit" })
          } else if(selectedcategory === 'Registered') {
            this.props.navigation.navigate("UserRegistered");
          }
  }

  handleWebNavigation=(selectedcategory:any)=>{
    this.setState({search:"",userconnections:[]})
      if (selectedcategory === 'My Connections') {
        this.setState({
          openstatusmodal: true,
          selectedCategoryStatus: selectedcategory,
          selectedCategoryMenu:'Awaiting response',
        });
      }
      if (selectedcategory === 'Following') {
        this.allFollowingData()
        this.setState({
          openstatusmodal: true,
          selectedCategoryStatus: selectedcategory,
        });
      }
  
      if (selectedcategory === 'Registered') {
        this.allFollowingData()
        this.setState({
          openstatusmodal: true,
          selectedCategoryStatus: selectedcategory,
        });
      }
      if (selectedcategory === 'My Favourites') {
        this.getFavourites();
        this.setState({
          openstatusmodal: true,
          selectedCategoryStatus: selectedcategory,
        });
      }
  
      if (selectedcategory === 'My Availability') {
  
        this.setState({
          openstatusmodal: true,
          selectedCategoryStatus: selectedcategory,
        });
      }
  
      if (selectedcategory === 'My Task Preferences') {
        this.setState({
          openstatusmodal: true,
          selectedCategoryStatus: selectedcategory,
        });
      }
      if (selectedcategory === 'Connections') {
        this.allConnectionData();
        this.allPendingRequestData();
        this.allConnectedData();
        this.setState({
          openstatusmodal: true,
          selectedCategoryStatus: selectedcategory,
          selectedCategoryMenu:'Connected',
        });
      }
    
  }

  getR1CurrentPointBalance = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getR1CurrentPointsApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_gamification/donate_points/r1_rewarded_points`,
      header: header
    });
  }
  filterReractor=(user:any)=>{
    return user?.attributes?.name ?
    user.attributes.name.toLowerCase():
    user?.full_name.toLowerCase()
  }
  getFilteredResult=(data:any)=>{
    const result= data?.filter((user: any) => !this.state.search ?
    true:
    this.filterReractor(user).includes(this.state.search.toLowerCase())
    );
             return result
   }

  getActivityLog = async () => {
    const header = {
      "token": localStorage.getItem("token"),
      "type": "user_entity"
    }
    this.getActivityLogApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_profile/user_activity_logs`,
      header: header
    });
  }

  handleSearchClick = (search: string) => {
    const { filteredData } = this.state;

      let tableData = filteredData.filter((item: any) => {
        if (search === "") {
          return true;
        } else if (
          item.title?.toString().toLowerCase().includes(search.toLowerCase()) ||
          item.type?.toString().toLowerCase().includes(search.toLowerCase()) ||
          item.status?.toString().toLowerCase().includes(search.toLowerCase()) ||
          item.pledge_organisation?.toString().toLowerCase().includes(search.toLowerCase()) ||
          item.date?.toString().toLowerCase().includes(search.toLowerCase()) ||
          item.time?.toString().toLowerCase().includes(search.toLowerCase())
        ) {
          return item;
        }
      });
      this.setState({ tableData });
    }



  // Customizable Area End
}
