import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  cakeIcon,
  schooldropIcon,
  schoolpickIcon,
  VetIcon,
  RentIcon,
  GetTogetherIcon,
  MeetingIcon,
  DoctorIcon,
  GymIcon,
  FlightIcon,
  OutforCoffeeIcon,
  CustomIcon,
  ActivityIcon,
  FeesIcon,
} from "./assets";
import scale from "../../../components/src/Scale";
import { icons } from "../../../components/src/constants";

export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  children: React.ReactNode | Element;
  showBackButton: boolean;
  title: string;
  showBottomBar: boolean;
  openDilog: () => void;
  selectedevents?: any;
  classes: any;


  // Customizable Area End
}

interface S {
  // Customizable Area Start
  flatlistdata: any;
  isShowMap: boolean;
  openEventsDilog: boolean;
  isReadMore: boolean;
  state: any;
  setTabValue: any;
  pledgesDelete: boolean;
  pledgesActive: boolean;
  pledgesPast: boolean;
  amountSliderValue: any;
  openevents: any,
  setViewPladges: any,
  setPastPladges: any,
  setActivePladges: any;
  errorsuccessmesg: any;
  eventactionState: any;
  project_journey: any;
  selectedMedia: any;
  selectedMediaType: any;
  selectedMediaContent: any;
  msgModal: any;
  commentmodalText: any;
  activePledgesTabData: any;
  pastPledgesTabData: any;
  deleteActivePledgeId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
export default class EventsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiPhoneLoginCallId: any;
  apicreateEventsApiCallId: string = "";
  viewPladgesApiCallId: string = "";
  pledgesPastTabDataApiCallId: string = "";
  pledgesActiveTabDataApiCallId: string = "";
  activePledgesApiCallId: string = "";
  pastPledgesApiCallId: string = "";
  deletePledgesApiCallId: string = "";
  addToFavsApiId: string = "";
  private readonly errorTitle = "Error";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      pledgesActive: true,
      amountSliderValue: 500,
      pledgesPast: false,
      pledgesDelete: false,
      setTabValue: 0,
      isShowMap: false,
      state: 0,
      commentmodalText: "",
      flatlistdata: [
        {
          leftMargin: scale(7),
          image: cakeIcon,
          buttonTitle: "Birthday",
        },
        {
          leftMargin: scale(11),
          image: schooldropIcon,
          buttonTitle: "School Drop Off",
        },
        {
          leftMargin: scale(12),
          image: schoolpickIcon,
          buttonTitle: "School Pick Up",
        },
        {
          leftMargin: scale(9),
          image: GetTogetherIcon,
          buttonTitle: "Get Together",
        },
        {
          leftMargin: scale(8),
          image: MeetingIcon,
          buttonTitle: "Meeting",
        },
        {
          leftMargin: scale(7),
          image: OutforCoffeeIcon,
          buttonTitle: "Out for Coffee",
        },
        {
          leftMargin: scale(9),
          image: GymIcon,
          buttonTitle: "Gym",
        },
        {
          leftMargin: scale(11),
          image: FlightIcon,
          buttonTitle: "Flight",
        },
        {
          leftMargin: scale(9),
          image: DoctorIcon,
          buttonTitle: "Doctor",
        },
        {
          leftMargin: scale(8),
          image: VetIcon,
          buttonTitle: "Vet",
        },
        {
          leftMargin: scale(8),
          image: RentIcon,
          buttonTitle: "Rent",
        },
        {
          leftMargin: scale(5),
          image: FeesIcon,
          buttonTitle: "Fees",
        },
        {
          leftMargin: scale(9),
          image: ActivityIcon,
          buttonTitle: "Activity",
        },
        {
          leftMargin: scale(10),
          image: CustomIcon,
          buttonTitle: "Custom",
        },
      ],
      openEventsDilog: false,
      isReadMore: true,
      openevents: false,
      setViewPladges: [],
      setPastPladges: {},
      setActivePladges: {},
      errorsuccessmesg: "",
      eventactionState: {},
      project_journey: false,
      selectedMedia: 3,
      // selectedMediaType: "speaker",
      selectedMediaType: "video",
      selectedMediaContent: "",
      msgModal: false,
      activePledgesTabData: [],
      pastPledgesTabData: [],
      deleteActivePledgeId: '',
      // Customizable Area End
    };
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getViewPladges();
    this.getActivePledges()
    this.setState({
      selectedMedia: this.props.selectedevents?.attributes.posts[0].id,
      selectedMediaType: this.props.selectedevents?.attributes.posts[0].type, selectedMediaContent: this.props.selectedevents?.attributes.posts[0].url
    })
    this.ActivePledgesTabData();
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
  if (apiRequestCallId === this.apicreateEventsApiCallId) {
    console.log(responseJson, "responseJson of create events");
    if (responseJson?.data) {
      this.getViewPladges();
    } else {
      console.log(responseJson?.error);
    }
  } else if (
    apiRequestCallId === this.viewPladgesApiCallId ||
    apiRequestCallId === this.pledgesPastTabDataApiCallId ||
    apiRequestCallId === this.pledgesActiveTabDataApiCallId
  ) {
    if (responseJson?.data) {
      this.setState({ setViewPladges: responseJson.data });
    } else {
      this.setState({ setViewPladges: "" });
    }
  }

  if (responseJson !== null) {
    this.handleAllAPIresponse(apiRequestCallId, responseJson);
  }

    // Customizable Area End

  }

  // Customizable Area Start


  handleAllAPIresponse = (apiRequestCallId: any, responseJson: any) => {

    if (apiRequestCallId === this.apicreateEventsApiCallId) {
        this.getViewPladges()
    }
    if (apiRequestCallId === this.viewPladgesApiCallId) {

        this.setState({ setViewPladges: responseJson.data });
      
    } 
    if (apiRequestCallId === this.activePledgesApiCallId) {
        console.log(responseJson, 'response data result')
        this.setState({ activePledgesTabData: responseJson.data });

    } 
    if (apiRequestCallId === this.pastPledgesApiCallId) {

        this.setState({ pastPledgesTabData: responseJson.data });

    } 
    if (apiRequestCallId === this.deletePledgesApiCallId) {

        this.ActivePledgesTabData();
        this.setState({ pledgesDelete: false });
    }

  }
  // range slider 
  handleChange = (event: any, amountSliderValue: any) => {
    this.setState({ amountSliderValue });
  };

  donatevaluetext(donatevalue: number) {
    return `£ ${donatevalue}`;
  }


  
  check_event_type_wise_navigation_title = (type: any) => {
    const navigationTitles: any = {
      need: "Event Details",
      project_funding: "Funding Campaign",
      activity: "Activity Details",
      update: "Update",
      project_Journey: "Project Journey",
    };

    return navigationTitles[type] || "";
  }

  check_event_type_wise_cancellation_action = (type: any) => {
    if (type === "need") {
      return "Cancel Pledge";
    } else if (type === "project_funding") {
      return "Cancel Fund";
    } else {
      return "Cancel Attend";
    }
  }


  check_event_type_wise_modal_action = (type: any, actionState: any) => {
    if (actionState === "after") {
      return { action_name: "Confirm Cancellation", modalmessage: "You are cancelling your reservation at this upcomming activity" }
    } else if (actionState === "before" && type === "need") {
      return { action_name: "Confirm Pledge", modalmessage: "Thanks for option to help on this event. Your Pledge will be submitted to the event host for approval" }
    } else if (actionState === "before" && type === "activity") {
      return { action_name: "Confirm Attend", modalmessage: "Thanks for option to help on this event. Your Pledge will be submitted to the event host for approval" }
    } else {
      return { action_name: "Confirm Donate", modalmessage: "Thanks for option to help on this event" }
    }
  }

  check_event_type_wise_modal_action_message = (type: any) => {
    if (this.state.eventactionState._action === "after") {
      return { action_name: "Confirm Cancellation", modalmessage: "You are cancelling your reservation at this upcomming activity" };
    } else if (type === "project_funding") {
      return {
        action_name: "Confirm Cancellation",
        modalmessage: "Thanks for option to help on this event. Your Pledge will be submitted to the event host for approval"
      };
    } else {
      return "Confirm Donate";
    }
  }
 


  check_event_type_wise_actions = (type:any) => {
    const typeActions:any = {
      need: "Pledge To Help",
      project_funding: "Donate",
      activity: "Attend",
      update: "Update",
    };
  
    return typeActions[type] || "Pledge To Help";
  };


  // modal functionality
  handleClickOpen = (_action: any, _action_type: any) => {
    this.setState({ openEventsDilog: true, eventactionState: { "_action": _action, "_action_type": _action_type } });
  };

  handleClose = () => {
    this.setState({ openEventsDilog: false });
  };

  handleMsgClose = () => {
    this.setState({ msgModal: false })
  }

  handlePastPledgeTabBtn = () => {
    this.setState({
      pledgesActive: false,
      pledgesPast: true,
    });
    this.getPastPledges()
  }

  handleActivePledgeTabBtn = () => {
    this.setState({
      pledgesActive: true,
      pledgesPast: false,
    });
    this.getActivePledges()
  }

  // on Confirm Pladges Click
  onConfirmPladgesClick = (non_profit_event_id: any) => {
    const acc_id = localStorage.getItem("acc_id");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };



    const httpBody = {
      pledge: {
        non_profit_event_id: non_profit_event_id,
        account_id: acc_id
      }
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apicreateEventsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmPladgespApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPOSTMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  // Home dashboard past events
  getViewPladges = () => {
    const getviewPladges = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewPladgesApiCallId = getviewPladges.messageId;

    getviewPladges.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ViewPladgespApiEndPoint}?non_profit_event_id=${this.props.selectedevents?.id}`
    );

    getviewPladges.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getviewPladges.id, getviewPladges);
    console.log("getviewPladges", getviewPladges)
    console.log("runEngine", runEngine)

  }

  getPastPledges = () => {
    const getPastPledges = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pledgesPastTabDataApiCallId = getPastPledges.messageId;

    const httpBody = {
      account_id: '3',
    };

    getPastPledges.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getPastPledges.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pledgesPastTabDataEndPoint}?account_id=3`
    );

    getPastPledges.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPastPledges.id, getPastPledges);
  }

  getActivePledges = () => {
    const getActivePledges = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pledgesActiveTabDataApiCallId = getActivePledges.messageId;

    const httpBody = {
      account_id: '3',
    };


    getActivePledges.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pledgesActiveTabDataEndPoint}?account_id=3`
    );

    getActivePledges.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    getActivePledges.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getActivePledges.id, getActivePledges);
  }

  selectedImageIcons = (selectedIconsMedia: any) => {
    if (selectedIconsMedia === "image") {
      return icons.camerablue;
    } else if (selectedIconsMedia === "video") {
      return icons.videoblue;
    } else if (selectedIconsMedia === "chats") {
      return icons.chatsblue;
    } else {
      return icons.speakerblue
    }

  }

  ActivePledgesTabData = () => {

    const acc_id = localStorage.getItem('acc_id')

    const activePladges = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      "account_id":441
    };

    this.activePledgesApiCallId = activePladges.messageId;

    activePladges.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `my_pledges/active_pledges`
    );

    activePladges.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );


    activePladges.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(activePladges.id, activePladges);
  }

  PastPledgesTabData = () => {

    const acc_id = localStorage.getItem('acc_id')

    const pastPladges = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pastPledgesApiCallId = pastPladges.messageId;

    pastPladges.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pastPledgesApiEndPoint}?account_id=${acc_id}`
    );

    pastPladges.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(pastPladges.id, pastPladges);
  }

  addToFavs = (e:any,item:any) => {
    let token = localStorage.getItem("token")
    let type =""
    if(item.type==="non_profit_event"){
    type="NonProfitEvent"
    }
    else{
      type="Need"
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const attr = {data:{
      favouriteable_id:item.id,
      favouriteable_type:type
    },
      token:token
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToFavsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attr)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  DeleteActivePledgeTab = (id: any) => {
    console.log(id, 'delete it')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePledgesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelPladgespApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleDescriptionText = (string: string, limit: number) => {
    let dots = "...";
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }

    return string;
  }

  loadMediaContent = (type: any, content: any) => {
    this.setState({ selectedMediaType: type, selectedMediaContent: content })
  }
  onClickModalSubmit = (param1: any, param2: any, param3: any) => {
    console.log(param1, param2, param3)
  }

  // Customizable Area End
}


