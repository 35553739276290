// Customizable Area Start
import React from "react";


import {Box, Grid, Button } from "@material-ui/core";
import { logo } from "./assets";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import './LandingPageUserFlow.web.css';
import { FAMILYFONTS } from "../../../components/src/constants";
import { isMobile } from "react-device-detect";

// Customizable Area End

class LandingPageUserFlow extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Grid container style={{backgroundColor: '#fff'}}>
          <Grid className="mainContainer">      
            <Box className="landingPagelogo">
              <img className="logoImg" src={logo} alt="logo" />
            </Box>
            {isMobile ? null : 
            <Box className="buttonContainer">
              <Box className="landingPageDarkButton">
                <Button
                  type="submit"
                  style={{
                    ...FAMILYFONTS.family1,
                  }}
                  onClick={this.handleSignInNavigation}
                  data-testid="signIn"
                >
                  Sign In
                </Button>
              </Box>
              <Box className="landingPageLightButton">
                <Button
                  type="submit"
                  style={{
                    ...FAMILYFONTS.family1,
                  }}
                  onClick={this.handleSignUpNavigation}
                  data-testid="signUp"
                >
                  Register
                </Button>
              </Box>
            </Box>
            }
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
  export default LandingPageUserFlow;
  export {LandingPageUserFlow};
// Customizable Area End
