// Customizable Area Start
import React, { useState } from 'react';
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography, Button } from '@material-ui/core';
import { icons } from "../../../components/src/constants";
import { InitiateButton } from '../../../components/src/design-system/button';
import { VerticalSpacer } from '../../../components/src/shared/vertical-spacer';
import { useNavigate } from '../../../components/src/utils/router-context';
import { MonthDatePicker } from './MonthDatePicker';
import { EventPickerModal } from './EventPickerModal';
import { DaysLine } from './DaysLine';
import { HourLinesContainer ,apiCallBack} from './HoursLines';
import { RegisterationMobileHeader } from './RegisterationMobileHeader';
import { useIsMobileScreen } from '../../../components/src/utils/is-mobile';
import { HideContainer } from '../../../components/src/shared/hide-container';
import clsx from 'clsx';
import CustomSuccessModel from '../../../components/src/custom/CustomSuccessModel';
import { submitHandlerCallBack ,useDates} from './EventPicker';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../../../components/src/api/keys/user.keys';
import {format} from "date-fns";


const useStyles = makeStyles((theme) => ({
  formContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'min(670px, 80vh)',
    [theme.breakpoints.down('sm')]: {
      height: '75%',
      marginLeft: '-6.6%',
    },
  },
  formContent: {
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft:'9.6%',
    padding: '3px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'max-content',
    },
  },
  root: {
    position: 'relative',
    maxWidth: '500px',
    boxSizing: 'border-box',
    '*': {
      boxSizing: 'border-box',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90vw',
    },
  },
  monthDatePickerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft:'5%',
    },
  },
  filterIcon: {
    height: '24px', width: '24px',
  },
  titleText: {
    fontSize: '1.8rem',
    fontFamily: 'Rene_Bieder_Milliard_SemiBold',
    textAlign: 'center',
  },
  submitButton: {
    fontSize: '1.3rem',
  },
  skipButton: {
    fontSize: '1.3rem',
    textTransform: 'capitalize',
  },
  submitButtonsContainer: {
    paddingLeft:'10%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#019be1'
    }
  }
});

export function AvailabilityForm({
  hideSkipButton,
  submitButtonTitle,
  preventNavigationOnSuccess,
  popup,
  close
}: {
  hideSkipButton?: boolean,
  submitButtonTitle?: string,
  preventNavigationOnSuccess?: boolean,
  popup?:boolean,
  close?:any,
}) {

  const classes = useStyles();

  
  const [filterByDate, setFilterByDate] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);

  const isMobile = useIsMobileScreen();
  const navigate = useNavigate();
  const [openSuccessModal, setSuccessModel] = useState(false)
  const currentUrl = window.location.href.split('/');
  const key = currentUrl[currentUrl.length - 1];
  const isEdit = key === "edit";
  const disable = key === "SettingsAvailibility" ;
  const {
    fromDate,
    toDate
  } = useDates(filterByDate);
  const queryClient = useQueryClient();
  function submitHandler() {
    localStorage.setItem("user_selected_date",'false');
    localStorage.setItem("all_day","false");
    submitHandlerCallBack();
    const apiCall = async ()  =>{ 
      try {
        await queryClient.invalidateQueries(userKeys.fetchAvailabilitiesOfDay({ date: format(new Date(fromDate), 'dd/MM/yyyy') }))
        await queryClient.invalidateQueries(userKeys.fetchAvailabilitiesOfDay({ date: format(new Date(toDate), 'dd/MM/yyyy') }));
      } catch (error) {
        console.error(error);
        apiCall();
      }
    }
    if (!preventNavigationOnSuccess && !isEdit && !disable){
      navigate('/AccountVerifiedSuccessweb');
    } else {
      apiCall();
      setSuccessModel(true) 
    }  
  }

  function handleSuccessModalClose() {
    setSuccessModel(false)
    apiCallBack();
    if(popup){
      close();
    }
  }
  
  function skipHandler() {
    navigate('/AccountVerifiedSuccessweb');
  }



  return (
    <>
<MuiThemeProvider theme={theme}>
      <div id="availability-form" className={clsx(classes.root, classes.formContentRoot)} >
        <HideContainer hideWhen={isMobile}>
          <Typography
            variant="h1"
            color="primary"
            className={classes.titleText}
          >
            { !preventNavigationOnSuccess &&  'Availability'}
          </Typography>
          <VerticalSpacer />
        </HideContainer>

        <div className={classes.formContent}>
          <div className={classes.monthDatePickerContainer} style={{position:"relative",top:10}}>
            <MonthDatePicker
              date={filterByDate}
              setDate={setFilterByDate}
            />
            <IconButton aria-label="filter-date" onClick={() => {setModalOpen(true);
            localStorage.setItem("user_selected_date","true");
            localStorage.setItem('all_day','false');
            }}>
              <img src={icons.filter} className={classes.filterIcon} />
            </IconButton>
          </div>
         
          <EventPickerModal
            filterByDate={filterByDate}
            open={modalOpen}
            onClose={() => setModalOpen(false)
            } />

          <VerticalSpacer />

         
          <DaysLine
            onChange={setFilterByDate}
            selectedDate={filterByDate} />
         <VerticalSpacer />
          <HourLinesContainer filterByDate={filterByDate} />
          
          
        </div>
        <div className={classes.submitButtonsContainer}>
          <InitiateButton
            data-testId="submit-btn"
            className={classes.submitButton}
            onClick={submitHandler}
            fullWidth
            color="primary"
            variant="contained"
            disabled={localStorage.getItem("set_button")==='false'?true:false}
            size="large">
            {submitButtonTitle || 'Continue'}
          </InitiateButton>
          <HideContainer hideWhen={isMobile || hideSkipButton}>
            <Button
              className={classes.skipButton}
              fullWidth
              color="primary"
              variant="text"
              onClick={skipHandler}
              size="large">
              {'Skip'}
            </Button>
          </HideContainer>
        </div>

      </div>

      <CustomSuccessModel openSuccessModal={openSuccessModal} handleSuccessModalClose={handleSuccessModalClose} msgTitle='Thank you' msg="Changes has been updated" />

      <RegisterationMobileHeader
        title="My Availability"
        hideSkipButton={isEdit|| disable}
        onSkip={skipHandler}
      />
      </MuiThemeProvider>
    </>
  );
}

// Customizable Area End
