 {/* Customizable Area Start */}
export const SET_SELECTED_FILTER_EVENTS = 'SET_SELECTED_FILTER_EVENTS'


export const setSelectedFilterEventSuccess= (selectedEvents: any) => ({
    type:SET_SELECTED_FILTER_EVENTS,
    payload: {selectedEvents}
})

export function setSelectedFilterEvents(selectedEvents: any){
  
    return (dispatch: (arg0: { type: string; payload: { selectedEvents: any } }) => void) => {
        dispatch(setSelectedFilterEventSuccess(selectedEvents))
        
    }
}


 {/* Customizable Area End */}