// Customizable Area Start
// @ts-nocheck
import React from "react";
import PropTypes from 'prop-types';
import BusSubscriptionPlanController from "./BusSubscriptionPlanController.web"
import BusDashbaordWeb from "./BusDashboard.web";
// @ts-ignore
import styles from "./BusChangeSubscription.module.css";
import { icons } from "../../../components/src/constants";
import {
    Box,
    Typography,
    Container,
    IconButton,
    withStyles,
    Switch,
    createStyles,
    CircularProgress,
    Theme
} from '@material-ui/core'
import CancelModel from "../../../components/src/custom/CancelSubscriptionModal.web";
import { styled } from "@material-ui/styles";
import CheckIcon from '@material-ui/icons/Check';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from "react-router-dom";

export const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
        },
    }),
)(LinearProgress);

export const AntSwitch: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 32,
            height: 18,
            padding: 0,
            display: "flex",
            margin: '3px 10px 0 0',
        },
        switchBase: {
            padding: 2,
            color: "white",
            "&$checked": {
                transform: "translateX(14px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: "#359ce2",
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 13,
            height: 13,
            boxShadow: "none",
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 18 / 2,
            opacity: 1,
            backgroundColor: "grey",
        },
        checked: {},
    })
)(Switch);

class BusChangeSubscription extends BusSubscriptionPlanController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }


    remainingDaysProgressBar(days: any) {
        if (days < 30) {
            return `${days} days left`
        } else {
            let months = 0, day = 0
            while (days) {
                if (days >= 30) {
                    months++;
                    days -= 30;
                } else {
                    day++;
                    days--;
                }
            }
            if (day === 0) {
                return `${months} months left`
            } else {
                return `${months} months and ${day} days left`
            }
        }
    }

    calculateTimeProgress(remainingDays, totalDays) {
        const progressPercentage = (remainingDays / totalDays) * 100
        return parseInt(progressPercentage)
    }

    calculateEmpProgressBar(empCount,totalEmpCount) {
        const planName = localStorage.getItem('plan');
        if(empCount>=totalEmpCount) return 100;
        if ((planName === "Trial-Business")|| empCount===0) {
            return 1;
        } else {
            const progressPercentage = (empCount / totalEmpCount) * 100
            return progressPercentage 
        }
    }

    employmentCount(text: string) {
        if ((text === "Trial-Business") || (text === "Full Business")) {
            return ""
        } else if ((text === "Supported Business")) {
            return "out of 100"
        } else {
            return "out of 50"
        }
    }

    render() {
        return (
            <div >
                {/* @ts-ignore */}
                <BusDashbaordWeb children={this.props.children} classes={this.props.classes} navigation={this.props.navigation} id={""} adminSelectedTab={undefined} theme={undefined}
                >
                    <Container maxWidth="xl" style={{ marginLeft: 280, padding: 50, width: 'auto' }}>
                        <Wrapper style={{ padding: 25, background: 'white', borderRadius: '12px' }}>
                            {this.state.loader ? <div
                                style={{
                                    height: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress style={{ color: "#009be2" }} />
                            </div> :
                                <Box>
                                    <Box display="flex" alignItems="center">
                                        <IconButton
                                            data-testId='IconBtn'
                                            onClick={() => this.props.navigation.goBack()}
                                        >
                                            <img src={icons.back} />
                                        </IconButton>
                                        <Typography className="page-title">Business subscriptions</Typography>
                                    </Box>
                                    <Box mt='15px' mb={"30px"} ml={'15px'}>
                                        <Typography className="current-plan">Current Plan</Typography>
                                    </Box>
                                    <Box ml={'15px'} className="plan-details">
                                        <Box className="main-card">
                                            <div className={styles.firstBox}>
                                                <div className={styles.planText}>Plan</div>
                                                <div className={styles.planType}>{this.handlePlanName(this.state.currentPlanInfo?.subscription?.data?.attributes?.name)}</div>
                                            </div>
                                            <div className={styles.firstBox}>
                                                <div className={styles.planText}>Paymnet</div>
                                                <div className={styles.planType}>£ {this.state.currentPlanInfo?.subscription?.data?.attributes?.price} <span className={styles.planText}>Per Quarter</span></div>
                                            </div>
                                            <Box style={{ display: 'flex', verticalAlign: 'middle', marginTop: 35, marginRight: 60 }}>
                                                <div className={styles.changePlan} data-testId={"changeBtn"} onClick={() => this.navigateToPlanChangePage()}>Change Plan</div>
                                                <div className={styles.cancelPlan} data-testId={"cancelBtn"} onClick={() => this.setState({ openSuccessModal: true })}>Cancel Subscription</div>
                                            </Box>
                                        </Box>
                                        <div className={styles.subscriptionDaysText}>Subscription days</div>
                                        <BorderLinearProgress className={styles.progressDaysBar} variant="determinate" value={this.calculateTimeProgress(this.state.currentPlanInfo?.remaining_days, this.state.currentPlanInfo?.subscription?.data?.attributes?.valid_for_days)} />
                                        <Box className={styles.infoText}>{this.remainingDaysProgressBar(this.state.currentPlanInfo?.remaining_days)}</Box>
                                        {this.handlePlanName(this.state.currentPlanInfo?.subscription?.data?.attributes?.name) !== "Trial-Business" &&
                                            <Box style={{ display: 'flex', marginLeft: 40 }}>
                                                <AntSwitch data-testId='autoRenewSwitch' checked={this.state.autoPay} onChange={() => this.autoPaySubscription()} />
                                                <div className={styles.autoRenewtext}>Auto-renew every 3 months</div>
                                            </Box>}

                                        <div className={styles.subscriptionDaysText}>Plan details</div>
                                        {this.state?.currentPlanInfo?.descriptions?.map((description: string, index2: number) => (
                                            <Box display="flex" mt="15px" mb="15px" key={`note-${index2}`}>
                                                <CheckIcon className="check" />
                                                <Typography className="description"> {description}</Typography>
                                            </Box>
                                        ))}
                                        <div className={styles.subscriptionDaysText}>Verified employees</div>
                                        <BorderLinearProgress className={styles.progressEmployeeBar} variant="determinate" value={this.calculateEmpProgressBar(this.state.currentPlanInfo?.registered_employees,this.state.currentPlanInfo?.can_affiliate_upto)} />
                                        <Box className={styles.infoText}>{this.state.currentPlanInfo?.registered_employees} employees verified their employment {localStorage.getItem("plan")==="Trial-Business"?"":`out of ${this.state.currentPlanInfo?.can_affiliate_upto}`}</Box><br /><br />
                                    </Box>
                                </Box>}
                        </Wrapper>
                    </Container>
                </BusDashbaordWeb>
                <CancelModel data-testId='cancelModel' openSuccessModal={this.state.openSuccessModal} handleSuccessRedirectModalClose={() => this.cancelSubcriptionPlan()} handleSuccessModalClose={this.handleSuccessModalClose} />
            </div>
        )
    }
}

export { BusChangeSubscription }
export default withRouter(BusChangeSubscription)

const Wrapper = styled(Box)({
    "& .MuiTypography-root": {
        fontFamily: "Rene_Bieder_Milliard_SemiBold"
    },
    "& .page-title": {
        fontSize: '22px',
    },
    "& .title": {
        fontSize: "20px",
        letterSpacing: "-0.55px"
    },
    "& .descriptions": {
        fontSize: "12px",
        fontFamily: "Rene_Bieder_Milliard_SemiBold",
        color: "rgba(0,0,0,0.59)",
        letterSpacing: "-0.39999998px"
    },
    "& .current-plan": {
        fontSize: "20px",
        letterSpacing: "-0.55px"
    },
    "& .plan-details": {
        boxShadow: "0px 0px 8px rgba(212,212,212,0.5)",
        borderRadius: 12,
    },
    "& .main-card": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .check": {
        marginRight: "5px",
        marginLeft: '40px',
        height: "20px",
        color: "#009be2"
    },
    "& .description": {
        lineHeight: "17px",
        fontSize: "14px",
        fontFamily: "Rene_Bieder_Milliard_Book",
        color: "#9A9A9A",
        letterSpacing: "-0.35px"
    },
});
// Customizable Area End