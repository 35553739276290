// Customizable Area Start
import React, { useState, useEffect, useRef } from 'react'
import Draggable from 'react-draggable';
import './swipeSavedSearchList.css'
import {
    FAMILYFONTS,
  constants,
  icons,
  images,
} from "../../../components/src/constants"
import Modal from '@material-ui/core/Modal';
import { Box, Button, Typography } from '@material-ui/core';
import {
  FONTS
} from "../../../components/src/constants";
import { isMobile } from "react-device-detect";
// Customizable Area End


export default ({ children, key,deletesaved }: any) => {
  // Customizable Area Start
  const [isDrag, setIsDrag] = useState(false)
  const [isActionOpen, setIsActionOpen] = useState(false)
  const [percent, setPercent] = useState(0)
  const [left, setLeft] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const itemRef = useRef()
  const actionRef = useRef()

  const handleStart = (e: any, data: any) => {
    setIsDrag(true)
  }

  const handleClick = () => {
    // console.log(isDrag, left)
    if (left !== 0) {
      setIsActionOpen(false)
      setLeft(0)
    } else {
      console.log('click')
    }
  }

  const handleStop = (e: any) => {
    if (percent > 30) {
      setIsActionOpen(true)
      // @ts-ignore
      const w = actionRef.current.offsetWidth
      const leftWithAction = left > 0 ? w : w * -1
      setLeft(leftWithAction)
    } else {
      setLeft(0)
    }

    setIsDrag(false)
  }

  const handleDrag = (e: any, data: { x: React.SetStateAction<number>; }) => {
    // @ts-ignore
    const w = itemRef.current.offsetWidth
    // @ts-ignore
    const x = data.x < 0 ? data.x * -1 : data.x
    // @ts-ignore
    const p = x / w * 100

    setPercent(p)
    setLeft(data.x)
  }

  const handleOpen = () => {
    deletesaved()
  }

  // Customizable Area End
  return (
    // Customizable Area Start
    <div style={{position:"relative",padding:10}}>
      {/* @ts-ignore */}
      <div className='box-actionsavedsearch' onClick={handleOpen} ref={actionRef}>
        <div style={{justifyContent:"space-between" ,alignItems:"center",display:"flex",flexDirection:"column",    height: 41}}>
        <img src={icons.deleteIcon} style={{ height: 20, cursor: 'pointer' }} />

        <Typography style={{...FAMILYFONTS.family3,fontSize:10,color:"red"}}>Delete</Typography>
        </div>

      </div>

      {isMobile ?

        <Draggable
          axis="x"
          handle=".savedsearchitem"
          defaultPosition={{ x: 0, y: 0 }}
          position={{ x: left, y: 0 }}
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={handleStop}
          bounds={{ right: 0 }} // Setting bounds to prevent dragging to the right
          >

          <div
            // @ts-ignore
            ref={itemRef}
            className='savedsearchitem'
            style={{ transform: `translate3d(${left}px, 0, 0px)` }}
          // onClick={handleClick}
          >{children}</div>
        </Draggable> :
        <div
          // @ts-ignore
          ref={itemRef}
          className='savedsearchitem'
          style={{ transform: `translate3d(${left}px, 0, 0px)` }}
        // onClick={handleClick}
        >{children}</div>
      }


    </div>
    // Customizable Area End
  )
}