// Customizable Area Start
import React from "react";
import PropTypes from 'prop-types';
import BusSubscriptionPlanController from "./BusSubscriptionPlanController.web"
import BusDashbaordWeb from "./BusDashboard.web";
// @ts-ignore
import styles from "./BusSubscriptionDetails.module.css";
import { icons } from "../../../components/src/constants";
import {
    withStyles, Switch, createStyles, Theme
} from '@material-ui/core'
import SuccessModel from "../../../components/src/custom/SuccessSubscriptionModel";

export const AntSwitch: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 32,
            height: 18,
            padding: 0,
            display: "flex",
        },
        switchBase: {
            padding: 2,
            color: "white",
            "&$checked": {
                transform: "translateX(14px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: "#359ce2",
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        
        thumb: {
            width: 13,
            height: 13,
            boxShadow: "none",
        },

        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 18 / 2,
            opacity: 1,
            backgroundColor: "grey",
        },

        checked: {},
    })
)(Switch);

export default class BusSubscriptionPayments extends BusSubscriptionPlanController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }

    render() {
        return (
            <div >
                {/* @ts-ignore */}
                <BusDashbaordWeb children={this.props.children} classes={this.props.classes} navigation={this.props.navigation} id={""} adminSelectedTab={undefined} theme={undefined}
                >
                    <div style={{ marginLeft: 280 }}>
                        <div className={styles.mainWrapper}>
                            <div className={styles.headerDiv}>
                                <img className={styles.iconBack} src={icons.back} alt='back' data-testId='backBtn' onClick={() => this.props.navigation.goBack()} />
                                <p className={styles.paymentText}>Subscription payments</p>
                            </div>
                            {/* left side form */}
                            <div className={styles.paymentDetailsDiv}>
                                <div className={styles.paymentDetailsText}>Payment</div><br /><br />

                                <div className={styles.cardDetailsDiv}>
                                    <input type="radio" className={styles.radioButon} checked={true} />
                                    <div className={styles.cardLogo}>
                                        <p className={styles.visaLogo}>VISA</p>
                                    </div>
                                    <div className={styles.cardNo}>.... .... .... 9373</div>
                                    <div className={styles.cardExpiry}>Expiry 12/29</div>
                                </div>

                                <div className={styles.anotherCard}>Use another card</div>

                                <div className={styles.emailDiv}>
                                    <label className={styles.emailText}>Email</label>
                                    <input type="email" className={styles.emailFeild} placeholder="Enter email address" />
                                </div>

                                <div className={styles.cardsDiv}>
                                    <label className={styles.cardsText}>Card Details</label>
                                    <input type="text" className={styles.cardNoFeild} placeholder="Card Number" />
                                    <input type="text" className={styles.cardExpiryFeild} placeholder="Expiry Date" />
                                    <input type="text" className={styles.cardCVVFeild} placeholder="CVV" />
                                    <input type="text" className={styles.cardHolderFeild} placeholder="Card holder's name" />
                                </div>

                                <div className={styles.regionDiv}>
                                    <label className={styles.regionText}>Region or Country</label>
                                    <input type="text" className={styles.regionFeild} placeholder="Country name" />
                                    <input type="text" className={styles.zipFeild} placeholder="ZIP" />
                                </div>

                                <div className={styles.savedSwitch}>
                                    <AntSwitch />
                                    <p className={styles.saveTxt}>Save the card for future payments except CVV</p>
                                </div>
                                <button data-testId='payBtn' onClick={() => this.setState({ openSuccessModal : true})} className={styles.payBtn}>
                                    Pay
                                </button>
                            </div>

                            {/* divider */}
                            <div className={styles.divider}></div>

                            {/* right side form */}
                            <div className={styles.subscriptionDetailsDiv}>
                                <div className={styles.subscriptionDetailsText}>Subscription Details</div><br /><br />

                                <div className={styles.subsItems}>
                                    <div className={styles.subsName}>Presense - only plan charges</div>
                                    <div className={styles.subsPrice}>12.99</div>

                                    <div className={styles.betweenLine}></div>

                                    <div className={styles.subsTotal}>Total</div>
                                    <div className={styles.subsTotalPrice}>12.99</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BusDashbaordWeb>
                <SuccessModel openSuccessModal={this.state.openSuccessModal} handleSuccessRedirectModalClose={this.handleSuccessRedircModalClose} msgTitle='Thank you for subscribing' msg="You have successfully subscribed to the Presence- Only Business subscription plan" />
            </div>
        )
    }
}
// Customizable Area End