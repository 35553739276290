import React from "react";
import PropTypes from 'prop-types';
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import NonProfitProfileController from "./NonProfitProfileController.web";
// @ts-ignore
import styles from "./NonProfitProfile.module.css";
import { icons, constants } from "../../../components/src/constants";
import {
    Button, Typography, Tabs, Tab, Card, IconButton, Toolbar,
    Dialog, Checkbox, CircularProgress, Box
} from '@material-ui/core'
import { styled } from "@material-ui/styles";
import moment from "moment";
import CustomSuccessModel from "../../../components/src/custom/CustomSuccessModel";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
//@ts-ignore
import classes from "./userEvent.module.css";
import { isMobile } from "react-device-detect";
import { HideContainer } from "../../../components/src/shared/hide-container";

export default class NonProfitProfile extends NonProfitProfileController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }

    fallbackMessage() {
        return (
            <div className={styles.notFound}><p>No events available</p></div>
        )
    }

    event_type_bg_color = (type: any) => {
        if (type === "need") {
            return "#03BD95";
        } else if (type === "project_funding") {
            return "#049BE1";
        } else if (type === "activity") {
            return "#B4038C";
        } else {
            return "#009BE2";
        }
    }

    event_type_name = (type: any) => {
        if (type === "need") {
            return "Volunteer";
        } else if (type === "project_funding") {
            return "Donate";
        } else if (type === "activity") {
            return "Attend";
        } else {
            return "Volunteer";
        }
    }

    redirectToNeed = (eventId: number, type: string) => {
        if (type === "activity") {
            this.props.navigation.navigate("EventInfo", { id: eventId });
        } else if (type === "project_funding") {
            this.props.navigation.navigate("FundCampaign", { id: eventId });
        } else {
            this.props.navigation.navigate("EventsDetails", { id: eventId });
        }
    }

    renderCountry = (location: string, post_code: string) => {
        return post_code ? post_code.substring(0, post_code.indexOf(" ")) || post_code : location?.slice(location.lastIndexOf(",") + 1).trim();
      }
    
      renderLocation = (location: string, post_code: string) => {
        if (post_code && !location.includes(post_code)) {
          location += `, ${post_code}`;
        }
        return location;
    }

    currentData(item: any) {
        const isApproved = item.pledge_status === "Approved" || item.event_type === "project_funding" || item.event_type === "activity";
        return (
            <Card className={`${styles.cardInner} ${styles.nonProfitEvent}`} data-testId="event-card" onClick={()=>this.redirectToNeed(item.id, item.event_type)}>
                <div className={styles.leftCard}>
                    <img
                        className={styles.cardImage}
                        src={item?.event_image?.url ? item?.event_image?.url : "https://images.unsplash.com/photo-1591197172062-c718f82aba20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"}
                        alt="event image" 
                    />
                    {item.dbs_registration && <span className={styles.btnDbsCarousel}>DBS</span>}
                </div>
                <div className={styles.rightCard}>
                    <div  className={styles.rightCardEvents}>
                        <Typography className={styles.eventTitle}>
                            {item.event_name}
                        </Typography>
                        <span className={styles.timeline} style={{ background: this.event_type_bg_color(item?.event_type) }}>{this.event_type_name(item?.event_type)}</span>
                    </div>
                    <div className={styles.innerInfo}>
                        <div
                            className={styles.userInfo}
                        >
                            <div className={styles.avatarImgcontainer}>
                                <img src={this.state.NpDetais.attributes?.logo?.url ? this.state.NpDetais.attributes?.logo?.url : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"} alt="" className={styles.avatarImg} />
                            </div>
                            <Typography variant="body1">
                                {this.state.NpDetais.attributes?.organisation_name}
                            </Typography>
                        </div>
                        <div className={styles.imageTextWrapper}>
                            <div className={styles.avatarImgcontainer}>
                                <img
                                    src={icons.calender}
                                    alt="avatar"
                                    className={styles.avatarImg}
                                />
                            </div>
                            <span className={styles.eventdate_loc}>
                                {moment(item.from_date).format('DD MMM YYYY')}, {moment(item.start_time).format('HH:mm a')}
                            </span>
                        </div>
                        <div className={styles.imageTextWrapper}>
                            <div className={styles.avatarImgcontainer}>
                                <img
                                    src={icons.locationred}
                                    alt="location"
                                    className={styles.avatarImg}
                                />
                            </div>
                            <span className={styles.eventdate_loc}>
                            {isApproved
                                ? this.renderLocation(item.location, item.post_code)
                                : this.renderCountry(item.location, item.post_code)}
                            </span>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

    eventCard = () => {
        const { NpDetais } = this.state
        return (
            NpDetais?.attributes?.upcoming_events?.length > 0 ?
            <div className={styles.cardWrapper}>
                {NpDetais.attributes.upcoming_events.map((item: any) => (
                    this.currentData(item)
                ))}
            </div>
            : this.fallbackMessage()
        )
    }

    mainCard = () => {
        return (
            <div>{this.eventCard()}</div>
        )
    }

    followBtn = () => {
        return (
            this.state.followStatus ? <Button
                variant="outlined"
                style={{
                    position: 'relative',
                    left: 0,
                    boxShadow: "none",
                    textTransform: "capitalize",
                    border:"none",
                    backgroundColor: "#E9E9E9",
                    color: '#000',
                    fontFamily: "Rene_Bieder_Milliard_Medium",
                    borderRadius: 10,
                    padding: "2px 9px",
                    fontSize: "17px",
                }}
                data-testId={'unfollowBtn'}
                onClick={() => this.unfollowNpProfile(this.state.NpDetais.attributes.id)}
                className={styles.FollowBtn}
            >
                Following
            </Button> :
                <Button
                    variant="outlined"
                    className={styles.FollowBtn}
                    style={{
                        position: 'relative',
                        left: 0,
                        boxShadow: "none",
                        border: "none",
                        textTransform: "capitalize",
                        backgroundColor: " #009be2",
                        color: "white ",
                        fontFamily: "Rene_Bieder_Milliard_Medium",
                        borderRadius: 10,
                        padding: "2px 18px",
                        fontSize: "17px",
                    }}
                    color="primary"
                    data-testId={'followBtn'}
                    onClick={() => this.setState({ followAlertModal: true })}
                >
                    Follow
                </Button>
        )
    }

    contactNPODialog = () => {
        return (
            <ContactModal
                fullScreen={false}
                open={this.state.contactModal}
                onClose={() => this.setState({ contactModal: false })}
                data-testId='closeModel'
                PaperProps={{
                    style: { borderRadius: 16, width: 500, boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)", padding: "40px" }
                }}>
                <div className="modalTitle">Non profit organisation</div>
                <div className="close" data-testId="contactModal" role="button" onClick={() => this.setState({ contactModal: false })}>X</div>

                <Typography variant='h6' className="contactText">
                   Contact number: <a className="link" href={`tel:${this.state.NpDetais.attributes?.contact_number}`}>
                    {this.state.NpDetais.attributes?.contact_number ? "+" + this.state.NpDetais.attributes?.contact_number : "--"}
                    </a>
                </Typography>
            </ContactModal>
        )
    }

    followAlertDialog = () => {
        return (
            <Dialog
                hideBackdrop
                fullScreen={false}
                open={this.state.followAlertModal}
                onClose={() => this.setState({ followAlertModal: false })}
                aria-labelledby="responsive-dialog-title"
                data-testId='closeModel'
                PaperProps={{
                    style: { borderRadius: 16, width: 500, boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)" }
                }}>
                <div
                    style={{
                        fontFamily: "Rene_Bieder_Milliard_SemiBold",
                        fontSize: '1.25rem',
                        lineHeight: 1.6,
                        letterSpacing: '0.0075em',
                        padding: '20px'
                    }}>
                    Select alerts you would like to receive.
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Select All</Typography>
                    <Checkbox
                        color="primary"
                        name="selectAll"
                        data-testId="selectAll"
                        onChange={(e) => this.handleAllOptions(e)}
                        checked={this.state.followOptionsModal.selectAll}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Published Need</Typography>
                    <Checkbox
                        color="primary"
                        name="publishedNeed"
                        data-testId="publishedNeed"
                        checked={this.state.followOptionsModal.selectAll || this.state.followOptionsModal.publishedNeed}
                        onChange={(e) => this.handleAllOptions(e)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Published Upcoming Activity</Typography>
                    <Checkbox
                        color="primary"
                        name="publishedActivity"
                        data-testId="publishedActivity"
                        checked={this.state.followOptionsModal.selectAll || this.state.followOptionsModal.publishedActivity}
                        onChange={(e) => this.handleAllOptions(e)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Published Project Funding</Typography>
                    <Checkbox
                        color="primary"
                        name="publishedProjectFund"
                        data-testId="publishedProjectFund"
                        checked={this.state.followOptionsModal.selectAll || this.state.followOptionsModal.publishedProjectFund}
                        onChange={(e) => this.handleAllOptions(e)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                    <Typography className={styles.optText}>Broadcasted Message</Typography>
                    <Checkbox
                        color="primary"
                        name="broadcastedMsgs"
                        data-testId="broadcastedMsgs"
                        checked={this.state.followOptionsModal.selectAll || this.state.followOptionsModal.broadcastedMsgs}
                        onChange={(e) => this.handleAllOptions(e)}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                            <CheckBoxIcon style={{ color: "#359ce2" }} />
                        }
                    />
                </div>
                <div style={{ display: 'flex', padding: '10px', marginBottom: 20 }}>
                    <Button variant="outlined" className={styles.cancelBtn}  data-testId='cancelBtn' onClick={() => this.setState({ followAlertModal: false})}>Cancel</Button>
                    <Button variant="contained" className={styles.saveBtn}  data-testId='saveBtn' onClick={() => this.followNotifications(this.state.NpDetais.attributes?.id)}>Save</Button>
                </div>
            </Dialog>
        )
    }

    headerWeb() {
        return (
          <Toolbar className={classes.toolBar}>
            {!isMobile && (
              <div className={`${classes.backIconWeb}`}>
                <IconButton
                  aria-label="Open drawer"
                  color={"inherit"}
                  data-testId="back-btn"
                  onClick={() => this.props.navigation.goBack()}
                >
                  <img src={icons.back} />
                </IconButton>
                <Typography
                  variant="h6"
                  className={classes.activityDetails}
                  noWrap
                >
                  Non-Profit Profile
                </Typography>
              </div>
            )}
          </Toolbar>
        );
    }

    render() {
        const { NpDetais } = this.state
        const eventTotal = NpDetais?.attributes?.upcoming_events?.length;
        return (
            <div >
                {/* @ts-ignore */}
                <DashboardWeb
                    showBackButton={constants._icons.show}
                    showBottomBar={constants._icons.show}
                    showDrawerHeader={isMobile}
                    navigation={this.props.navigation}
                    title={"Non-Profit Profile"}
                >
                    <div className={classes.eventContainer}>
                        <HideContainer hideWhen={isMobile}>
                        {this.headerWeb()}
                        </HideContainer>
                            <div className={styles.mainDiv}>
                                {this.state.loading ?
                                    <Wrapper>
                                        <div className="loading">
                                            <CircularProgress size={40} color="primary" />
                                        </div>
                                    </Wrapper>
                                :
                                <>
                                <div className={styles.orgProfile}>
                                    <div className={styles.orgLogo}>
                                        <img src={NpDetais.attributes?.logo?.url ? NpDetais.attributes?.logo?.url : icons.profile} alt="pr" className={styles.orgImg} />
                                    </div>
                                    <div className={styles.mainPrgSection}>
                                        <div className={styles.orgInfo}>
                                            <div className={styles.orgInfo1}>
                                                <p className={styles.orgProfileTetx}>{NpDetais?.attributes?.organisation_name}</p>
                                                
                                                <div className={styles.locationData} style={{ display: 'flex', alignItems: 'center'}}>
                                                    <p className={styles.location} style={{ color: isMobile ? "gray" : "rgb(38 37 37)" }}>{NpDetais.attributes?.town ? NpDetais.attributes.town : '-'}, {NpDetais.attributes?.country ? NpDetais.attributes.country : '-'}</p>
                                                    <img className={styles.locationImg} src={icons.locationred} alt='location' />
                                                </div>
                                            </div>
                                            {this.state.followingStatus ? <Button
                                                variant="outlined"
                                                style={{
                                                    position: 'relative',
                                                    left: 0,
                                                    width: 100,
                                                    boxShadow: "none",
                                                    textTransform: "capitalize",
                                                    backgroundColor: "#ccc",
                                                    color: "#000",
                                                    fontFamily: "Rene_Bieder_Milliard_Medium",
                                                    borderRadius: 12,
                                                    border: "none",
                                                    padding: "2px 15px",
                                                    fontSize: "16px",
                                                }}
                                                color="primary"
                                                disabled
                                                className={styles.FollowBtn}
                                            >
                                            Following
                                            </Button> : this.followBtn()}

                                        </div>
                                        <hr className={styles.statsLine1} />
                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '75px', marginTop: '-2px', }}>
                                            <div>
                                                <p className={styles.eventsData}>{eventTotal > 9 ? eventTotal : `0${eventTotal}`}</p>
                                                <p className={styles.eventsText}>Upcoming events</p>
                                            </div>
                                            <div>
                                                <p className={styles.followersData}>{NpDetais.attributes?.followers > 9 ? NpDetais.attributes?.followers : `0${NpDetais.attributes?.followers}`}</p>
                                                <p className={styles.followersText}>Followers</p>
                                            </div>
                                        </div>

                                        <hr className={styles.statsLine} />
                                        <div className={styles.aboutDiv}>
                                            <p className={styles.aboutText}>About</p>

                                            <p className={styles.description}>{NpDetais.attributes?.description}</p>
                                            <hr className={styles.statsLine2} />

                                            <div className={styles.mainBtnDiv}>
                                                <Button variant="outlined"  className={styles.messageBtn} startIcon={<IconButton style={{ marginRight: "12px" }}><img src={icons.chats} style={{ width: 22 }} /></IconButton>} 
                                                data-testId="message-btn"
                                                 onClick={() => this.MessageNpProfile(NpDetais.attributes?.id)}
                                                >
                                                    Message</Button>
                                                <Button data-testId="contactBtn" variant="outlined" className={styles.contactBtn}
                                                onClick={() => this.setState({ contactModal: true })}
                                                startIcon={<IconButton style={{ marginRight: '12px' }}><img src={icons.phoneblueIcon} style={{ width: 22 }} /></IconButton>}>Contact</Button>
                                                <Button variant="contained" className={styles.donateBtn} data-testId="donate-btn" startIcon={<IconButton style={{ marginRight: '12px' }}><img src={icons.needswhite} style={{ width: 22, transform: 'scaleX(-1)' }} /></IconButton>}
                                                    onClick={() => this.props.navigation.navigate("Gamification", { id: this.state.id })}
                                                >
                                                    Donate
                                                </Button>
                                            </div>
                                        </div> 
                                        <hr className={styles.dividerOneLine} />
                                        <div className={styles.mainTabDiv}>
                                            <Tabs
                                                value={0}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                centered
                                                >
                                                <Tab label="Upcoming events" className={styles.tabHeadDivider} style={{ fontFamily: "Rene_Bieder_Milliard_Medium", color: '#000', textTransform: "unset", fontSize: '20px', }} />
                                            </Tabs>
                                        </div>
                                        <hr className={styles.dividerTwoLine} />
                                    </div>    
                                </div>
                                {this.mainCard()}
                                </>
                                }
                                {this.followAlertDialog()}
                            </div>
                    </div>
                    {this.contactNPODialog()}
                </DashboardWeb>
                <CustomSuccessModel openSuccessModal={this.state.openSuccessModal} handleSuccessModalClose={this.handleSuccessModalClose} msgTitle='Thank you' msg="Your changes have been updated" />
            </div>
        )
    }
}

const ContactModal = styled(Dialog)({
    position: "relative",
    "& .close": {
        position: "absolute",
        right: "20px",
        top: "20px",
        fontSize: "20px", 
        cursor: "pointer"
    },
   "& .modalTitle" : {
        fontFamily: "Rene_Bieder_Milliard_SemiBold",
        fontSize: '20px',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
        padding: '0px 0 20px',
        textAlign: "center"
   },
   "& .contactText" : {
        fontSize: '1.25rem',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
        textAlign: "center"
   }, 
   "& .link": {
        fontFamily: "Rene_Bieder_Milliard_SemiBold", 
        color: "inherit", 
        cursor: "default"
   }
});

const Wrapper = styled(Box)({
    "& .loading": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "400px",
        "& .MuiCircularProgress-root": {
        color: '#009BE2'
        }
    }
});