 {/* Customizable Area Start */}
import { combineReducers } from 'redux';
import { ADD_DBS_DETAILS} from '../types';


const initInformation = {
_addDBS_info:[]
}

function addDBSInfo(state = initInformation,action: { type: any; payload: string | number; }){
    switch(action.type){
        case  ADD_DBS_DETAILS: 
            return{
                ...state,
                _addDBS_info:action.payload
            }    
        default:
            return state;
    }
}

export default addDBSInfo;

 {/* Customizable Area End */}