 {/* Customizable Area Start */}
// actions.js

export const FETCH_CURRENT_LOCATION_REQUEST = 'FETCH_CURRENT_LOCATION_REQUEST';
export const FETCH_CURRENT_LOCATION_SUCCESS = 'FETCH_CURRENT_LOCATION_SUCCESS';
export const FETCH_CURRENT_LOCATION_FAILURE = 'FETCH_CURRENT_LOCATION_FAILURE';
export const SET_CURRENT_LOCATION__LATITUDE_LONGITUDE = 'SET_CURRENT_LOCATION__LATITUDE_LONGITUDE'

export const setCurrentLocation = (lat: any, lng: any, post_code: any, location: any) => {
  return (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
    dispatch({ type: SET_CURRENT_LOCATION__LATITUDE_LONGITUDE, payload: { lat, lng, post_code, location } });
  }
}
export const fetchCurrentLocation = () => {
  return (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
    dispatch({ type: FETCH_CURRENT_LOCATION_REQUEST });

    const successCallback = (position: { coords: { latitude: any; longitude: any; }; }) => {
      const { latitude, longitude } = position.coords;
      
      const geocoder = new (window as any).google.maps.Geocoder();

      geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results: { address_components: any; }[], status: string) => {
        if (status === 'OK' && results[0]) {
          const addressComponents = results[0].address_components;
          const addressDetails = {
            doorName: '',
            area: '',
            city: '',
            country: '',
            postcode: ''
          };

          addressComponents.forEach((component: { types: any[]; long_name: string; }) => {
            const componentType = component.types[0];

            if (componentType === 'street_number') {
              addressDetails.doorName = component.long_name;
            } else if (componentType === 'route') {
              addressDetails.area = component.long_name;
            } else if (componentType === 'locality') {
              addressDetails.city = component.long_name;
            } else if (componentType === 'country') {
              addressDetails.country = component.long_name;
            } else if (componentType === 'postal_code') {
              addressDetails.postcode = component.long_name;
            }
          });

          dispatch({ type: FETCH_CURRENT_LOCATION_SUCCESS, payload: { latitude, longitude, address: addressDetails } });
        } else {
          dispatch({ type: FETCH_CURRENT_LOCATION_FAILURE, payload: 'Unable to fetch address details' });
        }
      });
    };

    const errorCallback = (error: { message: any; }) => {
      dispatch({ type: FETCH_CURRENT_LOCATION_FAILURE, payload: error.message });
    };

    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        } else {
          dispatch({ type: FETCH_CURRENT_LOCATION_FAILURE, payload: 'Geolocation permission denied' });
        }
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      dispatch({ type: FETCH_CURRENT_LOCATION_FAILURE, payload: 'Geolocation is not supported' });
    }
  };
};


 {/* Customizable Area End */}
