// Customizable Area Start
import React from "react";


import PropTypes from "prop-types";
import {
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

import { COLORS } from "../../../framework/src/Globals";
//@ts-ignore
import { MyNonProfitForm } from "./MyNonProfitForm";

const styles = (theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: "8px",
      backgroundColor: COLORS.white,
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0),
      },
    },
    searchIcon: {
      left: '50px',
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
      "@media (max-width: 600px)": {
        left: '0px',
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.skyBlue,
      },
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
    inputInput: {
      margin: '15px 50px 0px 50px',
      borderRadius: '13px',
      padding: "16 45 16 0",
      marginBottom: "10px",
      boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
      paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
      transition: theme.transitions.create("width"),
      fontSize: "revert",
      fontFamily: "Rene_Bieder_Milliard_Light",
      fontWeight: 700,
      "@media (max-width: 600px)": {
        marginTop: "15",
        padding: "16 65 16 0",
        marginBottom: "10px",
        boxShadow: "0 0 8px rgb(0 0 0 / 12%)",
        paddingLeft: `calc(1em + ${theme.spacing(5.5)}px)`,
        transition: theme.transitions.create("width"),
        fontSize: "revert",
        fontFamily: "Rene_Bieder_Milliard_Light",
        fontWeight: 700,
        margin: '15px 0px 0px 0px'
      },
      "&::placeholder": {
        color: "#000501",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Rene_Bieder_Milliard_Light",
      },
    },

    toolBar: {
      display: "flex",
      flexDirection: "row",
      padding: "0px",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionTabDesktop: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    aftersearchIconbutton: {
      borderRadius: "8px",
      color: "text.primary",
      backgroundColor: "white",
      margin: "8px",
      padding: "8px",
      height: 46,
      width: 46,
    },
    image: {
      width: 128,
      height: 128,
    },
    _tabs_logo: {
      height: "20px",
      width: "20px",
      left: "33px",
      top: "788px",
      borderRadius: " 0px",
    }
  });

// Customizable Area End

class MyNonProfits extends EmailAccountRegistrationController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
     <MyNonProfitForm/> 
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles as any, { withTheme: true })(
  MyNonProfits as any
);

export { MyNonProfits }

// Customizable Area End
