import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// @ts-ignore
import { constants } from "../../../components/src/constants";
import { getCurrentLocation } from "../../../components/src/utils/current-location";
import * as Yup from 'yup';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleDisableEmail: any;
  handleDisablePwd: any;
  children: any;
  classes: any;
  theme?: any;
  registerNextBtnValidation: any;
  fullName: any;
  orgName: any;
  regNum: any;
  streetName: any;
  officeStreetName: any;
  officeCountry: any;
  officeTown: any;
  switchStatus: any;
  officePostCode: any;
  town: any;
  country: any;
  postCode: any;
  address: any;
  desc: any;
  contactNum: any;
  adminMobNum: any;
  emailId: any;
  regPassword: any;
  emailDomain: any;
  regConfirmPwd: any;
  link: any;
  webUrl: any;
  logo: any;
  acceptTerms?: boolean;
  regEmailApiError: any;
  mobileNumberApiError: any;
  handledisableDomain?: any;
  domainApiError?: any;
  businessApiError?: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  disableEmail: boolean;
  disablePwd: boolean;
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  mobileOpen: boolean;
  open: boolean;
  classes: any;
  theme?: any;
  isMobile: boolean;
  selectedTab: any;
  selectedBottomTab: any;
  slideIndex: any;
  value: number;
  setAnchorEl: any;
  organizationState: any;
  age: any;
  setLandingPageModal: any;
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  emailValidation: boolean;
  emailPasswordValidation: boolean;
  enablePasswordField: boolean;
  forgotPwdEmail: string;
  otp: string;
  otpError: string;
  newPwd: string;
  confirmPwd: string;
  enableNewPwd: boolean;
  enableConfirmPwd: boolean;
  resetPasswordValidation: boolean;
  resetPwdError: string;
  matchPwdError: string;
  isOtpResend: boolean;
  new_otp: string;
  desc: string;
  contactNum: string;
  adminMobNum: string;
  emailId: string;
  regPassword: string;
  emailDomain: string;
  regConfirmPwd: string;
  link: string;
  webUrl: string;
  enablePwdField: boolean;
  enableConfirmPwdField: boolean;
  fullName: any;
  regNum: any;
  streetName: string;
  officeStreetName: string;
  officeTown: string;
  officePostCode: string;
  officeCountry: string;
  town: string;
  country: any;
  orgName: any;
  orgType: any;
  postCode: any;
  logo: any;
  regEmailValidation: boolean;
  regPwdValidation: boolean;
  regPwdError: string;
  regEmailError: string;
  activeStep: any;
  fullNameError: any;
  projectNameError: any;
  registrationNumberError: any;
  StreetNameError: any;
  officeStreetNameError: any;
  officeTownError: any;
  officePostCodeError: any;
  officeCountryError: any;
  townError: any;
  countryError: any;
  postCodeError: any
  orgTypeError: any;
  orgDesError: any;
  emailDomainError: any;
  contactNumberError: any;
  adminMobileNumberError: any;
  expireOtpError: any;
  linkError: any;
  webUrlError: any;
  descriptionWordCount: any;
  address: any;
  switchStatus: boolean;
  mobileOtp: string;
  emailOtp: string;
  emailApiError: string;
  mobileNumberApiError: string;
  contactNumFromResponse: string;
  dataStatic: any;
  registrationApiResponse: any;
  mobileTokenResponse: string;
  registrationAPIToken: string;
  emailLinkToken: string;
  mobileOtpError: string;
  regEmailApiError: any;
  domainnameError: any;
  domainApiError: any;
  businessApiError: string;
  disableDomain: boolean;
  modalValue: string;
  acceptTerms: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FormRegitrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  registrationApiCallId: string = "";
  mobileVerificationRegistrationCallId: string = "";
  mobileOtpVerifyCallId: string = "";
  emailTokenCallId: string = "";
  emailOtpCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),

      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      //getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      disableEmail: false,
      disablePwd: false,
      dashboardData: [],
      errorMsg: "",
      value: 0,
      token: "",
      loading: false,
      mobileOpen: false,
      open: false,
      classes: {},
      theme: {},
      isMobile: false,
      selectedTab: 0,
      selectedBottomTab: 0,
      slideIndex: 1,
      setAnchorEl: null,
      organizationState: "",
      age: "",
      setLandingPageModal: false,
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      emailValidation: false,
      emailPasswordValidation: false,
      enablePasswordField: true,
      forgotPwdEmail: "",
      otp: "",
      otpError: "",
      newPwd: "",
      confirmPwd: "",
      enableNewPwd: true,
      enableConfirmPwd: true,
      resetPasswordValidation: false,
      resetPwdError: "",
      matchPwdError: "",
      isOtpResend: false,
      new_otp: "",
      desc: "",
      contactNum: "",
      adminMobNum: "",
      emailId: "",
      regPassword: "",
      emailDomain: "",
      regConfirmPwd: "",
      link: "",
      webUrl: "",
      enablePwdField: false,
      enableConfirmPwdField: false,
      fullName: "",
      orgName: "",
      orgType: "",
      regNum: "",
      streetName: "",
      officeStreetName: "",
      officeTown: "",
      officePostCode: "",
      officeCountry: "",
      town: "",
      country: "",
      postCode: "",
      logo: "",
      regEmailValidation: false,
      regEmailError: "",
      regPwdValidation: false,
      regPwdError: "",
      activeStep: 0,
      fullNameError: "",
      projectNameError: "",
      registrationNumberError: "",
      StreetNameError: "",
      officeStreetNameError: "",
      officeTownError: "",
      officePostCodeError: "",
      officeCountryError: "",
      townError: "",
      countryError: "",
      postCodeError: "",
      orgTypeError: "",
      orgDesError: "",
      emailDomainError: "",
      contactNumberError: "",
      adminMobileNumberError: "",
      expireOtpError: "",
      linkError: "",
      webUrlError: "",
      descriptionWordCount: 0,
      address: "",
      switchStatus: false,
      mobileOtp: '',
      emailOtp: '',
      emailApiError: '',
      mobileNumberApiError: '',
      contactNumFromResponse: '',
      dataStatic: {},
      registrationApiResponse: {},
      mobileTokenResponse: "",
      registrationAPIToken: '',
      emailLinkToken: '',
      mobileOtpError: '',
      regEmailApiError: '',
      domainnameError: "",
      domainApiError: "",
      businessApiError: "",
      disableDomain: false,
      modalValue: "",
      acceptTerms: false
      // Customizable Area End
    };
    //runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.registrationApiCallId) {
        this.registrationApiCallIdFunction(responseJson, errorReponse)
      }

      if (apiRequestCallId === this.mobileVerificationRegistrationCallId) {
        this.handlemobileVerificationRegistrationCallIdFunction(responseJson)
      }

      if (apiRequestCallId === this.mobileOtpVerifyCallId) {
        this.handleMobileOtpVerifyCallIdFunction(responseJson)
      }

      if (apiRequestCallId === this.emailTokenCallId) {
        this.handleEmailTokenCallIdFunction(responseJson)
      }

      if (apiRequestCallId === this.emailOtpCallId) {
        this.handleEmailOtpCallId(responseJson)
      }

    }

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getLocation();

    // Customizable Area Start
    this.getLocation();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // Customizable Area End
  }
  async getLocation(){
    try {
      const location = await getCurrentLocation();
       this.setState({ streetName:location.address, town:location.city, country:location.country, postCode:location.postcode });
    } catch (error) {
      console.log(error);
    }
  }  
  
  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 ? true : false });
    if (window.innerWidth >= 760) {
      this.setState({ open: true });
      this.setState({ isMobile: true });
    }
  }

  componentWillUnmount(): any {
    typeof window !== 'undefined' && window.removeEventListener("resize", this.resize.bind(this));
  }

  // Refactor
  registrationApiCallIdFunction = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      if (responseJson.data) {
        this.setState({ registrationApiResponse: responseJson }, () => this.handleMobileOtpVerification())
        localStorage.setItem("EmailId", responseJson.data.attributes.email)
        localStorage.setItem("Mobile", responseJson.data.attributes.contact_number)
        localStorage.setItem("showSubscribe", "true")
      }
      if (responseJson?.errors && responseJson.errors?.length) {
        responseJson.errors.map((err: any) => {
          if (err?.email) {
            this.setState({ regEmailApiError: err?.email })
          }
          else if (err?.contact_number) {
            this.setState({ mobileNumberApiError: 'Contact Number ' + err?.contact_number })
          }
          else if (err?.corporate_email_domain) {
            this.setState({ domainApiError: err?.corporate_email_domain })

          } else if (err?.name_of_business) {
            this.setState({activeStep: 0, businessApiError: err?.name_of_business });
          }
        })
      }
    }
  }

  handlemobileVerificationRegistrationCallIdFunction = (responseJson: any) => {
    if (responseJson) {
      localStorage.setItem("mobileToken", responseJson.token)
      this.props.navigation.navigate('MobileOtpVerification')
    }
    if (responseJson && responseJson.errors) {
      console.log('MobileOtpVerification Error');
    }
  }

  handleMobileOtpVerifyCallIdFunction = (responseJson: any) => {
    if (responseJson.message) {
      this.props.navigation.navigate('CongratsBusiness');
    }
    if (responseJson.errors[0].pin === 'Please enter a valid OTP') {
      this.setState({ mobileOtpError: responseJson.errors[0].pin })
    }
    if (responseJson.errors[0].pin === 'This Pin has expired, please request a new pin code.') {
      this.setState({ mobileOtpError: responseJson.errors[0].pin })
    }
    if (responseJson.errors[0].message) {
      this.setState({ mobileOtpError: responseJson.errors[0].message });
    }
  }

  handleEmailTokenCallIdFunction = (responseJson: any) => {
    if (responseJson) {
      localStorage.setItem("EmailToken", responseJson.token)
      this.props.navigation.navigate('CongratsBusinessAfterEmail')
    }
    if (responseJson && responseJson.errors) {
      console.log('EmailOtpVerificationRegistration Error');
    }
  }

  handleEmailOtpCallId = (responseJson: any) => {
    if (responseJson) {
      this.props.navigation.navigate('CongratsBusinessAfterEmail')
    }
    if (responseJson && responseJson.errors) {
      console.log('CongratsBusinessAfterEmail Error');
    }
  }
  // Validation for the first registration part1
  checkRegisterBeforeNext = (value: any) => {
    for (const prop in value) {
      if (Object.prototype.hasOwnProperty.call(value, prop)) {
        switch (prop) {
          case 'fullName':
            this.setState({ fullName: value.fullName });
            break;
          case 'orgName':
            this.setState({ orgName: value.orgName, businessApiError: "" });
            break;
          case 'regNum':
            this.setState({ regNum: value.regNum });
            break;
          case 'streetName':
            this.setState({ streetName: value.streetName });
            break;
          case 'town':
            this.setState({ town: value.town });
            break;
          case 'country':
            this.setState({ country: value.country });
            break;
          case 'postCode':
            this.setState({ postCode: value.postCode });
            break;
          case 'officeStreetName':
            this.setState({ officeStreetName: value.officeStreetName });
            break;
          case 'officeTown':
            this.setState({ officeTown: value.officeTown });
            break;
          case 'officeCountry':
            this.setState({ officeCountry: value.officeCountry });
            break;
          case 'officePostCode':
            this.setState({ officePostCode: value.officePostCode });
            break;
          case 'desc':
            this.setState({ desc: value.desc });
            break;
          case 'contactNum':
            this.setState({ contactNum: value.contactNum, mobileNumberApiError: "" });
            break;
          case 'emailId':
            this.setState({ emailId: value.emailId, regEmailApiError: "" });
            break;
          case 'regPassword':
            this.setState({ regPassword: value.regPassword });
            break;
          case 'regConfirmPwd':
            this.setState({ regConfirmPwd: value.regConfirmPwd });
            break;
          case 'emailDomain':
            this.setState({ emailDomain: value.emailDomain, domainApiError: "" });
            break;
          case 'logo':
            this.setState({ logo: value.logo });
            break;
          case 'switchStatus':
            this.setState({ switchStatus: value.switchStatus });
            break;
          case 'acceptTerms':
            this.setState({ acceptTerms: value.acceptTerms });
            break;
          default:
            break;
        }
      }
    }
  }
  // Validation for the first registration part1 End

  // Full Name Validation Start

  isValidFullName = (value: any) => {
    const reg = /^[A-Z][a-z]+[,.'-]?(?: [A-Z][a-z]+[,.'-]?)*$/i;
    return reg.test(String(value).toLowerCase());
  }

  validFullName = (value: any) => {
    if (value == '') {
      this.setState({ fullNameError: "Please enter full name" })
    }
    if (this.isValidFullName(value)) {
      this.setState({ fullNameError: "" })
    }
  }

  // Full Name Validation End

  // Business Name Validation Start 
  isValidDes = (value: any) => {
    const reg = /^\S[^\r\n]+\S$/;
    return reg.test(String(value).toLowerCase());
  }

  validBusinessName = (value: any) => {
    if (value == '') {
      this.setState({ projectNameError: 'Please enter business name' })
    }
    if (this.isValidDes(value)) {
      this.setState({ projectNameError: '' })
    }
  }
  // Business Name Validation Start 

  // Registration number validation start
  validRegistrationNumber = (value: any) => {
    if (this.isValidDes(value)) {
      this.setState({ registrationNumberError: "" })
    }
  }
  // Registration number validation end

  // Validation of validStreetName Start
  validStreetName = (value: any) => {
    if (value == '') {
      this.setState({ StreetNameError: 'Please enter street number' })
    }
    if (this.isValidDes(value)) {
      this.setState({ StreetNameError: "" })
    }
  }
  // Validation of validStreetName End

  // Validation of Town start
  validTown = (value: any) => {
    if (value == '') {
      this.setState({ townError: 'Please enter town' })
    }
    if (this.isValidDes(value)) {
      this.setState({ townError: "" })
    }
  }
  // Validation of Town End

  // Validation of valid country name start
  validCountry = (value: any) => {
    if (value == '') {
      this.setState({ countryError: 'Please enter country' })
    }
    if (this.isValidDes(value)) {
      this.setState({ countryError: "" })
    }
  }
  // Validation of valid country name end

  // Validation of valid post code start
  validPostCode = (value: any) => {
    if (value == '') {
      this.setState({ postCodeError: 'Please enter post code' })
    }
    if (this.isValidDes(value)) {
      this.setState({ postCodeError: "" })
    }
  }
  // Validation of valid post code end

  // Validation of valid stree name Start
  validOfficeStreetName = (value: any) => {
    if (value == '') {
      this.setState({ officeStreetNameError: 'Please enter street name' })
    }
    if (this.isValidDes(value)) {
      this.setState({ officeStreetNameError: "" })
    }
  }
  // Validation of valid stree name end

  // Validation of valid officeCountry Start
  validOfficeCountry = (value: any) => {
    if (value == '') {
      this.setState({ officeCountryError: 'Please enter country name' })
    }
    if (this.isValidDes(value)) {
      this.setState({ officeCountryError: "" })
    }
  }
  // Validation of valid officeCountry end

  // Validation of valid officePostcode Start
  validOfficePostCode = (value: any) => {
    if (value == '') {
      this.setState({ officePostCodeError: 'Please enter post code' })
    }
    if (this.isValidDes(value)) {
      this.setState({ officePostCodeError: "" })
    }
  }
  // Validation of valid officePostcode End

  //Validation for brief description validation register
  descriptionValidation = (value: any) => {
    if (value === '') {
      this.setState({ orgDesError: 'Please enter description of business' })
    }
    if (value.trim().length > 1) {
      this.setState({ orgDesError: "" })
    }
  }
  //Validation for brief description validation register

  // word counter start
  wordCounter = (value: string, total: number) => {
    this.setState({
      descriptionWordCount: value.trim() === "" ? 0 : total
    });
  }
  // Word counter end

  // Contact number validation Start

  validContactNumber = (value: any) => {
    if (value === '') {
      this.setState({ contactNumberError: 'Please enter valid contact number' })
    }
    if (value !== '') {
      this.setState({ contactNumberError: '' })
    }
  }
  // Contact number validation End

  handleDisableEmail = (value: any) => {
    console.log("valuesss", value)
    if (value !== "") {
      this.setState({ disableEmail: true })
    } else {
      this.setState({ disableEmail: false })
    }
  }

  handleDisablePwd = (value: any) => {
    console.log("valuesss", value)
    if (value !== "") {
      this.setState({ disablePwd: true })
    } else {
      this.setState({ disablePwd: false })
    }
  }

  // Email validation Start
  isValidEmail(value: any) {
    let schema = Yup.string().email();
    return schema.isValidSync(value);
  }

  validateEmail(value: any) {
    let emailError = '';
    let emailValidation = false;

    if (value === '') {
      this.props?.handleDisableEmail(emailError);
    } else if (this.isValidEmail(value)) {
      this.props?.handleDisableEmail(emailError);
    } else {
      emailError = 'Please enter a valid email address.';
      emailValidation = true;
      this.props?.handleDisableEmail(emailError);
    }

    this.setState({ emailError, emailValidation });
  }

  // Email validation end

  // Validate domain start
  validDomains = (value: any) => {
    if (value == '') {
      this.setState({ emailDomainError: 'Please enter description of organization' })
    } else if (value.trim().length > 1) {
      this.setState({ emailDomainError: "" })
    } else {
      this.setState({ emailDomainError: 'Please enter description of organization' })
    }
  }
  // Validate domain end

  // Validate password start
  validatePassword(value: string) {
    if (value === "") {
      this.setState({ passwordError: constants.BasicPasswordValidation, emailPasswordValidation: true }, () => {
        this.props.handleDisablePwd(this.state.passwordError);
      });
    }
    if (value !== "") {
      (!value.match(/^(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[\s\S]{8,}$/)) ?
        this.setState({ passwordError: constants.PasswordValidation, emailPasswordValidation: true }, () => {
          this.props.handleDisablePwd(this.state.passwordError)
        })
        :
        this.setState({ passwordError: "", emailPasswordValidation: false }, () => {
          this.props.handleDisablePwd(this.state.passwordError)
        }
        )
    }
  }

  // Validate password end
  handleNext = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }, 1000);
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  // For validation of valid officeStreetName
  validOfficeTown = (value: any) => {
    if (value == '') {
      this.setState({ officeTownError: 'Please enter town' })
    }
    if (this.isValidDes(value)) {
      this.setState({ officeTownError: '' })
    }
  }

  handleSwitch = (e: any) => {
    if (this.state.switchStatus === false) {
      this.setState({ switchStatus: true })
    } else {
      this.setState({ switchStatus: false })
    }
  }

  setOtp = (e: any) => {
    this.setState({ mobileOtp: e })
  }

  validProjectName = (value: any) => {
    if (value == '') {
      this.setState({ projectNameError: 'Please enter country name' })
    }
    if (this.isValidDes(value)) {
      this.setState({ projectNameError: "" })
    }
  }

  handleRegisterSubmitButton = () => {
    const header = {
    };

    const formData = new FormData();
    formData.append("[data][attributes][full_name]", this.state.fullName);
    formData.append("[data][attributes][name_of_business]", this.state.orgName);
    formData.append("[data][attributes][email]", this.state.emailId);
    formData.append("[data][attributes][password]", this.state.regPassword);
    formData.append("[data][attributes][password_confirmation]", this.state.regConfirmPwd);
    formData.append("[data][attributes][registration_number]", this.state.regNum);
    formData.append("[data][attributes][corporate_email_domain]", this.state.emailDomain);
    formData.append("[data][attributes][street]", this.state.streetName);
    formData.append("[data][attributes][town]", this.state.town);
    formData.append("[data][attributes][post_code]", this.state.postCode);
    formData.append("[data][attributes][country]", this.state.country);
    formData.append("[data][attributes][office_street]", this.state.officeStreetName);
    formData.append("[data][attributes][office_town]", this.state.officeTown);
    formData.append("[data][attributes][office_post_code]", this.state.officePostCode);
    formData.append("[data][attributes][office_country]", this.state.officeCountry);
    formData.append("[data][attributes][contact_number]", this.state.contactNum);
    formData.append("[data][attributes][description]", this.state.desc);
    formData.append("[data][attributes][logo]", this.state.logo);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registrationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.registrationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleMobileOtpVerification = () => {
    const header = {
      'Content-Type': 'application/json'
    };

    const contactNo = this.state.registrationApiResponse.data.attributes.contact_number;
    const accountType = this.state.registrationApiResponse.data.type;

    const httpBody =
    {
      "data":
      {
        "attributes":
        {
          "full_phone_number": contactNo,
          "type": accountType
        }
      }
    }

    this.handleResendFunction(header, httpBody);

  }

  handleMobileOtpVerify = () => {
    const header = {
      'Content-Type': 'application/json'
    };

    let token = "";
    if (typeof window !== "undefined") {
      token = localStorage.getItem("mobileToken") || ""
    }
    let otp = this.state.mobileOtp;

    const httpBody =
    {
      "token": token,
      "pin": otp,
      "type": "business_account"
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.mobileOtpVerifyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mobileOtpVerifyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleEmailTokenCallId = () => {
    const header = {
      'Content-Type': 'application/json'
    };

    let emailId = "";
    if (typeof window !== "undefined") {
      emailId = localStorage.getItem("EmailId") || ""
    }

    const httpBody = {
      "email": emailId
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailTokenCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailTokenApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleVerifyEmail = () => {
    if (this.props.navigation.navigate)
      this.props.navigation.navigate("EmailOtpVerificationRegistration");
  }

  handleEmailOTP = () => {
    console.log("resendLink invoke")
    let token = "";
    if (typeof window !== "undefined") {
      token = localStorage.getItem("EmailToken") || ""
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOtpEnterEndPoint + `${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleInputFocus = (event: any) => {
    const outlineElement = event.target.parentElement.querySelector(
      '.MuiOutlinedInput-notchedOutline'
    );
    if (outlineElement) {
      outlineElement.style.borderColor = '#009BE2';
    }
  };

  handleInputBlur = (event: any) => {
    const outlineElement = event.target.parentElement.querySelector(
      '.MuiOutlinedInput-notchedOutline'
    );
    if (outlineElement) {
      outlineElement.style.borderColor = '#d5d5d5';
    }
  };

  handleEmailToHome = () => {
    this.props.navigation.navigate('BusHomeDashboard')
  }

  handleMobileResendLink = () => {
    const header = {
      'Content-Type': 'application/json'
    };

    const contactNo = localStorage.getItem('Mobile');
    const accountType = "business_account";

    const httpBody =
    {
      "data":
      {
        "attributes":
        {
          "full_phone_number": contactNo,
          "type": accountType
        }
      }
    }

    this.handleResendFunction(header, httpBody);
  }

  handleResendFunction = (header: any, httpBody: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.mobileVerificationRegistrationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mobileVerificationRegistrationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  isvalidDomain = (value: any) => {
    if (value?.length > 0) {
      let valid=this.handleMulitpleDomain(value)
        return valid
    }
    else {
      return true
    }

  }

  validDomainName = (value: any) => {
    if (this.isvalidDomain(value)) {
      this.setState({ domainnameError: "" })
      this.props.handledisableDomain(false)
    }
    else {
      this.setState({ domainnameError: "Please enter valid domain" })
      this.props.handledisableDomain(true)
    }
  }

  handledisableDomain = (status: boolean) => {
    this.setState({ disableDomain: status })
  }

  handleMulitpleDomain = (value: any) => {
    const reg = /^@\w+\.\w+$/;
    let isValid = true

    if (value.includes(",")) {
      let domains = value.split(",");
      for (let domain of domains) {
        if (!reg.test(String(domain).toLowerCase())) {
          isValid = false
        }

      }
    }
    else {
      isValid = reg.test(String(value).toLowerCase())
    }

    return isValid;
  }

  // Customizable Area End
}