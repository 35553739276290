 {/* Customizable Area Start */}
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'

export const setSelectedTabSuccess= (selectedTab: any) => ({
    type:SET_SELECTED_TAB,
    payload: {selectedTab}
})

export function setSelectedTab(selectedTab: any){
    // console.log(selectedTab,"selectedTab")
    return (dispatch: (arg0: { type: string; payload: { selectedTab: any } }) => void) => {
        dispatch(setSelectedTabSuccess(selectedTab))
        localStorage.setItem("selectedTab", JSON.stringify(selectedTab));
    }
}

 {/* Customizable Area End */}