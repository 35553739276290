import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useSpinnerStyles = makeStyles({
  root: {
    margin: '10px auto',
    marginTop:'30%',
    display: 'block',
    color: '#009BE2'
  },
});


export function InitiateSpinner() {


  const spinnerClasses = useSpinnerStyles();

  return (
    <CircularProgress
      size={40}
      className={spinnerClasses.root}
      color="primary"
    />
  );
}
