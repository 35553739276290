// Customizable Area Start
import React from 'react'

import Modal from '@material-ui/core/Modal';
import { Box, Typography, Button } from '@material-ui/core';
import {
  FONTS
} from "../constants";
// @ts-ignore\
import classOuter from "./ContactusSuccessModal.module.css"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// Customizable Area End


export default ({ openSuccessModal, handleSuccessModalClose, handleModalClose,alertText }: any) => {
  // Customizable Area Start

  // Customizable Area End
  return (
    // Customizable Area Start
    <Modal
      open={openSuccessModal}
      onClose={handleModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classOuter.modalWrapper}
    >
      <Box>
        <Box className={classOuter.modalBox}
        >
          <Box>
            <div>
              <ErrorOutlineIcon className={classOuter.error} />

              <Typography style={{marginBottom:26}} variant='h6'>{alertText}</Typography>
             
              <div>
                <Button
                  className={classOuter.modalBtn}
                  variant="contained"
                  onClick={() => handleModalClose()}
                >
                  Ok
                </Button>
              </div>
            </div>

          </Box>

        </Box>
      </Box>
    </Modal>
    // Customizable Area End
  )
}