import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";
import { apiCall } from "../../../components/src/utils/utils.web";
const { Conversation } = require('@twilio/conversations');

// Customizable Area Start
interface ChatMessages {
    author: string;
    body: string;
    dateUpdated: string;
    index: number;
    media: any;
    memberSid: string;
    sid: string;
    timestamp: string;
    type: string;
}

interface MemberData {
    email: string,
    full_name: string,
    id: number
    profile_picture: null | { url: null | string },
    role: string,
    type: string,
    event_picture: null,
    participant_id: string | undefined,
}

interface CustomAttributes {
    pinned?: boolean;
    senderId: string;
    senderType: string;
}
interface Values {
    body: string;
    customAttributes: CustomAttributes;
    author: string;
    dateUpdated: string;
    index: number;
    media: string | null;
    memberSid: string | null;
    sid: string;
    timestamp: string;
    type: string;
    DiffLabel: string;
}
type Channel = typeof Conversation;

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    client: any;
    channelUniqueName: string;
    chatRoomType:string;
    chatUserInformation: any;
    currentChatTyping: boolean;
    closeChatWindow: () => void;
    screenType: any;
    toggleType: any;
    clearChat: (sid: string, updatedChats: Array<any>) => void;
    createIndividualConnection: (id: string, type: string) => void;
    onMuteNotification: (id: string, isMute: boolean) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    chatInfo: boolean;
    mobileView: boolean;
    email: string | null;
    messages: any;
    loading: boolean;
    msgLoading: boolean;
    messageText: string;
    selectedEmoji: null;
    token: string | null;
    currentPage: number,
    perPage: number,
    searchTerm: string,
    chatInformation: any,
    individualChat: boolean,
    searchBarChat: boolean,
    deleteModal: boolean,
    emojiPicker: boolean,
    userId: any,
    currentSearchSelect: number,
    searchResults: Array<any>,
    totalUnreadMessages: number,
    typingStatus: boolean,
    clearChatOption: number,
    clearChatApplied: any,
    chatMessageObj: any,
    pinnedMessagesData: Array<string>,
    showMediaLoading: boolean,
    groupMediaLinks: Array<any>,
    groupHyperLinks: Array<any>,
    groupMediaCount: number,
    organizarMessage: boolean,
    lastDeliveredMessageIndex: number,
    lastReadMessageIndex: number,
    delivery: any,
    read: any,
    mediaScreen: boolean,
    mediaPopup:boolean,
    mediaTabValue: number,
    groupMediaPageCount: number,
    groupLinkPageCount: number,
    groupMediaCurrentPage: number,
    groupHyperCurrentPage: number,
    paginationHyperLoading: boolean,
    paginationMediaLoading: boolean,
    chatLinkRefAdded: boolean,
    chatMediaRefAdded: boolean,
    groupLinkCount: number,
    organizarMessageCount: number,
    currentMsgLen: number,
    loader: boolean;
    isMute: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class IndividualchatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    channel: any;
    client: any;
    channelUniqueName: any;
    chatUserInformation: any;
    clearChatApi: string = "";
    chatMessagesRef: any;
    chatMediaRef: React.RefObject<HTMLDivElement>;
    chatLinkRef: React.RefObject<HTMLDivElement>;
    exitGroupApi: string = "";
    screenType: any;
    toggleType: any;
    saveLinkApi: string = "";
    getMediasApi: string = "";
    saveHyperLinkApi: string = "";
    getHyperMediasApi: string = "";
    paginationMediaLinkApi: string = "";
    paginationHyperLinkApi: string = "";
    muteNotificationApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.joinChannel = this.joinChannel.bind(this);
        this.importData = this.importData.bind(this);
        this.sendMedia = this.sendMedia.bind(this);
        this.typingMessage = this.typingMessage.bind(this);
        this.displayChatInfo = this.displayChatInfo.bind(this);
        this.keyUp = this.keyUp.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.nextSearch = this.nextSearch.bind(this);
        this.exitGroup = this.exitGroup.bind(this);
        this.onChangeClearChat = this.onChangeClearChat.bind(this);
        this.clearChat = this.clearChat.bind(this);
        this.getChatMessages = this.getChatMessages.bind(this);
        this.getMessagesData = this.getMessagesData.bind(this);
        this.checkClearDates = this.checkClearDates.bind(this);
        this.getMessagesPromises = this.getMessagesPromises.bind(this);
        this.setMessagesState = this.setMessagesState.bind(this);
        this.updateMessages = this.updateMessages.bind(this);
        this.checkLatestClearDates = this.checkLatestClearDates.bind(this);
        this.handleMessageRemoved = this.handleMessageRemoved.bind(this);
        this.handleMessageUpdated = this.handleMessageUpdated.bind(this);
        this.handleMessageObjUpdate = this.handleMessageObjUpdate.bind(this);
        this.getParticipantId = this.getParticipantId.bind(this);
        this.handleMessageAddedIndividual = this.handleMessageAddedIndividual.bind(this);
        this.handleMessageChannelUpdated = this.handleMessageChannelUpdated.bind(this);
        this.updatedReceipts = this.updatedReceipts.bind(this);
        this.saveLink = this.saveLink.bind(this);
        this.saveMedia = this.saveMedia.bind(this);
        this.displayMediaAndLinks = this.displayMediaAndLinks.bind(this);
        this.handleMediaTabChange = this.handleMediaTabChange.bind(this);
        this.getMedias = this.getMedias.bind(this);
        this.getHyperMedias = this.getHyperMedias.bind(this);
        this.getHyperPaginationMedias = this.getHyperPaginationMedias.bind(this);
        this.getPaginationMedias = this.getPaginationMedias.bind(this);
        this.handleChatMediaPagination = this.handleChatMediaPagination.bind(this);
        this.handleChatLinkPagination = this.handleChatLinkPagination.bind(this);
        this.handleChatPaginationScroll = this.handleChatPaginationScroll.bind(this);
        this.toggleOrganiserMsg = this.toggleOrganiserMsg.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
        ];

        this.state = {
            // Customizable Area Start
            mobileView: true,
            email: '',
            messages: {},
            chatInfo: false,
            loading: true,
            msgLoading: true,
            messageText: '',
            selectedEmoji: null,
            token: '',
            currentPage: 1,
            perPage: 20,
            searchTerm: "",
            chatInformation: {
                image: '',
                name: '',
                description: '',
                member_data: [],
                organisation_name: ''
            },
            individualChat: false,
            searchBarChat: false,
            deleteModal: false,
            emojiPicker: false,
            userId: 0,
            currentSearchSelect: 0,
            searchResults: [],
            totalUnreadMessages: 0,
            typingStatus: false,
            clearChatOption: 0,
            clearChatApplied: [],
            chatMessageObj: {},
            pinnedMessagesData: [],
            showMediaLoading: false,
            groupMediaCount: 0,
            lastDeliveredMessageIndex: -1,
            groupMediaLinks: [],
            groupHyperLinks: [],
            lastReadMessageIndex: -1,
            organizarMessage: false,
            delivery: {},
            read: {},
            mediaScreen: false,
            mediaPopup:false,
            mediaTabValue: 0,
            groupMediaPageCount: 0,
            groupLinkPageCount: 0,
            groupMediaCurrentPage: 1,
            groupHyperCurrentPage: 1,
            paginationHyperLoading: false,
            paginationMediaLoading: false,
            chatLinkRefAdded: false,
            chatMediaRefAdded: false,
            groupLinkCount: 0,
            organizarMessageCount: 0,
            currentMsgLen: 0,
            loader: false,
            isMute: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.chatMessagesRef = createRef();
        this.chatMediaRef = createRef();
        this.chatLinkRef = createRef();
        this.handlePaginationMessages = this.handlePaginationMessages.bind(this);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        const apiMessageId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


        if (apiMessageId === this.clearChatApi) {
            const updatedChats = [...this.state.clearChatApplied];
            updatedChats.push(responseJson);
            this.props.clearChat(this.chatUserInformation.attributes.sid, updatedChats);
            this.setState({
                clearChatApplied: updatedChats,
            });
        }

        if (apiMessageId === this.exitGroupApi) {
            this.setState({
                loading: false
            })
            this.props.closeChatWindow();
        }

        if (apiMessageId === this.saveLinkApi) {
            this.setState({
                groupMediaLinks: [responseJson, ...this.state.groupMediaLinks],
                groupMediaCount: this.state.groupMediaCount + 1
            });
        }

        if (apiMessageId === this.getMediasApi) {
            this.setState({
                groupMediaLinks: responseJson.media,
                groupMediaPageCount: responseJson.total_pages,
                groupMediaCount: responseJson.media_count,
            });
        }

        if (apiMessageId === this.saveHyperLinkApi) {
            this.setState({
                groupHyperLinks: [responseJson, ...this.state.groupHyperLinks],
                groupLinkCount: this.state.groupLinkCount + 1
            });
        }

        if (apiMessageId === this.getHyperMediasApi) {
            this.setState({
                groupHyperLinks: responseJson.media,
                groupLinkPageCount: responseJson.total_pages,
                groupLinkCount: responseJson.media_count,
            });
        }

        if (apiMessageId === this.paginationHyperLinkApi) {
            this.setState({
                paginationHyperLoading: false,
                groupHyperLinks: [...this.state.groupHyperLinks, ...responseJson.media],
            });
        }

        if (apiMessageId === this.paginationMediaLinkApi) {
            this.setState({
                paginationMediaLoading: false,
                groupMediaLinks: [...this.state.groupMediaLinks, ...responseJson.media],
            });
        }
        if(apiMessageId === this.muteNotificationApiCallId){
            this.props.onMuteNotification(this.chatUserInformation?.id, responseJson.is_mute);
            this.setState({ isMute: responseJson.is_mute, loader: false });
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let showOrgMsgs = false;
        let organizarMessage = localStorage.getItem('organizerMsgChatId') ?? "{}";
        const chatId = JSON.parse(organizarMessage);
        if (chatId && this.props.chatUserInformation.attributes.sid in chatId) {
            showOrgMsgs = chatId[this.props.chatUserInformation.attributes.sid];
        }
        this.client = this.props.client;
        this.channelUniqueName = this.props.channelUniqueName;
        this.chatUserInformation = this.props.chatUserInformation;
        const individualChatType = this.props.chatUserInformation.attributes.friendly_name != 'eventChatRoom';
        const chatInformation = this.getChatInformation(individualChatType, this.props.chatUserInformation);
        const email = localStorage.getItem('user_email');
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('acc_id');
        this.setState({
            email,
            token,
            loading: true,
            chatInformation,
            individualChat: individualChatType,
            userId,
            totalUnreadMessages: 0,
            typingStatus: this.props.currentChatTyping,
            clearChatApplied: this.props.chatUserInformation.attributes.clear_chat,
            chatMessageObj: {},
            messages: {},
            pinnedMessagesData: this.props.chatUserInformation.attributes.pinned,
            groupMediaCount: 0,
            groupMediaLinks: [],
            delivery: this.props.chatUserInformation.attributes.delivery,
            read: this.props.chatUserInformation.attributes.read,
            groupMediaPageCount: 0,
            groupLinkPageCount: 0,
            organizarMessage: showOrgMsgs,
            isMute: this.props.chatUserInformation.attributes.is_notification_mute
        });
        this.getMedias();
        this.getHyperMedias();
        this.handleUserClick();
        this.chatMessagesRef.current?.addEventListener('scroll', this.handlePaginationMessages);
    }
    
    async componentDidUpdate(prevProps: Props, prevState: S) {
        if (!this.state.chatLinkRefAdded && this.chatLinkRef.current) {
            this.chatLinkRef.current.addEventListener('scroll', this.handleChatLinkPagination);
            this.setState({
                chatLinkRefAdded: true
            })
        }
        if (!this.state.chatMediaRefAdded && this.chatMediaRef.current) {
            this.chatMediaRef.current.addEventListener('scroll', this.handleChatMediaPagination);
            this.setState({
                chatMediaRefAdded: true
            })
        }
        if (prevProps.currentChatTyping !== this.props.currentChatTyping) {
            this.setState({
                typingStatus: this.props.currentChatTyping,
            });
        }
        if (prevState.clearChatApplied.length < this.state.clearChatApplied.length) {
            this.updateMessages();
        }
        if (prevState.chatInfo && prevState.chatInfo != this.state.chatInfo) {
            this.scrollToBottom();
            this.chatMessagesRef.current?.addEventListener('scroll', this.handlePaginationMessages);
        }
        if(prevProps.chatUserInformation !== this.props.chatUserInformation){
            this.channel.removeListener('messageAdded', this.handleMessageAddedIndividual);
            this.channel.removeListener('updated', this.handleMessageChannelUpdated);
            this.client = this.props.client;
            this.channelUniqueName = this.props.channelUniqueName;
            this.chatUserInformation = this.props.chatUserInformation;
            const individualChatType = this.props.chatUserInformation.attributes.friendly_name != 'eventChatRoom';
            const chatInformation = this.getChatInformation(individualChatType, this.props.chatUserInformation);
            this.chatLinkRef.current?.removeEventListener('scroll', this.handleChatLinkPagination);
            this.chatMediaRef.current?.removeEventListener('scroll', this.handleChatMediaPagination);
            this.setState({
                loading: true,
                messages: {},
                chatInformation,
                individualChat: individualChatType,
                totalUnreadMessages: 0,
                clearChatApplied: this.props.chatUserInformation.attributes.clear_chat,
                chatMessageObj: {},
                pinnedMessagesData: this.props.chatUserInformation.attributes.pinned,
                groupMediaCount: 0,
                groupMediaLinks: [],
                delivery: this.props.chatUserInformation.attributes.delivery,
                read: this.props.chatUserInformation.attributes.read,
                groupMediaPageCount: 0,
                groupLinkPageCount: 0,
                chatLinkRefAdded: false,
                organizarMessageCount: 0,
                organizarMessage: false
            });
            this.getMedias();
            this.getHyperMedias();
            this.handleUserClick();
        }
        if (prevProps.chatUserInformation.attributes.sid != this.props.chatUserInformation.attributes.sid) {
            let showOrgMsgs = false;
            let organizarMessage = localStorage.getItem('organizerMsgChatId') ?? "{}";
            const chatId = JSON.parse(organizarMessage);
            if (chatId && this.props.chatUserInformation.attributes.sid in chatId) {
                showOrgMsgs = chatId[this.props.chatUserInformation.attributes.sid];
            }
            this.setState({ organizarMessage: showOrgMsgs },()=>{
                this.handleUserClick();
            });
        }
        this.handleState(prevState);
    }

    handleState = (prevState: S) => {
        if(!this.state.organizarMessage && prevState.organizarMessage !== this.state.organizarMessage){
            this.getChatMessages(this.channel, false, {}, false);
        }
        if(this.state.currentMsgLen !== 0 && this.state.currentMsgLen !== prevState.currentMsgLen){
            const messageListing = document.getElementById('messagesListing');
            const elementsFound = document.querySelectorAll('.chatWrapper');
            if(messageListing && elementsFound){
                messageListing.scrollTop = elementsFound[this.state.currentMsgLen-1]?.closest('li')?.offsetTop ?? 0;
            }
            this.setState({ currentMsgLen: 0 });
        }
    }
    
    async componentWillUnmount(): Promise<void> {
        this.channel.removeListener('messageAdded', this.handleMessageAddedIndividual);
        this.channel.removeListener('updated', this.handleMessageChannelUpdated);
        this.channel.removeListener("messageRemoved", this.handleMessageRemoved);
        this.channel.removeListener("messageUpdated", this.handleMessageUpdated);
    }

    updateMessages = () => {
        const lastClearChat = this.state.clearChatApplied[this.state.clearChatApplied.length - 1].clear_chat;
        let newMessagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) => {
                const chatCleared = this.checkLatestClearDates(message.timestamp, lastClearChat.from, lastClearChat.to);
                if (chatCleared) return;
                if (dateKey in newMessagesData) {
                    newMessagesData = {
                        ...newMessagesData,
                        [message.DiffLabel]: {
                            data: [
                                ...newMessagesData[message.DiffLabel].data,
                                message
                            ]
                        }
                    };
                } else {
                    newMessagesData = {
                        ...newMessagesData,
                        [message.DiffLabel]: {
                            data: [
                                message
                            ]
                        }
                    };
                }
            })
        });
        this.setState({
            messages: newMessagesData
        });
    }
    handleClose =()=>{
        if(this.state.mediaPopup){
            this.setState({chatInfo:false});
        } 
        this.setState({ mediaScreen: !this.state.mediaScreen,mediaPopup:false})
    }
    checkLatestClearDates = (messageDate: string, from: string, to: string) => {
        let chatCleared = false;
        const fromDate = new Date(from).toLocaleString('en-IN', { hour12: false });
        if (fromDate != 'Invalid Date' &&  new Date(messageDate) < new Date(from)) {
            chatCleared = true;
        }
        return chatCleared;
    }

    handlePaginationMessages(event: any) {
        if (event.target.scrollTop <= 20 && this.state.chatMessageObj.hasPrevPage && (!this.state.organizarMessage || this.props.chatUserInformation.attributes.friendly_name !== 'eventChatRoom')) {
            this.getChatMessages(this.channel, true, this.state.messages, true);
        }
    }
                    
    handleChatMediaPagination(event: any) {
        this.handleChatPaginationScroll(this.chatMediaRef, this.state.paginationMediaLoading, this.getPaginationMedias, this.state.groupMediaCurrentPage, this.state.groupMediaPageCount);
    }

    handleChatPaginationScroll = (currentRef: React.RefObject<HTMLDivElement>, loadingState: boolean, callBackFunction: Function, currentPage: number, totalPages: number) => {
        if (currentRef.current && currentPage + 1 <= totalPages) {
            const scrollableHeight = currentRef.current.scrollHeight - currentRef.current.clientHeight;
            if (currentRef.current.scrollTop >= scrollableHeight && !loadingState) {
                callBackFunction();
            }
        }
    }

    handleChatLinkPagination(event: any) {
        this.handleChatPaginationScroll(this.chatLinkRef, this.state.paginationHyperLoading, this.getHyperPaginationMedias, this.state.groupHyperCurrentPage, this.state.groupLinkPageCount);
    }

    getChatInformation(individualChatType: boolean, chatInform: any) {
        let chatInformation = {};
        if (individualChatType) {
            const secondPerson = chatInform.attributes.first_user_image.id != localStorage.getItem('acc_id') ? this.props.chatUserInformation.attributes.first_user_image : this.props.chatUserInformation.attributes.second_user_image;
            chatInformation = {
                image: secondPerson.url,
                name: secondPerson.name,
                description: '',
                member_data: chatInform.attributes.member_data,
                organisation_name: ''
            }
        } else {
            chatInformation = {
                image: chatInform.attributes.image?.url,
                name: chatInform.attributes.event_name,
                description: chatInform.attributes.description,
                member_data: chatInform.attributes.member_data,
                organisation_name: chatInform.attributes.organisation_name,
            }
        }
        return chatInformation;
    }

    getMessageObj(data: any, media: any, dateLabel: string) {
        return {
            customAttributes: data.attributes,
            author: data.author,
            body: data.body,
            dateUpdated: data.dateUpdated.toString(),
            index: data.index,
            media,
            memberSid: data.memberSid,
            sid: data.sid,
            timestamp: data.timestamp,
            type: data.type,
            DiffLabel: dateLabel,
        }
    }



    updatedReceipts = (participantId: string, currentDeliveryReceipts: any, currentReadReceipts: any, finalMsgId: number) => {
        let callUpdateGroup = false;
        if (!(participantId in currentDeliveryReceipts) || currentDeliveryReceipts[participantId] < finalMsgId) {
            currentDeliveryReceipts[participantId] = finalMsgId;
        }
        if (!(participantId in currentReadReceipts) || currentReadReceipts[participantId] < finalMsgId) {
            callUpdateGroup = true;
            currentReadReceipts[participantId] = finalMsgId;
        }
        return {updatedDeliveries: currentDeliveryReceipts, updatedReads: currentReadReceipts, callUpdateGroup};
    }

    joinChannel = async (channel: any) => {
        if (channel._internalState.status != 'joined') {
            try {
                await this.channel.join();
            } catch(e) {}
        }
        this.setState({
            messages: {},
            msgLoading: true
        })
        if(this.state.organizarMessage){
            this.getOrganizerMessages();
        } else {
            this.getChatMessages(channel, false, {}, false);
        }
        channel.on("messageAdded", this.handleMessageAddedIndividual);
        channel.on("messageRemoved", this.handleMessageRemoved);
        channel.on("messageUpdated", this.handleMessageUpdated);
        channel.on('updated', this.handleMessageChannelUpdated);
    }

    messageHandler = (channel: Channel, objMessage: { hasPrevPage?: boolean; items: Array<{ state: { timestamp: string; } }> }, currentMessages: {}, prevCall: boolean, onScroll: boolean) => {
        let messagesArray: any = [];
        if (objMessage.items.length > 0) {
            messagesArray = objMessage.items.map(async (message: any) => {
                const chatCleared = this.checkClearDates(message.state.timestamp);
                if (chatCleared) return;
                const DiffLabel = this.dateLabel(message.state.timestamp);
                return this.getMessagesPromises(message, DiffLabel);
            });
            Promise.all(messagesArray).then((values) => {
                let newMessagesList: any = {};
                if (prevCall) {
                    values.reverse();
                    newMessagesList = currentMessages;
                }
                const newMessages = this.setMessagesState(prevCall, values as Values[], newMessagesList);
                if (!onScroll) {
                    this.scrollToBottom();
                }
                if (Object.keys(newMessages).length < 30 && objMessage.hasPrevPage && !this.state.organizarMessage) {
                    this.getChatMessages(channel, true, newMessages, false);
                }
            });
        } else {
            this.setState({ loading: false, msgLoading: false });
        }
    }

    getChatMessages = async (channel: Channel, prevCall: boolean, currentMessages: {}, onScroll: boolean) => {
        const objMessage = await this.getMessagesData(prevCall, channel);
        if (prevCall && onScroll) {
            this.setState({ currentMsgLen: objMessage.items.length });
        }
        this.messageHandler(channel, objMessage, currentMessages, prevCall, onScroll);
    }

    setMessagesState = (prevCall: boolean, values: Values[], newMessages: { [x: string]: { data: Values[] }; }) => {
        const arrChatMessages = [...values];
        arrChatMessages.forEach((message: any) => {
            if (!message) return;
            if (message.DiffLabel in newMessages) {
                let messageDataList = [];
                if (prevCall) {
                    messageDataList = [
                        message,
                        ...newMessages[message.DiffLabel].data
                    ]
                } else {
                    messageDataList = [
                        ...newMessages[message.DiffLabel].data,
                        message
                    ]
                }
                newMessages = {
                    ...newMessages,
                    [message.DiffLabel]: {
                        data: messageDataList
                    }
                };
            } else {
                if (prevCall) {
                    newMessages = {
                        [message.DiffLabel]: {
                            data: [
                                message
                            ]
                        },
                        ...newMessages,
                    };
                } else {
                    newMessages = {
                        ...newMessages,
                        [message.DiffLabel]: {
                            data: [
                                message
                            ]
                        }
                    };
                }
            }
        });
        this.setState({ messages: newMessages, loading: false, msgLoading: false });
        return newMessages;
    }

    getOrganizerMessages = async() => {
        const orgMessages = [];
        let messages = await this.channel.getMessages();
        while (messages.hasPrevPage) {
            const filteredMessages = messages.items.filter((message: { state: { participantSid: string; }; }) => message.state.participantSid === this.props.chatUserInformation.participantId);
            orgMessages.unshift(...filteredMessages);
            messages = await messages.prevPage();
        }
        const filteredMessages = messages.items.filter((message: { state: { participantSid: string; }; }) => message.state.participantSid === this.props.chatUserInformation.participantId);
        orgMessages.unshift(...filteredMessages);
        if(orgMessages.length > 0){
            this.messageHandler(this.channel, { items: orgMessages }, [], true, false);
        } else {
            this.setState({ organizarMessageCount: 0 });
        }
        this.setState({ loading: false, msgLoading: false });
    }

    getMessagesPromises = async (message: any, DiffLabel: string) => new Promise(async (resolve, reject) => {
        let messageState = {};
        if (message.state.type == 'media') {
            const urlLink = await message.state.media.getContentTemporaryUrl();
            messageState = this.getMessageObj(message.state, urlLink, DiffLabel);
        } else {
            messageState = this.getMessageObj(message.state, message.state.media, DiffLabel);
        }
        resolve(messageState);
    });

    checkClearDates = (messageDate: string) => {
        let chatCleared = false;
        if (this.state.clearChatApplied.length > 0) {
            this.state.clearChatApplied.forEach((dates: any) => {
                const fromDate = new Date(dates.clear_chat.from).toLocaleString('en-IN', { hour12: false });
                if (fromDate != 'Invalid Date' && new Date(messageDate) < new Date(dates.clear_chat.from)) {
                    chatCleared = true;
                }
            });
        }
        return chatCleared;
    }

    getMessagesData = async (prevCall: boolean, channel: Channel) => {
        let objMessage: any = {};
        if (prevCall) {
            objMessage = await this.state.chatMessageObj.prevPage();
            this.setState({
                chatMessageObj: objMessage
            });
        } else {
            objMessage = await channel.getMessages();
            this.setState({
                chatMessageObj: objMessage
            });
        }
        return objMessage;
    }

    handleMessageAddedIndividual = async (message: any) => {
        if (message.conversation.sid != this.channel.sid) return;
        this.getMedias();
        this.getHyperMedias();
        let { messages } = this.state;
        let duplicateMsg = false
        Object.values(messages).forEach((msgs: any) => {
            if (msgs.data.filter((mssg: any) => mssg.sid == message.state.sid).length > 0) {
                duplicateMsg = true;
            }
        });
        if (duplicateMsg) return;
        let messageState;
        const DiffLabel = this.dateLabel(message.state.timestamp);
        if (message.state.type == 'media') {
            const urlLink = await message.state.media.getContentTemporaryUrl();
            messageState = this.getMessageObj(message.state, urlLink, DiffLabel);
        } else {
            messageState = this.getMessageObj(message.state, message.state.media, DiffLabel);
        }
        let organizarMessageCount = this.state.organizarMessageCount;
        if (messageState.customAttributes.senderType == 'non_profit_entity') {
            organizarMessageCount++;
        }
        if (DiffLabel in messages) {
            messages = {
                ...messages,
                [DiffLabel]: {
                    data: [
                        ...messages[DiffLabel].data,
                        messageState
                    ]
                }
            };
        } else {
            messages = {
                ...messages,
                [DiffLabel]: {
                    data: [
                        messageState
                    ]
                }
            };
        }
        this.setState({
            showMediaLoading: false,
            messages,
            organizarMessageCount
        });
        this.scrollToBottom();
    };

    handleUserClick = async () => {
        this.channel = await this.client.getConversationBySid(this.chatUserInformation.attributes.sid);
        await this.joinChannel(this.channel);
    }
 
    sendMessage = async () => {
        const { messageText: text } = this.state;
        if (text && String(text).trim()) {
            const links: Array<string> = [];
            await this.channel.prepareMessage()
                .setBody(text)
                .setAttributes({senderId: this.state.userId, senderType: 'user_entity', pinned: false})
                .build()
                .send();
            const urlRegex = /(https?:\/\/[^\s]+)/;    
            const messageParts = text.split(urlRegex);    
            messageParts.forEach((part, index) => {
                if (urlRegex.test(part)) {
                    links.push(part);
                }
            });
            this.saveLink(links);
            this.setState({ messageText: "" });
            this.scrollToBottom();
        }
        this.setState({emojiPicker: false})
    };

    importData = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            this.setState({
                showMediaLoading: true
            });
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            this.scrollToBottom();
            this.sendMedia(formData);
            const formMedia = new FormData();
            formMedia.append('media_link', event.target.files[0]);
            this.saveMedia(formMedia);
        }
      }
    
    sendMedia = async (formData: any) => {
        await this.channel.prepareMessage()
            .setBody('')
            .setAttributes({senderId: this.state.userId, senderType: 'user_entity', pinned: false})
            .addMedia(formData)
            .build()
            .send();
        this.scrollToBottom();
    }

    typingMessage = (e: any) => {
        this.channel.typing();
        let length = 0;
        for (let _ of e.target.value) {
            length++;
        }
        if(length <= 150){
            this.setState({ messageText: e.target.value })
        }
    }

    displayChatInfo = () => {
        if (!this.state.individualChat) {
            this.setState({ chatInfo: !this.state.chatInfo })
            this.scrollToBottom();
        }
    };

    keyUp = (event: any) => {
        if (event.keyCode == 13) { 
            event.preventDefault(); 
            this.sendMessage();
        }
    }

    scrollToBottom = () => {
        const messageListing = document.getElementById('messagesListing');
        if (messageListing) {
            messageListing.scrollTo(0, messageListing.scrollHeight + 100)
        }
    }

    scrollToBottomEmoji = () => {
        if (typeof document !== 'undefined'){
            let element = global.document.querySelector(".EmojiPickerReact");
            if (element) {
                element.scrollIntoView({block:'start'});
            }
        }  
   }

    handleEmojiPicker = () => {
        if(this.state.emojiPicker === false){
            this.setState({
                emojiPicker: true
            },() => this.scrollToBottomEmoji())
        }
        if(this.state.emojiPicker === true){
            this.setState({
                emojiPicker: false
            },()=>this.scrollToBottomEmoji())
        }        
    }

    handleEmojiSend = (emojiObject: any) => {
        const emojiText = emojiObject.emoji; // Get the emoji text
        
        // Combine the emoji with the existing text if it exists
        const updatedMessageText = this.state.messageText
        ? `${this.state.messageText}${emojiText}`
        : emojiText;
        let length = 0;
        for (let _ of updatedMessageText) {
            length++;
        }
        if(length <= 150){
            this.setState({ 
            selectedEmoji: emojiText,
            messageText: updatedMessageText 
            });
        }
      };

      dateLabel = (messageTime: any) => {
        const date2: any = new Date(messageTime);
        const date1: any = new Date();
        let DiffLabel = '';
        const DiffDays = Math.floor((Math.abs(date2 - date1) / 1000) / (60 * 60 * 24));
        if (DiffDays > 1) {
            DiffLabel = new Date(messageTime).toLocaleString('en-IN',{ day: 'numeric', month: 'short'});
            const yearLabel = new Date(messageTime).toLocaleString('en-IN',{ year: 'numeric' });
            DiffLabel = DiffLabel + ', ' + yearLabel;
            
        } else if (DiffDays == 1) {
            DiffLabel = 'Yesterday';
        } else {
            const date3 = new Date();
            date3.setHours(0,0,0,0);
            if (date2 < date3) {
                DiffLabel = 'Yesterday';
            } else {
                DiffLabel  = 'Today';
            }
        }
        return DiffLabel;
    }

    nextSearch = (direction: string) => {
        let newIndex = this.state.currentSearchSelect;
        if (direction == 'up') {
            newIndex = newIndex + 1;
        } else {
            newIndex = newIndex - 1;
        }
        if (this.state.searchResults.length - 1 < newIndex || newIndex < 0) return;
        const messageListing = document.getElementById('messagesListing');
        if (messageListing) {
            messageListing.scrollTop = this.state.searchResults[newIndex].closest('li').offsetTop;
        }
        this.setState({
            currentSearchSelect: newIndex,
        });
    }

    onSearchChange = (event: any) => {
        const elements = Array.from(document.querySelectorAll('.messagesContent'));
        let elementsFound: any = [];
        elements.forEach((elementData: any) => {
            if (elementData.textContent.toLowerCase().includes(event.target.value.toLowerCase())) {
                elementsFound = [
                    ...elementsFound,
                    elementData,
                ]
            }
        });

        if (elementsFound.length > 0) {
            elementsFound.reverse();
            const messageListing = document.getElementById('messagesListing');
            if (messageListing) {
                messageListing.scrollTop = elementsFound[0].closest('li').offsetTop;
            }
        }

        this.setState({
            searchTerm: event.target.value,
            currentSearchSelect: 0,
            searchResults: elementsFound
        });
    }

    getParticipantId = (memberData: any) => {
        const participant = memberData
        .filter((item: any) => item.id == this.state.userId &&
            'user_entity' == item.type.toLowerCase()
        );
        const participantId = participant[0]?.participant_id;
        return participantId;
    }

    exitGroup() {
        const { token } = this.state;

        const participantId = this.getParticipantId(this.state.chatInformation.member_data);
        
        const httpBody = {
            "sid": this.chatUserInformation.attributes.sid,
            "participant_id": participantId
         }

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'type': 'user_entity',
            'token': token,
        };
    
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        
        let url = `${configJSON.exitGroup}`;
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        this.exitGroupApi = request.messageId;
        runEngine.sendMessage(request.id, request);
        this.setState({
            loading: true,
        });
    }

    onChangeClearChat(event: any) {
        this.setState({
            clearChatOption: event.target.checked ? parseInt(event.target.value) : 0
        });
    }

    clearChat() {
        const { token } = this.state;

        let fromDate = this.getClearDates(this.state.clearChatOption);
        let toDate = this.getClearDates(0);
        const clearChat = {
            "from": fromDate,
            "to": toDate, 
        }

        const httpBody = {
            "sid": this.chatUserInformation.attributes.sid,
            "clear_chat": clearChat
        }

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'type': 'user_entity',
            'token': token,
        };
    
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        

        let url = `${configJSON.clearChat}`;
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        this.clearChatApi = request.messageId;
        runEngine.sendMessage(request.id, request);
        this.setState({
            clearChatOption: 0,
            deleteModal: false,
        });
    }


    getClearDates = (type: number) => {
        const currentDate = new Date();
        let oneDay = 24 * 60 * 60 * 1000;
        let prevDay = currentDate.getTime() - oneDay;
        let prevDate = new Date(prevDay);
        switch (type) {
            case 2:
                oneDay = 7 * 24 * 60 * 60 * 1000;
                prevDay = currentDate.getTime() - oneDay;
                prevDate = new Date(prevDay);
                break;
            case 3:
                prevDate = new Date(currentDate);
                prevDate.setMonth(currentDate.getMonth() - 1);
                break;
            case 0:
                prevDate = new Date (currentDate);
                break;
            default:
                break;
        }
        return new Date(prevDate).toISOString();
    }

    handleMessageRemoved = (messageData: any) => {
        let messagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) => {
                if (message.sid == messageData.sid) return;
                messagesData = this.handleMessageObjUpdate(dateKey, messagesData, message);
            })
        });
        this.setState({
            messages: messagesData
        });
    }

    handleBackIcon = () => {
        // @ts-ignore
        console.log('ClickedBackButton',navigation.goBack())
    }
        
    handleProfileBackIcon = () => {
       this.props.screenType("chat-screen")
       this.props.toggleType(false)
    }

    handleMessageObjUpdate = (dateKey: string, messagesData: any, message: any) => {
        if (dateKey in messagesData) {
            messagesData = {
                ...messagesData,
                [message.DiffLabel]: {
                    data: [
                        ...messagesData[message.DiffLabel].data,
                        message
                    ]
                }
            };
        } else {
            messagesData = {
                ...messagesData,
                [message.DiffLabel]: {
                    data: [
                        message
                    ]
                }
            };
        }
        return messagesData;
    }

    handleMessageUpdated = (messageData: any) => {
        let messagesData: any = {};
        Object.keys(this.state.messages).forEach((dateKey: string) => {
            this.state.messages[dateKey].data.forEach((message: any) => {
                if (message.sid == messageData.message.sid) {
                    message.customAttributes = messageData.message.state.attributes;
                }
                messagesData = this.handleMessageObjUpdate(dateKey, messagesData, message);
            })
        });
        this.setState({
            messages: messagesData
        });
    }

    handleMessageChannelUpdated = (data: { conversation: any, updateReasons: Array<string> }) => {
        const { conversation, updateReasons } = data;
        if (updateReasons.indexOf('attributes') == -1) return;

    }

    saveLink = (links: Array<string>) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': localStorage.getItem("token"),
            type: configJSON.userType
        };

        links.forEach((link: string) => {
            const httpBody = {
                "sid": this.chatUserInformation.attributes.sid,
                "media_link": link,
                "media_type": "link"
            };
           
            const request = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            request.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
    
            request.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
              );
    
            this.saveLinkApi = request.messageId;
        
        
            request.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.saveMedias
            );
    
            request.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );
        
            runEngine.sendMessage(request.id, request);
        });
    }

    saveMedia = (formData: any) => {
        const header = {
            'token': localStorage.getItem("token"),
            type: configJSON.userType
        };

        formData.append('sid', this.chatUserInformation.attributes.sid);
        formData.append('media_type', "file");
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        this.saveHyperLinkApi = request.messageId;
    
    
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.saveMedias
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
    
        runEngine.sendMessage(request.id, request);
    }

    displayMediaAndLinks = () => this.setState({ mediaScreen: !this.state.mediaScreen });

    handleMediaTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ mediaTabValue: newValue })
    }

    getMedias = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': localStorage.getItem("token"),
            type: configJSON.userType
        };
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.getMediasApi = request.messageId;

        const url = `${configJSON.getMedias}?filter=file&sid=${this.chatUserInformation.attributes.sid}&page=1`;
    
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(request.id, request);
    }

    getHyperMedias = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': localStorage.getItem("token"),
            type: configJSON.userType
        };
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.getHyperMediasApi = request.messageId;

        const url = `${configJSON.getMedias}?filter=link&sid=${this.chatUserInformation.attributes.sid}&page=1`;
    
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(request.id, request);
    }

    getHyperPaginationMedias = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': localStorage.getItem("token"),
            type: configJSON.userType
        };
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.paginationHyperLinkApi = request.messageId;

        const url = `${configJSON.getMedias}?filter=link&sid=${this.chatUserInformation.attributes.sid}&page=${this.state.groupHyperCurrentPage + 1}`;
    
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(request.id, request);
        this.setState({
            groupHyperCurrentPage: this.state.groupHyperCurrentPage + 1,
        });
    }

    getPaginationMedias = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'token': localStorage.getItem("token"),
            type: configJSON.userType
        };
       
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.paginationMediaLinkApi = request.messageId;

        const url = `${configJSON.getMedias}?filter=file&sid=${this.chatUserInformation.attributes.sid}&page=${this.state.groupMediaCurrentPage + 1}`;
    
        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(request.id, request);
        this.setState({
            groupMediaCurrentPage: this.state.groupMediaCurrentPage + 1,
        });
    }

    toggleOrganiserMsg = async (event: any) => {
        let checkedValue = event.target.checked;
        this.setState({ organizarMessage: checkedValue });
        let organizerMsgChatId = localStorage.getItem('organizerMsgChatId') ?? "{}";
        const chatId = JSON.parse(organizerMsgChatId);
        localStorage.setItem('organizerMsgChatId', JSON.stringify({ ...chatId, [this.channel.sid]: checkedValue }));
        if(checkedValue){
            this.getOrganizerMessages();
        }
    }

    onMuteNotification = async () => {
        this.setState({ loader: true });
        const header = {
            "content-type": "application/json",
            "token": localStorage.getItem("token"),
            "type": "user_entity"
        }
        this.muteNotificationApiCallId = await apiCall({
            method: "POST",
            endPoint: "/bx_block_groupchat/conversation_participants",
            header: header,
            body: JSON.stringify({
                "client_conversation_id": this.chatUserInformation.id
            })
        });
    }
}
