// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../../components/src/utils/utils.web";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  history: any;
  location: any;
  match: any;
}

export interface IResponse {
  data: IData[];
}

export interface IData {
  id: string;
  attributes: {
    name: string;
    allow_trial_purchase: boolean | null;
    price: string;
    plan_type: string;
    description: any;
    notes: any;
    subscribed: boolean;
    used: boolean;
    discount_points: any;
    valid_for_days: number;
    total_points: any;
    discount_on_next_subscription: any;
  }
}

export interface IPointData {
  id: number;
  amount: number;
  point: number;
  isDisabled: boolean;
}

export interface IPlan {
  id: string;
  title: string;
  amount: number;
  duration: string;
  isCurrent: boolean;
  isExpired: boolean;
  descriptions: string[];
  notes: string[];
  days: number;
  pointsData: IPointData[];
  isTrialPurchased: boolean;
}
export interface S {
  // Customizable Area Start
  id: string;
  loading: boolean;
  planData: any[];
  totalPoint: number;
  pointsData: any[];
  buttonId: number;
  selectedPlanId: string;
  isVisible: boolean;
  planTitle: string;
  planType: string;
  planAmount: number;
  discountAmount: number;
  discountPoints: number;
  days: number;
  remainingAmount: number;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SubscriptionPlanController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fetchSubscriptionPlanDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: "",
      loading: false,
      planData: [],
      totalPoint: 0,
      pointsData: [],
      buttonId: -1,
      selectedPlanId: "-1",
      isVisible: false,
      planTitle: "",
      planAmount: 0,
      discountAmount: 0,
      discountPoints: 0,
      planType: "",
      days: 0,
      remainingAmount: 0
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        if (!responseJson.error && apiRequestCallId === this.fetchSubscriptionPlanDataApiCallId) {
          this.handlePlanDataApiResponse(responseJson);
        }
        this.setState({ loading: false });
      }
    }
  }

  async componentDidMount() {
    this.fetchSubscriptionPlanData();
  }

  handlePlanName = (name: string) => {
    const plans = [
      { name: "Trial", title: "Trial" },
      { name: "Presence-Only", title: "Presence-Only" },
      { name: "Discounted", title: "Discounted Non-Profit" },
      { name: "Supported", title: "Supported Non-Profit" },
      { name: "Full", title: "Full Non-Profit" }
    ];
    return plans.filter((item: any) => name?.includes(item.name))[0]?.title.toString();
  }

  isTrialExpired = (trial : string, allowPurchase: boolean | null) => {
    if(trial === "Trial" && !allowPurchase) {
      return true;
    } else {
      return false;
    }
  }

  handlePlanDataApiResponse = (responseJson: IResponse) => {
    let index = null, planType = "new", totalPoint = 0, remainingAmount = 0;
    const planData = responseJson.data.map((plan: IData, idx: number) => {
      if (plan.attributes.subscribed) {
        index = idx;
        planType = "change";
      }
      totalPoint = parseFloat(plan.attributes.total_points ?? 0);
      if (plan.attributes.discount_on_next_subscription) {
        remainingAmount = parseFloat(parseFloat((plan.attributes.discount_on_next_subscription / 100).toString()).toFixed(2));
      }
      const title = this.handlePlanName(plan.attributes.name);
      return {
        id: plan.id,
        title: title,
        days: plan.attributes.valid_for_days,
        amount: parseFloat(plan.attributes.price),
        duration: plan.attributes.valid_for_days >= 90 ? "Per Quarter" : "For 30 Days",
        isCurrent: plan.attributes.subscribed,
        isExpired: plan.attributes.used,
        descriptions: Object.values(plan.attributes.description ?? {}),
        notes: Object.values(plan.attributes.notes ?? {}),
        pointsData: Object.entries(plan.attributes.discount_points).map((obj: any, index: number) => {
          return {
            id: index,
            amount: parseFloat(obj[0]),
            point: parseFloat(obj[1]),
            isDisabled: parseFloat(obj[1]) > totalPoint,
          }
        }),
        isTrialPurchased: this.isTrialExpired(title, plan.attributes.allow_trial_purchase)
      }
    });
    this.setState({
      planData,
      remainingAmount,
      totalPoint,
      planType,
      pointsData: index !== null ? planData[index].pointsData : []
    });
  }

  onContinue = () => {
    this.props.history.push({
      pathname: "/PaymentOption",
      state: {
        planId: this.state.selectedPlanId,
        planType: this.state.planAmount === 0 ? "trial" : this.state.planType,
        days: this.state.days,
        planTitle: this.state.planTitle,
        planAmount: this.state.planAmount,
        discountAmount: this.state.discountAmount,
        discountPoints: this.state.discountPoints,
      }
    });
  }

  fetchSubscriptionPlanData = async () => {
    this.setState({ loading: true });
    const header = {
      "token": localStorage.getItem("nonprofitToken"),
      "type": "non_profit_entity"
    }
    this.fetchSubscriptionPlanDataApiCallId = await apiCall({
      method: "GET",
      endPoint: configJSON.subscriptionsApiEndPoint,
      header: header
    });
  }
}
// Customizable Area End