import React from "react";

import {
  // Customizable Area Start
  Card,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Switch,
  Avatar,
  Dialog,
  Box,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Redirect } from "react-router-dom";
import GoogleMap from "../../../components/src/utils/GoogleMap.web";
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import moment from "moment";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { COLORS } from "../../../framework/src/Globals";
import UserEventsController, { Props } from "./UserEventsController.web";
import DashboardWeb from "../../dashboard/src/Dashboard.web";
import { constants, icons, images } from "../../../components/src/constants";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
//@ts-ignore
import userEvent from "./userEvent.module.css";
import { isMobile } from "react-device-detect";
import OutlookCalendar from "../../../components/src/utils/OutlookCalendar.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class UserEvents extends UserEventsController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddress = (address:string,postalCode:string) => {
    return postalCode?postalCode.substring(0, postalCode.indexOf(" "))||postalCode:
    address.slice(address.lastIndexOf(",") + 1).trim();
  }
  renderLocation=(address:string, postalCode:string)=> {
    if(address && postalCode && !address.includes(postalCode)){
      address += `, ${postalCode}`; 
   }
  return address;
 }
  openDialog = () => {
    return (
      <Dialog
        className={userEvent.dialogWrapper} 
        open={this.state.isOpen}
        data-test-id="modal-close"
        onClose={() => this.setState({ isOpen: false })}
      >
        <div className={userEvent.dialogStyle}>
          <div>
            <Typography className={userEvent.titleFont}>{this.state.title}</Typography>
            <div className={userEvent.userInfo}>
              <div className={userEvent.avatarImgcontainer}>
                {this.state.organizationLogo ?
                  <img
                    src={this.state.organizationLogo}
                    alt="not found!"
                    className={userEvent.avatarImg}
                  />
                  :
                  <Avatar style={{ width: "30px", height: "30px" }}>
                    <Box fontSize="14px">{this.state.organizationName.charAt(0).toUpperCase()}</Box>
                  </Avatar>
                }
              </div>
              <Typography variant="body1" className={userEvent.userName}>{this.state.organizationName}</Typography>
            </div>
            <div className={userEvent.imageTextWrapper}>
              <div className={userEvent.avatarImgcontainer}>
                <img
                  src={icons.calender}
                  alt="avatar"
                  className={userEvent.avatarImg}
                />
              </div>
              <span className={userEvent.eventdate_loc}>
                {moment(this.state.startDate).format("DD MMM YYYY")},{" "}
                {moment(this.state.startTime).format("HH:mm a")}
              </span>
            </div>
            <div className={userEvent.imageTextWrapper}>
              <div className={userEvent.avatarImgcontainer}>
                <img
                  src={icons.locationred}
                  alt="location"
                  className={userEvent.avatarImg}
                />
              </div>
              <div className={userEvent.eventdate_loc}>
                {this.state.eventStatus === "Approved" || localStorage.getItem("acc_id") === this.state.accountId ?this.renderLocation(this.state.location,this.state.post_code):
                this.renderAddress(this.state.location,this.state.post_code)}
              </div>
            </div>
            <div className={userEvent.joinChat}>
              <AvatarGroup className={`AvatarGroupMyNeed`} max={4}>
                {this.state.participantList.map(
                  (person: any, index: number) => {
                    return (
                      <Avatar
                        src={person.profile_picture?.url}
                        className={`singleAvatar ${userEvent.multipleAvatarStyle}`}
                        key={`${index + 1}`}
                        alt={person.full_name}
                      >
                        {person.full_name.charAt(0).toUpperCase()}
                      </Avatar>
                    );
                  }
                )}
              </AvatarGroup>
            </div>
            <div className={userEvent.dialogBoxText}>
              {this.state.isCancelButton
                ? "You are cancelling your reservation at this upcoming activity."
                : "Thanks for opting to help on this event. Your pledge will be submitted to the event host for approval."
              }
            </div>
            <Button className={userEvent.BlueBtn} onClick={this.onButtonClick} data-test-id="dialog-submit-button">
              {this.state.isCancelButton
                ? "Confirm Cancellation"
                : "Confirm Pledge"
              }
            </Button>
            <Button className={`${userEvent.whiteBtn} ${userEvent.boldButton}`} data-test-id="cancelDialog" onClick={() => this.setState({ isOpen: false })}>
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  headerWeb() {
    return (
      <Toolbar className={userEvent.toolBar}>
        {!isMobile && (
          <div className={`${userEvent.backIconWeb}`}>
            <IconButton
              data-test-id="back-btn"
              aria-label="Open drawer"
              color="inherit"
              onClick={() => {
                this.props.navigation.goBack()}
              }
            >
              <img src={icons.back} />
            </IconButton>
            <Typography
              className={userEvent.activityDetails}
              variant="h6"
              noWrap
            >
              Event Details
            </Typography>
          </div>
        )}
      </Toolbar>
    );
  }

  renderTopCard = () => {
    return (
      <>
        {" "}
        <div className={userEvent.leftCard}>
          {!this.state.isImageLoaded &&
            <div className={userEvent.imageLoading}>
              <CircularProgress />
            </div>
          }
          <img
            className={this.state.isImageLoaded ? userEvent.cardImage: userEvent.hideImage}
            src={this.state.eventImage ?? images.cardImg2}
            onLoad={()=> this.setState({ isImageLoaded: true })}
          />
          {this.state.isFavorite ? (
            <FavoriteIcon
              className={userEvent.FavoriteIcon}
              onClick={this.removeFromFavoriteNeed}
            />
          ) : (
            <FavoriteBorderIcon
              onClick={this.addToFavoriteNeed}
              className={userEvent.FavoriteBorderIcon}
            />
          )}
        </div>
        <div className={userEvent.eventDetails}>
          <div className={userEvent.spaceBetweenDivs}>
            <div>
              <Typography className={userEvent.titleFont}>
                {this.state.title}
              </Typography>
              <div 
                data-test-id="redirect-btn"
                className={userEvent.userInfo}
                onClick={() => {
                  if(this.state.type !== "r1"){
                    this.props.navigation.navigate("NonProfitProfile", {id: this.state.organisation_id})}}
                  }
              >
                <div className={userEvent.avatarImgcontainer}>
                  {this.state.organizationLogo ?
                    <img
                      alt="not found!"
                      src={this.state.organizationLogo}
                      className={userEvent.avatarImg}
                    />
                    : <Avatar style={{ height: "30px", width: "30px" }}>
                      <Box fontSize="14px">{this.state.organizationName.charAt(0).toUpperCase()}</Box>
                    </Avatar>
                  }
                </div>
                <Typography variant="body1" className={userEvent.userName}>
                  {this.state.organizationName}
                </Typography>
              </div>
            </div>
            {!this.state.isEventPast &&
              <div>
                <div>
                  <img
                    src={icons.chats}
                    className={userEvent.chatIcon}
                    data-test-id="navigateToChat"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if(this.state.eventStatus === "Approved"){
                        this.onClickPopup(this.state.sid)}
                      else{this.MessageNpProfile(this.state.organisation_id) }} }                  />
                </div>
                <div style={{ marginRight: "11px" }}>
                  <Typography className={userEvent.messageText}>
                    Message
                  </Typography>
                </div>
              </div>
            }
          </div>

          <div className={userEvent.innerInfo}>
            <div className={`${userEvent.spaceBetweenDivs}`}>
              <div className={userEvent.imageTextWrapper}>
                <div className={userEvent.avatarImgcontainer}>
                  <img
                    src={icons.calender}
                    alt="avatar"
                    className={userEvent.avatarImg}
                  />
                </div>
                {this.state.startDate && (
                  <span className={userEvent.eventdate_loc}>
                    {moment(this.state.startDate).format("DD MMM YYYY")},{" "}
                    {moment(this.state.startTime).format("HH:mm a")}
                  </span>
                )}
              </div>
              {!this.state.isEventPast &&
                <OutlookCalendar
                  title={this.state.title}
                  classes={userEvent}
                  description={this.state.description}
                  location={this.state.location}
                  startDate={this.getDateAndTime(this.state.startDate, this.state.startTime)}
                  endDate={this.getDateAndTime(this.state.toDate, this.state.toTime)}
                />
              }
            </div>
            <div className={userEvent.imageTextWrapper}>
              <div className={userEvent.avatarImgcontainer}>
                <img
                  alt="location"
                  src={icons.locationred}
                  className={userEvent.avatarImg}
                />
              </div>
              <div className={userEvent.eventdate_loc}>
              {this.state.eventStatus === "Approved" || localStorage.getItem("acc_id") === this.state.accountId ?this.renderLocation(this.state.location,this.state.post_code):
              this.renderAddress(this.state.location,this.state.post_code)}
                </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  handleClick = () => {
    if (!this.state.isEventPast){
      this.setState({ isOpen: true, isCancelButton: false });
    }
  }

  onCancelPledge = () => {
    if (!this.state.isEventPast){
      this.setState({ isOpen: true, isCancelButton: true });
    }
  }

  renderBottomCard = () => {
    return (
      <div className={userEvent.eventDetails}>
        <div className={userEvent.about}>About the activity</div>
        <div className={userEvent.description}>Description</div>
        {this.state.description.split("\n").map((desc: string, index: number) => {
          return <p key={`${index + 1}`} className={userEvent.detailDescription}>{desc}</p>
        })}
        {(this.state.eventStatus === "Approved" && this.state.type === "r1")
         ? (
          <div>
          <div className={userEvent.description}>Additional Information</div>
          {this.state.additional_information?.split("\n").map((desc: string, index: number) => {
            return <p key={`${index + 1}`} className={userEvent.detailDescription}>{desc}</p>
          })}
         </div>
        )
        :null
        }
        {
         (this.state.eventStatus === "Approved" && this.state.type === "r2")
            ?
          (<div>
          <div className={userEvent.description}>Itinerary and Notes</div>
          {this.state.itinerarv_and_notes?.split("\n").map((desc: string, index: number) => {
            return <p key={`${index + 1}`} className={userEvent.detailDescription}>{desc}</p>
          })}
          </div>
        ):
        null
        }
        <div className={userEvent.spaceBetweenDivs}>
          <div className={userEvent.location}>Location</div>
          <div onClick={this.openMap} className={userEvent.locationWrapper}>
            <img src={icons.directions} className={userEvent.directionImg} />
            <div className={userEvent.getDirection}>Get Directions</div>
          </div>
        </div>
        <Box mb={isMobile && localStorage.getItem("acc_id") === this.state.accountId ? "55px" : "0px"}>
          <GoogleMap
            //@ts-ignore
            lat={this.state.eventLat}
            lng={this.state.eventLang}
          />
        </Box>
        {localStorage.getItem("acc_id") !== this.state.accountId && <div className={userEvent.spaceBetweenDivs}>
          {(this.state.eventStatus === null || this.state.eventStatus === "Cancelled") && (
            <Button
              className={
                this.state.isEventPast
                  ? userEvent.grayBtn
                  : userEvent.BlueBtn
              }
              data-test-id="pledge-button" onClick={() => this.handleClick()}>Pledge To Help</Button>
          )}
          {this.state.eventStatus === "Pending" && (
            <>
              <Button className={userEvent.BlueBtn} data-test-id="cancel-pledge" onClick={() => { if (!this.state.isEventPast) this.setState({ isOpen: true, isCancelButton: true }) }}>Cancel Pledge</Button>
              <Button className={`${userEvent.grayBtn} ${userEvent.spaceLeft}`} data-test-id="cancel-button">Pledge Pending</Button>
            </>
          )}
          {this.state.eventStatus === "Approved" && (
            <>
              <Button className={userEvent.BlueBtn} data-test-id="discard-pledge" onClick={() => this.onCancelPledge()}>Cancel Pledge</Button>
              <Button className={`${userEvent.grayBtn} ${userEvent.spaceLeft}`}>
                Pledged
                <ThumbUpOutlinedIcon style={{ fontSize: "19px", marginLeft: "7px" }} />
              </Button>
            </>
          )}
        </div>}
      </div>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {/* @ts-ignore */}
        <DashboardWeb
          data-test-id="dashboard"
          title={"Event Details"}
          showDrawerHeader={isMobile ? true : false}
          showBackButton={constants._icons.show}
          navigation={{
            ...this.props.navigation,
            goBack: () => {
             this.props.navigation.goBack()
            }
          }}
          showBottomBar={true}
          >
          {this.state.nonProfitProfileUrl && <Redirect to={`${this.state.nonProfitProfileUrl}`} />}
          <div className={userEvent.eventContainer}>
            {this.headerWeb()}
            {this.openDialog()}
            <Card>
              {this.renderTopCard()}
              {this.renderBottomCard()}
            </Card>
            {this.openDialog()}
          </div>
        </DashboardWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const AntSwitch: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      width: "38px",
      borderRadius: "11.5px",
      display: "flex",
      height: "20px",
    },
    switchBase: {
      color: "rgb(136 136 136)",
      padding: 2,
      "&$checked": {
        transform: "translateX(16px)",
        color: COLORS.skyBlue,
        "& + $track": {
          opacity: 1,
          backgroundColor: COLORS.white,
          border: "1px solid #009BE2",
        },
      },
    },
    thumb: {
      width: "16px",
      height: "16px",
      boxShadow: "none",
      "&$checked": {
        transform: "translateX(16px)",
        color: COLORS.skyBlue,
        "& + $track": {
          opacity: 1,
          border: "1px solid #009BE2",
          backgroundColor: COLORS.white,
          borderColor: COLORS.skyBlue,
        },
      },
    },
    track: {
      border: " 1px solid rgba(151, 151, 151, 0.43272)",
      backgroundColor: COLORS.white,
      borderRadius: "11.5px",
      opacity: 1,
    },
    checked: {},
  })
)(Switch);

export default UserEvents;
// Customizable Area End
